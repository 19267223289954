<!--  Author - Rajesh Subramanian / Monisha Balasubramanian , esales software solutions -->
<div class="aside-menu side-menu"> 
  <ul class="nav flex-column custom-scrollbar" id="sidenav" data-children=".nav-item" *ngFor="let t030AccessObj of t002MenuSet">
    <li class="nav-item" role="tab" id="heading-ecommerce" *ngIf="t030AccessObj.t030_Current_Access.viewAccess===true && t030AccessObj.menuName!='Settings'">
      <a class="nav-link ripple with-arrow collapsed" data-toggle="collapse" attr.data-target="#{{t030AccessObj.iconName}}" aria-expanded="false" aria-controls="collapse-ecommerce" *ngIf="t030AccessObj.routerurl!=null">
        <mat-icon> {{t030AccessObj.iconName}}</mat-icon> <span>{{t030AccessObj.menuName}}</span>
      </a>
      <ul id="{{t030AccessObj.iconName}}" class="collapse" role="tabpanel" aria-labelledby="heading-ecommerce" data-children=".nav-item">
        <li class="nav-item" *ngFor="let t030AccessDispObj of t030AccessObj.childrenMenuSorted">
          <!-- <a class="nav-link sub-link ripple" *ngIf="t030AccessDispObj.t030_Current_Access.viewAccess === true && t030AccessDispObj.t030_Current_Access.enableMenu === true && t030AccessDispObj.routerurl != null" [routerLink]="[t030AccessDispObj.routerurl]" (click)="addMenuToHistory(t030AccessDispObj,'review')">
            <span>{{t030AccessDispObj.menuName}}</span>
          </a>             -->
          <a class="nav-link sub-link ripple" 
          *ngIf="t030AccessDispObj.t030_Current_Access.viewAccess === true 
          && t030AccessDispObj.t030_Current_Access.enableMenu === true 
          && t030AccessDispObj.routerurl != null
          && t030AccessDispObj.routerurl != 'api/documentation'"
           [routerLink]="[t030AccessDispObj.routerurl]" (click)="addMenuToHistory(t030AccessDispObj,'review')">
            <span>{{t030AccessDispObj.menuName}}</span>
          </a>
          <a class="nav-link sub-link ripple" 
          *ngIf="t030AccessDispObj.t030_Current_Access.viewAccess === true 
          && t030AccessDispObj.t030_Current_Access.enableMenu === true 
          && t030AccessDispObj.routerurl == 'api/documentation'" 
          (click)="addMenuToHistory(t030AccessDispObj,'review')">
            <span>{{t030AccessDispObj.menuName}}</span>
          </a> 
          <a class="nav-link ripple" *ngIf="t030AccessDispObj.t030_Current_Access.viewAccess === true && t030AccessDispObj.t030_Current_Access.enableMenu === true && t030AccessDispObj.routerurl === null">
            <mat-icon>{{t030AccessDispObj.iconName}}</mat-icon> <span>{{t030AccessDispObj.menuName}}</span>     
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>