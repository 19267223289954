import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup} from '@angular/forms';
import {CommonService} from '../../../shared/common.service';
import {CreateDatabaseDetailsComponent} from '../../../superadmin/database/database-details.component';
import {ErpPopupService} from '../../components/Erp-popup-service';
import {ErpOverlayRef} from '../../components/ErpOverlayRef';

 @Component({
  selector: 'app-view-edit-top',
  templateUrl: './view-edit-top.component.html',
  providers: [],
})

export class ViewEditTopComponent implements OnInit {
 @Input() moduleName: string;
 @Output() printButtonClicked = new EventEmitter<any>();
 @Input() arrayListIsOne: string;
// @Input() billOpenStatus:boolean;
  @Input() showConvertOpen:boolean;
  @Input() showConvertTask:boolean;
  //@Input() showConvertVoid:boolean;
  @Input() showConvertSent:boolean;
  //@Input() convertVoidFlag:boolean;
  @Input() showVoidFlag:boolean;
   //@Input() invoiceConvertFlag:boolean;
   @Input() salesConvertFlag:boolean;
   //@Input() convertInvoice:boolean;
   @Input() showShipmentFlag:boolean;
   @Input() multiplePDFPackage:boolean; 
   @Input() convertFlag:boolean; 
   @Input() currentPageAccessObj;
   //@Input() recordPaymentFlag:boolean; 
   //@Input() convertInvoiceFlag:boolean;
   //@Input() showVoidButton:boolean;
   @Input() showConvertWIP:boolean;
   //@Input() showProductionSlipStatus:boolean;
   @Input() emailShowFlag:boolean;
   @Input() closeTaskFlag:boolean;
    @Output() executeQueryEmitter=new EventEmitter<any>();
lookupFormGroup: FormGroup;
options;
 dropDownFlag:boolean =false;
  moreActions = ['Mark as Active', 'Mark as Inactive',];
  estimateActions = ['Mark as Sent', 'Mark as Accepted', 'Mark as Declined'];
  artworkActions = ['Mark as Sent', 'Mark as Accepted', 'Mark as Declined', 'Mark as Partially Accepted'];
  issuesActions = ['Mark as Closed'];
    productionSlipActions = ['Mark as Completed', 'Mark as Void'];
    
 constructor( private commonService: CommonService,private dialog:MatDialog,private viewPackShipPDFIcon:ErpPopupService ) 
 {}
   ngOnInit() 
{
    this.loadMoreActionValues();
}
loadMoreActionValues() 
  {
   this.lookupFormGroup = this.commonService.formLookUpFormGroup(this.moduleName+'_MA');
    
    this.commonService.fetchFromLookup(this.lookupFormGroup.value).subscribe(
      data => {
        this.options = data;
      },
      error => {
        this.commonService.showERPErrorMessage(error)
      });
    
   

  }
   openEmailDialog() {
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'EMAIL'}});
   }

    /*openDialogPrint() {
    this.printButtonClicked.emit('A');
   }
   sendCustStmts() {
   	this.printButtonClicked.emit('A');
   }*/
   downloadPdf() {
  
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'PDF'}});
     //this.closeBtn();
     if(this.moduleName == 'VIEW_CLIENT_INVOICE')
        this.downloadAnnexure();
   }

   downloadAnnexure(){

     
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'ANNEXURE'}});
     this.closeBtn();
   }

   downloadTaxInvoicePdf(){
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'TAX_INVOICE_PDF'}});
     this.closeBtn();
   }


   downloadPackagesPdf(){
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'PACKAGE_PDF'}});
     //this.closeBtn();
   }
   downloadShipmentPdf(){
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'SHIPMENT_PDF'}});
     //this.closeBtn();
   }
   downloadCustomerStatements(){
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'CONTACTS_PDF'}});
     this.closeBtn();
   }
   downloadProductionSlip()
   {
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'PS_PDF'}});
     //this.closeBtn();
   }
   delete() {
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'DELETE'}});
     //this.closeBtn();
   }
   convertToOpen() {
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'OPEN'}});
     //this.closeBtn();
   }
   convertToTask() {
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'TASK'}});
     //this.closeBtn();
   }
   /*convertToBill() {
     this.printButtonClicked.emit('BILL');
     this.closeBtn();
   }*/
   /*convertToInvoice() {
     this.printButtonClicked.emit('INVOICE');
     this.closeBtn();
   }*/
   convertToPO() {
     this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'PART_REQUEST_TO_PO'}});
     this.closeBtn();
   }
   /*applyToBills() {
     this.printButtonClicked.emit('APPLY_TO_BILLS');
     this.closeBtn();
   }*/
    /*applyToInvoice() {
     this.printButtonClicked.emit('APPLY_TO_INVOICE');
     this.closeBtn();
   }*/
  /*openUseCreditPopUp(){
    this.printButtonClicked.emit('OPEN_VENDOR_CREDITS_POPUP')
    this.closeBtn();
  }  */
  /*openRecordPaymentPopup(){
    this.printButtonClicked.emit('OPEN_RECORD_PAYMENT_POPUP');
    this.closeBtn();
  }*/
  /*convertSOToVoid(){
    this.printButtonClicked.emit('CONVERT_SO_TO_VOID');
    this.closeBtn();
  }*/
  closeBtn() {
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'CLOSE'}});
  }

/*emit method for the bulk action starts 9072*/
openMoreActions(value)
{
 this.printButtonClicked.emit({action:'MORE_ACTIONS',data:value}); 
  if(value=='Delete')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'DELETE'}});
  if(value=='Mark as Active')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'ACTIVE'}});
  if(value=='Mark as Inactive')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'INACTIVE'}});
  if(value=='Mark as Sent')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'SENT'}});
  if(value=='Mark as Accepted')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'ACCEPTED'}});
  if(value=='Mark as Declined')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'DECLINED'}});
  if(value=='Mark as WIP')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'WIP'}});
  if(value=='Mark as Closed')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'CLOSED'}});
  if(value=='Mark as Partially Accepted')
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'PARTIALLY ACCEPTED'}});
}

markasSent()
{
    this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'SENT'}});
}
convertToSalesOrder():void{
  this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'ESTIMATE_TO_SALES_ORDER'}});
   this.closeBtn();
}
convertToProofWIP()
{
  this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'CONVERT_TO_PROOF_WIP'}});
  this.closeBtn();
}
closeTask()
{
  this.printButtonClicked.emit({action:'MORE_ACTIONS',data:{lookupValue:'CLOSE_TASK'}});
  this.closeBtn();
}
databaseDetailsForm;
executeQuery(element,environment?:string)
  
{ 
  const dialogRef = this.dialog.open(CreateDatabaseDetailsComponent, {
    maxWidth:'30vw',
    data: {selectedRowObj: element, selectedEnvironment:environment,databaseDetailsForm:this.databaseDetailsForm}
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result.close){          
            this.databaseDetailsForm=result.resultValue;
            this.executeQueryEmitter.emit(this.databaseDetailsForm.value);              
          }
          this.closeBtn();
    });


}
}
