import { ERPFormBase } from './erp-form-base';

export class DropdownForm extends ERPFormBase<string> {
    controlType = 'dropdown';
  options: {key: string, value: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }

}
