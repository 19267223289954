import { Component, OnInit } from '@angular/core';
import{Response} from '@angular/http';
import {Router, ActivatedRoute} from '@angular/router';
import{AuthService} from './auth.service';

@Component({
  selector: 'app-signintoken',
  templateUrl: './nohtml.html' ,

})
export class SigninTokenComponent implements OnInit {
 param: string;
  constructor(private router: Router, /*private socialAuthService: AuthService,*/
    private authservice: AuthService,
  private activatedRoute: ActivatedRoute ) { }
   userDataObj ;
  ngOnInit() {
       this.authservice.signInLoadGooglePage(this.activatedRoute.snapshot.queryParams.code)
 ;


  }




}
