import {Directive, HostListener, ElementRef, OnInit, Input, OnChanges, SimpleChanges, Renderer2} from '@angular/core';
import { DateFormatPipe } from './dateFormatPipe.pipe';
import {environment} from '../../environments/environment';
import {CommonService} from './common.service';
import {CommonUtils} from '../core/common-utils';

@Directive({
	selector: '[dateFormatter]'
})
export class DateFormatterDirective implements OnInit {

  static readonly dateFormatType: any;
  private ouput: any;
  constructor(private elementRef: ElementRef,
     private datePipe: DateFormatPipe,
     private commonService: CommonService, private commonUtils: CommonUtils
  ) {
   this.ouput = this.elementRef.nativeElement;
   }

  ngOnInit() {
    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
      this.ouput.value = this.datePipe.transform(this.ouput.value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
     
 
  }

  /*@HostListener('focus', ['$event.target.value'])
    onFocus(value, format?) {
      this.ouput.value = this.datePipe.transform(value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
    }*/
  @HostListener('blur', ['$event.target.value'])
    onBlur(value, format?) {
      this.ouput.value = this.datePipe.transform(value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
    }

 @HostListener('dateChange', ['$event.target.value'])
    onDateChange(value, format?) {
      this.ouput.value = this.datePipe.transform(value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
    }

    @HostListener('ngModelChange', ['$event'])
    ngModelChange(value, format?) { 
      debugger;
     this.ouput.value = this.datePipe.transform(value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
    }

 /*@HostListener('mouseover',  ['$event.target.value']) 
   onMouseOver(value) {
     
      this.ouput.value = this.datePipe.transform(value, this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
   }*/
 




}
