
import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Inject} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ERPErrorStateMatcher} from '../../shared/ERPErrormatcher';
import {SettingsService} from '../settings-service';
 import {environment} from '../../../environments/environment';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {Observable,  of } from 'rxjs';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  providers: [],
})
export class CreateUserComponent extends CommonUtils implements OnInit {
 matcher = new ERPErrorStateMatcher();
 myForm: FormGroup;
 rolesList = [];
 locationList = [];
 availableuserList: any;
 availableUserNameLength: any;
 elseBlock;
  elseBlock1;
   elseBlock2;
    elseBlock3;   
userObj;
organizationObj;
requiredPasswordFlag = true;
locationIds: any;
requiredUsernameFlag;disabledValue:boolean = false;
userNameMinLengthFlag = false;
createButtonEnable = true;
moduleName : string = environment.NEW_USER;
 errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
  constructor(private router: Router,
    public dialogRef: MatDialogRef<CreateUserComponent>, public settingsService: SettingsService, private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private commonUtils: CommonUtils) 
{
     super();
 
this.settingsService.userForEdit = data.userObj;
if(data.userNameEditFlag != null && data.userNameEditFlag != undefined)
  this.requiredUsernameFlag = data.userNameEditFlag;
else
  this.requiredUsernameFlag = false;
this.myForm = new FormGroup
({
  userId: new FormControl('0'),
  userName: new FormControl({value: '', disabled: this.disabledValue}),
  password: new FormControl(''),
  setPassword: new FormControl(false),
  createdTs: new FormControl(new Date()),
  updatedTs: new FormControl(new Date()),
  email: new FormControl('', [Validators.required, Validators.email]),
  userStatus: new FormControl(true),
  type: new FormControl(''),
  t029Roles: new FormGroup({
    roleId: new FormControl(0), 
    lazyLoad: new FormControl('N'),
    pageSize:new FormControl(0),
    paginationLoadFlag: new FormControl(''),
  }),
    t028Location: new FormGroup({
    locationId: new FormControl(''), 
    locationName: new FormControl(''),
    status: new FormControl(true),
  }),
  t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
  t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
  t000Organization: new FormGroup({organizationId: new FormControl(0)}),
  userNameCheckInMainDB: new FormControl('N'),
  defaultUrl: new FormControl(''),
  lazyLoad: new FormControl('Y'),
  locationIds: new FormControl(),
});


 }

  onNoClick(): void {
   this.dialogRef.close();
  }

  ngOnInit() 
  {
    this.pageLoad();
  }

  pageLoad():void
  {
      try
      {
      this.organizationObj=this.commonService.loggedInUserObj.t000Organization.organizationId  
      this.fetchRoles();
      this.fetchLocations(); 
        if (this.commonUtils.checkNullOrUndefined(this.settingsService.userForEdit)) 
        {

         this.disabledValue=true;
         this.myForm.patchValue(this.settingsService.userForEdit);
         this.locationIds = this.settingsService.userForEdit.locationId.split(",");
         let locationId =[];
         for(let loop = 0 ;loop < this.locationIds.length ; loop++){
                locationId.push(parseFloat(this.locationIds[loop]));
             }
         this.myForm.get('locationIds').setValue(locationId);      
        }
        this.validationMethod();
     
      }
      catch(error)
     {
       this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
       this.errorFormGroup.get('errorStackTrace').setValue(error.message);
       this.commonService.saveErrorMessage(this.errorFormGroup.value);
     } 
  }

callFetchUser():Observable<any>{
  this.myForm.get('userNameCheckInMainDB').setValue('N'); // if N is set then validation is done at the organizaiton level 
  return this.settingsService.fetchUsers(this.myForm.value);
}

 fetchRoles(): void {

   
   
    this.settingsService.fetchRoles(this.myForm.get('t029Roles').value).
      subscribe(
       data => {
         this.rolesList = data;
        
       },
         error => {
         this.commonService.showERPErrorMessage(error);
       });
     }

  fetchLocations(){
  this.commonService.showProgressBar();
  this.commonService.fetchLocation(this.myForm.get('t028Location').value).subscribe(
    data=>{ 
      this.locationList = data;
        this.commonService.hideProgressBar();        
    },
    error=>{
       this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);  
    }
    );
}
  saveOrUpdateUsers(): void {  
    this.commonService.showProgressBar();
  
     /*****setting user obj in the form****/
    
this.userObj = this.commonService.loggedInUserObj;
 if (this.myForm.get('userId').value == 0) {
this.myForm.get('t001UserCreatedBy').patchValue(this.userObj);
this.myForm.get('t001UserUpdatedBy').patchValue(this.userObj);
this.myForm.get('t000Organization.organizationId').setValue(this.organizationObj);
}
if (this.commonUtils.checkNullOrUndefined(this.myForm.get('userId').value))
{
  
  
this.myForm.get('t001UserUpdatedBy').patchValue(this.userObj);
}
    this.settingsService.saveOrUpdateUser(this.myForm.value).
    subscribe(data => {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage(data.erpmessage);
      this.dialogRef.close(true);
      this.router.navigate(['/settings/reviewuser']);
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  /*  this.dialogRef.close(true);
    this.router.navigate(['/settings/reviewuser']);*/

   }
 cancelClick(): void {
  this.dialogRef.close();
}
  isCheckedValue() {
    if (this.myForm.get('setPassword').value === false) {
      this.requiredPasswordFlag = false;
    } else {
     this.requiredPasswordFlag = true;
   }
  }

/*saveButtonClick(): void{
   this.t101ContactPersons = this.myForm.get('t101ContactPersons') as FormArray;
    this.t101ContactPersons.push(this.addContact());
    this.validationMethod();
  }*/

  validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.myForm);   
  }
 userNameExistsCheck(): void
  {
     this.availableuserList = this.myForm.controls['userName'].valueChanges.pipe(
     debounceTime(400),
     distinctUntilChanged(),
     switchMap( userList => this.callFetchUser()),);
}
   /*findUserNameLength(): void{
     this.availableUserNameLength = this.myForm.get('userName').value.length;
     if(this.availableUserNameLength <= 4){
       this.userNameMinLengthFlag = true;
     }else{
       this.userNameMinLengthFlag = false;
     }
  }*/

}
