import {Component, OnInit, Inject,EventEmitter,Output} from '@angular/core';
import {Router} from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormControl,FormGroup} from '@angular/forms';
import {CommonService} from '../shared/common.service';
import {CommonUtils} from '../core/common-utils';
import {environment } from '../../environments/environment';
import {DialogOverviewExampleComponent} from '../dialogBox/dialogBox.component';
import { ImportErrorComponent } from './importerror/importerror.component';
declare const require: any;

@Component({
  selector: 'app-importpo',
  templateUrl: './importpo.component.html',
  styleUrls: ['./importpo.component.css']
})
export class ImportPODialog extends CommonUtils implements OnInit {
  filename: any;
  disabled: boolean = false;
 importFile: File;
 imgElem: any;
dialogResult:any=[];
  dialogTextToDisplay: string;
  showDuplicateRecords: boolean;
  showOverwiteText: boolean;
  radioToggleValue: string;
  showAutoItemNumber = false;
  showCharEncoding = false; 
  showZipFolderUpload = false;
  enableItemDiv=false;
  enableOtherDiv=true;
  notesForBulkItemDelete = false;
  myForm:FormGroup;
  SERVICE_URL;
 @Output() viewPageRefresh:EventEmitter<any>=new EventEmitter<any>();
  constructor(public snackBar: MatSnackBar, private router: Router,private commonService: CommonService,
    public dialogRef: MatDialogRef<ImportPODialog>,public dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public data: any, private commonUtils: CommonUtils,) {
   super();
   this.SERVICE_URL=this.commonService.SERVICE_URL;
    this.dialogTextToDisplay = data.labelText;
    this.myForm = new FormGroup({
      uploadedFileName:new FormControl(),
      zipFolderName:new FormControl()
  });
  }
 
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {    
    if (this.dialogTextToDisplay === 'Purchase Orders') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = true;
      this.showCharEncoding = true;

    } else if (this.dialogTextToDisplay === 'Items') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;
      this.disableFlag=true;
      this.showZipFolderUpload = true;
      this.enableItemDiv=true;
      this.enableOtherDiv=false;
    } else if (this.dialogTextToDisplay === 'Item') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;
      this.disableFlag=true;
      this.showZipFolderUpload = true;
      this.enableItemDiv=true;
      this.enableOtherDiv=false;
    } else if (this.dialogTextToDisplay === 'Contacts') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;    
    } else if (this.dialogTextToDisplay === 'Employees') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;    
    } else if (this.dialogTextToDisplay === 'Invoice') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = true;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Sales Orders') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = true;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Bills') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Recurring Bills') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Recurring Expenses') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Expenses') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Payments Made') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Vendor Credits') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Vendor Credits Refunds') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Sales Price Lists') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Purchase Price Lists') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Quantity Adjustment') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Value Adjustment') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Estimates') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Retainer Invoices') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Invoice Payments') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Recurring Invoices') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Credit Notes') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Refunds') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Journals') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    } else if (this.dialogTextToDisplay === 'Accounts') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = true;
    }else if (this.dialogTextToDisplay === 'Assets') {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;    
    } else if(this.dialogTextToDisplay === 'Items For Delete'){
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;
      this.notesForBulkItemDelete = true;
    }
    else {
      this.showDuplicateRecords = false;
      this.showAutoItemNumber = false;
      this.showCharEncoding = false;
    }
  }

  radioToggle(): void {
    if (this.radioToggleValue === 'Overwrite') {
      this.showOverwiteText = true;
    } else {
      this.showOverwiteText = false;
    }
  } 
  downloadSampleFile()
  {       
    if(this.dialogTextToDisplay.toUpperCase() === 'Employees'.toUpperCase())
     window.open(this.SERVICE_URL + environment.IMPORT_FOLDER + environment.IMPORT_EMPLOYEE_TEMPLATE +'?'+new Date(), '_blank');
    if(this.dialogTextToDisplay.toUpperCase() === 'Employee Earnings'.toUpperCase())
     window.open(this.SERVICE_URL + environment.IMPORT_FOLDER +  environment.IMPORT_EARNINGS_TEMPLATE +'?'+new Date(), '_blank');
    if(this.dialogTextToDisplay.toUpperCase() === 'Assets'.toUpperCase())
     window.open(this.SERVICE_URL + environment.IMPORT_FOLDER + environment.IMPORT_ASSETS_TEMPLATE +'?'+new Date(), '_blank');
 }

 fileEvent(event) {
   const fileSelected: File = event.target.files[0];
   if (fileSelected != null) {       
        this.disableFlag =false;
        this.myForm.get('uploadedFileName').setValue(fileSelected);
      }
    }
 folderUploadEvent(event) {
   const fileSelected: File = event.target.files[0];
   if (fileSelected != null) {       
        this.disableFlag =false;
        this.myForm.get('zipFolderName').setValue(fileSelected);
      }
    }
 importFiles(): void {   
   this.disableFlag=true; 
    if(this.dialogTextToDisplay === 'Assets'){   
      this.saveImportedFiles("IMPORT_CSV_ASSETS");
    }
    else if(this.dialogTextToDisplay === 'Employees'){   
      this.saveImportedFiles("IMPORT_CSV_EMPLOYEE");
    }
    else if(this.dialogTextToDisplay === 'Employee Earnings'){   
      this.saveImportedFiles("IMPORT_CSV_EMPLOYEE_EARNINGS");
    }
    else {
      this.saveImportedFiles();
    }
 }
   
   saveImportedFiles(moduleName?:any): void {
    this.commonService.showProgressBar();
   const fileinputname: File=this.myForm.get('uploadedFileName').value;   
      const body = new FormData();
     body.append('file', fileinputname);
     body.append('loggedUserId',this.commonService.loggedInUserObj.userId);
     body.append('moduleName',moduleName);
     body.append('orgId', this.commonService.loggedInUserObj.t000Organization.organizationId);
     body.append('fpEnable', this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.enableFP);
     body.append('cartEnable', this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.enableCart);
 
     if(this.dialogTextToDisplay === 'Items'){
       const zipFolderName: File = this.myForm.get('zipFolderName').value;
        body.append('zipFolder', zipFolderName);
     }
    this.commonService.importFiles(body,this.dialogTextToDisplay).
      subscribe(
      data=>{   
        if (moduleName == 'IMPORT_CSV_EMPLOYEE'){
          this.commonService.fetchDuplicateImport().subscribe(
            data =>{
              this.commonService.hideProgressBar();
              let error;
              if (this.commonUtils.checkNullOrUndefined(data)){
                if (data.length >0){
                let errorObj = "";
                for (var loop =0; loop < data.length; loop++){
                  if (errorObj != ""){
                    errorObj = errorObj+ ', '+ data[loop].userName;
                  } else {
                    errorObj = data[loop].userName;
                  }
                }
                
                error = errorObj;
                this.commonService.showImportEmployeeErrorMessage(error);
                } else {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage("Employees imported successfully");
                  this.doneClick(fileinputname);
                }
                }
            }
          );
        } else {
          if(data !=null)     {
            this.commonService.hideProgressBar();
            if(data.lineItemDetailList != null){
              this.dialogRef.close(data.lineItemDetailList);
            }
            else{
              if(data.erpmessage.indexOf("ErrorSku") !=-1){ 
                var errorSku=data.erpmessage.replace("ErrorSku-[","");
                errorSku=errorSku.replace("]","");
               this.commonService.showImportSKUErrorMessage(errorSku);
              }
              else{
                 this.commonService.showERPMessage(data.erpmessage);
              }
              this.doneClick(fileinputname);
            }
            
            this.disableFlag=false;
          }
        }
       },
       error=>{ 
         this.commonService.hideProgressBar();
         this.doneClick(fileinputname);
         this.disableFlag=false;
         
          if(error.error.erpmessage === 'Failed'){
            this.commonService.showERPErrorMessage(error);
          }           
          else{
            this.commonService.showImportErrorMessage(error.error);
          }
           
       }); 

   }  

  doneClick(inputObj){
	this.dialogResult.done=true;
	if(inputObj.name!=null){
	this.dialogResult.fileName=inputObj.name;
	}
	this.dialogRef.close(this.dialogResult);

	}
  myControl: FormControl = new FormControl();
  options = ['UTF-8 (Unicode)', 'UTF-16 (Unicode)', 'ISO-8859-1', 'ISO-8859-2', 'ISO-8859-1 (Turkish)', 'GB2312 (Simplified Chinese)', 'Big5 (Traditional Chinese)', 'Shift_JIS (Japanese)'];

}


