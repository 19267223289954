import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HrmService} from '../hrm-service';
import {CommonService} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
 import {environment} from '../../../environments/environment';
 
@Component({
  selector: 'app-addappraisal',
  templateUrl: './create-appraisalmaster.component.html'

})
export class CreateAppraisalMaster extends CommonUtils implements OnInit {
  moduleName=environment.NEW_APPRAISAL_MASTER;
  screenTitle='Appraisal Master';

	appraisalForm:FormGroup; 
subAppraisalSet; 
appraisalObj;
saveBlock:boolean=false;
   constructor(private router: Router,
    public dialogRef: MatDialogRef<CreateAppraisalMaster>, 
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private hrmService:HrmService, private commonService:CommonService,private commonUtils:CommonUtils) 
     { 
       super();

this.appraisalForm=new FormGroup({
 appraisalId:new FormControl(0),
appraisalName:new FormControl(''),
status:new FormControl(''),
initializeFlag:new FormControl('Y'),
createdTs:new FormControl(''),
subAppraisalSet:this.formBuilder.array([]),
t001UserCreatedBy: new FormGroup({userId: new FormControl()}),
  t001UserUpdatedBy: new FormGroup({userId: new FormControl()}),
       })
   this.appraisalObj = data.appraisalObj;
   }

  ngOnInit()
  {
    this.pageLoad();
  }

pageLoad()
{
 //this.validationMethod();
 this.subAppraisalSet = this.appraisalForm.get('subAppraisalSet')  as FormArray;

if(this.commonUtils.checkNullOrUndefined(this.appraisalObj))
{
  let subAppraisalLength: number=0;
  if(this.appraisalObj.appraisalId!=0)
  {    
    if(this.commonUtils.checkNullOrUndefined(this.appraisalObj.subAppraisalSet))
    {
       
      subAppraisalLength=this.appraisalObj.subAppraisalSet.length;
         if( subAppraisalLength>0)
         {
            for (let loop = 0; loop < subAppraisalLength; loop++)  
            this.subAppraisalSet.push(this.addJDPoints());
         }
        
    }
    
  }
    this.appraisalForm.patchValue(this.appraisalObj);

this.appraisalForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
}
else
{
this.appraisalForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
this.appraisalForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
}

}
 addJDPoints(): FormGroup
 {
    return this.formBuilder.group({
      createdTs: new FormControl(''),
        subAppraisalId: new FormControl(0),
        subAppraisalName: new FormControl(''),
        status: new FormControl(''),
          t208_AppraisalObj:new FormControl(0)
      });
  }
 addMoreJDPoints(): void {
    this.subAppraisalSet = this.appraisalForm.get('subAppraisalSet')  as FormArray;
    this.subAppraisalSet.push(this.addJDPoints());
   
  }
saveOrUpdateAppraisalMaster()
{
  this.saveBlock=true;
  this.commonService.showProgressBar(); 

  
  this.hrmService.saveOrUpdateAppraisalMaster(this.appraisalForm.value).subscribe(
    data=>
    {
      this.commonService.showERPMessage('Appraisal master saved successfully');
      this.commonService.hideProgressBar();
      this.dialogRef.close(true);
    },
    error=>
    {
      this.commonService.hideProgressBar();
    })

} 

 cancelClick(): void {
  this.dialogRef.close(true);
}

/*validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.categoryForm);   
  }*/
}