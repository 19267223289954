<div class="login_wrapper" style="background-image: url(assets/ERP-loginpage-bg.jpg); background-size:cover;">
<div class="container">
  <div class="col-lg-12 loginbox">
  <div class="row login-box-12">
  <div class="col-lg-8 textwrapper">
    <div class="wpawll-headline">BRINGING <span class="colorred">PEOPLE </span>TOGETHER</div>
     <div class="col-lg-12" style="margin-bottom: 5%; padding-left:0px;">
       <img [src]="imagePath"  (error)="errorHandler($event)" alt="" class="img-hris">
       
    </div>
  </div>
   <div class="col-lg-4 logincenter">

 <mat-card class="col-lg-12">
  <mat-card-title >
      Sign In</mat-card-title>
    
   <mat-card-content >
  <form (ngSubmit)="loginPageSubmit()" [formGroup]="myForm">
 
  <div >
    <mat-form-field class="col-lg-12" formGroupName="t000Organization">
    <input matInput placeholder="Portal Name" formControlName="portalName">  
  </mat-form-field>

  <mat-form-field class="col-lg-12">
    <input matInput placeholder="{{USERNAME_PLACEHOLDER}}" id="username"  formControlName="userName">    
  </mat-form-field>   
  </div>
   
   <div>
    <mat-form-field class="col-lg-12">
      <input matInput  type="password" placeholder="Password" id="password" formControlName="password" (enter)="loginPageSubmit()">
    </mat-form-field>
  </div>
   <div layout="row" class="buttonalgincenter">
    <button class="signin-button" color="primary" style="width: 100%;margin-top:2%;" type="submit">Sign In</button>
  </div>
<div class="col-lg-12">
</div>
<div class="col-lg-12" style="text-align: right">
<span matTooltip="IP : {{loggedInUserIp}} / Browser-Version: {{this.myForm.get('browserName').value}} {{this.myForm.get('browserVersion').value}}">
<mat-icon style="color:#0655b9">info</mat-icon></span>
</div>

</form>
</mat-card-content>
</mat-card>
</div>
</div>
</div>
<div class="row login-box-12 footer-wrapper">
</div>

</div>

</div>