/**Author -esales -9014  Rajesh Subramanian **/
import { Component, OnInit,HostListener ,Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {CommonService} from '../shared/common.service';
import {CreateissuesComponent} from '../issues/createissues/createissues.component';
import {CommonUtils} from '../core/common-utils';
 import {ChangePasswordComponent} from './changepassword/change-password.component';
import {environment} from '../../environments/environment';
import {FormControl, FormGroup, NgForm, FormBuilder,FormArray} from '@angular/forms';
import {ViewSearchComponent} from '../popup/view-search/view-search.component';
import {ErpPopupService} from '../core/components/Erp-popup-service';
import {ErpOverlayRef} from '../core/components/ErpOverlayRef';
import { DialogOverviewExampleComponent}
  from '../dialogBox/dialogBox.component';
  import {ViewMainHeaderIconComponent} from '../popup/view-topicon/view-topicon.component';
  import { CreateTicketComponent } from '../header/createTicket/createTicket.component';
  import {HrmService} from './../hrm/hrm-service';
@Component({
  selector: 'app-mainheader',
  templateUrl: './mainheader.component.html'
})
export class MainHeaderComponent extends CommonUtils implements OnInit {

@Input() displaySettings;
 keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
imageSrc;
searchList=[];
searchName: string;
myForm: FormGroup;
errorimageSourcePath=environment.ESALESUITE_LOGO;
@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

	if(this.keyCode==37 && this.ctrlKey){
       event.preventDefault();
    	event.stopPropagation();
 		this.navigateScreen('Backward');      
  	}else if(this.keyCode==39 && this.ctrlKey){
		event.preventDefault();
		event.stopPropagation();
		this.navigateScreen('Forward');       
	}
  }
	buildNumber=this.commonService.BuildNumber;
	shouldRun:boolean = true;
	loggedInUserObj;
	notificationCount:number;
	enableNotification:boolean=false;
	mobileIconsFlag:boolean=false;
	storageUsed:number;
	storagePercent:number;
	notificationDetailList;
   	appScreenForm = new FormGroup({
		value: new FormControl(''),
		dispValue: new FormControl(''),
	});
	t037ReminderDetailsFormGroup: FormGroup;

  ngOnInit() {
	  this.checkUserLogin();
this.loggedInUserObj=this.commonService.loggedInUserObj;

 let logoPath=this.loggedInUserObj.t000Organization.logoPath
  let portalName=this.loggedInUserObj.t000Organization.portalName;
  this.imageSrc = this.commonService.SERVICE_URL+'erpassets/'+portalName.toUpperCase()+'/'+logoPath; 

this.fetchNotificationCount();
 
 this.commonService.notificationChangedSubject.subscribe(
       result=>{
       	
         if(result==='REFRESH_NOTIFICATION')
         {
         
           this.fetchNotificationCount();
         }
       }); 
this.pageLoad();
  }
   checkUserLogin(){
 	 
 	//   console.log(window.location.pathname);
 	  if(this.commonUtils.checkNullOrUndefined(window.location.pathname) && window.location.pathname == '/'){
 	  	this.commonService.checkUserLogin('SignUp');
 	  }else{
 	  	 this.commonService.checkUserLogin('Pages');
 	  }
	 
   }
pageLoad():void{

}

dropDownFlag;
notificationFlag;

constructor(private authService: AuthService, private commonService: CommonService, private router: Router,
	public dialog: MatDialog, public commonUtils:CommonUtils,private viewSearchPopup:ErpPopupService,
	private viewTopIcon:ErpPopupService,private hrmService: HrmService ) {
	super();
	//this.imageSrc=this.commonService.SERVICE_URL;

	this.myForm=new FormGroup({
      searchName: new FormControl(''),
    });

	this.t037ReminderDetailsFormGroup = new FormGroup({
		t001UserReminderTo: new FormGroup({
		  userId: new FormControl(),
		})
	});
}

ponum;
	signOut() {

		 
		 //Rajesh commented this on sept 7- so that always the org field is captured
		//this.commonService.loggedInUserObj = null;
		//let sessionOrgportalName=this.cookieService.get('loginPortalName');
		//this.router.navigate([sessionOrgportalName]);
		let dialog;
		if (this.commonUtils.checkNullOrUndefined(this.commonService.myAttendanceObj)){
			if (this.commonService.myAttendanceObj.active){
				dialog = 'You are not Punched Out from the System. Are you sure you want to Punch-Out while Logging out? ';
			} else {
				dialog = 'You will be signed out from the system. Are you sure you want to continue? ';
			}
		} else {
			dialog = 'You will be signed out from the system. Are you sure you want to continue? ';
		}
		
		
		const dialogTextVal = dialog;
             const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                          width: '500px',
                          data: {dialogText: dialogTextVal}
                        });
             dialogRef.afterClosed().subscribe(result => {           
                if (result === true) {
					if (this.commonUtils.checkNullOrUndefined(this.commonService.myAttendanceObj) && this.commonService.myAttendanceObj.active){
					let empAttendanceFormObj = new FormGroup({
						attendanceId: new FormControl(),
						userObj: new FormGroup({
						  userId: new FormControl(),
						}),
						  loggedInTime:new FormControl(),
						  loggedOutTime:new FormControl(),
						  searchName: new FormControl(''),
						  createdTs: new FormControl(''),
						  presentFlag: new FormControl(),
						  locCord:new FormControl(''),
						  active: new FormControl(),
						  loginInTimeStamp: new FormControl(), 
						  extraHours: new FormControl(),
						  totalHrs: new FormControl(),
						  hoursToBeWorked: new FormControl(),
						  shiftIdObj: new FormGroup({
							shiftAllocationId: new FormControl()
						  }),
						  workedHours: new FormControl(),
						  logoutFlag: new FormControl()
					  });
							  empAttendanceFormObj.patchValue(this.commonService.myAttendanceObj);
						   empAttendanceFormObj.get('loggedOutTime').setValue(new Date());
						   empAttendanceFormObj.get('active').setValue(false);
						   empAttendanceFormObj.get('logoutFlag').setValue(true);
						   this.hrmService.saveOrUpdateEmployeeAttendance(empAttendanceFormObj.value).subscribe(
							data => {
							})
						}
                          this.commonService.clearUserData();
						  this.commonService.cookieService.deleteAll();
		                  this.commonService.emitInvalidLogin();
		                   this.commonService.showERPMessage(' You have been signed out from the system successfully',null);
                }
                else{
                     
                    }
          });
	 
	}
	

	navigateScreen(key:any):void{
		let currentIndex = this.commonService.currentMenuIndex;
		let menulength = this.commonService.menuHistory.length;
		let menuList = this.commonService.menuHistory;
		
		if(currentIndex >= 0 && menulength > 0){
			if(key=='Forward'){
				if(currentIndex < menulength-1){
					this.commonService.currentMenuIndex = currentIndex+1;
				}
			}else if(key=='Backward'){
				if(currentIndex != 0){
					this.commonService.currentMenuIndex = currentIndex-1;
				}
			}
			currentIndex = this.commonService.currentMenuIndex;
			this.router.navigate(['/'+menuList[currentIndex]]);
		}
	}

	addMenuList(menuUrl: any):void{
		this.commonService.addMenuList(menuUrl);
	}

	createTicket(): void{
	  const dialogRef = this.dialog.open(CreateTicketComponent,{
       width: '50%',
       height: '50%',
       id: 'feedbackpopup',
      });

      dialogRef.afterClosed().subscribe(result => {
		
      });
	}

	sendFeedbackOrIssues(): void{
	  const dialogRef = this.dialog.open(CreateissuesComponent,{
       // width: '50%',
       height:'45%',
       id: 'feedbackpopup',
      });

      dialogRef.afterClosed().subscribe(result => {
		
      });
	}

callDropDown(elementRef,iconName)
{
	let helpDispaly:ErpOverlayRef= this.viewTopIcon.open(elementRef._elementRef,
	{ 
		data: {iconName:iconName,displaySettings:this.displaySettings}},ViewMainHeaderIconComponent);
		helpDispaly.afterClosed.subscribe(
       	result=>{
        	if(result)
        	{
        		
        	}
        },
        error=>{ 

		});
}

fetchNotificationCount()
{
	this.t037ReminderDetailsFormGroup.get('t001UserReminderTo.userId').setValue(this.commonService.loggedInUserObj.employeeId);
	this.commonService.fetchNotificationCount(this.t037ReminderDetailsFormGroup.value).subscribe(
		data=>
		{			
			if(this.commonUtils.checkNullOrUndefined(data))
			{				
				if(parseInt(data)!=0)
				{
					this.notificationCount=parseInt(data);
					this.enableNotification=true;
				}
				else
					this.enableNotification=false;				
			}				
		},
		error=>
		{
			console.log(error);			
		});
}

offlineModeAlert(){
}
 
callChangePassword()
{

const dialogRef = this.dialog.open(ChangePasswordComponent, {
            //width: '25%',
            id: 'changepasspopup',
            data: {}
          });

          dialogRef.afterClosed().subscribe(result => 
          {
            
          });	
}

callNotification(){
	
	this.fetchReminderToShowNotification();
}

routeToEditPage(notificationItem: any){
	  this.router.navigate([notificationItem.routeURL]);
}

fetchReminderToShowNotification(){	
	this.commonService.fetchReminderToShowNotification(this.t037ReminderDetailsFormGroup.value).subscribe(
		data=>
			{
				if(this.commonUtils.checkNullOrUndefined(data))
				{
					this.notificationFlag=true;
					this.notificationDetailList = data;
				}					
			},
		error=>
			{
				console.log(error);
			});
	}

showMobileIcons()
{
	if(this.mobileIconsFlag==false)
	this.mobileIconsFlag=true;
	else
	this.mobileIconsFlag=false;	
}

searchClicked(event){

	if(event.keyCode === 13)
 	{
  		this.searchName=event.target.value;
  		if(this.commonUtils.checkNullOrUndefined(this.searchName))
  		{
			let searchViewComponent:ErpOverlayRef= this.viewSearchPopup.open(this.keyboardEvent,
	       	 { width:'1900px',
	       	 
	       	 	data: 
	       	 	{
	       	 		searchName:this.searchName,		
	            }
	        },ViewSearchComponent);
			searchViewComponent.afterClosed.subscribe(
       	 	result=>{
        		if(result)
        		{
        			this.myForm.get('searchName').setValue('');
        		}
        	},
        	error=>{ });
		}
		else
		{
			 this.commonService.showERPMessage("Please enter the input for search");
		}
	}
}
	
errorHandler(event) {
    event.target.src = this.errorimageSourcePath;
}

}
