	<div class="col-lg-12">
		<mat-label class="header-label">
		{{LOAN_TRANSACTION}}
		</mat-label>
	</div>
	
	<div class="col-lg-12 mb-pd-0">
		<div class="table-responsive">
	<div class="example-container">


		<mat-table #table [dataSource]="dataSource">		    
		    <ng-container matColumnDef="amountPaid" >
		      <mat-header-cell *matHeaderCellDef >AMOUNT PAID</mat-header-cell>
		      <mat-cell *matCellDef="let element">{{element.amountPaid}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="loanDate" >
		      <mat-header-cell *matHeaderCellDef >{{LOAN_DATE}}</mat-header-cell>
		      <mat-cell *matCellDef="let element">{{element.date | dateFormatPipe}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="updatedBy" >
		      <mat-header-cell *matHeaderCellDef >{{UPDATED_BY}}</mat-header-cell>
		      <mat-cell *matCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t001UserUpdatedBy)">{{element.t001UserUpdatedBy.userName}}</p></mat-cell>
		</ng-container>
		  <mat-header-row *matHeaderRowDef="displayed_Columns"></mat-header-row>
    

    <mat-row class="mat-row" *matRowDef="let row; columns: displayed_Columns;">
    </mat-row>
	</mat-table>

	</div>
	</div>

		<div class="col-lg-12" align="center" style="padding-top: 1%">
          <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
    </div>

</div>
