import {map,switchMap, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup,FormArray, FormBuilder, Validators, FormControlName, AbstractControl} from '@angular/forms';
import { RecruitmentService } from '../recruitment-service';
import {CommonService} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
import {EmailDialogComponent,DialogOverviewExampleComponent} from '../../dialogBox/dialogBox.component';


@Component({
    selector: 'app-interviewround',
    templateUrl: './interviewround.component.html'
  })
  
  export class InterviewRoundComponent extends CommonUtils implements OnInit {
      myForm: FormGroup;
      toEmail;
      ccEmail;
      bccEmail;
      notificationForm: FormGroup;
      myFirstRoundForm: FormGroup;
      mySecondRoundForm: FormGroup;
      myThirdRoundForm: FormGroup;
      myHrRoundForm: FormGroup;
      onetooneFlag= true;
      panelFlag= false;
      activeRoundIndex = 0;
      activeInterviewerIndex = 0;
      name;
      interviewRoundObj;
      interviewerList=[];
      interviewType;
      feedbackStatusList;
      interviewTypeFlag:boolean=true;
      feedbackList;
      newInterviewRoundObj;
      activeRound;
        firstRoundFlag = false;
        secondRoundFlag = false;
        thirdRoundFlag = false;
        hrRoundFlag = false;
          interviewer1Flag = false;
            interviewer2Flag = false;
                interviewer3Flag = false;
                  currentloginEmpId;
                  firstRoundStatusList = [];
                  secondRoundStatusList= [];
                  thirdRoundStatusList = [];
                  hrRoundStatusList = [] ;
userObj;
disableSave = true;
hiredFlag :boolean =false;

    constructor(private recruitService: RecruitmentService, private router: Router, public dialog: MatDialog,private formBuilder: FormBuilder,
        private commonService: CommonService, public commonUtils: CommonUtils, private route: ActivatedRoute,
        private dialogRef:MatDialogRef<InterviewRoundComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
              super(); 
               
              this.currentloginEmpId = this.commonService.loggedInUserObj.employeeId;
              this.newInterviewRoundObj=data;
              if(this.commonUtils.checkNullOrUndefined(data) &&
                this.commonUtils.checkNullOrUndefined(data.t004_CandidateStatusObj)){
                  this.activeRound = data.t004_CandidateStatusObj.lookupValue;
                  if(this.activeRound.indexOf("First Round") >= 0){
                    this.firstRoundFlag = true;
                    this.activeRoundIndex = 0;
                  }else if(this.activeRound.indexOf("Second Round") >= 0){
                    this.secondRoundFlag = true;
                    this.activeRoundIndex = 1;
                  }else  if(this.activeRound.indexOf("Third Round") >= 0){
                    this.thirdRoundFlag = true;
                    this.activeRoundIndex = 2;
                  }else  if(this.activeRound.indexOf("HR Round") >= 0 || this.activeRound.indexOf("Shortlisted") >= 0
                    || this.activeRound.indexOf("Hired") >= 0 || this.activeRound.indexOf("On Hold") >= 0 || this.activeRound.indexOf("Rejected") >= 0){
                    this.hrRoundFlag = true;
                    this.activeRoundIndex = 3;
                  }else{
                    // console.log(this.activeRound);
                  }
              }
              this.myFirstRoundForm = this.getFeedBackForm();
              this.mySecondRoundForm = this.getFeedBackForm();
              this.myThirdRoundForm = this.getFeedBackForm();
              this.myHrRoundForm = this.getFeedBackForm(); 
        }

       getFeedBackForm(): FormGroup{
          return  new FormGroup({
          feedbackId: new FormControl(0),
          interviewer1_Name: new FormControl(''),
          interviewer1_Comments: new FormControl(''),
          interviewer2_Name: new FormControl(''),
          interviewer2_Comments: new FormControl(''),
          interviewer3_Name: new FormControl(''),
          interviewer3_Comments: new FormControl(''),
          interviewer1_empId: new FormControl(''),
          interviewer2_empId: new FormControl(''),
          interviewer3_empId: new FormControl(''),
          interviewType:new FormControl(''),
          interviewer1Flag: new FormControl(false),
          interviewer2Flag: new FormControl(false),
          interviewer3Flag: new FormControl(false),
          t004StatusObj:new FormGroup({
            lookupId:new FormControl(0),
            lookupCode:new FormControl(''),
            lookupValue:new FormControl('')
          }),
          t843InterviewAppointmentObj:new FormGroup({
           appointmentId:new FormControl(0)
          }),
          t840CandidateObj:new FormGroup({
            candidateId: new FormControl(0),
            candidateCode: new FormControl(''),
            firstName: new FormControl(''),
            lastName: new FormControl(''),
            email: new FormControl(''),
            secondaryEmail: new FormControl(''),
            jobApplication: new FormGroup({
              jobId: new FormControl(0),
              jobCode: new FormControl(''),
              title: new FormControl('')
            })
          }),
          interviewer1_Status:new FormGroup({
             lookupId:new FormControl(0),
            lookupCode:new FormControl(''),
            lookupValue:new FormControl('')
          }),
           interviewer2_Status:new FormGroup({
             lookupId:new FormControl(0),
            lookupCode:new FormControl(''),
            lookupValue:new FormControl('')
          }),
            interviewer3_Status:new FormGroup({
             lookupId:new FormControl(0),
            lookupCode:new FormControl(''),
            lookupValue:new FormControl('')
          }),
          result_Status: new FormControl(''),
          createdTs: new FormControl(new Date()),
          updatedTs: new FormControl(),
          t001UserCreatedBy: new FormGroup({
            userId: new FormControl(0)
          }),
          t001UserUpdatedBy: new FormGroup({
            userId: new FormControl(0)
          }),
          editedBy:new FormControl(this.commonService.loggedInUserObj.empName),
          editedById:new FormControl(this.commonService.loggedInUserObj.employeeId),
          moduleName:new FormControl(''),
          emailFlag:new FormControl(false),
          bulkAction:new FormControl(''),
          batchSyncType:new FormControl(''),
          t020EmailTemplateObj: new FormGroup({
        type: new FormControl(''),  
        emailTemplateId: new FormControl(),
        subject: new FormControl(''),
        body: new FormControl(''),
        cC:new FormControl(),
        bCC:new FormControl(),
        to:new FormControl(),
        defaultTemplate:new FormControl(),
        statusFlag:new FormControl(),
        billId:new FormControl(0),
        pdfAttachedInEmail : new FormControl(false),
        t001UserCreatedBy: new FormGroup({userId: new FormControl(0),userName: new FormControl('')}),
        templateName: new FormControl('')
      }),
      });
        }

        ngOnInit(){

           this.fetchFromLookup('CANDIDATE_FEEDBACK_STATUS'); 
           this.setInitialInterviewerData();
           this.fetchFeedbackForRounds();

        }
        setInitialInterviewerData(){
          this.myForm = this.getFeedBackForm(); 
          
          if(this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj)){
              
             this.myForm.get('t004StatusObj').patchValue(this.newInterviewRoundObj.t004_CandidateStatusObj)
             this.myForm.get('t840CandidateObj').patchValue(this.newInterviewRoundObj)
             this.myForm.get('t843InterviewAppointmentObj').patchValue(this.newInterviewRoundObj.t843_InterviewAppointmentObj)
             if(this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerNames)){
               if((this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerNames).indexOf(',') > 0){
                 this.interviewerList=this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerNames.split(",");
                 
                 for(var loop=0;loop<=this.interviewerList.length;loop++){
                   if(this.commonUtils.checkNullOrUndefined(this.interviewerList[loop]) && loop == 0){
                     this.myForm.get('interviewer1_Name').setValue(this.interviewerList[loop]);
                   }
                   if(this.commonUtils.checkNullOrUndefined(this.interviewerList[loop]) && loop == 1){
                     this.myForm.get('interviewer2_Name').setValue(this.interviewerList[loop]);
                   }
                   if(this.commonUtils.checkNullOrUndefined(this.interviewerList[loop]) && loop == 2){
                      this.myForm.get('interviewer3_Name').setValue(this.interviewerList[loop]);
                   }
                 }
               }else{

                 this.interviewerList=this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerNames;

                this.myForm.get('interviewer1_Name').setValue(this.interviewerList);
               }
             }
         
              if(this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerNames)){
               if((this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerId).indexOf(',') > 0){
                 let interviewerIdList = this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerId.split(",");
                   if(this.commonUtils.checkNullOrUndefined(interviewerIdList[0])){
                     this.myForm.get('interviewer1_empId').setValue(interviewerIdList[0]);
                     if(interviewerIdList[0] === this.currentloginEmpId){ 
                         this.myForm.get('interviewer1Flag').setValue(false);
                         this.disableSave = false;
                     }else{ 
                       this.myForm.get('interviewer1Flag').setValue(true);
                      
                     }
                       
                   }else if(!this.commonUtils.checkNullOrUndefined(interviewerIdList[0])){ 
                     this.myForm.get('interviewer1Flag').setValue(true);
                     //this.disableSave = true;
                   }
                   else{ 
                     
                     this.myForm.get('interviewer1Flag').setValue(true);
                   }
                   if(this.commonUtils.checkNullOrUndefined(interviewerIdList[1])){ 
                     
                     this.myForm.get('interviewer2_empId').setValue(interviewerIdList[1]);
                     if(interviewerIdList[1] === this.currentloginEmpId){  
                      this.myForm.get('interviewer2Flag').setValue(false);
                      this.disableSave = false;
                     }else{ 
                       
                       this.myForm.get('interviewer2Flag').setValue(true);
                     }
                         
                   }else if(!this.commonUtils.checkNullOrUndefined(interviewerIdList[1])){ 
                     

                     this.myForm.get('interviewer2Flag').setValue(true);
                   }
                   else{ 
                     
                     this.myForm.get('interviewer2Flag').setValue(true);
                   }
                   if(this.commonUtils.checkNullOrUndefined(interviewerIdList[2])){ 
                      this.myForm.get('interviewer3_empId').setValue(interviewerIdList[2]);
                      if(interviewerIdList[2] === this.currentloginEmpId){ 
                        this.myForm.get('interviewer3Flag').setValue(false);
                        this.disableSave = false;
                      }else{ 
                        
                        this.myForm.get('interviewer3Flag').setValue(true);
                      }
                         
                   }else{ 
                    
                     this.myForm.get('interviewer3Flag').setValue(true);
                   } 
               }else{
                       let interviewerIdList = this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewerId;
                      this.myForm.get('interviewer1_empId').setValue(interviewerIdList);

                       if(this.commonUtils.checkNullOrUndefined(interviewerIdList)){ 
                         if(interviewerIdList === this.currentloginEmpId){ 
                          this.myForm.get('interviewer1Flag').setValue(false);
                          this.disableSave = false;
                         }else{
                           
                           this.myForm.get('interviewer1Flag').setValue(true);
                         }
                         
                       }  else{
                         
                             this.myForm.get('interviewer1Flag').setValue(true);
                       }
                }
             }

             if(this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewType)
               && this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewType.lookupValue)){
               this.interviewType=this.newInterviewRoundObj.t843_InterviewAppointmentObj.interviewType.lookupValue;
               this.myForm.get('interviewType').setValue(this.interviewType);
               this.onChangeButton(this.interviewType);
             }
            
              
              if( (this.newInterviewRoundObj.t004_CandidateStatusObj.lookupValue).toString().indexOf("First Round") >= 0){ 
                    this.myFirstRoundForm.patchValue(this.myForm.value);
                    }else  if((this.newInterviewRoundObj.t004_CandidateStatusObj.lookupValue).toString().indexOf("Second Round") >= 0){
                        this.mySecondRoundForm.patchValue(this.myForm.value);
                    }else  if((this.newInterviewRoundObj.t004_CandidateStatusObj.lookupValue).toString().indexOf("Third Round") >=0){
                         this.myThirdRoundForm.patchValue(this.myForm.value);
                    }else  if((this.newInterviewRoundObj.t004_CandidateStatusObj.lookupValue).toString().indexOf("HR Round") >= 0){
                         this.myHrRoundForm.patchValue(this.myForm.value);
                 }

             this.activeInterviewerIndex = this.getActiveInterviewerSelectedIndex();
          }
        }
          getActiveInterviewerSelectedIndex(){ 

            if(this.activeRoundIndex === 0){
                if(this.myFirstRoundForm.get('interviewer1Flag').value === false){
                  return 0;
                }else if(this.myFirstRoundForm.get('interviewer2Flag').value === false){
                  return 1;
                }else if(this.myFirstRoundForm.get('interviewer3Flag').value === false){
                    return 2;
                 }else return 0;
              }else if(this.activeRoundIndex === 1){
                if(this.mySecondRoundForm.get('interviewer1Flag').value === false){
                  return 0;
                }else if(this.mySecondRoundForm.get('interviewer2Flag').value === false){
                  return 1;
                }else if(this.mySecondRoundForm.get('interviewer3Flag').value === false){
                    return 2;
                 }else return 0;
              }else if(this.activeRoundIndex === 2){
                if(this.myThirdRoundForm.get('interviewer1Flag').value === false){
                  return 0;
                }else if(this.myThirdRoundForm.get('interviewer2Flag').value === false){
                  return 1;
                }else if(this.myThirdRoundForm.get('interviewer3Flag').value === false){
                    return 2;
                 }else return 0;
              }else if(this.activeRoundIndex === 3){
                if(this.myHrRoundForm.get('interviewer1Flag').value === false){
                  return 0;
                }else if(this.myHrRoundForm.get('interviewer2Flag').value === false){
                  return 1;
                }else if(this.myHrRoundForm.get('interviewer3Flag').value === false){
                    return 2;
                 }else return 0;
              }
            else
              return 0;
             
          }
         setInterviewerValues(dataFormGroup,data,round){
         
           if(this.commonUtils.checkNullOrUndefined(data)){
             this.myForm = this.getFeedBackForm();
             this.interviewRoundObj=data;
             this.myForm.patchValue(data);
             this.myForm.get('t004StatusObj').patchValue(this.interviewRoundObj.t004StatusObj);
             this.myForm.get('t840CandidateObj').patchValue(this.interviewRoundObj.t840CandidateObj);
             this.myForm.get('t843InterviewAppointmentObj').patchValue(this.interviewRoundObj.t843InterviewAppointmentObj);
             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj.interviewerNames)){
               if((this.interviewRoundObj.t843InterviewAppointmentObj.interviewerNames).indexOf(',') > 0){
                 this.interviewerList=this.interviewRoundObj.t843InterviewAppointmentObj.interviewerNames.split(",");
               
                 for(var loop=0;loop<=this.interviewerList.length;loop++){
                   if(loop == 0 && this.commonUtils.checkNullOrUndefined(this.interviewerList[loop])){
                     this.myForm.get('interviewer1_Name').setValue(this.interviewerList[loop]);
                   }
                   if(loop ==  1 && this.commonUtils.checkNullOrUndefined(this.interviewerList[loop])){
                     this.myForm.get('interviewer2_Name').setValue(this.interviewerList[loop]);
                   }
                   if(loop == 2 && this.commonUtils.checkNullOrUndefined(this.interviewerList[loop])){
                      this.myForm.get('interviewer3_Name').setValue(this.interviewerList[loop]);
                   }
                 }
               }else{
                 this.interviewerList=this.interviewRoundObj.t843InterviewAppointmentObj.interviewerNames;
                this.myForm.get('interviewer1_Name').setValue(this.interviewerList);
               }
             }
             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj.interviewerNames)){
               if((this.interviewRoundObj.t843InterviewAppointmentObj.interviewerId).indexOf(',') > 0){
                 let interviewerIdList = this.interviewRoundObj.t843InterviewAppointmentObj.interviewerId.split(",");
                
                 if(this.commonUtils.checkNullOrUndefined(interviewerIdList[0])){
                     this.myForm.get('interviewer1_empId').setValue(interviewerIdList[0]);
                     if(interviewerIdList[0] === this.currentloginEmpId){ 
                       this.myForm.get('interviewer1Flag').setValue(false);
                       this.disableSave = false;
                     }else{ 
                       this.myForm.get('interviewer1Flag').setValue(true);
                       
                     }
                         
                   }else{ 
                    
                     this.myForm.get('interviewer1Flag').setValue(true);
                   }
                   
                   if(this.commonUtils.checkNullOrUndefined(interviewerIdList[1])){
                     this.myForm.get('interviewer2_empId').setValue(interviewerIdList[1]);
                     if(interviewerIdList[1] === this.currentloginEmpId){  
                       this.disableSave = false;
                        this.myForm.get('interviewer2Flag').setValue(false);
                       }else{ 
                          
                         this.myForm.get('interviewer2Flag').setValue(true);
                       }
                           
                   }else{ 
                     //this.disableSave = true;
                     this.myForm.get('interviewer2Flag').setValue(true);
                   }
                   if(this.commonUtils.checkNullOrUndefined(interviewerIdList[2])){
                      this.myForm.get('interviewer3_empId').setValue(interviewerIdList[2]);
                     if(interviewerIdList[2] === this.currentloginEmpId){  
                        this.myForm.get('interviewer3Flag').setValue(false);
                        this.disableSave = false;
                       }else{ 
                         
                         this.myForm.get('interviewer3Flag').setValue(true);
                       }
                         
                   }else{ 
                     
                     this.myForm.get('interviewer3Flag').setValue(true);
                   } 
               }else{
                       let interviewerIdList = this.interviewRoundObj.t843InterviewAppointmentObj.interviewerId;
                      this.myForm.get('interviewer1_empId').setValue(interviewerIdList);
                       
                       if(this.commonUtils.checkNullOrUndefined(interviewerIdList)){ 
                         if(interviewerIdList === this.currentloginEmpId ){ 
                           this.disableSave = false;
                          this.myForm.get('interviewer1Flag').setValue(false);
                         }else{
                           
                           this.myForm.get('interviewer1Flag').setValue(true);
                         }
                         
                       }  else{
                          
                             this.myForm.get('interviewer1Flag').setValue(true);
                       }
                }
             }
             
             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.interviewer1_Comments)){
                 this.myForm.get('interviewer1_Comments').setValue(this.interviewRoundObj.interviewer1_Comments);
             }else{
                 this.myForm.get('interviewer1_Comments').setValue('');
             }
             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.interviewer2_Comments)){
                 this.myForm.get('interviewer2_Comments').setValue(this.interviewRoundObj.interviewer2_Comments);
             }else{
                 this.myForm.get('interviewer2_Comments').setValue('');
             }
             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.interviewer3_Comments)){
                 this.myForm.get('interviewer3_Comments').setValue(this.interviewRoundObj.interviewer3_Comments);
             }else{
                 this.myForm.get('interviewer3_Comments').setValue('');
             }

             if(this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj) && 
               this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj.interviewType)
               && this.commonUtils.checkNullOrUndefined(this.interviewRoundObj.t843InterviewAppointmentObj.interviewType.lookupValue)){
               this.interviewType=this.interviewRoundObj.t843InterviewAppointmentObj.interviewType.lookupValue;
               this.myForm.get('interviewType').setValue(this.interviewType);
               this.onChangeButton(this.interviewType);
             }
            if(round === 'First Round'){ 
               
              
              this.myFirstRoundForm.patchValue(this.myForm.value);
              if(this.firstRoundFlag === false){
                this.myFirstRoundForm.get('interviewer1Flag').setValue(true);
                this.myFirstRoundForm.get('interviewer2Flag').setValue(true);
                this.myFirstRoundForm.get('interviewer3Flag').setValue(true);
              }
             }else if(round === 'Second Round'){
               
             
              this.mySecondRoundForm.patchValue(this.myForm.value);
                if(this.secondRoundFlag === false){
                this.mySecondRoundForm.get('interviewer1Flag').setValue(true);
                this.mySecondRoundForm.get('interviewer2Flag').setValue(true);
                this.mySecondRoundForm.get('interviewer3Flag').setValue(true);
              }
            }else if(round === 'Third Round'){
               
             
              this.myThirdRoundForm.patchValue(this.myForm.value);
               if(this.thirdRoundFlag === false){
                this.myThirdRoundForm.get('interviewer1Flag').setValue(true);
                this.myThirdRoundForm.get('interviewer2Flag').setValue(true);
                this.myThirdRoundForm.get('interviewer3Flag').setValue(true);
              }
            }else if(round === 'HR Round'){
             
             
              this.myHrRoundForm.patchValue(this.myForm.value);
               if(this.hrRoundFlag === false){
                this.myHrRoundForm.get('interviewer1Flag').setValue(true);
                this.myHrRoundForm.get('interviewer2Flag').setValue(true);
                this.myHrRoundForm.get('interviewer3Flag').setValue(true);
              }
            }
          }
        }
        onChangeButton(value){
            if (value === "One on One"){
              this.onetooneFlag = true;
              this.panelFlag = false;
            }
            else if (value == "Panel"){
              this.panelFlag = true;
              this.onetooneFlag = false;
            }
        }
        cancelClick(): void {
           this.dialogRef.close(true);
          }
        saveOrUpdateFeedbackForcandidate(round){
           this.commonService.showProgressBar();
          if(this.myForm.get('feedbackId').value !=0){
              this.myForm.get('updatedTs').setValue(new Date());
              this.myForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
            }else{
              this.myForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
            } 
          this.commonService.showProgressBar();
          if(round === 'First_Round'){
            this.myForm.patchValue(this.myFirstRoundForm.value);
          }else  if(round === 'Second_Round'){
            this.myForm.patchValue(this.mySecondRoundForm.value);
          }else  if(round === 'Third_Round'){
            this.myForm.patchValue(this.myThirdRoundForm.value);
          }else  if(round === 'HR_Round'){
            this.myForm.patchValue(this.myHrRoundForm.value);
            
            let userObj = this.commonService.loggedInUserObj;
        
        this.newInterviewRoundObj.t001UserCreatedBy = userObj;
        this.newInterviewRoundObj.t001UserCreatedBy = userObj;
        this.newInterviewRoundObj.t004_CandidateStatusObj = this.myHrRoundForm.get('interviewer1_Status').value;
        this.newInterviewRoundObj.bulkAction = this.myHrRoundForm.get('interviewer1_Status.lookupValue').value;
        this.newInterviewRoundObj.remarks = this.myHrRoundForm.get('interviewer1_Comments').value;

         let interviewUpdateStatusList = [];
          interviewUpdateStatusList.push(this.newInterviewRoundObj);
          
        this.recruitService.bulkUpdateActionForCandidateStatus(interviewUpdateStatusList).subscribe(
          data => 
          {

          },error =>{});

          }

         if(this.commonUtils.checkNullOrUndefined(this.newInterviewRoundObj.bulkAction) && this.newInterviewRoundObj.bulkAction === 'Hired'){
           
            if(this.commonUtils.checkNullOrUndefined(this.myForm.get('t840CandidateObj.email').value)){
              this.toEmail=this.myForm.get('t840CandidateObj.email').value;
            }
             if(this.commonUtils.checkNullOrUndefined(this.myForm.get('t840CandidateObj.secondaryEmail').value)){
              this.ccEmail=this.myForm.get('t840CandidateObj.secondaryEmail').value;
            }
            this.myForm.get('bulkAction').setValue(this.newInterviewRoundObj.bulkAction);
            this.confirmToSendShipmentEmail();
          }else{
          
              this.recruitService.saveOrUpdateFeedbackForRounds(this.myForm.value).subscribe(
            data => {
              this.commonService.hideProgressBar(); 
  
              this.addReminderDetails();
              this.commonService.showERPMessage(data.erpmessage,null);
              this.dialogRef.close(true);
             
            }, error => {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            }); 
          }          

        }
         confirmToSendShipmentEmail(){
          const dialogTextVal = 'Would you like to send offer email to this candidate ' + this.myForm.get('t840CandidateObj.firstName').value +' ?';
            const confTextVal = 'ConfirmOfferEmail';
            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                  width: '500px',
                                  data: {dialogText: dialogTextVal, confText: confTextVal}
                                });
                  dialogRef.afterClosed().subscribe(result => {
                   if (result === true) {
                          this.myForm.get('emailFlag').setValue(true);
                          this.emailPopUpOpenMethod();
                          this.dialogRef.close(true); 
                        } else{
                          this.myForm.get('emailFlag').setValue(false);
                          this.dialogRef.close(true); 
                        }
                  });
        }
         emailPopUpOpenMethod(){
    let dialogRef = this.dialog.open(EmailDialogComponent,
     {
      width: '85%',
      height: '90%',
      data: {dialogName: "OFFER_LETTER_EMAIL", toEmail:this.toEmail, ccEmail:this.ccEmail, bccEmail:this.bccEmail}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(this.commonUtils.checkNullOrUndefined(result)){
        this.myForm.get('t020EmailTemplateObj').patchValue(result);
        this.myForm.get('moduleName').setValue("OFFER_LETTER_EMAIL");
        this.myForm.get('batchSyncType').setValue('EMAIL');

          this.recruitService.saveOrUpdateFeedbackForRounds(this.myForm.value).subscribe(
            data => {
              this.commonService.hideProgressBar(); 
  
              this.addReminderDetails();
              this.commonService.showERPMessage(data.erpmessage,null);
              this.dialogRef.close(true);
             
            }, error => {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            }); 
      }      
    });
  }
        addReminderDetails(){

this.notificationForm = new FormGroup({
  t001UserCreatedBy: new FormGroup({
      userId: new FormControl(),
      userName: new FormControl(),
  }),
    t001UserUpdatedBy: new FormGroup({
      userId: new FormControl()
  }),
    routeURL: new FormControl(),
    moduleName: new FormControl(),
     t001UserReminderTo: new FormGroup({
      userId: new FormControl()
  }),
     notesDescription: new FormControl(),
     reminderDate: new FormControl(),
     reminderModuleName: new FormControl(''),
     reminderSentBy: new FormGroup({
       userId: new FormControl(),
     })

});

 //let navigateUrl = '/recruitment/reviewcandidate';
     /*****setting user obj in the form****/
this.userObj = this.commonService.loggedInUserObj.userId;
this.notificationForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
this.notificationForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);

  this.notificationForm.get('t001UserReminderTo.userId').setValue(this.newInterviewRoundObj.t843_InterviewAppointmentObj.hremployeeId);
  let navigateUrl = this.commonUtils.getAddLink('VIEW_CANDIDATE',btoa(this.newInterviewRoundObj.candidateId) );
  
  this.notificationForm.get('routeURL').setValue('/recruitment/createcandidate/'+btoa(this.newInterviewRoundObj.candidateId)+'/feedback');

 this.notificationForm.get('reminderDate').setValue(new Date());
this.notificationForm.get('t001UserCreatedBy.userName').setValue(this.commonService.loggedInUserObj.userName);
this.notificationForm.get('moduleName').setValue('CANDIDATE_FEEDBACK_UPLOAD');
this.notificationForm.get('notesDescription').setValue('Feedback is uploaded by '+this.commonService.loggedInUserObj.empName+' to the candidate name ( '+this.newInterviewRoundObj.t843_InterviewAppointmentObj.candidateName + ' ) and the Appointment # ( '+ this.newInterviewRoundObj.t843_InterviewAppointmentObj.appointmentNumber +' )');
this.notificationForm.get('reminderModuleName').setValue('FeedBack');
this.notificationForm.get('reminderSentBy.userId').setValue( this.commonService.loggedInUserObj.employeeId);

this.commonService.addReminderDetails(this.notificationForm.value).subscribe(
    data => {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage("Notification sent successfully");
      this.commonService.notificationChangedSubject.next("REFRESH_NOTIFICATION");
    },error => {
     this.commonService.hideProgressBar();
    });
}
        fetchFromLookup(lookupCode): void {
           let lookupFetchForm = new FormGroup({
               lookupId: new FormControl(),
               lookupCode: new FormControl(lookupCode),
               lookupValue: new FormControl(''),
           });
          this.commonService.fetchFromLookup(lookupFetchForm.value).subscribe(
          data => {
            if (this.commonUtils.checkNullOrUndefined(data)){
            if (lookupCode === 'CANDIDATE_FEEDBACK_STATUS')
                  this.feedbackStatusList= data;
                  for(var loopCount=0;loopCount<this.feedbackStatusList.length;loopCount++){
                    if(this.feedbackStatusList[loopCount].lookupValue === 'Rejected' || this.feedbackStatusList[loopCount].lookupValue === 'On Hold'){
                      this.firstRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.secondRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.thirdRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.hrRoundStatusList.push(this.feedbackStatusList[loopCount]);
                    }
                    else if(this.feedbackStatusList[loopCount].lookupValue === 'Approved to Second Round' ){
                      this.firstRoundStatusList.push(this.feedbackStatusList[loopCount]); 
                    }
                     else if(this.feedbackStatusList[loopCount].lookupValue === 'Approved to Third Round' ){
                      this.firstRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.secondRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      
                    }
                     else if(this.feedbackStatusList[loopCount].lookupValue === 'Approved to HR Round' ){
                      this.firstRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.secondRoundStatusList.push(this.feedbackStatusList[loopCount]);
                      this.thirdRoundStatusList.push(this.feedbackStatusList[loopCount]);
                    }
                     else if(this.feedbackStatusList[loopCount].lookupValue === 'Approved for Hiring' ||
                       this.feedbackStatusList[loopCount].lookupValue === 'Hired' ||
                       this.feedbackStatusList[loopCount].lookupValue === 'Shortlisted'){
                      this.hrRoundStatusList.push(this.feedbackStatusList[loopCount]);
                    }
                  }
            }
          }, error => {
            this.commonService.showERPErrorMessage(error);
          });
        }
        onClickStatusIssue(lookupObj){
          this.myForm.get('result_Status').setValue(lookupObj.lookupValue);
          
        }

        onClickHRStatus(lookupObj){
          this.myHrRoundForm.get('interviewer1_Status.lookupValue').setValue(lookupObj.lookupValue);
           
          if(lookupObj.lookupValue  === 'Hired'){
             this.hiredFlag =true;
          }else{
             this.hiredFlag=false;
          }
         
         
        }

        fetchFeedbackForRounds():void{
          this.myForm = this.getFeedBackForm();
          this.myForm.get('t840CandidateObj.candidateId').setValue(this.data.candidateId);
          this.recruitService.fetchFeedbackForRounds(this.myForm.value).subscribe(
          data => { 
            if (this.commonUtils.checkNullOrUndefined(data)){
                this.feedbackList= data; 
               

                if(this.commonUtils.checkNullOrUndefined(data) && data.length>0){
                  for(var loopCount = 0; loopCount<data.length; loopCount++){
                    
                    if((data[loopCount].t004StatusObj.lookupValue).toString().indexOf("First Round") >= 0){
                        this.myFirstRoundForm = this.getFeedBackForm();
                        this.setInterviewerValues(this.myFirstRoundForm,data[loopCount],'First Round');
                    }else  if((data[loopCount].t004StatusObj.lookupValue).toString().indexOf("Second Round") >= 0){
                        this.mySecondRoundForm = this.getFeedBackForm();
                        this.setInterviewerValues(this.mySecondRoundForm,data[loopCount],'Second Round');
                    }else  if((data[loopCount].t004StatusObj.lookupValue).toString().indexOf("Third Round") >=0){
                        this.myThirdRoundForm = this.getFeedBackForm();
                        this.setInterviewerValues(this.myThirdRoundForm,data[loopCount],'Third Round');
                    }else  if((data[loopCount].t004StatusObj.lookupValue).toString().indexOf("HR Round") >= 0){
                        this.myHrRoundForm = this.getFeedBackForm();
                       
                        this.setInterviewerValues(this.myHrRoundForm,data[loopCount],'HR Round');
                    }
                  }
                }
            }
          }, error => {
            this.commonService.showERPErrorMessage(error);
          });
        }
  }