<mat-card class="col-lg-12">
  <mat-card-title><mat-icon>account_balance</mat-icon>{{ASSET_TRANSACTION}}</mat-card-title>
  <mat-card-content style="margin-top: 10px;">
    <form (ngSubmit)="saveOrUpdateAssetTransaction()"  [formGroup]="assetTransactionForm">  

      <div class="col-lg-12 nopadding">

        <div class="col-lg-12 nopadding">

          <mat-form-field  formGroupName="movementType" class="col-lg-3">      
            <mat-select placeholder="{{MOVEMENT_TYPE}}" formControlName="lookupId" >
                <mat-option *ngFor="let moveType of movementTypeList" [value]="moveType.lookupId" (click)="movementTypeSelected(moveType)">
                  {{moveType.lookupValue}}
                </mat-option>
             </mat-select>
          </mat-form-field> 

           <mat-form-field class="col-lg-12" >
              <input matInput placeholder="{{ASSET_NAME}}" formControlName="assetName" >
          </mat-form-field>

          <mat-form-field class="col-lg-4">
             <input matInput placeholder="{{ASSET_NUMBER}}" formControlName="assetNumber" >
          </mat-form-field>

          <mat-form-field  class="col-lg-4">
            <input [matDatepicker]="assignedDate" placeholder="{{ASSIGNED_DATE}}" matInput formControlName="assignedDate" dateFormatter>
            <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="assignedDate"></mat-datepicker-toggle>
            <mat-datepicker #assignedDate></mat-datepicker>
          </mat-form-field>

        <div class="col-lg-2">
     <!--       <mat-form-field >
             <input matInput placeholder="{{QUANTITY}}" formControlName="availableQuantity" >
          </mat-form-field> -->
          <br>
         <!--  <span style="color:crimson;font-size:10px" align="end"> Available Qty: {{availableQty}}</span> --> <!-- {{assetTransactionForm.controls.availableQuantity.value}}</span> -->
       </div>

        </div>
  <div class="row col-lg-12">
        <div class="row col-lg-12">
        <mat-form-field  formGroupName="assignedFromType" class="col-lg-6">      
              <mat-select placeholder="{{ASSIGNED_FROM}}" formControlName="lookupId" [disabled]="this.assetTransCount>0" >
                  <mat-option *ngFor="let assignType of assignmentTypeList" [value]="assignType.lookupId" (click)="AssignmentFromSelected(assignType)">{{assignType.lookupValue}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
        <div class="col-lg-6">

          <div class="col-lg-12" *ngIf="assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'VENDOR'">
            <!-- <app-customer-search [formGroup]="vendorFormFromGroup" [contactType]="'VENDOR'" (contactClicked)="selectionClicked($event)"></app-customer-search> -->
            <mat-form-field class="col-lg-4 col-md-4 col-sm-4 col-xs-12" >
              <input matInput placeholder="Vendor Name" formControlName="vendorName" >
            </mat-form-field>
          </div>

          <div class="col-lg-12" *ngIf="assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'CUSTOMER'">
             <!-- <app-customer-search [formGroup]="customerFormFromGroup" [contactType]="'CUSTOMER'" (contactClicked)="selectionClicked($event)"></app-customer-search> -->
             <mat-form-field class="col-lg-4 col-md-4 col-sm-4 col-xs-12" >
              <input matInput placeholder="Customer Name" formControlName="customerName">
            </mat-form-field>
         </div>

        <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'WAREHOUSE'">
          <mat-form-field  formGroupName="warehouseFormFrom" class="col-lg-12">      
            <mat-select placeholder="{{WAREHOUSE}}" formControlName="locationId" [disabled] ="disableWarehouseBlock">
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.locationId" (click)="selectionClicked(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
         </div> 
          <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'EMPLOYEE'">
          <mat-form-field class="col-lg-12" formGroupName="t800EmployeeFrom" >
              <input class="inputWidth" type="text" aria-label="Number" placeholder="{{EMPLOYEE}}" matInput formControlName="firstName" [matAutocomplete]="empType" readonly="disableEmployeeBlock"><b class="caret" style="margin-left: -13px;"></b>
              <mat-autocomplete #empType="matAutocomplete" (optionSelected)="contactClicked($event.option)">
                <mat-option *ngFor="let employee of employeeList" [value]="employee.employeeId"(click)="selectionClicked(employee)">{{employee.firstName}}</mat-option>
              </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'SUB_WAREHOUSE_LOCATION'">
          <mat-form-field  formGroupName="warehouseFormFrom" class="col-lg-12">      
            <mat-select placeholder="{{WAREHOUSE}}" formControlName="locationId" [disabled] ="disableSubWarehouseBlock" >
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.locationId" (click)="selectionClicked(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
         </div> 
       </div>
             <div class="col-lg-12 nopadding">
          <mat-form-field  formGroupName="warehouseBlockFormFrom" class="col-lg-6" *ngIf="enableWareHouseBlockFrom">      
            <mat-select placeholder="Secondary Level Warehouse" formControlName="locationId" [disabled] ="disableSubWarehouseBlock" >
                <mat-option *ngFor="let warehouse of warehouseBlockFromList" [value]="warehouse.locationId" (click)="selectedWarehouseBlockFrom(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
          <mat-form-field  formGroupName="warehouseRoomFormFrom" class="col-lg-6" *ngIf="enableWareHouseRoomFrom">      
            <mat-select placeholder="Select Location" formControlName="locationId" [disabled] ="disableSubWarehouseBlock" >
                <mat-option *ngFor="let warehouse of warehouseRoomFromList" [value]="warehouse.locationId" (click)="selectedWarehouseRoomFrom(warehouse)"  >{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
         </div> 

       
 
       </div>

       <div class="row col-lg-12">
       <mat-form-field  formGroupName="assignedType" class="col-lg-6">      
              <mat-select placeholder="{{ASSIGNED_TO}}" formControlName="lookupId" >
                  <mat-option *ngFor="let assignType of assignmentTypeList" [value]="assignType.lookupId" (click)="AssignmentTypeSelected(assignType)">{{assignType.lookupValue}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
       <div class="col-lg-6">

          <div class="col-lg-12" *ngIf="assetTransactionForm.get('assignedType').get('lookupValue').value == 'VENDOR'">
            <!-- <app-customer-search [formGroup]="vendorFormGroup" [contactType]="'VENDOR'" (contactClicked)="contactClicked($event)"></app-customer-search> -->
            <mat-form-field class="col-lg-4 col-md-4 col-sm-4 col-xs-12" >
              <input matInput placeholder="Vendor Name" formControlName="vendorName">
            </mat-form-field>
          </div>

          <div class="col-lg-12" *ngIf="assetTransactionForm.get('assignedType').get('lookupValue').value == 'CUSTOMER'">
             <!-- <app-customer-search [formGroup]="customerFormGroup" [contactType]="'CUSTOMER'" (contactClicked)="contactClicked($event)"></app-customer-search> -->
             <mat-form-field class="col-lg-4 col-md-4 col-sm-4 col-xs-12" >
              <input matInput placeholder="Customer Name" formControlName="customerName">
            </mat-form-field>
         </div>

        <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedType').get('lookupValue').value == 'WAREHOUSE'">
          <mat-form-field  formGroupName="warehouseForm" class="col-lg-12">      
            <mat-select placeholder="{{WAREHOUSE}}" formControlName="locationId" >
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.locationId" (click)="contactClicked(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
        </div>
         <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedType').get('lookupValue').value == 'EMPLOYEE'">
          <mat-form-field class="col-lg-12" formGroupName="t800Employee">
              <input class="inputWidth" type="text" aria-label="Number" placeholder="{{EMPLOYEE}}" matInput formControlName="firstName" [matAutocomplete]="empTypeTo"><b class="caret" style="margin-left: -13px;"></b>
              <mat-autocomplete #empTypeTo="matAutocomplete" (optionSelected)="contactClicked($event.option)">
                <mat-option *ngFor="let employee of employeeList" [value]="employee.employeeId" (click)="contactClicked(employee)">{{employee.firstName}}</mat-option>
              </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-lg-12 nopadding" *ngIf="assetTransactionForm.get('assignedType').get('lookupValue').value == 'SUB_WAREHOUSE_LOCATION'">
          <mat-form-field  formGroupName="warehouseForm" class="col-lg-12">      
            <mat-select placeholder="{{WAREHOUSE}}" formControlName="locationId" >
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.locationId" (click)="contactClicked(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
        </div>
      </div>
        <div class="col-lg-12 nopadding">
          <mat-form-field  formGroupName="warehouseBlockForm" class="col-lg-6" *ngIf="enableWareHouseBlock">      
            <mat-select placeholder="Secondary Level Warehouse" formControlName="locationId" >
                <mat-option *ngFor="let warehouse of warehouseBlockList" [value]="warehouse.locationId" (click)="selectedWarehouseBlock(warehouse)">{{warehouse.locationName}}
                </mat-option>
             </mat-select>
          </mat-form-field>
          <mat-form-field  formGroupName="warehouseRoomForm" class="col-lg-6" *ngIf="enableWareHouseRoom">      
            <mat-select placeholder="Select Location" formControlName="locationId" >
                <mat-option *ngFor="let warehouse of warehouseRoomList" [value]="warehouse.locationId" (click)="selectedWarehouseRoom(warehouse)" >{{warehouse.locationName}} 
                </mat-option>
             </mat-select>
          </mat-form-field>
         </div> 

       

       </div>
</div>
</div>
 

      <div class="col-lg-12 buttons" >
        <button mat-raised-button color="primary" type="submit" class="mat-red"  [disabled]="disableFlag">{{SAVE_BUTTON}}</button>
        <button mat-raised-button color="primary" type="button" (click)="cancelClick();">{{CANCEL_BUTTON}}</button>
      </div>

    </form>
  </mat-card-content>
</mat-card>

