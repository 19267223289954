 <mat-card   style="height: 100%;">
      <form  [formGroup]="myForm" *ngIf="notesFlag==false"> 
      <mat-card-title   class="mat-card-title cardheadertitle"  >
        <mat-icon>alarm_on</mat-icon>Reminder
        <!-- <mat-slide-toggle class="example-margin" style="float:right;font-size: 12px" color="primary"  checked="false" (change)="notesFlagStatus($event)" >Notes</mat-slide-toggle>  -->
      </mat-card-title> 
      <mat-card-content style="margin-top: 2%;">
        <mat-divider></mat-divider>
          <div  >
            <div   style="margin-top: 2%;">

              <mat-form-field    >
                <input matInput [matDatepicker]="reminderDate" placeholder="{{ADD_REMINDER}}" dateFormatter  formControlName="reminderDate" [min]="minDateForReminderValidation">
                <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="reminderDate"></mat-datepicker-toggle>
                <mat-datepicker #reminderDate></mat-datepicker>   
              </mat-form-field>
            </div>
            <div   style="margin-top: 2%;">
              <mat-form-field  style="width: 100%" >

                <textarea matInput formControlName="notesDescription" placeholder="{{REMINDER_NOTES}}" maxlength="200" onfocus="this.select()"></textarea>
              </mat-form-field>
            </div>
            <div  align="center">
              <button mat-raised-button type="button" color="primary" class="mat-red" (click)="addReminderDetails()" >{{SAVE_BUTTON}}</button>
            </div>
          </div>
      </mat-card-content>
    </form>
    <!-- <form  [formGroup]="myNotesForm" *ngIf="notesFlag==true"> 
      <mat-card-title   class="mat-card-title cardheadertitle"  >
        <mat-icon>alarm_on</mat-icon>Notes<mat-slide-toggle class="example-margin" style="float:right" color="primary"  checked="true" (change)="notesFlagStatus($event)">Reminder</mat-slide-toggle></mat-card-title>
      <mat-card-content style="margin-top: 2%;">
        <mat-divider></mat-divider>
          <div class="col-lg-12">
            <div class="col-lg-12" style="margin-top: 2%;">
              <mat-form-field class="col-lg-12" >
                  <textarea matInput formControlName="userComments" placeholder="{{ADD_NOTES}}" maxlength="200" onfocus="this.select()"></textarea>
              </mat-form-field>
            </div>
            <div class="col-lg-12" align="center">
              <button mat-raised-button type="button" color="primary" class="mat-red" (click)="addNotesDetails()" >{{SAVE_BUTTON}}</button>
            </div>
          </div>
      </mat-card-content>
    </form>  -->
    </mat-card>