import { Component,OnInit, Input ,Inject,Output} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../core/components/custom-token';
import {ErpOverlayRef} from '../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent, group, query } from '@angular/animations';
/*import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';*/
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';
import {CommonService} from '../shared/common.service';
import {CommonUtils} from '../core/common-utils';
@Component({
  selector: 'file-preview-overlay',
  templateUrl: './erp-overlay.component.html',
   animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    
  ])
  ]
})
export class ErpOverlayComponent  implements OnInit{ 

UserLabelName;
displayedColumns;
column=[];
displayedColumnsOriginal
@Output() emitterObj:EventEmitter<any>=new EventEmitter<any>();
  constructor(
      public dialogRef: ErpOverlayRef,public commonService:CommonService,public commonUtils:CommonUtils,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
     
    this.displayedColumns=data.displayedColumns;
    this.displayedColumnsOriginal=data.displayedColumnsOriginal;
    this.UserLabelName = data.UserLabelName;
  }
  ngOnInit() 
   {
     
     
     
   }
selectedObj;
  clickedColumn(element,columnName){
    
   const index: number = this.displayedColumnsOriginal.indexOf(columnName);
     
     this.column = [];
     if(element.checked===true)
       this.column.push({columnName:columnName, flag: true, index:index})
     else
       this.column.push({columnName:columnName, flag: false,index:index})
    
     this.commonService.erpSubject.next(this.column);
   //  this.dialogRef.dialogClose(this.column);
   	
  }

    }
