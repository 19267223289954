import { Injectable }       from '@angular/core';
import {Subject} from 'rxjs';
import {Response} from '@angular/http';
import { ERPFormBase } from './base/erp-form-base';
import { TextboxForm } from './base/erp-form-text-box';
import { DropdownForm } from './base/erp-form-dropdown';
import { CheckboxForm } from './base/erp-form-checkbox';
import {CommonService} from '../../shared/common.service';

@Injectable()
export class CustomFieldService {
customControlListReadyStatus = new Subject();
t016_CustomControlMappingList: any[] = [];
customFieldElements: ERPFormBase<any>[] = [];

constructor( private commonService: CommonService) {}
  // Todo: get from a remote source of question metadata
  // Todo: make asynchronous
  
/*  fetchCustomFieldsControl(moduleName) {

this.commonService.fetchDynamicControls(moduleName).subscribe(
  (data) => {        
    this.customFieldElements = [];
     this.t016_CustomControlMappingList = data;
  for (let loop = 0; loop < this.t016_CustomControlMappingList.length; loop++) {
    this.formControls(this.t016_CustomControlMappingList[loop]);
 }

  this.customControlListReadyStatus.next('TRUE');
  },
  error=>{
    console.log(error);
    this.commonService.showERPErrorMessage(error);
    
  })
  }*/

  fetchCustomFieldsControl(moduleName):Promise<any> 
  {    
 return new Promise(resolve=>
   
   
   this.commonService.fetchDynamicControls(moduleName).subscribe(
  (data) => {     ;
        
    this.customFieldElements = [];
     this.t016_CustomControlMappingList = data;
  for (let loop = 0; loop < this.t016_CustomControlMappingList.length; loop++) {
    this.formControls(this.t016_CustomControlMappingList[loop]);
 }
 resolve(this.customFieldElements); 
 // this.customControlListReadyStatus.next('TRUE');
  },
  error=>{
    this.commonService.showERPErrorMessage(error);
    
  }));

  }

formControls(controltypeObj) {
if (this.getCurrentControlType(controltypeObj) != null) {
  this.customFieldElements.push(this.getCurrentControlType(controltypeObj));
}
//  this.customControlListReadyStatus.next('TRUE');
}

getCurrentControlType(controltypeObj): ERPFormBase<any> {
 if (controltypeObj.t015ControlTypeMaster === 'undefined') {
   alert('Custom Fields did not load in this page ');
 }
 const controlType: string = controltypeObj.t015ControlTypeMaster.type;
 const defaultValue: string = controltypeObj.defaultValue;
 const labelName: string = controltypeObj.labelName;
const formControlName: string = controltypeObj.customFormControlName;
  if (controlType === 'TEXTINPUT') {

   return new TextboxForm({
        key: formControlName,
        label: labelName,
        value: defaultValue,
        required: true,
        order: 1
      })
 } else if (controlType === 'DROPDOWN') {

   const dropdownOptions: string = controltypeObj.dropdownOptions;
 const dropdownStringArray: any[] = dropdownOptions.split(',');
const dropdownArray: any[] = []
 for (let loop = 0; loop < dropdownStringArray.length; loop++) {
dropdownArray.push({key: dropdownStringArray[loop], value: dropdownStringArray[loop]});
 }
   return new DropdownForm({
        key: formControlName,
        label: labelName,
        options: dropdownArray,
        order: 3
      })
 } else if (controlType === 'TEXTAREA') {
  return new TextboxForm({
        key: formControlName,
        label: labelName,
        value: defaultValue,
        required: true,
        order: 1
      })
 } else if (controlType === 'CHECKBOX') {

  return new CheckboxForm({
        key: formControlName,
        label: labelName,
          type: defaultValue,
        order: 4,
        value: false
      })
 } else { return null; }

  }


}
