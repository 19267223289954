import { ERPFormBase } from './erp-form-base';

export class CheckboxForm extends ERPFormBase<string> {
  controlType = 'checkbox';
  // type: boolean;

  constructor(options: {} = {}) {
    super(options);
   // this.type = options['type'] || '';
  }
}
