<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
<mat-card-title >CREATE MENU</mat-card-title>
<mat-card-content>
<form (ngSubmit)="saveOrUpdateSuperadminMenu()"   [formGroup]="myForm">
<div class="col-lg-12">
	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{MENU_NAME_PLACEHOLDER}}"   formControlName="menuName" >	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{MENU_ORDER_PLACEHOLDER}}"   formControlName="menuorder"  >	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{MENU_LEVEL_PLACEHOLDER}}"   formControlName="menuLevel"  >	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{ROUTER_URL_PLACEHOLDER}}"   formControlName="routerurl"  >	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{ADD_URL_PLACEHOLDER}}"   formControlName="addurl"  >	
		</mat-form-field>
	</div>


	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{MODULE_NAME_PLACEHOLDER}}"   formControlName="moduleName"  >	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{ICON_NAME_PLACEHOLDER}}"   formControlName="iconName"  >	
		</mat-form-field>
	</div>


	 <div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" formGroupName="parentMenuObj">
			<mat-select placeholder="{{SELECT_PARENT}}" formControlName="menuId">
			<mat-option *ngFor="let parentMenu of parentMenuList" [value]="parentMenu.menuId">
			{{parentMenu.menuName}}
			</mat-option>
			</mat-select>
			</mat-form-field>
		</div>

	<div class="col-lg-12">
		<div class="col-lg-10">
     		Status
     	</div>
		<div class="col-lg-2">
			<mat-slide-toggle color="primary" formControlName="status"></mat-slide-toggle>
		</div>
	</div>

	<div class="col-lg-12"></div>

	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons" style="padding-left: 91px;">
			<button mat-raised-button color="primary" class="mat-red" type="submit">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>



</div>
</form>
</mat-card-content>
</mat-card>