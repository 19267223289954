import {Component, OnInit, Inject} from '@angular/core';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CommonService} from '../../shared/common.service';



@Component({
  selector: 'app-create-validation',
  templateUrl: './create-validation.component.html' 
})
export class CreateValidationComponent extends CommonUtils implements OnInit {

 moduleName: string = environment.VIEW_VALIDATION;
 validationForm:FormGroup;
 validationFormFetch:FormGroup;
 validationObj;
 validationModuleList;
 parentValidationList=[];
 controlNameList=[];
 constructor(private router: Router,
    public dialogRef: MatDialogRef<CreateValidationComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,private commonService:CommonService,private commonUtils:CommonUtils) 
     { 
       super();

this.validationForm=new FormGroup({
 validationId:new FormControl(0),
 controlName:new FormControl(''),
 moduleName:new FormControl(''),
parent:new FormControl(''),
required:new FormControl(''),
nullCheck:new FormControl(''),
minLength:new FormControl(''),
minLengthValue:new FormControl(''),
maxLength:new FormControl(''),
maxLengthValue:new FormControl(''),
email:new FormControl(''),
level:new FormControl(''),
validationLicense:new FormControl(''),
validationModule:new FormControl(''),
parentObj: new FormGroup({parentId: new FormControl(0)})
       })
this.validationFormFetch=new FormGroup({
 validationId:new FormControl(0),
 controlName:new FormControl(''),
 moduleName:new FormControl(''),
parent:new FormControl(''),
required:new FormControl(''),
nullCheck:new FormControl(''),
minLength:new FormControl(''),
minLengthValue:new FormControl(''),
maxLength:new FormControl(''),
maxLengthValue:new FormControl(''),
email:new FormControl(''),
level:new FormControl(''),
validationLicense:new FormControl(''),
validationModule:new FormControl(''),
parentObj: new FormGroup({parentId: new FormControl(0)})
       })
 this.validationObj = data.validationObj;
   }

 ngOnInit()
  {  	
    this.pageLoad();
  }

  pageLoad()
{

	this.validationForm.get('validationLicense').setValue(this.commonService.validationLicense);
	this.validationForm.get('validationModule').setValue(this.commonService.validationModule);
  this.validationForm.get('moduleName').setValue(this.commonService.validationModule);
this.controlNameList=this.commonService.appScreenMap.get(this.commonService.validationModule).controlName.split("~");
if(this.commonUtils.checkNullOrUndefined(this.validationObj) && this.validationObj.validationId != 0 ){
  	 this.validationForm.patchValue(this.validationObj);
     this.validationForm.get('moduleName').setValue(this.validationObj.moduleName);     
     
	}  

this.loadValidationModule();
}

saveOrUpdateValidation()
{
  this.validationForm.get('validationLicense').setValue(this.commonService.validationLicense);
  this.commonService.showProgressBar(); 
  this.commonService.saveOrUpdateValidation(this.validationForm.value).subscribe(
    data=>
    {
      this.commonService.showERPMessage('Validation saved successfully');
      this.commonService.hideProgressBar();
      this.dialogRef.close(true);
    },
    error=>
    {
      this.commonService.hideProgressBar();
    })

}

 cancelClick(): void {
  this.dialogRef.close(true);
  this.commonService.hideProgressBar();
}

loadValidationModule():void{  
  
    this.commonService.fetchAllReviewDisplayColumn(this.validationFormFetch.value).subscribe(data => {
    this.validationModuleList = data;      
         
    });
  }

  bindValue(option){
    this.validationForm.get('moduleName').setValue(option.moduleName);
  
  }

}
