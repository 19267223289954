import { Component, Input, Inject, Output } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, FormArray } from '@angular/forms'
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import { ErpOverlayRef } from '../../core/components/ErpOverlayRef';
import { EventEmitter } from '@angular/core';
import { map, switchMap, debounceTime } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; import { CommonService } from '../../shared/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonUtils } from '../../core/common-utils';
import { environment } from '../../../environments/environment';
import { RecruitmentService } from 'src/app/recruitment/recruitment-service';
import { array } from '@amcharts/amcharts4/core';
@Component({
  selector: 'app-ctccalculation',
  templateUrl: './ctccalculation.component.html'

})
export class CtcCalculationComponent extends CommonUtils {

  inputObj;
  myForm: FormGroup;
  salaryType;
  amount;
  zeroValue = "0";
  basicAmount: number = 0;
  yearlybasicAmount: number = 0;
  hraAmount: number = 0;
  yearlyhraAmount: number = 0;
  convAllow: number = 0;
  yearlyconvAllow: number = 0;
  specAllow: number = 0;
  yearlyspecAllow: number = 0;
  pfEmployee: number = 0;
  yearlypfEmployee: number = 0;
  pfEmployer: number = 0;
  yearlypfEmployer: number = 0;
  esiEmployee: number = 0;
  yearlyesiEmployee: number = 0;
  esiEmployer: number = 0;
  yearlyesiEmployer: number = 0;
  takehome: number = 0;
  yearlytakehome: number = 0;
  grosspay: number = 0;
  yearlygrosspay: number = 0;
  ctc: number = 0;
  yearlyctc: number = 0;
  taxEmployer: number = 0;
  yearlytaxEmployer: number = 0;
  basicAmountString;
  yearlybasicAmountString;
  hraAmountString;
  yearlyhraAmountString;
  convAllowString;
  yearlyconvAllowString;
  specAllowString;
  yearlyspecAllowString;
  pfEmployeeString;
  yearlypfEmployeeString;
  pfEmployerString;
  yearlypfEmployerString;
  esiEmployeeString;
  yearlyesiEmployeeString;
  esiEmployerString;
  yearlyesiEmployerString;

  taxEmployerString: any;
  yearlytaxEmployerString: any;
  takehomeString;
  yearlytakehomeString;
  grosspayString;
  yearlygrosspayString;
  ctcString;
  yearlyctcString;
  totalTaxAmt: any = 0;
  taxAmt: any = 0;
  showFlag: boolean = false;
  taxList: any = []
  constructor(private router: Router, public dialog: MatDialog, private recruitService: RecruitmentService,
    private commonService: CommonService, public commonUtils: CommonUtils, private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CtcCalculationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.inputObj = data.candidateExpectedSalaryList;
    this.myForm = new FormGroup({
      expectedSalaryId: new FormControl(0),
      amount: new FormControl(0),
      salaryType: new FormControl(),
      basicAmount: new FormControl(0),
      hraAmount: new FormControl(0),
      convAllow: new FormControl(0),
      specAllow: new FormControl(0),
      pfEmployee: new FormControl(0),
      pfEmployer: new FormControl(0),
      esiEmployee: new FormControl(0),
      esiEmployer: new FormControl(0),
      takehome: new FormControl(0),
      grosspay: new FormControl(0),
      ctcAmount: new FormControl(0),
      yearlyctcAmount: new FormControl(0),
      calculationflag: new FormControl(false)
    });

  }
  ngOnInit() {
    this.pageload();
    this.fetchTaxSetting();
  }
  deductTax(event) {
    // console.log("deducttax", event);
    // console.log("deducttax", this.taxList);
    let taxPercent = 0;
    this.totalTaxAmt = 0;
    this.taxAmt = 0;
    var totAmt = event;
    var taxData = this.taxList;
    // console.log("taxData", taxData);
    let amnt = null;
    var taxPushlist = [];
    for (var loop = 0; loop < taxData.length; loop++) {
      var payTaxpush = taxData[loop];
      // console.log("event", event, "payTaxpush.startingSalary", payTaxpush.startingSalary, "payTaxpush.endingSalary", payTaxpush.endingSalary)
      if (payTaxpush.exactValue <= event) {
        // console.log("if", payTaxpush);
        taxPushlist.push(payTaxpush);
      }
    }
    // console.log("taxPushlist", taxPushlist);

    for (var loop = 0; loop < taxPushlist.length; loop++) {
      var payTax = taxPushlist[loop];
      if (event < payTax.exactValue && amnt == null) {
        this.taxAmt = + totAmt * (payTax.taxRate / 100);
        // console.log("this.taxAmt4", this.taxAmt, "this.taxAmt", amnt);
      }
      else if (amnt == null) {
        amnt = totAmt - payTax.exactValue;
        this.taxAmt = + payTax.exactValue * (payTax.taxRate / 100);
        // console.log("this.taxAmt3", this.taxAmt, "this.taxAmt", amnt);
      }
      else if (amnt > 0.00) {
        if (payTax.endingSalary == 0) {
          this.taxAmt = + amnt * (payTax.taxRate / 100);
          this.totalTaxAmt = this.checkIFNull(this.totalTaxAmt) + this.taxAmt;
          // console.log("this.totalTaxAmt", this.totalTaxAmt, "this.taxAmt", this.taxAmt);
        }
        else if (amnt < payTax.exactValue) {
          // console.log("this.taxAmt2", this.taxAmt, "payTax.exactValue2", payTax.exactValue);
          this.taxAmt = + amnt * (payTax.taxRate / 100);
          // console.log("this.taxAmt2", this.taxAmt, "payTax.exactValue2", payTax.exactValue);
        }
        else {
          // console.log("this.taxAmt1", this.taxAmt, "payTax.exactValue1", payTax.exactValue);
          this.taxAmt = + payTax.exactValue * (payTax.taxRate / 100);
          // console.log("this.taxAmt1", this.taxAmt, "payTax.exactValue1", payTax.exactValue);
        }
        amnt = amnt - payTax.exactValue;
        // console.log("amnt1", amnt, "payTax.exactValue", payTax.exactValue);
      }
      this.totalTaxAmt = this.checkIFNull(this.totalTaxAmt) + this.checkIFNull(this.taxAmt);

      // console.log("this.totalTaxAmt1", this.totalTaxAmt, "this.taxAmt1", this.taxAmt);
      // console.log("payTax.startingSalary",payTax.startingSalary,"payTax.endingSalary",payTax.endingSalary);
      // if(event>payTax.startingSalary && (event<=payTax.endingSalary || payTax.endingSalary==0)){
      //   console.log('payTax.taxRate',payTax.taxRate);
      //   taxPercent=payTax.taxRate;
      // } 

    }
    return this.totalTaxAmt;
  }
  checkIFNull(event) {
    // console.log('checkIFNull', event);
    var returnValue = 0;
    if (event == '' || event == 'null' || event == null || event == 'undefined' || event == undefined) {
      // console.log('checkIFNull if', event);
      returnValue = 0;
    }
    else {
      // console.log('checkIFNull else', event);
      returnValue = event;
    }
    // console.log('checkIFNull else', returnValue);
    return returnValue;
  }
  fetchTaxSetting() {
    let taxSetting = new FormGroup({
      payrollSettingsObj: new FormGroup({
        t000OrganizationObj: new FormGroup({
          organizationId: new FormControl(this.commonService.loggedInUserObj.t000Organization.organizationId)
        })
      })
    })
    this.recruitService.fetchTaxSettingForSalaryCalculation(taxSetting.value).subscribe(
      data => {
        this.taxList = data;
        // console.log('taxSetting', data);
      }, error => {
      });
  }
  pageload() {
    if (this.commonUtils.checkNullOrUndefined(this.inputObj[0]) && this.inputObj[0].expectedSalaryId != 0) {
      this.myForm.get('expectedSalaryId').setValue(this.inputObj[0].expectedSalaryId);
      if (this.inputObj[0].type === 'TakeHome') {
        this.salaryType = this.inputObj[0].type;
        this.myForm.get('salaryType').setValue(this.inputObj[0].type);
        this.myForm.get('amount').setValue(this.inputObj[0].takehome);
        this.onCalculatingSalary();

      } else if (this.inputObj[0].type === 'GrossSalary') {
        this.salaryType = this.inputObj[0].type;
        this.myForm.get('salaryType').setValue(this.inputObj[0].type);
        this.myForm.get('amount').setValue(this.inputObj[0].grosspay);
        this.onCalculatingSalary();

      } else if (this.inputObj[0].type === 'MonthlyCTC') {
        this.salaryType = this.inputObj[0].type;
        this.myForm.get('salaryType').setValue(this.inputObj[0].type);
        this.myForm.get('amount').setValue(this.inputObj[0].ctcAmount);
        this.onCalculatingSalary();

      }
      else if (this.inputObj[0].type === 'YearlyCTC') {
        this.salaryType = this.inputObj[0].type;
        this.myForm.get('salaryType').setValue(this.inputObj[0].type);
        this.myForm.get('amount').setValue(this.inputObj[0].ctcAmount);
        this.onCalculatingSalary();
      }
    }

  }
  onChangeSalaryType(salaryType) {
    this.salaryType = salaryType;
    this.myForm.get('salaryType').setValue(salaryType);
    this.myForm.get('salaryType').setValidators(null);
    this.myForm.get('salaryType').markAsTouched();
    this.myForm.get('salaryType').updateValueAndValidity();
  }

  onCalculatingSalary() {
    if (this.commonUtils.checkNullOrUndefined(this.myForm.get('amount').value) && this.commonUtils.checkNullOrUndefined(this.myForm.get('salaryType').value)) {
      this.showFlag = true;
      this.myForm.get('amount').setValidators(null);
      this.myForm.get('amount').markAsTouched();
      this.myForm.get('amount').updateValueAndValidity();
      if (this.salaryType === 'GrossSalary') {
        this.grosspay = parseFloat(this.myForm.get('amount').value);
        this.basicAmount = this.grosspay * 0.6;
        this.hraAmount = this.basicAmount * 0.35;
        this.convAllow = this.basicAmount * 0.1175;
        this.specAllow = this.grosspay - (this.basicAmount + this.hraAmount + this.convAllow);
        if (this.basicAmount > 15000) {
          this.pfEmployer = 1800;
          this.pfEmployee = 1800;
        } else {
          this.pfEmployer = this.basicAmount * 0.12;
          this.pfEmployee = this.basicAmount * 0.12;
        }
        if (this.grosspay < 21000) {
          this.esiEmployee = this.grosspay * 0.0075;
        } else {
          this.esiEmployee = 0;
        }
        if (this.grosspay < 21000) {
          this.esiEmployer = this.grosspay * 0.0325;
        } else {
          this.esiEmployer = 0;
        }
        this.takehome = (this.basicAmount + this.hraAmount + this.convAllow + this.specAllow) - (this.pfEmployee + this.esiEmployee);
        this.ctc = this.grosspay + this.esiEmployer + this.pfEmployer;
        this.yearlyctc = this.ctc * 12;


        this.yearlybasicAmount = this.basicAmount * 12;
        this.yearlyhraAmount = this.hraAmount * 12;
        this.yearlyconvAllow = this.convAllow * 12;
        this.yearlyspecAllow = this.specAllow * 12;
        this.yearlypfEmployee = this.pfEmployee * 12;
        this.yearlypfEmployer = this.pfEmployer * 12;
        this.yearlyesiEmployee = this.esiEmployee * 12;
        this.yearlyesiEmployer = this.esiEmployer * 12;
        this.yearlytakehome = this.takehome * 12;
        this.yearlygrosspay = this.grosspay * 12;
        //Tax Calculation
        var deducttax = this.deductTax(this.yearlyctc);
        this.yearlytaxEmployer = deducttax;
        this.taxEmployer = this.yearlytaxEmployer / 12;
        this.taxEmployerString = Math.round(this.taxEmployer).toFixed(2);
        this.yearlytaxEmployerString = Math.round(this.yearlytaxEmployer).toFixed(2);

        this.basicAmountString = Math.round(this.basicAmount).toFixed(2);
        this.hraAmountString = Math.round(this.hraAmount).toFixed(2);
        this.convAllowString = Math.round(this.convAllow).toFixed(2);
        this.specAllowString = Math.round(this.specAllow).toFixed(2);
        this.pfEmployeeString = Math.round(this.pfEmployee).toFixed(2);
        this.pfEmployerString = Math.round(this.pfEmployer).toFixed(2);
        this.esiEmployeeString = Math.round(this.esiEmployee).toFixed(2);
        this.esiEmployerString = Math.round(this.esiEmployer).toFixed(2);
        this.takehomeString = Math.round(this.takehome - this.taxEmployer).toFixed(2);
        this.grosspayString = Math.round(this.grosspay).toFixed(2);
        this.ctcString = Math.round(this.ctc).toFixed(2);
        this.yearlyctcString = Math.round(this.yearlyctc).toFixed(2);


        this.yearlybasicAmountString = Math.round(this.yearlybasicAmount).toFixed(2);
        this.yearlyhraAmountString = Math.round(this.yearlyhraAmount).toFixed(2);
        this.yearlyconvAllowString = Math.round(this.yearlyconvAllow).toFixed(2);
        this.yearlyspecAllowString = Math.round(this.yearlyspecAllow).toFixed(2);
        this.yearlypfEmployeeString = Math.round(this.yearlypfEmployee).toFixed(2);
        this.yearlypfEmployerString = Math.round(this.yearlypfEmployer).toFixed(2);
        this.yearlyesiEmployeeString = Math.round(this.yearlyesiEmployee).toFixed(2);
        this.yearlyesiEmployerString = Math.round(this.yearlyesiEmployer).toFixed(2);
        this.yearlytakehomeString = Math.round(this.yearlytakehome - this.yearlytaxEmployer).toFixed(2);
        this.yearlygrosspayString = Math.round(this.yearlygrosspay).toFixed(2);

        this.myForm.get('basicAmount').setValue(this.basicAmountString);
        this.myForm.get('hraAmount').setValue(this.hraAmountString);
        this.myForm.get('convAllow').setValue(this.convAllowString);
        this.myForm.get('specAllow').setValue(this.specAllowString);
        this.myForm.get('pfEmployee').setValue(this.pfEmployeeString);
        this.myForm.get('pfEmployer').setValue(this.pfEmployerString);
        this.myForm.get('esiEmployee').setValue(this.esiEmployeeString);
        this.myForm.get('esiEmployer').setValue(this.esiEmployerString);
        this.myForm.get('takehome').setValue(this.takehomeString);
        this.myForm.get('grosspay').setValue(this.grosspayString);
        this.myForm.get('ctcAmount').setValue(this.ctcString);
        this.myForm.get('yearlyctcAmount').setValue(this.yearlyctcString);
      }
      else if (this.salaryType === 'MonthlyCTC') {
        this.ctc = this.myForm.get('amount').value;
        if (this.ctc > 26800) {
          if (this.ctc < 22512) {
            this.grosspay = this.ctc - 1800 / 1.0325;
          } else {
            this.grosspay = this.ctc - 1800;
          }
        } else {
          if (this.ctc < 22512) {
            this.grosspay = this.ctc / 1.1045;
          } else {
            this.grosspay = this.ctc / 1.072;
          }
        }
        this.basicAmount = this.grosspay * 0.6;
        this.hraAmount = this.basicAmount * 0.35;
        this.convAllow = this.basicAmount * 0.1175;
        this.specAllow = this.grosspay - (this.basicAmount + this.hraAmount + this.convAllow);
        if (this.basicAmount > 15000) {
          this.pfEmployer = 1800;
          this.pfEmployee = 1800;
        } else {
          this.pfEmployer = this.basicAmount * 0.12;
          this.pfEmployee = this.basicAmount * 0.12;
        }
        if (this.grosspay < 21000) {
          this.esiEmployee = this.grosspay * 0.0075;
        } else {
          this.esiEmployee = 0;
        }
        if (this.grosspay < 21000) {
          this.esiEmployer = this.grosspay * 0.0325;
        } else {
          this.esiEmployer = 0;
        }
        this.takehome = (this.basicAmount + this.hraAmount + this.convAllow + this.specAllow) - (this.pfEmployee + this.esiEmployee);
        this.yearlyctc = this.ctc * 12;

        this.yearlybasicAmount = this.basicAmount * 12;
        this.yearlyhraAmount = this.hraAmount * 12;
        this.yearlyconvAllow = this.convAllow * 12;
        this.yearlyspecAllow = this.specAllow * 12;
        this.yearlypfEmployee = this.pfEmployee * 12;
        this.yearlypfEmployer = this.pfEmployer * 12;
        this.yearlyesiEmployee = this.esiEmployee * 12;
        this.yearlyesiEmployer = this.esiEmployer * 12;
        this.yearlytakehome = this.takehome * 12;
        this.yearlygrosspay = this.grosspay * 12;
        //Tax Calculation
        var deducttax = this.deductTax(this.yearlyctc);
        this.yearlytaxEmployer = deducttax;
        this.taxEmployer = this.yearlytaxEmployer / 12;
        this.taxEmployerString = Math.round(this.taxEmployer).toFixed(2);
        this.yearlytaxEmployerString = Math.round(this.yearlytaxEmployer).toFixed(2);


        this.basicAmountString = Math.round(this.basicAmount).toFixed(2);
        this.hraAmountString = Math.round(this.hraAmount).toFixed(2);
        this.convAllowString = Math.round(this.convAllow).toFixed(2);
        this.specAllowString = Math.round(this.specAllow).toFixed(2);
        this.pfEmployeeString = Math.round(this.pfEmployee).toFixed(2);
        this.pfEmployerString = Math.round(this.pfEmployer).toFixed(2);
        this.esiEmployeeString = Math.round(this.esiEmployee).toFixed(2);
        this.esiEmployerString = Math.round(this.esiEmployer).toFixed(2);
        this.takehomeString = Math.round(this.takehome - this.taxEmployer).toFixed(2);
        this.grosspayString = Math.round(this.grosspay).toFixed(2);
        this.ctcString = Math.round(this.ctc).toFixed(2);
        this.yearlyctcString = Math.round(this.yearlyctc).toFixed(2);


        this.yearlybasicAmountString = Math.round(this.yearlybasicAmount).toFixed(2);
        this.yearlyhraAmountString = Math.round(this.yearlyhraAmount).toFixed(2);
        this.yearlyconvAllowString = Math.round(this.yearlyconvAllow).toFixed(2);
        this.yearlyspecAllowString = Math.round(this.yearlyspecAllow).toFixed(2);
        this.yearlypfEmployeeString = Math.round(this.yearlypfEmployee).toFixed(2);
        this.yearlypfEmployerString = Math.round(this.yearlypfEmployer).toFixed(2);
        this.yearlyesiEmployeeString = Math.round(this.yearlyesiEmployee).toFixed(2);
        this.yearlyesiEmployerString = Math.round(this.yearlyesiEmployer).toFixed(2);
        this.yearlytakehomeString = Math.round(this.yearlytakehome - this.yearlytaxEmployer).toFixed(2);
        this.yearlygrosspayString = Math.round(this.yearlygrosspay).toFixed(2);

        this.myForm.get('basicAmount').setValue(this.basicAmountString);
        this.myForm.get('hraAmount').setValue(this.hraAmountString);
        this.myForm.get('convAllow').setValue(this.convAllowString);
        this.myForm.get('specAllow').setValue(this.specAllowString);
        this.myForm.get('pfEmployee').setValue(this.pfEmployeeString);
        this.myForm.get('pfEmployer').setValue(this.pfEmployerString);
        this.myForm.get('esiEmployee').setValue(this.esiEmployeeString);
        this.myForm.get('esiEmployer').setValue(this.esiEmployerString);
        this.myForm.get('takehome').setValue(this.takehomeString);
        this.myForm.get('grosspay').setValue(this.grosspayString);
        this.myForm.get('ctcAmount').setValue(this.ctcString);
        this.myForm.get('yearlyctcAmount').setValue(this.yearlyctcString);
      }
      else if (this.salaryType === 'YearlyCTC') {
        this.yearlyctc = this.myForm.get('amount').value;
        this.ctc = this.yearlyctc / 12;
        if (this.ctc > 26800) {
          if (this.ctc < 22512) {
            this.grosspay = this.ctc - 1800 / 1.0325;
          } else {
            this.grosspay = this.ctc - 1800;
          }
        } else {
          if (this.ctc < 22512) {
            this.grosspay = this.ctc / 1.1045;
          } else {
            this.grosspay = this.ctc / 1.072;
          }
        }
        this.basicAmount = this.grosspay * 0.6;
        this.hraAmount = this.basicAmount * 0.35;
        this.convAllow = this.basicAmount * 0.1175;
        this.specAllow = this.grosspay - (this.basicAmount + this.hraAmount + this.convAllow);
        if (this.basicAmount > 15000) {
          this.pfEmployer = 1800;
          this.pfEmployee = 1800;
        } else {
          this.pfEmployer = this.basicAmount * 0.12;
          this.pfEmployee = this.basicAmount * 0.12;
        }
        if (this.grosspay < 21000) {
          this.esiEmployee = this.grosspay * 0.0075;
        } else {
          this.esiEmployee = 0;
        }
        if (this.grosspay < 21000) {
          this.esiEmployer = this.grosspay * 0.0325;
        } else {
          this.esiEmployer = 0;
        }
        this.takehome = (this.basicAmount + this.hraAmount + this.convAllow + this.specAllow) - (this.pfEmployee + this.esiEmployee);

        this.yearlybasicAmount = this.basicAmount * 12;
        this.yearlyhraAmount = this.hraAmount * 12;
        this.yearlyconvAllow = this.convAllow * 12;
        this.yearlyspecAllow = this.specAllow * 12;
        this.yearlypfEmployee = this.pfEmployee * 12;
        this.yearlypfEmployer = this.pfEmployer * 12;
        this.yearlyesiEmployee = this.esiEmployee * 12;
        this.yearlyesiEmployer = this.esiEmployer * 12;
        this.yearlytakehome = this.takehome * 12;
        this.yearlygrosspay = this.grosspay * 12;
        //Tax Calculation
        var deducttax = this.deductTax(this.yearlyctc);
        this.yearlytaxEmployer = deducttax;
        this.taxEmployer = this.yearlytaxEmployer / 12;
        this.taxEmployerString = Math.round(this.taxEmployer).toFixed(2);
        this.yearlytaxEmployerString = Math.round(this.yearlytaxEmployer).toFixed(2);

        this.basicAmountString = Math.round(this.basicAmount).toFixed(2);
        this.hraAmountString = Math.round(this.hraAmount).toFixed(2);
        this.convAllowString = Math.round(this.convAllow).toFixed(2);
        this.specAllowString = Math.round(this.specAllow).toFixed(2);
        this.pfEmployeeString = Math.round(this.pfEmployee).toFixed(2);
        this.pfEmployerString = Math.round(this.pfEmployer).toFixed(2);
        this.esiEmployeeString = Math.round(this.esiEmployee).toFixed(2);
        this.esiEmployerString = Math.round(this.esiEmployer).toFixed(2);
        this.takehomeString = Math.round(this.takehome - this.taxEmployer).toFixed(2);
        this.grosspayString = Math.round(this.grosspay).toFixed(2);
        this.ctcString = Math.round(this.ctc).toFixed(2);
        this.yearlyctcString = Math.round(this.yearlyctc).toFixed(2);


        this.yearlybasicAmountString = Math.round(this.yearlybasicAmount).toFixed(2);
        this.yearlyhraAmountString = Math.round(this.yearlyhraAmount).toFixed(2);
        this.yearlyconvAllowString = Math.round(this.yearlyconvAllow).toFixed(2);
        this.yearlyspecAllowString = Math.round(this.yearlyspecAllow).toFixed(2);
        this.yearlypfEmployeeString = Math.round(this.yearlypfEmployee).toFixed(2);
        this.yearlypfEmployerString = Math.round(this.yearlypfEmployer).toFixed(2);
        this.yearlyesiEmployeeString = Math.round(this.yearlyesiEmployee).toFixed(2);
        this.yearlyesiEmployerString = Math.round(this.yearlyesiEmployer).toFixed(2);
        this.yearlytakehomeString = Math.round(this.yearlytakehome - this.yearlytaxEmployer).toFixed(2);
        this.yearlygrosspayString = Math.round(this.yearlygrosspay).toFixed(2);

        this.myForm.get('basicAmount').setValue(this.basicAmountString);
        this.myForm.get('hraAmount').setValue(this.hraAmountString);
        this.myForm.get('convAllow').setValue(this.convAllowString);
        this.myForm.get('specAllow').setValue(this.specAllowString);
        this.myForm.get('pfEmployee').setValue(this.pfEmployeeString);
        this.myForm.get('pfEmployer').setValue(this.pfEmployerString);
        this.myForm.get('esiEmployee').setValue(this.esiEmployeeString);
        this.myForm.get('esiEmployer').setValue(this.esiEmployerString);
        this.myForm.get('takehome').setValue(this.takehomeString);
        this.myForm.get('grosspay').setValue(this.grosspayString);
        this.myForm.get('ctcAmount').setValue(this.ctcString);
        this.myForm.get('yearlyctcAmount').setValue(this.yearlyctcString);


      }
      else if (this.salaryType === 'TakeHome') {
        this.takehome = parseFloat(this.myForm.get('amount').value);
        if (this.takehome < 19488) {
          if (this.takehome > 23200) {
            this.grosspay = this.takehome + 1800 / 0.9925;
          } else {
            this.grosspay = this.takehome / 0.9205;
          }

        } else {
          if (this.takehome > 23200) {
            this.grosspay = this.takehome + parseFloat("1800");
          } else {
            this.grosspay = this.takehome / 0.928;
          }
        }
        this.basicAmount = this.grosspay * 0.6;
        this.hraAmount = this.basicAmount * 0.35;
        this.convAllow = this.basicAmount * 0.1175;
        this.specAllow = this.grosspay - (this.basicAmount + this.hraAmount + this.convAllow);
        if (this.basicAmount > 15000) {
          this.pfEmployer = 1800;
          this.pfEmployee = 1800;
        } else {
          this.pfEmployer = this.basicAmount * 0.12;
          this.pfEmployee = this.basicAmount * 0.12;
        }
        if (this.grosspay < 21000) {
          this.esiEmployee = this.grosspay * 0.0075;
        } else {
          this.esiEmployee = 0;
        }
        if (this.grosspay < 21000) {
          this.esiEmployer = this.grosspay * 0.0325;
        } else {
          this.esiEmployer = 0;
        }
        this.ctc = this.grosspay + this.esiEmployer + this.pfEmployer;
        this.yearlyctc = this.ctc * 12;

        this.yearlybasicAmount = this.basicAmount * 12;
        this.yearlyhraAmount = this.hraAmount * 12;
        this.yearlyconvAllow = this.convAllow * 12;
        this.yearlyspecAllow = this.specAllow * 12;
        this.yearlypfEmployee = this.pfEmployee * 12;
        this.yearlypfEmployer = this.pfEmployer * 12;
        this.yearlyesiEmployee = this.esiEmployee * 12;
        this.yearlyesiEmployer = this.esiEmployer * 12;
        this.yearlytakehome = this.takehome * 12;
        this.yearlygrosspay = this.grosspay * 12;
        //Tax Calculation
        var deducttax = this.deductTax(this.yearlyctc);
        this.yearlytaxEmployer = deducttax;
        this.taxEmployer = this.yearlytaxEmployer / 12;
        this.taxEmployerString = Math.round(this.taxEmployer).toFixed(2);
        this.yearlytaxEmployerString = Math.round(this.yearlytaxEmployer).toFixed(2);

        this.basicAmountString = Math.round(this.basicAmount).toFixed(2);
        this.hraAmountString = Math.round(this.hraAmount).toFixed(2);
        this.convAllowString = Math.round(this.convAllow).toFixed(2);
        this.specAllowString = Math.round(this.specAllow).toFixed(2);
        this.pfEmployeeString = Math.round(this.pfEmployee).toFixed(2);
        this.pfEmployerString = Math.round(this.pfEmployer).toFixed(2);
        this.esiEmployeeString = Math.round(this.esiEmployee).toFixed(2);
        this.esiEmployerString = Math.round(this.esiEmployer).toFixed(2);
        this.takehomeString = Math.round(this.takehome - this.taxEmployer).toFixed(2);
        this.grosspayString = Math.round(this.grosspay).toFixed(2);
        this.ctcString = Math.round(this.ctc).toFixed(2);
        this.yearlyctcString = Math.round(this.yearlyctc).toFixed(2);


        this.yearlybasicAmountString = Math.round(this.yearlybasicAmount).toFixed(2);
        this.yearlyhraAmountString = Math.round(this.yearlyhraAmount).toFixed(2);
        this.yearlyconvAllowString = Math.round(this.yearlyconvAllow).toFixed(2);
        this.yearlyspecAllowString = Math.round(this.yearlyspecAllow).toFixed(2);
        this.yearlypfEmployeeString = Math.round(this.yearlypfEmployee).toFixed(2);
        this.yearlypfEmployerString = Math.round(this.yearlypfEmployer).toFixed(2);
        this.yearlyesiEmployeeString = Math.round(this.yearlyesiEmployee).toFixed(2);
        this.yearlyesiEmployerString = Math.round(this.yearlyesiEmployer).toFixed(2);
        this.yearlytakehomeString = Math.round(this.yearlytakehome - this.yearlytaxEmployer).toFixed(2);
        this.yearlygrosspayString = Math.round(this.yearlygrosspay).toFixed(2);

        this.myForm.get('basicAmount').setValue(this.basicAmountString);
        this.myForm.get('hraAmount').setValue(this.hraAmountString);
        this.myForm.get('convAllow').setValue(this.convAllowString);
        this.myForm.get('specAllow').setValue(this.specAllowString);
        this.myForm.get('pfEmployee').setValue(this.pfEmployeeString);
        this.myForm.get('pfEmployer').setValue(this.pfEmployerString);
        this.myForm.get('esiEmployee').setValue(this.esiEmployeeString);
        this.myForm.get('esiEmployer').setValue(this.esiEmployerString);
        this.myForm.get('takehome').setValue(this.takehomeString);
        this.myForm.get('grosspay').setValue(this.grosspayString);
        this.myForm.get('ctcAmount').setValue(this.ctcString);
        this.myForm.get('yearlyctcAmount').setValue(this.yearlyctcString);

      }
    } else {
      this.commonService.showERPMessage("Please enter salary type and amount for calculate");
      this.showFlag = false;
      if (!this.commonUtils.checkNullOrUndefined(this.myForm.get('salaryType').value)) {
        this.myForm.get('salaryType').setValidators(Validators.required);
        this.myForm.get('salaryType').markAsTouched();
        this.myForm.get('salaryType').updateValueAndValidity();
      }
      if (!this.commonUtils.checkNullOrUndefined(this.myForm.get('amount').value)) {
        this.myForm.get('amount').setValidators(Validators.required);
        this.myForm.get('amount').markAsTouched();
        this.myForm.get('amount').updateValueAndValidity();
      }
    }


  }
  saveSalaryBreakDowncalculation() {
    this.myForm.get('calculationflag').setValue(true);
    this.dialogRef.close(this.myForm.value);
  }
  cancelClick() {
    this.myForm.get('calculationflag').setValue(false);
    this.dialogRef.close(this.myForm.value);
  }
}
