import { Component, OnInit,Inject } from '@angular/core';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {HrmService} from '../../hrm/hrm-service';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CommonService} from '../../shared/common.service';
import {Router} from '@angular/router';
import {FileUploadComponent} from'../../dialogBox/fileupload/file-upload.component';

@Component({
  selector: 'add-appraisalcomments',
  templateUrl: './add-appraisal-comments.component.html'

})
export class AddAppraisalComments extends CommonUtils implements OnInit{
		appraisalCommentsForm: FormGroup;
		subAppraisalName: string;
		subAppraisalId;
		appraisalId;
		selfAppraiserDisableFlag: boolean = true;
		appraiser1DisableFlag: boolean = true;
		appraiser2DisableFlag: boolean = true;
		appraiser3DisableFlag: boolean = true;
		appraiser4DisableFlag: boolean = true;
		appraiser5DisableFlag: boolean = true;
		activeAppraiserTabIndex = 0;
		uploadedFileArrayparent:Array<any>=[];
		accessFlag:boolean=true;
		deleteflag:boolean=false;
		userName;

		constructor(public dialogRef: MatDialogRef<AddAppraisalComments>,private router: Router,private commonService: CommonService, private commonUtils: CommonUtils,
  				@Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder, private hrmService: HrmService,public dialog: MatDialog) {
 						super();
			this.appraisalCommentsForm = this.getAppraiserForm();
  			if(this.commonUtils.checkNullOrUndefined(data) && this.commonUtils.checkNullOrUndefined(data.appraisalIndexObj)){
  					this.appraisalCommentsForm.patchValue(data.appraisalIndexObj);
  					this.subAppraisalName = data.subAppraisalName;
  					this.subAppraisalId=data.subAppraisalId;
  					this.appraisalId=data.appraisalId;
  					this.userName=data.userName;
  					this.bindAllAppraiserComments(data);
  			}
  			
		}
  
		ngOnInit()
		  {
		//    this.pageLoad();
		  }

		  getAppraiserForm():FormGroup{ 
	 		 return new FormGroup({
	 		 	appraisalFormId: new FormControl(0),
	 		 	t001_UserObj: new FormGroup({userId: new FormControl(0), userName: new FormControl('')}),
	 		 	t828_AppraisalObj:  new FormGroup({appraisalId: new FormControl(0)}),
	 		 	t829_SubAppraisalObj:  new FormGroup({subAppraisalId: new FormControl(0),subAppraisalName: new FormControl('')}),
	 		 	selfAppraiserObj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	selfAppraiselPoint: new FormControl(''),
	 		 	selfAppraiserComments: new FormControl(''),
	 		 	appraiser1Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	appraiser1Point: new FormControl(''),
	 		 	appraiser1Comments: new FormControl(''),
	 		 	appraiser2Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	appraiser2Point: new FormControl(''),
	 		 	appraiser2Comments: new FormControl(''),
	 		 	appraiser3Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	appraiser3Point: new FormControl(''),
	 		 	appraiser3Comments: new FormControl(''),
	 		 	appraiser4Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	appraiser4Point: new FormControl(''),
	 		 	appraiser4Comments: new FormControl(''),
	 		 	appraiser5Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
	 		 										firstName: new FormControl(''),lastName: new FormControl('')}),
	 		 	appraiser5Point: new FormControl(''),
	 		 	appraiser5Comments: new FormControl(''),
	 		 	t001_UserCreatedByObj:  new FormGroup({userId: new FormControl(0)}),
	 		 	t001_UserUpdatedByObj:  new FormGroup({userId: new FormControl(0)}),
	 		 	fileUpload : new FormControl(''),
	 		 	uploadedFileArray:this.formBuilder.array([]),
	 		 
	  		});
		  }

	formatLabel(value: number | null) {
	    if (!value) {
	      return 0;
	    }
	    return value;
 	 }
 	 bindAllAppraiserComments(data): void
 	 {
 	 	let currentUserObj = this.commonService.loggedInUserObj;
 	 	let selfAppraiserObj: FormGroup = this.appraisalCommentsForm.get('selfAppraiserObj') as FormGroup;
 	 	let appraiser1Obj: FormGroup = this.appraisalCommentsForm.get('appraiser1Obj') as FormGroup;
 	 	let appraiser2Obj: FormGroup = this.appraisalCommentsForm.get('appraiser2Obj') as FormGroup;
 	 	let appraiser3Obj: FormGroup = this.appraisalCommentsForm.get('appraiser3Obj') as FormGroup;
 	 	let appraiser4Obj: FormGroup = this.appraisalCommentsForm.get('appraiser4Obj') as FormGroup;
		let appraiser5Obj: FormGroup = this.appraisalCommentsForm.get('appraiser5Obj') as FormGroup;

		if(this.commonUtils.checkNullOrUndefined(data.appraisalFormObj)){
	 	 		if(this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.employeeLevelReporting)){
	 	 			let employeeLevelReporting: string = data.appraisalFormObj.employeeLevelReporting;
			 	 	let appraiser = employeeLevelReporting.split(",");
			 	 	for(var loopcount = 0 ; loopcount<appraiser.length; loopcount++){
			 	 		// set values to self appraiser object in app comments form
			 	 		if(loopcount === 0){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 			
			 	 			if(appraiserSplit[0] != "")
			 	 					selfAppraiserObj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					selfAppraiserObj.get('firstName').setValue(appraiserName[0]);
			 	 					selfAppraiserObj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			selfAppraiserObj.get('userName').setValue(appraiserSplit[2]);
			 	 		}
			 	 		// set values to  appraiser 1 object in app comments form
			 	 		if(loopcount === 1){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 		
			 	 			if(appraiserSplit[0] != "")
			 	 					appraiser1Obj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					appraiser1Obj.get('firstName').setValue(appraiserName[0]);
			 	 					appraiser1Obj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			appraiser1Obj.get('userName').setValue(appraiserSplit[2]);
			 	 		}
			 	 		// set values to  appraiser 2 object in app comments form
			 	 		if(loopcount === 2){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 			
			 	 			if(appraiserSplit[0] != "")
			 	 					appraiser2Obj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					appraiser2Obj.get('firstName').setValue(appraiserName[0]);
			 	 					appraiser2Obj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			appraiser2Obj.get('userName').setValue(appraiserSplit[2]);
			 	 		}
			 	 		// set values to  appraiser 3 object in app comments form
			 	 		if(loopcount === 3){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 			
			 	 			if(appraiserSplit[0] != "")
			 	 					appraiser3Obj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					appraiser3Obj.get('firstName').setValue(appraiserName[0]);
			 	 					appraiser3Obj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			appraiser3Obj.get('userName').setValue(appraiserSplit[2]);
			 	 		}
			 	 		// set values to  appraiser 4 object in app comments form
			 	 		if(loopcount === 4){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 			
			 	 			if(appraiserSplit[0] != "")
			 	 					appraiser4Obj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					appraiser4Obj.get('firstName').setValue(appraiserName[0]);
			 	 					appraiser4Obj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			appraiser4Obj.get('userName').setValue(appraiserSplit[2]);
						}
			 	 		// set values to  appraiser 5 object in app comments form
			 	 		if(loopcount === 5){
			 	 			let appraiserSplit = appraiser[loopcount].split("::");
			 	 			
			 	 			if(appraiserSplit[0] != "")
			 	 					appraiser5Obj.get('employeeId').setValue(appraiserSplit[0]);
			 	 			if(appraiserSplit[1] != "" && appraiserSplit[1] != " "){
			 	 					let appraiserName = appraiserSplit[1].split(" ");
			 	 					appraiser5Obj.get('firstName').setValue(appraiserName[0]);
			 	 					appraiser5Obj.get('lastName').setValue(appraiserName[1]);
			 	 			}
			 	 			appraiser5Obj.get('userName').setValue(appraiserSplit[2]);
			 	 		}
			 	 	}
	 	 		}
	 	 	}

 	 	 if(this.appraisalCommentsForm.get('appraisalFormId').value === 0){
	 	 	 	 	
	 	 		if(this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.t001_UserObj)){
	 	 			let t001_UserObj: FormGroup = this.appraisalCommentsForm.get('t001_UserObj') as FormGroup;
	 	 			t001_UserObj.patchValue(data.appraisalFormObj.t001_UserObj);
	 	 		}
	 	 		if(this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.t828_AppraisalObj)){
	 	 			let t828_AppraisalObj: FormGroup = this.appraisalCommentsForm.get('t828_AppraisalObj') as FormGroup;
	 	 			t828_AppraisalObj.patchValue(data.appraisalFormObj.t828_AppraisalObj);
	 	 		}
	 	 		
	 	 		this.appraisalCommentsForm.get('t001_UserCreatedByObj').patchValue(currentUserObj);
	 	 		this.appraisalCommentsForm.get('t001_UserUpdatedByObj').patchValue(currentUserObj);

	 	 }else{
	 	 		this.appraisalCommentsForm.get('t001_UserUpdatedByObj').patchValue(currentUserObj);
	 	 }

	 	 	if(this.commonUtils.checkNullOrUndefined(data.subAppraisalId) && this.commonUtils.checkNullOrUndefined(data.subAppraisalName)){
	 	 		let  t829_SubAppraisalObj: FormGroup = this.appraisalCommentsForm.get('t829_SubAppraisalObj') as FormGroup;
	 	 		t829_SubAppraisalObj.get('subAppraisalId').setValue(data.subAppraisalId);
	 	 		t829_SubAppraisalObj.get('subAppraisalName').setValue(data.subAppraisalName);
	 	 		//t829_SubAppraisalObj.patchValue(data.appraisalIndexObj);


	 	 	}
	 	 	if(currentUserObj.userName === selfAppraiserObj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = false;
	 	 		this.appraiser1DisableFlag = true;
	 	 		this.appraiser2DisableFlag = true;
	 	 		this.appraiser3DisableFlag = true;
	 	 		this.appraiser4DisableFlag = true;
	 	 		this.appraiser5DisableFlag = true;
	 	 		this.activeAppraiserTabIndex = 0;
	 	 	}else if(currentUserObj.userName === appraiser1Obj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = true;
	 	 		this.appraiser1DisableFlag = false;
	 	 		this.appraiser2DisableFlag = true;
	 	 		this.appraiser3DisableFlag = true;
	 	 		this.appraiser4DisableFlag = true;
	 	 		this.appraiser5DisableFlag = true;
	 	 		this.activeAppraiserTabIndex = 1;
	 	 	}else if(currentUserObj.userName === appraiser2Obj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = true;
	 	 		this.appraiser1DisableFlag = true;
	 	 		this.appraiser2DisableFlag = false;
	 	 		this.appraiser3DisableFlag = true;
	 	 		this.appraiser4DisableFlag = true;
	 	 		this.appraiser5DisableFlag = true;
	 	 		this.activeAppraiserTabIndex = 2;
	 	 	}else if(currentUserObj.userName === appraiser3Obj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = true;
	 	 		this.appraiser1DisableFlag = true;
	 	 		this.appraiser2DisableFlag = true;
	 	 		this.appraiser3DisableFlag = false;
	 	 		this.appraiser4DisableFlag = true;
	 	 		this.appraiser5DisableFlag = true;
	 	 		this.activeAppraiserTabIndex = 3;
	 	 	}else if(currentUserObj.userName === appraiser4Obj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = true;
	 	 		this.appraiser1DisableFlag = true;
	 	 		this.appraiser2DisableFlag = true;
	 	 		this.appraiser3DisableFlag = true;
	 	 		this.appraiser4DisableFlag = false;
	 	 		this.appraiser5DisableFlag = true;
	 	 		this.activeAppraiserTabIndex = 4;
	 	 	}else if(currentUserObj.userName === appraiser5Obj.get('userName').value){
	 	 		this.selfAppraiserDisableFlag = true;
	 	 		this.appraiser1DisableFlag = true;
	 	 		this.appraiser2DisableFlag = true;
	 	 		this.appraiser3DisableFlag = true;
	 	 		this.appraiser4DisableFlag = true;
	 	 		this.appraiser5DisableFlag = false;
	 	 		this.activeAppraiserTabIndex = 5;
	 	 	}
	 	 	 this.uploadedFileArrayparent = [];
	 	if(this.commonUtils.checkNullOrUndefined(data.appraisalIndexObj.uploadedFileName)){
          let uploadedArray =data.appraisalIndexObj.uploadedFileName.split(",");
          let fileSize=[];
          if(this.commonUtils.checkNullOrUndefined(data.appraisalIndexObj.fileSizes)){
            fileSize = data.appraisalIndexObj.fileSizes.split(",");
          }
          for(let loop =0;loop < uploadedArray.length ;loop++){
            this.uploadedFileArrayparent.push({'name':uploadedArray[loop],'size':fileSize[loop],'addedNewly':false});
          }  
        }
        else if(this.commonUtils.checkNullOrUndefined(data.appraisalIndexObj.uploadedFileArray)){
          this.uploadedFileArrayparent = data.appraisalIndexObj.uploadedFileArray;
           for(let loop =0;loop < this.uploadedFileArrayparent.length ;loop++){
         	(this.appraisalCommentsForm.get('uploadedFileArray')as FormArray).push(this.createUploadFileList(this.uploadedFileArrayparent[loop].name,this.uploadedFileArrayparent[loop].size,this.uploadedFileArrayparent[loop].addedNewly));
          } 
        }

 	 }

 	 closePopup(){
 	 	this.dialogRef.close(this.appraisalCommentsForm.value);
 	 }
 	 attachFiles(){
 	 	
 	 	let currentUserObj = this.commonService.loggedInUserObj;this.appraisalCommentsForm.get('selfAppraiserObj.userName').value
 	 	if(currentUserObj.userName === this.appraisalCommentsForm.get('selfAppraiserObj.userName').value){
	 	 		this.accessFlag = true;
	 	 	}else if(currentUserObj.userName === this.appraisalCommentsForm.get('appraiser1Obj.userName').value){
	 	 		this.accessFlag = false;
	 	 	}else if(currentUserObj.userName === this.appraisalCommentsForm.get('appraiser2Obj.userName').value){
	 	 		this.accessFlag = false;
	 	 	}else if(currentUserObj.userName === this.appraisalCommentsForm.get('appraiser3Obj.userName').value){
	 	 		this.accessFlag = false;
	 	 	}else if(currentUserObj.userName === this.appraisalCommentsForm.get('appraiser4Obj.userName').value){
	 	 		this.accessFlag = false;
	 	 	}else if(currentUserObj.userName === this.appraisalCommentsForm.get('appraiser5Obj.userName').value){
	 	 		this.accessFlag = false;
	 	 	}
	 	 	else{
	 	 		this.accessFlag = false;
	 	 	}
 	 	let imagePath =null;
 	 
	 	if(this.subAppraisalId!=0){
 		
	 		let appraisalId=this.appraisalCommentsForm.get('t828_AppraisalObj.appraisalId').value;
	 		let username=this.appraisalCommentsForm.get('t001_UserObj.userName').value;
		    let subAppraisalId=this.subAppraisalId;
		    let orgId = this.commonService.loggedInUserObj.t000Organization.organizationId;
		    let portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
		    
		    imagePath = this.commonService.SERVICE_URL+"erpassets/"+portalName+"/"+"APPRAISAL"+"/"+username+"/"+appraisalId+"/"+subAppraisalId;  
 	 	}
 	 	else{
	    	imagePath = null;
	  	}
	   
	    const dialogRef = this.dialog.open(FileUploadComponent, { 
      width: '40%',
     
      data: {dialogLabel: "Attach Files",uploadedFileArray:this.uploadedFileArrayparent,
      imagePath:imagePath,hideDeleteFlag: this.accessFlag}
    });
    dialogRef.afterClosed().subscribe(result => {  
    	

      if (result.done === true && result != 'undefined') { 
        this.uploadedFileArrayparent=result.uploadedArray;
        if(this.uploadedFileArrayparent !=null && this.uploadedFileArrayparent.length>0){
          var fileUploaded = "";
          var attachFlag="";
          (this.appraisalCommentsForm.get('uploadedFileArray')as FormArray).clear();
        for(let loop =0;loop < this.uploadedFileArrayparent.length ;loop++){
        	(this.appraisalCommentsForm.get('uploadedFileArray')as FormArray).push(this.createUploadFileList(this.uploadedFileArrayparent[loop].name,this.uploadedFileArrayparent[loop].size,this.uploadedFileArrayparent[loop].addedNewly));
            fileUploaded = fileUploaded+this.uploadedFileArrayparent[loop].name+",";
            }
          fileUploaded = fileUploaded.substring(0,fileUploaded.length-1);
       
        this.appraisalCommentsForm.get('fileUpload').setValue(fileUploaded);  
        }
        else{
          this.appraisalCommentsForm.get('fileUpload').setValue("");
          (this.appraisalCommentsForm.get('uploadedFileArray')as FormArray).clear();
        }
       } 
    });     
}
createUploadFileList(name,size,addedNewly): FormGroup {
  return this.formBuilder.group({
    name: new FormControl(name),
    size: new FormControl(size),
    addedNewly: new FormControl(addedNewly),
   
   });
}

}