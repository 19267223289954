<html>
    <body>
        <div class="col-lg-12 row nopadding">
            <div id="project-dashboard" class="page-layout simple right-sidebar col-lg-12 nopadding">
                <div class="page-content-wrapper custom-scrollbar">
                    <div class="page-content">
                        <div class="row pt-0">
                            <div class="col-12 col-sm-12 col-xl-12 pt-0">
                                <img style="width:100%" class="topbanner" src="assets/hris_banner.jpg">
                            </div>  
                        </div>
                        <!-- WIDGET GROUP -->
                        <div class="widget-group row no-gutters">
                            <!-- WIDGET 1 -->
                            <div class="col-12 col-sm-6 col-xl-4 p-1"  *ngIf="roleName === 'EMPLOYEE' || roleName === 'EMPLOYEE_MANAGER'|| roleName === 'HR' || roleName === 'RECRUITER'">
                                <div class="widget widget2 card" >
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">My Corner</span>
                                            <label *ngIf="commonUtils.checkNullOrUndefined(myShift) && 
                                            commonUtils.checkNullOrUndefined(myShift.startTime)" style="float: right;">
                                            <span style="font-size: 14px;">First In:</span> {{myShift.startTime}}</label>
                                        </div>
                                    </div>
                                    <div class="widget-content">
                                        <div class="row no-gutters mycorner-cs">
                                            <div id="added-tasks" class="col-6 d-flex flex-column align-items-center br-right justify-content-center py-2">
                                                <div class="count h3 text-secondary">{{loginTime  | date: 'HH:mm:ss'}}</div>
                                                    <label class="count-title">IN Time</label>
                                                <div>
                                                    <button type="button" [disabled]='loginButton' class="punchbtn btn btn-out" (click)="loginProcess('LOGIN-IN')">Punch In </button>
                                                </div>
                                            </div>
                                            <div id="completed-tasks" class="col-6 d-flex flex-column align-items-center justify-content-center py-2">
                                                <div class="count h3 text-secondary">{{logoutTime | date: 'HH:mm:ss'}}</div>
                                                    <label class="count-title">OUT Time</label>
                                                <div>
                                                    <button type="button" [disabled]='!loginButton' class="punchbtn btn btn-out" (click)="loginProcess('LOGIN-OUT')">Punch Out</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="widget-footer doticon p-0 row no-gutters align-items-center">
                                        <button  (click)="showAsFullHistory('LOGIN_HISTORY')"  mat-button type="button" matSuffix mat-button aria-label="Clear">
                                            <mat-icon>more_horiz</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- / WIDGET 1 -->

                            <!-- WIDGET 2 -->
                            <div class="col-12 col-sm-6 col-xl-4 p-1"  *ngIf="roleName === 'EMPLOYEE'  || roleName === 'EMPLOYEE_MANAGER' || roleName === 'HR' || roleName === 'RECRUITER'">
                                <div class="widget widget3 card">
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between ">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">Birthday for Current Month</span>
                                        </div>
                                        <div *ngIf="empBirthdayList.length > 0 && empBirthdayList.length < 10" class="fa-stack fa-lg fa-1x border bg-red mb-3"> {{empBirthdayList.length}}</div>
                                        <div *ngIf="empBirthdayList.length > 9 " class="fa-stack-2 fa-lg fa-1x border bg-red mb-3"> {{empBirthdayList.length}}</div>
                                    </div>
                                    <div class="widget-content bdaycontent pt-1 pb-1 d-flex flex-column align-items-center justify-content-center birthday-cs">
                                        <div class="fullrow row pt-1">
                                            <ng-template ngFor let-emp [ngForOf]="empBirthdayList" let-i="index">
                                                <div class="activity row no-gutters py-0 col-12 col-sm-6 col-xl-6"  *ngIf="emp.displayFlag === 'true'">
                                                    <img  src="{{photoSrcPath}}{{emp.USER_NAME}}/{{emp.PHOTO_FILE_NAME}}" (error)="errorHandler($event)" class="col-auto avatar mr-2" alt="{{emp.USER_NAME}}" >   
                                                    <div  class="col">
                                                        <div>
                                                            <span class="bday-employee md-accent-color">{{emp.EMP_NAME}}</span>
                                                        </div>
                                                        <span class="time text-muted">{{emp.DESIGNATION}}</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <div class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6"  *ngIf="empBirthdayList.length === 0" style="margin: 0 auto;"   >
                                                <div class="col pt-3">
                                                    <span class="h6 card-header-tabs"> <img style="width:100%"  src="assets/nobirthday.png">  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-footer doticon p-0 row no-gutters align-items-center">
                                        <button *ngIf="empBirthdayList.length > 4" (click)="showAsFullHistory('Birthday')" mat-button type="button" matSuffix mat-button aria-label="Clear">
                                                <mat-icon>more_horiz</mat-icon>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-8 p-1"  *ngIf="(roleName === 'ADMIN' ) && (roleName !== 'EMPLOYEE' && roleName !== 'EMPLOYEE_MANAGER' &&  roleName !== 'HR')">
                                <div class="widget widget3 card" >
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">Birthday for Current Month</span>
                                        </div>
                                        <div *ngIf="empBirthdayList.length > 0 && empBirthdayList.length < 10" class="fa-stack fa-lg fa-1x border bg-red mb-3"> {{empBirthdayList.length}}</div>
                                        <div *ngIf="empBirthdayList.length > 9 " class="fa-stack-2 fa-lg fa-1x border bg-red mb-3"> {{empBirthdayList.length}}</div>
                                    </div>
                                    <div class="widget-content bdaybigcontent pt-1 pb-1 d-flex flex-column align-items-center justify-content-center birthday-cs">
                                        <div class="fullrow row pt-1">
                                            <ng-template ngFor let-emp [ngForOf]="empBirthdayList" let-i="index">
                                                <div  class="activity row no-gutters py-0 col-12 col-sm-6 col-xl-6"    *ngIf="emp.displayFlag === 'true'" >
                                                    <img src="{{photoSrcPath}}{{emp.USER_NAME}}/{{emp.PHOTO_FILE_NAME}}" (error)="errorHandler($event)" class="col-auto avatar mr-2" alt="{{emp.USER_NAME}}" >  
                                                    <div class="col">
                                                        <div>
                                                            <span class="bday-employee md-accent-color">{{emp.EMP_NAME}}</span>                                                               
                                                        </div>
                                                        <span class="time text-muted" style="margin-right: 2%;">{{emp.DESIGNATION}}</span>
                                                        <span class="time text-muted">{{emp.DOB | dateFormatPipe}}</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <div *ngIf="empBirthdayList.length === 0" class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6" style="margin: 0 auto;"    >
                                                <div class="col pt-3">
                                                    <span class="h6 card-header-tabs"> <img style="width:100%" src="assets/nobirthday.png">  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-footer doticon p-0 row no-gutters align-items-center">
                                        <button *ngIf="empBirthdayList.length > 4"  (click)="showAsFullHistory('Birthday')" mat-button type="button" matSuffix mat-button aria-label="Clear">
                                            <mat-icon>more_horiz</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- / WIDGET 2 -->

                            <!-- WIDGET 5 -->
                            <div class="col-12 col-sm-6 col-xl-4 p-1">
                                <div class="widget widget3 card hrAnnouncementHeight" >
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">HR Announcements</span>
                                        </div>
                                    </div>
                                    <div class="widget-content annivcontent minht pt-1 pb-1 d-flex flex-column align-items-center justify-content-center" 
                                        style="padding:20px; height: 180px;">
                                        <div class="fullrow announcescroll pt-1">
                                            <ng-template ngFor let-announcement [ngForOf]="announcementsList" let-i="index">
                                                <div class="activity marginAnnounce row no-gutters py-0 col-12 col-sm-12 col-xl-12"  *ngIf="announcement.active === true"  >
                                                    <div class="row">
                                                        <span>*</span>
                                                        <span class="bday-employee md-accent-color" [innerHTML]="announcement.announcementDescription" style="word-wrap: break-word;padding-left: 10px;"></span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <div *ngIf="announcementsList.length === 0" class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6" style="margin: 0 auto;"    >
                                                <div class="col-lg-12">
                                                    <span class="h6 card-header-tabs" style="font-style: italic;font-weight: 100;font-size: 15px;"> No Announcements </span>
                                                </div>
                                            </div>                                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- / WIDGET 5 -->

                            <!-- WIDGET 3 -->
                            <div class="col-12 col-sm-6 col-xl-8 p-1">
                                <div class="widget widget3 card" style="height: 100% !important;">
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">Anniversary for Current Month</span>
                                        </div>
                                        <div *ngIf="empAnniversaryList.length > 0 && empAnniversaryList.length < 10 " class="fa-stack fa-lg fa-1x border bg-red mb-3">{{empAnniversaryList.length}}</div>
                                        <div *ngIf="empAnniversaryList.length > 9 " class="fa-stack-2 fa-lg fa-1x border bg-red mb-3">{{empAnniversaryList.length}}</div>
                                    </div>
                                    <div class="widget-content annivcontent minht pt-1 pb-1 d-flex flex-column align-items-center justify-content-center" style="height: 100%;">
                                        <div class="fullrow row pt-1">
                                            <ng-template ngFor let-emp [ngForOf]="empAnniversaryList" let-i="index">
                                                <div class="activity row no-gutters py-0 col-12 col-sm-6 col-xl-6"  *ngIf="emp.displayFlag === 'true'"  >
                                                    <img  src="{{photoSrcPath}}{{emp.USER_NAME}}/{{emp.PHOTO_FILE_NAME}}" (error)="errorHandler($event)" class="col-auto avatar mr-2" alt="{{emp.USER_NAME}}" >   
                                                    <div class="col">
                                                        <div>
                                                            <span class="bday-employee md-accent-color">{{emp.EMP_NAME}}</span>
                                                        </div>
                                                        <span class="time text-muted" style="margin-right: 2%;">{{emp.DESIGNATION}}</span>
                                                        <span class="time text-muted">{{emp.DOJ | dateFormatPipe}}</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <div *ngIf="empAnniversaryList.length === 0" class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6" style="margin: 0 auto;"    >
                                            <div class="col pt-3">
                                                <span class="h6 card-header-tabs"> <img style="width:100%" src="assets/no-anniversary.png">  </span>
                                            </div>
                                            </div>                                                     
                                        </div>
                                    </div>
                                    <div class="widget-footer doticon p-0 row no-gutters align-items-center">
                                        <button  *ngIf="empAnniversaryList.length > 4" (click)="showAsFullHistory('Anniversary')" mat-button type="button" matSuffix mat-button aria-label="Clear">
                                            <mat-icon>more_horiz</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- / WIDGET 3 -->

                            <!-- WIDGET 4 -->
                            <div class="col-12 col-sm-6 col-xl-4 p-1">
                                <div class="widget widget3 card" style="height: 100% !important;">
                                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-3">
                                            <span class="h6 card-head">HR Policies</span>
                                        </div>
                                    </div>
                                    <div class="widget-content annivcontent minht pt-1 pb-1 d-flex flex-column align-items-center justify-content-center" style="height: 100%;">
                                        <div class="fullrow row pt-1" style="text-align: center;" *ngIf="hrPolicyfileExistFlag">
                                            <a href="{{pdfSource}}" color="primary" type="button" target="_blank" style="width:100%" >{{VIEW_UPLOADED_PDF}}</a>
                                        </div>
                                        <div *ngIf="!hrPolicyfileExistFlag" class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6" style="margin: 0 auto;"    >
                                            <div class="col-lg-12">
                                                <span class="h6 card-header-tabs" style="font-style: italic;font-weight: 100;font-size: 15px;"> Policies are yet to be uploaded by HR </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- / WIDGET 4 -->

                            <div class="col-12 col-lg-8 p-1" *ngIf="(roleName === 'ADMIN' || roleName === 'HR') && (roleName !== 'EMPLOYEE' || roleName !== 'EMPLOYEE_MANAGER')">
                                <div class="widget widget6 card">
                                    <div class="widget-header px-4 row no-gutters align-items-center justify-content-between">
                                        <div class="col pt-2">
                                            <span class="h6 card-head">Attendance</span>
                                        </div> 
                                        <div class="col-7 pt-3 filterrange reportLink" style="text-align: right;">
                                            <a [matTooltip]="getAttendanceDateTooltip('Today')" class="attendanceClick" [ngClass]="{'activeAttendanceClass' : id === '1'}"  (click)="fetchAttendance('Today','1')">Today</a>  |
                                            <a class="attendanceClick" [matTooltip]="getAttendanceDateTooltip('Yesterday')"[ngClass]="{'activeAttendanceClass' : id === '2'}" (click)="fetchAttendance('Yesterday','2')">Yesterday</a>  |  
                                            <a class="attendanceClick"  [matTooltip]="getAttendanceDateTooltip('Last Week')" [ngClass]="{'activeAttendanceClass' : id === '3'}" (click)="fetchAttendance('Last Week','3')">Last Week</a>  |  
                                            <a class="attendanceClick" [matTooltip]="getAttendanceDateTooltip('Last Month')"[ngClass]="{'activeAttendanceClass' : id === '4'}" (click)="fetchAttendance('Last Month','4')">Last Month</a>  |  
                                            <a class="attendanceClick" matTooltip="You can search on your own by giving From and To Date by clicking on here" [routerLink]="['/reports/attendanceHistory']">Custom</a> 
                                        </div>                                        
                                    </div>

                                    <div class="widget-content margintop2">
                                        <div class="row atdata ml-4 mr-4 pt-4 pb-4">
                                            <div class="col-12 col-sm-6 col-xl-3 mt-4 p-0">
                                                <div class="row no-gutters align-items-center justify-content-between bg-navblue text-auto p-3">
                                                    <div class="att-title title h6">Late In</div>
                                                    <div class="more text-muted">{{attendanceData.lateInCount}}</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-xl-3 mt-4 p-0">
                                                <div class="row no-gutters align-items-center justify-content-between bg-boxred text-auto p-3">
                                                    <div class="att-title title h6">Not Logged In</div>
                                                    <div class="more text-muted">{{attendanceData.absenteesCount}}</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-xl-3 mt-4 p-0">
                                                <div class="row no-gutters align-items-center justify-content-between bg-boxgreen text-auto p-3">
                                                    <div class="att-title title h6">On Time</div>
                                                    <div class="more text-muted">{{attendanceData.onTimeCount}}</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-xl-3 p-0">
                                                <div id="added-tasks" class="col-12 d-flex flex-column align-items-center justify-content-center py-0">
                                                    <div class="count h3 text-abscence">{{attendanceData.absenteesCount}}</div>
                                                    <div class="count-title abscence-title">Absentees</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-2 p-1"  *ngIf="(roleName === 'ADMIN' || roleName === 'HR') && (roleName !== 'EMPLOYEE' || roleName !== 'EMPLOYEE_MANAGER')">
                                <div class="widget widget6 ">
                                    <div class="widget-header timesheetimg row no-gutters align-items-center justify-content-between">
                                    <div class="widget-content">
                                        <img [routerLink]="['/reports/timesheetHistory']"  style="border-radius:8px; width:100%" src="assets/images/timesheet.png">
                                    </div>                                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-2 p-1" *ngIf="(roleName === 'ADMIN' || roleName === 'HR') && (roleName !== 'EMPLOYEE' || roleName !== 'EMPLOYEE_MANAGER')">
                                <div class="widget widget6 ">
                                    <div class="widget-header timesheetimg row no-gutters align-items-center justify-content-between">
                                        <div class="widget-content">
                                            <img [routerLink]="['/reports/reportsSummary']"  style="border-radius:8px; width:100%" src="assets/images/reports.png">
                                        </div>                                                        
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>