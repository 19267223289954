
import {throwError as observableThrowError, Observable,Subscription,Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Response, Headers,Http} from '@angular/http';
import {environment} from '../../environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {ErrorMessageAlertComponent} from '../dialogBox/error/error-msg.component';
import {MatDialogModule, MatDialog} from '@angular/material/dialog';
import { map,catchError } from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {  take } from 'rxjs/operators';
import {Router, ActivatedRoute,NavigationStart} from '@angular/router';
import {CommonUtils} from '../core/common-utils';
import { CookieService } from 'ngx-cookie-service';
import {Store} from '@ngrx/store';
import * as SecureLS from 'secure-ls';
 
import * as fromStore from '../store';
import * as fromTemplateActions from '../store/actions/createtemplate.actions';
@Injectable()
export class CommonService {
  BuildNumber='4.1.0';
  loggedInUserObj: any;
  pageChangeEvent = new Subject<any>();
  paginationChangeEvent = new Subject<any>();
  reconcileSubject = new Subject<any>();
  projectSubject = new Subject<any>();
  loggesInUserObjSubject: Subject<string> = new Subject<string>();
  erpSubject: Subject<any> = new Subject<any>();
  refreshClickedSubject: Subject<any> = new Subject<any>();
  notificationChangedSubject: Subject<any> = new Subject<any>();
  menuClickedSubject: Subject<any> = new Subject<any>();
  nistarProductSetupSubject: Subject<any> = new Subject<any>();

  SERVICE_URL: string;
  currencySymbol: string;
  currentlySelectedMenu: any;
  menuHistory=[];
  currentMenuIndex:number=0;
  reviewDisplayedColumnsMap = new Map();
  userDisplayedColumnsMap = new Map();
  columnLabelMap = new Map();
  userLabelMap = new Map();
  currentSelectedModuleBasedAccess = new Map();
  recordOldChangesForEachModuleMap:Map<string,any> = new Map();
  createTemplateSettingsMap:Map<string,any>= new Map<string,any>();
  searchForm:FormGroup;
  returnForm:FormGroup;
  appScreenList=[];
  appScreenMap=new Map();
  breadCrumbList:Array<any>=[];
  validationSettingList=[];
  validationLicense=new FormControl('');
  validationModule=new FormControl('');
  loggedInUserIp;
  myAttendanceObj: any;
googleApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=';
  googleApiKey = 'AIzaSyAwj1rLOx6B6SDKJph93CWFDzX8A_qQdYw';
    yearList = [];

  constructor( private commonUtils: CommonUtils, private router: Router,
   private http: HttpClient,private httpObj: Http,private activatedRoute:ActivatedRoute,
    private snackBar: MatSnackBar, public dialog: MatDialog ,public cookieService:CookieService,private store:Store<fromStore.CreateTemplateStates> ,
    ) {
        let currentDate = new Date();
        let currentyear = currentDate.getFullYear();
         
        for(var loopCount = currentyear; loopCount >= 1900;loopCount-- ){
          this.yearList.push(loopCount);
        }
  }

  fetchCurrency(currencyFormGroup):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchCurrency',currencyFormGroup)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchFromLookup(lookupFormGroup) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchFromLookup', lookupFormGroup)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
  fetchDeliveryMethod():Observable<any> {

    return this.http.get(this.SERVICE_URL + 'admin/fetchDeliveryMethod')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchDepartments():Observable<any> {

    return this.http.get(this.SERVICE_URL + 'admin/fetchDepartments')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchCountry() :Observable<any>{

    return this.http.get(this.SERVICE_URL + 'admin/fetchCountry')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchLocation(locationObj):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'admin/fetchLocation',locationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateWareHouses(wareHouseObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateWareHouses', wareHouseObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchLanguage():Observable<any> {

    return this.http.get(this.SERVICE_URL + 'admin/fetchLanguage')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchIndustries() :Observable<any>{

    return this.http.get(this.SERVICE_URL + 'admin/fetchIndustries')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 
  fetchControlTypeMaster():Observable<any> {

    return this.http.get(this.SERVICE_URL + 'admin/fetchControlTypeMaster')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchCarrier():Observable<any> {

    return this.http.get(this.SERVICE_URL + 'admin/fetchCarrier')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchWarehouse(warehouseObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchWarehouse',warehouseObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  uploadFiles(files):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'admin/UploadFiles', files,{responseType:'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }
 uploadSignatureFile(fileObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/UploadSignatureFile', fileObj,{responseType:'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }
  //saveOrUpdateNDAPDFSignature
  saveOrUpdateNDAPDFSignature(commonObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateNDAPDFSignature', commonObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchDuplicateImport() :Observable<any>{

    return this.http.get(this.SERVICE_URL + 'import/fetchDuplicateImports')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  importFiles(files,moduleName):Observable<any> {
   if(moduleName==="Items" || moduleName==="Contacts" || moduleName==="Assets" || moduleName === "Employees" ||
     moduleName === "Inventory Adjustments" || moduleName === "Sales Line Item Detail" || moduleName === "Purchases Line Item Detail" || moduleName === "Price List Item Level" || 
     moduleName === "Items For Delete" || moduleName === "Nistar Items" || moduleName === "Employee Earnings"){
    	return this.http.post(this.SERVICE_URL + 'import/uploadEmployeeFile', files)
      .pipe(map(data => data)
      ,catchError(this.handleError));
    }
  
     if(moduleName==="ContactsPerson"){
      return this.http.post(this.SERVICE_URL + 'import/importContactPersonFiles', files)
      .pipe(map(data => data)
      ,catchError(this.handleError));
    }
  }
   
 fetchDynamicControls(moduleName):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchDynamicControls', moduleName)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchAutoGenerateNumber(autoGenerateObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchAutoGenerateNumber', autoGenerateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchAutoGenerateNumbers(autoGenerateObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchAutoGenerateNumbers', autoGenerateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }


  saveAutoGenerateNumber(autoGenerateObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/saveAutoGenerateNumber', autoGenerateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 saveAutoGenerateNumbers(autoGenerateObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveAutoGenerateNumbers', autoGenerateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

fetchReportsDetails(modulename):Observable<any> {
       return this.http.post(this.SERVICE_URL + 'admin/reportSummary',modulename)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
downloadReportSummary(modulename):Observable<any> {
    if(modulename.reportType=="Excel(XLS)"){
      return this.http.post(this.SERVICE_URL + 'admin/generateExcel',modulename,{responseType: 'text'})
      .pipe(map(data => data)
      ,catchError(this.handleError));
    }else if (modulename.reportType == "APPRAISAL_DETAIL_REPORT"){
      return this.http.post(this.SERVICE_URL + 'reports/downloadExportAppraisalForCandidate',modulename,{responseType: 'text'})
      .pipe(map(data => data)
      ,catchError(this.handleError));
    } else {
      return this.http.post(this.SERVICE_URL + 'admin/generateSummaryPDF',modulename,{responseType: 'text'})
      .pipe(map(data => data)
      ,catchError(this.handleError));
    }     
  }

  generatePDF(pdfArrays):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/generatePDF', pdfArrays, {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  decryptDirectLinkID(id):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/decryptDirectLinkID', id, {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  private handleError(error: any):Observable<any> {
     
    return observableThrowError(error);
  }

//if module name is passed then the page will be redidrected on click of the confirmation button
  showERPMessage(message, moduleName?: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = 2000;
    config.verticalPosition = 'top';
    if(message != null && message != '')
      this.snackBar.open(message, null, config);
   
    
  if(this.appScreenMap.size === 0 ){
     let promise =  this.loadAppscreenMapAfterReload(moduleName);   
      promise.then(data=>{
         if (this.commonUtils.checkNullOrUndefined(moduleName)) {
            const redirectURL = this.getRedirectLink(moduleName);
            this.router.navigate([redirectURL]);
          }
      });
 
  }

  if(this.commonUtils.checkNullOrUndefined(moduleName)){
      const redirectURL = this.getRedirectLink(moduleName);
      this.router.navigate([redirectURL]);
  } 
 }
  dialogRefObj: any = null;
  showERPErrorMessage(error) 
  {    
     console.log(error);
   
    if(this.commonUtils.checkNullOrUndefined(error) &&
      this.commonUtils.checkNullOrUndefined(error.error)&&
      this.commonUtils.checkNullOrUndefined(error.error.erpmessage))
    {
      if(this.dialogRefObj == null){
        this.dialogRefObj = this.dialog.open(ErrorMessageAlertComponent, 
          {
            width: '500px',
            data: {
             
              dialogText: error.error.erpmessage,
              dialogDetailText: error.error.erpDetailMessage,
              dialogSupport:"false"
          
            }
          }); 
          this.dialogRefObj
              .afterClosed()
              .pipe(take(1))
              .subscribe((result=>{  this.dialogRefObj = null;
              }));
         
      }
      if(error.error.errorCode==="981"){
        console.log(" Respect the privacy of others. Dont try to hack.  ");
        
         this.emitInvalidLogin();
     }
    
  }
  /*else part added by - 9072*/
  else if(this.commonUtils.checkNullOrUndefined(error) && this.commonUtils.checkNullOrUndefined(error.error) &&
    this.commonUtils.checkNullOrUndefined(error.error.message))
  { 

    if(this.dialogRefObj == null){
      this.dialogRefObj = this.dialog.open(ErrorMessageAlertComponent, 
        {
          width: '500px',
          data: {
           
            dialogText: error.error.message,
            dialogSupport:"false"
           
          }
        });
        this.dialogRefObj
            .afterClosed()
            .pipe(take(1))
            .subscribe((result=>{ 
               this.dialogRefObj = null;
            }));
    }
  }
  else if(this.commonUtils.checkNullOrUndefined(error) && this.commonUtils.checkNullOrUndefined(error.error))
  {
    if(this.dialogRefObj == null){
      this.dialogRefObj = this.dialog.open(ErrorMessageAlertComponent, 
        {
          width: '500px',
          data: {
           
            dialogText: error.error,
            dialogSupport:"false"
           
          }
        });
        this.dialogRefObj
            .afterClosed()
            .pipe(take(1))
            .subscribe((result=>{ 
               this.dialogRefObj = null;
            }));
    }
   
  }
  
  }
  showAdminERPErrorMessage(error){
    const dialogRef = this.dialog.open(ErrorMessageAlertComponent, 
    {
      width: '500px',
      data: {
       
        dialogText: error,
        dialogSupport:"false"
       
      }
    });
  }
  showImportErrorMessage(error) {
    const dialogRef = this.dialog.open(ErrorMessageAlertComponent, {
      width: '500px',
      data: {
        dialogText: error.erpmessage,
        dialogDetailText: error.erpmessage,
        dialogSupport:"false"
      }
    });
  }
  showImportEmployeeErrorMessage(error) {
    const dialogRef = this.dialog.open(ErrorMessageAlertComponent, {
      width: '500px',
      data: {
        dialogText: "Duplicate User Name Imports",
        dialogDetailText: error,
        dialogSupport:"true"
      }
    });
  }
  showImportSKUErrorMessage(errorSku){
    const dialogRef = this.dialog.open(ErrorMessageAlertComponent, {
      width: '500px',
      data: {
        dialogText: "Below given SKU's are not found in the system. Please expand the panel to view in detail.",
        dialogDetailText:errorSku,
        dialogSupport:"true"
      }
    });
  }
  formLookUpFormGroup(moduleName,pageNo?,pageSize?,recordCount?,paginationLoadFlag?,fetchModuleName?): FormGroup 
  {

    const lookupFormGroup = new FormGroup({
      lookupId: new FormControl('0'),
      lookupCode: new FormControl(moduleName),
      status:new FormControl(true),
      pageNo: new FormControl(pageNo),
       pageSize: new FormControl(pageSize),
       recordCount: new FormControl(recordCount),
       paginationLoadFlag:new FormControl(paginationLoadFlag),
       moduleName: new FormControl(fetchModuleName)
    });
    return lookupFormGroup;
  }
  formFPCredentialsFormGroup(moduleName,pageNo?,pageSize?,recordCount?,paginationLoadFlag?): FormGroup 
  {

    const fpSettingsFormGroup = new FormGroup({
      fpSettingsId: new FormControl('0'),
      fpSettingsFormGroup: new FormControl(moduleName),
      pageNo: new FormControl(pageNo),
       pageSize: new FormControl(pageSize),
       recordCount: new FormControl(recordCount),
       paginationLoadFlag:new FormControl(paginationLoadFlag)
    });
    return fpSettingsFormGroup;
  }

  showProgressBar() {
    this.store.dispatch(new fromTemplateActions.ShowProgressBar());

  
  }
  hideProgressBar() {
    this.store.dispatch(new fromTemplateActions.HideProgressBar());

   
  }
  fetchInvoiceNumberStatus(invoiceObj) :Observable<any>{

    return this.http.post(this.SERVICE_URL + 'invoice/fetchInvoiceNumberStatus', invoiceObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchEmailTemplate(emailObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchEmailTemplete', emailObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchReportTemplate(templateObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchReportTemplete', templateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
  fetchValidations(validationFormGroup) :Observable<any>{    
    return this.http.post(this.SERVICE_URL + 'admin/fetchValidation',validationFormGroup)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchReviewDisplayColumn(moduleName) :Observable<any>
  {
    
    return this.http.post(this.SERVICE_URL + 'admin/fetchReviewDisplayColumn',moduleName)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAllReviewDisplayColumn(moduleName) :Observable<any>
  {
    
    return this.http.post(this.SERVICE_URL + 'admin/fetchAllReviewDisplayColumn',moduleName)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchReviewDisplayColumnScreen(t034ReviewDisplayColumnObj) :Observable<any>
  {
    
    return this.http.post(this.SERVICE_URL + 'admin/fetchReviewDisplayColumnScreen',t034ReviewDisplayColumnObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Fetch Resource List ---------- 9056 --------- START*/
  fetchResource():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchResource')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Fetch Resource List ---------- 9056 --------- END*/

  /*Fetch Parent Task List ---------- 9056 --------- START*/
  fetchParentTask(t823taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchParentTask',t823taskObj) 
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchParentTaskList(t823taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchParentTaskList',t823taskObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAllParentTask(t823taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAllParentTask',t823taskObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Fetch Parent Task List ---------- 9056 --------- END*/

  // donot modify in this method unless you knowfor sure.
  checkForLoginUser(userObj):Observable<any> {
    /* return this.http.post(this.commonService.SERVICE_URL+'admin/loginUser',userObj)
     .pipe(map(data=>data)
     ,catchError(this.handleError));*/
    return this.http.post(this.SERVICE_URL + 'admin/loginUser', userObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  appLoadReloadlogin() {
	  //peoplezenn domain address Issue 9057
    if(window.location.href.indexOf('neuzenhr.peoplezenn.com')!=-1)
      this.SERVICE_URL="https://neuzenhrapi.peoplezenn.com/"+"api/";

    if(window.location.href.indexOf('neuzenhruat.peoplezenn.com')!=-1)
      this.SERVICE_URL="https://neuzenhruatapi.peoplezenn.com/"+"api/";
  
  console.log(window.location.href);
  //workofesales domain address Issue 9057
	if(window.location.href.indexOf('neuzenhr.workofesales.com')!=-1)
      this.SERVICE_URL="https://neuzenhrapi.workofesales.com/"+"api/";
    if(window.location.href.indexOf('neuzenhruat.workofesales.com')!=-1)
      this.SERVICE_URL="https://neuzenhruatapi.workofesales.com/"+"api/";
  
  //Ip address Issue 9057
  if(window.location.href.indexOf('35.238.253.161')!=-1)
      this.SERVICE_URL="https://neuzenhrapi.workofesales.com/"+"api/";
    if(window.location.href.indexOf('35.208.41.144')!=-1)
      this.SERVICE_URL="http://35.208.41.144:01/"+"api/";
  
  if(window.location.href.indexOf('20.1.10.35')!=-1)
      this.SERVICE_URL="http://20.1.10.35:8081/"+"api/";
  
  if(window.location.href.indexOf('hrisuat.workofesales.com')!=-1)
      this.SERVICE_URL="https://hrisuatapi.workofesales.com/"+"api/";
  
    if(window.location.href.indexOf('localhost')!=-1)
      this.SERVICE_URL="http://localhost:"+environment.SERVICE_PORT+"/api/";    
    let Subscription:Subscription=this.router.events.subscribe((url:NavigationStart) =>
      {          
        let incomingURL:string=url.url;
         //do not remove this line . this is to avoid loop
          Subscription.unsubscribe();            
        if(this.commonUtils.checkNullOrUndefined(incomingURL))
          this.appLoad(incomingURL.replace('/',''));
      }); 
  }

clearUserData(){
  // this.cookieService.delete('loginPortalName','/','');
  // this.cookieService.delete('loginUserId','/','');
  //  this.cookieService.delete('loginDefaultURL','/','');
  // this.cookieService.delete('userIdentityToken','/','');
  // The below line does the same action what the above three lines do
  // this.cookieService.deleteAll('/','');
  var ls = new SecureLS();
  ls.removeAll();
}

loadUserData(){
  // this.loginUserId=this.cookieService.get('loginUserId');
  // this.loginPortalName=this.cookieService.get('loginPortalName');
  // this.loginDefaultURL=this.cookieService.get('loginDefaultURL');
  // this.userIdentityToken=this.cookieService.get('userIdentityToken');
  var ls = new SecureLS();
  this.loginUserId=ls.get('loginUserId');
  this.loginPortalName=ls.get('loginPortalName');
  this.loginDefaultURL=ls.get('loginDefaultURL');
  this.userIdentityToken=ls.get('userIdentityToken');
}

async setUserData(){
//  await this.cookieService.set('loginUserId', this.loggedInUserObj.userId,new Date('31-12-2071 23:59:00'),'/','',false,'Lax');  
//  await this.cookieService.set('loginPortalName',this.loggedInUserObj.t000Organization.portalName,new Date('31-12-2071 23:59:00'),'/','',false,'Lax');
//  await this.cookieService.set('loginDefaultURL',this.loggedInUserObj.t029Roles.defaultPage,new Date('31-12-2071 23:59:00'),'/','',false,'Lax');  
//  await this.cookieService.set('userIdentityToken',this.loggedInUserObj.userIdentityToken,new Date('31-12-2071 23:59:00'),'/','',false,'Lax');
var ls = new SecureLS();
  await ls.set('loginUserId', this.loggedInUserObj.userId);  
  await ls.set('loginPortalName',this.loggedInUserObj.t000Organization.portalName);
  await ls.set('loginDefaultURL',this.loggedInUserObj.t029Roles.defaultPage);  
  await ls.set('userIdentityToken',this.loggedInUserObj.userIdentityToken);  
}
async checkUserLogin(userObj){
  // console.log("SignUp event",userObj);
  let event=userObj;
await this.loadUserData();
  // console.log("this.loginUserId",this.loginUserId);
  //  console.log("this.loginDefaultURL",this.loginDefaultURL);
  if(this.commonUtils.checkNullOrUndefined(this.loginUserId)){
    if(event =='SignUp'){
      this.router.navigate([this.loginDefaultURL]);
    }
  }
  else if(event!='SignUp'){
    this.router.navigate(['/']);
  }
}
loginUserId;
loginPortalName;
userIdentityToken;
loginDefaultURL;
  
  appLoad(redirectUrl:string){
     this.loadUserData();  
 
/** if the incoming URL is not equal to the cookie value then clear off everything related to user and show 
them the fresh login page for the given organization**/
 if(redirectUrl!=this.loginPortalName){
    // this.clearUserData();
    this.router.navigate([redirectUrl]);

 }
 
   
//if the login portal name is not found in cookie then set it to the incoming url
  if(!this.commonUtils.checkNullOrUndefined(this.loginPortalName)){
                this.loginPortalName=redirectUrl;
         } 
 
    if (!this.commonUtils.checkNullOrUndefined(this.loggedInUserObj)) {
         //control will come inside if login object is  null in above line
       //if login object is not null then no problem. continue with the code in each ts file
    	if (!this.commonUtils.checkNullOrUndefined(this.loginUserId)) {
       		if(this.commonUtils.checkNullOrUndefined(redirectUrl) && (redirectUrl.indexOf('PORTAL') != -1 && 
            redirectUrl.indexOf('UN') != -1 && redirectUrl.indexOf('PASS') != -1)){
             this.queryParamBasedLogin(redirectUrl);
          }
          else{
          	this.router.navigate([redirectUrl]);
          }
      }  
      else if(this.commonUtils.checkNullOrUndefined(redirectUrl) && (redirectUrl.indexOf('PORTAL') != -1 && 
            redirectUrl.indexOf('UN') != -1 && redirectUrl.indexOf('PASS') != -1)){
             this.queryParamBasedLogin(redirectUrl);
      }
      
      //if the user id is not null then fetch the login object and continue
      else {
          const myForm = new FormGroup
          ({
            userName: new FormControl(''),
            password: new FormControl(''),
            userId: new FormControl(this.loginUserId) ,
            userIdentityToken:new FormControl(this.userIdentityToken),
             t000Organization:new FormGroup({
              organizationId:new FormControl(),
                portalName:new FormControl(this.loginPortalName),
            })
          });


        this.checkForLoginUser(myForm.value)
          .subscribe(
          data => {
           
            
            if (data != null) {
                this.loggedInUserObj = data;

               this.formMenuMap();
               this.formCreateTemplateSettingsMap(data);
              this.afterLoginSuccess(false,redirectUrl);
              
            }

          }, error => {
            this.hideProgressBar();
            this.showERPErrorMessage(error);

          });
        }
    }
  }
  getIpFromMethod(): Promise<any>{
    return new Promise(resolve=>{
     this.getIpClientIp().subscribe(
                   data=>{
                     this.loggedInUserIp=data['ip'];
                     
                   });
   });
  }
queryParamBasedLogin(redirectUrl): Promise<any>{
  let urlParams = redirectUrl.split('&');
     let portalStr = [];
             let userNameStr = [];
             let passWordStr = [];
             let ipStr = [];
 return new Promise(resolve=>{
              
              if(urlParams != null && urlParams.length > 0){
        
             if( redirectUrl.indexOf('IP') != -1){
                 ipStr = urlParams[3].split('=');
                 this.loggedInUserIp = ipStr[1];
              }else{
                  
                   let promise = this.getIpFromMethod();
                   promise.then(data=>{
                      
                   })
             }
          if(urlParams[0].indexOf('=') != -1 && urlParams[1].indexOf('=') != -1 && urlParams[2].indexOf('=') != -1 ){
              portalStr = urlParams[0].split('=');
              userNameStr = urlParams[1].split('=');
              passWordStr = urlParams[2].split('=');
             // ipStr = urlParams[3].split('=');
            }
            else if(urlParams[0].indexOf('%3D') != -1 && urlParams[1].indexOf('%3D') != -1 && urlParams[2].indexOf('%3D') != -1){
               portalStr = urlParams[0].split('%3D');
              userNameStr = urlParams[1].split('%3D');
              passWordStr = urlParams[2].split('%3D');
               // ipStr = urlParams[3].split('%3D');
            }
          
            if(portalStr != null && portalStr.length > 0 && userNameStr != null && userNameStr.length > 0
             && passWordStr != null && passWordStr.length > 0){
                 const myForm = new FormGroup
                  ({
                  userName: new FormControl(userNameStr[1]),
                  password: new FormControl(passWordStr[1]),
                   t000Organization:new FormGroup({
                    organizationId:new FormControl(),
                      portalName:new FormControl(portalStr[1]),
                  }),
              //  userIpaddress: new FormControl('210.212.229.234'),
                userIpaddress: new FormControl(this.loggedInUserIp)
                });
                  
               this.checkForLoginUser(myForm.value).subscribe(
                data => {
                  
                  if (this.commonUtils.checkNullOrUndefined(data)){
                      this.loggedInUserObj = data;

                      
                      this.formMenuMap();
                      this.hideProgressBar();
                      this.formCreateTemplateSettingsMap(data);
                      this.afterLoginSuccess(false,redirectUrl);
                    }
                  },
                    error => {
                       this.hideProgressBar();
                      this.showERPErrorMessage(error);
                    });
              }
            }
            resolve(redirectUrl); 
    });
}
t002MenuSet;
formMenuMap(){ 
 if(this.commonUtils.checkNullOrUndefined(this.loggedInUserObj) &&
          this.commonUtils.checkNullOrUndefined(this.loggedInUserObj.t029Roles)){
          this.t002MenuSet = this.loggedInUserObj.t029Roles.t002MenuSet; 
           
          this.commonUtils.formMenuMap(this.t002MenuSet);  

         
          
        }
}


    formCreateTemplateSettingsMap(data:any){
     let t051CreateTemplateSettingsArray:Array<any>=data.t000Organization.t051CreateTemplateSettingsList as Array<any>;
   if(this.commonUtils.checkNullOrUndefined(t051CreateTemplateSettingsArray))
   {
    for(var loop=0;loop<t051CreateTemplateSettingsArray.length;loop++)
    {
      this.createTemplateSettingsMap.set(t051CreateTemplateSettingsArray[loop].moduleName,t051CreateTemplateSettingsArray[loop]);
    } 
   } 
  }
  authObject:any={};
  public async  afterLoginSuccess(navigateToDefaultUrl:boolean,navigateToUrl?:string){      
    await this.setUserData();
     this.loggesInUserObjSubject.next('LOGIN_AVAILABLE');
      if(navigateToDefaultUrl)
         this.router.navigate([this.loggedInUserObj.t029Roles.defaultPage]);
     this.hideProgressBar();
     this.addMenuList(this.loggedInUserObj.t029Roles.defaultPage);
  }


getToken() :Promise<any> {
 return new Promise(resolve=>{ 
    const headers = new Headers();
    let token;

    const body = JSON.stringify({
       grant_type:"client_credentials"
    });
    const options = {
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa('9999:Erp@123'),
      })
    };
 
    this.httpObj.post(this.SERVICE_URL + 'oauth/token?grant_type=client_credentials', body,
      options)
      .pipe(map(data => data.json())).subscribe(
      data => {
     
        token = data;
       
        resolve(data);
        
      },
      error => {
        // console.log(" Error in get Token ",error);
      });

   // return token;

  }
  );

  }
  addMenuList(menuUrl):void
  {
    
    if(this.currentMenuIndex != this.menuHistory.length-1){
     for(var loop=this.menuHistory.length-1; loop>this.currentMenuIndex; loop--){
        this.menuHistory.splice(loop, 1);
      }
    }
    if((menuUrl != this.menuHistory[this.menuHistory.length-1]))
    {
      this.menuHistory.push(menuUrl);      
    }

    if(this.menuHistory.length > 0)
    {
      this.currentMenuIndex = this.menuHistory.length-1;      
    }
  }

  getSelectedMenu(moduleName:string,data:any){    
    let t030AccessSetArray:Array<any>=data.t029Roles.t030AccessSet as Array<any>;
    for(var loop=0;loop<t030AccessSetArray.length;loop++){
      let actualAccessArray:Array<any>=t030AccessSetArray[loop].t030DisplayAccess as Array<any>;
      if(actualAccessArray!=null){
       for(var innerloop=0;innerloop<actualAccessArray.length;innerloop++){
           if(actualAccessArray[innerloop].t002Menu.moduleName!='undefined' &&
           actualAccessArray[innerloop].t002Menu.moduleName!=null &&
            actualAccessArray[innerloop].t002Menu.moduleName.indexOf(moduleName)!=-1){             
              this.currentlySelectedMenu=actualAccessArray[innerloop];
              break;
            }          
        }
      }
    }
 
      this.loggesInUserObjSubject.next('LOGIN_AVAILABLE');
  }
   

public downloadGeneratedReport(fileName:string,moduleName: string):void
{
  if(fileName!='No Records Found'){
    if(fileName.trim().indexOf('.csv') != -1 ){
    window.open(this.SERVICE_URL + 'erpassets/'+this.loggedInUserObj.t000Organization.portalName.toUpperCase() +'/'+ moduleName +'/Export_Data/'+ fileName+'?'+new Date(), '_blank');
    }
    else if(fileName.trim().indexOf('.xls') != -1 || fileName.indexOf('.xlsx') != -1){
    window.open(this.SERVICE_URL + 'erpassets/'+this.loggedInUserObj.t000Organization.portalName.toUpperCase() +'/'+ moduleName +'/Export_Data/'+ fileName+'?'+new Date(), '_blank');
    }
    else if(fileName.trim().indexOf('.txt') != -1){
      window.open(this.SERVICE_URL+'admin/viewTextInBrowser?fileName='+fileName+'&moduleName='+moduleName+'&authCode='+
        this.loggedInUserObj.t000Organization.portalName.toUpperCase(), "_blank");
    }
  
  }
  else
    this.showERPMessage(fileName);
}

 saveErrorMessage(errorObj) :Observable<any>
  {
    return new Observable();
  }
 
 public cleanUpListener(subscriptionList:Array<Subscription>){
       for (let loop = 0; loop < subscriptionList.length; loop++) {
        subscriptionList[loop].unsubscribe();
      }
 }
   saveOrUpdateTemplate(templateObj) {
       return this.http.post(this.SERVICE_URL + 'settings/saveTemplate', templateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 saveOrUpdateReportTemplate(reportTemplateObj) :Observable<any>{
     
    return this.http.post(this.SERVICE_URL + 'settings/saveReportTemplate', reportTemplateObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

deleteDatabaseScript(databaseScript) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/deleteDataBaseScript', databaseScript)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  setTitle(titleService:any,titleName) {
    titleService.setTitle(titleName);
  }

  checkNaN(element: any): number {
    element = isNaN(element) ? 0 : +element;
    return element;
  }

  accessObjForModule;
  getAccessObjForCurrentScreen(moduleName): any{
    this.accessObjForModule = this.commonUtils.returnObjForMenuIfItHasAccess(moduleName);  
    if( !this.commonUtils.checkNullOrUndefined(this.accessObjForModule) || !this.accessObjForModule.viewAccess ){  
      let error='You are not authorized to view this page. Contact Admin';     
      this.showAdminERPErrorMessage(error);
    }
    return this.accessObjForModule;
  }

emitInvalidLogin(){
    this.loggesInUserObjSubject.next('INVALID_LOGIN_ATTEMPT');   
}

saveOrUpdateLookup(lookUpList) :Observable<any>{

    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateLookup', lookUpList)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  validationList=[];
  fetchAndSetValidationData(moduleName:string,inputFormForValidation:FormGroup):Promise<any>{  
    let validationFormGroup=new FormGroup({
      moduleName: new FormControl('')
    });

    validationFormGroup.get('moduleName').setValue(moduleName);
   return new Promise(resolve=>
     this.fetchValidations(validationFormGroup.value).subscribe(
      data => {
        this.validationList = data;
         this.commonUtils.setValidationForPage(inputFormForValidation,data);
            resolve(data);   
      },
      error => {
        this.hideProgressBar();
        this.showERPErrorMessage(error);
      }));
  }

 saveIssuesOrFeedback(issueOrFeedbackObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateIssuesOrFeedback', issueOrFeedbackObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

downloadLabel(upsForm):Observable<any>
{
   return this.http.post(this.SERVICE_URL + 'admin/downloadLabel',  upsForm,{responseType:'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
}

fetchProjectStatus():Observable<any>{

    return this.http.get(this.SERVICE_URL + 'hrm/fetchProjectStatus')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  addPushSubscriber(sub:any) {
        return this.http.post('/api/notifications', sub);
    }

    fetchIssues(issuesObj):Observable<any>{
 return this.http.post(this.SERVICE_URL + 'admin/fetchIssuesOrFeedback', issuesObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  downloadImage(url): any {
    return this.http.get(url, { responseType: 'blob' as 'json' }).pipe(map(
    (res:any) => {
            return new Blob([res])
    })) 
  }
saveOrUpdateLicense(licenseObj):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateLicense', licenseObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchLicense(licenseObj):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'admin/fetchLicense', licenseObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*method for bulk action of issue starts - 9072*/
  bulkUpdateIssue(issueArray):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'admin/bulkUpdateActionIssue', issueArray,{responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }
  /*method for bulk action of issue starts - 9072*/
  /*fetch method email already exists starts -  9072*/
  fetchLicenseEmailAlreadyExists(licenseObj) :Observable<any>{
     
   return this.http.post(this.SERVICE_URL + 'admin/fetchLicenseEmailAlreadyExists', licenseObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
    /*fetch method email already exists ends -  9072*/

getIpClientIp(): Observable<string> {
      return this.http.get(environment.IP_FETCH_URL) // ...using post request '
      .pipe(map(data => {return data}),
    catchError(this.handleError)); // ...and calling .json() on the response to return data
      //.catch((error:any) => Observable.throw(error.json().error || 'Server error')); //...errors if any
  }
  
   generatePSPDF(pdfArrays):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/generatePSPDF', pdfArrays, {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }


  fetchNotificationCount(reminderObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/getReminderCount', reminderObj, {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  bindOldChangesInModule(moduleName, formValues){
      this.recordOldChangesForEachModuleMap.set(moduleName,formValues);
  }

  getStorage():Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/getStorage',  {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  saveOrUpdateEmailConfiguration(emailConfigurationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateEmailConfiguration', emailConfigurationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
  fetchEmailConfiguration(emailConfigurationObj) :Observable<any> {    
    return this.http.post(this.SERVICE_URL + 'admin/fetchEmailConfiguration', emailConfigurationObj)
     .pipe(map(data => data),
     catchError(this.handleError));
  }

  saveOrUpdateUserPassword(ChangePasswordObj) :Observable<any> {    
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateUserPassword', ChangePasswordObj)
     .pipe(map(data => data),
     catchError(this.handleError));
  }


   fetchReminderToShowNotification(reminderObj) :Observable<any> {    
    return this.http.post(this.SERVICE_URL + 'admin/fetchReminderToShowNotification', reminderObj)
     .pipe(map(data => data),
     catchError(this.handleError));
  }
  saveOrUpdateNotification(reminderObj) :Observable<any> {    
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateNotification', reminderObj)
     .pipe(map(data => data),
     catchError(this.handleError));
  }

  convertToTask(toTaskObj):Observable<any>
 {
  return this.http.post(this.SERVICE_URL + 'admin/convertToTask', toTaskObj )
      .pipe(map(data => data)
      ,catchError(this.handleError)); 
 }

  fetchUserNameAlreadyExists(userObj) :Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/fetchUserNameAlreadyExists', userObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
    /*fetch method username already exists ends -  9072*/
triggerBatchSyncLog(triggerObj) :Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/triggerBatchSyncLog', triggerObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  sendEmailFromReviewScreen(resultObj) :Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/sendEmailFromReviewScreen', resultObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
 sendAutomatedEmail(resultObj) :Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/sendAutomatedEmail', resultObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  fetchAutomatedEmailDetail(automatedEmailObj) :Observable<any>{
    
    return this.http.post(this.SERVICE_URL + 'admin/fetchAutomatedEmailDetails', automatedEmailObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  saveOrUpdateAutomatedEmailDetails(automatedEmailObj) :Observable<any>{
    
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateAutomatedEmailDetails', automatedEmailObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
 deleteAutomatedEmail(automatedEmailObj) :Observable<any>{
 
     return this.http.post(this.SERVICE_URL + 'admin/deleteAutomatedEmailDetails', automatedEmailObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
fetchDataBase(databaseObj):Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/fetchDataBase', databaseObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  fetchERPPropertiesMap():Observable<any>{
     return this.http.get(this.SERVICE_URL + 'admin/fetchERPProperties',{responseType:'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
}
  saveOrUpdateDatabaseScript(databaseObj):Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateDataBaseScript', databaseObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
executeQuery(databaseObj):Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/executeQuery', databaseObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  fetchTCLinkMaster(tclinkmasterObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchTCLinkMaster', tclinkmasterObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateTestCaseLinkMaster(tclinkmasterObj):Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateTestCaseLinkMaster', tclinkmasterObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  saveOrUpdateUserDisplayColumns(ColumnObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateUserDisplayColumns', ColumnObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAllUserDisplayColumn(moduleName) :Observable<any>
  {
    
    return this.http.post(this.SERVICE_URL + 'admin/fetchAllUserDisplayColumn',moduleName)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAppScreenDisplayValues(appScreenObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'admin/fetchAppScreenDisplayValues',appScreenObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  executeTestCaseLink(endPointUrl,testCaseObj):Observable<any>{
      return this.http.post(this.SERVICE_URL +endPointUrl,testCaseObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
    }
    fetchSuperadminMenu(menuObj):Observable<any>{
   return this.http.post(this.SERVICE_URL + 'admin/fetchSuperadminMenu', menuObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  saveOrUpdateSuperadminMenu(superadminMenuObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateSuperadminMenu', superadminMenuObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateBatchSyncLogForQB(CommonObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateBatchSyncLogForQB', CommonObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateValidation(validationObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateValidation', validationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   saveOrUpdateReviewDisplayColumn(reviewDisplayObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateReviewDisplayColumn', reviewDisplayObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEmailSettingsConfiguration(emailConfigurationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchEmailSettingsConfiguration', emailConfigurationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateEmailSettingsConfiguration(emailConfigurationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/saveOrUpdateEmailSettingsConfiguration', emailConfigurationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 testEmailFromEmailSettings(resultObj) :Observable<any>{
      
   return this.http.post(this.SERVICE_URL + 'admin/testEmailFromEmailSettings', resultObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  downloadExportDataAsset(dataObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'report/assetsDownloadReport', dataObj,{responseType: 'text'})
     .pipe(map(data => data.valueOf())
     ,catchError(this.handleError));
  }
  getRedirectLink(moduleName: string, editId?: number): string {
  
  if(this.appScreenMap.has(moduleName))
    {
      return this.appScreenMap.get(moduleName).redirectLink;
    }
  }
  getNumberToFixedDecimal(inputStr:string,type:string):string{
     let fixedNumber:Number=Number(inputStr); 
     let fixedNumberStr;
     switch (type) {
       case "QUANTITY":
            fixedNumberStr=fixedNumber.toFixed(this.loggedInUserObj.t000Organization.t036_General_Settings.numberOfDecimals);
  
         break;
       
       default:
         // code...
         break;
     }
  return fixedNumberStr

  }
     fetchProjectHours(commonObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchProjectHours', commonObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchSearchHeader(searchObj) :Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'admin/fetchSearchHeader', searchObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchVersionNumberFromData(commonObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchVersionNumberFromDatabase', commonObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  createTicket(toTaskObj):Observable<any>
 {
  return this.http.post(this.SERVICE_URL + 'admin/createTicket', toTaskObj )
      .pipe(map(data => data)
      ,catchError(this.handleError)); 
 }
  
   fetchEstimatedHoursForTimesheet(estHoursObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'admin/fetchEstimatedHoursForTimesheet', estHoursObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  loadAppscreenMapAfterReload(moduleName):Promise<any>{
     let displayForm = new FormGroup
             ({ 
                 moduleName: new FormControl(''),

             })
             displayForm.get('moduleName').setValue(moduleName);
         return new Promise(resolve=>
             this.fetchAllReviewDisplayColumn(displayForm.value).subscribe(
             data => 
              {                    
                if (data.length > 0)
                 {                                                    
                   for(var loop=0;loop<data.length;loop++){
                     if(this.commonUtils.checkNullOrUndefined(data[loop].dispValue)){
                       this.appScreenList.push(data[loop]);
                       this.appScreenMap.set(data[loop].moduleName,data[loop]);
                     }
                    }
                }  
            resolve(data);

      }));
}
     fetchDashboardDatas() :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchDashboardDatas',null)
    .pipe(map(data => data),
    catchError(this.handleError));
  }


  fetchSingleWareHouseLocation() :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/fetchSingleWareHouseLocation', null)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchEmployeeHierarchy(employeeObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'hrm/fetchEmployeeHierarchy',employeeObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  fetchTaskHierarchy(taskObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'hrm/fetchTaskHierarchy',taskObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  public doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();     
    if (xhr.status === 404) {
        return false;
    } else {
        return true;
    }
  }

  addReminderDetails(t037ReminderObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'admin/addReminderDetails', t037ReminderObj)
    .pipe(map(data => data)
    ,catchError(this.handleError));
  }
  fetchAddressFromLatLong(addressObj):Observable<any> {
    return this.httpObj.get(this.googleApiUrl + addressObj.cordinates + '&key=' + this.googleApiKey)
        .pipe(map(data => data.json())
        ,catchError(this.handleError));     

 }
 //get&set userDataCustom added by Ajith[9121] implementing JWT authentication on 18, OCT 2022
getUserDataCustom(inputKey){
  return this.cookieService.get(inputKey);  
}
setUserDataCustom(inputKey,inputValue){
this.cookieService.set(inputKey,  inputValue,new Date('31-12-2071 23:59:00'),'/','',false,'Lax');  
}
 
 fetchEmployeeTrainingHeader(employeeFormGroup): Observable<any> {
  return this.http.post(this.SERVICE_URL + 'training/fetchEmployeeTrainingHeader', employeeFormGroup)
    .pipe(map(data => data)
      , catchError(this.handleError));
}
fetchTechnology(techId): Observable<any> {
  return this.http.post(this.SERVICE_URL + 'training/fetchTechnologies',techId)
    .pipe(map(data => data)
      , catchError(this.handleError));
}
fetchSkills(techId): Observable<any> {
  return this.http.post(this.SERVICE_URL + 'training/fetchSkills',techId)
    .pipe(map(data => data)
      , catchError(this.handleError));

}
saveTrainingDetails(value): Observable<any> {
return this.http.post(this.SERVICE_URL + 'training/insertTaskDetails', value)
  .pipe(map(data => data)
    , catchError(this.handleError));
}
saveOrUpdateFeedback(value: any): Observable<any> {
return this.http.post(this.SERVICE_URL + 'training/saveOrUpdateFeedback', value)
  .pipe(map(data => data)
    , catchError(this.handleError));
}
fetchHeaderData(value): Observable<any> {
return this.http.post(this.SERVICE_URL + 'training/fetchHeaderData', value)
  .pipe(map(data => data)
    , catchError(this.handleError));
}

fetchrFromTrainingTaskDetailsTable(headerId): Observable<any> {
return this.http.post(this.SERVICE_URL + 'training/fetchFromTaskDetailsTable', headerId)
  .pipe(map(data => data)
    , catchError(this.handleError));
}

fetchEmployeeDetailsForTrainingReport(employeeObject):Observable<any>{
return this.http.post(this.SERVICE_URL + 'training/fetchEmployeeDetailForHeader', employeeObject)
  .pipe(map(data => data)
    , catchError(this.handleError));
}
fetchHeaderDataBasedOnManager(value){
  return this.http.post(this.SERVICE_URL + 'training/fetchTraineeFeedbackBasedOnManger', value)
  .pipe(map(data => data)
    , catchError(this.handleError));
  }
}
