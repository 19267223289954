import { Component, OnInit,ViewChild, Inject, Input,Injectable,NgZone} from '@angular/core';
import { MatDialog,MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MatSort} from '@angular/material/sort';
import {CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {Router} from '@angular/router';
import { CommonModule } from '@angular/common'; 
import { PageEvent } from '@angular/material/paginator'; 
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-viewmap',
  templateUrl: './viewmap.component.html'
})
export class ViewMapComponent  extends CommonUtils implements OnInit {

  lat;
  lng;
  id_value;
  addressObj;
  addressFormObj : FormGroup;
  address;
  displayaddress;
  line1Edited;
  line2Edited;
  line1="";
  line2 ="";
  line3="";
  line4="";
  city;
  state;
  country;
  zip;
  county;
  locationCoordinates;
  addressComponentArray;
  currentAddress;
  dialogResult:any=[];
    private geoCoder;
  zoom: number;
  constructor( public dialog: MatDialog,public commonService: CommonService,
 private commonUtils: CommonUtils,private router: Router,private formBuilder: FormBuilder,
 public dialogRef: MatDialogRef<ViewMapComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
 private zone: NgZone, private mapsAPILoader: MapsAPILoader) { 
   super();
  this.addressFormObj = new FormGroup({
    line1 : new FormControl("",{
      updateOn: "change",
      validators: [Validators.maxLength(200)],
    }),
    line2 : new FormControl("",{
      updateOn: "change",
      validators: [Validators.maxLength(200)],
    }),
    city : new FormControl(""),
    state :new FormControl(""),
    zip : new FormControl(""),
    country :new FormControl(""),
    county : new FormControl(""),
    address : new FormControl(""),
    cordinates : new FormControl("")
  })
this.addressObj=this.data.address;
  }
  ngOnInit(){
    // console.log('map',this.addressObj)
     //this.geoCoder = new google.maps.Geocoder;
    if (this.commonUtils.checkNullOrUndefined(this.addressObj))
    this.id_value = this.addressObj.employeeId;
    if (this.id_value != 0){
      if(this.commonUtils.checkNullOrUndefined(this.addressObj.locCord)){
        var splitString = (this.addressObj.locCord).split("/");
        this.lat = parseFloat(splitString[0]);
        this.lng = parseFloat(splitString[1]);
        // console.log(this.lat,this.lng)
        this.addressFormObj.get('cordinates').setValue(splitString[0]+","+splitString[1]);
         this.fetchAddress();
      }else{
        this.setCurrentLocation();
      }
    } else if (this.id_value == 0 ){
      this.setCurrentLocation();
    }
  }
  
  locationSelected(event) {
// console.log(event);
//this.lat = 
  }

  
  map;
  mapClickListener;

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        // Here we can get correct event   34.858462961578745/-92.17115331441165
        // console.log(e.latLng.lat(), e.latLng.lng());
        this.lat = e.latLng.lat();
        this.lng =  e.latLng.lng();
        this.addressFormObj.reset();
        this.addressFormObj.get('cordinates').setValue(this.lat + "," + this.lng);
        this.fetchAddress();
      });
    });
  }
  
  patchvalue(data){
              this.line3="";
              this.line1="";
              this.line2="";
              this.currentAddress = data.results[0].formatted_address;
              this.addressFormObj.get('address').setValue(this.currentAddress); 
              this.addressFormObj.get('cordinates').setValue(this.lat+"/"+this.lng);
              this.addressComponentArray = data.results[0].address_components;
              for (var loop =0; loop < this.addressComponentArray.length; loop++){
                if (this.addressComponentArray[loop].types[0] == "street_number"){
                  this.line1 =this.addressComponentArray[loop].short_name;
                }
                if (this.addressComponentArray[loop].types[0] == "route"){
                  this.line2 =this.addressComponentArray[loop].short_name;
                }
                if (this.addressComponentArray[loop].types[0] == "political"){

                  if(this.commonUtils.checkNullOrUndefined( this.line3)){
                    this.line3 =this.line3 + ","+this.addressComponentArray[loop].short_name;
                  }else{
                    this.line3 =this.addressComponentArray[loop].short_name;
                  }                  
                }
                if (this.addressComponentArray[loop].types[0] == "locality"){
                  this.addressFormObj.get('city').setValue(this.addressComponentArray[loop].short_name);
                  this.city = this.addressComponentArray[loop].short_name;
                }
                if (this.addressComponentArray[loop].types[0] == "administrative_area_level_1"){
                  this.addressFormObj.get('state').setValue(this.addressComponentArray[loop].long_name);
                  this.state = this.addressComponentArray[loop].long_name;
                }
                if (this.addressComponentArray[loop].types[0] == "administrative_area_level_2"){
                  this.addressFormObj.get('county').setValue(this.addressComponentArray[loop].long_name);
                }
                if (this.addressComponentArray[loop].types[0] == "country"){
                  this.addressFormObj.get('country').setValue(this.addressComponentArray[loop].long_name);
                  this.country = this.addressComponentArray[loop].long_name;
                }
                if (this.addressComponentArray[loop].types[0] == "postal_code"){
                  this.addressFormObj.get('zip').setValue(this.addressComponentArray[loop].short_name);
                  this.zip = this.addressComponentArray[loop].short_name;
                }
              }
              if(this.commonUtils.checkNullOrUndefined(this.line1)){
                this.addressFormObj.get('line1').setValue(this.line1);
              }else{
                this.addressFormObj.get('line1').setValue('');
              }
              if(this.commonUtils.checkNullOrUndefined(this.line2)){
                this.addressFormObj.get('line1').setValue(this.addressFormObj.get('line1').value+" "+this.line2);
              }else{
                this.addressFormObj.get('line1').setValue('');
              }
             if(this.commonUtils.checkNullOrUndefined(this.line3)){
                this.addressFormObj.get('line1').setValue(this.addressFormObj.get('line1').value +" "+this.line3);
              }else{
                this.addressFormObj.get('line1').setValue('');
              }
              
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  close() {
  	this.dialogRef.close(true);
    this.dialogResult.done=false;
    this.dialogRef.close(this.dialogResult);
  }

  cancelClick(): void {
    this.dialogResult.done=false;
    this.dialogRef.close(this.dialogResult);
    }

    saveAddress(){
      if (this.commonUtils.checkNullOrUndefined(this.addressFormObj.get('line2').value))
      this.line2 = this.addressFormObj.get('line2').value;
      else
      this.line2 = "";
      this.addressFormObj.get('address').setValue(this.currentAddress);   
      this.dialogResult.done=true;
      this.dialogResult.result=this.addressFormObj.value;
      this.dialogRef.close(this.dialogResult);
    }

    private setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
//          console.log(this.lat,this.lng);
          this.addressFormObj.get('cordinates').setValue(this.lat + "," + this.lng);
          this.fetchAddress();
          this.zoom = 8;
        });
      }
    }
    
    fetchAddress(){
      this.commonService.fetchAddressFromLatLong(this.addressFormObj.value).subscribe(
     data => {
       if(this.commonUtils.checkNullOrUndefined(data)  &&( data.status === 'OK' || data.statusText === 'OK')){
         this.patchvalue(data);
        this.zoom = 12;
       }
     },error=>{
      this.commonService.showERPErrorMessage(error);
    });
   }

}