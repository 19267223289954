<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  >
<div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12"  >
  <div class="row col-lg-12">
    <div class="col-lg-4 nopadding">
        <label class="labelClass">{{dialogLabel}} : </label>
      </div>
      <div class="col-lg-8">
        <input #myInput *ngIf="uploadedFileArray.length<5 && hideDeleteIcon && fileUploadType !='' " type="file"   color="primary" (change)="onFileChange($event)">
         <input #myInput1 *ngIf="fileUploadType != 'SINGLE' && fileUploadType != 'MULTIPLE' && uploadedFileArray.length<5 && !hideDeleteIcon" type="file"   color="primary" (change)="onFileChange($event)">
          <input #myInput2 *ngIf="fileUploadType === 'SINGLE' && uploadedFileArray.length < 1 && !hideDeleteIcon" type="file" color="primary" (change)="onFileChange($event)">
          <input #myInput3 *ngIf="fileUploadType === 'MULTIPLE' && uploadedFileArray.length < 5 && !hideDeleteIcon" type="file" color="primary" (change)="onFileChange($event)">
       </div>
</div>

         <div *ngFor="let item of uploadedFileArray; index as idx" class="row col-lg-12 nopadding marginTop2">

          <div class="col-lg-2">
              <div style="width:50px;height:50px">  
                 <img (error)="errorHandler($event)"   *ngIf="item.addedNewly === false && (item.name.indexOf('.png')!=-1 || item.name.indexOf('.PNG')!=-1 || item.name.indexOf('.JPG')!=-1 || item.name.indexOf('.jpg')!=-1 || item.name.indexOf('.png')!=-1 || item.name.indexOf('.JPEG')!=-1 || item.name.indexOf('.GIF')!=-1 || item.name.indexOf('.JFIF')!=-1 || item.name.indexOf('.jpeg')!=-1 || item.name.indexOf('.gif')!=-1 || item.name.indexOf('.jfif')!=-1) " src="{{imagePath}}/{{item.name}}" class="myImg" style=" border: 1px solid;margin-right: 3px;width: 100%; height: 100%;" alt="">  
                <img (error)="errorHandler($event)"   *ngIf="(item.addedNewly === true || item.addedNewly === 'undefined') && (item.name.indexOf('.png')!=-1 || item.name.indexOf('.PNG')!=-1 || item.name.indexOf('.JPG')!=-1 || item.name.indexOf('.jpg')!=-1 || item.name.indexOf('.png')!=-1 || item.name.indexOf('.JPEG')!=-1 || item.name.indexOf('.GIF')!=-1 || item.name.indexOf('.JFIF')!=-1 || item.name.indexOf('.jpeg')!=-1 || item.name.indexOf('.gif')!=-1 || item.name.indexOf('.jfif')!=-1)  " src="{{tempFolderPath}}{{item.name}}" class="myImg" style=" border: 1px solid;margin-right: 3px;width: 100%; height: 100%;" alt="">           
                <img  *ngIf="item.name.indexOf('.zip')!=-1 || item.name.indexOf('.ZIP')!=-1" src="assets/zip_icon.png" class="myImg" style="  margin-right: 3px;width: 100%; height: 100%;" alt="">
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.pdf')!=-1 || item.name.indexOf('.PDF')!=-1" src="assets/pdf_icon.png" class="myImg" style=" margin-right: 3px;width: 100%; height: 100%;" alt="">  
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.csv')!=-1 || item.name.indexOf('.xlsx')!=-1  || item.name.indexOf('.xls')!=-1" src="assets/excel_icon.png" class="myImg" style=" margin-right: 3px;width: 100%; height: 100%;" alt="">        
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.doc')!=-1 || item.name.indexOf('.DOC')!=-1 || item.name.indexOf('.docx')!=-1 || item.name.indexOf('.DOCX')!=-1 || item.name.indexOf('.odt')!=-1 || item.name.indexOf('.ODT')!=-1" src="assets/doc_icon.png" class="myImg" style="  margin-right: 3px;width: 100%; height: 100%;" alt="">
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.otf')!=-1 || item.name.indexOf('.OTF')!=-1" src="assets/font_icon.png" class="myImg" style=" margin-right: 3px;width: 100%; height: 100%;" alt="">
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.ai')!=-1 || item.name.indexOf('.AI')!=-1 " src="assets/ai_icon.png" class="myImg" style="  margin-right: 3px;width: 100%; height: 100%;" alt="">
                <img (error)="errorHandler($event)"  *ngIf="item.name.indexOf('.ppt')!=-1 || item.name.indexOf('.PPT')!=-1  || item.name.indexOf('.pptx')!=-1   || item.name.indexOf('.PPTX')!=-1 || item.name.indexOf('.pptm')!=-1  || item.name.indexOf('.PPTM')!=-1"  src="assets/ppt_icon.png" class="myImg" style=" margin-right: 3px;width: 100%; height: 100%;" alt="">  
              </div>
            </div>

           <div class="col-lg-8 " style="padding-top: 10px;word-break: break-word">{{item.name}}</div>

          <div class="col-lg-1 mat_icon_color" *ngIf="!hideDeleteIcon">
            <button mat-icon-button (click)="removeFile(idx)"><mat-icon>delete</mat-icon></button>
          </div>
           <div class="col-lg-1  mat_icon_color" (click)="downloadFile(item.name)" *ngIf="item.addedNewly !=true" style="padding-top: 10px;"><mat-icon>file_download</mat-icon></div>
         </div>
         <mat-error *ngIf="errorFlag">{{errorMsg}}</mat-error> 
        <mat-hint *ngIf="uploadedFileArray.length>=5" style="color:crimson">You have reached maximum of 5 files</mat-hint>
        <mat-hint *ngIf="fileUploadType === 'SINGLE' && uploadedFileArray.length > 1" style="color:crimson">Only one file are allowed for this Upload. Remove the old file and try again.</mat-hint>
            <div class="row col-lg-12 paddingTop30px" >
       <button mat-raised-button type="button"  color="primary" (click)="doneClick()">{{DONE_LABEL}}</button> 
       
         
        <button mat-raised-button type="button" color="primary" (click)="cancelClick()">{{DISCARD_LABEL}}</button>
      </div>

    </div>
  </mat-card>