import { Component, OnInit, Inject,Input,HostListener,ViewChild,ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {FormGroup, FormControl, FormBuilder, NgForm, Validators} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {CommonService} from '../shared/common.service';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import {HrmService} from '../hrm/hrm-service';
import {SettingsService} from '../settings/settings-service';
import {CommonUtils} from '../core/common-utils';
import {CustomValidators} from '../customValidationForEmailPopup/customValidation.component';
import { RecruitmentService } from '../recruitment/recruitment-service';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {switchMap, debounceTime, map,distinctUntilChanged,} from 'rxjs/operators';
import {Subscription, Observable} from 'rxjs/Rx';
import { TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem, TreeItem,DropdownDirective, DropdownToggleDirective } from 'ngx-treeview';
  import { isNil, remove, reverse } from 'lodash';
//import { NgxEditorModule } from 'ngx-editor';

@Component({
  selector: 'app-dialogBox',
  templateUrl: './dialogBox.component.html',
   providers: []
})

export class DialogOverviewExampleComponent implements OnInit {

	dialogTextToDisplay: string;
	confTextToDisplay; estimateStatus: string;
  contactsArray = [];
  userStatus; roleUser: string;
  deleteContactsCancel; statusEstimateCancel; deleteUserCancel; deleteRoleCancel = false;

  contactsInactiveArray = [];
  contactsDeleteArray = [];
  itemsArray = [];
  inactiveItemsArray = [];
  deleteItemsArray = [];
  estimateArray = [];
  userDeleteArray = [];
  roleObj: any;

	constructor(public snackBar: MatSnackBar,
    private router: Router, public dialog: MatDialog,private hrmService: HrmService,
	  public settingsService: SettingsService, public dialogRef: MatDialogRef<DialogOverviewExampleComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService,private commonUtils: CommonUtils, 
    private recruitService: RecruitmentService) {
      this.dialogTextToDisplay = data.dialogText;
	    this.confTextToDisplay = data.confText;
      this.roleUser = data.status;
      this.roleObj = data.roleObj;
	}
  	ngOnInit() {}

  onNoClick(): void {
    if (this.roleUser === 'delete') {
        this.deleteRoleCancel = false;
        this.dialogRef.close(this.deleteRoleCancel);
    } 
    else if(this.confTextToDisplay === 'ConfirmBaselineTaskDate'){
      this.dialogRef.close();
    }
    else if(this.confTextToDisplay === 'ConfirmShiftDate'){
      this.dialogRef.close();
    }
    else {
       this.dialogRef.close();
    }
  }

	onOKClick(): void {
     if (this.roleUser === 'role') {
        this.commonService.showProgressBar();
        this.deleteRoleCancel = true;
        this.settingsService.deleteRoles(this.roleObj).subscribe();
        this.commonService.hideProgressBar();
        this.dialogRef.close(this.deleteRoleCancel);
    }
    
    if(this.confTextToDisplay === 'deleteTask'){
        if(this.commonUtils.checkNullOrUndefined(this.roleObj)){
         this.hrmService.deleteTask(this.roleObj).subscribe(
          data => {
            
             this.commonService.hideProgressBar();
             this.commonService.showERPMessage(data.erpmessage);
             if(this.commonUtils.checkNullOrUndefined(data)){
               this.dialogRef.close(true);
             }
          },
          error => {
            this.commonService.hideProgressBar();
            this.commonService.showERPErrorMessage(error);
          }); 
       }
      }else if (this.confTextToDisplay !== '') {      
        this.dialogRef.close(true);
      } 
      else if(this.confTextToDisplay === 'confirmPackage'){
        this.dialogRef.close(true);
      }
      else {
        this.dialogRef.close();
      }
  }
}

import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./dialogBox.component.css'],
   providers: [ ]
})
export class EmailDialogComponent extends CommonUtils implements OnInit {
  templateList;
  templateName;
  moduleName: string;
  pdfAttachedInEmail;
  templateForm: FormGroup;
  form:FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
body;
  // Enter, comma
separatorKeysCodes = [ENTER, COMMA];

toEmailList = [];
ccEmailList = [];
bccEmailList = [];

config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '36rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
public options: Object = {
 key:"dC8A5A4D3A-9H3E2I2C2C6C3A1B5B1E1phA-21nB-13taD1B-8H-8=="
}
 add(event: MatChipInputEvent, mailFor : String): void {
    const input = event.input;
    const value = event.value;
  if(mailFor === 'TO'){
     /* if ((value || '').trim()) {
        this.toEmailList.push(value.trim());
      }*/
      if ((value || '').trim()) {
       this.form.controls['toEmailList'].setErrors(null);  
        const tempToEmails = this.form.controls['toEmailList'].value; 
        tempToEmails.push(value.trim());
        this.form.controls['toEmailList'].setValue(tempToEmails);  

          if (this.form.controls['toEmailList'].valid) { 
              this.form.controls['toEmailList'].markAsDirty();
              //input.value = '';                                    
          }else {
              
              this.form.controls['toEmailList'].markAsUntouched();  
          }
        } else {
          this.form.controls['toEmailList'].updateValueAndValidity();  
    }
    
  }else if(mailFor === 'CC'){
      if ((value || '').trim()) {
        this.ccEmailList.push(value.trim());
      }
  }else if(mailFor === 'BCC'){
      if ((value || '').trim()) {
        this.bccEmailList.push(value.trim());
      }
  }
  if (input) {
      input.value = '';
  }
}

remove(email: any, mailFor : String): void {
  
      


 if(mailFor === 'TO'){
   let controller = this.form.controls['toEmailList'];

   const index = this.toEmailList.indexOf(email);
    if (index >= 0) {
      this.toEmailList.splice(index, 1);
    }
    controller.updateValueAndValidity();  
    controller.markAsDirty();

  }else if(mailFor === 'CC'){
    const index = this.ccEmailList.indexOf(email);
    if (index >= 0) {
      this.ccEmailList.splice(index, 1);
    }
  }else if(mailFor === 'BCC'){
    const index = this.bccEmailList.indexOf(email);
     if (index >= 0) {
      this.bccEmailList.splice(index, 1);
    }
  }

}

EMAIL_HEADER;TO_EMAIL;CC_EMAIL;BCC_EMAIL;CANCEL_BUTTON;SEND_BTN;EMAIL_BODY;EMAIL_SUBJECT;
toEmail;
invHeaderId;
pdfAttachmentInEmailFlag;
showAttachFlag:boolean = true;
constructor(public snackBar: MatSnackBar, public dialog: MatDialog,private router: Router, public dialogRef: MatDialogRef<EmailDialogComponent>,
 @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService, private commonUtils : CommonUtils,private formBuilder: FormBuilder,) {
  super();
  this.moduleName = data.dialogName;
  if(this.moduleName === 'NEW_RESERVATION' || this.moduleName === 'NEW_EMPLOYEE'  || this.moduleName === 'RESIGNATION_EMAIL'){
    this.showAttachFlag= false;
  }else{
    this.showAttachFlag= true;
  }
   
   if(this.commonUtils.checkNullOrUndefined(data.invHeaderId))
     this.invHeaderId = data.invHeaderId;
  if(this.commonUtils.checkNullOrUndefined(data.toEmail)){
  if(data.toEmail.indexOf(',')){
     let toArray =  data.toEmail.split(",");
      if(toArray.length>0){
        for(var loop=0;loop<toArray.length;loop++){
         this.toEmailList.push(toArray[loop]);
        }
     }
   }else{
     this.toEmailList.push(data.toEmail);
   }
 }
 if(this.commonUtils.checkNullOrUndefined(data.ccEmail)){
  if(data.ccEmail.indexOf(',')){
     let toArray =  data.ccEmail.split(",");
      if(toArray.length>0){
        for(var loop=0;loop<toArray.length;loop++){
         this.ccEmailList.push(toArray[loop]);
        }
     }
   }else{
     this.ccEmailList.push(data.ccEmail);
   }
 }
  if(this.commonUtils.checkNullOrUndefined(data.bccEmail)){
  if(data.bccEmail.indexOf(',')){
     let toArray =  data.bccEmail.split(",");
      if(toArray.length>0){
        for(var loop=0;loop<toArray.length;loop++){
         this.bccEmailList.push(toArray[loop]);
        }
     }
   }else{
     this.bccEmailList.push(data.bccEmail);
   }
 }
  //default toemail id of vendor or customer
  /*if(this.commonUtils.checkNullOrUndefined(this.toEmail))
  this.toEmailList.push(this.toEmail);*/

  this.EMAIL_HEADER = commonUtils.EMAIL_HEADER;
  this.TO_EMAIL = commonUtils.TO_EMAIL;
  this.CC_EMAIL = commonUtils.CC_EMAIL;
  this.BCC_EMAIL = commonUtils.BCC_EMAIL;
  this.CANCEL_BUTTON = commonUtils.CANCEL_BUTTON;
  this.SEND_BTN = commonUtils.SEND_BTN;
  this.EMAIL_BODY = commonUtils.EMAIL_BODY;
  this.EMAIL_SUBJECT = commonUtils.EMAIL_SUBJECT;

  this.templateForm = new FormGroup({
  type: new FormControl(this.moduleName),
  emailTemplateId: new FormControl(),
  templateName: new FormControl(),
  cC:new FormControl(),
  bCC:new FormControl(),
  to:new FormControl(),
  subject: new FormControl(''),
  defaultTemplate: new FormControl(true),
  body: new FormControl(''),
  statusFlag: new FormControl('Y'),
  billId: new FormControl(0),
  invoiceLinkExpiryDate: new FormControl(new Date()),
  pdfAttachedInEmail : new FormControl(false),
  t001UserCreatedBy: new FormGroup({userId: new FormControl(0),userName: new FormControl('')})
  });

 }
  onNoClick(): void {
    this.dialogRef.close();
  }

  submitDialog() {

    this.form.controls['toEmailList'].markAsTouched();
    if (this.form.valid) {
     this.templateForm.get('to').setValue(this.toEmailList.toString());
     this.templateForm.get('cC').setValue(this.ccEmailList.toString());
     this.templateForm.get('bCC').setValue(this.bccEmailList.toString());
     this.templateForm.get('pdfAttachedInEmail').setValue(this.pdfAttachmentInEmailFlag);
     this.dialogRef.close(this.templateForm.value);
    }else{
      this.commonService.showERPMessage('Please enter a valid Email to proceed');
    }  
  }

  ngOnInit() 
  {

    this.form =  this.formBuilder.group({
      toEmailList: [this.toEmailList, [CustomValidators.validateRequired,CustomValidators.validateEmails]]
    });
    this.form.controls['toEmailList'].setValue(this.toEmailList);

    if( this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj) && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t000Organization) 
      && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.pdfAttachedInEmail) )
     this.pdfAttachmentInEmailFlag = this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.pdfAttachedInEmail;
     this.fetchTemplateList();
  }

  checkBoxEvent(event){
  if(event.checked === true)
    this.pdfAttachmentInEmailFlag = true;
  else
    this.pdfAttachmentInEmailFlag = false;
}

 fetchTemplateList()
  {
  
    this.commonService.fetchEmailTemplate(this.templateForm.value).subscribe(
    data => {
    

     //default value
     if(this.commonUtils.checkNullOrUndefined(data) && data.length>0){
      this.templateList = data;
     this.templateForm.patchValue(data[0]);


if(this.commonUtils.checkNullOrUndefined(data[0].bCC)){
  if(data[0].bCC.indexOf(',')){
     let toArray =  data[0].bCC.split(",");
      if(toArray.length>0){
        for(var loop=0;loop<toArray.length;loop++){
         this.bccEmailList.push(toArray[0]);
          
       }
     }
   }else{
     this.bccEmailList.push(data[0].bCC);
   }
 }

if(this.commonUtils.checkNullOrUndefined(data[0].cC)){
  if(data[0].cC.indexOf(',')){
     let toArray =  data[0].cC.split(",");
      if(toArray.length>0){
        for(var loop=0;loop<toArray.length;loop++){
         this.ccEmailList.push(toArray[0]);
        }
     }
   }else{
     this.ccEmailList.push(data[0].cC);
   }
 }

     }
    });

 }

//on selecting the template dropdown, bind the values to form based on template
bindValues(id) {
  this.templateForm.reset();
  this.templateForm.get('emailTemplateId').setValue(id);
   this.commonService.fetchEmailTemplate(this.templateForm.value).subscribe(
    data => {
  if(this.commonUtils.checkNullOrUndefined(data) && data.length>0){
  this.templateForm.patchValue(data[0]);
   this.bccEmailList=[];
   this.ccEmailList=[];

   if(this.commonUtils.checkNullOrUndefined(data[0].bCC)){
  if(data[0].bCC.indexOf(',')){
     let toArray =  data[0].bCC.split(",");
     if(toArray.length>0){
       for(var loop=0;loop<toArray.length;loop++){
         this.bccEmailList.push(toArray[0]);
       }
     }
   }else{
     this.bccEmailList.push(data[0].bCC);
   }
 }

if(this.commonUtils.checkNullOrUndefined(data[0].cC)){
  if(data[0].cC.indexOf(',')){
     let toArray =  data[0].cC.split(",");
     if(toArray.length>0){
       for(var loop=0;loop<toArray.length;loop++){
         this.ccEmailList.push(toArray[0]);
         
       }
     }
   }else{
     this.ccEmailList.push(data[0].cC);
   }
 }
    }
 });
}
}

@Component({
  selector: 'app-searchInGridDialog',
  templateUrl: './searchInGridDialog.component.html',
  styleUrls: ['./dialogBox.component.css'],
   providers: [ ]
})

export class SearchInGridDialogComponent extends CommonUtils implements OnInit {
  
  keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   // event.preventDefault();
   // event.stopPropagation();
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

 
    if(this.keyCode==13){
       this.sendSeachCriteria();       
  }

  }
  searchModuleValue;moduleName: string;
  dialogForm:FormGroup;
  t100ContactCustomerId:FormGroup;
  t100ContactVendorId:FormGroup;
  t100Contact:FormGroup;
  assetCategoryId : FormGroup;
  t200_Item : FormGroup;
  taxForm : FormGroup;
  brandForm : FormGroup;
  categoryForm : FormGroup;
  budgetModuleDisplay = false;
  poModuleDisplay; contactsModuleDisplay; itemsModuleDisplay; salesModuleDisplay; billModuleDisplay = false;
  estimatesModuleDisplay; recurBillsModuleDisplay; expensesModuleDisplay; recurExpensesModuleDisplay = false;
  paymentsMadeModuleDisplay; vendorCreditsModuleDisplay; invoiceModuleDisplay; retainerInvoiceModuleDisplay = false;
  recurringInvoiceModuleDisplay; paymentsReceivedModuleDisplay; creditNotesModuleDisplay; accountsModuleDisplay; journalsModuleDisplay = false;
  employeeModuleDisplay; assetModuleDisplay;indentModuleDisplay;salesPersonModuleDisplay;paycodeModuleDisplay;projectPlanSearchDisplay;artWorkRequestSearchDisplay= false;
  productionSlipModuleDisplay;artWorkModuleDisplay;projectPlanIdSearchDisplay;reservationModuleDisplay;jobModuleDisplay;itemsModuleDisplayForNistarMarketing;manageLoanModuleDisplay;
  candidateModuleDisplay;appointmentModuleDisplay;intervieSlotModuleDisplay;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  searchModule = ['Items', 'Contacts', 'Invoices', 'Estimates', 'Sales Orders', 'Credit Notes', 'Expenses',
  'Bills', 'Vendor Credits', 'Purchase Orders', 'Payments Received', 'Payments Made', 'Recurring Invoices',
  'Recurring Bills',  'Employees','Paycode', 'Assets','Sales Person','Project Plan','Artwork Request','Accounts','Production Slip', 'Artwork','View Loan' ];
  statuses = [{value: 'Active', dbValue: true}, {value: 'Inactive', dbValue: false}];
  types = ['CUSTOMER', 'VENDOR'];
  poStatuses = [];
  productionSlipStatus = [];
  items = [];
  vendors = [];
  billStatus = [];
  industry = [];
  statusInvoice=[]
  customers = [];
  statusCredit= [];
  statusVendorCredits= [];
  taxList = [];
  select = 'select';
  brandList = [];
  statusecurInvoice=[];
  statusExpense=[];
  expenseAccount=[];
  statusSalesOrder=[];
  statusRecurExpense=[];
  rBillStatus=[];
  projects = [];
  paymentMethods = [];
  paidThrough=[];
  expenseAcc=[];
  contactsStatus=[];
  subCategoryList=[];
  categoryList=[];
  genderList=[];
  assetTypes=[];
  orgUsersList=[];
  conditionList =[];
  validationModuleName;
  routeUrl;
  showChildCount : boolean = false;
  paycodeType=['Earnings','Deduction'];
  confirmationList = this.commonUtils.confirmationList;
  noOfChildList = this.commonUtils.noOfChildList;
  scCreatedList =this.commonUtils.scCreatedList;
  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500,
    hasDivider: true
  }; 
  rows:any=[];
  /*summaryStringList = [{value:'',dispValue:'All'},{value:'S',dispValue:'Summary'},{value:'NS',dispValue:'Non-Summary'}];*/
  constructor(public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchInGridDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,public commonUtils:CommonUtils,private recruitService:RecruitmentService,
    private hrmService:HrmService,public settingsService: SettingsService,
   ) {
  super();
        this.dialogForm=data.dialogForm;
        this.moduleName=data.dialogText;
this.portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
this.dialogForm.get('searchGridFlag').setValue('Y');

    this.t100ContactVendorId = new FormGroup({
      contactId: new FormControl('0'),
      contactsType: new FormControl('VENDOR'),
      contactDisplayName: new FormControl('')
    }); 

this.categoryForm=new FormGroup({
   categoryId:new FormControl(),
   categoryName:new FormControl(),
  t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE')}),
  });

    this.t100ContactCustomerId = new FormGroup({
      contactId: new FormControl('0'),
      contactsType: new FormControl('CUSTOMER'),
      contactDisplayName: new FormControl('')
    }); 

    this.t100Contact= new FormGroup({
       contactId: new FormControl('0'),
      contactsType: new FormControl('VENDOR'),
      contactDisplayName: new FormControl('')
    }); 
   this.assetCategoryId = new FormGroup({
      categoryId : new FormControl(0),
      categoryName : new FormControl(''),
      sortOrder : new FormControl(0)
    });
    this.t200_Item = new FormGroup({
      itemName: new FormControl(''),
        sku: new FormControl(''),
        filterType:new FormControl(''),
        itemId: new FormControl('0'),
        active: new FormControl(true),
        skuForDisplay : new FormControl(''),
        t207_SubCategoryObj:new FormGroup({subCategoryId:new FormControl(0),subCategoryName:new FormControl('')}),
        gender: new FormControl(''),
        salesDescription:new FormControl(''),
        t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE')}),
    });
    this.taxForm = new FormGroup({
      status : new FormControl(true)
    });
    this.brandForm=new FormGroup({
   brandId:new FormControl(),
   brandName:new FormControl(),
  });

      if(this.moduleName === 'VIEW_PO') {
        this.poModuleDisplay = true;
        this.searchModuleValue = 'Purchase Orders';
      } else if (this.moduleName === 'VIEW_CONTACT') {
        this.contactsModuleDisplay = true;
        this.searchModuleValue = 'Contacts';
      } else if (this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS') {
         this.searchModuleValue = 'Items';
         this.itemsModuleDisplay = true;
      }else if(this.moduleName === 'VIEW_PRODUCT_SETUP'){
         this.searchModuleValue = 'Items';
         this.itemsModuleDisplayForNistarMarketing = true;
      }
      else if(this.moduleName==='VIEW_LOAN'){
        this.manageLoanModuleDisplay = true;
        this.searchModuleValue = 'View Loan';
      }
      else if (this.moduleName === 'VIEW_SALESORDER') {
        this.salesModuleDisplay = true;
        this.searchModuleValue = 'Sales Orders';
      } else if (this.moduleName === 'VIEW_BILL') {
        this.billModuleDisplay = true;
        this.searchModuleValue = 'Bills';
      } else if (this.moduleName === 'VIEW_ESTIMATE') {
        this.estimatesModuleDisplay = true;
        this.searchModuleValue = 'Estimates';
      } 
      else if (this.moduleName === 'VIEW_RESERVATION') {
        this.reservationModuleDisplay = true;
        this.searchModuleValue = 'Reservation';
      } else if (this.moduleName === 'VIEW_JOB') {
        this.jobModuleDisplay = true;
        this.searchModuleValue = 'Jobs';
      }
      else if (this.moduleName === 'VIEW_INTERVIEW_SLOT') {
        this.intervieSlotModuleDisplay = true;
        this.searchModuleValue = 'Interview Slot';
      } else if (this.moduleName === 'VIEW_R_BILL') {
        this.recurBillsModuleDisplay = true;
        this.searchModuleValue = 'Recurring Bills';
      } else if (this.moduleName === 'VIEW_EXPENSE') {
        this.expensesModuleDisplay = true;
        this.searchModuleValue = 'Expenses';
      } else if (this.moduleName === 'VIEW_R_EXPENSE') {
        this.recurExpensesModuleDisplay = true;
        this.searchModuleValue = 'Recurring Expenses';
      } else if (this.moduleName === 'VIEW_PAYMENT_MADE') {
        this.paymentsMadeModuleDisplay = true;
        this.searchModuleValue = 'Payments Made';
      } else if (this.moduleName === 'VIEW_VENDOR_CREDIT') {
        this.vendorCreditsModuleDisplay = true;
        this.searchModuleValue = 'Vendor Credits';
      } else if (this.moduleName === 'VIEW_INVOICE') {
        this.invoiceModuleDisplay = true;
        this.searchModuleValue = 'Invoices';
      } else if (this.moduleName === 'Retainer Invoices') {
        this.retainerInvoiceModuleDisplay = true;
        this.searchModuleValue = 'Retainer Invoices';
      } else if (this.moduleName === 'VIEW_R_INVOICE') {
        this.recurringInvoiceModuleDisplay = true;
        this.searchModuleValue = 'Recurring Invoices';
      } else if (this.moduleName === 'VIEW_PAYMENT_RECIEVED' ) {
        this.paymentsReceivedModuleDisplay = true;
        this.searchModuleValue = 'Payments Received';
      } else if (this.moduleName === 'VIEW_CREDIT_NOTES') {
        this.creditNotesModuleDisplay = true;
        this.searchModuleValue = 'Credit Notes';
      } else if (this.moduleName === 'Journals') {
        this.journalsModuleDisplay = true;
        this.searchModuleValue = 'Journals';
      } else if (this.moduleName === 'VIEW_EMPLOYEE') {
        this.employeeModuleDisplay = true;
        this.searchModuleValue = 'Employees';
      } else if (this.moduleName === 'VIEW_CANDIDATE') {
        this.candidateModuleDisplay = true;
        this.searchModuleValue = 'Candidate';
      } else if (this.moduleName === 'VIEW_APPOINTMENT') {
        this.appointmentModuleDisplay = true;
        this.searchModuleValue = 'Appointment';
      } else if (this.moduleName === 'VIEW_ASSETS') {
        this.assetModuleDisplay = true;
        this.searchModuleValue = 'Assets';
      } else if(this.moduleName === 'VIEW_SALES_PERSON'){
        this.salesPersonModuleDisplay = true;
        this.searchModuleValue = 'Sales Person';
      } else if (this.moduleName === 'VIEW_INDENT') {
        this.indentModuleDisplay = true;
        this.searchModuleValue = 'Indent';
      }else if (this.moduleName === 'VIEW_STOCK_REQUEST') {
        this.indentModuleDisplay = true;
        this.searchModuleValue = 'Stock Request';
      }else if(this.moduleName === 'VIEW_PAYCODE'){
        this.paycodeModuleDisplay =true;
        this.searchModuleValue = 'Paycode';
      } else if(this.dialogForm.get('t822ProjectObj.projectId').value !=0 && this.moduleName === 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
        this.projectPlanSearchDisplay =true;
        this.searchModuleValue = 'Project Plan';
      }else if(this.dialogForm.get('t822ProjectObj.projectId').value ==0 && this.moduleName === 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
        this.projectPlanIdSearchDisplay =true;
        this.searchModuleValue = 'Project Plan';
      } else if(this.moduleName === 'CHART_OF_ACCOUNTS'){
        this.accountsModuleDisplay =true;
        this.searchModuleValue = 'Accounts';
      }
      else if (this.moduleName === 'VIEW_PRODUCTION_SLIP') {
        this.productionSlipModuleDisplay = true;
        this.searchModuleValue = 'Production Slip';
      }
      else if(this.moduleName === 'VIEW_ARTWORK_REQUEST'){
        this.artWorkRequestSearchDisplay =true;
        this.searchModuleValue = 'Artwork Request';
      }else if(this.moduleName === 'VIEW_ARTWORK'){
        this.artWorkModuleDisplay =true;
        this.searchModuleValue = 'Artwork';
      
      }else if(this.moduleName === 'VIEW_ACCOUNT_BUDGET'){
        this.budgetModuleDisplay = true;
           this.searchModuleValue = 'Budgeting';
      }else {
        this.poModuleDisplay = false;
        this.contactsModuleDisplay = false;
        this.itemsModuleDisplay = false;
        this.salesModuleDisplay = false;
        this.billModuleDisplay = false;
        this.estimatesModuleDisplay = false;
        this.reservationModuleDisplay = false;
        this.jobModuleDisplay = false;
        this.intervieSlotModuleDisplay = false;
        this.recurBillsModuleDisplay = false;
        this.expensesModuleDisplay = false;
        this.recurExpensesModuleDisplay = false;
        this.paymentsMadeModuleDisplay = false;
        this.vendorCreditsModuleDisplay = false;
        this.invoiceModuleDisplay = false;
        this.retainerInvoiceModuleDisplay = false;
        this.recurringInvoiceModuleDisplay = false;
        this.paymentsReceivedModuleDisplay = false;
        this.creditNotesModuleDisplay = false;
        this.journalsModuleDisplay = false;
        this.employeeModuleDisplay = false;
        this.candidateModuleDisplay = false;
        this.appointmentModuleDisplay = false;
        this.indentModuleDisplay=false;
        this.assetModuleDisplay=false;
        this.paycodeModuleDisplay=false;
        this.salesPersonModuleDisplay = false;
        this.projectPlanSearchDisplay =false;
        this.artWorkRequestSearchDisplay =false;
        this.accountsModuleDisplay = false;
        this.productionSlipModuleDisplay = false;
        this.artWorkModuleDisplay = false;
        this.projectPlanIdSearchDisplay=false;
        this.itemsModuleDisplayForNistarMarketing = false;
        this.budgetModuleDisplay = false;
        this.manageLoanModuleDisplay = false;
      }
    }

salesAccountsList = [];
purchaseAccountsList=[];
salaryList=[];
industryList=[];
designationList=[];
departmentList=[];
locationList=[];
salesPersonList=[];
estimateStatus=[];
reservationStatus=[];
jobStatus=[];
interviewStatus=[];
interviewSlotTypeList=[];
jobList=[];
accountsList=[];
accountsDetailTypeList=[];
artworkStatus=[];
resourceExtensionList=[];
fiscalYearList = [];
budgetPeriodList = [];
employeeList = [];
candidateList = [];
reportingMgrList=[];
statusList=[];
 salaryObj: any;
 parentTaskList;
  ngOnInit() 
  {
   this.pageLoad();
  }
   getItems(parentChildObj:any) {
    let itemsArray:any = [];
    parentChildObj.forEach((set: TreeItem) => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }
  onFilterChange(value: string): void {
    
  }
  onCheckedChangeFunction(event:any,event1:any){
    //treeViewCls
    var element1 = document.querySelector(".treeViewCls .dropdown-menu");
element1.classList.remove("show");

    var element = document.querySelector(".treeViewCls .dropdown");
element.classList.remove("show");
var element2 = document.querySelector(".treeViewCls .dropdown-toggle");
element2.setAttribute('aria-expanded','false')
    this.dialogForm.get('t823ParentTask.taskName').setValue(event1);
      this.dialogForm.get('t823ParentTask.taskId').setValue(event);
  
  }
  onSelectedChange(downlineItems: DownlineTreeviewItem[]): void {
    this.rows = [];
    downlineItems.forEach(downlineItem => {
      // console.log(downlineItem);
    });
  }
  pageLoad()
  {
     if(this.moduleName=='VIEW_PO')
     {

       this.validationModuleName='VIEW_PO_SEARCH';
       this.fetchPoStatus('PO_STATUS');
       this.routeUrl='purchasemodule/reveiwpo';
       this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
     } else if(this.moduleName=='VIEW_CANDIDATE')
     {
       this.validationModuleName='VIEW_CANDIDATE_SEARCH';
       this.routeUrl='recruitment/reviewcandidate';
       this.fetchPoStatus('VIEW_CANDIDATE_INDUSTRY');
       this.fetchIndustry();
       this.dialogForm.get('routeUrl').setValue(this.routeUrl);
      
     } else if(this.moduleName=='VIEW_APPOINTMENT')
     {
       this.fetchReportingManager();
        this.fetchPoStatus('VIEW_APPOINTMENT');
       this.validationModuleName='VIEW_APPOINTMENT_SEARCH';
       this.routeUrl='recruitment/reviewappointment';
       this.dialogForm.get('routeUrl').setValue(this.routeUrl);
      
     }
      
    else if(this.moduleName=='VIEW_BILL')
    {
      this.validationModuleName='VIEW_BILL_SEARCH';
      this.fetchPoStatus('BILL_STATUS');
      this.routeUrl='purchasemodule/reviewbill';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
    }
    else if(this.moduleName=='VIEW_R_BILL')
    {
      this.validationModuleName='VIEW_R_BILL_SEARCH';
      this.fetchPoStatus('VIEW_R_BILL');
      this.routeUrl='purchasemodule/reviewrbill';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
    }
    else if(this.moduleName=='VIEW_CREDIT_NOTES')
    {
      this.validationModuleName='VIEW_CREDIT_NOTES_SEARCH';
      this.fetchPoStatus('VIEW_CREDIT_NOTES');
      this.routeUrl='invoice/reviewcreditnotes';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    
    }
    else if(this.moduleName=='VIEW_VENDOR_CREDIT')
    {
      this.validationModuleName='VIEW_VENDOR_CREDIT_SEARCH';
      this.fetchPoStatus('VIEW_VENDOR_CREDIT');
      this.routeUrl='purchasemodule/reviewvendorcr';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
    }
    else if(this.moduleName=='VIEW_R_INVOICE')
    {
      this.validationModuleName='VIEW_R_INVOICE_SEARCH';
      this.fetchPoStatus('VIEW_R_INVOICE');
      this.routeUrl='invoice/reviewrinvoice';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
    }
    else if(this.moduleName=='VIEW_R_EXPENSE')
    {
      this.validationModuleName='VIEW_R_EXPENSE_SEARCH';
      this.fetchPoStatus('VIEW_R_EXPENSE');
      this.routeUrl='purchasemodule/reviewRecurrExpenses';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    
    }
    else if(this.moduleName=='VIEW_CONTACT')
    {
      this.validationModuleName='VIEW_CONTACT_SEARCH';
      this.fetchPoStatus('VIEW_CONTACT');
      this.routeUrl='contacts';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     
    }
    else if(this.moduleName=='VIEW_EMPLOYEE')
    {
      this.validationModuleName='VIEW_EMPLOYEE_SEARCH';
      this.routeUrl='hrmmodule/reviewemployee';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
      
      this.fetchDesignation();
      this.fetchDepartment();
      this.fetchLocation();
      this.fetchSalary();
    }
    else if(this.moduleName=='VIEW_PAYCODE')
    {
      
      this.routeUrl='hrmmodule/viewpaycode';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    
      this.validationModuleName='VIEW_PAYCODE_SEARCH';
    }
    
    
   else if(this.moduleName=='VIEW_SALES_PERSON')
   {
     this.routeUrl='contacts/reviewsalesperson';
     this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     this.validationModuleName='VIEW_SALES_PERSON_SEARCH';
   }
   
     else if(this.moduleName=='PROJECT_PLAN' && this.dialogForm.get('t822ProjectObj.projectId').value!=0)
   {
     this.routeUrl='projectmodule/projectplan';
     this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     this.fetchResourceFortheProject();
     this.fetchParentTaskList();
     this.fetchParentTaskHierarchy();
     this.validationModuleName='PROJECT_PLAN';
   }
   else if(this.moduleName === 'PROJECT_PLAN_CLIENT'){
     this.routeUrl='projectmodule/viewprojectplan';
     this.dialogForm.get('routeUrl').setValue(this.routeUrl);
     this.fetchResourceFortheProject();
     this.validationModuleName='PROJECT_PLAN';
    }
     else if(this.moduleName=='VIEW_PRODUCTION_SLIP')
    {
      this.validationModuleName='VIEW_PRODUCTION_SLIP_SEARCH';
      this.fetchPoStatus('VIEW_PRODUCTION_SLIP');
      this.routeUrl='sales/reviewProductionSlip';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }
    else if(this.moduleName=='VIEW_ARTWORK')
    {
     
      this.validationModuleName='VIEW_ARTWORK_SEARCH';
      this.fetchPoStatus('VIEW_ARTWORK');
      this.routeUrl='sales/reviewartwork';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }
    else if(this.moduleName === 'VIEW_ACCOUNT_BUDGET'){
      this.validationModuleName='VIEW_ACCOUNT_BUDGET_SEARCH';
      this.fetchPoStatus('FINANCIAL_YEAR');
      this.fetchPoStatus('BUDGET_PERIOD');
      this.routeUrl='accounts/reviewAccountBudget';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }else if(this.moduleName==='VIEW_LOAN')
    {
      this.validationModuleName='VIEW_LOAN_SEARCH';
      this.fetchSalary();
      this.fetchEmployee();
      this.routeUrl='hrmmodule/viewloan';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }
    else if(this.moduleName==='VIEW_JOB'){
       this.fetchLocation();
       this.fetchPoStatus('JOB_STATUS');
      this.validationModuleName='VIEW_JOB_SEARCH';
      this.routeUrl='recruitment/reviewjobs';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }
     else if(this.moduleName ==='VIEW_INTERVIEW_SLOT'){
         this.fetchJobs()
       this.fetchPoStatus('INTERVIEW_SLOT_STATUS');
       this.fetchPoStatus('INTERVIEW_SLOT_TYPE');
      this.validationModuleName='VIEW_INTERVIEW_SLOT_SEARCH';
      this.routeUrl='recruitment/reviewinterviewslot';
      this.dialogForm.get('routeUrl').setValue(this.routeUrl);
    }  
    this.fetchValidationMethod();       
  }

  fetchParentTaskHierarchy():void{ 
    var arrayVal:any=[];
      let invoiceForm: FormGroup = new FormGroup({ t822ProjectObj: new FormGroup({ projectId: new FormControl(0) }) });
    let projectId = this.dialogForm.get('t822ProjectObj.projectId').value;
    invoiceForm.get('t822ProjectObj.projectId').setValue(projectId);
    if (projectId != 0 && this.commonUtils.checkNullOrUndefined(projectId)) {
      invoiceForm.get('t822ProjectObj.projectId').setValue(projectId);
    }
    this.hrmService.fetchParentTaskHierarchy(invoiceForm.value).subscribe(
      data => {
      const Maplist1=data; 
     const Maplist=  Maplist1.reduce((acc:any, nodeData:any) => (nodeData.children = [], acc[nodeData.TaskId] = nodeData, acc), {});        
        const rootData =  Object.values(Maplist)
        .map((nodeData:any) => (nodeData.ParentTaskId && Maplist[nodeData.ParentTaskId].children.push(nodeData), nodeData))
        .filter(nodeData => nodeData.ParentTaskId === 0).forEach(function(i){
           arrayVal.push(i);  //console.log(i);  
        }); 
    this.items = this.getItems(arrayVal);
    const elementTest = document.querySelector('.treeview-header .row-filter input') as HTMLInputElement;
    elementTest.placeholder = 'Search Here'; 
      },
      error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  fetchParentTaskList(){
    this.parentTaskList = this.dialogForm.get(['t823ParentTask']).get(['taskName']).valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap( taskName =>  this.fetchSearchParentTask(taskName)),);
  }
  public fetchSearchParentTask(taskName):Observable<any>{   


    let searchParentTaskForm=new FormGroup({
      taskType:new FormControl('AP'),
      t822ProjectObj:new FormGroup({
        projectId:new FormControl()
      }),
      startDate:new FormControl(''),
      endDate:new FormControl(''),
      t004_LookupTaskStatusObj:new FormGroup({
        lookupValue:new FormControl('')
      }),
      t823ParentTask:new FormGroup({
        taskName:new FormControl('')
      }),
      isPagination:new FormControl(true),
      summary:new FormControl(true)
    });
       searchParentTaskForm.get('taskType').setValue('AP');
       searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.dialogForm.get('t822ProjectObj.projectId').value);
       searchParentTaskForm.get('startDate').setValue('');
       searchParentTaskForm.get('endDate').setValue('');
       searchParentTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
       searchParentTaskForm.get('t823ParentTask.taskName').setValue(taskName);
       searchParentTaskForm.get('isPagination').setValue('true');
   
   searchParentTaskForm.get('summary').setValue(true);
     
    // console.log("searchParentTaskForm",JSON.stringify(searchParentTaskForm.value));
      return this.commonService.fetchParentTaskList(searchParentTaskForm.value);
     
    }
  taskSelected(selectedTask): void {
    if(this.commonUtils.checkNullOrUndefined(selectedTask)){
       this.dialogForm.get('t823ParentTask.taskName').setValue(selectedTask.taskName);
       this.dialogForm.get('t823ParentTask.taskId').setValue(selectedTask.taskId); 
       this.dialogForm.get('parentflag').setValue(true);

    }else{
        this.dialogForm.get('t823ParentTask.taskName').setValue("None");
    }
  } 
   fetchValidationMethod(): void
  {
   if(this.commonUtils.checkNullOrUndefined(this.validationModuleName))
    this.commonService.fetchAndSetValidationData(this.validationModuleName,this.dialogForm);   
  }

  
  fetchDesignation(): void {
    this.hrmService.fetchDesignation().subscribe(
     data => {
         this.designationList = data;
       }, error => {
         this.commonService.showERPErrorMessage(error);
      });
  }
  fetchReportingManager(): void {
  this.hrmService.fetchReportingManager().subscribe(
    data => {
      this.reportingMgrList = data;
    }, error => {
      this.commonService.showERPErrorMessage(error);
      
    });
  }
  fetchDepartment(): void {
    this.hrmService.fetchDepartment().subscribe(
      data => {
         this.departmentList = data;
       }, error => {
          this.commonService.showERPErrorMessage(error);
      });
  }

  fetchLocation(): void
   {
    this.hrmService.fetchLocation().subscribe(
      data =>
      {
         this.locationList = data;
      }, error => 
      {
         this.commonService.showERPErrorMessage(error);
      });
  }
 fetchSalary(): void {
    this.hrmService.fetchSalary().subscribe(
      data => {
         this.salaryList = data;
      }, error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
salaryChangeEvent(){
   this.dialogForm.get('t818SalaryGroup').get('salaryId').value;
    this.salaryObj=this.dialogForm.get('t818SalaryGroup').value;
    if(this.commonUtils.checkNullOrUndefined(this.salaryObj) && this.commonUtils.checkNullOrUndefined(this.salaryObj.salaryId)&&
      this.salaryObj.salaryId!=0){
    this.fetchEmployee();
   }
  
 } 
   fetchEmployee(){
  let myForm = new FormGroup({
    t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE EMPLOYEE')}),
     t818SalaryGroup: new FormGroup({salaryId: new FormControl(0)}),
   requestFlag: new FormControl('FetchAll')
})
if(this.commonUtils.checkNullOrUndefined(this.salaryObj) && this.commonUtils.checkNullOrUndefined(this.salaryObj.salaryId)&&
      this.salaryObj.salaryId!=0){
    myForm.get('t818SalaryGroup.salaryId').setValue(this.salaryObj.salaryId);
   }
this.hrmService.fetchEmployee(myForm.value).
      subscribe(
      data => {
        if (this.commonUtils.checkNullOrUndefined(data)) {
           this.employeeList = data;
         }
       },
        error => {
        this.commonService.showERPErrorMessage(error);
      });
}
   fetchResourceFortheProject():void{
      let invoiceForm:FormGroup = new FormGroup({t822ProjectObj:new FormGroup({projectId:new FormControl(0)})});
      let projectId=this.dialogForm.get('t822ProjectObj.projectId').value;
     invoiceForm.get('t822ProjectObj.projectId').setValue(projectId);
      if(projectId!=0 && this.commonUtils.checkNullOrUndefined(projectId)){
        invoiceForm.get('t822ProjectObj.projectId').setValue(projectId);
      }  
      this.hrmService.fetchResourceExtension(invoiceForm.value).subscribe(
      data => {  
       this.resourceExtensionList=data;       
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
 fetchUsers() {

    this.settingsService.fetchUsers(this.dialogForm.value).
      subscribe(
      data => {     
      if(this.commonUtils.checkNullOrUndefined(data))   
        this.orgUsersList = data;
      
      });

  }
  fetchCondition():void{
  this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('CONDITION_CODE').value).
  subscribe(
  data => {
    if (this.commonUtils.checkNullOrUndefined(data)){
      this.conditionList = data;
      
    }
  },
  error => {
    this.commonService.showERPErrorMessage(error);
  });
}

 displayChildCount(value){
   
   if(this.commonUtils.checkNullOrUndefined(value))
     this.showChildCount = true;

 }
 allowNumeric(){ 
  if(this.commonUtils.checkNullOrUndefined(this.dialogForm.get('noOfChildInParentCount').value)
  && isNaN(this.dialogForm.get('noOfChildInParentCount').value)){
    this.dialogForm.get('noOfChildInParentCount').reset();
  }
 }
 onFocusOutStartDate(){
   
   if(this.commonUtils.checkNullOrUndefined(this.dialogForm.get('startDate').value)){
    
      this.dialogForm.get('endDate').setValidators(Validators.required);
      this.dialogForm.get('endDate').markAsTouched();
    this.dialogForm.get('endDate').updateValueAndValidity();
  }else{
      
      this.dialogForm.get('endDate').setValidators(null);
      this.dialogForm.get('endDate').markAsTouched();
    this.dialogForm.get('endDate').updateValueAndValidity();
  }

 }
 fetchIndustry(){
  let industryForm = new FormGroup({ status: new FormControl(true)});
  this.recruitService.fetchCandidateIndustry(industryForm.value).subscribe(
    data => {
      this.industryList = data;
    },
    error => {
     this.commonService.showERPErrorMessage(error);
   });
 }
  fetchPoStatus(lookupCode){
  this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup(lookupCode).value).
      subscribe(
      data => {
        if (this.commonUtils.checkNullOrUndefined(data)) 
        {
            if(lookupCode=='PO_STATUS')
             this.poStatuses = data;
            else if(lookupCode=='BILL_STATUS')
             this.billStatus = data;
            else if(lookupCode=='VIEW_R_BILL')
             this.rBillStatus = data;
            else if(lookupCode=='VIEW_INVOICE')
             this.statusInvoice = data;
            else if(lookupCode=='VIEW_CREDIT_NOTES')
             this.statusCredit= data;
            else if(lookupCode=='VIEW_VENDOR_CREDIT')
             this.statusVendorCredits = data;
            else if(lookupCode=='VIEW_EXPENSE')
             this.statusExpense = data;
            else if(lookupCode=='VIEW_R_EXPENSE')
             this.statusRecurExpense = data;
            else if(lookupCode=='VIEW_R_INVOICE')
             this.statusecurInvoice = data;
            else if(lookupCode=='SO_STATUS')
             this.statusSalesOrder = data;
            else if(lookupCode=='VIEW_CONTACT')
             this.contactsStatus = data;
            else if(lookupCode=='VIEW_ESTIMATE')
             this.estimateStatus=data;
           else if(lookupCode==='VIEW_RESERVATION')
             this.reservationStatus=data;
           else if(lookupCode==='JOB_STATUS'){
             this.jobStatus=data;
           }
            else if(lookupCode==='INTERVIEW_SLOT_STATUS'){
             this.interviewStatus=data;
           }
            else if(lookupCode==='INTERVIEW_SLOT_TYPE'){
             this.interviewSlotTypeList=data;
           }
            else if(lookupCode==='VIEW_APPOINTMENT'){
             this.statusList=data;
           }
            else if(lookupCode=='VIEW_PRODUCTION_SLIP')
             this.productionSlipStatus=data;
             else if(lookupCode=='VIEW_ARTWORK')
             this.artworkStatus=data;
           else if(lookupCode === 'BUDGET_PERIOD')
             this.budgetPeriodList = data;
           else if(lookupCode === 'FINANCIAL_YEAR')
             this.fiscalYearList = data;
            else if (lookupCode === 'VIEW_CANDIDATE_INDUSTRY')
             this.industry = data;
        }
      },
      error => {
          this.commonService.showERPErrorMessage(error);
      });
}

 contactClicked(selectedContact: any): void {    
      if(selectedContact.contactsType=='VENDOR'){
        if(this.moduleName=='VIEW_PAYMENT_MADE'){
           this.dialogForm.get('t100Contact').patchValue(selectedContact);
        }else if(this.moduleName=='VIEW_ASSETS'){
           this.dialogForm.get('t100ContactsVendorObj').patchValue(selectedContact);
        }else{
             this.dialogForm.get('t100ContactVendorId').patchValue(selectedContact);
        }
      }else{
        if(this.moduleName=='VIEW_PAYMENT_RECIEVED'){
          this.dialogForm.get('t100Contact').patchValue(selectedContact);
        }
        else{
          this.dialogForm.get('t100ContactCustomerId').patchValue(selectedContact); 
        }  
      }
  }
itemSelected(selectedItem: any):void
{
 this.dialogForm.get('t200_Item').patchValue(selectedItem);
}
categoryClicked(selected: any):void{
  if(this.moduleName=='VIEW_ASSETS')
  {
     this.dialogForm.get('assetCategoryId').patchValue(selected);
     
     
  }
}
//search the result on enter key press
  searchOnKeyPress(event) {
    if (event.keyCode === 13) {
      this.sendSeachCriteria();
    }
  }
changeModuleName(moduleName)
{
    this.poModuleDisplay = false;
        this.contactsModuleDisplay = false;
        this.itemsModuleDisplay = false;
        this.salesModuleDisplay = false;
        this.billModuleDisplay = false;
        this.estimatesModuleDisplay = false;
        this.reservationModuleDisplay = false;
        this.jobModuleDisplay = false;
        this.intervieSlotModuleDisplay = false;
        this.recurBillsModuleDisplay = false;
        this.expensesModuleDisplay = false;
        this.recurExpensesModuleDisplay = false;
        this.paymentsMadeModuleDisplay = false;
        this.vendorCreditsModuleDisplay = false;
        this.invoiceModuleDisplay = false;
        this.retainerInvoiceModuleDisplay = false;
        this.recurringInvoiceModuleDisplay = false;
        this.paymentsReceivedModuleDisplay = false;
        this.creditNotesModuleDisplay = false;
        this.journalsModuleDisplay = false;
        this.employeeModuleDisplay = false;
        this.candidateModuleDisplay = false;
        this.appointmentModuleDisplay = false;
        this.indentModuleDisplay=false;
        this.assetModuleDisplay=false;
        this.paycodeModuleDisplay=false;
        this.salesPersonModuleDisplay = false;
        this.productionSlipModuleDisplay =false;
        this.artWorkModuleDisplay = false;
        this.itemsModuleDisplayForNistarMarketing = false;
        this.budgetModuleDisplay = false;
        this.manageLoanModuleDisplay = false;

  if(moduleName==='Items'){
     this.itemsModuleDisplay = true;
     this.moduleName='VIEW_ITEM';    
  }
  if(moduleName=='Contacts'){
     this.contactsModuleDisplay=true;
    this.moduleName='VIEW_CONTACT';    
  }
  if(moduleName=='Invoices'){
    this.invoiceModuleDisplay=true
    this.moduleName='VIEW_INVOICE';    
  }
  if(moduleName=='Estimates'){
    this.estimatesModuleDisplay=true
    this.moduleName='VIEW_ESTIMATE';    
  }
   if(moduleName==='Reservation'){
    this.reservationModuleDisplay=true
    this.moduleName='VIEW_RESERVATION';    
  }
  if(moduleName==='Jobs'){
    this.jobModuleDisplay=true
    this.moduleName='VIEW_JOB';    
  }
  if(moduleName==='Interview Slot'){
    this.intervieSlotModuleDisplay=true
    this.moduleName='VIEW_INTERVIEW_SLOT';    
  }
  if(moduleName=='Retainer Invoices'){
    this.retainerInvoiceModuleDisplay=true;
    this.moduleName='VIEW_R_INVOICE';    
  }
  if(moduleName=='Sales Orders'){
    this.salesModuleDisplay=true;
    this.moduleName='VIEW_SALESORDER';    
  }
  if(moduleName=='Credit Notes'){
    this.creditNotesModuleDisplay=true;
    this.moduleName='VIEW_CREDIT_NOTES';    
  }
  if(moduleName=='Expenses'){
    this.expensesModuleDisplay=true;
    this.moduleName='VIEW_EXPENSE';    
  }
  if(moduleName=='Bills'){
    this.billModuleDisplay=true;
    this.moduleName='VIEW_BILL';    
  }
  if(moduleName=='Vendor Credits'){
    this.vendorCreditsModuleDisplay=true;
    this.moduleName='VIEW_VENDOR_CREDIT';    
  }
  if(moduleName=='Purchase Orders'){
    this.poModuleDisplay=true
    this.moduleName='VIEW_PO';    
  }
  if(moduleName=='Payments Received'){
    this.paymentsReceivedModuleDisplay=true;
    this.moduleName='VIEW_PAYMENT_RECIEVED';    
  }
  if(moduleName=='Payments Made'){
    this.paymentsMadeModuleDisplay=true;
    this.moduleName='VIEW_PAYMENT_MADE';    
  }
  if(moduleName=='Recurring Invoices'){
    this.recurringInvoiceModuleDisplay=true;
    this.moduleName='VIEW_R_INVOICE';    
  }
  if(moduleName=='Recurring Bills'){
    this.recurBillsModuleDisplay=true;
    this.moduleName='VIEW_R_BILL';    
  }
  if(moduleName=='Recurring Expenses'){
    this.recurExpensesModuleDisplay=true;
    this.moduleName='VIEW_R_EXPENSE';    
  }
  if(moduleName=='Employees'){
    this.employeeModuleDisplay=true;
    this.moduleName='VIEW_EMPLOYEE';    
  }
  if(moduleName=='Candidate'){
    this.candidateModuleDisplay=true;
    this.moduleName='VIEW_CANDIDATE';    
  }
  if(moduleName=='Appointment'){
    this.appointmentModuleDisplay=true;
    this.moduleName='VIEW_APPOINTMENT';    
  }
  if(moduleName=='Paycode'){
    this.paycodeModuleDisplay=true;
    this.moduleName='VIEW_PayCode';    
  }
  if(moduleName=='Assets'){
    this.assetModuleDisplay=true;
    this.moduleName='VIEW_Assets';    
  }
  if(moduleName=='Sales Person'){
    this.salesPersonModuleDisplay=true;
    this.moduleName='VIEW_SALES_PERSON';    
  }
   if(moduleName=='Project Plan'){
    this.projectPlanSearchDisplay=true;
    this.moduleName='PROJECT_PLAN';    
  }
   if(moduleName=='Artwork Request'){
    this.artWorkRequestSearchDisplay=true;
    this.moduleName='VIEW_ARTWORK_REQUEST';    
  }
  if(moduleName=='Production Slip'){
    this.productionSlipModuleDisplay=true;
    this.moduleName='VIEW_PRODUCTION_SLIP';    
  }
   if(moduleName=='Artwork'){
    this.artWorkModuleDisplay=true;
    this.moduleName='VIEW_ARTWORK';    
  }
  if(moduleName === 'Budgeting'){
    this.budgetModuleDisplay = true;
    this.moduleName = 'VIEW_ACCOUNT_BUDGET';
  }
  if(moduleName==='viewloan'){
    this.manageLoanModuleDisplay = true;
    this.moduleName = 'VIEW_LOAN';
  }
 /*  const redirectURL = this.commonUtils.getRedirectLink(this.moduleName)
      this.router.navigate([redirectURL]);
    this.dialogRef.close();
    */
  this.pageLoad();
}
clearContact(value:string,contactType): void{
     if(value === 'CLEAR_CONTACT'){
        if(contactType === 'CUSTOMER')
           this.dialogForm.get('t100ContactCustomerId').reset();
        if(contactType === 'VENDOR')
           this.dialogForm.get('t100ContactVendorId').reset();
     }
}
 
 fetchJobs(){
     let jobForm = new FormGroup({
        
       t004JobStatusObj: new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl('JOB_STATUS'),
         lookupValue: new FormControl('ACTIVE'),
       })
     });
       this.recruitService.fetchJobs(jobForm.value).subscribe(
         data=>{ 
           
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.jobList = data;
         }
       }, error =>{

       });
     }
  onChangeOfStatus(lookupObj){
     this.dialogForm.get('t004_LookupObj').patchValue(lookupObj);
  }
 onChangeOfJobName(jobObj){
     this.dialogForm.get('t842_JobName').patchValue(jobObj);
  }
   onChangeOfInterviewStatus(lookupObj){
     this.dialogForm.get('interviewType').patchValue(lookupObj);
  }
  sendSeachCriteria(): void {
       this.commonService.addMenuList(this.routeUrl);
      if (this.moduleName === 'VIEW_CONTACT') 
      {
         this.dialogForm.get('moduleName').setValue('VIEW_CONTACT');             
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_ITEM') {
         this.dialogForm.get('moduleName').setValue('VIEW_ITEM'); 
         this.dialogForm.get('statusFlag').setValue('all');
          this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_ITEMS') {
         this.dialogForm.get('moduleName').setValue('VIEW_ITEMS'); 
         this.dialogForm.get('statusFlag').setValue('all');
          this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'VIEW_PO') {
         this.dialogForm.get('moduleName').setValue('VIEW_PO');  
      //   this.router.navigate(['/purchasemodule/reveiwpo']);    
         this.dialogRef.close(this.dialogForm.value);     
      } else if (this.moduleName === 'VIEW_SALESORDER') {
         this.dialogForm.get('moduleName').setValue('VIEW_SALESORDER');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_BILL') {
         this.dialogForm.get('moduleName').setValue('VIEW_BILL'); 
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_ESTIMATE') {
         this.dialogForm.get('moduleName').setValue('VIEW_ESTIMATE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_RESERVATION') {
         this.dialogForm.get('moduleName').setValue('VIEW_RESERVATION');
         this.dialogRef.close(this.dialogForm.value);
      }else if (this.moduleName === 'VIEW_JOB') {
         this.dialogForm.get('moduleName').setValue('VIEW_JOB');
         this.dialogRef.close(this.dialogForm.value);
      }else if (this.moduleName === 'VIEW_R_BILL') {
         this.dialogForm.get('moduleName').setValue('VIEW_R_BILL');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_EXPENSE') {
         this.dialogForm.get('moduleName').setValue('VIEW_EXPENSE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_R_EXPENSE') {
         this.dialogForm.get('moduleName').setValue('VIEW_R_EXPENSE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_PAYMENT_MADE') {
         this.dialogForm.get('moduleName').setValue('VIEW_PAYMENT_MADE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_VENDOR_CREDIT') {
         this.dialogForm.get('moduleName').setValue('VIEW_VENDOR_CREDIT');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_INVOICE') {
         this.dialogForm.get('moduleName').setValue('VIEW_INVOICE');
        this.dialogRef.close(this.dialogForm.value);
      }else if (this.moduleName === 'VIEW_R_INVOICE') {
         this.dialogForm.get('moduleName').setValue('VIEW_R_INVOICE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_PAYMENT_RECIEVED') {
         this.dialogForm.get('moduleName').setValue('VIEW_PAYMENT_RECIEVED');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_CREDIT_NOTES') {
         this.dialogForm.get('moduleName').setValue('VIEW_CREDIT_NOTES');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_EMPLOYEE') {
         this.dialogForm.get('moduleName').setValue('VIEW_EMPLOYEE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_CANDIDATE') {
        this.dialogForm.get('moduleName').setValue('VIEW_CANDIDATE');
        this.dialogRef.close(this.dialogForm.value);
     } else if (this.moduleName === 'VIEW_APPOINTMENT') {
      this.dialogForm.get('moduleName').setValue('VIEW_APPOINTMENT');
    
      if(this.commonUtils.checkNullOrUndefined(this.dialogForm.get('interviewerNameIdList').value)){
          this.dialogForm.get('interviewerName').setValue(this.dialogForm.get('interviewerNameIdList').value.toString());
      }    
      this.dialogRef.close(this.dialogForm.value);
    } else if (this.moduleName === 'VIEW_INTERVIEW_SLOT') {
        this.dialogForm.get('moduleName').setValue('VIEW_INTERVIEW_SLOT');
        this.dialogRef.close(this.dialogForm.value);
     } else if (this.moduleName === 'VIEW_PAYCODE') {
         this.dialogForm.get('moduleName').setValue('VIEW_PAYCODE');
         this.dialogRef.close(this.dialogForm.value);
      } else if (this.moduleName === 'VIEW_ASSETS') {
         this.dialogForm.get('moduleName').setValue('VIEW_ASSETS');
         this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'VIEW_LOAN') {
         this.dialogForm.get('moduleName').setValue('VIEW_LOAN');
         this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'VIEW_SALES_PERSON') {
         this.dialogForm.get('moduleName').setValue('VIEW_SALES_PERSON');
         this.dialogRef.close(this.dialogForm.value);
      }
       else if (this.moduleName === 'PROJECT_PLAN') {
         this.dialogForm.get('moduleName').setValue('PROJECT_PLAN');
         this.dialogRef.close(this.dialogForm.value);
      }
       else if (this.moduleName === 'VIEW_ARTWORK_REQUEST') {             
         this.dialogForm.get('moduleName').setValue('VIEW_ARTWORK_REQUEST');
         this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'CHART_OF_ACCOUNTS') {
         this.dialogForm.get('moduleName').setValue('CHART_OF_ACCOUNTS');
         this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'VIEW_PRODUCTION_SLIP') 
      {
         this.dialogForm.get('moduleName').setValue('VIEW_PRODUCTION_SLIP');             
         this.dialogRef.close(this.dialogForm.value);
      }
      else if (this.moduleName === 'VIEW_ARTWORK') 
      {
         this.dialogForm.get('moduleName').setValue('VIEW_ARTWORK');             
         this.dialogRef.close(this.dialogForm.value);
      } 
      else if (this.moduleName === 'VIEW_ACCOUNT_BUDGET') 
      {
         this.dialogForm.get('moduleName').setValue('VIEW_ACCOUNT_BUDGET');             
         this.dialogRef.close(this.dialogForm.value);
      }
       else if (this.moduleName === 'PROJECT_PLAN_CLIENT') {
         this.dialogForm.get('moduleName').setValue('PROJECT_PLAN_CLIENT');
         this.dialogRef.close(this.dialogForm.value);
      }else if (this.moduleName === 'VIEW_PRODUCT_SETUP') {
         this.dialogForm.get('moduleName').setValue('VIEW_PRODUCT_SETUP'); 
         this.dialogForm.get('statusFlag').setValue('all');
          this.dialogRef.close(this.dialogForm.value);
      }
      
      
   }

  onNoClick(): void {
      this.dialogRef.close();
   }

  myControl: FormControl = new FormControl();
  myControl3: FormControl = new FormControl();
  myControl4: FormControl = new FormControl();
  myControl5: FormControl = new FormControl();
  myControl6: FormControl = new FormControl();
  myControl7: FormControl = new FormControl();
  myControl8: FormControl = new FormControl();
  myControl9: FormControl = new FormControl();
  myControl10: FormControl = new FormControl();
  myControl11: FormControl = new FormControl();
  myControl12: FormControl = new FormControl();
  myControl13: FormControl = new FormControl();
  myControl14: FormControl = new FormControl();
  myControl15: FormControl = new FormControl();
  date: FormControl = new FormControl(new Date());

}

@Component({
  selector: 'app-configureemail',
  templateUrl: './configureemail.component.html',
  styleUrls: ['./dialogBox.component.css']
})
export class ConfigureEmailComponent extends CommonUtils implements OnInit {

ELEMENT_DATA: EmailElement[] = [
    {name: 'Rajesh', email: 'srajesh@esales.in'}
  ];
dataSource = new MatTableDataSource<EmailElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<EmailElement>(true, []);
  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private router: Router,
   public dialogRef: MatDialogRef<ConfigureEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { super(); }

  newAddContactField = false;
  defaultIconField = true;
  displayedColumns = [];
  disableInput = true;

  
  ngOnInit() {
    if (this.defaultIconField === true && this.newAddContactField === false) {
      this.displayedColumns = ['name', 'email', 'edit', 'delete'];
    } else if (this.defaultIconField === false && this.newAddContactField === true) {
     this.displayedColumns = ['name', 'email', 'save', 'cancel'];
    }
  }

  addNewContacts(): void {
    this.disableInput = false;
    this.ELEMENT_DATA.push({name: '', email: ''});
    this.newAddContactField = true;
    this.defaultIconField = false;
    this.displayedColumns = ['name', 'email', 'save', 'cancel'];
    this.dataSource = new MatTableDataSource<EmailElement>(this.ELEMENT_DATA);
  }

  saveContact(emailelement: any): void {
    this.newAddContactField = false;
    this.defaultIconField = true;
    this.ELEMENT_DATA.push({name: emailelement.name, email: emailelement.email});
    this.displayedColumns = ['name', 'email', 'edit', 'delete'];
    this.dataSource = new MatTableDataSource<EmailElement>(this.ELEMENT_DATA);
  }

  cancel(emailelement): void {
    const index = this.ELEMENT_DATA.indexOf(emailelement);
    if (index >= 0) {
       this.ELEMENT_DATA.splice(index, 1);
       this.dataSource = new MatTableDataSource<EmailElement>(this.ELEMENT_DATA);
     }
     this.newAddContactField = false;
     this.defaultIconField = true;
     this.displayedColumns = ['name', 'email', 'edit', 'delete'];
  }

  editContact(): void {
    this.disableInput = false;
  }

  
}

export interface EmailElement {
   name: string;
   email: string;
}