<div class="Header-values">
  <h3>{{CREATE_TICKET}}</h3>
</div>
<div class="dropdown-divider"></div>
<br>
<form (ngSubmit)="saveTicket()" [formGroup]="ticketForm">
<div class="Formdiv" >
  <mat-form-field class="col-lg-12">
    <input matInput  placeholder="Ticket Name" formControlName="taskName" >
  </mat-form-field>
  <mat-form-field class="col-lg-12">
    <textarea matAutosizeMaxRows="20" matAutosizeMinRows="20" matInput placeholder="{{TICKET_NOTES}}" name="notes" class="textarea-width" formControlName="taskDesc" onfocus="this.select()">   
    </textarea>
  </mat-form-field>
  <div class ="row">
  <div class="col-lg-8 paddingbut" align="left" >
  	<button mat-raised-button color="primary" class="mat-red" [disabled]="!ticketForm.valid">{{SAVE_BUTTON}}</button>
    <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
  </div>
  <div class="col-lg-4" style="margin-top: 2%;" align="right"> 
  <button type="button" mat-raised-button color="primary" (click)="loadTickets()">View All My Tickets</button>   
</div>
</div>
</div>
</form>