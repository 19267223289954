import { Component, OnInit,Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HrmService} from '../hrm-service';
import{CommonService} from '../../shared/common.service';
import {PaginationComponent} from '../../core/view-template/view-pagination/pagination-component';
import {CommonUtils} from '../../core/common-utils';

@Component({
  selector: 'app-viewloantransaction',
  templateUrl: './loan-transaction.component.html'

})
export class LoanTransactionComponent extends CommonUtils implements OnInit {
ELEMENT_DATA: Element[] = [];
  displayed_Columns = ['amountPaid', 'loanDate', 'updatedBy'];
  dataSource = new MatTableDataSource<Element>();
  myForm: FormGroup;
  element;
  loanTransactionList = [];
  recordCount;
  emptyContacts = false;
  pageSize = 10;
 errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
constructor(private dialogRef: MatDialogRef<LoanTransactionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
	private hrmService: HrmService, private commonService: CommonService,public commonUtils:CommonUtils) {
  super();
	this.element = data.element;
this.myForm = new FormGroup({
	loanId: new FormControl(0),
	pageNo: new FormControl('0'),
    pageSize: new FormControl('10'),
    recordCount: new FormControl('0'),
	t811Loan: new FormGroup({loanId: new FormControl(0)})
})
}
ngOnInit() 
{
  /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
        
         if(result==='LOGIN_AVAILABLE')
         {
           this.pageLoad();
         }
        
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
 this.pageLoad();
}
pageLoad():void
{
   try
  {
    if (this.commonUtils.checkNullOrUndefined(this.element)) {
    if (this.element.loanId != 0) {
    this.myForm.patchValue(this.element);
    }
    }

  this.fetchLoanTransaction();
}
       catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue('LOAN_TRANSACTION');
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{});

}

}
 cancelClick() {
	this.dialogRef.close();
}
fetchLoanTransaction() {	
	this.myForm.get('t811Loan.loanId').setValue(this.myForm.get('loanId').value);	
	this.hrmService.fetchLoanTransaction(this.myForm.value).subscribe(
		data => {        
         if (this.commonUtils.checkNullOrUndefined(data)) {
         this.ELEMENT_DATA = data;
             this.dataSource = new MatTableDataSource<Element>(this.ELEMENT_DATA);
              this.recordCount = data[0].totalRecordCount;
             this.emptyContacts = false;
         }
       },

        error => {
        this.commonService.showERPErrorMessage(error);
      }
)
}
}
