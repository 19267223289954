import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HrmService} from '../hrm-service';
import {CommonService} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
 import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-addpaycode',
  templateUrl: './create-paycode.component.html'

})
export class CreatePaycodeComponent extends CommonUtils implements OnInit {
  myForm: FormGroup;  
  createButtonEnable = true;
  PAYCODE_MASTER;
  ADD_PAYCODE;
  PAY_CODE;
  DISPLAY_ORDER;
  SHOW_IN_REPORT;
  CONSIDER_FOR_PF;
  CONSIDER_FOR_ESI;
  CONSIDER_FOR_LOP;
  DEDUCTION;
  SAVE_BUTTON;
  CANCEL_BUTTON;
  DESCRIPTION_PLACEHOLDER;
  editId: number;
  userObj;
  currentlySelectedMenu;
  editAccess = true;
  moduleName : string = environment.NEW_PAYCODE;
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
  constructor(private hrmService: HrmService, public snackBar: MatSnackBar, private router: Router,
    private commonService: CommonService, private commonUtils: CommonUtils,
    public dialogRef: MatDialogRef<CreatePaycodeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  
    this.editId = data.paycodeId;
    this.myForm = new FormGroup({
      payCodeId: new FormControl(0),
      payCode: new FormControl('', Validators.required),
      earnDescription: new FormControl(),
      displayOrder: new FormControl(),
      deduct: new FormControl(false),
      considerForLOP: new FormControl(false),
      showReport: new FormControl(false),
      pFAvailability: new FormControl(false),
      eSIAvailability: new FormControl(false),
      considerForTakeHome:new FormControl(false),
      considerForCTC:new FormControl(false),
      editable:new FormControl(false),
      considerForIT:new FormControl(false),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)})
    }
    )
  }
  ngOnInit() 
  {

  /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
   //  this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu
           this.pageLoad();
           
         }
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
 this.pageLoad();

  }
  pageLoad():void
  {
    try
    {
    if (this.commonUtils.checkNullOrUndefined(this.editId))
    this.fetchPaycode();
   }
catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{});
}
  }
  saveOrUpdatePayCode(): void 
  {
    this.disableFlag=true;
    this.userObj = this.commonService.loggedInUserObj.userId;
    if (this.myForm.get('payCodeId').value === 0 || (!this.commonUtils.checkNullOrUndefined(this.myForm.get('payCodeId').value))) {
      this.myForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    if (this.myForm.get('payCodeId').value != 0) {
      this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }

    this.commonService.showProgressBar();
   
    this.hrmService.saveOrUpdatePayCode(this.myForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
        this.disableFlag=false;
          this.dialogRef.close(true);
         // this.router.navigate(['hrmmodule/viewpaycode']);
        
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
         this.disableFlag=false;
      });
  }

  fetchPaycode(): void {

    this.myForm.get('payCodeId').setValue(this.editId);
    this.hrmService.fetchEditPayCode(this.myForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        if (this.commonUtils.checkNullOrUndefined(data)) {
          this.myForm.patchValue(data);
        }
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      }
    )

  }


  cancelClick(): void {
    this.dialogRef.close();
  }
  
}
