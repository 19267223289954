
import { Component, OnInit, OnDestroy, ViewChild, Input,Output,EventEmitter,HostListener} from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroup, NgForm, FormBuilder,FormArray} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DialogOverviewExampleComponent,SearchInGridDialogComponent} from '../../dialogBox/dialogBox.component';
import {ImportContactsDialog} from '../../import/importContacts.component';
import {ExportPODialogComponent} from '../../export/exportpo.component';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component'; 
import {SettingsService} from '../../settings/settings-service';
import {CommonService} from '../../shared/common.service';
import {Subscription} from 'rxjs/Rx';
import {PaginationComponent} from '../../core/view-template/view-pagination/pagination-component';
import{ViewHistoryComponent} from '../../core/view-template/view-history/view-history.component';
import {HrmService} from '../../hrm/hrm-service';
import {RecruitmentService} from '../../recruitment/recruitment-service';
import {CommonUtils} from '../common-utils';
import {environment} from '../../../environments/environment';
import { Title }  from '@angular/platform-browser';
import {ViewTemplateInterface} from './interface/view-template-wrapper-interface';
import {EmailDialogComponent} from '../../dialogBox/dialogBox.component';
import {Store} from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromTemplateActions from '../../store/actions/createtemplate.actions';
import {InterviewAllotmentComponent} from '../../recruitment/candidate/interviewallotment/interviewallotment.component';
import { AssetTrackingService } from '../../assettracking/assettracking-service';

@Component({
  selector: 'app-view-template-wrapper',
  templateUrl: './view-template-wrapper.component.html',
  providers: [ 
  ],
})
export class ViewTemplateWrapperComponent extends CommonUtils implements OnInit,OnDestroy,ViewTemplateInterface {
   
detailObj;
detailId;
candidateId;
 keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
  resourceExtensionList;
  allResourceExtensionList;
  action;
  resourceExtensionError={error:'Resource already extended for this project'};
  appraisalLevelassociationError={error:'Appraisal level already added for the resource'};
  selectedProjectId ;
  versionNumber;
  projectId;
  popupCount:number=0;
  resourceAlreadyExistList=[];
  selectedUserObj: any;
  allAppraisalLevelList =[];
  popupResourceExtensinList;
  attachPdfInEmail;
  productSetupRefreshClick : boolean = false;
  portalName :string;
  cartEnable;
arrayListIsOne: string;
   billOpenStatus=false;
   showConvertOpen=false;
   showConvertTask=false;
   showConvertSent=false;
   showConvertVoid=false;
   showVoidFlag=true;
   emailShowFlag=false;
   showFirstPanel = true;
   showBreadCrumb = false;
   showShipmentFlag = false;
   showSecondPanel = false;
   billStatus = true;
   orgUsersList;
   versionNumberList;
   appraisalLevelList;
   estimateAcceptStatus=false;
   invoiceConvertFlag=false;
   salesConvertFlag=false;
   convertInvoice=false;
   convertInvoiceFlag=false;
   multiplePDFPackage=false;
   showVoidButton=false;
   showConvertWIP=false;
   convertVoidFlag=false;
   showProductionSlipStatus=false;
   closeTask=false;
   breadcrumFlag =false;
   myForm: FormGroup;
   invoiceForm:FormGroup;
   stockRequestForm:FormGroup;
   dialogForm:FormGroup;
   invoiceNumber;
   balanceDue;
   toApproveList = [];
   //contactsArray = [];
   //contactsArrayList = [];
   convertToVoidArray=[];
   convertToNonVoidArray=[];
   contactsList:FormArray;
   sortedData = [];
   recordCount;
   pageSize=10;
   pageNo=0;
   selectedTaskObj;
   selectedProjectObj;
   selectedFilterObj;
   selectedTaskType;
   selectedGlobalFlag : boolean = false;
   paginationFlag : boolean = false;
   refreshFlag : boolean = false;
   breadCrumblist=[];
   bulkUpdateResult: string;
   t100ContactCustomerId: FormGroup;
   isEmptyGrid=false;
   taskOpenCount:number=0;
   draftCount:number=0;
   openCount:number=0;
   othersCount:number=0;
   soVoidCount:number=0;
   otherCount:number=0;
   othersOpenCount:number=0;
   sentCount:number=0;
   othersSentCount:number=0;
   voidCount:number=0;
   othersVoidCount:number=0;
   othersTaskCount:number=0;
   contactsSearch = this.commonUtils.contactsSearch;
    currencySymbol: string;
    templateError={error:'Template not found'};
    convertToVoidError={error:'Refunded credits and credits that are applied to bill(s) cannot be deleted'};
    purchaseId;salesOrderId: number;
    loggedInUserObjRole;
    //myForm2: FormGroup;
    displayForm:FormGroup;
    userDisplayForm:FormGroup;
    errorFormGroup:FormGroup;
    t401InvoiceDetailsList:FormArray;
    methodName: string;
     myControl: FormControl = new FormControl();
    myControl1: FormControl = new FormControl();
    moreActions = this.commonUtils.moreActions;
    dialogText: string;
    confText: string;
    moreActionValue: string;
    ELEMENT_DATA: any[] = [];
  displayedColumns = [];
  userLabelColumn;
  quickInfoLabels;quickInfoValues = [];
  itemsGroup=[];
  displayedColumnsFromDB;
  accessObjForModule;
  displayed_Columns1 = this.commonUtils.displayedColumns1;
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
   selection = new SelectionModel<any>(true, []);
  employeeId=0;
editAccessObj;
searchForm;
dateFormat;
selectedOption;
schemaValue;
userDisplayedColumns = [];
cloneSearchIconFalg: boolean=true;
tempModuleNameForItem;
searchString;
noRecord: boolean=false;
projectList = [];
@Input() viewChildAssetFlag: boolean;

@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

    if(this.keyCode==83 && this.ctrlKey){
       event.preventDefault();
    event.stopPropagation();
 this.searchIconClick();
      
  }else   if(this.keyCode==82 && this.ctrlKey){
     event.preventDefault();
    event.stopPropagation();
    this.refreshPage({action:"REFRESH_GRID",resetFilter:true});       
  }
    
   
  }
   @Input() displayHeaderLabel:boolean=true;
   @Input() displayDefaultTop:boolean=true;
   @Input() showMenuShortcuts:boolean=false;
   @Input() loadAsTab:boolean=false;
   @Input() moduleName: string='' ;
   @Input() editHideFlag: boolean;
   @Input() screenTitle: string;
   @Input() refForeignKeyId:any=0;
   @Input() refForeignKeyId1:any=0;
   @Input() refContactId:any=0;
   @Input() refItemId:any=0;
   @Input() searchList: any[];
   @Input() showPagination:boolean=true;
   @Input() templateCSSHeightFlag: string;
   @Input() tempModuleForItem: string ;
   @Input() contactType:string;
   @Output() viewTemplateWrapperEmitter=new EventEmitter<any>();

   @Input() styleNumber: string;
   @Input() colorName: string;
   @Input() itemNumber: string;
   @Input() searchFlag: boolean;
   @Input() deleteCancelFlag: boolean;
 
   //projectForm:FormGroup;
   

SERVICE_URL;
   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(PaginationComponent) PaginationComponentObj: PaginationComponent;
   
   subscription: Subscription;

 currentlySelectedMenu;
 createAccess= true;
 estimateId;
 partRequestId;
 subscriptionList: Array<Subscription> = [];
 currentPageAccessObj;
 convertAccessObj;
 convertFlag=false;
 filterSelectedOption; 
 recordPaymentFlag=false;
 employeeObj;
 employeeForm=new FormGroup({
 employeeId: new FormControl(0),
 userName: new FormControl(''),
  })

 
  
  applyLeaveForm = new FormGroup({
    moduleName: new FormControl(this.moduleName) ,
    clAvailable: new FormControl(''),
    slAvailable: new FormControl(''),
    totalAvail: new FormControl(''),
    compOffAvailable: new FormControl(''),
    slApproved: new FormControl(''),
    clApproved: new FormControl(''),
    compOffApproved: new FormControl(''),
   t800EmployeeObj: new FormGroup({employeeId: new FormControl(this.employeeId)}),
    t004LookupLeaveTypeObj:new FormGroup({
    lookupId: new FormControl(''),
    lookupValue:new FormControl('')}),
    t004LookupStatusObj:new FormGroup({
    lookupId: new FormControl(''),
    lookupValue:new FormControl('')}),
    month : new FormControl(''),
    startDate :new FormControl(new Date()),
    endDate : new FormControl(new Date()),
    leaveReason: new FormControl(''),
    leaveHistoryId : new FormControl(0),
    totalAppliedDays: new FormControl(''),
    leaveId : new FormControl(''),
    appliedPermission : new FormControl(''),
    appliedPermissionHours:new FormControl(''),
    appliedPermissionMinutes:new FormControl(''),
    requestFlag :new FormControl(''),
    empId : new FormControl(this.employeeId),
     slApplied: new FormControl(''),
      clApplied: new FormControl(''),
      approverId: new FormControl(''),
      pageNo: new FormControl(this.pageNo),
        pageSize: new FormControl(this.pageSize),
        recordCount: new FormControl('0'),
       trxType: new FormControl('')
 })

timesheetForm = new FormGroup({
    moduleName: new FormControl(this.moduleName) ,
    taskNumber: new FormControl(''),
    taskName: new FormControl(''),
    workedHours: new FormControl(''),
    workedDate: new FormControl(''),
    taskDescription: new FormControl(''),
    createdDate: new FormControl(''),
  });
  candidateForm = new FormGroup({
    candidateId: new FormControl(),
    candidateCode: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    secondaryEmail: new FormControl(),
    mobile: new FormControl(),
    secondaryMobile: new FormControl(),
    address: new FormControl(),
    city: new FormControl(),
    state: new FormControl(),
    country: new FormGroup({
        countryId: new FormControl()
      }),
      profileSummary: new FormControl(),
      source: new FormGroup({
        lookupCode: new FormControl,
        lookupId: new FormControl(),
        lookupValue: new FormControl(),
      }),
      referral: new FormGroup({
        lookupCode: new FormControl,
        lookupId: new FormControl(),
        lookupValue: new FormControl(),
      }),
      facebookId: new FormControl(),
      linkedInId: new FormControl(),
      educationName: new FormControl(),
      instituteName: new FormControl(),
      result: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      employeeName: new FormControl(),
      designation: new FormControl(),
      role: new FormControl(),
      empStartDate: new FormControl(),
      empEndDate: new FormControl(),
      currentWork: new FormControl(),
      createdTS: new FormControl(),
      updatedTS: new FormControl(),
      t001UserCreatedBy: new FormGroup({
        userId: new FormControl()
      }),
      t001UserUpdatedBy: new FormGroup({
        userId: new FormControl()
      }),
      // t012Language : new FormGroup({
      //   langId: new FormControl(0), 
      //   name: new FormControl()
      // }),
      currentSalary: new FormControl(),
      expectedSalary: new FormControl(),
      noticePeriod: new FormControl(),
      reasonForJobChange: new FormControl(),
      industry: new FormGroup({
        lookupCode: new FormControl,
        lookupId: new FormControl(),
        lookupValue: new FormControl(),
      }),
      jobApplication: new FormControl(),
      otherDetails: new FormControl(),
      jobSiteName: new FormControl(),
      searchKeyword: new FormControl(),
      totalYearsOfExp: new FormControl(),
      currentLocation: new FormControl(),
      preferredLocation: new FormControl()
  });




   constructor(  private router: Router, public dialog: MatDialog,
     private activatedRoute: ActivatedRoute, 
     private formBuilder: FormBuilder, private settingsService: SettingsService, private commonService: CommonService, 
    private hrmService: HrmService, private recruitService:RecruitmentService,
     private commonUtils: CommonUtils,private assetTrackingService: AssetTrackingService,
      private titleService: Title,  private route: ActivatedRoute,
      private store:Store<fromStore.CreateTemplateStates>
     ) {

 super();
       this.displayForm = new FormGroup
       ({

        moduleName: new FormControl(this.moduleName),

       })
       this.userDisplayForm = new FormGroup
       ({
        columnId: new FormControl(0),
        t001UserCreatedBy:new FormGroup({
          userId:new FormControl(0)
        }),
        moduleName: new FormControl(this.moduleName),
        columnName: new FormControl(''),
        lookupIds: new FormControl('')

       });

        this.errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
         
}
 
   ngOnInit() 
   { 
    try
    {
/**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/

      let subscriptionObj=this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
          if(result=='LOGIN_AVAILABLE')
         { 
            this.subscriptionList[0].unsubscribe();
            this.commonService.cleanUpListener(this.subscriptionList);
            this.currentPageAccessObj = this.commonService.getAccessObjForCurrentScreen(this.moduleName);
           
             if(this.commonUtils.checkNullOrUndefined(this.currentPageAccessObj)){
               this.pageLoad();
            }else
            this.commonService.emitInvalidLogin();   
           }          
        });
     this.subscriptionList.push(subscriptionObj)

       this.route.params.subscribe(params => {
         this.detailId = +params['detailId'];
           
          
         this.idChangedFunction(this.route.snapshot.params); 
       });
   
/** code ends above **/ 
this.loggedInUserObjRole = this.commonService.loggedInUserObj.roleName;
        if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
            this.currentPageAccessObj =this.commonService.getAccessObjForCurrentScreen(this.moduleName);
      if(this.commonUtils.checkNullOrUndefined(this.currentPageAccessObj)){
          this.commonService.currentSelectedModuleBasedAccess.set("CURRENT_MODULE_ACCESS",this.currentPageAccessObj);
         this.pageLoad();  
        }
     }  
    
  }
   catch(error)
   {
    
     this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{
      
       },
       error=>{
     
       });

   }

   
    }
parentTaskId;
  idChangedFunction(params:any):void
  {

    this.parentTaskId = params.parentTaskId;
    
    if(this.commonUtils.checkNullOrUndefined(this.parentTaskId))
    {
      let element={taskId:this.parentTaskId};
      this.onParentTaskIconClick(element);
}

}
  sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

refreshPage(value:any)
{

  if(value.action==='REFRESH_PAGE')
  { 

    this.viewGridLoad();
  }
  else if(value.action === 'REFRESH_GRID')
   {
     if(this.moduleName === 'VIEW_RESOURCE_EXTENSION'){
         this.refreshFlag=true;
         this.selectedProjectId ="";
         this.selectedUserObj="";
         this.invoiceForm.get('t822ProjectObj.projectId').setValue(0);
         this.invoiceForm.get('t001_UserObj.userId').setValue(0);
     }
     if(this.moduleName === 'VIEW_PRODUCT_SETUP'){
       
       this.productSetupRefreshClick = true;
       this.searchFlag = false;
     }

     if(value.resetFilter){
    let lookupList = [];
     if(this.commonService.userDisplayedColumnsMap.has(this.moduleName)){
       if(this.commonUtils.checkNullOrUndefined(this.commonService.userDisplayedColumnsMap.get(this.moduleName).lookupIds))
         lookupList = this.commonService.userDisplayedColumnsMap.get(this.moduleName).lookupIds.split(",");
     }
       this.selectedTaskObj = {};
       this.selectedFilterObj = ""; //Filter object set to empty on 07, Feb 2023 by 9121
       this.commonService.bindOldChangesInModule(this.moduleName,"");
       this.invoiceForm=this.getSaveForm(this.moduleName);
       this.invoiceForm.get('t823ParentTask.taskId').setValue(0);
       this.pageNo=0;
       this.invoiceForm.get('pageNo').setValue(this.pageNo);
       this.invoiceForm.get('pageSize').setValue(this.pageSize);
       this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm);
       if(this.commonUtils.checkNullOrUndefined(lookupList) && lookupList.length > 0){
        for(var loop=0; loop<lookupList.length; loop++)
          (this.invoiceForm.get('lookUpIdList') as FormArray).push(this.lookupList(lookupList[loop],''));
      }
        this.viewGridLoad();
      
    }
     
   }
   else if(value.action==='EXPORT_DATA')
   {

   if(this.commonUtils.checkNullOrUndefined(value) && this.commonUtils.checkNullOrUndefined(value.data)){
       this.invoiceForm.get('moduleType').setValue(value.data);
     }
   
     this.invoiceForm.get('dateFormat').setValue(this.dateFormat);
     this.invoiceForm.get('moduleName').setValue(this.moduleName);
     const dialogRef = this.dialog.open(ExportPODialogComponent, {
      width: '800px',
      data: {invoiceForm:this.invoiceForm}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
   }
   else if(value.action==='REFRESH_RESOURCE' && this.commonUtils.checkNullOrUndefined(value.data)) 
   {
     this.selectedProjectId = value.data;
     this.invoiceForm.get('t822ProjectObj.projectId').setValue(value.data);
     this.screenTitle="Manage Resource Extension";
     this.fetchResourceExtension();
     this.fetchAllResourceExtensionProjectWise();
   }
   else if(value.action==='REFRESH_TASK') 
   {
     if(this.commonUtils.checkNullOrUndefined(value))
       this.selectedProjectObj = value;
       this.fetchProjectPlan();
   }
   else if (value.action === 'REFRESH_RESOURCE_BASED_PROJECT' && this.commonUtils.checkNullOrUndefined(value.data)) {
      this.selectedUserObj = value.data;
      this.invoiceForm.get('t001_UserObj.userId').setValue(value.data);
      this.screenTitle = "Manage Resource Extension";
      this.fetchResourceExtension();
      this.fetchAllResourceExtensionProjectWise();
    }

}

  pageLoad():any
    {
      this.commonService.setTitle(this.titleService,this.commonUtils.getPageTitle('VIEW_CONTACT'));
      this.SERVICE_URL=this.commonService.SERVICE_URL;
      this.portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
      this.cartEnable=this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.enableCart;
           
if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);

                this.pageSize=this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.defaultPageSize;
             
           //Adding below line to fix the pagination issue of setting the default value 
            this.invoiceForm.get('pageSize').setValue(this.pageSize);
       
               this.initializePagination();
               this.initializeEmitChanges();
               let pageHeaderLabelPromise=this.loadPageHeaderLabels();
                  
               let reviewDisplayColumnPromise=this.resolvePromise(pageHeaderLabelPromise);
               reviewDisplayColumnPromise.then(data=>{

                 this.viewGridLoad();
               })
            //  this.viewGridLoad();
               this.editAccessObj=this.currentPageAccessObj.editAccess;
              // this.initializeAccessForModule();
              this.dateFormat=this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;
           if(this.moduleName === 'PROJECT_PLAN'  || this.moduleName === 'PROJECT_PLAN_CLIENT'){
              this.showBreadCrumb = true;
              if(this.router.url === '/projectmodule/projectplan' || this.router.url === '/projectmodule/viewprojectplan')
                this.commonService.breadCrumbList=[];
           }      
     }

     resolvePromise(pageHeaderLabelPromise):Promise<any>{
     return new Promise( resolve=>{ 
          pageHeaderLabelPromise.then(data=>{
      if(this.commonService.reviewDisplayedColumnsMap.get(this.moduleName) == null){
          let displayForm = new FormGroup
             ({ 
                 moduleName: new FormControl(''),

             })
             this.commonService.fetchAllReviewDisplayColumn(displayForm.value).subscribe(
             data => 
              {  
                            
                if (data.length > 0)
                 {   
                                                 
                   for(var loop=0;loop<data.length;loop++){
                     this.commonService.columnLabelMap = new Map();
                     this.commonService.reviewDisplayedColumnsMap.set(data[loop].moduleName,data[loop].columnName);

                       if(this.commonUtils.checkNullOrUndefined(data[loop].columnName) && 
                         this.commonUtils.checkNullOrUndefined(data[loop].labelName)){
                         let columnStringArray:[]=data[loop].columnName.split(',');
                         let labelStringArray:[]=data[loop].labelName.split(',');
                         for(var insideLoop=0; insideLoop<columnStringArray.length;insideLoop++){
                             this.commonService.columnLabelMap.set(columnStringArray[insideLoop],labelStringArray[insideLoop]);
                         }
                        this.commonService.userLabelMap.set(data[loop].moduleName,this.commonService.columnLabelMap);
                     }

                     if(this.commonUtils.checkNullOrUndefined(data[loop].dispValue)){
                       this.commonService.appScreenList.push(data[loop]);
                       this.commonService.appScreenMap.set(data[loop].moduleName,data[loop]);
                     }
                    }
                    
                 this.bindDisplayedColumns();
                 this.loadDataForQuickInfo(); 
                 resolve(data);
                 //this.viewGridLoad(); 
                }  
            
         },error => {
           this.commonService.showERPErrorMessage("Error fetching the Column header");
            resolve(data);
         });

           }
           else{
               this.bindDisplayedColumns();
               this.loadDataForQuickInfo();
             resolve(data);
               //this.viewGridLoad();
           }
  });
     
   });
   }
loadDataForQuickInfo()
{
    
  this.displayForm.get('moduleName').setValue('QI_'+this.moduleName);
  this.fetchQuickInfoData();
}

loadPageHeaderLabels():Promise<any>{
     
   this.displayForm.get('moduleName').setValue(this.moduleName);
   //this is for user preference display column according to the loggedIn user
   this.userDisplayForm.get('moduleName').setValue(this.moduleName);
  
    let promise= this.fetchAllUserDisplayColumn();
    return promise;
   


}
fetchAllUserDisplayColumn():Promise<any>
{
  let userDisplayForm = new FormGroup
       ({ 
        moduleName: new FormControl(''),
        t001UserCreatedBy: new FormGroup({userId: new FormControl(this.commonService.loggedInUserObj.userId)})
       })
   return new Promise(resolve=>
     this.commonService.fetchAllUserDisplayColumn(userDisplayForm.value).
      subscribe(
       data =>{
         
         if (data.length > 0)
         {
             for(var loop=0;loop<data.length;loop++){
                this.commonService.userDisplayedColumnsMap.set(data[loop].moduleName,data[loop]);
            }
          let lookupIds = [];
                 
          if(this.commonService.userDisplayedColumnsMap.has(this.moduleName) && 
            this.commonUtils.checkNullOrUndefined(this.commonService.userDisplayedColumnsMap.get(this.moduleName).lookupIds))
            lookupIds = this.commonService.userDisplayedColumnsMap.get(this.moduleName).lookupIds.split(",");


           
          
         
        this.invoiceForm.get('lookUpIdList').reset();
          let lookupList = this.invoiceForm.get('lookUpIdList') as FormArray ;
                 
          if(this.commonUtils.checkNullOrUndefined(lookupIds) && lookupIds.length > 0)
          {
            for(var loop=0; loop < lookupIds.length; loop++){
              lookupList.push(this.lookupList(lookupIds[loop],''));
            }          
          } 
          
           this.bindDisplayedColumns();
           this.loadDataForQuickInfo();

           resolve(data);   
         }
         else{
           resolve(data);
         }
       },

       error=>{

       })
      )

}
//viewgridloadmethod
viewGridLoad() 
{
// this.store.dispatch(new fromTemplateActions.ShowProgressBar());
  this.commonService.notificationChangedSubject.next("REFRESH_NOTIFICATION");
  // load the default filter values for the users
  let lookupIDs = this.invoiceForm.get('lookUpIdList') as FormArray;
if(this.commonUtils.checkNullOrUndefined(lookupIDs) && lookupIDs.length > 0){
  //no changes . Keep the lookup ids as it is in the list
  let oldValues = this.commonService.recordOldChangesForEachModuleMap.get(this.moduleName);
   if(this.commonUtils.checkNullOrUndefined(oldValues))
   {
      this.pageSize =oldValues.pageSize;
      this.pageNo = oldValues.pageNo; 
    }
    this.invoiceForm.get('pageSize').setValue(this.pageSize);
    this.invoiceForm.get('pageNo').setValue(this.pageNo);

}
else{
  // ********** This code is added for Sticky grid load *************
  // **** START - LOAGING LAST CHANGES ****** 
   let oldValues = this.commonService.recordOldChangesForEachModuleMap.get(this.moduleName);
  
        if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
            this.invoiceForm=this.getSaveForm(this.moduleName);

       if(this.commonUtils.checkNullOrUndefined(this.currentPageAccessObj) && this.currentPageAccessObj.viewAllRecords === false)
       {
         if(this.moduleName=='VIEW_PACKAGES')
          this.invoiceForm.get('createdById.userId').setValue(this.commonService.loggedInUserObj.userId);
          else 
        this.invoiceForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
        
       }
       if(this.commonUtils.checkNullOrUndefined(oldValues))
       {
            this.invoiceForm.patchValue(oldValues);
            this.filterSelectedOption = this.invoiceForm.get('t004_LookupObj').get('lookupId').value;
            this.pageSize = this.invoiceForm.get('pageSize').value;
            this.pageNo = this.invoiceForm.get('pageNo').value;  
        }
        this.invoiceForm.get('pageSize').setValue(this.pageSize);

         /* this.invoiceForm.get('refForeignKeyId').setValue(this.refForeignKeyId);
          this.invoiceForm.get('refForeignKeyId1').setValue(this.refForeignKeyId1);
          
          this.invoiceForm.get('loadAsTabFlag').setValue(this.loadAsTab);*/
           
  // **** END - LOAGING LAST CHANGES ****** 
}
if(this.commonUtils.checkNullOrUndefined(this.refForeignKeyId))
{
  this.invoiceForm.get('refForeignKeyId').setValue(this.refForeignKeyId);
}

/*this is set to fetch the item list based on the given style - did for nistar marketing - starts*/

if(this.productSetupRefreshClick ===true || this.deleteCancelFlag === true){
  this.invoiceForm.get('styleNumber').setValue('');
  this.invoiceForm.get('mpn').setValue('');
  this.invoiceForm.get('itemNumber').setValue('');
}else if(this.searchFlag === true){
  
  this.invoiceForm.get('asin').setValue('');
  this.invoiceForm.get('t054brandObj.brandId').setValue(0);
  this.invoiceForm.get('imagesConfirmationFlag').setValue('');
  this.invoiceForm.get('t207_SubCategoryObj.subCategoryId').setValue(0);
  this.invoiceForm.get('t207_SubCategoryObj.subCategoryName').setValue('');
  this.invoiceForm.get('t206_CategoryObj.categoryId').setValue(0);
  this.invoiceForm.get('t206_CategoryObj.categoryName').setValue('');
  this.invoiceForm.get('upc').setValue('');
  this.invoiceForm.get('ean').setValue('');
  this.invoiceForm.get('t071GenderId.genderId').setValue(0);
  this.invoiceForm.get('invoiceStartDate').setValue('');
  this.invoiceForm.get('invoiceEndDate').setValue('');
  this.invoiceForm.get('fullColorName').setValue('');
  this.invoiceForm.get('fullSizeConfirmationFlag').setValue('');
  this.invoiceForm.get('salesRateConfirmationFlag').setValue('');
  this.invoiceForm.get('purchaseRateConfirmationFlag').setValue('');
  this.invoiceForm.get('conditionId.lookupId').setValue(0);
  this.invoiceForm.get('featureConfirmationFlag').setValue('');
  this.invoiceForm.get('scCreatedFlag').setValue('');
  this.invoiceForm.get('salesDescription').setValue('');
  this.invoiceForm.get('t001UserCreatedBy.userId').setValue(0);
  this.invoiceForm.get('validationString').setValue('');
      

       if(this.commonUtils.checkNullOrUndefined(this.styleNumber))
    {
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('styleNumber').setValue(this.styleNumber);
    }else if (this.styleNumber === ''){
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('styleNumber').setValue(this.styleNumber);
    }

    if(this.commonUtils.checkNullOrUndefined(this.colorName))
    {
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('mpn').setValue(this.colorName);
    }else if (this.colorName === ''){
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('mpn').setValue(this.colorName);
    }

    if(this.commonUtils.checkNullOrUndefined(this.itemNumber))
    {
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('itemNumber').setValue(this.itemNumber);
    }else if (this.itemNumber === ''){
      this.invoiceForm.get('statusText').setValue(true);
      this.invoiceForm.get('itemNumber').setValue(this.itemNumber);
    }
 }

/*this is set to fetch the item list based on the given style - did for nistar marketing - ends*/

if(this.commonUtils.checkNullOrUndefined(this.refForeignKeyId1))
{
  this.invoiceForm.get('refForeignKeyId1').setValue(this.refForeignKeyId1);       
}
if(this.commonUtils.checkNullOrUndefined(this.loadAsTab))
{
 this.invoiceForm.get('loadAsTabFlag').setValue(this.loadAsTab);    
}
if(this.commonUtils.checkNullOrUndefined(this.refContactId))
{
  this.invoiceForm.get('refContactId').setValue(this.refContactId); 
}
if(this.commonUtils.checkNullOrUndefined(this.refItemId))
{
  this.invoiceForm.get('refItemId').setValue(this.refItemId); 
}
if(this.commonUtils.checkNullOrUndefined(this.contactType))
{
  this.invoiceForm.get('contactType').setValue(this.contactType); 
} 
    if (this.moduleName === 'VIEW_EMPLOYEE') {
           this.screenTitle="Manage Employees";
          this.fetchEmployee();

      }  else if(this.moduleName === 'REVIEW_TRAINEE'){
        this.screenTitle='Manage Trainee'
        this.fetchTraineeDetials(); 
      } 
      else if (this.moduleName === 'VIEW_PAYCODE') {
           this.screenTitle="Manage Paycode";
     this.fetchPaycode();
      }else if (this.moduleName === 'VIEW_LOAN') {
           this.screenTitle="Manage Loans";
      this.fetchLoanList();
      }
      else if(this.moduleName==='VIEW_PROJECT'){
         
        this.screenTitle="Manage Project";
        this.fetchProject();
      }

      else if(this.moduleName==='VIEW_JOB'){
         
        this.screenTitle="Manage Jobs";
        this.fetchJobs();
      }
      else if(this.moduleName==='VIEW_ORGANIZATION'){
         
        this.screenTitle="Manage Organization";
        this.fetchOrganization();
      }
      else if(this.moduleName === 'APPROVE_LEAVE'){
        this.screenTitle="Approve Leave";
      this.fetchEmployeeDetails();
        //this.fetchAllAppliedLeaves();
      }
      else if(this.moduleName === 'VIEW_LEAVE'){
        this.screenTitle="My Leave";
      this.fetchEmployeeDetails();
        //this.fetchAllAppliedLeaves();
      }
       else if(this.moduleName === 'VIEW_USER'){
        this.screenTitle="Manage User";
        this.fetchOrgUsers();
      } else if(this.moduleName === 'VIEW_ROLE'){
        this.screenTitle="Manage Roles";
        this.fetchRoles();
      }
      else if(this.moduleName === 'VIEW_ISSUES')
       {
        this.screenTitle="Manage Feedback";
     //   this.invoiceForm.get('clientViewable').setValue(true);
        //if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.projectId) && this.selectedProjectObj.projectId!=0)
        //  this.fetchProjectPlan();
        this.fetchIssues();
      }
       else if(this.moduleName === 'VIEW_FEEDBACK')
       {
        this.screenTitle="Manage Feedback";
        this.invoiceForm.get('clientViewable').setValue(true);
        //if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.projectId) && this.selectedProjectObj.projectId!=0)
         this.fetchProjectPlan();
       // this.fetchIssues();
      }
      else if(this.moduleName === 'VIEW_TICKETS')
       {
        this.screenTitle="View Tickets";
        this.invoiceForm.get('clientViewable').setValue(true);
          this.fetchProjectPlan();
      }
      else if(this.moduleName === 'VIEW_LICENSE')
       {
        this.screenTitle="Manage Licenses";
        this.fetchLicense();
      } 
      
        else if(this.moduleName === 'VIEW_WAREHOUSE')
       {
        this.screenTitle="Manage Warehouse";
        this.fetchWarehouse();
      }
       else if(this.moduleName === 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
       {
        this.screenTitle="Manage Project Plan";

          /*if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)
         &&this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.projectId) && this.selectedProjectObj.projectId!=0)*/
       if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.value)){
         this.fetchProjectPlan();
       }
        else
        this.fetchProjectPlanEmptyGrid();
      }

      else if(this.moduleName === 'VIEW_TIMESHEET' )
       {
        this.screenTitle="Time Sheet";
        //for timesheet load all tasks 
        this.selectedTaskType='A';    
        //this.fetchProjectPlan();
        this.fetchUserBasedTimeSheet();
      }
       else if(this.moduleName === 'VIEW_HRIS_TIMESHEET')
       {
        this.screenTitle="Time Sheet";
        //for timesheet load all tasks 
        this.selectedTaskType='A';    
        this.fetchProjectPlan();
        
      }
      else if(this.moduleName === 'TIMESHEET_HISTORY' || this.moduleName === 'HRIS_TIMESHEET_HISTORY')
      {
        this.screenTitle="Time Sheet History";
        this.fetchTimeSheet();
      }
      else if(this.moduleName === 'VIEW_BATCH_SYNC')
       {
         this.screenTitle="Manage Batch Sync";
        this.fetchBatchSync();
      }
      else if(this.moduleName==='VIEW_DATABASE')
      {
        this.fetchDataBase(this.versionNumber);
        this.fetchVersionNumber();
      }else if (this.moduleName === 'MY_LOAN') {
           this.screenTitle="My Loans";
      this.fetchLoanList();
     }
     else if (this.moduleName === 'VIEW_RECONCILIATION') {
           this.screenTitle="Reconciliation";
      
     }
     else if (this.moduleName === 'REVIEW_MEDICAL_BILL') {
           this.screenTitle="Medical Bill";
           this.fetchAppliedMedicalBill();
     }
      else if (this.moduleName === 'VIEW_AUTOMATED_EMAIL') {
           this.screenTitle="Manage Automated Email Script";
           this.fetchAutomatedEmailDetails();
     }
      else if(this.moduleName === 'VIEW_TCLINKMASTER')
       {
         this.screenTitle="Manage TestCase Link Master";
        this.fetchTCLinkMaster();
      }
       else if (this.moduleName === 'VIEW_RESOURCE_EXTENSION') 
      {
        this.screenTitle="Manage Resource Extension";
        this.fetchOrgUsersForDropDown();
        this.fetchResourceExtensionEmptyGrid();
        if(this.paginationFlag == false && this.refreshFlag == false)
        {
          this.fetchAllResourceExtensionProjectWise();
        }
      
     }
      else if (this.moduleName === 'VIEW_MENU') 
       {

           this.screenTitle="Menu";
           let promise= this.fetchSuperadminMenu();
             promise.then(data=>
            {
            }
           ); 
     }
      else if (this.moduleName === 'VIEW_REVIEW_DISPLAY_COLUMN') 
       {
           this.screenTitle="Review Display Column";
           this.fetchReviewDispayColumn();
     }


 else if (this.moduleName === 'VIEW_VALIDATION') 
       {
           this.screenTitle="Validation";
           this.fetchSuperadminValidation();
     }
      else if(this.moduleName === 'VIEW_APPRAISAL_MASTER'){
        this.screenTitle="Manage Appraisal Master";
        this.fetchAppraisalMaster();
      }
      else if (this.moduleName === 'VIEW_SHIFT_MASTER') {
       this.screenTitle="Shift Master";
       this.fetchShiftMaster();
      }
      else if(this.moduleName === 'VIEW_SHIFT_ALLOCATION'){
        this.screenTitle = "Employee Shift Allocation";
        this.fetchShiftAllocation();
      }
      else if (this.moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL') 
       {
           this.screenTitle="Employee Appraisal Association";
           this.fetchOrgUsersForDropDown();
           this.fetchEmployeeAppraisalLevel();
           this.fetchAppraisalMasterForDropDown();
         
       }

       else if (this.moduleName === 'VIEW_APPRAISAL_FORM') 
       {
           this.screenTitle="Appraisal Form";
           this.fetchEmployeeAppraisalLevel();
       }    
       else if (this.moduleName === 'VIEW_ASSETS') {

        this.screenTitle = "Manage Assets";
        this.fetchAssetList();
      }
      else if (this.moduleName === 'VIEW_WAREHOUSE') {
        this.screenTitle = "Manage Warehouse";
        this.fetchWarehouse();
      }
       else if(this.moduleName === 'TS_VIEW_PO')
       {
          
           if(this.commonUtils.checkNullOrUndefined(this.searchList) && (this.searchList.length!=0))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
            
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_BILL')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
            
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_R_BILL')
       {
     
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
             this.searchString = 'topSearch';
             
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_EXPENSE')
       {
       
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_R_EXPENSE')
       {

           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
   
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_PAYMENT_MADE')
       {
     
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
       
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_VENDOR_CREDIT')
       {
   
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
   
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_SALESORDER')
       {

           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
           
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_ESTIMATE')
       {
          
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
         
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_ARTWORK')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';          
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_PRODUCTION_SLIP')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
            
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_PACKAGES')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
            
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_INVOICE')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
             
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_R_INVOICE')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
             
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_PAYMENT_RECIEVED')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
       
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_CREDIT_NOTES')
       {
        
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
             
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_ITEM')
       {
          
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
              this.tempModuleNameForItem =this.tempModuleForItem;
            
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_CATEGORY')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
           
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_PRICE_LIST')
       {
     
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
         
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_ITEMS_GROUP')
       {
    
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
         
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_CONTACT')
       {
        
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
          
           }
        
       }
       else if(this.moduleName === 'TS_VIEW_SALES_PERSON')
       {
         
           if(this.commonUtils.checkNullOrUndefined(this.searchList))
           {
              this.dataSource = new MatTableDataSource<any>(this.searchList);
              this.searchString = 'topSearch';
            
           }
        
       }
         
      else if (this.moduleName === 'MANAGE_FEEDBACK_MYCORNER')
      {
        this.screenTitle="My Feedback";
        if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
          this.invoiceForm.get('t800EmployeeObj.userName').setValue(this.commonService.loggedInUserObj.userName);
        this.fetchHRMFeedback();
      }
      else if (this.moduleName==='VIEW_INTERVIEW_SLOT'){
        this.screenTitle="Manage Interview Slot";
        this.fetchInterviewSlot();
      }
      else if (this.moduleName === 'VIEW_ANNOUNCEMENTS')
      {
        this.screenTitle="Manage Announcements";
        this.fetchAnnouncements();
      } 
      else if (this.moduleName==='VIEW_CANDIDATE'){
        this.screenTitle="Manage Candidate";
        this.fetchCandidate();
      }
      else if (this.moduleName==='VIEW_APPOINTMENT'){
        this.screenTitle="Manage Appointment";
        this.fetchAppointment();
      }
}
    ngOnDestroy() {
      for (let loop = 0; loop < this.subscriptionList.length; loop++) {
        this.subscriptionList[loop].unsubscribe();
      }
    }

    initializePagination() {
      this.subscriptionList[1] = this.commonService.paginationChangeEvent.subscribe(
  
        (status: any) => {
          this.action = status.action;
           if (this.action === 'PAGINATION') {
            const pageNo = status.pageIndex;
            const pageSize = status.pageSize; 
            this.invoiceForm.get('pageNo').setValue(pageNo);
            this.invoiceForm.get('pageSize').setValue(pageSize);
            this.commonService.bindOldChangesInModule(this.moduleName, this.invoiceForm.value);
            if (this.moduleName === 'VIEW_RESOURCE_EXTENSION') {
              this.paginationFlag = true;
              this.viewGridLoad();
            }else
              this.viewGridLoad();
          }
        }
      );
    }
  
    initializeEmitChanges() {
      this.subscriptionList[2] = this.commonService.pageChangeEvent.subscribe(
  
        (status: any) => {
        if(this.moduleName == 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
           if(status.action == 'PAGINATION')
            this.action = "PAGINATION";
          else
            this.action = "FILTER_CHANGED";
        }
        else{
          this.action  = status.action;
        }
        if (this.action.indexOf('FILTER_CHANGED') !== -1) 
        {
         if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
            this.selectedOption = status.value.filterOptionValue;
            this.selectedFilterObj = status.value.filterOptionValue; 
          }
          else{
          this.selectedOption = status.value; 
          this.selectedFilterObj = status.value;  
           }
          this.invoiceForm.get('t004_LookupObj').patchValue(this.selectedOption);
          if(this.moduleName =='VIEW_TIMESHEET'){
            this.invoiceForm.patchValue(this.selectedFilterObj);
          }
                             
          this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
          //this.viewGridLoad();
          let oldValues = this.commonService.recordOldChangesForEachModuleMap.get(this.moduleName);
           if(this.commonUtils.checkNullOrUndefined(oldValues))
           {
             oldValues.pageNo = 0;
            }
          if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
          {
            if(this.invoiceForm.get('t822ProjectObj.projectId').value!=0)
            this.fetchProjectPlan();
            else
            this.viewGridLoad();
          } 
          else
          {
            this.viewGridLoad();
          }
   
        } else if (this.action.indexOf('SORT_CLICKED') !== -1) 
        {

          const sortBy = status.sortBy;
          const sortType = status.sortType;
              this.invoiceForm.get('sortType').setValue(sortType);
            this.invoiceForm.get('sortBy').setValue(sortBy);
             this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
           if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
          {
            if(this.invoiceForm.get('t822ProjectObj.projectId').value!=0)
            this.fetchProjectPlan();
            else
            this.viewGridLoad();
          }
        else
           this.viewGridLoad();
          
        } 
        // else if (this.action === 'PAGINATION')
        //  {
        //    const pageNo = status.pageIndex;
        //    const pageSize = status.pageSize;
         
        // //this.invoiceForm=this.getSaveForm(this.moduleName);
        // this.invoiceForm.get('pageNo').setValue(pageNo);
        // this.invoiceForm.get('pageSize').setValue(pageSize);
        // this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
        // if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
        //   {
        //     if(this.invoiceForm.get('t822ProjectObj.projectId').value!=0){
        //       this.fetchProjectPlan();
        //       this.viewGridLoad();
        //     }
        //     else
        //       this.viewGridLoad();
        //   }
        //   else if (this.moduleName=== 'VIEW_RESOURCE_EXTENSION'){
        //     this.paginationFlag =true;
        //     this.viewGridLoad();

        //   }
        // else
        //    this.viewGridLoad();

        // }
       });
}
   

  togglePanel(firstPanelValue: boolean) {
        this.showFirstPanel = firstPanelValue;
        this.showSecondPanel = !firstPanelValue;
     }

   

    openMoreActions(value): void
    {
      this.commonService.showProgressBar();
      this.moreActionValue = value; 
    }

gridCheckBoxSelected(contactsArray) 
{ 
  
this.store.dispatch(new fromTemplateActions.LoadViewTemplateSearchResults(this.invoiceForm.value));

 // this.initializeAccessForModule();
  this.taskOpenCount=0;
  this.draftCount=0;
  this.openCount=0;
  this.othersOpenCount=0;
  this.othersCount=0;
  this.sentCount=0;
  this.othersSentCount=0;
  this.voidCount=0;
  this.soVoidCount=0;
  this.otherCount=0;
  this.othersVoidCount=0;
  this.othersTaskCount=0;
  //this.contactsArray = contactsArray; 
   //this.contactsArrayList=[];
    for (let loop = 0; loop < this.selection.selected.length; loop++) 
    {
          if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop]))
          {
            if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
            {
                this.selection.selected[loop].organizationId = this.commonService.loggedInUserObj.t000Organization.organizationId;
                this.selection.selected[loop].orgLogoPath = this.commonService.loggedInUserObj.t000Organization.logoPath;
                this.selection.selected[loop].portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
          }
          this.selection.selected[loop].moduleName = this.moduleName;
          
         
          }
          
     }
       
  /*Report Download For Packages and Shipment*/
  if(this.moduleName === 'VIEW_PACKAGES')
  {
    if (this.selection.selected.length === 1)
    {
      this.multiplePDFPackage=false;
      if(this.selection.selected[0].packageStatusId.lookupValue === 'SHIPPED'|| this.selection.selected[0].packageStatusId.lookupValue === 'DELIVERED'){
        this.showShipmentFlag = true;
      }
      else{
        this.showShipmentFlag = false;
      }
      this.arrayListIsOne = 'show';
    }
    else{
      this.arrayListIsOne = 'hide';
    }
  }

 
   if(this.moduleName === 'VIEW_PRODUCTION_SLIP')
  {
    if (this.selection.selected.length === 1)
    {
    
      if(this.selection.selected[0].productionSlipStatus==='OPEN')
      {
        this.showProductionSlipStatus = true;
      }
      else{
        this.showProductionSlipStatus = false;
      }
      this.arrayListIsOne = 'show';
    }
    else{
      this.arrayListIsOne = 'hide';
    }
  }


      
  if (this.selection.selected.length === 1)
  {
   
    if ((this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupPOBillStatusId) && (this.selection.selected[0].t004LookupPOStatusId.lookupValue !='DRAFT')) ||   
     (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupSOStatusId) &&  (this.selection.selected[0].t004LookupSOStatusId.lookupValue !='DRAFT')) || 
     (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004Lookup) && (this.selection.selected[0].t004Lookup.lookupValue !='DRAFT')) || 
      (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupCreditNoteStatusId) && (this.selection.selected[0].t004LookupCreditNoteStatusId.lookupValue !='DRAFT')) || 
      (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004lookupInvoiceStatusId) && (this.selection.selected[0].t004lookupInvoiceStatusId.lookupValue !='DRAFT')) ||
      (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004StatusObj) && (this.selection.selected[0].t004StatusObj.lookupValue !='DRAFT') &&this.selection.selected[0].moduleName === 'VIEW_RESERVATION') || 
      (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].contactStatus) && (this.selection.selected[0].contactStatus !='INACTIVE')))
     {
         this.arrayListIsOne = 'show';
         this.emailShowFlag=true;
       }
       else if((this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupSOStatusId) && (this.selection.selected[0].t004LookupSOStatusId.lookupValue !='DRAFT')))
       {

       }
       else
       {
         this.emailShowFlag=false;
         
       }

    if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupBillStatusId) && 
      (this.selection.selected[0].t004LookupBillStatusId.lookupValue==='OPEN'||this.selection.selected[0].t004LookupBillStatusId.lookupValue==='PARTIALLY PAID' ||this.selection.selected[0].t004LookupBillStatusId.lookupValue==='OVERDUE'))
      {
        this.billOpenStatus=true;

      }
    
     else  if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004Lookup) && 
      (this.selection.selected[0].t004Lookup.lookupValue==='OPEN'))
{
    this.billOpenStatus=true;
}
      else  if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004lookupInvoiceStatusId) && 
      (this.selection.selected[0].t004lookupInvoiceStatusId.lookupValue==='OPEN' || this.selection.selected[0].t004lookupInvoiceStatusId.lookupValue==='PARTIALLY PAID'))
      {
       this.billOpenStatus=true;
     }
      else
 this.billOpenStatus=false; 

      
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupPOBillStatusId) && 
      this.selection.selected[0].t004LookupPOBillStatusId.lookupValue==='BILLED' &&
      this.selection.selected[0].t004LookupPOStatusId && 
      this.selection.selected[0].t004LookupPOStatusId.lookupValue==='BILLED')
      {
        this.arrayListIsOne = 'hide';
      }

      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupPOStatusId) && 
      this.selection.selected[0].t004LookupPOStatusId.lookupValue==='DRAFT' &&
      this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupPOBillStatusId) && 
      this.selection.selected[0].t004LookupPOBillStatusId.lookupValue==='UNBILLED')
      {
         this.arrayListIsOne = 'hide';
      }
       else
      {
        this.arrayListIsOne = 'show';
      }
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].packageStatusId) && 
      this.selection.selected[0].packageStatusId.lookupValue==='NOT SHIPPED')
      {
         this.showVoidButton = true;
      }
       else
      {
        this.showVoidButton =false;
      }
     
      /*if(this.commonUtils.checkNullOrUndefined(this.contactsArray[0].t004StatusObj) && 
      (this.contactsArray[0].t004StatusObj.lookupValue==='ACCEPTED' || this.contactsArray[0].t004StatusObj.lookupValue==='INVOICED'))  
      {
       this.estimateAcceptStatus=true;
      } 
      else
      {
        this.estimateAcceptStatus=false;
      }*/

      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004StatusObj) && 
      (this.selection.selected[0].t004StatusObj.lookupValue==='ACCEPTED' || this.selection.selected[0].t004StatusObj.lookupValue==='PARTIALLY INVOICED'))  
      {
       this.invoiceConvertFlag=true;
       this.salesConvertFlag=true;
      } 
      

      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004StatusObj) && 
      (this.selection.selected[0].t004StatusObj.lookupValue==='INVOICED'))  
      {
       this.invoiceConvertFlag=false;
       this.salesConvertFlag=true;
      } 
      

       if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004LookupSOStatusId) && 
      (this.selection.selected[0].t004LookupSOStatusId.lookupValue==='OPEN'))
      {
       this.convertInvoice=true;
      } 
      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].creditNoteStatus) && 
      (this.selection.selected[0].creditNoteStatus==='OPEN'))
      {    
       this.convertInvoice=true;
      }  
      else
      {
        this.convertInvoice=false;
      }

      // if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004_LookupTaskStatusObj) && 
      // (this.selection.selected[0].t004_LookupTaskStatusObj.lookupValue==='OPEN'))
      // {
      //  this.closeTask=true;
      // } 
      // else 
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].t004TaskStatusObj) && 
      (this.selection.selected[0].t004TaskStatusObj.lookupValue==='OPEN'))
      {
       this.closeTask=true;
      } 
  } else 
  {
    this.arrayListIsOne = 'hide';
  }

  if(this.selection.selected.length>0)
  {
 if(this.selection.selected.length>1)
      this.emailShowFlag=false;
if(this.moduleName=='VIEW_PACKAGES' &&this.selection.selected.length!=1)
     this.multiplePDFPackage=true;

    for(let loop=0;loop<this.selection.selected.length;loop++)

    {
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004LookupPOStatusId) && 
      this.selection.selected[loop].t004LookupPOStatusId.lookupValue==='DRAFT')
      {

        
        this.draftCount=this.draftCount+1;
      
      }
      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004Lookup) && 
      this.selection.selected[loop].t004Lookup.lookupValue==='DRAFT')
      {
       this.draftCount=this.draftCount+1;
      }
       else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004LookupBillStatusId) && 
      this.selection.selected[loop].t004LookupBillStatusId.lookupValue==='DRAFT')
      {
       this.draftCount=this.draftCount+1;
      }
       else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004lookupStockRequestStatusId) && 
      this.selection.selected[loop].t004lookupStockRequestStatusId.lookupValue==='DRAFT')
      {
       this.draftCount=this.draftCount+1;
      }
        else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004lookupReturnsStatusId) && 
      this.selection.selected[loop].t004lookupReturnsStatusId.lookupValue==='DRAFT')
      {
       this.draftCount=this.draftCount+1;
      }
       else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004LookupSOStatusId) && 
      this.selection.selected[loop].t004LookupSOStatusId.lookupValue==='DRAFT')
      {
       this.draftCount=this.draftCount+1;
      }

       else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004lookupInvoiceStatusId) && 
      this.selection.selected[loop].t004lookupInvoiceStatusId.lookupValue==='DRAFT')
      {
        
        this.draftCount=this.draftCount+1;
      }
       else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].creditNoteStatus) && 
      (this.selection.selected[loop].creditNoteStatus==='DRAFT'))
      {        
       this.draftCount=this.draftCount+1;
      }  
       else
      {
         this.othersCount=this.othersCount+1;     
      }
        if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004PackageStatusId) && 
      this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004ShipmentStatusId) &&
      this.selection.selected[loop].t004PackageStatusId.lookupValue==='NOT PACKAGED' &&
      this.selection.selected[loop].t004ShipmentStatusId.lookupValue==='NOT SHIPPED' &&
      (this.selection.selected[loop].salesOrderStatus=='DRAFT' || this.selection.selected[loop].salesOrderStatus=='OPEN'))
    {
      this.soVoidCount=this.soVoidCount + 1;
    }
    else 
      this.otherCount=this.otherCount + 1;
    if(this.soVoidCount>0){
      if(this.otherCount<=0)
        this.convertVoidFlag = true;
      else
        this.convertVoidFlag = false;
    }
    else
      this.convertVoidFlag = false;
      /****************for getting the count of draft status in artwork request header - starts*****************/
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004StatusObj) && 
      (this.selection.selected[loop].t004StatusObj.lookupValue==='DRAFT'))
      {     
       this.sentCount=this.sentCount+1;
      } 
      else{
       this.othersSentCount=this.othersSentCount+1; 
      }
      /*****************for getting the count of draft status in artwork request header - ends*****************/


      if(this.draftCount>0)
      {        
        if(this.othersCount<=0)
          this.showConvertOpen=true;
        else
          this.showConvertOpen = false;
      }
      else
      {
        this.showConvertOpen=false;       
      }

      /*this if is for executing vendor credits void logic*/

      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004Lookup) && 
     (this.selection.selected[loop].t004Lookup.lookupValue==='DRAFT'|| (this.selection.selected[loop].t004Lookup.lookupValue==='OPEN' && 
       this.selection.selected[loop].creditBalance===this.selection.selected[loop].finalTotal)))
      {
        this.voidCount=this.voidCount+1;
      
      }

      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].creditNoteStatus) && 
      (this.selection.selected[0].creditNoteStatus==='DRAFT' || this.selection.selected[0].creditNoteStatus==='OPEN'))
      { 
       this.voidCount=this.voidCount+1;
     
      }  
      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].billStatus) && 
      (this.selection.selected[0].billStatus==='OPEN' || this.selection.selected[0].billStatus==='DRAFT' ))
      { 
       this.voidCount=this.voidCount+1;
     
      } 
      else if(this.commonUtils.checkNullOrUndefined(this.selection.selected[0].invoiceStatus) && 
      (this.selection.selected[0].invoiceStatus==='OPEN' || this.selection.selected[0].invoiceStatus==='DRAFT' ))
      { 
       this.voidCount=this.voidCount+1;
     
      }
    
      else
        this.othersVoidCount=this.othersVoidCount+1;

if(this.voidCount>0)
      { 
        if(this.othersVoidCount===0){
          this.showConvertVoid=true;
        }
        else{
          this.showConvertVoid = false;
        }
      }
      else
      {
        this.showConvertVoid=false;       
      }




     /***********************for hide pdf button in the edit top when the status is void-starts************************/
    if((this.commonUtils.checkNullOrUndefined(this.selection.selected[0].billStatus) && (this.selection.selected[0].billStatus==='VOID')) 
       || (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].creditNoteStatus) && (this.selection.selected[0].creditNoteStatus==='VOID'))
       || (this.commonUtils.checkNullOrUndefined(this.selection.selected[0].vendorCreditStatus) && (this.selection.selected[0].vendorCreditStatus==='VOID')))
      { 
       this.showVoidFlag=false;
     
      } 
      else
        this.showVoidFlag=true;

      /****************for hide / show the mark as sent button in the edit top on the artwork request header - starts*****************/
      if(this.sentCount>0)
      { 
        if(this.othersSentCount===0){
          this.showConvertSent=true;
        }
        else{
          this.showConvertSent = false;
        }
      }
      else
      {
        this.showConvertSent=false;       
      }
      /****************for hide / show the mark as sent button in the edit top on the artwork request header - ends*****************/
       
       
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].issueStatus) && 
      (this.selection.selected[loop].issueStatus==='OPEN'))
      {
           
       this.openCount=this.openCount+1;
       
      } 
      else{
         
       this.othersOpenCount=this.othersOpenCount+1;
       
      }
      if(this.openCount>0)
      { 
        if(this.othersOpenCount===0){
           this.showConvertTask=true;
        }
        else{
          this.showConvertTask = false;
        }
      }
      else
      {
        this.showConvertTask=false;       
      }

      /********************* this is to convert the open task to closed - 9103*****************************/
      if(this.commonUtils.checkNullOrUndefined(this.selection.selected[loop].t004TaskStatusObj) && 
      (this.selection.selected[loop].t004TaskStatusObj.lookupValue==='OPEN'))
      {
        this.taskOpenCount=this.taskOpenCount+1;
      }      
      else{
        this.othersTaskCount=this.othersTaskCount+1;
       }
        if(this.taskOpenCount>0)
      { 
        if(this.othersTaskCount===0){
           this.closeTask=true;
        }
        else{
          this.closeTask = false;
        }
      }
      else
      {
        this.closeTask=false;       
      }
          
    }

     
  }

}

closeEditTopDialog(){
           this.showFirstPanel = true;
          this.showSecondPanel = false;
        this.selection.clear();
          //this.contactsArray = [];
}

    openDialogPrint(value,selectedObjectFromGrid?): void 
    {
      

      let portalName: string='';
      if(this.commonUtils.checkNullOrUndefined(this.selection) && this.commonUtils.checkNullOrUndefined(this.selection.selected[0]))
        portalName = this.selection.selected[0].portalName;
     if (value === 'PDF') 
      {        
        this.commonService.showProgressBar();
        this.commonService.generatePDF(this.selection.selected).subscribe(
        data => 
          {            
            if (this.commonUtils.checkNullOrUndefined(data)) 
            {
              const invoicePDFNames = data;
              const invoicePDFList = invoicePDFNames.split(',');              

              for (let loop = 0; loop < invoicePDFList.length; loop++) 
              {
                 let reportsFolder="";
                if(invoicePDFList[loop].indexOf("Template not found")<0 && invoicePDFList[loop] != 'failed')
                {
                  if(this.moduleName === 'VIEW_PO')
                  {
                    reportsFolder =environment.NEW_PO;
                  }
                  else if(this.moduleName === 'VIEW_SALESORDER')
                  {
                    reportsFolder =environment.NEW_SALESORDER;
                  }
                  else if(this.moduleName === 'VIEW_INVOICE'){
                    reportsFolder = environment.NEW_INVOICE;
                  }
                  else if(this.moduleName === 'VIEW_ESTIMATE'){
                    reportsFolder = environment.NEW_ESTIMATE;
                  }
                  else if(this.moduleName === 'VIEW_ARTWORK'){
                    reportsFolder = environment.NEW_ARTWORK;
                  }
                  else if(this.moduleName === 'VIEW_BILL'){
                    reportsFolder = environment.NEW_BILL;
                  }
                  else if(this.moduleName === 'VIEW_VENDOR_CREDIT'){
                    reportsFolder = environment.NEW_VENDOR_CREDIT;
                  }
                  else if(this.moduleName === 'VIEW_CREDIT_NOTES')
                  {
                    reportsFolder = environment.NEW_CREDIT_NOTES;
                  }
				          else if(this.moduleName === 'VIEW_CLIENT_INVOICE')
                  {
                    reportsFolder = environment.NEW_CLIENT_INVOICE;
                  }
                   else if(this.moduleName === 'VIEW_RETURNS')
                  {
                    reportsFolder = environment.NEW_RETURNS;
                  }
                  else if(this.moduleName === 'VIEW_SALES_RECEIPT'){
                    reportsFolder = environment.NEW_SALES_RECEIPT;
                  }
                  else if(this.moduleName === 'VIEW_REFUND_RECEIPT'){
                    reportsFolder = environment.NEW_REFUND_RECEIPT;
                  }
                  if(this.commonUtils.checkNullOrUndefined(reportsFolder)){
                    window.open(this.SERVICE_URL+"erpassets/"+portalName+"/"+reportsFolder+"/Reports/"+invoicePDFList[loop]+'?'+new Date(), '_blank');
                  }
                  else{
                    window.open(this.SERVICE_URL + 'downloadPDF/' + invoicePDFList[loop]+'?'+new Date(), '_blank');
                  }
                }
                else if(invoicePDFList[loop] === 'failed')
                {
                 
                  this.closeEditTopDialog();
                  this.commonService.showERPErrorMessage({error:"Failed to download"});
                }
                else
                {
                  let message = invoicePDFList[loop].replace("~",",");
                  
                  this.closeEditTopDialog();
                  this.commonService.showERPErrorMessage({error:message});
                }
                
              }
            
            }
             this.commonService.hideProgressBar();
            
             this.closeEditTopDialog();
          }, error => {
           
            this.closeEditTopDialog();
            this.commonService.hideProgressBar();
            this.commonService.showERPErrorMessage(error);
      
          });
      }
       else if (value === 'DELETE') 
      {
        for (let loop = 0; loop < this.selection.selected.length; loop++) 
        {
          this.selection.selected[loop].moduleName = this.moduleName;
          this.selection.selected[loop].itemFlag='DELETE';
          this.selection.selected[loop].bulkAction='DELETE';
        }

        if (this.moduleName === 'VIEW_EMPLOYEE') 
        {          
          this.hrmService.saveBulkEmployee(this.selection.selected).subscribe(
          data => {
            this.commonService.showERPMessage(data);
             this.fetchEmployee();
             this.closeEditTopDialog();
          }, error => {
            
          });
         
        }
      } 
      else if (value === 'CLOSE') {
         this.closeEditTopDialog();
       } 
       else if (value === 'ACTIVE') 
       {
        if(this.moduleName == 'VIEW_EMPLOYEE')
          {
             for (let loop = 0; loop < this.selection.selected.length; loop++)
              {
                this.selection.selected[loop].bulkAction = 'Active';
              }
              this.hrmService.saveBulkEmployee(this.selection.selected).subscribe(
              data => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPMessage(data);
              this.closeEditTopDialog();
              },
              error => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            });
           this.fetchEmployee();
            //this.contactsArray = [];
            value = '';
            this.bulkUpdateResult = '';
          }
        }
          else if (value === 'INACTIVE') {

               if(this.moduleName == 'VIEW_EMPLOYEE')
          {
             for (let loop = 0; loop < this.selection.selected.length; loop++)
              {
                this.selection.selected[loop].bulkAction = 'InActive';
              }
              this.hrmService.saveBulkEmployee(this.selection.selected).subscribe(
              data => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPMessage(data);
              this.fetchEmployee();
              this.closeEditTopDialog();
              },
              error => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            });           
            //this.contactsArray = [];
            value = '';
            this.bulkUpdateResult = '';
          }
        }
       else if(value=='WIP')
      {
       
        if (this.selection.selected != null && this.selection.selected.length > 0)
        {
          for (let loop = 0; loop < this.selection.selected.length; loop++)
          {
            this.selection.selected[loop].bulkAction='WIP';
          }
          if(this.moduleName=='VIEW_FEEDBACK')
          {
             this.commonService.bulkUpdateIssue(this.selection.selected).subscribe(
              data => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPMessage('The selected feedbacks are  marked as WIP');
              this.viewGridLoad();
              this.closeEditTopDialog();
              },
              error => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            });
            
            value = '';
          }
           
        }
      }
       else if(value=='CLOSED')
      {
       
        if (this.selection.selected != null && this.selection.selected.length > 0)
        {
          for (let loop = 0; loop < this.selection.selected.length; loop++)
          {
            this.selection.selected[loop].bulkAction='CLOSED';
          }
          if(this.moduleName=='VIEW_FEEDBACK')
          {
             this.commonService.bulkUpdateIssue(this.selection.selected).subscribe(
              data => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPMessage('The selected feedbacks are  marked as Closed');
              this.viewGridLoad();
              this.closeEditTopDialog();
              },
              error => 
              {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            });
            value = '';
          }
           
        }
      }
       else if (value === 'EMPLOYEE') 
      {
      this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_EMPLOYEE');
       if(this.convertAccessObj.editAccess==true){      
         
        if (this.commonUtils.checkNullOrUndefined(this.selection.selected) && this.moduleName == 'VIEW_CANDIDATE' && this.selection.selected.length == 1){
          if(this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.candidateLatestStatus) && selectedObjectFromGrid.candidateLatestStatus == 'Hired') {
            if(this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.t004_CandidateStatusObj) && this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.t004_CandidateStatusObj.lookupValue)
              && selectedObjectFromGrid.t004_CandidateStatusObj.lookupValue === 'Hired'){
                 this.candidateId = this.selection.selected[0].candidateId;
                this.methodName='Convert_To_Employee';
            }else{
                   this.commonService.showERPMessage("Hired Candidate can only be converted to Employee ");
                    return;
            }
          }
          else{
            this.commonService.showERPMessage(" Hired Candidate can only be converted to Employee");
            return;
          }
        }
        else if(this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid)){
          
          if(this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.candidateLatestStatus) && selectedObjectFromGrid.candidateLatestStatus == 'Hired') {
             if(this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.t004_CandidateStatusObj) && this.commonUtils.checkNullOrUndefined(selectedObjectFromGrid.t004_CandidateStatusObj.lookupValue)
              && selectedObjectFromGrid.t004_CandidateStatusObj.lookupValue === 'Hired'){
                this.candidateId = selectedObjectFromGrid.candidateId;
                this.methodName='Convert_To_Employee';
             }else{
                     this.commonService.showERPMessage("Hired Candidate can only be converted to Employee");
                    return;
            }
          }
          else{
            this.commonService.showERPMessage("Hired Candidate can only be converted to Employee");
            return;
          }
        }
        else
            this.methodName='Convert_To_Employee';

          this.router.navigate([this.commonUtils.getAddSecondLink(this.methodName, btoa( this.candidateId))]);
          this.commonService.addMenuList(this.commonUtils.getAddSecondLink(this.methodName,btoa( this.candidateId)));
       }
      } 
/* code to convert selected feedbacks to task -- starts */
      else if(value=='TASK')
      {
       
        if (this.selection.selected != null && this.selection.selected.length > 0)
        {
          for (let loop = 0; loop < this.selection.selected.length; loop++)
          {
            this.selection.selected[loop].bulkAction='WIP';
          }
          if(this.moduleName=='VIEW_FEEDBACK')
          {
             this.commonService.convertToTask(this.selection.selected).subscribe(
              data => 
              {
                this.viewGridLoad();
                this.closeEditTopDialog();
                this.commonService.showERPMessage('Selected feedbacks are converted to task');
              },
              error => 
              {
               this.commonService.hideProgressBar();
               this.commonService.showERPErrorMessage(error);
            });
            value = '';
          }
           
        }

      }
/* code to convert selected feedbacks to task -- ends */
        //converts the open project task to close in timesheet screen starts - 9103
         else if(value==='CLOSE_TASK')
        {
         if (this.selection.selected != null && this.selection.selected.length > 0)
          {
            for (let loop = 0; loop < this.selection.selected.length; loop++)
            {
              this.selection.selected[loop].bulkAction='CLOSED';
              this.selection.selected[loop].userId = this.commonService.loggedInUserObj.userId;
               this.selection.selected[loop].taskId = this.selection.selected[loop].t823TaskObj.taskId;
            }
          
          this.hrmService.convertProjectTaskToClosed(this.selection.selected).subscribe(data=>
          {
                this.viewGridLoad();
                this.closeEditTopDialog();
                this.commonService.showERPMessage('Selected task converted to Closed');
          },
            error=>
            {
              this.commonService.showERPErrorMessage(error);
            })
            //this.contactsArray = [];
            value = '';
        }
      }
        
    }
    searchIconClick(): void {
      this.commonService.bindOldChangesInModule(this.moduleName,"");
   
    this.invoiceForm=this.getSaveForm(this.moduleName)
    if(this.moduleName === 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
      {
        if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.value))
          this.invoiceForm.get('t822ProjectObj.projectId').setValue(this.selectedProjectObj.value);
      }
    this.dialogForm=this.invoiceForm;

      const dialogRef = this.dialog.open(SearchInGridDialogComponent, 
      {
        width: '700px',
        data: { dialogText: this.moduleName,dialogForm:this.dialogForm}
      });

      dialogRef.afterClosed().subscribe(result => 
      {
       
        if(this.commonUtils.checkNullOrUndefined(result))
        {
          
             /*  this.commonService.searchForm=result;
            this.router.navigate([result.routeUrl]);*/
             this.moduleName=result.moduleName;
             let projectId=0;//this.commonService.projectId;
             let parentTask=result.t823ParentTask.taskId;
             this.invoiceForm=this.getSaveForm(this.moduleName);
             this.invoiceForm.patchValue(result); 
             this.loadPageHeaderLabels();
             if(this.moduleName === 'VIEW_PRODUCT_SETUP'){
               this.productSetupRefreshClick = true;
               this.searchFlag = false;
                 this.viewGridLoad();
             }

             if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT')
             {
              this.invoiceForm.get('t822ProjectObj.projectId').setValue(projectId);
             this.selectedGlobalFlag = false;
             this.breadcrumFlag =false;             
             if(this.parentTaskId > 0){
              this.selectedTaskType='S'         
             }else{
              this.selectedTaskType='A'
             }
			 this.fetchProjectPlan();
              /*else
              this.viewGridLoad();*/
             }
             else if(this.moduleName==='VIEW_LOAN'){
                this.invoiceForm.get('t800Employee.t818SalaryGroup').setValue(result.t818SalaryGroup);
                this.viewGridLoad();
               this.myForm=this.getSaveForm(this.moduleName);
               this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
          
             }
             else
             this.viewGridLoad();
             this.myForm=this.getSaveForm(this.moduleName);
             this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
          }
       });
    }
        
 searchEnabled(result):void
 {
   
             this.moduleName=result.moduleName;
             this.invoiceForm=this.getSaveForm(this.moduleName);
             
             this.invoiceForm.patchValue(result); 
             this.loadPageHeaderLabels();
             this.viewGridLoad();
             this.myForm=this.getSaveForm(this.moduleName);
             this.commonService.searchForm=null;
 }

/************** Fetch HRM Feedback - starts - Monisha **************/
fetchHRMFeedback(){
  this.commonService.showProgressBar();
  this.hrmService.fetchHRMFeedback(this.invoiceForm.value).
  subscribe(data => {
    this.dataSource = new MatTableDataSource<any>(data);
    if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {  
      this.recordCount = data[0].totalRecordCount;
      this.isEmptyGrid = false;
      if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
        this.PaginationComponentObj.ngOnInit();
      this.commonService.hideProgressBar();
    }else {
      this.isEmptyGrid = true;
      this.commonService.hideProgressBar();
    }
  },
  error => {
    this.commonService.hideProgressBar();
    this.commonService.showERPErrorMessage(error);
  });
}
/************** Fetch HRM Feedback - starts - Monisha **************/

/************** Fetch Announcements - starts - Monisha **************/
fetchAnnouncements(){
  this.commonService.showProgressBar();
  this.hrmService.fetchAnnouncements(this.invoiceForm.value).subscribe(
  data => {    
    if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
      for(var loop = 0 ; loop < data.length; loop++){
        data[loop].announcementDescription = data[loop].announcementDescription.replace(/<[^>]+>/g, '');
      }
      this.dataSource = new MatTableDataSource<any>(data);
      this.recordCount = data[0].totalRecordCount;
      this.isEmptyGrid = false;
      if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
        this.PaginationComponentObj.ngOnInit();
      this.commonService.hideProgressBar();
    }else {
      this.isEmptyGrid = true;
        this.dataSource = new MatTableDataSource<any>([]);
      this.commonService.hideProgressBar();
    }
  },
  error => {
    this.commonService.hideProgressBar();
    this.commonService.showERPErrorMessage(error);
  });
}
/************** Fetch Announcements - starts - Monisha **************/

    fetchEmployee(): void {
      this.commonService.showProgressBar();
       this.invoiceForm.get('status').setValue(null);
      if(this.invoiceForm.get('searchGridFlag').value != 'Y'){
        
       if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj) && 
          this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.type) && 
          this.commonService.loggedInUserObj.type === 'EMPLOYEE' && this.loggedInUserObjRole != 'HR'){
            this.invoiceForm.get('userName').setValue(this.commonService.loggedInUserObj.userName);
        }else{
          this.invoiceForm.get('userName').setValue('');
        }
      }
      
      this.hrmService.fetchEmployee(this.invoiceForm.value).
      subscribe(
       data => {
          
          this.commonService.hideProgressBar();
          this.dataSource = new MatTableDataSource<any>(data);            
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {           
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
             if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });
    }

    fetchPaycode(): void 
    {
  
      /* if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);*/
       this.commonService.showProgressBar();
     
      this.hrmService.fetchPayCode(this.invoiceForm.value).
      subscribe(
       data => {
          this.commonService.hideProgressBar();
       //    this.tableDataSource = new TableDataSource<any>(data,ViewTable,this.viewTableValidatorService);
          this.dataSource = new MatTableDataSource<any>(data);
            
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
            
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });
    }
    fetchShiftMaster(): void{
           this.commonService.showProgressBar();
     
      this.hrmService.fetchShiftMaster(this.invoiceForm.value).
      subscribe(
       data => {
          this.commonService.hideProgressBar();
       this.dataSource = new MatTableDataSource<any>(data);
            
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
            
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });
    }
      fetchShiftAllocation(): void{
      this.commonService.showProgressBar();
      this.hrmService.fetchShiftAllocation(this.invoiceForm.value).
      subscribe(
       data => {         
            this.commonService.hideProgressBar();
       this.dataSource = new MatTableDataSource<any>(data);
            
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
            
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });
    }
    fetchLoanList(): void {
      this.commonService.showProgressBar();
      /* if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);*/
        if(this.moduleName==='MY_LOAN')
        {
        this.employeeForm.get('userName').setValue(this.commonService.loggedInUserObj.userName);
        this.hrmService.fetchEmployee(this.employeeForm.value).subscribe(
     data=>
     {
        

       if(this.commonUtils.checkNullOrUndefined(data))
       {
         this.employeeObj=data[0];
         if(this.commonUtils.checkNullOrUndefined(this.employeeObj) && this.commonUtils.checkNullOrUndefined(this.employeeObj.employeeId)){
           this.invoiceForm.get('t800Employee.employeeId').setValue(this.employeeObj.employeeId);
           this.employeeId=this.employeeObj.employeeId;
         }
         
      this.hrmService.fetchLoan(this.invoiceForm.value).subscribe(
        data => {
          this.commonService.hideProgressBar();
       //   this.tableDataSource = new TableDataSource<any>(data,ViewTable,this.viewTableValidatorService);
           this.dataSource = new MatTableDataSource<any>(data);
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
            
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
        });
    }
  });
      }
      else if(this.moduleName==='VIEW_LOAN')
      {
        this.hrmService.fetchLoan(this.invoiceForm.value).subscribe(
        data => {
          this.commonService.hideProgressBar();
       //   this.tableDataSource = new TableDataSource<any>(data,ViewTable,this.viewTableValidatorService);
           this.dataSource = new MatTableDataSource<any>(data);
           if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
            
             if (data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
        });
      }
}

    changeSearchContacts(selectedFilterTypeObj): void 
    {

        let element={taskId:this.parentTaskId};
      let currentPageSize = this.invoiceForm.get('pageSize').value;
      //this.myForm.get('t004_LookupObj').patchValue(selectedFilterTypeObj);
     this.invoiceForm=this.getSaveForm(this.moduleName);
     this.invoiceForm.get('t004_LookupObj').patchValue(selectedFilterTypeObj);
     this.invoiceForm.get('pageSize').setValue(currentPageSize);
     this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
     if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT')
     {
       this.invoiceForm
     }
     else
     this.viewGridLoad();
    }

    userObj(): FormGroup {
      return this.formBuilder.group({
          userId: new FormControl(1)
        });
    }

    fetchUser(): void {


      
    this.settingsService.fetchUsers(this.userObj().value).subscribe(
      data => {
        if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {

           this.commonService.currencySymbol = data[0].t000Organization.t003Currency.symbol;
          this.currencySymbol = data[0].t000Organization.t003Currency.symbol;
        }
      }, error => {
        this.commonService.showERPErrorMessage(error);
    

      });
    }

  fetchReviewDisplayedColumns(){
     this.commonService.showProgressBar(); 
      this.commonService.fetchReviewDisplayColumn(this.displayForm.value).
      subscribe(
       data => 
       {     
         if(this.commonUtils.checkNullOrUndefined(data.columnName))       
             this.displayedColumns=data.columnName.split(",");
           this.commonService.hideProgressBar(); 
             
         this.loadDataForQuickInfo();         
       },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });    
    }
  bindDisplayedColumns(){
    if(this.commonService.userDisplayedColumnsMap.size>0){
      if(this.commonService.userDisplayedColumnsMap.has(this.moduleName) &&
        this.commonUtils.checkNullOrUndefined(this.commonService.userDisplayedColumnsMap.get(this.moduleName).columnName))    
        this.userDisplayedColumns=this.commonService.userDisplayedColumnsMap.get(this.moduleName).columnName.split(",");
      else{
        if(this.commonService.reviewDisplayedColumnsMap.size>0)
          this.displayedColumns=this.commonService.reviewDisplayedColumnsMap.get(this.moduleName).split(",");
      }
    }
    if(this.commonService.reviewDisplayedColumnsMap.size>0)
      this.displayedColumns=this.commonService.reviewDisplayedColumnsMap.get(this.moduleName).split(",");

    if(this.commonService.userLabelMap.size>0)
      this.userLabelColumn  = this.commonService.userLabelMap.get(this.moduleName);   

 
  if(!this.currentPageAccessObj.createAccess){
    let indexValue=this.displayedColumns.indexOf('cloning',0);
    if(indexValue>-1){
        this.displayedColumns.splice(indexValue,1);
      
    }
      
  }
  
  if(this.userDisplayedColumns.length==0)
    this.userDisplayedColumns=Object.assign([],this.displayedColumns);

       this.commonService.hideProgressBar(); 
      this.validateAccess();   
    }
    fetchQuickInfoData(){
   
      
     this.commonService.showProgressBar(); 
       
      this.commonService.fetchReviewDisplayColumn(this.displayForm.value).
      subscribe(
       data => 
       { 
     
         
           if(this.commonUtils.checkNullOrUndefined(data.columnName))       
             this.quickInfoValues=data.columnName.split(",");

           if(this.commonUtils.checkNullOrUndefined(data.labelName)) 
             this.quickInfoLabels=data.labelName.split(",");
           
         this.commonService.hideProgressBar();         
       },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      }); 
    }

    fetchProject(){ 
        this.invoiceForm.get('lookupValue').setValue('');
        this.invoiceForm.get('t004_LookupObj.lookupValue').setValue('');
       this.commonService.showProgressBar();  
       this.hrmService.fetchProject(this.invoiceForm.value)
       .subscribe(
         data=>{ 
            this.commonService.hideProgressBar();   
       if (this.commonUtils.checkNullOrUndefined(data) != null) {
          this.dataSource = new MatTableDataSource<any>(data);            
              if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            this.isEmptyGrid = false;
            if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
           } else {
             this.isEmptyGrid = true;
           }
         }
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }
      );
    }
    fetchJobs(){
     this.commonService.showProgressBar();  
     this.recruitService.fetchJobs(this.invoiceForm.value).subscribe(
         data=>{ 
             
            this.commonService.hideProgressBar();   
       if (this.commonUtils.checkNullOrUndefined(data) != null) {
          this.dataSource = new MatTableDataSource<any>(data);            
              if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            this.isEmptyGrid = false;
            if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
           } else {
             this.isEmptyGrid = true;
           }
         }
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }
      );
    }
     fetchOrganization(){ 
       
       if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
       this.commonService.showProgressBar();  
       this.settingsService.fetchOrganization(this.invoiceForm.value)
       .subscribe(
         data=>{ 
            this.commonService.hideProgressBar();    
       if (this.commonUtils.checkNullOrUndefined(data) != null) {
        //     this.tableDataSource = new TableDataSource<any>(data,ViewTable,this.viewTableValidatorService);
           this.dataSource = new MatTableDataSource<any>(data);
            
              if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
             }
           
           } 
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }


         )
    }

      fetchEmployeeDetails()
  {
    this.employeeForm.get('userName').setValue(this.commonService.loggedInUserObj.userName);
        this.hrmService.fetchEmployee(this.employeeForm.value).subscribe(
     data=>
     {
        

       if(this.commonUtils.checkNullOrUndefined(data))
       {
         this.employeeObj=data[0];
         if(this.commonUtils.checkNullOrUndefined(this.employeeObj) && this.commonUtils.checkNullOrUndefined(this.employeeObj.employeeId)){
           this.applyLeaveForm.get('empId').setValue(this.employeeObj.employeeId);
           this.employeeId=this.employeeObj.employeeId;
         }
         
         if(this.moduleName === 'APPROVE_LEAVE' )
         this.fetchAllAppliedLeaves();
         if(this.moduleName === 'VIEW_LEAVE')
         this.fetchApplyLeave();
       }
     },error=>
     {

     })
  }
fetchApplyLeave(){
     this.commonService.showProgressBar();

     if(this.applyLeaveForm.value.approverId!=0)
       this.applyLeaveForm.get('empId').setValue(0);
     else if(this.applyLeaveForm.value.empId!=0)
       this.applyLeaveForm.get('approverId').setValue(0);
       
 //this if for filtering
     if(this.invoiceForm.get('t004_LookupObj.lookupValue').value === 'UNAPPROVED LEAVES')
       this.applyLeaveForm.get('requestFlag').setValue('unapprovedLeaves');
     else if(this.invoiceForm.get('t004_LookupObj.lookupValue').value === 'APPROVED LEAVES')
       this.applyLeaveForm.get('requestFlag').setValue('approvedLeaves');
     else 
       this.applyLeaveForm.get('requestFlag').setValue('');

     this.applyLeaveForm.get('pageSize').setValue(this.invoiceForm.get('pageSize').value);
     this.applyLeaveForm.get('pageNo').setValue(this.invoiceForm.get('pageNo').value);
      this.hrmService.fetchApplyLeave(this.applyLeaveForm.value)
    .subscribe(
      data => { 
        this.commonService.hideProgressBar();
        //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
        if(this.commonUtils.checkNullOrUndefined(data[0]) && this.commonUtils.checkNullOrUndefined(data[0].t825LeaveHistory))
             this.dataSource = new MatTableDataSource<any>(data[0].t825LeaveHistory);
         else 
           this.dataSource = new MatTableDataSource<any>([]);

         if(this.commonUtils.checkNullOrUndefined(data[0]) &&  this.commonUtils.checkNullOrUndefined(data[0].t825LeaveHistory) && data[0].t825LeaveHistory.length > 0){
            //this.ELEMENT_DATA = data;
            this.recordCount = data[0].t825LeaveHistory.length;
            this.isEmptyGrid =false;
         }
         else{
           this.isEmptyGrid = true;
         
         }
     }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
  fetchAllAppliedLeaves(){
    this.applyLeaveForm.get('pageSize').setValue(this.invoiceForm.get('pageSize').value);
     this.applyLeaveForm.get('pageNo').setValue(this.invoiceForm.get('pageNo').value);
     if(this.applyLeaveForm.get('requestFlag').value === 'All_Queue'){
       this.applyLeaveForm.get('empId').setValue(0);
       this.applyLeaveForm.get('trxType').setValue('DEBIT');
     }
      this.commonService.showProgressBar();
     this.hrmService.fetchAllAppliedLeaves(this.applyLeaveForm.value)
    .subscribe(
      data => {  
        
        this.commonService.hideProgressBar();
        //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
            //this.ELEMENT_DATA = data;
            this.recordCount = data[0].totalRecordCount;
            this.isEmptyGrid =false;
         }
         else{
           this.isEmptyGrid = true;
         
         }
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }

  //FETCH USERS - added by 9061
 fetchOrgUsers(): void {
    
   this.commonService.showProgressBar();
     if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
    this.settingsService.fetchUsers(this.invoiceForm.value).
      subscribe(
      data => {
        this.commonService.hideProgressBar();

        this.orgUsersList = data;
        //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);

             this.dataSource = new MatTableDataSource<any>(data);
         if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
              this.recordCount = data[0].totalRecordCount;
          }
      },
      error => {
        
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  }

  fetchOrgUsersForDropDown(): void {
    if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
      this.invoiceForm.get('pageSize').setValue(1000);
      this.invoiceForm.get('userFilter').setValue('active');
      
    this.settingsService.fetchUsers(this.invoiceForm.value).
      subscribe(
      data => {        
        this.orgUsersList = data;
      },
      error => {        
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  }

   fetchAppraisalMasterForDropDown() {
      
      this.hrmService.fetchAppraisalMaster(this.invoiceForm.value).
      subscribe(
       data => {
         this.appraisalLevelList = data;
       },
       error => {
        
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
    
  }


     fetchRoles() {
      this.commonService.showProgressBar();
       if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
      this.invoiceForm.get('status').setValue(true);
      this.settingsService.fetchRoles(this.invoiceForm.value).
      subscribe(
       data => {
          
         this.commonService.hideProgressBar();
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
          if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
         if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
            this.PaginationComponentObj.ngOnInit();
        }
       },
       error => {
       
       
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
    
  }
  fetchIssues()
  {
      if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
       this.invoiceForm.get("createdBy").setValue(this.commonService.loggedInUserObj.userName);

    this.commonService.fetchIssues(this.invoiceForm.value).
      subscribe(
       data => {
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
         
         if (this.commonUtils.checkNullOrUndefined(data)) {  
            if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            }
              this.isEmptyGrid = false;
           }else {
               this.isEmptyGrid = true;
            }          
            this.commonService.hideProgressBar();
       },error => {
        

         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
         
       }
      );
  }
  fetchLicense()
  {
     this.commonService.showProgressBar();
      if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);               
      this.commonService.fetchLicense(this.invoiceForm.value).
      subscribe(
       data => {
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data)) {  
            if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            }
              this.isEmptyGrid = false;
           }else {
               this.isEmptyGrid = true;
            }
            this.commonService.hideProgressBar();
       },error => {
        

         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
         
       }
      );
  }

  fetchFeedback()
  {
     this.router.navigate(['/settings/reviewIssues']);
  }
  
fetchWarehouse():void
{
 
this.commonService.showProgressBar();
      if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);               
      this.commonService.fetchLocation(this.invoiceForm.value).
      subscribe(
       data => {
        
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data)) {  
            if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            }
              this.isEmptyGrid = false;
           }else {
               this.isEmptyGrid = true;
            }
            this.commonService.hideProgressBar();
       },error => {
        

         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
         
       }
      );
}

projectPlanForm;

fetchProjectPlan():void
{ 
   this.commonService.showProgressBar();

  if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm)){
    this.invoiceForm=this.getSaveForm(this.moduleName); 
  }

if(this.breadcrumFlag){
  if(this.commonUtils.checkNullOrUndefined(this.selectedTaskObj) &&  this.commonUtils.checkNullOrUndefined(this.selectedTaskObj.taskId) ){
     this.invoiceForm.get("t823ParentTask.taskId").setValue(this.selectedTaskObj.taskId);
     this.invoiceForm.get("taskType").setValue('A');
  }else{
    this.invoiceForm.get("t823ParentTask.taskId").setValue(0);
  }
}

  if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT'){
     if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.value)){
      this.invoiceForm.get("t822ProjectObj.projectId").setValue(this.selectedProjectObj.value);
    }
    else{
      if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj.projectId)){
        this.invoiceForm.get("t822ProjectObj.projectId").setValue(this.selectedProjectObj.projectId);
      }
    }
  }
  //  //changes made for the allocated User Object
      if(this.moduleName==='PROJECT_PLAN' && this.commonUtils.checkNullOrUndefined(this.invoiceForm.get("allocatedUserObj.userId"))){
      this.invoiceForm.get("t001_UserObj.userId").setValue(this.invoiceForm.get("allocatedUserObj.userId").value);   
  }

  else if(this.moduleName==='VIEW_TICKETS'){
      this.invoiceForm.get('taskType').setValue('NS');
     /* this project name is hardcoded because of the feature help desk ticketing system in erp*/
      this.invoiceForm.get("t822ProjectObj.projectName").setValue("HelpDesk Tickets");
     this.invoiceForm.get("createdBy").setValue(this.commonService.loggedInUserObj.userName);
  }
 
  if(this.commonUtils.checkNullOrUndefined(this.selectedFilterObj))
      this.invoiceForm.get("t004_LookupObj").patchValue(this.selectedFilterObj);
 
  if(this.commonUtils.checkNullOrUndefined(this.selectedGlobalFlag) && this.selectedGlobalFlag){   
      this.invoiceForm.get("t823ParentTask.taskId").setValue(0);
      this.invoiceForm.get("globalFlag").setValue(this.selectedGlobalFlag);
  }
  else{
      this.invoiceForm.get("globalFlag").setValue(false);
  }

  if(this.commonUtils.checkNullOrUndefined(this.selectedTaskType))
      this.invoiceForm.get('taskType').setValue(this.selectedTaskType);

  if(this.moduleName==='VIEW_TIMESHEET' || this.moduleName==='VIEW_HRIS_TIMESHEET'){
      this.invoiceForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
  }

  if(this.moduleName==='VIEW_ISSUES'){
    this.invoiceForm.get('portalName').setValue("esalescorp");
  }
   
  this.commonService.fetchParentTask(this.invoiceForm.value).
      subscribe(
       data => {
        
        this.commonService.hideProgressBar();
        if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {  
        for (let loop = 0; loop < data.length; loop++) 
            {
              data[loop].startDate = new Date(data[loop].startDate);
              data[loop].endDate = new Date(data[loop].endDate);
             
            }
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
        
          
            this.dataSource = new MatTableDataSource<any>(data);
            this.isEmptyGrid = false;
            if (data.length > 0) {
              this.recordCount = data[0].totalRecordCount;
            }           
            this.fillBreadCrumbs(data);
            //this.commonService.erpSubject.next("SHOW_FOOTER_ROW");
            if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT'){
              this.noRecord= true;
              this.commonService.projectSubject.next( this.noRecord);
            }
            
          }else {
              this.isEmptyGrid = true;  
              this.dataSource = new MatTableDataSource<any>([]);
              if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT'){
                this.noRecord= false;
                this.commonService.projectSubject.next( this.noRecord);
                this.fillBreadCrumbs(data);               
              }
          }
       },error => {

         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
         
       }
      );
}

fetchUserBasedTimeSheet():void

{ 
   this.commonService.showProgressBar();

  if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm)){
    this.invoiceForm=this.getSaveForm(this.moduleName); 
  }

  if(this.commonUtils.checkNullOrUndefined(this.selectedTaskObj) &&  this.commonUtils.checkNullOrUndefined(this.selectedTaskObj.taskId)){
     this.invoiceForm.get("t823ParentTask.taskId").setValue(this.selectedTaskObj.taskId);
     this.invoiceForm.get("taskType").setValue('A');
  }else{
    this.invoiceForm.get("t823ParentTask.taskId").setValue(0);
  }

 
  if(this.commonUtils.checkNullOrUndefined(this.selectedFilterObj))
      this.invoiceForm.get("t004_LookupObj").patchValue(this.selectedFilterObj);
 
  if(this.commonUtils.checkNullOrUndefined(this.selectedTaskType))
      this.invoiceForm.get('taskType').setValue(this.selectedTaskType);

  if( this.moduleName==='VIEW_HRIS_TIMESHEET'){
      this.invoiceForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
  }


  if(this.moduleName === 'VIEW_TIMESHEET' ){
      this.invoiceForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
       this.invoiceForm.get('t001ResourceObj.userId').setValue(this.commonService.loggedInUserObj.userId);
  }

   
 
  this.hrmService.fetchResourceTaskAllocation(this.invoiceForm.value).
      subscribe(
       data => {
        for (let loop = 0; loop < data.length; loop++) 
            {
              data[loop].startDate = new Date(data[loop].startDate);
              data[loop].endDate = new Date(data[loop].endDate);
             
            }

         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {  
            if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            }
            
            this.isEmptyGrid = false;
           }else {
               this.isEmptyGrid = true; 
                
            }
            this.commonService.hideProgressBar();
       },error => {

         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
         
       }
      );
}

fetchCandidate(){
   let roleName = this.commonService.loggedInUserObj.roleName.toUpperCase();
   let looplistId=this.invoiceForm.get('lookUpIdList').value; 
  if(roleName === 'EMPLOYEE_MANAGER'){
    this.invoiceForm = this.getSaveForm(this.moduleName);
    this.invoiceForm.get('interviewerName').setValue(this.commonService.loggedInUserObj.employeeId);
    if(looplistId!=null && looplistId.length > 0){
      let lookupList = this.invoiceForm.get('lookUpIdList') as FormArray
      for(var loop=0; loop < looplistId.length; loop++){
        lookupList.push(this.lookupList(looplistId[loop].lookupId,looplistId[loop].lookupValue));
      }
    }
  }
  this.recruitService.fetchCandidate(this.invoiceForm.value).subscribe(
    data=> {
      this.commonService.hideProgressBar();
      this.dataSource = new MatTableDataSource<any>(data);            
       if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {           
         if (data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
         }
         this.isEmptyGrid = false;
         if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
          this.PaginationComponentObj.ngOnInit();
       } else {
         this.isEmptyGrid = true;
       }
     },
     error => {
       this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error);
  });
}

fetchInterviewSlot(){
  this.commonService.showProgressBar();  
     this.recruitService.fetchInterviewSlot(this.invoiceForm.value).subscribe(
         data=>{ 
           
            this.commonService.hideProgressBar();   
       if (this.commonUtils.checkNullOrUndefined(data) != null) {
          this.dataSource = new MatTableDataSource<any>(data);            
              if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            this.isEmptyGrid = false;
            if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
           } else {
             this.isEmptyGrid = true;
           }
         }
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }
      );
}

fetchAppointment(){
  this.commonService.showProgressBar(); 
   let looplistId=this.invoiceForm.get('lookUpIdList').value; 
  let roleName = this.commonService.loggedInUserObj.roleName.toUpperCase();
  if(roleName === 'EMPLOYEE_MANAGER'){
    this.invoiceForm = this.getSaveForm(this.moduleName);
    this.invoiceForm.get('interviewerName').setValue(this.commonService.loggedInUserObj.employeeId);
    if(looplistId!=null && looplistId.length > 0){
      let lookupList = this.invoiceForm.get('lookUpIdList') as FormArray
      for(var loop=0; loop < looplistId.length; loop++){
        lookupList.push(this.lookupList(looplistId[loop].lookupId,looplistId[loop].lookupValue));
      }
    }
  }
     this.recruitService.fetchAppointment(this.invoiceForm.value).subscribe(
         data=>{ 

            this.commonService.hideProgressBar();   
       if (this.commonUtils.checkNullOrUndefined(data) != null) {
          this.dataSource = new MatTableDataSource<any>(data);            
              if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;
            this.isEmptyGrid = false;
            if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
           } else {
             this.isEmptyGrid = true;
           }
         }
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }
      );
}

 fetchProjectBasedOnResourceExtension() :Promise<any> {

  let resourceExtensionForm =new FormGroup
       ({
          extensionId:new FormControl(0),
          /*t800_EmployeeObj:new FormGroup({
            employeeId:new FormControl(0)}),*/
          t001_UserObj: new FormGroup({userId: new FormControl(0)}),
          t822ProjectObj:new FormGroup({projectId:new FormControl(0)}),
          t001_UserCreatedByObj:new FormGroup({userId:new FormControl(0)}),
          t001_UserUpdatedByObj:new FormGroup({userId:new FormControl(0)}),
          createdTs:new FormControl(),
          updatedTs:new FormControl(),
          pageSize:new FormControl(0),
          statusFlag: new FormControl(''),
          status: new FormControl(true),
          userIds : new FormControl(),
          employeeId:new FormControl(0),
          t004_LookupObj:new FormGroup({lookupId:new FormControl(0),lookupValue:new FormControl('')})
       })
  resourceExtensionForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
  resourceExtensionForm.get('t004_LookupObj.lookupValue').setValue('DEVELOPMENT');
  return new Promise(resolve=>
      this.hrmService.fetchProjectBasedOnResourceExtension(resourceExtensionForm.value).subscribe(
        data=>
        {
          this.projectList=[];
          if(this.commonUtils.checkNullOrUndefined(data)){
             this.projectList = data;   
             let projectListFormArrayObj = this.invoiceForm.get('projectList') as FormArray; 
             for(var loopCount = 0; loopCount < this.projectList.length; loopCount++){
               projectListFormArrayObj.push(this.getProjectFormGroup());
             }
             projectListFormArrayObj.patchValue(this.projectList);
          }
           resolve(data); 
        },
        error=>
        {
           this.commonService.showERPErrorMessage(error);
        })
    );
}
  

 public resetProjectPlanForm(): FormGroup {
    return new FormGroup({
       
   taskList: new FormArray([]),
   moduleName: new FormControl(this.moduleName),
   summary: new FormControl(false),
   t823ParentTask: new FormGroup({taskId: new FormControl(0)}),
   autoSchedule: new FormControl(false),
   taskName: new FormControl(''),
   taskDesc: new FormControl(''),
   taskNumber: new FormControl(''),
   startDate: new FormControl(new Date()),
   endDate: new FormControl(new Date()),
   resourceId: new FormControl(0),
   displayDicardMessage:new FormControl('Y'),  
   pageNo:new FormControl(0),
   pageSize:new FormControl(this.pageSize),
   isPagination:new FormControl('true'),
   initializeFlag: new FormControl('Y'),
   fileUpload : new FormControl('')   
  })}; 

  getContactVendor(contactType): FormGroup {
    return new FormGroup({
      contactDisplayName: new FormControl(''),
      contactId: new FormControl('0'),
      contactsType: new FormControl(contactType),
      company: new FormControl(''),
      email: new FormControl(''),
      t003Currency: new FormGroup({
        currencyId: new FormControl('0'),
        currencyCode: new FormControl('')
      })
    });
  }

getProjectFormGroup(): FormGroup{
  return new FormGroup(
    {
      projectId:new FormControl()
    });
}

//getsaveformmethod
  getSaveForm(moduleName): FormGroup {
      const invoiceForm = new FormGroup({
      	t822ProjectObj:new FormGroup({projectId:new FormControl(0),projectName:new FormControl('')}),
         t823_taskObj:new FormGroup({t822ProjectObj:new FormGroup({projectId:new FormControl(0)})}),
         projectList: new FormArray([]),
         assetCategoryId:new FormGroup({categoryId:new FormControl(0),categoryName:new FormControl('')}),
         assetSubCategoryId:new FormGroup({subCategoryId:new FormControl(0),subCategoryName:new FormControl('')}),
       t005AccountModule:new FormGroup({accountModuleId:new FormControl(0),name:new FormControl('')}),
       t007AccountDetailType:new FormGroup({accountDetailId:new FormControl(0),accountDetailType:new FormControl('')}),
       dateFormat:new FormControl(''),
       fileType:new FormControl('XLS'),
       packageType:new FormControl(''),
       moduleType:new FormControl(''),
       statusValue:new FormControl(''),
      type  :new FormControl(''),
	   appraisalDate: new FormControl(''),
      artworkNo :new FormControl(''),
      numberOfProof: new FormControl(''),
      invoiceHeaderId: new FormControl(''),
      invoiceNumber: new FormControl('' ),
      invoiceDate: new FormControl(),
      dueDate: new FormControl(),
      amountDue:new FormControl(),
      invoiceStartDate: new FormControl(),
      invoiceEndDate: new FormControl(),
      invoiceDueStartDate: new FormControl(),
      invoiceDueEndDate: new FormControl(),
      refNumber: new FormControl(''),     
      creditNoteNumber: new FormControl(''),
      t100ContactVendorId: new FormGroup({contactId: new FormControl(0), contactsType: new FormControl('VENDOR')}),
      t100ContactCustomerId: new FormGroup({contactId: new FormControl(0), contactsType: new FormControl('CUSTOMER')}),
      t008Tax: new FormGroup({taxId: new FormControl(0)}),
      t006GLAccount: new FormGroup({gLAccountId: new FormControl(0)}),
      accountCode: new FormControl(''),
      subTotal: new FormControl({value: '0', disabled: false}),
      profileName: new FormControl('' ),
      paymentMadeHeaderId: new FormControl('0'),
      paymentReceivedHeaderId: new FormControl('0'),
      createdBy: new FormControl(''),
      journalHeaderId: new FormControl('0'),
      journalNumber:new FormControl(''),
      internalNotes:new FormControl(''),
      notes:new FormControl(''),
      expenseAccountId: new FormControl('0'),
      t100Contact: new FormGroup({contactId: new FormControl(0), contactsType: new FormControl('VENDOR')}),
      t023PaymentMode:new FormGroup({paymentModeId: new FormControl(0)}),
      itemNameForFetch:new FormControl(''),
      itemDescForFetch:new FormControl(''),
      userName:new FormControl(''),
      firstName:new FormControl(''),
      mobileNumber:new FormControl(''),
      officalEmail:new FormControl(''),
      address:new FormControl(''),
      terms: new FormGroup({lookupId: new FormControl(0)}),
      finalTotal: new FormControl({value: '0', disabled: false}),
      creditBalance: new FormControl(''),
      minTotalValue:new FormControl(''),
      maxTotalValue:new FormControl(''),
      customerNotes: new FormControl(''),
      termsandcondition: new FormControl(''),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
       moduleName: new FormControl(moduleName),
       journalType:new FormControl(''),
       statusText: new FormControl('DRAFT'),
       t200_Item: new FormGroup({itemName: new FormControl(''), 
         itemId: new FormControl('0'),
       }),
       asin:new FormControl(''),
         selectedProductType: new FormControl(''),
         upc: new FormControl(''),
         ean: new FormControl(''),
          t071GenderId: new FormGroup({
        genderId: new FormControl(0),
        name: new FormControl('')
      }),
        fullColorName: new FormControl(''),
         salesRateConfirmationFlag: new FormControl(''),
         purchaseRateConfirmationFlag: new FormControl(''),
         imagesConfirmationFlag: new FormControl(''),
         fullSizeConfirmationFlag: new FormControl(''),
         featureConfirmationFlag: new FormControl(''),
         scCreatedFlag:new FormControl(''),
         noOfChildInParentCount :  new FormControl(0),
         conditionId: new FormGroup({
        lookupId: new FormControl(0),
        lookupValue: new FormControl('')
      }),
       t206_CategoryObj:new FormGroup({categoryId:new FormControl(0),categoryName:new FormControl('')}),
       t207_SubCategoryObj:new FormGroup({subCategoryId:new FormControl(0),subCategoryName:new FormControl('')}),
       t004_LookupObj:new FormGroup({lookupId: new FormControl(0),lookupValue: new FormControl('ALL')}),
       t507PurchaseOrderHeader:new FormGroup({invoiceHeaderId: new FormControl(0)}), //for bill details -PO header id
       t507_PurchaseOrder_HeaderObj:new FormGroup({invoiceHeaderId: new FormControl(0)}), // for po receive details - po header id
      lazyLoad: new FormControl('N'),
      screenType:new FormControl(''),
      payCode: new FormControl(''),
      actualCode: new FormControl(''),
      itemName: new FormControl(''),
        sku: new FormControl(''),
        salesRate: new FormControl(),
        purchaseRate: new FormControl(),
        manufacturer:new FormControl(''),
        t054brandObj:new FormGroup({
          brandId: new FormControl(),
          brandName: new FormControl()
        }),
        brand:new FormControl(''),
        salesDescription: new FormControl(''),
        t006GLSalesAccount: new FormGroup({gLAccountId: new FormControl()}),
        t006GLPurchasesAccount: new FormGroup({gLAccountId: new FormControl()}),
        active: new FormControl(true),
        isDeleted: new FormControl('N'),
        statusFlag:new FormControl(''),
        displayOrder: new FormControl(''),
        earnDescription: new FormControl(''),
        lastName: new FormControl(''),
        pageNo: new FormControl('0'),
        pageSize: new FormControl(this.pageSize),
        recordCount: new FormControl('0'),
        filterType: new FormControl(''),
        contactsType: new FormControl('') ,
        contactDisplayName: new FormControl(''),
        company: new FormControl(''),
        email: new FormControl(''),
        dateSort: new FormControl(''),
        activeCriteria: new FormControl(''),
        workPhone:new FormControl(''),
        workPhoneWithMask:new FormControl(''),
        receivablesSort: new FormControl(''),
        payablesSort: new FormControl(''),
        bulkAction: new FormControl(''),
        userId: new FormControl(0),
        itemFlag:new FormControl(''),
        sortType: new FormControl('desc'),
        sortBy:new FormControl('CREATED_DATE'),
        assetName : new FormControl(''),
        t100ContactsVendorObj: new FormGroup({contactDisplayName: new FormControl(''), contactId: new FormControl('0'),contactsType: new FormControl('VENDOR')}),
        description : new FormControl(''),
        purchasedOn : new FormControl(''),
        modelNumber : new FormControl(''),
        assetNumber : new FormControl(''),
        initializeFlag: new FormControl('Y'),          
        lookupId: new FormControl('0'),
        lookupValue: new FormControl('ALL'),
        userFilter: new FormControl('all'),
        deleted: new FormControl(false),
        userFlag: new FormControl(''),
        /*commented this due to refresh grid before uncomment check the flow*/
       /* status: new FormControl(true),*/
        roleId: new FormControl('0'),
        paginationLoadFlag:new FormControl('Y'),
        empId : new FormControl(this.employeeId),
        t800Employee: new FormGroup({ 
			employeeId: new FormControl(0), 
			t818SalaryGroup: new FormGroup({
				salaryId: new FormControl(0)
			})
		}),
        name : new FormControl(''),
        mobileNo : new FormControl(''),
        t816Designation: new FormGroup({designationId: new FormControl(0)}),
        t009Department: new FormGroup({departmentId: new FormControl(0)}),
        t818SalaryGroup: new FormGroup({salaryId: new FormControl(0)}),
        t028Location: new FormGroup({locationId: new FormControl(0)}),
        t302SalesPersons: new FormGroup({salesPersonId: new FormControl(0)}),
        t302SalesPersonName: new FormControl(''),
        searchGridFlag: new FormControl(''),
        routeUrl:new FormControl(''),
        referenceNumber: new FormControl(''),
        customerName:new FormControl(''),
        repEmail:new FormControl(''),
        productionSlipNumber: new FormControl(),
        soNumber:new FormControl(),
        t001UserCreatedBy: new FormGroup({userId: new FormControl(''), type: new FormControl(''), userName: new FormControl('')}),

   artworkCSAName:new FormControl(''),
   primaryContactEmail:new FormControl(''),
   artServiceVendorName:new FormControl(''),
   decoVendorName:new FormControl(''),
   t401InvoiceDetailsList: new FormArray([]),
   t525_PurchaseOrderReceive_DetailsList: new FormArray([]),
   taskList: new FormArray([]),
    summary: new FormControl(false),
   t823ParentTask: new FormGroup({taskId: new FormControl(0),taskName:new FormControl()}),
   t800EmployeeObj: new FormGroup({
     employeeId: new FormControl(0),
     userName: new FormControl('')
   }),
   autoSchedule: new FormControl(false),
   taskName: new FormControl(''),
   taskDesc: new FormControl(''),
   taskNumber: new FormControl(''),
   shiftName: new FormControl(''),
   startTime: new FormControl(),
   endTime: new FormControl(),
   startDate: new FormControl(''),
   endDate: new FormControl(''),
   //T_5517 / 9014
   deliverystartDate: new FormControl(''),
   deliveryendDate: new FormControl(''),
   t001_ResponsibleUserObj: new FormGroup({ userId: new FormControl(0) }),
   allocatedUserObj: new FormGroup({ userId: new FormControl(0) }),
   //T_5517 / 9014
   prodmoveDate : new FormControl(''),
   resourceId: new FormControl(0),
   displayDicardMessage:new FormControl('Y'),  
   fileUpload : new FormControl(''),
validationString:new FormControl(''),
billing_hours:new FormControl(0),
percent_complete:new FormControl(0),
actualHours:new FormControl(0),
/** job form **/
jobCode:new FormControl(''),
title:new FormControl(''),
yearExperience:new FormControl(0),
monthExperience:new FormControl(0),
requiredSkills:new FormControl(''),
location:new FormControl(''),
salaryStartRange: new FormControl(),
salaryEndRange: new FormControl(),
/** job form **/
/** interviewslot form **/
t842_JobName:new FormGroup({jobId:new FormControl(0)}),
/** interviewslot form **/
/** appointment form */
interviewerNameIdList:new FormControl(),
interviewerName: new FormControl(''),
status: new FormControl(),
interviewType: new FormGroup({
  lookupCode: new FormControl(),
  lookupId: new FormControl(),
  lookupValue: new FormControl(),
}),
interviewStatus: new FormGroup({
  lookupCode: new FormControl(),
  lookupId: new FormControl(),
  lookupValue: new FormControl(),
}),
/** appointment form */
/** Candidate Form */
candidateCode: new FormControl(),
industry: new FormGroup({
  industryId: new FormControl()
}),
searchKeyword: new FormControl(),
totalYearsOfExp: new FormControl(),
noticePeriod: new FormControl(),
/** Candidate Form */
   isPagination:new FormControl('true'),
   productionSlipHeaderId:new FormControl(0),
   workOrderNumber:new FormControl(''),
   loggedInUserObj: new FormGroup({userId: new FormControl(0),type: new FormControl(''),
    userName: new FormControl('')}),
   createdById: new FormGroup({userId: new FormControl(0)}),
   t507_PurchaseOrder_Header_Obj:new FormGroup({invoiceHeaderId:new FormControl(0)}),
   refForeignKeyId:new FormControl(0),
   refForeignKeyId1:new FormControl(0),
   styleNumber: new FormControl(''),
   portalName: new FormControl(''),
   itemNumber: new FormControl(''),
   mpn: new FormControl(''),
   refContactId:new FormControl(0),
   refItemId:new FormControl(0),
   contactType:new FormControl(''),
   loadAsTabFlag: new FormControl(false),
   billId:new FormControl(0),
   lookUpIdList: new FormArray([]),
   clientViewable: new FormControl(''),
   globalFlag : new FormControl(false),
   taskType:new FormControl('S'), //S - SUMMARY / NS - NON SUMMARY / A - ALL 
   versionNumber : new FormControl(''),
   pmtRefId : new FormControl(''),
   createdByEmail: new FormControl(''),
   currencySymbol:new FormControl(''),//added for binding currency symbol in export data
   t001_UserObj:new FormGroup({userId:new FormControl(0)}),
   t001ResourceObj:new FormGroup({userId:new FormControl(0)}),
   t800_EmployeeObj: new FormGroup({ employeeId: new FormControl(0)}), // Self appraiser employeed id
   t800_ApproverObj: new FormGroup({ employeeId: new FormControl(0)}), // Next level approver employeed id
   oneToOneDiscussionFlag: new FormControl(false),
   oneToOneDiscussionComments: new FormControl(''),
   license: new FormControl(''),
   salesOrderQBSync: new FormControl(false),
   billQBSyncStatus: new FormControl(false),
   paymentReceivedQBSyncStatus: new FormControl(false),
   estimateQBSyncStatus : new FormControl(false),
   invoiceQBSyncStatus : new FormControl(false),
   expenseQBSyncStatus : new FormControl(false),
   paymentMadeQBSyncStatus : new FormControl(false),
   vendorCreditsQbSyncStatus : new FormControl(false),
   purchaseOrderQBSyncStatus : new FormControl(false),
   itemQBSyncStatus : new FormControl(false),
   creditNotesQBSyncStatus : new FormControl(false),
   attachPdfInEmail : new FormControl(false),
   fiscalYearObj: new FormGroup({
         lookupId: new FormControl(''),
         lookupCode: new FormControl(''), 
        lookupValue: new FormControl('')
       }),
       budgetPeriodObj: new FormGroup({
         lookupId: new FormControl(''),
         lookupCode: new FormControl(''), 
        lookupValue: new FormControl('')
       }),
       announcementDescription:new FormControl(''),
       announcementId:new FormControl(0),
      receivingsHeaderId:new FormControl(0),
      purchaseDate:new FormControl(new Date()),
      receivingDate:new FormControl(new Date()),
      expectedUnits:new FormControl(''),
      unitsReceived:new FormControl(''),
      discrepancies:new FormControl(''),
      lotNumber:new FormControl(''),
      orderNumber:new FormControl(''),
      scPoNumber:new FormControl(''),
      noOfPallets:new FormControl(''),
      t218ReceivingDetailsList:this.formBuilder.array([]),
      t004VendorId:new FormGroup({lookupId: new FormControl(0),lookupCode: new FormControl(''), lookupValue: new FormControl('')}),
      t004OrderStatusId:new FormGroup({lookupId: new FormControl(0),lookupCode: new FormControl(''), lookupValue: new FormControl('')}),
      t004LotTypeId:new FormGroup({lookupId: new FormControl(0),lookupCode: new FormControl(''), lookupValue: new FormControl('')}),
      t004CreatedPOInSCId:new FormGroup({lookupId: new FormControl(0),lookupCode: new FormControl(''), lookupValue: new FormControl('')}),
      t206GroupId:new FormGroup({categoryId:new FormControl(0),categoryName:new FormControl('')}),

   typeSalesOrReceipt: new FormControl('')
    });
  
    // Logged in user details is set in the invoiceForm  - 9071
    invoiceForm.get('loggedInUserObj').get('userId').setValue(this.commonService.loggedInUserObj.userId);
    invoiceForm.get('loggedInUserObj').get('userName').setValue(this.commonService.loggedInUserObj.userName);
    invoiceForm.get('loggedInUserObj').get('type').setValue(this.commonService.loggedInUserObj.type);
    
    return invoiceForm;
  }
  public addLineItem(): FormGroup {
    return new FormGroup({
      invoiceDetailsId: new FormControl('0'),
      quantity: new FormControl('1'),
      rate: new FormControl('0'),
      lineitemtaxrate: new FormControl('0'),
      lineitemtaxvalue: new FormControl('0'),
      amount: new FormControl('0'),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(1)}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(1)}),
      itemName: new FormControl('1'),
      itemDescription: new FormControl('1'),
      itemId: new FormControl('0'),
      associationLinkFlag: new FormControl(''),
      t008Tax: new FormGroup({taxId: new FormControl('0')}),

        t200_Item: new FormGroup({
        itemName: new FormControl(''),
        itemId: new FormControl('0'),
        active: new FormControl(true),
        rate: new FormControl('0'),
        isDeleted: new FormControl('N'),
        createdTs: new FormControl(new Date()),
        updatedTs: new FormControl(new Date()),
        t001UserCreatedBy: new FormGroup({userId: new FormControl(1)}),
        t001UserUpdatedBy: new FormGroup({userId: new FormControl(1)}),
      }),
      childHeaderObj: new FormGroup({invoiceDetailsId: new FormControl('')})
    });
  }

  public validateAccess(){

  }
  initializeAccessForModule()
  {
   if(this.moduleName=='VIEW_PO')
    {
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_BILL');
       if(this.convertAccessObj.createAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
    if(this.moduleName=='VIEW_SALESORDER')
    {
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_INVOICE');
       if(this.convertAccessObj.createAccess==true)
       this.convertInvoiceFlag=true;
       else
       this.convertInvoiceFlag=false;
    }
   if(this.moduleName=='VIEW_ESTIMATE')
    { 
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_INVOICE');
       if(this.convertAccessObj.createAccess==true || this.convertAccessObj.createAccess=='true' )
       this.convertInvoiceFlag=true;     
       else
       this.convertInvoiceFlag=false;

       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_SALESORDER');
       if(this.convertAccessObj.createAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
    if(this.moduleName=='VIEW_ARTWORK')
    { 
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_SALESORDER');
       if(this.convertAccessObj.createAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
   if(this.moduleName=='VIEW_CREDIT_NOTES')
    {
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_INVOICE');
       if(this.convertAccessObj.editAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
    if(this.moduleName=='VIEW_VENDOR_CREDIT')
    {
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_BILL');
       if(this.convertAccessObj.editAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
     if(this.moduleName=='VIEW_INVOICE')
    {
      
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_CREDIT_NOTES');
       if(this.convertAccessObj.editAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;

       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_PAYMENT_RECIEVED');
       if(this.convertAccessObj.createAccess==true)
       this.recordPaymentFlag=true;
       else
       this.recordPaymentFlag=false;
    }
   if(this.moduleName=='VIEW_BILL')
    {
       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_VENDOR_CREDIT');
       if(this.convertAccessObj.editAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;

       this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_PAYMENT_MADE');
       if(this.convertAccessObj.createAccess==true)
       this.recordPaymentFlag=true;
       else
       this.recordPaymentFlag=false;
    }
    if(this.moduleName=='PART_REQUEST')
    {
      this.convertAccessObj= this.commonService.getAccessObjForCurrentScreen('NEW_PO');
       if(this.convertAccessObj.createAccess==true)
       this.convertFlag=true;
       else
       this.convertFlag=false;
    }
   
  }
  

  checkNaN(element: any): number {
    element = isNaN(element) ? 0 : +element;
    return element;
  }

  fetchBatchSync()
  {
      this.commonService.showProgressBar();
      if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);
      this.settingsService.fetchBatchSync(this.invoiceForm.value).
      subscribe(
       data => {
          
         this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
          }
       },
       error => {
       
       
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
    
  }

  selectedSummaryTask:any;
  onParentTaskIconClick(element){
     if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
    this.invoiceForm.get("t823ParentTask.taskId").setValue(element.taskId);
    this.invoiceForm.get("validationString").setValue('false');
    this.resetPagination();
    if(this.invoiceForm.get('t822ProjectObj.projectId').value!=0)
    this.fetchProjectPlan();
    else
    this.viewGridLoad();  
  }

addBreadCrumb(data){
  this.selectedTaskObj=data;
  this.breadcrumFlag=true; 
  if(!this.commonUtils.checkNullOrUndefined(this.selectedTaskObj) ||  this.selectedTaskObj<= 0)
        this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:[]}); 
  //calling the project plan load when child task is clicked 
  this.viewGridLoad();
}

resetPagination(){
   this.pageNo=0;
      
       this.invoiceForm.get('pageNo').setValue(this.pageNo);
        this.invoiceForm.get('pageSize').setValue(this.pageSize);
}

fetchDataBase(versionNumber)
{
  this.versionNumber = versionNumber;
  this.commonService.showProgressBar();
  if(this.commonUtils.checkNullOrUndefined(versionNumber))
    this.invoiceForm.get('versionNumber').setValue(versionNumber);
  else
    this.invoiceForm.get('versionNumber').setValue(null);

  if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
  this.invoiceForm=this.getSaveForm(this.moduleName);
  this.commonService.fetchDataBase(this.invoiceForm.value).subscribe(
       data => {
         this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
        
       },
       error => {
       
       
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
}

  sendEmailFromReviewScreen(result): void
      {   
         this.commonService.showProgressBar();
          this.commonService.sendEmailFromReviewScreen(result).subscribe(
           data => {
              this.commonService.hideProgressBar();
              this.commonService.showERPMessage('Email sent successfully');
              this.closeEditTopDialog();
                    },
           error => {
              this.commonService.hideProgressBar();
             this.commonService.showERPErrorMessage(error);
           });
     }
     /*searchResultsCheckBoxSelected(selection){
       this.selection=selection;
     }*/
     userDisplayColumn(columns){
       if(this.commonUtils.checkNullOrUndefined(columns) && columns.length > 0){
         const originalIndex: number = this.displayedColumns.indexOf(columns[0].columnName);
         const index: number = this.userDisplayedColumns.indexOf(columns[0].columnName);
         
           if(columns[0].flag==false)
           {
             if (index!== -1) 
             this.userDisplayedColumns.splice(index, 1);
           }
           else if(columns[0].flag==true)
           {
             if (originalIndex !== -1)
             this.userDisplayedColumns.splice(originalIndex, 0,columns[0].columnName);
           }
        }
        let resultantStr = [];
        for(var loopCount = 0; loopCount< this.displayedColumns.length; loopCount++){
          for(var innerLoopCount = 0; innerLoopCount < this.userDisplayedColumns.length; innerLoopCount++){
            if(this.displayedColumns[loopCount] === this.userDisplayedColumns[innerLoopCount]){
              resultantStr.push(this.displayedColumns[loopCount]);
            }
          }
          
        }
        var str = resultantStr.join();
        
        if(this.commonService.userDisplayedColumnsMap.has(this.moduleName)){
          this.userDisplayForm.get('columnId').setValue(this.commonService.userDisplayedColumnsMap.get(this.moduleName).columnId);
          this.userDisplayForm.get('lookupIds').setValue(this.commonService.userDisplayedColumnsMap.get(this.moduleName).lookupIds);
        }
        else
          this.userDisplayForm.get('columnId').setValue(0);
        this.userDisplayForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
        this.userDisplayForm.get('moduleName').setValue(this.moduleName);
        this.userDisplayForm.get('columnName').setValue(str);
        
        this.commonService.saveOrUpdateUserDisplayColumns(this.userDisplayForm.value).subscribe(
        data=>{
               
               this.loadPageHeaderLabels();
         
        },
        error=>{
          this.commonService.showERPErrorMessage(error);
        });
    }
     executeQuery(databaseDetailsObj){
       
       this.commonService.executeQuery({t052DatabaseScriptList:this.selection.selected,dataBaseObj:databaseDetailsObj}).subscribe(
    data=>{
      if(data){
    
     this.commonService.showERPMessage(" Statements executed successfully ");
     this.viewGridLoad();
      this.commonService.hideProgressBar();
   //  this.commonService.showERPMessage(data.erpmessage);
  
      }
      },error=>{
       this.commonService.showERPErrorMessage(error);

    });
     }

fetchAppliedMedicalBill(): void {
    
    this.hrmService.fetchAppliedMedicalBill(this.invoiceForm.value).subscribe(
      data => {    
      this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }

  fetchAutomatedEmailDetails(): void{
    this.commonService.fetchAutomatedEmailDetail(this.invoiceForm.value).subscribe(
      data => {    
      this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }

  fetchTCLinkMaster()
  {
    
      this.commonService.showProgressBar();
      if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);
      this.commonService.fetchTCLinkMaster(this.invoiceForm.value).
      subscribe(
       data => {
         this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
          }
       },
       error => {
       
       
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
    
  }
   fetchAllResourceExtensionProjectWise()
  {
    this.commonService.showProgressBar();
     this.invoiceForm.get('pageSize').setValue(this.pageSize);
     this.invoiceForm.get('paginationLoadFlag').setValue('');
     this.hrmService.fetchResourceExtension(this.invoiceForm.value).subscribe(
      data => { 
            this.allResourceExtensionList=data;
                this.commonService.hideProgressBar();
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }


  fetchResourceExtension()
  {
    this.commonService.showProgressBar();
     this.invoiceForm.get('pageSize').setValue(this.pageSize);
     this.invoiceForm.get('paginationLoadFlag').setValue('Y');
     this.hrmService.fetchResourceExtension(this.invoiceForm.value).subscribe(
      data => {   
       this.resourceExtensionList=data;
      this.commonService.hideProgressBar();     
         this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
      },
       error => {
        this.commonService.showERPErrorMessage(error);
        this.commonService.hideProgressBar();  
      });
  }

  fetchSuperadminMenu():Promise<any> {
    if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);
    if(this.commonUtils.checkNullOrUndefined(this.schemaValue))
    {
      this.invoiceForm.get('license').setValue(this.schemaValue)
    }
     return new Promise(resolve=>
      this.commonService.fetchSuperadminMenu(this.invoiceForm.value).
      subscribe(
        data => { 

         this.commonService.hideProgressBar();
         this.dataSource = new MatTableDataSource<any>(data);
         if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
          }
          resolve(data); 
       },
       error => {
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       }));

  }
  fetchReviewDispayColumn(){
    this.commonService.showProgressBar();
    if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
      this.invoiceForm=this.getSaveForm(this.moduleName);
    
    this.commonService.fetchReviewDisplayColumnScreen(this.invoiceForm.value).subscribe(
     data => 
       {  
         this.commonService.hideProgressBar(); 
         this.dataSource = new MatTableDataSource<any>(data);
         if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
             
       }
     },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });
  }


fetchSuperadminValidation(): void {
 
          this.commonService.hideProgressBar();
          this.dataSource = new MatTableDataSource<any>(this.commonService.validationSettingList[0]);
           if (this.commonUtils.checkNullOrUndefined(this.commonService.validationSettingList[0])) {
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
    }


  fetchResourceExtensionEmptyGrid()
  {
    this.isEmptyGrid=true;
    if(this.refreshFlag == true){
      this.dataSource = new MatTableDataSource<any>([]);
    }
  }
  fetchProjectPlanEmptyGrid()
  {

  if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT'){
   this.noRecord= false;
   this.commonService.projectSubject.next( this.noRecord);
   }  
   this.isEmptyGrid=true;
  }

  userCheckFlag:boolean=false;
  userList=[];
  checkForResourceExtension(form:any)
  {
     if(this.commonUtils.checkNullOrUndefined(this.allResourceExtensionList))
     {
          let statusFlag:boolean=false;
          
         for(var loop=0;loop<this.allResourceExtensionList.length;loop++)
         {
             if(this.allResourceExtensionList[loop].t001_UserObj.userId === form.t001_UserObj.userId)
             {
                     this.commonService.showERPErrorMessage(this.resourceExtensionError); 
               this.userCheckFlag = true;       
               break;
             }
         }

            if(!this.userCheckFlag)
            {
              this.userList.push(form);
            }

  }
}
// below code is commented which is used for previous logic in resource extension
         /*if(statusFlag==false)
         {
            this.commonService.showProgressBar();
            this.hrmService.saveOrUpdateResourceExtension(value).subscribe(
            data=>
            {
            this.commonService.hideProgressBar();
            this.commonService.showERPMessage("Resource added successfully");
            this.refreshPage({action:"REFRESH_RESOURCE",data:value.t822ProjectObj.projectId});

            },
            error=>
            {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            })
         }
         else
         {
           this.commonService.showERPErrorMessage(this.resourceExtensionError);
         }

        }*/
      /*  else
        {
            this.commonService.showProgressBar();
            this.hrmService.saveOrUpdateResourceExtension(value).subscribe(
            data=>
            {
            this.commonService.hideProgressBar();
            this.commonService.showERPMessage("Resource added successfully");
            this.refreshPage({action:"REFRESH_RESOURCE",data:value.t822ProjectObj.projectId});
            this.fetchAllResourceExtensionProjectWise();
            },
            error=>
            {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            })
        }

     
        
  }*/

  // above code is commented which is used for previous logic in resource extension
userExistFlag:boolean = false;
  saveOrUpdateResourceExtension(form){
             let count=0;
          //  this.commonService.showProgressBar();
           for(var outerLoop = 0 ; outerLoop < form.userIds.length ; outerLoop++){
                                
              for(var innerLoop = 0 ; innerLoop < this.allResourceExtensionList.length ; innerLoop++){
                this.userExistFlag = false;  
                if(this.allResourceExtensionList[innerLoop].t001_UserObj.userId == form.userIds[outerLoop]){
                 this.userExistFlag = true;
                 break;
                }

              }
              if(!this.userExistFlag){
                count++;
              form.t001_UserObj.userId = form.userIds[outerLoop] ;  
              if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
               form.t001_UserCreatedByObj.userId = this.commonService.loggedInUserObj.userId;
              // commented on 12, Oct 2022 by 9172 for removing updateby value in resource extension while creation and added Created TS
              //  form.t001_UserUpdatedByObj.userId = this.commonService.loggedInUserObj.userId; 
              form.createdTs = new Date();
              }
            this.hrmService.saveOrUpdateResourceExtension(form).subscribe(
            data=>
            {

            this.commonService.hideProgressBar();
            this.commonService.showERPMessage("Resources have been extended to the selected project successfully! Only the non-existing resources will be added");
            this.refreshPage({action:"REFRESH_RESOURCE",data:form.t822ProjectObj.projectId});

            },
            error=>
            {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            })
              }
               }
               if(count <= 0 && form.userIds.length > 0 ){
                 this.commonService.showERPMessage("Selected resources are already exist in the project");
           } 
            else if(count <= 0 && form.userIds.length === 0 ){
                 this.commonService.showERPMessage("No resources selected. Select atleast one resource to proceed");
        }
      }
            

            /*for(var loop=0;loop<this.resourceExtensionList.length;loop++){
              if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
               this.userList[loop].get('t001_UserCreatedByObj.userId').setValue(this.commonService.loggedInUserObj.userId);
               this.userList[loop].get('t001_UserUpdatedByObj.userId').setValue(this.commonService.loggedInUserObj.userId);
              }*/

           
          

         
  

     testCaseLinkForm: FormGroup = new FormGroup({
      linkId: new FormControl(0),
      endPointUrl:new FormControl(''),
      request:new FormControl(''),
      response:new FormControl(''),
      t004LookupStatusId: new FormGroup({lookupId: new FormControl(), lookupCode: new FormControl(''), lookupValue: new FormControl('')})
  });


     saveOrUpdateTestcaseLinkform(testCaseLinkForm){
 this.commonService.showProgressBar();
 
      this.commonService.saveOrUpdateTestCaseLinkMaster(testCaseLinkForm).subscribe(
        data => {
          this.commonService.hideProgressBar();
          this.commonService.showERPMessage(data.erpmessage);
          
        }, error => {
          this.commonService.showERPErrorMessage(error.erpmessage);
          this.commonService.hideProgressBar();
          //this.disableFlag=false;
        });
     }

  executeTestCAseLinkForm(){
       let obj = this.testCaseLinkForm.value
              
        for(var loop=0;loop<this.dataSource.data.length;loop++){
         let currentObj= this.dataSource.data[loop];
         let endpointUrl=currentObj.endPointUrl;
         let request=currentObj.request;
  
         let jsonObj=JSON.parse(currentObj.request);
           if(this.commonUtils.checkNullOrUndefined(endpointUrl) && this.commonUtils.checkNullOrUndefined(request)){
             try{
                this.commonService.executeTestCaseLink(endpointUrl,jsonObj).subscribe(
                data=>{
                  currentObj.response=data.erpmessage;
                  this.testCaseLinkForm.patchValue(currentObj);
                  this.testCaseLinkForm.get('response').setValue('success');
                  this.testCaseLinkForm.get('t004LookupStatusId.lookupValue').setValue('SUCCESS');
                  this.testCaseLinkForm.get('t004LookupStatusId.lookupCode').setValue('VIEW_TCLINKMASTER');
                  this.saveOrUpdateTestcaseLinkform(this.testCaseLinkForm.value);
              },
              error=>
              {
                currentObj.response=error.error.erpmessage;
                this.testCaseLinkForm.get('linkId').setValue(currentObj.linkId);
                this.testCaseLinkForm.get('response').setValue(currentObj.response);
                this.testCaseLinkForm.get('t004LookupStatusId.lookupValue').setValue('ERROR');
                this.testCaseLinkForm.get('t004LookupStatusId.lookupCode').setValue('VIEW_TCLINKMASTER');
                this.saveOrUpdateTestcaseLinkform(this.testCaseLinkForm.value);

              });
       }catch(errorObj){

       }finally{

       }
     }
      this.viewGridLoad();
     }
   }
  setTaskType(value){
    this.selectedTaskType = value;
  //  this.fetchProjectPlan();
  }

  setGlobalChangeFlag(value){
    this.selectedGlobalFlag = value;  
   // this.fetchProjectPlan();
  }

  loadOnlyFirstLevelParent(value){
    this.selectedTaskObj = {};
    this.selectedGlobalFlag = false;
    this.selectedTaskType = 'A';  
    this.resetProjectPlanValues();
    this.commonService.refreshClickedSubject.next({action:'RESET'});
    this.addBreadCrumb(value.data);
  }

  resetProjectPlanValues(){
    let oldValues = this.commonService.recordOldChangesForEachModuleMap.get(this.moduleName);
    if(this.commonUtils.checkNullOrUndefined(oldValues)){
      oldValues.taskName=null;
      oldValues.taskNumber=null;
      oldValues.taskDesc=null;
      oldValues.startDate=null; 
      oldValues.endDate=null; 
      oldValues.versionNumber=null;
       if(this.commonUtils.checkNullOrUndefined(oldValues.t001_UserObj) && this.commonUtils.checkNullOrUndefined(oldValues.t001_UserObj.userId))
       {
         oldValues.t001_UserObj.userId = null;
       }
     
    }
  }

  viewTemplateWrapperReceiver(emittedValue:any){
    switch (emittedValue.action) 
    {
      case "CHECKBOX_SELECTED":
         this.selection=emittedValue.data;
      break;
      case "GET_TASK_NAMES":
          this.selectedGlobalFlag = false;
          this.commonService.refreshClickedSubject.next({action:'UNCHECK_GLOBAL'});
          this.resetProjectPlanValues();
          this.addBreadCrumb(emittedValue.data);
      break;
      case "HOME_CLICKED":
        this.loadOnlyFirstLevelParent(emittedValue);
      break;
      case "TASK_TYPE_CHANGED":
        this.setTaskType(emittedValue.data);
      break;
      case "GLOBAL_CHKBOX_CHANGED":
        this.setGlobalChangeFlag(emittedValue.data);
      break;
      case "REFRESH_PAGE":
          this.refreshPage(emittedValue);
      break;
          case "REFRESH_RESOURCE":
          this.refreshPage(emittedValue);
      break;
       case "EXPORT_DATA":
          this.refreshPage(emittedValue);
      break;
      case "REFRESH_TASK":
          this.refreshPage(emittedValue);
      break;
      case "REFRESH_GRID":
          this.refreshPage(emittedValue);
      break;
      case "SEARCH_ICON_CLICK":
          this.searchIconClick();
      break;
      case "PRINT_DIALOG_OPEN":
        this.openDialogPrint(emittedValue.data,emittedValue.selectedObjectFromGrid);
      break;
      case "VIEW_HISTORY":
        this.openViewHistory(emittedValue.data);
      break;
      case "TRIGGER_TOGGLE_PANEL":
          this.togglePanel(emittedValue.data);
      break;
      case "GRID_CHECKBOX_SELECTED":
          this.gridCheckBoxSelected(emittedValue.data)
      break;
      case "USER_DISPLAY_COLUMN" :
          this.userDisplayColumn(emittedValue.data);
      break;
      case "EXECUTE_TC_LINK":
          this.executeTestCAseLinkForm();
      break;
      case "TEAM_LEAVE":
         this.fetchTeamLeave(emittedValue);
        break;
      case "MY_LEAVE":
         this.fetchMyLeave(emittedValue);
        break;
      case "REFRESH_TASK":
          this.refreshPage(emittedValue);
      break;
      case "CHECK_RESOURCE_EXTENSION":
         this.checkForResourceExtension(emittedValue.data);
      break;
     case "SAVE_RESOURCE_EXTENSION":
         this.saveOrUpdateResourceExtension(emittedValue.data);
      break;
      case "ALL_QUEUE":
         this.fetchAllQueue();
      break;
      case "MY_QUEUE":
         this.fetchMyQueue(emittedValue);
      break;
      case "FILTER_CHANGED":
      this.filterChangedFunction(emittedValue.data);
      break;
      case "DEFAULT_FILTER":
      this.defaultFilterFunction(emittedValue.data);
      break;
      case "MORE_ACTIONS":
      this.openDialogPrint(emittedValue.data.lookupValue);
      break;
      case "APPRAISAL_LEVEL":
         this.employeeAppraisalLevel(emittedValue.data);
      break;
      case "EMIT_ALL_VALUES":
         this.emitAllSelectedValues(emittedValue.data); 
      break;
       case "SCHEMA_LIST":
         this.schemaListFunction(emittedValue.data); 
      break;
      case "LOAD_USERS":
        this.fetchOrgUsersForDropDown();
      break;
      case "CLEAR_BREADCRUMB":
        this.clearBreadCrumb();
      break;
      case "LOAD_VERSION_NUMBER":
        this.fetchDataBase(emittedValue.data);
      break;
      case "PROJECT_BASED_TIMESHEET":
         this.patchProjectInTimesheet(emittedValue.data); 
      break;
      case "CLOSE_TOP_SEARCH":
          this.viewTemplateWrapperEmitter.emit({action:"CLOSE_TOP_SEARCH"});
      break;
      case "OPEN_DAILOG_POPUP":
          this.openDialogPopupForTopSearch(emittedValue.data);
      break;
      case "UPDATE_REVOKE_APPLIED_LEAVE_STATUS":
          this.updateRevokestatus(emittedValue.data);
      break;
      case "EMPLOYEE_CHANGE_FOR_FEEDBACK":
          this.loadFeedbackForSelectedEmployee(emittedValue.value);
      break;
      case "EMPLOYEE_CHANGE_FOR_APPRAISAL_FORM":
          this.loadAppraisalForSelectedEmployee(emittedValue.value);
      break;
      case "CREATED_DATE_BASED_FEEDBACK_FETCH":
          this.loadFeebackForSelectedCreatedDate(emittedValue.value);
      case "REFRESH_RESOURCE_BASED_PROJECT":
            this.refreshPage(emittedValue);
            break;
      default:
        // code...
        break;
    }
  }
patchProjectInTimesheet(data)
{
 if(this.commonUtils.checkNullOrUndefined(data))
 {
   this.invoiceForm.get('t823_taskObj.t822ProjectObj.projectId').setValue(data.projectValue);
   this.selectedProjectId=data.projectValue;
 }
 
 this.fetchTimeSheet();
}

loadFeedbackForSelectedEmployee(selectedEmployeeId){
  this.invoiceForm.get('t800EmployeeObj.userName').setValue('');
  this.invoiceForm.get('t800EmployeeObj.employeeId').setValue(selectedEmployeeId);
  this.fetchHRMFeedback();
}
loadFeebackForSelectedCreatedDate(createdDate){ 
this.invoiceForm.get('createdTs').setValue(createdDate);
 this.invoiceForm.get('statusText').setValue("SEARCH_BY_CREATED_DATE");
  this.fetchHRMFeedback();
}
loadAppraisalForSelectedEmployee(selectedEmployeeId){
  this.invoiceForm.get('t800EmployeeObj.userName').setValue('');
  this.invoiceForm.get('invoiceStartDate').setValue(selectedEmployeeId.invoiceStartDate);
  this.invoiceForm.get('invoiceEndDate').setValue(selectedEmployeeId.invoiceEndDate);
  if (this.commonUtils.checkNullOrUndefined(selectedEmployeeId.filterEmployeeOptionValue))
    this.invoiceForm.get('t800_EmployeeObj.employeeId').setValue(selectedEmployeeId.filterEmployeeOptionValue);
  this.fetchEmployeeAppraisalLevel();
}

defaultFilterFunction(data){
  let lookupList;
  if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)
  {
    for(var loop=0; loop<data.length; loop++){
      if(loop==0)
        lookupList=data[loop].lookupId;
      else
        lookupList=lookupList+','+data[loop].lookupId;
    }
  }

  if(this.commonService.userDisplayedColumnsMap.has(this.moduleName)){
    this.userDisplayForm.get('columnId').setValue(this.commonService.userDisplayedColumnsMap.get(this.moduleName).columnId);
    this.userDisplayForm.get('columnName').setValue(this.commonService.userDisplayedColumnsMap.get(this.moduleName).columnName);
  }
  else{
    this.userDisplayForm.get('columnId').setValue(0);
    this.userDisplayForm.get('columnName').setValue(this.commonService.reviewDisplayedColumnsMap.get(this.moduleName));
  }
    this.userDisplayForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
    this.userDisplayForm.get('moduleName').setValue(this.moduleName);
    this.userDisplayForm.get('lookupIds').setValue(lookupList);
        
        this.commonService.saveOrUpdateUserDisplayColumns(this.userDisplayForm.value).subscribe(
        data=>{
              this.commonService.showERPMessage("Default Filter values saved ");
        },
        error=>{
          this.commonService.showERPErrorMessage(error);
        });
}
filterChangedFunction(data){
  
          const selectedOption = data; 
          this.invoiceForm.get('lookUpIdList').reset();
          
          let lookupList = this.invoiceForm.get('lookUpIdList') as FormArray ;
                 
          if(this.commonUtils.checkNullOrUndefined(selectedOption) && selectedOption.length > 0)
          {
            for(var loop=0; loop < selectedOption.length; loop++){
              lookupList.push(this.lookupList(selectedOption[loop].lookupId,selectedOption[loop].lookupValue));
            }       
          } 
          this.resetPagination();
          this.commonService.bindOldChangesInModule(this.moduleName,this.invoiceForm.value);
          //this.viewGridLoad();
          if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT')
          {
            if(this.invoiceForm.get('t822ProjectObj.projectId').value!=0)
            this.fetchProjectPlan();
            else
            this.viewGridLoad();
          }
          else
          {
            this.viewGridLoad();
          }
}
lookupList(lookupId,lookupValue): FormGroup {
      return this.formBuilder.group({
          lookupId: new FormControl(lookupId),
          lookupValue: new FormControl(lookupValue)
        });
    }

sortClickedFunction(emittedValue){


         
}
      
openViewHistory(emittedValue){
  const dialogRef = this.dialog.open(ViewHistoryComponent, {
          width: '90%',
          height: '30%',
          data:emittedValue
        });

        dialogRef.afterClosed().subscribe(result => {
        });
  
}



  fetchTeamLeave(emittedValue){
    this.applyLeaveForm.get('approverId').setValue(emittedValue.data);
    this.applyLeaveForm.get('empId').setValue(0);
     this.displayForm.get('moduleName').setValue('VIEW_TEAM_LEAVE')
    this.commonService.fetchReviewDisplayColumn(this.displayForm.value).
      subscribe(
       data => 
       { 
           if(this.commonUtils.checkNullOrUndefined(data.columnName))       
             this.userDisplayedColumns=data.columnName.split(",");
         this.commonService.hideProgressBar();         
       },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      }); 
    this.fetchApplyLeave();
  }
  fetchMyLeave(emittedValue){
    this.applyLeaveForm.get('approverId').setValue(0);
    this.applyLeaveForm.get('empId').setValue(emittedValue.data);
    this.displayForm.get('moduleName').setValue('VIEW_LEAVE')
    this.commonService.fetchReviewDisplayColumn(this.displayForm.value).
      subscribe(
       data => 
       { 
           if(this.commonUtils.checkNullOrUndefined(data.columnName))       
             this.userDisplayedColumns=data.columnName.split(",");
         this.commonService.hideProgressBar();         
       },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      }); 
    this.fetchApplyLeave();
  }
fetchAllQueue(){
  this.applyLeaveForm.get('empId').setValue(0);
   this.applyLeaveForm.get('trxType').setValue('DEBIT');
  this.applyLeaveForm.get('requestFlag').setValue('All_Queue');
  this.fetchAllAppliedLeaves();
}
fetchMyQueue(emittedValue){
  this.applyLeaveForm.get('empId').setValue(emittedValue.data);
  this.applyLeaveForm.get('requestFlag').setValue('My_Queue');
  this.fetchAllAppliedLeaves();
}
fetchTimeSheet(){
  this.commonService.showProgressBar();
if(this.moduleName != 'HRIS_TIMESHEET_HISTORY'){
    if(this.commonUtils.checkNullOrUndefined(this.selectedProjectId) && this.selectedProjectId!=0)
  this.invoiceForm.get('t823_taskObj.t822ProjectObj.projectId').setValue(this.selectedProjectId);

         this.invoiceForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
}
  
      this.hrmService.fetchTimeSheet(this.invoiceForm.value).subscribe(
        data => {
         this.commonService.hideProgressBar();
       this.dataSource = new MatTableDataSource<any>(data);
        if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) 
        {
             if (data.length > 0) 
             {
              this.recordCount = data[0].totalRecordCount;
             }
             this.isEmptyGrid = false;
             if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
              this.PaginationComponentObj.ngOnInit();
        } else 
           {
             this.isEmptyGrid = true;
           }
        }, error => {
          this.commonService.showERPErrorMessage(error);
          this.commonService.hideProgressBar();
          //this.disableFlag=false;
        });
}


     fetchAppraisalMaster() {
      this.commonService.showProgressBar();
       if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
        // Added status as true - Invoice form returns string as Status values - changes done by 9121 on 13 OCT, 2022
        this.invoiceForm.get('status').setValue(true);
      //this.invoiceForm.get('initializeFlag').setValue('N');
      this.hrmService.fetchAppraisalMaster(this.invoiceForm.value).
      subscribe(
       data => {
         this.appraisalLevelList = data;
         this.commonService.hideProgressBar();
         //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
             this.dataSource = new MatTableDataSource<any>(data);
          if ( this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
         this.recordCount = data[0].totalRecordCount;
        this.isEmptyGrid = false;
        if(this.commonUtils.checkNullOrUndefined(this.PaginationComponentObj))
          this.PaginationComponentObj.ngOnInit();
           }else {
               this.isEmptyGrid = true;
            }
       },
       error => {
        
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
       });
    
  }

  employeeAppraisalLevel(value:any){
  let saveFlag = '';
  if(this.commonUtils.checkNullOrUndefined(value.t828_AppraisalObj.appraisalId)&& value.t828_AppraisalObj.appraisalId!=0){
    for(let loop =0; loop < this.allAppraisalLevelList.length; loop++) {
      if(this.allAppraisalLevelList[loop].t001_UserObj.userId === value.t001_UserObj.userId && this.allAppraisalLevelList[loop].t828_AppraisalObj.appraisalId === value.t828_AppraisalObj.appraisalId){
        this.commonService.showERPErrorMessage(this.appraisalLevelassociationError);
        saveFlag = 'AlreadyExist';
        
      }
    }

   if(saveFlag != 'AlreadyExist') {
     
       this.commonService.showProgressBar();
            this.hrmService.saveOrUpdateEmployeeAppraisalLevel(value).subscribe(
            data=>
            {
            this.commonService.hideProgressBar();
            this.commonService.showERPMessage("Appraisal added successfully");
            this.refreshPage({action:"REFRESH_PAGE"});

            },
            error=>
            {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            })
    }
  }

           
  }

  fetchEmployeeAppraisalLevel()
  {
    this.invoiceForm.get('pageSize').setValue(this.pageSize);
    /*added to restrict the records based on logged in object (view appraisal is employee menu)*/
   
    if(this.moduleName==='VIEW_APPRAISAL_FORM' && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.roleName) && 
      this.commonService.loggedInUserObj.roleName != 'HR' && this.commonService.loggedInUserObj.roleName != 'ADMIN'){ 
       this.invoiceForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
       /************ Add next approver object to view all pending appraisel from Logged-in Appraiser - starts  **********/
        this.invoiceForm.get('t800_ApproverObj.employeeId').setValue(this.commonService.loggedInUserObj.employeeId);
        /************ Add next approver object to view all pending appraisel from Logged-in Appraiser  - ends **********/
    }
    else if(this.moduleName==='VIEW_APPRAISAL_FORM' && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.roleName) && 
      (this.commonService.loggedInUserObj.roleName === 'HR' || this.commonService.loggedInUserObj.roleName === 'ADMIN')){
        // Set both the UserId and ApproverId as 0 which means it will fetch all the Appraisals
        this.invoiceForm.get('t001_UserObj.userId').setValue(0);
        this.invoiceForm.get('t800_ApproverObj.employeeId').setValue(0);
    }
    /*added to restrict the records based on logged in object (view appraisal is employee menu)*/ 
     this.hrmService.fetchEmployeeAppraisalLevel(this.invoiceForm.value).subscribe(
      data => {    
      this.commonService.hideProgressBar();
      this.allAppraisalLevelList = data;
         this.dataSource = new MatTableDataSource<any>(data);
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.recordCount = data[0].totalRecordCount;
             this.isEmptyGrid = false;
           } else {
             this.isEmptyGrid = true;
           }
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }

  fetchVersionNumber(){
    if(!this.commonUtils.checkNullOrUndefined(this.invoiceForm))
        this.invoiceForm=this.getSaveForm(this.moduleName);
      
    this.commonService.fetchVersionNumberFromData(this.invoiceForm.value).
      subscribe(
      data => {
        this.commonService.hideProgressBar();
        if(this.commonUtils.checkNullOrUndefined(data))
          this.versionNumberList = data;
      },
      error => {
        
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  }

  emitAllSelectedValues(value){
    this.selectedFilterObj = value.filterOptionValue;
    this.setTaskType(value.taskType);
    this.setGlobalChangeFlag(value.globalFlag)
    this.refreshPage({action:"REFRESH_TASK",value:value.projectValue});
    this.initializeEmitChanges();
  }
schemaListFunction(value)
{
  this.schemaValue = value;
  this.fetchSuperadminMenu();
}
clearBreadCrumb(){
    this.breadCrumblist =[];
    this.addBreadCrumb(this.breadCrumblist);
}


    //method for opening productionslip and salesperson dailog popup
    openDialogPopupForTopSearch(topSearchObj){
      if(topSearchObj.moduleName === 'TS_VIEW_SALES_PERSON')
      {
       let spId=topSearchObj.salesPersonId;
        this.router.navigate(['/contacts/reviewsalesperson',spId]);
       this.viewTemplateWrapperEmitter.emit({action:"CLOSE_TOP_SEARCH"});
        
      }
    else if(topSearchObj.moduleName === 'TS_VIEW_PRODUCTION_SLIP')
      {
        
        let prodSlipId=topSearchObj.productionSlipHeaderId;
        this.router.navigate(['/sales/reviewProductionSlip',prodSlipId]);
       this.viewTemplateWrapperEmitter.emit({action:"CLOSE_TOP_SEARCH"});
     }
  }

   updateRevokestatus(element){
    this.applyLeaveForm.patchValue(element);
    this.applyLeaveForm.get('t004LookupStatusObj.lookupValue').setValue('REVOKED');
    this.applyLeaveForm.get('clApproved').setValue(0);
    this.applyLeaveForm.get('compOffApproved').setValue(0);
    this.applyLeaveForm.get('slApproved').setValue(0);
    this.hrmService.saveApplyLeave(this.applyLeaveForm.value).subscribe(
      data => {  
        this.fetchApplyLeave();
      },
       error => {
        this.commonService.showERPErrorMessage(error);
       
      });
  }

  fetchTraineeDetials(){

    let userid=0;
    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.userId)){
             userid= this.commonService.loggedInUserObj.userId;
    }
    this.commonService.showProgressBar();
    this.invoiceForm.get('userId').setValue(userid);
    this.commonService.fetchHeaderDataBasedOnManager(this.invoiceForm.value).
    subscribe(
      data => {
        this.commonService.hideProgressBar();    
        this.dataSource = new MatTableDataSource<any>(data);
        if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {

          if (data.length > 0) {
            this.recordCount = data[0].totalRecordCount;;
          }
          this.isEmptyGrid = false;
          this.commonService.hideProgressBar();
        } else {
          this.isEmptyGrid = true;
        }
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      }
    )
  }
  //filling bread Crumbs
  fillBreadCrumbs(data){    
    
     let len =0;
     if (this.commonUtils.checkNullOrUndefined(data))
      len =data.length;
    let isChildren =true;
    if(len == 0){
      this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:[]});
    } else  if(len ==1 ) {
      if (this.commonUtils.checkNullOrUndefined(data[0].taskHirarchy)){
        data[0].taskHirarchy.splice(-1);
        this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:data[0]});
      }
    } else if (len > 1 &&  data[0].hasOwnProperty('t823ParentTask') ){
      if (this.commonUtils.checkNullOrUndefined(data[0].t823ParentTask.taskId)){
        let parentid=data[0].t823ParentTask.taskId;
        for(let i=1;i < len ;i++){
            if(this.commonUtils.checkNullOrUndefined(data[i].t823ParentTask) &&
            this.commonUtils.checkNullOrUndefined(data[i].t823ParentTask.taskId) &&
             data[i].t823ParentTask.taskId != parentid){                             
                isChildren=false;
            }
        }
      }     
      if(isChildren){
      data[0].taskHirarchy.splice(-1);
      this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:data[0]});
      }else{
        this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:[]});
      }
    } else {
      this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:[]});
    }

    }

    fetchAssetList(): void {

      if (this.viewChildAssetFlag === true) {
        this.invoiceForm = this.getSaveForm(this.moduleName);
        if (this.commonUtils.checkNullOrUndefined(this.refForeignKeyId)) {
          this.invoiceForm.get('refForeignKeyId').setValue(this.refForeignKeyId);
        }
      }
      this.commonService.showProgressBar();
  
  
      this.assetTrackingService.fetchAssets(this.invoiceForm.value).subscribe(
        data => {
          //   this.tableDataSource = new TableDataSource<any>(data, ViewTable, this.viewTableValidatorService);
          this.dataSource = new MatTableDataSource<any>(data);
          if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
  
            if (data.length > 0) {
              this.recordCount = data[0].totalRecordCount;
            }
            this.isEmptyGrid = false;
            this.PaginationComponentObj.ngOnInit();
          } else {
            this.isEmptyGrid = true;
          }
          this.commonService.hideProgressBar();
        },
        error => {
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
        });
    }

  }

class ViewTable {
} 
export interface Element {
  contactDisplayName: string;
  company: string;
  email: string;
  workPhone: string;
  receivables: number;
  payables: number;
  invoiceNumber: string;
  refNumber: string;
  finalTotal: number;
  endPointUrl:string;
}