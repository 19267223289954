
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
 
import {HttpClient} from '@angular/common/http';
import {CommonService} from '../shared/common.service';
import {map,catchError } from 'rxjs/operators';
import {CommonUtils} from '../core/common-utils';
@Injectable()
export class AssetTrackingService {
  constructor(private http: HttpClient,
    private commonService: CommonService,private commonUtils:CommonUtils) {}


  fetchAssets(assetObj):Observable<any> 
  {

    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchAssets',assetObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchEditAsset(assetObj):Observable<any>{
     
    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchEditAsset', assetObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateIndent(indentObj) :Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'assets/saveOrUpdateIndent', indentObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
fetchIndent(indentObj) :Observable<any>
  {

    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchIndent',indentObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEditIndent(indentObj) :Observable<any>
  {

    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchIndent',indentObj)
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  private handleError(error: any):Observable<any> {
    // console.log(" Error in asset tracking",error);
   
    return observableThrowError(error);
  }

  fetchParentAssets(assetObj) :Observable<any>{ 
    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchParentAssets', assetObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchAssetCategories(categoryObj):Observable<any> 
  {

    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchAssetCategories', categoryObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateAsset(assetobj) :Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'assets/saveOrUpdateAsset', assetobj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   saveOrUpdateAssetTransaction(assetobj) :Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'assets/saveOrUpdateAssetTransaction', assetobj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  deleteAssetDepreciation(assetobj) :Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'assets/deleteAssetDepreciation', assetobj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAssetNumberStatus(assetobj):Observable<any> {
  
    return this.http.post(this.commonService.SERVICE_URL + 'assets/fetchAssetNumberStatus', assetobj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
}
