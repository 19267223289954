<mat-card class="col-lg-12">
<form [formGroup]="shiftAllocationForm" (ngSubmit)="saveOrUpdateShiftAllocation()">
   <h3 style="text-align:center">{{ALLOCATE_SHIFT}}</h3>
  <mat-card-content>
 
  <div class="col-lg-12">

       <mat-form-field  formGroupName="t818SalaryGroup" class="col-lg-12">   
       <mat-select placeholder="{{SELECT_SALARY_GROUP}}" formControlName="salaryId">
                    <mat-option *ngFor="let salarygroup of salaryGroupsList" [value]="salarygroup.salaryId" (click)="fetchEmployee(salarygroup)">
                      {{salarygroup.salaryGroupName}}
                    </mat-option>
             </mat-select>
      </mat-form-field> 
   

      <mat-form-field *ngIf="shiftAllocationId != 0"  formGroupName="t800Employee" class="col-lg-12">   
       <mat-select placeholder="{{SELECT_EMPLOYEE}}" formControlName="employeeId" [disabled]="true">
                    <mat-option *ngFor="let employee of employeesList" [value]="employee.employeeId" (click)="fetchEmployeeShift()">
                      {{employee.userName}}  - <span style="color:grey;"><i> {{employee.firstName}}  {{employee.lastName}} </i></span> 
                    </mat-option>
             </mat-select>
    </mat-form-field> 

    <mat-form-field *ngIf="shiftAllocationId == 0"  class="col-lg-12">   
      <mat-select multiple placeholder="{{SELECT_EMPLOYEE}}" formControlName="employeeIdList">
                   <mat-option #multiSelect *ngFor="let employee of employeesList" [value]="employee.employeeId">
                     {{employee.userName}}  - <span style="color:grey;"><i> {{employee.firstName}}  {{employee.lastName}} </i></span> 
                   </mat-option>
            </mat-select>
   </mat-form-field> 
 

      <mat-form-field  formGroupName="t834ShiftMaster" class="col-lg-12">   
       <mat-select placeholder="{{SELECT_SHIFT_NAME}}" formControlName="shiftMasterId">
                    <mat-option *ngFor="let shift of shiftsList" [value]="shift.shiftMasterId">
                      {{shift.shiftName}}
                    </mat-option>
             </mat-select>
      </mat-form-field> 
    
      <mat-form-field *ngIf="shiftAllocationId != 0" class="col-lg-6">
    <input matInput [matDatepicker]="startDatePicker" placeholder="{{START_DATE}}" formControlName="startDate" dateFormatter (dateChange)="checkDate('start',$event)">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
   
    <mat-form-field *ngIf="shiftAllocationId != 0" class="col-lg-6">
      <input matInput [matDatepicker]="endDatePicker" placeholder="{{END_DATE}}" formControlName="endDate" 
      [min]="shiftAllocationForm.controls.startDate.value" dateFormatter  (dateChange)="checkDate('end',$event)">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker" ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="shiftAllocationId == 0" class="col-lg-6">
      <input matInput [matDatepicker]="startDatePicker" placeholder="{{START_DATE}}" formControlName="startDate" dateFormatter>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
     
      <mat-form-field *ngIf="shiftAllocationId == 0" class="col-lg-6">
        <input matInput [matDatepicker]="endDatePicker" placeholder="{{END_DATE}}" formControlName="endDate" 
        [min]="shiftAllocationForm.controls.startDate.value" dateFormatter>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker" ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
  
    <div class="row" >
       <div class="col-lg-12 buttons">
      <button mat-raised-button color="primary" [disabled]="!shiftAllocationForm.valid || disableFlag" >{{ALLOCATE_BUTTON}}</button>
       <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
      </div>

   </div>

  </div>

  </mat-card-content>
</form>
</mat-card>