
import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';


@Injectable()

export class APIService {
  constructor(private http: HttpClient) {}

  private handleError(error: any) {
    return observableThrowError(error);
  }

}
