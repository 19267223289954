<div class="modal-header">
  <h4 class="modal-title">Export</h4>
</div>
<div class="modal-body">
  <form (ngSubmit)="downloadExportData()"   [formGroup]="invoiceForm">
  <div>
    <label>You can export your data in CSV or XLS format.</label>
  </div>
  <div class="row col-lg-12">
    <div class="col-lg-6">
     <!--  <mat-form-field class="example-full-width" style="width: 100%;">
        <input class="inputWidth" type="text" placeholder="Select Module" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="module" [(ngModel)]="moduleValue"><b class="caret" style="margin-left: -13px;"></b>
        <mat-autocomplete #module="matAutocomplete">
          <mat-optgroup *ngFor="let option of options" [label]="option.name" [disabled]="option.disabled">
            <mat-option *ngFor="let subOption of option.subOptions" [value]="subOption.value">
              {{subOption.viewValue}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
    </mat-form-field> -->
     <mat-form-field  class="example-full-width" style="width: 100%;">
       <mat-select placeholder="{{MODULES}}" formControlName="moduleName">
          <mat-option  *ngFor="let module of moduleNamesList" [value]="module.moduleName">{{module.dispValue}} </mat-option>
        </mat-select>
    </mat-form-field> 
    </div>
      
  </div>
  <div class="col-lg-12" style="margin-top: 1%;" *ngIf="showPOPanel">
    <mat-radio-group (change)="radioToggle()" [(ngModel)]="radioToggleValue">
      <mat-radio-button value="All Purchase Orders">All {{moduleValue}}</mat-radio-button><br>
      <mat-radio-button value="Specific Period">Specific Period</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row col-lg-12"*ngIf="shiftAllocationFlag">
  <div class="col-lg-6">
    <mat-form-field style="width:100%">
      <input [matDatepicker]="picker11" placeholder="{{FROM_DATE}}"  matInput formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="picker11" class="matdatepickericon"></mat-datepicker-toggle>
      <mat-datepicker #picker11></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-lg-6" >
    <mat-form-field style="width:100%">
      <input [matDatepicker]="picker12" placeholder="{{TO_DATE}}"  matInput formControlName="endDate">
      <mat-datepicker-toggle matSuffix [for]="picker12" class="matdatepickericon"></mat-datepicker-toggle>
      <mat-datepicker #picker12></mat-datepicker>
    </mat-form-field>
  </div>
</div>
  <div class="col-lg-12" *ngIf="showThisPanel" style="margin-top: 2%;">
    <div class="col-lg-4">
      <mat-form-field class="col-lg-12">
        <input [matDatepicker]="picker1" placeholder="{{FROM_DATE}}"  matInput [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="col-lg-12">
        <input [matDatepicker]="picker2" placeholder="{{TO_DATE}}"  matInput [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-lg-12" style="margin-top: 1%;" *ngIf="showCustPanel">
    <mat-radio-group>
      <mat-radio-button value="Customers">Customers</mat-radio-button><br>
      <mat-radio-button value="Customer's Contact Persons">Customer's Contact Persons</mat-radio-button><br>
      <mat-radio-button value="Customer's Addresses">Customer's Addresses</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="col-lg-12" style="margin-top: 1%;" *ngIf="showVendorsPanel">
    <mat-radio-group>
      <mat-radio-button value="Vendors">Vendors</mat-radio-button><br>
      <mat-radio-button value="Vendor's Contact Persons">Vendor's Contact Persons</mat-radio-button><br>
      <mat-radio-button value="Vendor's Addresses">Vendor's Addresses</mat-radio-button>
    </mat-radio-group>
  </div>
  <!-- <div class="col-lg-12">
    <div class="col-lg-6" style="margin-left: -4%;">
        <mat-form-field>
          <mat-select placeholder="Select An Export File Template">
            <mat-option *ngFor="let temp of templates" [value]="temp.value">
              {{ temp.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
  </div> -->
  <div class="dropdown-divider"></div>
  <div class="col-lg-12" style="margin-left:-2%;margin-top: 2%;">
    <label class="text-muted">Export As</label>
  </div>
  <div class="col-lg-6">
      <mat-radio-group formControlName="fileType"  >
        <mat-radio-button  color="primary" value="CSV"> CSV (Comma Separated Value) </mat-radio-button>
        <mat-radio-button  color="primary" value="XLS"> XLS (Microsoft Excel) </mat-radio-button>
        <mat-radio-button  color="primary" value="TSV"> TSV (Tab Delimited) </mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="col-lg-12" style="margin-left:-2%;margin-top: 2%;">
    <b>NOTE :</b><label class="text-muted"> You can export only the first 25,000 rows.You can also backup all the data of your account. To initiate backup of your data,<a> Click here</a></label>
  </div>
  <div class="dropdown-divider"></div>
  <div class="col-lg-12" align="center">
    <button mat-raised-button color="primary" type="submit"  >Export</button>
    <button mat-raised-button color="primary" type="button" (click)="onNoClick()">Cancel</button>
  </div>
</form>
</div>