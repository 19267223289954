<mat-card class="col-lg-12 aply-leave mb-pd-0">
<mat-card-title ><mat-icon> how_to_reg </mat-icon> {{APPLY_LEAVE}}</mat-card-title>
<mat-card-content>

<form [formGroup]="applyLeaveForm" (ngSubmit)="saveLeave()">
<div class="col-lg-12 mb-pd-0" >
	<div class="row" >

	<div class="col-lg-6">
	 
	<!-- <div class="row row-label col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6" >	
				<mat-label class="">
				Leave Applied On : 
			    </mat-label>						
		</div>
		
		<div class="innerrrow-label col-lg-6" >
			<mat-label class="">
				{{ currentDate | dateFormatPipe }}
			</mat-label>
		</div>
	</div>
	<br> -->
	<div class="row">
	<div class="row row-label greenText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{SL_AVAIL}} 
			</mat-label>
		</div>
		
		<div class="innerrrow-label col-lg-6 ">
			<mat-label class="">
				{{slAvail}}
			</mat-label>
		</div>
	</div>

</div>

<div class="row">
	<div class="row row-label greenText col-lg-12">
		<div class="innerlrow-label alignRight  col-lg-6">
			<mat-label class="">
				{{CL_AVAIL}} 
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6 ">
			<mat-label class="">
				{{clAvail}}
			</mat-label>
		</div>
	</div>
	</div>
	<div class="row">
	<div class="row row-label greenText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{TOTAL_AVAIL}} 
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{totalAvail}}
			</mat-label>
		</div>
	</div>
	</div>
	<div class="row row-label greenText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{COMP_OFF_AVAIL}} 
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{compOffAvail}}
			</mat-label>
		</div>
	</div>


	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{SL_USED}} :
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{slUsed}}
			</mat-label>
		</div>
	</div>
	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{CL_USED}} :
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{clUsed}}
			</mat-label>
		</div>
	</div>
	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{COMP_OFF_USED}} :
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{compOffUsed}}
			</mat-label>
		</div>
	</div>
	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				{{LOP_DAYS}} :
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{lop}}
			</mat-label>
		</div>
	</div>
	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				Permission Applied (in year):
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{permissionApplied}} 
			</mat-label>
		</div>
	</div>
	<div class="row row-label redText col-lg-12">
		<div class="innerlrow-label alignRight col-lg-6">
			<mat-label class="">
				Permission Hours (in year) :
			</mat-label>
		</div>
		<div class="innerrrow-label col-lg-6">
			<mat-label class="">
				{{permissionHours}}
			</mat-label>
		</div>
	</div>
	<br><br>
</div>
<div class="col-lg-6">
	   	   
 <canvas baseChart *ngIf="pieChartData !=null && pieChartData.length>0"
                [data]="pieChartData"
                [labels]="pieChartLabels"
               
                [chartType]="pieChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
        
    </div>
</div>

	<div class="col-lg-12 nopadding">
		<mat-form-field class="col-lg-4" formGroupName="t004LookupLeaveTypeObj">
			<mat-select placeholder="{{SELECT_TYPE}}" formControlName="lookupId" >

				<mat-option *ngFor="let leaveType of leaveTypeList" [value]="leaveType.lookupId" (click)="leaveTypeChangeEvent(leaveType.lookupValue)">
					{{leaveType.lookupValue}}
				</mat-option>

			</mat-select>
		</mat-form-field>

		<mat-form-field class="col-lg-4" *ngIf="permissionFlag == false">
			<input matInput placeholder="{{TOTAL_DAYS}}" value=""  formControlName="totalAppliedDays" type="number" (change)="numberOfDaysEntered()"  class="number">
		</mat-form-field>
		<mat-form-field class="col-lg-4" *ngIf="permissionFlag">
			<input matInput type="number" placeholder="{{HOURS}}"  min="0" formControlName="appliedPermissionHours">
			 
		</mat-form-field>
		<mat-form-field class="col-lg-4" *ngIf="permissionFlag">
			 <input matInput type="number" placeholder="{{MIN}}" min="0" formControlName="appliedPermissionMinutes">
		</mat-form-field>
	</div>
	
	<div class="col-lg-12 nopadding" >
		<mat-form-field class="col-lg-4">
			<input matInput  [matDatepicker]="startDatePicker" placeholder="{{START_DATE}}" formControlName="startDate" 
			(dateChange)="onChangeStartDate($event)">
			<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #startDatePicker></mat-datepicker>
		</mat-form-field>
	 
		<mat-form-field class="col-lg-4">
			<input matInput [matDatepicker]="endDatePicker" placeholder="{{END_DATE}}" formControlName="endDate" 
			[min]="applyLeaveForm.controls.startDate.value" [max]="maxEndDate">
			<mat-datepicker-toggle matSuffix [for]="endDatePicker" ></mat-datepicker-toggle>
			<mat-datepicker #endDatePicker></mat-datepicker>
		</mat-form-field>

	</div>

 
	<div class="col-lg-12 nopadding">
		<mat-form-field class="col-lg-8">
			<textarea matInput placeholder="{{REASON}}" formControlName="leaveReason" onfocus="this.select()" ></textarea>
		</mat-form-field>


	</div>
	<br>
	<br>
		<div class="button-row">
			<button mat-raised-button color="primary" [disabled]="disableFlag || !applyLeaveForm.valid">{{APPLY_BTN}}</button>
			<button mat-raised-button color="primary" type="button" (click)="closeDialog()">{{CANCEL_BUTTON}}</button>
		</div>
</div>
</form>
</mat-card-content>
</mat-card>

