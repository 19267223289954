import {Action} from '@ngrx/store';

//load po 
export const LOAD='[Purcahse] Load Purchase';
export const LOAD_FAIL='[Purcahse] Load Purchase Fail';
export const LOAD_SUCCESS='[Purcahse] Load Purchase Success';

export const LOAD_VIEW_TEMPLATE_SEARCH_RESULTS='[ViewTemplate] Load View Template Search Results';

export const SHOW_PROGRESS='[Progress bar] Show Progress bar';
export const HIDE_PROGRESS='[Progress bar] Hide Progress bar';

export class LoadViewTemplateSearchResults implements Action{
	readonly type=LOAD_VIEW_TEMPLATE_SEARCH_RESULTS;
	constructor(public payload:any){}
}
 

export class HideProgressBar implements Action{
	readonly type=HIDE_PROGRESS;
//	constructor(public payload:any){}
}

export class ShowProgressBar implements Action{
	readonly type=SHOW_PROGRESS;
	//constructor(public payload:any){}
}




export class LoadCreateTemplate implements Action{
readonly type=LOAD;
constructor(public payload:any){}
}
 
export class LoadCreateTemplateFail implements Action{
readonly type=LOAD_FAIL;
constructor(public payload:any){}
}

export class LoadCreateTemplateSuccess implements Action{
readonly type=LOAD_SUCCESS;
constructor(public payload:any[]){}
}

//action type 
export type ViewTemplateAction=LoadCreateTemplate | LoadCreateTemplateSuccess | LoadCreateTemplateFail | LoadViewTemplateSearchResults | HideProgressBar |ShowProgressBar;
