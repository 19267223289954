 <mat-card class="col-lg-12">
  	<mat-card-title>
  		<mat-icon>home</mat-icon>New Loan</mat-card-title>
    <form (ngSubmit)="saveOrUpdateLoan()" [formGroup]="loanForm">
    	<mat-card-content >
          <div class="row col-lg-12 mb-pd-0 mb-mr-0">
    	  	
                  <div class="col-lg-4" formGroupName="t800Employee">
                    <mat-form-field  formGroupName="t818SalaryGroup" class="dis-blk"> 
                      <mat-select placeholder="{{SALARY_GROUP}}" formControlName="salaryId">
                    <mat-option *ngFor="let salaryGroup of salaryList" [value]="salaryGroup.salaryId" (click)="salary(salaryGroup.salaryId)">
                      {{salaryGroup.salaryGroupName}}
                    </mat-option>
                   </mat-select>  
                  </mat-form-field>
                  </div>            

            <div class="col-lg-4">
            <mat-form-field  formGroupName="t800Employee" class="dis-blk">       
                <mat-select placeholder="{{EMPLOYEE}}" formControlName="employeeId" >
                    <mat-option *ngFor="let employee of employeeList" [value]="employee.employeeId">
                   {{employee.userName}} - {{employee.firstName}} {{employee.lastName}}
                    </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
   
            <div class="col-lg-4">
         <mat-form-field class="dis-blk">
             <input matInput placeholder="{{AMOUNT}}" formControlName="loanAmount"> 
        </mat-form-field>
        </div>
    </div>


    		<div class="row col-lg-12 mb-pd-0 mb-mr-0">
    			<div class="col-lg-4">
            	<mat-form-field class="dis-blk">
        		 <input matInput  placeholder="EMI" formControlName="eMIAmount"> 
            </mat-form-field>
        </div>

    <div class="col-lg-4">
        <mat-form-field class="dis-blk">
         <input matInput   [matDatepicker]="picker" placeholder="{{LOAN_DATE_SMALL}}" formControlName="loanDate">
           <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker> 
      </mat-form-field>
    </div>

    <div class="col-lg-4">
              <mat-form-field class="dis-blk">
             <input matInput  placeholder="{{CASH_IN_HAND}}" formControlName="amountPaid"> 
            </mat-form-field>
        </div>

 		</div>
        
   		<div class="col-lg-12 buttons" align="left" style="padding-top: 1%">
    			<button mat-raised-button color="primary" class="mat-red" *ngIf="editAccess" [disabled]="disableFlag" >Save</button>
          <button mat-raised-button color="primary" type="button" (click)="cancelClick()">Cancel</button>
    		</div>
    </mat-card-content>
  </form>
</mat-card>
