<div class="  mat-elevation-z8 viewcontacts">

  <mat-card-title >
<mat-icon>home</mat-icon>{{EMAIL_HEADER}}</mat-card-title>
<mat-card class="col-lg-12  ">
<mat-card-content  class="height90 displayBlock" >

<form [formGroup]="templateForm" class="height100 displayBlock">
     
    <div class="col-lg-12 nopaddingLR">
     <mat-form-field class="col-lg-3" >
       <mat-select formControlName="emailTemplateId" placeholder="{{SELECT_TEMPLATE_PLACEHOLDER}}">
        <mat-option *ngFor="let template of templateList" [value]="template.emailTemplateId" (click)="bindValues(template.emailTemplateId)">
        {{ template.templateName }}
        </mat-option>
       </mat-select>
    </mat-form-field>
  </div> 

   
      <div class="col-lg-12 marginTopM2 nopaddingLR" [ngClass]="[form.get('toEmailList').errors ? 'inValidBg' : 'validBg']">
      <mat-form-field class="col-lg-12"   >
          <span matPrefix style="color: #1656ca;">TO &nbsp;</span>
        <mat-chip-list #chipList >
         <mat-chip color="primary" *ngFor="let toEmail of toEmailList" [selectable]="selectable" [removable]="removable" (removed)="remove(toEmail, 'TO')">            {{toEmail}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip> 
        <input  matInput  
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'TO')" />
        </mat-chip-list>
      </mat-form-field>
       <!-- <mat-error style="padding-left: 16px; padding-bottom:15px;color:crimson" *ngIf="form.get('toEmailList').hasError('required')">To Email is required!</mat-error> -->
    </div>

     
    <div class="col-lg-12 marginTopM2 nopaddingLR" >
     
      <mat-form-field class="col-lg-12"   >
          <span matPrefix style="color: #1656ca;">CC &nbsp;</span>
        <mat-chip-list #chipList2>
         <mat-chip color="primary" *ngFor="let ccEmail of ccEmailList" [selectable]="selectable" [removable]="removable" (removed)="remove(ccEmail, 'CC')">            {{ccEmail}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip> 
        <input  matInput pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
               [matChipInputFor]="chipList2"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'CC')" />
        </mat-chip-list>
      </mat-form-field>
    </div>

  
  <div class="col-lg-12 marginTopM2 nopaddingLR" >
      <mat-form-field class="col-lg-12"   >
          <span matPrefix style="color: #1656ca;">BCC &nbsp;</span>
        <mat-chip-list #chipList3>
         <mat-chip color="primary" *ngFor="let bccEmail of bccEmailList" [selectable]="selectable" [removable]="removable" (removed)="remove(bccEmail, 'BCC')">            {{bccEmail}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip> 
        <input matInput pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
               [matChipInputFor]="chipList3"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'BCC')" />
        </mat-chip-list>
      </mat-form-field>
    </div>

    <div class="col-lg-12 nopaddingLR">
      <mat-form-field class="col-lg-12">
         <span matPrefix style="color: #1656ca;">{{EMAIL_SUBJECT}} &nbsp;</span>
        <input matInput   formControlName="subject">
      </mat-form-field>
    </div>

   <div class="col-lg-12 width100 height60">
    <!-- <div [froalaEditor]="options" formControlName="body" class="width100 height99 displayBlock">{{body}}</div> -->
<!--      <app-ngx-editor minHeight="100px"  [placeholder]="'Enter text here...'" [spellcheck]="true" formControlName="body"></app-ngx-editor> -->
 <!-- <textarea minHeight="100px" [innerHTML]="body"   [placeholder]="'Enter text here...'"  formControlName="body"></textarea>  -->
 <angular-editor  formControlName="body" [config]="config"></angular-editor>
   </div>


 
    <div class="col-lg-12 col-md-3 col-sm-4 col-xs-12 nopadding" *ngIf="showAttachFlag"> 
      <div  class="col-lg-12 nopadding" *ngIf="pdfAttachmentInEmailFlag === true"  style="padding-top: 6%;">
          <mat-checkbox  color="primary" checked="true"  (change)="checkBoxEvent($event)">{{IS_PDF_ATTACHED}}</mat-checkbox>
      </div>
        <div  class="col-lg-12 nopadding" *ngIf="pdfAttachmentInEmailFlag === false" style="padding-top: 6%;">
          <mat-checkbox  color="primary"   (change)="checkBoxEvent($event)">{{IS_PDF_ATTACHED}}</mat-checkbox>
        </div>
     </div> 
  
    <!--   <div class="dropdown-divider"></div>
    <div class="dropdown-divider"></div> -->
    <div class="col-lg-12" align="left" style="padding-top: 10px;">
      <button mat-raised-button type="button" (click)="submitDialog()" color="primary">{{SEND_BTN}}</button>
      <button mat-raised-button color="primary"  type="button" (click)="onNoClick()">{{CANCEL_BUTTON}}</button>
  </div>
</form>
</mat-card-content>
</mat-card>

 </div>