import {NgModule} from '@angular/core';
import {SharedModule} from './shared.module';
import {CustomFieldWrapperComponent} from './core/custom-fields/custom-fields-wrapper.component';
import {DynamicFormQuestionComponent} from './core/custom-fields/elements/dynamic-form-elements-component';
import {ViewDefaultComponent} from './core/view-template/view-default-top/view-default-top.component';
import {PaginationComponent} from './core/view-template/view-pagination/pagination-component';
import {ViewSearchResultsComponent} from './core/view-template/view-search-results/view-search-results.component';
import {ViewEditTopComponent} from './core/view-template/view-edit-top/view-edit-top.component';
import {ViewTemplateWrapperComponent} from './core/view-template/view-template-wrapper.component';
import { ManagelicenseComponent } from './superadmin/managelicense/managelicense.component';
import {EmailDialogComponent} from './dialogBox/dialogBox.component';
import {DialogOverviewExampleComponent} from './dialogBox/dialogBox.component';
import {SearchInGridDialogComponent} from './dialogBox/dialogBox.component';
import {ConfigureEmailComponent} from './dialogBox/dialogBox.component';
import {ExportPODialogComponent} from './export/exportpo.component';
import {ImportPODialog} from './import/importpo.component';
import { ERPPromptComponent} from './dialogBox/prompt/erp-prompt.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import {ImportContactsDialog} from './import/importContacts.component';
import{FileUploadComponent} from './dialogBox/fileupload/file-upload.component';
import {CreateLicenseComponent} from'./superadmin/createlicense/createlicense.component';
import {DocumentPreviewComponent} from './dialogBox/documentPreview/documentPreviewPage.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {ViewTemplateWrapperAnalyticsComponent} from './core/analytics/view-template-wrapper-analytics.component';
import { ChartsModule } from 'ng2-charts';
import {ViewBreadcrumbComponent} from './core/view-template/view-breadcrumb/view-breadcrumb.component';
 import {PdfViewerModule} from 'ng2-pdf-viewer';
 import { UploadSignatureComponent } from './dialogBox/signatureUpload/upload-signature.component';
import { ImportErrorComponent } from './import/importerror/importerror.component';
import { TreeviewModule } from 'ngx-treeview';
@NgModule({
  declarations: [
    DynamicFormQuestionComponent,
    CustomFieldWrapperComponent,
    ViewDefaultComponent,
    PaginationComponent,
    ViewSearchResultsComponent,
    ViewEditTopComponent,
    ViewTemplateWrapperComponent,
    ManagelicenseComponent,
    EmailDialogComponent,
    DialogOverviewExampleComponent,
    SearchInGridDialogComponent,
    ExportPODialogComponent,
    ImportPODialog,
    ConfigureEmailComponent,
    ERPPromptComponent,
    ERPPromptComponent,
    ImportContactsDialog,
    FileUploadComponent,
    CreateLicenseComponent,
     DocumentPreviewComponent,
     ViewTemplateWrapperAnalyticsComponent,
     ViewBreadcrumbComponent,
UploadSignatureComponent,
ImportErrorComponent

  ],
  entryComponents: [
      EmailDialogComponent, 
      DialogOverviewExampleComponent,
      DocumentPreviewComponent,
      SearchInGridDialogComponent,
      ConfigureEmailComponent,
      ExportPODialogComponent,
      ImportPODialog,
      ERPPromptComponent,
      ImportContactsDialog,
      FileUploadComponent,
      ViewTemplateWrapperAnalyticsComponent,
      UploadSignatureComponent,
     ],
  exports: [
    DynamicFormQuestionComponent,
    CustomFieldWrapperComponent,
    ViewDefaultComponent,
    PaginationComponent,
    ViewSearchResultsComponent,
    ViewEditTopComponent,
    ViewTemplateWrapperComponent,
    ManagelicenseComponent,
    EmailDialogComponent, 
    DialogOverviewExampleComponent,
    SearchInGridDialogComponent,
    ConfigureEmailComponent,
    ExportPODialogComponent,
    ImportPODialog,
    DocumentPreviewComponent,
     AngularEditorModule,
        ViewBreadcrumbComponent,
         PdfViewerModule,
         ImportErrorComponent
  ],
  imports: [
    SharedModule,
   AngularEditorModule,
   ChartsModule,
   PdfViewerModule,SignaturePadModule,
   TreeviewModule.forRoot(),
  ]
})

export class FeatureModule {}