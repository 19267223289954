import {Component, OnInit, Inject,ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl, FormArray, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SettingsService} from '../settings-service';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {CustomFieldWrapperComponent} from '../../core/custom-fields/custom-fields-wrapper.component';

@Component({
  selector: 'app-create-warehouse',
  templateUrl: './create-warehouse.component.html'
})

export class CreateWareHouseComponent extends CommonUtils implements OnInit {
    @ViewChild(CustomFieldWrapperComponent, { static: true }) customFieldWrapperComponentObj:CustomFieldWrapperComponent;
 myForm;myParentForm: FormGroup;
 moduleName='NEW_WAREHOUSE';
 parentLocationList=[];
 wareHouseList;
 superParentLocationList=[];
 userObj;
 public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
 countryList;
 isUnder='is under' ;
 superParent='Super Parent';
 typeList = ['Inbound', 'Outbound', 'Both'];
 canReceiveStockFlag:boolean=false;
 isAddressFlag:boolean=false;
 selectedLocation;
 templateSettingMap;
 errorFormGroup=new FormGroup({
  errorMessage: new FormControl(''),
  errorStackTrace: new FormControl('')      
 })

  constructor(private router: Router, public settingsService: SettingsService,
    public dialogRef: MatDialogRef<CreateWareHouseComponent>, private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any, public commonUtils: CommonUtils)
     {

    
    super();
    

  this.settingsService.wareHouseForEdit = data.wareHouseObj;

  this.myForm = new FormGroup
  ({
    locationId: new FormControl(0),
    locationName: new FormControl(''),
    status: new FormControl(true),
    createdTs: new FormControl(new Date()),
    updatedTs: new FormControl(new Date()),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    parent: new FormControl(false),
    wareHouse: new FormControl(false),
    t028_LocationObj: new FormGroup({locationId: new FormControl(0),locationName: new FormControl('')}),
    t028_SuperParentObj: new FormGroup({locationId: new FormControl(0),locationName: new FormControl('')}),
    level: new FormControl(1),
    canReceiveStock: new FormControl(false),
    address: new FormControl(false),
    maximumItems: new FormControl(0),
    type: new FormControl(),
    fpEnable:new FormControl(false),
    customValue1:new FormControl(''),
    customValue2:new FormControl(''),
    locationAttention: new FormControl(''),
    locationAddressLineOne: new FormControl(''),
    locationAddressLineTwo: new FormControl(''),
    locationCity: new FormControl(''),
    locationState: new FormControl(''),
    locationZipCode: new FormControl(''),
    t010locationCountryObj:new FormGroup({countryId: new FormControl(0), countryCode: new FormControl(''),
    countryValue: new FormControl(''), active: new FormControl(''), createdBy: new FormControl(''),
    createdDttm: new FormControl(new Date()), updatedBy: new FormControl(''),
    updatedDttm: new FormControl(new Date())}),
    locationPhone: new FormControl(''),
    locationFax: new FormControl(''),
    gstNumber : new FormControl('')
  });

  this.myParentForm = new FormGroup
  ({
    parent: new FormControl(true)
  });

}

  ngOnInit() 
 {
  this.pageLoad();
 }
pageLoad():void
{
  try
  {  
    this.fetchParentLocationList();
    if (this.commonUtils.checkNullOrUndefined(this.settingsService.wareHouseForEdit)) 
    {
      this.myForm.patchValue(this.settingsService.wareHouseForEdit);
     this.isChecked();
    }
    this.templateSettingMap=this.commonService.createTemplateSettingsMap.get(this.moduleName);
  }
  catch(error)
   {
     this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value);
   }
   this.fetchCountry();
   this.validationMethod();
   this.isAddress();
   
}
 onfocusout()
 {
   this.isUnder=this.myForm.get('locationName').value+ this.PARENT_WAREHOUSE;
 }

 fetchParentLocationList():void{
    this.commonService.fetchLocation(this.myParentForm.value).
      subscribe(
      data => {
   /* for(var loop=0;loop<data.length;loop++){
      if( data[loop].parent === true && data[loop].wareHouse ===true)
      {
         this.superParentLocationList.push(data[loop]);
      }
    } */      
       this.parentLocationList =  data;
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
 }

  changeParentObj(selectedOption):void{
    if(this.commonUtils.checkNullOrUndefined(selectedOption.level)){
      this.myForm.get('level').setValue(parseFloat(selectedOption.level)+1);
    }
    //this.selectedLocation = selectedOption;
       
    if(this.commonUtils.checkNullOrUndefined(selectedOption.locationId)){
      this.superParentLocationList=[];
       if(selectedOption.parent=== true && selectedOption.wareHouse === true && selectedOption.address ===true)
        {     
          this.superParentLocationList.push({locationName:selectedOption.locationName , locationId:selectedOption.locationId}); 
          if(this.commonUtils.checkNullOrUndefined(selectedOption.t028_SuperParentObj))
          {
               if(selectedOption.t028_SuperParentObj.parent=== true && selectedOption.t028_SuperParentObj.wareHouse === true && selectedOption.t028_SuperParentObj.address ===true)
                {
              this.superParentLocationList.push({locationName:selectedOption.t028_SuperParentObj.locationName , locationId:selectedOption.t028_SuperParentObj.locationId}); 
            }
          }
        }

    }    
  
  }

 fetchCountry(): void {
  this.commonService.fetchCountry().
  subscribe(
  data => {
  this.countryList = data;
  },
  error => {
  this.commonService.showERPErrorMessage(error);
  });
  }
  saveOrUpdateWarehouses(): void {
    this.commonService.showProgressBar();
    this.userObj = this.commonService.loggedInUserObj.userId;
    this.myForm.get('fpEnable').setValue(this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.enableFP);
    if (this.myForm.get('locationId').value == 0) {
      this.myForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }else{
      this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    
    this.commonService.saveOrUpdateWareHouses(this.myForm.value).
    subscribe(data => {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage(data.erpmessage);
      this.dialogRef.close(true);
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

    this.dialogRef.close(true);
  //  this.router.navigate(['/settings/reviewWarehouse']);
  }

 cancelClick(): void {
  this.dialogRef.close(true);
 }
 isChecked()
  {
    if(this.myForm.get('canReceiveStock').value===true)
    {
      this.canReceiveStockFlag=true;
      this.myForm.get('maximumItems').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('maximumItems').markAsTouched();
      this.myForm.get('maximumItems').updateValueAndValidity();
      this.myForm.get('type').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('type').markAsTouched();
      this.myForm.get('type').updateValueAndValidity();
    }
    else
    {
      this.canReceiveStockFlag=false;
      this.myForm.get('maximumItems').setValidators(null);
      this.myForm.get('maximumItems').markAsTouched();
      this.myForm.get('maximumItems').updateValueAndValidity();
      this.myForm.get('type').setValidators(null);
      this.myForm.get('type').markAsTouched();
      this.myForm.get('type').updateValueAndValidity();
    }
  }
  validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.myForm);   
  }
  
  isAddress()
  {
    if(this.myForm.get('address').value===true)
    {
        this.isAddressFlag=true;

      this.myForm.get('locationAttention').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('locationAddressLineOne').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('locationZipCode').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('locationState').setValidators([Validators.required,Validators.min(1)]);
      this.myForm.get('t010locationCountryObj.countryId').setValidators([Validators.required,Validators.min(1)]);
    }
    else
    {
        this.isAddressFlag=false;

         this.myForm.get('locationAttention').setValidators(null);
         this.myForm.get('locationAddressLineOne').setValidators(null);
         this.myForm.get('locationZipCode').setValidators(null);
         this.myForm.get('locationState').setValidators(null);
         this.myForm.get('t010locationCountryObj.countryId').setValidators(null);
    }
      this.myForm.get('locationAttention').markAsTouched();
      this.myForm.get('locationAttention').updateValueAndValidity();
      this.myForm.get('locationAddressLineOne').markAsTouched();
      this.myForm.get('locationAddressLineOne').updateValueAndValidity();
      this.myForm.get('locationZipCode').markAsTouched();
      this.myForm.get('locationZipCode').updateValueAndValidity();
      this.myForm.get('locationState').markAsTouched();
      this.myForm.get('locationState').updateValueAndValidity();
      this.myForm.get('t010locationCountryObj.countryId').markAsTouched();
      this.myForm.get('t010locationCountryObj.countryId').updateValueAndValidity();
  }
  allowNumeric(formCtrlVal){ 
    if(formCtrlVal === 'locationZipCode'){
      if(this.commonUtils.checkNullOrUndefined(this.myForm.get('locationZipCode').value)
      && isNaN(this.myForm.get('locationZipCode').value)){
        this.myForm.get('locationZipCode').reset();
      }
    }else if(formCtrlVal === 'locationFax'){
      if(this.commonUtils.checkNullOrUndefined(this.myForm.get('locationFax').value)
      && isNaN(this.myForm.get('locationFax').value)){
        this.myForm.get('locationFax').reset();
      }
    }
    
   }
 
}
