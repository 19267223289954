import {Directive, HostListener, ElementRef, OnInit, Input} from '@angular/core';
import {MyCurrencyPipe} from './my-currency.pipe';
@Directive({
  selector: '[roundoff]'
})
export class MathRoundoffDirective implements OnInit {

  private el: any;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: MyCurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;

  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value);
  }
  @HostListener('valueChanges', ['$event.target.value'])
  onChange(value) {
    this.el.value = this.currencyPipe.transform(value); // opossite of transform
  }
}
