
import {throwError as observableThrowError, Observable,Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CommonService} from '../shared/common.service';
@Injectable()
export class HrmService {
  constructor(private http: HttpClient,private commonService:CommonService) {}
SERVICE_URL: string = this.commonService.SERVICE_URL;
  fetchDesignation() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchDesignation')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchDepartment() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchDepartment')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchLocation():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchLocation')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*fetchBandType() {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchBandType')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }*/
  fetchSalary() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchSalaryGroup')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*fetchEmployeeType() {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchEmployeeType')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }*/
  fetchBatchType() {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchBatchType')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchBankType() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchBankType')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchReportingManager():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchReportingManager')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  } 
  fetchInterviewer():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchInterviewer')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  } 
  fetchFinancialYear():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchFinancialYear')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEmployee(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchActiveEmployee', employeeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchActiveEmployeeForSalary(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchActiveEmployeeForSalary', employeeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchHRReprtingForInterview(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchHRReprtingForInterview', employeeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
fetchEmployeeNumberStatus(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchEmployeeNumberStatus', employeeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEditEmployee(employeeObj):Observable<any> {   
    return this.http.post(this.SERVICE_URL + 'hrm/fetchActiveEmployee', employeeObj)
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  fetchLoanTransaction(loanTransactionObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/fetchLoanTransaction', loanTransactionObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchMonth() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchMonth')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchPayCode(payCodeObj) :Observable<any>{
    
    return this.http.post(this.SERVICE_URL + 'hrm/fetchPayCode', payCodeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEditPayCode(payCodeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchPayCode', payCodeObj)
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  fetchAppliedMedicalBill(medicalBillObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchMedicalBill', medicalBillObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
  deleteMedicalBill(medicalBillObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/deleteMedicalBill', medicalBillObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchLoan(loanObj) :Observable<any>{
   
    return this.http.post(this.SERVICE_URL + 'hrm/fetchLoan', loanObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchPayableDays(lopObj):Observable<any> {
   
    return this.http.post(this.SERVICE_URL + 'hrm/fetchPayableDays', lopObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchLeaveType() :Observable<any>{
    return this.http.get(this.SERVICE_URL + 'hrm/fetchLeaveType')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchApplyLeave(applyLeaveObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchApplyLeave',applyLeaveObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchAllAppliedLeaves(applyLeaveObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAllAppliedLeaves',applyLeaveObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  updateAppliedLeaves(leaveHistoryObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/updateAppliedLeaves',leaveHistoryObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateEmployee(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveEmployee', employeeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateLeaveCreditToSelectedEmployee(leaveHistoryObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateLeaveCreditToSelectedEmployee', leaveHistoryObj,
      {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  saveOrUpdatePayCode(payCodeObj):Observable<any> {
   
    return this.http.post(this.SERVICE_URL + 'hrm/savePayCode', payCodeObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateDeclarationDetails(declarationObj):Observable<any> {
 
    return this.http.post(this.SERVICE_URL + 'hrm/saveDeclaration', declarationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateHraDetails(declarationHraObj):Observable<any> {
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveHraDetails', declarationHraObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEstimateEarnings(estimateEarningObj) :Observable<any>{

    return this.http.post(this.SERVICE_URL + 'hrm/viewearnings', estimateEarningObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchActualEarnings(actualEarningObj):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'hrm/viewActualEarnings', actualEarningObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateEarningsForPaycode(t802EarningObj):Observable<any> {
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveEarningForPaycode', t802EarningObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateEarnings(t802EmployeePayRollList):Observable<any> {
   
    return this.http.post(this.SERVICE_URL + 'hrm/saveEarnings', t802EmployeePayRollList)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateMedicallBill(medicallBillObj):Observable<any> {
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveMedicallBill', medicallBillObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateLoan(loanObj) :Observable<any>{
   
    return this.http.post(this.SERVICE_URL + 'hrm/saveLoan', loanObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdatePayableDays(t807EmployeePayRollLopList):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/savepayabledays', t807EmployeePayRollLopList)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrRecalculatePayableDays(payableObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/recalculatepayabledays', payableObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateMasterPayroll(masterPayrollObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateMasterPayroll', masterPayrollObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateTrailPayroll(trailPayrollObj):Observable<any> {
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateTrailPayroll', trailPayrollObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  downloadReport(loanObj) :Observable<any>{
   
    return this.http.post(this.SERVICE_URL + 'reports/downloadReport', loanObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

   fetchPayrollSettings(payrollSettingsObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchPayrollSettings',payrollSettingsObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchPayrollStatus():Observable<any> {
    return this.http.get(this.SERVICE_URL + 'hrm/fetchPayrollStatus')
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  
  saveOrUpdatePayrollSettings(trailPayrollObj):Observable<any> {
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdatePayrollSettings', trailPayrollObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchProject(projectObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchProject',projectObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateProjectPlan(taskList):Observable<any>{
   return this.http.post(this.SERVICE_URL + 'hrm/saveProjectPlan', taskList)
      .pipe(map(data => data)
      ,catchError(this.handleError)); 
  }

  fetchHraDeclaration(hraDeclarationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchHraDeclaration', hraDeclarationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchDeclaration(declarationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/viewdeclaration', declarationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  private handleError(error: any) :Observable<any>{
    console.log(error);
     
    return observableThrowError(error.erpmessage);
  }
saveApplyLeave(leaveHistObj):Observable<any>{
  return this.http.post(this.SERVICE_URL + 'hrm/saveApplyLeave', leaveHistObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
}
 saveBulkEmployee(employeeList):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'hrm/saveBulkEmployees', employeeList,{responseType: 'text'})
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Task Number validation -------- 9056 ----------START*/
  fetchTaskNumberStatus(taskObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/fetchTaskNumberStatus', taskObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Task Number validation -------- 9056 ----------END*/

  /*Delete selected task from Project Plan -------- 9056 ----------START*/
  deleteTask(taskObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/deleteTask', taskObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  /*Delete selected task from Project Plan -------- 9056 ----------END*/

  downloadExportDataForProjectPlan(dataObj):Observable<any>
 {
  
   return this.http.post(this.SERVICE_URL + 'hrm/projectPlanDownloadReport', dataObj,{responseType: 'text'})
    .pipe(map(data => data.valueOf())
    ,catchError(this.handleError));
 }
 updateStatusForLeaveHistory(leaveHistoryObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/updateStatusForLeaveHistory', leaveHistoryObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateCreditLeave(creditLeaveObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateCreditLeave', creditLeaveObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   saveOrUpdateResourceExtension(resourceObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateResourceExtension', resourceObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchResourceExtension(resourceObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/fetchResourceExtension', resourceObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchProjectResource(resourceObj): Observable<any> {
        return this.http.post(this.SERVICE_URL + 'hrm/fetchProjectResource', resourceObj)
          .pipe(map(data => data)
            , catchError(this.handleError));
      }
  fetchParentTaskHierarchy(resourceObj): Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchParentTaskHierarchy', resourceObj)
      .pipe(map(data => data)
        , catchError(this.handleError));
  }
   deleteResourceExtension(resourceObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/deleteResourceExtension', resourceObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
   fetchProjectBasedOnResourceExtension(resourceObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/fetchProjectBasedOnResourceExtension', resourceObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   convertProjectTaskToClosed(taskObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/convertProjectTaskToClosed', taskObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 
    fetchTimeSheet(timesheetObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchTimeSheet', timesheetObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchActualWorkedHrsTaskBasedUserLevel(timesheetObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchActualWorkedHrsTaskBasedUserLevel', timesheetObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateAppraisalMaster(appraisalObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateAppraisalMaster', appraisalObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
   fetchAppraisalMaster(appraisalObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAppraisalMaster', appraisalObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateEmployeeAppraisalLevel(appraisalLevelObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateEmployeeAppraisalLevel', appraisalLevelObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEmployeeAppraisalLevel(appraisalLevelObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/fetchEmployeeAppraisalLevel', appraisalLevelObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

     deleteEmployeeAppraisalLevel(appraisalLevelObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'hrm/deleteEmployeeAppraisalLevel', appraisalLevelObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

   saveAppraisalForm(appraisalFormObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/saveAppraisalForm', appraisalFormObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchAppraisalForm(appraisalFormObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAppraisalForm', appraisalFormObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

// method to download loan report
  downloadExportDataLoan(LoanObj):Observable<any>
 {
   return this.http.post(this.commonService.SERVICE_URL + 'hrm/LoanDownloadReport', LoanObj,{responseType: 'text'})
    .pipe(map(data => data.valueOf())
    ,catchError(this.handleError));
 }

  //Method to export medical bill data 
    downloadExportDataForMedicalBill(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'accounts/downloadExportDataForMedicalBill', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

    //Method to export paycode data 
     downloadExportDataForPaycode(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'accounts/downloadExportDataForPaycode', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

    //Method to export timesheet data 
       downloadExportDataForTimesheet(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'accounts/downloadExportDataForTimesheet', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

//Method to export timesheet data 
       downloadExportDataForHrisTimesheet(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'reports/downloadExportDataForHrisTimesheet', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

    //Method to export timesheet history data 
       downloadExportDataForTimesheetHistory(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'accounts/downloadExportDataForTimesheetHistory', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

  // Method to save shifts
  saveOrUpdateShiftMaster(shiftMasterObj):Observable<any> {     
    
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateShiftMaster', shiftMasterObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  // Method to fetch shifts
    fetchShiftMaster(shiftMasterObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchShiftMaster', shiftMasterObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  //Method to export Shift Master data 
  downloadExportDataForShiftMaster(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'hrm/downloadExportDataForShiftMaster', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }
   saveOrUpdateShiftAllocation(shiftAllocationObj):Observable<any> {     
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateShiftAllocation', shiftAllocationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
      fetchShiftAllocation(shiftAllocationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchShiftAllocation', shiftAllocationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

    //Method to export Shift Allocation data 
   downloadExportDataForShiftAllocation(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'hrm/downloadExportDataForShiftAllocation', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }

   downloadAttachments(taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/getTaskBasedAttachedFiles', taskObj, {responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }

  fetchCurrentMonthBasisEmployeeList(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchCurrentMonthBasisEmployeeList',employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
   fetchDashBoardAttendanceDetails(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchDashBoardAttendanceDetails',employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
   fetchEmployeeAttendance(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchEmployeeAttendance',employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchEmployeeAttendanceDetail(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAttendanceDetail',employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateEmployeeAttendance(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateEmployeeAttendance',employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateEmployeeOffBoardingFlag(employeeObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateEmployeeOffBoardingFlag',employeeObj,{responseType: 'text'})
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  decryptEmployeeId(encryptedId) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/decryptEmployeeId',encryptedId,{responseType: 'text'})
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchHRMFeedback(feedbackObj):Observable<any> {   
    return this.http.post(this.SERVICE_URL + 'hrm/fetchHRMFeedback', feedbackObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchAnnouncements(announcementsObj):Observable<any> {   
    return this.http.post(this.SERVICE_URL + 'hrm/fetchAnnouncements', announcementsObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateHRMFeedback(feedbackObj):Observable<any> {   
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateHRMFeedback', feedbackObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateHRPolicyFile(hrPolicyFileObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateHRPolicyFile', hrPolicyFileObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateAnnouncements(announcementsObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateAnnouncements', announcementsObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateEmployeeHRPolicyFlag(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateEmployeeHRPolicyFlag', employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchEmployeeFlagValue(employeeObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchEmployeeFlagValue', employeeObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  saveOrUpdateStatusEmployeeAppraisalAssociation(employeeAppraisalAssociationObj):Observable<any> {   
    return this.http.post(this.SERVICE_URL + 'hrm/saveOrUpdateStatusEmployeeAppraisalAssociation', employeeAppraisalAssociationObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  downloadExportDataEmployee(dataObj):Observable<any>
 {
  
   return this.http.post(this.commonService.SERVICE_URL + 'hrm/employeeDownloadReport', dataObj,{responseType: 'text'})
    .pipe(map(data => data.valueOf())
    ,catchError(this.handleError));
 }

  fetchHistoryForAppraisalForm(appraisalFormObj):Observable<any>{
    return this.http.post(this.SERVICE_URL + 'hrm/fetchHistoryForAppraisalForm', appraisalFormObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }

  fetchResourceTaskAllocation(taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchResourceTaskAllocation', taskObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  fetchPayrollHistory(taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchPayrollHistory', taskObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  savePayrollHistory(taskObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/savePayrollHistroy', taskObj)
    .pipe(map(data => data),
    catchError(this.handleError));
  }
  fetchEmailAlreadyExists(empObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'hrm/fetchEmailAlreadyExists', empObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  fetchObjectForConversionCandidateToEmployee(formObj):Observable<any> {
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchObjectForConversionCandidateToEmployee', formObj)
    .pipe(map(data => data[0]),
    catchError(this.handleError));
  }
  fetchDesignationConfiguration(designationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/fetchDesignationConfiguration', designationObj)
      .pipe(map(data => data)
    ,catchError(this.handleError));
  }
  
  saveDesignationConfiguration(designationObj):Observable<any> {
    return this.http.post(this.SERVICE_URL + 'hrm/saveDesignation', designationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchUsersForResourceExtension(resourceObj): Observable<any> {
        return this.http.post(this.SERVICE_URL + 'hrm/fetchUsersForResourceExtension', resourceObj)
          .pipe(map(data => data)
            , catchError(this.handleError));
      }
      fetchTimeSheetForResource(resourceObj):Observable<any>{
        return this.http.post(this.SERVICE_URL+'hrm/fetchTimeSheetForResource',resourceObj).pipe(map(data =>data,catchError(this.handleError)));
      }
}
