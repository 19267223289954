import {switchMap, debounceTime, map,distinctUntilChanged,} from 'rxjs/operators';
import { Component, OnInit, Inject,Output,EventEmitter, Input,ViewChild, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {FormGroup, FormControl,Validators, FormArray, FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ProjectService} from './project.service'; 
import {Response} from '@angular/http';
import {CommonService} from '../shared/common.service';
import {HrmService} from '../hrm/hrm-service';
import {CommonUtils} from '../core/common-utils';
import {environment} from '../../environments/environment';
import {AutoNumberGenerateComponent} from '../dialogBox/autogenerate/auto-num-generate.component';
import {CanComponentDeactivate} from '../auth/can-deactivate-guard.service';
import {ERPErrorStateMatcher} from '../shared/ERPErrormatcher';
import {ERPPromptComponent} from '../dialogBox/prompt/erp-prompt.component';
import {FileUploadComponent} from'../dialogBox/fileupload/file-upload.component';
import {DialogOverviewExampleComponent} from '../dialogBox/dialogBox.component';
import { DateFormatPipe } from '../shared/dateFormatPipe.pipe';
import {Subscription, Observable} from 'rxjs/Rx';
import {MultiUserWorkedHoursComponent} from './multiuserworkedhours/multiuserworkedhours.component';
import { TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem, TreeItem,DropdownDirective, DropdownToggleDirective } from 'ngx-treeview';
  import { isNil, remove, reverse } from 'lodash';
  import { ViewQuickInfoComponent } from '../core/view-template/view-quick-info/view-quick-info.component';
import { ErpPopupService } from '../core/components/Erp-popup-service';
@Component({
selector: 'app-createtask',
templateUrl: './createtask.component.html',
providers: [],
})
export class CreateTaskComponent extends CommonUtils implements CanComponentDeactivate,OnInit {
//placeholder from common utils

DISPLAY_NAME;
CANCEL_BUTTON;
SAVE_BUTTON;
elseBlock;
userId;
moduleName : string =environment.CREATE_TASK;
saveClick = true;
dispLabel = 'New';
disableButton = false;
step = 0;
editAccess = true;
projectObj;
resourceList = [];
parentTaskList;
subParentTaskList;
projectList=[];
taskList=[];
taskStatusList=[];
taskTypeList = [];
taskBugTypeList = [];
showProjectDropDown:boolean=false;
currentlySelectedMenu;
contactEditId;
dialogLabel=environment.NEW_TASK_LABEL;
disabledAutoFlag=false;
displayAutoGen=false;
placeholderstr = 'Task #';
TASKNUMBER_ALERT = 'Task # is';
TASKNUMBER_EXISTS_ALERT = 'Task # already exists';
taskNumberStatus;
editId;
imagePath:string;
uploadedFileArrayparent:Array<any>=[];
accessFlag:boolean=true;
taskObj;
workedHours;
userEmail;
taskStatus;
openTaskLookupObj;
enhancementTypeLookupObj;
taskId = 0;
changeTaskStatus:boolean=false;
baseLine_start_date;
baseLine_end_date;
editStr;
systemGeneratedDisableFlag = false;
t839_Resource_TaskallocationList: FormArray;
matcher = new ERPErrorStateMatcher();

myAGForm = new FormGroup({
      type: new FormControl(this.moduleName)
    })
  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500,
    hasDivider: true
  };
  items: any;
  rows:any=[];
 @Input() myFocusTriggeringEventEmitter: EventEmitter<string>;
createTaskForm =this.getTaskForm();  

getTaskForm(){
  return  new FormGroup({
   taskId: new FormControl(0),
   moduleName: new FormControl(this.moduleName) ,
   taskNumber: new FormControl(''),
   summary: new FormControl(false),
   taskNameSearch:new FormControl(),
  t823ParentTask: new FormGroup({taskId:new FormControl(0),taskName: new FormControl('')}),
   autoSchedule: new FormControl(false),
   clientViewable: new FormControl(false),
   taskName: new FormControl(''),
   taskDesc: new FormControl(''),
   estimated_hours:new FormControl(0),
   userIdList: new FormControl(''),
   userIds: new FormArray([]),
   t839_Resource_TaskallocationList: new FormArray([]),
   development:new FormControl(false),
   codeReview: new FormControl(false),
   testing: new FormControl(false),
   billing_hours:new FormControl(0),
   nonBillableHours:new FormControl(0),
   actualHours:new FormControl(0),
   percent_complete:new FormControl(0),
   startDate: new FormControl(new Date()),
   endDate: new FormControl(new Date()),
   
   prodmoveDate:new FormControl(new Date()),
   //t800EmployeeObj: new FormGroup({employeeId:new FormControl(0)}),
   t001_UserObj: new FormGroup({userId:new FormControl(0)}), 
    t001_ResponsibleUserObj: new FormGroup({userId:new FormControl(0)}), 
   displayDicardMessage:new FormControl('Y'),
   t822ProjectObj:new FormGroup({projectId:new FormControl(0),projectName:new FormControl('')}),
   validationString: new FormControl(''),
     fileUpload : new FormControl(''),
     globalFlag : new FormControl(false),
   t004_LookupTaskStatusObj:new FormGroup({
   lookupId: new FormControl(0),
   lookupCode: new FormControl(''), 
   lookupValue: new FormControl('')
  }),
   t004_LookupTaskTypeObj: new FormGroup({
     lookupId: new FormControl(0),
     lookupCode: new FormControl(''), 
     lookupValue: new FormControl('')
   }),
    t004_LookupBugTypeObj: new FormGroup({
     lookupId: new FormControl(0),
     lookupCode: new FormControl(''), 
     lookupValue: new FormControl('')
   }),
   versionNumber:new FormControl(''),
   taskCreatedUserById: new FormControl(0),
   taskUpdatedUserById: new FormControl(0),
   createdByEmail: new FormControl(''),
   taskType:new FormControl('AP'), //AP = ALL PARENT TASKS 
   portalName:new FormControl(''),
   userEmail : new FormControl(''),
   createdBy : new FormControl(''),
   baseLine_start_date: new FormControl(''),
   baseLine_end_date: new FormControl(''),
   newEntryDateStatus:new FormControl(false),
   isPagination: new FormControl(''),
   sendemail: new FormControl(false), // T_5214 / 9014   
 })
}
  public addResourceLineItem(user_Id): FormGroup {
    return new FormGroup({
          resourceAllocationId: new FormControl(),
          t823TaskObj: new FormGroup({
            taskId: new FormControl()
          }),
          t001ResourceObj: new FormGroup({
            userId: new FormControl(user_Id)
          }),
          t004TaskStatusObj: new FormGroup({
             lookupId: new FormControl(0),
             lookupCode: new FormControl(''), 
             lookupValue: new FormControl('')
            }),
          activeFlag: new FormControl()
      });
  }
fetchTaskFlag:boolean = false;
setForFileAttachmentFlag:boolean = false;
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private commonService: CommonService,
private activatedRoute: ActivatedRoute, public dialog: MatDialog, private router: Router,
private route: ActivatedRoute, public commonUtils: CommonUtils,private hrmService: HrmService,private datePipe: DateFormatPipe,
public dialogRef: MatDialogRef<CreateTaskComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private erpCustomDialog: ErpPopupService) {
 super();
 if(commonUtils.checkNullOrUndefined(data)){
this.taskObj=data.selectedTaskObj;
if(commonUtils.checkNullOrUndefined(this.taskObj) && commonUtils.checkNullOrUndefined(this.taskObj.t822ProjectObj)){
    this.projectObj = this.taskObj.t822ProjectObj;
}
if(this.commonUtils.checkNullOrUndefined(this.taskObj)  
&& this.commonUtils.checkNullOrUndefined(this.taskObj.taskId) && this.taskObj.taskId != 0 ) { 
      this.commonService.fetchParentTask({taskId: this.taskObj.taskId}).subscribe(
          dataResult => {
                if(this.commonUtils.checkNullOrUndefined(dataResult) && dataResult.length > 0){
                  this.taskObj = dataResult[0];
                  if(this.commonUtils.checkNullOrUndefined(this.taskObj)) {        
                    this.createTaskForm.patchValue(this.taskObj);
                    this.checkSummaryFlag(true);
                    if(data.statusFlag === 'clone'){
                      this.createTaskForm.get('taskId').setValue(0);
                      this.createTaskForm.get('moduleName').setValue(this.moduleName);
                      this.createTaskForm.get('taskNumber').setValue('');
                      this.createTaskForm.get('estimated_hours').setValue(0);
                      this.createTaskForm.get('billing_hours').setValue(0);
                      this.createTaskForm.get('nonBillableHours').setValue(0);
                      this.createTaskForm.get('actualHours').setValue(0);
                      this.createTaskForm.get('percent_complete').setValue(0);
                   }
                    if(commonUtils.checkNullOrUndefined(this.taskObj) && commonUtils.checkNullOrUndefined(this.taskObj.t822ProjectObj)){
                      this.projectObj = this.taskObj.t822ProjectObj;
                  }
                    if(this.commonUtils.checkNullOrUndefined(this.taskObj.startDate)){
                      this.commonUtils.patchDates(this.createTaskForm,'startDate',this.taskObj.startDate);                    
                    }
                    if(this.commonUtils.checkNullOrUndefined(this.taskObj.endDate)){
                      this.commonUtils.patchDates(this.createTaskForm,'endDate',this.taskObj.endDate);                   
                    }
                    if(this.commonUtils.checkNullOrUndefined(this.taskObj.prodmoveDate)){
                      this.commonUtils.patchDates(this.createTaskForm,'prodmoveDate',this.taskObj.prodmoveDate);                 
                    }
                   else{
                      this.commonUtils.patchDates(this.createTaskForm,'prodmoveDate',new Date());
                    }
                    let responseUserExistsFlag = false;
                    if(this.commonUtils.checkNullOrUndefined(this.resourceExtensionList) 
                          && this.resourceExtensionList.length > 0){
                            let resUserId = this.createTaskForm.get('t001_ResponsibleUserObj.userId').value;
                      for(var innerLoop = 0;innerLoop<this.resourceExtensionList.length;innerLoop++){
                        if(this.resourceExtensionList[innerLoop].t001_UserObj.userId === resUserId){
                          responseUserExistsFlag = true;
                        }
                      }
                      if(responseUserExistsFlag === false){
                        if(this.checkNullOrUndefined(dataResult[0].t001_ResponsibleUserObj)){
                          let userObjNew = dataResult[0].t001_ResponsibleUserObj;
                            userObjNew.userStatus = false;
                            let resourceExtensionObjNew = {t001_UserObj: userObjNew,t822ProjectObj: this.projectObj }
                            this.resourceExtensionList.push(resourceExtensionObjNew);
                            this.resourceExtensionList.sort((a, b) => a.t001_UserObj.userId> b.t001_UserObj.userId ? 1 : -1);  
                        }
                        
                      }
                    }
                  }
                }
          }, error => {

          }
        );
    } else{
      if(this.commonUtils.checkNullOrUndefined(this.taskObj))  
        this.createTaskForm.patchValue(this.taskObj);
    }
    if(commonUtils.checkNullOrUndefined(this.taskObj) && commonUtils.checkNullOrUndefined(this.taskObj.t004_LookupTaskStatusObj) 
      && commonUtils.checkNullOrUndefined(this.taskObj.t004_LookupTaskStatusObj.lookupValue) && 
      this.taskObj.t004_LookupTaskStatusObj.lookupValue==='YTS' ){
        this.taskStatus=this.taskObj.t004_LookupTaskStatusObj.lookupValue;
        this.taskId=this.taskObj.taskId;
        this.changeTaskStatus=true;
      this.onChangeStatus(this.taskStatus);
    }
   if(commonUtils.checkNullOrUndefined(this.taskObj) && commonUtils.checkNullOrUndefined(this.taskObj.t822ProjectObj)){
       
       this.projectObj = this.taskObj.t822ProjectObj;
       //this.selectProject(this.projectObj);
        
       if(!commonUtils.checkNullOrUndefined(this.taskObj.t800EmployeeObj))
          this.taskObj.t800EmployeeObj={};
       /*if(commonUtils.checkNullOrUndefined(this.projectObj.t823ParentTask) && !commonUtils.checkNullOrUndefined(data.statusFlag)){
         this.fetchTaskFlag  = true;
         this.taskObj.t823ParentTask = {taskId:this.projectObj.t823ParentTask};
       }
       else if(commonUtils.checkNullOrUndefined(this.projectObj.t823ParentTask) && data.statusFlag === 'edit'){
         this.fetchTaskFlag  = true;
         this.taskObj.t823ParentTask.taskId = this.projectObj.t823ParentTask.taskId;
       }
       else{
         this.fetchTaskFlag  = false;
         this.taskObj.t823ParentTask = {};
       }*/

       this.editStr = data.statusFlag;
       
        if(commonUtils.checkNullOrUndefined(this.taskObj) && (data.statusFlag === 'edit' || data.statusFlag === 'clone')){
          this.fetchTaskFlag  = true;
        }else{
          this.fetchTaskFlag  = false;
        }
       
       if(this.commonUtils.checkNullOrUndefined(this.taskObj)) {        

         //this.createTaskForm.patchValue(this.taskObj);
  
            if(this.commonUtils.checkNullOrUndefined(this.taskObj)  
              && this.commonUtils.checkNullOrUndefined(this.taskObj.taskId) && this.taskObj.taskId != 0 ) {
              
              let resourceTaskForm = new FormGroup({
                t823TaskObj: new FormGroup({
                  taskId: new FormControl(this.taskObj.taskId)
                })              
				});
              this.hrmService.fetchResourceTaskAllocation(resourceTaskForm.value).subscribe(
			data => {
                  let userIdList=[];
                  if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
                    for(var loopCount = 0; loopCount<data.length; loopCount++){
                      if(this.commonUtils.checkNullOrUndefined(data[loopCount].t001ResourceObj) && data[loopCount].activeFlag === true )
                          userIdList.push(data[loopCount].t001ResourceObj.userId);   
                    }
                    this.createTaskForm.get('userIdList').setValue(userIdList);
                    let userExistsFlag = false;
                    if(this.commonUtils.checkNullOrUndefined(userIdList) && userIdList.length > 0){
                      for(var loop=0;loop<userIdList.length;loop++){
                        userExistsFlag = false;
                        if(this.commonUtils.checkNullOrUndefined(this.resourceExtensionList) && this.resourceExtensionList.length>0){
                          for(var innerLoop = 0;innerLoop<this.resourceExtensionList.length;innerLoop++){
                            if(this.resourceExtensionList[innerLoop].t001_UserObj.userId === userIdList[loop]){
                              userExistsFlag = true;
                            }
                          }
                          if(userExistsFlag === false){
                            let userObjNew =  data[loop].t001ResourceObj;
                            userObjNew.userStatus = false;
                            let resourceExtensionObjNew = {t001_UserObj: userObjNew,t822ProjectObj: this.projectObj }
                            this.resourceExtensionList.push(resourceExtensionObjNew);
                          }
                        }
                      }
                      this.resourceExtensionList.sort((a, b) => a.t001_UserObj.userId> b.t001_UserObj.userId ? 1 : -1);  
                    }
                  
                  }
                }
                ,error => {
                  console.log(error)
                });

              if(this.taskObj.taskId === 0 && data.statusFlag != 'clone'){
                this.createTaskForm.get("t823ParentTask.taskId").setValue(0);
                this.createTaskForm.get("t823ParentTask.taskName").setValue("");
              }
            }     

        
         
       }
        if(this.createTaskForm.get('taskId').value === 0 && data.statusFlag != 'clone'){
                this.createTaskForm.get("t823ParentTask.taskId").setValue(0);
                this.createTaskForm.get("t823ParentTask.taskName").setValue("");
              }
       if(data.statusFlag === 'clone'){
          this.createTaskForm.get('taskId').setValue(0);
          this.createTaskForm.get('moduleName').setValue(this.moduleName);
          this.createTaskForm.get('taskNumber').setValue('');
          this.createTaskForm.get('estimated_hours').setValue(0);
          this.createTaskForm.get('billing_hours').setValue(0);
          this.createTaskForm.get('nonBillableHours').setValue(0);
          this.createTaskForm.get('actualHours').setValue(0);
          this.createTaskForm.get('percent_complete').setValue(0);
       }
    }
  }
}
ngOnInit()
{
  this.fetchParentTaskHierarchy(); 
  var date = new Date();
   var endDate=new Date(date.getFullYear(), date.getMonth()+1, 0);
   this.createTaskForm.get('prodmoveDate').setValue(endDate);
   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
//     this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu;
           this.editAccess=this.currentlySelectedMenu.editAccess;
           this.pageLoad();
         }
       });

     if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
    this.pageLoad();
if(this.commonUtils.checkNullOrUndefined(this.taskObj) && this.commonUtils.checkNullOrUndefined(this.taskObj.taskId) && this.taskObj.taskId != 0 && this.editStr != 'clone' ){
        this.systemGeneratedDisableFlag = true;
       }else{
        this.systemGeneratedDisableFlag = false;
       }

       


}

getItems(parentChildObj:any) { 
  let itemsArray:any = [];
  parentChildObj.forEach((set: TreeItem) => {
    itemsArray.push(new TreeviewItem(set))
  });
  return itemsArray;
}
onFilterChange(value: string): void {
}
onCheckedChangeFunction(event:any,event1:any){
  //treeViewCls
  var element1 = document.querySelector(".treeViewCls .dropdown-menu");
element1.classList.remove("show");

  var element = document.querySelector(".treeViewCls .dropdown");
element.classList.remove("show");
var element2 = document.querySelector(".treeViewCls .dropdown-toggle");
element2.setAttribute('aria-expanded','false')
  
  this.createTaskForm.get('t823ParentTask.taskName').setValue(event1);
    this.createTaskForm.get('t823ParentTask.taskId').setValue(event);

}
onSelectedChange(downlineItems: DownlineTreeviewItem[]): void {
  this.rows = [];
  downlineItems.forEach(downlineItem => {
  });
}
pageLoad(){ 
  /** code ends above **/ 
  this.displayAutoGen=this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.displaySettingsIcon;

  this.parentTaskList = this.createTaskForm.get(['t823ParentTask']).get(['taskName']).valueChanges.pipe(
         debounceTime(400),
         distinctUntilChanged(),
         switchMap( taskName =>  this.fetchSearchParentTask(taskName)),);

this.subParentTaskList = this.createTaskForm.get(['t823ParentTask']).get(['taskName']).valueChanges.pipe(
         debounceTime(400),
         distinctUntilChanged(),
         switchMap( taskName =>  this.fetchSearchSubParentTask(taskName)),);
  this.validationMethod(); 
  this.fetchAutoGenerate();    
  this.fetchResourceFortheProject();
  this.fetchTaskStatus();
  this.fetchTaskTypeList();
   this.fetchTaskBugTypeList();
   
  if(!this.commonUtils.checkNullOrUndefined(this.projectObj)){
    this.fetchProjectBasedOnResourceExtension();
    this.showProjectDropDown = true;
  }
  if(this.fetchTaskFlag){    
    
    this.setForFileAttachmentFlag = true;
    this.fetchParentTask();
  }else{
    this.setForFileAttachmentFlag = false;
  }
 }

    canDeactivate() {    
 
 if(this.createTaskForm.dirty && this.createTaskForm.get('displayDicardMessage').value==='Y'){
     const dialogRef = this.dialog.open(ERPPromptComponent, {
      width: '500px', disableClose: true,
      data: {dialogText: environment.DISCARD_LABEL, confButton1: 'Leave',confButton2:'Stay'}
    });
     return  dialogRef.afterClosed().pipe(map(
       result => {
         if(result==='BUTTON1')
           return true;
           return false;
            }));
 }
  
  }
  fetchAutoGenerate() {
    this.myAGForm.get('type').setValue(this.moduleName);
    this.commonService.fetchAutoGenerateNumber(this.myAGForm.value).subscribe(
      data => {
        if (this.commonUtils.checkNullOrUndefined(data)) 
        {
          if(data.autoGenerate){
            //this.displayAutoGen = true;
            this.disabledAutoFlag = true;
            this.placeholderstr = 'System Generated';
         }
        }
      }, error => {
        this.commonService.showERPErrorMessage(error);
      });
  }

  /** Popup code**/
  openSettingsDialog(): void {
    const dialogRef = this.dialog.open(AutoNumberGenerateComponent, {
      width: '850px', disableClose: true,
      data: {dialogLabel: this.dialogLabel, dialogOrderType: this.dialogLabel, moduleName: this.moduleName}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.disabledAutoFlag = true;
        this.placeholderstr = 'System Generated';
        this.createTaskForm.get('taskNumber').setValue('');
      } else if (result === false) {
        this.disabledAutoFlag = false;
        this.placeholderstr = 'Task #';
      }

      if(this.disabledAutoFlag === true && this.displayAutoGen === true){
        this.createTaskForm.get('taskNumber').setValidators(null);
        this.createTaskForm.get('taskNumber').updateValueAndValidity();
      }else{
        this.createTaskForm.get('taskNumber').setValidators([Validators.required,Validators.min(1)]);
        this.createTaskForm.get('taskNumber').updateValueAndValidity();
      }

    });
  }
  /*Fetch Resource List ----- START*/
  fetchResource(): void {
 
    this.commonService.fetchResource().
    subscribe(
    data => {
    this.resourceList = data;
    },
    error => { 
    this.commonService.showERPErrorMessage(error);
    });
  }
 
 invoiceForm = new FormGroup({
  taskId: new FormControl(0),
        t822ProjectObj:new FormGroup({projectId:new FormControl(0)})
      });
 resourceExtensionList;
  fetchResourceFortheProject():void{
     this.invoiceForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
      this.hrmService.fetchResourceExtension(this.invoiceForm.value).subscribe(
      data => { 
        this.resourceExtensionList=data;       
      },
       error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  
  fetchParentTaskHierarchy():void{ 
    var arrayVal:any=[];
    if(this.commonUtils.checkNullOrUndefined(this.taskObj.taskId)){
         this.invoiceForm.get('taskId').setValue(this.taskObj.taskId);
    }
     this.invoiceForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
    
    this.hrmService.fetchParentTaskHierarchy(this.invoiceForm.value).subscribe(
      data => {
      const Maplist1=data; 
     const Maplist=  Maplist1.reduce((acc:any, nodeData:any) => (nodeData.children = [], acc[nodeData.TaskId] = nodeData, acc), {});        
        const rootData =  Object.values(Maplist)
        .map((nodeData:any) => (nodeData.ParentTaskId && Maplist[nodeData.ParentTaskId].children.push(nodeData), nodeData))
        .filter(nodeData => nodeData.ParentTaskId === 0).forEach(function(i){
           arrayVal.push(i);    
        }); 
    this.items = this.getItems(arrayVal);
    const elementTest = document.querySelector('.treeview-header .row-filter input') as HTMLInputElement;
    elementTest.placeholder = 'Search Here'; 
      },
      error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  /*Fetch Resource List ----- END*/
  /*Fetch Project List -------- START*/
  /*fetchProject(){ 
      this.hrmService.fetchProject(this.createTaskForm.value)
       .subscribe(
         data=>{ 
           this.projectList = data;
         },
         error=>{
         this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);    
         }


         )
    }*/
//this method is used to show only projects which are extended by the logged in employee
    fetchProjectBasedOnResourceExtension()
    {
       let resourceExtensionForm = ({t800_EmployeeObj:({employeeId:this.commonService.loggedInUserObj.t800EmployeeObj.employeeId})});
              
       this.hrmService.fetchProjectBasedOnResourceExtension(resourceExtensionForm).subscribe(
       data=>
        {
         this.projectList=[];
         if(this.commonUtils.checkNullOrUndefined(data))
         this.projectList=data;
    },
    error=>
    {
       this.commonService.showERPErrorMessage(error);
    })
}

  fetchParentTask(): void {
   
    let searchParentTaskForm=this.getTaskForm();
    searchParentTaskForm.get('taskType').setValue('AP');
    searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
    searchParentTaskForm.get('startDate').setValue('');
    searchParentTaskForm.get('endDate').setValue('');
    if(this.setForFileAttachmentFlag){
      searchParentTaskForm.get('taskType').setValue('');
      searchParentTaskForm.get('taskId').setValue(this.taskObj.taskId);   
      searchParentTaskForm.get('taskName').setValue(this.taskObj.taskName);
      searchParentTaskForm.get('taskNumber').setValue(this.taskObj.taskNumber);
      searchParentTaskForm.get("globalFlag").setValue(true);
    }
    this.commonService.fetchParentTask(searchParentTaskForm.value).
    subscribe(

    data => {    
             
      if(this.commonUtils.checkNullOrUndefined(data) && data.length === 1){

          this.workedHours = data[0].workedHours;
          this.userEmail = data[0].userEmail;
          if(this.editStr === 'edit' && this.commonUtils.checkNullOrUndefined(data[0].baseLine_start_date) &&
           this.commonUtils.checkNullOrUndefined(data[0].baseLine_end_date))
          {
             this.baseLine_start_date = data[0].baseLine_start_date;
             this.baseLine_end_date =data[0].baseLine_end_date;
             this.taskId=data[0].taskId;
          }
          if(this.editStr === 'edit' && this.commonUtils.checkNullOrUndefined(data[0].t004_LookupTaskStatusObj) &&
           this.commonUtils.checkNullOrUndefined(data[0].t004_LookupTaskStatusObj.lookupValue) && 
           data[0].t004_LookupTaskStatusObj.lookupValue==='DELIVERED' )
          {
            this.editAccess=false;
          }
          else
          {
             this.editAccess=true;
          }
        if(this.commonUtils.checkNullOrUndefined(data[0].uploadedFileName)){
         this.createTaskForm.get('fileUpload').setValue(data[0].uploadedFileName);
          let uploadedArray =data[0].uploadedFileName.split(",");
          let fileSize=[];
          if(this.commonUtils.checkNullOrUndefined(data[0].fileSizes)){
            fileSize = data[0].fileSizes.split(",");
          }
          this.uploadedFileArrayparent = [];
          for(let loop =0;loop < uploadedArray.length ;loop++){
            this.uploadedFileArrayparent.push({'name':uploadedArray[loop],'size':fileSize[loop],'addedNewly':false});
          }
        }
      }
    
    },
    error => {
    this.commonService.showERPErrorMessage(error);
    });
  }

 /* fetchAllParentTask(){
  
    let searchParentTaskForm=this.getTaskForm();
    searchParentTaskForm.get('taskType').setValue('AP');
    searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
    searchParentTaskForm.get('startDate').setValue('');
    searchParentTaskForm.get('endDate').setValue('');
    searchParentTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
    
    this.commonService.fetchAllParentTask(searchParentTaskForm.value).
    subscribe(
    data => { 
      if(this.commonUtils.checkNullOrUndefined(data)){
        this.parentTaskList = data;
      }    
    },
    error => {
      this.commonService.showERPErrorMessage(error);
    });
  }*/
  /*Fetch Parent Task List ----- END*/
removeAllResource(){
 if( this.commonUtils.checkNullOrUndefined(this.createTaskForm.get('userIdList').value))
 {
  this.createTaskForm.get('userIdList').setValue('');
 }
}

  saveOrUpdateTask(){
    this.enableFormBeforeSave();
    this.createTaskForm.get('displayDicardMessage').setValue('N'); 
    this.createTaskForm.get('userEmail').setValue(this.userEmail);

    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.t000Organization != null && this.commonService.loggedInUserObj.t000Organization.portalName != null
      && this.taskId === 0)
      this.createTaskForm.get('portalName').setValue(this.commonService.loggedInUserObj.t000Organization.portalName);
    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.userName != null)
      this.createTaskForm.get('createdBy').setValue(this.commonService.loggedInUserObj.userName);
    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.email != null)
      this.createTaskForm.get('createdByEmail').setValue(this.commonService.loggedInUserObj.email);
    
      if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
        if(this.createTaskForm.get('taskId').value === 0)
          this.createTaskForm.get('taskCreatedUserById').setValue(this.commonService.loggedInUserObj.userId);
        else {
          this.createTaskForm.get('taskUpdatedUserById').setValue(this.commonService.loggedInUserObj.userId);
        }
      }
    this.commonService.showProgressBar();

    

    if(this.commonUtils.checkNullOrUndefined(this.createTaskForm.get('userIdList').value)){
    (this.createTaskForm.get('t839_Resource_TaskallocationList')as FormArray).controls = [];
    let userIdList = this.createTaskForm.get('userIdList').value;
      if(userIdList.length > 0){
        const t839_Resource_TaskallocationList  = this.createTaskForm.get('t839_Resource_TaskallocationList') as FormArray;
        for(var loopCount = 0 ; loopCount < userIdList.length; loopCount++){
          t839_Resource_TaskallocationList.push(this.addResourceLineItem(userIdList[loopCount]));
        }
      }
    }  
     
    this.taskList.push(this.createTaskForm.value);
    this.disableButton=true;
    this.projectService.saveTask(this.taskList)
    .subscribe(
      data => {  
        this.disableButton=false;
        if(this.commonUtils.checkNullOrUndefined(data)){
          this.commonService.hideProgressBar();
          this.commonService.showERPMessage(data[0].erpmessage);
          this.dialogRef.close(true);
        }
      }, error => {
        this.commonService.hideProgressBar();
        this.disableButton=false;
        this.commonService.showERPErrorMessage(error);
      });
 
  }
  cancelClick(){
    this.dialogRef.close();
  }
  taskNumberExistsCheck(): void{
    this.taskNumberStatus = this.createTaskForm.controls['taskNumber'].valueChanges.pipe(
      debounceTime(400),
      switchMap(invoiceNumberStatus =>
        this.hrmService.fetchTaskNumberStatus(this.createTaskForm.value)),);
  }
  onfocusoutAutoGenField():void{
   /*to remove the validations for the auto generated value - if it is true - starts*/
   if(this.disabledAutoFlag === true && this.displayAutoGen === true){
      this.createTaskForm.get('taskNumber').setValidators(null);
      this.createTaskForm.get('taskNumber').markAsTouched();
      this.createTaskForm.get('taskNumber').updateValueAndValidity();
         
   }
 }
 validationMethod(): void{   
     let promise=this.commonService.fetchAndSetValidationData(this.moduleName,this.createTaskForm);
     promise.then(data=>
        {
         //this.validationListForSelectedModule=data;
         this.onfocusoutAutoGenField();
         this.checkSummaryFlag(true);
        }
     ); 
   }
   fetchTaskStatus()
   {
     this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('PROJECT_PLAN').value).
     subscribe(
     data =>
      {
             this.taskStatusList = data;

             if(this.commonUtils.checkNullOrUndefined(this.taskStatusList) && this.taskStatusList.length > 0){
               for(var loopCount = 0; loopCount < this.taskStatusList.length; loopCount++ ){
                 if(this.taskStatusList[loopCount].lookupValue === 'OPEN'){
                   this.openTaskLookupObj = this.taskStatusList[loopCount];
                    if(this.createTaskForm.get('taskId').value === 0){
                      this.createTaskForm.get('t004_LookupTaskStatusObj').patchValue(this.openTaskLookupObj);
                       this.validationForUserName();
                    }
                   }
               }
             }
             
      },
    error => 
    {
        this.commonService.showERPErrorMessage(error);
      });
   }
fetchTaskTypeList()
   {
     this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('TASK_TYPE').value).
     subscribe(
     data =>
      {
             this.taskTypeList = data;
               if(this.commonUtils.checkNullOrUndefined(this.taskTypeList) && this.taskTypeList.length > 0){
               for(var loopCount = 0; loopCount < this.taskTypeList.length; loopCount++ ){
                 if(this.taskTypeList[loopCount].lookupValue === 'ENHANCEMENT'){
                   this.enhancementTypeLookupObj = this.taskTypeList[loopCount];
                    if(this.createTaskForm.get('taskId').value === 0){ 
                       this.createTaskForm.get('t004_LookupTaskTypeObj').patchValue(this.enhancementTypeLookupObj);
                    }
                 }
               }
             }
      },
    error => 
    {
        this.commonService.showERPErrorMessage(error);
      });
   }
   fetchTaskBugTypeList()
   {
     this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('BUG_TYPE').value).
     subscribe(
     data =>
      {
             this.taskBugTypeList = data;
             
      },
    error => 
    {
        this.commonService.showERPErrorMessage(error);
      });
   }
checkSummaryFlag(initialLoad?){
  if(this.createTaskForm.get('summary').value){
 
    this.createTaskForm.get('billing_hours').disable();
    this.createTaskForm.get('estimated_hours').disable();
    this.createTaskForm.get('percent_complete').disable();
    this.createTaskForm.get('startDate').disable();
    this.createTaskForm.get('endDate').disable();
    this.createTaskForm.get('development').enable();
    this.createTaskForm.get('codeReview').enable();
    this.createTaskForm.get('testing').enable();

    //this.createTaskForm.get('t004_LookupTaskStatusObj.lookupId').disable();
   // this.createTaskForm.get('t001_UserObj.userId').disable();
    this.createTaskForm.get('userIdList').disable();
  //  this.createTaskForm.get('t823ParentTask.taskName').disable();
   
    this.resourceList=[]; 
    
     if(!this.commonUtils.checkNullOrUndefined(initialLoad)){
		 this.createTaskForm.get('t823ParentTask.taskId').setValue(0);
        this.createTaskForm.get('t823ParentTask.taskName').setValue(''); 
     }
      
    this.createTaskForm.get('t823ParentTask.taskName').setValidators(null);
    this.createTaskForm.get('t823ParentTask.taskName').markAsTouched();
    this.createTaskForm.get('t823ParentTask.taskName').updateValueAndValidity();

    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').setValidators(null);
    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').markAsTouched();
    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').updateValueAndValidity();

    this.createTaskForm.get('t001_ResponsibleUserObj.userId').setValidators(null);
    this.createTaskForm.get('t001_ResponsibleUserObj.userId').markAsTouched();
    this.createTaskForm.get('t001_ResponsibleUserObj.userId').updateValueAndValidity();
   
  }
  else{
    this.createTaskForm.get('billing_hours').enable();
    this.createTaskForm.get('estimated_hours').enable();
    this.createTaskForm.get('percent_complete').enable();
    this.createTaskForm.get('development').disable();
    this.createTaskForm.get('startDate').enable();
    this.createTaskForm.get('endDate').enable();
    this.createTaskForm.get('development').disable();
    this.createTaskForm.get('codeReview').disable();
    this.createTaskForm.get('testing').disable();
    //this.createTaskForm.get('t004_LookupTaskStatusObj.lookupId').enable();
    //this.createTaskForm.get('t001_UserObj.userId').enable();
    this.createTaskForm.get('userIdList').enable();
    this.createTaskForm.get('development').disable();
    this.createTaskForm.get('t823ParentTask.taskName').enable(); 
      if(!this.commonUtils.checkNullOrUndefined(initialLoad)){
        this.createTaskForm.get('t823ParentTask.taskId').setValue(0);
        this.createTaskForm.get('t823ParentTask.taskName').setValue('');
      } 
        
    this.createTaskForm.get('t823ParentTask.taskName').setValidators([Validators.required,Validators.min(1)]);
    this.createTaskForm.get('t823ParentTask.taskName').markAsTouched();
    this.createTaskForm.get('t823ParentTask.taskName').updateValueAndValidity();
 
    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').setValidators([Validators.required,Validators.min(1)]);
    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').markAsTouched();
    this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').updateValueAndValidity();

    this.createTaskForm.get('t001_ResponsibleUserObj.userId').setValidators([Validators.required, Validators.min(1)]);
    this.createTaskForm.get('t001_ResponsibleUserObj.userId').markAsTouched();
    this.createTaskForm.get('t001_ResponsibleUserObj.userId').updateValueAndValidity();
    this.fetchResource();
  }
}

attachFiles(){
 this.editId= this.createTaskForm.get('taskId').value; /*t823ParentTask*/
 
  let imagePath =null;
  if(this.editId !=0){
    let uniqueNumber:string;
    uniqueNumber = this.createTaskForm.get('taskNumber').value;
    let orgId = this.commonService.loggedInUserObj.t000Organization.organizationId;
    let portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
    
    imagePath = this.commonService.SERVICE_URL+"erpassets/"+portalName+"/"+this.moduleName+"/"+uniqueNumber;
  }
  else{
    imagePath = null;
  }
  
  const dialogRef = this.dialog.open(FileUploadComponent, { 
      width: '40%',
     // height: '60%',
      data: {dialogLabel: "Attach Files",uploadedFileArray:this.uploadedFileArrayparent,imagePath:imagePath,hideDeleteFlag: this.accessFlag}
    });
    dialogRef.afterClosed().subscribe(result => {  
      if (result.done === true) {        
        this.uploadedFileArrayparent=result.uploadedArray;
                      
        if(this.uploadedFileArrayparent !=null && this.uploadedFileArrayparent.length>0){
          var fileUploaded = "";
          for(let loop =0;loop < this.uploadedFileArrayparent.length ;loop++){
             
            fileUploaded = fileUploaded+this.uploadedFileArrayparent[loop].name+",";
          }
          fileUploaded = fileUploaded.substring(0,fileUploaded.length-1);
       
          this.createTaskForm.get('fileUpload').setValue(fileUploaded);
          
        }
        else{
          this.createTaskForm.get('fileUpload').setValue("");
        }
        
      } 
    });
}


enableFormBeforeSave(){
    this.createTaskForm.get('billing_hours').enable();
    this.createTaskForm.get('estimated_hours').enable();
    this.createTaskForm.get('percent_complete').enable();
    this.createTaskForm.get('startDate').enable();
    this.createTaskForm.get('endDate').enable();
    //this.createTaskForm.get('t004_LookupTaskStatusObj.lookupId').enable();
    //this.createTaskForm.get('t001_UserObj.userId').enable();
    this.createTaskForm.get('userIdList').enable();
}

onChangeStatus(lookupValue)
{

this.createTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue(lookupValue);
  if(this.changeTaskStatus=== true)
  {
     if(this.taskStatus === 'YTS' && lookupValue === 'OPEN' && this.taskId!=0 ){
        this.validationForUserName();
     }
     else{
       this.removeValidationForUserName();
     }
  }
  else if(this.changeTaskStatus=== false)
  {

   if(lookupValue === 'OPEN' && this.taskId==0 ){
    if(this.editStr === 'clone')
     {
       //this.createTaskForm.get('t001_UserObj.userId').setValue('');
       this.createTaskForm.get('userIdList').setValue('');
     }
       this.validationForUserName();
   }
   else{
       this.removeValidationForUserName();
   }

  }
  else if(lookupValue != 'OPEN'){
    this.removeValidationForUserName(); 
  }

}

onChangeTaskType(statusObj){
   
  this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').setValue(0);
  this.createTaskForm.get('t004_LookupBugTypeObj.lookupValue').setValue("");
  this.createTaskForm.get('t004_LookupTaskTypeObj.lookupId').setValue(statusObj.lookupId);
  this.createTaskForm.get('t004_LookupTaskTypeObj.lookupValue').setValue(statusObj.lookupValue);
if(statusObj.lookupValue === 'BUG'){
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').setValidators([Validators.required,Validators.min(1)]);
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').markAsTouched();
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').updateValueAndValidity();
  }else{
      this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').setValidators(null);
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').markAsTouched();
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').updateValueAndValidity();
  }
  
}
onChangeBugType(statusObj){
    this.createTaskForm.get('t004_LookupBugTypeObj.lookupId').setValue(statusObj.lookupId);
  this.createTaskForm.get('t004_LookupBugTypeObj.lookupValue').setValue(statusObj.lookupValue);
}
validationForUserName(){
   /*this.createTaskForm.get('t001_UserObj.userId').setValidators([Validators.required,Validators.min(1)]);
   this.createTaskForm.get('t001_UserObj.userId').markAsTouched();
   this.createTaskForm.get('t001_UserObj.userId').updateValueAndValidity();*/
   this.createTaskForm.get('userIdList').setValidators([Validators.required,Validators.min(1)]);
   this.createTaskForm.get('userIdList').markAsTouched();
   this.createTaskForm.get('userIdList').updateValueAndValidity();
}
removeValidationForUserName()
{
/*  this.createTaskForm.get('t001_UserObj.userId').setValidators(null);
   this.createTaskForm.get('t001_UserObj.userId').markAsTouched();
   this.createTaskForm.get('t001_UserObj.userId').updateValueAndValidity();*/
     this.createTaskForm.get('userIdList').setValidators(null);
   this.createTaskForm.get('userIdList').markAsTouched();
   this.createTaskForm.get('userIdList').updateValueAndValidity();
}
confirmToSaveBaseLineDate(){  
     let currentStatus = this.createTaskForm.get('t004_LookupTaskStatusObj.lookupValue').value;
     let startDate= this.createTaskForm.get('startDate').value;
     let organisationFormat=this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;
     var startDateChanged=this.datePipe.transform(startDate, organisationFormat);
     let endDate=this.createTaskForm.get('endDate').value;
     var endDateChanged=this.datePipe.transform(endDate, organisationFormat);

     if(this.taskStatus === 'YTS' && currentStatus === 'OPEN' && this.taskId != 0 ){
   
    const dialogTextVal = 'The ' + startDateChanged +' and ' + endDateChanged + ' will be set as Baseline dates for the tasks. Do you want to continue saving ?';
    const confTextVal = 'ConfirmBaselineTaskDate';
    const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                          width: '500px',
                          data: {dialogText: dialogTextVal, confText: confTextVal}
                        });
          dialogRef.afterClosed().subscribe(result => {
           if (result === true) {
                  this.createTaskForm.get('newEntryDateStatus').setValue(true);
                  this.saveOrUpdateTask();
                }
          });
      }else if(currentStatus === 'OPEN' && this.taskId === 0 ){
    
        const dialogTextVal = 'The ' + startDateChanged +' and ' + endDateChanged + ' will be set as Baseline dates for the tasks. Do you want to continue saving ?';
        const confTextVal = 'ConfirmBaselineTaskDate';
        const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                              width: '500px',
                              data: {dialogText: dialogTextVal, confText: confTextVal}
                            });
              dialogRef.afterClosed().subscribe(result => {
               if (result === true) {
                  this.createTaskForm.get('newEntryDateStatus').setValue(true);
                      this.saveOrUpdateTask();
                    }
              });
      }
    else{
            this.createTaskForm.get('newEntryDateStatus').setValue(false);
            this.saveOrUpdateTask();
    }

   }

 // Prevent Saturday and Sunday from being selected.
    satSunDisableInSelectDateFilter = (d: Date): boolean => {
      if(this.commonUtils.checkNullOrUndefined(d)){
         const day = d.getDay();
          return day !== 0 && day !== 6;
      }
     
  }
  
   public fetchSearchSubParentTask(taskName):Observable<any>{   


 let searchParentTaskForm=this.getTaskForm();
    searchParentTaskForm.get('taskType').setValue('AP');
    searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
    searchParentTaskForm.get('startDate').setValue('');
    searchParentTaskForm.get('endDate').setValue('');
    searchParentTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
    searchParentTaskForm.get('t823ParentTask.taskName').setValue(taskName);
    searchParentTaskForm.get('isPagination').setValue('true');

searchParentTaskForm.get('summary').setValue(false);
  
 
   return this.commonService.fetchParentTaskList(searchParentTaskForm.value);
  
 }
   public fetchSearchParentTask(taskName):Observable<any>{   


 let searchParentTaskForm=this.getTaskForm();
    searchParentTaskForm.get('taskType').setValue('AP');
    searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
    searchParentTaskForm.get('startDate').setValue('');
    searchParentTaskForm.get('endDate').setValue('');
    searchParentTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
    searchParentTaskForm.get('t823ParentTask.taskName').setValue(taskName);
    searchParentTaskForm.get('isPagination').setValue('true');

searchParentTaskForm.get('summary').setValue(true);
  
 
   return this.commonService.fetchParentTaskList(searchParentTaskForm.value);
  
 }
 taskSelected(selectedTask): void {
   if(this.commonUtils.checkNullOrUndefined(selectedTask)){
      this.createTaskForm.get('t823ParentTask.taskName').setValue(selectedTask.taskName);
      this.createTaskForm.get('t823ParentTask.taskId').setValue(selectedTask.taskId);   
   }else{
       this.createTaskForm.get('t823ParentTask.taskName').setValue("None");
   }
 }  
 addingMoreUser(userObj){
    
 }
 openActualHours(taskId){ 
   const dialogRef = this.dialog.open(MultiUserWorkedHoursComponent,
    {
      width: '65%',
      height:'75%',
      data: {taskId: taskId}
    });   

    dialogRef.afterClosed().subscribe(result => { 
    });

 } 
 viewTaskHierarchy(elementObj,taskId,taskName, elementRef) {
  if (this.commonUtils.checkNullOrUndefined(taskName)) {
    taskName = elementObj.taskName;      
  }
  let infoDialogRef;
  infoDialogRef = this.erpCustomDialog.open(elementRef._elementRef, {
    data: { moduleName: "VIEW_TASK_HIERARCHY", taskId: taskId, taskName: taskName }
  }, ViewQuickInfoComponent);
  infoDialogRef.afterClosed.subscribe(result => {
  });
}
}