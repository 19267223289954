import {ErrorHandler,Injectable} from '@angular/core';
import {LoggerService} from './error-log-service';
import {FormControl, FormGroup, FormArray, NgForm, Validators, AbstractControl, FormBuilder} from '@angular/forms';
@Injectable()
export class ERPErrorHandler implements ErrorHandler {
  constructor(private commonService: LoggerService) {
  //	super();
  }
 errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
  handleError(error: any) {
    // send to the observable
    console.log(' error in angualr ',error);
    this.errorFormGroup.get('errorMessage').setValue(error);
     this.errorFormGroup.get('errorStackTrace').setValue(error);
   
    this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
    	resut=>{//console.log(resut);
    	},
    	error=>{
    	//	console.log(error);
    		
    	})
    
    // next show a friendly message
   /* this.snackBar.open('Your message here', 'Dismiss', {
      duration: 2000
    });*/
  }
}