
<div class="example-container mat-elevation-z8 viewcontacts">
  
  <form (ngSubmit)="saveOrUpdateOrganizationProfile()"   [formGroup]="myForm" enctype="multipart/form-data"  #formdata>
  
<mat-card-title  class="mat-card-title cardheadertitle">
 
      <mat-icon>shop</mat-icon>{{ORGANIZATION_PROFILE}}</mat-card-title>

      <mat-card class="col-lg-12">

      <mat-card-content>


<div class="col-lg-12 row nopadding">
  
  <div class="col-lg-6 nopadding">
 
   

      <div class="col-lg-12" style="margin-top: 1%;">
        <mat-form-field class="col-lg-12">
          <input matInput placeholder="{{ORGANIZATION_NAME}}" formControlName="organizationName">
        </mat-form-field>
      </div>
      
    <div class="col-lg-12">
         <mat-form-field  class="col-lg-6"  formGroupName="t028Location">
          <input type="text" placeholder="{{BUSINESS_LOCATION}}" aria-label="Terms" matInput formControlName="locationName" [matAutocomplete]="locations">
             <mat-autocomplete #locations="matAutocomplete" (optionSelected)="businessLocationSelected($event.option)">
            <mat-option *ngFor="let locations of businessLocations" [value]="locations.locationId">{{locations.locationName}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
    
         <mat-form-field  class="col-lg-6" formGroupName="t027Industry">
          <input type="text" placeholder="{{INDUSTRY}}" aria-label="Terms" matInput formControlName="industryName" [matAutocomplete]="indutry">
          <mat-autocomplete #indutry="matAutocomplete" (optionSelected)="industrySelected($event.option)">
            <mat-option *ngFor="let indutry of indutries" [value]="indutry.industryId">{{indutry.industryName}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
    </div>

     <div class="col-lg-12">
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{PRIMARY_CONTACT_NAME}}" formControlName="primaryContactName">
    </mat-form-field>
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{PRIMARY_CONTACT_EMAIL}}" formControlName="primaryContactEmailAddress" matTooltip="{{EMAIL_ADDRESS_ALERT}}">
     
    </mat-form-field>
  </div>
 

<div class="col-lg-12">

    <mat-form-field class="col-lg-6" formGroupName="t003Currency">
      <input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_CURRENCY}}" matInput formControlName="currencyCode" [matAutocomplete]="currency"><b class="caret" style="margin-left: -13px;"></b>
      <mat-autocomplete #currency="matAutocomplete" (optionSelected)="currencySelected($event.option)">
        <mat-option *ngFor="let currency of  currencyList" [value]="currency.currencyId">{{currency.currencyCode }}-{{currency.currencyValue}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  
    <mat-form-field class="col-lg-6" formGroupName="t012Language">
      <input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_LANGUAGE}}" matInput formControlName="name" [matAutocomplete]="lang"><b class="caret" style="margin-left: -13px;"></b>
      <mat-autocomplete #lang="matAutocomplete" (optionSelected)="languageSelected($event.option)">
        <mat-option *ngFor="let lang of languages" [value]="lang.langId">{{lang.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

   
  </div>

<div class="col-lg-12">
  <mat-form-field class="col-lg-6"  formGroupName="t004LookupFiscalYearId">
      <input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_FISCAL_YEAR}}" matInput formControlName="lookupValue" [matAutocomplete]="fiscal"><b class="caret" style="margin-left: -13px;"></b>
      <mat-autocomplete #fiscal="matAutocomplete" (optionSelected)="fiscalYearSelected($event.option)">
        <mat-option *ngFor="let fiscal of fiscalYears" [value]="fiscal.lookupId">{{fiscal.lookupValue}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

   <mat-form-field class="col-lg-6" formGroupName="t004LookupDateFormatId">
     <input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_DATE_FORMAT}}" matInput formControlName="lookupValue" [matAutocomplete]="dateFrmt"><b class="caret" style="margin-left: -13px;"></b>
     <mat-autocomplete #dateFrmt="matAutocomplete" (optionSelected)="dateFormatSelected($event.option)">
       <mat-option *ngFor="let dateformat of dateFormats" [value]="dateformat.lookupId">{{dateformat.lookupValue}}</mat-option>
     </mat-autocomplete>
   </mat-form-field>

</div>

<div class="col-lg-12">
  <mat-form-field class="col-lg-6" formGroupName="t004LookuptimeZoneId">
     <input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_TIMEZONE}}" matInput formControlName="lookupValue" [matAutocomplete]="zone"><b class="caret" style="margin-left: -13px;"></b>
     <mat-autocomplete #zone="matAutocomplete" (optionSelected)="timeZoneSelected($event.option)">
       <mat-option *ngFor="let zone of timezones" [value]="zone.lookupId">{{zone.lookupValue}}</mat-option>
     </mat-autocomplete>
   </mat-form-field>
     <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{PORTAL_NAME_SMALL}}" formControlName="portalName">
    </mat-form-field>
  </div>
  <div class="col-lg-12" style="margin-top: 1%;">
            <mat-form-field class="col-lg-12">
        <!--   <input matInput placeholder="{{WHITE_LIST_IP}}" formControlName="whitelistIP"> -->

            <mat-chip-list #chipList>
         <mat-chip color="primary" *ngFor="let ip of whitelistIPList" [selectable]="selectable" [removable]="removable" (removed)="remove(ip, 'TO')">            {{ip}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip> 
        <input  matInput  placeholder="{{WHITE_LIST_IP}}"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'TO')" />
        </mat-chip-list>

         </mat-form-field>
         </div> 

           <!--   <div class="col-lg-12 marginTopM2 nopaddingLR" >
      <mat-form-field class="col-lg-12"   >
          
        <mat-chip-list #chipList>
         <mat-chip color="primary" *ngFor="let ip of whitelistIPList" [selectable]="selectable" [removable]="removable" (removed)="remove(ip, 'TO')">            {{ip}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip> 
        <input  matInput  
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'TO')" />
        </mat-chip-list>
      </mat-form-field>
    </div> -->

    </div> 

  <div class="col-lg-3">

     <img [src]="logo" *ngIf="showExistingImg===false" style="max-height:246px; max-width: 246px; border: 1px solid; display: block;"> 
    <img *ngIf="showExistingImg===true" id="existingImg" src="{{myForm.controls.imageSrc.value}}" (error)="errorHandler($event)" style="max-height:246px; max-width: 246px; border: 1px solid; display: block;">  

 <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <label class="labelClass paddingLabels">{{UPLOAD_LOGO}}</label>
         <input type="file" class="paddingLabels" (change)="onFileChange($event)">
         <!-- <div *ngFor="let item of fileArray">
           {{item.name}} {{item.size}}
         </div> -->

    </div>


  </div>

<!--  
 <div class="col-lg-12">

 

 </div> -->
 <!--  <div class="col-lg-12">
  <div class="col-lg-10">
   <mat-form-field class="col-lg-3">
     <input matInput placeholder="{{CITY}}" formControlName="city">
   </mat-form-field>
   <mat-form-field class="col-lg-3">
     <input matInput placeholder="{{STATE_PROVINCE}}" formControlName="state">
   </mat-form-field>
   <mat-form-field class="col-lg-3">
     <input matInput placeholder="{{POSTAL_CODE}}" formControlName="zipCode">
   </mat-form-field>
 
 <mat-form-field class="col-lg-3" formGroupName="countryId">
    <mat-select placeholder="{{COUNTRY}}" formControlName="countryId">
      <mat-option *ngFor="let country of countryList" [value]="country.countryId">
       {{country.countryCode}}
      </mat-option>
      </mat-select>
   </mat-form-field>
 </div>
 
 </div> -->
 <!--  <div class="col-lg-12">
  <div class="col-lg-10">
   <mat-form-field class="col-lg-4">
     <input matInput placeholder="{{PHONE}}" formControlName="phone"
      [textMask]="{mask: mask}">
   </mat-form-field>
   <mat-form-field class="col-lg-4">
     <input matInput placeholder="{{FAX}}" formControlName="fax">
   </mat-form-field>
   <mat-form-field class="col-lg-4">
     <input matInput placeholder="{{WEBSITE}}" formControlName="website">
   </mat-form-field>
 </div>
 </div> -->
 
   

 <div class="col-lg-10">
 
 
 
  
  </div>  
<div class='col-lg-12 nopadding'  style="margin-top: 15px !important;">
<mat-accordion>
          <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)"
                               (closed)="addressPanelOpenState = false" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-right: 0;padding-left: 0;">
            <mat-expansion-panel-header class="mat-expansion-panel-custom">
              <mat-panel-title>
                {{BILLING_SHIPPING_ADDRESS}}
              </mat-panel-title>
              <mat-panel-description>
                <!--  Input Basic Details -->
              </mat-panel-description>
            </mat-expansion-panel-header>
 
 
                  <div class="col-lg-12 row nopadding" >          
                <div class="col-lg-6 nopadding" >
                  <div class="col-lg-6">{{BILLING_ADDRESS}}</div>
<!--  <div formArrayName="t000_Organization_BillingAddressesSet" *ngFor="let billTo of myForm.get('t000_Organization_BillingAddressesSet')['controls']; index as indx">
                            
                <div class="col-lg-6" *ngIf="billTo.controls.isDefault.value === 'Y'">
                  <div class="col-lg-6">{{BILLING_ADDRESS}}</div> -->

                  <div class="col-lg-12 nopadding" formGroupName="defaultBillingLocationId">

                    <mat-form-field class="col-lg-6" >
                      <mat-select placeholder="{{LOCATION}}" formControlName="locationId" >
                        <mat-option *ngFor="let locations of businessLocations" [value]="locations.locationId"  (click)="billingLocationSelected(locations)">
                          {{locations.locationName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>                     

                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{ATTENTION}}" formControlName="locationAttention" maxlength="250">

                    </mat-form-field>

                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{STREET1}}" formControlName="locationAddressLineOne" maxlength="150">

                    </mat-form-field>
                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{STREET2}}" formControlName="locationAddressLineTwo" maxlength="150">

                    </mat-form-field>

                  
                      <mat-form-field class="col-lg-6">
                        <input matInput readonly placeholder="{{CITY}}" formControlName="locationCity" maxlength="75">
                      </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{STATE}}" formControlName="locationState" maxlength="75">
                    </mat-form-field>
                    <div class="col-lg-12 nopadding">
                      <mat-form-field class="col-lg-6">
                        <input matInput readonly maxlength="20" placeholder="{{ZIP_CODE}}" formControlName="locationZipCode"  >
                      </mat-form-field>
                      <mat-form-field class="col-lg-6" formGroupName="t010locationCountryObj">
                      <mat-select disabled placeholder="{{COUNTRY}}" formControlName="countryId">
                        <mat-option *ngFor="let country of countryList" [value]="country.countryId">
                          {{country.countryValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    </div>

                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{PHONE}}" formControlName="locationPhone" [textMask]="{mask: mask}">
                    </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{FAX}}" formControlName="locationFax" myNumberOnly maxlength="20">
                    </mat-form-field>

                  <mat-form-field class="col-lg-6">
                      <input matInput placeholder="{{GST_No}}" formControlName="gstNumber" maxlength="50"  >
                  </mat-form-field> 

                  </div>
                </div>


 
           

              <div class="col-lg-6 nopadding" > 
                   <!-- <div class="col-lg-6" > -->
                  <div class="col-lg-6">{{SHIPPING_ADDRESS}}</div>
                 <!--  <div class="col-lg-6" style="text-align: right;"><a style="cursor: pointer;" (click)="manageAddresses()">{{MANAGE_ADDRESS}}</a> </div> -->
                 <!-- <div class="col-lg-6"><a style="cursor: pointer;float: right;" (click)="copyAsDefaultShippingAddress()">{{COPY_ADDRESS_MESSAGE}}</a></div> -->

                  <div class="col-lg-12 nopadding" formGroupName="defaultShippingLocationId">

                    <mat-form-field class="col-lg-6" >
                      <mat-select placeholder="{{LOCATION}}" formControlName="locationId">
                        <mat-option *ngFor="let locations of businessLocations" [value]="locations.locationId"   (click)="shippingLocationSelected(locations)">
                          {{locations.locationName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{ATTENTION}}" formControlName="locationAttention" maxlength="250">
                    </mat-form-field>

                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{STREET1}}" formControlName="locationAddressLineOne" maxlength="150">
                    </mat-form-field>

                    <mat-form-field class="col-lg-12">
                      <input matInput readonly placeholder="{{STREET2}}" formControlName="locationAddressLineTwo" maxlength="150">
                    </mat-form-field>
                    <mat-form-field class="col-lg-6">
                        <input matInput readonly placeholder="{{CITY}}" formControlName="locationCity" maxlength="75">
                      </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{STATE}}" formControlName="locationState" maxlength="75">
                    </mat-form-field>
                    <div class="col-lg-12 nopadding">
                      <mat-form-field class="col-lg-6">
                        <input matInput readonly placeholder="{{ZIP_CODE}}" formControlName="locationZipCode" maxlength="20"  >
                      </mat-form-field>
                        <mat-form-field class="col-lg-6" formGroupName="t010locationCountryObj">
                      <mat-select disabled placeholder="{{COUNTRY}}" formControlName="countryId">
                        <mat-option *ngFor="let country of countryList" [value]="country.countryId">
                          {{country.countryValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                      
                    </div>
                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{PHONE}}" formControlName="locationPhone" [textMask]="{mask: mask}">
                    </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <input matInput readonly placeholder="{{FAX}}" formControlName="locationFax" maxlength="20" myNumberOnly>
                    </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <input matInput placeholder="{{GST_No}}" formControlName="gstNumber" maxlength="50"  >
                    </mat-form-field>

                  </div> 
                </div>
            
   
                  <div class="col-lg-12 nopadding">
                    <label class="discountDivColor"><i> This address will be used for UPS charges calculation. Ensure to provide the details as registered with UPS  <a [routerLink]="['/settings/createIntegrationUps']" >Configure </a></i></label>
                  </div>
               
               </div>
              
  </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class='col-lg-12 nopadding'  style="margin-bottom: 15px !important;">
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)"
                                 (closed)="bankPanelOpenState = false" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding" style="padding-right: 0;padding-left: 0;">
              <mat-expansion-panel-header class="mat-expansion-panel-custom">
                <mat-panel-title>
                {{BANK_DETAILS}}
                </mat-panel-title>
                <mat-panel-description>
                  <!-- Input Other Details -->
                </mat-panel-description>
              </mat-expansion-panel-header>
          <div class="col-lg-12 nopadding">
              <mat-form-field class="col-lg-3">
                  <input matInput placeholder="{{BANK_NAME}}" formControlName="bankName" maxlength="100">
              </mat-form-field>

               <mat-form-field class="col-lg-3">
                <input matInput placeholder="{{BRANCH}}" formControlName="branch" maxlength="50">
              </mat-form-field>

              <mat-form-field class="col-lg-3">
                    <input matInput placeholder="{{ACC_NO}}" formControlName="accountNumber" maxlength="50">
                </mat-form-field>
            </div>

              <div class="col-lg-12">
                <mat-form-field class="col-lg-3">
                   <input matInput placeholder="{{IFSC_CODE}}" formControlName="ifscCode" maxlength="50"  >
                  </mat-form-field>

                <mat-form-field class="col-lg-3">
                  <input matInput placeholder="{{COMPANY_PAN}}" formControlName="companyPAN" maxlength="50">
                </mat-form-field>

                <mat-form-field class="col-lg-3">
                  <input matInput placeholder="{{GSTIN_UIN}}" formControlName="gstin_Uin" maxlength="50"  >
              </mat-form-field>
              </div>

            </mat-expansion-panel>
          </mat-accordion>
</div>
  
  <div class="col-lg-12 paddingbut buttons"  >
    <button mat-raised-button color="primary" class="mat-red" type="submit" >{{SAVE_BUTTON}}</button>
    <button mat-raised-button color="primary" type="button" (click)="onNoClick();">{{CANCEL_BUTTON}}</button>
  </div>
</div>
</mat-card-content>
</mat-card>


</form>
</div>