
<mat-card class="col-lg-12 ">
<mat-card-title *ngIf="this.myForm.get('locationId').value === 0"><mat-icon>store</mat-icon>{{CREATE_WAREHOUSE}}</mat-card-title>
<mat-card-title *ngIf="this.myForm.get('locationId').value != 0"><mat-icon>store</mat-icon>EDIT WAREHOUSE</mat-card-title>
<mat-card-content>
<form (ngSubmit)="saveOrUpdateWarehouses()" [formGroup]="myForm">

		<mat-form-field class="col-lg-6" formGroupName="t028_LocationObj" *ngIf="this.commonUtils.checkNullOrUndefined(templateSettingMap.dispIsUnder) && templateSettingMap.dispIsUnder" style="padding-left: 8%;padding-right: 0%;">
            	<mat-select placeholder="{{templateSettingMap.phIsUnder}}" formControlName="locationId">
                    <mat-option *ngFor="let location of parentLocationList" [value]="location.locationId" (click)="changeParentObj(location)">
                      {{location.locationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
	<div class="col-lg-12" *ngIf="templateSettingMap.dispLocationName">
		<mat-form-field class="col-lg-12" >
				<input matInput placeholder="{{templateSettingMap.phLocationName}}" formControlName="locationName" (focusout)='onfocusout()'>		
		</mat-form-field>
	</div>


		<mat-slide-toggle class="example-margin col-lg-12" style="margin-left: -1%;margin-top: 0%; padding-left:8%;" color="primary" formControlName="wareHouse" *ngIf="templateSettingMap.dispIsWarehouse">{{templateSettingMap.phIsWarehouse}}</mat-slide-toggle>
		<mat-slide-toggle color="primary" class="example-margin col-lg-12" style="margin-left: -1%;margin-top: 0%; padding-left:8%;" formControlName="parent" *ngIf="templateSettingMap.dispMoreLocation">{{templateSettingMap.phMoreLocation}} {{myForm.controls.locationName.value}}</mat-slide-toggle>
	
           <!--  <mat-form-field class="col-lg-6" formGroupName="t028_SuperParentObj" *ngIf="templateSettingMap.dispSuperParent" style="padding-left: 8%;padding-right: 0px;">
            	<mat-select placeholder="{{templateSettingMap.phSuperParent}}" formControlName="locationId">
                    <mat-option *ngFor="let location of superParentLocationList" [value]="location.locationId">
                      {{location.locationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

           
           <div class="col-lg-12" *ngIf="templateSettingMap.dispReceiveStock" style="padding-left: 4%">
           <mat-checkbox color="primary" class="col-lg-2" formControlName="canReceiveStock" (change)="isChecked()">{{templateSettingMap.phReceiveStock}}</mat-checkbox>
           </div>
           <div class="col-lg-12" *ngIf="canReceiveStockFlag">
		<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispMaxItems">
				<input matInput placeholder="{{templateSettingMap.phMaxItems}}" type="number" formControlName="maximumItems" class="number">			
		</mat-form-field>
		<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispReceiveType">
            	<mat-select placeholder="{{templateSettingMap.phReceiveType}}" formControlName="type">
                    <mat-option *ngFor="let type of typeList" [value]="type">
                      {{type}}                 
                    </mat-option>
                </mat-select>
            </mat-form-field>
	</div>
	   <div class="col-lg-12" *ngIf="templateSettingMap.dispAddAddress"  style="padding-left: 4%">
	   <mat-checkbox color="primary" class="col-lg-2" formControlName="address" (change)="isAddress()">{{templateSettingMap.phAddAddress}}</mat-checkbox>
	   </div>
    
	<div class="col-lg-12" *ngIf="isAddressFlag">
					<mat-form-field class="col-lg-12" *ngIf="templateSettingMap.dispLocationAttention">
					<input matInput placeholder="{{templateSettingMap.phLocationAttention}}" formControlName="locationAttention">
					</mat-form-field>
					<mat-form-field class="col-lg-12" *ngIf="templateSettingMap.dispLocationStreetOne">
					<input matInput placeholder="{{templateSettingMap.phLocationStreetOne}}" formControlName="locationAddressLineOne">
					</mat-form-field>
					<mat-form-field class="col-lg-12" *ngIf="templateSettingMap.dispLocationStreetTwo">
					<input matInput placeholder="{{templateSettingMap.phLocationStreetTwo}}" formControlName="locationAddressLineTwo">
					</mat-form-field>
					<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationCity">
						<input matInput placeholder="{{templateSettingMap.phLocationCity}}" formControlName="locationCity">
						</mat-form-field>
					
					<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationState">
					<input matInput placeholder="{{templateSettingMap.phLocationState}}"  formControlName="locationState">
					</mat-form-field>
					<div class="col-lg-12 nopadding">
						<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationZipCode">
						<input matInput  placeholder="{{templateSettingMap.phLocationZipCode}}"  myNumberOnly formControlName="locationZipCode" (blur)="allowNumeric('locationZipCode')">
						</mat-form-field>
						<mat-form-field class="col-lg-6" formGroupName="t010locationCountryObj" *ngIf="templateSettingMap.dispLocationCountry">
					<mat-select placeholder="{{templateSettingMap.phLocationCountry}}" formControlName="countryId">
					<mat-option *ngFor="let country of countryList" [value]="country.countryId">
					{{country.countryValue}}
					</mat-option>
					</mat-select>
					</mat-form-field>
						
					</div>
					<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationPhone">
					<input matInput placeholder="{{templateSettingMap.phLocationPhone}}"  [textMask]="{mask: mask}" formControlName="locationPhone">
					</mat-form-field>
					<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationFax">
					<input matInput placeholder="{{templateSettingMap.phLocationFax}}"  myNumberOnly formControlName="locationFax" (blur)="allowNumeric('locationFax')">
					</mat-form-field>
					<mat-form-field class="col-lg-6" *ngIf="templateSettingMap.dispLocationGST">
					<input matInput placeholder="{{templateSettingMap.phLocationGST}}"  formControlName="gstNumber">
					</mat-form-field>
				</div>

	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-row">
  <app-custom-fields-wrapper [form]="myForm" [moduleName]="moduleName"></app-custom-fields-wrapper>
</div>

		<div class="col-lg-12 buttons" style="margin-top:25px;padding-left:8%;">
			<button mat-raised-button color="primary" class="mat-red" type="submit" [disabled]="!myForm.valid">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
		
</form>
</mat-card-content>
</mat-card>