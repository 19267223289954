<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
<mat-card-title >Create TestCase Link</mat-card-title>
<mat-card-content>
<form [formGroup]="testCaseLinkForm">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="col-lg-10 col-md-10 col-sm-10 col-xs-12">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{END_POINT_URL}}"   formControlName="endPointUrl"  >		
			</mat-form-field>
		</div>

		<div class="col-lg-10 col-md-10 col-sm-10 col-xs-12">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{REQUEST}}"   formControlName="request"  >		
			</mat-form-field>
		</div>
		
		<div class="col-lg-10 col-md-10 col-sm-10 col-xs-12">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{RESPONSE}}"   formControlName="response"  >		
			</mat-form-field>
		</div>

		<!-- <mat-form-field class="col-lg-10 col-md-10 col-sm-10 col-xs-12" formGroupName="t004LookupStatusId">
                  <mat-select placeholder="Link Status" formControlName="lookupId">
                    <mat-option *ngFor="let status of testCaseList" [value]="status.lookupId">
                      {{status.lookupValue}}
                    </mat-option>
                  </mat-select>
           </mat-form-field>     -->
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons" >
			<button mat-raised-button color="primary" class="mat-red" type="submit"  [disabled]="!testCaseLinkForm.valid" (click)="savetestcaseLinkMaster()">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
	</div>
	
</form>
</mat-card-content>
</mat-card>