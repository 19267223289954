import {Component, OnInit,ViewChild,Input, Output, EventEmitter, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
@Component({
  selector: 'app-importerror',
  templateUrl: './importerror.component.html'
})

export class ImportErrorComponent implements OnInit {
	dataList;
	module;
	dataSourceColumns: string[];
	dataSource = new MatTableDataSource([]);
	 constructor(public dialogRef: MatDialogRef<ImportErrorComponent>, 
        public dialog: MatDialog, public commonService: CommonService,private router: Router,
        private commonUtils: CommonUtils,@Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder) {
	 }
	 ngOnInit(): void {
           this.dataList = this.data.Obj;
           this.patchValue();
        }
        patchValue(){
			var finalList = [];
			this.dataSource.data=[];
				this.dataSourceColumns = ['userName', 'status'];
			for(var loop = 0; loop < this.dataList.length; loop++) {
				var commonObj ={'userName': '','status':''};
				commonObj['userName'] = this.dataList[loop].userName;
				commonObj['status'] = this.dataList[loop].statusText;
				finalList.push(commonObj);
      		}
      		this.dataSource.data = finalList;
      	} 


        cancelClick(): void {
  this.dialogRef.close();
}
}