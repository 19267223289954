<div class="col-lg-12 nopadding row ">
<div class="col-lg-6" *ngIf="moduleName != 'PROJECT_PLAN' && moduleName != 'VIEW_PROJECT'">
  <mat-card class="col-lg-12 createcontact mat-card-dashboard"  >
  <mat-card-title>
  <mat-icon>home</mat-icon>Status</mat-card-title>
   
<mat-card-content class="col-lg-12">
<div class="col-lg-12">
    
 <canvas baseChart *ngIf="barChartDataStatus !=null && barChartDataStatus.length>0"
            [datasets]="barChartDataStatus"
            [labels]="barChartLabelsStatus"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        
    </div>
</mat-card-content>
</mat-card>
</div>
<div class="col-lg-12"  *ngIf="moduleName === 'PROJECT_PLAN' && moduleName!='VIEW_PROJECT'">
 <mat-card class="col-lg-12 createcontact mat-card-dashboard">
  <mat-card-title>
  <mat-icon>home</mat-icon>{{QuantityOrAmount}}</mat-card-title>
   
<mat-card-content class="col-lg-12 nopadding">
  <div class="col-lg-12" >
    
 <canvas baseChart *ngIf="barChartDataQuantity !=null && barChartDataQuantity.length>0"
            [datasets]="barChartDataQuantity"
            [labels]="barChartLabelsQuantity"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>      
    </div>
</mat-card-content>
</mat-card> 
</div>

<div class="col-lg-6" *ngIf="moduleName != 'PROJECT_PLAN' && moduleName!='VIEW_PROJECT'">
 <mat-card class="col-lg-12 createcontact mat-card-dashboard">
    <mat-card-title>
        <mat-icon>home</mat-icon>{{QuantityOrAmount}}</mat-card-title>
   
<mat-card-content class="col-lg-12 nopadding">
       <div class="col-lg-12" >
           
 <canvas baseChart *ngIf="barChartDataQuantity !=null && barChartDataQuantity.length>0"
            [datasets]="barChartDataQuantity"
            [labels]="barChartLabelsQuantity"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>      
    </div>
</mat-card-content>
</mat-card> 
</div>

<div class="col-lg-6" *ngIf="moduleName === 'VIEW_SALESORDER' && moduleName!='VIEW_PROJECT'">
 <mat-card class="col-lg-12 createcontact mat-card-dashboard"  >
    <mat-card-title>
      <mat-icon>home</mat-icon>TotalAmount</mat-card-title>
   
<mat-card-content class="col-lg-12">
     <div class="col-lg-12" >
         
 <canvas baseChart *ngIf="barChartDataAmount !=null && barChartDataAmount.length>0"
            [datasets]="barChartDataAmount"
            [labels]="barChartLabelsAmount"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        
    </div>
</mat-card-content>
</mat-card> 

</div>
<div class="col-lg-12" *ngIf="moduleName === 'VIEW_PROJECT'">
 <mat-card class="col-lg-12 createcontact mat-card-dashboard"  >
    <mat-card-title>
      <mat-icon>home</mat-icon>Project</mat-card-title>
   
<mat-card-content class="col-lg-12 nopadding">
     <div class="col-lg-12" >
         
 <canvas baseChart *ngIf="barChartDataQuantity !=null && barChartDataQuantity.length>0"
            [datasets]="barChartDataQuantity"
            [labels]="barChartLabelsQuantity"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        
    </div>
</mat-card-content>
</mat-card> 

</div>

<div class="col-lg-6" *ngIf="moduleName === 'VIEW_PRODUCTION_SLIP'">
 <mat-card class="col-lg-12 createcontact mat-card-dashboard"  >
    <mat-card-title>
      <mat-icon>home</mat-icon>Total Production Volume</mat-card-title>
   
<mat-card-content class="col-lg-12">
     <div class="col-lg-12" >
         
 <canvas baseChart *ngIf="barChartProductionAmount !=null && barChartProductionAmount.length>0"
            [datasets]="barChartProductionAmount"
            [labels]="barChartLabelsProductionAmount"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="chartColors"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        
    </div>
</mat-card-content>
</mat-card> 

</div>

</div>