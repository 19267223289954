<div class="fliter-drop" style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
font-size: 12px;line-height: 18px !important;padding: 10px;" >

<div *ngFor="let lookupObj of   filterArray">
 

 
<mat-checkbox [checked]="lookupObj.statusFlag" color="primary" (change)="clickedLookupObj($event,lookupObj);">{{lookupObj.lookupValue}}</mat-checkbox>
 
</div>
<button mat-raised-button type="button" (click)="doneClicked();" style="font-size: 12px;margin:2px">Apply</button>
<button mat-raised-button type="button" (click)="defaultLookupObj();" style="font-size: 12px;">Is Default</button>
</div> 