<mat-card class="col-lg-12">
<form [formGroup]="shiftMasterForm" (ngSubmit)="saveOrUpdateShiftMaster()">
   <h3 style="text-align:center">{{ADD_SHIFT}}</h3>
  <mat-card-content>
 
  <div class="col-lg-12">

    <div class="row">
      <mat-form-field class="col-lg-12">
      <input type="text"   matInput required placeholder="{{SHIFT_NAME}}" formControlName="shiftName">
      </mat-form-field>
    </div>

    <div class="row">      
      <mat-form-field class="col-lg-6">
         <input matInput [ngxTimepicker]="startTimepicker"  required [max] ="shiftMasterForm.get('endTime').value" formControlName="startTime" placeholder="{{START_TIME}}">
         <ngx-material-timepicker #startTimepicker (click)="setTime('start')"></ngx-material-timepicker>    
    </mat-form-field>

     <mat-form-field class="col-lg-6">
         <input matInput [ngxTimepicker]="endTimepicker"  required [min] ="shiftMasterForm.get('startTime').value" formControlName="endTime" placeholder="{{END_TIME}}">
         <ngx-material-timepicker #endTimepicker (click)="setTime('end')"></ngx-material-timepicker>    
    </mat-form-field>
    </div>

    <div class="row" >
      <div class="col-lg-12 buttons">
      <button mat-raised-button color="primary" class="mat-red" [disabled]="!shiftMasterForm.valid" >{{SAVE_BUTTON}}</button>

        <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
      </div>

   </div>

  </div>

  </mat-card-content>
</form>
</mat-card>