
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {CommonService} from '../shared/common.service';
import {map,catchError } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class SettingsService {
  userForEdit: any;
  roleForEdit: any;
  wareHouseForEdit: any;
  
  constructor(private http: HttpClient, private commonService: CommonService) {

  }

  saveOrUpdateOrganizationProfile(OrganizationObj):Observable<any> {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveOrganization', OrganizationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }


  fetchOrganization(OrganizationObj):Observable<any> {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchOrganization', OrganizationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

   fetchLoginPageOrganization(OrganizationObj):Observable<any> {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchLoginPageOrganization', OrganizationObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  
  saveOrUpdateUser(userObj):Observable<any> {

    return this.http.post(this.commonService.SERVICE_URL + 'admin/createUsers', userObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchUsers(userObj) :Observable<any>{


    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchUsers', userObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchRoles(roleObj) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchRoles', roleObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchEditRoles(roleObj) :Observable<any>{
    
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchRoles', roleObj)
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  saveBulkUsers(userObj):Observable<any> {
    
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveBulkUsers', userObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchMenu(menuObj) :Observable<any>
  {

    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchMenu',menuObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));

  }
  saveOrUpdateRoles(roleObj):Observable<any> {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/createRoles', roleObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateCustomContorls(controlsObj) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveOrUpdateCustomContorls', controlsObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateCurrencies(currencyList) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveOrUpdateCurrencies', currencyList)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  saveOrUpdateTaxes(taxList) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveOrUpdateTaxes', taxList)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  deleteRoles(roleObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'admin/deleteRoles', roleObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  getLatestRates(currencyCode) :Observable<any>{
    return this.http.get(environment.LATEST_RATES_URL+currencyCode)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchRoleObj():Observable<any>
  {
    return this.http.get(this.commonService.SERVICE_URL + 'admin/fetchRoleObj')
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 saveGeneralSettings(settingsObj):Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveGeneralSettings',settingsObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  fetchGeneralSettings(settingsObj):Observable<any>
  {
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchGeneralSettings',settingsObj)
      .pipe(map(data => data[0])
      ,catchError(this.handleError));
  }
  
  private handleError(error: any) :Observable<any>{
 
    return observableThrowError(error);
  }

  fetchBatchSync(batchObj) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchBatchSync', batchObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }

  saveOrUpdateCreateTemplateSettings(createTemplateSettingObj){
    return this.http.post(this.commonService.SERVICE_URL + 'admin/saveOrUpdateCreateTemplateSettings',createTemplateSettingObj)
        .pipe(map(data => data)
        ,catchError(this.handleError));
  }
fetchCreateTemplateSettings(createTemplateSettingObj){
    return this.http.post(this.commonService.SERVICE_URL + 'admin/fetchCreateTemplateSettings',createTemplateSettingObj)
        .pipe(map(data => data)
        ,catchError(this.handleError));
  }  
  
}