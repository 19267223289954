
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {Component, OnInit, ViewChild,Inject} from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormGroup, FormControl,Validators, FormArray, FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ProjectService} from './project.service'; 
import {CommonService} from '../shared/common.service';
import {CommonUtils} from '../core/common-utils';
import {environment} from '../../environments/environment';
import {CanComponentDeactivate} from '../auth/can-deactivate-guard.service';
import {ERPPromptComponent} from '../dialogBox/prompt/erp-prompt.component';
import {AutoNumberGenerateComponent} from '../dialogBox/autogenerate/auto-num-generate.component';

@Component({
selector: 'app-createproject',
templateUrl: './createproject.component.html',
providers: [],
})
export class CreateProjectComponent extends CommonUtils implements CanComponentDeactivate,OnInit {
//placeholder from common utils
lookupList=[];
DISPLAY_NAME;
CANCEL_BUTTON;
elseBlock;
projectNumberStatus;
SAVE_BUTTON;
PROJECT_NAME;
PROJECT_DESC;
PROJECT_CODE;
PROJECT_URL;
PROJECT_COMMENT;
moduleName : string =environment.NEW_PROJECT; 
saveClick = true;
dispLabel = 'New';
disableButton = false;
step = 0;
editAccess = true;
userObj;
myAGForm: FormGroup;
disabledAutoFlag = false;
placeholderStr:string = 'Project #';
projectObj;
projectCodeDisableFlag = false;
PROJECTNUMBER_ALERT = 'Project # is';
PROJECT_EXISTS_ALERT = 'Project # already exists';

currentlySelectedMenu;
public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
contactEditId;


projectForm: FormGroup=new FormGroup({
    projectName: new FormControl(''),
    projectId : new FormControl(0),
    projectDesc: new FormControl(''),
    projectCode: new FormControl(''),
    comment: new FormControl(''),
    moduleName: new FormControl(this.moduleName),
    t004ProjectStatusObj: new FormGroup({lookupId: new FormControl(0),lookupValue: new FormControl('')}),
    url: new FormControl(''),
    displayDicardMessage:new FormControl('Y'),
    createdTs: new FormControl(),
    updatedTs: new FormControl(),
    // t001UserCreatedBy:new FormGroup({userId: new FormControl(this.commonService.loggedInUserObj.userId)}), 
    // t001UserUpdatedBy: new FormGroup({userId: new FormControl}),
    userCreatedBy:new FormControl(''), 
    userUpdatedBy: new FormControl(''),
  });
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
 
constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private commonService: CommonService,
private router: Router,private commonUtils: CommonUtils,private dialog:MatDialog,private dialogRef:MatDialogRef<CreateProjectComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
 super();
this.projectObj = data.projectObj;
  this.myAGForm = new FormGroup({
   type: new FormControl(this.moduleName)
});
 
}
ngOnInit()
{

   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
//     this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu;
           this.editAccess=this.currentlySelectedMenu.editAccess;
           
         }
       });
/** code ends above **/ 
this.fetchProjectStatus();
 this.fetchAutoGenerate();
 this.validationMethod();

  if(this.commonUtils.checkNullOrUndefined(this.projectObj) && this.projectObj.projectId != 0  ){
    this.projectCodeDisableFlag = true;
    this.projectForm.patchValue(this.projectObj);
  }else{
    this.projectCodeDisableFlag = false;
  }
}
    canDeactivate() {    
 
 if(this.projectForm.dirty && this.projectForm.get('displayDicardMessage').value==='Y'){
     const dialogRef = this.dialog.open(ERPPromptComponent, {
      width: '500px',
      data: {dialogText: environment.DISCARD_LABEL, confButton1: 'Leave',confButton2:'Stay'}
    });
     return  dialogRef.afterClosed().pipe(map(
       result => {
         if(result==='BUTTON1')
           return true;
           return false;
            }));
 }
  
  }
fetchProjectStatus(){
  this.commonService.fetchProjectStatus().
      subscribe(
      data => {

        this.lookupList = data;
      },
      error => {
        this.commonService.showERPErrorMessage(error);
      });
}
  saveOrUpdateProject(){
    this.projectForm.get('displayDicardMessage').setValue('N');
    this.projectForm.get('moduleName').setValue(this.moduleName);
    if(this.commonUtils.checkNullOrUndefined(this.projectForm.get('projectId').value) && this.projectForm.get('projectId').value != 0 )
    {
      this.projectForm.get('userUpdatedBy').setValue(this.commonService.loggedInUserObj.userId);
      this.projectForm.get('updatedTs').setValue(new Date());
    } else{
      this.projectForm.get('userCreatedBy').setValue(this.commonService.loggedInUserObj.userId);
      this.projectForm.get('createdTs').setValue(new Date());
    }
    this.commonService.showProgressBar();    
     this.projectService.saveProject(this.projectForm.value)
    .subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage,null);
        this.dialogRef.close(true);
       
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  }
  cancelClick(){
    this.dialogRef.close(true);
  }

  openSettingsDialog(): void {
  const dialogRef = this.dialog.open(AutoNumberGenerateComponent, {
    width: '850px',
    data: { dialogLabel: 'Project Code', dialogOrderType: 'New Project', moduleName: this.moduleName}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
      this.disabledAutoFlag = true;
      this.placeholderStr = 'System Generated';
      this.projectForm.get('projectCode').setValue('');
      this.onfocusoutAutoGenField();
    } else if (result === false) {
      this.disabledAutoFlag = false; 
      this.placeholderStr = 'Project #';
       this.validationMethod(); 
    }

    });
}
fetchAutoGenerate() {
   this.myAGForm.get('type').setValue(this.moduleName);
   this.commonService.fetchAutoGenerateNumber(this.myAGForm.value).subscribe(
    data => {
          if (data != null) {
     if (data.autoGenerate === true) {
        this.disabledAutoFlag = true;
        this.placeholderStr = 'System Generated';
         this.onfocusoutAutoGenField();
     }
      }
        }, error => {
         this.commonService.showERPErrorMessage(error);
       });
}

validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.projectForm);   
  }
onfocusoutAutoGenField():void{
  /*to remove the validations for the auto generated value - if it is true - starts*/
  if(this.disabledAutoFlag === true){
      this.projectForm.get('projectCode').setValidators(null);
      this.projectForm.get('projectCode').markAsTouched();
      this.projectForm.get('projectCode').updateValueAndValidity();
    }
  /*to remove the validations for the auto generated value - if it is true - ends*/
}

projectExistsCheck(): void{
  this.projectForm.get('moduleName').setValue(this.moduleName);
    this.projectNumberStatus = this.projectForm.controls['projectCode'].valueChanges.pipe(
      debounceTime(400),
      switchMap(projectNumberStatus =>
        this.projectService.fetchProjectNumberStatus(this.projectForm.value)));
  }
}