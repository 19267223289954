import { Component, OnInit, OnDestroy, ViewChild, Input,HostListener} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import {CommonService} from '../../../shared/common.service';

@Component({
  selector: 'app-view-pagination',
  templateUrl: './pagination-component.html',
  providers: [],
})

export class PaginationComponent implements OnInit, OnDestroy {

  keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;


@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

   if(this.keyCode==39 && this.ctrlKey && this.shiftKey){
    event.preventDefault();
    event.stopPropagation();
      if(this.paginator.hasNextPage())
        this.paginator.nextPage();


  }
  if(this.keyCode==37 && this.ctrlKey && this.shiftKey){
    event.preventDefault();
    event.stopPropagation();
      if(this.paginator.hasPreviousPage())
        this.paginator.previousPage();


  }
  }

	pageEvent: PageEvent;
	@Input() recordCount;
  @Input() pageNo;
   pageSizeOptions = [5, 10, 25,50, 100];
  @Input() pageSize;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   
	constructor(private commonService: CommonService,) {
   
  }

	ngOnDestroy() {}

	ngOnInit() 
  {
    this.paginator.pageSize=this.pageSize;
    this.paginator.pageIndex=this.pageNo;
		 this.commonService.refreshClickedSubject.subscribe(
                  result=>{
                  	if(result.resetFilter){
                            if(result.action==='REFRESH_GRID'){
                              // reset the pagination after hitting the refresh grid
                            		 this.paginator.pageIndex = 0;
                            		this.pageSize = this.paginator.pageSize;
                            }
                         }
         });
	}  

	pageClicked(event: PageEvent) {
		const dataObject = {action:'PAGINATION',pageIndex:event.pageIndex,pageSize: event.pageSize};
		this.commonService.paginationChangeEvent.next(dataObject);
		// this.itemService.filterChangeObj.next(dataArray);
	}
  
}