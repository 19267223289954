<mat-card class="col-lg-12" >
	<mat-card-title ><mat-icon>home</mat-icon><label class="historyLabel">{{DATABASE_DETAILS}}</label></mat-card-title>	
	<form [formGroup]="databaseDetailsForm">
	<mat-card-content >
		<div class="col-lg-12">
			<mat-form-field >
        <span matPrefix style="color: #1656ca;">jdbc:mysql://</span>
       			<input   matInput placeholder="{{IP}}" formControlName="ip" >
              <span matSuffix style="color: #1656ca;">:3306/</span>
     		</mat-form-field>
         <span matPrefix style="color: #1656ca;">jdbc:mysql://{{databaseDetailsForm.controls.ip.value}}:3306/</span>
           
      	</div>
     	<div class="col-lg-12" >
         	<mat-form-field >
       			<input  matInput placeholder="{{USER_NAME}}" formControlName="userName" >
     		</mat-form-field>
       	</div>
       	<div class="col-lg-12" >
         	<mat-form-field >
       			<input type='password' matInput placeholder="{{PASSWORD_PLACEHOLDER}}" formControlName="password" >
     		</mat-form-field>
       	</div>
		
    <div class="col-lg-12">
		 <mat-form-field class="example-full-width"  >
          <mat-select placeholder="{{SELECT_ENVIRONMENT}}" formControlName="environmentName">
            <mat-option  
             *ngFor="let environmentObj of environments" [value]="environmentObj" >
              {{environmentObj}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>

		<div class="col-lg-12 buttons">
     
     		<button mat-raised-button color="primary" (click)="saveOrUpdateDatabaseDetails()" class="buttonWidth">{{EXECUTE_BUTTON}}</button>  
     		<button mat-raised-button color="primary"  (click)="closeDialog()" class="buttonWidth">{{CANCEL_BUTTON}}</button> 
     	</div> 
	</mat-card-content>
</form>
</mat-card> 