 

 <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11  nopadding" id="mainheader">
  <div class="row" style="margin-left: 10px !important">
 	 <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 main-head" style="padding-left: 0px !important; padding-right: 0px !important;"> 
    <div class="logo" style="padding-top: 10px; font-size: 18px;">
     <span  class="count text-secondary">Neuzen HR</span>
    </div>

  <div class="hammer" id="mobile-menu-toggler" >  
              <div class="bar top"></div>
              <div class="bar middle"></div>
              <div class="bar bottom"></div>
            </div >




  </div>
   <div class="col-lg-1 col-md-2 col-sm-2 col-xs-2 settingstab nopadding">
 		<div class="col-lg-6 col-md-6 col-sm-6  col-xs-6 " >
      <button mat-button type="button"   matSuffix mat-icon-button aria-label="Clear" (click)="navigateScreen('Backward')">
 			<mat-icon class="mat_icon_color" >arrow_back</mat-icon>	
    </button>
 		</div>
		<div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
 			<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="navigateScreen('Forward')">
    <mat-icon class="mat_icon_color">arrow_forward</mat-icon>
    </button>
 		</div>
	</div>
  <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 searchwidth searchmod" >
  </div>
<!-- Comented for Temporaraly -->
  <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 searchwidth searchmod" [formGroup]="myForm">
      <input class="col-lg-10 col-md-12 col-sm-12 col-xs-12 headersearch" formControlName="searchName" placeholder="{{SEARCH_BUTTON}}" (keypress)="searchClicked($event)">
  </div>  --> 

<!-- below code is for laptop -->

	<div  class="col-lg-5 col-md-5 col-sm-5 col-xs-5 settingstab"> 
    <!-- <mat-icon class=" col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" (click)="createTicket()" matTooltip="Create Ticket">assignment</mat-icon> -->
	  <mat-icon class=" col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" (click)="sendFeedbackOrIssues()" matTooltip="Send Feedback / Issues">feedback</mat-icon>  
    <mat-icon class=" col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color"  [routerLink]="['/settings']" *ngIf="displaySettings" matTooltip="Settings">settings</mat-icon> 
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1" appDropdown>
	  <mat-icon class=" col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color"  #help style="padding-left: 0px;" (click)="callDropDown(help,'help')">help</mat-icon> 
     </div>  

      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1" appDropdown>
	     <mat-icon  #notification class="col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" (click)="callDropDown(notification,'notifications')" *ngIf="enableNotification==true"
	    matBadge="{{notificationCount}}" matBadgeColor="warn" style="padding:0px">notifications</mat-icon>  
        <mat-icon #notification class="col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" style="padding:0px" *ngIf="enableNotification==false">notifications</mat-icon>  
      </div>     
 
	      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 " appDropdown>
	       
         <mat-icon  #accCircle class=" mat_icon_color"  
         matTooltip=" Organization:{{loggedInUserObj.t000Organization.portalName}} &#13; 
	    Username: {{loggedInUserObj.userName}} " [matTooltipClass]="'my-tooltip'"  (click)="callDropDown(accCircle,'account_circle')">account_circle</mat-icon> 
     </div> 

 <mat-icon class="col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" (click)="signOut()" 
	    matTooltip=" Sign out of {{loggedInUserObj.t000Organization.portalName}}">power_settings_new</mat-icon> 

</div>

	</div>

<!-- below code is for mobile -->

<div  class="col-lg-5 col-md-6 col-sm-6 col-xs-2 settingstab-mobile"  > 
	     
	   <mat-icon class=" col-xs-12 vertIcon mat_icon_color nopadding" (click)="showMobileIcons()" >more_vert</mat-icon>


  <div class="mobile-icons" *ngIf="mobileIconsFlag===true" >

    <!--  <mat-icon class=" col-xs-1 mat_icon_color" (click)="sendFeedbackOrIssues()" matTooltip="Send Feedback / Issues">feedback</mat-icon>  -->
     
     <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 btn-group"  appDropdown>
      <mat-icon #help class=" col-xs-1 mat_icon_color"(click)="callDropDown(help,'help')" style="padding-left: 0px;">help</mat-icon> 
      <div class="dropdown-menu mobileHelp" style="min-width: 148px; padding:10px;" *ngIf="dropDownFlag===true">
       <div style="padding-left:10px">
         <div class="col-lg-12 nopadding" style="text-align:center">
         <img src="{{imageSrc}}erpassets/esales_suite_erp.png" style="width:80%" alt=""></div>
        <div class="col-lg-12 nopadding">
           <div style=" font-size: 9px;text-align:  right;" appDropdown>Beta - Build #: 0.51
           <mat-icon matTooltip="Online Mode" (click)="offlineModeAlert()">cloud_queue</mat-icon>
           </div>
        </div>
          </div>  
            </div>
     </div>

    <div class="col-xs-1 btn-group"  appDropdown >
       <mat-icon #notification class="col-lg-1 col-md-1 col-sm-1 col-xs-1 " (click)="callDropDown(notification,'notifications')" *ngIf="enableNotification==true"
      matBadge="{{notificationCount}}" matBadgeColor="warn" style="padding:0px">notifications</mat-icon>  
        <mat-icon class="col-lg-1 col-md-1 col-sm-1 col-xs-1 mat_icon_color" style="padding:0px" *ngIf="enableNotification==false">notifications</mat-icon>  
        <div class="dropdown-menu pull-left mobileNotification" style="min-width: 148px;" *ngIf="notificationFlag===true">
          <div style="padding-left:10px;">
         <div class="col-lg-2" >
                <div class="col-lg-1 notification"  style="width:150px;"*ngFor="let notificationItem of notificationDetailList" > 
                    {{notificationItem.notesDescription}}
                    <mat-divider></mat-divider>
                </div>
         </div>
          </div>
        </div>
      </div> 
	      
      <div class="col-xs-1 btn-group"  appDropdown >
	       
         <mat-icon #accCircle class=" mat_icon_color" (click)="callDropDown(accCircle,'account_circle')" matTooltip=" Organization:{{loggedInUserObj.t000Organization.portalName}}\n
	       Username: {{loggedInUserObj.userName}} ">account_circle</mat-icon> 
     
       <div class="dropdown-menu pull-left mobileDropDown" style="min-width: 148px;" *ngIf="dropDownFlag===true">
      
       <div style="padding-left:10px">
         <label class="fltheader"></label>
         <a [routerLink]="['/settings/orgProfile']">
          <p>Organization Profile</p>
         </a>

         <a>
          <p> Storage Used {{storageUsed}}</p>
         </a>
         
          <a>
         	<p>Change Password</p>
         </a>
      </div>
      </div>
      </div> 

      <mat-icon class="col-xs-1  mat_icon_color" (click)="signOut()" 
      matTooltip=" Sign out of {{loggedInUserObj.t000Organization.portalName}}">power_settings_new</mat-icon> 
	    
    </div>   
    </div>  
            
     
 


	

</div> 
  
 
