import {Component, OnInit, Input, Output, EventEmitter,Inject} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../../../core/components/custom-token';
import {ErpOverlayRef} from '../../../core/components/ErpOverlayRef';
import {CommonUtils} from '../../common-utils';
import { CommonService } from '../../../shared/common.service';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-view-quick-info',
  templateUrl: './view-quick-info.component.html',
  providers: []
})

export class ViewQuickInfoComponent extends CommonUtils implements OnInit {

 @Input() moduleName: string;
  @Output() printButtonClicked = new EventEmitter<any>();
 @Input() selectedRow: any;
 @Input() quickInfoValues: any;
 @Input() quickInfoLabels: any;
@Input() convtToBill:boolean=true;
 @Input() convertInvoice:boolean;
 @Input() statusToConvert:boolean; 
 firstStep;secondStep:boolean = false;
 CONVERT_TO_BILL;
 CONVERT_TO_INVOICE;
 historyFlag:boolean=false;
 employeeHierarchy = [];
 employeeName;
 taskHierarchyList = [];
 taskName;
  constructor(private commonUtils: CommonUtils, public dialogRef: ErpOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any, private commonService: CommonService) {
  	super();
    if(this.commonUtils.checkNullOrUndefined(data.moduleName) && data.moduleName === 'VIEW_EMPLOYEE_HIERARCHY' ){
        this.moduleName=data.moduleName;
        this.employeeName = data.empName;
        if(this.commonUtils.checkNullOrUndefined(data.employeeId) && data.employeeId > 0){
              this.commonService.fetchEmployeeHierarchy({employeeId: data.employeeId}).subscribe(
          result => {
             if(this.commonUtils.checkNullOrUndefined(result) && this.commonUtils.checkNullOrUndefined(result.searchName)){
              this.employeeHierarchy = result.searchName.split("::");
            }
          },
          error => {

          }
        );
      }
    }else{
      this.moduleName=data.moduleName;
      this.selectedRow=data.selectedRow;
      this.quickInfoLabels=data.quickInfoLabels;
      this.quickInfoValues=data.quickInfoValues;
      this.convtToBill=data.convtToBill;
      this.convertInvoice=data.convertInvoice;
    }
    if(this.commonUtils.checkNullOrUndefined(data.moduleName) && data.moduleName === 'VIEW_TASK_HIERARCHY' ){
      this.moduleName=data.moduleName;
      this.taskName = data.taskName;
      if(this.commonUtils.checkNullOrUndefined(data.taskId) && data.taskId > 0){
            this.commonService.fetchTaskHierarchy({taskId: data.taskId}).subscribe(
        result => {
          if (this.commonUtils.checkNullOrUndefined(result)){
            let taskObj = new FormGroup({
              taskId: new FormControl(),
              taskName: new FormControl()
            })
            for (var loop = 0; loop < result.length; loop++){
              taskObj.get('taskId').setValue(result[loop].taskId);
              taskObj.get('taskName').setValue(result[loop].taskName);
              this.taskHierarchyList.push(taskObj.value);
            }
          }             
        },
        error => {
        }
      );
    }
  }else{
    this.moduleName=data.moduleName;
    this.selectedRow=data.selectedRow;
    this.quickInfoLabels=data.quickInfoLabels;
    this.quickInfoValues=data.quickInfoValues;
    this.convtToBill=data.convtToBill;
    this.convertInvoice=data.convertInvoice;
  }


  }
	
  ngOnInit() {
    if(this.moduleName=='VIEW_PO' || this.moduleName=='VIEW_BILL' || this.moduleName=='VIEW_R_BILL' || this.moduleName=='VIEW_PAYEMNT_MADE' || this.moduleName=='VIEW_EXPENSE'
        || this.moduleName=='VIEW_VENDOR_CREDIT' || this.moduleName=='VIEW_ESTIMATE' || this.moduleName=='VIEW_SALESORDER' || this.moduleName=='VIEW_INVOICE' || this.moduleName=='VIEW_R_INVOICE' || this.moduleName=='VIEW_CREDIT_NOTES')
      this.historyFlag=true;
    else
      this.historyFlag=false;
  } 

	convertToBill() {
   		this.printButtonClicked.emit('CONVERT_TO_BILL');
       this.dialogRef.dialogClose({action:'CONVERT_TO_BILL',selectedObject:this.selectedRow});
 	}
convertToInvoice() {
   this.printButtonClicked.emit('INVOICE');
   this.dialogRef.dialogClose({action:'INVOICE'});
 }
 convertToSalesOrder():void{
  this.printButtonClicked.emit('ESTIMATE_TO_SALES_ORDER');
  this.dialogRef.dialogClose({action:'ESTIMATE_TO_SALES_ORDER'});
  }
  viewHistory():void{
    this.selectedRow.moduleName=this.moduleName;
    this.printButtonClicked.emit({selectedRow:this.selectedRow,action:'VIEW_HISTORY'});
    this.dialogRef.dialogClose({selectedRow:this.selectedRow,action:'VIEW_HISTORY'});
  }

setMarginLeft(index){
  let indexChanged = 10 * index;
  let indexChangedStr: string = indexChanged+"px";
    let styles = {
      'margin-left': indexChangedStr,
      };
  return styles;
 }

}