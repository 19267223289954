<!-- <div style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);">
<div *ngFor="let columnName of inputObj">

<mat-checkbox (click)="clickedColumn(columnName);">{{columnName}}</mat-checkbox>
</div>
</div> --> 
<div class="edit-popup" style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);  padding: 5px;text-align: center;">
<form [formGroup]="approveLeaveForm">
<div>
	<div >
    	<mat-form-field >
    		<input  matInput formControlName= 'clApproved' placeholder="{{CL_APPROVED}}">
    	</mat-form-field>
    </div>
    <div>
    	<mat-form-field>
    		<input  matInput formControlName= 'slApproved' placeholder="{{SL_APPROVED}}">
    	</mat-form-field>
    </div>
    <div>
    	<mat-form-field >
    		<input  matInput formControlName= 'compOffApproved' placeholder="{{COMP_OFF_APPROVED}}">
    	</mat-form-field>
    </div>
    <div>
    	<mat-form-field>
    		<input  matInput formControlName= 'lop' placeholder="{{LOP}}"  >
    	</mat-form-field>
    </div>
    <div style="width: 100% !important">
    	<button style="width:40% !important;min-width: 72px !important;" type="button" mat-raised-button color="primary" (click)='saveApproveLeaveForm("approve")'>{{APPROVE_BUTTON}}</button>
    	<button style="width:40% !important;min-width: 72px !important;" type="button" mat-raised-button color="primary" (click)='saveApproveLeaveForm("reject")'>{{REJECT_BUTTON}}</button>
    <!-- 	<button type='button' mat-raised-button color="primary" >{{CANCEL_BUTTON}}</button> -->
    </div>
</div>
</form>
</div>