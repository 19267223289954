  
<mat-card class="col-lg-12 createcontact  mb-mr-0 mb-pd-0">
  	<mat-card-title>
  		<mat-icon>home</mat-icon>{{dispLabel}} Project</mat-card-title>
   
  <form [formGroup]="projectForm" (ngSubmit)="saveOrUpdateProject()">
   
  	<mat-card-content >
        <div class="col-lg-12 mb-mr-0 mb-pd-0">
		    
		    <mat-form-field  class="col-lg-12">
		    <input type="text"   matInput  placeholder="{{PROJECT_NAME}}" formControlName="projectName">
		    <button mat-button  *ngIf="projectForm.controls['projectName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(projectForm,'projectName')" >
                    <mat-icon>close</mat-icon>
                </button>
		    </mat-form-field>
		</div>

		<div class="col-lg-12  mb-mr-0 mb-pd-0">
			<mat-form-field class="col-lg-12">
		    <input type="text"   matInput  placeholder="{{PROJECT_DESC}}" formControlName="projectDesc" >
		    <button mat-button  *ngIf="projectForm.controls['projectDesc'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(projectForm,'projectDesc')" >
                    <mat-icon>close</mat-icon>
                </button>
		    </mat-form-field>
		</div>
 		<div class="col-lg-12  mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-12">
		    <input matInput  placeholder="{{placeholderStr}}" formControlName="projectCode" (focusout)="onfocusoutAutoGenField()" readonly="{{disabledAutoFlag}}" (keypress)="projectExistsCheck()" (paste)="projectExistsCheck()">
		     <button type="button" mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="openSettingsDialog()" [disabled]="projectCodeDisableFlag">
                  <div  style="margin-right: 8%;" ><mat-icon>settings</mat-icon></div>
                </button>
                <mat-error *ngIf="((projectForm.controls['projectCode'].hasError('required')) && (!disabledAutoFlag)) else elseBlock">{{PROJECTNUMBER_ALERT}} <strong>{{REQUIRED}}</strong> </mat-error> 
                <mat-hint #elseBlock *ngIf="(projectNumberStatus | async)?.length>0" style="color:crimson">  {{PROJECT_EXISTS_ALERT}}
                </mat-hint>
		    </mat-form-field>
		</div>
		<div class="col-lg-12  mb-mr-0 mb-pd-0">
		    <mat-form-field  class="col-lg-12" formGroupName="t004ProjectStatusObj">
				<mat-select placeholder="{{STATUS}}" formControlName="lookupId">
					<mat-option>None</mat-option>
					<mat-option *ngFor="let lookupObj of lookupList" [value]="lookupObj.lookupId">
					    {{ lookupObj.lookupValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-lg-12  mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-12">
		    <input type="text" matInput  placeholder="{{PROJECT_URL}}" formControlName="url">
		     <button mat-button  *ngIf="projectForm.controls['url'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(projectForm,'url')" >
                    <mat-icon>close</mat-icon>
                </button>
		    </mat-form-field>
		</div>
		<div class="col-lg-12  mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-12">
		    <input type="text" matInput  placeholder="{{PROJECT_COMMENT}}" formControlName="comment">
		     <button mat-button  *ngIf="projectForm.controls['comment'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(projectForm,'comment')" >
                    <mat-icon>close</mat-icon>
                </button>
		    </mat-form-field>
		</div>
		    
		<div class="col-lg-12 alignCenter buttons">
		    <button mat-raised-button color="primary" class="mat-red" *ngIf="editAccess" [disabled]="(((projectNumberStatus | async)?.length>0)||(!projectForm.valid)||(disableButton))">{{SAVE_BUTTON}}</button>
		    <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
  
	</mat-card-content>

 </form>
</mat-card>
 


 