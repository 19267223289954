import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { CustomFieldService } from '../custom-fields/customfields.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ERPFormBase } from './base/erp-form-base';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../common-utils';
@Component({
  selector: 'app-custom-fields-wrapper',
  templateUrl: './custom-fields-wrapper.component.html'
})

export class CustomFieldWrapperComponent implements OnInit {
 customFieldElements: ERPFormBase<any>[] = [];
  @Input() form: FormGroup;
  @Input() moduleName: string;
  @Input() divClass: string;
  @Output() customElementsEmitter =new EventEmitter<any>();
constructor(private customFieldService: CustomFieldService,private commonService:CommonService,
  private commonUtils:CommonUtils) {}

ngOnInit() {
   if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
     this.pageLoad(this.moduleName);
     this.divClass=" nopadding";
   }
 }

 pageLoad(moduleName)
 {
     const body = new FormData();
     body.append('moduleName', moduleName);
     body.append('status','true');
    let promise=this.customFieldService.fetchCustomFieldsControl(body);    
    promise.then(data=>
        {
      this.customFieldElements=data;        
      this.customElementsEmitter.emit({moduleName:this.moduleName,length:data.length});
      // DO NOT DELETE THE BELOW LINE IN CODE REVIEW
    //   this.dynamicFormGroup = this.qcs.toFormGroup(this.questions);
 
 let formControl: FormControl;
       this.customFieldElements.forEach(customFieldElementObj=>{
         if(this.form.get(customFieldElementObj.key) === null){
      formControl = customFieldElementObj.required ? new FormControl(customFieldElementObj.value || '', Validators.required)
                                              : new FormControl(customFieldElementObj.value || '');
         }else{
       formControl = customFieldElementObj.required ? new FormControl(this.form.get(customFieldElementObj.key) || '', Validators.required)
                                              : new FormControl(customFieldElementObj.value || '');
         }

      this.form.addControl(customFieldElementObj.key, formControl);
        // DO NOT DELETE THE BELOW LINE IN CODE REVIEW
      // qcs.toGroup must be called here if you want to add group
    });

  // DO NOT DELETE THE BELOW LINE IN CODE REVIEW
     //  this.invoiceForm.addControl('dynamicFormGroup',this.dynamicFormGroup);
        
        }
     ); 


    

 }

}
