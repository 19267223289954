import {map,switchMap, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup,FormArray, FormBuilder, Validators, FormControlName, AbstractControl} from '@angular/forms';
import { RecruitmentService } from '../../recruitment-service';
import {CommonService} from '../../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../../core/common-utils';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-interviewallotment',
    templateUrl: './interviewallotment.component.html'
  })
  
export class InterviewAllotmentComponent extends CommonUtils implements OnInit {
  myForm: FormGroup;
  employeeForm: FormGroup;
  candidateObj;
  interviewAppointmentList;
  jobList;
  candidateStatusList;
  candidateStatus;
  interviewAllotmentList=[];
  displayflag:boolean = false;
  remarksflag:boolean = false;
  disableButton:boolean = false;
  constructor(private recruitService: RecruitmentService, private router: Router, public dialog: MatDialog,private formBuilder: FormBuilder,
    private commonService: CommonService, public commonUtils: CommonUtils, private route: ActivatedRoute,
    private dialogRef:MatDialogRef<InterviewAllotmentComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    super(); 

    this.myForm = new FormGroup({
      candidateId: new FormControl(0),
      candidateCode: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      jobApplication: new FormGroup({
        jobId: new FormControl(0),
        jobCode: new FormControl(''),
        title: new FormControl('')
      }),
      email: new FormControl(''),
      secondaryEmail: new FormControl(''),
      mobile: new FormControl(''),
      secondaryMobile: new FormControl(''),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zipcode: new FormControl(''),
      country: new FormGroup({
          countryId: new FormControl('')
        }),
      t004_CandidateStatusObj:new FormGroup({
        lookupId:new FormControl(0),
        lookupCode:new FormControl(''),
        lookupValue:new FormControl('')
      }),
      t843_InterviewAppointmentObj:new FormGroup({
       appointmentId:new FormControl(0),
       interviewerEmail:new FormControl(''),
       interviewName:new FormControl(''),
       startTime:new FormControl(''),
       location:new FormControl(''),
       interviewDate:new FormControl(''),
       endTime:new FormControl('')
      }),
      bulkAction:new FormControl(''),
        remarks: new FormControl(''),
        t001UserCreatedBy: new FormGroup({userId: new FormControl(),t000Organization:new FormGroup({organizationId:new FormControl()})}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(),t000Organization:new FormGroup({organizationId:new FormControl()})}),
    });

    this.candidateObj=data ;
  }
  ngOnInit(){
    this.fetchJobs();
    this.fetchInterviewApointment();
    this.fetchFromLookup('VIEW_CANDIDATE_STATUS');  
    if(this.commonUtils.checkNullOrUndefined(this.data)){
        this.candidateObj=this.data ;
       if(this.commonUtils.checkNullOrUndefined(this.candidateObj) && this.candidateObj.candidateId !=0){
      this.myForm.patchValue(this.candidateObj);
      this.myForm.get('t004_CandidateStatusObj').patchValue(this.candidateObj.t004_CandidateStatusObj);
      if(this.commonUtils.checkNullOrUndefined(this.myForm.get('t004_CandidateStatusObj.lookupValue').value)){
        this.myForm.get('t004_CandidateStatusObj').patchValue(this.myForm.get('t004_CandidateStatusObj').value);
        this.myForm.get('bulkAction').setValue(this.myForm.get('t004_CandidateStatusObj.lookupValue').value);
         this.candidateStatus=this.myForm.get('t004_CandidateStatusObj.lookupValue').value;
          if( this.candidateStatus === 'On Hold' ||  this.candidateStatus === 'Hired' ||  this.candidateStatus === 'Rejected'){
            this.displayflag = false;
            this.remarksflag = true;
          }else  if(this.candidateStatus === 'First Round - Online Technical (Written)'
            || this.candidateStatus === 'Second Round - Technical'|| this.candidateStatus === 'Third Round - Technical and Managerial'
            || this.candidateStatus === 'HR Round - Finalisation'){ 
             this.displayflag = true;
             this.remarksflag = false;
            if(this.commonUtils.checkNullOrUndefined(this.candidateObj.t843_InterviewAppointmentObj) &&
              this.candidateObj.t843_InterviewAppointmentObj.appointmentId!=0){
               this.onChangeOfInterviewAppointment(this.candidateObj.t843_InterviewAppointmentObj);
               this.myForm.get('t843_InterviewAppointmentObj').patchValue(this.candidateObj.t843_InterviewAppointmentObj);
            }else{
              this.myForm.get('t843_InterviewAppointmentObj.appointmentId').setValidators([Validators.required]);
               this.myForm.get('t843_InterviewAppointmentObj.appointmentId').markAsTouched();
               this.myForm.get('t843_InterviewAppointmentObj.appointmentId').updateValueAndValidity();
            }
          }
          else{
            this.displayflag = false;
            this.remarksflag = false;
          }
      }
      

      }
    }   
  }
  cancelClick(): void {
    this.dialogRef.close(true);
  }
  saveOrUpdateAppointmentForcandidate(){
    this.disableButton=true;
   let userObj = this.commonService.loggedInUserObj;
      this.myForm.get('t001UserCreatedBy').patchValue(userObj)
      this.myForm.get('t001UserUpdatedBy').patchValue(userObj);
    this.interviewAllotmentList.push(this.myForm.value);
    this.recruitService.bulkUpdateActionForCandidateStatus(this.interviewAllotmentList).subscribe(
      data => 
      {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage('Selected candidate are updated to '+ this.candidateStatus);
      this.cancelClick()
      },
      error => 
      {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
    });
  }
  onChangeOfStatus(lookupObj,lookupValue){
     this.candidateStatus=lookupValue;
    this.myForm.get('t004_CandidateStatusObj').patchValue(lookupObj);
     this.myForm.get('bulkAction').setValue(lookupValue);
    if(lookupValue === 'First Round - Online Technical (Written)'
      || lookupValue === 'Second Round - Technical'|| lookupValue === 'Third Round - Technical and Managerial'
      || lookupValue === 'HR Round - Finalisation'){         
       this.remarksflag=false;
       this.fetchInterviewAppointmentBasedOnStatus(lookupValue);
       this.myForm.get('t843_InterviewAppointmentObj.appointmentId').setValidators([Validators.required]);
       this.myForm.get('t843_InterviewAppointmentObj.appointmentId').markAsTouched();
       this.myForm.get('t843_InterviewAppointmentObj.appointmentId').updateValueAndValidity();
    }else if(lookupValue === 'On Hold' || lookupValue === 'Hired' || lookupValue === 'Rejected'){
      this.displayflag = false;
      this.remarksflag = true;
    }
    else{
       this.displayflag = false;
      this.remarksflag = false;
    }
  }

  fetchJobs(){
     let jobForm = new FormGroup({
        
       t004JobStatusObj: new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl('JOB_STATUS'),
         lookupValue: new FormControl('ACTIVE'),
       })
     });
       this.recruitService.fetchJobs(jobForm.value).subscribe(
         data=>{ 
           
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.jobList = data;
         }
       }, error =>{

       });
  }
  fetchInterviewAppointmentBasedOnStatus(candidateStatus){
    this.disableButton = true;
     let interviewAppointmentForm = new FormGroup({        
       interviewStatus: new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl('VIEW_APPOINTMENT'),
         lookupValue: new FormControl('ACTIVE'),
       }),
       interviewSlotStatus: new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl('INTERVIEW_SLOT_TYPE'),
         lookupValue: new FormControl(''),
       })
     });
      if(candidateStatus === 'First Round - Online Technical (Written)'){
        interviewAppointmentForm.get('interviewSlotStatus.lookupValue').setValue('First Round - Online Technical (Written)');
      } else if(candidateStatus === 'Second Round - Technical'){
         interviewAppointmentForm.get('interviewSlotStatus.lookupValue').setValue('Second Round - Technical');
      }else if(candidateStatus === 'Third Round - Technical and Managerial'){
         interviewAppointmentForm.get('interviewSlotStatus.lookupValue').setValue('Third Round - Technical and Managerial');
      }else if(candidateStatus === 'HR Round - Finalisation'){
         interviewAppointmentForm.get('interviewSlotStatus.lookupValue').setValue('HR Round - Finalisation');
      }
       this.recruitService.fetchAppointment(interviewAppointmentForm.value).subscribe(
         data=>{ 
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
              this.displayflag = true;                    
             this.interviewAppointmentList = data;
         }else{
            this.displayflag = false;
            this.disableButton = false;
            this.commonService.showERPMessage('Create interview appointment for this '+ this.candidateStatus +' round');
         }
       }, error =>{
         this.displayflag = false;
       });
  }
  fetchFromLookup(lookupCode): void {
     let lookupFetchForm = new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl(lookupCode),
         lookupValue: new FormControl(''),
     });
    this.commonService.fetchFromLookup(lookupFetchForm.value).subscribe(
    data => {
      if (this.commonUtils.checkNullOrUndefined(data)){
      if (lookupCode === 'VIEW_CANDIDATE_STATUS')
      this.candidateStatusList= data;
      
      }
    }, error => {
      this.commonService.showERPErrorMessage(error);
    });
  }
  onChangeOfInterviewAppointment(appointmentObj){
    
    if(this.commonUtils.checkNullOrUndefined(appointmentObj.interviewType)
      && this.commonUtils.checkNullOrUndefined(appointmentObj.interviewType.lookupValue)
      && (appointmentObj.interviewType.lookupValue === 'Telephonic' || appointmentObj.interviewType.lookupValue === 'Online')){
      this.remarksflag = true;
    }
    this.disableButton=false;
    this.myForm.get('t843_InterviewAppointmentObj').patchValue(appointmentObj);
    this.myForm.get('t843_InterviewAppointmentObj.appointmentId').setValidators(null);
    this.myForm.get('t843_InterviewAppointmentObj.appointmentId').markAsTouched();
    this.myForm.get('t843_InterviewAppointmentObj.appointmentId').updateValueAndValidity();
  }
  fetchInterviewApointment(){
     let interviewAppointmentForm = new FormGroup({        
       interviewStatus: new FormGroup({
         lookupId: new FormControl(),
         lookupCode: new FormControl('VIEW_APPOINTMENT'),
         lookupValue: new FormControl('ACTIVE'),
       }),
     });     
       this.recruitService.fetchAppointment(interviewAppointmentForm.value).subscribe(
         data=>{ 
         if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {                      
             this.interviewAppointmentList = data;
         }
       }, error =>{
       });
  }
}