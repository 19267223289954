import {Injectable,Injector,ComponentRef} from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {Overlay} from '@angular/cdk/overlay';
 
import { ComponentPortal ,PortalInjector} from '@angular/cdk/portal';
import { OverlayConfig, OverlayRef, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {ErpOverlayRef} from './ErpOverlayRef';
import { FILE_PREVIEW_DIALOG_DATA } from './custom-token';
// Each property can be overridden by the consumer
interface ErpDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?:any;
  width?:any;
}

const DEFAULT_CONFIG: ErpDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
   data:[]
}


@Injectable()
export class ErpPopupService { 

  // Inject overlay service
  constructor(private injector: Injector,private overlay: Overlay) { }

  open<T>(elementRef:any,config: ErpDialogConfig = {},componentOrTemplateRef: ComponentType<T>) {
      const dialogConfig = { ...DEFAULT_CONFIG, ...config };
      const overlayRef = this.createOverlay(dialogConfig,elementRef);
     
    // Create ComponentPortal that can be attached to a PortalHost
    //   const filePreviewPortal = new ComponentPortal(ErpOverlayComponent);
       const dialogRef = new ErpOverlayRef(overlayRef);
       const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef,componentOrTemplateRef);
       overlayRef.backdropClick().subscribe(_ => dialogRef.close());
       
      return dialogRef;
  }

  private getOverlayConfig(config: ErpDialogConfig,elementRef:any): OverlayConfig {
    const positionStrategy =   this.overlay.position().connectedTo(elementRef,{ originX: 'start', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' })
    .withFallbackPosition({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'center' })
     .withFallbackPosition({ originX: 'center', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' })
  

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy:positionStrategy,
      width:config.width,
    });

    return overlayConfig;
  } 

  private createOverlay(config: ErpDialogConfig,elementRef:any) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config,elementRef);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }


 private attachDialogContainer<T>(overlayRef: OverlayRef, config: ErpDialogConfig, dialogRef: ErpOverlayRef,componentOrTemplateRef: ComponentType<T>) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(componentOrTemplateRef, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

private createInjector(config: ErpDialogConfig, dialogRef: ErpOverlayRef): PortalInjector {
     const injectionTokens = new WeakMap();

    injectionTokens.set(ErpOverlayRef, dialogRef);
    injectionTokens.set(FILE_PREVIEW_DIALOG_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }


}