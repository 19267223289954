/**Author -esales -9014  Rajesh Subramanian **/
import {Injectable, Injector,HostListener,EventEmitter,HostBinding,ViewChild,ElementRef,ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {CommonService} from './shared/common.service';
import {Subscription,Observable} from 'rxjs/Rx';
import { OverlayContainer} from '@angular/cdk/overlay';
import {environment} from '../environments/environment';
import { trigger, state, animate, transition, style } from '@angular/animations';
//import {SwPush, SwUpdate} from "@angular/service-worker";
import {CommonUtils} from './core/common-utils';
import {Store} from '@ngrx/store';


import * as fromStore from './store'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html' ,
  animations: [
   trigger('visibilityChanged', [
  state('shown' , style({ opacity: 1 })), 
  state('hidden', style({ opacity: 0 })),
  transition('shown => hidden', animate('1000ms ease-out')),
    transition('hidden => shown', animate('1000ms ease-in')),
  ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {
readonly VAPID_PUBLIC_KEY = "BCHdKSN3Te0cAoWGQohBWjG_rMRgN2UpqriVhkIr9LOYKjy7mBFCraBaFEGvT0vPtjoKnYyQ7Ssx8Dsk1xhqHMk";
errorimageSourcePath=environment.ESALESUITE_LOGO;
  @HostBinding('class') componentCssClass;

@HostListener('window:beforeunload', ['$event'])
beforeunloadHandler(event) {
   return event.returnValue='Do you want to close the tab ? ';
}
loggedInUserIp;
imageSrc;
showBtnTrue:boolean=false;
  keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
  storageUsed:number;
storagePercent:number;
loggedInUserObj;
    htmlElementMap:Map<string,string>=new Map();
    currentFocus='INITIAL';
   // serverPath=environment.SERVICE_URL+'erpassets/gcp.png';
    displaySettings=false;
    @ViewChild('sidenav', { static: true })   sideNav: any;
public myFocusTriggeringEventEmitter = new EventEmitter<string>();
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   // event.preventDefault();
   // event.stopPropagation();
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;
//13=enter key
    /*if(this.keyCode==13){
        event.preventDefault();
     event.stopPropagation();
      if(this.ctrlKey){

      }else{
    
       this.myFocusTriggeringEventEmitter.emit(this.htmlElementMap[this.currentFocus]);
       this.currentFocus=this.htmlElementMap[this.currentFocus];
      
    }
  }

  else*/ if (this.keyCode==77 && this.ctrlKey){
      event.preventDefault();
     event.stopPropagation();
  var sidenavobj=document.getElementById("buttonid") as HTMLButtonElement ;
     sidenavobj.click();
  }
  }
  displayMenu="";
   
  subscriptionList: Array<Subscription> = [];
 showHeader;
//private swPush: SwPush;
  constructor(private authService: AuthService, private commonService: CommonService,
   private injector: Injector,private router:Router,public overlayContainer: OverlayContainer,
   public commonUtils:CommonUtils,private cd: ChangeDetectorRef,
   private store:Store<fromStore.CreateTemplateStates>) {
  
  }
data$:Observable<any>;

 
  ngOnInit() {

this.data$=this.store.select<any>(fromStore.getLoadingState);
 
this.onlineCheck();
    

 this.commonService.getIpClientIp().subscribe(
   data=>{ 
     this.loggedInUserIp=data['ip'];
   });

 if(environment.production){


      /*this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => {this.commonService.addPushSubscriber(sub).subscribe();console.log(sub)}
        )
        .catch(err => console.error("Could not subscribe to notifications", err));*/
  
}
 
//this is where the logged in user is reloaded from cookie 
    this.commonService.appLoadReloadlogin(); 

     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
          
          if(result==='INVALID_LOGIN_ATTEMPT')
         {
           this.showHeader=false;
           this.router.navigate(['']);
          return; 
                                                      
         }
          if(result==='LOGIN_AVAILABLE') 
         {
            this.showHeader=true;   

             this.loggedInUserObj=this.commonService.loggedInUserObj;

          let logoPath=this.loggedInUserObj.t000Organization.logoPath
          let portalName=this.loggedInUserObj.t000Organization.portalName;
          this.imageSrc = this.commonService.SERVICE_URL+'erpassets/'+portalName.toUpperCase()+'/'+logoPath;                      
         }

     });
  }


  ngOnDestroy() {
    for (let loop = 0; loop < this.subscriptionList.length; loop++) {
      this.subscriptionList[loop].unsubscribe();
    }
  }
  isAuthenticated() {
 
    return this.authService.isAuthenticated();
  }

  onSetTheme(theme) {
     
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
 
    
  }
  toggleBtn(){
    this.showBtnTrue=!this.showBtnTrue;
  }
 
  private baseUrl = 'https://erp.esalesuite.com';
    private isOnline = false;

    onlineCheck() {
        let xhr = new XMLHttpRequest();
        return new Promise((resolve, reject)=>{
            xhr.onload = () => {
                // Set online status
                this.isOnline = true;
                resolve(true);
            };
            xhr.onerror = () => {
                // Set online status
                this.isOnline = false;
                reject(false);
            };
           // xhr.open('GET', this.baseUrl, true);
         //   xhr.send();
        });
    }

    clickHandler() {
        this.onlineCheck().then(() => {
            // Has internet connection, carry on 
            console.log('internet connection is active');
            
        }).catch(() => {
            // Has no internet connection, let the user know
            alert('Sorry, no internet.');
        });
    }

     ngAfterViewInit() {
        this.cd.detectChanges();

    }

    settingsAvailable(value){
    if(value)
      this.displaySettings=value;
    else
      this.displaySettings=false;
    }
 

errorHandler(event) {
    event.target.src = this.errorimageSourcePath;
}
 
  
}
