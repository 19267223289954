import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, FormArray} from '@angular/forms'
import { DatePipe } from '@angular/common'
import { Injectable } from "@angular/core";

@Injectable()
export class CommonUtils {
public portalName;
public disableFlag=false
public ACCOUNT='ACCOUNT';
public ACCESS_NUMBER='Access Number';
public EMAIL_CONFIGURATION = 'Email Configuration'; 
public CHANGE_PASSWORD = 'Change Password';
public USERNAME_PLACEHOLDER = 'Username';
public ALLOCATED_PLACEHOLDER = 'Allocated To';
public RESOURCE_PLACEHOLDER = 'Resource Name';
public PMT_ID = 'PMT Id';
public USERNAME_BOLD = 'USERNAME';
public PASSWORD_PLACEHOLDER = 'Password';
public HOST='Host';
public EMAIL_PLACEHOLDER = 'Email';
public EMAIL_PLACEHOLDER_PERSONAL = 'Personal Email';
public EMAIL_PLACEHOLDER_OFFICIAL = 'Official Email'
public OLD_PASSWORD = 'Old Password';
public NEW_PASSWORD = 'New Password';
public CONFIRM_PASSWORD = 'Confirm Password';
public ACCOUNT_TYPE_PLACEHOLDER = 'Account Type';
public ACCOUNT_NAME_PLACEHOLDER = 'Account Name';
public ACCOUNT_CODE_PLACEHOLDER = 'Account Code';
public DESCRIPTION_PLACEHOLDER = 'Description';
public HEADER_DESCRIPTION_PLACEHOLDER = 'General Instructions';
public LINE_ITEM_DESCRIPTION_PLACEHOLDER = 'Instructions';
public INSTRUCTION_BOLD = 'INSTRUCTIONS';
public HEADER_DESCRIPTION_BOLD = 'GENERAL INSTRUCTIONS';
public SAVE_BUTTON = 'Save';
public CHANGE_BUTTON = 'Change';
public CANCEL_BUTTON = 'Cancel';
public PREVIEW_BUTTON = 'Preview';
public EXECUTE_BUTTON=" Execute";
public APPROVE_BUTTON='Approve';
public REJECT_BUTTON='Reject';
public SUBMIT_BUTTON='Submit';
public STATUS = 'Status';
public CONTROL_TYPE = 'Control Type';
public LABEL_NAME = 'Label Name';
public DEFAULT_VALUE = 'Default Value';
public REQUIRED = 'Required';
public SHOW_REPORT = 'Show in report';
public DROPDOWN_VALUE = 'DropDown Values';
public REMOVE = 'Remove';
public MODULES = 'Screen';
public USERS = 'Users';
public SELECT_ROLE = 'Select Role';
public SELECT_SALESPERSON='Select Salesperson';
public PARENT_ITEM='Parent Item';
public PARENT_CONTACT='Parent Contact';
public IMAGE_BOLD='IMAGE';
public ACCOUNT_SMALL='Account';
public PRICE_LIST_TOOL_TIP='Note : Will be applied to items original price';
public TRANSACTION_DATE_TOOL_TIP='The Accountant locked the Transaction Date :  ';
public TRANSACTION_DATE_MESSAGE='The Accountant locked the Transaction Date :  '
public PAY_BUTTON = 'Pay';
public SO_NUM = 'Sales Order#';
//contacts screen
public WAREHOUSE_ALLOCATION='Warehouse Allocation';
public CUSTOMER = 'Customer';
public VENDOR = 'Vendor';
public CLIENT = 'Client';
public BASIC_DETAILS = 'Basic Details';
public SALUTATION = 'Salutation';
public FIRST_NAME = 'First Name';
public LAST_NAME = 'Last Name';
public NICK_NAME = 'Nick Name';
public MARK_ACTIVE = 'Mark as Active';
public MARK_INACTIVE = 'Mark as Inactive';
public DELETE_BUTTON = 'Delete';
public COMPANY = 'Company';
public DISPLAY_NAME = 'Display Name';
public NAME_HINT = 'This  will be used in all PDF';
public WORK_PHONE = 'Work Phone';
public MOBILE_PHONE = 'Mobile Phone';
public CONTACT_FAX= 'Fax';
public SOURCE='Source';
public CONTACT_STATUS='Status';
public ASSET_TYPE='Asset Type';
public CONTACT_BUSINESS_TYPE='Business Type';
public ADDRESS_STATUS='Address Status';
public EMAIL_ALERT='Email is';
public EMAIL_EXISTS_ALERT='Email already exists';
public USER_EXISTS_ALERT='Username already exists';
public UNIT_NO='Unit No';
public FP_CONTACTS='FP Details';
public INTERNAL_NOTES = 'Internal Notes';
public NEXT_BUTTON = 'Next';
public OTHER_DETAILS = 'Other Details';
public CURRENCY = 'Currency';
public PAYMENT_TERMS = 'Payment Terms';
public WEBSITE = 'Website';
public FACEBOOK = 'Facebook';
public TWITTER = 'Twitter';
public PREVIOUS_BUTTON = 'Previous';
public BILLING_SHIPPING = 'Billing / Shipping';
public BILLING_ADDRESS = 'Billing Address';
public ATTENTION = 'Attention';
public STREET1 = 'Street1';
public STREET2 = 'Street2';
public COUNTRY = 'Country';
public STATE = 'State';
public ZIP_CODE = 'Zip Code';
public CITY = 'City';
public PHONE = 'Phone'; 
public FAX = 'Fax';
public SHIPPING_ADDRESS = 'Shipping Address';
public CONTACT_PERSON = 'Contact Persons';
public DESIGNATION = 'Designation';
public SELECT_DESIGNATION = 'Select Designation';
public DEPARTMENT = 'Department';
public ADD_SUB_CONTACT = 'Add Contact Person';
public END_BUTTON = 'End';
public COPY_ADDRESS_MESSAGE = 'Copy Default Billing Address';
public MANAGE_ADDRESS = 'Manage Addresses';
public CREDIT_LIMIT='Credit Limit';
public PRIMARY_CONTACT='Primary Contact';
public PS_PACK_TYPE_BULK='Bulk';
public PS_PACK_TYPE_STUDENT='Student Pack';
public PS_PACK_TYPE_SERVICE_ORDER='Service Order';
public PS_PACK_TYPE='Package Type';
public VENDOR_TYPE ='Vendor Type';
public SUPPLIER_VENDOR = 'Supplier Vendor';
public SERVICE_VENDOR = 'Service Vendor';
public RETURNED_QTY = 'Returned';
public RETURNS_NUMBER_BOLD = 'RETURNS #';
public RETURNS_NUMBER = 'Returns #';
public JOB_NUMBER_BOLD='JOB #';
public PART_REQUEST_NUMBER_BOLD='PART REQUEST #';
public WO_NUMBER_BOLD='WO #';
public PART_REQUEST_WARRANTY="WARRANTY"
public DONE_LABEL='Done';
public DISCARD_LABEL='Discard';
public ENTER_NAME='Enter Name';
public ENTER_EMAIL='Enter Email';
public SYSTEM_GENERATED='System Generated';
public BCC_EMAIL_ID='Bcc Email ID';
public TO_EMAIL_ID='To Email ID';
public CC_EMAIL_ID='Cc Email ID';
public PS_DECO_TYPE='Decoration Type';
public CONTACTNUMBER_ALERT='Contact # is ';
public CONTACT_EXISTS_ALERT='Contact # already exists';
public EMPLOYEENUMBER_ALERT='Employee Code is';
public EMPLOYEENUMBER_EXISTS_ALERT='Employee Code already exists';
public EXPENSENUMBER_ALERT='Expense # is';
public EXPENSENUMBER_EXISTS_ALERT='Expense # already exists';
public PAYMENTMADENUMBER_ALERT='Payment Made # is';
public PAYMENTMADENUMBER_EXISTS_ALERT='Payment Made # already exists';
public PAYMENTRECEIVEDNUMBER_ALERT='Payment Received # is';
public PAYMENTRECEIVEDNUMBER_EXISTS_ALERT='Payment Received # already exists';
public IS_GROUP="Is Group";
public INDUCTION_DATE_TIME = "Induction Date/Time";
public ITPOLICY_DATE_TIME = "IT Policy Date/Time";
public SHPOLICY_DATE_TIME = "SH Policy Date/Time";

public NEW_ANNOUNCEMENTS = 'New Announcements';
public ANNOUNCEMENT_DESC = 'Announcement Description';

public NEW_HR_POLICIES = 'New HR Policy';
public VIEW_UPLOADED_PDF = 'View Uploaded PDF';
public HR_POLICY_TERMS_AND_CONDITIONS = 'I have read and agree the Terms and Conditions (or) Privacy Statement applied in this HR Policy';
public VIEW_UPLOADED_NDA_PDF = 'View NDA';

public YES_BUTTON = ' YES, Completed';
public NO_BUTTON = ' NO';

//Interview slot
public JOB_NAME = 'Job Name';
public JOB_NAME_BOLD = 'JOB NAME';
public INTERVIEW_DATE_BOLD = 'INTERVIEW DATE';
public INTERVIEW_STATUS_BOLD = 'INTERVIEW STATUS';
public INTERVIEW_SLOT_TYPE = 'Interview Slot Type';
public INTERVIEW_SLOT_STATUS_BOLD = 'REMARKS';
public INTERVIEW_SLOT_STATUS = 'Status';
//create-template
public REFERENCE_NUMBER = 'Reference #';
public TAX = 'TAX(%)';
public TOTAL = 'TOTAL';
public SUB_TOTAL = 'Sub Total';
public TAX_VALUE = 'TAX VALUE';
public TAX_VALUE_PLACEHOLDER = 'Tax Value Placeholder';
public TOTAL_TAX = 'Total Tax';
public RATE = 'RATE';
public INVOICE_DATE = 'Date';
public QUANTITY = 'Quantity';
public BILLTO_ADDRESS = 'Bill To Address ';
public REASON_TO_EDIT = 'Reason To Edit';
public TYPE_TEXT = 'Enter your reason for editing';


public INVOICE_DATE_BOLD = 'INVOICE DATE';
public INVOICE_DATE_SMALL = 'Invoice Date';
public TAX_AUTHORITY = 'Select Authority Name';
public ADDRESS_TYPE = 'Address Type';
public DISPLAYED_ON_INVOICE = 'Will be displayed on the documents';
public COMPANY_TERMS = 'Mention your company\'s Terms and conditions';
public REMINDER_NOTES = 'Remind me about';
public ADD_NOTES = 'Add Notes';
public ANNEXURE = 'Annexure '; 
public REMARKS = 'Remarks'; 
public TERMS_CONDITIONS = 'Terms and Conditions';
public DISCOUNT = 'Discount';
public SAVE_DRAFT_BUTTON = 'Save as draft';
public SAVE_SEND_BUTTON = 'Save & Send';
public LATE_FEE='Late Fee';
public AUTHORITY_NAME='Authority Name';
public REQUEST_BUTTON='Request for approval';
//public INVOICENUMBER_EXISTS_ALERT = 'Invoice Number already exists';
public ATTACH_FILES = 'Attach File(s)';
//public INVOICENUMBER_ALERT = 'Invoice number is'
public ADD_NEW_ITEM = 'Add New Item';
public ADD_NEW_PROOF = 'Add New Proof';
//create items
public GOODS = 'Goods';
public SERVICES = 'Services';
public NAME = 'Name';
public UNITS = 'Units';
public UNITS_BOLD = 'UNITS';
public ITEM_MEASUREMENT_MESSAGE = 'The item will be measured in terms of this unit';
public SKU = 'SKU';
public PARTNO = 'Part #';
public SALES_INFORMATION = 'Sales Information';
public TRACK_INVENTORY = 'Track Inventory for this item'; 
public OPENING_STOCK = 'Opening Stk';
public OPENING_STOCK_RATE = 'Opening Stk Rate per unit';
public PURCHASE_INFORMATION = 'Purchase_Information';
public PURCHASE_RATE = 'Purchase_Rate';
public PURCHASE_RATE_BOLD = 'PURCHASE RATE';
public ITEM_RATE = 'Rate';
public ITEM_TAX = 'Tax(%)';
public ACCOUNTS = 'Account';
public CUSTOMER_PRICE = 'Customer Price';
public UNIT_PRICE = 'Unit Price';
//public ACCOUNT = 'Account';
public ASSET_TRACKING="Asset Tracking";
public INVENTORY_STOCK_LOCATIONS = "Inventory Stock Locations";
public SUB_CATEGORY_BOLD='SUB CATEGORY';
public SUB_CATEGORY_PLACEHOLDER='Sub Category';
public SUB_CATEGORY_NAME_PLACEHOLDER='Sub Category Name';
//create payment
public AMOUNT = 'Amount';
public PAYMENT_DATE = 'Payment Date';
public PAYMENT_MODE = 'Payment Mode';
public PAID_THROUGH = 'Paid Through';
public TAX_DEDUCTED = 'Tax deducted while making payment';
public DATE = 'Date';
public BILL_NUMBER = 'Bill#';
public PURCHASE_ORDER = 'Purchase Orders';
public BILL_AMOUNT = 'Bill Amount';
public AMOUNT_DUE = 'Amount Due';
public WITHHOLDING_TAX = 'Withholding Tax';
public PAYMENT = 'Payment';
public AMOUNT_EXCESS = 'Amount in excess';
public NOTES_INTERNAL_USE = 'Notes(Internal use. Not visible to vendor)';
public RECORD_PAYMENT_MADE = 'Record Payment Made';
public PAY_IN_FULL = 'Pay in Full';
public AMOUNT_PAID = 'Amount Paid';
public AMOUNT_USED_PAYMENT = 'Amount used for payments';
public CUSTOMER_NAME='Customer Name';
public PAYMENT_MADE='Payment Made';
public DROPSHIP_ADDRESS='Dropship Address';
public DATE_OF_ADJUSTMENT = 'Date Of Adjustment';
public BILL_AMOUNT_BOLD = 'BILL AMOUNT';
public AMOUNT_DUE_BOLD = 'AMOUNT DUE';
public TRACKING_NUMBER_BOLD = 'TRACKING #';
public CREDIT_CARD_FEE='Credit Card Fee';
public CREDIT_CARD_FEE_PRECENT='Credit Card Fee (%)';
public AMOUNT_TO_PAY='Amount To Pay';
public PURCHASE_ORDER_NUMBER = 'Purchase Order#';
public PO_FROM_DATE = 'PO From Date';
public PO_TO_DATE = 'PO To Date';
public SHIPPING_NUM = 'SHIPPING #';
public SHIPPING_DATE = 'SHIPMENT DATE';
public ROW = 'ROW';
public SECTION = 'SECTION';
public SEAT = 'SEAT';

//create employee
public NEW_EMPLOYEE = 'New Employee';
public FLAG_UPDATE_FOR_EMPLOYEE = 'Flag Update For Employee';
public NEW_FEEDBACK_DISP = 'Provide Feedback';
public PERSONAL_INFORMATION = ' Personal Information';
public EMPLOYEE_CODE = 'Employee Code';
public MALE = 'Male';
public FEMALE = 'Female';
public MOBILE_NUMBER = 'Mobile Number';
public FAMILY_PHONE_NUMBER = 'Phone Number';
public NUMBER_OF_CHILDREN = 'Number of Children';
public DOMESTIC_PARTNER_NAME = 'Domestic Partner Name';
public ADD_EDUCATION_DETAILS = 'Add Education Details';
public ADD_CERTIFICATION_DETAILS = 'Add Certification Details';
public DATE_OF_BIRTH = 'Date of Birth';
public ADDRESS = 'Address';
public PIN_CODE = 'Pin Code';
public BLOOD_GROUP = 'Blood Group';
public DESIGNATION_ROLE = 'Designation / Role';
public ALLOTED_EMAIL = 'Alloted Email-ID';
public SYSTEM_SPECIFICATION = 'System Specifications';
public ROLE = 'Role';
public OFFICIAL_INFORMATION = 'Official Information';
public DATE_OF_JOINING = 'Date of Joining';
public DATE_OF_CONFIRMATION = 'Probation Period End Date';
public PASSPORT_NUMBER = 'Passport Number';
public OFFERED_SALARY = 'Offered Salary (Per Annum)';
public CLASS_NAME = 'Class Name';
public UNIVERSITY_BOARD_NAME = 'University / Board Name';
public PERCENTAGE_CGPA = 'Percentage / CGPA';
public CURRENTLY_WORKING_HERE = 'Is Currently Working Here';
public INSURANCE_NAME = 'Insurance Name';
public COVERAGE_AMOUNT = 'Coverage Amount';
public ADD_HEALTH_BENEFITS = 'Add Health Benefits';
public ADD_MEMBERSHIP_AFFILIATION = 'Add Membership / Affiliation';
public ADD_REWARDS = 'Add Achievements';
public FATHER_NAME = 'Father Name';
public MOTHER_NAME = 'Mother Name';
public SPOUSE_NAME = 'Spouse Name';
public INSTITUTION_NAME = 'Institution Name';
public LOCK_ONBOARDING_DATA = 'Document Reviewed and Confirmed. Lock Onboarding data for Employee.';
public GRADE = 'Grade';
public ADD_JOB_ORGANIZATION_DETAILS = 'Job / Organization Details';
public DATE_OF_LEAVING = 'Date of Leaving';
public REPORTING_MANAGER = 'Reporting Manager';
public EMPLOYEE_TYPE = 'Employee Type';
public HOBBIES = 'Hobbies';
public BIOMETRIC_CODE = 'Biometric Code';
public AADHAR_NUMBER = 'Aadhar Number';
public REPORTING_LOCATION = 'Reporting Location';
public REPORTING_LOCATION_BOLD = 'REPORTING LOCATION';
public BAND_TYPE = 'Band Type';
public PAYROLL_INFORMATION = 'Payroll Information';
public BATCH_TYPE = 'Batch Type';
public BANK_NAME = 'Bank Name';
public ESI = 'ESI#';
public REPORT_TYPE = 'Report Type';
public SALARY_GROUP = 'Salary Group';
public SELECT_SALARY_GROUP = 'Select Salary Group';
public BATCH = 'Batch';
public BANK_AC_NUMBER = 'Bank A/C Number';
public PF = 'PF#';
public PAN_NUMBER = 'Pan Number';
public NAME_AS_BANK = 'Name as per Bank';
public CREATE_BUTTON = 'Create';
public SET_PASSWORD = 'Set password';
public SALARY_BAND = 'Salary Batch';
public IS_RP = 'Is Reporting Manager';
public PF_ELIGILBE_AMOUNT = 'PF Eligible Amount';
public PF_ELIGILBE = 'PF Eligible';
public ESI_ELIGILBE = 'ESI Eligible';
public USER_NAME ='User Name';
public USER_NAME_BOLD ='USER NAME';
public EMP_NAME_BOLD ='EMPLOYEE NAME';
public LOAN_DATE = 'LOAN DATE';
public LOAN_DATE_SMALL  = 'Loan Date';
public LOAN_AMOUNT = 'LOAN AMOUNT';
public BAL_AMT = 'BALANCE AMOUNT';
public PAID_AMT = 'PAID AMOUNT';
public EMI_AMT = 'EMI AMOUNT';
public IS_FA = 'Is Final Approver';
public IS_IN = 'Is Interviewer';
public CASH_IN_HAND = 'Cash In Hand';
public UPLOAD_PHOTO = 'Upload Your Photo';
public LAST_WORKING_DATE = 'Last Working Date';
public ACCEPT_RESIGNATION = 'Accept Resignation';
public INITIATE_CLEARANCE_FORMS = 'Initiate Clearance Forms';
public INITIATE_PROJECT_HANDOVER_PROCESS = 'Initiate Project Handover Check Process';

//create paycode
public PAYCODE_MASTER = 'Paycode Master';
public ADD_PAYCODE = 'Add Paycode';
public PAY_CODE = 'Add New Paycode';
public DISPLAY_ORDER = 'Display Order';
public SHOW_IN_REPORT = 'Show in Report';
public CONSIDER_FOR_PF = 'Consider for PF';
public CONSIDER_FOR_ESI = 'Consider for ESI';
public CONSIDER_FOR_LOP = 'Consider for LOP';
public DEDUCTION = 'Deduction';
public IS_EDITABLE='IsEditable';
public CONSIDER_FOR_CTC='Consider for CTC';
public CONSIDER_FOR_TAKE_HOME='Consider for Take Home';
public CONSIDER_FOR_IT='Consider for IT';
public SHOW_IN_REPORT_BOLD = 'SHOW IN REPORT';
public CONSIDER_FOR_PF_BOLD = 'CONSIDER FOR PF';
public CONSIDER_FOR_ESI_BOLD = 'CONSIDER FOR ESI';
public CONSIDER_FOR_LOP_BOLD = 'CONSIDER FOR LOP';
public DEDUCTION_BOLD = 'DEDUCTION';
public DISPLAY_ORDER_BOLD = 'DISPLAY ORDER';
public PAYCODE_NUMBER_BOLD = 'PAYCODE';
public PAYABLE_DAYS = 'Payable Days Confirm'
public ADD_WORK_REQUEST='Add Work Request';

//CREATE DECLARATION
public DECLARATION = 'Declaration';
public YEAR = 'Year';
public EMPLOYEE = 'Employee';
public APRIL = 'Apr';
public MAY = 'May';
public JUNE = 'Jun';
public JULY = 'Jul';
public AUGUST = 'Aug';
public SEPTEMBER = 'Sep';
public OCTOBER = 'Oct';
public NOVEMBER = 'Nov';
public DECEMBER = 'Dec';
public JANUARY = 'Jan';
public FEBUARY = 'Feb';
public MARCH = 'Mar';
public EXCEMPTION = 'Excemption';
public DEDUCTION_VI = 'Deduction VI A(Max of 1,50,000)';
public NATIONAL_SAVINGS_CERTIFICATE = 'National Savings Certificate';
public ELSS_MUTUAL_FUND = 'ELSS Mutual Fund';
public PPF_LIC_ELSS = '80CC(PPF,LIC,ELSS)';
public PENSION_FUNDS = '80ccc(Pension Funds)';
public HOUSING_LOAN_REPAYMENTS = 'Housing Loan Principal repayments';
public INTEREST_ON_HIGHER_EDUCATION_LOAN = 'Interest On Higher Education Loan';
public MEDICAL_INSURANCE = '80D(Medical Insurance Premium-Max 25000)';
public DONATIONS = 'Donations';
public OTHER_INCOME = 'Other Income';
public HOUSING_LOAN_INTEREST_PAID = 'Housing Loan Interest Paid';
public FIXED_DEPOSIT = 'Fixed Deposit';
public TUITION_FEES = 'Tuition Fees';
public ACTUAL_TOTAL = 'Actual Total';
public EIGHTY_EE = 'Eighty EE';
public TOTAL_MAX = 'Total(Max Rs.1,50,000)';

//create employee earnings
public EMPLOYEE_EARNINGS = 'Employee Earnings';
public RESET_BUTTON = 'Reset';
public ACTUAL_EARNINGS = 'Actual Earnings';
public ESTIMATE = 'Estimate';

//create my YTD
public MY_YTD = 'MY YTD';

//PdfTemplate
public TEMPLATE_NAME = 'Template Properties';

//organisation profile

public ORGANIZATION_PROFILE = 'Organization Profile';
public UPLOAD_LOGO = 'Upload Your Logo';
public ORGANIZATION_NAME = 'Organization Name';
public BUSINESS_LOCATION = 'Business Location';
public INDUSTRY = 'Industry';
public COMPANY_ADDRESS_LINE1 = 'Company Address Line1';
public COMPANY_ADDRESS_LINE2 = 'Company Address Line2';
public STATE_PROVINCE = 'State/Province';
public POSTAL_CODE = 'Zip/Postal Code';
public PRIMARY_CONTACT_NAME = 'Primary Contact Name';
public PRIMARY_CONTACT_EMAIL = 'Primary Contact Email Address';
public SELECT_CURRENCY = 'Base currency';
public SELECT_FISCAL_YEAR = 'Fiscal Year';
public SELECT_LANGUAGE = 'Language';
public SELECT_TIMEZONE = 'Timezone';
public SELECT_DATE_FORMAT = 'Date Format';
public EMAIL_ADDRESS_ALERT = 'Emails will be sent from the Primary Address';
public WHITE_LIST_IP=" White List IP";
public BILLING_SHIPPING_ADDRESS='BILLING/SHIPPING ADDRESS';
public BANK_DETAILS = 'BANK DETAILS';
public IFSC_CODE='IFSC Code';
public ACC_NO = 'Account Number';
public COMPANY_PAN = 'Company PAN';
public GST_No='GST #';
public GSTIN_UIN = 'GSTIN UIN';
public BRANCH='Branch';
//CREATE EXPENSES
public RECORD_EXPENSE = 'Record Expense';
public TAX_INCLUSIVE = 'Tax Inclusive';
public TAX_EXCLUSIVE = 'Tax Exclusive';
public UPLOAD_FILE = 'Upload File';
public MAXIMUM_FILE_SIZE = 'Maximum file size allowed is 7 MB';
public EXPENSE_ACCOUNT = 'Expense Account';
public NOTES = 'Notes';
public NOTES_BOLD = 'NOTES';
public TAX_AMOUNT = 'Tax Amount';
public ADD_ANOTHER_LINE = 'Add another line';
public EXPENSE_TOTAL = 'Expense Total';
public SAVE_NEW_BUTTON = 'Save & New';
public EXPENSE_AMOUNT_BOLD = 'EXPENSE AMOUNT';


//create tax
public IS_COMPOUND_TAX = 'Is Compound Tax';
public ADD_NEW_TAX = 'Add New Tax';
public TAX_DELETE_MESSAGE='Taxes cannot be deleted once created as this will affect reporting and existing data.';

//CREATE CURRENCY
public CURRENCY_NAME = 'Currency Name';
public CODE = 'Code';
public SYMBOL = 'Symbol';
public SORT_ORDER = 'Sort Order';
public ADD_NEW_CURRENCY = 'Add New Currency';
public EXCHANGE_RATE = 'Exchange Rates';
public GET_LATEST_RATE = 'GET LATEST RATES';
public BASE_CURRENCY_STRING = 'Organization - Base Currency';
public CURRENCY_DELETE_MESSAGE='Currencies cannot be deleted once created as this will affect reporting and existing data.';

//create Nistar Admin
public ADD_NEW_BRAND = 'Add New Brand';
public ADD_NEW_TYPE = 'Add New Type';
public ADD_NEW_GROUP = 'Add New Group';
public ADD_NEW_MATERIAL = 'Add New Material';
public ADD_NEW_PERCENTAGE = 'Add New Percentage';
public ADD_NEW_SHOES_WIDTH = 'Add New Shoes Width';
public ADD_NEW_GENDER = 'Add New Gender';
public ADD_NEW_MADEIN = 'Add New MadeIn';
public ADD_NEW_PACKOF = 'Add New PackOf';
public ADD_NEW_SIZE = 'Add New Size';
public ADD_NEW_FULL_SIZE = 'Add New Full Size';
public BRANDNAME = 'Brand Name';
public PRODUCTTYPE = 'Type';
public GROUPNAME = 'Group Name';
public MATERIALNAME = 'Material Name';
public VALUE = 'Value';
public WIDTHNAME = 'Width Name';
public GENDERNAME = 'Gender Name';
public COUNTRYNAME = 'Country Name';
public PACKNAME = 'Pack Name';
public SIZENAME = 'Size Name';
public FULLSIZENAME = 'Full Size Name';


//PAYMENT-RECIEVED
public PAYMENT_RECEIVED = 'Payment Received';
public BANK_CHARGES = 'Bank Charges(if any)';
public PAY_FULL_AMOUNT = 'Pay full amount';
public TAX_DEDUCTED_QUES = 'Tax deducted?';
public CUSTOMER_HAS_DEDUCTED_TAX = 'Yes, the customer has deducted tax';
public CLEAR_APPLIED_AMOUNT = 'Clear applied amount';
public INVOICE_NUMBER = 'Invoice#';
public INVOICE_AMOUNT = 'Invoice Amount';
public LIST_CONTAINS_SENT_INVOICES = '**List contains only SENT invoices';
public AMOUNT_RECIEVED = 'Amount Received';
public DEPOSIT_TO = 'Deposit To';
public INVOICE_AMOUNT_BOLD = 'INVOICE AMOUNT';

//CREATE ROLE
public MENU = 'MENU';
public MENU_PLACEHOLDER = 'Menu';
public FULL_ACCESS = 'ALL';
public VIEW = 'VIEW';
public EDIT = 'EDIT';
public DELETE = 'DELETE';
public CREATE = 'CREATE';
public APPROVE_BOLD = 'APPROVE';
public CREATE_ROLE = 'Create Role';
public EDIT_ROLE = 'Edit Role';
public ROLE_BOLD='ROLE';
public REVIEW_ROLE='Review Roles';
public APPROVE = 'Approve';
public DEFAULT_PAGE = 'Default Page';
public VIEW_ALL_RECORDS='View All Records';
public VIEW_ALL_RECORDS_BOLD='VIEW ALL RECORDS';
public CONTACT_NUMBER_BOLD='CONTACT #';
public ENABLE_MENU='ENABLE MENU';
//CREATE USER
public CREATE_USER = 'Create User';
public USERNAME_EXISTS_ALERT = 'Username already exists';
public USERNAME_MAX_LENGTH = 'Maximum allowed is 100 characters';
public USRENAME_ALERT = 'Username is';
public USERNAME_MIN_LENGTH = 'Minimum of 5 characters required ';
public EMAIL_VALIDATION_ALERT = 'Please enter a valid email address';
public EMAIL_REQUIRED = 'Email is';
public REVIEW_USER='Review Users';
//CREATE TAXGROUP
public TAX_GROUP_NAME='Tax Group Name';
public TAX_GROUP='Tax Group';
public SELECT_TAX='Select Tax';
public ACTIVE='Active';
public TAXES_INCLUDED='Taxes';
public TOTAL_RATE='Total Rate';


//Create Account
public ACCOUNT_TYPE =  'Account Type';
public DETAIL_TYPE =  'Detail Type';
//Create Reconciliation
public CREATE_RECONCILIATION = 'Reconciliation';
public START_RECONCILIATION_BTN= 'Start Reconciling';

//CREATE JOURNAL
public NEW_JOURNAL = 'New Journal';
public CASH_BASED_JOURNAL = 'Cash based journal';
public JOUNAL_TYPE = 'Jounal Type';
public CONTACT = 'Contact';
public DEBITS = 'Debits';
public CREDITS = 'Credits';
public SELECT_ACCOUNT='Select Account';
//auto-generate
public PREFIX = 'Prefix';
public NEXT_NUMBER = 'Next Number';
public SUFFIX = 'Suffix';
public MANUALLY_ADDED = 'I will add them manually each time';

//Budgeting
public BUDGETING_SCREEN_TITLE = 'Review Budgeting';
public BUDGET_CREATED_DATE = 'CREATED DATE';
public BUDGET_NAME = 'BUDGET NAME';
public BUDGET_YEAR = 'BUDGET YEAR';
public BUDGET_PERIOD = 'BUDGET PERIOD';
public BUDGET_TOTAL_AMOUNT = 'TOTAL AMOUNT';
public BUDGET_PERIOD_PLACEHOLDER = 'Budget Period';
public BUDGET_NAME_PLACEHOLDER = 'Budget Name';

//process payroll
public PROCESS_PAYROLL = 'Process Payroll';
public MONTH = 'Month';
public TRAIL_BUTTON = 'Trial';
public MASTER_BUTTON = 'Master';
public CREDIT_LEAVE = 'Credit leave';
public SICK_LEAVE = 'SL';
public CASUAL_LEAVE = 'CL';
public COMP_OFF_LEAVE = 'Comp Off'

//process reports
public PROCESS_REPORTS = 'Payroll Reports';


//Payroll Settings
public PROCESS_SETTINGS = 'Payroll Settings';
public PF_EMPE_CONT = 'PF Employee Contribution (%)';
public PF_EMPR_CONT = 'PF Employer Contribution (%)';
public PF_SALARY_SLAB = 'PF Salary Slab';
public ESI_EMPE_CONT = 'ESI Employee Contribution (%)';
public ESI_EMPR_CONT = 'ESI Employer Contribution (%)';
public ESI_SALARY_SLAB = 'ESI Salary Slab';
public SALARY_SLAB_NO = 'Slab';
public STARTING_SALARY = 'Starting Salary';
public ENDING_SALARY = 'Ending Salary';
public TAX_RATE_PERCENT = 'Tax Rate (%)';
public HRA_EXCEMPTION_1 = 'HRA Excemption 1 (%)';
public HRA_EXCEMPTION_2 = 'HRA Excemption 2 (%)';
public REBATE = 'Rebate';
public REBATE_SLAB_LIMIT = 'Rebate Slab Limit';
public SUR_CHARGE = 'Sur Charge (%)';
public SUR_CHARGE_LIMIT = 'Sur Charge Limit';
public STAD_DEDUCTION = 'Standard Deductions';
public TAX_ACCOUNT_MAPPING='Tax';
public ADJUSTMENT_ACCOUNT='Adjustment';



//REVIEW ITEMS
public REVIEW_ITEMS = 'Review Items';
public SORT_BY_DESC = 'SORT BY DESC';
public IMPORT_ITEMS = 'Import Items';
public EXPORT_ITEMS = 'Export Items';
public REFRESH_LIST = 'Refresh List';
public NAME_BOLD = 'DISPLAY NAME';
public DESCRIPTION_BOLD = 'DESCRIPTION';
public RATE_BOLD = 'RATE';
public STOCK_ON_HAND_BOLD = 'STK ON HAND';
public ACCOUNT_NAME_BOLD = 'ACCOUNT NAME';
public ITEM_TYPE= 'ITEM TYPE';
public BULK_ACTIONS = 'Bulk Actions';

// batch sync
public MODULE_NAME_BOLD = 'MODULE NAME';
public MODULE_KEY_BOLD= 'MODULE KEY';
//view search
public JOURNAL_NUMBER='JOURNAL #';
public DEBIT_AMOUNT='DEBIT AMOUNT';
public CREDIT_AMOUNT='CREDIT AMOUNT';
public ARTWORK_NUMBER_BOLD='ARTWORK #';
public PROFILE_NAME='Profile Name';
public COMPANY_NAME_BOLD = 'COMPANY NAME';
public COMPANY_NAME = 'Company Name';
public EMAIL_BOLD = 'EMAIL';
public WORK_PHONE_BOLD = 'WORK PHONE';
public RECEIVABLES = 'RECEIVABLES';
public PAYABLES = 'PAYABLES';
public PROFILE_NAME_BOLD = 'PROFILE NAME';
public DATE_BOLD = 'DATE';
public EXPIRY_DATE_BOLD = 'EXPIRY DATE';
public EXPIRY_DATE = 'Expiry Date';
public ESTIMATE_BOLD = 'ESTIMATE #';
public ESTIMATE_NUMBER = 'Estimate #';
public RESERVATION_BOLD = 'RESERVATION #';
public RESERVATION_NUMBER = 'Reservation #';
public VENDOR_NAME_BOLD = 'VENDOR NAME';
public REFERENCE_NUMBER_BOLD = 'REF #';
public STATUS_BOLD = 'STATUS';
public BILLED_STATUS_BOLD = 'BILLED STATUS';
public INV_STATUS_BOLD = 'INV STATUS';
public DUE_DATE_BOLD = 'DUE DATE';
public AMOUNT_BOLD = 'AMOUNT';
public BALANCE_DUE_BOLD = 'BALANCE DUE';
public BALANCE_BOLD= 'BALANCE';
public OUTSTANDING_BALANCE = 'OUTSTANDING BALANCE';
public REF_BOLD = 'REF#';
public MODE_BOLD = 'MODE';
public UNUSED_AMOUNT_BOLD = 'UNUSED AMOUNT';
public PO_RECEIVE = 'PO RECEIVE';
public PAID_THROUGH_BOLD = 'PAID THROUGH';
public CUSTOMER_NAME_BOLD = 'CUSTOMER NAME';
public EMPLOYEE_CODE_BOLD = 'EMPLOYEE CODE';
public EMPLOYEE_NAME_BOLD = 'EMPLOYEE NAME';
public COMMENTS_BOLD = 'COMMENTS';
public COMMENTS = 'Feedback Comments';
public MILESTONES = 'MILESTONES';
public ISSUES_BOLD = 'ISSUES';
public EMPLOYEE_NAME = 'Employee Name';
public CLIENT_NAME_BOLD = 'CLIENT NAME';
public MOBILE_NUMBER_BOLD = 'MOBILE NUMBER';
public DEPARTMENT_BOLD = 'DEPARTMENT';
public DESIGNATION_BOLD = 'DESIGNATION';
public REPORTING_MGR_BOLD = 'REPORTING MANAGER NAME';
public EXPENSE_ACCOUNT_BOLD = 'EXPENSE ACCOUNT';
public FREQUENCY_BOLD = 'FREQUENCY';
public START_DATE_BOLD = 'START_DATE';
public START_YEAR = 'Start Year';
public END_YEAR = 'End Year';
public CUSTOMER_PHONE='CUSTOMER PHONE#';
public PAYMENT_NUMBER="PAYMENT #";
public CREDIT_NOTE_NUMBER="CREDIT NOTE #";
public VENDOR_CREDIT_NUMBER="VENDOR CREDIT #";
public ADD_REMINDER = 'Remind me on ';
public PACKAGE = 'PACKAGE'
public PACKAGE_TITLE = 'Package';
public ITEM_DESC = 'Items & Description';
public APPROVED = 'Approved';
public QUANTITY_APPROVE = 'Quantity To Approve';
public RECEIVED_QTY = 'Received';
public QTY_TO_RECEIVE = 'Quantity to Receive';
public PACKED_QTY = 'Packed';
public QTY_TO_PACK = 'Quantity to pack';
public SO_NO = 'SALES ORDER#';
public ORDERD_QTY = 'Ordered';
public PACKAGE_NO = 'PACKAGE #';
public PACKAGE_DATE = 'PACKAGE DATE';
public DELIVER = 'DELIVER';
public SHIPMENT_STATUS="SHIPMENT STATUS";
public VENDOR_CREDIT_BOLD="VENDOR CREDIT #";
public SALES_RATE_BOLD="SALES RATE";
public MANUFACTURER_BOLD="MANUFACTURER";
public SHIPPING = 'SHIP';
public SHIPPING_TITLE = 'New Shipping';
public SHIPMENT_ORDER_NO = 'Shipment Order#';
public SHIP_DATE = 'Ship Date';
public CARRIER = 'Carrier (Select or Type to add)';
public TRACKING_NO = 'Tracking#';
public SHIP_AMT_IF = 'Shipping Amount (If any)';
public SERVICE_BASED_CHARGE = 'Service Based Shipping Charges';
public SHIPMENT_DELEVERED = 'Shipment already delivered';
public SEND_STATUS_NOTIFICATION = 'Send Status Notification';
public DOWNLOAD_BTN = 'Download';
public ARTWORK_ID_BOLD='ARTWORK ID';
public ARTWORK_NAME_BOLD='ARTWORK NAME';
public CREATED_DATE_BOLD='CREATED DATE';
public APPROVED_DATE_BOLD='APPROVED DATE';
public ORDER_NUMBER_BOLD = 'ORDER #';
public BILL_DATE_BOLD = 'BILL DATE';
public BILL_DATE = 'Bill Date';
public REFUND_RECEIPT_NUMBER_BOLD = 'REFUND RECEIPT #';
public BILL_NUMBER_BOLD = 'BILL#';
public VENDOR_NAME = 'VENDOR NAME';
public VENDOR_PHONE_BOLD = 'PHONE#';
public PO_DATE_BOLD = 'PO DATE';
public PO_NUMBER_BOLD = 'PO #';
public RECEIVED_QTY_BOLD = 'RECEIVED QTY';
public INVOICE_NUMBER_BOLD = 'INVOICE #';
public SALES_RECEIPT_NUMBER_BOLD = 'SALES RECEIPT #';
public STARTS_ON_BOLD = 'STARTS ON';
public ENDS_ON_BOLD = 'ENDS ON';
public PACKAGE_STATUS = 'PACKAGE STATUS';
public RECEIVED_STATUS = 'RECEIVED STATUS';
public INVOICE_STATUS = 'INVOICE STATUS';
public SO_NUMBER_BOLD = 'Sales Order#';
public CONVERT_TO_BILL = 'CONVERT TO BILL';
public CONVERT_TO_TASK = 'CONVERT TO TASK';
public CONVERT_TO_INVOICE = 'CONVERT TO INVOICE';
public CREATE_BILL='Create Bill';
public CONVERT_SO='Convert To SalesOrder';
public CONVERT_INVOICE='Convert To Invoice';
public PERSONALIZED='Personalized';
public MULTIPLE_ORDER='Multiple Order';
public ARTWORK_DETAILS='Artwork Details';
public STOCKREQ_NUM_BOLD = "STOCK REQUEST #";
public REQ_BY_BOLD = "REQ BY";
public STOCK_RECEIVE = "STOCK RECEIVE";
public RETURN ="Return";
public RETURN_RECEIVE = 'RETURN RECEIVE';
public SO_RECEIVE = 'SO RECEIVE';
public ARTWORK_VALUE ='ARTWORK';
public JOB_CODE='Job Code';
public JOB_TITLE='Job Title';
public JOB_STATUS='Job Status';
public EXP_YEAR='Year of Experience';
public EXP_MONTH='Month of Experience';
public REQUIRED_SKILLS='Required Skills';
//reports Summary
public REPORTS_SUMMARY = 'Reports Summary';
public REPORTS_SALES = 'Sales';
public REPORTS_PURCHASE = 'Purchases and Expenses';
public SALESBYSALESPERSON_BTN = 'Sales by sales person';
public EXPENSE_DETAILS_BTN= 'Expense details';
public SALES_BY_CUSTOMER_BTN=  'Sales by customer';
public SALES_BY_ITEM_BTN= 'Sales by item';
public EXPENSES_BY_CUSTOMER_BTN='Expenses by customer';
public PURCHASE_DETAILS_VENDOR_BTN='Purchase details for vendor';
public PURCHASES_BY_ITEM_BTN='Purchases by Item';
public PURCHASES_BY_VENDOR_BTN='Purchases by vendor';
public PURCHASE_ORDER_HISTORY ='Purchase Order History';
public RECEIVE_HISTORY='Receive History';
public SERVICE_ORDER='Service Order';
public BULK_ORDER='Bulk Order';
public STUDENT_ORDER='Student Order';
public QUANTITY_RECEIVED='(Quantity Received)';
public QUANTITY_RECEIVED_BOLD='QUANTITY RECEIVED ';
//SalesbysalesPerson
public SALESBYSALESPERSON_PAGE_NAME = 'Sales by Sales Person';
public SALESBYITEM_PAGE_NAME = 'Sales by Item';
public SALESBYCUSTOMER_PAGE_NAME = 'Sales by Customer';
public SALESBYCUSTOMERGROUP_PAGE_NAME = 'Sales by Customer Group';
public PURCHASESBYVENDOR_PAGE_NAME = 'Purchases by Vendor';
public PURCHASESBYITEM_PAGE_NAME = 'Purchases by Item';
public PURCHASESDETAILBYVENDOR_PAGE_NAME = 'Purchase Details for Vendor';
public EXPENSESBYCUSTOMER_PAGE_NAME = 'Expenses by Vendor';
public EXPENSES_BY_VENDOR = 'Expenses by Vendor';
public SALES_PERSON_NAME = 'Sales Person Name';
public EMAIL = 'Email';

public EXPENSEDETAILS_PAGE_NAME = 'Expense Details';
public ACCOUNT_TRANSACTION_PAGE_NAME = 'Account Transaction';
public GENERAL_LEDGER_PAGE_NAME = 'General Ledger';
public TRIAL_BALANCE_PAGE_NAME = 'Trial Balance';

public TO_DATE= 'To Date';
public FROM_DATE= 'From Date';
public EXPORT_AS= 'Export As';
public APPLY_BTN= 'Apply';
public SELECT_EMP= 'Select Employee';
public ADD_CREDIT_LEAVE= 'Apply Credit Leave';
//Inventory Summary
public PO_ORDERED='PO Ordered';
public PO_RECEIVED_QUANTITY='PO Received';
public PO_YET_TO_RECEIVED='PO Yet to Receive';
public SO_ORDERED='SO Ordered';
public SO_PACKED='SO Packed';
public SO_YET_TO_PACKED='SO Yet to Pack';
public SO_SHIPPED='SO Shipped';
public SO_YET_TO_SHIPPED='SO Yet to Ship';
public INVENTORY_DETAIL_REPORT='Inventory Detail Report';
public INVENTORY_SUMMARY='Inventory Summary';
public YES = ' (YES)';
public NO = ' (NO)';
//mamabear invntory summary
public MAMABEAR_INVENTORY_SUMMARY='MamaBear Inventory Summary';
//Journal Report
public JOURNALREPORT_PAGE_NAME='Journal Report';
//table header
public INVOICE = 'INVOICE';
public CREDIT_NOTE= 'CREDIT NOTE';
public SALES_TOTAL= 'TOTAL';
public SALES_NAME= 'NAME';
public COUNT = 'COUNT';
public SALES= 'SALES';
public SALES_WITH_TAX= 'SALES WITH TAX';
	
//fp details
public CREATE_WORK_ORDER='Create Work Order';
public SELECT_CATEGORY='Select Category';
public SELECT_SUBCATEGORY='Select SubCategory';
public SELECT_ITEM='Select Item';
public ADD_NEW_LINE='Add New Line ';

//fp details
public CATEGORY='Category';
public SUBCATEGORY='SubCategory';
public ITEM='Item';
//create assets
public ASSETS="Assets";
public PRODUCT_MODEL_NUMBER="Product Model #";
public GROUP="Group";
public IDENTIFICATION_NUMBER="Identification #";
public COST_RATE="Cost Rate";
public PURCHASE_ON="Purchased On";
public LOCATION="Location";
public CREATE_ASSET="Create Asset";
public ASSET_SERVICES="Asset Service";
public ASSET_TRANSACTION="Asset Transaction";
public LOCATION_BOLD="LOCATION";
public INVOICENUMBER_EXISTS_ALERT ="Asset # already exists";


public ASSET_NAME="Asset Name";
public ASSET_DETAILS="Asset Details";
public DEPPRICIATION="Depreciation";
public ASSET_NUMBER="Asset #";
public ASSET_NUMBER_BOLD="ASSET #";
public BARCODE_NUMBER = "Barcode #";
public MODEL_NUMBER = "Model #";
public PRODUCT_BRAND = "Brand";
public PRODUCT_SERIAL_NUMBER = "Serial Number";
public PRODUCT_CATEGORY = "Category";
public CATEGORY_BOLD = "CATEGORY";
public SCRAP_VALUE = "Scrap Value";
public PURCHASED_ON = "Purchased On";
public WARRENTY_EXP_DATE = "Warranty expire date";
public IS_NEW_ASSET = "New Asset";
public IS_ACTIVE = "Is Active";
public EXP_LIFE_PER_YEAR = "Expected life per year";
public DEPRICIATION_METHOD = "Depreciation Method";
public DEPRICIATION_RATE = "Depreciation Rate(%)";
public HASH = "#"
public ORIGINAL_RESIDUAL_COST = "Original Cost - Residual Cost";
public DEPRECIATION_EXP = "Depreciation Expense";
public BOOK_VAL_BEG = "Book value at the beginning";
public BOOK_VAL_END = "Book value at the end of the year";
public CALCULATE_DEPRECIATION ="Calculate";
public USEFUL_LIFE_IN_UNITS = "Useful life in units of production";
public UNITS_PRODUCED = "Units Produced";
public DEP_PER_UNIT = "Per unit depreciation";
public PARTS_COST = "Parts Cost";
public LABOR_COST = "Labor Cost";
public SERVICE_STATUS= "Service Status";
public ASSIGNED_TYPE= "Assigned Type";
public MOVEMENT_TYPE= "Transaction Type";
public ASSIGNED_DATE= "Assigned Date";
public WAREHOUSE= "Warehouse";
public ASSIGNED_TO="Assigned To";
public ASSIGNED_FROM="Assigned From";

public ASSET_NAME_BOLD="ASSET NAME";
public ASSET_DESCRIPTION_BOLD="ASSET DESCRIPTION";
public ASSET_TYPE_BOLD="ASSET TYPE";
public PURCHASED_ON_BOLD="PURCHASED ON";
public CHECK_IN="CHECK IN";
public CHECK_OUT="CHECK OUT";
public DELETE_BOLD="DELETE";
public EDIT_BOLD="EDIT";
public CHECK_IN_SM="Check In";
public CHECK_OUT_SM="Check Out";

//Save and Send labels
public EMAIL_HEADER ="Email Confirmation Template";
public SELECT_TEMPLATE="Template"
public TO_EMAIL = "To";
public CC_EMAIL = "Cc";
public BCC_EMAIL = "Bcc";
public EMAIL_SUBJECT = "Subject";
public EMAIL_BODY = "Body";
public SEND_BTN = "Send";
public SAVE_BTN = "Save";

//View Project
public PROJECT_NAME="Project Name";
public PROJECT_DESC="Project Description";
public PROJECT_CODE="Project Code";
public PROJECT_COMMENT="Comment";
public PROJECT_URL="Url";
public PROJECT_STATUS="Project Status ";
public CREATE_TASK="New Task";
public RESOURCE_NAME="Resource Name";
public ESTIMATED_HRS = "Estimated Hrs";
public ACTUAL_HRS = "Actual Hrs";
public NO_OF_RESOURCE = "No.of Resources";
public ADD_RESOURCE_EXTENSION = "Add Resource";

//Project Plan
public CLIENT_VIEWABLE = "Client Viewable";
public IS_SUMMARY="Is Summary";
public PARENT_TASK="Parent Task";
public TASK_STATUS="Task Status";
public ATTENDANCE_CRITERIA="Attendance Criteria";
public ATTENDANCE_DATE = "ATTENDANCE DATE";
public AUTO_SCHEDULE="Auto Sch";
public TASK_ID="Task Id";
public TASK_NAME="Task Name";
public TASK_NUMBER = "Task Number";
public TASK_DESC="Task Description";
public START_DATE="Start Date";
public END_DATE="End Date";
public DELIVERY_DATE="Delivery Date";
public TASK_DATES="Task Dates";
public BASELINE_DATES="Baseline Dates";
public RESOURCE="Resource";
public RESOURCE_NAME_BOLD="RESOURCE NAME";
public PROJECT_PLAN_HEADER ="Project Plan";
public DESC = "Description";
public BASELINE_HRS = "Baseline Hrs";
public BILL_HRS = "Billable Hrs";
public EST_HRS = "Estimated Hrs";
public DEVIATION = "Deviation";
public COMPLETED_PERCENT = "% Complete";
public IS_CLIENTVIEWABLE = "Client Viewable";
public VERSION_NUMBER = "Version Number";
public GLOBALLY="Global";
public NON_BILL_HRS = "Non Billable Hrs";
//timesheet history
public WORKED_HOURS = "Worked Hours";
public WORKED_DATE = "Worked Date";
public USER_COMMENT = "User Comments";
public CREATED_DATE = "Created Date";
//validation settings
public CONTROL_NAME="Control Name";
public IS_PARENT="Is Parent";
public PARENT_NAME="Parent Name";
public LEVEL="Level";
public IS_REQUIRED="Is Required";
public IS_NULL="Is Null";
public IS_MINLENGTH="Is MinLength";
public MIN_LENGTH="Min Length";
public IS_EMAIL="Is Email";
public IS_MAXLENGTH="Is MaxLength";
public MAX_LENGTH="Max Length";
public VALIDATION="Validation";

//My Leave
public LEAVE_TYPE = "Leave Type";
public APPLIED_DAYS = "APPLIED DAYS";
public APPROVED_DAYS = "APPROVED DAYS";
public LOP = "LOP";
public APPLIED_DATE = "Applied Date";
public REASON = "REASON";
public LEAVE_STATUS = "Status";
public PERMISSION_HRS = "Permission Hrs";

public APPR_DAYS = "Approved Days";
public APP_DAYS = "Total Applied Days";
//Payable and receivable reports
public PAYABLE = "Payable";
public AGING_DETAILS = "Aging Details";
public RECEIVABLE = "Receivable";
public CUSTOMER_BALANCE = "Customer Balances";
public AGING_SUMMARY = "Aging Summary Details";
public RECEIVABLE_AGING = "Receivable Aging Details";
public PAYABLE_AGING = "Payable Aging";
public RECEIVABLE_AGING_SUMMARY = "Receivable Aging Summary";
public PAYABLE_AGING_SUMMARY = "Payable Aging Summary";
public INVOICE_DETAILS = "Invoice Details";
public SALES_ORDER_DETAILS = "Sales Order Details";
public SALES_ESTIMATE_DETAILS = "Sales Estimate Details";
public TRANSACTION_NUMBER="TRANSACTION #";
public AGE="AGE";

//Activity Log reports
public ACTIVITY_LOG = "Activity Log";
public ERROR_LOG = "Error Log";

//WareHouse Screens
public LOCATION_NAME = "LOCATION NAME";
public IS_WAREHOUSE = "IS WAREHOUSE";
public PARENT_LOCATION = "PARENT LOCATION";
public PARENT_LOCATION_PLACEHOLDER = "Parent Location";
public REVIEW_WAREHOUSE = "Review Warehouse";
public LOCATIONNAME = "Location Name";
public ISWAREHOUSE = "Is WareHouse";
public PARENT_WAREHOUSE = " is under";
public CREATE_WAREHOUSE = "CREATE WAREHOUSE";
public MAXIMUM_ITEMS = "Maximum Items";
public WAREHOUSE_TYPE = "Type";
public IS_RECEIVESTOCK = "Receive Stock";
//Organization screen
public ORGANIZATIONPROFILE_NAME = 'ORGANIZATION NAME';
public PORTAL_NAME = 'PORTAL NAME';
public ADDRESS1 = 'ADDRESS1';
public ADDRESS2 = 'ADDRESS2';
public ORGAIZATION_CITY = 'CITY';
public PORTAL_NAME_SMALL = 'Portal Name';

//Po-Receive Screen
public ITEMS_DESC = "Items and Description";
public RECEIVE ="Receive";
public WAREHOUSE_LOCATION = "Warehouse Location";
public SELECT_LOCATION = "Select Location";
public ADD_TO_GRID = "Add to Grid";
public DONE_BTN = "Done";
public APPLY_BILLS = "Apply Bills";
public APPLY_INVOICE = "Apply Invoice";
public LOT_NUMBER = "LOT #";

//items group
public REORDER_LEVEL_BOLD='REORDER LEVEL';
public SALES_ACCOUNT='Sales Account';
public PURCHASE_ACCOUNT='Purchase Account';
public INVENTORY_ACCOUNT='Inventory Account';
public PRODUCTION_BOLD='PRODUCTION';
public ITEM_GROUP_TYPE = 'Item Group Type';
public ITEM_GROUP_TYPE_BOLD = 'ITEM GROUP TYPE';
public ITEM_GROUP_NAME_BOLD = 'ITEM GROUP NAME';
public ITEM_GROUP_GOODS = 'Goods';
public ITEM_GROUP_SERVICES = 'Services';
public ITEM_GROUP_NAME = 'Item Group Name';
public ITEM_GROUP_NAME_EXISTS = 'Item group name already exists';

public MANUFACTURER_PLACEHOLDER = 'Manufacturer';
public CREATE_ATTRIBUTE_OPTIONS = 'Create Attributes and Options';
public ADD_MORE_ATTRIBUTES = 'Add More Attribute';
public SELECT_ITEM_TYPE = 'Select Your Item Type';
public INVENTORY = 'Inventory';
public NON_INVENTORY = 'Non Inventory';
public SALES_ONLY = 'Sales Only';
public PURCHASE_ONLY = 'Purchase Only';
public BOTH = 'Both';
public INCLUDE_OPENING_STOCK = 'Include Opening Stk';
public OPENING_STOCK_VALUE = 'Opening Stk';
public PURCHASE_PRICE = 'Purchase Price';
public SELLING_PRICE = 'Selling Price';
public UPC = 'UPC';
public EAN = 'EAN';
public ISBN = 'ISBN';
public MPN = 'MPN';
public REORDER_LEVEL = 'Reorder Level';
public SKU_EXISTS = 'SKU already exists';
public UPC_EXISTS = 'UPC already exists';
public PART_NO_EXISTS = 'Part # already exists';
public NO_ITEMS_IN_ITEM_GROUP = 'Please enter attributes';
public ATTRIBUTE_PLACEHOLDER = 'Attribute';
public OPTIONS_PLACEHOLDER = 'Options';
public PAYMENT_BOLD='PAYMENT';
public ITEMS_TABLE='Item';
public INVENTORY_TABLE='Inventory';
public INVENTORY_ITEM='Inventory Item';


// general settings
public GENERAL_SETTINGS='General Settings';
public GENERAL='General';
public DISPLAY_TAX='Display Tax Switch'; 
public PAYSLIP_TEMPLATE = 'PaySlip Template';
public BANK_TEMPLATE = 'Bank Template';
public IT_TEMPLATE = 'IT Template';
public PF_TEMPLATE = 'PF Template';
public ADD_NEW_SALES_PERSON='Add Sales Person';
public TAX_AT_LINE='Tax at line level';
public DISPLAY_SETTINGS='Display Settings Icon';
public ENABLE_LATEFEE='Enable LateFee In Invoice';
public ENABLE_SALESPERSON='Enable Sales Person';
public INVENTORY_DEDUCTION='Inventory Deduction';
public ENABLE_FP='Enable FP';
public ENABLE_CART='Enable Cart';
public ENABLE_STICKY_GRID='Enable Sticky Grid';
public GL_ENABLE_TRANSCATION_LOCKING='GL Transaction Locking';
public TRANSACTION_LOCKING_DATE='Transaction Locking Date';
public AUTO_GENERATE_SETTINGS='Manage Auto-Generation';
public GRAPHICS_EMAILID='Graphics Vendor Email';
public ARTWORK='Artwork';
public SALES_PERSON_SETTINGS='Manage Sales Persons';
public LATE_FEE_PERCENTAGE='Late Fee Percentage';
public ENABLE_PORTAL_ACCESS='Enable Portal';
public SALES_PERSON='Sales Person';
public SALES_PERSON_BOLD='SALES PERSON';
public IS_EMAIL_UNIQUE = 'Is Email Unique';
public PRODUCTION_SLIP='PROD SLIP';
public PRODUCTION_BALANCE='Production Balance';
public CREATE_DOWNLOAD='Create & Download';
public SAVE_DOWNLOAD='Save & Download';
public PRODUCTION_SLIP_NUMBER='PRODUCTION SLIP #'
public PRODUCTION_SLIP_NUMBER_SMALL='Productin slip #'
public DOWNLOAD_BOLD='DOWNLOAD';
public DISCOUNT_AFTER_TAX='Discount After Tax';
public REPORT_CRITERIA_RANGE='Reports Date Criteria Range';
public NUMBER_OF_DECIMALS='No Of Decimals';
public IS_SINGLE_WAREHOUSE = 'Is Single Warehouse';
public EGRAPHICS_PLUS = 'eGraphicsPlus';
public IS_PDF_ATTACHED = 'Attach PDF';
public INVOICE_LINK_EXPIRATION_DATE_FLAG = 'Is InvoiceLink Expirable';
public ADJUST_STOCK_REASON='Manage Reason - Adjust Stock';

//Adjust_Stock
public STOCK_REASON="Reason";
public ADD_NEW_REASON = 'Add New Reason';
public SELECT_REASON="Select Reason";
public ADJUST_QTY="Quantity To Be Adjusted"


//UPS_INTEGRATION
public UPS_INTEGRATION="Ups Credentials ";
public DESCRIPTION="Description";
public UOM_TYPE="UOM Type";
public PACKAGE_TYPE="Package Type";
public SHIPPING_CHARGE_TYPE="Shipping Charge Type";
public PACKAGE_DIMENSION="Package Dimension";
//Qb_INTEGRATION
public QB_INTEGRATION='QB Credentials';
public COMPANY_ID='Company Id';
public CLIENT_ID='Client Id';
public CLIENT_SECRET='Client Secret';
public ACCESS_TOKEN='Access Token';
public BASE_URL_QBO='Base Url Qbo';
public CREATE_PO='Purchase Order';
public CREATE_QB_BILL ='Bill';
public CREATE_VC ='Vendor Credits';
public CREATE_EXPENSE='Expense';
public CREATE_PAYMENT_MADE ='Payment Made';
public CONNECT_BUTTON ='Connect';
public CREATE_SALES_ORDER = 'Sales Order';
public CREATE_INVOICE = 'Invoice';
public CREATE_ESTIMATE = 'Estimate';
public CREATE_CREDIT_NOTES = 'Credit Notes';
public CREATE_CREDIT_NOTE = 'Credit Note';
public CREATE_PAYMENT_RECEIVED = 'Payment Received';
public CREATE_ITEM_OBJ = 'Item';
public CREATE_CUSTOMER_OBJ = 'Customer';
public CREATE_VENDOR_OBJ = 'Vendor';
public RESPONSIBLE_USER = 'Responsible User';

public QB_ID = 'QuickBooks Id';
public QB_STATUS = 'QuickBooks Status';
public MODEL_NUMBER_BOLD='MODEL #'
public RETURN_QB_RESPONSE_URL = 'Response URL';
/*record payment - 9072*/
public TAX_ACCOUNT='Tax Account'
public RECORD_PAYMENT='Record Payment';
public ALERT_MESSAGE_PAYMENT='Amount should be less than or equal to invoice amount';

/*Apply Leave*/
public APPLY_LEAVE = 'Apply Leave';
public SL_AVAIL = 'SL Available :';
public CL_AVAIL = 'CL Available :';
public TOTAL_AVAIL = 'Total Available :';
public COMP_OFF_AVAIL = 'Comp-off Available :';
public SL_USED = 'SL Used';
public CL_USED = 'CL Used';
public COMP_OFF_USED = 'Comp-off Used';
public LOP_DAYS = 'LOP Days';
public TOTAL_DAYS = 'Total Days';
public SELECT_TYPE = 'Select Type';
public TOTAL_HRS_MINS = 'Total Hrs/Mins';
public HOURS='Hrs';
public MIN='Min';
public MY_LEAVE_HIST = 'My Leave History';
public LEAVE_DETAILS = 'Details';
public UNAPPROVED_LEAVES_LINK ='Unapproved Leaves';
public CL_APPLIED = 'CL Applied';
public SL_APPLIED = 'SL Applied';
public COMP_OFF = 'Comp Off';
public LEAVE_REASON = 'Reason';
public CL ='CL';
public SL = 'SL';

public COMP_OFF_BALANCE = "Comp-off Balance";
public SL_BALANCE = 'SL Balance';
public CL_BALANCE = 'CL Balance';
public APPROVER_NAME = "Approver Name";
public SL_AVAILABLE = 'SL Available';
public CL_AVAILABLE = 'CL Available';
public CL_APPROVED = 'CL Approved';
public SL_APPROVED = 'SL Approved';
public COMP_OFF_APPROVED = 'Comp-Off Approved';
public TOT_APPROVED_DAYS = 'Approved Days';
public EMP_NAME = 'User Name';
public NO_RECORD ='No Records Found';
public CLOSE_BUTTON_BOLD = 'CLOSE';
public CLOSE_BUTTON='Close';
public DESIGNATION_NAME = 'Designation Name';
public FEEDBACK_COMMENTS = 'Feedback Comments';
public FEEDBACK_REPORT = 'Feedback Report';
public APPRAISAL_REPORT = 'Appraisal Report';
public SELECT_APPRAISAL_STATUS = 'Select Appraisal Status';
public SELECT_FREQUENCY = 'Select Frequency';
public SELECT_YEAR = 'Select Year';
public SELECT_MONTH = 'Select Month';
public POSITIVE_MILESTONES = 'Any Positive Milestones';
public ISSUES = 'Any Issues';
public ADD_NEW_DESIGNATION = 'Add New Designation';
public DESIGNATION_CODE = 'Designation Code';
public IT_EMAIL = 'IT Email';
public ADMIN_EMAIL = 'Admin Email';
public DESIGNATION_DELETE_MESSAGE = 'Designation cannot be deleted once created as this will affect existing data.';

public INDENT_NO = 'INDENT #';
public CONTINUE_BTN = 'Continue';
public CLONING_ICON = 'CLONING';


/*loan transaction - 9072*/
public LOAN_TRANSACTION='Loan Transaction';
public UPDATED_BY='UPDATED BY';


/*General settings - 9072*/
public PURCHASE='Purchase';
public ACCOUNT_MODULE='Account Module';
public PAYPAL_CONFIGURATION='PayPal Configuration';
public AUTHORISEDPAYMENT_CONFIGURATION='Authorised Payment Configuration'

public CREATE_INVOICE_BTN = 'Create Invoice';
public PAY_LINK = 'PAY';

public SEND_FEEDBACK = 'We would love to hear from you';
public FEEDBACK_NOTES = 'Describe your issues or share your ideas';

public CREATE_TICKET = 'Create Ticket';
public TICKET_NOTES = 'Describe your issues';

/*Production Slip ------ 9056*/
public PROD_SLIP_TITLE = 'Production Slip';
public REP_EMAIL = 'Rep Email';
public ITEM_NAME = 'Item Name';
public QTY_ORDERED = 'Qty Ordered';
public QTY_FOR_PRODUCTION='Qty for production';
public ENGRAVED_QTY_FOR_PRODUCTION='Engraved Ordered Qty';
public ENGRAVED = 'Engraved';
public BLANK = 'Blank';
public DROP_SHIP_ADDRESS = 'Drop Ship Address';
public PROD_ENGRAVED = 'Product to be Engraved';
public DUE_DATE_PLACEHOLDER = 'Due Date';
public CONTACT_NAME = 'Contact Name';
public BRAND_BOLD='BRAND';
public AVAILABLE_QTY='AVAILABLE QTY';
public AVAILABLE_QTY_SMALL='Available Quantity';
public SOLD_QTY='SOLD QUANTITY';
public TEXT_VALUE='Text Value';
public FONT_COLOR_PS='Font Color';
public FONT_FAMLILY_PS='Font Family';
public FONT_SIZE_PS='Font Size';
public ART_IMAGE='Image';

public ENABLE_ENGRAVING = 'Enable Engraving';

public TEMPLATE_NAME_PLACEHOLDER = 'Display Template Name';
public XSL_TEMPLATE_NAME_PLACEHOLDER = 'XSL Template Name';

public TEMPLATE_SETTINGS = 'Template Settings';
public SELECT_MODULE = 'Select Module';
public SELECT_TEMPLATE_PLACEHOLDER = 'Select Template';
public IS_DEFAULT = 'Is Default';
public FONT_FAMLILY ='PDF Font Family';
public FONT_SIZE = 'Size';
public FONT_COLOR = 'Color';
public TEMPLATE_HEADER = 'Template Header';
public TITLE = 'Title';
public LABEL_COLOR = 'Label Color';
public HEADER_FONT_SIZE = 'Size';
public HEADER_FONT_COLOR = 'Color';
//public TABLE_HEADER_BCKGRND_COLOR = 'Table Header Background Color';
//public TABLE_HEADER_FONT_COLOR = 'Table Header Font Color';
public ITEM_TABLE ='Item Table';
public ITEM_FONT_SIZE = 'Item Font Size';
public ITEM_BCKGRND_COLOR = 'BG Color';
public ITEM_FONT_COLOR = 'Item Font Color';
public TEMPLATE_FOOTER = 'Template Footer';
public PAPER_SIZE_LABEL = 	'Paper Size';
public PAPER_SIZE_A4 = 'A4';
public PAPER_SIZE_LETTER = 'Letter';
public TEMPLATE_ORIENTATION_LABEL = 'Orientation';
public TEMPLATE_ORIENTATION_PORTRAIT = 'Portrait';
public TEMPLATE_ORIENTATION_LANDSCAPE = 'Landscape';
public TEMPLATE_MARGIN = 'Margins';
public TEMPLATE_MARGIN_INCH = '(in inches)';
public TEMPLATE_MARGIN_TOP = 'Top';
public TEMPLATE_MARGIN_BOTTOM = 'Bottom';
public TEMPLATE_MARGIN_LEFT = 'Left';
public TEMPLATE_MARGIN_RIGHT = 'Right';
public ORG_FONT_COLOR = 'Color';
public TEMPLATE_DOC_TITLE = 'Document Title';
public TEMPLATE_ORG_HEADER = 'Organization';
public SHOW_ORG_NAME = 'Name';
public SHOW_ORG_LOGO = 'Logo';
public SHOW_ORG_ADDRESS = 'Address';
public TEMPLATE_VENDOR_NAME_HEADER = 'Vendor Name';
public TEMPLATE_DOC_INFO = 'Document Information';
public TEMPLATE_ITEM_TABLE_HEADER = 'Item Header';
public TEMPLATE_ITEM_TABLE_DETAIL = 'Item Detail';
public TEMPLATE_TOTAL = 'Total';
public TEMPLATE_SUB_TOTAL = 'Sub Total';
public ITEM_NAME_EXISTS = 'Name already exists';
public DISPLAY_NAME_EXISTS = 'Display Name already exists';
public SO_NUMBER='SO #';
public INVENTORY_STATUS='INV STATUS';
public EXTERNAL_SALES_ORDER_QTY='External SO Qty';
public INTERNAL_SALES_ORDER_QTY='Internal SO Qty';
public ACTUAL_AVAILABLE='Actual Available';

/*Price List ----- START ------ 9056*/
public TYPE = 'Type';
public PRICELIST_NAME = 'Price List Name';
public SALES_RADIO_OPTION = 'Sales';
public PURCHASE_RADIO_OPTION = 'Purchases';
public SALES_RADIO_OPTION_VALUE = 'SALES';
public PURCHASE_RADIO_OPTION_VALUE = 'PURCHASES';
public PERCENTAGE = 'Percentage';
public PERCENTAGE_SYMBOL = '%';
public MARKUP = 'Select';
public PRICELIST = 'Price List';
public MARKUP_MARKDOWN='MARK UP/MARK DOWN';
/*Price List ----- END ------ 9056*/


/*report starts 9072*/

public INVOICE_COUNT_BOLD='INVOICE COUNT';
public CN_COUNT_BOLD='CN COUNT';
public ITEM_NAME_BOLD='ITEM NAME';
public QUANTITY_SOLD_BOLD='QUANTITY SOLD';
public AVERAGE_PRICE_BOLD='AVERAGE PRICE';
public ACCOUNT_BOLD='ACCOUNT';
public TRANSACTION_DETAILS_BOLD='TRANSACTION DETAILS';
public TYPE_BOLD='TYPE';
public DEBITS_BOLD = 'DEBITS';
public CREDITS_BOLD = 'CREDITS';
public CURRENT_DUE_BOLD = 'CURRENT DUE';
public INVOICE_BALANCE_BOLD = 'INVOICE BALANCE';
public AVAILABLE_CREDITS_BOLD = 'AVAILABLE CREDITS';
public ESTIMATE_DATE_BOLD = 'ESTIMATE DATE';
public ESTIMATE_AMOUNT_BOLD = 'ESTIMATE AMOUNT';
public SHIPMENT_DATE_BOLD = 'SHIPMENT DATE';
public EXPECTED_DATE_BOLD = 'EXPECTED DATE';
public QUANTITY_ORDERED_BOLD = 'QUANTITY ORDERED';
public QUANTITY_RECIEVED_BOLD = 'QUANTITY RECIEVED';
public EXPENSE_COUNT_BOLD = 'EXPENSE COUNT';
public BILL_COUNT_BOLD = 'BILL COUNT';
public JOURNAL_COUNT_BOLD = 'JOURNAL COUNT';
public AMOUNT_WITH_TAX_BOLD = 'AMOUNT WITH TAX';
public EXPENSE_AMOUNT_WITH_TAX_BOLD = 'EXPENSE AMOUNT WITH TAX';
public ERROR_MESSAGE_BOLD = 'ERROR MESSAGE';
public STACKTRACE_ERROR_MESSSAGE_BOLD='STACKTRACE ERROR MESSSAGE';
public TOTAL_BOLD='TOTAL';
public ACCOUNT_NUMBER = 'Account Number';

public SETTINGS_URL_TEXT = 'settings/';

/*report ends 9072*/

/*license starts 9072*/
public CREATE_LICENSE='Create License';
public ERP='ERP';
public CRM='CRM';
public WEB_STORE='Web Store';
public ESALES_CART='Esales Cart';
public LICENSE_NAME='License Name';
public ADDRESS_MEESAGE='Copy Billing Address';
public ADD_ORGANIZATION='Add Organization';
public LICENSE_NAME__BOLD='LICENSE NAME';
public CONTACT_NAME_BOLD='CONTACT NAME';
public PRODUCTS_BOLD='PRODUCTS';
/*license ends 9072*/

public CREATED_BY_BOLD='CREATED BY';
public CARRIER_BOLD='CARRIER';
public PORT='Port';
public PORTOCOL='Protocol';
public AUTH='Auth';
public TLS='TLS';

/*Paypal configuration Screen*/
public CLIENTID_PLACEHOLDER='Client ID';
public SECRETID_PLACEHOLDER='Secret Id';
public SANDBOXPAYMENTURL_PLACEHOLDER='Sandbox Payment URL';
public RETURN_PLACEHOLDER='Return URL';
public CANCEL_PLACEHOLDER='Cancel URL';

/*Authorised payment configuration Screen*/
public LOGINID_PLACEHOLDER='Login ID';
public TRANSACTIONKEY_PLACEHOLDER='Transaction Key';

public SHOW_TOOLTIP_CUSTOM_FIELDS = 'Not enabled for Engraving';

/*Stock Deduct Screen */
public STOCK_DEDUCT = "Stock Deduct";
public WAREHOUSE_NAME = "Warehouse Name";
public PO_RECEIVED = "PO Receive #";
public RECEIVED_QNTY = "Quantity On Hand";
public QNTY_TO_DEDUCT = "Quantity to deduct";
public QNTY_DEDUCT = "Quantity Deducted";
/*Client Invoice Screen */
public CLIENT_INVOICE = "Client Invoice";

/*Stock Request Screen */
public STOCK_REQUEST = "Stock Request";
public STOCK_REQUEST_NO = "Stock Request #";
public REQUESTED_BY = "Requested By";
/*Stock Receive Screen */
public PACKAGES = 'Packages';
/*Artwork-KSG*/
public CSA = "CSA";
public PRIMARY_CONTACT_EMAIL_VALUE = "Primary Contact Email";
public DECO_VENDOR = "Deco Vendor";
public ART_SERVICE_VENDOR = "Art Service Vendor";
public PROOF_NUMBER = "Proof#";
public GARMENT_ITEM_TYPE = "Garment Item Type";
public GRAMENT_BRAND_STYLE_NUMBER = "Garment Brand / Style#";
public GARMENT_REFERENCE_LINK = "Garment Reference Link";
public GARMENT_COLOR = "Garment Color";
public GARMENT_SIZE_RANGE = "Garment Size Range";
public DECO_LOCATION = "Logo Location";
public DECO_COLOR = "Logo Color";
public DECO_SIZE = "Logo Size";
public DECO_TYPE = "Logo Type";
public SPECIAL_INSTRUCTION = "Special Instruction";
public ADD_NEW_DECO = "Add New Deco";
public ADD_NEW_PRODUCT = "Add New Product";
public NUMBER_OF_PROOF = "NUMBER OF PROOF";
public ART_SERVICE_VENDOR_VALUE = "ART SERVICE VENDOR";
public DECO_VENDOR_VALUE = "DECO VENDOR";
public REQ_CHANES_TEXT="Let us know what needs to be changed";
public SUBMIT_FEEDBACK = "Submit Feedback";
public GARMENT_ITEM_NAME = "Garment Item Name";
public itemReturnType = "Goods";

/* superadmin create template settings */
public CREATE_TEMPLATE_SETTING=" Create Template Settings ";
public SHIPMENT_PREFERENCE = "Shipment Preference";
public DELIVERY_METHOD = "Shipment Preference";
public APPROVED_QUANTITY = "Approved Quantity";
public MODE_TERMS_OF_PAYMENT = "Mode/Terms of Payment";
public SUPPLIER_REFERENCES = "Supplier's Reference";
public OTHER_REFERENCES = "Other References";
public BUYERS_ORDER_NUMBER = "Buyer's Order Number";
public DESPATCH_DOCUMENT_NUMBER = "Despatch Document Number";
public DELIVERY_NOTE_DATE = "Delivery Note Date";
public DESPATCHED_THROUGH = "Despatched through";
public DESTINATION = "Destination";
public HSN_SAC = "HSN/SAC";
public CGST = "CGST";
public SGST = "SGST";
public PO_REFERENCE_NUMBER = "Po Reference #";
public BILL_TO_LOCATION = "Bill To Location";
public DELIVER_TO_LOCATION = "Deliver To Location";
public BILLIING_GST_NUMBER = "Billing - GST #";
public SHIPPING_GST_NUMBER = "Shipping - GST #";
public CONTACT_TYPE_VENDOR = "Contact Type - Vendor";
public SALES_ORDER = "Sales Rate";
public TAX_TYPE = "Tax Type";
public CLIENT_PRICE = "Client Price";
public PREFERRED_VENDOR = "Preferred Vendor";
public FROM_ADDRESS = "From Address";
public TO_ADDRESS = "To Address";
public CREATE_LOCATION_UNDER = "I will create more location under";
public IS_UNDER = "Is Under";
public SUPER_PARENT = "Super Parent";
public CAN_RECEIVE_STOCK = "Can Receive Stock";
public ADD_ADDRESS = "Add Address";
public PROFILENAME = "Profile Name";
public ITEM_DETAIL_LABEL = "Item Detail Label";
public ITEM_LABEL = "Item Label";
public ACCOUNT_LABEL = "Account Label";
public ITEM_TOTAL = "Item Total";
public ATTACHMENT = "Attachment";
public TEMPLATE = "Template";
public TERMS_CONDITION = "Terms & Condition";
public PAYMENT_NOTES = "Payment Notes";

/* superadmin review column*/
public DATABASE_DETAILS='Database Details'
public DATABASE_DATE="Date";
public DATABASE_SCRIPT="Script";
public DATABASE_STATUS="Status";
public DATABASE_CREATEDBY="Created By";
public DATABASE_UAT_BUTTON="UAT";
public DATABASE_PRODUCTION_BUTTON="Production";
public STATEMENTS_WITH_SEMICOLMN_SEPRATED="Enter Statements here with semicolon separated";



public RETURN_QTY = "Returned Quantity";
public CONTACT_NO = 'CONTACT #';
/* create client invoice */
public SEARCH_BUTTON = 'Search';
/*gl periods screen */
public GL_PERIODS = 'GL Periods';
public ADD_NEW_PERIOD = 'Add New Period';
public PERIOD_NO = 'Period No.';
public QUATER_NO = 'Quarter No.';
public PERIOD_NAME = 'Period Name';
public ADJUSTMENTS = 'Adjustment Period';

/*gl account settings screen*/
public GL_ACCOUNT_SETTINGS = 'GL Account Settings';
public INVOICE_LABEL = 'Invoice';
public BILL_LABEL = 'Bill';
public REC_BILL_LABEL = 'Recurring Bill';
public PAY_MADE_LABEL = 'Payments Made';
public PAY_REC_LABEL = 'Payments Received';
public VC_LABEL = 'Vendor Credits';
public CN_LABEL = 'Credit Notes';
public SALES_RECEIPT_LABEL = 'Sales Receipt';
public REFUND_RECEIPT_LABEL = 'Refund Receipt';

/*SuperAdmin Menu screen*/
public MENU_ID = 'MENU ID';
public MENU_NAME = 'MENU NAME';
public MENU_ORDER = 'MENU ORDER';
public MENU_LEVEL = 'MENU LEVEL';
public ROUTER_URL = 'ROUTER URL';
public ADD_URL = 'ADD URL';
public ICON_NAME = 'ICON NAME';
public MODULE_NAME = 'MODULE NAME';
public MENU_NAME_PLACEHOLDER = 'Menu Name';
public MENU_ORDER_PLACEHOLDER = 'Menu Order';
public MENU_LEVEL_PLACEHOLDER = 'Menu Level';
public ROUTER_URL_PLACEHOLDER = 'Router Url';
public ADD_URL_PLACEHOLDER = 'Add Url';
public MODULE_NAME_PLACEHOLDER = 'Module Name';
public ICON_NAME_PLACEHOLDER = 'Icon Name';
public SELECT_PARENT = 'Select Parent';



/*Dispatch package*/
public TRACKING_NUM = 'Tracking Number';
public SHIPPING_NUMBER = 'Shipping Number';
public SHIPMENT_DATE = 'Shipment Date';
public SHIPPING_CHARGES = 'Shipping Charges';
public ESTIMATED_DELIVERY_DATE = 'Estimated Delivery Date';
public SHIPMENT_PROCESS_SERVICE_TYPE = 'Shipment Process Service Type';

/*Part Request Screen */
public PART_NAME_BOLD = 'PART NAME';
public PART_NUMBER_BOLD = 'PART #';
public PART_DESC = 'PART DESCRIPTION';
public PART_DEDUCT = 'Part Deduct';
public IS_DEFECTIVE = 'Is Defective';
/*Part Recieve Screen */
public PART_NAME = 'Part Name';
public REPORT_TITLE_INVENTORY_AGING_DETAILS='Inventory Aging Details';
public REPORT_TITLE_INVENTORY_TALLY_REPORT='Inventory Tally';
public BILLING_REPORT = "Billing Report";

/*Review Display screen*/
public RDC_MODULE_NAME = 'MODULE NAME';
public COLUMN_NAME = 'COLUMN NAME';
public RDC_LABEL_NAME = 'LABEL NAME';
public COLUMN_NAME_PLACEHOLDER = 'Column Name';
public LABEL_NAME_PLACEHOLDER = 'Label Name';

/*Appraisal Master screen*/
public APPRAISAL_BOLD = 'APPRAISAL LEVEL';
public APPRAISAL_LEVEL = 'Apraisal Level';
public APPRAISAL_ASSIGNED_DATE = 'APPRAISAL ASSIGNED DATE';
public SUB_APPRAISAL_BOLD ='JD POINTS';
public APPLY_APPRAISAL_FORM ='Apply Appraisal Form';
public ONE_TO_ONE_DISCUSSION_FLAG = 'One to One Discussion Completed';
public ONE_TO_ONE_DISCUSSION_COMMENTS = 'Enter your Comments / Feedback on One to One Discussion';
public APPRAISAL_APPROVED = 'Approve the Appraisal';
public APPRAISAL_REJECTED = 'Reject the Appraisal';
public APPRAISAL_HOLD = 'Mark as Hold';
public APPRAISAL_STATUS = 'APPRAISAL STATUS';
public APPRAISAL_STATUS_REPORT = 'Appraisal Status';
public APPRAISAL_DATE = 'APPRAISAL DATE'

/*Appraisal Form screen*/
public SELF_APPRAISAL ='SELF APPRAISAL';
public APPRAISER ='APPRAISER';

public APPROVE_TO_NEXT_LEVEL = 'Approve to Next Level';
public RESOURCE_WISE_PROJECT_ALLOCATION_REPORT = 'Resource wise Project Allocation Report';
public PROJECT_WISE_RESOURCE_ALLOCATION_REPORT = 'Project wise Resource Allocation Report';
public LEAVE_HISTORY = 'Leave History';
public ATTENDANCE_HISTORY = 'Attendance History';
public TIMESHEET_HISTORY = 'Timesheet History';
public HRIS_TIMESHEET_HISTORY = 'Timesheet History';
public QB_NOTSYNCHED_INVOICE = 'QB NotSynched Invoice';
public WAREHOUSE_REPORT_SUMMARY = 'Warehouse Report';

public EXPENSE_NUMBER ='EXPENSE #';

/*View PO Receive Screen*/
public PO_RECEIVE_NUMBER = 'PO RECEIVE #';
public PO_RECEIVE_DATE = 'PO RECEIVE DATE';

/*View SO Receive Screen*/
public SO_RECEIVE_NUMBER = 'SO RECEIVE #';
public SO_RECEIVE_DATE = 'SO RECEIVE DATE';

/*Create Template Screen*/
public CUSTOMER_CREATE_TEMPLATE = 'CUSTOMER';
public VENDOR_CREATE_TEMPLATE = 'VENDOR';
public fontNormal = "fontNormal";
public fontBold = "fontBold";
public currentFocus = "CREATE_TEMPLATE_INITIAL";

/*Create Account screen*/
public AS_OF ="as of";

/*Create currency screen*/
public AFN = "AFN";
public CURRENCY_CODE = "Currency Code";
public CURRENCY_SYMBOL = "Currency Symbol";
public DECIMAL_PLACE = "Decimal Place";
public FORMAT ="Format";
public SELECTCURRENCY ="Select Currency";

/*Create contct FP*/
public REFERENCE_FILED_1 = "ReferenceField 1";
public REFERENCE_FILED_2 = "ReferenceField 2";
public REFERENCE_FILED_3 = "ReferenceField 3";
public REFERENCE_FILED_4 = "ReferenceField 4";

/*VIew Default Top*/
public PROJECT = "Project";
public TASK_TYPE = "Task Type";
public SELECT_LICENSE = "Select License";
public FILTER_BY = "Filter By";

//serachIngrid popup
public SELECT_EXPENSE_ACCOUNT = "Select Expense Account";
public SELECT_PAID_THROUGH = "Select Paid Through";
public SELECT_PROJECTS = "Select Projects";
public SELECT_PROJECT = "Select the Project";
public SELECT_CUSTOMER_NAME = "Select Customer Name";
public SELECT_PLACEHOLDER = "Select Placeholder";
public TAX_NAME = "Tax Name";
public RATE_PERCENTAGE = "Rate (%)";
public FIELD_1 = "Field 1";
public CONTACT_DISPLAY_NAME = "Contact Display Name";
public SELECT_STATUS = "Select Status";
public DELIVERY_FROM_DATE = "Delivery From Date";
public DELIVERY_TO_DATE = "Delivery To Date";
public MIN_RANGE = "Min Range";
public MAX_RANGE = "Max Range";
public SO_FROM_DATE = "SO From Date";
public SO_TO_DATE = "SO To Date";
public SHIPMENT_FROM_DATE = "Shipment From Date";
public SHIPMENT_TO_DATE = "Shipment To Date";
public BILL_FROM_DATE = "Bill From Date";
public BILL_TO_DATE = "Bill To Date";
public DUE_FROM_DATE = "Due From Date";
public DUE_TO_DATE = "Due To Date";
public ESTIMATE_FROM_DATE = "Estimate From Date";
public ESTIMATE_TO_DATE = "Estimate To Date";
public RESERVATION_FROM_DATE = "Reservation From Date";
public RESERVATION_TO_DATE = "Reservation To Date";
public EXPIRY_FROM_DATE = "Expiry From Date";
public EXPIRY_TO_DATE = "Expiry To Date";
public START_DATE_FROM = "Start Date From";
public START_DATE_TO = "Start Date To";
public END_DATE_FROM= "End Date From";
public END_DATE_TO = "End Date To";
public SELCET_PAYMENT_MODE = "Select Payment Mode";
public EXPENSE_FROM_DATE = "Expense From Date";
public EXPENSE_TO_DATE = "Expense To Date";
public PAYMENT_FROM_DATE = "Payment From Date";
public PAYMENT_TO_DATE = "Payment To Date";
public CREDITS_FROM_DATE = "Credit From Date";
public CREDITS_TO_DATE = "Credit To Date";
public INVOICE_FROM_DATE = "Invoice From Date";
public INVOICE_TO_DATE = "Invoice To Date";
public RETAINER_INVOICE_NUMBER = "Retainer Invoice#";
public ASSET_CATEGORY = "Asset Category";
public EMAIL_ADDRESS = "Email Address";
public ITEM_DESCRIPTION = "Item Description";
public PAYCODE_NAME = "Paycode Name";
public ARTWORK_NUMBER = "Artwork#";
public ARTWORK_FROM_DATE = "ArtworK From Date";
public ARTWORK_TO_DATE = "ArtworK To Date";
public SELCTED_FILTER_TYPE = "Select Filter Type";
public CREATED_FROM_DATE = "Created From Date";
public CREATED_TO_DATE = "Created To Date";
public SEARCH_BY_CREATED_DATE = "Search by Created Date";

//Appraial
public SELCTED_FILTER_PERIOD = "Select Filter Period";
public SELF_APRAISER_COMMENTS = "Self Appraiser Comments";
public APRAISER_1_COMMENT = "Appraiser 1 Comments";
public APRAISER_2_COMMENT = "Appraiser 2 Comments";
public APRAISER_3_COMMENT = "Appraiser 3 Comments";
public APRAISER_4_COMMENT = "Appraiser 4 Comments";
public APRAISER_5_COMMENT = "Appraiser 5 Comments";

//Medical Bill
public DEPENDENT_NAME = "Dependent Name";
public TYPE_HERE_TO_SEARCH = "Type here to search..";

//Shipping
public WEIGHT = "Weight";
public LENGTH = "Length";
public HEIGHT="Height";
public WIDTH = "Width";
public NUMBER_OF_PACKAGES="Number of Packages";
public FEDEX_PACKAGING_TYPE="FedEx Packaging Type";
public FEDEX_DROPOFFTYPE="FedEx Dropoff Type";

//settings
public ITEM_COLUMN="Item Column";
public INVENTORY_COLUMN="Inventory Column";
public CONDITION="Condition";
public TEXT_CONDITION="Text Condition";
public FETCH_CONDITION="Fetch Condition";
public ADD_COLUMN_STRING="Column Header String";
public LEAVE_HISTORY_COLUMN="Leave History Column";

//Create Test Case
public END_POINT_URL = "EndPointURL";
public REQUEST = "Request";
public RESPONSE = "Response";
public SELECT_CONTROL_NAME = "Select Control Name";


//Others
public TEMPLATE_NAME_SMALL = "Template Name";
public FROM = "From";
public CC = "Cc";
public BCC = "Bcc";
public PLACEHOLDER = "Placeholder";
public GRAND_TOTAL = "Grand Total";

//Manage category
public MANAGE_CATEGORY = "Category Name";

//Database Detail
public IP = "IP";
public SELECT_ENVIRONMENT = "Select Environment";

// Shift Master
public ADD_SHIFT = "Add Shift";
public SHIFT_NAME = "Shift Name";
public START_TIME = "Start Time";
public RESCHEDULE_TIME = "Reschedule Time";
public END_TIME = "End Time";
public SHIFT_NAME_BOLD = "SHIFT NAME";
public START_TIME_BOLD = "START TIME";
public END_TIME_BOLD = "END TIME";
public SHIFT_START_DATE = "START DATE";
public SHIFT_END_DATE = "END DATE";

//Shift Allocation
public ALLOCATE_BUTTON = "Allocate";
public ALLOCATE_SHIFT = "Allocate Shift";
public SELECT_EMPLOYEE = "Select Employee";
public SELECT_SHIFT_NAME = "Select Shift Name";
public SALARY_GROUP_BOLD = "SALARY GROUP";

// Reports
public TASK_REPORT = "Task Report";
public TIMESHEET_REPORT = "Timesheet Report";
public APPRAISAL_DETAIL_REPORT = "Appraisal Detail Report";

// Fedex Configuration
public FEDEX_CONFIGURATION = "Fedex Configuration";
public KEY_PLACEHOLDER = "Authentication Key";
public ACCOUNTNO_PLACEHOLDER = "Account Number";
public METERNO_PLACEHOLDER = "Meter Number";
public SMART_POST_HUB_ID_PLACEHOLDER = "Hub Id";
public ENDPOINT_URL_PLACEHOLDER = "EndPoint URL";

public EMAIL_SETTINGS_CONFIGURATION = 'Email Settings Configuration';

//Product Set up screen
public PRODUCT_SETUP = 'Product Setup';
public ITEM_NUMBER = 'Item #';
public STYLE_NUMBER = 'Style #';
public COLOR_NAME = 'Color Code / Name';
public ADD_PRODUCT = 'Add Product';
public ADD_SIZE = 'Add Size';
public ADD_COLOR = 'Add Color';
public ADD_MORE_SIZES = 'Add More Sizes';
public ADD_MORE_COLORS = 'Add More Colors';
public ENTER_VALUE = 'Enter Values';
public ADD_VALUE = 'Add Values';
public ADD_PARENT = 'Add Parent';
public ADD_CHILD = 'Add Child';
public CLONE_PARENT = 'Clone Parent';
public ADD_MORE_CHILD = 'Add More Child';

public SEARCH_TEXT = 'Please type into search';

/******************************RESERVATION SCREEN - Starts*******************************/
public SALES_REP_EMAIL = "Sales Rep Email";
public NO_OF_PARTICIPANTS = "No Of Participants";
public PROJECTION = "Projection";
public CUSTOM_MODEL = "Custom Model";
/******************************RESERVATION SCREEN - Ends*******************************/
/****************Nistar Receiving screen starts ******************/
public PURCHASE_DATE = "PUCHASE DATE";
public ORDER_STATUS = "ORDER STATUS";
public DISCREPANCIES = "DISCREPANCIES"; 
/****************Nistar Receiving screen ends******************/
/****************Candidate Screen Starts***********************/
public NEW_CANDIDATE = "New Candidate";
public CANDIDATE_CODE = "CANDIDATE CODE";
public CANDIDATE_NAME = "CANDIDATE NAME";
public CANDIDATE_MOBILE = "MOBILE NUMBER";
public CANDIDATE_EMAIL = "EMAIL";
public CANDIDATE_SOURCE = "SOURCE";
public CANDIDATE_INDUSTRY = "INDUSTRY";
public TOTAL_YRS_OF_EXP = "TOTAL YRS OF EXP";
public CANDIDATE_EDUCATION = "EDUCATION";
public CANDIDATE_EXPERIENCE = "EXPERIENCE";
public TOTAL_YRS_OF_EXP_CREATE = "Total yrs of Exp";
public FACEBOOK_ID = "Facebook Id";
public LINKEDIN_ID = "LinkedIn Id";
public PROFILE_SUMMARY = "Profile Summary";
public REFERRAL = "Referral";
public QUALIFICATION_NAME = "Qualification";
public PASSEDOUTYR = "Passed Out Year";
public INSTITUTE_NAME = "Institute Name";
public RESULT = "Result";
public EMPLOYEER_NAME = "Employer";
public STARTEDWORK = "Started Working From";
public ENDWORK = "Worked Till";
public JOB_ROLE = "Job Role";
public CURRENR_WORK = "I Currently Work here";
public READING = "Reading";
public WRITING = "Writing";
public SPEAKING = "Speaking";
public ADD_LANGUAGE = "Add Language";
public ADD_QUALIFICATION = "Add Qualification";
public ADD_EXPERIENCE = "Add Experience";
public ADD_REFERENCE = "Add Reference";
public CANDIDATE_NUMBER_ALERT = "Candidate # is";
public CANDIDATE_EXISTS_ALERT = "Candidate # already exist";
public SECONDARY_EMAIL = "Secondary Email";
public SECONDARY_MOBILE_NUMBER = "Secondary Mobile Number";
public CURRENT_SALARY = "Current Salary";
public EXPECTED_SALARY = "Expected Salary";
public EXPECTED_SALARY_BOLD = "EXPECTED SALARY";
public NOTICE_PERIOD = "Notice Period";
public NOTICE_PERIOD_BOLD = "NOTICE PERIOD";
public REASON_FOR_JOB_CHANGE = "Reason for Job Change";
public JOBSITE_NAME = "Job Site Name"
public SEARCH_KEYWORD = "Search Keyword";
public CURRENT_LOCATION = "Current Location";
public CURRENT_LOCATION_BOLD = "CURRENT LOCATION";
public PREFERRED_LOCATION = "Preferred Location";
public EDUCATION = "Education";
public EXPERIENCE = "Experience";
public LANGUAGE_PROFICIENCY = "Language Proficiency";
public REFERENCE_NAME = "Reference Name";
public REFERENCE = "Reference";
public ADDRESS_LINE = "Address Line";
public ATTACHEMENTS = "Attachments";
public ZIPCODE = "Zipcode";
/****************Candidate Screen ends***********************/
/****************Appointment Screen Starts***********************/
public INTERVIEW_NAME = "Interview Name";
public INTERVIEW_NAME_BOLD = "INTERVIEW ROUND";
public TIMEZONE = "Timezone";
public TIMEZONE_BOLD = "TIMEZONE";
public INTERVIEWER_NAME = "Interviewer Name";
public INTERVIEWER_NAME_BOLD = "INTERVIEWER NAME";
public INTERVIEW_DATE = "Interview Date";
public INTERVIEW_TYPE = "Interview Type";
public INTERVIEWER_EMAIL = "Interviewer Email";
public SELECT_SLOT = "Select Slot";
public INTERVIEW_STATUS = "Status";
public INTERVIEW_BOLD = "STATUS" ;
/****************Appointment Screen Ends***********************/
/****************Interview Round Starts************************/
public INTERVIEWER_COMMENTS = "Comments";
public SALARY_FROM = "Salary Start Range";
public SALARY_TO = "Salary End Range";
public RESCHEDULE_DATE = "Reschedule Date";
public RESCHEDULE_REASON = "Reason";
/****************Interview Round Ends************************/
/****************Training Feedback Report */
public  TRAINING_FEEDBACK_REPORT='Training Feedback Report';
	public INDUCTION_DONE_BY='Induction Done By';
	public TECHNOLOGIES_USED='Technologies Used';
	public SKILLS='Skills';
	public TRAINING_START='Training Start Date';	
	public TRAINING_END='Training End Date';
/****************Trainig Feedback Report Ends */

	getAddLink(moduleName: string, editId: any, portalName?): string {		
	 	if (moduleName === 'VIEW_ITEM' ) {return '/itemsmodule/newitem/' + editId; }
	 	if (moduleName === 'VIEW_ITEMS') {return '/itemsmodule/newItems/' + editId;}
		if (moduleName === 'VIEW_CONTACT' || moduleName === 'NEW_CONTACT' || moduleName === 'TS_VIEW_CONTACT') { return '/contacts/createcontacts/' + editId; }
		if (moduleName === 'VIEW_PO' || moduleName === 'NEW_PO' || moduleName === 'TS_VIEW_PO') {return 'purchasemodule/createpo/' + editId; }
		if (moduleName === 'VIEW_BILL' || moduleName === 'NEW_BILL' || moduleName === 'TS_VIEW_BILL' ) {return 'purchasemodule/createbill/' + editId; }
		if (moduleName === 'VIEW_REFUND_RECEIPT' || moduleName === 'NEW_REFUND_RECEIPT' || moduleName === 'TS_VIEW_REFUND_RECEIPT' ) {return 'sales/createRefundReceipt/' + editId; }
		if (moduleName === 'VIEW_R_BILL' || moduleName === 'NEW_R_BILL' || moduleName === 'TS_VIEW_R_BILL') {return 'purchasemodule/createrbill/' + editId; }
		if (moduleName === 'VIEW_EXPENSE' || moduleName === 'TS_VIEW_EXPENSE') {return 'purchasemodule/createexpenses/' + editId; }
		if (moduleName === 'VIEW_R_EXPENSE' || moduleName === 'TS_VIEW_R_EXPENSE') {return 'purchasemodule/createInvoice/' + editId; }
		if (moduleName === 'VIEW_PAYMENT_MADE' || moduleName === 'TS_VIEW_PAYMENT_MADE') {return '/purchasemodule/createpayment/' + editId; }
		if (moduleName === 'VIEW_VENDOR_CREDIT' || moduleName === 'NEW_VENDOR_CREDIT' || moduleName === 'TS_VIEW_VENDOR_CREDIT') {return 'purchasemodule/createvendorcr/' + editId; }
		if (moduleName === 'VIEW_ARTWORK' || moduleName === 'NEW_ARTWORK' || moduleName === 'TS_VIEW_ARTWORK') {return 'invoice/createartwork/' + editId; }
		if (moduleName === 'VIEW_ESTIMATE' || moduleName === 'NEW_ESTIMATE' || moduleName === 'TS_VIEW_ESTIMATE') {return 'invoice/createestimates/' + editId; }
		if (moduleName === 'VIEW_SALESORDER' || moduleName === 'NEW_SALESORDER' || moduleName === 'TS_VIEW_SALESORDER') {return '/invoice/createsalesorder/' + editId; }
		if (moduleName === 'VIEW_INVOICE' || moduleName === 'NEW_INVOICE' || moduleName === 'TS_VIEW_INVOICE') {return 'invoice/createInvoice/' + editId; }
		if (moduleName === 'VIEW_R_INVOICE' || moduleName === 'NEW_R_INVOICE' || moduleName === 'TS_VIEW_R_INVOICE') {return 'invoice/creatrinvoice/' + editId; }
		if (moduleName === 'VIEW_PAYMENT_RECIEVED' || moduleName === 'TS_VIEW_PAYMENT_RECIEVED') {return 'invoice/createPaymentsReceived/' + editId; }
		if (moduleName === 'VIEW_CREDIT_NOTES' || moduleName === 'NEW_CREDIT_NOTES' || moduleName === 'TS_VIEW_CREDIT_NOTES') {return '/invoice/createcreditnotes/' + editId; }
		if (moduleName === 'VIEW_EMPLOYEE') {return '/hrmmodule/newemployee/' + editId; }
		if (moduleName === 'VIEW_CANDIDATE') {return '/recruitment/createcandidate/' + editId; }
		if (moduleName === 'VIEW_PAYCODE') {return '/hrmmodule/addpaycode/' + editId; }
		if (moduleName === 'VIEW_APPOINTMENT') {return '/recruitment/createappointment/' + editId; }
		if (moduleName === 'VIEW_LOAN') {return '/hrmmodule/addloan/' + editId; }
		if (moduleName === 'VIEW_PACKAGES' || moduleName === 'TS_VIEW_PACKAGES') {return '/invoice/createshipping/' + editId; }
		if (moduleName === 'VIEW_ASSETS') {return '/assetmodule/createasset/' + editId; }
		if (moduleName === 'VIEW_PROJECT') {return '/projectmodule/createproject/' + editId; }
	    if (moduleName === 'VIEW_INDENT') {return 'assetmodule/createindent/' + editId;}
	    if (moduleName === 'VIEW_SALES_PERSON' || moduleName === 'TS_VIEW_SALES_PERSON') {return '/contacts/createsalesperson/' + editId; }
	    if (moduleName === 'VIEW_ITEMS_GROUP' || moduleName === 'TS_VIEW_ITEMS_GROUP') {return 'itemsmodule/newitemGroup/' + editId;}
	    if(moduleName==='VIEW_PRICE_LIST' || moduleName === 'TS_VIEW_PRICE_LIST'){return 'itemsmodule/createpricelists/' + editId;}
	    if(moduleName==='VIEW_LICENSE'){return 'superadmin/createLicense/' + editId;}
	    if(moduleName==='VIEW_ARTWORK_REQUEST' || moduleName === 'NEW_ARTWORK_REQUEST'){return 'artwork/createArtworkRequest/' + editId;}
	    if (moduleName === 'VIEW_CLIENT_INVOICE') {return 'invoice/createClientInvoice/' + editId; }
        if (moduleName === 'VIEW_STOCK_REQUEST') {return 'sales/createstockrequest/' + editId;}
        if (moduleName === 'VIEW_APPROVE_STOCK_REQUEST') {return 'sales/createapprovestock/' + editId;}
        if (moduleName === 'VIEW_DATABASE') {return 'superadmin/database/' + editId;}
        if (moduleName === 'VIEW_RETURNS') {return 'sales/createStockReturns/' + editId;}
        if (moduleName === 'VIEW_RECEIVE_RETURNS') {return 'sales/createStockReturns/' + editId;}
        if (moduleName === 'VIEW_DELIVERY_CHALLAN_RETURN') {return 'sales/createReturns/' + editId;}
        if (moduleName === 'VIEW_RECONCILIATION') {return 'accounts/createreconciliation/' + editId;}   
        if (moduleName === 'VIEW_ACCOUNT_BUDGET') {return 'accounts/createAccountBudget/' + editId;} 
        if (moduleName === 'VIEW_JOURNAL') {return 'accounts/createmj';}  
        if (moduleName === 'VIEW_PO_RECEIVE') {return 'purchasemodule/createpo/' + editId; }
        if (moduleName === 'VIEW_SO_RECEIVE') {return '/invoice/createsalesorder/' + editId; }
        if (moduleName === 'VIEW_INTERVIEW_SLOT') {return '/recruitment/newinterviewslot/' + editId; }
        if (moduleName === 'VIEW_SALES_RECEIPT' || moduleName === 'NEW_SALES_RECEIPT') {return 'sales/createSalesReceipt/' + editId; }
        if (moduleName === 'VIEW_RESERVATION' || moduleName === 'NEW_RESERVATION' ) {return 'invoice/createreservation/' + editId; }
        if (moduleName === 'VIEW_RECEIVINGS') {return '/itemsmodule/createOrder/' + editId;}
		if (moduleName === 'MANAGE_FEEDBACK_MYCORNER') {return '/mycornermodule/provideFeedback';}
		if (moduleName === 'VIEW_ANNOUNCEMENTS') {return '/hrmmodule/newAnnouncements/'+ editId;}
		if(moduleName === 'REVIEW_TRAINEE'){return '/hrmmodule/editTrainee/'+editId;}
	}

	getAddSecondLink(moduleName: string, editId: any): string {
		if (moduleName === 'VIEW_PO') {return 'purchasemodule/poreceive/' + editId; }
		if (moduleName === 'Convert_To_Bill') {return '/purchasemodule/createbill/' + editId + '/true'; }
		if (moduleName === 'VIEW_SALESORDER') {return '/invoice/socreatepackage/' + editId; }
		if (moduleName === 'Convert_To_Invoice') {return '/invoice/createInvoice/' + editId + '/true'; }
		if (moduleName === 'Estimate_To_Convert_To_Invoice') {return '/invoice/createInvoice/' + editId + '/estToInvoicetrue'; }
		if (moduleName === 'VIEW_PACKAGES') {return '/sales/reviewsalesorder/'}
		if (moduleName === 'Convert_To_SalesOrder') {return '/invoice/createsalesorder/' + editId + '/true'; }
		if (moduleName === 'NEW_R_INVOICE') {return 'invoice/createInvoice/' + editId+ '/newInvoice'; }
		if (moduleName === 'NEW_R_BILL') {return '/purchasemodule/createbill/' + editId+ '/convertBill';}
		if (moduleName === 'Artwork_To_SalesOrder') {return '/invoice/createsalesorder/' + editId + '/convertSales'; }
		if (moduleName === 'NEW_RETURNS') {return 'sales/createStockReturns/' + editId+ '/redirectReturnsFlag'; }
		if (moduleName === 'Convert_To_PO') {return '/purchasemodule/createpo/' + editId + '/convertPO'; }
		if (moduleName === 'SalesOrder_To_PurchaseOrder') {return '/purchasemodule/createpo/' + editId + '/convertToPurchase'; }
		if (moduleName === 'Reservation_To_SalesOrder') {return '/invoice/createsalesorder/' + editId + '/reservToSales'; }
		if (moduleName === 'Convert_To_Employee') {return '/hrmmodule/newemployee/' + editId+ '/convertToEmployee'; }		
		if(moduleName === 'REVIEW_TRAINEE'){return '/hrmmodule/editTrainee/'+editId;}

	}
	getRedirectCloneLink(moduleName: string, editId: any,cloneFlag:string): string {
	
		
		if (moduleName === 'VIEW_PO' || moduleName=== 'NEW_PO') {return 'purchasemodule/createpo/' + editId +'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_CONTACT') {return '/contacts/createcontacts/' + editId + '/'+cloneFlag;}
		if (moduleName === 'VIEW_ITEM') {return '/itemsmodule/newitem/' + editId+ '/'+cloneFlag;}
		if (moduleName === 'VIEW_ITEMS') {return '/itemsmodule/newItems/' + editId+ '/'+cloneFlag;}
		if (moduleName === 'VIEW_BILL' || moduleName === 'NEW_BILL') {return 'purchasemodule/createbill/' + editId+'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_REFUND_RECEIPT') {return 'sales/createRefundReceipt/' + editId+'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_R_BILL' || moduleName === 'NEW_R_BILL') {return 'purchasemodule/createrbill/' + editId+ '/'+cloneFlag;}
		if (moduleName === 'VIEW_EXPENSE' || moduleName === 'NEW_EXPENSE') {return 'purchasemodule/createexpenses/' + editId+ '/'+cloneFlag;}
		if (moduleName === 'VIEW_PAYMENT_MADE') {return '/purchasemodule/createpayment/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_VENDOR_CREDIT' || moduleName === 'NEW_VENDOR_CREDIT') {return 'purchasemodule/createvendorcr/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_ESTIMATE' || moduleName === 'NEW_ESTIMATE') {return 'invoice/createestimates/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_SALESORDER' || moduleName === 'NEW_SALESORDER') {return '/invoice/createsalesorder/' + editId+'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_SALES_RECEIPT' || moduleName === 'NEW_SALES_RECEIPT') {return 'sales/createSalesReceipt/' + editId+'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_INVOICE' || moduleName === 'NEW_INVOICE') {return 'invoice/createInvoice/' + editId+'/'+'clone' + '/'+cloneFlag; }
		if (moduleName === 'VIEW_R_INVOICE' || moduleName === 'NEW_R_INVOICE') {return 'invoice/creatrinvoice/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_PAYMENT_RECIEVED') {return 'invoice/createPaymentsReceived/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_CREDIT_NOTES' || moduleName === 'NEW_CREDIT_NOTES') {return '/invoice/createcreditnotes/' + editId+ '/'+cloneFlag; }
		/*if (moduleName === 'VIEW_ARTWORK_REQUEST') {return '/invoice/createartworkrequest/' + editId+ '/'+cloneFlag; }*/
	    if (moduleName === 'VIEW_CLIENT_INVOICE') {return 'invoice/createClientInvoice/' + editId+ '/'+cloneFlag; }
	    if (moduleName === 'VIEW_ARTWORK' || moduleName === 'NEW_ARTWORK') {return '/invoice/createartwork/' + editId+ '/'+cloneFlag; }
	    if (moduleName === 'VIEW_ARTWORK_REQUEST') {return '/artwork/createArtworkRequest/' + editId+ '/'+cloneFlag; }
	    if (moduleName === 'VIEW_RESERVATION' || moduleName === 'NEW_RESERVATION') {return 'invoice/createreservation/' + editId+ '/'+cloneFlag; }
		if (moduleName === 'VIEW_APPOINTMENT') {return '/recruitment/createappointment/' + editId + '/'+cloneFlag; }

	}
	getRedirectToBackOrder(moduleName: string, id: number, flag:string): string{
		if (moduleName === 'NEW_SALESORDER') {return 'purchasemodule/createpo/' + id +'/'+'backOrder' + '/'+flag; }
	}

/*getRedirectLink(moduleName: string, editId?: number): string {
	
	if(this.commonService.appScreenMap.has(moduleName))
		{
			return this.commonService.appScreenMap.get(moduleName).redirectLink;
		}


	 if (moduleName === 'NEW_CONTACT') {return '/contacts'}
	if (moduleName === 'NEW_ITEM') {return '/itemsmodule'}
	if (moduleName === 'NEW_ITEMS') {return '/itemsmodule/viewItems'}
    if (moduleName === 'NEW_PO') {return '/purchasemodule/reveiwpo'}
    if (moduleName === 'NEW_BILL') {return '/purchasemodule/reviewbill'}
    if (moduleName === 'NEW_R_BILL') {return '/purchasemodule/reviewrbill'}
    if (moduleName === 'NEW_EXPENSE') {return '/purchasemodule/reviewexpenses'}
    if (moduleName === 'NEW_R_EXPENSE') {return '/purchasemodule/reviewRecurrExpenses'}
    if (moduleName === 'NEW_PAYMENT_MADE') {return '/purchasemodule/viewpayment'}
    if (moduleName === 'NEW_VENDOR_CREDIT') {return '/purchasemodule/reviewvendorcr'}
   	if (moduleName === 'NEW_EMPLOYEE') {return '/hrmmodule'}

	if (moduleName === 'NEW_ARTWORK') {return '/sales/reviewartwork'}
 	if (moduleName === 'NEW_ESTIMATE') {return '/sales/reviewestimates'}
    if (moduleName === 'NEW_SALESORDER') {return '/sales/reviewsalesorder'}
    if (moduleName === 'NEW_INVOICE') {return '/sales/reviewinvoice'}
    if (moduleName === 'NEW_R_INVOICE') {return '/sales/reviewrinvoice'}
    if (moduleName === 'NEW_PAYMENT_RECIEVED') {return '/sales/reviewPaymentsReceived'}
    if (moduleName === 'NEW_CREDIT_NOTES') {return '/sales/reviewcreditnotes'}
    if (moduleName === 'NEW_PROJECT') {return '/projectmodule/reviewproject'}
    if (moduleName === 'NEW_INDENT') {return 'assetmodule/reviewindent'}
    if (moduleName === 'APPLY_LEAVE') {return 'mycornermodule/applyleave'}
    if(moduleName==='New_PayCode') {return 'hrmmodule/viewpaycode'}
    if(moduleName==='New_Assets'){return 'assetmodule/reviewasset'}
    if (moduleName === 'NEW_SALES_PERSON') {return '/contacts/reviewsalesperson'}
    if(moduleName==='NEW_ITEMS_GROUP'){return 'itemsmodule/reviewItemsGroup'}
    if(moduleName==='NEW_PRICE_LIST'){return 'itemsmodule/reviewpricelists'}
    if(moduleName==='NEW_CATEGORY'){return 'settings/reviewCategory'}
    if(moduleName==='NEW_JOURNAL'){return '/accounts/createmj'}
    if(moduleName==='NEW_LICENSE'){return '/superadmin/viewLicense'}
    if (moduleName === 'VIEW_PACKAGES') {return '/sales/reviewpackages'}
    if(moduleName==='NEW_ARTWORK_REQUEST'){return 'sales/reviewArtworkrequest'}
    if (moduleName === 'NEW_STOCK_REQUEST') {return 'sales/reviewstockrequest'}
    if (moduleName === 'CREATE_DELIVERY_CHALLAN_RETURN') {return 'sales/reviewReturns'}
    if (moduleName === 'NEW_APPROVE_STOCK_REQUEST') {return 'sales/reviewapprovestock'}
    if(moduleName === 'NEW_RETURNS') {return 'sales/reviewStockReturns'}
    if(moduleName === 'NEW_CLIENT_INVOICE') {return 'invoice/reviewClientInvoice'}
    if(moduleName === 'VIEW_DISPATCH_PACKAGE') {return 'invoice/dispatchPackage'}	
    if(moduleName === 'VIEW_DISPATCH_RETURNS') {return 'invoice/dispatchReturns'}	
  }*/

getRedirectLinkFromCreatecontactPage(moduleName: string, editId?: number): string {

	if (moduleName === 'VIEW_ESTIMATE') {return '/invoice/createestimates/0/false/null/' + editId ; }
		if (moduleName === 'VIEW_SALESORDER') {return '/invoice/createsalesorder/0/false/null/' + editId ; }
		if (moduleName === 'VIEW_INVOICE') {return 'invoice/createInvoice/0/false/null/' + editId ; }
	  	if (moduleName === 'VIEW_CREDIT_NOTES') {return '/invoice/createcreditnotes/0/false/null/' + editId ; }
		if (moduleName === 'VIEW_ARTWORK_REQUEST') {return '/invoice/createartworkrequest/0/false/null/' + editId ; }

  }

 patchDates(inputForm,formControlName,value){
	   inputForm.controls[formControlName].setValue(new Date(value),{onlySelf:true});
	  // inputForm.get(formControlName).setValue(new Date(inputForm.get(formControlName).value),{onlySelf:true});
	 }

patchValue(inputForm,formControlName,value){
	   inputForm.controls[formControlName].setValue(value,{onlySelf:true});
	  // inputForm.get(formControlName).setValue(new Date(inputForm.get(formControlName).value),{onlySelf:true});
	 }
	patchDatesInList(inputFormArray,formControlName){
		
		if(inputFormArray != null && inputFormArray != undefined && inputFormArray.length > 0){
			for(var loop=0; loop<inputFormArray.length;loop++){
				let frmGrp = inputFormArray.controls[loop];
				frmGrp.get(formControlName).setValue(new Date(frmGrp.get(formControlName).value),{onlySelf:true});
			}
		}
	  }


// THIS BELOW METHOD IS USED TO DISPLAY THE DATE FORMAT AS DYNAMICALLY WHILE EDIT AND PATCH THE FORM VALUES IN VIEW SCREENS, 
// AND WE HAVE TO PASS THE HTML DOM ELEMENT IN THE FIRST ARGUMENT.
 patchDates2(elem,inputForm,formControlName,value){
 	
 	let dp = new DatePipe(navigator.language);
	let p = 'yyyy/MM/dd'; 
	elem.value = dp.transform(new Date(value), p);
 
 }


    setValidationForPage(inputForm:FormGroup,data:any,
    	t100ContactCustomerId?:FormGroup,t100ContactVendorId?:FormGroup){
    	for(var loop=0; loop < data.length; loop++){

    			/*this is for validating the immediate controls inside the form*/
	    		if(data[loop].parent === true && data[loop].level === 0){
	    			if(this.checkNullOrUndefined(inputForm.get(data[loop].controlName))){ 
		    	 		(inputForm.get(data[loop].controlName)).setValidators(this.formValidatorArray(data[loop]));
		    	 		(inputForm.get(data[loop].controlName)).markAsTouched();		      		
		    			(inputForm.get(data[loop].controlName)).updateValueAndValidity();
		     		}
	    		}

	    		/*this is for validating the objects inside the form*/
	    		if(data[loop].parent === true && data[loop].level === 1){
	    			var splitString = (data[loop].controlName).split(",");
	    			if(this.checkNullOrUndefined(inputForm.get(splitString[0]))){   		 	
		    			(inputForm.get(splitString[0]) as FormGroup).get(splitString[1]).setValidators(this.formValidatorArray(data[loop]));
		    			(inputForm.get(splitString[0]) as FormGroup).get(splitString[1]).markAsTouched();		    	 	
		    			(inputForm.get(splitString[0]) as FormGroup).get(splitString[1]).updateValueAndValidity();
					}
	    		}	    		
	    		
	    		/*this is for validating the list objects inside the form*/
	    		if(data[loop].parent === true && data[loop].level === 2){
	    			let firstFormArray:FormArray=(inputForm.get(data[loop].controlName) as FormArray);
	    			let childArray = data[loop].childrenList;
	    			if(this.checkNullOrUndefined(firstFormArray)){
	    				if(firstFormArray.length === 0){
	    					(inputForm.get(data[loop].controlName)).setValidators(this.formValidatorArray(data[loop]));
	    					(inputForm.get(data[loop].controlName)).markAsTouched();	    			 		
	    					(inputForm.get(data[loop].controlName)).updateValueAndValidity();
	    				}
	    				if(this.checkNullOrUndefined(childArray) && firstFormArray.length > 0){
		   					for(var firstLoop=0; firstLoop < firstFormArray.length; firstLoop++){
		   						for(var insideLoop=0; insideLoop < childArray.length; insideLoop++){
		   							if(childArray[insideLoop].level === 0){
		   								firstFormArray.at(firstLoop).get(childArray[insideLoop].controlName).setValidators(this.formValidatorArray(childArray[insideLoop]));
		   								firstFormArray.at(firstLoop).get(childArray[insideLoop].controlName).markAsTouched();
		    							firstFormArray.at(firstLoop).get(childArray[insideLoop].controlName).updateValueAndValidity();
		   							}

		   							if(childArray[insideLoop].level === 1){
				   						var splitInnerString = (childArray[insideLoop].controlName).split(",");
						    			firstFormArray.at(firstLoop).get(splitInnerString[0]).get(splitInnerString[1]).setValidators(this.formValidatorArray(childArray[insideLoop]));
						    			firstFormArray.at(firstLoop).get(splitInnerString[0]).get(splitInnerString[1]).markAsTouched();
						    			firstFormArray.at(firstLoop).get(splitInnerString[0]).get(splitInnerString[1]).updateValueAndValidity();
					    			}
			   					}
		   					} 
		   				}
	    			}
	    		}	    		
    		} 
    }

    formValidatorArray(inputObj){
    	let validatorArray=[];
		if(inputObj.required=== true)
			validatorArray.push(Validators.required);
		if(inputObj.minLength=== true)
			validatorArray.push(Validators.min(inputObj.minLengthValue));
		if(inputObj.nullCheck=== true)
			validatorArray.push(Validators.nullValidator);
		if(inputObj.email=== true)
			validatorArray.push(Validators.email);
		if(inputObj.maxLength=== true)
			validatorArray.push(Validators.maxLength(inputObj.maxLengthValue));
		return validatorArray;
    }

/*
the below method will return true if the input object is not null*/
   public checkNullOrUndefined(inputObject:any):boolean{
   	if(inputObject===null || inputObject==='null' ||  inputObject==='' || inputObject=== undefined || inputObject==='undefined' )
   		return false;
   	return true;
   }

  
public hideAnalyticalChartIcon(moduleName:any):boolean{
   	
   	if(moduleName === 'VIEW_CONTACT' || moduleName === 'VIEW_SALES_PERSON' || moduleName === 'VIEW_ITEM' 
   		|| moduleName === 'VIEW_ITEMS_GROUP' || moduleName === 'VIEW_CATEGORY' || moduleName === 'VIEW_PRICE_LIST' 
   		|| moduleName === 'VIEW_ITEMS'|| moduleName === 'VIEW_R_BILL'|| moduleName === 'VIEW_EXPENSE'|| moduleName === 'VIEW_R_EXPENSE'
   		|| moduleName === 'VIEW_PAYMENT_MADE'  || moduleName === 'VIEW_PO_RECEIVE' || moduleName === 'VIEW_ARTWORK'|| moduleName === 'VIEW_VENDOR_CREDIT_BILL'
   		|| moduleName === 'VIEW_R_INVOICE'|| moduleName === 'VIEW_JOURNAL'|| moduleName === 'CHART_OF_ACCOUNTS'
   		|| moduleName === 'VIEW_RECONCILIATION' || moduleName === 'VIEW_ASSETS' || moduleName === 'VIEW_PAYMENT_RECIEVED'
   		|| moduleName === 'VIEW_INDENT' || moduleName === 'PROJECT_PLAN_CLIENT'|| moduleName === 'VIEW_RESOURCE_EXTENSION' 
   		|| moduleName === 'VIEW_TIMESHEET'||   moduleName === 'VIEW_HRIS_TIMESHEET' || moduleName === 'TIMESHEET_HISTORY' || moduleName === 'VIEW_EMPL-OYEE'|| moduleName === 'VIEW_APPRAISAL_MASTER' 
   		|| moduleName === 'REVIEW_MEDICAL_BILL'|| moduleName === 'VIEW_LOAN' || moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL' || moduleName === 'MY_LEAVE' 
   		|| moduleName === 'APPROVE_LEAVE' || moduleName === 'MY_LOAN' || moduleName === 'VIEW_APPRAISAL_FORM'|| moduleName === 'VIEW_DATABASE'|| moduleName === 'VIEW_BATCH_SYNC'
		|| moduleName === 'VIEW_TCLINKMASTER'|| moduleName === 'VIEW_MENU' || moduleName === 'VIEW_ORGANIZATION' || moduleName === 'VIEW_USER'|| moduleName === 'VIEW_ROLE'
		|| moduleName === 'VIEW_ISSUES'|| moduleName === 'VIEW_FEEDBACK'|| moduleName === 'VIEW_AUTOMATED_EMAIL'|| moduleName === 'VIEW_WAREHOUSE' || moduleName === 'VIEW_CLIENT_INVOICE' 
		|| moduleName === 'VIEW_CREDIT_NOTES_INVOICE' || moduleName === 'VIEW_LEAVE'|| moduleName === 'VIEW_ARTWORK_REQUEST' || moduleName === 'MANAGE_GRAPHICS_ARTWORK_REQUEST' 
		|| moduleName === 'VIEW_SHIPPING' || moduleName === 'VIEW_SO_RECEIVE' || moduleName === 'VIEW_INVENTORY_ADJUSTMENT'|| moduleName === 'VIEW_STOCK_REQUEST'|| moduleName === 'VIEW_APPROVE_STOCK_REQUEST'    
   		|| moduleName === 'VIEW_DISPATCH_PACKAGE'|| moduleName === 'VIEW_RETURNS'|| moduleName === 'VIEW_RECEIVE_RETURNS' || moduleName === 'PART_REQUEST' || moduleName === 'VIEW_DELIVERY_CHALLAN_RETURN'
   		|| moduleName === 'VIEW_SHIFT_MASTER'  || moduleName === 'VIEW_SHIFT_ALLOCATION' || moduleName === 'VIEW_SALES_RECEIPT' || moduleName === 'VIEW_PRODUCT_SETUP' || moduleName === 'VIEW_ACCOUNT_BUDGET'
   		|| moduleName === 'VIEW_RECEIVINGS' || moduleName === 'MANAGE_FEEDBACK_MYCORNER' || moduleName === 'VIEW_JOB'
   		||  moduleName === 'VIEW_INTERVIEW_SLOT' ||  moduleName === 'VIEW_APPOINTMENT' || moduleName === 'VIEW_CANDIDATE'|| moduleName=== 'REVIEW_TRAINEE' )

   		return false;
   	return true;
   }

public hideSearchIcon(moduleName:any):boolean{
   
   	if(moduleName === 'VIEW_ITEMS_GROUP' || moduleName === 'VIEW_CATEGORY' || moduleName === 'VIEW_PRICE_LIST' 
   		|| moduleName === 'VIEW_PO_RECEIVE' || moduleName === 'VIEW_VENDOR_CREDIT_BILL'
   		|| moduleName === 'VIEW_PACKAGES' || moduleName === 'VIEW_JOURNAL'
   		|| moduleName === 'CHART_OF_ACCOUNTS' || moduleName === 'VIEW_RECONCILIATION' || moduleName === 'VIEW_PROJECT'
   		|| moduleName === 'VIEW_RESOURCE_EXTENSION' || moduleName === 'VIEW_TIMESHEET' || moduleName === 'VIEW_HRIS_TIMESHEET' || moduleName === 'TIMESHEET_HISTORY' 
   		|| moduleName === 'VIEW_APPRAISAL_MASTER'|| moduleName === 'VIEW_PAYCODE' || moduleName === 'REVIEW_MEDICAL_BILL'  
   		|| moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL'|| moduleName === 'MY_LEAVE'|| moduleName === 'APPROVE_LEAVE' 
   		|| moduleName === 'MY_LOAN' || moduleName === 'VIEW_APPRAISAL_FORM' || moduleName === 'VIEW_DATABASE' || moduleName === 'VIEW_BATCH_SYNC'
   		|| moduleName === 'VIEW_TCLINKMASTER'|| moduleName === 'VIEW_MENU'|| moduleName === 'VIEW_ORGANIZATION'|| moduleName === 'VIEW_USER'|| moduleName === 'VIEW_ROLE'  
   		|| moduleName === 'VIEW_ISSUES' || moduleName === 'VIEW_FEEDBACK' || moduleName === 'VIEW_AUTOMATED_EMAIL'|| moduleName === 'VIEW_WAREHOUSE'|| moduleName === 'VIEW_CLIENT_INVOICE' 
   		|| moduleName === 'VIEW_CREDIT_NOTES_INVOICE'|| moduleName === 'VIEW_LEAVE'|| moduleName === 'VIEW_ARTWORK_REQUEST'|| moduleName === 'MANAGE_GRAPHICS_ARTWORK_REQUEST'  
   		|| moduleName === 'VIEW_SHIPPING' || moduleName === 'VIEW_SO_RECEIVE' || moduleName === 'VIEW_INVENTORY_ADJUSTMENT' || moduleName === 'VIEW_APPROVE_STOCK_REQUEST' 
   		|| moduleName === 'VIEW_DISPATCH_PACKAGE'|| moduleName === 'VIEW_RETURNS' || moduleName === 'VIEW_RECEIVE_RETURNS' || moduleName === 'PART_REQUEST' 
		|| moduleName === 'VIEW_DELIVERY_CHALLAN_RETURN' || moduleName === 'VIEW_SHIFT_MASTER'  || moduleName === 'VIEW_SHIFT_ALLOCATION' || moduleName === 'VIEW_SALES_RECEIPT'
		|| moduleName === 'VIEW_RECEIVINGS' || moduleName === 'MANAGE_FEEDBACK_MYCORNER' || moduleName=== 'REVIEW_TRAINEE')
   		return false;
   	return true;
   }

   /********************************Field Power API Integration method - starts*********************************/
   public ERROR_CODES = [{errorCode:"1000",errorMessage:"SUCCESS_MESSAGE"},
   					   {errorCode:"1001",errorMessage:"AUTHCODE_NOT_FOUND_ERROR"},
					   {errorCode:"1002",errorMessage:"REFCUSTID_NOT_FOUND_ERROR"},
					   {errorCode:"1003",errorMessage:"REQUIRED_VALUE_NOT_SUPPLIED_ERROR"},
					   {errorCode:"1004",errorMessage:"VALUE_EXCEEDED_MAX_MIN_ERROR"},
					   {errorCode:"1005",errorMessage:"EMAIL_VALIDATION_ERROR"},
					   {errorCode:"1006",errorMessage:"EMAIL_ALREADY_EXISTS_ERROR"},
					   {errorCode:"1007",errorMessage:"INVALID_DATA_ERROR"},
					   {errorCode:"1008",errorMessage:"INVALID_DROPDOWN_VALUES_SUPPLIED_ERROR"},
					   {errorCode:"1009",errorMessage:"NUMBER_FORMAT_ERROR"},
					   {errorCode:"1010",errorMessage:"DATA_LINKED_TO_TRANSACTIONS_ERROR"},
					   {errorCode:"1011",errorMessage:"LIST_SIZE_EXCEEDED_ERROR"},
					   {errorCode:"1012",errorMessage:"CONTACTID_NOT_FOUND_ERROR"},
					   {errorCode:"1013",errorMessage:"REFADDRESSID_NOT_FOUND_ERROR"},
					   {errorCode:"1014",errorMessage:"REFCONTACTID_NOT_FOUND_ERROR"},
					   {errorCode:"1015",errorMessage:"ADDRESSID_NOT_FOUND_ERROR"},
					   {errorCode:"1016",errorMessage:"CONTACTPERSONID_NOT_FOUND_ERROR"},
					   {errorCode:"1017",errorMessage:"REFCATEGORYID_NOT_FOUND_ERROR"},
					   {errorCode:"1018",errorMessage:"REFWORKTYPEID_NOT_FOUND_ERROR"},
					   {errorCode:"1019",errorMessage:"REFWORKREQUESTID_NOT_FOUND_ERROR"},
					   {errorCode:"1020",errorMessage:"CATEGORYID_NOT_FOUND_ERROR"},
					   {errorCode:"1021",errorMessage:"SUBCATEGORYID_NOT_FOUND_ERROR"},
					   {errorCode:"1022",errorMessage:"ITEMID_NOT_FOUND_ERROR"},
					   {errorCode:"1023",errorMessage:"SKU_ALREADY_EXISTS_ERROR"},
					   {errorCode:"1024",errorMessage:"TAXID_NOT_FOUND_ERROR"},
					   {errorCode:"1025",errorMessage:"LIST_SIZE_PAUCITY_ERROR"},
					   {errorCode:"1026",errorMessage:"TAXGROUPID_NOT_FOUND_ERROR"},
					   {errorCode:"1027",errorMessage:"PART_MASTERID_NOT_FOUND_ERROR"},
					   {errorCode:"1028",errorMessage:"PART_NUMBER_ALREADY_EXISTS_ERROR"},
					   {errorCode:"1029",errorMessage:"INVOICEID_NOT_FOUND_ERROR"},
					   {errorCode:"1030",errorMessage:"TAXID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1031",errorMessage:"CATEGORYID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1032",errorMessage:"SUBCATEGORYID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1033",errorMessage:"SERVICE_ADDRESSID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1034",errorMessage:"BILLING_ADDRESSID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1035",errorMessage:"ITEMID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1036",errorMessage:"CONTACTID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1037",errorMessage:"PARTMASTERID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1038",errorMessage:"TAXGROUPID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1039",errorMessage:"ATTACHMENT_TYPE_NOT_SUPPORTED_ERROR"},
					   {errorCode:"1040",errorMessage:"INVALID_DATE_FORMAT_ERROR"},
					   {errorCode:"1041",errorMessage:"MODEL_DOESNT_EXIST_IN_BRAND"},
					   {errorCode:"1042",errorMessage:"REFSUBCATEGORYID_NOT_FOUND_ERROR"},
					   {errorCode:"1043",errorMessage:"ORDERID_NOT_FOUND_ERROR"},
					   {errorCode:"1044",errorMessage:"TAX_AT_LINE_LEVEL_ERROR"},
					   {errorCode:"1045",errorMessage:"REFPRODUCTID_NOT_FOUND_ERROR"},
					   {errorCode:"1046",errorMessage:"ADDRESSID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1047",errorMessage:"WAREHOUSEID_NOT_FOUND_ERROR"},
					   {errorCode:"1048",errorMessage:"WAREHOUSEID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1049",errorMessage:"CLIENT_NAME_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1050",errorMessage:"REFPARTREQUESTID_NOT_FOUND_ERROR"},
					   {errorCode:"1051",errorMessage:"REFPARTREQUESTID_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1052",errorMessage:"QUANTITY_NOT_FOUND_ERROR"},
					   {errorCode:"1053",errorMessage:"INVALID_STATUS_ERROR"},
					   {errorCode:"1054",errorMessage:"INVALID_TAX_TYPE"},
					   
					   ];

	
	public POST_REQ_FIELDS_CONTACT = [
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the contact will be updated. If the value is zero, then the contact will be created in the system"},
	{fieldName:"contactType",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"",notes:"Possible values are: CUSTOMER, VENDOR"},
	{fieldName:"salutation",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"",
	notes:"Possible values are: Mr., Mrs., Ms., Miss., Dr., Mdm., Sir."},
	{fieldName:"firstName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"lastName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"company",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"displayName",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"email",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string that includes the domain name",
	notes:"Email validation will be done"},
	{fieldName:"workPhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:""},
	{fieldName:"homePhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:""},
	{fieldName:"internalNotes",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"currency",defaultValue:"USD",required:"No",min:"",max:"100",validFormats:"",
	notes:"Possible values are: AFN, AUD, CAD, CNY, EUR, GBP, INR, JPY, USD, ZAR, AED"},
	{fieldName:"paymentTerms",defaultValue:"Net 30",required:"No",min:"",max:"100",validFormats:"",
	notes:"Possible values are: Net 15, Net 30, Net 45, Net 60, Due end of the month, Due end of next month, Due on Receipt"},
	{fieldName:"website",defaultValue:"",required:"No",min:"",max:"150",validFormats:"150 character string",notes:""},
	{fieldName:"facebook",defaultValue:"",required:"No",min:"",max:"150",validFormats:"150 character string",notes:""},
	{fieldName:"twitter",defaultValue:"",required:"No",min:"",max:"150",validFormats:"150 character string",notes:""},
	{fieldName:"preferredContact",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"",
	notes:"Possible values are: SMS/TEXT,EMAIL,PHONE"},
	{fieldName:"refCustomerId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"referenceField1",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"referenceField2",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"referenceField3",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"referenceField4",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"",
	notes:"Used to know the source place from where the data is received"},
	{fieldName:"status",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"",
	notes:"Possible values are: ACTIVE, INACTIVE, ON HOLD"},
	{fieldName:"contactBusinessType",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"Client name will be AMAZON,FLIPKART,CROMA,BPL",
	notes:""},
	{fieldName:"fax",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:""}
	];

	public POST_REQ_FIELDS_CONTACT_ADDRESS = [
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"ContactId from ERP system for which the address needs to be updated"},
	{fieldName:"addressId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the address will be updated. If the value is zero, then the address will be created in the system"},
	{fieldName:"refCustomerId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"refAddressId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},	
	{fieldName:"addressType",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"",
	notes:"Possible values are: BILLING ADDRESS,SERVICE ADDRESS"},
	{fieldName:"attention",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"addressLineOne",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"addressLineTwo",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"country",defaultValue:"",required:"Yes",min:"1",max:"2",validFormats:"",
	notes:"2 digit ISO codes. Please use the link for your reference: https://countrycode.org/"},
	{fieldName:"city",defaultValue:"",required:"Yes",min:"",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"state",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:""},
{fieldName:"zipCode",defaultValue:"",required:"Yes",min:"1",max:"25",validFormats:"",notes:""},
	{fieldName:"phone",defaultValue:"",required:"No",min:"",max:"20",validFormats:"",notes:""},
	{fieldName:"fax",defaultValue:"",required:"No",min:"",max:"20",validFormats:"",notes:""},
	{fieldName:"isDefault",defaultValue:"N",required:"No",min:"",max:"1",validFormats:"",
	notes:"Possible values are: Y,N. If all values have the default value as Y, then the system will randomly update one of them as Y and remaining as N"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",
	notes:"Used to know the source place from where the data is received"},
	{fieldName:"status",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"",notes:"Possible values are: 0,1"}
	];

	public POST_REQ_FIELDS_CONTACT_PERSONS = [
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the contact person will be updated. If the value is zero, then the contact person will be created in the system"},
	{fieldName:"contactPersonId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"refCustomerId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"refContactId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"refAddressId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"salutation",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Possible values are: Mr.,Mrs.,Ms.,Miss.,Dr.,Mdm.,Sir."},
	{fieldName:"firstName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"lastName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"email",defaultValue:"",required:"No",min:"1",max:"100",
	validFormats:"100 character string that includes the domain name",	notes:"Email validation will be done"},
	{fieldName:"workPhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:""},
	{fieldName:"homePhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:""},
	{fieldName:"designation",defaultValue:"",required:"No",min:"",max:"75",validFormats:"75 character string",notes:""},
	{fieldName:"contactPersonType",defaultValue:"",required:"Yes",min:"",max:"50",validFormats:"50 character string",
	notes:"SALES,GENERAL,BILLING,SERVICE"}
	];

	public POST_REQ_FIELDS_DELETE_CONTACT = [
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	{fieldName:"refCustomerId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];

	/********************************Field Power API Integration method - ends*********************************/
	/********************************Field Power API Integration Work order master method - starts*********************************/
	public POST_REQ_FIELDS_CATEGORY = [
	{fieldName:"categoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the category will be updated. If the value is zero, then the category will be created in the system"},
	{fieldName:"refCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"description",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:""},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",
	notes:"Used to know the source place from where the data is received"}
	];

	public POST_REQ_FIELDS_SAVE_WAREHOUSE = [
	{fieldName:"warehouseId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the category will be updated. If the value is zero, then the category will be created in the system"},
	{fieldName:"warehouseName",defaultValue:"",required:"Yes",min:"1",max:"75",validFormats:"75 character string",notes:""},
	{fieldName:"warehouseType",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Possible values are Physical and Van"},
	{fieldName:"warehouseTypeDescription",defaultValue:"",required:"Yes",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",
	notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_DELETE_CATEGORY = [
	{fieldName:"categoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	{fieldName:"refCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];

	public POST_REQ_FIELDS_DELETE_PRODUCT = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	{fieldName:"refProductId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];

	public POST_REQ_FIELDS_WORK_TYPE = [
	{fieldName:"subCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the work request will be updated. If the value is zero, then the work request will be created in the system"},
	{fieldName:"refWorkTypeId/refSubCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"categoryId",defaultValue:"",required:"Yes",min:"",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"refCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"description",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:""},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",
	notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_DELETE_WORK_TYPE = [
	{fieldName:"subCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	{fieldName:"refWorkTypeId/refSubCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];
	public POST_REQ_FIELDS_WORK_REQUEST = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the work request will be updated. If the value is zero, then the work request will be created in the system"},
	{fieldName:"refWorkRequestId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"categoryId",defaultValue:"",required:"Yes",min:"",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"refCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"subCategoryId",defaultValue:"",required:"Yes",min:"",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"refWorkTypeId/refSubCategoryId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Unique reference id of the third party system"},
	{fieldName:"description",defaultValue:"",required:"Yes",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"salesPrice",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"18 character string",notes:""},
	{fieldName:"purchasePrice",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"18 character string",notes:""},
	{fieldName:"sku",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Unique number for item that should not exists in ERP system"},
	{fieldName:"itemType",defaultValue:"",required:"Yes",min:"",max:"1",validFormats:"1 character string",
	notes:"Possible values are G,S"},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",
	notes:"Used to know the source place from where the data is received"}	
	];
	public POST_REQ_FIELDS_DELETE_WORK_REQUEST = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	{fieldName:"refWorkRequestId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];


	public POST_REQ_FIELDS_SAVE_PART_REQUEST = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed to retrieve the quantity for this item"},
	{fieldName:"refPartRequestId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:""},
	{fieldName:"isWarranty",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",
	notes:"Pssible values are 1 and 0"},
	{fieldName:"workOrderNumber",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:""},
	{fieldName:"jobNumber",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:""},
	{fieldName:"warehouseId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",
	notes:""},
	{fieldName:"requestQuantity",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:""},
	{fieldName:"isOEM",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",
	notes:"Possible values are 1 and 0"},
	{fieldName:"status",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:"Possible values are REQUESTED,ALLOCATED,CLOSED and CANCELLED"},

	
	];

	public POST_REQ_FIELDS_QUANTITY_DETECT_IN_STOCK = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed to deduct the quantity of this item from the stock"},
	{fieldName:"quantityToDeduct",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""}
	];
	public POST_REQ_FIELDS_SAVE_INVOICE_PART1 = [
	{fieldName:"invoiceId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:"If this value is non-zero, then the invoice will be updated. If the value is zero, then the work request will be created in the system"},
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"serviceAddressId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"billingAddressId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"clientName",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"paymentTerms",defaultValue:"",required:"Yes",min:"",max:"100",validFormats:"",
	notes:"Possible values are: Net 15, Net 30, Net 45, Net 60, Due end of the month, Due end of next month, Due on Receipt"},
	{fieldName:"workOrderId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"workJobId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"workOrderNumber",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"invoiceDate",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"dueDate",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"poNumber",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"poDate",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"paymentStatus",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Possible values are DRAFT,OPEN,PARTIALLY PAID and PAID"},
	{fieldName:"isSingleInvoice",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"taxAtLineLevel",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	];
	public POST_REQ_FIELDS_SAVE_INVOICE_PART2 = [
	{fieldName:"subTotalValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"discountValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"adjustmentValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"taxtType",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:"Possible values are Tax and Tax group"},
	{fieldName:"taxId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"taxValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"totalAmount",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"customerNotes",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"termsAndConditions",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"isPayment",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible value are 1 and 0"}
	];

	public POST_REQ_FIELDS_SAVE_INVOICE_PAYMENT_DETAILS= [
	{fieldName:"paymentMode",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Possible values are CASH,BANK TRANSFER,CHEQUE,ACH,BANK REMITTANCE and CREDIT CARD"},
	{fieldName:"paidAmount",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"bankCharges",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"paymentDate",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"referenceNumber",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""}
	];

	public POST_REQ_FIELDS_SAVE_INVOICE_DETAILS = [
	{fieldName:"categoryId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"subCategoryId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"itemType",defaultValue:"",required:"Yes",min:"1",max:"5",validFormats:"5 character string",notes:"Possible values are Part and Job"},
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"If it is part send part id. If it is job send work request id"},
	{fieldName:"jobWoNumber",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:""},
	{fieldName:"itemDescription",defaultValue:"",required:"Yes",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"quantity",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"taxtType",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:"Possible values are Tax and Tax group"},
	{fieldName:"taxId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"taxValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"totalValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"isWarranty",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"isASP",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},	
	];

	public POST_REQ_FIELDS_SAVE_PART_MASTER = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the tax will be updated. If the value is zero, then the tax will be created in the system"},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"1000",validFormats:"1000 character string",notes:""},
	{fieldName:"brand",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"model",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"partType",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Possible values are Consumables,New,Used"},
	{fieldName:"partNumber",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"isWarranty",defaultValue:"0",required:"No",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"description",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"serialNumber",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:""},
	{fieldName:"uom",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Possible values are Cubic Feet,Each,Feet,Centimeter,Gallons,Meter"},
	{fieldName:"unitPrice",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"oem",defaultValue:"",required:"No",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"preferredVendor",defaultValue:"",required:"No",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"manufacturer",defaultValue:"",required:"No",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"taxType",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"10 character string",notes:"Possible values are Tax and TaxGroup"},
	{fieldName:"taxId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:"Should pass respective tax id. If tax type is tax then pass tax id. If tax type is tax group then pass tax group id"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_SAVE_PART_MASTER_ATTACHMENT = [
	{fieldName:"attachmentType",defaultValue:"Photo",required:"Yes",min:"1",max:"5",validFormats:"10 character string",notes:""},
	{fieldName:"attachment",defaultValue:"",required:"Yes",min:"1",max:"",validFormats:"Max character string",notes:""}
	];
	public POST_REQ_FIELDS_SAVE_PRODUCT = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the Item will be updated. If the value is zero, then the Item will be created in the system"},
	{fieldName:"refProductId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"1000",validFormats:"1000 character string",notes:""},
	{fieldName:"sku",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"description",defaultValue:"",required:"Yes",min:"1",max:"1000",validFormats:"1000 character string",notes:""},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"manufacturer",defaultValue:"",required:"No",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"categoryId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"refCategoryId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"subCategoryId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"refSubCategoryId",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_SAVE_PRODUCT_ATTACHMENT = [
	{fieldName:"attachmentType",defaultValue:"Photo",required:"Yes",min:"1",max:"5",validFormats:"10 character string",notes:""},
	{fieldName:"attachment",defaultValue:"",required:"Yes",min:"1",max:"",validFormats:"Max character string",notes:""}
	];
	public POST_REQ_FIELDS_SAVE_TAX = [
	{fieldName:"taxId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the tax will be updated. If the value is zero, then the tax will be created in the system"},
	{fieldName:"taxName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"taxPercent",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"taxAuthorityName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Possible values are Regional Council,District Council and Cantonment Council"},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_DELETE_TAX = [
	{fieldName:"taxId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	];
	public POST_REQ_FIELDS_DELETE_TAX_GROUP = [
	{fieldName:"taxGroupId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	];
	public POST_REQ_FIELDS_DELETE_PART_MASTER = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"},
	];
	public POST_REQ_FIELDS_SAVE_TAX_GROUP = [
	{fieldName:"taxGroupId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"If this value is non-zero, then the tax will be updated. If the value is zero, then the tax will be created in the system"},
	{fieldName:"taxGroupName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:""},
	{fieldName:"taxIdList",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"List size should always greater than zero",notes:"Eg.[12,45,21]"},
	{fieldName:"isActive",defaultValue:"",required:"Yes",min:"1",max:"1",validFormats:"1 character string",notes:"Possible values are 1 and 0"},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"Used to know the source place from where the data is received"}
	];
	public POST_REQ_FIELDS_SAVE_ORDER_PART1 = [
	{fieldName:"orderId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
	notes:"If this value is non-zero, then the order will be updated. If the value is zero, then the work request will be created in the system"},
	{fieldName:"refOrderId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"contactId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"serviceAddressId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"billingAddressId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"deliveryMethod",defaultValue:"",required:"Yes",min:"",max:"100",validFormats:"",
	notes:"Possible values are: Net 15, Net 30, Net 45, Net 60, Due end of the month, Due end of next month, Due on Receipt"},
	{fieldName:"referenceNumber",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"orderDate",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"shipmentDate",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Valid date format MM/dd/yyyy"},
	{fieldName:"orderStatus",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:"Possible values are DRAFT,OPEN and CLOSED"}
	];
	public POST_REQ_FIELDS_SAVE_ORDER_PART2 = [
	{fieldName:"subTotalValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"discountValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"adjustmentName",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"adjustmentValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"totalAmount",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"customerNotes",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"termsAndConditions",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:""},
	{fieldName:"source",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",notes:""}
	];
	public POST_REQ_FIELDS_SAVE_ORDER_DETAILS = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:""},
	{fieldName:"itemDescription",defaultValue:"",required:"Yes",min:"1",max:"1000",validFormats:"1000 character string",notes:""},
	{fieldName:"quantity",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:""},
	{fieldName:"totalValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"textValue",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
	{fieldName:"fontColor",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"50 character string",notes:""},
	{fieldName:"fontFamily",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"100 character string",notes:""},
	{fieldName:"fontSize",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"11 character string",notes:""},
	];
	public POST_REQ_FIELDS_DELETE_WAREHOUSE = [
	{fieldName:"warehouseId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",
	notes:"Needed for deleting the values"}
	];
	/********************************Field Power API Integration Work order master method - ends*********************************/
	
	/******************************** Quick Books API Documentation - Request Object - Starts *********************/
	public QB_SYNC_BASIC_REQ_FIELDS = [
	{fieldName:"companyId",defaultValue: "",required:"Yes",min:"1",max:"20",validFormats:"Encrypted string format",notes:"This fields should be included as encrypted format"},
	{fieldName:"portalName",defaultValue: "",required:"Yes",min:"1",max:"20",validFormats:"Encrypted string format",notes:"This fields should be included as encrypted format"}
	];
	public QB_CONTACT_TYPE_REQ_FIELDS = [
	{fieldName:"contactType",defaultValue: "",required:"Yes",min:"1",max:"20",validFormats:"Valid String value",
	notes:"This fields should has CUSTOMER or VENDOR.If type is CUSTOMER mentioned the object as customerObj and If type is VENDOR mentioned the object as vendorObj."}
	];
	public QB_SETTINGS_REQ_FIELDS = [
	{fieldName:"portalName",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid portal name",
	notes:"Portal name - depends on the organization based string info"},
	{fieldName:"companyId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid Company id from QuickBooks",
	notes:"Company id - unique id for each quickbook account"},
	{fieldName:"itemEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks item sync"},
	{fieldName:"customerEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks customer sync"},
	{fieldName:"invoiceEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks invoice sync"},
	{fieldName:"salesOrderEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks sales order sync"},
	{fieldName:"purchaseOrderEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks purchase order sync"},
	{fieldName:"billEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks bill sync"},
	{fieldName:"vendorEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks vendor sync"},
	{fieldName:"vendorCreditEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks vendor credit sync"},
	{fieldName:"paymentMadeEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks payment made sync"},
	{fieldName:"expenseEnabled",defaultValue: "",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"This is a flag to enable the quickbooks expense sync"},
	
	];

	public QB_INVOICE_REQ_FIELDS_PART1 = [
	{fieldName:"invoiceId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Invoice sync table, then the Invoice will be updated. If the value not exists in Invoice sync table, then the Invoice will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Invoice number for reference"},
	{fieldName:"invoiceNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Invoice number for reference"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Invoice"}
	];
	public QB_SALES_ORDER_REQ_FIELDS_PART1 = [
	{fieldName:"salesOrderId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Sales order sync table, then the Sales order will be updated. If the value not exists in  Sales order sync table, then the  Sales order will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"Yes",min:"1",max:"21",validFormats:"Valid data for reference",notes:"Sales order number for reference"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"total",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid deciaml value",notes:"Total amount of the  Sales order"}
	];
	public QB_PURCHASE_ORDER_REQ_FIELDS_PART1 = [
	{fieldName:"purchaseOrderId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Purchase order sync table, then the Purchase order will be updated. If the value not exists in  Purchase order sync table, then the  Purchase order will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"PO number for reference"},
	{fieldName:"poNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"PO number for reference"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for purchase order"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];
	public QB_BILL_REQ_FIELDS_PART1 = [
	{fieldName:"billId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Bill sync table, then the Bill will be updated. If the value not exists in  Bill sync table, then the  Bill will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"billNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"paymentTerms",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Term like Due to Recript,NET 10, NET 20, NET 15,...etc"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for Bill"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];
	public QB_VENDOR_CREDIT_REQ_FIELDS_PART1 = [
	{fieldName:"vendorCreditId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Vendor credit sync table, then the Vendor credit will be updated. If the value not exists in  Vendor credit sync table, then the  Vendor credit will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Vendor Credit number for reference"},
	{fieldName:"vendorCreditNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Vendor Credit number for reference"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for vendor credits"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];
	public QB_CUSTOMER_REQ_FIELDS = [
	{fieldName:"customerId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is non-zero, then the contact will be updated. If the value is zero, then the contact will be created in the system"},
	{fieldName:"salutation",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"",
	notes:"Possible values are: Mr., Mrs., Ms., Miss., Dr., Mdm., Sir."},
	{fieldName:"firstName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"This field indicates the first name of the customer"},
	{fieldName:"middleName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:"This field indicates the last name of the customer"},
	{fieldName:"lastName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:"This field indicates the last name of the customer"},
	{fieldName:"companyName",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:"This field indicates the company name of the customer"},
	{fieldName:"displayName",defaultValue:"",required:"Yes",min:"",max:"100",validFormats:"100 character string",
	notes:"This field indicates the display name of the customer, otherwise concatenation of first name, middle name and last name will be taken as display name"},
	{fieldName:"email",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string that includes the domain name",
	notes:"Email validation will be done"},
	{fieldName:"workPhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates contact work phone number"},
	{fieldName:"mobilePhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates contact mobile number"},
	{fieldName:"website",defaultValue:"",required:"No",min:"",max:"150",validFormats:"150 character string",notes:"Indicates website value"},
	{fieldName:"fax",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates fax number"}
	];
	public QB_VENDOR_REQ_FIELDS = [
	{fieldName:"vendorId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is non-zero, then the contact will be updated. If the value is zero, then the contact will be created in the system"},
	{fieldName:"salutation",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"",
	notes:"Possible values are: Mr., Mrs., Ms., Miss., Dr., Mdm., Sir."},
	{fieldName:"firstName",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"50 character string",notes:"This field indicates the first name of the customer"},
	{fieldName:"middleName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:"This field indicates the last name of the customer"},
	{fieldName:"lastName",defaultValue:"",required:"No",min:"",max:"50",validFormats:"50 character string",notes:"This field indicates the last name of the customer"},
	{fieldName:"companyName",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:"This field indicates the company name of the customer"},
	{fieldName:"displayName",defaultValue:"",required:"Yes",min:"",max:"100",validFormats:"100 character string",
	notes:"This field indicates the display name of the customer, otherwise concatenation of first name, middle name and last name will be taken as display name"},
	{fieldName:"email",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string that includes the domain name",
	notes:"Email validation will be done"},
	{fieldName:"workPhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates contact work phone number"},
	{fieldName:"mobilePhone",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates contact mobile number"},
	{fieldName:"website",defaultValue:"",required:"No",min:"",max:"150",validFormats:"150 character string",notes:"Indicates website value"},
	{fieldName:"fax",defaultValue:"",required:"No",min:"",max:"15",validFormats:"15 character string",notes:"Indicates fax number"}
	];
	public QB_CONTACT_ADDR_REQ_FIELDS = [
	{fieldName:"line1",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string",notes:"Indicates the address line one"},
	{fieldName:"line2",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:"Indicates the address line two to specify address in detail"},
	{fieldName:"city",defaultValue:"",required:"No",min:"1",max:"2",validFormats:"100 character string",notes:"Valid city name"},
	{fieldName:"country",defaultValue:"",required:"No",min:"",max:"100",validFormats:"100 character string",notes:"Valid country name / country code"},
	{fieldName:"postalCode",defaultValue:"",required:"No",min:"1",max:"25",validFormats:"Valid code",notes:"Indicates postal code information"}
	];
	public QB_LINE_ITEM_REQ_FIELDS = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Unique integer id for item"},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:"Unique name for product/ item to sync"},
	{fieldName:"sku",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string",notes:"Each product/ item referenced by sku value"},
	{fieldName:"active",defaultValue:"true",required:"Yes",min:"1",max:"1",validFormats:"Valid boolean value",
	notes:"Indicates the active status :: If it is true only, it will be accessed from quickbooks"},
	{fieldName:"taxable",defaultValue:"false",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"It indicates whether the item is line level taxable or not"},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Rate of the item"},
	{fieldName:"salesAccountName",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"Valid account name :: string",notes:"Account name in the type of 'Sales of Product Income'"},
	{fieldName:"salesAccountType",defaultValue:"Sales of Product Income",required:"Yes",min:"1",max:"100",validFormats:"Valid account type",notes:"'Sales of Product Income' is the default sales account type"},
	{fieldName:"salesDescription",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:"Indicates the description for sales information"},
	{fieldName:"categoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this category, otherwise the item is not under any category"},
	{fieldName:"subCategoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this sub-category"},
	{fieldName:"type",defaultValue:"NonInventory",required:"Yes",min:"1",max:"100",validFormats:"Valid string value",
	notes:"If type='Inventory' then trackQtyOnHand [true], qtyOnHand and invStartDate and Asset account are required fields :: Otherwise type = 'NonInventory / Service', these are not required fields"},
	{fieldName:"trackQtyOnHand",defaultValue:"false",required:"No",min:"1",max:"100",validFormats:"Valid boolean value",notes:"If it is set as true then it is not able to turn on as false"},
	{fieldName:"qtyOnHand",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid integer value",notes:"Indicates quantity of the item on hand "},
	{fieldName:"invStartDate",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid date [yyyy-mm-dd]",notes:"Opening date of the balance of inventory"},
	{fieldName:"assetAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for asset based"},
	{fieldName:"assetAccountType",defaultValue:"Other Current Asset",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Other Current Asset'"},
	{fieldName:"purchaseCost",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid decimal value",notes:"It indicates the cost for purchase from vendor"},
	{fieldName:"purchaseAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for purchase based"},
	{fieldName:"purchaseAccountType",defaultValue:"Cost of Goods Sold",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Cost of Goods Sold'"},
	{fieldName:"quantity",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"10 character string",notes:"Indicates the number of items in that particular line details"},
	{fieldName:"lineAmount",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Line item level amount is required"},
	];
	public QB_ITEM_REQ_FIELDS = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Unique integer id for item"},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:"Unique name for product/ item to sync"},
	{fieldName:"sku",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string",notes:"Each product/ item referenced by sku value"},
	{fieldName:"active",defaultValue:"true",required:"Yes",min:"1",max:"1",validFormats:"Valid boolean value",
	notes:"Indicates the active status :: If it is true only, it will be accessed from quickbooks"},
	{fieldName:"taxable",defaultValue:"false",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"It indicates whether the item is line level taxable or not"},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Rate of the item"},
	{fieldName:"salesAccountName",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"Valid account name :: string",notes:"Account name in the type of 'Sales of Product Income'"},
	{fieldName:"salesAccountType",defaultValue:"Sales of Product Income",required:"Yes",min:"1",max:"100",validFormats:"Valid account type",notes:"'Sales of Product Income' is the default sales account type"},
	{fieldName:"salesDescription",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:"Indicates the description for sales information"},
	{fieldName:"categoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this category, otherwise the item is not under any category"},
	{fieldName:"subCategoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this sub-category"},
	{fieldName:"type",defaultValue:"NonInventory",required:"Yes",min:"1",max:"100",validFormats:"Valid string value",
	notes:"If type='Inventory' then trackQtyOnHand [true], qtyOnHand and invStartDate and Asset account are required fields :: Otherwise type = 'NonInventory / Service', these are not required fields"},
	{fieldName:"trackQtyOnHand",defaultValue:"false",required:"No",min:"1",max:"100",validFormats:"Valid boolean value",notes:"If it is set as true then it is not able to turn on as false"},
	{fieldName:"qtyOnHand",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid integer value",notes:"Indicates quantity of the item on hand "},
	{fieldName:"invStartDate",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid date format [yyyy-mm-dd]",notes:"Opening date of the balance of inventory"},
	{fieldName:"assetAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for asset based"},
	{fieldName:"assetAccountType",defaultValue:"Other Current Asset",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Other Current Asset'"},
	{fieldName:"purchaseCost",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"It indicates the cost for purchase from vendor"},
	{fieldName:"purchaseAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for purchase based"},
	{fieldName:"purchaseAccountType",defaultValue:"Cost of Goods Sold",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Cost of Goods Sold'"}
	];
	public QB_LINE_ITEM_REQ_FIELDS_FOR_PURCHASE = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Unique integer id for item"},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:"Unique name for product/ item to sync"},
	{fieldName:"sku",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string",notes:"Each product/ item referenced by sku value"},
	{fieldName:"active",defaultValue:"true",required:"Yes",min:"1",max:"1",validFormats:"Valid boolean value",
	notes:"Indicates the active status :: If it is true only, it will be accessed from quickbooks"},
	{fieldName:"taxable",defaultValue:"false",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"It indicates whether the item is line level taxable or not"},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Rate of the item"},
	{fieldName:"purchaseAccountName",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for purchase based"},
	{fieldName:"purchaseAccountType",defaultValue:"Cost of Goods Sold",required:"Yes",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Cost of Goods Sold'"},
	{fieldName:"purchaseDescription",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:"Indicates the description for sales information"},
	{fieldName:"categoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this category, otherwise the item is not under any category"},
	{fieldName:"subCategoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this sub-category"},
	{fieldName:"type",defaultValue:"NonInventory",required:"Yes",min:"1",max:"100",validFormats:"Valid string value",
	notes:"If type='Inventory' then trackQtyOnHand [true], qtyOnHand and invStartDate and Asset account are required fields :: Otherwise type = 'NonInventory / Service', these are not required fields"},
	{fieldName:"trackQtyOnHand",defaultValue:"false",required:"No",min:"1",max:"100",validFormats:"Valid boolean value",notes:"If it is set as true then it is not able to turn on as false"},
	{fieldName:"qtyOnHand",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid integer value",notes:"Indicates quantity of the item on hand "},
	{fieldName:"invStartDate",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid date [yyyy-mm-dd]",notes:"Opening date of the balance of inventory"},
	{fieldName:"assetAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for asset based"},
	{fieldName:"assetAccountType",defaultValue:"Other Current Asset",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Other Current Asset'"},
	{fieldName:"quantity",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"10 character string",notes:"Indicates the number of items in that particular line details"},
	{fieldName:"lineAmount",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Line item level amount is required"},
	];

	public QB_LINE_BILL_REQ_FIELDS_FOR_PURCHASE = [
	{fieldName:"billId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Bill sync table, then the Bill will be updated. If the value not exists in  Bill sync table, then the  Bill will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"billNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"paymentTerms",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Term like Due to Recript,NET 10, NET 20, NET 15,...etc"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for Bill"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"appliedAmount",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];

	public QB_PAYMENT_MADE_REQ_FIELDS_PART1 = [
	{fieldName:"paymentMadeId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Payment Made sync table, then the Payment made will be updated. If the value not exists in  Payment Made sync table, then the  Payment Made will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"paymentMadeNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"BILL number for reference"},
	{fieldName:"paymentType",defaultValue: "",required:"Yes",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Type must be CHECK or CREDIT CARD. Check Payment Detail  of CHECK is stored using random number"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for Bill"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];

	public QB_LINE_ACCOUNT_REQ_FIELDS_FOR_EXPENSE = [
	{fieldName:"accountName",defaultValue: "",required:"Yes",min:"1",max:"200",validFormats:"Any valid string information",
	notes:"find the account id by given account name "},
	{fieldName:"accountType",defaultValue: "",required:"Yes",min:"1",max:"200",validFormats:"Any valid string information",notes:"Account Type for the account name"},
	{fieldName:"lineDescription",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Description for the account name "},
	{fieldName:"totalAmount",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount  for the expense in line level"}
	];

	public QB_EXPENSE_REQ_FIELDS_PART1 = [
	{fieldName:"expenseId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Expense sync table, then the Expense will be updated. If the value not exists in Expense sync table, then the  Expense will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:" Reference number for expense"},
	{fieldName:"expenseNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:" Reference number for expense"},
	{fieldName:"paymentType",defaultValue: "",required:"Yes",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Type like check,credit card,cash.If Payment Type is Check,It cannot be updated to any other payment type"},
	{fieldName:"paymentName",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Method like cash,check,visa,mastercard etc.. "},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for Bill"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Purchase Order"}
	];

	public QB_ESTIMATE_REQ_FIELDS_PART1 = [
	{fieldName:"estimateId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Estimate sync table, then the Estimate will be updated. If the value not exists in Estimate sync table, then the Estimate will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Estimate number for reference"},
	{fieldName:"estimateNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Estimate number for reference"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	/*{fieldName:"total",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount of the Invoice"}*/
	];

	public QB_LINE_ITEM_REQ_FIELDS_FOR_ESTIMATE = [
	{fieldName:"itemId",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Unique integer id for item"},
	{fieldName:"itemName",defaultValue:"",required:"Yes",min:"1",max:"250",validFormats:"250 character string",notes:"Unique name for product/ item to sync"},
	{fieldName:"sku",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"100 character string",notes:"Each product/ item referenced by sku value"},
	/*{fieldName:"active",defaultValue:"true",required:"Yes",min:"1",max:"1",validFormats:"Valid boolean value",
	notes:"Indicates the active status :: If it is true only, it will be accessed from quickbooks"},*/
	{fieldName:"taxable",defaultValue:"false",required:"No",min:"1",max:"1",validFormats:"Valid boolean value",notes:"It indicates whether the item is line level taxable or not"},
	{fieldName:"unitPrice",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Rate of the item"},
	{fieldName:"salesAccountName",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"Valid account name :: string",notes:"Account name in the type of 'Sales of Product Income'"},
	{fieldName:"salesAccountType",defaultValue:"Sales of Product Income",required:"Yes",min:"1",max:"100",validFormats:"Valid account type",notes:"'Sales of Product Income' is the default sales account type"},
	{fieldName:"salesDescription",defaultValue:"",required:"No",min:"1",max:"500",validFormats:"500 character string",notes:"Indicates the description for sales information"},
	{fieldName:"categoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this category, otherwise the item is not under any category"},
	{fieldName:"subCategoryName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid string value",notes:"This field indicates the given item is under this sub-category"},
	{fieldName:"type",defaultValue:"NonInventory",required:"Yes",min:"1",max:"100",validFormats:"Valid string value",
	notes:"If type='Inventory' then trackQtyOnHand [true], qtyOnHand and invStartDate and Asset account are required fields :: Otherwise type = 'NonInventory / Service', these are not required fields"},
	{fieldName:"trackQtyOnHand",defaultValue:"false",required:"No",min:"1",max:"100",validFormats:"Valid boolean value",notes:"If it is set as true then it is not able to turn on as false"},
	{fieldName:"qtyOnHand",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid integer value",notes:"Indicates quantity of the item on hand "},
	{fieldName:"invStartDate",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid date [yyyy-mm-dd]",notes:"Opening date of the balance of inventory"},
	{fieldName:"assetAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for asset based"},
	{fieldName:"assetAccountType",defaultValue:"Other Current Asset",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Other Current Asset'"},
	{fieldName:"purchaseCost",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid decimal value",notes:"It indicates the cost for purchase from vendor"},
	{fieldName:"purchaseAccountName",defaultValue:"",required:"No",min:"1",max:"100",validFormats:"Valid account - string value",notes:"Account name will be linked for purchase based"},
	{fieldName:"purchaseAccountType",defaultValue:"Cost of Goods Sold",required:"No",min:"1",max:"100",validFormats:"Valid account type - string value",notes:"Default value - 'Cost of Goods Sold'"},
	{fieldName:"quantity",defaultValue:"",required:"No",min:"1",max:"10",validFormats:"10 character string",notes:"Indicates the number of items in that particular line details"},
	{fieldName:"lineAmount",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"Line item level amount is required"},
	];

public QB_CREDIT_NOTES_REQ_FIELDS_PART1 = [
	{fieldName:"creditNotesId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in credit notes sync table, then the credit notes will be updated. If the value not exists in credit notes sync table, then the credit notes will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Credit Notes number for reference"},
	{fieldName:"creditNotesNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Credit Notes number for reference"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	];

	public QB_PAYMENT_RECEIVED_REQ_FIELDS_PART1 = [
	{fieldName:"paymentReceivedId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in payment received sync table, then the credit notes will be updated. If the value not exists in payment received sync table, then the payment received will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment received number for reference"},
	{fieldName:"paymentReceivedNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment received number for reference"},
	{fieldName:"paymentType",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Type like check,credit card,cash.If Payment Type is Check,It cannot be updated to any other payment type"},
	{fieldName:"paymentName",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Method like cash,check,visa,mastercard etc.. "},
	{fieldName:"total",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Total amount received for  the list of Invoices"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	];

	public QB_LINE_INVOICE_REQ_FIELDS_FOR_PAYMENT_RECEIVED = [
	{fieldName:"invoiceId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Invoice sync table, then the Invoice will be updated. If the value not exists in  Invoice sync table, then the  Invoice will be created in the system"},
	{fieldName:"refNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Invoice number for reference"},
	{fieldName:"invoiceNumber",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Invoice number for reference"},
	{fieldName:"paymentTerms",defaultValue: "",required:"No",min:"1",max:"21",validFormats:"Any valid string information",notes:"Payment Term like Due to Recipt,NET 10, NET 20, NET 15,...etc"},
	{fieldName:"notes",defaultValue: "",required:"No",min:"1",max:"500",validFormats:"Any valid string information",notes:"Memo for Invoice"},
	{fieldName:"created_Date",defaultValue: "Current date",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Created date"},
	{fieldName:"due_Date",defaultValue: "",required:"No",min:"",max:"",validFormats:"Valid date [yyyy-mm-dd]",notes:"Due date should be greater than the created date"},
	{fieldName:"appliedAmount",defaultValue: "",required:"No",min:"1",max:"10",validFormats:"Valid decimal value",notes:"Payment amount applied for each Invoices"}
	];

	public QB_PAYMENT_INVOICE_REQ_FIELDS = [
	{fieldName:"invoiceId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Integer value",
	notes:"If this value is exists in Invoice sync table, then able to get the Invoice Payment status for that Invoice"},
	];

	public QB_INVOICE_DELETE_REQ_FIELDS = [
	{fieldName:"invoiceId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_SALESORDER_DELETE_REQ_FIELDS = [
	{fieldName:"salesOrderId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_PURCHASEORDER_DELETE_REQ_FIELDS = [
	{fieldName:"purchaseOrderId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];
	public QB_BILL_DELETE_REQ_FIELDS = [
	{fieldName:"billId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];
	public QB_VENDOR_CREDIT_DELETE_REQ_FIELDS = [
	{fieldName:"vendorCreditId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];
	public QB_PAYMENT_MADE_DELETE_REQ_FIELDS = [
	{fieldName:"paymentMadeId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_EXPENSE_DELETE_REQ_FIELDS = [
	{fieldName:"expenseId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_ESTIMATE_DELETE_REQ_FIELDS = [
	{fieldName:"estimateId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_CREDIT_NOTES_DELETE_REQ_FIELDS = [
	{fieldName:"creditNotesId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];

	public QB_PAYMENT_RECEIVED_DELETE_REQ_FIELDS = [
	{fieldName:"paymentReceivedId",defaultValue: "",required:"Yes",min:"1",max:"10",validFormats:"Valid integer value",notes:"It should be already synced with the system"}
	];



	/******************************** Quick Books API Documentation - Request Object - Ends *********************/

	/***********************  Quick Books API Integration Error Codes Array declaration - starts **********************/
   public QB_SYNC_API_ERROR_CODES = [
   					   {errorCode:"0001",errorMessage:"SUCCESS MESSAGE"},
   					   {errorCode:"0002",errorMessage:"REQUIRED PARAMETER MISSING :: QUICK BOOK COMPANY ID / PORTAL_NAME"},
   					   {errorCode:"0003",errorMessage:"INVAILD QUICK BOOK COMPANY ID / PORTAL_NAME"},
   					   {errorCode:"0004",errorMessage:"UNABLE_TO_CONNECT_QB_COMPANY"},
   					   {errorCode:"0005",errorMessage:"UNABLE_TO_GET_ACCESS_TOKEN / REFRESH_ACCESS_TOKEN"},
   					   {errorCode:"0006",errorMessage:"INVALID_REQUEST - MISSING_CUSTOMER_OBJECT"},
   					   {errorCode:"0007",errorMessage:"ANOTHER_CUSTOMER_OR_VENDOR_OR_EMPLOYEE_IS_ALREADY_USING_THIS_NAME"},
   					   {errorCode:"0008",errorMessage:"CUSTOMER_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0009",errorMessage:"CUSTOMER_DISPLAY_NAME_NOT_FOUND_ERROR"},
   					   {errorCode:"0010",errorMessage:"INVOICE_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0011",errorMessage:"LINE_DETAILS_ARRAY_OBJECT_FOUND_ERROR"},
   					   {errorCode:"0012",errorMessage:"CUSTOMER_OBJECT_NOT_FOUND_ERROR"},
   					   {errorCode:"0013",errorMessage:"SALES_ORDER_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0014",errorMessage:"INVALID_REQUEST - MISSING_ITEM_OBJECT"},
   					   {errorCode:"0015",errorMessage:"INVOICE_LINE_ITEM_LEVEL_AMOUNT_NOT_FOUND_ERROR"},
   					   {errorCode:"0016",errorMessage:"VALID_PORTAL_NAME"},
   					   {errorCode:"0017",errorMessage:"AUTHENTICATION_ERROR - REQUEST_FOR_REFRESH_TOKEN"},
   					   {errorCode:"0018",errorMessage:"REQUIRED_VALUE_SUPPLIED_INCORRECT_FORMAT_ERROR"},
   					   {errorCode:"0019",errorMessage:"ITEM_NAME_NOT_FOUND_ERROR"},
   					   {errorCode:"0020",errorMessage:"ITEMID_NOT_FOUND_ERROR"},
   					   {errorCode:"0021",errorMessage:"ITEM_BASED_SALES / PURCHASE [INCOME / EXPENSE] ACCOUNT_REFERENCE_NOT_FOUND_ERROR"},
   					   {errorCode:"0022",errorMessage:"ACCESS_TOKEN_DOES_NOT_HAVE_SUFFICIENT_SCOPE - QUICKBOOKS_URL_MISMATCH"},
   					   {errorCode:"0023",errorMessage:"QUICKBOOKS_SYNC_ERROR"},
   					   {errorCode:"0024",errorMessage:"ITEM_TYPE_REQUIRED_FOR_ITEM CREATE/UPDATE_REQUESTS"},
   					   {errorCode:"0025",errorMessage:"NO_QUICKBOOK_INVOICE_ID_FOR_GIVEN_INVOICE_ID_TO_DELETE"},
   					   {errorCode:"0026",errorMessage:"NO_QUICKBOOK_SALES_ORDER_ID_FOR_GIVEN_SALES_ORDER_ID_TO_DELETE"},
   					   {errorCode:"0027",errorMessage:"DUPLICATE / ITEM_NAME_ALREADY_EXISTS"},
   					   {errorCode:"0028",errorMessage:"DUPLICATE / CUSTOMER_NAME_ALREADY_EXISTS"},
   					   {errorCode:"0029",errorMessage:"NUMBER_FORMAT_ERROR"},
   					   {errorCode:"0030",errorMessage:"AS_OF_DATE_FOR_INITIAL_QUANTITY_ON_HAND_NOT_FOUND_ERROR"},
   					   {errorCode:"0031",errorMessage:"ASSET_ACCOUNT_REFERENCE_NOT_FOUND_ERROR"},
					   {errorCode:"0032",errorMessage:"SALES_AND_PURCHASE_ACCOUNT_REFERENCE_NOT_FOUND_ERROR"},
					   {errorCode:"0033",errorMessage:"INVALID_DATA_ERROR - LINE_ITEM_LEVEL_AMOUNT"},
					   {errorCode:"0034",errorMessage:"INVALID_DATA_ERROR - LINE_ITEM_LEVEL_QUANTITY"},
					   {errorCode:"0035",errorMessage:"INVALID_DATA_ERROR - LINE_ITEM_LEVEL_UNITPRICE"},
					   {errorCode:"0036",errorMessage:"TRANSACTION_DATE_CANNOT_EARLIER_THAN_INVENTORY_START_DATE_OF_THE_ITEM"},
					   {errorCode:"0037",errorMessage:"INVALID_DATA_ERROR - ITEM_BASED_QUANTITY_ON_HAND_VALUE"},
					   {errorCode:"0038",errorMessage:"INVALID_DATA_ERROR - ITEM_BASED_PURCHASE_COST"},
					   {errorCode:"0039",errorMessage:"INVALID_DATA_ERROR - ITEM_ID"},
					   {errorCode:"0040",errorMessage:"INVALID_REQUEST - EMAIL_VALIDATION_ERROR"},
					   {errorCode:"0041",errorMessage:"ITEM MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0042",errorMessage:"CUSTOMER MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0043",errorMessage:"INVOICE MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0044",errorMessage:"SALES ORDER MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0045",errorMessage:"INVALID_DATA_ERROR - CUSTOMER_ID"},
					   {errorCode:"0046",errorMessage:"PURCHASE_ORDER_ID_NOT_FOUND_ERROR"},
					   {errorCode:"0047",errorMessage:"VENDOR_ID_NOT_FOUND_ERROR"},
					   {errorCode:"0048",errorMessage:"VENDOR_OBJECT_NOT_FOUND_ERROR"},
					   {errorCode:"0049",errorMessage:"CONTACT_TYPE_NOT_FOUND_ERROR"},
					   {errorCode:"0050",errorMessage:"DUPLICATE / VENDOR_NAME_ALREADY_EXISTS"},
					   {errorCode:"0051",errorMessage:"INVALID_REQUEST - MISSING_VENDOR_OBJECT"},
   					   {errorCode:"0052",errorMessage:"VENDOR_DISPLAY_NAME_NOT_FOUND_ERROR"},
					   {errorCode:"0053",errorMessage:"NO_QUICKBOOK_PURCHASE_ORDER_ID_FOR_GIVEN_PURCHASE_ORDER_ID_TO_DELETE"},
					   {errorCode:"0054",errorMessage:"PURCHASE ORDER MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0055",errorMessage:"VENDOR MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
					   {errorCode:"0056",errorMessage:"BILL_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0057",errorMessage:"BILL MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0058",errorMessage:"NO_QUICKBOOK_BILL_ID_FOR_GIVEN_BILL_ID_TO_DELETE"},
   					   {errorCode:"0059",errorMessage:"INVALID_DATA_ERROR - VENDOR_ID"},
   					   {errorCode:"0060",errorMessage:"VENDOR_CREDIT_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0061",errorMessage:"VENDOR CREDIT MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0062",errorMessage:"NO_QUICKBOOK_VENDOR_CREDIT_ID_FOR_GIVEN_VENDOR_CREDIT_ID_TO_DELETE"},
   					   {errorCode:"0063",errorMessage:"PAYMENT MADE MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0064",errorMessage:"APPLIED_AMOUNT_NOT_FOUND_ERROR"},
   					   {errorCode:"0065",errorMessage:"TOTAL_AMOUNT_NOT_FOUND_ERROR"},
   					   {errorCode:"0066",errorMessage:"PAYMENT_MADE_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0067",errorMessage:"NO_QUICKBOOK_PAYMENT_MADE_ID_FOR_GIVEN_PAYMENT_MADE_ID_TO_DELETE"},
   					   {errorCode:"0068",errorMessage:"PAYMENT_TYPE_NOT_FOUND_ERROR"},
   					   {errorCode:"0069",errorMessage:"CHECK / CREDIT CARD ARE REQUIRED"},
   					   {errorCode:"0070",errorMessage:"EXPENSE MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0071",errorMessage:"NO_QUICKBOOK_EXPENSE_ID_FOR_GIVEN_EXPENSE_ID_TO_DELETE"},
   					   {errorCode:"0072",errorMessage:"EXPENSE_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0073",errorMessage:"ACCOUNT_NAME_NOT_FOUND_ERROR"},
   					   {errorCode:"0074",errorMessage:"TOTAL_AMOUNT_NOT_FOUND_ERROR"},
   					   {errorCode:"0075",errorMessage:"ALREADY_PAYMENT_TYPE_IS_CHECK_WE_UNABLE_TO_UPDATE_THE_PAYMENT_TYPE"},
   					   {errorCode:"0076",errorMessage:"ACCOUNT_TYPE_NOT_FOUND_ERROR"},
   					   {errorCode:"0077",errorMessage:"ESTIMATE MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0078",errorMessage:"NO_QUICKBOOK_ESTIMATE_ID_FOR_GIVEN_ESTIMATE_ID_TO_DELETE"},
   					   {errorCode:"0079",errorMessage:"ESTIMATE_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0080",errorMessage:"CREDIT_NOTES_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0081",errorMessage:"CREDIT NOTES MODULE IS NOT ENABLED FOR QUICKBOOKS SYNC"},
   					   {errorCode:"0082",errorMessage:"NO_QUICKBOOK_CREDIT_NOTES_ID_FOR_GIVEN_CREDIT_NOTES_ID_TO_DELETE"},	
   					   {errorCode:"0083",errorMessage:"LINE_ITEM_LEVEL_UNIT_PRICE_NOT_FOUND_ERROR"},
   					   {errorCode:"0084",errorMessage:"PAYMENT_RECEIVED_ID_NOT_FOUND_ERROR"},
   					   {errorCode:"0085",errorMessage:"NO_QUICKBOOK_PAYMENT_RECEIVED_ID_FOR_GIVEN_PAYMENT_RECEIVED_ID_TO_DELETE"},
   					   {errorCode:"0086",errorMessage:"NO_QUICKBOOK_INVOICE_ID_TO_GET_INVOICE_PAYMENT_STATUS"},
   					   

					   ];
    /***********************  Quick Books API Integration Error Codes Array declaration - ends **********************/
	/******************************** PMT API Documentation - Request Object - Starts *********************/

		public POST_REQ_FIELDS_TASK = [
		{fieldName:"taskId",defaultValue:"",required:"Yes",min:"1",max:"20",validFormats:"20 character string",
		notes:"If this value is non-zero, then the task will be updated. If the value is zero, then the task will be created in the system"},
		{fieldName:"refPMTId",defaultValue:"",required:"Yes",min:"1",max:"50",validFormats:"20 character string",
		notes:"Unique reference id of the third party system"},
		{fieldName:"taskDesc",defaultValue:"",required:"No",min:"1",max:"10000",validFormats:"10000 character string",notes:""},
		{fieldName:"taskDate",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Valid date format MM/dd/yyyy"},
		{fieldName:"deliveryDate",defaultValue:"",required:"Yes",min:"1",max:"10",validFormats:"10 character string",notes:"Valid date format MM/dd/yyyy"},
		{fieldName:"estimated_hours",defaultValue:"",required:"No",min:"1",max:"5",validFormats:"Valid integer value",notes:"Indicates task hours"},
		{fieldName:"versionNumber",defaultValue:"",required:"No",min:"1",max:"20",validFormats:"20 character string",notes:""},
		];
		POST_REQ_FIELDS_PROJECT =[
		{fieldName:"projectCode",defaultValue:"",required:"Yes",min:"1",max:"100",validFormats:"100 character string",notes:"It maps the task in that project"},
		];
	/******************************** PMT API Documentation - Request Object - Ends *********************/

	/******************************** PMT API Documentation Error code  method - starts *********************************/
   public PMT_ERROR_CODES = [
   					   {errorCode:"1000",errorMessage:"SUCCESS_MESSAGE"},
   					   {errorCode:"1001",errorMessage:"AUTHCODE_NOT_FOUND_ERROR"},
					   {errorCode:"1002",errorMessage:"REQUIRED_VALUE_NOT_SUPPLIED_ERROR"},
					   {errorCode:"1003",errorMessage:"VALUE_EXCEEDED_MAX_MIN_ERROR"},
					   {errorCode:"1004",errorMessage:"INVALID_DATA_ERROR"},
					   {errorCode:"1005",errorMessage:"INVALID_DATE_FORMAT_ERROR"},
					   {errorCode:"1006",errorMessage:"PROJECT_DOESNT_EXISTS_ERROR"},
					   {errorCode:"1007",errorMessage:"TASKID_DOSENT_EXISTS_ERROR"},
					   
					   ];
	/******************************** PMT API Documentation Error code  method - ends *********************************/
	/*****************************form menu map for the logged in user - starts*********************************/
	 
	loggedInUserObjectAvailableMenuMap = new Map();
	splittedModuleNames=[];
	viewAccessModuleName=[];
	reviewDisplayedColumnsMap = new Map();
	userDisplayedColumnsMap = new Map();

	public formMenuMap(menuSet){
		 
		for(var loop = 0; loop < menuSet.length; loop++){ 
			 
	       this.formMenuWithChildMap(menuSet[loop]);
        } 
                         
	}

	 
	public formMenuWithChildMap(menuObject){
		if(this.checkNullOrUndefined(menuObject)){
			if(   this.checkNullOrUndefined(menuObject.childrenMenu) 
		&& menuObject.childrenMenu.length>0 ){

			for(var secondLoop = 0; secondLoop < menuObject.childrenMenu.length; secondLoop++){
				
				if(this.checkNullOrUndefined(menuObject.childrenMenu[secondLoop]) && 
					this.checkNullOrUndefined(menuObject.childrenMenu[secondLoop].childrenMenu) &&
					menuObject.childrenMenu[secondLoop].childrenMenu.length > 0){

						for(var thirdLoop = 0; thirdLoop < menuObject.childrenMenu.length; thirdLoop++){
	 			
	              	this.formMenuWithChildMap(menuObject.childrenMenu[thirdLoop]);
	              }
	            }else{
	            	if(this.checkNullOrUndefined(menuObject.childrenMenu[secondLoop].moduleName)){
	            		this.splittedModuleNames = (menuObject.childrenMenu[secondLoop].moduleName).split(",");
		              	for(var thirdLoop = 0; thirdLoop < this.splittedModuleNames.length; thirdLoop++){
		              		let splittedString = this.splittedModuleNames[thirdLoop];
		              		this.loggedInUserObjectAvailableMenuMap.set(this.splittedModuleNames[thirdLoop],
		              			menuObject.childrenMenu[secondLoop].t030_Current_Access);
		              	}
	            	}	
	            }
			}
		}else{
			if(this.checkNullOrUndefined(menuObject.moduleName)){
			this.splittedModuleNames = (menuObject.moduleName).split(",");
		              	for(var thirdLoop = 0; thirdLoop < this.splittedModuleNames.length; thirdLoop++){
		              		let splittedString = this.splittedModuleNames[thirdLoop];
		              		this.loggedInUserObjectAvailableMenuMap.set(this.splittedModuleNames[thirdLoop],
		              			menuObject.t030_Current_Access);
		              	}
		              }
		}
		}
	}

	/********************************form menu map for the logged in user - ends**********************************/

	/****************return access obj for the input modulename if the user has an access - starts****************/
	accessObjForModule;

	public returnObjForMenuIfItHasAccess(moduleName){
   		this.accessObjForModule = this.loggedInUserObjectAvailableMenuMap.get(moduleName);
		return this.accessObjForModule;
	}
	/***************return access obj for the input modulename if the user has an access - ends*******************/ 

	getPageTitle(moduleName:string):string{
		//if(moduleName==='VIEW_CONTACT')
			return 'Neuzen ';
	}

	checkNaN(element: any): number {
	    element = isNaN(element) ? 0 : +element;
	    return element;
	  }

	 moduleMap:Map<string,Map<string,string>>=new Map();
	 formHtmlElementsMap():void{
	  	let htmlLoginElementMap:Map<string,string>=new Map();
	  	htmlLoginElementMap['username']='password';
	  	htmlLoginElementMap['INITIAL']='username';
	  	htmlLoginElementMap['password']='SUBMIT';
	  	this.moduleMap['LOGIN']=htmlLoginElementMap;
 
	  	let htmlCreateTemplateElementMap:Map<string,string>=new Map();
	  	htmlCreateTemplateElementMap['CREATE_TEMPLATE_INITIAL']='vendorCustomerSearchId';
	  	htmlCreateTemplateElementMap['vendorCustomerSearchId']='uniqueNumberId';
	  	htmlCreateTemplateElementMap['uniqueNumberId']='refNumId';
	  	htmlCreateTemplateElementMap['refNumId']='enableLateId';
	  	htmlCreateTemplateElementMap['enableLateId']='profileNameId';
	  	htmlCreateTemplateElementMap['profileNameId']='invDateId';
	  	htmlCreateTemplateElementMap['invDateId']='dueDateId';
	  	htmlCreateTemplateElementMap['dueDateId']='termsId';
	  	htmlCreateTemplateElementMap['termsId']='shipPrefId';
	  	htmlCreateTemplateElementMap['shipPrefId']='deliveryId';
	  	htmlCreateTemplateElementMap['deliveryId']='taxSwitchId';
	  	htmlCreateTemplateElementMap['taxSwitchId']='salesPersonId';
	  	htmlCreateTemplateElementMap['salesPersonId']='itemSearchId';
	  	htmlCreateTemplateElementMap['itemSearchId']='qtyId';
	  	htmlCreateTemplateElementMap['qtyId']='rateId';
	  	htmlCreateTemplateElementMap['rateId']='lineItemTaxId';
	  	this.moduleMap['CREATE_TEMPLATE']=htmlCreateTemplateElementMap; 

	  	let htmlNistarItemElementMap:Map<string,string>=new Map();
	  	htmlNistarItemElementMap['INITIAL']='upcId';
	  	htmlNistarItemElementMap['upcId']='sizeId';
	  	htmlNistarItemElementMap['sizeId']='brandId';
	  	this.moduleMap['NISTAR_CREATE_ITEMS']=htmlNistarItemElementMap; 
	 }

	  salutations = [
		{value: 'Mr.', viewValue: 'Mr.'},
		{value: 'Mrs.', viewValue: 'Mrs.'},
		{value: 'Ms.', viewValue: 'Ms.'},
		{value: 'Miss.', viewValue: 'Miss.'},
		{value: 'Mdm.', viewValue: 'Mdm.'},
		{value: 'Sir.', viewValue: 'Sir.'},
		{value: 'Dr.', viewValue: 'Dr.'},
	];

	displayed_Columns = ['amountPaid', 'loanDate', 'updatedBy'];

	 addressStatusList = [
		{value: true, viewValue: 'Active'},
   		{value: false, viewValue: 'InActive'},
	];

	  taxTypeList = [
     {value:'Tax', viewValue: 'Tax'},
     {value:'Tax Group', viewValue: 'Tax Group'}
     ];

     decoVendorList = [
    {value: 'eSales Graphics House', viewValue: 'eSales Graphics House'}
     ];

     artServiceVendorList = [
    {value: 'eGraphics Vendor 1', viewValue: 'eGraphics Vendor 1'},
    {value: 'eGraphics Vendor 2', viewValue: 'eGraphics Vendor 2'},
    {value: 'eGraphics Vendor 3', viewValue: 'eGraphics Vendor 3'}
     ]; 

     csaList = [
     {value: 'N/A', viewValue: 'N/A'},
    {value: 'Angie Onysyk', viewValue: 'Angie Onysyk'},
     {value: 'Brittney Kirkwood', viewValue: 'Brittney Kirkwood'},
     {value: 'Bree Minor', viewValue: 'Bree Minor'},
     {value: 'Brittney Seguin', viewValue: 'Brittney Seguin'},
     {value: 'Dan Lewis', viewValue: 'Dan Lewis'},
     {value: 'Danielle Bulloch', viewValue: 'Danielle Bulloch'},
     {value: 'Hilary Reid', viewValue: 'Hilary Reid'},
     {value: 'Julie Lucente', viewValue: 'Julie Lucente'},
     {value: 'Jacklyn Selfe', viewValue: 'Jacklyn Selfe'},
     {value: 'Kym Penner', viewValue: 'Kym Penner'},
     {value: 'Stacey Robertson', viewValue: 'Stacey Robertson'},
     {value: 'Stefanie Polman-Tuin', viewValue: 'Stefanie Polman-Tuin'},
     {value: 'Tyler Mclaren', viewValue: 'Tyler Mclaren'},
     {value: 'Tayler Gregg', viewValue: 'Tayler Gregg'},
     {value: 'Tanya Lockwood', viewValue: 'Tanya Lockwood'},
     {value: 'Wayne McMurchy', viewValue: 'Wayne McMurchy'}
     ];

     itemFilterList = [
     {value:'ALL', viewValue: 'All'},
     {value:'GOODS',viewValue:'Goods'},
     {value:'SERVICES',viewValue:'Services'}
     ];

     warrantyFilterList = [
     {value:'ALL', viewValue: 'All'},
     {value:'TRUE',viewValue:'In Warranty'},
     {value:'FALSE',viewValue:'Out of Warranty'}
     ];

     receiveTypeList=[
     {value:'WH TO WH', viewValue: 'Warehouse To Warehouse'},
     {value:'WH TO VEN', viewValue: 'Warehouse To Vendor'}
     ];
     contactsSearch = {data: 'ALL', value: 'ALL'};
     moreActions = ['Mark as Active', 'Mark as Inactive', 'Delete'];
     displayedColumns1 = ['select', 'contactDisplayName', 'company', 'email' ];
     statusOptions = ['Active', 'InActive'];

     Markdatas = [
    {value: 'Markup', viewValue: 'Markup'},
    {value: 'Markdown', viewValue: 'Markdown'},
    {value: 'Item Level', viewValue: 'Item Level'}
  	];

  	
  	decorationTypeList=[
  	'Laser Engraved','Helix-Color', 'None'
  	];

  	 nistarAdminSettingList = [
     {value:'Product_Size', viewValue: 'Size'},
     {value:'Product_PackOf',viewValue:'PackOf'},
     {value:'Product_MadeIn',viewValue:'MadeIn'},
     {value:'Gender',viewValue:'Gender'},
     {value:'Shoes_Width',viewValue:'Shoes Width'},
     {value:'Percentage',viewValue:'Percentage'},
     {value:'Product_Material',viewValue:'Material'},
     {value:'Product_Group',viewValue:'Group'},
     {value:'Product_Sub_Type',viewValue:'Product Sub Type'},
     {value:'Brand',viewValue:'Brand'},
     {value:'Product_Full_Size',viewValue:'Full Size'},
     {value:'Product_Type',viewValue:'Product Type'},
     ];

      ussinoAdminSettingList = [
     {value:'Product_Size', viewValue: 'Size'},
     {value:'Product_PackOf',viewValue:'PackOf'},
     {value:'Product_MadeIn',viewValue:'MadeIn'},
     {value:'Gender',viewValue:'Gender'},
     {value:'Shoes_Width',viewValue:'Shoes Width'},
     {value:'Percentage',viewValue:'Percentage'},
     {value:'Product_Material',viewValue:'Material'},
     {value:'Product_Group',viewValue:'Group'},
     {value:'Product_Sub_Type',viewValue:'Product Type'},
     {value:'Brand',viewValue:'Brand'},
     {value:'Product_Full_Size',viewValue:'Full Size'},
     ];

  	displayedColumns = ['top', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Total'];

  	 confirmationList = [
    {value: 'Yes', viewValue: 'YES'},
    {value: 'No', viewValue: 'NO'}
  	];
  	noOfChildList = [
    {value: 'Lesser_Then', viewValue: 'Lesser Than'},
    {value: 'Greater_Then', viewValue: 'Greater Than'}
  	];
    
    scCreatedList = [
    {value: 'TRUE', viewValue: 'TRUE'},
    {value: 'FALSE', viewValue: 'FALSE'}

    ];
    
  	  adjustStockType=[
     {value:'INCREASE', viewValue: 'Increase'},
     {value:'DECREASE', viewValue: 'Decrease'},
     {value:'OVERRIDE', viewValue:'Override'}
     ];

	 keySequenceMap:Map<number,number>=new Map();
	 sequenceKeyMap:Map<number,number>=new Map();
formPrimaryKeyMapContacts(data,modulename?:string){
 
for(var loop=0;loop<data.length;loop++){
	if(modulename==='VIEW_CONTACT'){
  	this.sequenceKeyMap.set(loop,data[loop].contactId);
  	this.keySequenceMap.set(data[loop].contactId,loop);
 	 }
 	else{
  	this.sequenceKeyMap.set(loop,data[loop].invoiceHeaderId);
  	this.keySequenceMap.set(data[loop].invoiceHeaderId,loop);
 	 }
}
 }

	removeValidation(formControlName,inputForm:FormGroup):void{
   /*to remove the validations for the auto generated value - if it is true - starts*/
    
       inputForm.get(formControlName).setValidators(null);
      inputForm.get(formControlName).markAsTouched();
      inputForm.get(formControlName).updateValueAndValidity();
         
   }
	resetFormControl(formName:FormGroup,inputStr:string): void{
	 formName.get(inputStr).setValue('');
	}

	resetFormControlInFormArray(formName:FormGroup,formArray,indx,inputStr:string): void{
	  (formName.get(formArray) as FormArray).at(indx).get(inputStr).setValue('');
	}

	removeCommaInNumber(value,fractionSize = 2){
	  	let returnvalue:number;
	  	if(typeof value === 'string'){
	  		let [ integer, fraction = '' ] = (value || '').split('.');
	  		integer = integer.replace(new RegExp(',', 'g'), '');
	    	fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? '.' + (fraction + '000000').substring(0, fractionSize): '';
	      	returnvalue =  Number(integer + fraction);
	  	}
	  	else if(typeof value === 'number'){
	  		let [ integer, fraction = '' ] = (value || '').toString().split('.');
	   		fraction =  fractionSize > 0 ? '.' + (fraction + '000000').substring(0, fractionSize): '';
	   		integer = integer.replace(new RegExp(',', 'g'), '');
	    	returnvalue =  Number(integer + fraction);	      
	  	}
	  	return returnvalue;
    }
}