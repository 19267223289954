import { Component, OnInit, Inject} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';

@Component({
  selector: 'app-auto-num-generate',
  templateUrl: './auto-num-generate.component.html'
})
export class AutoNumberGenerateComponent  implements OnInit {

PREFIX;
NEXT_NUMBER;
SUFFIX;
MANUALLY_ADDED;
SAVE_BUTTON;
CANCEL_BUTTON;
	dialogLabelDisplay: string;
dialogPrefixDefault: string;
dialogNextNumber: string;
dialogOrderType: string;
moduleName; warningText: string;
locationId:number;
showFields; autoGenerateNumber; keyedInNumber; requiredNextNumber; savedValue = false;
myForm: FormGroup;

  constructor(public snackBar: MatSnackBar, private commonService: CommonService,
    public dialogRef: MatDialogRef<AutoNumberGenerateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private commonUtils: CommonUtils) {

  this.dialogLabelDisplay = data.dialogLabel;
  this.dialogPrefixDefault = data.dialogPrefixValue;
  this.dialogNextNumber = data.dialogNextNumber;
  this.dialogOrderType = data.dialogOrderType;
  this.moduleName = data.moduleName;
  this.locationId = data.locationId;
this.PREFIX = this.commonUtils.PREFIX;
this.NEXT_NUMBER = this.commonUtils.NEXT_NUMBER;
this.SUFFIX = this.commonUtils.SUFFIX;
this.MANUALLY_ADDED = this.commonUtils.MANUALLY_ADDED;
this.SAVE_BUTTON = this.commonUtils.SAVE_BUTTON;
this.CANCEL_BUTTON = this.commonUtils.CANCEL_BUTTON;
  this.myForm = new FormGroup({
    autoGenerateId: new FormControl(0),
    type: new FormControl(this.moduleName),
    prefix: new FormControl('', Validators.maxLength(10)),
    nextNumber: new FormControl('',[Validators.required,Validators.minLength(1), Validators.maxLength(10)]),
    autoGenerate: new FormControl(''),
    createdTs: new FormControl(new Date()),
    updatedTs: new FormControl(new Date()),
    suffix: new FormControl('', Validators.maxLength(10)),
    t028Location: new FormGroup({locationId: new FormControl(0)})
  })
}

  onSaveClick(): void {
    this.saveAutoGenerateNumber();
    if (this.savedValue) {
      this.dialogRef.close(this.myForm.get('autoGenerate').value);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close('CANCEL');
  }

  ngOnInit() {
    this.fetchAutoGenerateNumber();
  }

  fetchAutoGenerateNumber(): void {
    if(this.locationId != 0 )
     this.myForm.get('t028Location').get('locationId').setValue(this.locationId);
    this.commonService.showProgressBar();
    this.commonService.fetchAutoGenerateNumber(this.myForm.value).subscribe(
    data => {
      this.commonService.hideProgressBar();
      if (data != null) {
        if (data.autoGenerate === true) {
          this.showFields = true;
          this.autoGenerateNumber = true;
          this.keyedInNumber = false;
          this.warningText = 'Your ' + this.dialogOrderType + ' are set on auto-generate mode to save your time.'+
          'Are you sure about changing this setting?';
        } else if (data.autoGenerate === false) {
          this.showFields = false;
          this.autoGenerateNumber = false;
          this.keyedInNumber = true;
           this.myForm.get('nextNumber').clearValidators();
           this.myForm.get('nextNumber').markAsTouched();
           this.myForm.get('nextNumber').updateValueAndValidity();
          this.warningText = 'You have selected manual ' + this.dialogOrderType + ' numbering. Do you want us to auto-generate it for you?';
        }
        this.myForm.patchValue(data);
      }
    }, error => {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
    });
  }

  radioToggle(event): void {
    if (event.value === 'autoGenerate') {
      this.showFields = true;
      this.requiredNextNumber = true;
      this.myForm.get('autoGenerate').setValue(true);
      this.myForm.get('nextNumber').setValidators([Validators.required,Validators.min(1)]);
       this.myForm.get('nextNumber').markAsTouched();
       this.myForm.get('nextNumber').updateValueAndValidity();
    } else if (event.value === 'keyedIn') {
      this.showFields = false;
      this.requiredNextNumber = false;
      this.myForm.get('autoGenerate').setValue(false);
       this.myForm.get('nextNumber').clearValidators();
       this.myForm.get('nextNumber').markAsTouched();
       this.myForm.get('nextNumber').updateValueAndValidity();

    }
  }
  allowNumeric(){ 
    if(this.commonUtils.checkNullOrUndefined(this.myForm.get('nextNumber').value)
    && isNaN(this.myForm.get('nextNumber').value)){
      this.myForm.get('nextNumber').reset();
    }
   }
  saveAutoGenerateNumber(): void {
    if (this.myForm.get('autoGenerate').value === true && this.myForm.get('nextNumber').value === '') {
      this.requiredNextNumber = true;
      this.savedValue = false;
    } else if (this.myForm.get('autoGenerate').value === false || this.myForm.valid) {
      this.commonService.showProgressBar();
      this.commonService.saveAutoGenerateNumber(this.myForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();

        if (data != null) {
          this.myForm.patchValue(data);
            this.commonService.showERPMessage(data.erpmessage);
        }

      
      }, error => {
         this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
      this.savedValue = true;
    } else {
      this.savedValue = false;
    }
  }
}
