import {Component, OnInit,Output, EventEmitter, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';

@Component({
  selector: 'database-details',
  templateUrl: './database-details.component.html'
})
export class CreateDatabaseDetailsComponent extends CommonUtils implements OnInit {
//function;
selectedRowObj;
databaseDetailsForm:FormGroup;
editDatabaseDetailsFormGroup;
 @Output() viewPageRefresh:EventEmitter<any>=new EventEmitter<any>();
    environments=['local','uat','uslive','apaclive'];
 
constructor(private commonService: CommonService,private router: Router, private commonUtils: CommonUtils, 
	public dialogRef: MatDialogRef<CreateDatabaseDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data: any)
{
	super();
  	//this.function=data.function;
    this.editDatabaseDetailsFormGroup = data.databaseDetailsForm;
  	this.selectedRowObj=data.selectedRowObj;

  	this.databaseDetailsForm= new FormGroup({
  		url:new FormControl,
      ip:new FormControl,
  		userName: new FormControl,
  		password: new FormControl,
  		databaseId: new FormControl(this.selectedRowObj.databaseId),
      	script:new FormControl(this.selectedRowObj.script),
      	status: new FormControl(this.selectedRowObj.status),
      	uATStatus: new FormControl(this.selectedRowObj.uATStatus),
      	productionStatus: new FormControl(this.selectedRowObj.productionStatus),
        environmentName:new FormControl('')
  	})
     
}
ngOnInit()
{
this.pageLoad();
}
pageLoad()
{
  if(this.commonUtils.checkNullOrUndefined(this.editDatabaseDetailsFormGroup)){
       this.databaseDetailsForm.get('url').setValue(this.editDatabaseDetailsFormGroup.get('url').value);
       this.databaseDetailsForm.get('ip').setValue(this.editDatabaseDetailsFormGroup.get('ip').value);
       this.databaseDetailsForm.get('userName').setValue(this.editDatabaseDetailsFormGroup.get('userName').value);
       this.databaseDetailsForm.get('password').setValue(this.editDatabaseDetailsFormGroup.get('password').value);

    }

}
saveOrUpdateDatabaseDetails()
{
	 this.commonService.showProgressBar();
   let urlValue=this.databaseDetailsForm.get('ip').value;
    this.dialogRef.close({close:true,resultValue:this.databaseDetailsForm});
    
    
this.databaseDetailsForm.get('url').setValue("jdbc:mysql://"+urlValue+":3306/")
 /* this.commonService.executeQuery(this.databaseDetailsForm.value).subscribe(
    data=>{
      if(data){
     this.dialogRef.close({close:true,resultValue:this.databaseDetailsForm});
      this.commonService.hideProgressBar();
     this.commonService.showERPMessage(data.erpmessage);
  
      }
      },error=>{
       this.commonService.showERPErrorMessage(error);

    });
*/  

}
closeDialog(): void {
this.dialogRef.close({close:true,resultValue:this.databaseDetailsForm});
}
}