import {Component,Inject} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import {FormGroup, FormControl,FormArray, FormBuilder} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-changepassword',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent  extends CommonUtils
{
	ChangePasswordForm:FormGroup;
	enableHint:boolean=false;
	disableButton:boolean=true;
	constructor (private commonService: CommonService, private commonUtils: CommonUtils, public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,)
	{
		super();
		
			this.ChangePasswordForm = new FormGroup
			({
				userId:new FormControl(''),
				userName:new FormControl(''),  
				newPassword: new FormControl(''),  
                confirmPassword: new FormControl(''),
            
               
			})
		
    }

     ngOnInit()
	    {
	     if(this.checkNullOrUndefined(this.commonService.loggedInUserObj))
	     {
	     	this.ChangePasswordForm.patchValue(this.commonService.loggedInUserObj);
	    // 	this.ChangePasswordForm.get('userId').setValue(this.commonService.loggedInUserObj.userId);
	     //	this.ChangePasswordForm.get('userName').setValue(this.commonService.loggedInUserObj.userName);

	     }
	    }
checkUserPassword()
{
	if(this.ChangePasswordForm.get('newPassword').value==this.ChangePasswordForm.get('confirmPassword').value)
	{

		this.enableHint=false;
		this.disableButton=false;
	}
	else
	{
		this.enableHint=true;
		this.disableButton=true;
	}
}
	    saveOrUpdateUserPassword():void
	    {
	   
	    
	   this.commonService.saveOrUpdateUserPassword(this.ChangePasswordForm.value).subscribe(
          data=>{
          	this.dialogRef.close();
          	this.commonService.showERPMessage('Password have been changed successfully');
          	
          },
      
         error=>{
         	this.dialogRef.close();
         	this.commonService.showERPErrorMessage(error);
         });	    
	    }
	    cancelClick()
	    {
	    	this.dialogRef.close();
	    }
}