import {Component,OnInit,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CommonUtils} from '../common-utils';
import {CommonService} from '../../shared/common.service';
import {FormGroup, FormControl} from '@angular/forms';

@Component({
   selector: 'app-view-template-wrapper-analytics',
  templateUrl: './view-template-wrapper-analytics.component.html',
  providers: [],
})

export class ViewTemplateWrapperAnalyticsComponent extends CommonUtils implements OnInit {
 
moduleName;
chartClass;
QuantityOrAmount='Quantity';
commonForm: FormGroup = new FormGroup({
  moduleName: new FormControl(''),
  loggedInUserObj:new FormGroup({
  userId:new FormControl(0)
  })
});
 constructor(public dialogRef: MatDialogRef<ViewTemplateWrapperAnalyticsComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private CommonUtils:CommonUtils,private commonService:CommonService) 
{

  super();
  this.moduleName=data.moduleName;
}
 ngOnInit()
 {

    this.pageLoad();
 }
 pageLoad()
 {
   // The projects assigned to logged in user will be displayed in chart in Project Plan screen whereas all the projects
   //  will be displayed in Projects screen
   if(this.moduleName=='PROJECT_PLAN' || this.moduleName=='VIEW_PROJECT')
     this.fetchProjectHours();
 }

  public barChartOptions:any = {
    scaleShowVerticalLines: false ,
    responsive:true
  };
  public barChartLabelsStatus:string[] = [ 'Count of Status' ];
  public barChartLabelsQuantity:string[] = [ 'Count of Quantity' ];
  public barChartLabelsProject:string[];
  public barChartType:string = 'bar';
  public chartColors;
  public barChartLegend:boolean = true;
 
  public barChartDataStatus:any[] = [];
  public barChartDataQuantity:any[] = [];

 public barChartLabelsAmount:string[] = [ 'Total Amount' ];
 public barChartDataAmount:any[] = [];
  
  public barChartLabelsProductionAmount:string[] = [ 'Total Quantity' ];
  public barChartProductionAmount:any[] = [];
 
  // events
  public chartClicked(e:any):void {
 
  }
 
  public chartHovered(e:any):void {
   
  }

  fetchCarrier():void{
    this.QuantityOrAmount = 'Shipments';
    this.barChartLabelsQuantity = ['Count Of Shipments'];
    this.commonService.fetchCarrier().subscribe(

    data=>
    {      
     if(this.CommonUtils.checkNullOrUndefined(data))
      {
        for(var loop=0 ; loop < data.length; loop++){
          this.barChartDataQuantity.push( {data:[data[loop].countCarrier], label:[data[loop].carrierName]}); 
        }                 
      }
    },
    error=>
    {
     
    });
   }
  fetchProjectHours():void{
     this.commonForm.get('loggedInUserObj').patchValue(this.commonService.loggedInUserObj);
     this.commonForm.get('moduleName').setValue(this.moduleName);
   this.commonService.fetchProjectHours(this.commonForm.value).subscribe(

    data=>
    {     
    if(this.CommonUtils.checkNullOrUndefined(data))
      {
        this.barChartLabelsQuantity = [];
        let estimatedHoursList = [];
        let workedHoursList = [];        
     for(var loop=0 ; loop < data.length; loop++)
     {  
     
       
       estimatedHoursList.push(data[loop].Estimated_Hours);
       workedHoursList.push(data[loop].Actual_Hours);
         this.barChartLabelsQuantity.push(data[loop].Project_Name);
     } 
     this.barChartDataQuantity.push({data:estimatedHoursList,label:'Estimated Hours'});
     this.barChartDataQuantity.push({data:workedHoursList,label:'Actual Hours'}); 
    }
    },
    error=>
    {
     
    });
  }
}
