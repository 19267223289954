
import {throwError as observableThrowError, interval as observableInterval, Observable,Subject,Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Http, Response, Headers} from '@angular/http';
import {Router} from '@angular/router';
import { map,catchError } from 'rxjs/operators';
 
import {CommonService} from '../shared/common.service';
import {HttpClient} from '@angular/common/http';
import {DialogOverviewExampleComponent, EmailDialogComponent, SearchInGridDialogComponent} from '../dialogBox/dialogBox.component';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private httpObj: Http, private router: Router, public dialog: MatDialog, private commonService: CommonService) {}
  public tokenExpirySubscription: Subscription;
  public token: string;
  public refresh_token: string;
  public expires_in: number;
  signInLoadGooglePage(signindata: any) {
    const body = JSON.stringify(signindata);
    const headers = new Headers();
    headers.append('content-Type', 'application/json');
    this.http.post('https://www.googleapis.com/oauth2/v4/token?code=' + signindata + '&redirect_uri=' + environment.APP_URL + 'signin/code/&client_id=733594033776-rg97b4q3i9le9t4dmcuj9ji8n4hbhbt5.apps.googleusercontent.com&client_secret=FkgkjtCqOipJUlWZRCGo6UM1&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/plus.me&grant_type=authorization_code', body)
      .subscribe(
      (response: Response) => {
      
        this.token = response.json().access_token;
        this.refresh_token = response.json().refresh_token;
        this.expires_in = response.json().expires_in;

      
        const sessionTimeout = observableInterval(1000);
        this.tokenExpirySubscription = sessionTimeout.subscribe(
          x => {
            
            if (x >= this.expires_in) {
              this.openDialogSessionTimeout();
              //	alert("Your session expired. Please relogin to continue ");
              this.tokenExpirySubscription.unsubscribe();
         //     this.router.navigate(['']);
            }
           
          }
        );
        this.router.navigate(['home']);
      })
  }
  isAuthenticated() {
   
 if(this.commonService.loggedInUserObj==null || this.commonService.loggedInUserObj==='undefined'
   || this.commonService.loggedInUserObj===undefined 
   || this.commonService.loggedInUserObj.userId===0)
   return false;


    return true;
  }



  openDialogSessionTimeout(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
      width: '500px',
      data: {dialogText: 'Your Session has timed out. Please relogin to continue. ', confText: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
     });
  }
  /*getToken() {

    const headers = new Headers();
    let token;

    const body = JSON.stringify({
       grant_type:"client_credentials"
    });
    const options = {
      headers: new Headers({        
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa('9999:Erp@123'),
      })
    };
 
    this.httpObj.post(this.commonService.SERVICE_URL + 'oauth/token?grant_type=client_credentials', body,
      options)
      .pipe(map(data => data.json())).subscribe(
      data => {
        token = data;        
      },
      error => {
        console.log(" Error in get Token ",error);
      });

    return token;
  }*/
  //existing gettoken() commented and added new one
  // by Ajith[9121] implementing JWT authentication on 18, OCT 2022
  getToken():Promise<any> {

    return new Promise((resolve, reject)=>{  

   this.httpObj.post(this.commonService.SERVICE_URL + 'api/authenticate',
   {"username":this.commonService.loggedInUserObj.userName,"password":this.commonService.loggedInUserObj.password}).subscribe(
    (response: Response) => {
     
       this.token = response.json().token;  
            this.commonService.setUserDataCustom('accessToken',this.token);    
             resolve(  this.token);
     },
     error => {
      //  alert(' Error authenticating. Contact the Administrator')
     });

  
 });
}

  private handleError(error: any) {
    //   alert(error);
    return observableThrowError(error);
  }

}
