import { Component, OnInit,Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
import {Subscription,Observable} from 'rxjs/Rx';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-createreviewdisplay',
  templateUrl: './createreviewdisplay.component.html' 
})
export class CreateReviewDisplayColumn extends CommonUtils  {
myReviewForm;
reviewDisplayObj;
 moduleName: string = environment.NEW_REVIEW_DISPLAY_COLUMN;

 constructor (public dialogRef: MatDialogRef<CreateReviewDisplayColumn>,private commonService: CommonService, private commonUtils: CommonUtils,
  @Inject(MAT_DIALOG_DATA) public data: any){
 	 super();
 this.reviewDisplayObj = data.reviewDisplayObj;

 	this.myReviewForm = new FormGroup
({
  	reviewDisplayColumnId: new FormControl(''),
	moduleName: new FormControl(''),
  columnName: new FormControl(''),
  labelName:new FormControl('')
});
 }
 cancelClick(): void {
  this.dialogRef.close();
}

  ngOnInit() 
  {
    this.pageLoad();
  }

  pageLoad(){
    if(this.commonUtils.checkNullOrUndefined(this.reviewDisplayObj) && this.reviewDisplayObj.reviewDisplayColumnId != 0  ){
      this.myReviewForm.patchValue(this.reviewDisplayObj);
  }
 
  }

saveOrUpdateReviewDisplayColumn(): void {
   this.commonService.showProgressBar();
    this.commonService.saveOrUpdateReviewDisplayColumn(this.myReviewForm.value).
    subscribe(data => {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage(data.erpmessage);
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
     });

   this.dialogRef.close(true);

   }

}


