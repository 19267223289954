 
     <!-- <div class="btn-group" >
        <span matTooltip="Send Customer Statements"><button mat-icon-button (click)="sendCustStmts()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">account_balance_wallet</mat-icon></button></span>
     </div>
     <div class="btn-group">
        <span matTooltip="Print"><button mat-icon-button (click)="openDialogPrint()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">print</mat-icon></button></span>
     </div> -->
      <div class="btn-group">

       <span *ngIf="moduleName!='VIEW_ITEM'  && moduleName!='VIEW_PACKAGES' && moduleName!='VIEW_ISSUES'  &&  moduleName!='VIEW_PRICE_LIST' && moduleName!='VIEW_EMPLOYEE' && moduleName!='VIEW_PRODUCTION_SLIP' && moduleName!='VIEW_ARTWORK_REQUEST' && moduleName!='VIEW_FEEDBACK' && moduleName!='MANAGE_ARTWORK' && moduleName!='VIEW_SHIPPING' && moduleName!='VIEW_SALES_RECEIPT' && moduleName!='VIEW_REFUND_RECEIPT' && emailShowFlag && arrayListIsOne" matTooltip="send email"><button mat-icon-button (click)="openEmailDialog()" color="primary" class="buttonWidth"><mat-icon class="material-icons">email</mat-icon></button></span> 

         <!--  <span *ngIf=" moduleName == 'VIEW_PO' || moduleName == 'VIEW_SALESORDER' || moduleName =='VIEW_VENDOR_CREDIT'  ||  moduleName =='VIEW_CREDIT_NOTES'  || 
          moduleName =='VIEW_INVOICE'  ||  emailShowFlag || arrayListIsOne" matTooltip="send email"><button mat-icon-button (click)="openEmailDialog()" color="primary" class="buttonWidth"><mat-icon class="material-icons">email</mat-icon></button></span>{{emailShowFlag}} -->

        <span *ngIf="moduleName!='VIEW_ITEM' && moduleName!='VIEW_CONTACT' && moduleName!='VIEW_PACKAGES' && moduleName!='VIEW_ISSUES'   &&   moduleName!='VIEW_PRICE_LIST' && moduleName!='VIEW_EMPLOYEE' && moduleName!='VIEW_PRODUCTION_SLIP' && moduleName!='VIEW_ARTWORK_REQUEST'&& moduleName!='VIEW_FEEDBACK' && moduleName!='MANAGE_ARTWORK' && moduleName!='VIEW_RETURNS' && moduleName != 'VIEW_TIMESHEET'  && moduleName!='VIEW_SHIPPING' && moduleName!='VIEW_RESERVATION' && moduleName!='VIEW_DATABASE' && showVoidFlag" matTooltip="Download PDF"><button mat-icon-button (click)="downloadPdf()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button></span>
        <span *ngIf="moduleName=='VIEW_CONTACT' " matTooltip="Send Customer Statements"><button mat-icon-button (click)="downloadCustomerStatements()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">contacts
        </mat-icon></button></span>
         <span *ngIf="moduleName=='VIEW_PRODUCTION_SLIP' " matTooltip="Production Slip"><button mat-icon-button (click)="downloadProductionSlip()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">picture_as_pdf
        </mat-icon></button></span>
        <span  *ngIf="multiplePDFPackage" matTooltip="Download PDF"><button mat-icon-button (click)="downloadPackagesPdf()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button></span>

     </div>
     <!--  <div class="btn-group" *ngIf="multiplePDFPackage">
        <span  **ngIf="multiplePDFPackage" matTooltip="Download PDF"><button mat-icon-button (click)="downloadPackagesPdf()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button></span>
      </div> -->
      <div class="btn-group"  appDropdown *ngIf="moduleName=='VIEW_RETURNS' && arrayListIsOne==='show' ">
        <button mat-icon-button type="button" class="buttonWidth" color="primary" (click)="callDropDown(moduleName,'VIEW_RETURNS')" aria-haspopup="true" aria-expanded="false"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button>
     
        <div class="dropdown-menu" style="min-width: 170px;" *ngIf="dropDownFlag===true">
        
          <div class="dropdown-divider"></div>
            <a class="dropdown-item"  style="cursor: pointer;"  (click)="downloadTaxInvoicePdf()"><mat-icon >file_download</mat-icon><p style="margin-left: -1%;">Tax Invoice</p>
            </a>
          
            <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="cursor: pointer;" (click)="downloadPdf()"><mat-icon>file_download</mat-icon><p style="margin-left: -1%;">Delivery Challan</p>
              </a>
            <div class="dropdown-divider"></div>
          
        </div>
      </div>
       <div class="btn-group"  appDropdown *ngIf="moduleName=='VIEW_PACKAGES' && arrayListIsOne==='show' ">
        <button mat-icon-button type="button" class="buttonWidth" color="primary" (click)="callDropDown(moduleName,'VIEW_PACKAGES')" aria-haspopup="true" aria-expanded="false"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button>
     
        <div class="dropdown-menu" style="min-width: 170px;" *ngIf="dropDownFlag===true">
        
          <div class="dropdown-divider"></div>
            <a class="dropdown-item"  style="cursor: pointer;"  (click)="downloadPackagesPdf()" ><mat-icon >file_download</mat-icon><p style="margin-left: -1%;">Packages</p>
            </a>
          <div *ngIf="showShipmentFlag">
            <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="cursor: pointer;" (click)="downloadShipmentPdf()"><mat-icon>file_download</mat-icon><p style="margin-left: -1%;">Shipment</p>
              </a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </div>
     <!--  <div class="btn-group">
        <span *ngIf="moduleName=='VIEW_CONTACT' " matTooltip="Download PDF"><button mat-icon-button (click)="downloadStmtPdf()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">picture_as_pdf</mat-icon></button></span>
     </div> -->
<!-- 
     <div class="btn-group" *ngIf="moduleName!='VIEW_CONTACT'">
        <span matTooltip="Delete"><button mat-icon-button (click)="delete()" color="primary" class="buttonWidth"><mat-icon class="matIconColor">delete</mat-icon></button></span>
     </div> -->

    <div class="btn-group">
        <span *ngIf="moduleName!='VIEW_CONTACT' && moduleName!='VIEW_ITEM' && moduleName!='VIEW_SALESORDER' && moduleName!='VIEW_INVOICE' && moduleName!='VIEW_CREDIT_NOTES' && moduleName != 'VIEW_BILL' && moduleName != 'VIEW_VENDOR_CREDIT' && moduleName != 'VIEW_PO' && moduleName!='VIEW_REFUND_RECEIPT'  && showConvertOpen &&currentPageAccessObj.editAccess " matTooltip="Convert to Open"><button mat-raised-button (click)="convertToOpen()" color="primary" class="buttonWidth">Convert To Open</button></span>
       <!--  <span *ngIf="moduleName==='VIEW_VENDOR_CREDIT' && arrayListIsOne==='show' && billOpenStatus &&currentPageAccessObj.editAccess && convertFlag" matTooltip="Apply to Bills">
          <button mat-raised-button (click)="applyToBills()" color="primary" class="buttonWidth">Apply to Bills</button></span> -->
        <!-- <span *ngIf="moduleName==='VIEW_PO' && arrayListIsOne==='show' &&currentPageAccessObj.editAccess && convertFlag" matTooltip="Convert to Bill"><button mat-raised-button  (click)="convertToBill()" color="primary" class="buttonWidth">Convert To Bill</button></span> -->

        <!-- <span *ngIf="moduleName==='VIEW_SALESORDER' && arrayListIsOne==='show' && convertInvoiceFlag &&currentPageAccessObj.editAccess && convertInvoice" matTooltip="Convert to Invoice"><button mat-raised-button  (click)="convertToInvoice()" color="primary" class="buttonWidth">Convert To Invoice</button>
        </span>
        
        <span *ngIf="moduleName==='VIEW_SALESORDER' && convertVoidFlag && currentPageAccessObj.editAccess" matTooltip="Convert to Void"><button mat-raised-button  (click)="convertSOToVoid()" color="primary" class="buttonWidth">Convert To Void</button>
        </span> -->
          <span *ngIf="moduleName==='PART_REQUEST' && arrayListIsOne==='show' && convertFlag &&currentPageAccessObj.editAccess " matTooltip="Convert to PO"><button mat-raised-button  (click)="convertToPO()" color="primary" class="buttonWidth">Convert To PO</button>
        </span>

        <!--  <span  *ngIf="moduleName==='VIEW_BILL' &&  arrayListIsOne==='show' && billOpenStatus &&currentPageAccessObj.editAccess && convertFlag" matTooltip="Use Credit"><button mat-raised-button type="button" (click)="openUseCreditPopUp()" color="primary" class="buttonWidth">Use Credits</button></span>
 -->
<!-- <span  *ngIf="moduleName==='VIEW_INVOICE' &&  arrayListIsOne==='show' && billOpenStatus && currentPageAccessObj.editAccess && convertFlag" matTooltip="Use Credit"><button mat-raised-button type="button" (click)="openUseCreditNotesPopUp()" color="primary" class="buttonWidth">Use Credits</button></span> -->

         <!-- <span  *ngIf="moduleName==='VIEW_BILL'  &&  arrayListIsOne==='show' && billOpenStatus &&currentPageAccessObj.editAccess && recordPaymentFlag" matTooltip="Record Payment"><button mat-raised-button type="button" (click)="openRecordPaymentPopup()" color="primary" class="buttonWidth">Record Payment</button></span> -->

          <!-- <span  *ngIf="( moduleName==='VIEW_ARTWORK_REQUEST' || moduleName==='VIEW_ARTWORK') &&  arrayListIsOne==='show' &&currentPageAccessObj.editAccess && convertFlag && salesConvertFlag" matTooltip="Convert to Sales Order"><button mat-raised-button type="button" (click)="convertToSalesOrder()" color="primary" class="buttonWidth"> Convert to Sales Order</button></span> -->
          <!-- <span  *ngIf="(moduleName==='VIEW_ESTIMATE') &&  arrayListIsOne==='show' &&currentPageAccessObj.editAccess && convertInvoiceFlag && invoiceConvertFlag" matTooltip="Convert to Invoice"><button mat-raised-button type="button" (click)="convertToInvoice()" color="primary" class="buttonWidth">Convert to Invoice</button></span> -->

          <!--  <span  *ngIf="(moduleName==='VIEW_VENDOR_CREDIT' ) && showConvertVoid &&currentPageAccessObj.editAccess" matTooltip="Convert to Void"><button mat-raised-button type="button" (click)="convertToVoid()" color="primary" class="buttonWidth">Convert to Void</button></span> -->

           <!--  <span  *ngIf="moduleName==='VIEW_PACKAGES' && currentPageAccessObj.editAccess && showVoidButton" matTooltip="Mark as Void"><button mat-raised-button type="button" (click)="markAsVoid()" color="primary" class="buttonWidth">Mark as Void</button></span> -->

<!-- <span *ngIf="moduleName=='VIEW_ARTWORK_REQUEST' && currentPageAccessObj.editAccess && showConvertSent" matTooltip="Mark as Sent"><button mat-raised-button (click)="markasSent()" color="primary" class="buttonWidth">Mark as Sent</button></span> -->

<span *ngIf="moduleName =='VIEW_FEEDBACK' && showConvertTask && currentPageAccessObj.editAccess" >
    <button mat-raised-button (click)="convertToTask()" matTooltip="Convert to Task" color="primary" class="buttonWidth">Convert To Task</button>
</span>
<span *ngIf="moduleName =='MANAGE_ARTWORK' && showConvertWIP && currentPageAccessObj.editAccess && arrayListIsOne=='show'" >
    <button mat-raised-button (click)="convertToProofWIP()" matTooltip="Convert to Proof WIP" color="primary" class="buttonWidth">Convert To Proof WIP</button>
</span>

 <!--  <span  *ngIf="moduleName==='VIEW_INVOICE' " matTooltip="Use Credit"><button mat-raised-button type="button" (click)="openUseCreditPopUp()" color="primary" class="buttonWidth">Use Credits</button>
  <button mat-raised-button type="button" (click)="openRecordPaymentPopup()" color="primary" class="buttonWidth">Record Payment</button>
 
</span>
 -->
<!-- <span *ngIf="moduleName =='VIEW_PRODUCTION_SLIP' &&currentPageAccessObj.editAccess && arrayListIsOne=='show' && showProductionSlipStatus" >
    <button mat-raised-button (click)="productionSlipStatusChange('void')" matTooltip="Mark as Void" color="primary" class="buttonWidth">Mark as Void</button>
</span>
<span *ngIf="moduleName =='VIEW_PRODUCTION_SLIP' &&currentPageAccessObj.editAccess && arrayListIsOne=='show' && showProductionSlipStatus" >
    <button mat-raised-button (click)="productionSlipStatusChange('completed')" matTooltip="Mark as Completed" color="primary" class="buttonWidth">Mark as Completed</button>
</span> -->

<!--  <span  *ngIf="moduleName==='VIEW_CREDIT_NOTES' && arrayListIsOne==='show' && convertInvoice &&currentPageAccessObj.editAccess && convertFlag" matTooltip="Use Credit"> 
<button mat-raised-button (click)="applyToInvoice()" color="primary" class="buttonWidth">Apply to Invoice</button>
</span> -->
<span  *ngIf="moduleName==='VIEW_TIMESHEET' && closeTaskFlag && currentPageAccessObj.editAccess" matTooltip="Mark as Closed"> 
<button mat-raised-button (click)="closeTask()" color="primary" class="buttonWidth">Close</button>
</span>



     </div>
 <!-- <div *ngIf="currentPageAccessObj.editAccess"> -->
     <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="(moduleName =='VIEW_CONTACT'  || moduleName=='VIEW_ITEM' || moduleName=='VIEW_EMPLOYEE' || moduleName === 'VIEW_PRICE_LIST')&&currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of moreActions" [value]="action">
              {{action}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div>
    <!--  <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="moduleName =='VIEW_PRODUCTION_SLIP' &&currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of productionSlipActions" [value]="action">
              {{action}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div> -->

     <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="(moduleName ==='VIEW_PRODUCTION_SLIP' || moduleName === 'VIEW_SALESORDER' || moduleName === 'VIEW_INVOICE'
     || moduleName === 'VIEW_ESTIMATE' || moduleName === 'VIEW_RESERVATION' || moduleName === 'VIEW_PACKAGES' || moduleName === 'VIEW_CREDIT_NOTES' 
     || moduleName === 'VIEW_PO' || moduleName === 'VIEW_BILL' || moduleName === 'VIEW_VENDOR_CREDIT' || moduleName === 'VIEW_ARTWORK' || moduleName === 'VIEW_ARTWORK_REQUEST' || moduleName === 'VIEW_SHIPPING')&& currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of options" [value]="action">
              {{action.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div>

      <!-- <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="(moduleName =='VIEW_ESTIMATE' )&&currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of estimateActions" [value]="action">
              {{action}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div> -->
       <!-- <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="(moduleName =='VIEW_ARTWORK' )&&currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of artworkActions" [value]="action">
              {{action}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div> -->

       <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="moduleName =='VIEW_FEEDBACK'&&currentPageAccessObj.editAccess">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-select placeholder="More Actions">
            <mat-option (click)="openMoreActions(action)"
             *ngFor="let action of issuesActions" [value]="action">
              {{action}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div>


  <div class="btn-group" style="margin-left: 1%; margin-top: 1%;" 
     *ngIf="(moduleName =='VIEW_DATABASE' && currentPageAccessObj.editAccess)">
        <button mat-raised-button  matTooltip="Mark as Void" color="primary" (click)="executeQuery({},null)" class="buttonWidth">Execute </button>
     </div>

     <!-- </div> -->
     <div class="btn-group pull-left-one" >
         <span matTooltip="Remove Selection!"><button mat-icon-button type="button" color="primary" class="buttonWidth" (click)="closeBtn()"><mat-icon class="matIconColor">close</mat-icon></button></span>
     </div>
 