    <main>
      <div id="wrapper">
        <aside  id="aside"  *ngIf="showHeader" class="aside aside-left" data-fuse-bar="aside" data-fuse-bar-media-step="md" data-fuse-bar-position="left">          
          <div class="aside-content-wrapper">
            <div  class="aside-content bg-primary-700 text-auto" >
              <div class="aside-toolbar" style="display:none;">
                <div class="logo">
                  <img src="{{imageSrc}}" (error)="errorHandler($event)">
                </div>
                <button id="toggle-fold-aside-button" type="button" class="btn btn-icon d-none d-lg-block" data-fuse-aside-toggle-fold>
                <i class="icon icon-backburger"></i>
                </button>
              </div>
              <div class="col-lg-12 nopadding" style="color:#222;">
              <app-menu (settingsAvailableEmitter)="settingsAvailable($event)"></app-menu></div>
            </div>
          </div>
        </aside>
        <div class="content-wrapper">
          <nav id="toolbar" class="bg-white" *ngIf="showHeader">
            <div class="row no-gutters align-items-center flex-nowrap" >
              <div class="col">
                <div class="row no-gutters align-items-center flex-nowrap">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 topheader"  style="height: 40px" >
                  <app-mainheader [displaySettings]="displaySettings"></app-mainheader>
                </div>
                <div class="col-lg-12" style="margin-top: 52px !important;position: fixed;top: 0">
                   <mat-progress-bar class="col-lg-12" *ngIf="(data$ | async)" mode="indeterminate" ></mat-progress-bar>
                </div>
              </div>
            </div> </div>
           
          </nav>
          
          <!-- <div class="content custom-scrollbar"> -->
             <div >
             <mat-sidenav-container class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">    
    <mat-sidenav-content class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding"  style="overflow: visible; min-height: 100vh;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 adjustcontainer">      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mobileTitlePadding">        
        <router-outlet></router-outlet>
      </div>
    </div>
   <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearBoth" style="text-align: right;font-size: 11px;">
      {{storagePercent |number :'1.1'}} % full <br><i>Using {{storageUsed | number :'1.1'}} MB / 5000MB</i>
    </div>
  -->
   
    </mat-sidenav-content>
    
    </mat-sidenav-container>

    <footer class="the-footer flex-wrap justify-between">
 <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearBoth" style="text-align: center;  ">
 <!--      <a href="https://esales.in" target="_blank"><img src="assets/esales_software_solutions.png" style="width:8%" alt="" matTooltip="Product of esales software solutions">
      </a> -->
     <!--  <img src="assets/gcp.png" width="80px" matTooltip="Runs on Google Cloud ">     -->  
    </div>
    </footer>
          </div>
        </div>
      </div>
    </main>