import { Component,Inject} from '@angular/core';
import {CommonUtils } from  '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup, FormArray, NgForm, Validators, AbstractControl, FormBuilder} from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'document-preview',
  templateUrl: './documentPreviewPage.component.html'
})
export class DocumentPreviewComponent   extends CommonUtils {
	
currencyCode;
myForm: FormGroup;
moduleDispText: string;
organizationObj: any;
lineItemList:Array<any>=[];
orgImgPath: string;
portalName: string;
moduleName: string;
templateData: any;
t100ContactVendorId: any;
t100ContactCustomerId: any;
terms: any;
t023PaymentMode: any;
shipmentPref: any;
dropShipAddress;
noTemplateFlag:boolean = true;

constructor(public commonUtils: CommonUtils,
			private commonService: CommonService,
             public dialogRef: MatDialogRef<DocumentPreviewComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
   		super();
      this.orgImgPath  = this.commonService.SERVICE_URL+'erpassets';
      if(this.commonUtils.checkNullOrUndefined(data) && this.commonUtils.checkNullOrUndefined(data.myForm)){
         this.myForm = this.getSaveForm(data.moduleName); 
         this.moduleName = data.moduleName;
         if(this.moduleName === 'NEW_BILL'){
           let balanceDue = data.myForm.paidAmount;
         }
         if(this.commonUtils.checkNullOrUndefined(data.templateForm[0])){
           this.templateData = data.templateForm[0];  
         }
         this.t100ContactVendorId = data.myForm.t100ContactVendorId;
         this.t100ContactCustomerId = data.myForm.t100ContactCustomerId;
         this.lineItemList = data.myForm.t401InvoiceDetailsList;
         this.terms = data.myForm.terms;
         this.shipmentPref = data.myForm.t024ShipmentPrefence;
         this.displayDocument(data.myForm);
         this.myForm.get('invoiceNumber').setValue(data.invoiceNumber);
         this.dropShipAddress = data.dropshipAddr;
         this.t023PaymentMode = data.myForm.t023PaymentMode;
      } 
  }

displayDocument(data:any){
	this.myForm.patchValue(data);
	this.organizationObj = this.commonService.loggedInUserObj.t000Organization;  
	this.portalName = this.organizationObj.portalName;
	this.portalName = this.portalName.toUpperCase();

  if(this.moduleName === 'NEW_PO'){
    if(this.templateData.xslTemplateName === 'PurchaseOrder_Template2.XSL' || this.templateData.xslTemplateName === 'Purchase_Order.XSL' ){
      this.noTemplateFlag = false;
    }
  }else   if(this.moduleName === 'NEW_BILL'){
      this.noTemplateFlag = false;
  }else   if(this.moduleName === 'NEW_VENDOR_CREDIT'){
      this.noTemplateFlag = false;
  }else   if(this.moduleName === 'NEW_ESTIMATE'){
    if(this.templateData.xslTemplateName === 'Estimate_Order.XSL' || this.templateData.xslTemplateName === 'Estimate_Order_FO.XSL'){
      this.noTemplateFlag = false;
    }
  }else   if(this.moduleName ===  'NEW_SALESORDER'){
     if(this.templateData.xslTemplateName === 'Sales_Order_FO.XSL' || this.templateData.xslTemplateName === 'Sales_Order_FO_Amount.XSL'){
      this.noTemplateFlag = false;
    }
  }else   if(this.moduleName ===  'NEW_INVOICE'){
     if(this.templateData.xslTemplateName === 'Invoice_Order.XSL' || this.templateData.xslTemplateName === 'Invoice_Order_FO.XSL'){
      this.noTemplateFlag = false;
    }
  }else   if(this.moduleName ===  'NEW_CREDIT_NOTES'){
      this.noTemplateFlag = false;
  }else   if(this.moduleName ===  'NEW_SALES_RECEIPT'){
     if(this.templateData.xslTemplateName === 'Sales_Receipt.XSL'){
      this.noTemplateFlag = false;
    }
  }else  if(this.moduleName ===  'NEW_REFUND_RECEIPT'){
      this.noTemplateFlag = false;
  }
  else{
    this.noTemplateFlag = true;
  }

}

getSaveForm(moduleName): FormGroup {
    const invoiceForm: FormGroup = new FormGroup({
      batchSyncType:new FormControl(''),
      sourceModule:new FormControl(''),
      invoiceHeaderId: new FormControl(0),
      lineItemDetailIDStr: new FormControl(''),
      taxAtLineLevel: new FormControl(true),
      invoiceNumber: new FormControl(''),
      invoiceDate: new FormControl(new Date()),
      dueDate: new FormControl(new Date()),
      notes: new FormControl(''),
      lateFee: new FormControl(),
      refNumber: new FormControl(''),
      profileName: new FormControl(''),
      t401InvoiceDetailsList: new FormArray([]),
      t304_FP_WorkOrdersSet:new FormArray([]),
      /*t100ContactVendorId: this.t100ContactVendorId,
      t100ContactCustomerId: this.t100ContactCustomerId,*/
      t008Tax: new FormGroup({taxId: new FormControl(0)}),
      amountDue:new FormControl(''),
      subTotal: new FormControl({value: '0', disabled: false}),
      contactCurrencyCode:new FormControl(''),
      fpEnable:new FormControl(false),
      t020EmailTemplateObj: new FormGroup({
        type: new FormControl(''),  
        emailTemplateId: new FormControl(),
        subject: new FormControl(''),
        body: new FormControl(''),
        cC:new FormControl(),
        bCC:new FormControl(),
        to:new FormControl(),
        defaultTemplate:new FormControl(),
        statusFlag:new FormControl()
      }),
      //t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE')}),
      terms: new FormGroup({lookupId: new FormControl(0),lookupValue: new FormControl('')}),
      t024ShipmentPrefence: new FormGroup({shipmentPerfenceId: new FormControl(0), name: new FormControl('')}),
      t302SalesPersons : new FormGroup({salesPersonId: new FormControl(0), name:new FormControl('')}),
      t022deliveryMethod: new FormGroup({deliveryMethodId: new FormControl(0)}),
      discountType: new FormControl(''),
      totalTaxValue: new FormControl('0'),
      discountTypeValue: new FormControl('0'),
      discountValue: new FormControl({value: '0', disabled: false}),
      adjustmentInputValue: new FormControl('0'),
      adjustmentName: new FormControl(''),
      adjustmentFinalValue: new FormControl({value: '0', disabled: false}),
      finalTotal: new FormControl({value: '0', disabled: false}),
      paidAmount: new FormControl('0'),
      balanceDue: new FormControl('0'),
      creditBalance: new FormControl('0'),
      amountInWords: new FormControl(''),
       paymentMade: new FormControl(0),
      paymentMadeUsingCredits: new FormControl(0),
      totaltaxrate: new FormControl('0'),
      customerNotes: new FormControl(''),
      billingId:new FormControl(0),
      shippingId:new FormControl(0),
      quantityToBeInvoiced:new FormControl(0),
      invoicedQuantity:new FormControl(0),
      termsandcondition: new FormControl(''),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(0), userName : new FormControl('')}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(0), userName : new FormControl('')}),
      moduleName: new FormControl(moduleName),
      statusText: new FormControl('DRAFT'),
      glTransactionSynch:new FormControl(false),
      parentHeaderObj: new FormGroup({invoiceHeaderId: new FormControl(''), moduleName : new FormControl('')}),
      t004LookupPOStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t019TemplateDetails: new FormGroup({templateDetailId: new FormControl(0)}),
      t004LookupPOReceivedStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004LookupPOBillStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004LookupBillStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004StatusObj: new FormGroup({lookupId: new FormControl(0)}),
      lazyLoad: new FormControl('N'),
      loggedInUserOrganizationName : new FormControl(''),
      orgLogoPath : new FormControl(''),
      displayDicardMessage:new FormControl('Y'),
      fileUpload:new FormControl(''),
      organizationId:new FormControl(''),
      portalName: new FormControl(''),
      dateFormat:new FormControl(''),
      billingAttention : new FormControl(''),
      billingAddressLineOne : new FormControl(''),
      billingAddressLineTwo : new FormControl(''),
      //t010BillingCountry : new FormGroup({countryId: new FormControl(4)}),
      t010BillingCountry: new FormGroup({countryId: new FormControl(0),countryValue: new FormControl('')}),
      billingCity : new FormControl(''),
      billingState : new FormControl(''),
      billingZipCode : new FormControl(''),
      billingPhone : new FormControl(''),
      billingFax: new FormControl(''),
      billingGstNumber : new FormControl(''),
      shippingAttention: new FormControl(''),
      shippingAddressLineOne: new FormControl(''),
      shippingAddressLineTwo: new FormControl(''),
      //t010ShippingCountry: new FormGroup({countryId: new FormControl(4)}),
       t010ShippingCountry: new FormGroup({countryId: new FormControl(0),countryValue: new FormControl('')}),
      shippingCity: new FormControl(''),
      shippingState: new FormControl(''),
      shippingZipCode: new FormControl(''),
      shippingPhone: new FormControl(''),
      shippingFax: new FormControl(''),
      shippingGstNumber: new FormControl(''),
      totalReceivedQuantity: new FormControl(''),
      salesOrderStatus: new FormControl(''),
      totalBilledQuantity: new FormControl(''),
      totalUnBilledQuantity: new FormControl(''),
      totalQuantityToBeReceived: new FormControl(''),
      totalOrderedQuantity: new FormControl(''),
      t004LookupSOStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004ShipmentStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004InvoiceStatusId: new FormGroup({lookupId: new FormControl(0)}),
      t004PackageStatusId: new FormGroup({lookupId: new FormControl(0)}),
      currencyConversionAmt:new FormControl(''),
      t004LookupSOInventoryStatusId: new FormGroup({lookupId: new FormControl(0)}),
      totalPackedQuantity: new FormControl(''),
      totalQuantityYetToBePacked: new FormControl(''),
      totalShippedQuantity: new FormControl(''),
      totalQuantityYetToBeShipped: new FormControl(''),
      totalInvoicedQuantity: new FormControl(''),
      totalQuantityYetToBeInvoiced: new FormControl(''),
      workOrderNumber:new FormControl(''),
      creditCardFee:new FormControl(0),
      remarks :  new FormControl(''),
      annexure:  new FormControl(''),
      t201PriceListHeader :  new FormGroup({
         priceListHeaderId : new FormControl(0),
         name:new FormControl(''),
         itemType : new FormControl(''),
         percentageType: new FormControl(''),
         percentageValue:new FormControl()}),
         billId: new FormControl(0),
         t004LookupCreditNoteStatusId: new FormGroup({lookupId: new FormControl(0)}),
         t004Lookup: new FormGroup({lookupId: new FormControl(0)}),
         t004lookupInvoiceStatusId: new FormGroup({lookupId: new FormControl(0)}),
         t507_PurchaseOrder_Header_Obj:new FormGroup({invoiceHeaderId : new FormControl(0)}),
         t517_RecurringBill_Header_Obj:new FormGroup({invoiceHeaderId : new FormControl(0)}),
          t303_SalesOrder_HeaderObj:new FormGroup({invoiceHeaderId : new FormControl(0)}),
         t300_Estimate_HeaderObj:new FormGroup({invoiceHeaderId : new FormControl(0)}),
      });
    return invoiceForm;    
  }

  closeBtnClick():void{
    this.dialogRef.close();
  }
}
