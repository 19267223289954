
<mat-card class="col-lg-12">
 <div class="modal-body">
 <div class="col-lg-12">
  <div class="col-lg-12">
    <mat-card-title><mat-icon>search</mat-icon>Search</mat-card-title>
    <!--<div class="module-center-align">
       <mat-form-field class="col-lg-6" >
        <mat-select [(ngModel)]="searchModuleValue"> 
           <mat-option *ngFor="let module of searchModule" [value]="module" (click)="changeModuleName(module)">{{module}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  -->
      </div>
</div>
</div>


   <form [formGroup]="dialogForm">
<div class="modal-body" style="clear:both">

  
    <div class="col-lg-12" *ngIf="contactsModuleDisplay">
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
      
          <mat-select placeholder="{{SELECT_TYPE}}" formControlName="contactsType">
            <mat-option *ngFor="let type of types" [value]="type">
              {{type}}
            </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{CONTACT_DISPLAY_NAME}}" formControlName="contactDisplayName" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{COMPANY_NAME}}" formControlName="company" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
        
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{FIRST_NAME}}" formControlName="firstName" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{LAST_NAME}}" formControlName="lastName" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
       <!--  <mat-form-field class="col-lg-6">
         
          <mat-select placeholder="Select Status" formControlName="activeCriteria">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{status.value}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
         <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of contactsStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{EMAIL_PLACEHOLDER}}" formControlName="email" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{WORK_PHONE}}" formControlName="workPhone" [textMask]="{mask: mask}" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      </div>
      <!-- <div class="col-lg-12">
        <mat-form-field class="col-lg-12">
          <input matInput placeholder="Notes" formControlName="internalNotes" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      </div> -->
    </div>


  <div class="col-lg-12" *ngIf="poModuleDisplay">
   
    <div class="col-lg-12 row ">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{PURCHASE_ORDER_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>
     <div class="col-lg-6">
   <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'">
   </app-customer-search>
    </div>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{PO_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{PO_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{DELIVERY_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{DELIVERY_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
     <div class="col-lg-12 row">
      <!--   <mat-form-field class="col-lg-6">
        <input type="text" placeholder="Item Name" formControlName="itemNameForFetch" matInput>
      </mat-form-field> --> 
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
        <mat-form-field class="col-lg-3">
        <input matInput placeholder="Min Range"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="Max Range"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field> 
    </div>
    <div class="col-lg-12 row">
    
    <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId" >
            <mat-option *ngFor="let status of poStatuses" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
  </mat-form-field>
  <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}" formControlName="refNumber">
      </mat-form-field>
   
    </div>
   
   
  </div>

<!-- **********************Seach popup for Item screen - NistarMarketing -- Starts **********************-->
<div class="col-lg-12 scroll_Search" *ngIf="itemsModuleDisplayForNistarMarketing">
    <div class="col-lg-12 row">
        <div class="col-lg-6">
          <mat-form-field class="col-lg-12">
           <mat-select placeholder="ASIN" formControlName="asin" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t054brandObj">
        <mat-select placeholder="{{PRODUCT_BRAND}}" formControlName="brandId" >
          <mat-option *ngFor="let brand of brandList" [value]="brand.brandId">
            {{ brand.brandName }}
          </mat-option>
        </mat-select>
       </mat-form-field>
    </div>
     
    </div>

    <div class="col-lg-12 row">
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="Images" formControlName="imagesConfirmationFlag">
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t207_SubCategoryObj">
        <mat-select placeholder="Product Type" formControlName="subCategoryName" >
          <mat-option *ngFor="let subcategory of subCategoryList" [value]="subcategory.subCategoryName">
            {{ subcategory.subCategoryName }}
          </mat-option>
        </mat-select>
       </mat-form-field>
      </div>
    
    </div>

    <div class="col-lg-12 row">
      <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t206_CategoryObj">
        <mat-select placeholder="Group" formControlName="categoryName" >
          <mat-option *ngFor="let category of categoryList" [value]="category.categoryName">
            {{ category.categoryName }}
          </mat-option>
        </mat-select>
       </mat-form-field>
    </div>

    <div class="col-lg-6">
          <mat-form-field class="col-lg-12">
           <mat-select placeholder="UPC" formControlName="upc" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="col-lg-12 row" >
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12">
           <mat-select placeholder="EAN" formControlName="ean" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
     <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t071GenderId">
        <mat-select placeholder="Gender" formControlName="genderId" >
          <mat-option *ngFor="let gender of genderList" [value]="gender.genderId">
            {{ gender.name }}
          </mat-option>
        </mat-select>
       </mat-form-field>
    </div>

    </div>

 <div class="col-lg-12 row">
  <div class="col-lg-6">
      <mat-form-field class="col-lg-12">
        <input [matDatepicker]="picker3" placeholder="{{CREATED_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <mat-form-field class="col-lg-12" >
        <input [matDatepicker]="picker4" placeholder="{{CREATED_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
</div>

<div class="col-lg-12 row" >
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="Full Color Name" formControlName="fullColorName" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12">
           <mat-select placeholder="Full Size" formControlName="fullSizeConfirmationFlag" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="col-lg-12 row" >
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12">
           <mat-select placeholder="Retail Price" formControlName="salesRateConfirmationFlag" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="Cost Price" formControlName="purchaseRateConfirmationFlag" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="col-lg-12 row" >
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" formGroupName="conditionId">
           <mat-select placeholder="Condition" formControlName="lookupId" >
           <mat-option *ngFor="let condition of conditionList" [value]="condition.lookupId">
            {{ condition.lookupValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="Features" formControlName="featureConfirmationFlag" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>



    <div class="col-lg-12 row" >
   
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="Description" formControlName="salesDescription" >
           <mat-option *ngFor="let selectConfirmation of confirmationList" [value]="selectConfirmation.value">
            {{ selectConfirmation.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

   <div class="col-lg-6">
        <mat-form-field  class="col-lg-12"  formGroupName="t001UserCreatedBy" >         
         <mat-select placeholder="{{USERS}}"  formControlName="userId">
          <mat-option  *ngFor="let user of orgUsersList" [value]="user.userId" >
            {{user.userName}}
          </mat-option>
        </mat-select>
    </mat-form-field> 
    </div> 
   
   </div> 

   <div class="col-lg-12 row" >
      <div class="col-lg-6">
         <mat-form-field class="col-lg-12" >
          <mat-select placeholder="No Of Childs in Parent" formControlName="validationString" >
          <mat-option *ngFor="let noOfChilds of noOfChildList" [value]="noOfChilds.value" (onSelectionChange)="displayChildCount(noOfChilds)">
           {{ noOfChilds.viewValue }}
         </mat-option>
         </mat-select>
       </mat-form-field>
     </div>

     <div  class="col-lg-6" *ngIf="showChildCount">
         <mat-form-field  class="col-lg-12" >
            <input  matInput placeholder="Child Count" formControlName="noOfChildInParentCount" myNumberOnly (blur)="allowNumeric()" > 
          </mat-form-field>
     </div>
  </div> 
 
    <div class="col-lg-12 row" >
      <div class="col-lg-6">
          <mat-form-field class="col-lg-12" >
           <mat-select placeholder="SC Created" formControlName="scCreatedFlag" >
          <mat-option *ngFor="let scCreated of scCreatedList" [value]="scCreated.value">
            {{ scCreated.viewValue }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


  </div>
<!-- **********************Seach popup for Item screen - NistarMarketing -- Ends **********************-->

  <div class="col-lg-12" *ngIf="itemsModuleDisplay">
    <div class="col-lg-12 row">

      <div class="col-lg-6 ">
      <mat-form-field class="col-lg-12">
        <input matInput placeholder="{{ITEM_NAME}}" formControlName="itemName" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field>
    </div>
     <div class="col-lg-6">
      <mat-form-field class="col-lg-12">
        <input matInput placeholder="{{SKU}}" formControlName="sku" (keypress)="searchOnKeyPress($event)">
      </mat-form-field>
      </div>
    </div>

    <div class="col-lg-12 row">

      <div class="col-lg-6">
      <mat-form-field class="col-lg-12">
        <input matInput placeholder="{{MANUFACTURER_PLACEHOLDER}}" formControlName="manufacturer" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field>
    </div>
     <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t054brandObj">
     <mat-select placeholder="{{PRODUCT_BRAND}}" formControlName="brandId" >
     <mat-option *ngFor="let brand of brandList" [value]="brand.brandId">
      {{ brand.brandName }}
    </mat-option>
  </mat-select>
 </mat-form-field>
      </div>
    </div>


    <div class="col-lg-12 row">
     <div class="col-lg-6">
       <mat-form-field  class="col-lg-12" formGroupName="t006GLSalesAccount">
     <mat-select placeholder="{{SALES_ACCOUNT}}" formControlName="gLAccountId" >
        <mat-option *ngFor="let t006GLAccount of salesAccountsList" [value]="t006GLAccount.gLAccountId">   
     {{ t006GLAccount.name }} -<span style="color:grey;"><i> {{ t006GLAccount.t005AccountModule.name}} </i></span> 
       </mat-option>
  </mat-select>
 </mat-form-field> 
 

</div>
      <div class="col-lg-6">
       <mat-form-field  class="col-lg-12" formGroupName="t006GLPurchasesAccount">
     <mat-select placeholder="{{PURCHASE_ACCOUNT}}" formControlName="gLAccountId" >
        <mat-option *ngFor="let t006GLAccount of purchaseAccountsList" [value]="t006GLAccount.gLAccountId">
       {{ t006GLAccount.name }} -<span style="color:grey;"><i> {{ t006GLAccount.t005AccountModule.name}} </i></span> 
       </mat-option>
  </mat-select>
 </mat-form-field> 
 

</div>
     </div>

    <div class="col-lg-12 row">
      <div class="col-lg-6">
         <mat-form-field  class="col-lg-12" formGroupName="t008Tax">
     <mat-select placeholder="{{TAX_ACCOUNT_MAPPING}}" formControlName="taxId" >
      <mat-option [value]="select">Select</mat-option>
     <mat-option *ngFor="let tax of taxList" [value]="tax.taxId">
      {{ tax.name }}
    </mat-option>
  </mat-select>
 </mat-form-field>
</div>
 
  </div>
</div>

  <div class="col-lg-12" *ngIf="salesModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{SO_NUM}}" formControlName="invoiceNumber">
      </mat-form-field>
       <div class="col-lg-6">
    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
     
    </div>
    <div class="col-lg-12 row">
     <!--  <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
        <mat-form-field class="col-lg-6" formGroupName="t302SalesPersons">
       <mat-select placeholder="{{SELECT_SALESPERSON}}" formControlName="salesPersonId">
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.salesPersonId">
                      {{salesPerson.name}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{SO_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{SO_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{SHIPMENT_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{SHIPMENT_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
     <div class="col-lg-12 row">
      
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MAX_RANGE}}" formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
     <!--  <div class="col-lg-6">
    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div> -->
    </div>
    <div class="col-lg-12 row">

       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusSalesOrder" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
       <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}" formControlName="refNumber">
      </mat-form-field>
     
    </div>

    <div class="col-lg-12">
    </div>
  </div>

  <div class="col-lg-12" *ngIf="billModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{BILL_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>
     
       <div class="col-lg-6">
   <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'">
   </app-customer-search>
  
    </div> 
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{BILL_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{BILL_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{DUE_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{DUE_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
     <!--   <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}" formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}" formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
           <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of billStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
       <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
      
    </div>
    
   
    <!-- not for phase 1 -->
   <!--  <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input class="inputWidth" type="text" aria-label="Number" placeholder="Select Customers" matInput [formControl]="myControl14" [matAutocomplete]="customers"><b class="caret" style="margin-left: -13px;"></b>
        <mat-autocomplete #customers="matAutocomplete">
          <mat-option *ngFor="let cust of customers" [value]="cust">
            {{cust}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
     
    </div> -->
  </div>

  <div class="col-lg-12" *ngIf="estimatesModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{ESTIMATE_NUMBER}}"  formControlName="invoiceNumber">
      </mat-form-field>
       <div class="col-lg-6">
     <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
      
    </div>
      <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{ESTIMATE_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{ESTIMATE_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{EXPIRY_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{EXPIRY_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
  <!--   <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
       <mat-form-field class="col-lg-6" formGroupName="t302SalesPersons">
       <mat-select placeholder="{{SELECT_SALESPERSON}}" formControlName="salesPersonId">
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.salesPersonId">
                      {{salesPerson.name}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
     </div>
      <div class="col-lg-12 row">
         <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
      </div>
    <div class="col-lg-12">
     <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of estimateStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
    </div>
    
  </div>

  <div class="col-lg-12" *ngIf="recurBillsModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{NAME}}"  formControlName="profileName">
      </mat-form-field>
    <!--   <mat-form-field class="col-lg-6">
        <input class="inputWidth" type="text" aria-label="Number" placeholder="Status" matInput [formControl]="myControl5" [matAutocomplete]="status" formControlName="invoiceNumber"><b class="caret" style="margin-left: -10px;"></b>
        <mat-autocomplete #status="matAutocomplete">
          <mat-option *ngFor="let status of  statusecurInvoice" [value]="status">
            {{status}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->
      <div class="col-lg-6 ">
      <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'">
   </app-customer-search>
 </div>
       
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{START_DATE_FROM}}" matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{START_DATE_TO}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{END_DATE_FROM}}" matInput  formControlName="invoiceDueStartDate"> 
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{END_DATE_TO}}" matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of rBillStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"   formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
  </div>

  <div class="col-lg-12" *ngIf="expensesModuleDisplay">
     <div class="col-lg-12 row">
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="referenceNumber">
      </mat-form-field>
    <div class="col-lg-6">
    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'"></app-customer-search>
    </div> 
    </div>
    <div class="col-lg-12 row">
     <mat-form-field class="col-lg-6" formGroupName="t023PaymentMode">
       <mat-select  placeholder="{{SELCET_PAYMENT_MODE}}" formControlName="paymentModeId">
        <mat-option *ngFor="let pay of paymentMethods" [value]="pay.paymentModeId">
            {{pay.name}}
          </mat-option>
      </mat-select>
      </mat-form-field>

         <mat-form-field  class="col-lg-6" formGroupName="t006GLAccount">
             <mat-select placeholder="{{PAID_THROUGH}}" formControlName="gLAccountId">
               <mat-option>None</mat-option>
                  <mat-option *ngFor="let t006GLAccount of purchaseAccountsList"   [value]="t006GLAccount.gLAccountId">
               {{ t006GLAccount.name }} -<span style="color:grey;"><i> {{ t006GLAccount.t005AccountModule.name}} </i></span> 
              </mat-option> <!-- changed to bind the gl account in dropdown -->
              </mat-select>
       </mat-form-field>

    </div>
   
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{EXPENSE_FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon" ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{EXPENSE_TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon" ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
 
       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusExpense" [value]="status.lookupId"  (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"   formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
  
  </div>

  <div class="col-lg-12" *ngIf="recurExpensesModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{NAME}}"  formControlName="profileName">
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{START_DATE_FROM}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{START_DATE_TO}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{END_DATE_FROM}}" matInput  formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{END_DATE_TO}}" matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <mat-select placeholder="{{EXPENSE_ACCOUNT}}" [formControl]="myControl5">
          <mat-optgroup *ngFor="let group of expenseAccount" [label]="group.name" [disabled]="group.disabled">
            <mat-option *ngFor="let expenseAcc of group.expenseAcc" [value]="expenseAcc.value">
              {{ expenseAcc.viewValue }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusRecurExpense" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
       <div class="col-lg-6">
      <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'">
   </app-customer-search>
   <!--  <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search> --> 
    </div>

    <div class="col-lg-6">
      <mat-form-field class="col-lg-12">
        <textarea matInput placeholder="{{NOTES}}" formControlName="customerNotes" onfocus="this.select()"></textarea>
      </mat-form-field>
    </div>
     
    </div>
    
  </div>

  <div class="col-lg-12" *ngIf="paymentsMadeModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6" formGroupName="t023PaymentMode">
       <mat-select  placeholder="{{PAYMENT_MODE}}" formControlName="paymentModeId">
        <mat-option *ngFor="let pay of paymentMethods" [value]="pay.paymentModeId">
            {{pay.name}}
          </mat-option>
      </mat-select>
      </mat-form-field>
       <div class="col-lg-6">
   <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100Contact" [contactType]="'VENDOR'">
   </app-customer-search>
   
    </div>
     
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{PAYMENT_FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{PAYMENT_TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MAX_RANGE}}"   formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    
  
  </div>

  <div class="col-lg-12" *ngIf="vendorCreditsModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{VENDOR_CREDIT_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>
       <div class="col-lg-6">
   <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactVendorId" [contactType]="'VENDOR'">
   </app-customer-search>
   
    </div>
    
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{CREDITS_FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{CREDITS_TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
 
    <div class="col-lg-12 row">
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
           <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
       <div class="col-lg-12 row">

       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusVendorCredits" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
  <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
       
    </div>
  </div>

  <div class="col-lg-12" *ngIf="invoiceModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{INVOICE_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>

       <div class="col-lg-6">
    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
   
    </div>
      <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{INVOICE_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{INVOICE_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{DUE_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{DUE_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <!-- <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
       <mat-form-field class="col-lg-6" formGroupName="t302SalesPersons">
       <mat-select placeholder="{{SELECT_SALESPERSON}}" formControlName="salesPersonId">
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.salesPersonId">
                      {{salesPerson.name}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
    </div>
    
    <div  class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MIN_RANGE}}"   formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MAX_RANGE}}" formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusInvoice" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
       <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}" formControlName="refNumber">
      </mat-form-field>
      
    </div>

  </div>

  <div class="col-lg-12" *ngIf="retainerInvoiceModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{RETAINER_INVOICE_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"   formControlName="refNumber">
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{FROM_DATE}}" matInput  formControlName="invoiceDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{TO_DATE}}" matInput  formControlName="dueDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">

       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of poStatuses" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{DESCRIPTION_PLACEHOLDER}}" formControlName="itemDescForFetch">
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    <div class="col-lg-6">

    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
    </div>
  </div>

  <div class="col-lg-12" *ngIf="recurringInvoiceModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{NAME}}" formControlName="profileName">
      </mat-form-field>
      <div class="col-lg-6">
    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{START_DATE_FROM}}" matInput >
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{START_DATE_TO}}" matInput >
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{END_DATE_FROM}}" matInput >
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{END_DATE_TO}}" matInput >
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
       <!--  <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">

       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusecurInvoice" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
   <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"   formControlName="refNumber">
      </mat-form-field>
    </div>
    </div>

  <div class="col-lg-12" *ngIf="paymentsReceivedModuleDisplay">
    <div class="col-lg-12 row">
    <div class="col-lg-6">

    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100Contact" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
      <!-- <mat-form-field class="col-lg-6">
        <input matInput placeholder="Payment #" formControlName="paymentReceivedHeaderId">
      </mat-form-field> -->
      <mat-form-field class="col-lg-6" formGroupName="t023PaymentMode">
       <mat-select  placeholder="{{SELCET_PAYMENT_MODE}}" formControlName="paymentModeId">
        <mat-option *ngFor="let pay of paymentMethods" [value]="pay.paymentModeId">
            {{pay.name}}
          </mat-option>
      </mat-select>
      </mat-form-field>
    </div>
     <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{PAYMENT_FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{PAYMENT_TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
        <!-- <mat-form-field class="col-lg-6">
        <input matInput placeholder="Reference#"   formControlName="referenceNumber">
      </mat-form-field> -->
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    
  </div>

  <div class="col-lg-12" *ngIf="creditNotesModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{CREDIT_NOTE_NUMBER}}" formControlName="invoiceNumber">
      </mat-form-field>
      <div class="col-lg-6">

    <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{CREDITS_FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{CREDITS_TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">

      
    <!--  <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> -->
      <div class="col-lg-6 nopadding">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
       <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}" formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}" formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
       <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of statusCredit" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}" formControlName="refNumber">
      </mat-form-field>
     
    </div>
    </div>   

  <div class="col-lg-12" *ngIf="journalsModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{JOURNAL_NUMBER}}" formControlName="journalNumber">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{NOTES}}" formControlName="notes">
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MIN_RANGE}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{MAX_RANGE}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
  </div>

  <div class="col-lg-12" *ngIf="employeeModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{EMPLOYEE_CODE}}" formControlName="userName">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{EMPLOYEE_NAME}}" formControlName="firstName">
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{EMAIL_ADDRESS}}" formControlName="officalEmail">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{MOBILE_NUMBER}}" formControlName="mobileNumber" [textMask]="{mask: mask}">
      </mat-form-field>
    </div>
     <div class="col-lg-12">
      <mat-form-field class="col-lg-6" formGroupName="t009Department">
       <mat-select placeholder="{{DEPARTMENT}}" formControlName="departmentId">
                    <mat-option *ngFor="let department of departmentList" [value]="department.departmentId">
                      {{department.departmentValue}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
      <mat-form-field class="col-lg-6" formGroupName="t816Designation">
       <mat-select placeholder="{{DESIGNATION}}" formControlName="designationId">
                    <mat-option *ngFor="let designation of designationList" [value]="designation.designationId">
                      {{designation.designationName}}
                    </mat-option>
                  </mat-select>
       </mat-form-field>
    </div>
     <div class="col-lg-12">
      <mat-form-field class="col-lg-6" formGroupName="t818SalaryGroup">
        <mat-select placeholder="{{SALARY_GROUP}}" formControlName="salaryId">
              <mat-option *ngFor="let salaryGroup of salaryList" [value]="salaryGroup.salaryId">
                {{salaryGroup.salaryGroupName}}
              </mat-option>
         </mat-select>
      </mat-form-field>
       <mat-form-field formGroupName="t028Location" class="col-lg-6">       
       <mat-select placeholder="{{REPORTING_LOCATION}}" formControlName="locationId">
                    <mat-option *ngFor="let location of locationList" [value]="location.locationId">
                      {{location.locationName}}
                    </mat-option>
             </mat-select>
       </mat-form-field>
   </div>
  </div>


   <div class="col-lg-12 nopadding" *ngIf="assetModuleDisplay">

 <div class="col-lg-12 nopadding">
  <mat-form-field class="col-lg-6 ">
    <input matInput placeholder="{{ASSET_NUMBER}}" formControlName="assetNumber">
  </mat-form-field>
  <mat-form-field class="col-lg-6 ">
        <input matInput placeholder="{{ASSET_NAME}}" formControlName="assetName">
  </mat-form-field>
  </div>
  <div class="col-lg-12 nopadding">
    <mat-form-field class="col-lg-6 ">
      <input matInput placeholder="Brand" formControlName="{{PRODUCT_BRAND}}">
    </mat-form-field>
    <mat-form-field class="col-lg-6 ">
        <textarea matInput placeholder="{{DESCRIPTION_PLACEHOLDER}}" formControlName="description" onfocus="this.select()"></textarea>
      </mat-form-field>

      
      </div>
      <div class="col-lg-12 nopadding">
        <mat-form-field class="col-lg-6 ">
        <input matInput placeholder="{{MODEL_NUMBER}}" formControlName="modelNumber">
      </mat-form-field>

      <!-- <mat-form-field class="col-lg-6 nopadding">      
          <mat-select placeholder="{{ASSET_CATEGORY}}">
            <mat-option *ngFor="let type of assetTypes" [value]="type.categoryId" (click)="categoryClicked(type)">{{type.categoryName}}</mat-option>
            </mat-select>
      </mat-form-field> -->

       <mat-form-field class="col-lg-6 " >
        <input [matDatepicker]="purchasedOn" placeholder="{{PURCHASE_ON}}" matInput  formControlName="purchasedOn" >
        <mat-datepicker-toggle matSuffix [for]="purchasedOn" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #purchasedOn></mat-datepicker>
      </mat-form-field>
      </div>
   </div>


 <div class="col-lg-12" *ngIf="salesPersonModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-4">
        <input matInput placeholder="{{EMP_NAME}}" formControlName="name">
      </mat-form-field>
       <mat-form-field class="col-lg-4">
        <input matInput placeholder="{{EMAIL_ADDRESS}}" formControlName="email">
      </mat-form-field>
      <mat-form-field class="col-lg-4">
        <input matInput placeholder="{{MOBILE_NUMBER}}" formControlName="mobileNo" [textMask]="{mask: mask}">
      </mat-form-field>
    </div>
  </div>

<div class="col-lg-12" *ngIf="indentModuleDisplay">
  <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{INDENT_NO}}" formControlName="journalNumber">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{FROM_DATE}}" matInput  formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{TO_DATE}}" matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{NOTES}}" formControlName="notes">
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{ITEM_NAME}}"  formControlName="minTotalValue" noAndDotOnly>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="{{ITEM_DESCRIPTION}}"  formControlName="maxTotalValue" noAndDotOnly>
      </mat-form-field>
    </div>
  </div>

   <div class="col-lg-12" *ngIf="paycodeModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-4">
        <input matInput placeholder="{{PAYCODE_NAME}}" formControlName="payCode">
      </mat-form-field>
       <mat-form-field class="col-lg-4">
        <input matInput placeholder="{{DISPLAY_ORDER}}" formControlName="displayOrder">
      </mat-form-field>
      <mat-form-field class="col-lg-4">
       <mat-select placeholder="{{SELECT_TYPE}}" formControlName="filterType">
            <mat-option *ngFor="let type of paycodeType" [value]="type">
              {{type}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   </div>

   <div class="col-lg-12" *ngIf="projectPlanSearchDisplay">
    <div class="col-lg-12"> 
      <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
      let-onCheckedChange="onCheckedChange">
      <div class="form-inline row-item">
        <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
        <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
          d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
        </svg>
        <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
        </svg>
        </i>
        <div class="form-check">
        <!-- <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
          [disabled]="item.disabled" [indeterminate]="item.indeterminate" /> -->
        <label class="form-check-label" DropdownToggleDirective (click)="onCheckedChangeFunction(item.value,item.text)">
          {{item.text}}
        </label>
        
        </div>
      </div>
    </ng-template>
    <ngx-dropdown-treeview class="treeViewCls noborder col-lg-6" [config]="config"  [items]="items" (filterChange)="onFilterChange($event)" [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)">
    </ngx-dropdown-treeview>
  <mat-form-field   class="col-lg-6" formGroupName="t823ParentTask" >
    <input type="text" placeholder="{{PARENT_TASK}}" aria-label="task" matInput formControlName="taskName"   [matAutocomplete]="parentTask"  focus [focusEvent]="myFocusTriggeringEventEmitter">
    <mat-autocomplete #parentTask="matAutocomplete" (optionSelected)="taskSelected($event.option.value)"  >
      <mat-option  value="" >None</mat-option>
    <mat-option *ngFor="let parentTask of parentTaskList | async" [value]="parentTask" >
    {{ parentTask.taskName }}<span *ngIf ="this.commonUtils.checkNullOrUndefined(parentTask.t823ParentTask)" style="color:grey;"><i> - {{ parentTask.t823ParentTask.taskName}} </i></span> 
    </mat-option>
    </mat-autocomplete>
    </mat-form-field> 
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{TASK_NUMBER}}" formControlName="taskNumber">
    </mat-form-field>
      <!-- <mat-form-field   class="col-lg-6" formGroupName="t823ParentTask" >
        <input type="text" placeholder="{{PARENT_TASK}}" aria-label="task" matInput formControlName="taskName"   [matAutocomplete]="parentTask"  focus [focusEvent]="myFocusTriggeringEventEmitter">
        <mat-autocomplete #parentTask="matAutocomplete" (optionSelected)="taskSelected($event.option.value)"  >
          <mat-option  value="" >None</mat-option>
        <mat-option *ngFor="let parentTask of parentTaskList | async" [value]="parentTask" >
        {{ parentTask.taskName }}<span *ngIf ="this.commonUtils.checkNullOrUndefined(parentTask.t823ParentTask)" style="color:grey;"><i> - {{ parentTask.t823ParentTask.taskName}} </i></span> 
        </mat-option>
        </mat-autocomplete>
        </mat-form-field>  -->
        <!-- <mat-form-field class="col-lg-6" formGroupName="t001_UserObj">       
          <mat-select placeholder="{{USERNAME_PLACEHOLDER}}" formControlName="userId">
            <mat-option>None</mat-option>
              <mat-option *ngFor="let user of resourceExtensionList" [value]="user.t001_UserObj.userId" >
                  {{user.t001_UserObj.userName}}  -  <span style="color:grey;"><i> {{user.t001_UserObj.empName}} </i></span> 
              </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{TASK_NAME}}" formControlName="taskName">
      </mat-form-field>

      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{DESC}}" formControlName="taskDesc" >
      </mat-form-field>
      
      </div>
      <div class="col-lg-12">
       <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker1" placeholder="{{START_DATE}}" matInput formControlName="startDate" (focusout)="onFocusOutStartDate()" >
        <mat-datepicker-toggle matSuffix [for]="picker1" class="matdatepickericon" (focusout)="onFocusOutStartDate()"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker2" placeholder="{{END_DATE}}" matInput formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

<!--T_5517 / 9014-->
<mat-form-field class="col-lg-6">
        <input [matDatepicker]="deliverystdate" placeholder="Delivery Start Date" matInput formControlName="deliverystartDate">
        <mat-datepicker-toggle matSuffix [for]="deliverystdate" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #deliverystdate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="deliveryenddate" placeholder="Delivery End Date" matInput formControlName="deliveryendDate">
        <mat-datepicker-toggle matSuffix [for]="deliveryenddate" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #deliveryenddate></mat-datepicker>
      </mat-form-field>
<!--T_5517 /9014 -->
    
         <!-- <mat-form-field class="col-lg-6">
          <mat-select placeholder="Detail Type" formControlName="taskType">
            <mat-option *ngFor="let summaryString of summaryStringList" [value]="summaryString.value">
             {{summaryString.dispValue}} 
            </mat-option> 
          </mat-select>
                 </mat-form-field> -->
                 <mat-form-field class="col-lg-6" formGroupName="t001_ResponsibleUserObj">       
                  <mat-select placeholder="Select Responsible Person" formControlName="userId">
                    <mat-option>None</mat-option>
                      <mat-option *ngFor="let user of resourceExtensionList" [value]="user.t001_UserObj.userId" >
                          {{user.t001_UserObj.userName}}  -  <span style="color:grey;"><i> {{user.t001_UserObj.empName}} </i></span> 
                      </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-lg-6" formGroupName="allocatedUserObj">       
                  <mat-select placeholder="Allocated To" formControlName="userId">
                    <mat-option>None</mat-option>
                      <mat-option *ngFor="let user of resourceExtensionList" [value]="user.t001_UserObj.userId" >
                          {{user.t001_UserObj.userName}}  -  <span style="color:grey;"><i> {{user.t001_UserObj.empName}} </i></span> 
                      </mat-option>
                  </mat-select>
                </mat-form-field>
         <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{VERSION_NUMBER}}" formControlName="versionNumber">
      </mat-form-field>

      

          <!-- <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{PMT_ID}}" formControlName="pmtRefId">
      </mat-form-field> -->
     
      
    </div>
   </div>

<div class="col-lg-12" *ngIf="projectPlanIdSearchDisplay">
    <p style="text-align: center;"> Please select a project and try again</p>
   </div>

    <div class="col-lg-12" *ngIf="accountsModuleDisplay">
      <div class="col-lg-12">
       
        <mat-form-field class="col-lg-6" formGroupName="t005AccountModule">
          <mat-select placeholder="{{ACCOUNT_SMALL}}" formControlName="accountModuleId">
            <mat-option *ngFor="let account of accountsList" [value]="account.accountModuleId" (click)="fetchDetailType(account)">
           {{account.name}}
            </mat-option> 
          </mat-select>
        </mat-form-field> 
      <mat-form-field class="col-lg-6" formGroupName="t007AccountDetailType">
          <mat-select placeholder="{{DETAIL_TYPE}}" formControlName="accountDetailId">
            <mat-option *ngFor="let accountDetail of accountsDetailTypeList" [value]="accountDetail.accountDetailId">
             {{accountDetail.accountDetailType}} 
            </mat-option> 
          </mat-select>
        </mat-form-field> 
      </div>
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{NAME}}" formControlName="name" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
        
       
      </div>
    </div>


   <div class="col-lg-12 scroll_Search" *ngIf="artWorkRequestSearchDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{ARTWORK_NUMBER}}"  formControlName="invoiceNumber">
      </mat-form-field>
      <mat-form-field class="col-lg-6" formGroupName="t302SalesPersons">
       <mat-select placeholder="{{SELECT_SALESPERSON}}" formControlName="salesPersonId">
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.salesPersonId">
                      {{salesPerson.name}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
    </div> 
      <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
     <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field> 
    </div>
    <div class="col-lg-12">
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{CSA}}"  formControlName="artworkCSAName">
      </mat-form-field>
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{EMAIL_PLACEHOLDER}}"  formControlName="primaryContactEmail">
      </mat-form-field>
    </div>
  <!--   <div class="col-lg-12">
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="Item Name" formControlName="itemNameForFetch" (keypress)="searchOnKeyPress($event)"> 
      </mat-form-field> 
      <div class="col-lg-6">
        <app-item-search (itemSelected)="itemSelected($event)" [formGroup] ="t200_Item"></app-item-search> 
      </div>
      
     </div> -->
      <div class="col-lg-12">
         <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{ART_SERVICE_VENDOR}}"  formControlName="artServiceVendorName" >
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{DECO_VENDOR}}"  formControlName="decoVendorName">
      </mat-form-field>
      </div>
 <div class="col-lg-12">
     
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="Reference#"  formControlName="refNumber">
      </mat-form-field>
    </div> 
    
  </div>


   <div class="col-lg-12" *ngIf="productionSlipModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{PRODUCTION_SLIP_NUMBER_SMALL}}"  formControlName="productionSlipNumber">
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{SO_NUMBER_BOLD}}"  formControlName="soNumber">
      </mat-form-field>
       <!-- <div class="col-lg-6">
     <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div> -->
    </div>
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{EXPIRY_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{EXPIRY_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
      <div class="col-lg-12">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{ACCOUNT_NAME_PLACEHOLDER}}" formControlName="customerName" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      <mat-form-field class="col-lg-6">
          <input matInput placeholder="{{EMAIL_PLACEHOLDER}}" formControlName="repEmail" (keypress)="searchOnKeyPress($event)">
        </mat-form-field>
      </div>
    <div class="col-lg-12">
     <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of productionSlipStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="referenceNumber">
      </mat-form-field>
    </div>
    
  </div>

<div class="col-lg-12 scroll_Search" *ngIf="artWorkModuleDisplay">
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{ARTWORK_NUMBER}}"  formControlName="invoiceNumber">
      </mat-form-field>
       <div class="col-lg-6">
     <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
      
    </div>
     
    <div class="col-lg-12">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker5" placeholder="{{ARTWORK_FROM_DATE}}"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker5" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker6" placeholder="{{ARTWORK_TO_DATE}}"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker6" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
     <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of artworkStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REFERENCE_NUMBER}}"  formControlName="refNumber">
      </mat-form-field>
    </div>
    
  </div>
  <div class="col-lg-12" *ngIf="reservationModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{RESERVATION_NUMBER}}"  formControlName="invoiceNumber">
      </mat-form-field>
       <div class="col-lg-6">
     <app-customer-search (contactClicked)="contactClicked($event)" [formGroup]="t100ContactCustomerId" [contactType]="'CUSTOMER'"></app-customer-search>
    </div>
      
    </div>
      <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker3" placeholder="{{RESERVATION_FROM_DATE}}"   matInput formControlName="invoiceStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker3" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker4" placeholder="{{RESERVATION_TO_DATE}}"  matInput  formControlName="invoiceEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker4" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
       <mat-form-field class="col-lg-6" formGroupName="t302SalesPersons">
       <mat-select placeholder="{{SELECT_SALESPERSON}}" formControlName="salesPersonId">
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.salesPersonId">
                      {{salesPerson.name}}
                    </mat-option>
        </mat-select>
       </mat-form-field>
        <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
            <mat-select placeholder="{{SELECT_STATUS}}" formControlName="lookupId">
            <mat-option *ngFor="let status of reservationStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     </div>    
  </div>

  <div class="col-lg-12" *ngIf="budgetModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{BUDGET_NAME_PLACEHOLDER}}"  formControlName="name">
      </mat-form-field>
       <div class="col-lg-6">
     <mat-form-field class="col-lg-6" formGroupName="fiscalYearObj">
            <mat-select placeholder="{{SELECT_FISCAL_YEAR}}" formControlName="lookupId">
            <mat-option *ngFor="let status of fiscalYearList" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
      
    </div>
      <div class="col-lg-12 row">
       <mat-form-field class="col-lg-6" formGroupName="budgetPeriodObj">
            <mat-select placeholder="{{BUDGET_PERIOD_PLACEHOLDER}}" formControlName="lookupId">
            <mat-option *ngFor="let status of budgetPeriodList" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
      
  </div>

<div class="col-lg-12" *ngIf="manageLoanModuleDisplay">
    <div class="col-lg-12 row">
        <div class="col-lg-6">
      <mat-form-field class="col-lg-12" formGroupName="t818SalaryGroup">
        <mat-select placeholder="{{SALARY_GROUP}}" formControlName="salaryId">
              <mat-option *ngFor="let salaryGroup of salaryList" [value]="salaryGroup.salaryId" (click)="salaryChangeEvent()" >
                {{salaryGroup.salaryGroupName}}
              </mat-option>
         </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
          <mat-form-field class="col-lg-12" formGroupName="t800Employee">
        <mat-select placeholder="{{EMPLOYEE_NAME}}" formControlName="employeeId">
              <mat-option *ngFor="let employee of employeeList" [value]="employee.employeeId">
                {{employee.userName}} - {{employee.firstName}} {{employee.lastName}}
              </mat-option>
         </mat-select>
      </mat-form-field>
    </div>
    </div>
</div>
<div class="col-lg-12" *ngIf="candidateModuleDisplay">
  <div class="col-lg-12">
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="Candidate Code" formControlName="candidateCode">
    </mat-form-field>
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="Candidate Name" formControlName="firstName">
    </mat-form-field>
  </div>

  <div class="col-lg-12">
    <mat-form-field  class="col-lg-6" formGroupName="industry">
      <mat-select placeholder="{{INDUSTRY}}" formControlName="industryId">
        <mat-option *ngFor="let industry of industryList" [value]="industry.industryId">
        {{industry.industryValue}}
        </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{TOTAL_YRS_OF_EXP_CREATE}}" formControlName="totalYearsOfExp">
    </mat-form-field>
  </div>

  <div class="col-lg-12">
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{SEARCH_KEYWORD}}" formControlName="searchKeyword">
    </mat-form-field>
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{NOTICE_PERIOD}}" formControlName="noticePeriod">
    </mat-form-field>
  </div>
  </div>

   <div class="col-lg-12" *ngIf="jobModuleDisplay">
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{JOB_CODE}}"  formControlName="jobCode">
      </mat-form-field>
       <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{JOB_TITLE}}"  formControlName="title">
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
        <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{LOCATION}}"  formControlName="location">
      </mat-form-field>
      <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
        <mat-select placeholder="{{JOB_STATUS}}" formControlName="lookupId">
          <mat-option *ngFor="let status of jobStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </div>  
     <div class="col-lg-12 row">
        <mat-form-field class="col-lg-6">
        <input type="number" min="0" oninput="validity.valid||(value='');" matInput placeholder="{{EXP_YEAR}}"  formControlName="yearExperience">
        </mat-form-field>
        <mat-form-field class="col-lg-6">
          <input type="number" min="0" max="12" oninput="validity.valid||(value='');" matInput placeholder="{{EXP_MONTH}}"  formControlName="monthExperience">
        </mat-form-field>
      </div>
      <div class="col-lg-12 row">
        <mat-form-field class="col-lg-6">
         <input matInput placeholder="{{SALARY_FROM}}"  formControlName="salaryStartRange">
       </mat-form-field>
       <mat-form-field class="col-lg-6">
         <input matInput placeholder="{{SALARY_TO}}"  formControlName="salaryEndRange">
       </mat-form-field>
     </div> 
      <div class="col-lg-12 row">
       <mat-form-field class="col-lg-6">
        <input matInput placeholder="{{REQUIRED_SKILLS}}"  formControlName="requiredSkills">
      </mat-form-field>
    </div>  
  </div>

  <div class="col-lg-12" *ngIf="appointmentModuleDisplay">
  <div class="col-lg-12 row">
        <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker7" placeholder="Interview From Date"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker7" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker7></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker8" placeholder="Interview To Date"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker8" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker8></mat-datepicker>
      </mat-form-field>
  </div>
  <div class="col-lg-12 row">
    <mat-form-field  class="col-lg-6" formGroupName="interviewStatus">
     <mat-select placeholder="{{STATUS}}" formControlName="lookupId">
      <mat-option *ngFor="let status of statusList" [value]="status.lookupId">
      {{status.lookupValue}}
      </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-lg-6">
      <input matInput placeholder="{{LOCATION}}" formControlName="location">
    </mat-form-field>
  </div>
  <div class="col-lg-12 row">
     <mat-form-field  class="col-lg-6">
        <mat-select multiple placeholder="{{INTERVIEWER_NAME}}" #multiSelect formControlName="interviewerNameIdList" >
          <mat-option *ngFor="let name of reportingMgrList"  [value]="name.employeeId">
          {{name.firstName}} {{name.lastName}}
          </mat-option>
          </mat-select>
      </mat-form-field>
  </div>
    </div>

    <div class="col-lg-12" *ngIf="intervieSlotModuleDisplay">
    <div class="col-lg-12 row">
         <mat-form-field class="col-lg-6" formGroupName="t842_JobName">
        <mat-select placeholder="Job Name" formControlName="jobId">
          <mat-option *ngFor="let status of jobList" [value]="status.jobId"  (click)="onChangeOfJobName(status)">
              {{ status.jobCode }} - {{ status.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-6" formGroupName="interviewType">
        <mat-select placeholder="Interview Slot Type" formControlName="lookupId">
          <mat-option *ngFor="let status of interviewSlotTypeList" [value]="status.lookupId" (click)="onChangeOfInterviewStatus(status)">
              {{status.lookupValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </div>
    <div class="col-lg-12 row">
        <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker7" placeholder="Interview From Date"  matInput formControlName="invoiceDueStartDate">
        <mat-datepicker-toggle matSuffix [for]="picker7" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker7></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <input [matDatepicker]="picker8" placeholder="Interview To Date"  matInput  formControlName="invoiceDueEndDate">
        <mat-datepicker-toggle matSuffix [for]="picker8" class="matdatepickericon"></mat-datepicker-toggle>
        <mat-datepicker #picker8></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-12 row">
      <mat-form-field class="col-lg-6" formGroupName="t004_LookupObj">
        <mat-select placeholder="{{STATUS}}" formControlName="lookupId">
          <mat-option *ngFor="let status of interviewStatus" [value]="status.lookupId" (click)="onChangeOfStatus(status)">
              {{status.lookupValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </div>
    </div>

 <div class="dropdown-divider"></div>
   <div class="col-lg-12" style="text-align: right;font-size: 11px;" class="discountDivColor">
    Go mouse free - Use Tab to navigate between fields & <br> Hit Enter key to start searching.
   </div>
  <div class="col-lg-12" align="center">
    <button mat-raised-button color="primary" (click)="sendSeachCriteria()" [disabled]="!dialogForm.valid">{{SEARCH_BUTTON}}</button>
    <button mat-raised-button color="primary" type="button" (click)="onNoClick()">{{CANCEL_BUTTON}}</button>
  </div>
</div>
</form>
</mat-card>