<div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-right: 0">
<div class="col-lg-9 res-dis">
<form  [formGroup]="emitValueFormGroup"> 
 
     <mat-form-field class="col-lg-3 example-full-width"  *ngIf="  moduleName === 'PROJECT_PLAN'">   

      <mat-select placeholder="{{PROJECT}}"  formControlName="projectValue"  > <!-- [(ngModel)]="projectValue" -->
          <mat-option (onSelectionChange)="onChangeProject('',$event)">All</mat-option>
          <mat-option  (onSelectionChange)="onChangeProject(project,$event)"   *ngFor="let project of projectList" [value]="project.projectId"> 
             {{project.projectName}} - <span style="color:grey;"><i> {{project.projectCode}} </i></span> 
          </mat-option>
        </mat-select>
    </mat-form-field> 

     <mat-form-field class="col-lg-3 example-full-width"  *ngIf="   moduleName ==='TIMESHEET_HISTORY'  || moduleName === 'PROJECT_PLAN_CLIENT'">   

      <mat-select placeholder="{{PROJECT}}"  formControlName="projectValue"  > <!-- [(ngModel)]="projectValue" -->
          <mat-option (click)="loadSelectedProject('')" (onSelectionChange)="onChangeProject('',$event)">All</mat-option>
          <mat-option  (onSelectionChange)="onChangeProject(project,$event)"  *ngFor="let project of projectList" [value]="project.projectId" (click)="loadSelectedProject(project)"> 
             {{project.projectName}} - <span style="color:grey;"><i> {{project.projectCode}} </i></span> 
          </mat-option>
        </mat-select>
    </mat-form-field> 

     <mat-form-field class="col-lg-3" *ngIf="moduleName === 'PROJECT_PLAN'">
          <mat-select placeholder="{{TASK_TYPE}}" formControlName="taskType"> <!-- [(ngModel)]="taskType" -->
            <mat-option *ngFor="let summaryString of summaryStringList" [value]="summaryString.value" >
             {{summaryString.dispValue}} 
            </mat-option> 
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-3" *ngIf=" moduleName === 'PROJECT_PLAN_CLIENT'">
          <mat-select placeholder="{{TASK_TYPE}}" formControlName="taskType"> <!-- [(ngModel)]="taskType" -->
            <mat-option *ngFor="let summaryString of summaryStringList" [value]="summaryString.value" (click)="taskTypeclicked(summaryString.value)">
             {{summaryString.dispValue}} 
            </mat-option> 
          </mat-select>
        </mat-form-field>

         <mat-form-field class="col-lg-2 example-full-width"  *ngIf="moduleName === 'VIEW_MENU'">   
        <mat-select placeholder="{{SELECT_LICENSE}}"  [formControl]="license"> 
          <mat-option   *ngFor="let license of licenseList" [value]="license" (click)="loadSelectedLicense(license)">
            {{license}}
          </mat-option>
        </mat-select>
    </mat-form-field> 


     <!--<mat-checkbox *ngIf="moduleName === 'PROJECT_PLAN'" class="col-lg-1 paddingTop10px" color="primary" formControlName="globalFlag">{{GLOBALLY}}</mat-checkbox>
        <mat-checkbox *ngIf="moduleName === 'PROJECT_PLAN_CLIENT'" class="col-lg-1 paddingTop10px" color="primary" formControlName="globalFlag" (change)="globalCheckboxChange()">{{GLOBALLY}}</mat-checkbox>-->  <!-- [(ngModel)]="globalFlag" -->      
        <mat-form-field class="col-lg-2 example-full-width" *ngIf="moduleName != 'VIEW_EMPLOYEEEARNINGS' && moduleName != 'VIEW_ROLE' && moduleName != 'VIEW_USER'   && moduleName != 'VIEW_WAREHOUSE' && moduleName != 'VIEW_TAX_GROUP' && moduleName != 'MY_LOAN' && moduleName != 'APPROVE_LEAVE' && moduleName != 'VIEW_AUTOMATED_EMAIL'  && moduleName != 'TIMESHEET_HISTORY' && moduleName != 'VIEW_RESOURCE_EXTENSION' && moduleName != 'VIEW_EMPLOYEE_APPRAISAL_LEVEL' && moduleName != 'VIEW_SHIFT_MASTER' && moduleName != 'VIEW_SHIFT_ALLOCATION' &&
           moduleName != 'CHART_OF_ACCOUNTS' && moduleName != 'VIEW_MENU'  && moduleName != 'VIEW_ISSUES' && customFilter==false && moduleName != 'VIEW_JOURNAL'
           && moduleName != 'VIEW_PO_RECEIVE'  && moduleName != 'VIEW_CREDIT_NOTES_INVOICE' && moduleName != 'VIEW_VENDOR_CREDIT_BILL' && moduleName != 'VIEW_SO_RECEIVE'  && moduleName != 'VIEW_PRICE_LIST' && moduleName != 'VIEW_ITEMS_GROUP' && moduleName != 'VIEW_SALES_PERSON' &&  moduleName != 'VIEW_SALES_RECEIPT' &&  moduleName != 'VIEW_REFUND_RECEIPT' &&  moduleName != 'VIEW_PRODUCT_SETUP' && moduleName != 'VIEW_ACCOUNT_BUDGET'  && moduleName != 'VIEW_RECEIVINGS' && moduleName  != 'PROJECT_PLAN' && moduleName != 'VIEW_HRIS_TIMESHEET' && moduleName != 'MANAGE_FEEDBACK_MYCORNER' && moduleName!='REVIEW_TRAINEE'" >
           <mat-select placeholder="{{FILTER_BY}}" #filterdropdown formControlName="filterOptionValue" (keyup)="filterDropdownChanged('')"> <!-- [(ngModel)]="filterOptionValue"  -->
          <mat-option (click)="filterDropdownChanged(option)"  *ngFor="let option of options" [value]="option">
            {{option.lookupValue}} 
          </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-2" *ngIf="moduleName === 'VIEW_APPRAISAL_FORM' && (this.roleName === 'HR' || this.roleName === 'ADMIN')" style="width:200px">
          <input matInput [matDatepicker]="startDatePicker" placeholder="From Date" formControlName="invoiceStartDate" dateFormatter >
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
       
        <mat-form-field class="col-lg-2" *ngIf="moduleName === 'VIEW_APPRAISAL_FORM' && (this.roleName === 'HR' || this.roleName === 'ADMIN')" style="width:200px">
          <input matInput [matDatepicker]="endDatePicker" placeholder="To Date" formControlName="invoiceEndDate" dateFormatter  >
          <mat-datepicker-toggle matSuffix [for]="endDatePicker" ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-lg-3 example-full-width" *ngIf="moduleName  === 'VIEW_APPRAISAL_FORM' && (this.roleName === 'HR' || this.roleName === 'ADMIN')">
          <mat-select #employeeSearchFilterInput placeholder="{{SELECT_EMPLOYEE}}" formControlName="filterEmployeeOptionValue" >
            <mat-option (click)="employeeSelected(0)">None</mat-option>
            <mat-option *ngFor="let employeeObj of employeeList" [value]="employeeObj.employeeId" (click)="employeeSelected(employeeObj.employeeId)">
              {{employeeObj.userName}} - {{employeeObj.firstName}} {{employeeObj.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-lg-12 buttons" *ngIf="moduleName  === 'VIEW_APPRAISAL_FORM' && (this.roleName === 'HR' || this.roleName === 'ADMIN')">
          <button mat-raised-button color="primary" class="radio_padding" style="height:43px; float: center;" (click)="fetchReportsDetails()">{{APPLY_BTN}}
        </button>
      </div>
        <mat-form-field class="col-lg-2 example-full-width" *ngIf="moduleName  === 'PROJECT_PLAN'" >
           <mat-select placeholder="{{FILTER_BY}}" #filterdropdown formControlName="filterOptionValue" > <!-- [(ngModel)]="filterOptionValue"  -->
          <mat-option   *ngFor="let option of options" [value]="option">
            {{option.lookupValue}} 
          </mat-option>
        </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-2" *ngIf="moduleName === 'VIEW_TIMESHEET'" style="width:200px">
          <input matInput [matDatepicker]="startDatePicker" placeholder="From" formControlName="invoiceStartDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>


  
        <mat-form-field class="col-lg-2" *ngIf="moduleName === 'VIEW_TIMESHEET'" style="width:200px">
         <!--<input matInput [matDatepicker]="endDatePicker" placeholder="To" formControlName="invoiceEndDate" dateFormatter>--> 
          <input matInput [matDatepicker]="endDatePicker" placeholder="To " formControlName="invoiceEndDate" >
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>


      

      <div class="btn-group col-lg-1 nopadding"  style="margin-left: 1%; margin-top: 1%; margin-bottom:1%" *ngIf="moduleName === 'VIEW_TIMESHEET'" >
            <button mat-raised-button color="primary" class="buttonWidth" (click)="showHoursInTimesheet()">Show</button>
      </div>
     
       <div class="col-lg-5" style="float:right;padding-top:20px" *ngIf="moduleName === 'VIEW_TIMESHEET' && showHoursFlag">
        <span class="col-lg-4">Est Hrs:  {{estimatedHours}}</span>
        <span class="col-lg-4">Bill Hrs:  {{billableHours}} </span>
        <span class="col-lg-4">Non-Bill Hrs:  {{nonBillableHours}}</span>
      </div> 

          <div class="btn-group col-lg-1 nopadding"  *ngIf="moduleName === 'PROJECT_PLAN'" >
            <button mat-raised-button color="primary" class="buttonWidth" (click)="searchClicked()">Search</button>
     
        <span *ngIf="moduleName === 'PROJECT_PLAN'  || moduleName === 'PROJECT_PLAN_CLIENT'" class="col-lg-2">
            <a (click)="resetProject()">
      <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Reset All / Clear search popup criteria">autorenew</mat-icon> </button> </a>
          </span>   </div>

          <div class="col-lg-12"  *ngIf="moduleName === 'PROJECT_PLAN'  || moduleName === 'PROJECT_PLAN_CLIENT'">
            <span style="font-weight: bold">Searching using the below filter values : </span>
          </div>    
          <div class="col-lg-12"  *ngIf="moduleName === 'PROJECT_PLAN'  || moduleName === 'PROJECT_PLAN_CLIENT'">
          <span><span  style="font-weight: bold">  Project : </span>
          <span *ngIf="this.commonUtils.checkNullOrUndefined(selectedProjectObj)" > {{selectedProjectObj.projectCode}} - {{selectedProjectObj.projectName}}</span>
           <span *ngIf="!(this.commonUtils.checkNullOrUndefined(selectedProjectObj))" > Nil</span>,
          <span  style="font-weight: bold"> Task Type : </span> 
          <span *ngIf="emitValueFormGroup.get('taskType').value === 'S'">Summary</span>
          <span *ngIf="emitValueFormGroup.get('taskType').value == 'NS'">Non-Summary</span>
          <span *ngIf="emitValueFormGroup.get('taskType').value == 'A'">All</span> 
           <span *ngIf="emitValueFormGroup.get('taskType').value != 'A'
            && emitValueFormGroup.get('taskType').value != 'S' && emitValueFormGroup.get('taskType').value != 'NS'">Nil</span>, 
          <!--<span  style="font-weight: bold"> Global : </span> {{emitValueFormGroup.get('globalFlag').value}} ,--> 
          <span  style="font-weight: bold"> Status : </span> <span *ngIf="this.commonUtils.checkNullOrUndefined(emitValueFormGroup.get('filterOptionValue'))">{{emitValueFormGroup.get('filterOptionValue').value.lookupValue}}</span>
          <span *ngIf="!(this.commonUtils.checkNullOrUndefined(emitValueFormGroup.get('filterOptionValue').value))">Nil</span>
        </span>
          </div>

  </form>

   <div class="btn-group" style="margin-left: 1%; margin-top: 1%; margin-bottom:1%" *ngIf="moduleName == 'VIEW_TCLINKMASTER'">
            <button mat-raised-button  matTooltip="Execute Url" color="primary" class="buttonWidth" (click)="executeUrl()">Start </button>
      </div>
      


      <!-- below is for resoruce extension 9014 --> 
 <div class="row">
<div class="float-left col-lg-6 dlp-flx">
 <form  [formGroup]="emitValueFormGroup"> 

     <mat-form-field class="col-lg-12 example-full-width"  *ngIf="moduleName === 'VIEW_RESOURCE_EXTENSION' "> 
      <mat-select placeholder="{{PROJECT}}"  formControlName="projectValue"  > <!-- [(ngModel)]="projectValue" -->
          <mat-option (click)="loadSelectedProject('')" (onSelectionChange)="onChangeProject('',$event)">All</mat-option>
          <mat-option  (onSelectionChange)="onChangeProject(project,$event)"  *ngFor="let project of projectList" [value]="project.projectId" (click)="loadSelectedProject(project)"> 
             {{project.projectName}} - <span style="color:grey;"><i> {{project.projectCode}} </i></span> 
          </mat-option>
        </mat-select>
    </mat-form-field> 

    </form>


     <form [formGroup]="resourceExtensionForm">
        <mat-form-field class="col-lg-12 example-full-width" *ngIf="moduleName === 'VIEW_RESOURCE_EXTENSION' ">
       
           <mat-select placeholder="{{USERS}}" formControlName="userIds">
            
              <mat-option (onSelectionChange)="onChangeUsersForProject(user,$event)" *ngFor="let user of orgUsersList"
                [value]="user.userId" (click)="loadSelectedUserBasedProjectAllocation(user)">
                {{user.userName}} <span style="color:grey;" *ngIf="user.empName != null && user.empName != '' "> - <i>
                  {{user.empName}} </i></span>
              </mat-option>
            </mat-select>
        </mat-form-field>
      </form>

   <!--   <mat-form-field class="example-full-width" >       -->   
         <!-- <div class="btn-group" *ngIf="moduleName === 'VIEW_RESOURCE_EXTENSION'" style="margin-top: 9px;">


        <span  matTooltip="Add Resource"> 
          <button mat-icon-button type="button" color="primary" class="buttonWidth" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="addDisable" (click)="saveOrUpdateResourceExtension()">
            <mat-icon class="matIconColor">add</mat-icon>
        </button>
        </span>
        
      </div> -->
</div>
</div>
<!-- above is for resoruce extension 9014 --> 

   <div class="col-lg-2 paddingTop10px float-left txtcenter"  *ngIf= "customFilter">
     <button mat-button type="button"  #filterButtonClick (click)="openFilter(filterButtonClick)">Filter By
        <div class="mat-select-arrow-wrapper">
            <div class="mat-select-arrow"></div>
        </div>
     </button>
</div>
<div class="row col-lg-12" *ngIf="moduleName === 'MANAGE_FEEDBACK_MYCORNER' && (commonService.loggedInUserObj.t029Roles.roleName === 'HR' || commonService.loggedInUserObj.t029Roles.roleName === 'ADMIN' )">
  <mat-form-field class="col-lg-3">
    <mat-select placeholder="{{SELECT_SALARY_GROUP}}" [(ngModel)]="salaryGroupId">
        <mat-option *ngFor="let salaryGroup of salaryGroupList" [value]="salaryGroup.salaryId" (click)="salaryChangeEvent()">
          {{salaryGroup.salaryGroupName}}
        </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-lg-3" matTooltip="Select Salary Group to load the Employees">
    <mat-select placeholder="{{SELECT_EMPLOYEE}}" [(ngModel)]="employeeId">
      <mat-option [value]="0" (click)="employeeChangeEvent()">None</mat-option>
      <mat-option *ngFor="let employeeObj of employeeList" [value]="employeeObj.employeeId" (click)="employeeChangeEvent()">
        {{employeeObj.userName}} - {{employeeObj.firstName}} {{employeeObj.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-lg-4">
    <input #dateSearchInput matInput [matDatepicker]="startDatePicker" placeholder="{{SEARCH_BY_CREATED_DATE}}" [(ngModel)]="createdDate"  
      dateFormatter id="startDatePicker" [disabled]="startDateFlag" (dateChange)="checkDate($event)">
    <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker ></mat-datepicker>
  </mat-form-field>
</div>
    <mat-form-field class="col-lg-3 example-full-width"  *ngIf="moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL'">
         <mat-select placeholder="{{USERS}}" [(ngModel)]="employeeId">
          <mat-option  *ngFor="let user of orgUsersList" [value]="user.userId" (click)="setUserObj(user)"  >
            {{user.userName}}  <span style="color:grey;" *ngIf="user.empName != null && user.empName != '' "> - <i> {{user.empName}} </i></span> 
          </mat-option>
        </mat-select>
    </mat-form-field> 
    <mat-form-field class="col-lg-3 example-full-width"  *ngIf="moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL'">  
        <mat-select placeholder="{{APPRAISAL_BOLD}}" [(ngModel)]="appraisalLevelValue">
          <mat-option   *ngFor="let appraisalLevel of appraisalLevelList" [value]="appraisalLevel">
            {{appraisalLevel.appraisalName}}
          </mat-option>
        </mat-select>
    </mat-form-field> 


    <div class="btn-group" *ngIf=" moduleName === 'VIEW_EMPLOYEE_APPRAISAL_LEVEL' " style="margin-top: 9px;">
      <span  matTooltip="Add Employee Appraisal Level">
          <button mat-icon-button type="button" color="primary" class="buttonWidth" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="addDisable"  (click)="saveOrUpdateEmployeeAppraisalLevel()">
            <mat-icon class="matIconColor">add</mat-icon>
         </button>
      </span>
    </div>

      <!-- my team leave - 9103-->
      <div class="btn-group marginLeft10px" *ngIf="moduleName === 'VIEW_LEAVE' && managerAccess" style="margin-top: 9px;">
        <button  mat-raised-button type="button" color="primary" class="buttonWidth" (click)="fetchTeamLeave()"> My Team Leave</button>
      </div>
      <div class="btn-group" *ngIf="moduleName === 'VIEW_LEAVE' && managerAccess" style="margin-top: 9px;">
        <button  mat-raised-button type="button" color="primary" class="buttonWidth" (click)="fetchMyLeave()"> My Leave</button>
      </div>
      <!-- my team leave - 9103-->
       <!-- my Queue & all queue for final approver - 9103-->
      <div class="btn-group marginLeft10px" *ngIf="moduleName === 'APPROVE_LEAVE' && finalApproverAccess" style="margin-top: 9px;">
        <button  mat-raised-button type="button" color="primary" class="buttonWidth" (click)="fetchAllQueue()"> All Queue</button>
      </div>
      <div class="btn-group" *ngIf="moduleName === 'APPROVE_LEAVE' && finalApproverAccess" style="margin-top: 9px;">
        <button  mat-raised-button type="button" color="primary" class="buttonWidth" (click)="fetchMyQueue()"> My Queue</button>
      </div>

      <mat-form-field class="col-lg-3 example-full-width"  *ngIf="moduleName === 'VIEW_DATABASE'">   
         <mat-select placeholder="{{VERSION_NUMBER}}" [(ngModel)]="versionNumber">
          <mat-option (click)="loadVersionNumber()">None</mat-option>
          <mat-option  *ngFor="let versionNo of versionNumberList" [value]="versionNo" (click)="loadVersionNumber()" >
            {{versionNo}}
          </mat-option>
        </mat-select>
    </mat-form-field>

      </div>
      <!--  my Queue & all queue for final approver - 9103-->
   <!--  </mat-form-field>  -->
    <div class="rightCorner col-lg-3 txtcenter" style="padding-right: 0">
    
    <a  *ngIf="moduleName !='REVIEW_TRAINEE'">
      <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="openIcon('REFRESH')">
      <mat-icon class="reportLink"  matTooltip="This will reset and bring back the data like how we had on the initial page load" >refresh</mat-icon> </button> </a>

       <a (click)="openIcon('SEARCH')" *ngIf="this.commonUtils.hideSearchIcon(moduleName)">
          <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
            <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon>
          </button>
        </a>

        <a (click)="openIcon('ANALYTICALCHART')" *ngIf="this.commonUtils.hideAnalyticalChartIcon(moduleName)"> 
        <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="View Analytical Chart">insert_chart</mat-icon></button>
        </a>
        
          <a (click)="openIcon('REVIEWDISPLAYCOLUMN')">
         <button mat-button type="button"  matSuffix mat-icon-button #moreMenu   
         matSuffix   aria-label="Clear">
            <mat-icon matTooltip="Columns">more_vert</mat-icon>
        </button>
</a>
      <div class="btn-group" *ngIf=" currentPageAccessObj!=undefined && currentPageAccessObj.createAccess && moduleName != 'VIEW_R_EXPENSE' && 
      moduleName != 'MY_LOAN' && moduleName != 'APPROVE_LEAVE' && moduleName != 'PART_REQUEST' && createFlag && 
      moduleName!='VIEW_EMPLOYEE_APPRAISAL_LEVEL' && moduleName!='PROJECT_PLAN_CLIENT' && moduleName!='VIEW_SHIPPING'
      && moduleName!='VIEW_PRODUCT_SETUP' && moduleName!='VIEW_TIMESHEET'  && moduleName!='TIMESHEET_HISTORY' && moduleName!='VIEW_APPRAISAL_FORM'
      && moduleName!='HRIS_TIMESHEET_HISTORY' && moduleName!='REVIEW_MEDICAL_BILL'   && moduleName!='REVIEW_TRAINEE'">
          <span matTooltip={{createLabel}}><a (click)="newContact()">
            <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
              <mat-icon >add</mat-icon>
            </button></a></span>
      </div>
      <div class="btn-group">
      <button mat-button type="button" *ngIf="moduleName=='VIEW_TIMESHEET' || moduleName=='VIEW_HRIS_TIMESHEET'" style="float: right;" matSuffix mat-icon-button aria-label="Clear" (click)="viewHistory()">
          <mat-icon class="reportLink"  matTooltip="History">history</mat-icon>
      </button>
    </div>
      <!-- Settings button -->
      <div class="btn-group" >
        <span  >
        <!-- 
          <button mat-icon-button type="button" color="primary" class="buttonWidth" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="settingsDisable">
            <mat-icon class="matIconColor">settings</mat-icon>
        </button>
-->
      </span>
          <span *ngIf="moduleName === 'VIEW_AUTOMATED_EMAIL'"><a (click)="sendEmail()"> 
          <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" color="primary" >
            <mat-icon matTooltip="Auto Email" class="matIconColor">arrow_upward</mat-icon></button></a></span>
            <span *ngIf="moduleName === 'VIEW_DATABASE'"><!-- <a (click)="reloadERPProperties()"> 
          <button mat-icon-button type="button" color="primary" class="buttonWidth" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
            <mat-icon matTooltip="Reload ERP Properties" class="matIconColor">autorenew</mat-icon></button></a> -->

              <a (click)="reloadERPProperties()">
      <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" color="primary">
      <mat-icon class="reportLink"  matTooltip="Reload ERP Properties">autorenew</mat-icon> </button> </a>
          </span>
      </div>

     <!-- Menu Options button  Phase 2 -->
    <div class="btn-group"  appDropdown >
      <div  *ngIf="moduleName != 'VIEW_AUTOMATED_EMAIL' && moduleName != 'VIEW_ACCOUNT_BUDGET'"> 
        <button  mat-button type="button"  matSuffix mat-icon-button #sideMenu aria-label="Clear" (click)="sideMenuBtnClick()"         [disabled]="menuDisable">
        <mat-icon class="matIconColor">menu</mat-icon>
        </button>
     </div>
        
       <div class="dropdown-menu pull-left" style="min-width: 170px;" *ngIf="dropDownFlag===true && menuDisable === false">
           <div *ngIf="sortoptions!=null && sortoptions.length>0 " >
         <label class="fltheader">SORT BY</label>

        <label class="col-lg-12 submenu"> 
          <a class="col-lg-12 mat-menu-item sortOptions dropdown-item" 
          *ngFor="let option of sortoptions" > 

          <span class="sortOptionsTitle col-lg-8 sortWordWrap"> {{option.displayValue}} </span>
          <span class="sortOptionsIcons col-lg-4">
                <mat-icon  (click)="sortContacts(option.lookupValue,'asc')"  style="color: green;font-size: 15px;" class="menu_add_btn"> arrow_upward
                </mat-icon>  

                <mat-icon  (click)="sortContacts(option.lookupValue,'desc')" style="color: crimson;font-size: 15px;"> arrow_downward</mat-icon> 
          </span> 
           </a>
        </label>

          </div>  
           <ng-template [ngIf]="importDisable">
        
        
         <div class="dropdown-divider"></div>
         <div>Import Data</div>

         <a class="dropdown-item"  style="cursor: pointer;"  (click)="importData()" >
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
          <mat-icon class="mat_icon_color"> file_upload</mat-icon> </button><p style="margin-left: -1%;">Import Data</p></a>

         </ng-template>
        <ng-template [ngIf]="exportDisable">

         <div class="dropdown-divider"></div>
         <a class="dropdown-item" style="cursor: pointer;" (click)="exportData()">
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button><p style="margin-left: -1%;">Export Data</p></a>
        <div class="dropdown-divider"></div>
      </ng-template>
     
       </div>
     </div> 
      </div>

     

       
  </div>