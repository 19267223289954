<div  style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);  padding: 5% !important;">
<form [formGroup]="timesheetForm">
<div>
	<div>
        <mat-form-field class="col-lg-12 nopadding">
            <input [matDatepicker]="workedDate" placeholder="{{WORKED_DATE}}" matInput formControlName="workedDate" dateFormatter>
            <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="workedDate"></mat-datepicker-toggle>
            <mat-datepicker #workedDate></mat-datepicker>
        </mat-form-field>
    </div>
    <div>
    	<mat-form-field  class="col-lg-12 nopadding">
    	    <input matInput formControlName='workedHours' placeholder="{{WORKED_HOURS}}" onlyDecimal="true" DecimalPlaces="2" minValue="0.00" maxValue="9999999999999.00" noAndDotOnly>
    	</mat-form-field>
    </div>
    <div>
    	<mat-form-field  class="col-lg-12 nopadding">
            <textarea matInput placeholder="{{TASK_DESC}}" formControlName="taskDescription" matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="4" onfocus="this.select()"></textarea>
        </mat-form-field>
    </div>
    
    <div style="width: 100% !important">
    	<button style="width:40% !important" type='button' mat-raised-button color="primary" (click)='saveTimeSheet()'>{{SUBMIT_BUTTON}}</button>
    </div>
</div>
</form>
</div>