<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
<mat-card-title >{{CREATE_USER}}</mat-card-title>
<mat-card-content>
<form (ngSubmit)="saveOrUpdateUsers()"   [formGroup]="myForm">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{USERNAME_PLACEHOLDER}}" formControlName="userName" [errorStateMatcher]="matcher" 
			[readonly]="disabledValue"  (keypress)="userNameExistsCheck()" (paste)="userNameExistsCheck()">
			<!-- (blur)="findUserNameLength()" -->
			<!-- <mat-error *ngIf="myForm.controls['userName'].hasError('required'); else elseBlock">{{USRENAME_ALERT}} <strong>{{REQUIRED}}</strong> </mat-error>
			<mat-error #elseBlock  *ngIf="myForm.controls['userName'].hasError('minlength'); else elseBlock1">{{USERNAME_MIN_LENGTH}} </mat-error>
			<mat-error #elseBlock1  *ngIf="myForm.controls['userName'].hasError('maxlength'); else elseBlock2">{{USERNAME_MAX_LENGTH}}</mat-error> -->
			<mat-hint #elseBlock1 *ngIf="(availableuserList | async)?.length>0" class="discountDivColor">	{{USERNAME_EXISTS_ALERT}}</mat-hint>
			<mat-hint #elseBlock2 *ngIf="availableUserNameLength <= 4" class="discountDivColor">	{{USERNAME_MIN_LENGTH}}</mat-hint> 
			</mat-form-field>
			
		</div>
		<div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			<mat-form-field class="col-lg-10 col-md-10 col-sm-10 col-xs-10" >
				<input type="password" matInput placeholder="{{PASSWORD_PLACEHOLDER}}" formControlName="password" readonly="{{requiredPasswordFlag}}">	
			</mat-form-field>
			 <mat-slide-toggle color="primary" class="col-lg-1 col-md-2 col-sm-2 col-xs-2" formControlName="setPassword" (click)='isCheckedValue()' style="padding-top: 5%;padding-left:0;padding-right: 0;"></mat-slide-toggle>
		</div>
		<div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input matInput placeholder="{{EMAIL_PLACEHOLDER}}"   formControlName="email"  >
			<!-- <mat-error *ngIf="myForm.controls['email'].hasError('required'); else elseBlock">{{EMAIL_REQUIRED}} <strong>{{REQUIRED}}</strong> </mat-error>
			<mat-error #elseBlock *ngIf="myForm.controls['email'].hasError('email') && !myForm.controls['email'].hasError('required');  ">
			{{EMAIL_VALIDATION_ALERT}}
			</mat-error> -->			
			</mat-form-field>
		</div>
		 <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" formGroupName="t029Roles">
			<mat-select placeholder="{{SELECT_ROLE}}" formControlName="roleId">
			<mat-option *ngFor="let roles of rolesList" [value]="roles.roleId">
			{{roles.roleName}}
			</mat-option>
			</mat-select>
			</mat-form-field>
		</div> 
		 <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<mat-select placeholder="{{SELECT_LOCATION}}" multiple formControlName='locationIds'>
			<mat-option *ngFor="let location of locationList" [value]="location.locationId">
			{{location.locationName}}
			</mat-option>
			</mat-select>
			</mat-form-field>
		</div> 
		<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding">
		 	<mat-slide-toggle class="col-lg-1 col-md-1 col-sm-1 col-xs-1 nopadding" color="primary" formControlName="userStatus">IsActive</mat-slide-toggle>
		</div> 

		<!-- <div class="col-lg-10">
		 	<mat-form-field class="col-lg-12" >
      			<input matInput placeholder="NAME">
     		</mat-form-field>
     	</div>
     	<div class="col-lg-10">
     		<mat-form-field class="col-lg-12" >
     			<input matInput placeholder="dept">
     		</mat-form-field>
     	</div> -->
    
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons paddingTop30px" style="display: flex;" >
			<button mat-raised-button color="primary" class="mat-red" type="submit"  [disabled]="(availableuserList | async)?.length>0 || !myForm.valid">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
	</div>
	
</form>
</mat-card-content>
</mat-card>