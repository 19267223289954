import { OverlayRef } from '@angular/cdk/overlay';
import{ EventEmitter, Output, Directive } from '@angular/core';
 import {Subscription,Observable} from 'rxjs/Rx';
@Directive()
export class ErpOverlayRef {
 
  constructor(private overlayRef: OverlayRef) { }
 @Output() afterClosed=new EventEmitter();
  close(): void {
    this.overlayRef.dispose();
  }
  
dialogClose(returnObject:any){
   	   this.afterClosed.emit(returnObject);
   	   this.overlayRef.dispose();
  	  
  }
}
