<div [formGroup]="form"  >
  <!-- <label [attr.for]="question.key">{{question.label}}</label>
   -->
  <div [ngSwitch]="question.controlType" >
<!-- The style is removed because it affects in the create items screen - please check in all before changing --- style="width:150%;" -->
 <mat-form-field  class="col-lg-12" *ngSwitchCase="'textbox'">
    <input matInput placeholder="{{question.label}}"  [formControlName]="question.key"  
            [id]="question.key" [type]="question.type" (focusout)="focusOutCustomFields()">
</mat-form-field>
<!-- The style is removed because it affects in the create items screen - please check in all before changing --- style="width: 350%;margin-left: -30px;" -->
<mat-form-field class="col-lg-12"  *ngSwitchCase="'dropdown'">
    <mat-select   placeholder="{{question.label}}"  [id]="question.key"  [formControlName]="question.key" >
      <mat-option *ngFor="let opt of question.options" [value]="opt.key" (click)="focusOutCustomFields()">{{opt.value}}</mat-option>
  </mat-select>
</mat-form-field>

<!-- <mat-form-field *ngSwitchCase="'checkbox'"> -->

    <mat-checkbox  class="col-lg-12"  color="primary"  type="checkbox"  *ngSwitchCase="'checkbox'"  [id]="question.key"  [formControlName]="question.key" (click)="focusOutCustomFields()"
    >{{question.label}}</mat-checkbox>
       
<!-- </mat-form-field> -->

  </div> 

 <!--  <div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div> -->
</div>