import { Component, OnInit } from '@angular/core';
import{Response} from '@angular/http';
import {Router} from '@angular/router';


@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html' ,

})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router) { }
   userDataObj ;
  ngOnInit() {

  }





}
