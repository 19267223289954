<mat-card class="col-lg-12 ">
<mat-card-title ><mat-icon> how_to_reg </mat-icon> {{APPLY_APPRAISAL_FORM}}</mat-card-title>
<mat-card-content>
	<form [formGroup]="applyAppraisalForm" (ngSubmit)="saveAppraisalForm()">
	<div>
		<div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="float: right;">
			<mat-form-field class="col-lg-12">
				<mat-select placeholder="{{EXPORT_AS}}">
					<mat-option *ngFor="let report of reports" [value]="report.value"  (click)="downloadReportSummary(report.value)">
						{{ report.viewValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>		
	<div class="row col-lg-12 divTableHeading">
		<div class="alignLeft col-lg-2">
			<mat-label >
				{{APPRAISAL_BOLD}} 
			</mat-label>
		</div>
		
		<div class="alignLeft col-lg-7">
			<mat-label >
				{{SUB_APPRAISAL_BOLD}}
			</mat-label>
		</div>
		
	</div>

	<div class="col-lg-12 gridColor greyborderpad" formArrayName="subAppraisalSet" *ngFor="let appraisalFormObj of applyAppraisalForm.get('subAppraisalSet')['controls']; index as idx"> 
        <div class="row" [formGroupName]="idx">
			<ng-template [ngIf]="appraisalFormObj.controls['status'].value">
			<div class="col-lg-2 alignLeft paddingTop10px">
				<span>
					<mat-label>{{applyAppraisalForm.get('t828_AppraisalObj').get('appraisalName').value}}</mat-label>
				</span>
			</div>

			<div class="col-lg-7 alignLeft paddingTop10px">
				<mat-label>{{appraisalFormObj.controls.subAppraisalName.value}}</mat-label>
			</div>

			<div class="col-lg-3 alignCenter paddingTop10px">
					<button class="btn-rev" mat-raised-button color="primary" type="button" (click)="openPopupToReviewOrAddComments(idx,appraisalFormObj)">Review / Provide your comments</button>
			</div>
			</ng-template>
		</div>	
	</div>
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row" style="padding: 0;">
		<div class="col-lg-3 col-md-4 col-sm-12 col-xs-12" style="padding: 0;margin-top: 3%;">
			<mat-checkbox class="col-lg-12 col-md-12 col-sm-12 col-xs-12" color="primary" formControlName="oneToOneDiscussionFlag"
			matTooltip="Click this if you have completed the One to One Discussion" >{{ONE_TO_ONE_DISCUSSION_FLAG}}
			</mat-checkbox>
		</div>
		<mat-form-field class="col-lg-9 col-md-8 col-sm-12 col-xs-12" *ngIf="applyAppraisalForm.controls['oneToOneDiscussionFlag'].value">
			<textarea matInput placeholder="{{ONE_TO_ONE_DISCUSSION_COMMENTS}}" formControlName="oneToOneDiscussionComments" name="oneToOneDiscussionComments"></textarea>         
		</mat-form-field>
	</div>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons" align="center">
	<button *ngIf="showApproverButton" style="margin-top: 20px;" mat-raised-button color="primary" class="mat-red" type="submit">{{SAVE_BUTTON}}</button>
	<button style="margin-top: 20px;" mat-raised-button color="primary"  (click)="cancelClicked()" type="button">{{CANCEL_BUTTON}}</button>
	<button *ngIf="showApproverButton && isFinalApprover === false" style="margin-top: 20px;" mat-raised-button color="primary" (click)="approveToNextLevelAppraiser()" type="button">{{APPROVE_TO_NEXT_LEVEL}}</button>
	<button *ngIf="showApproverButton && isFinalApprover" style="margin-top: 20px;" mat-raised-button color="primary" (click)="statusUpdateInAppraisalEmployeeAssociation('ON HOLD')" type="button">{{APPRAISAL_HOLD}}</button>
	<button *ngIf="showApproverButton && isFinalApprover" style="margin-top: 20px;" mat-raised-button color="primary" (click)="statusUpdateInAppraisalEmployeeAssociation('APPROVED')" type="button">{{APPRAISAL_APPROVED}}</button>
	<button *ngIf="showApproverButton && isFinalApprover" style="margin-top: 20px;" mat-raised-button color="primary" (click)="statusUpdateInAppraisalEmployeeAssociation('REJECTED')" type="button">{{APPRAISAL_REJECTED}}</button>
</div>

</form>
</mat-card-content>
</mat-card>

