import { Component ,Inject} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import {CommonUtils} from '../../core/common-utils';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'side-menu-overlay-component',
  templateUrl: './side-menu-overlay.component.html',
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
    ]
 })

export class SideMenuOverlayComponent extends CommonUtils { 
sortoptions;
importDisable;
exportDisable;
exportParentEnable: boolean = false;
exportChildEnable: boolean = false;
exportParentMatrixEnable: boolean = false;
exportParentImagesEnable: boolean = false;
exportAmazonListing:boolean = false;
bulkImportDeleteEnable: boolean = false;
bulkImportItemEnable:boolean = false;

constructor(public dialogRef: ErpOverlayRef,@Inject(FILE_PREVIEW_DIALOG_DATA) public data: any,public commonUtils: CommonUtils) { 
    super();
    this.sortoptions = data.sortoptions;
    this.importDisable = data.importDisable;
    this.exportDisable = data.exportDisable;
     
    if(this.commonUtils.checkNullOrUndefined(data.exportParentEnable))
      this.exportParentEnable = data.exportParentEnable;
    if(this.commonUtils.checkNullOrUndefined(data.exportChildEnable))
      this.exportChildEnable = data.exportChildEnable;
    if(this.commonUtils.checkNullOrUndefined(data.exportParentMatrixEnable))
      this.exportParentMatrixEnable = data.exportParentMatrixEnable;
    if(this.commonUtils.checkNullOrUndefined(data.exportParentImagesEnable))
      this.exportParentImagesEnable = data.exportParentImagesEnable;
    if(this.commonUtils.checkNullOrUndefined(data.exportAmazonListing))
      this.exportAmazonListing = data.exportAmazonListing;
    if(this.commonUtils.checkNullOrUndefined(data.bulkImportDeleteEnable))
      this.bulkImportDeleteEnable = data.bulkImportDeleteEnable;
     if(this.commonUtils.checkNullOrUndefined(data.bulkImportItemEnable))
      this.bulkImportItemEnable = data.bulkImportItemEnable;
  }
  
ngOnInit() 
{
  this.pageload(); 
}
pageload()
{
    
} 
openSideMenuOption(option,orderStr){
		let returnSortOption:any={};
		returnSortOption.option = option;
		returnSortOption.orderStr = orderStr;
		this.dialogRef.dialogClose(returnSortOption);
   }
    importData(){
    	this.dialogRef.dialogClose("IMPORT_DATA");
    }
    exportData(){
    	this.dialogRef.dialogClose("EXPORT_DATA");
    }

    exportDataForNistar(moduleClicked){
      this.dialogRef.dialogClose("EXPORT_DATA,"+moduleClicked);
    }
    bulkImportForNistar(moduleClicked){
       this.dialogRef.dialogClose("IMPORT_DATA," +moduleClicked);
    }
}