import { Component, OnInit, Inject} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-erp-prompt',
  templateUrl: './erp-prompt.component.html',
   providers: []
})

export class ERPPromptComponent  {

dialogTextToDisplay:string;
confButton1:string='';
confButton2:string='';
moduleName:string='';
	constructor( 
     public dialog: MatDialog,
     public dialogRef: MatDialogRef<ERPPromptComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any ) {

      this.dialogTextToDisplay = data.dialogText;
	  this.confButton1=data.confButton1;
	  this.confButton2=data.confButton2;
    this.moduleName=data.moduleName;


	}
	

	onButton1Click(): void 
    {
      if(this.moduleName==='NEW_SALESORDER' || this.moduleName==='NEW_ESTIMATE' || this.moduleName ==='VIEW_PACKAGES' || this.moduleName ==='NEW_INVOICE')
      this.dialogRef.close("YES");
    else
      this.dialogRef.close("BUTTON1");
    } 
    onButton2Click(): void {
       if(this.moduleName==='NEW_SALESORDER' || this.moduleName==='NEW_ESTIMATE' || this.moduleName ==='VIEW_PACKAGES' || this.moduleName ==='NEW_INVOICE')
      this.dialogRef.close("NO");
    else
      this.dialogRef.close("BUTTON2");
    } 
}