import { Component, Input ,Inject,Output} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
@Component({
  selector: 'icons-component',
  templateUrl: './icons.component.html'
   
})
export class IconsComponent extends CommonUtils { 
 
 

  constructor(
      public dialogRef: ErpOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
     super();
    
     
  }
ngOnInit() 
{
  this.pageload(); 
}
pageload()
{
    
} 
openIcon(screen:String){
this.dialogRef.dialogClose(screen);
    }
}
