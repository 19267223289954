import {Component, OnInit,Inject} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import {Headers} from '@angular/http';
import {CommonService} from '../../../shared/common.service';
import {Constants} from '../../../shared/dateFormatPipe.pipe';
import {SettingsService} from '../../settings-service';
import {CommonUtils} from '../../../core/common-utils';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs/Rx';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
@Component({
  selector: 'app-settings',
  templateUrl: './createorganizationprofile.component.html'
})

export class CreateOrganizationProfileComponent extends CommonUtils implements OnInit {
  
  errorimageSourcePath=environment.NO_IMAGE_FOR_ORG_PROFILE;
myForm: FormGroup;
separatorKeysCodes = [ENTER, COMMA];
 visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
 currencyList ;
 countryList=[];
 whitelistIPList = [];
 businessLocations;
 languages;
 indutries;
 fiscalYears;
 dateFormats;
 timezones;
 logo: any;
 imgFile: File;
 imgElem: any;
 headers: Headers;
 userObj;
 organizationId;
t000_Organization_BillingAddressesSet: FormArray;
t000_Organization_ShippingAddressesSet: FormArray;
t100OrganizationBillingFormarray: FormArray;
t100OrganizationShippingFormarray: FormArray;
defaultBillingFlag: string;
defaultShippingFlag: string;
 organizationObj;
 showExistingImg = true;
imageSrc: any;
erpassets;
portalName;
step = 0;
bankPanelOpenState;
addressPanelOpenState;
SERVICE_URL;
moduleName=environment.VIEW_ORGANIZATION;
serverPath;
 errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
currencyFormGroup=new FormGroup({
      currencyId: new FormControl(0),
      currencyCode: new FormControl(''),
      currencyValue: new FormControl(''),
      status: new FormControl('N'),
      sortOrder: new FormControl(0),
      symbol: new FormControl(''),
      baseCurrency: new FormControl(false),
      exchangeRate: new FormControl('') 
     
   })
  constructor(
    private commonService: CommonService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private commonUtils: CommonUtils,
    public dialogRef: MatDialogRef<CreateOrganizationProfileComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
 super();
 this.SERVICE_URL=this.commonService.SERVICE_URL;
this.erpassets=this.SERVICE_URL;
this.serverPath=this.SERVICE_URL

this.organizationId=data.organizationId;
  this.myForm = new FormGroup({
    organizationId : new FormControl(0),
    organizationName : new FormControl('', Validators.maxLength(75)),
    address1 : new FormControl('', Validators.maxLength(250)),
    address2 : new FormControl('', Validators.maxLength(250)),
    city : new FormControl('', Validators.maxLength(75)),
    state : new FormControl('', Validators.maxLength(75)),
    zipCode : new FormControl('', Validators.maxLength(11)),
    phone : new FormControl('', Validators.maxLength(20)),
    fax : new FormControl('', Validators.maxLength(20)),
    website : new FormControl('', Validators.maxLength(75)),
    primaryContactName : new FormControl('', Validators.maxLength(50)),
    primaryContactEmailAddress : new FormControl('', Validators.maxLength(75)),
    createdTs: new FormControl(new Date),
    updatedTs: new FormControl(new Date),
    imageName: new FormControl (''),
    logoPath : new FormControl(''),
    imageSrc : new FormControl(''),
    active: new FormControl(true),
    schemaName:new FormControl(''),
    url:new FormControl(''),
    userName:new FormControl(''),
    password:new FormControl(''),
    portalName:new FormControl(''),
    authSecret:new FormControl(''),
    whitelistIP: new FormControl(''),
    countryId: new FormGroup({countryId: new FormControl(1)}),
    t000_Organization_ShippingAddressesSet: this.formBuilder.array([]),
    t000_Organization_BillingAddressesSet: this.formBuilder.array([]),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    t028Location : new FormGroup({locationId: new FormControl(0), locationName: new FormControl('', Validators.maxLength(75))}),
    t003Currency : new FormGroup({currencyId: new FormControl(0), currencyCode: new FormControl(''), currencyValue: new FormControl('', Validators.maxLength(100))}),
    t027Industry : new FormGroup({industryId: new FormControl(0), industryName: new FormControl('', Validators.maxLength(75))}),
    t012Language : new FormGroup({langId: new FormControl(0), name: new FormControl('', Validators.maxLength(75))}),
    t004LookuptimeZoneId : new FormGroup({lookupId: new FormControl(0), lookupCode: new FormControl('TIME_ZONE'), lookupValue: new FormControl('', Validators.maxLength(100))}) ,
    t004LookupFiscalYearId : new FormGroup({lookupId: new FormControl(0), lookupCode: new FormControl('FISCAL_YEAR'), lookupValue: new FormControl('', Validators.maxLength(100))}),
    t004LookupDateFormatId : new FormGroup({lookupId: new FormControl(0), lookupCode: new FormControl('DATE_FORMAT'), lookupValue: new FormControl('', Validators.maxLength(100))}),
    bankName: new FormControl(''),
    ifscCode:new FormControl(''),
    accountNumber: new FormControl(''),
    companyPAN: new FormControl(''),
    branch: new FormControl(''),
    gstin_Uin: new FormControl(''),
    defaultBillingLocationId : new FormGroup({ locationId: new FormControl(0), 
                                 locationName: new FormControl('', Validators.maxLength(75)),
                                 locationAttention: new FormControl(''),
                                 locationAddressLineOne: new FormControl(''),
                                 locationAddressLineTwo: new FormControl(''),
                                 locationCity: new FormControl(''),
                                 locationState: new FormControl(''),
                                 locationZipCode: new FormControl(''),
                                 t010locationCountryObj: new FormGroup({countryId: new FormControl(), 
                                                                        countryName: new FormControl('')}),
                                 locationPhone: new FormControl(''),
                                 locationFax: new FormControl(''),
                                 gstNumber: new FormControl('')

                               }),

    defaultShippingLocationId : new FormGroup({ locationId: new FormControl(0), 
                                 locationName: new FormControl('', Validators.maxLength(75)),
                                 locationAttention: new FormControl(''),
                                 locationAddressLineOne: new FormControl(''),
                                 locationAddressLineTwo: new FormControl(''),
                                 locationCity: new FormControl(''),
                                 locationState: new FormControl(''),
                                 locationZipCode: new FormControl(''),
                                 t010locationCountryObj: new FormGroup({countryId: new FormControl(), 
                                                                        countryName: new FormControl('')}),
                                 locationPhone: new FormControl(''),
                                 locationFax: new FormControl(''),
                                  gstNumber: new FormControl('')

                               }),
  })

  }

  fetchCountry(): void {
  this.commonService.fetchCountry().
  subscribe(
  data => {
  this.countryList = data;
  },
  error => {
  this.commonService.showERPErrorMessage(error);
  });
  }
 currencySelected(selectedCurrency): void {
   this.myForm.get('t003Currency.currencyCode').setValue(selectedCurrency.viewValue);
   this.myForm.get('t003Currency.currencyId').setValue(selectedCurrency.value);
 }

 businessLocationSelected(selectedBusiness): void {
   this.myForm.get('t028Location.locationName').setValue(selectedBusiness.viewValue);
   this.myForm.get('t028Location.locationId').setValue(selectedBusiness.value);
 }

 industrySelected(selectedIndustry): void {
   this.myForm.get('t027Industry.industryName').setValue(selectedIndustry.viewValue);
   this.myForm.get('t027Industry.industryId').setValue(selectedIndustry.value);
 }
 languageSelected(selectedlanguage): void {
   this.myForm.get('t012Language.name').setValue(selectedlanguage.viewValue);
   this.myForm.get('t012Language.langId').setValue(selectedlanguage.value);
 }
 timeZoneSelected(selectedTimeZone): void {
   this.myForm.get('t004LookuptimeZoneId.lookupValue').setValue(selectedTimeZone.viewValue);
   this.myForm.get('t004LookuptimeZoneId.lookupId').setValue(selectedTimeZone.value);
 }
 fiscalYearSelected(selectedYear): void {
   this.myForm.get('t004LookupFiscalYearId.lookupValue').setValue(selectedYear.viewValue);
   this.myForm.get('t004LookupFiscalYearId.lookupId').setValue(selectedYear.value);
 }
 dateFormatSelected(selectedDateFormat): void {
   this.myForm.get('t004LookupDateFormatId.lookupValue').setValue(selectedDateFormat.viewValue);
   this.myForm.get('t004LookupDateFormatId.lookupId').setValue(selectedDateFormat.value);
 }
 billingLocationSelected(selectedLocation): void {
   this.myForm.get('defaultBillingLocationId.locationAttention').setValue(selectedLocation.locationAttention);
   this.myForm.get('defaultBillingLocationId.locationAddressLineOne').setValue(selectedLocation.locationAddressLineOne);
   this.myForm.get('defaultBillingLocationId.locationAddressLineTwo').setValue(selectedLocation.locationAddressLineTwo);
    if(this.commonUtils.checkNullOrUndefined(selectedLocation.t010locationCountryObj))
   this.myForm.get('defaultBillingLocationId.t010locationCountryObj.countryId').setValue(selectedLocation.t010locationCountryObj.countryId);
   this.myForm.get('defaultBillingLocationId.locationState').setValue(selectedLocation.locationState);
   this.myForm.get('defaultBillingLocationId.locationZipCode').setValue(selectedLocation.locationZipCode);
   this.myForm.get('defaultBillingLocationId.locationCity').setValue(selectedLocation.locationCity);
   this.myForm.get('defaultBillingLocationId.locationPhone').setValue(selectedLocation.locationPhone);
   this.myForm.get('defaultBillingLocationId.locationFax').setValue(selectedLocation.locationFax);
   this.myForm.get('defaultBillingLocationId.gstNumber').setValue(selectedLocation.gstNumber);
  }
  shippingLocationSelected(selectedLocation): void {

   this.myForm.get('defaultShippingLocationId.locationAttention').setValue(selectedLocation.locationAttention);
   this.myForm.get('defaultShippingLocationId.locationAddressLineOne').setValue(selectedLocation.locationAddressLineOne);
   this.myForm.get('defaultShippingLocationId.locationAddressLineTwo').setValue(selectedLocation.locationAddressLineTwo);
   if(this.commonUtils.checkNullOrUndefined(selectedLocation.t010locationCountryObj))
   this.myForm.get('defaultShippingLocationId.t010locationCountryObj.countryId').setValue(selectedLocation.t010locationCountryObj.countryId);
   this.myForm.get('defaultShippingLocationId.locationState').setValue(selectedLocation.locationState);
   this.myForm.get('defaultShippingLocationId.locationZipCode').setValue(selectedLocation.locationZipCode);
   this.myForm.get('defaultShippingLocationId.locationCity').setValue(selectedLocation.locationCity);
   this.myForm.get('defaultShippingLocationId.locationPhone').setValue(selectedLocation.locationPhone);
   this.myForm.get('defaultShippingLocationId.gstNumber').setValue(selectedLocation.gstNumber);

   
 }
subscriptionList: Array<Subscription> = [];
currentPageAccessObj
  ngOnInit() 
{
   
   //this.commonService.checkLoggedInUser(this.moduleName);
     let subscriptionObj= this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
            
            this.commonService.cleanUpListener(this.subscriptionList);
            this.currentPageAccessObj = this.commonService.getAccessObjForCurrentScreen(this.moduleName);

            if(this.commonUtils.checkNullOrUndefined(this.currentPageAccessObj)){
              //this.editAccess=this.currentPageAccessObj.editAccess;

              this.pageLoad();
            }else
            this.commonService.emitInvalidLogin();                       
         }

       });
     this.subscriptionList.push(subscriptionObj)

 if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
  this.pageLoad();

   
}
pageLoad():void
{
  this.t100OrganizationBillingFormarray = this.myForm.get('t000_Organization_BillingAddressesSet')  as FormArray;
  this.t100OrganizationShippingFormarray = this.myForm.get('t000_Organization_ShippingAddressesSet')  as FormArray;
   try
    {
     
     this.t100OrganizationBillingFormarray.push(this.addBilling());
     this.t100OrganizationShippingFormarray.push(this.addShipping());
     this.fetchCurrency();
     this.fetchCountry();
     this.fetchLocation();
     this.fetchLanguage();
     this.fetchIndustries();
     this.fetchFromLookup(this.myForm.get('t004LookuptimeZoneId'));
     this.fetchFromLookup(this.myForm.get('t004LookupFiscalYearId'));
     this.fetchFromLookup(this.myForm.get('t004LookupDateFormatId'));
     if(this.commonUtils.checkNullOrUndefined(this.organizationId))
     this.fetchOrganization();
   }
   catch(error)
  {
      this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
      this.errorFormGroup.get('errorStackTrace').setValue(error.message);
      this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{
        
       });
} 
}
addBilling(): FormGroup {
  return this.formBuilder.group({
  organizationBillingId: new FormControl(0),
  billingAttention: new FormControl(''),
  billingAddressLineOne: new FormControl(''),
  billingAddressLineTwo: new FormControl(''),
  t010BillingCountry: new FormGroup({countryId: new FormControl(4), countryCode: new FormControl(''),
  countryValue: new FormControl(''), active: new FormControl(''), createdBy: new FormControl(''),
  createdDttm: new FormControl(new Date()), updatedBy: new FormControl(''),
  updatedDttm: new FormControl(new Date())}),
  billingCity: new FormControl(''),
  billingState: new FormControl(''),
  billingZipCode: new FormControl(''),
  billingPhone: new FormControl(''),
  billingFax: new FormControl(''),
  isDefault: new FormControl('Y'),
  t000_OrganizationObj: new FormGroup({organizationBillingId: new FormControl(0)}),
  });
  }


  addShipping(): FormGroup {
  return this.formBuilder.group({
  organizationShippingId: new FormControl(0),
  shippingAttention: new FormControl(''),
  shippingAddressLineOne: new FormControl(''),
  shippingAddressLineTwo: new FormControl(''),
  t010ShippingCountry: new FormGroup({countryId: new FormControl(4), countryCode: new FormControl(''),
  countryValue: new FormControl(''), active: new FormControl(''), createdBy: new FormControl(''),
  createdDttm: new FormControl(new Date()), updatedBy: new FormControl(''),
  updatedDttm: new FormControl(new Date())}),
  shippingCity: new FormControl(''),
  shippingState: new FormControl(''),
  shippingZipCode: new FormControl(''),
  shippingPhone: new FormControl(''),
  shippingFax: new FormControl(''),
  isDefault: new FormControl('Y'),
  t000_OrganizationObj: new FormGroup({organizationShippingId: new FormControl(0)})});
  }
fetchCurrency(): void {
    this.commonService.fetchCurrency(this.currencyFormGroup.value).
      subscribe(
      data => {
         this.currencyList = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
  }

fetchLocation(): void {
   this.commonService.fetchLocation(this.myForm.value).
      subscribe(
      data => {
        this.businessLocations = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}

fetchLanguage(): void {
   this.commonService.fetchLanguage().
      subscribe(
      data => {
         this.languages = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}
fetchIndustries(): void {
   this.commonService.fetchIndustries().
      subscribe(
      data => {
         this.indutries = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}

fetchFromLookup(lookupObj): void {

   this.commonService.fetchFromLookup(lookupObj.value).
      subscribe(
      data => {
         if (this.commonUtils.checkNullOrUndefined(data)) {
           if (data[0].lookupCode === 'TIME_ZONE') {
             this.timezones = data;
           } else if (data[0].lookupCode === 'FISCAL_YEAR') {
             this.fiscalYears = data;
           } else if (data[0].lookupCode === 'DATE_FORMAT') {
             this.dateFormats = data;
           }
         }

       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}


  /*copyAsDefaultShippingAddress(): void {
  this.t000_Organization_BillingAddressesSet = this.myForm.get('t000_Organization_BillingAddressesSet') as FormArray;
  this.t000_Organization_ShippingAddressesSet = this.myForm.get('t000_Organization_ShippingAddressesSet') as FormArray;
  for (let loop = 0; loop < this.t000_Organization_BillingAddressesSet.length; loop++) {
  this.defaultBillingFlag = this.t000_Organization_BillingAddressesSet.at(loop).get('isDefault').value;
  if (this.defaultBillingFlag === 'Y') {
  for (const insideLoop = 0; insideLoop < this.t000_Organization_ShippingAddressesSet.length; loop++) {
  this.defaultShippingFlag = this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('isDefault').value;
  if (this.defaultShippingFlag === 'Y') {
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingAttention').setValue(this.t000_Organization_BillingAddressesSet.
  at(loop).value.billingAttention);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingAddressLineOne').setValue(this.t000_Organization_BillingAddressesSet.
  at(loop).value.billingAddressLineOne);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingAddressLineTwo').setValue(this.t000_Organization_BillingAddressesSet.
  at(loop).value.billingAddressLineTwo);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('t010ShippingCountry').get('countryId').setValue(
  this.t000_Organization_BillingAddressesSet.at(loop).value.t010BillingCountry.countryId);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingState').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.billingState);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingZipCode').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.billingZipCode);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingCity').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.billingCity);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingPhone').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.billingPhone);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('shippingFax').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.billingFax);
  this.t000_Organization_ShippingAddressesSet.at(insideLoop).get('isDefault').setValue(this.t000_Organization_BillingAddressesSet.at(loop).value.isDefault);


  break;
  }
  }
  }
  }
  }*/
  copyAsDefaultShippingAddress(): void {

         let defaultBillingLocationObj = this.myForm.get('defaultBillingLocationId').value;
         this.myForm.get('defaultShippingLocationId.locationId').setValue(defaultBillingLocationObj.locationId);
         this.myForm.get('defaultShippingLocationId.locationAttention').setValue(defaultBillingLocationObj.locationAttention);
         this.myForm.get('defaultShippingLocationId.locationAddressLineOne').setValue(defaultBillingLocationObj.locationAddressLineOne);
         this.myForm.get('defaultShippingLocationId.locationAddressLineTwo').setValue(defaultBillingLocationObj.locationAddressLineTwo);
         if(this.commonUtils.checkNullOrUndefined(defaultBillingLocationObj.t010locationCountryObj))
         this.myForm.get('defaultShippingLocationId.t010locationCountryObj.countryId').setValue(defaultBillingLocationObj.t010locationCountryObj.countryId);
         this.myForm.get('defaultShippingLocationId.locationState').setValue(defaultBillingLocationObj.locationState);
         this.myForm.get('defaultShippingLocationId.locationZipCode').setValue(defaultBillingLocationObj.locationZipCode);
         this.myForm.get('defaultShippingLocationId.locationCity').setValue(defaultBillingLocationObj.locationCity);
         this.myForm.get('defaultShippingLocationId.locationPhone').setValue(defaultBillingLocationObj.locationPhone);
         this.myForm.get('defaultShippingLocationId.locationFax').setValue(defaultBillingLocationObj.locationFax);
          this.myForm.get('defaultShippingLocationId.gstNumber').setValue(defaultBillingLocationObj.gstNumber);
  }
fetchOrganization(): void 
{


 this.myForm.get('organizationId').setValue(this.organizationId);
 this.organizationObj = this.commonService.loggedInUserObj;
    
 this.settingsService.fetchOrganization(this.myForm.value).subscribe(
    data => {
      
    
        if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)       
        this.myForm.patchValue(data[0]); 
      this.whitelistIPList=data[0].whitelistIP.split(',');
        this.myForm.get('authSecret').setValue(data[0].authSecret);
        this.showExistingImg = true;
        //portalname
        var portalName = this.myForm.get('portalName').value;
        portalName = portalName.toUpperCase();
        this.imageSrc = this.commonService.SERVICE_URL+'erpassets/'+portalName+'/'+ this.myForm.get('logoPath').value;
        this.myForm.controls.imageSrc.setValue(this.imageSrc);
        this.imgElem = document.getElementById('existingImg') as HTMLElement;
        this.imgElem.src = this.imageSrc; 
    
    },
    error => {
         this.commonService.showERPErrorMessage(error);
    });
 }

  showAddressPanel = false;
  additionalFields = false;

  ELEMENT_DATA: Element[] = [];

  displayedColumns = ['label', 'value'];
  dataSource = new MatTableDataSource<Element>(this.ELEMENT_DATA);
  selection = new SelectionModel<Element>(true, []);
 


  showOptions(event): void {
    if (event.checked === true) {
      this.showAddressPanel = true;
    } else if (event.checked === false) {
      this.showAddressPanel = false;
    }
  }

  addAdditionalFields(): void {
    this.additionalFields = true;
    this.ELEMENT_DATA.push({label: '', value: ''});
    this.dataSource = new MatTableDataSource<Element>(this.ELEMENT_DATA);
  //  $('mat-header-row').addClass('dispClass');
  }

 /* confMails(): void {
    const dialogRef = this.dialog.open(ConfigureEmailComponent, {
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
     });
  }*/

fileArray:Array<any>=[];
onFileChange(fileInput) {
    this.logo = fileInput.target.files[0];
    this.imgFile = fileInput.target.files[0];
    const reader = new FileReader();
    reader.onload = (readerResult: any) => {
        this.logo = readerResult.target.result;
     }
    reader.readAsDataURL(fileInput.target.files[0]);
  this.fileArray.push(fileInput.target.files[0]);
 
    if (this.imgFile != null) {
    const body = new FormData();
    body.append('file', this.imgFile);
     this.commonService.uploadFiles(body).
      subscribe(
       (response) => {
          
        this.showExistingImg = false;
        this.myForm.get('imageName').setValue('SAVEIMAGE~`' + this.imgFile.name + '~`' + response);
        this.myForm.get('logoPath').setValue(this.imgFile.name);

       });

      }
}


saveOrUpdateOrganizationProfile( ): void {

   /*****setting user obj in the form****/
this.userObj = this.commonService.loggedInUserObj.userId;


 if (this.myForm.get('organizationId').value === 0 || this.myForm.get('organizationId').value === '' || this.myForm.get('organizationId').value === null) {
this.myForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
}
if (this.myForm.get('organizationId').value != 0 && this.myForm.get('organizationId').value != null) {
this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
}

 this.t100OrganizationBillingFormarray = this.myForm.get('t000_Organization_BillingAddressesSet') as FormArray;
  this.t100OrganizationShippingFormarray = this.myForm.get('t000_Organization_ShippingAddressesSet') as FormArray;

   this.commonService.showProgressBar();
  if (this.myForm.valid) {
    var whitelistIPStr = "";
    for(var loop=0; loop < this.whitelistIPList.length; loop++){
       whitelistIPStr = whitelistIPStr + this.whitelistIPList[loop]+",";
    }
    if(whitelistIPStr != "")
      whitelistIPStr = whitelistIPStr.substring(0,whitelistIPStr.length-1);
    this.myForm.get('whitelistIP').setValue(whitelistIPStr);
    this.settingsService.saveOrUpdateOrganizationProfile(this.myForm.value).subscribe(
    data => {
      
      this.commonService.hideProgressBar();
      this.myForm.patchValue(data);
      this.commonService.loggedInUserObj.t000Organization.logoPath = data.logoPath;
      this.commonService.showERPMessage('Organization profile saved successfully.');
      
      this.setDateFormat(this.myForm.value.t004LookupDateFormatId);
      this.dialogRef.close(true);
      },

    error => 
    {
        this.dialogRef.close(true);
       this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error);
  });
} else {
  this.commonService.showERPMessage('Invalid Form');
}
}

  checkIfEditEligible(): boolean {
   
    if (this.commonService.currentlySelectedMenu != null)
      return this.commonService.currentlySelectedMenu.editAccess;
    else return false;
  }

  setDateFormat(t004LookupDateFormatId):void{
    if(t004LookupDateFormatId != null && t004LookupDateFormatId != undefined &&
       t004LookupDateFormatId.lookupValue != null && t004LookupDateFormatId.lookupValue != undefined && t004LookupDateFormatId.lookupValue != ''){

        Constants.DATE_FMT =  t004LookupDateFormatId.lookupValue;
              
    }else{
        Constants.DATE_FMT =  environment.DATE_FORMAT;
        
    }
  }


errorHandler(event) {
    event.target.src = this.errorimageSourcePath;
}


 add(event: MatChipInputEvent, mailFor : String): void {
    const input = event.input;
    const value = event.value;
  if(mailFor === 'TO'){
      if ((value || '').trim()) {
        this.whitelistIPList.push(value.trim());
      }
  } 
  if (input) {
      input.value = '';
  }
}


remove(email: any, mailFor : String): void {
  
 if(mailFor === 'TO'){
   const index = this.whitelistIPList.indexOf(email);
    if (index >= 0) {
      this.whitelistIPList.splice(index, 1);
    }
  } 

}
onNoClick()
{
 this.dialogRef.close(true); 
}
setStep(index: number) {
  this.step = index;
  }

} 



export interface Element {
  label: string;
  value: string;
}
