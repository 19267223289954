<mat-card class="col-lg-12 history" style="height: 100%;">
  <mat-card-title style="padding: 0px;">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">      
     <mat-icon>description</mat-icon> Interview Allotment  </div>
  </mat-card-title>

<mat-card-content >
    <form   [formGroup]="myForm">
          <div class="col-lg-12 row"  style="padding-bottom: 15px;padding-top: 10px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">      
              <b> Candidate : </b>{{candidateObj.candidateCode}} <span *ngIf="this.commonUtils.checkNullOrUndefined(candidateObj.firstName)"> - {{candidateObj.firstName}} </span><span *ngIf="this.commonUtils.checkNullOrUndefined(candidateObj.lastName)"> {{candidateObj.lastName}}</span></div>
              
          </div>
          <div class="col-lg-12 row"  >
            <mat-form-field  class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="t004_CandidateStatusObj">
              <mat-select placeholder="Candidate Status" formControlName="lookupId">
              <mat-option *ngFor="let lookupObj of candidateStatusList" [value]="lookupObj.lookupId" (click)="onChangeOfStatus(lookupObj,lookupObj.lookupValue)">
                {{lookupObj.lookupValue}}
              </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field  class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="jobApplication">
              <mat-select placeholder="Job Name" formControlName="jobId">
                <mat-option *ngFor="let jobObj of jobList" [value]="jobObj.jobId">
                {{jobObj.jobCode}}-{{jobObj.title}}
                </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field  class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="t843_InterviewAppointmentObj" *ngIf="displayflag">
              <mat-select placeholder="Interview Appointment" formControlName="appointmentId">
                <mat-option *ngFor="let interviewObj of interviewAppointmentList" [value]="interviewObj.appointmentId" (click)="onChangeOfInterviewAppointment(interviewObj)">
                {{interviewObj.interviewName}}
                </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
           <div class="col-lg-12 row" *ngIf="remarksflag" >
              <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                <textarea  matInput placeholder="Remarks" formControlName="remarks" name="remarks" onfocus="this.select()"></textarea>
              </mat-form-field>
          </div>
          <div class="col-lg-12 alignCenter buttons" style="margin-top: 1%;">
              <button mat-raised-button color="primary" class="mat-red" [disabled]="((!myForm.valid)||(disableButton))" (click)="saveOrUpdateAppointmentForcandidate()">{{SAVE_BUTTON}}</button>
              <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
          </div>
    </form>  
</mat-card-content>
</mat-card>
          
