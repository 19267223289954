
import {throwError as observableThrowError, Observable,Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Response, Headers} from '@angular/http';
import {HttpClient } from '@angular/common/http';
import {CommonService} from '../shared/common.service';
import {map,catchError } from 'rxjs/operators';
@Injectable() 
export class ProjectService {

	contactObjFromEdit: any;
	filterChangeObj = new Subject<Array<any>>();
	selectedCurrencyObj:any;
	constructor(private http: HttpClient, private commonService: CommonService) {}

	saveProject(projectObj) :Observable<any>{
	    return this.http.post(this.commonService.SERVICE_URL + 'hrm/saveProject', projectObj)
	      .pipe(map(data => data)
	      ,catchError(this.handleError));
	}

    saveTask(taskList) :Observable<any>{
	    return this.http.post(this.commonService.SERVICE_URL + 'hrm/saveProjectPlan', taskList)
	      .pipe(map(data => data)
	      ,catchError(this.handleError));
    }

 	downloadExportData(dataObj):Observable<any>
 	{
		return this.http.post(this.commonService.SERVICE_URL + '/contacts/downloadReport', dataObj,{responseType: 'text'})
			.pipe(map(data => data.valueOf())
			,catchError(this.handleError));
	}
	private handleError(error: any):Observable<any> {
		console.log(error);
		return observableThrowError(error);
	}

	saveOrUpdateTimeSheet(timesheetObj) :Observable<any>{
	    return this.http.post(this.commonService.SERVICE_URL + 'hrm/saveTimeSheet', timesheetObj)
	      .pipe(map(data => data)
	      ,catchError(this.handleError));
	}
	fetchProjectNumberStatus(projectObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'hrm/fetchProjectNumberStatus', projectObj)
	    .pipe(map(data => data),
	    catchError(this.handleError));
  	}
}