import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ERPFormBase } from '../base/erp-form-base';
import {SharedModule} from '../../../shared.module';
import {CommonService} from '../../../shared/common.service';

@Component({
  selector: 'app-elements',
  templateUrl: './dynamic-form-elements.component.html'
})

export class DynamicFormQuestionComponent implements OnInit {

 @Input() question: ERPFormBase<any>;
 @Input() form: FormGroup;

	constructor(private commonService: CommonService
	  // DO NOT DELETE THE BELOW LINE IN CODE REVIEW
	// private qcs: FormControlService,
	) {}

  ngOnInit() {

  }

  focusOutCustomFields(){
  	this.commonService.refreshClickedSubject.next({action:'CALL_FOCUS_OUT'});
  }

}
