import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl,FormGroup} from '@angular/forms';
import {CommonService} from '../shared/common.service';
import {ImportPODialog} from '../import/importpo.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './importContacts.component.html',
  styleUrls: ['./importpo.component.css']
})

export class ImportContactsDialog implements OnInit {

  dialogTextToDisplay: string;
  contactText: string;
  buttonDisabled = false;
  radioToggleValue: string;  
  searchModuleValue;moduleName: string;
   moduleType: string;
   searchModule = ['Items', 'Contacts', 'Invoices', 'Estimates','Assets', 'Retainer Invoices', 'Sales Orders', 'Credit Notes', 'Expenses',
  'Bills', 'Vendor Credits', 'Purchase Orders', 'Payments Received', 'Payments Made', 'Recurring Invoices',
  'Recurring Bills', 'Recurring Expenses', 'Employees','Paycode','Employee Earnings','Inventory Adjustments', 
  'Sales Line Item Detail','Purchases Line Item Detail','Price List Item Level','Items For Delete','Nistar Items'];

  constructor(private router: Router, public dialog: MatDialog,private commonService: CommonService,
    public dialogRef: MatDialogRef<ImportContactsDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogTextToDisplay = data.labelText;
    this.moduleName=data.dialogName;
    this.moduleType=data.dialogType;
    if (this.dialogTextToDisplay === 'Contacts/ImportCustomers') {
      this.contactText = 'Customers';
    } else if (this.dialogTextToDisplay === 'Contacts/ImportVendors') {
      this.contactText = 'Vendors';
    }
    else if(this.dialogTextToDisplay === 'ImportEmployee')
      this.contactText = 'Employee';
    else if(this.dialogTextToDisplay === 'ImportAssets')
      this.contactText = 'Assets';
    else if(this.dialogTextToDisplay === 'ImportEarnings')
     this.contactText = 'Employee Earnings';
    else if(this.dialogTextToDisplay === 'ImportItems')
      this.contactText = 'Items';
    else if(this.dialogTextToDisplay === 'ImportContacts')
      this.contactText = 'Contacts';
    else if(this.dialogTextToDisplay === 'ImportInventoryAdjustments')
      this.contactText = 'Inventory Adjustments';
    else if(this.dialogTextToDisplay === 'ImportItemsForDelete')
      this.contactText = 'Items For Delete';
    else if(this.dialogTextToDisplay === 'ImportNistarItems')
      this.contactText = 'Nistar Items';
  }

  radioToggle(): void 
  {
    if (this.radioToggleValue != undefined && this.radioToggleValue != 'undefined' && this.radioToggleValue != '') {
      this.buttonDisabled = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {        
    if (this.moduleName === 'VIEW_CONTACT'){        
        this.searchModuleValue = 'Contacts';
      }              
      else if (this.moduleName === 'VIEW_PO')         
        this.searchModuleValue = 'Purchase Orders';      
      else if (this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS')         
        this.searchModuleValue = 'Items';
      else if (this.moduleName === 'VIEW_SALESORDER')        
        this.searchModuleValue = 'Sales Orders';
      else if (this.moduleName === 'VIEW_BILL')         
        this.searchModuleValue = 'Bills';
      else if (this.moduleName === 'VIEW_R_BILL')        
        this.searchModuleValue = 'Recurring Bills';
      else if (this.moduleName === 'VIEW_ESTIMATE')        
        this.searchModuleValue = 'Estimates';      
      else if (this.moduleName === 'VIEW_EXPENSE')         
        this.searchModuleValue = 'Expenses';      
      else if (this.moduleName === 'VIEW_PAYMENT_MADE')        
        this.searchModuleValue = 'Payments Made';
      else if (this.moduleName === 'VIEW_VENDOR_CREDIT')       
        this.searchModuleValue = 'Vendor Credits';
      else if (this.moduleName === 'VIEW_INVOICE')       
        this.searchModuleValue = 'Invoices';
      else if (this.moduleName === 'Retainer Invoices')        
        this.searchModuleValue = 'Retainer Invoices';
      else if (this.moduleName === 'VIEW_R_INVOICE')        
        this.searchModuleValue = 'Recurring Invoices';
      else if (this.moduleName === 'VIEW_PAYMENT_RECIEVED')        
        this.searchModuleValue = 'Payments Received';
      else if (this.moduleName === 'VIEW_CREDIT_NOTES')        
        this.searchModuleValue = 'Credit Notes';
      else if (this.moduleName === 'VIEW_EMPLOYEE')       
        this.searchModuleValue = 'Employees';  
      else if(this.moduleName === 'VIEW_PAYCODE')
        this.searchModuleValue = 'Paycode';      
      else if (this.moduleName === 'VIEW_ASSETS')       
        this.searchModuleValue = 'Assets';
      else if(this.moduleName === 'VIEW_EMPLOYEEEARNINGS')
        this.searchModuleValue = 'Employee Earnings';
      else if(this.moduleName === 'VIEW_ITEM')
        this.searchModuleValue = 'Items';
      else if(this.moduleName === 'VIEW_INVENTORY_ADJUSTMENT')
        this.searchModuleValue = 'Inventory Adjustments';
      else if(this.moduleName === 'SALES_DETAIL_IMPORT')
        this.searchModuleValue = 'Sales Line Item Detail';
      else if(this.moduleName === 'PURCHASES_DETAIL_IMPORT')
        this.searchModuleValue = 'Purchases Line Item Detail';
      else if(this.moduleName === 'PRICE_LIST')
        this.searchModuleValue = 'Price List Item Level';
      else if(this.moduleName === 'VIEW_PRODUCT_SETUP' &&  this.moduleType==="BULK_IMPORT_DELETE")//Bulk import for delete Items - NistarMarketing
        this.searchModuleValue = 'Items For Delete';
      else if(this.moduleName === 'VIEW_PRODUCT_SETUP' && this.moduleType === 'BULK_IMPORT_ITEM')//Bulk import for Items - NistarMarketing
        this.searchModuleValue = 'Nistar Items';
  }

  continueClick(): void {
    //this.dialogRef.close();
      const dialogRef = this.dialog.open(ImportPODialog, {
      width: '800px',
      data: {labelText: this.searchModuleValue}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.length>0){
        this.dialogRef.close(result);
      }else if(result.done===true){
        this.dialogRef.close(result);
      }
      else{
        this.dialogRef.close(true);
      }
    });
  }
searchModuleClick(){
     this.searchModuleValue = 'ContactsPerson';
 }  
}