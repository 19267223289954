 
    
      <div  mat-dialog-title style="font-size: 18px">Neuzen - Message</div>
<div class="col-lg-12"></div>
      <div class="col-lg-1">
       <mat-icon style="font-size: 35px;color: crimson;">warning</mat-icon>
      </div>
      
      <div class="col-lg-11">
       <mat-expansion-panel class="col-lg-12" style="background-color: white;
       box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(249, 249, 249, 0.14), 0 1px 5px 0 rgba(247, 245, 245, 0)" >
         <mat-expansion-panel-header style="background-color: white" >
           
      
              <mat-panel-title>
                 {{dialogText}}
              </mat-panel-title>
              <mat-panel-description style="font-size: 11px">
                More info
              </mat-panel-description>
   </mat-expansion-panel-header >
    <div class="col-lg-12" *ngIf="this.commonUtils.checkNullOrUndefined(dialogDetailText) && dialogDetailText!='' && dialogDetailText.length>100"style="max-height: 50px;overflow-y: scroll;">
        {{dialogDetailText}}
        <br>
        <p *ngIf="!dialogSupport"> An Error log has been sent to our support team. We will look into this and revert back. You will be notified once this error is resolved. </p>
      </div>
       <div class="col-lg-12" *ngIf="this.commonUtils.checkNullOrUndefined(dialogDetailText) && dialogDetailText!='' && dialogDetailText.length<=100">
        {{dialogDetailText}}
        <br>
        <p *ngIf="!dialogSupport"> An Error log has been sent to our support team. We will look into this and revert back. You will be notified once this error is resolved. </p>
      </div>
       </mat-expansion-panel>
        </div>
     
       <div class="col-lg-12"   style="padding-top: 5px" >
        <div class="col-lg-5"></div>
          <button class="col-lg-2" mat-raised-button type="submit" color="primary" (click)="onOKClick()">OK</button>
           <div class="col-lg-5"></div>
           
        </div>
   