import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[noAndDotOnly]'
})

export class NumberAndDotOnlyDirective {
  /*Allow decimal numbers and negative values - private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);*/
  private regex: RegExp = new RegExp(/^[0-9]*\.?[0-9]*$/);

  // Allow key codes for special events
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) != -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
