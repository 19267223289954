<mat-card class="col-lg-12 history" style="height: 100%;">
  <mat-card-title style="padding: 0px">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding: 0px" >      
    <mat-icon>feedback</mat-icon> Interview Feedback  </div>
  </mat-card-title>
<mat-card-content >
  <!-- <form   [formGroup]="myForm"> -->
    <div class="row" style="padding-bottom: 15px;padding-left: 5px;">
       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">      
                  <b> Candidate : </b> {{newInterviewRoundObj.candidateCode}} <span *ngIf="this.commonUtils.checkNullOrUndefined(newInterviewRoundObj.firstName)"> - {{newInterviewRoundObj.firstName}} </span><span *ngIf="this.commonUtils.checkNullOrUndefined(newInterviewRoundObj.lastName)"> {{newInterviewRoundObj.lastName}}</span></div>
    </div>
    <div class="tab-control">
        <mat-tab-group animationDuration="0ms" [selectedIndex]="activeRoundIndex">
            <mat-tab label="First Round">
               <form   [formGroup]="myFirstRoundForm">
              <div class="col-lg-12 row" style="margin-top: 1%;" >
                <div class="col-lg-12" >      
                  <b> Interview Type : </b> {{myFirstRoundForm.get('interviewType').value}}
                </div>
               <!--  <mat-radio-group class=" row col-lg-10" disabled formControlName="interviewType" >
                  <mat-radio-button  color="primary"  value="One on One"  style="padding: 5px;">One on One</mat-radio-button><br>
                  <mat-radio-button color="primary"  value="Panel" style="padding: 5px;">Panel</mat-radio-button><br>
                </mat-radio-group> -->
              </div>    
              <div *ngIf="myFirstRoundForm.get('interviewType').value != 'Panel'">                              
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding-top: 10px;">      
                  <b> Interviewer Name : </b>{{myFirstRoundForm.get('interviewer1_Name').value}} 
                </div>
              
                <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                  <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status"> 
                    <mat-select placeholder="Feedback Status" formControlName="lookupId" [disabled]="(myFirstRoundForm.get('interviewer1Flag').value === true)">
                    <mat-option *ngFor="let lookupObj of firstRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                      {{lookupObj.lookupValue}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                  <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                  <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                    <textarea  matInput placeholder="Comments" [readonly]="(myFirstRoundForm.get('interviewer1Flag').value === true)" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 row mb-mr-0 mb-pd-0" *ngIf="myFirstRoundForm.get('interviewType').value === 'Panel'">
                <mat-tab-group animationDuration="0ms" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" [selectedIndex]="activeInterviewerIndex">
                  <mat-tab label="Interviewer 1">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding-top: 10px;" >      
                        <b> Interviewer Name : </b>{{myFirstRoundForm.get('interviewer1_Name').value}} 
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                          <mat-select placeholder="Feedback Status" formControlName="lookupId" [disabled]="(myFirstRoundForm.get('interviewer1Flag').value === true)">
                          <mat-option *ngFor="let lookupObj of firstRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>      
                       <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myFirstRoundForm.get('interviewer1Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>       
                  </mat-tab>
                  <mat-tab label="Interviewer 2" *ngIf="this.commonUtils.checkNullOrUndefined(myFirstRoundForm.get('interviewer2_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : {{myFirstRoundForm.get('interviewer2_Name').value}} </b>
                      </div>
                    
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer2_Status">
                          <mat-select  [disabled]="(myFirstRoundForm.get('interviewer2Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of firstRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                        <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myFirstRoundForm.get('interviewer2Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer2_Comments" name="interviewer2_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>
                  <mat-tab label="Interviewer 3" *ngIf="this.commonUtils.checkNullOrUndefined(myFirstRoundForm.get('interviewer3_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : {{myFirstRoundForm.get('interviewer3_Name').value}} </b>
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer3_Status">
                          <mat-select  [disabled]="(myFirstRoundForm.get('interviewer3Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of firstRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                       <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea   [readonly]="(myFirstRoundForm.get('interviewer3Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer3_Comments" name="interviewer3_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>                   
                </mat-tab-group>     
              </div>
             <div class="col-lg-12 alignCenter buttons">
       
                 <button mat-raised-button color="primary" class="mat-red" (click)="saveOrUpdateFeedbackForcandidate('First_Round')" *ngIf="firstRoundFlag" [disabled]="disableSave" >{{SAVE_BUTTON}}</button>
                  <button mat-raised-button color="primary" (click)="cancelClick()" >Close</button>                     
              </div>  
            </form>
            </mat-tab>
            <mat-tab label="Second Round">
               <form   [formGroup]="mySecondRoundForm">
              <div class="col-lg-12 row" style="margin-top: 1%;" >
               <div class="col-lg-12" >      
                  <b> Interview Type : </b> {{mySecondRoundForm.get('interviewType').value}}
                </div>
               <!--  <mat-radio-group class=" row col-lg-10" disabled formControlName="interviewType" >
                  <mat-radio-button  color="primary"  value="One on One"  style="padding: 5px;">One on One</mat-radio-button><br>
                  <mat-radio-button color="primary"  value="Panel"  style="padding: 5px;">Panel</mat-radio-button><br>
                </mat-radio-group> -->
              </div>    
               <div *ngIf="mySecondRoundForm.get('interviewType').value != 'Panel'">                           
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                  <b> Interviewer Name : </b>{{mySecondRoundForm.get('interviewer1_Name').value}} 
                </div>
              
                <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                  <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                    <mat-select placeholder="Feedback Status" formControlName="lookupId"  [disabled]="(mySecondRoundForm.get('interviewer1Flag').value === true)">
                    <mat-option *ngFor="let lookupObj of secondRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                      {{lookupObj.lookupValue}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                  <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                  <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                    <textarea  [readonly]="(mySecondRoundForm.get('interviewer1Flag').value === true)"  matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 row mb-mr-0 mb-pd-0" *ngIf="mySecondRoundForm.get('interviewType').value === 'Panel'">
                <mat-tab-group animationDuration="0ms" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" [selectedIndex]="activeInterviewerIndex">
                  <mat-tab label="Interviewer 1">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding-top: 10px;">      
                        <b> Interviewer Name : </b>{{mySecondRoundForm.get('interviewer1_Name').value}}
                        <br> 
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                          <mat-select placeholder="Feedback Status" formControlName="lookupId" [disabled]="(mySecondRoundForm.get('interviewer1Flag').value === true)">
                          <mat-option *ngFor="let lookupObj of secondRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>  
                       <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  matInput placeholder="Comments" formControlName="interviewer1_Comments" [readonly]="(mySecondRoundForm.get('interviewer1Flag').value === true)" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>           
                  </mat-tab>
                  <mat-tab label="Interviewer 2" *ngIf="this.commonUtils.checkNullOrUndefined(mySecondRoundForm.get('interviewer2_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding-top: 10px;" >      
                        <b> Interviewer Name : </b>{{mySecondRoundForm.get('interviewer2_Name').value}} 
                      </div>
                    
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer2_Status">
                          <mat-select placeholder="Feedback Status" formControlName="lookupId" [disabled]="(mySecondRoundForm.get('interviewer2Flag').value === true)">
                          <mat-option *ngFor="let lookupObj of secondRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                        <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  matInput placeholder="Comments" formControlName="interviewer2_Comments" [readonly]="(mySecondRoundForm.get('interviewer2Flag').value === true)" name="interviewer2_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>
                  <mat-tab label="Interviewer 3" *ngIf="this.commonUtils.checkNullOrUndefined(mySecondRoundForm.get('interviewer3_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" style="padding-top: 10px;">      
                        <b> Interviewer Name : </b> {{mySecondRoundForm.get('interviewer3_Name').value}}  
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer3_Status">
                          <mat-select placeholder="Feedback Status" formControlName="lookupId" [disabled]="(mySecondRoundForm.get('interviewer3Flag').value === true)" >
                          <mat-option *ngFor="let lookupObj of secondRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                       <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  matInput placeholder="Comments" formControlName="interviewer3_Comments" [readonly]="(mySecondRoundForm.get('interviewer3Flag').value === true)" name="interviewer3_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>                   
                </mat-tab-group>     
              </div>
             <div class="col-lg-12 alignCenter buttons">
                 <button mat-raised-button color="primary" class="mat-red" (click)="saveOrUpdateFeedbackForcandidate('Second_Round')" *ngIf="secondRoundFlag" [disabled]="disableSave" >{{SAVE_BUTTON}}</button>
                  <button mat-raised-button color="primary" (click)="cancelClick()" >Close</button>                     
              </div> 
            </form>
            </mat-tab>
            <mat-tab label="Third Round">
               <form   [formGroup]="myThirdRoundForm">
              <div class="col-lg-12 row" style="margin-top: 1%;" >
                 <div class="col-lg-12" >      
                  <b> Interview Type : </b> {{myThirdRoundForm.get('interviewType').value}}
                </div>
                <!-- <mat-radio-group class=" row col-lg-10" disabled formControlName="interviewType" >
                  <mat-radio-button  color="primary"  value="One on One"  style="padding: 5px;">One on One</mat-radio-button><br>
                  <mat-radio-button color="primary"  value="Panel"  style="padding: 5px;">Panel</mat-radio-button><br>
                </mat-radio-group> -->
              </div>    
               <div *ngIf="myThirdRoundForm.get('interviewType').value != 'Panel'">                             
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                  <b> Interviewer Name : </b> {{myThirdRoundForm.get('interviewer1_Name').value}}  
                </div>
               
                <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                  <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                    <mat-select placeholder="Feedback Status" formControlName="lookupId"  [disabled]="(myThirdRoundForm.get('interviewer1Flag').value === true)"  >
                    <mat-option *ngFor="let lookupObj of thirdRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                      {{lookupObj.lookupValue}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                 <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                  <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                    <textarea  [readonly]="(myThirdRoundForm.get('interviewer1Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 row mb-mr-0 mb-pd-0" *ngIf="myThirdRoundForm.get('interviewType').value === 'Panel'">
                <mat-tab-group animationDuration="0ms" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" [selectedIndex]="activeInterviewerIndex">
                  <mat-tab label="Interviewer 1">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myThirdRoundForm.get('interviewer1_Name').value}}
                      </div>
                      
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                          <mat-select  [disabled]="(myThirdRoundForm.get('interviewer1Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of thirdRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>  
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myThirdRoundForm.get('interviewer1Flag').value === true)"  matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>           
                  </mat-tab>
                  <mat-tab label="Interviewer 2" *ngIf="this.commonUtils.checkNullOrUndefined(myThirdRoundForm.get('interviewer2_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myThirdRoundForm.get('interviewer2_Name').value}} 
                      </div>
                      
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer2_Status">
                          <mat-select  [disabled]="(myThirdRoundForm.get('interviewer2Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of thirdRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myThirdRoundForm.get('interviewer2Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer2_Comments" name="interviewer2_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>
                  <mat-tab label="Interviewer 3" *ngIf="this.commonUtils.checkNullOrUndefined(myThirdRoundForm.get('interviewer3_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myThirdRoundForm.get('interviewer3_Name').value}}  
                      </div>
                    
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer3_Status">
                          <mat-select placeholder="Feedback Status" formControlName="lookupId"  [disabled]="(myThirdRoundForm.get('interviewer3Flag').value === true)" >
                          <mat-option *ngFor="let lookupObj of thirdRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickStatusIssue(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                   <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myThirdRoundForm.get('interviewer3Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer3_Comments" name="interviewer3_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>                   
                </mat-tab-group>     
              </div>
             <div class="col-lg-12 alignCenter buttons">
                 <button mat-raised-button color="primary" class="mat-red" (click)="saveOrUpdateFeedbackForcandidate('Third_Round')"  *ngIf="thirdRoundFlag" [disabled]="disableSave" >{{SAVE_BUTTON}}</button>
                  <button mat-raised-button color="primary" (click)="cancelClick()" >Close</button>                     
              </div> 
            </form>
            </mat-tab>
            <mat-tab label="HR Round">
               <form   [formGroup]="myHrRoundForm">
              <div class="col-lg-12 row" style="margin-top: 1%;" >
                <div class="col-lg-12" >      
                  <b> Interview Type : </b> {{myHrRoundForm.get('interviewType').value}}
                </div>
                 <!--  <div class="col-lg-2" >      
                  <b> Interview Type : </b>
                </div> -->
                <!-- <mat-radio-group class=" row col-lg-10" disabled formControlName="interviewType" >
                  <mat-radio-button  color="primary"  value="One on One"  style="padding: 5px;">One on One</mat-radio-button><br>
                  <mat-radio-button color="primary"  value="Panel"  style="padding: 5px;">Panel</mat-radio-button><br>
                </mat-radio-group> -->
              </div>    
               <div *ngIf="myHrRoundForm.get('interviewType').value != 'Panel'">                           
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">       
                  <b> Interviewer Name : </b> {{myHrRoundForm.get('interviewer1_Name').value}}  
                </div>
               
                <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                  <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                    <mat-select [disabled]="(myHrRoundForm.get('interviewer1Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                    <mat-option *ngFor="let lookupObj of hrRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickHRStatus(lookupObj)">
                      {{lookupObj.lookupValue}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                 <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                  <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                    <textarea [readonly]="(myHrRoundForm.get('interviewer1Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 row mb-mr-0 mb-pd-0" *ngIf="myHrRoundForm.get('interviewType').value === 'Panel'">
                <mat-tab-group animationDuration="0ms" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" [selectedIndex]="activeInterviewerIndex">
                  <mat-tab label="Interviewer 1">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myHrRoundForm.get('interviewer1_Name').value}} 
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer1_Status">
                          <mat-select [disabled]="(myHrRoundForm.get('interviewer1Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of hrRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickHRStatus(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                       <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea  [readonly]="(myHrRoundForm.get('interviewer1Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer1_Comments" name="interviewer1_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>          
                  </mat-tab>
                  <mat-tab label="Interviewer 2" *ngIf="this.commonUtils.checkNullOrUndefined(myHrRoundForm.get('interviewer2_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myHrRoundForm.get('interviewer2_Name').value}} 
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer2_Status">
                          <mat-select [disabled]="(myHrRoundForm.get('interviewer2Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of hrRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickHRStatus(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0">
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea [readonly]="(myHrRoundForm.get('interviewer2Flag').value === true)" matInput placeholder="Comments" formControlName="interviewer2_Comments" name="interviewer2_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>
                  <mat-tab label="Interviewer 3" *ngIf="this.commonUtils.checkNullOrUndefined(myHrRoundForm.get('interviewer3_Name').value)">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0"  style="padding-top: 10px;">        
                        <b> Interviewer Name : </b> {{myHrRoundForm.get('interviewer3_Name').value}} 
                      </div>
                     
                      <div class="col-lg-12 row mb-mr-0 mb-pd-0" style="padding-top: 10px;">
                         <mat-form-field  class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" formGroupName="interviewer3_Status">
                          <mat-select [disabled]="(myHrRoundForm.get('interviewer3Flag').value === true)" placeholder="Feedback Status" formControlName="lookupId" >
                          <mat-option *ngFor="let lookupObj of hrRoundStatusList" [value]="lookupObj.lookupId" (click)="onClickHRStatus(lookupObj)">
                            {{lookupObj.lookupValue}}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>   
                     <div class="col-lg-12 row mb-mr-0 mb-pd-0" >
                        <mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
                          <textarea [readonly]="(myHrRoundForm.get('interviewer3Flag').value === true)"  matInput placeholder="Comments" formControlName="interviewer3_Comments" name="interviewer3_Comments" onfocus="this.select()"></textarea>                          
                        </mat-form-field>
                      </div>
                  </mat-tab>                   
                </mat-tab-group>     
              </div>
             <div class="col-lg-12 alignCenter buttons">
                 <button mat-raised-button color="primary" class="mat-red" (click)="saveOrUpdateFeedbackForcandidate('HR_Round')" *ngIf="hrRoundFlag" [disabled]="disableSave" >{{SAVE_BUTTON}}</button>
                  <button mat-raised-button color="primary" (click)="cancelClick()" >Close</button>                     
              </div> 
            </form>
            </mat-tab>
        </mat-tab-group>    
    
</div>
<!-- </form> -->
</mat-card-content>
</mat-card>
          
