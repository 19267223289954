import { Component, OnInit,Inject, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
import {Subscription,Observable} from 'rxjs/Rx';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-createMenu',
  templateUrl: './createMenu.component.html' 
})
export class CreateMenuComponent extends CommonUtils  {
myForm;
parentMenuList = [];
menuObj;
schemaName;
 moduleName: string = environment.NEW_MENU;

 constructor (public dialogRef: MatDialogRef<CreateMenuComponent>,private commonService: CommonService, private commonUtils: CommonUtils,
  @Inject(MAT_DIALOG_DATA) public data: any){
 	 super();
 this.menuObj = data.menuObj;
 this.schemaName=data.schemaName;

 	this.myForm = new FormGroup
({
  	menuId: new FormControl(''),
	menuName: new FormControl(''),
	menuorder:new FormControl(''),
	menuLevel:new FormControl(''),
	routerurl:new FormControl(''), 
	addurl:new FormControl(''),
	moduleName:new FormControl(''),
	status : new FormControl(false),
	iconName:new FormControl(''),
  license:new FormControl(''),
	parentMenuObj: new FormGroup({menuId: new FormControl(0)})
});
 }
 cancelClick(): void {
  this.dialogRef.close();
}

  ngOnInit() 
  {
    this.pageLoad();
  }

  pageLoad(){
    if(this.commonUtils.checkNullOrUndefined(this.menuObj) && this.menuObj.menuId != 0  ){
      this.myForm.patchValue(this.menuObj);
  }
  	this.fetchParentId();
  }

 fetchParentId(): void {
    this.commonService.fetchSuperadminMenu(this.myForm.get('parentMenuObj').value).
      subscribe(
       data => {
         this.parentMenuList = data;
        
       },
         error => {
         this.commonService.showERPErrorMessage(error);
       });
     }


saveOrUpdateSuperadminMenu(): void {
   this.commonService.showProgressBar();
   if(this.commonUtils.checkNullOrUndefined(this.schemaName))
    {
     this.myForm.get('license').setValue(this.schemaName);
    }
    else
    {
      this.myForm.get('license').setValue('esaleserp');
    }
    this.commonService.saveOrUpdateSuperadminMenu(this.myForm.value).
    subscribe(data => {
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage(data.erpmessage);
      },
      error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
     });

   this.dialogRef.close(true);

   }

}


