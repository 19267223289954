<mat-card   style="height: 100%;">
    <form  [formGroup]="myForm"> 
    <mat-card-title   class="mat-card-title cardheadertitle"  >
      <mat-icon>alarm_on</mat-icon>Rescheduler
      <!-- <mat-slide-toggle class="example-margin" style="float:right;font-size: 12px" color="primary"  checked="false" (change)="notesFlagStatus($event)" >Notes</mat-slide-toggle>  -->
    </mat-card-title> 
    <mat-card-content style="margin-top: 2%;">
      <mat-divider></mat-divider>
        <div  > 
          <div class="col-lg-12 row mb-mr-0 mb-pd-0 nopadding"  style="padding-top: 10px !important;">
            <mat-form-field class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-pd-5 nopadding" >
              <input matInput [matDatepicker]="rescheduleDate" placeholder="{{RESCHEDULE_DATE}}" dateFormatter  formControlName="rescheduleDate">
              <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="rescheduleDate"></mat-datepicker-toggle>
              <mat-datepicker #rescheduleDate></mat-datepicker>   
            </mat-form-field>
            <mat-form-field class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input matInput [ngxTimepicker]="rescheduleTime" [format]=24 formControlName="rescheduleTime"  placeholder="{{RESCHEDULE_TIME}}">
              <ngx-material-timepicker #rescheduleTime ></ngx-material-timepicker>    
         </mat-form-field>
         <!-- <mat-form-field class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <input matInput [ngxTimepicker]="endTime"   placeholder="{{END_TIME}}">
          <ngx-material-timepicker #endTime ></ngx-material-timepicker>    
          </mat-form-field> -->
  
          </div>
          <div   style="margin-top: 2%;">
            <mat-form-field  style="width: 100%" >

              <textarea matInput formControlName="reason" placeholder="{{RESCHEDULE_REASON}}" maxlength="200" onfocus="this.select()"></textarea>
            </mat-form-field>
          </div>
          <div  align="center">
            <button mat-raised-button type="button" color="primary" class="mat-red" (click)="addRescheduler()" >{{SAVE_BUTTON}}</button>
          </div>
        </div>
    </mat-card-content>
  </form>
  </mat-card>