import { Component,OnInit, Input ,Inject,Output} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
/*import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';*/
@Component({
  selector: 'erp-filter-overlay',
  templateUrl: './erp-filter.component.html'
   
})
export class ErpFilterComponent  implements OnInit{ 

filterArray;
selectedLookupObj=[];

@Output() emitterObj:EventEmitter<any>=new EventEmitter<any>();
  constructor(
      public dialogRef: ErpOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
         
    this.filterArray=data.filterArray;
   
  }
  ngOnInit() 
   {
     this.selectedLookupObj=[];
     
     
   }
selectedObj;
  clickedLookupObj(element,selectedLookupObj){
    const index: number = this.selectedLookupObj.indexOf(selectedLookupObj);
     
     if(element.checked==true){
       if(selectedLookupObj.lookupValue==='ALL' || selectedLookupObj.lookupValue==='All'){
         
         
         
         for(var loop=1;loop<this.filterArray.length;loop++){
           this.filterArray[loop].statusFlag=true;
           this.selectedLookupObj.push(this.filterArray[loop]);
         }
       }
       else
         this.selectedLookupObj.push(selectedLookupObj);
     }
     else if(element.checked==false){
      if(selectedLookupObj.lookupValue==='ALL' || selectedLookupObj.lookupValue==='All'){
        this.selectedLookupObj=[];
         for(var loop=1;loop<this.filterArray.length;loop++){
           this.filterArray[loop].statusFlag=false;
         }
       }
       else{
         this.selectedLookupObj.splice(index,1);
       }
     }
     
  
  
   	
  }
  defaultLookupObj(){
    this.dialogRef.dialogClose({data:this.selectedLookupObj,flag:true});
  }
  doneClicked(){
    for(var loop=1;loop<this.filterArray.length;loop++){
           this.filterArray[loop].statusFlag=false;
         }
    this.dialogRef.dialogClose({data:this.selectedLookupObj,flag:false});
  }
   /*drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumnsOriginal, event.previousIndex, event.currentIndex);
  }*/

    }
