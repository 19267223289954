import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HrmService } from '../../hrm/hrm-service';
import { CommonService } from '../../shared/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonUtils } from '../../core/common-utils';
import { environment } from '../../../environments/environment';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-addresource',
  templateUrl: './add-resource-extension.component.html'

})
export class AddResourceExtensionComponent extends CommonUtils implements OnInit {
  projectIdSelected;
  projectList;
  pageSize;
  currentlySelectedMenu;
  orgUsersList;
  selectedProjectObj;
  moduleName: string = environment.ADD_RESOURCE_EXTENSION;
  allResourceExtensionList;
  resourceExtensionForm: FormGroup;
  resourceExtensionError = { error: 'Resource already extended for this project' };
  selectedProjectResourceList: any;
  constructor(private hrmService: HrmService, public snackBar: MatSnackBar, private router: Router,
    private ngxTimepicker: NgxMaterialTimepickerModule,
    private commonService: CommonService, private commonUtils: CommonUtils,
    public dialogRef: MatDialogRef<AddResourceExtensionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.projectList = data.projectList;
    this.orgUsersList = data.userList;
    this.projectIdSelected = data.selectedProject;

    //this.allResourceExtensionList = data.allResourceExtensionList;
    //this.fetchAllResourceExtensionProjectWise();
    this.pageSize = data.pageSize;
    this.resourceExtensionForm = new FormGroup({
      projectValue: new FormControl(''),
      extensionId: new FormControl(0),
      t001_UserObj: new FormGroup({ userId: new FormControl(0) }),
      t822ProjectObj: new FormGroup({ projectId: new FormControl(0) }),
      t001_UserCreatedByObj: new FormGroup({ userId: new FormControl(this.commonService.loggedInUserObj.userId) }),
      t001_UserUpdatedByObj: new FormGroup({ userId: new FormControl() }),
      moduleName: new FormControl(''),
      createdTs: new FormControl(),
      updatedTs: new FormControl(),
      pageSize: new FormControl(0),
      statusFlag: new FormControl(''),
      status: new FormControl(true),
      empIds: new FormControl(),
      employeeId: new FormControl(0),
      t004_LookupObj: new FormGroup({ lookupId: new FormControl(0), lookupValue: new FormControl('') })
    })

  }
  ngOnInit() {
    /**
  DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
  Below code is used to get the access for the page which is being loaded / refreshed. 
  Without the below code, the refresh of page will not display the access properly
  **/
    //  this.commonService.checkLoggedInUser(this.moduleName);
    this.commonService.loggesInUserObjSubject.subscribe(
      result => {
        if (result === 'LOGIN_AVAILABLE') {
          this.currentlySelectedMenu = this.commonService.currentlySelectedMenu
          this.pageLoad();

        }
      });
    /** code ends above **/
    if (this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
      this.pageLoad();

  }
  pageLoad(): void {
    if (this.commonUtils.checkNullOrUndefined(this.projectIdSelected)) {
      this.resourceExtensionForm.get('t822ProjectObj.projectId').setValue(this.projectIdSelected)
      this.fetchAllResourceProjectWise(this.projectIdSelected);
    }
  }
  fetchAllResourceProjectWise(event) {
    this.commonService.showProgressBar();
    let resourceForm: FormGroup = new FormGroup({
      t822ProjectObj: new FormGroup({ projectId: new FormControl(0) })
    });
    resourceForm.get('t822ProjectObj.projectId').setValue(this.resourceExtensionForm.get('t822ProjectObj.projectId').value)
    this.hrmService.fetchUsersForResourceExtension(this.resourceExtensionForm.value).subscribe(
      data => {
        this.selectedProjectResourceList = data;
        this.commonService.hideProgressBar();
      },
      error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  checkRemoveExistUserObj;
  allResourceExtensionList1;
  userExistFlag: boolean = false;
  saveOrUpdateResourceExtension() {
    let count = 0;
    if (this.commonUtils.checkNullOrUndefined(this.resourceExtensionForm.get('empIds').value)) {
        this.hrmService.saveOrUpdateResourceExtension(this.resourceExtensionForm.value).subscribe(
          data => {
            this.commonService.hideProgressBar();
            this.commonService.showERPMessage("Resources have been extended to the selected project successfully! Only the non-existing resources will be added");
            this.dialogRef.close(data);
          },
          error => {
            this.commonService.hideProgressBar();
            this.commonService.showERPErrorMessage(error);
          })
    } else {
      this.commonService.showERPMessage("No resources selected. Select atleast one resource to proceed");
    }
  }

  userIdList = [];
  addDisable;
  tempArrayLengthOfSelectedList: number = 0;
  checkUserExists(userId, multiSelectValue) {
    // The user selected will be checked with the respective project and added if the user does not exist 
    // added to enable the button once the user selected
    if (this.tempArrayLengthOfSelectedList < multiSelectValue.selected.length) {
      if (this.commonUtils.checkNullOrUndefined(userId))
        this.addDisable = false;
      else
        this.addDisable = true;
      this.tempArrayLengthOfSelectedList = multiSelectValue.selected.length;
      // added to enable the button once the user selected
      if (this.commonUtils.checkNullOrUndefined(this.resourceExtensionForm) && this.commonUtils.checkNullOrUndefined(this.resourceExtensionForm.value) &&
        this.commonUtils.checkNullOrUndefined(this.resourceExtensionForm.value.projectValue)) {
        this.resourceExtensionForm.get('t822ProjectObj.projectId').setValue(this.resourceExtensionForm.value.projectValue);
        this.resourceExtensionForm.get('t001_UserObj.userId').setValue(userId);
        // this.viewDefaultTopEmitter.emit({ action: "CHECK_RESOURCE_EXTENSION", data: this.resourceExtensionForm.value });
        this.checkForResourceExtension(this.resourceExtensionForm.value);
      }
    } else {
      this.tempArrayLengthOfSelectedList = multiSelectValue.selected.length;
    }
  }

  userCheckFlag: boolean = false;
  userList = [];
  checkForResourceExtension(form: any) {
    if (this.commonUtils.checkNullOrUndefined(this.allResourceExtensionList)) {
      let statusFlag: boolean = false;

      for (var loop = 0; loop < this.allResourceExtensionList.length; loop++) {
        if (this.allResourceExtensionList[loop].t001_UserObj.userId === form.t001_UserObj.userId) {
          this.commonService.showERPErrorMessage(this.resourceExtensionError);
          this.userCheckFlag = true;
          break;
        }
      }

      if (!this.userCheckFlag) {
        this.userList.push(form);
      }

    }
  }
  cancelClick(): void {
    this.dialogRef.close();
  }
  onChangeProject(project, event) {
    if (event.isUserInput == true) {
      if (this.commonUtils.checkNullOrUndefined(project)) {
        this.selectedProjectObj = project;
      }
    }
    else {
      this.selectedProjectObj = null;
    }
  }
}
