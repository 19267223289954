<mat-card class="col-lg-12">
<form [formGroup]="myForm" (ngSubmit)="saveOrUpdatePayCode()">
   <h3>{{ADD_PAYCODE}}</h3>
  <mat-card-content>
 
  <div class="col-lg-12">
    <div class="col-lg-12">
      <mat-form-field  class="col-lg-6">
      <input type="text"   matInput  placeholder="{{PAY_CODE}}" formControlName="payCode">
      </mat-form-field>
      
      <mat-form-field class="col-lg-6">
      <input type="text"   matInput  placeholder="{{DISPLAY_ORDER}}" formControlName="displayOrder">
      </mat-form-field>
    </div>
    
    <div class="col-lg-12">
      <mat-form-field class="col-lg-12">
      <textarea  matInput  placeholder="{{DESCRIPTION_PLACEHOLDER}}" formControlName="earnDescription" onfocus="this.select()"></textarea>
      </mat-form-field>
    </div>
    
    <div class="col-lg-12 checkbox_padding">
      <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="showReport">{{SHOW_IN_REPORT}}</mat-checkbox>
      </div>
      <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="pFAvailability">{{CONSIDER_FOR_PF}}</mat-checkbox>
      </div>
     
    </div>
    <div class="col-lg-12 checkbox_padding">
      
      <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="considerForLOP">{{CONSIDER_FOR_LOP}}</mat-checkbox>
      </div>
      <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="deduct">{{DEDUCTION}}</mat-checkbox>
      </div>

    </div>
    <div class="col-lg-12 checkbox_padding">
    <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="eSIAvailability">{{CONSIDER_FOR_ESI}}</mat-checkbox>
      </div>

       <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="editable">{{IS_EDITABLE}}</mat-checkbox>
      </div>
    </div>
      

       <div class="col-lg-12 checkbox_padding">
     <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="considerForIT">{{CONSIDER_FOR_IT}}</mat-checkbox>
      </div>

       <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="considerForCTC">{{CONSIDER_FOR_CTC}}</mat-checkbox>
      </div>
    </div>

      <div class="col-lg-12 checkbox_padding">
     <div class="col-lg-6">
        <mat-checkbox color="primary" checked="true" formControlName="considerForTakeHome">{{CONSIDER_FOR_TAKE_HOME}}</mat-checkbox>
      </div>

    </div>

       <div class="col-lg-12 checkbox_padding row"  style="padding-left: 25px">
         <div class="col-lg-4">
       <button mat-raised-button color="primary" class="mat-red" *ngIf="editAccess" [disabled]="!myForm.valid||disableFlag" >{{SAVE_BUTTON}}</button>

     </div>
      <div class="col-lg-3">
       <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
     </div>
     
    </div>
  </div>
  </mat-card-content>
</form>
</mat-card>