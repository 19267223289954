  
<mat-card class="col-lg-12 createcontact  mb-mr-0 mb-pd-0">
  	<mat-card-title>
  		<mat-icon>home</mat-icon>{{dispLabel}} Job</mat-card-title>
   
  <form [formGroup]="jobForm" >
   	<mat-card-content >
   		<div class="col-lg-12 mb-mr-0 mb-pd-0">
   			<mat-form-field class="col-lg-6">
			    <input matInput  placeholder="{{placeholderStr}}" formControlName="jobCode" (focusout)="onfocusoutAutoGenField()" readonly="{{disabledAutoFlag}}" (keypress)="jobExistsCheck()" (paste)="jobExistsCheck()">
			     <button type="button" mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="openSettingsDialog()" [disabled]="jobCodeDisableFlag">
                  <div  style="margin-right: 8%;" ><mat-icon>settings</mat-icon></div>
                </button>
                <mat-error *ngIf="((jobForm.controls['jobCode'].hasError('required')) && (!disabledAutoFlag)) else elseBlock">{{JOBNUMBER_ALERT}} <strong>{{REQUIRED}}</strong> </mat-error> 
                <mat-hint #elseBlock *ngIf="(jobNumberStatus | async)?.length>0" style="color:crimson">  {{JOB_EXISTS_ALERT}}
                </mat-hint>
		    </mat-form-field>
   			<mat-form-field  class="col-lg-6">
		    <input type="text"   matInput  placeholder="Job Title" formControlName="title">
		    <button mat-button  *ngIf="jobForm.controls['title'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(jobForm,'title')" >
                    <mat-icon>close</mat-icon>
                </button>
		    </mat-form-field>
   		</div>
   	

		<div class="col-lg-12 mb-mr-0 mb-pd-0">
		    <mat-form-field  class="col-lg-6" formGroupName="t004JobTypeObj">
				<mat-select placeholder="Job Type" formControlName="lookupId">
				<mat-option>None</mat-option>
				<mat-option *ngFor="let lookupObj of jobTypeList" [value]="lookupObj.lookupId" (click)="onChangeJobType(lookupObj)">
					    {{ lookupObj.lookupValue }}
					</mat-option>
				</mat-select>
		    </mat-form-field>
		    <mat-form-field  class="col-lg-6" formGroupName="t816Designation">
				<mat-select placeholder="Designation" formControlName="designationId">
				<mat-option>None</mat-option>
				<mat-option *ngFor="let designationObj of designationList" [value]="designationObj.designationId" (click)="onChangeDesignation(designationObj)">
					{{ designationObj.designationName }}
				</mat-option>
				</mat-select>
		    </mat-form-field>
		</div>
			<div class="col-lg-12 mb-mr-0 mb-pd-0">
		  <mat-form-field class="col-lg-12">
		    <input type="text"   matInput  placeholder="Job Description" formControlName="description" >
		    <button mat-button  *ngIf="jobForm.controls['description'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(jobForm,'description')" >
                    <mat-icon>close</mat-icon>
            </button>
		  </mat-form-field>
		  
		</div>
		<div class="col-lg-12 mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-6">
		    <input type="text"   matInput  placeholder="Location" formControlName="location" >
		    <button mat-button  *ngIf="jobForm.controls['location'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(jobForm,'location')" >
                    <mat-icon>close</mat-icon>
            </button>
		  </mat-form-field>
		  <mat-form-field class="col-lg-6">
		    <input type="number" min="0" oninput="validity.valid||(value='');" matInput  placeholder="No.of Open Postions" formControlName="numberOfOpenPositions">		   
		  </mat-form-field>
		</div>
		<div class="col-lg-12 mb-mr-0 mb-pd-0">
		  <mat-form-field class="col-lg-6">
		    <input type="number" min="0" oninput="validity.valid||(value='');" matInput  placeholder="Experience in Years" formControlName="yearExperience">		   
		  </mat-form-field>
		  <mat-form-field class="col-lg-6">
		    <input type="number" min="0"  max="12" oninput="validity.valid||(value='');" matInput  placeholder="Experience in Months" formControlName="monthExperience">		   
		  </mat-form-field>
		</div>
		<div class="col-lg-12 mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-6">
		    <input matInput  placeholder="Offered Salary(Per Annum)" formControlName="offeredSalary" >
		  </mat-form-field>
		  <mat-form-field  class="col-lg-6" formGroupName="t004JobStatusObj">
				<mat-select placeholder="Status" formControlName="lookupId">
				<mat-option *ngFor="let lookupObj of jobStatusList" [value]="lookupObj.lookupId" (click)="onChangeJobStatus(lookupObj)">
					    {{ lookupObj.lookupValue }}
					</mat-option>
				</mat-select>
		    </mat-form-field>
		</div>
		<div class="col-lg-12 mb-mr-0 mb-pd-0">
		    <mat-form-field class="col-lg-12" *ngIf="!statusReasonFlag">
		   <textarea  matInput  placeholder="Required Skills" formControlName="requiredSkills" onfocus="this.select()"></textarea>
		  </mat-form-field>
		   <mat-form-field class="col-lg-6" *ngIf="statusReasonFlag">
		   <textarea  matInput  placeholder="Required Skills" formControlName="requiredSkills" onfocus="this.select()"></textarea>
		  </mat-form-field>
		   <mat-form-field class="col-lg-6" *ngIf="statusReasonFlag">
		   <textarea  matInput  placeholder="Reason for {{jobstatus}} the job "formControlName="reason" onfocus="this.select()"></textarea>
		  </mat-form-field>
		</div>

		<div class="col-lg-12 alignCenter buttons">
		    <button mat-raised-button color="primary" class="mat-red" *ngIf="editAccess" [disabled]="(((jobNumberStatus | async)?.length>0)||(!jobForm.valid)||(disableButton))"  (click)="saveOrUpdateJobs()">{{SAVE_BUTTON}}</button>
		    <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
   	</mat-card-content>
 </form>
</mat-card>
 


 