<mat-card class="col-lg-12">
  <form [formGroup]="resourceExtensionForm">
    <div class="float-left row col-lg-12">
      <h4>{{ADD_RESOURCE_EXTENSION}}</h4>
    </div>
    <mat-card-content>

      <div class="col-lg-12">


        <div class="float-left row col-lg-12">
          <mat-form-field class="col-lg-12 example-full-width" formGroupName="t822ProjectObj">
            <mat-select placeholder="{{PROJECT}}" formControlName="projectId"
              (selectionChange)="fetchAllResourceProjectWise($event.value)">
              <!-- [(ngModel)]="projectValue" -->
              <mat-option (onSelectionChange)="onChangeProject('',$event)">All
              </mat-option>
              <mat-option (onSelectionChange)="onChangeProject(project,$event)" *ngFor="let project of projectList"
                [value]="project.projectId">
                {{project.projectName}} - <span style="color:grey;"><i> {{project.projectCode}} </i></span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-lg-12 example-full-width">
            <mat-select multiple placeholder="{{USERS}}" #multiSelect formControlName="empIds">
              <mat-option *ngFor="let user of selectedProjectResourceList" [value]="user.userId"
               >
               <!--- (click)="checkUserExists(user.userId,multiSelect)"-->
                {{user.userName}} <span style="color:grey;" *ngIf="user.empName != null && user.empName != '' "> - <i>
                    {{user.empName}} </i></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row  col-lg-12">
          <div class="col-lg-6" style="text-align: right">
            <button mat-raised-button color="primary" class="mat-red"
              (click)="saveOrUpdateResourceExtension()">{{SAVE_BUTTON}}</button>
          </div>

          <div class="col-lg-6" style="text-align: left">
            <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
          </div>
        </div>

      </div>

    </mat-card-content>
  </form>
</mat-card>