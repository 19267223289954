import { Component, Input ,Inject,Output} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
@Component({
  selector: 'file-preview-overlay',
  templateUrl: './approveleave.component.html'
   
})
export class ApproveLeaveComponent extends CommonUtils { 

inputObj;
approveLeaveForm: FormGroup;
  constructor(
      public dialogRef: ErpOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
     super();
    this.inputObj=data;
    this.approveLeaveForm = new FormGroup({
      leaveHistoryId : new FormControl(0),
      leaveId:new FormControl(0),
     clApproved: new FormControl(''),
     slApproved: new FormControl(''),
     compOffApproved: new FormControl(''),
     lop: new FormControl(''),
     approvedDays : new FormControl(''),
     totalAppliedDays: new FormControl(''),
     statusFlag: new FormControl(''),
     t004LookupLeaveTypeObj:new FormGroup({
    lookupId: new FormControl(''),
    lookupValue:new FormControl('')}),
    approverId: new FormControl(0)
 })
     
  }
ngOnInit() 
{
  this.pageload();
}
pageload()
{
  this.approveLeaveForm.patchValue(this.inputObj);
  /*this.approveLeaveForm.get('clApproved').setValue(this.inputObj.clApproved);
  this.approveLeaveForm.get('slApproved').setValue(this.inputObj.slApproved);
  this.approveLeaveForm.get('compOffApproved').setValue(this.inputObj.compOffApproved);
  this.approveLeaveForm.get('lop').setValue(this.inputObj.lop);*/
}
  saveApproveLeaveForm(action){
    if(action === "approve"){
      this.approveLeaveForm.get('approverId').setValue(this.inputObj.approverId);
      this.approveLeaveForm.get('statusFlag').setValue('APPROVED');
    }
    else{
      this.approveLeaveForm.get('approverId').setValue(this.inputObj.approverId);
      this.approveLeaveForm.get('statusFlag').setValue('REJECTED');
    }

  	this.dialogRef.dialogClose(this.approveLeaveForm.value);
   	
  }

    }
