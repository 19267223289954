import { Component, OnInit, Inject} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CommonUtils} from '../../core/common-utils';
@Component({
  selector: 'app-error-message',
  templateUrl: './error-msg.component.html'
})
export class ErrorMessageAlertComponent 
{
	confTextToDisplay; estimateStatus: string;
	dialogText: string;
	dialogDetailText: string;
	dialogSupport:boolean = false;
constructor(public dialogRef: MatDialogRef<ErrorMessageAlertComponent>,@Inject(MAT_DIALOG_DATA) public data: any, public commonUtils: CommonUtils) 
{
	 this.dialogText = data.dialogText;
	 this.dialogDetailText = data.dialogDetailText;
	 if(data.dialogSupport === "true"){
	 	this.dialogSupport=true;
	 }else{
	 	this.dialogSupport=false;
	 }
}

onOKClick() 
{
	this.dialogRef.close();
}
}
