 
<div [formGroup]="myForm" class="mat-card-view-edit-top" style="height: 100%;background-color: white;width: 100%; position: fixed;">



  <!-- <mat-form-field appearance="outline"  class="col-lg-12">
    <mat-label>Search</mat-label>
    <input style="width:92%" matInput placeholder="{{TYPE_HERE_TO_SEARCH}}" formControlName="searchName" (keypress)="searchOnKeyPress($event)">    
  </mat-form-field> -->
  <div class="col-lg-12" *ngIf=" this.searchLabel">
    <mat-label style="font-size: medium; font-family: bold; color: black;"> Searching for "{{this.searchName}}".....</mat-label>
  </div>


   <div class="col-lg-12" style=" overflow-y: auto; max-height: 90%;" >  
    <div *ngIf="moduleNameforPO==='TS_VIEW_PO' && this.searchPOList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Purchase Order</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PO'" [searchList]="this.searchPOList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
 <div *ngIf="moduleNameforBill==='TS_VIEW_BILL' && this.searchBillList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Bill</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_BILL'" [searchList]="this.searchBillList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false"  [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
   <div *ngIf="moduleNameforRBill==='TS_VIEW_R_BILL' &&  this.searchRBIllList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Recurring Bill</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_R_BILL'" [searchList]="this.searchRBIllList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
 <div *ngIf="moduleNameforExp==='TS_VIEW_EXPENSE' &&  this.searchExpenseList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Expense</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_EXPENSE'" [searchList]="this.searchExpenseList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforRExp==='TS_VIEW_R_EXPENSE'&&  this.searchRExpenseList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Recurring Expense</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_R_EXPENSE'" [searchList]="this.searchRExpenseList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
   <div *ngIf="moduleNameforPayM==='TS_VIEW_PAYMENT_MADE' &&  this.searchPayMadeList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Payment Made</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PAYMENT_MADE'" [searchList]="this.searchPayMadeList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforVC==='TS_VIEW_VENDOR_CREDIT' &&  this.searchVCList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Vendor Credits</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_VENDOR_CREDIT'" [searchList]="this.searchVCList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforSO==='TS_VIEW_SALESORDER' &&  this.searchSOList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Sales Order</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_SALESORDER'" [searchList]="this.searchSOList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforEst==='TS_VIEW_ESTIMATE' &&  this.searchEstimateList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Estimate</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_ESTIMATE'" [searchList]="this.searchEstimateList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforArt==='TS_VIEW_ARTWORK' &&  this.searchArtworkList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Artwork</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_ARTWORK'" [searchList]="this.searchArtworkList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div> 
  <div *ngIf="moduleNameforPS==='TS_VIEW_PRODUCTION_SLIP' &&  this.searchPSList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Production Slip</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PRODUCTION_SLIP'" [searchList]="this.searchPSList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforPack==='TS_VIEW_PACKAGES' &&  this.searchPackageList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Packages</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PACKAGES'" [searchList]="this.searchPackageList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforInv==='TS_VIEW_INVOICE' &&  this.searchInvoiceList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Invoice</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_INVOICE'" [searchList]="this.searchInvoiceList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforRInv==='TS_VIEW_R_INVOICE' &&  this.searchRInvoiceList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Recurring Invoice</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_R_INVOICE'" [searchList]="this.searchRInvoiceList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforPayR==='TS_VIEW_PAYMENT_RECIEVED'&&  this.searchPayReceiveList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Payment Received</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PAYMENT_RECIEVED'" [searchList]="this.searchPayReceiveList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforCN==='TS_VIEW_CREDIT_NOTES' &&  this.searchCNList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Credit Notes</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_CREDIT_NOTES'" [searchList]="this.searchCNList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforItem==='TS_VIEW_ITEM' &&  this.searchItemList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Item</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_ITEM'" [searchList]="this.searchItemList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)" [tempModuleForItem]="this.itemModuleName"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforCategory==='TS_VIEW_CATEGORY' &&  this.searchCategoryList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Category</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_CATEGORY'" [searchList]="this.searchCategoryList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforPL==='TS_VIEW_PRICE_LIST' &&  this.searchPLList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Price List</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_PRICE_LIST'" [searchList]="this.searchPLList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforIG==='TS_VIEW_ITEMS_GROUP' &&  this.searchIGList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Items Group</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_ITEMS_GROUP'" [searchList]="this.searchIGList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'" (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforContact==='TS_VIEW_CONTACT'  &&  this.searchContactList.length>0"  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Contact</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_CONTACT'" [searchList]="this.searchContactList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'"(viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div>
  <div *ngIf="moduleNameforSP==='TS_VIEW_SALES_PERSON'  &&  this.searchSPList.length>0 "  class="col-lg-12">
    <mat-label style="font-size: x-large; color: black;"> Sales Person</mat-label> 
      <app-view-template-wrapper [moduleName]="'TS_VIEW_SALES_PERSON'" [searchList]="this.searchSPList" [refForeignKeyId]="" [screenTitle]="" [displayHeaderLabel]="false" [displayDefaultTop]="false" [showMenuShortcuts]="false" [showPagination]="false" [templateCSSHeightFlag]="'autosearchcontacts'"
        (viewTemplateWrapperEmitter)="viewTopSearch($event)"></app-view-template-wrapper>
  </div> 
 <div class="mat-row noRecordPadding" *ngIf="isEmptySearch">
  <label style="font-size: large; color: black;"> No Record Found </label>
 </div>
</div>

<div>
	 <button style="margin-left: 40%; margin-top: 2%;" type="button" mat-raised-button color="primary" (click)="closeDialog()" >Close
   </button>
</div>


</div>
 