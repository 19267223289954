
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {Component, OnInit, ViewChild,Inject} from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormGroup, FormControl,Validators, FormArray, FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {RecruitmentService} from '../../recruitment/recruitment-service';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
import {CanComponentDeactivate} from '../../auth/can-deactivate-guard.service';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component';
import {HrmService} from '../../hrm/hrm-service';
import {AutoNumberGenerateComponent} from '../../dialogBox/autogenerate/auto-num-generate.component';

@Component({
selector: 'app-createjob',
templateUrl: './createjob.component.html',
providers: [],
})
export class CreateJobComponent extends CommonUtils implements CanComponentDeactivate,OnInit {
//placeholder from common utils
jobStatusList=[];
jobTypeList=[];
designationList=[];
DISPLAY_NAME;
CANCEL_BUTTON;
elseBlock;
jobNumberStatus;
SAVE_BUTTON;
moduleName : string =environment.NEW_JOB; 
saveClick = true;
dispLabel = 'New';
disableButton = false;
step = 0;
editAccess = true;
userObj;
myAGForm: FormGroup;
disabledAutoFlag = false;
placeholderStr:string = 'Job #';
jobObj;
jobCodeDisableFlag = false;
statusReasonFlag :boolean =false;
JOBNUMBER_ALERT = 'Job # is';
JOB_EXISTS_ALERT = 'Job # already exists';
jobstatus;

currentlySelectedMenu;
public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
contactEditId;


jobForm: FormGroup=new FormGroup({
    title: new FormControl(''),
    jobId : new FormControl(0),
    description: new FormControl(''),
    jobCode: new FormControl(''),
    link: new FormControl(''),
    location: new FormControl(''),
    yearExperience: new FormControl(0),
    monthExperience: new FormControl(0),
    numberOfOpenPositions: new FormControl(0),
    offeredSalary: new FormControl(0.00),
    requiredSkills: new FormControl(''),
    moduleName: new FormControl(this.moduleName),
    t004JobStatusObj: new FormGroup({lookupId: new FormControl(0),lookupValue: new FormControl('')}),
    t004JobTypeObj: new FormGroup({lookupId: new FormControl(0),lookupValue: new FormControl('')}),
    t816Designation: new FormGroup({designationId: new FormControl(0),designationCode: new FormControl(''),designationName: new FormControl('')}),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(this.commonService.loggedInUserObj.userId)}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    createdTs: new FormControl(new Date()),
    updatedTs: new FormControl(''),
    displayDicardMessage:new FormControl('Y'),
    reason: new FormControl(''),
 });
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
 
constructor(private formBuilder: FormBuilder, private recruitService: RecruitmentService, private commonService: CommonService,private hrmService: HrmService,
private router: Router,private commonUtils: CommonUtils,private dialog:MatDialog,private dialogRef:MatDialogRef<CreateJobComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
 super();
this.jobObj = data.jobObj;
  this.myAGForm = new FormGroup({
   type: new FormControl(this.moduleName)
});
 
}
ngOnInit()
{

   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
//     this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu;
           this.editAccess=this.currentlySelectedMenu.editAccess;
           
         }
       });
/** code ends above **/ 
  this.fetchJobStatus('VIEW_JOB_TYPE');
  this.fetchJobStatus('JOB_STATUS');
  this.fetchDesignation();
  this.fetchAutoGenerate();
  this.validationMethod();

  if(this.commonUtils.checkNullOrUndefined(this.jobObj) && this.jobObj.jobId != 0  ){
    this.jobCodeDisableFlag = true;
    this.jobForm.get('jobId').setValue(this.jobObj.jobId);
    // this.jobForm.patchValue(this.jobObj);
    this.fetchJobs();
    this.dispLabel = 'Edit'
    this.disabledAutoFlag = true;
  }else{
    this.jobCodeDisableFlag = false;
  }
}
fetchJobs(){
  this.commonService.showProgressBar();  
  this.recruitService.fetchJobs(this.jobForm.value).subscribe(
      data=>{ 
        this.jobForm.patchValue(data[0]);
      },
      error=>{
      this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error);    
      }
   );
 }
    canDeactivate() {    
 
 if(this.jobForm.dirty && this.jobForm.get('displayDicardMessage').value==='Y'){
     const dialogRef = this.dialog.open(ERPPromptComponent, {
      width: '500px',
      data: {dialogText: environment.DISCARD_LABEL, confButton1: 'Leave',confButton2:'Stay'}
    });
     return  dialogRef.afterClosed().pipe(map(
       result => {
         if(result==='BUTTON1')
           return true;
           return false;
            }));
 }
  
  }
  fetchJobStatus(lookupCode){
    this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup(lookupCode).value).subscribe(
    data => {
      if (this.commonUtils.checkNullOrUndefined(data)) 
      {
        if(lookupCode === 'VIEW_JOB_TYPE')
          this.jobTypeList = data;
        else if(lookupCode === 'JOB_STATUS')
          this.jobStatusList = data;
      }
    },
    error => {
      this.commonService.showERPErrorMessage(error);
    });
  }
   fetchDesignation(): void {
    this.hrmService.fetchDesignation().subscribe(
     data => {
         this.designationList = data;
       }, error => {
         this.commonService.showERPErrorMessage(error);
      });
  }
  saveOrUpdateJobs(){
    if(this.jobForm.valid){
      this.disableButton=true;
    this.jobForm.get('displayDicardMessage').setValue('N');
    this.jobForm.get('moduleName').setValue(this.moduleName);
    if(this.jobForm.get('jobId').value !=0){
      this.jobForm.get('updatedTs').setValue(new Date());
      this.jobForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
    }else{
      this.jobForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
    }
    this.commonService.showProgressBar();
     this.recruitService.saveOrUpdateJobs(this.jobForm.value)
    .subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage,null);
        this.dialogRef.close(true);
       
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
    }

  }
  cancelClick(){
    this.dialogRef.close(true);
  }
  onChangeJobType(typeObj){
    this.jobForm.get('t004JobTypeObj.lookupId').setValue(typeObj.lookupId);
    this.jobForm.get('t004JobTypeObj.lookupValue').setValue(typeObj.lookupValue);
  }
  onChangeJobStatus(statusObj){
    this.jobForm.get('t004JobStatusObj.lookupId').setValue(statusObj.lookupId);
    this.jobForm.get('t004JobStatusObj.lookupValue').setValue(statusObj.lookupValue);
    if(statusObj.lookupValue.toUpperCase() === 'CLOSED' || statusObj.lookupValue.toUpperCase() === 'HOLD'){
      this.statusReasonFlag=true;
      if (statusObj.lookupValue.toUpperCase() === 'CLOSED')
      this.jobstatus = 'closing';
      else if (statusObj.lookupValue.toUpperCase() === 'HOLD')
       this.jobstatus = 'holding'
      // this.jobstatus=statusObj.lookupValue.toLowerCase();
      this.jobForm.get('reason').setValidators([Validators.required]);
      this.jobForm.get('reason').markAsTouched();
      this.jobForm.get('reason').updateValueAndValidity();
      
    }else{
      this.jobForm.get('reason').setValidators(null);
      this.jobForm.get('reason').markAsTouched();
      this.jobForm.get('reason').updateValueAndValidity();
      this.statusReasonFlag=false;
    }
  }
  onChangeDesignation(designationObj){
    this.jobForm.get('t816Designation.designationId').setValue(designationObj.designationId);
    this.jobForm.get('t816Designation.designationName').setValue(designationObj.designationName);
    this.jobForm.get('t816Designation.designationCode').setValue(designationObj.designationCode);
  }
  openSettingsDialog(): void {
  const dialogRef = this.dialog.open(AutoNumberGenerateComponent, {
    width: '850px',
    data: { dialogLabel: 'Job Code', dialogOrderType: 'New Job', moduleName: this.moduleName}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
      this.disabledAutoFlag = true;
      this.placeholderStr = 'System Generated';
      this.jobForm.get('jobCode').setValue('');
      this.onfocusoutAutoGenField();
    } else if (result === false) {
      this.disabledAutoFlag = false; 
      this.placeholderStr = 'Job #';
       this.validationMethod(); 
    }

    });
}
fetchAutoGenerate() {
   this.myAGForm.get('type').setValue(this.moduleName);
   this.commonService.fetchAutoGenerateNumber(this.myAGForm.value).subscribe(
    data => {
          if (data != null) {
     if (data.autoGenerate === true) {
        this.disabledAutoFlag = true;
        this.placeholderStr = 'System Generated';
         this.onfocusoutAutoGenField();
     }
      }
        }, error => {
         this.commonService.showERPErrorMessage(error);
       });
}

validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.jobForm);   
  }
onfocusoutAutoGenField():void{
  /*to remove the validations for the auto generated value - if it is true - starts*/
  if(this.disabledAutoFlag === true){
      this.jobForm.get('jobCode').setValidators(null);
      this.jobForm.get('jobCode').markAsTouched();
      this.jobForm.get('jobCode').updateValueAndValidity();
    }
  /*to remove the validations for the auto generated value - if it is true - ends*/
}

jobExistsCheck(): void{
  this.jobForm.get('moduleName').setValue(this.moduleName);
    this.jobNumberStatus = this.jobForm.controls['jobCode'].valueChanges.pipe(
      debounceTime(400),
      switchMap(jobNumberStatus =>
        this.recruitService.fetchJobNumberStatus(this.jobForm.value)));
  }
}