<form [formGroup]="createTaskForm" >
	<mat-card class="col-lg-12 createcontact">
  		<mat-card-title class=" nopadding"><mat-icon>home</mat-icon>Create Timesheet</mat-card-title>
		<mat-form-field class="col-lg-6 example-full-width nopadding">
			<mat-select placeholder="{{SELECT_PROJECT}}" formControlName="projectValue">
				<mat-option (onSelectionChange)="onChangeProject(project, $event)" *ngFor="let project of projectList" [value]="project.projectId"> 
					{{project.projectName}} - <span style="color:grey;"><i> {{project.projectCode}} </i></span> 
				</mat-option>
			</mat-select>
			<mat-hint align="start" *ngIf="createTaskForm.controls['projectValue'].value===0">Select Project to continue further for creating Timesheet</mat-hint>
		</mat-form-field>  	 
        <div class="row">
			<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding">
				<mat-form-field  class="col-lg-12">
				    <input type="text"   matInput  placeholder="{{TASK_NAME}}" formControlName="taskName">
				    <button mat-button  *ngIf="createTaskForm.controls['taskName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'taskName')" >
                    <mat-icon>close</mat-icon>
                </button>
				</mat-form-field>
			</div>
			<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding">	
				<mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				    <textarea matTextareaAutosize matAutosizeMinRows="3" onfocus="this.select()"
                matAutosizeMaxRows="4" matInput  placeholder="{{TASK_DESC}}" formControlName="taskDesc" ></textarea>
                <button mat-button *ngIf="createTaskForm.controls['taskDesc'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'taskDesc')" >
                    <mat-icon>close</mat-icon>
                  </button>  
				</mat-form-field>
			</div>	  	 
	  	</div>
        <div class="col-lg-12 mb-mr-0 mb-pd-0">        	 
    	   <mat-form-field class="col-lg-4 nopadding" formGroupName="t001_UserObj">			 
	  				<mat-select placeholder="{{USERNAME_PLACEHOLDER}}" formControlName="userId" [disabled] ="userFlag">
	  					<mat-option>None</mat-option>
	  				    <mat-option *ngFor="let user of orgUsersList" [value]="user.userId">
	  				        {{user.userName}}  <span style="color:grey;" *ngIf="user.empName != null && user.empName != '' "> - <i> {{user.empName}} </i></span> 
	  				    </mat-option>
	  				</mat-select>
	  		</mat-form-field>
			<mat-form-field class="col-lg-3 col-md-4 col-sm-12 col-xs-12" >				  
				  <input matInput  [matDatepickerFilter]="satSunDisableInSelectDateFilter" [matDatepicker]="startDatePicker" placeholder="{{START_DATE}}" 
				     formControlName="startDate"  dateFormatter id="startDatePicker" [disabled]="startDateFlag" >  
				       <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
				      <mat-datepicker #startDatePicker ></mat-datepicker>  
			</mat-form-field>
			<mat-form-field class="col-lg-3 col-md-4 col-sm-12 col-xs-12"  >
				     <input matInput  [matDatepickerFilter]="satSunDisableInSelectDateFilter" [matDatepicker]="endDatePicker" placeholder="{{END_DATE}}" [disabled]="endDateFlag"  dateFormatter id="endDatePicker"
				     formControlName="endDate" [min]="createTaskForm.controls.startDate.value">
				       <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
				      <mat-datepicker #endDatePicker ></mat-datepicker>
			</mat-form-field>
		</div>    
		<div class="col-lg-12 buttons" style="padding-bottom: 20px">
		<button mat-raised-button color="primary" *ngIf="editAccess" class="mat-red" [disabled]="disableButton || !createTaskForm.valid" (click)="confirmToSaveBaseLineDate()">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="onNoClick()">{{CANCEL_BUTTON}}</button>
		</div>
	</mat-card>
 </form>