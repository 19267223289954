
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {environment} from '../../environments/environment';
import {CommonService} from './common.service';
@Pipe({
  name: 'dateFormatPipe'
})

export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
  	
  	if(value != 'Invalid Date'){
    return super.transform(value, Constants.DATE_FMT);
	}
  }
}
export class Constants{
  static DATE_FMT = environment.DATE_FORMAT;
  //static readonly DATE_FMT = environment.DATE_FORMAT;
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss a`;
  //static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} HH:mm:ss a`;


}
