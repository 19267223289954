import {FormControl, FormGroup, FormArray, NgForm, Validators, AbstractControl, FormBuilder} from '@angular/forms';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
import {Router,ActivatedRoute} from '@angular/router';
 import {environment} from '../../../environments/environment';
 import {Component , OnInit, Output, EventEmitter} from '@angular/core';
 import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-testcaselinkmaster',
  templateUrl: './create-testcaselinkmaster.component.html'
})
export class CreateTestCaseLinkComponent extends CommonUtils implements OnInit {
testCaseLinkForm: FormGroup;
testCaseList=[];
@Output() viewPageRefresh:EventEmitter<any>=new EventEmitter<any>();
moduleName : string = environment.NEW_TCLINKMASTER;
  constructor( private commonService: CommonService, public dialogRef: MatDialogRef<CreateTestCaseLinkComponent>,private router: Router) { 
    super();
  }
  onNoClick(): void {
   this.dialogRef.close();
  }
  ngOnInit() {
    this.pageLoad();
    this.testCaseLinkForm=this.getTestCaseLinkForm();
  }
pageLoad(){
 //this.fetchTCLinkMasterStatus();
}

  getTestCaseLinkForm():FormGroup{
     const testCaseLinkForm: FormGroup = new FormGroup({
      linkId: new FormControl(0),
      endPointUrl:new FormControl(''),
      request:new FormControl(''),
      response:new FormControl(''),
      t004LookupStatusId: new FormGroup({lookupId: new FormControl(), lookupCode: new FormControl('VIEW_TCLINKMASTER'), lookupValue: new FormControl('OPEN')})
  });
     return testCaseLinkForm;

  }
savetestcaseLinkMaster()
{
  
  this.commonService.showProgressBar();
      this.commonService.saveOrUpdateTestCaseLinkMaster(this.testCaseLinkForm.value).subscribe(
        data => {
          this.commonService.hideProgressBar();
          this.commonService.showERPMessage(data.erpmessage);
          this.cancelClick();
        }, error => {
          this.commonService.showERPErrorMessage(error.erpmessage);
          this.commonService.hideProgressBar();
          //this.disableFlag=false;
        });
}
cancelClick(): void {
  this.dialogRef.close(true);
  this.router.navigate(['/superadmin/testcaselinkmaster']); 
}
fetchTCLinkMasterStatus()
   {
     this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('VIEW_TCLINKMASTER').value).
     subscribe(
     data =>
      {
             this.testCaseList = data; 
      },
    error => 
    {
        this.commonService.showERPErrorMessage(error);
      });
   }
}
