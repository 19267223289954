import { Component, Input ,Inject,Output} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
@Component({
  selector: 'reminder-component',
  templateUrl: './reminder.component.html'
})

export class ReminderComponent extends CommonUtils { 

inputObj;
 notesFlag:boolean=false;
 inputData;
 minDateForReminderValidation: Date = new Date();

 myForm = new FormGroup({
   reminderDetailId: new FormControl(),
     reminderDate : new FormControl(),
    notesDescription : new FormControl(''),
    createdDttm: new FormControl(new Date),
    updatedDttm: new FormControl(new Date),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0), userName: new FormControl('')}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    invoiceHeaderId:new FormControl(0),
    moduleName: new FormControl(''),
    routeURL: new FormControl(''),
    contactId: new FormControl(0),
     itemId: new FormControl(0),
     itemGroupId: new FormControl(0),
     priceListHeaderId:new FormControl(0),
     paymentMadeHeaderId:new FormControl(0),
      employeeId:new FormControl(0),
      payCodeId:new FormControl(0),
      paymentReceivedHeaderId:new FormControl(0),
     expenseHeaderId:new FormControl(0)


 })

myNotesForm = new FormGroup({
    historyId: new FormControl(0),
    userComments : new FormControl(''),
    createdTs: new FormControl(new Date),
    headerHistoryId:new FormGroup({invoiceHeaderId:new FormControl(0),
      expenseHeaderId:new FormControl(0),
      paymentMadeHeaderId:new FormControl(0), 
      contactId: new FormControl(0)}),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0),userName: new FormControl('')})    
   })

  constructor(
      public dialogRef: ErpOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
     super();
    this.inputData=data;
   
     
  }
ngOnInit() 
{
  this.pageload(); 
}
pageload()
{
  this.myForm.patchValue(this.inputData);
}

notesFlagStatus(event)
{
  if(event.checked === true)
  {
    this.notesFlag=true;
    //this.reminderFlag=false;
  }
  else if(event.checked === false)
  {
    //this.reminderFlag=true;
    this.notesFlag=false;
  }
}
 
 addReminderDetails(){
   this.dialogRef.dialogClose(this.myForm.value);
 }

 addNotesDetails(){
   this.dialogRef.dialogClose(this.myNotesForm.value);
 }

}