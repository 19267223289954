import {Component , OnInit, Inject} from '@angular/core';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {FormControl, FormGroup, FormArray} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CommonService} from '../../shared/common.service';
import {AssetTrackingService} from '../assettracking-service';
import {Router, ActivatedRoute} from '@angular/router';
import {HrmService} from '../../hrm/hrm-service';

@Component({
  selector: 'app-asset-transaction',
  templateUrl: './asset-transaction.component.html'
})

export class AssetTransactionComponent extends CommonUtils implements OnInit{

currentlySelectedMenu;
moduleName='NEW_ASSET';
assetTransactionForm: FormGroup;
assetForm : FormGroup;
warehouseForm : FormGroup;
t800Employee : FormGroup;
warehouseFormFrom : FormGroup;
warehouseBlockForm: FormGroup;
warehouseRoomForm: FormGroup;
warehouseBlockFormFrom: FormGroup;
warehouseRoomFormFrom: FormGroup;
t800EmployeeFrom : FormGroup;
disableSubWarehouseBlock: boolean = false;
enableWareHouseBlock: boolean= false;
enableWareHouseRoom: boolean= false;
enableWareHouseBlockFrom: boolean= false;
enableWareHouseRoomFrom: boolean= false;
warehouseBlockList;
warehouseRoomList;
warehouseBlockFromList;
warehouseRoomFromList;
userObj;
private sub: any;
editId: number;
tranType: any;
refreshGrid:any;
movementTypeList;
assignmentTypeList;
warehouseList;
employeeList;
transactionType;
availableQty : number = 0;
finalAvailableQuantity: number = 0;;

vendorFormGroup: FormGroup = new FormGroup({
      contactDisplayName: new FormControl(''),
      contactId: new FormControl('0'),
      contactsType: new FormControl('VENDOR')
});

customerFormGroup: FormGroup = new FormGroup({
      contactDisplayName: new FormControl(''),
      contactId: new FormControl('0'),
      contactsType: new FormControl('CUSTOMER')
});

vendorFormFromGroup: FormGroup = new FormGroup({
      contactDisplayName: new FormControl(''),
      contactId: new FormControl('0'),
      contactsType: new FormControl('VENDOR')
});

customerFormFromGroup: FormGroup = new FormGroup({
      contactDisplayName: new FormControl(''),
      contactId: new FormControl('0'),
      contactsType: new FormControl('CUSTOMER')
});
lastAssetTransMoveType;
lastAssetTransFromType;
lastAssetTransFromId;
assetTransCount = 0;
lastAssetTransToType;
lastAssetTransToId;
assetData;
disableWarehouseBlock = false;
disableEmployeeBlock = false;
	constructor(
    private commonUtils: CommonUtils,
    private commonService:CommonService,
    private hrmService: HrmService,
    private assetTrackingService:AssetTrackingService,
    private router: Router, 
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AssetTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
    ) {
    super();
    
     this.assetForm = new FormGroup({
       assetHeaderId: new FormControl(0),
       assetNumber: new FormControl({value:'',disabled:true}),
       assetName: new FormControl({value:'',disabled:true}),
       lazyLoad:new FormControl('')
     });
     this.warehouseForm = new FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true),
         level: new FormControl("1")
     });
     this.warehouseBlockForm = new FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true),
          level: new FormControl("2")
     });
     this.warehouseRoomForm = new FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true)
     });
     this.t800Employee= new FormGroup({
       employeeId: new FormControl(0),
       firstName : new FormControl(''),
       statusFlag: new FormControl('ALL'),       
       fetchInitializeFlag: new FormControl('N'),
       requestFlag: new FormControl('FetchAll')
     });
      this.warehouseFormFrom = new FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true)
     });
      this.warehouseBlockFormFrom = new  FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true)
     });
         this.warehouseRoomFormFrom = new  FormGroup({
         locationId: new FormControl(0),
         locationName:new FormControl(''),
         wareHouse:new FormControl(true)
     });
     this.t800EmployeeFrom= new FormGroup({
       employeeId: new FormControl(0),
       firstName : new FormControl(''),
       statusFlag: new FormControl('ALL'),
       requestFlag: new FormControl('FetchAll')
     });
    this.assetTransactionForm = new FormGroup({
       assetTransactionId: new FormControl(0),
       assignedDate: new FormControl(new Date()),
       movementType:new FormGroup({lookupId : new FormControl(0), lookupCode: new FormControl('ASSET_MOVEMENT_TYPE'), lookupValue: new FormControl('')}),
       assignedType:new FormGroup({lookupId : new FormControl(0), lookupCode: new FormControl('ASSET_ASSIGN_TYPE'), lookupValue: new FormControl('')}),
       assignedId: new FormControl(0),
       assignedFromType:new FormGroup({lookupId : new FormControl(0), lookupCode: new FormControl('ASSET_ASSIGN_TYPE'), lookupValue: new FormControl('')}),
       assignedFromId: new FormControl(0),
       t001UserCreatedBy: new FormGroup({userId: new FormControl()}),
       t001UserUpdatedBy: new FormGroup({userId: new FormControl()}),
       createdTs: new FormControl(new Date()),
       updatedTs: new FormControl(new Date()),
       status:new FormControl(true),
       assetId: new FormControl(0),
         parent:new FormControl(true),
       availableQuantity: new FormControl(1),
       assetNumber: this.assetForm.get("assetNumber"),
       assetName:this.assetForm.get("assetName"),
       warehouseForm: this.warehouseForm,
       t800Employee: this.t800Employee,
        warehouseFormFrom: this.warehouseFormFrom,
       t800EmployeeFrom: this.t800EmployeeFrom,
       warehouseBlockFormFrom: this.warehouseBlockFormFrom,
       warehouseRoomFormFrom: this.warehouseRoomFormFrom,
       warehouseBlockForm: this.warehouseBlockForm,
       warehouseRoomForm: this.warehouseRoomForm,
       vendorName: new FormControl(''),
       customerName: new FormControl('')
     });

    this.editId = data.editId;
    this.tranType = data.transaction;
    this.refreshGrid = data.refreshGrid;
    this.transactionType = data.transaction;

    if(this.commonUtils.checkNullOrUndefined(data.assetData)){
      this.assetData = data.assetData;
    }
     
    
 }


	ngOnInit(){
  
 /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
   //  this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
          this.pageLoad();
         }
       });
/** code ends above **/ 
 if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
this.pageLoad();
  
 	}
pageLoad():void
{
    if(this.commonUtils.checkNullOrUndefined(this.editId)){
       if(this.editId != 0){
         this.fetchEditAsset();  
       }
    }

    this.fetchFromLookup(this.assetTransactionForm.get("movementType"));
    this.fetchFromLookup(this.assetTransactionForm.get("assignedType"));
    this.fetchWarehouse();
    this.fetchEmployees();
    
}
patchAssetLastTransaction(data)
{
  
  
  if(this.commonUtils.checkNullOrUndefined(data.assetTransCount) && data.assetTransCount === 0){
     if(this.assetTransactionForm.get('assignedFromType').get('lookupValue').value == 'VENDOR'){
            this.assetTransactionForm.get('vendorFormFromGroup.contactDisplayName').setValue(data.vendorName);
       }
    }
   if(this.commonUtils.checkNullOrUndefined(data.assetTransCount) && data.assetTransCount>0){
        this.assetTransCount = data.assetTransCount;
        if(data.lastAssetTransToType === 'SUB_WAREHOUSE_LOCATION'){
          this.disableSubWarehouseBlock = true;
          this.assetTransactionForm.get("assignedFromType.lookupValue").setValue(data.lastAssetTransToType);
          let locationForm = new FormGroup({
            locationId: new FormControl(data.lastAssetTransToId)
          });
            this.commonService.fetchLocation(locationForm.value).subscribe(
              result => { 
                  if(this.commonUtils.checkNullOrUndefined(result) 
                    && result.length>0 && this.commonUtils.checkNullOrUndefined(result[0].locParentIdList)){
                     let parentIds = result[0].locParentIdList.split(',');
                     let parentNames = result[0].locParentNameList.split(',');
                     
              this.enableWareHouseBlockFrom = true;
              this.enableWareHouseRoomFrom = true; 
                      
             this.loadWarehouseBlockFromList(data.lastAssetTransToId);
                     this.assetTransactionForm.get('warehouseBlockFormFrom.locationId').setValue(parseInt(parentIds[0]));
                     this.assetTransactionForm.get('warehouseFormFrom.locationId').setValue(parseInt(parentIds[1]));
                     this.assetTransactionForm.get('warehouseRoomFormFrom.locationId').setValue(parseInt(data.lastAssetTransToId));
                    
                     let selectWareHouseObj = new FormGroup({
                         locationId: new FormControl(parseInt(parentIds[1])),
                         locationName: new FormControl(parentNames[1])
                     });
                    this.selectionClicked(selectWareHouseObj.value);

                     let selectWareHouseBlockObj = new FormGroup({
                                 locationId: new FormControl(parseInt(parentIds[0])),
                                 locationName: new FormControl(parentNames[0])
                             });
                    this.selectedWarehouseBlockFrom(selectWareHouseBlockObj.value);

                     let selectWareHouseRoomObj = new FormGroup({
                                 locationId: new FormControl(parseInt(result[0].locationId)),
                                 locationName: new FormControl(result[0].locationName)
                             });
                    this.selectedWarehouseRoomFrom(selectWareHouseRoomObj.value);

                     this.assetTransactionForm.get('warehouseBlockFormFrom.locationName').setValue(parentNames[0]);
                     this.assetTransactionForm.get('warehouseFormFrom.locationName').setValue(parentNames[1]);
                     this.assetTransactionForm.get('warehouseRoomFormFrom.locationName').setValue(result[0].locationName);
              
                }
               },error => {
                 this.commonService.showERPErrorMessage(error);
               });
        }  
            if(data.lastAssetTransToType === 'WAREHOUSE'){
              /*
              this.assetTransactionForm.get("assignedFromId").setValue(selectedContact.locationId);
      this.warehouseFormFrom.get("locationId").setValue(selectedContact.locationId)
      this.warehouseFormFrom.get("locationName").setValue(selectedContact.locationName);
           this.enableWareHouseBlockFrom = false;
      this.enableWareHouseRoomFrom = false;
              */
              this.disableWarehouseBlock = true;
              //warehouseFormFrom
               this.assetTransactionForm.get("assignedFromId").setValue(parseInt(data.lastAssetTransToId));
                this.assetTransactionForm.get("warehouseFormFrom.locationId").setValue(parseInt(data.lastAssetTransToId));
                //this.assetTransactionForm.get("warehouseFormFrom.locationName").setValue(parseInt(data.lastAssetTransToId));
            }
            
            if(data.lastAssetTransToType === 'CUSTOMER'){
              this.assetTransactionForm.get('customerName').setValue(data.lastAssetTransContactName);
              this.assetTransactionForm.get('customerName').disable();
            } 
            if(data.lastAssetTransToType === 'VENDOR'){
              this.assetTransactionForm.get('vendorName').setValue(data.lastAssetTransContactName);
              this.assetTransactionForm.get('vendorName').disable();
            } 
      }  

    
       if(this.commonUtils.checkNullOrUndefined(this.assignmentTypeList) && this.assetTransCount > 0){ 
                if(this.commonUtils.checkNullOrUndefined(data.lastAssetTransToId)){
                    this.lastAssetTransToId = data.lastAssetTransToId;
                    this.assetTransactionForm.get('assignedFromId').setValue(data.lastAssetTransToId);

                  }  
                    if(this.commonUtils.checkNullOrUndefined(data.lastAssetTransFromId)){
        this.lastAssetTransFromId = data.lastAssetTransFromId;
      }  
      if(this.commonUtils.checkNullOrUndefined(data.lastAssetTransFromType)){
        this.lastAssetTransFromType = data.lastAssetTransFromType;
      }  
      if(this.commonUtils.checkNullOrUndefined(data.lastAssetTransMoveType)){
        this.lastAssetTransMoveType = data.lastAssetTransMoveType;
      }  
                     if(this.commonUtils.checkNullOrUndefined(data.lastAssetTransToType)){
                    this.lastAssetTransToType = data.lastAssetTransToType;

                    this.assetTransactionForm.get("assignedFromType.lookupValue").setValue(data.lastAssetTransToType);
                    this.assetTransactionForm.get("assignedFromType.lookupId").setValue(data.lastAssetTransToId);
                   
                    for(var loopCount = 0 ; loopCount<this.assignmentTypeList.length; loopCount++){
                     
                      if(this.lastAssetTransToType === this.assignmentTypeList[loopCount].lookupValue){
                        
                         this.assetTransactionForm.get('assignedFromType').patchValue(this.assignmentTypeList[loopCount]);
                        break;
                      }
                    }
                  } 

            }

    

    
}
  AssignmentTypeSelected(selectedAssignmentType): void 
  {
    this.assetTransactionForm.get('assignedType').patchValue(selectedAssignmentType);
    this.enableWareHouseBlock = false;
      this.enableWareHouseRoom = false;
  }
   AssignmentFromSelected(selectedAssignmentType): void 
  {
    if(selectedAssignmentType.lookupValue === 'VENDOR' && this.commonUtils.checkNullOrUndefined(this.assetData.selectedVendorId)){

      this.assetTransactionForm.get('vendorFormFromGroup.contactId').setValue(parseInt(this.assetData.selectedVendorId));
      this.assetTransactionForm.get('vendorFormFromGroup.contactDisplayName').setValue(this.assetData.vendorName);
      this.assetTransactionForm.get("assignedFromId").setValue(parseInt(this.assetData.selectedVendorId));
    }
    if(selectedAssignmentType.lookupValue === 'EMPLOYEE' && this.commonUtils.checkNullOrUndefined(this.assetData.lastAssetTransToId)){

      this.assetTransactionForm.get('vendorFormFromGroup.contactId').setValue(parseInt(this.assetData.lastAssetTransToId));
      this.assetTransactionForm.get('vendorFormFromGroup.contactDisplayName').setValue(this.assetData.vendorName);
      this.assetTransactionForm.get("assignedFromId").setValue(parseInt(this.assetData.selectedVendorId));
    }
     this.assetTransactionForm.get('assignedFromType').patchValue(selectedAssignmentType); 
     this.enableWareHouseBlockFrom = false;
      this.enableWareHouseRoomFrom = false;
  }
 
  movementTypeSelected(selectedMovementType):void{
    this.assetTransactionForm.get('movementType.lookupValue').setValue(selectedMovementType.lookupValue);
    this.assetTransactionForm.get('movementType.lookupId').setValue(selectedMovementType.lookupId);
  }

  fetchFromLookup(lookupObj): void {
     this.commonService.fetchFromLookup(lookupObj.value).subscribe(
        data => {
           if (this.commonUtils.checkNullOrUndefined(data)) {
             if(data != null && data != undefined && data.length > 0){
               if (data[0].lookupCode === 'ASSET_MOVEMENT_TYPE') {
                 this.movementTypeList = data;
                    for(var loop=0 ; loop < data.length; loop++){
                     let lookUpObj = data[loop];
                      if(lookUpObj.lookupValue === this.tranType){
                        this.assetTransactionForm.get("movementType").patchValue(lookUpObj);
                      }
                   }
               }else if (data[0].lookupCode === 'ASSET_ASSIGN_TYPE') {
                 this.assignmentTypeList = data;
                 this.patchAssetLastTransaction(this.assetData);
               }
             }
            }
       },error => {
         this.commonService.showERPErrorMessage(error);
       });
  }

   fetchWarehouse(): void {
     this.commonService.fetchLocation(this.warehouseForm.value).subscribe(
        data => {
          this.warehouseList =data;
                    
         },error => {
           this.commonService.showERPErrorMessage(error);
         });
   }

  fetchEmployees() {

    this.hrmService.fetchEmployee(this.t800Employee.value).subscribe(
      data => {
         if (this.commonUtils.checkNullOrUndefined(data)) {
           this.employeeList = data;
           this.patchEmployeeTransaction();
         }
       },
        error => {
        this.commonService.showERPErrorMessage(error);
      });
   }
    patchEmployeeTransaction(){
      let data = this.assetData;
      if(data.lastAssetTransToType === 'EMPLOYEE'){
        this.disableEmployeeBlock = true;
               this.assetTransactionForm.get("assignedFromId").setValue(parseInt(data.lastAssetTransToId));
                this.t800EmployeeFrom.get("employeeId").setValue(parseInt(data.lastAssetTransToId));
                let empId = parseInt(data.lastAssetTransToId);
                for(var loopCount = 0; loopCount < this.employeeList.length; loopCount++){
                  if(this.employeeList[loopCount].employeeId === empId)  {
                      this.t800EmployeeFrom.get("firstName").setValue(this.employeeList[loopCount].firstName);    
                      break;
                  }
                }
                
            }
    }
 contactClicked(selectedContact: any): void
  {
    
  
   if((this.assetTransactionForm.get("assignedType.lookupValue").value === 'CUSTOMER')
    || (this.assetTransactionForm.get("assignedType.lookupValue").value === 'VENDOR')){
      this.assetTransactionForm.get("assignedId").setValue(selectedContact.contactId);
   }
   if((this.assetTransactionForm.get("assignedType.lookupValue").value === 'EMPLOYEE'))
    {
      
      this.assetTransactionForm.get("assignedId").setValue(selectedContact.employeeId);
      this.t800Employee.get("employeeId").setValue(selectedContact.employeeId);
      this.t800Employee.get("firstName").setValue(selectedContact.firstName);
     
    }
   if((this.assetTransactionForm.get("assignedType.lookupValue").value === 'WAREHOUSE')){
      this.assetTransactionForm.get("assignedId").setValue(selectedContact.locationId);
      this.warehouseForm.get("locationId").setValue(selectedContact.locationId)
      this.warehouseForm.get("locationName").setValue(selectedContact.locationName);
       this.enableWareHouseBlock = false;
      this.enableWareHouseRoom = false;
    } 
    if((this.assetTransactionForm.get("assignedType.lookupValue").value === 'SUB_WAREHOUSE_LOCATION')){
    //  this.assetTransactionForm.get("assignedId").setValue(selectedContact.locationId);
      this.warehouseForm.get("locationId").setValue(selectedContact.locationId)
      this.warehouseForm.get("locationName").setValue(selectedContact.locationName);
       
      this.loadWarehouseBlockList(selectedContact.locationId);
      this.enableWareHouseBlock = true;
      this.enableWareHouseRoom = true;
   }
      
 }
 loadWarehouseBlockList(locationId){
   
  let warehouseForm = new FormGroup({
    t028_LocationObj: new FormGroup({
      locationId: new FormControl(locationId)
    }),
    level: new FormControl("2")
  });
      this.commonService.fetchLocation(warehouseForm.value).subscribe(
        data => {
          this.warehouseBlockList = data;
             this.enableWareHouseBlock = true;       
         },error => {
           this.commonService.showERPErrorMessage(error);
         });
 }
  loadWarehouseBlockFromList(locationId){
    
  let warehouseForm = new FormGroup({
    t028_LocationObj: new FormGroup({
      locationId: new FormControl(locationId)
    }),
    level: new FormControl("2")
  });
      this.commonService.fetchLocation(warehouseForm.value).subscribe(
        data => {
          this.warehouseBlockFromList = data;
             this.enableWareHouseBlockFrom = true;       
         },error => {
           this.commonService.showERPErrorMessage(error);
         });
 }
 selectedWarehouseBlock(locationObj){
   
   let warehouseForm = new FormGroup({
    t028_LocationObj: new FormGroup({
      locationId: new FormControl(locationObj.locationId)
    }),
    level: new FormControl("3")
  });
      this.commonService.fetchLocation(warehouseForm.value).subscribe(
        data => {
          this.warehouseRoomList = data;
             this.enableWareHouseRoom = true;       
         },error => {
           this.commonService.showERPErrorMessage(error);
         });
 }
  selectedWarehouseBlockFrom(locationObj){
   
   let warehouseForm = new FormGroup({
    t028_LocationObj: new FormGroup({
      locationId: new FormControl(locationObj.locationId)
    }),
    level: new FormControl("3")
  });
      this.commonService.fetchLocation(warehouseForm.value).subscribe(
        data => {
          this.warehouseRoomFromList = data;
             this.enableWareHouseRoomFrom = true;       
         },error => {
           this.commonService.showERPErrorMessage(error);
         });
 }
 selectionClicked(selectedContact: any): void
 {
 
  
   if((this.assetTransactionForm.get("assignedFromType.lookupValue").value === 'CUSTOMER')
    || (this.assetTransactionForm.get("assignedFromType.lookupValue").value === 'VENDOR')){

      this.assetTransactionForm.get("assignedFromId").setValue(selectedContact.contactId);
   }
   if((this.assetTransactionForm.get("assignedFromType.lookupValue").value === 'EMPLOYEE'))
   {
     
      this.assetTransactionForm.get("assignedFromId").setValue(selectedContact.employeeId);
      this.t800EmployeeFrom.get("employeeId").setValue(selectedContact.employeeId);
      this.t800EmployeeFrom.get("firstName").setValue(selectedContact.firstName);
      
   }
   if((this.assetTransactionForm.get("assignedFromType.lookupValue").value === 'WAREHOUSE')){
      this.assetTransactionForm.get("assignedFromId").setValue(selectedContact.locationId);
      this.warehouseFormFrom.get("locationId").setValue(selectedContact.locationId)
      this.warehouseFormFrom.get("locationName").setValue(selectedContact.locationName);
           this.enableWareHouseBlockFrom = false;
      this.enableWareHouseRoomFrom = false;
   }if((this.assetTransactionForm.get("assignedFromType.lookupValue").value === 'SUB_WAREHOUSE_LOCATION')){
      //this.assetTransactionForm.get("assignedId").setValue(selectedContact.locationId);
      this.warehouseForm.get("locationId").setValue(selectedContact.locationId)
      this.warehouseForm.get("locationName").setValue(selectedContact.locationName);
     this.loadWarehouseBlockFromList(selectedContact.locationId);
     this.enableWareHouseBlockFrom = true;
      this.enableWareHouseRoomFrom = true;
   }
  
          
 }
selectedWarehouseRoom(location){
  this.assetTransactionForm.get("assignedId").setValue(location.locationId);
}
selectedWarehouseRoomFrom(location){
   this.assetTransactionForm.get("assignedFromId").setValue(location.locationId);
}
 cancelClick():void
 {
   /*if(this.refreshGrid === 'Y'){
     this.router.navigate(["/assetmodule/reviewasset"]);  
   }*/
   this.dialogRef.close();
 }

 fetchEditAsset():void{
    this.assetForm.get("assetHeaderId").setValue(this.editId);
    this.assetTrackingService.fetchAssets(this.assetForm.value).subscribe(
    data => {
       if (data != null && data.length >0) 
       {
         if(this.commonUtils.checkNullOrUndefined(data[0]) && this.commonUtils.checkNullOrUndefined(data[0].quantity))
           this.availableQty =  data[0].quantity;
         
         this.assetTransactionForm.get("assetId").setValue(data[0].assetHeaderId);
         this.assetForm.patchValue(data[0]);
       }
    }, error => {
        this.commonService.showERPErrorMessage(error);
    });
  }


  saveOrUpdateAssetTransaction():void{
    this.disableFlag=true;
    this.userObj = this.commonService.loggedInUserObj.userId;
    let saveFlag='';

  if(this.transactionType == "CHECK OUT" && Number.parseInt(this.availableQty.toString()) < this.assetTransactionForm.get('availableQuantity').value){
    this.commonService.showERPMessage("Please enter quantity less than available quantity");
    saveFlag='N';
    this.disableFlag=false;
  }else{
    //checkIn
    saveFlag='Y';
  }

  if(saveFlag === 'Y'){
    if(this.assetTransactionForm.get('assetTransactionId').value === 0 ||(!this.commonUtils.checkNullOrUndefined(this.assetTransactionForm.get('assetTransactionId').value)))
    {
      this.assetTransactionForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.assetTransactionForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    if(this.assetTransactionForm.get('assetTransactionId').value != 0 && this.assetTransactionForm.get('assetTransactionId').value != null) {
      this.assetTransactionForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
   
if(this.commonUtils.checkNullOrUndefined(this.assetTransactionForm) && this.commonUtils.checkNullOrUndefined(this.assetTransactionForm.get('availableQuantity').value)) {
      this.assetTransactionForm.get('availableQuantity').setValue(this.assetTransactionForm.get('availableQuantity').value);
    }

    if(this.assetTransactionForm.valid) {
      this.commonService.showProgressBar();
     this.assetTrackingService.saveOrUpdateAssetTransaction(this.assetTransactionForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
        this.disableFlag=false;
        this.cancelClick();
      },error => {
        this.disableFlag=false;
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
        
      });
    }
  }

  }

}
