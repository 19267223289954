import { Component, OnInit, Inject} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {FileUploadComponent} from '../../dialogBox/fileupload/file-upload.component';
import {ReviewIssuesComponent} from '../review-issues/review-issues.component';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component';

@Component({
  selector: 'app-createissues',
  templateUrl: './createissues.component.html'
})
export class CreateissuesComponent extends CommonUtils implements OnInit {

  issueForm: FormGroup;
  loggedInUserId;
  loggedInUserName;
  organizationId;
  loggedInEmail;
  uploadedFileArrayparent:Array<any>=[];
  moduleName:string = environment.NEW_ISSUES;
  constructor(public dialogRef: MatDialogRef<CreateissuesComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  	private commonService: CommonService, private commonUtils: CommonUtils, private dialog: MatDialog,
    public dialogRefReview: MatDialogRef<ReviewIssuesComponent>,private router: Router) { 
  	super();
  	this.issueForm = new FormGroup({
  		issueOrFeedbackId: new FormControl(0),
  		description: new FormControl(''),
  		t001_UserCreatedByName: new FormGroup({userName: new FormControl(''), 
  		t000Organization: new FormGroup({organizationId : new FormControl(0)})}),
  		createdTs: new FormControl(new Date()),
  		fileUpload:new FormControl(''),
  		moduleName: new FormControl(this.moduleName),
      issueStatusId:new FormGroup({lookupId: new FormControl(0)}),
      statusText: new FormControl('OPEN'),
      portalName : new FormControl(''),
      createdBy : new FormControl(''),
      createdByEmail:new FormControl(''),
      taskNumber: new FormControl('')

  	})
  }

  ngOnInit() {
  	this.loggedInUserId = this.commonService.loggedInUserObj.userId;
  	this.organizationId = this.commonService.loggedInUserObj.t000Organization.organizationId;
    this.loggedInUserName = this.commonService.loggedInUserObj.userName;
     this.loggedInEmail = this.commonService.loggedInUserObj.email;
  }

  cancelClick(): void{
  	this.dialogRef.close();
  }

  saveIssuesOrFeedback(): void{
    this.commonService.showProgressBar();
    let userDescription=this.issueForm.get('description').value.trim();
   if(this.commonUtils.checkNullOrUndefined(userDescription) && userDescription !="")
   {
      this.issueForm.get('portalName').setValue(this.commonService.loggedInUserObj.t000Organization.portalName);
      this.issueForm.get('createdBy').setValue(this.loggedInUserName);
      this.issueForm.get('createdByEmail').setValue(this.loggedInEmail);
 //   this.issueForm.get('t001_UserCreatedByName.t000Organization.organizationId').setValue(this.organizationId);
     this.commonService.saveIssuesOrFeedback(this.issueForm.value).
      subscribe(
       data => 
       {
         if(this.commonUtils.checkNullOrUndefined(data))
         {
           this.cancelClick();
           this.dialogRef.close(true);
           this.commonService.hideProgressBar();
           this.commonService.showERPMessage("Thank you for sharing your feedback/suggestions.");
         }        
       },
       error => {
         this.commonService.showERPErrorMessage(error);
          this.commonService.hideProgressBar();
         this.dialogRef.close(true);
       });
    /*  let issueList = [];
      this.issueForm.get('portalName').setValue("esalescorp");
      issueList.push(this.issueForm.value);
      
      this.commonService.convertToTask(issueList).subscribe(
              data => 
              {
                this.cancelClick();
                this.dialogRef.close(true);
                this.commonService.hideProgressBar();
                this.commonService.showERPMessage("Thank you for sharing your feedback/suggestions. Our team will analyze this and get back to you.The task # is " +data[0].taskNumber+ ".");
              },
              error => 
              {
               this.commonService.hideProgressBar();
               this.commonService.showERPErrorMessage(error);
      });*/
   }
   else
   {
     this.commonService.hideProgressBar();
     const dialogRef = this.dialog.open(ERPPromptComponent, {
      width: '500px',
      data: {dialogText: 'Please share your feedback/suggestions to proceed.', confButton1: 'OK', confButton2: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
     });
  }
   

    
  }

  attachFiles(){
  	const dialogRef = this.dialog.open(FileUploadComponent, {
      width: '45%',
      data: {dialogLabel: "Upload Issue",uploadedFileArray:this.uploadedFileArrayparent}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.done === true) {
        this.uploadedFileArrayparent=result.uploadedArray;
        if(this.uploadedFileArrayparent !=null && this.uploadedFileArrayparent.length>0){
          var fileUploaded = "";
          for(let loop =0;loop < this.uploadedFileArrayparent.length ;loop++){             
            fileUploaded = fileUploaded+this.uploadedFileArrayparent[loop].name+",";
          }
          fileUploaded = fileUploaded.substring(0,fileUploaded.length-1);       
          this.issueForm.get('fileUpload').setValue(fileUploaded);
        }        
      } 
    });
  }
  loadFeedBack()
  {
     /*const dialogRef = this.dialog.open(ReviewIssuesComponent,
        {
          width: '90%',
          height: '90%'
        });*/
  
    this.dialogRef.close();
      this.router.navigate(['/settings/reviewIssues']);
  }
}
