<mat-card class="col-lg-12 createcontact">
<mat-card-title  class="mat-card-title cardheadertitle">
<mat-icon>shop</mat-icon>{{CREATE_LICENSE}}</mat-card-title>
<form  (ngSubmit)="saveOrUpdateLicense()"  [formGroup]="licenseForm">
	<mat-card-content>
	<div class="col-lg-12">
		<div class="col-lg-12">
			
			<mat-form-field class="col-lg-6">
			<input matInput placeholder="{{LICENSE_NAME}}" formControlName="licenseName">
			</mat-form-field>
		</div>
		<div class="col-lg-12">
			<mat-form-field class="col-lg-3">
			<input matInput placeholder="{{PRIMARY_CONTACT_NAME}}" formControlName="primaryContactName">
			</mat-form-field>
			<mat-form-field class="col-lg-3">
			<input matInput placeholder="{{PRIMARY_CONTACT_EMAIL}}"  matTooltip="{{EMAIL_ADDRESS_ALERT}}" formControlName="primaryContactEmailAddress">
			<mat-hint #elseBlock *ngIf="(emailStatus | async)?.length>0" class="discountDivColor"> {{EMAIL_EXISTS_ALERT}}</mat-hint>
              </mat-form-field>
			</div>
		<div class="col-lg-12">
			<mat-form-field class="col-lg-3" formGroupName="t003Currency">
			<input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_CURRENCY}}" matInput formControlName="currencyCode" [matAutocomplete]="currency"><b class="caret" style="margin-left: -13px;"></b>
			<mat-autocomplete #currency="matAutocomplete" (optionSelected)="currencySelected($event.option)">
			<mat-option *ngFor="let currency of  currencyList" [value]="currency.currencyId">{{currency.currencyCode }}-{{currency.currencyValue}}</mat-option>
			</mat-autocomplete>
			</mat-form-field>
			<mat-form-field class="col-lg-3" formGroupName="t012Language">
			<input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_LANGUAGE}}" matInput formControlName="name" [matAutocomplete]="lang"><b class="caret" style="margin-left: -13px;"></b>
			<mat-autocomplete #lang="matAutocomplete" (optionSelected)="languageSelected($event.option)">
			<mat-option *ngFor="let lang of languages" [value]="lang.langId">{{lang.name}}</mat-option>
			</mat-autocomplete>
			</mat-form-field>
		</div>
		<div class="col-lg-12">
			<mat-form-field class="col-lg-3" formGroupName="t004LookuptimeZoneId">
			<input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_TIMEZONE}}" matInput formControlName="lookupValue" [matAutocomplete]="zone"><b class="caret" style="margin-left: -13px;"></b>
			<mat-autocomplete #zone="matAutocomplete" (optionSelected)="timeZoneSelected($event.option)">
			<mat-option *ngFor="let zone of timezones" [value]="zone.lookupId">{{zone.lookupValue}}</mat-option>
			</mat-autocomplete>
			</mat-form-field>
			<mat-form-field class="col-lg-3" formGroupName="t004LookupDateFormatId">
			<input class="inputWidth" type="text" aria-label="Number" placeholder="{{SELECT_DATE_FORMAT}}" matInput formControlName="lookupValue" [matAutocomplete]="dateFrmt"><b class="caret" style="margin-left: -13px;"></b>
			<mat-autocomplete #dateFrmt="matAutocomplete" (optionSelected)="dateFormatSelected($event.option)">
			<mat-option *ngFor="let dateformat of dateFormats" [value]="dateformat.lookupId">{{dateformat.lookupValue}}</mat-option>
			</mat-autocomplete>
			</mat-form-field>
		</div>
		<div >
			
			<div class="col-lg-6" >
				<div class="col-lg-6"><b>{{BILLING_ADDRESS}}</b></div>
				<div class="col-lg-12" >
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{ATTENTION}}" formControlName="billingAttention">
					</mat-form-field>
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{STREET1}}" formControlName="billingAddressLineOne">
					</mat-form-field>
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{STREET2}}" formControlName="billingAddressLineTwo">
					</mat-form-field>
					<mat-form-field class="col-lg-6" formGroupName="t010BillingCountry">
					<mat-select placeholder="{{COUNTRY}}" formControlName="countryId">
					<mat-option *ngFor="let country of countryList" [value]="country.countryId">
					{{country.countryValue}}
					</mat-option>
					</mat-select>
					</mat-form-field>
					
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{STATE}}"  formControlName="billingState">
					</mat-form-field>
					<div class="col-lg-12 nopadding">
						<mat-form-field class="col-lg-6">
						<input matInput  placeholder="{{ZIP_CODE}}"  myNumberOnly formControlName="billingZipCode" (blur)="allowNumeric('billingZipCode')">
						</mat-form-field>
						<mat-form-field class="col-lg-6">
						<input matInput placeholder="{{CITY}}" formControlName="billingCity">
						</mat-form-field>
					</div>
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{PHONE}}"  [textMask]="{mask: mask}" formControlName="billingPhone">
					</mat-form-field>
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{FAX}}"  myNumberOnly formControlName="billingFax" (blur)="allowNumeric('billingFax')">
					</mat-form-field>
				</div>
			</div>
		</div>
		<div >
			<div class="col-lg-6" >
				<div class="col-lg-6"><b>{{SHIPPING_ADDRESS}}</b></div>
				<div class="col-lg-6"><a style="cursor: pointer;float: right;" (click)="copyAsBillingAddress()">{{ADDRESS_MEESAGE}}</a></div>
				<div class="col-lg-12" >
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{ATTENTION}}" formControlName="shippingAttention">
					</mat-form-field>
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{STREET1}}"  formControlName="shippingAddressLineOne">
					</mat-form-field>
					<mat-form-field class="col-lg-12">
					<input matInput placeholder="{{STREET2}}" formControlName="shippingAddressLineTwo">
					</mat-form-field>
					<mat-form-field class="col-lg-6" formGroupName="t010ShippingCountry">
					<mat-select placeholder="{{COUNTRY}}" formControlName="countryId">
					<mat-option *ngFor="let country of countryList" [value]="country.countryId">
					{{country.countryValue}}
					</mat-option>
					</mat-select>
					</mat-form-field>
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{STATE}}" formControlName="shippingState">
					</mat-form-field>
					<div class="col-lg-12 nopadding">
						<mat-form-field class="col-lg-6">
						<input matInput placeholder="{{ZIP_CODE}}" myNumberOnly formControlName="shippingZipCode" (blur)="allowNumeric('shippingZipCode')">
						</mat-form-field>
						<mat-form-field class="col-lg-6">
						<input matInput placeholder="{{CITY}}" formControlName="shippingCity">
						</mat-form-field>
					</div>
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{PHONE}}"  [textMask]="{mask: mask}" formControlName="shippingPhone">
					</mat-form-field>
					<mat-form-field class="col-lg-6">
					<input matInput placeholder="{{FAX}}" myNumberOnly formControlName="shippingFax" (blur)="allowNumeric('shippingFax')">
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-lg-12" >
			<mat-accordion>
			<mat-expansion-panel class="col-lg-12" style="padding-right: 0;padding-left: 0;" [expanded]="true">
			<mat-expansion-panel-header class="mat-expansion-panel-custom">
			<mat-panel-title>
			Products
			</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="col-lg-12">
			<div class="col-lg-3">
				<div class="col-lg-12">
					<mat-slide-toggle class="example-margin" color="primary" formControlName="enableERP">{{ERP}}</mat-slide-toggle>
				</div>
				<div class="col-lg-12" formArrayName="erpFormArray" *ngFor="let contact of licenseForm.get('erpFormArray')['controls']; index as idx">
				<!-- <div class="col-lg-4" formArrayName="erpFormArray" *ngFor="let contact of licenseForm.get('erpFormArray')['controls']; index as idx"> -->
				<div [formGroupName]="idx">
					<mat-form-field>
					<input matInput placeholder="{{ORGANIZATION_NAME}}" formControlName="organizationName">
					</mat-form-field>
				</div>
				</div>
			    <div class="col-lg-12">
					 <button type="button" mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="addErpOrganization()"><mat-icon>add</mat-icon>&nbsp;&nbsp;{{ADD_ORGANIZATION}}
                  </button>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="col-lg-12">
					<mat-slide-toggle class="example-margin" color="primary" formControlName="enableCRM">{{CRM}}</mat-slide-toggle>
				</div>
				<div class="col-lg-12" formArrayName="crmFormArray" *ngFor="let contact of licenseForm.get('crmFormArray')['controls']; index as idx">
				<div [formGroupName]="idx">
					<mat-form-field>
					<input matInput placeholder="{{ORGANIZATION_NAME}}" formControlName="organizationName">
					</mat-form-field>
				</div>
				</div>


			</div>
			<div class="col-lg-3">
				<div class="col-lg-12">
					<mat-slide-toggle class="example-margin" color="primary" formControlName="enableWebStore">{{WEB_STORE}}</mat-slide-toggle>
				</div>
				<div class="col-lg-12" formArrayName="webStoreFormArray" *ngFor="let contact of licenseForm.get('webStoreFormArray')['controls']; index as idx">
				<div [formGroupName]="idx">
					<mat-form-field>
					<input matInput placeholder="{{ORGANIZATION_NAME}}" formControlName="organizationName">
					</mat-form-field>
				</div>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="col-lg-12">
					<mat-slide-toggle class="example-margin" color="primary" formControlName="enableEsalesCart">{{ESALES_CART}}</mat-slide-toggle>
				</div>
				<div class="col-lg-12" formArrayName="eCartFormArray" *ngFor="let contact of licenseForm.get('eCartFormArray')['controls']; index as idx">
				<div [formGroupName]="idx">
					<mat-form-field>
					<input matInput placeholder="{{ORGANIZATION_NAME}}" formControlName="organizationName">
					</mat-form-field>
				</div>
				</div>
			</div>
			</div>
			</mat-expansion-panel>
			</mat-accordion>
		</div>
		<div class="dropdown-divider"></div>
		<div class="col-lg-12"  >
			<button mat-raised-button color="primary" type="submit" class="mat-red" [disabled]="!licenseForm.valid">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary" type="button"  (click)="cancelClick()" >{{CANCEL_BUTTON}}</button>
		</div>
	</div>
	</mat-card-content>
</form>
</mat-card>