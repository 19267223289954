import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    /*
      if(this.authService.isAuthenticated())
        return true;
  
      else{
  
      this.router.navigate([''], { queryParams: { returnUrl: state.url }});
      return false;
    } */

  }
  canActivateChild() {
    return true;
  }




}
