import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HrmService} from '../hrm-service';
import {CommonService} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DialogOverviewExampleComponent} from '../../dialogBox/dialogBox.component';
import { DateFormatPipe } from '../../shared/dateFormatPipe.pipe';
@Component({
  selector: 'app-addshiftallocation',
  templateUrl: './create-shift-allocation.component.html'

})
export class CreateShiftAllocationComponent extends CommonUtils implements OnInit {
  shiftAllocationForm: FormGroup;  
  createButtonEnable = true; 
  editId: number;
  userObj;
  myFilter;
  currentlySelectedMenu;
  editAccess = true;
  moduleName : string = environment.NEW_SHIFT_ALLOCATION;
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  });
  shiftsList=[];
  salaryGroupsList=[];
  employeesList=[];
  shiftAllocationObj;
  employeeDateList=[];
  shiftAllocationId=0;
  startDate;
  endDate;
  disableFlag=false;
  employeeIdList=[];
/*  time = {hour: 09, minute: 30};
  meridian = true;*/
  constructor(public dialog: MatDialog,private hrmService: HrmService, public snackBar: MatSnackBar, private router: Router,
    private commonService: CommonService, private commonUtils: CommonUtils, private datePipe: DateFormatPipe,
    public dialogRef: MatDialogRef<CreateShiftAllocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.shiftAllocationObj=data;
    if(this.commonUtils.checkNullOrUndefined(data.shiftAllocationId) && data.shiftAllocationId!=0){
      this.shiftAllocationId=data.shiftAllocationId;
      this.startDate=data.startDate;
      this.endDate=data.endDate;

    }
    this.shiftAllocationForm = new FormGroup({
      shiftAllocationId: new FormControl(0),
      t800Employee: new FormGroup({ employeeId: new FormControl(0), firstName: new FormControl(''), lastName: new FormControl(''), userName: new FormControl(''),
      t818SalaryGroup:new FormGroup({ salaryId: new FormControl(0), salaryGroupName: new FormControl('') })}),
      t818SalaryGroup:new FormGroup({ salaryId: new FormControl(0), salaryGroupName: new FormControl('') }),
      t834ShiftMaster:new FormGroup({ shiftMasterId: new FormControl(0), shiftName: new FormControl('') }),   
      startDate :new FormControl(new Date()),
      endDate : new FormControl(new Date()),  
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
      paginationLoadFlag:new FormControl(),
      t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE EMPLOYEE')}),
      requestFlag:new FormControl(),
      employeeIdList: new FormControl()
    }
    )
  }
  ngOnInit() 
  {
  /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
   //  this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu
           this.pageLoad();
           
         }
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
 this.pageLoad();

  }
  pageLoad():void
  {
    this.fetchSalaryGroup();
    this.fetchShift();
    

   try
    {
     if(this.commonUtils.checkNullOrUndefined(this.shiftAllocationObj)){
      this.bindShiftAllocation();
     }
    
   }
catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{});
}
  }
  fetchEmployee(salaryGroupObj){
    this.shiftAllocationForm.get('requestFlag').setValue('FetchAll');
    this.shiftAllocationForm.get('t800Employee.t818SalaryGroup.salaryId').setValue(salaryGroupObj.salatyId);
    this.commonService.showProgressBar();
    this.hrmService.fetchActiveEmployeeForSalary(this.shiftAllocationForm.value).subscribe(
      data => {
        this.employeesList = data;
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
  fetchSalaryGroup() : void{
     this.commonService.showProgressBar();
     this.hrmService.fetchSalary().subscribe(
      data => {
        this.salaryGroupsList = data;
        if(this.commonUtils.checkNullOrUndefined(this.salaryGroupsList) && this.shiftAllocationId !=0)
        {
          this.fetchEmployee(this.shiftAllocationObj.t818SalaryGroup);
          this.fetchEmployeeShift();
        }
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
  bindShiftAllocation():void
  {  
      this.shiftAllocationForm.patchValue(this.shiftAllocationObj);
      this.commonUtils.patchDates(this.shiftAllocationForm,'startDate',this.shiftAllocationObj.startDate);
      this.commonUtils.patchDates(this.shiftAllocationForm,'endDate',this.shiftAllocationObj.endDate);      
  }
 
  fetchShift() : void{
     this.hrmService.fetchShiftMaster(this.shiftAllocationForm.value).subscribe(
      data => {
        this.shiftsList = data;
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
  saveOrUpdateShiftAllocation(): void 
  {
    this.userObj = this.commonService.loggedInUserObj.userId;
    if (this.shiftAllocationForm.get('shiftAllocationId').value === 0 || (!this.commonUtils.checkNullOrUndefined(this.shiftAllocationForm.get('shiftAllocationId').value))) {
      this.shiftAllocationForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.shiftAllocationForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    if (this.shiftAllocationForm.get('shiftAllocationId').value != 0) {
      this.shiftAllocationForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    if (this.shiftAllocationForm.get('shiftAllocationId').value !=0){
    this.commonService.showProgressBar();
    this.hrmService.saveOrUpdateShiftAllocation(this.shiftAllocationForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
        this.dialogRef.close(true);        
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
    } else {
      this.fetchEmployeeShiftExist();
    }
    
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  fetchEmployeeShift(){
      this.employeeDateList=[];
      this.shiftAllocationForm.get('shiftAllocationId').setValue(0);
       this.commonService.showProgressBar();
        this.hrmService.fetchShiftAllocation(this.shiftAllocationForm.value).subscribe(
         data => {
           this.employeeDateList=data;
           if(this.shiftAllocationId !=0){
             this.shiftAllocationForm.get('shiftAllocationId').setValue(this.shiftAllocationId);
           }
           this.commonService.hideProgressBar();
           this.commonService.showERPMessage(data.erpmessage);
         },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
       });
  }

  checkDate(type: string, event: MatDatepickerInputEvent<Date>) {
    var shiftName;
      let startNewDate= new Date(this.shiftAllocationForm.get('startDate').value).getTime();
      let endNewDate= new Date(this.shiftAllocationForm.get('endDate').value).getTime();
      this.commonService.showProgressBar();
           if(this.employeeDateList.length > 0 && this.shiftAllocationId != 0){
                for(var loop = 0; loop < this.employeeDateList.length ; loop++){
                  shiftName = this.employeeDateList[loop].t834ShiftMaster.shiftName;
                 if(this.shiftAllocationId != this.employeeDateList[loop].shiftAllocationId){
                  if(this.commonUtils.checkNullOrUndefined(startNewDate) && this.commonUtils.checkNullOrUndefined(endNewDate)){
                     this.commonService.hideProgressBar();
                         if(startNewDate >= this.employeeDateList[loop].startDate && startNewDate <= this.employeeDateList[loop].endDate){
                           
                            const dialogTextVal = 'Selected dates already exists for this employee in this '+ shiftName+' shift.Please re-edit the dates.';
                            const confTextVal = 'ConfirmShiftDate';
                            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                    width: '500px',
                                    data: {dialogText: dialogTextVal}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                             if (result === true) {
                               this.disableFlag=true;
                            }
                            else{
                                this.cancelClick();
                              }
                            });
                           break;
                         }

                         if(endNewDate >= this.employeeDateList[loop].startDate && endNewDate <= this.employeeDateList[loop].endDate){
                         
                            const dialogTextVal = 'Selected dates already exists for this employee in this '+ shiftName+' shift.Please re-edit the dates.';
                            const confTextVal = 'ConfirmShiftDate';
                            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                    width: '500px',
                                    data: {dialogText: dialogTextVal}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                             if (result === true) {
                               this.disableFlag=true;
                            }
                            else{
                                this.cancelClick();
                              }
                            });
                           break;
                         }
                          
                    }
                  }
                   this.commonService.hideProgressBar();
                }
                 this.disableFlag=false;
              }
              else if(this.employeeDateList.length > 0 && this.shiftAllocationId === 0)
              {
                for(var loop = 0; loop < this.employeeDateList.length ; loop++){
                  shiftName = this.employeeDateList[loop].t834ShiftMaster.shiftName;
                 
                  if(this.commonUtils.checkNullOrUndefined(startNewDate) && this.commonUtils.checkNullOrUndefined(endNewDate)){
                     this.commonService.hideProgressBar();
                         if(startNewDate >= this.employeeDateList[loop].startDate && startNewDate <= this.employeeDateList[loop].endDate){
                            const dialogTextVal = 'Selected dates already exists for this employee in this '+ shiftName+' shift.Please re-edit the dates.';
                            const confTextVal = 'ConfirmShiftDate';
                            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                    width: '500px',
                                    data: {dialogText: dialogTextVal, confText: confTextVal}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                             if (result === true) {
                               this.disableFlag=true;
                            }
                            else{
                                this.cancelClick();
                              }
                            });
                           break;
                         }

                         if(endNewDate >= this.employeeDateList[loop].startDate && endNewDate <= this.employeeDateList[loop].endDate){
                            const dialogTextVal = 'Selected dates already exists for this employee in this '+ shiftName+' shift.Please re-edit the dates.';
                            const confTextVal = 'ConfirmShiftDate';
                            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                    width: '500px',
                                    data: {dialogText: dialogTextVal, confText: confTextVal}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                             if (result === true) {
                               this.disableFlag=true;
                            }
                            else{
                                this.cancelClick();
                              }
                            });
                           break;
                         }
                          
                    }
                   this.commonService.hideProgressBar();
                }
                this.disableFlag=false; 
            }
            
      }
fetchEmployeeShiftExist(){
  this.employeeDateList=[];
  this.shiftAllocationForm.get('shiftAllocationId').setValue(0);
  this.commonService.showProgressBar();
  this.hrmService.fetchShiftAllocation(this.shiftAllocationForm.value).subscribe(
    data => {
      if(this.commonUtils.checkNullOrUndefined(data)){
        this.employeeDateList=data;
        let empList=[];
        let idList=[];
        let empName='';
        for (var loop=0; loop < this.employeeDateList.length; loop++){
          if (this.employeeDateList[loop].employeeCount == 0){
            idList.push(this.employeeDateList[loop].t800Employee.employeeId);
          } else {
            empList.push(this.employeeDateList[loop].t800Employee.employeeId);
            if (this.commonUtils.checkNullOrUndefined(empName))
              empName = empName +', ' + this.employeeDateList[loop].t800Employee.firstName;
            else
            empName = this.employeeDateList[loop].t800Employee.firstName;
          }
        }
        if (idList.length>0){
          this.shiftAllocationForm.get('employeeIdList').setValue('');
          this.shiftAllocationForm.get('employeeIdList').setValue(idList);
          this.hrmService.saveOrUpdateShiftAllocation(this.shiftAllocationForm.value).subscribe(
            data => {
              this.commonService.hideProgressBar();
              if (empList.length>0){
                this.shiftAllocationForm.get('employeeIdList').setValue('');
                this.shiftAllocationForm.get('employeeIdList').setValue(empList);
                const dialogTextVal = 'Selected dates already exists for these employees '+empName+'. Please re-edit the dates.';
                                  const confTextVal = 'ConfirmShiftDate';
                                  const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                          width: '500px',
                                          data: {dialogText: dialogTextVal, confText: confTextVal}
                                  });
                                  dialogRef.afterClosed().subscribe(result => {
                                   if (result === true) {
                                     this.disableFlag=false;
                                  }
                                  else{
                                      this.cancelClick();
                                    }
                                  });
                               
              } else {
              this.commonService.showERPMessage(data.erpmessage);
                this.dialogRef.close(true);        
              }
            }, error => {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);
            });
        } else if (empList.length>0){
          
          const dialogTextVal = 'Selected dates already exists for these employees '+empName+'. Please re-edit the dates.';
                            const confTextVal = 'ConfirmShiftDate';
                            const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                                    width: '500px',
                                    data: {dialogText: dialogTextVal, confText: confTextVal}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                             if (result === true) {
                               this.disableFlag=false;
                            }
                            else{
                                this.cancelClick();
                              }
                            });
                         
        }
        
      }
      
      if(this.shiftAllocationId !=0){
        this.shiftAllocationForm.get('shiftAllocationId').setValue(this.shiftAllocationId);
      }
      this.commonService.hideProgressBar();
      this.commonService.showERPMessage(data.erpmessage);
    },
    error => {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
  });
}
 } 
