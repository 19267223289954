<mat-card class="col-lg-12" style="height: auto;" *ngIf="moduleName != 'VIEW_TASK_HIERARCHY' && moduleName != 'VIEW_EMPLOYEE_HIERARCHY'">
	<mat-card-title>
		<mat-icon>home</mat-icon>Quick Info
		<!-- <button mat-button type="button" *ngIf='historyFlag' style="float: right;" matSuffix mat-icon-button aria-label="Clear" (click)="viewHistory()">
	<mat-icon class="reportLink"  matTooltip="History">history</mat-icon></button> -->
	</mat-card-title>
	<mat-card-content style="margin-top: 2%;">
		<mat-divider></mat-divider>
		<div class="col-lg-12 row" style="margin-top: 2%; width:415px;">
			<div class="col-lg-6">
				<div *ngFor="let labelName of quickInfoLabels" style="height:25px;">
					<label>{{labelName}} </label>
				</div>
			</div>

			<div class="col-lg-6">
				<div *ngFor="let value of quickInfoValues" style="height:25px;">
					<label *ngIf="value.indexOf('Date')==-1 && value.indexOf('Rate')==-1">{{selectedRow[value]}}</label>
					<label *ngIf="value.indexOf('Date')!=-1">{{selectedRow[value] | dateFormatPipe}}</label>
					<label *ngIf="value.indexOf('Rate')!=-1">{{selectedRow[value] | myCurrency}}</label><br>
				</div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="moduleName==='VIEW_PO'&&convtToBill">
			<button mat-raised-button color="primary" (click)="convertToBill()"
				type="submit">{{CONVERT_TO_BILL}}</button>
		</div>

		<div class="col-lg-6" *ngIf="moduleName==='VIEW_ESTIMATE' && statusToConvert">
			<button mat-raised-button color="primary" (click)="convertToSalesOrder()">{{CONVERT_SO}}</button>
		</div>
		<div class="col-lg-6"
			*ngIf="(moduleName==='VIEW_ESTIMATE' || moduleName==='VIEW_SALESORDER') && statusToConvert ">
			<button mat-raised-button color="primary" (click)="convertToInvoice()">{{CONVERT_INVOICE}}</button>
		</div>
	</mat-card-content>
</mat-card>

 <mat-card class="col-lg-12" style="height: auto;" *ngIf="moduleName === 'VIEW_EMPLOYEE_HIERARCHY'">
<mat-card-title ><mat-icon>home</mat-icon> Employee Hierarchy</mat-card-title>
<mat-card-content style="margin-top: 2%;">
	<mat-divider></mat-divider>
	<div class="col-lg-12 row" *ngIf="employeeHierarchy.length > 0" style="margin-top: 2%; width: 430px;">
		<div class="col-lg-12" *ngFor="let value of employeeHierarchy; index as idx;" style="padding: 0px;">
			<div class="col-lg-12 row" *ngIf="value.length > 1">
				<div class="col-lg-12" [ngStyle]="setMarginLeft(idx)">
				<label *ngIf="employeeName != value" class="col-lg-12" [ngStyle]="setMarginLeft(idx)"  >
					<span *ngIf="idx != 0 "> <mat-icon style="color: #3b78e7;">arrow_upward</mat-icon> </span>  {{value}}  </label>
				<label *ngIf="employeeName === value" class="col-lg-12"  [ngStyle]="setMarginLeft(idx)" style="font-weight: bold;">
					<span *ngIf="idx != 0 "> <mat-icon style="color: #3b78e7;">arrow_upward</mat-icon> </span>  {{value}}  </label>
				</div>
			</div> 
		</div>
	</div>
</mat-card-content>
</mat-card>  

<mat-card class="col-lg-12" style="height: auto;" *ngIf="moduleName === 'VIEW_TASK_HIERARCHY'">
	<mat-card-title class="font_size_20 mat_card_title" style="padding-left: 0px;">
		<mat-icon>home</mat-icon> Task Hierarchy
	</mat-card-title>
	<mat-card-content style=" ">
		<mat-divider></mat-divider>
		<div class="col-lg-12 row" *ngIf="taskHierarchyList.length > 0" style="  width: 430px;">
			<div class="col-lg-12" *ngFor="let taskHierarchy of taskHierarchyList; index as idx;" style="padding: 0px;">
				<div class="row">
					<div class="col-lg-12 ">
						<div class="row">
							<div class="col-lg-12 "
								style="margin-left: 0px;padding-top: 5px;text-transform: capitalize;"
								[ngStyle]="setMarginLeft(idx)">
								<label *ngIf="taskName != taskHierarchy.taskName" class="col-lg-12"
									[ngStyle]="setMarginLeft(idx)" style="display: flex;font-size: 14px;">
									<span *ngIf="idx != 0 ">
										<mat-icon style="color: #3b78e7;font-size: 20px;">arrow_upward</mat-icon>
									</span> {{taskHierarchy.taskName}} </label>
								<label *ngIf="taskName === taskHierarchy.taskName" class="col-lg-12"
									[ngStyle]="setMarginLeft(idx)"
									style="font-weight: bold; display: flex;font-size: 14px;">
									<span *ngIf="idx != 0 ">
										<mat-icon style="color: #3b78e7;font-size: 20px;">arrow_upward</mat-icon>
									</span> {{taskHierarchy.taskName}} </label>

							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-12 row" *ngIf="value.length > 1">
				<div class="col-lg-12" [ngStyle]="setMarginLeft(idx)">
				<label *ngIf="taskName != value" class="col-lg-12" [ngStyle]="setMarginLeft(idx)"  >
					<span *ngIf="idx != 0 "> <mat-icon style="color: #3b78e7;">arrow_upward</mat-icon> </span>  {{value}}  </label>
				<label *ngIf="taskName === value" class="col-lg-12"  [ngStyle]="setMarginLeft(idx)" style="font-weight: bold;">
					<span *ngIf="idx != 0 "> <mat-icon style="color: #3b78e7;">arrow_upward</mat-icon> </span>  {{value}}  </label>
				</div>
			</div>  -->


			</div>
		</div>
	</mat-card-content>
</mat-card>