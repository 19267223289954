/**Author -esales -9014  Rajesh Subramanian **/
// import { BrowserModule } from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {DropdowDirective} from './shared/dropdown.directive';
import {MathRoundoffDirective} from './shared/math-roundoff.directive';
import {EsalesMaterialModule} from './esales.material.module';
import {MyCurrencyPipe} from './shared/my-currency.pipe';
import {NumberOnlyDirective} from './shared/numberOnly.directive';
import {OnlyDecimal} from './shared/onlyDecimal.directive';
import {TextMaskModule} from 'angular2-text-mask';
import {NumberAndDotOnlyDirective} from './shared/noAndDotOnly.directive';
import {NumberFormatDirective} from './shared/numberFormat.directive';
import {NumberFormatterPipe} from './shared/numberFormatter.pipe';
import {StatusColorDirective} from './shared/grid-status-color.directive';
import {DateFormatPipe} from './shared/dateFormatPipe.pipe';
import {DateTimeFormatPipe} from './shared/dateTimeFormatPipe.pipe';
import {DateFormatterDirective} from './shared/dateFormatter.directive';
import {FocusDirective} from './shared/focus.directive';

@NgModule({
  declarations: [
    DropdowDirective,
    MathRoundoffDirective,
    MyCurrencyPipe,
    NumberOnlyDirective,
    OnlyDecimal,
    NumberAndDotOnlyDirective,
    NumberFormatDirective,
    NumberFormatterPipe,
    StatusColorDirective,
    DateFormatterDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    FocusDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EsalesMaterialModule, 
    RouterModule,
    DropdowDirective,
    MathRoundoffDirective,
    MyCurrencyPipe,
    NumberOnlyDirective,
    TextMaskModule,
    OnlyDecimal,
    NumberAndDotOnlyDirective,
    NumberFormatDirective,
    NumberFormatterPipe,
    StatusColorDirective,
    DateFormatterDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    FocusDirective
  ]
})


export class SharedModule {}
