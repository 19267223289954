<div class="modal-header">
  <h4 class="modal-title">Import {{contactText}}</h4>
</div>
<div class="modal-body">
   <mat-form-field class="example-full-width" style="width: 50%;">
      <mat-select [(ngModel)]="searchModuleValue" disabled="true"> 
         <mat-option *ngFor="let module of searchModule" [value]="module">
            {{module}}
         </mat-option>
      </mat-select>
    </mat-form-field>

  <!-- <div *ngIf="searchModuleValue=='Contacts' ">You can import contacts into our application from a .CSV or .TSV file.</div>
  <div class="col-lg-12" style="margin-top: 1%;" *ngIf="searchModuleValue=='Contacts' ">
    <mat-radio-group (change)="radioToggle()" [(ngModel)]="radioToggleValue">
      <mat-radio-button  color="primary" value="{{contactText}}">{{contactText}}</mat-radio-button><br>
      <mat-radio-button  (change)="searchModuleClick()" color="primary" value="{{contactText}} Contact Persons">{{contactText}} Contact Persons</mat-radio-button>
    </mat-radio-group>
  </div> -->
  <div class="dropdown-divider"></div>
  <div class="col-lg-12" align="center">
    <button mat-raised-button color="primary" disabled={{buttonDisabled}} (click)="continueClick()">Continue</button>    
    <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
</div>
  
</div> 