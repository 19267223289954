<!-- {{'2018-03-19T06:32:50Z' | date:'yyyy-MM-dd HH:mm  a'}} -->
 <div class="col-lg-12">
 <div class="row">
  <div class="col-lg-8"></div>
  <div  class="col-lg-4 alignRight">
     <!--   <button mat-raised-button type="button"   
        (click)="openIcons()"    matSuffix   aria-label="Clear"
       > More Actions <mat-icon>more_vert</mat-icon>
        </button>
    
        <button mat-raised-button type="button" #moreMenu   
        (click)="openReviewDisplayColumns('')" matSuffix   aria-label="Clear">
           Columns <mat-icon>arrow_drop_down</mat-icon>
        </button>
        -->   
</div>
<div class="col-lg-1">
          
</div>
</div>
 
</div>
          
        
 <mat-sidenav-container class="col-lg-12 col-md-12 col-sm-12 nopadding"  style="overflow: visible" >
 

 <mat-sidenav-content class="col-lg-12 nopadding">    
  
  <div class="col-lg-12">     

       <div class="col-lg-12 col-md-12 " style="margin-top: 10px;padding-left:0px;padding-right:0px">

      <table class="col-lg-12 col-md-12  table   table-striped  erp-veiw-template-table vertical-align-middle cdk-table-class" style="font-size: 14px !important" cdk-table #table [dataSource]="dataSource"  >

     <!-- Checkbox Column -->
    <ng-container cdkColumnDef="select" >
      <th cdk-header-cell *cdkHeaderCellDef class="smallDiv ">
        <mat-checkbox (change)="$event ? masterToggle() : null" color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td cdk-cell *cdkCellDef="let row" class="smallDiv alignCenter" (click)="checkBoxAtLeastOneSelected(row)">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="$event ? checkBoxAtLeastOneSelected(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>     


    <!--CONTACT RELATED COLUMNS COMES BELOW  -->
    <ng-container cdkColumnDef="contactDisplayName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">
        <div class="sort-caret pos_relative">
          <div title="Name" class="pull-left over-flow"  > {{NAME_BOLD}}</div>
            <span class="sort hidden-print ">
           <!--    <b class="caret up" (click)="sortContacts('contactDisplayName','desc')"></b>
              <b class="caret down" (click)="sortContacts('contactDisplayName','asc')"></b> -->
            </span>
        </div>
      </th>
     <!--  <td cdk-cell *cdkCellDef="let element"><a style="cursor: pointer;text-decoration:none;" (click)="editContact(element)">  -->
       <td cdk-cell *cdkCellDef="let element" ><a class="reportLink alignLeft" style="text-decoration:none;" (click)="editRow(element);" status-color [status]= "element.contactStatus"> {{element.contactDisplayName}}</a>
        <!--    <span class="col-lg-0.5" *ngIf="element.contactsType=='VENDOR'" style="background-color: white;color: black;font-size: 20px;padding-left: 20px;"><b> V        
</b> </span>
      <span class="col-lg-0.5" *ngIf="element.contactsType=='CUSTOMER'" style="background-color: white;color: black;font-size: 20px;padding-left: 20px;"><b> C</b></span> -->
    </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <ng-container cdkColumnDef="type">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv alignLeft"></th>
     <td cdk-cell *cdkCellDef="let element" class="smallDiv alignLeft">
        <span   *ngIf="element.contactsType=='VENDOR'" 
        style="background-color: transparent;color: black;font-size: 12px;" status-color [status]= "element.contactStatus"><b> V</b> </span>
      <span   *ngIf="element.contactsType=='CUSTOMER'" style="background-color: transparent;color: black;font-size: 12px;" status-color [status]= "element.contactStatus"><b> C</b></span>
     </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Company Name Column -->
    <ng-container cdkColumnDef="company">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">
        <div class="sort-caret pos_relative">
          <div title="Comp Name" class="pull-left over-flow" data-test-title="Comp Name"> {{COMPANY_NAME_BOLD}}</div>
            <span class="sort hidden-print ">
              <!-- <b class="caret up" (click)="sortContacts('company','desc')"></b>
              <b class="caret down" (click)="sortContacts('company','asc')"></b> -->
            </span>
        </div>
      </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"  status-color [status]= "element.contactStatus"> {{element.company}} 
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <!-- Company Name Column -->
   
    <!-- Email Column -->
    <ng-container cdkColumnDef="email">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> 
        <div class="sort-caret pos_relative">
          <div title="Email" class="pull-left over-flow" data-test-title="Email"> {{EMAIL_BOLD}}</div>
            <span class="sort hidden-print ">
            <!--   <b class="caret up" (click)="sortContacts('email','desc')"></b>
              <b class="caret down" (click)="sortContacts('email','asc')"></b> -->
            </span>
        </div>
      </th>
      <td cdk-cell *cdkCellDef="let element"><a class="reportLink wordBreak alignLeft" style="text-decoration:none;" href="mailto:{{element.email}}" status-color [status]= "element.contactStatus"> {{element.email}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Work Phone Column -->
    <ng-container cdkColumnDef="workPhone">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{WORK_PHONE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.contactStatus"> {{element.workPhone}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    


    <!-- Receivables Column -->
    <ng-container cdkColumnDef="receivables">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign erp-money-class"> 
       <!--  <div class="sort-caret" style="position: relative">

          <div title="Receivables" class="pull-left over-flow" data-test-title="Receivables"> {{RECEIVABLES}}</div>

            <span class="sort hidden-print ">
              <b class="caret up" (click)="sortContacts('receivables','desc')"></b>
              <b class="caret down" (click)="sortContacts('receivables','asc')"></b> 
            </span>
        </div> -->
        {{RECEIVABLES}}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign erp-money-class"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t003Currency)" status-color [status]= "element.contactStatus"> {{element.t003Currency.symbol}} {{element.receivables | myCurrency}} </p><p *ngIf="element.t003Currency === null || element.t003Currency === undefined" status-color [status]= "element.contactStatus"> {{element.receivables | myCurrency}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
    <!-- Payables Column -->
    <ng-container cdkColumnDef="payables">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel erp-money-class"> 
      <!--  <div class="sort-caret" style="position: relative">

          <div title="Payables" class="pull-left over-flow" data-test-title="Payables"> {{PAYABLES}}</div>


            <span class="sort hidden-print ">
               <b class="caret up" (click)="sortContacts('payables','desc')"></b>
              <b class="caret down" (click)="sortContacts('payables','asc')"></b> 
            </span>
        </div>-->
        {{PAYABLES}}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign erp-money-class"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t003Currency)" status-color [status]= "element.contactStatus"> {{element.t003Currency.symbol}} {{element.payables | myCurrency}} </p> <p *ngIf="element.t003Currency === null || element.t003Currency === undefined" status-color [status]= "element.contactStatus"> {{element.payables | myCurrency}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="contactNumber" class="alignLeft">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CONTACT_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.accountNumber}} </a></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <!-- CONTACT COLUMNS ENDS HERE -->
    <!-- View Bill -->
<!-- View Bill starts -->
  
    <ng-container cdkColumnDef="billDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{BILL_DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.invoiceDate | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{BILL_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="billVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" >
       {{element.vendorName}}
       </a> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="billVendorPhoneNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_PHONE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.vendorPhoneNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billReferenceNumber">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{REFERENCE_NUMBER_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="wordBreakReference alignLeft"> {{element.refNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billStatus">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{STATUS_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]="element.billStatus"> {{element.billStatus}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billDueDate">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{DUE_DATE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.dueDate | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billAmount">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight reportHeaderRightAlign">{{AMOUNT_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)"> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.finalTotal | myCurrency}}</p> 
        <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.finalTotal | myCurrency}}</p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billBalanceDue">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel  alignRight">{{BALANCE_DUE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignRight "> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency) "> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.balanceDue | myCurrency}} </p>
        <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.balanceDue | myCurrency}}</p>  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="billExcessAmount">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel  alignRight">{{UNUSED_AMOUNT_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignRight "> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency) "> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.excessAmount | myCurrency}} </p>
        <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.excessAmount | myCurrency}}</p>  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    

      <ng-container cdkColumnDef="invoiceBalance">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight">{{BALANCE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignRight"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId.t003Currency)"> {{element.t100ContactCustomerId.t003Currency.symbol}} {{element.amountDue | myCurrency}}</p> 
        <p *ngIf="element.t100ContactCustomerId === null || element.t100ContactCustomerId === undefined"> {{element.amountDue | myCurrency}}</p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="outstandingBalance">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight">{{OUTSTANDING_BALANCE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignRight"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId.t003Currency)"> {{element.t100ContactCustomerId.t003Currency.symbol}} {{element.outstandingBalance | myCurrency}}</p> 
        <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId)"> {{element.outstandingBalance | myCurrency}}</p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>

      <ng-container cdkColumnDef="invoicePayment" class="flex76"> 
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight flex76"></th>
        <td cdk-cell *cdkCellDef="let element" class=" mediumFlexDiv ">
        <p *ngIf="element.finalTotal!=element.amountDue">  
          <button mat-button type="button" (click)="paymentReceivedHistory(element)" matSuffix mat-button aria-label="Clear">
            <mat-icon  matTooltip="Payment History" class="mat_icon_color">payment</mat-icon>
          </button>  </p>
           <p *ngIf="element.finalTotal==element.amountDue && element.amountDue!=0.00 && this.commonUtils.checkNullOrUndefined(element.amountDue) && this.commonUtils.checkNullOrUndefined(element.finalTotal) && element.invoiceStatus!='DRAFT' && element.invoiceStatus!='VOID' "> 

            <button mat-button type="button" (click)="recordPayment(element)" matSuffix mat-button aria-label="Clear">
              <mat-icon  class="mat_icon_color" matTooltip="Pay Invoice">account_balance_wallet
              </mat-icon> </button>
          </p>  </td>

       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billPayment">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv"></th>
        <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign smallDiv ">
        <p *ngIf="element.finalTotal!=element.balanceDue">  
          <button mat-button type="button" (click)="paymentCreditsHistory(element)"  matSuffix mat-button aria-label="Clear"> 
            <mat-icon  matTooltip="Payment History" class="mat_icon_color">payment
            </mat-icon>  </button>  </p>
           <p *ngIf="element.finalTotal==element.balanceDue && element.balanceDue!=0.00 && this.commonUtils.checkNullOrUndefined(element.balanceDue) && this.commonUtils.checkNullOrUndefined(element.finalTotal) && element.billStatus!='DRAFT' && element.billStatus!='VOID'"> <button mat-button type="button" (click)="recordPayment(element)" matSuffix mat-button aria-label="Clear">
            <mat-icon  class="mat_icon_color" matTooltip="Pay Bill">account_balance_wallet</mat-icon>
          </button>
          </p>  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <!-- view journal starts -->
       <ng-container cdkColumnDef="journalDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{DATE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.journalDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   
    <ng-container cdkColumnDef="journalNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{JOURNAL_NUMBER}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.journalNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   
    <ng-container cdkColumnDef="journalRefNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{REFERENCE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.referenceNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
  
    <ng-container cdkColumnDef="journalDebitAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{DEBIT_AMOUNT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight"> {{element.currencySymbol}} {{element.debitTotal | myCurrency}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="journalCreditAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{CREDIT_AMOUNT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight">{{element.currencySymbol}} {{element.creditTotal | myCurrency}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <!-- view journal ends -->
<!-- View Bill ends -->

      <ng-container cdkColumnDef="profileName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PROFILE_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.profileName}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


      <ng-container cdkColumnDef="vendorsName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" >
       {{element.vendorName}} 
     </a>
   </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="billStartsOn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{STARTS_ON_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.invoiceDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="billEndOn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{ENDS_ON_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.dueDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="expStartsOn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{STARTS_ON_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.startDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="expEndOn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{ENDS_ON_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.endDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="invoiceDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{DATE_BOLD}}</th>
      <div *ngIf="userType != 'SALES_PERSON' && userType != 'GRAPHICS' && userType != 'CUSTOMER'" >
        <td cdk-cell  *cdkCellDef="let element" class="alignCenter"> {{element.invoiceDate | dateFormatPipe}} </td>
      </div>
      <div *ngIf="userType === 'SALES_PERSON'"   >
        <td cdk-cell class="alignCenter" *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread" > {{element.invoiceDate | dateFormatPipe}} </td>
      </div>
     <!--  <div *ngIf="userType === 'GRAPHICS'" >
        <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread" > {{element.invoiceDate | dateFormatPipe}} </td>
      </div> -->
      <div *ngIf="userType === 'CUSTOMER'"   >
      <td cdk-cell  class="alignCenter" *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread" > {{element.invoiceDate | dateFormatPipe}} </td> </div>

     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="invoiceNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{INVOICE_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="soNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{SO_NUMBER}} </th>
      <td cdk-cell *cdkCellDef="let element"> <a class="reportLink alignLeft" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} ({{element.totalOrderedQuantity | number : fractionsize}})</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="estimateDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter"> {{DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.invoiceDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="estimateExpiryDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter"> {{EXPIRY_DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.dueDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="estimateNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{ESTIMATE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="reservationNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{RESERVATION_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="vendorCreditNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{VENDOR_CREDIT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}}</a> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="creditNoteNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{CREDIT_NOTE_NUMBER}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}}</a> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
   <ng-container cdkColumnDef="vendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId)">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" >{{element.t100ContactVendorId.contactDisplayName}}
      </a></p>
   
    </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="customerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId)">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" >{{element.t100ContactCustomerId.contactDisplayName}}
      </a></p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!--<ng-container cdkColumnDef="expenseCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId)">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" >{{element.t100ContactCustomerId.contactDisplayName}}
      </a></p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  -->
     <ng-container cdkColumnDef="customerPhone">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CUSTOMER_PHONE}} </th>
      <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId)">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" >{{element.t100ContactVendorId.workPhone}}
      </a></p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="contactPhone">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CUSTOMER_PHONE}} </th>
      <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId)">{{element.t100ContactCustomerId.workPhone}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="paymentCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" > {{element.customerName}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<ng-container cdkColumnDef="packageCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" >
       {{element.customerName}}</a> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      

      <ng-container cdkColumnDef="refNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{REFERENCE_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.refNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="status">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.status" > {{element.status}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="indentStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.indentStatus}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="artworkNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{ARTWORK_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} ({{element.numberOfProof| number : fractionsize}}) </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<ng-container cdkColumnDef="artworkNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{ARTWORK_NUMBER_BOLD}} </th>
    <div *ngIf="userType === 'SALES_PERSON'" >
      <td cdk-cell *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} ({{element.numberOfProof| number : fractionsize}}) </a></td>
    </div>
  <!--   <div *ngIf="userType === 'GRAPHICS'" >
      <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} ({{element.numberOfProof| number : fractionsize}}) </a></td>
    </div> -->
    <div *ngIf="userType === 'CUSTOMER'" >
      <td cdk-cell *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} ({{element.numberOfProof| number : fractionsize}}) </a></td>
    </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="invStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.invoiceStatus"> {{element.invoiceStatus}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <ng-container cdkColumnDef="estimatestatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{STATUS_BOLD}} </th>
      <div *ngIf="userType != 'SALES_PERSON' && userType != 'CUSTOMER'">
        <td cdk-cell *cdkCellDef="let element"  class="alignLeft" status-color [status]="element.t004StatusObj.lookupValue"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004StatusObj)"> {{element.t004StatusObj.lookupValue}} </p></td>
      </div>

      <div *ngIf="userType === 'SALES_PERSON'" >
        <td cdk-cell *cdkCellDef="let element"  class="alignLeft" status-color [status]="element.t004StatusObj.lookupValue" [salespersonRecordUnread]="element.salespersonRecordUnread"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004StatusObj)"> {{element.t004StatusObj.lookupValue}} </p></td>
      </div>
      <div *ngIf="userType === 'CUSTOMER'" >
        <td cdk-cell *cdkCellDef="let element"  class="alignLeft" status-color [status]="element.t004StatusObj.lookupValue" [customerRecordUnread]="element.customerRecordUnread"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004StatusObj)"> {{element.t004StatusObj.lookupValue}} </p></td>
      </div>
      
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<ng-container cdkColumnDef="salesStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.t004LookupSOStatusId.lookupValue"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupSOStatusId)"
        status-color [status]="element.t004LookupSOStatusId.lookupValue"> {{element.t004LookupSOStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="inventoryStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{INVENTORY_STATUS}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.t004LookupSOInventoryStatusId.lookupValue"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupSOInventoryStatusId)"
        status-color [status]="element.t004LookupSOInventoryStatusId.lookupValue"> {{element.t004LookupSOInventoryStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    
    <ng-container cdkColumnDef="vendorStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.vendorCreditStatus"
      > {{element.vendorCreditStatus}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="creditStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabe alignLeft"> {{STATUS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]="element.creditNoteStatus"> {{element.creditNoteStatus}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="packageStatus1">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{PACKAGE_STATUS}} </th>
      <td cdk-cell *cdkCellDef="let element" ><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004PackageStatusId)"   status-color [status]="element.t004PackageStatusId.lookupValue" (click)="directToReviewPackage()" style="cursor: pointer;"> {{element.t004PackageStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="shipmentStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flex76">   </th>
      <td cdk-cell *cdkCellDef="let element" class="flex76"> <!-- <p *ngIf="element.t004ShipmentStatusId != null && element.t004ShipmentStatusId != undefined"
        status-color  [status]="element.t004ShipmentStatusId.lookupValue"> {{element.t004ShipmentStatusId.lookupValue}} </p> 
 -->
     <p *ngIf="element.t004ShipmentStatusId.lookupValue=='NOT SHIPPED'" ><mat-icon   style="color:red; " matTooltip="{{element.t004ShipmentStatusId.lookupValue}}">local_shipping</mat-icon></p>
   
     <p *ngIf="element.t004ShipmentStatusId.lookupValue=='PARTIALLY SHIPPED'" ><mat-icon   style="color:blue; " matTooltip="{{element.t004ShipmentStatusId.lookupValue}}">local_shipping</mat-icon></p>
   
   
      <p *ngIf="element.t004ShipmentStatusId.lookupValue=='SHIPPED'" ><mat-icon style="color: orange; " matTooltip="{{element.t004ShipmentStatusId.lookupValue}}">local_shipping</mat-icon></p>
   
   </td>

     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="invoiceStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{INVOICE_STATUS}} </th>
 
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"  ><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004InvoiceStatusId)"
       status-color [status]="element.t004InvoiceStatusId.lookupValue" (click)="directToReviewInvoice()" style="cursor: pointer;">{{element.t004InvoiceStatusId.lookupValue}}</p> </td>
 
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="dueDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter"> {{DUE_DATE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.dueDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="finalTotal">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight reportHeaderRightAlign"> {{AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class reportValueRightAlign"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)"> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.finalTotal | myCurrency}}</p>
      <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.finalTotal | myCurrency}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="finalTotalCust">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight  "> {{AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class" > <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId.t003Currency)"> {{element.t100ContactCustomerId.t003Currency.symbol}} {{element.finalTotal | myCurrency}} </p>
      <p *ngIf="element.t100ContactCustomerId === null || element.t100ContactCustomerId === undefined"> {{element.finalTotal | myCurrency}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="finalTotalCustRI">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight"> {{AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="paddingRight203 erp-money-class"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId.t003Currency)"> {{element.t100ContactCustomerId.t003Currency.symbol}} {{element.finalTotal | myCurrency}} </p>
      <p *ngIf="element.t100ContactCustomerId === null || element.t100ContactCustomerId === undefined"> {{element.finalTotal | myCurrency}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="balanceDue">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter reportHeaderRightAlign"> {{BALANCE_DUE_BOLD}} </th>      
      <td cdk-cell *cdkCellDef="let element" class="alignRight erp-money-class"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)"> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.amountDue | myCurrency}} </p> 
      <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.amountDue | myCurrency}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="paymentDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter"> {{DATE_BOLD}} </th>
      
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.paymentDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="paymentNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PAYMENT_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.paymentReceivedNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<ng-container cdkColumnDef="paymentMadeNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PAYMENT_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.paymentMadeNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="paymentInvoiceNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INVOICE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.paymentInvoiceNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <ng-container cdkColumnDef="referenceNumber">

      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{REF_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.referenceNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="contactName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100Contact)">  
       <a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" >{{element.t100Contact.contactDisplayName}}
      </a></p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="mode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{MODE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t023PaymentMode)"> {{element.t023PaymentMode.name}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="receivedMode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{MODE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.paymentMode}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="paymentAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="alignRight boldLabel  "> {{AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100Contact) && this.commonUtils.checkNullOrUndefined(element.t100Contact.t003Currency)"> {{element.t100Contact.t003Currency.symbol}} {{element.amount | myCurrency}}</p>
      <p *ngIf="element.t100Contact === null || element.t100Contact === undefined"> {{element.amount | myCurrency}}</p>  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="unusedAmountContact">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel  alignRight"> {{UNUSED_AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100Contact) && this.commonUtils.checkNullOrUndefined(element.t100Contact.t003Currency)"> {{element.t100Contact.t003Currency.symbol}} {{element.amountExcess | myCurrency}}</p>
      <p *ngIf="element.t100Contact === null || element.t100Contact === undefined"> {{element.amountExcess | myCurrency}}</p>  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="unusedAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight"> {{UNUSED_AMOUNT_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)"> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.amountExcess | myCurrency}}</p> 
      <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.amountExcess | myCurrency}}</p>  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="poReceive">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter flex76"> </th>
        <td cdk-cell *cdkCellDef="let element" class="mediumFlexDiv reportHeaderRightAlign" >
        <button  mat-button type="button"    mat-icon-button aria-label="Clear" matTooltip="Receive Purchase Order " 
        [disabled]="element.hidePORecieveIcon" (click)="poReceive(element)"><mat-icon>assignment_returned</mat-icon> </button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="soReceive">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> </th>
      <td cdk-cell *cdkCellDef="let element" class="smallDiv " >
      <button type="button" matSuffix mat-button mat-icon-button aria-label="Clear" matTooltip="Receive Sales Order"  (click)="soReceive(element)" [disabled]="element.hideSOReceiveIcon"><mat-icon>assignment_returned</mat-icon></button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="package">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> </th>
      <td cdk-cell *cdkCellDef="let element" class=" smallDiv" >
        <button matTooltip="Create Package" mat-button type="button" matSuffix mat-icon-button aria-label="Clear" [disabled]="element.hidePackageIcon" (click)="soPackage(element)">
           <p *ngIf="element.t004PackageStatusId.lookupValue=='NOT PACKAGED'" >
            <mat-icon style="color:red; " >redeem</mat-icon>
          </p>
          <p *ngIf="element.t004PackageStatusId.lookupValue=='PARTIALLY PACKAGED'" >
            <mat-icon style="color:blue; " >redeem</mat-icon>
          </p>
          <p *ngIf="element.t004PackageStatusId.lookupValue=='PACKAGED'" >
            <mat-icon style="color:green; " >redeem</mat-icon>
          </p>
        </button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <ng-container cdkColumnDef="stockPackage">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"></th>
      <td cdk-cell *cdkCellDef="let element" class=" smallDiv" >
        <button matTooltip="Create Package" mat-button type="button" mat-icon-button matSuffix mat-button aria-label="Clear" [disabled]="element.hidePackageIcon" (click)="stockPackage(element)"><mat-icon  status-color [status]="element.t004lookupPackageStatusId.lookupValue"  >redeem</mat-icon></button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    
     <ng-container cdkColumnDef="reviewPackage">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> </th>
      <td cdk-cell *cdkCellDef="let element" class=" smallDiv" >
        <button mat-button type="button" matTooltip="Review Package"  mat-icon-button matSuffix mat-button aria-label="Clear" [disabled]="element.hidePackageIcon" (click)="viewPackage(element)"><mat-icon >video_label</mat-icon></button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="reviewStockPackage">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> </th>
      <td cdk-cell *cdkCellDef="let element" class=" smallDiv" >
        <button mat-button type="button" matTooltip="Review Package" mat-icon-button  matSuffix mat-button aria-label="Clear" [disabled]="element.hidePackageIcon" (click)="viewStockPackage(element)"><mat-icon >video_label</mat-icon></button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<ng-container cdkColumnDef="expenseId">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EXPENSE_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.expenseNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="expenseDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.expenseDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="expenseVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId)">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" >{{element.t100ContactVendorId.contactDisplayName}}
      </a> </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!--    <ng-container cdkColumnDef="expenseVendorPhone">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_PHONE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId)">{{element.t100ContactVendorId.workPhone}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
-->
    <ng-container cdkColumnDef="paidThrough">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel paddingRight10 alignLeft">{{PAID_THROUGH_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="paddingRight10 alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t006GLAccount)"> {{element.t006GLAccount.name}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!--  <ng-container cdkColumnDef="customerContactName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="wordBreak" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId)"> <a class="reportLink" style="text-decoration:none;" (click)="redirectToCustomer(element);" >
      {{element.t100ContactCustomerId.contactDisplayName}}
      </a> </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
-->
    <ng-container cdkColumnDef="expenseStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]="element.t004Lookup.lookupValue"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004Lookup)"> {{element.t004Lookup.lookupValue}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="expenseAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter boldLabel reportHeaderRightAlign">{{AMOUNT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="erp-money-class "> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)">{{element.t100ContactVendorId.t003Currency.symbol}} {{element.expenseTotal | myCurrency}}</p>
      <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.t003Currency.symbol}}{{element.expenseTotal | myCurrency}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="employeeCode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EMPLOYEE_CODE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" [status]="element.status"  > {{element.userName}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="employeeName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EMPLOYEE_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.firstName}} {{element.lastName}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="employeeEmail">
         <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EMAIL_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.officalEmail}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="employeeMobileNumber">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{MOBILE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.mobileNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="employeeDepartment">
         <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{DEPARTMENT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.departmentName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="employeeDesignation">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{DESIGNATION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.designationName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="reportingManagerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{REPORTING_MGR_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.reportingManagerName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="empHierarchy"> 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"></th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> 
        <button #empHierarchyId mat-button type="button"   matSuffix mat-button aria-label="Clear" (click)="viewEmpHierarchy(element,empHierarchyId)"> 
           <mat-icon matTooltip="View Employee Hierarchy Level">low_priority</mat-icon>
        </button> 
       </td> 
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="recurrExpenseAccount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EXPENSE_ACCOUNT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t006GLAccountExpenseAccountHeaderId)"> {{element.t006GLAccountExpenseAccountHeaderId.name}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="frequency">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{FREQUENCY_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004Lookup)"> {{element.t004Lookup.lookupValue}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="expenseStartDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{START_DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.startDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  

    <ng-container cdkColumnDef="expenseEndDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{END_DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.endDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="poStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" 
      >{{STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]="element.t004LookupPOStatusId.lookupValue"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupPOStatusId)">{{element.t004LookupPOStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 
    <ng-container cdkColumnDef="poBillStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{BILLED_STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"[status]="element.t004LookupPOBillStatusId.lookupValue"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupPOBillStatusId)">{{element.t004LookupPOBillStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="poInvStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INV_STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" [status]="element.t004LookupPOReceivedStatusId.lookupValue"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupPOReceivedStatusId)">{{element.t004LookupPOReceivedStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="paycodeNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PAYCODE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft
      status-color [status]="!element.deduct" > {{element.payCode}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
     <ng-container cdkColumnDef="displayOrder">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{DISPLAY_ORDER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.displayOrder}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="paycodeDescription">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{DESCRIPTION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.earnDescription}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="showInReport">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{SHOW_IN_REPORT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.showReportString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="considerPF">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{CONSIDER_FOR_PF_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.pFAvailabilityString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="considerESI">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{CONSIDER_FOR_ESI_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.eSIAvailabilityString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="considerLOP">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{CONSIDER_FOR_LOP_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.considerForLOPString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="deduction">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{DEDUCTION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.deductString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="salesOrderNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{SO_NO}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t303SalesOrderHeader)">
        <a class="reportLink" style="text-decoration:none;" (click)="viewSalesOrder(element);" matTooltip="View Sales Order" > {{element.t303SalesOrderHeader.invoiceNumber}}</a></p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
    <ng-container cdkColumnDef="stockRequestNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STOCKREQ_NUM_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t211_StockRequest_HeaderObj)">
     <a class="reportLink" style="text-decoration:none;" (click)="ViewDispatchPakage(element);" >{{element.t211_StockRequest_HeaderObj.invoiceNumber}}</a></p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="returnsNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{RETURNS_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t214_Returns_HeaderObj)">
        {{element.t214_Returns_HeaderObj.invoiceNumber}}</p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="packageSlipNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PACKAGE_NO}}</th>
 
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="viewPackage(element)" >{{element.packageSlipNumber}}</a></td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="packageDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{PACKAGE_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter">  {{element.packageDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="trackingNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{TRACKING_NUMBER_BOLD}}</th>
      <!-- <td cdk-cell *cdkCellDef="let element" style="flex: 0 1 150px !important;"> {{element.trackingNumber}} </td>  -->
    <td cdk-cell class="alignLeft trackingFlex" *cdkCellDef="let element">
        <button mat-button type="button"  *ngIf="(element.packageStatusId.lookupValue=='SHIPPED' || element.packageStatusId.lookupValue=='DELIVERED') && (element.carrier == 'UPS' ||  element.carrier == 'FedEx' )&& element.trackingNumberStatus === 'Automatic' " matSuffix mat-button aria-label="Clear" (click)="viewTrackingNumber(element)">
           <mat-icon matTooltip="View Download Tracking Number">view_comfy</mat-icon>
        </button> 
        <p *ngIf="element.trackingNumberStatus != 'Automatic'"> {{element.trackingNumber}}</p>
   </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="ship">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft smallDiv"> </th>
      <td cdk-cell class="alignLeft smallDiv" *cdkCellDef="let element">
   <button mat-button type="button"  matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hideShippedIcon" (click)="createShipping(element)" *ngIf="element.packageStatusId.lookupValue!='VOID'">
    <p *ngIf="element.packageStatusId.lookupValue=='NOT SHIPPED'" ><mat-icon class="discountDivColor"   matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
    <p *ngIf="element.packageStatusId.lookupValue=='DELIVERED'" ><mat-icon style="color: green; " matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
    <p *ngIf="element.packageStatusId.lookupValue=='SHIPPED'" ><mat-icon style="color: orange; " matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
   
  </button>
   <p *ngIf="element.packageStatusId.lookupValue=='VOID'" >{{element.packageStatusId.lookupValue}} </p>
       </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<ng-container cdkColumnDef="dispatchShip">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft smallDiv"> </th>
      <td cdk-cell class="alignLeft smallDiv" *cdkCellDef="let element">
   <button mat-button type="button"  matSuffix mat-button aria-label="Clear"  (click)="createDispatchShipping(element)" *ngIf="element.packageStatusId.lookupValue!='VOID'">
    <p *ngIf="element.packageStatusId.lookupValue=='NOT SHIPPED'" ><mat-icon class="discountDivColor" style="padding-top: 30%;" matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
    <p *ngIf="element.packageStatusId.lookupValue=='DELIVERED'" ><mat-icon style="color: green;padding-top: 30%;" matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
    <p *ngIf="element.packageStatusId.lookupValue=='SHIPPED'" ><mat-icon style="color: orange;padding-top: 30%;" matTooltip="{{element.packageStatusId.lookupValue}}">local_shipping</mat-icon></p>
   
  </button>
   <p *ngIf="element.packageStatusId.lookupValue=='VOID'" >{{element.packageStatusId.lookupValue}} </p>
       </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <ng-container cdkColumnDef="deliver">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft smallDiv"> </th>
      <td cdk-cell class="alignLeft smallDiv" *cdkCellDef="let element">
        <button mat-button type="button" matSuffix mat-button aria-label="Clear" mat-icon-button matTooltip="Mark as Delivered" [disabled]="element.hideDeliveredIcon" (click)="confirmToSendShipmentEmail(element)"  *ngIf="element.packageStatusId.lookupValue!='VOID'"><mat-icon >shopping_basket</mat-icon></button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="packageStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"  status-color [status]="element.packageStatusId.lookupValue"> <p *ngIf="element.packageStatusId != null && element.packageStatusId != undefined" >{{element.packageStatusId.lookupValue}} </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <ng-container cdkColumnDef="edit" *ngIf="moduleName === 'PROJECT_PLAN'"> <!-- style="flex: 0.07 1 45px !important;" -->
      <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel reportHeaderRightAlign mediumFlexDiv mediumFlexDiv1">
        <!--<a (click)="searchIconClick()">
          <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
            <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon>
          </button>
        </a>
      -->
      </th>
      <td cdk-cell  class=" mediumFlexDiv mediumFlexDiv1" *cdkCellDef="let element"  >
         <button mat-button type="button"  (click)="editRow(element);" matSuffix mat-button aria-label="Clear">
          <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="edit" *ngIf="moduleName != 'PROJECT_PLAN'"> 
      <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel  mediumFlexDiv mediumFlexDiv1"> </th>
      <td cdk-cell  class=" mediumFlexDiv mediumFlexDiv1" *cdkCellDef="let element"  >
         <button mat-button type="button" *ngIf="currentPageAccessObj.editAccess && !editHideFlag"  (click)="editRow(element);" matSuffix mat-button aria-label="Clear"  >
          <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="refreshEdit"> 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight mediumFlexDiv"></th>
      <td cdk-cell  class=" mediumFlexDiv" *cdkCellDef="let element"  >
        <button mat-button type="button" (click)="editRow(element);" matSuffix mat-button aria-label="Clear"  >
        <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="refreshPackage">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight mediumFlexDiv">
          <!--<a (click)="refreshIconClick(true)">
      <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>
    --></th>
      <td cdk-cell *cdkCellDef="let element" class=" mediumFlexDiv" >
        <button mat-button type="button" matTooltip="Review Package"  matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hidePackageIcon" (click)="viewPackage(element)"><mat-icon >video_label</mat-icon></button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


     <ng-container cdkColumnDef="searchClone"  class="div20px">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight" >
        <!--<a *ngIf="cloneSearchIconFalg" (click)="searchIconClick()">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon></button>
        </a>
      --></th>
       <td cdk-cell  *cdkCellDef="let element" >
         <button mat-button type="button" *ngIf="cloneSearchIconFalg" (click)="cloningObj(element);" matSuffix  aria-label="Clear">
          <mat-icon  matTooltip="Clone Row" class="mat_icon_color">content_copy</mat-icon> 
        </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     

    <ng-container cdkColumnDef="cloning" style="flex: 0 1 56px !important;">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter   "> </th>
      <td cdk-cell class=" smallDiv" *cdkCellDef="let element"  >
         <button mat-button type="button" (click)="cloningObj(element);" matSuffix   aria-label="Clear">
          <mat-icon  matTooltip="Clone Row" class="mat_icon_color">content_copy</mat-icon> 
        </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="trigger">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter "> </th>
      <td cdk-cell class=" mediumFlexDiv" *cdkCellDef="let element"  >
         <button mat-button type="button" (click)="trigger(element);" matSuffix   aria-label="Clear">
          <mat-icon  matTooltip="Trigger Message" class="mat_icon_color">send</mat-icon> 
         <!--  <i className="material-icons">send</i> -->
        </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- <ng-container cdkColumnDef="payment" class="smallDiv">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv"> {{PAY_LINK}} </th>
      <td cdk-cell class="alignRight smallDiv" *cdkCellDef="let element"  >
        <a class="reportLink" style="text-decoration:none;" (click)="invoicePayment(element.finalTotal)"> {{PAY_LINK}} </a>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->
 <ng-container cdkColumnDef="info"  >
      <!-- <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight miniDiv">   </th> -->
<th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight  div20px">
  <!-- <a (click)="refreshIconClick(true)">
   <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
    <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>-->
      
    </th>
      <td cdk-cell class="alignCenter div20px"  *cdkCellDef="let element" >
        <button mat-button type="button" #quickInfoBtn (click)="bindSelectedValue(element,quickInfoBtn)" matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="View More Information" class="mat_icon_color">info_outline</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="chartInfo" class="div20px">
     <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight  div20px">
      <!--<a (click)="openAnalyticalChart()">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="View Analytical Chart">insert_chart</mat-icon></button>
        </a>-->
          
        </th>
      <td cdk-cell class="alignCenter div20px"  *cdkCellDef="let element">
        <button mat-button type="button" #quickInfoBtn (click)="bindSelectedValue(element,quickInfoBtn)" matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="View More Information" class="mat_icon_color">info_outline</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="searchInfo" style="flex: 0 1 56px !important;">
     <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv">
      <!--<a (click)="searchIconClick()">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon></button>
        </a>
      --></th>
      <td cdk-cell class="alignCenter smallDiv"  *cdkCellDef="let element">
        <button mat-button type="button" #quickInfoBtn (click)="bindSelectedValue(element,quickInfoBtn)" matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="View More Information" class="mat_icon_color">info_outline</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


<!-- Search Column -->
    <ng-container cdkColumnDef="search" style="flex: 0 1 56px !important;" >
      <th cdk-header-cell *cdkHeaderCellDef class="reportHeaderRightAlign miniDiv">
        <!--<a (click)="searchIconClick()">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">

          <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon></button>
        </a>
      --></th>
      <td cdk-cell *cdkCellDef="let element" class="miniDiv"> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


     <ng-container cdkColumnDef="chart" style="flex: 0 1 56px !important;">
      <th cdk-header-cell *cdkHeaderCellDef class="reportHeaderRightAlign miniDiv">
        <!--<a (click)="openAnalyticalChart()">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="View Analytical Chart">insert_chart</mat-icon></button>
        </a>
      --></th>
      <td cdk-cell *cdkCellDef="let element" class="miniDiv"> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <ng-container cdkColumnDef="refresh" style="flex: 0 1 56px !important;">
      <th cdk-header-cell *cdkHeaderCellDef class="reportHeaderRightAlign miniDiv">
        <!-- <a (click)="refreshIconClick(true)">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
          <mat-icon class="reportLink" matTooltip="Refresh Grid">refresh</mat-icon></button>
        </a>
      --></th>
      <td cdk-cell *cdkCellDef="let element" class="miniDiv"> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>   

    <ng-container cdkColumnDef="salaryname">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{SALARY_GROUP_BOLD}}</th>
       <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t800Employee) && this.commonUtils.checkNullOrUndefined(element.t800Employee.t818SalaryGroup)"> {{element.t800Employee.t818SalaryGroup.salaryGroupName}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t800Employee.firstName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="resourceusername">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{RESOURCE_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t800Employee.userName}} -  {{element.t800Employee.firstName}}  {{element.t800Employee.lastName}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="reportinglocation">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{REPORTING_LOCATION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t800Employee) && this.commonUtils.checkNullOrUndefined(element.t800Employee.t028Location)"> {{element.t800Employee.t028Location.locationName}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="loandate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel ">{{LOAN_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.loanDate | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="loanamount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign">{{LOAN_AMOUNT}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign">{{element.currencySymbol}} {{element.loanAmount | myCurrency}} </td> 
     <!--  <td cdk-cell *cdkCellDef="let element"> {{element.t800Employee.t818SalaryGroup.salaryGroupName}} </td> -->
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="balanceamount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign">{{BAL_AMT}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign"> {{element.currencySymbol}} {{element.balanceAmount | myCurrency}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="paidamount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign">{{PAID_AMT}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign"> {{element.currencySymbol}} {{element.amountPaid}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="emiamount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign">{{EMI_AMT}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign"> {{element.currencySymbol}} {{element.eMIAmount}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>     
    <ng-container cdkColumnDef="transaction">
      <th cdk-header-cell *cdkHeaderCellDef class="alignRight" style="flex: 0 1 55px !important;">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
       <mat-icon class="reportLink" matTooltip="Payment">payment </mat-icon> </button>
      </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignRight"  style="flex: 0 1 55px !important;">
        <button mat-button type="button"  (click)="payment(element)" matSuffix mat-button aria-label="Clear">
         <mat-icon class="reportLink" matTooltip="Payment" >payment</mat-icon> </button>      
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- view_Artwork_KSG starts -->
 <ng-container cdkColumnDef="csa">
      <th cdk-header-cell   *cdkHeaderCellDef class="boldLabel">{{CSA}}</th>
     <!--   <div *ngIf="userType === 'GRAPHICS'">
        <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread"> {{element.artworkCSAName}} </td>
      </div> -->
      <div *ngIf="userType === 'SALES_PERSON'">
        <td cdk-cell  *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread"> {{element.artworkCSAName}} </td>
     </div>
     <div *ngIf="userType === 'CUSTOMER'">
        <td cdk-cell  *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread"> {{element.artworkCSAName}} </td> 
    </div>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="sku">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{SKU}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.sku}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
     <ng-container cdkColumnDef="numberOfProof">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign">{{NUMBER_OF_PROOF}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign" style="padding-right: 25px;"> {{element.numberOfProof}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="artServiceVendor">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ART_SERVICE_VENDOR_VALUE}}</th>
    <div *ngIf="userType === 'SALES_PERSON'" >
      <td cdk-cell *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread"> {{element.artServiceVendorName}} </td>
    </div>
   <!--  <div *ngIf="userType === 'GRAPHICS'" >
      <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread"> {{element.artServiceVendorName}} </td>
    </div> -->
    <div *ngIf="userType === 'CUSTOMER'" >
      <td cdk-cell *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread"> {{element.artServiceVendorName}} </td>
    </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="primaryContactEmail">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMAIL_BOLD}}</th>
    <div *ngIf="userType === 'SALES_PERSON'" >
    <td cdk-cell *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread" style="word-break: break-word;">{{element.primaryContactEmail}}</td>
    </div>
    <!-- <div *ngIf="userType === 'GRAPHICS'" >
    <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread" style="word-break: break-word;">{{element.primaryContactEmail}}</td>
    </div> -->
    <div *ngIf="userType === 'CUSTOMER'" >
    <td cdk-cell *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread" style="word-break: break-word;">{{element.primaryContactEmail}}</td>
    </div>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="decoVendor">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{DECO_VENDOR_VALUE}}</th>
      <div *ngIf="userType === 'SALES_PERSON'" >
        <td cdk-cell *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread" > {{element.decoVendorName}} </td>
      </div>
       <!-- <div *ngIf="userType === 'GRAPHICS'" >
        <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread" > {{element.decoVendorName}} </td>
      </div> -->
       <div *ngIf="userType === 'CUSTOMER'" >
        <td cdk-cell *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread" > {{element.decoVendorName}} </td>
      </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="salesPerson">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{SALES_PERSON_BOLD}}</th>
    <div *ngIf="userType === 'SALES_PERSON'" >
      <td cdk-cell *cdkCellDef="let element" status-color [salespersonRecordUnread]="element.salespersonRecordUnread"> {{element.t302SalesPersonName}} </td>
    </div>
    <!-- <div *ngIf="userType === 'GRAPHICS'" >
      <td cdk-cell *cdkCellDef="let element" status-color [graphicsRecordUnread]="element.graphicsRecordUnread"> {{element.t302SalesPersonName}} </td>
    </div> -->
    <div *ngIf="userType === 'CUSTOMER'" >
      <td cdk-cell *cdkCellDef="let element" status-color [customerRecordUnread]="element.customerRecordUnread"> {{element.t302SalesPersonName}} </td>
    </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    

<!-- view po starts -->
      <ng-container cdkColumnDef="poDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{PO_DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter">  {{element.invoiceDate | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="poNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PO_NUMBER_BOLD}}</th>
       <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}}  </a></td>
 
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="poVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" > {{element.vendorName}}</a> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="poVendorPhoneNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_PHONE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.vendorPhoneNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="poRefNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{REF_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="marginLeft10px alignLeft"> {{element.refNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="receivedQty" class="smallDiv  ">
      <th cdk-header-cell *cdkHeaderCellDef class="alignRight boldLabel smallDiv">{{RECEIVED_QTY_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight smallDiv"> 
      {{element.totalReceivedQuantity}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- view po ends -->
  <ng-container cdkColumnDef="porecievedstatus" class="flex3px">
      <th cdk-header-cell *cdkHeaderCellDef  class="flex3px alignLeft"></th>
      <td cdk-cell *cdkCellDef="let element" class="mediumFlexDiv reportHeaderRightAlign tableCell alignLeft" status-color [status]="'SHOW_POPUP'" [rowObj]="element" >
  <!-- 
     <mat-progress-spinner  *ngIf="element.totalQuantityToBeReceived!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.quantityStatusPercent}}"   [diameter]="30">
    </mat-progress-spinner>  -->
        <div   style="padding:0px">
          <div class="col-lg-12" style="padding:0px">
            <mat-progress-bar class="progressTopPadding" *ngIf="element.totalQuantityToBeReceived!=0 && element.totalOrderedQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalOrderedQuantity*100/element.totalOrderedQuantity}}"   matTooltip="Ordered Qty: {{element.totalOrderedQuantity}} ">
           </mat-progress-bar>
          </div>
          <div   style="padding:0px">
            <mat-progress-bar class="progressTopPadding" *ngIf="element.totalQuantityToBeReceived!=0 && element.totalReceivedQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalReceivedQuantity*100/element.totalOrderedQuantity}}"    matTooltip="Received Qty: {{element.totalReceivedQuantity}} ">
            </mat-progress-bar>
          </div>
          <div   style="padding:0px">
            <mat-progress-bar  class="progressTopPadding" *ngIf="element.totalQuantityToBeReceived!=0 && element.totalBilledQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalBilledQuantity*100/element.totalOrderedQuantity}}"    matTooltip="Billed Qty: {{element.totalBilledQuantity}} ">
            </mat-progress-bar>
            
          </div>
           <mat-icon matTooltip="Completely Received" style="color:green"  *ngIf="element.totalQuantityToBeReceived===0" else elseBlock >done</mat-icon> 
        </div>
      </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="soOrderStatus" class="flex3px">
      <th cdk-header-cell *cdkHeaderCellDef  class="flex3px"></th>
      <td cdk-cell *cdkCellDef="let element" class="mediumFlexDiv   tableCell" status-color [status]="'SHOW_POPUP'" [rowObj]="element" >
        <div   style="padding:0px">
          <div class="col-lg-12" style="padding:0px">
            <mat-progress-bar class="progressTopPadding" *ngIf="element.totalQuantityYetToBeShipped && element.totalOrderedQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalOrderedQuantity*100/element.totalOrderedQuantity}}"   matTooltip="Ordered Qty: {{element.totalOrderedQuantity}} ">
           </mat-progress-bar>
          </div>
          <div  style="padding:0px">
            <mat-progress-bar class="progressTopPadding" *ngIf="element.totalQuantityYetToBeShipped!=0 && element.totalPackedQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalPackedQuantity*100/element.totalOrderedQuantity}}"    matTooltip="Packed Qty: {{element.totalPackedQuantity}} ">
            </mat-progress-bar>
          </div>
          <div   style="padding:0px">
            <mat-progress-bar  class="progressTopPadding" *ngIf="element.totalQuantityYetToBeShipped!=0 && element.totalInvoicedQuantity!=0" [color]="'primary'" [mode]="'determinate'" value="{{element.totalInvoicedQuantity*100/element.totalOrderedQuantity}}"    matTooltip="Invoiced Qty: {{element.totalInvoicedQuantity}} ">
            </mat-progress-bar>
            
          </div>
           <mat-icon matTooltip="Completely Shipped" style="color:green"  *ngIf="element.totalQuantityYetToBeShipped===0" else elseBlock >done</mat-icon> 
        </div>
      </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!-- Name Column -->

  <ng-container cdkColumnDef="itemName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderLeftAlign alignLeft">
        <div class="sort-caret pos_relative">
          <div title="Name" class="pull-left over-flow"  > {{NAME_BOLD}}</div>
            <span class="sort hidden-print ">
                <!--  <span class="sort hidden-print ">
              <b class="caret up" (click)="sortItems('desc','itemName')"></b>
              <b class="caret down" (click)="sortItems('asc','itemName')"></b>
            </span> -->
              </span>
        </div>
      </th>

       <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" matTooltip="Edit Row" status-color [status]= "element.active"> {{element.itemName}} </a> 
    
    </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <!-- SKU Column -->
    <ng-container cdkColumnDef="itemSku">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{SKU}}

     <!--  <div class="sort-caret" style="position: relative">
          <div  class="pull-left over-flow" > {{SKU}}</div>
            <span class="sort hidden-print ">
              <b class="caret up" (click)="sortItems('desc','sku')"></b>
              <b class="caret down" (click)="sortItems('asc','sku')"></b>
            </span>
        </div> -->

      </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" ><a class="reportLink" style="text-decoration:none;" status-color [status]= "element.active" (click)="editRow(element);" >{{element.sku}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Description Column -->
    <ng-container cdkColumnDef="itemBrand">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" > {{BRAND_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t054brandObj)" status-color [status]= "element.active">{{element.t054brandObj.brandName}}</p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Rate Column -->
    <ng-container cdkColumnDef="itemSalesRate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign alignRight" >  {{SALES_RATE_BOLD}}
     
<!--  <div class="sort-caret" style="position: relative">
          <div class="pull-left over-flow"> {{RATE_BOLD}}</div>
            <span class="sort hidden-print ">
              <b class="caret up" (click)="sortItems('desc','sortBySalesRate')"></b>
              <b class="caret down" (click)="sortItems('asc','sortBySalesRate')"></b>
            </span>
  </div> -->

      </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignRight reportValueRightAlign" style="padding-right: 20px;" status-color [status]= "element.active"> {{element.currencySymbol}} {{element.salesRate | myCurrency }} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="itemPurchaseRate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign alignRight" >  {{PURCHASE_RATE_BOLD}}
        </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign" style="padding-right: 45px;" status-color [status]= "element.active"> {{element.currencySymbol}} {{element.purchaseRate | myCurrency }} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="manufacturer">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft  "> {{MANUFACTURER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="reportHeaderLeftAlign  alignLeft"  status-color [status]= "element.active"> {{element.manufacturer}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
    <!-- Stock On Hand Column -->
    <ng-container cdkColumnDef="stockOnHand">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel qty">{{STOCK_ON_HAND_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="qty" > {{element.inventoryOpeningStock }} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
    <!-- Account Name Column -->
    <ng-container cdkColumnDef="accName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel "> {{ACCOUNT_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" > {{element.salesAccountName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- review asset starts -->
 <ng-container cdkColumnDef="assetNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel "> {{ASSET_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="" status-color [status]="element.activeFlag"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.assetNumber}}</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="assetName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel "> {{ASSET_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="" status-color [status]="element.activeFlag"> {{element.assetName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="assetDesc">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel "> {{ASSET_DESCRIPTION_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="" status-color [status]="element.activeFlag"> {{element.description}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="assetType">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{MODEL_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.activeFlag"> {{element.modelNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="assetVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{VENDOR_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.activeFlag"><a class="reportLink" style="text-decoration:none;" (click)="redirectToVendor(element);" > {{element.vendorName}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="purchasedOn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{PURCHASED_ON_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.activeFlag"> {{element.purchasedOn | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="checkIn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> {{CHECK_IN}} </th>
      <td cdk-cell class="alignCenter smallDiv" *cdkCellDef="let element">
        <button mat-button type="button" mat-icon-button
          [disabled]="element.assetTransCount > 0 && element.lastAssetTransMoveType === 'CHECK IN'"
          [ngStyle]="{'cursor': element.assetTransCount > 0 && element.lastAssetTransMoveType === 'CHECK IN' ? 'no-drop' : 'pointer'}"
          (click)="assetTransaction(element,'CHECK IN')" matSuffix mat-button aria-label="Clear">
          <mat-icon matTooltip="Checkin Assets" class="mat_icon_color">add_shopping_cart
          </mat-icon>
        </button>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container>
    <ng-container cdkColumnDef="checkOut">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv"> {{CHECK_OUT}} </th>
      <td cdk-cell class="alignCenter smallDiv" *cdkCellDef="let element">
        <button mat-button type="button" mat-icon-button
          [disabled]="(element.assetTransCount > 0 && element.lastAssetTransMoveType === 'CHECK OUT') || element.assetTransCount === 0"
          [ngStyle]="{'cursor': element.assetTransCount > 0 && element.lastAssetTransMoveType === 'CHECK OUT' ? 'no-drop' : 'pointer'}"
          (click)="assetTransaction(element,'CHECK OUT')" matSuffix mat-button aria-label="Clear">
          <mat-icon matTooltip="Checkout Assets" class="mat_icon_color">shopping_cart
          </mat-icon>
        </button>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container>
      <ng-container cdkColumnDef="delete">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight flex76">{{DELETE_BOLD}}</th>
      <td cdk-cell class="alignRight flex76" *cdkCellDef="let element">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
          <mat-icon matTooltip="Delete" class="mat_icon_color" (click)="deleteRow(element)">delete</mat-icon> </button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- review asset ends -->
<!--review indent starts -->
 <ng-container cdkColumnDef="indentNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{INDENT_NO}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.invoiceNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- review indent ends -->

<!-- view project starts-->
<ng-container cdkColumnDef="projectName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.projectName}} </td>
</ng-container>
<ng-container cdkColumnDef="projectDesc">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_DESC}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.projectDesc}} </td>
</ng-container>
<ng-container cdkColumnDef="projectCode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_CODE}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.projectCode}} </td>
</ng-container>
<ng-container cdkColumnDef="comment">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_COMMENT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.comment}} </td>
</ng-container>

<ng-container cdkColumnDef="projectstatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_STATUS}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.t004ProjectStatusObj.lookupValue" class="alignCenter"> {{element.t004ProjectStatusObj.lookupValue}} </td>
</ng-container>
<ng-container cdkColumnDef="addTask">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{CREATE_TASK}}</th>
      <td cdk-cell *cdkCellDef="let element">
      <button mat-button type="button" (click)="addTask(element);" matSuffix mat-button aria-label="Clear">
       <mat-icon matTooltip="Create Task"  class="mat_icon_color">add</mat-icon>
     </button> </td>
</ng-container>
    <!--  view project ends -->

<ng-container cdkColumnDef="addReminder">
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #reminderBtn (click)="addReminder(element,reminderBtn);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Add Reminder"  class="mat_icon_color">schedule
    </mat-icon> </button> </td>
</ng-container>

<ng-container cdkColumnDef="moreReminder">
<th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight div20px">
      </th>
    <td cdk-cell class="mediumFlexDiv" *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #reminderBtn (click)="addReminder(element,reminderBtn);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Add Reminder"  class="mat_icon_color">schedule
    </mat-icon> </button> </td>
</ng-container>

<ng-container cdkColumnDef="reminder">
   <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel reportHeaderRightAlign mediumFlexDiv div20px"> </th>
     <td cdk-cell class="mediumFlexDiv" *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #reminderBtn (click)="addReminder(element,reminderBtn);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Add Reminder"  class="mat_icon_color">schedule
    </mat-icon> </button> </td>
</ng-container>



 <!--  view Organization Starts -->
 <ng-container cdkColumnDef="organizationName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ORGANIZATIONPROFILE_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.organizationName}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="portalName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PORTAL_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.portalName}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="address1">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ADDRESS1}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.address1}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="address2">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ADDRESS2}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.address2}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="city">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ORGAIZATION_CITY}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.city}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!--  view Organization ends -->
<!-- view for items group starts -->
 <ng-container cdkColumnDef="igGroupType">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft "> {{ITEM_GROUP_TYPE_BOLD}} </th>
       <td cdk-cell *cdkCellDef="let element" class="alignLeft">
         <p *ngIf="element!=undefined && this.commonUtils.checkNullOrUndefined(element.itemGroupType) &&  element.itemGroupType==='G'">{{ITEM_GROUP_GOODS}}</p>
        <p *ngIf="element!=undefined && this.commonUtils.checkNullOrUndefined(element.itemGroupType) && element.itemGroupType==='S'">{{ITEM_GROUP_SERVICES}}</p> 
      </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="itemType">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft  "> {{ITEM_TYPE}} </th>
       <td cdk-cell *cdkCellDef="let element"  class="alignLeft">
         <p *ngIf="element!=undefined && this.commonUtils.checkNullOrUndefined(element.itemType) &&  element.itemType==='G'" status-color [status]= "element.active">{{ITEM_GROUP_GOODS}}</p>
        <p *ngIf="element!=undefined && this.commonUtils.checkNullOrUndefined(element.itemType) && element.itemType==='S'" status-color [status]= "element.active">{{ITEM_GROUP_SERVICES}}</p> 
      </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="igName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{ITEM_GROUP_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> 
        <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" > {{element.itemGroupName}}</a>
       </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="igDescrption">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{DESCRIPTION_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.description}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="igUnits">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft "> {{UNITS_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.units}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="igCategory">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{CATEGORY_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.t206_CategoryObj.categoryName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="igSubCategory">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{SUB_CATEGORY_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.t207_SubCategoryObj.subCategoryName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     
<!-- view for items group ends -->
<ng-container cdkColumnDef="productionSlip" class="smallDiv" style="flex: 0 1 56px !important;">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv"></th>
   <td cdk-cell *cdkCellDef="let element" class="smallDiv " >
        <button matTooltip="Create Production Slip" mat-button type="button" matSuffix mat-icon-button aria-label="Clear" [disabled]="element.hideProductionslipIcon" (click)="viewProductionSlip(element)"><mat-icon>note</mat-icon></button></td>

</ng-container>


<ng-container cdkColumnDef="quickBook"   style="flex: 0 1 45px !important;" >
     <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight  "></th>
   <td cdk-cell *cdkCellDef="let element"   >
        <button matTooltip="QuickBook Sync" mat-button type="button" matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hideQuickBookSync" (click)="quickBookSync(element)"><mat-icon>book_border</mat-icon></button>

  <button   *ngIf="moduleName === 'VIEW_INVOICE'"  matTooltip="Fetch QuickBook Payment and Sync to ERP" mat-button type="button" matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hideQuickBookSync" (click)="quickBookPaymentSync(element)"><mat-icon>note_border</mat-icon></button>

      </td>

</ng-container>
 <ng-container cdkColumnDef="invoiceConvert"  class="div20px">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight" ></th>
       <td cdk-cell  *cdkCellDef="let element" >
         <button mat-button type="button" (click)="convertToInvoice(element);" matSuffix  aria-label="Clear" >
          <mat-icon  matTooltip="Convert to Invoice" class="mat_icon_color">receipt</mat-icon> 
        </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- <ng-container cdkColumnDef="qbPayment" class="smallDiv"  style="flex: 0 1 45px !important;" >
     <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv"></th>
   <td cdk-cell *cdkCellDef="let element" class="flex100 " >
         <button matTooltip="Fetch QuickBook Payment and Sync to ERP" mat-button type="button" matSuffix mat-button aria-label="Clear" [disabled]="element.hideQuickBookSync" (click)="quickBookPaymentSync(element)"><mat-icon>note</mat-icon></button>

      </td>

</ng-container> -->
<ng-container cdkColumnDef="qbSync" class="smallDiv"  style="flex: 0 1 45px !important;" >
     <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv"></th>
   <td cdk-cell *cdkCellDef="let element" class="flex100 " >
        <button matTooltip="QuickBook Sync" mat-button type="button" matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hideQuickBookSync" (click)="quickBookSync(element)"><mat-icon>book</mat-icon></button>

   </td>
</ng-container>
<!-- User starts -->
  <ng-container cdkColumnDef="userRole">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ROLE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"><a [routerLink]="['/settings/reviewrole']" matTooltip="Take me to View Role screen" status-color [status]="element.userStatus"> {{element.roleName}} </a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="userCode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{USER_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.userStatus"> {{element.userName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="userName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMP_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.userStatus"> {{element.empName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="userEmail">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMAIL_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.userStatus"> {{element.email}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- User ends-->
<!-- Role starts -->

 <ng-container cdkColumnDef="roleName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" > {{element.roleName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   
    <ng-container cdkColumnDef="roleDescription">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{DESCRIPTION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" > {{element.roleDescription}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


     <ng-container cdkColumnDef="roleDelete" class="smallDiv">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight" > {{DELETE_BOLD}} </th>
      <td cdk-cell  *cdkCellDef="let element" class="alignRight"   >
        <button mat-button type="button" (click)="deleteItem(element)" matSuffix mat-button aria-label="Clear">
          <mat-icon matTooltip="Delete Item"  >delete</mat-icon> </button>
         </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- Role ends -->
<!-- View Leave Screen-->




      <ng-container cdkColumnDef="CompOff">
        <th cdk-header-cell *cdkHeaderCellDef> {{COMP_OFF}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
          <span *ngIf="element.compOffAvailable != null">
            {{element.compOffApproved}}</span> 
          <span *ngIf="element.compOffAvailable == null">
            0.00</span>
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="LOP">
        <th cdk-header-cell *cdkHeaderCellDef> {{LOP}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
          <span *ngIf="element.lop != null">
            {{element.lop}}</span> 
          <span *ngIf="element.lop == null">
            0.00</span> 
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<ng-container cdkColumnDef="leaveDate">
        <th cdk-header-cell *cdkHeaderCellDef> {{DATE}}</th>
        <td cdk-cell *cdkCellDef="let element"> 
          {{element.startDate | dateFormatPipe}} - {{element.endDate | dateFormatPipe}}
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <!-- <ng-container cdkColumnDef="endDate">
        <th cdk-header-cell *cdkHeaderCellDef> {{TO_DATE}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
          {{element.endDate | dateFormatPipe}}
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->

      <ng-container cdkColumnDef="permission">
        <th cdk-header-cell *cdkHeaderCellDef> {{PERMISSION_HRS}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
          {{element.appliedPermissionHours}}:{{element.appliedPermissionMinutes}}
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="appliedDate">
        <th cdk-header-cell *cdkHeaderCellDef> {{APPLIED_DATE}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
         {{element.appliedDate | dateTimeFormatPipe}}
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

          <ng-container cdkColumnDef="Type">
          <th cdk-header-cell *cdkHeaderCellDef> {{LEAVE_TYPE}} </th>
          <td cdk-cell *cdkCellDef="let element">
            <span  *ngIf="element.trxType !=null && element.trxType === 'DEBIT'" status-color 
            [status]="'LEAVE_DEBIT'" >{{element.trxType}}</span>
              <span  *ngIf="element.trxType !=null && element.trxType != 'DEBIT' && element.trxType === 'CREDIT'" status-color [status]="'LEAVE_CREDIT'" >{{element.trxType}} </span>
              <span  *ngIf="element.trxType !=null && element.trxType != 'DEBIT' && element.trxType != 'CREDIT'" status-color [status]="element.trxType" >{{element.trxType}}</span>
          </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container matColumnDef="revokeAppliedLeave" >
    <th cdk-header-cell *cdkHeaderCellDef></th>
      <td cdk-cell *cdkCellDef="let element" > 
        <button  matTooltip="Revoke Applied Leave" mat-button type="button"  matSuffix mat-button mat-icon-button aria-label="Clear" [disabled]="element.t004LookupStatusObj.lookupValue != 'APPLIED'" (click) = "revokeAppliedLeaveStatus(element)">
      <mat-icon class="reportLink" >undo</mat-icon> </button> </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      
<!-- View Leave Screen-->
<!-- Approve Leave Starts -->
          <ng-container cdkColumnDef="emp_name">
              <th cdk-header-cell *cdkHeaderCellDef > {{EMP_NAME}} </th>
              <td cdk-cell *cdkCellDef="let element"> 
               {{element.userName}} - {{element.firstName}} {{element.lastName}}
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="Month">
              <th cdk-header-cell *cdkHeaderCellDef > {{MONTH}} </th>
              <td cdk-cell *cdkCellDef="let element" style="text-transform: uppercase;"> {{element.month}} </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="CL_Available">
              <th cdk-header-cell *cdkHeaderCellDef> {{CL_AVAILABLE}} </th>
              <td cdk-cell *cdkCellDef="let element">
                {{element.clAvailableInHeader}}
             <!--    <mat-form-field class="col-lg-10" floatLabel="{{ element.editing ? 'float' : 'never'}}">
                     <input matInput [formControl]="element.controls['clApproved']"
                      [readonly]="!element.editing" (input)="calculateTotalApproveDays(element)"  [(ngModel)]="element.clApproved" roundoff noAndDotOnly>
                  </mat-form-field> -->
                
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="SL_Available">
              <th cdk-header-cell *cdkHeaderCellDef> {{SL_AVAILABLE}} </th>
              <td cdk-cell *cdkCellDef="let element">
                {{element.slAvailableInHeader}}
               <!--  <mat-form-field class="col-lg-10"  floatLabel="{{ element.editing ? 'float' : 'never'}}">
                     <input matInput [formControl]="element.controls['slApproved']"
                      [readonly]="!element.editing" (input)="calculateTotalApproveDays(element)"  [(ngModel)]="element.slApproved" roundoff noAndDotOnly>
                  </mat-form-field> -->
               
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="CL">
              <th cdk-header-cell *cdkHeaderCellDef> {{CL_APPLIED}} </th>
              <td cdk-cell *cdkCellDef="let element" > 
                <span *ngIf="element.trxType === 'CREDIT'">
                  {{element.clAvailable}}</span>
                <span *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue === 'CL'">
                  {{element.totalAppliedDays}} </span>
                <span *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue != 'CL'">
                  0.00 </span>
                <span *ngIf="element.trxType === 'PERMISSION'">
                  0.00 </span>
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="SL">
              <th cdk-header-cell *cdkHeaderCellDef > {{SL_APPLIED}} </th>
              <td cdk-cell *cdkCellDef="let element" > 
                <span *ngIf="element.trxType === 'CREDIT'">
                  {{element.slAvailable}}</span>
                <span *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue === 'SL'">
                  {{element.totalAppliedDays}} </span>
                <span *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue != 'SL'">
                  0.00 </span>
                <span *ngIf="element.trxType === 'PERMISSION'">
                  0.00 </span>
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


    <ng-container cdkColumnDef="WFH">
      <th cdk-header-cell *cdkHeaderCellDef> WFH APPLIED </th>
      <td cdk-cell *cdkCellDef="let element">
       <span
          *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue === 'WFH'">
          {{element.totalAppliedDays}} </span>
           <span
          *ngIf="element.trxType === 'DEBIT' && this.commonUtils.checkNullOrUndefined(element.t004LookupLeaveTypeObj) && element.t004LookupLeaveTypeObj.lookupValue != null && element.t004LookupLeaveTypeObj.lookupValue != 'WFH'">
          0.00 </span>
        <span *ngIf="element.trxType === 'PERMISSION'">
          0.00 </span>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container>

             <ng-container cdkColumnDef="CompOffAvailable">
              <th cdk-header-cell *cdkHeaderCellDef > {{COMP_OFF}} </th>
              <td cdk-cell *cdkCellDef="let element"> {{element.compoffAvailableInHeader}} </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

           <!-- <ng-container cdkColumnDef="LOP">
              <th cdk-header-cell *cdkHeaderCellDef> {{LOP}} </th>
              <td cdk-cell *cdkCellDef="let element"> 
                <span *ngIf="element.lop != null">
                  {{element.lop}}</span> 
                <span *ngIf="element.lop == null">
                  0.00</span> 
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->
            <!-- <ng-container cdkColumnDef="startDate">
        <th cdk-header-cell *cdkHeaderCellDef> {{FROM_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element">{{element.startDate | dateFormatPipe}}
          
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="endDate">
        <th cdk-header-cell *cdkHeaderCellDef> {{TO_DATE}} </th>
        <td cdk-cell *cdkCellDef="let element"> 
         {{element.endDate | dateFormatPipe}}
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->
                  <ng-container cdkColumnDef="LeaveReason">
                <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> {{LEAVE_REASON}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.leaveReason}} </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="CL_Approved">
              <th cdk-header-cell *cdkHeaderCellDef> {{CL_APPROVED}} </th>
              <td cdk-cell *cdkCellDef="let element">
                {{element.clApproved}}
             <!--    <mat-form-field class="col-lg-10" floatLabel="{{ element.editing ? 'float' : 'never'}}">
                     <input matInput [formControl]="element.controls['clApproved']"
                      [readonly]="!element.editing" (input)="calculateTotalApproveDays(element)"  [(ngModel)]="element.clApproved" roundoff noAndDotOnly>
                  </mat-form-field> -->
                
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="SL_Approved">
              <th cdk-header-cell *cdkHeaderCellDef> {{SL_APPROVED}} </th>
              <td cdk-cell *cdkCellDef="let element">
                {{element.slApproved}}
               <!--  <mat-form-field class="col-lg-10"  floatLabel="{{ element.editing ? 'float' : 'never'}}">
                     <input matInput [formControl]="element.controls['slApproved']"
                      [readonly]="!element.editing" (input)="calculateTotalApproveDays(element)"  [(ngModel)]="element.slApproved" roundoff noAndDotOnly>
                  </mat-form-field> -->
               
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="CompOff_Approved">
              <th cdk-header-cell *cdkHeaderCellDef> {{COMP_OFF_APPROVED}} </th>
              <td cdk-cell *cdkCellDef="let element">
                   {{element.compOffApproved}}
                   <!--   <input matInput [formControl]="element.compOffApproved"
                      [readonly]="!element.editing"(input)="calculateTotalApproveDays(element)"   [(ngModel)]="element.compOffApproved" roundoff noAndDotOnly> -->
                  
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="Lop_Approved">
              <th cdk-header-cell *cdkHeaderCellDef> {{LOP}} </th>
              <td cdk-cell *cdkCellDef="let element">
                {{element.lop}}
               <!--  <mat-form-field class="col-lg-10"  >
                     <input matInput [formControl]="element.controls['lop']"
                      [readonly]="!element.editing" (input)="calculateTotalApproveDays(element)"  [(ngModel)]="element.lop" roundoff noAndDotOnly>
                  </mat-form-field> -->
                
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="Total_Approved">
              <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> {{TOT_APPROVED_DAYS}}</th>
              <td cdk-cell *cdkCellDef="let element" class="alignCenter">
                <span>{{element.approvedDays }}</span>
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

                <ng-container cdkColumnDef="Status">
                <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> {{STATUS}} </th>
                <td cdk-cell *cdkCellDef="let element" class="alignCenter" status-color [status]="element.t004LookupStatusObj.lookupValue">
                  {{element.t004LookupStatusObj.lookupValue}}
                </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
            <ng-container cdkColumnDef="actionsColumn"  > 
                <th cdk-header-cell *cdkHeaderCellDef  >
                <!--   <button mat-button color="accent" (click)="dataSource.createNew()"><i class="fa fa-plus mat-icon"></i></button> -->
                </th>
                <td cdk-cell *cdkCellDef="let element"  >
                    <button  matTooltip="Edit Row"  #moreMenu  *ngIf="this.commonUtils.checkNullOrUndefined( element.t004LookupStatusObj) &&
                    (
                     element.t004LookupStatusObj.lookupValue === 'APPLIED' || element.t004LookupStatusObj.lookupValue === 'REJECTED')" mat-button color="primary" focusable="false" (click)="editRow(element,moreMenu)">
                        <mat-icon class="material-icons">edit</mat-icon>
                    </button>
                    <button matTooltip="Edit Row" *ngIf=" this.commonUtils.checkNullOrUndefined(element.t004LookupStatusObj) && (element.t004LookupStatusObj.lookupValue != 'APPLIED'&& element.t004LookupStatusObj.lookupValue != 'REJECTED')" mat-button color="primary" disabled="true" focusable="false"  >
                        <mat-icon class="material-icons">edit</mat-icon>
                    </button>
                    <ng-template [ngIf]="moduleName!='PROJECT_PLAN'">
                      
                    <button matTooltip="Update Row"   mat-button color="primary" focusable="false" (click)=" updateLeave('Approve',element)">
                        <mat-icon class="material-icons">done</mat-icon>
                    </button>
                    </ng-template>
                    <button mat-button color="primary" focusable="false" *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupStatusObj) && element.t004LookupStatusObj.lookupValue != 'REJECTED' && element.t004LookupStatusObj.lookupValue != 'APPROVED'" (click)="updateLeave('Reject',element);">
                         <mat-icon class="material-icons">done</mat-icon>
                    </button>
                    <button matTooltip="Update Row" mat-button color="primary" focusable="false" *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupStatusObj) && (element.t004LookupStatusObj.lookupValue === 'REJECTED' || element.t004LookupStatusObj.lookupValue === 'APPROVED')"(click)="updateLeave('Reject',element);" ><!-- disabled="true"  this was added to the previous tag and 9014 commented this as this was throwing warning-->
                        <mat-icon class="material-icons">close</mat-icon>
                    </button>
                    <button   mat-button color="primary" focusable="false" (click)="editRow(element)">
                  <mat-icon class="material-icons">edit</mat-icon>
                </button>
                 
          <!--   <button matTooltip="Delete Row" mat-button color="primary" focusable="false" (click)="element.cancelOrDelete();deleteSelectedTask(element)">
                  <i class="fa fa-times mat-icon"></i>
                </button>
           -->      </td> 
          

             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

            <ng-container cdkColumnDef="approveActionColumn">
                <th cdk-header-cell *cdkHeaderCellDef >
                
                </th>
                <td cdk-cell *cdkCellDef="let element" style="display: flex">
                    <button matTooltip="Edit Row" #moreMenu  mat-button color="primary" focusable="false" (click)="editRow(element,moreMenu)" >
                       <mat-icon class="mat_icon_color">edit</mat-icon>
                    </button>
                    
                    <button matTooltip="Approve" mat-button color="primary" focusable="false" (click)=" updateLeave('APPROVED',element)"  >
                        <mat-icon class="mat_icon_color">check</mat-icon>
                    </button>
                    
                    <button matTooltip="Reject" mat-button color="primary" focusable="false"  (click)="updateLeave('REJECTED',element);" >
                       <mat-icon class="mat_icon_color">close</mat-icon>
                    </button>
                </td> 
          

             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- Approve Leave Ends -->
<!-- Manage category starts - 9072 -->
 <ng-container cdkColumnDef="categoryName">
              <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> CATEGORY</th>
              <td cdk-cell *cdkCellDef="let element" class="alignCenter">
                <span status-color [status]="element.status">{{element.categoryName }}</span>
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
            <ng-container cdkColumnDef="subcategoryName">
              <th cdk-header-cell *cdkHeaderCellDef class="alignLeft">SUB CATEGORY </th>
              <td cdk-cell *cdkCellDef="let element" class="alignLeft">
                <span status-color [status]="element.status">{{element.subCategoryName }}</span>
              </td>
             <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- Manage category ends - 9072 -->
    <!-- Price List START ------ 9056 -->
    <!-- Name Column -->
    <ng-container cdkColumnDef="priceListName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> NAME</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" status-color [status]= "element.status"> {{element.name}}</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Description Column -->
    <ng-container cdkColumnDef="priceListDescription">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> DESCRIPTION</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]= "element.status" class="alignLeft"> {{element.description}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Type Column -->
    <ng-container cdkColumnDef="priceListType">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" > TYPE</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]= "element.status" class="alignLeft"> {{element.itemType}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Currency Column -->
    <!-- <ng-container cdkColumnDef="currency">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> CURRENCY </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.currency}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->

<!-- SALES PERSON COLUMNS - STARTS -- 9071 -->
<ng-container cdkColumnDef="salesPersonName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" status-color [status]="element.status"> {{element.name}}</a>  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<ng-container cdkColumnDef="salesPersonEmail">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{EMAIL_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.status" class="alignLeft"> {{element.email}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="salesPersonMobileNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{MOBILE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]="element.status" class="alignLeft"> {{element.mobileNo}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- SALES PERSON COLUMNS - ENDS -- 9071 -->

    <!-- Description Column -->
    <ng-container cdkColumnDef="details">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{MARKUP_MARKDOWN}} </th>
      <td cdk-cell *cdkCellDef="let element" status-color [status]= "element.status" class="alignLeft">  
     
        <mat-icon  style="color: crimson" *ngIf="this.commonUtils.checkNullOrUndefined(element.percentageType) && element.percentageType=='Markdown'">arrow_downward </mat-icon>
             
                <mat-icon  style="color: green" *ngIf="this.commonUtils.checkNullOrUndefined(element.percentageType) && element.percentageType=='Markup'"> arrow_upward</mat-icon> 
        {{element.percentageType}} 
       
        <span style="margin-left:5px!important;" *ngIf="this.commonUtils.checkNullOrUndefined(element.percentageType) && element.percentageType!='ItemLevel'"> 
         {{element.percentageValue}} {{PERCENTAGE_SYMBOL}}</span>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Round Off Column -->
   <!--  <ng-container cdkColumnDef="rounding">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" mat-sort-header> ROUNDING </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.rounding}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->
    <!-- Price List END ------ 9056 -->
  <!--Issues starts ------ 9072 -->
     <ng-container cdkColumnDef="issuesDesc">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{DESCRIPTION_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.description}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="createdBy"  >
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv"> {{CREATED_BY_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="boldLabel smallDiv"> {{element.createdBy}}  </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="issuecreatedDate"  >
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv"> {{BUDGET_CREATED_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.createdTs | dateFormatPipe}}   </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
         <ng-container cdkColumnDef="issueStatus">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv"> {{STATUS_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="boldLabel smallDiv"> {{element.t004_LookupTaskStatusObj.lookupValue}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="issuePortalName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv"> {{PORTAL_NAME}}</th>
       <td cdk-cell *cdkCellDef="let element" class="boldLabel smallDiv"> {{element.portalName}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="refreshDownloadFiles" > 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight mediumFlexDiv">
        <!--<a (click)="refreshIconClick(true)">
      <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>
    --></th>
      <td cdk-cell *cdkCellDef="let element" class="boldLabel mediumFlexDiv">  
          <button mat-button type="button" (click)="downloadAttachedFiles(element)" matSuffix mat-button aria-label="Clear">
            <mat-icon  matTooltip="Download Attached Files" class="mat_icon_color">vertical_align_bottom</mat-icon>
          </button>
       </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!--Issues ends ------ 9072 -->
 <ng-container cdkColumnDef="licenseName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{LICENSE_NAME__BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.licenseName}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="primaryLicenseName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{CONTACT_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.primaryContactName}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="primaryLicenseEmail">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{EMAIL_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.primaryContactEmailAddress}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="licenseProduct">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{PRODUCTS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.enableProduct}}  </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
     <ng-container cdkColumnDef="creditBalance">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight"> {{BALANCE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight "> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t100ContactCustomerId.t003Currency)"> {{element.t100ContactCustomerId.t003Currency.symbol}} {{element.creditBalance | myCurrency}} </p>
      <p *ngIf="element.t100ContactCustomerId === null || element.t100ContactCustomerId === undefined"> {{element.creditBalance | myCurrency}}</p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


      <ng-container cdkColumnDef="vendorBalance">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight  ">{{BALANCE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="erp-money-class  "><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t100ContactVendorId.t003Currency)"> {{element.t100ContactVendorId.t003Currency.symbol}} {{element.creditBalance | myCurrency}}</p> 
        <p *ngIf="element.t100ContactVendorId === null || element.t100ContactVendorId === undefined"> {{element.creditBalance | myCurrency}}</p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="carrierPackage">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CARRIER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.carrier}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!--     warehouse starts -->
  <ng-container cdkColumnDef="locationName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{LOCATION_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" >
      <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" > {{element.locationName}}</a>  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="isWarehouse">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{IS_WAREHOUSE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" ><p *ngIf="element.wareHouse == true"> Yes </p> <p *ngIf="element.wareHouse == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="parent">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{PARENT_LOCATION}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="element.t028_LocationObj != null && element.t028_LocationObj != undefined"> {{element.t028_LocationObj.locationName}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- warehouse ends -->

   <!--  production slip starts -->
    <ng-container cdkColumnDef="productionSlipNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PRODUCTION_SLIP_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.productionSlipNumber}}</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     
  
      <ng-container cdkColumnDef="productionSlipSONumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{SO_NO}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="viewSalesOrder(element);" >{{element.soNumber}}</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  
       <ng-container cdkColumnDef="productionSlipCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{ACCOUNT_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.customerName}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="repEmail">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EMAIL_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.repEmail}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <ng-container cdkColumnDef="productionSlipStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{STATUS_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.productionSlipStatus}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <!--   production slip ends -->

      <!-- project plan view screen -->

      <ng-container cdkColumnDef="autoschedule">
            <th cdk-header-cell *cdkHeaderCellDef class="smallDiv" > {{AUTO_SCHEDULE}} </th>
            <td cdk-cell *cdkCellDef="let element"  class="smallDiv" > {{element.autoSchedule}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskNumber">
            <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel flex100"> {{TASK_NUMBER}} </th>
            <td cdk-cell *cdkCellDef="let row" class="flex100" status-color [status]="row.percent_complete"> 
               <button mat-raised-button *ngIf="row.summary"  (click)="openChildTask(row)" style="background-color: yellowgreen"> 

                 <p    matTooltip="View Child Task for {{row.taskNumber}}"> {{row.taskNumber}} 
                 <!-- <mat-icon style="vertical-align: middle;">subdirectory_arrow_right</mat-icon>
                 -->
                 </p>
                </button>
                 
              <span *ngIf="row.summary==false">{{row.taskNumber}}</span> 
              <span *ngIf="this.commonUtils.checkNullOrUndefined(row.pmtRefId)"> - {{row.pmtRefId}}  </span>
            </td>
         <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container> 
        <ng-container cdkColumnDef="taskName"  >
            <th cdk-header-cell *cdkHeaderCellDef class="flex15Percent"> {{TASK_NAME}}</th>
            <td cdk-cell *cdkCellDef="let row" status-color [status]="row.percent_complete" class="flex15Percent "> 
              {{row.taskName}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef> Total </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskDesc">
            <th cdk-header-cell *cdkHeaderCellDef class="flex30Percent"> {{DESC}} </th>
            <td cdk-cell *cdkCellDef="let row" status-color [status]="row.percent_complete" class="flex30Percent">
             {{ row.taskDesc}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  
        <ng-container cdkColumnDef="assignedResource">
          <th cdk-header-cell *cdkHeaderCellDef   class="flexdate"> {{RESOURCE}} </th>
          <td cdk-cell *cdkCellDef="let row"  class="flexdate" status-color [status]="row.percent_complete">
            <p *ngIf="row.t001_UserObj != null && row.t001_UserObj != undefined">
            {{row.t001_UserObj.userName}} - {{row.t001_UserObj.empName}} </p>
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskStartDate">
          <th cdk-header-cell *cdkHeaderCellDef class="flexdate" > {{TASK_DATES}} </th>
          <td cdk-cell *cdkCellDef="let row" class="flexdate" status-color [status]="row.percent_complete"> 
            {{row.startDate | dateFormatPipe}} -   {{row.endDate | dateFormatPipe}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="baseline_dates">
          <th cdk-header-cell *cdkHeaderCellDef class="flexdate" > {{BASELINE_DATES}} </th>
          <td cdk-cell *cdkCellDef="let row" class="flexdate" status-color [status]="row.percent_complete"> 
            {{row.baseLine_start_date | dateFormatPipe}} -   {{row.baseLine_end_date | dateFormatPipe}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskEndDate"  >
          <th cdk-header-cell *cdkHeaderCellDef  class="flexdate">{{DELIVERY_DATE}}</th>
          <td cdk-cell *cdkCellDef="let row" class="flexdate" status-color [status]="row.percent_complete"> 
             {{row.prodmoveDate | dateFormatPipe}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="taskHierarchy">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"></th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
        <button #taskHierarchyId mat-button type="button" matSuffix mat-button aria-label="Clear"
          (click)="viewTaskHierarchy(element,taskHierarchyId)">
          <mat-icon matTooltip="View Task Hierarchy Level">low_priority</mat-icon>
        </button>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container>
        <ng-container cdkColumnDef="billing_hours">
          <th cdk-header-cell *cdkHeaderCellDef class= "reportHeaderRightAlign flex76"> {{BILL_HRS}} </th>
          <td cdk-cell *cdkCellDef="let row" class="reportValueRightAlign alignRight flex76" status-color [status]="row.percent_complete"> 
            {{row.billing_hours}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef class="reportValueRightAlign alignRight flex76">  {{getTotalBillableHrs()}}</td>
    </ng-container> 
        <ng-container cdkColumnDef="estimated_hours">
          <th cdk-header-cell *cdkHeaderCellDef class= "reportHeaderRightAlign flex76"> {{EST_HRS}} </th>
          <td cdk-cell *cdkCellDef="let row" class="reportValueRightAlign alignRight flex76" status-color [status]="row.percent_complete"> 
            {{row.estimated_hours}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef class="reportValueRightAlign alignRight flex76"> {{getTotaEstimatedHrs()}} </td>
    </ng-container> 
    
        <ng-container cdkColumnDef="actual_hours">
          <th cdk-header-cell *cdkHeaderCellDef class= "reportHeaderRightAlign flex76"> {{ACTUAL_HRS}} </th>
          <td cdk-cell *cdkCellDef="let row" class="reportValueRightAlign alignRight flex76" status-color [status]="row.percent_complete" style="cursor: pointer;" matTooltip="Click here to show the sum of worked hrs against the allocated users" (click)="openActualHours(row)" > 
            <u>{{row.actualHours}}</u>
          </td>
         <td cdk-footer-cell *cdkFooterCellDef class="reportValueRightAlign alignRight flex76"> {{getTotalActualHrs()}} </td>
    </ng-container> 
        <ng-container cdkColumnDef="non_billing_hours">
          <th cdk-header-cell *cdkHeaderCellDef class= "reportHeaderRightAlign flex76"> {{NON_BILL_HRS}} </th>
          <td cdk-cell *cdkCellDef="let row" class="reportValueRightAlign alignRight flex76" status-color [status]="row.percent_complete"> 
            {{row.nonBillableHours}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef class="reportValueRightAlign alignRight flex76"> {{getTotalNonBillableHrs()}} </td>
    </ng-container>          

        <ng-container cdkColumnDef="percent_complete">
          <th cdk-header-cell *cdkHeaderCellDef  class= "reportHeaderRightAlign flex76">{{COMPLETED_PERCENT}} </th>
          <td cdk-cell *cdkCellDef="let row" class="reportValueRightAlign alignRight flex76" status-color [status]="row.percent_complete"> 
            {{row.percent_complete}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="attachedFiles">
          <th cdk-header-cell *cdkHeaderCellDef  class= "reportHeaderRightAlign flex76"></th>
         <td cdk-cell *cdkCellDef="let row" class="mediumFlexDiv " >
        <button type="button" matSuffix mat-button  mat-icon-button aria-label="Clear" matTooltip="Download Attachment" [disabled]="row.summary" (click)="downloadAttachments(row)"><mat-icon>file_download</mat-icon></button></td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <!-- project plan view screen ends -->
        <ng-container cdkColumnDef="tsprojectName">
            <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flex10Percent">{{PROJECT_NAME}} </th>
            <td cdk-cell *cdkCellDef="let element" class="flex10Percent"> {{element.t822ProjectObj.projectName}} </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="timeSheetprojectName">
            <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flex10Percent">{{PROJECT_NAME}} </th>
            <td cdk-cell *cdkCellDef="let element" class="flex10Percent"> {{element.projectName}} </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        
        
        <!-- timesheet 9103-->
        <ng-container cdkColumnDef="taskNumberTimesheet">
            <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel flex100"> {{TASK_NUMBER}} </th>
            <td cdk-cell *cdkCellDef="let row" class="flex100"> 
               {{row.taskNumber}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="isClientViewable">
          <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv">{{IS_CLIENTVIEWABLE}}</th>
          <td cdk-cell *cdkCellDef="let row"  class="smallDiv" status-color [status]="row.percent_complete" matTooltip="Internal or External ( client viewable)"><p *ngIf="row.clientViewable == true"
             style="background-color: transparent;color: blue;font-size: 20px;"> 
          <b>E </b></p> <p *ngIf="row.clientViewable == false"  style="background-color: transparent; color:red;  font-size: 20px;"><b> I</b> </p></td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

         <ng-container cdkColumnDef="versionNumber"  >
            <th cdk-header-cell *cdkHeaderCellDef class="flex100"> {{VERSION_NUMBER}} </th>
            <td cdk-cell *cdkCellDef="let row" class="flex100" status-color [status]="row.percent_complete"> 
              {{row.versionNumber}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  
    <ng-container cdkColumnDef="responsibleuser">
      <th cdk-header-cell *cdkHeaderCellDef class="flex100"> {{RESPONSIBLE_USER}} </th>
      <td cdk-cell *cdkCellDef="let row" class="flex100">
        <p *ngIf="this.commonUtils.checkNullOrUndefined(row.t001_ResponsibleUserObj)">
          {{row.t001_ResponsibleUserObj.userName}} - {{row.t001_ResponsibleUserObj.empName}}
        </p>
        <p *ngIf="!this.commonUtils.checkNullOrUndefined(row.t001_ResponsibleUserObj)">-</p>
      </td>
      <td cdk-footer-cell *cdkFooterCellDef> </td>
    </ng-container>
        <ng-container cdkColumnDef="taskNameTimesheet"  >
            <th cdk-header-cell *cdkHeaderCellDef class="flex15Percent"> {{TASK_NAME}} </th>
            <td cdk-cell *cdkCellDef="let row" class="flex15Percent"> 
              {{row.taskName}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskDescTimesheet">
            <th cdk-header-cell *cdkHeaderCellDef class="flex20Percent"> {{DESC}} </th>
            <td cdk-cell *cdkCellDef="let row" class="flex20Percent">
             {{ row.taskDesc}}
            </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskStartDateTimesheet">
          <th cdk-header-cell *cdkHeaderCellDef class="flexdate" > {{START_DATE}} </th>
          <td cdk-cell *cdkCellDef="let row" class="flexdate"> 
            {{row.startDate | dateFormatPipe}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="taskEndDateTimesheet">
          <th cdk-header-cell *cdkHeaderCellDef  class="flexdate">{{END_DATE}} </th>
          <td cdk-cell *cdkCellDef="let row" class="flexdate"> 
             {{row.endDate | dateFormatPipe}}
          </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     
     <ng-container cdkColumnDef="tsTaskStatus">
          <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{STATUS}}</th>
            <td cdk-cell *cdkCellDef="let element" status-color [status]="element.t004TaskStatusObj.lookupValue">
                <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004TaskStatusObj)" >
                  {{element.t004TaskStatusObj.lookupValue}} 
                </p>
            </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="taskStatus">
          <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{STATUS}}</th>
            <td cdk-cell *cdkCellDef="let element" status-color [status]="element.t004_LookupTaskStatusObj.lookupValue">
                <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004_LookupTaskStatusObj)" >
                  {{element.t004_LookupTaskStatusObj.lookupValue}} 
                </p>
            </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="editTask">
        <th cdk-header-cell *cdkHeaderCellDef></th>
         <td cdk-cell *cdkCellDef="let element">
          <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004TaskStatusObj) && element.t004TaskStatusObj.lookupValue == 'OPEN'" >
              <button matTooltip="Edit Row" #moreMenu mat-button color="primary" focusable="false" (click)="editRow(element,moreMenu)">
                 <mat-icon class="mat_icon_color" >edit</mat-icon> 
              </button>
          </p>
          </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="editHRISTask">
        <th cdk-header-cell *cdkHeaderCellDef></th>
         <td cdk-cell *cdkCellDef="let element">
          <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004_LookupTaskStatusObj) && element.t004_LookupTaskStatusObj.lookupValue == 'OPEN'" >
              <button matTooltip="Edit Row" #moreMenu mat-button color="primary" focusable="false" (click)="editRow(element,moreMenu)">
                 <mat-icon class="mat_icon_color" >edit</mat-icon> 
              </button>
          </p>
          </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="history">
        <th cdk-header-cell *cdkHeaderCellDef  ></th>
         <td cdk-cell *cdkCellDef="let element"  >

            <button matTooltip="History" #moreMenu mat-button color="primary" focusable="false" (click)="viewHistory(element)">
                 <mat-icon class="mat_icon_color" >history </mat-icon> 
              </button>

          
          </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="refreshHistory" > 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight smallDiv">
        <!--<a (click)="refreshIconClick(true)">
      <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>
    --></th>
       <td cdk-cell *cdkCellDef="let element" class="smallDiv">
           <button mat-button type="button" matSuffix mat-button aria-label="Clear" (click)="viewHistory(element)">
              <mat-icon class="reportLink"  matTooltip="History">history {{element.timeSheetId}}</mat-icon>
          </button>
          </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
         <!--timesheet 9103 -->
        <!-- manage artwork starts 9072 -->
        <ng-container cdkColumnDef="artworkDetailId">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ARTWORK_NUMBER_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" > {{element.invoiceDetailsId}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="artworkName">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ARTWORK_NAME_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" > {{element.itemName}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="createdDate">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CREATED_DATE_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" > {{element.createdTs | date:'short'}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="ApprovedDate">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{APPROVED_DATE_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" > <p *ngIf="element.artworkStatus==='READY FOR PRODUCTION'">{{element.updatedTs | date:'short'}}</p></td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="artworkNo">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ESTIMATE_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" > {{element.artworkNumber}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="artworkStatus">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STATUS_BOLD}}</th>
               <td cdk-cell *cdkCellDef="let element" status-color [status]="element.artworkStatus"> {{element.artworkStatus}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
         <!-- manage artwork ends 9072 -->
         <!-- tax group starts-->
         <ng-container cdkColumnDef="taxGroupName">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{TAX_GROUP_NAME}}</th>
             <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.taxGroupName}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="taxes">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{TAXES_INCLUDED}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.taxesIncluded}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="totalRate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{TOTAL_RATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.rate}} % </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      
    <!-- tax group ends-->

   <!-- <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row> -->
   
    
    <!-- Manage Stock Transfer Request Starts... -->
      <ng-container cdkColumnDef="date">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{DATE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element"> {{element.invoiceDate | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="stockRequestNumber">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STOCKREQ_NUM_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
            <ng-container cdkColumnDef="returnsNumber">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{RETURNS_NUMBER_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.invoiceNumber}} </a></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="requestedBy">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{REQ_BY_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" >
           <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t028_FromLocationObj)" >
       {{element.t028_FromLocationObj.locationName}} 
     </p>
        {{element.requestedBy}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      
      <ng-container cdkColumnDef="stockRequestStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{STATUS_BOLD}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupStockRequestStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupStockRequestStatusId)" >
       {{element.t004lookupStockRequestStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
         
       <ng-container cdkColumnDef="returnsStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{STATUS_BOLD}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupReturnsStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupReturnsStatusId)" >
       {{element.t004lookupReturnsStatusId.lookupValue}} 
     </p>
      </td>

       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>    
 <ng-container cdkColumnDef="returnsPackageStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{PACKAGE_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupPackageStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupPackageStatusId)" (click)="directToReviewPackage()" style="cursor: pointer;">
       {{element.t004lookupPackageStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="returnsReceivedStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{RECEIVED_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupReceivedStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupReceivedStatusId)" >
       {{element.t004lookupReceivedStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="returnstShipmentStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{SHIPMENT_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupShipmentStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupShipmentStatusId)" >
       {{element.t004lookupShipmentStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="stockRequestPackageStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{PACKAGE_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupPackageStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupPackageStatusId)" (click)="directToReviewPackage()" style="cursor: pointer;">
       {{element.t004lookupPackageStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="stockRequestReceivedStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{RECEIVED_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupReceivedStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupReceivedStatusId)" >
       {{element.t004lookupReceivedStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="stockRequestShipmentStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{SHIPMENT_STATUS}}</th>
      <td cdk-cell
       *cdkCellDef="let element" status-color [status]="element.t004lookupShipmentStatusId.lookupValue">
      <p  *ngIf="this.commonUtils.checkNullOrUndefined(element.t004lookupShipmentStatusId)" >
       {{element.t004lookupShipmentStatusId.lookupValue}} 
     </p>
      </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="stockReceive">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter flex76">{{STOCK_RECEIVE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="mediumFlexDiv " >
        <button type="button" matSuffix mat-button  mat-icon-button aria-label="Clear" matTooltip="Receive Stock" [disabled]=" element.hidePORecieveIcon" (click)="stockReceive(element)"><mat-icon>assignment_returned</mat-icon></button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Manage Stock Transfer Request ...Ends -->

    <!-- manage batch synch starts -->
      <ng-container cdkColumnDef="batchType">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{TYPE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.type}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="batchModuleName">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MODULE_NAME_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.moduleName}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="batchPrimaryKey">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MODULE_KEY_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.module_pk}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="batchPortalName">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PORTAL_NAME}}</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.portalName}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="batchStatus">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STATUS_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.status}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <!-- manage batch synch ends -->
     <!-- manage database screen -->
      <ng-container cdkColumnDef="databaseId">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv">Database ID</th>
        <td cdk-cell *cdkCellDef="let element" class="smallDiv"  >{{element.databaseId}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="databaseDate">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flexdate">{{DATABASE_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="flexdate" >{{element.createdTs | date:'short'}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="databaseScript">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{DATABASE_SCRIPT}}</th>
        <td cdk-cell *cdkCellDef="let element"  style="word-break: break-word;" >{{element.script}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="databaseStatus">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{DATABASE_STATUS}}</th>
        <td cdk-cell *cdkCellDef="let element" class="smallDiv">{{element.status}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="databaseCreatedBy">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel smallDiv">{{DATABASE_CREATEDBY}}</th>
        <td cdk-cell *cdkCellDef="let element" class="smallDiv">{{element.t001UserCreatedBy.userName}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

         <ng-container cdkColumnDef="LocalButton">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel mediumFlexDiv1">Environment</th>
         <td cdk-cell  *cdkCellDef="let element"  class="mediumFlexDiv1"  >
             <mat-progress-bar class="progressTopPadding" *ngIf="(element.localStatus === true && element.uATStatus ===false && element.usprod ===false)" [color]="'warn'" [mode]="'determinate'" value="25"   matTooltip="Executed in Local  ">
           </mat-progress-bar>



         <mat-progress-bar class="progressTopPadding" *ngIf="(element.localStatus === true && element.uATStatus ===true &&  element.usprod ===false)" [color]="'blue'" [mode]="'determinate'" value="50"   matTooltip="Executed in UAT  ">
           </mat-progress-bar>


   <!--  <mat-progress-bar class="progressTopPadding" *ngIf="(element.localStatus === true && element.uATStatus ===true &&  element.usprod ===true && element.apacprod ===false  )" [color]="'blue'" [mode]="'determinate'" value="75"   matTooltip="Executed in US PROD   ">
           </mat-progress-bar> -->

      <!--    <mat-progress-bar class="progressTopPadding" *ngIf="(element.localStatus === true && element.uATStatus ===true && element.productionStatus ===true )" [color]="'accent'" [mode]="'determinate'" value="100"   matTooltip="Executed in LIVE  ">
           </mat-progress-bar> -->
 <mat-icon *ngIf="(element.localStatus === true && element.uATStatus ===true &&  element.usprod ===true )" matTooltip="Executed in LIVE " style="color:green"   >done</mat-icon>
        
        </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


      
     <!-- manage database screen -->
      <ng-container cdkColumnDef="moremenu">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel mediumFlexDiv">
        <button mat-button type="button" #moreMenu  
         matSuffix mat-button aria-label="Clear"
       >
          
          <mat-icon>more_vert</mat-icon> 
        </button>
          <button mat-button type="button"    
        (mouseover)="openIcons()" (mouseout)="closeIcons()" matSuffix mat-button aria-label="Clear"
       >
          
          <mat-icon>more_vert</mat-icon>
        </button>
      </th>
        <td cdk-cell *cdkCellDef="let element" class="mediumFlexDiv"></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- payment details -->
  <ng-container cdkColumnDef="payDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="color:black;">{{DATE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.createdTs | dateFormatPipe}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="payNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="color:black;">{{PAYMENT_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t513PaymentMadeHeaderDetailId.paymentMadeHeaderId}}
     <!--  <a class="reportLink" style="text-decoration:none;"  (click)="editPaymentMade(element.t513PaymentMadeHeaderDetailId);" > {{element.t513PaymentMadeHeaderDetailId.paymentMadeHeaderId}}
        </a>  --> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="refNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="color:black;">{{REFERENCE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t513PaymentMadeHeaderDetailId.referenceNumber}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="paymentdetailmode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="color:black;">{{MODE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t513PaymentMadeHeaderDetailId.t023PaymentMode.name}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="paymentamount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign" style="color:black;">{{AMOUNT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="reportValueRightAlign" style="color:black;">  {{element.currencySymbol}} {{element.appliedAmount | myCurrency}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- Charts of Accounts - Starts -->
      <ng-container cdkColumnDef="accountName">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" style="color:black;">NAME</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.name}}  </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="accountType">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" style="color:black;">TYPE</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">
          <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t007AccountDetailType) && this.commonUtils.checkNullOrUndefined(element.t007AccountDetailType.accountModuleObj)">{{element.t007AccountDetailType.accountModuleObj.name}} </p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="accountDetailType">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" style="color:black;">DETAIL TYPE</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t007AccountDetailType)">
       {{element.t007AccountDetailType.accountDetailType}}</p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- Charts of Accounts - Ends -->
<!-- Review Automated mail Detals STARTS -->

 <ng-container cdkColumnDef="ModuleName">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter">MODULE NAME </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">MODULE NAME</span> {{element.moduleName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    
     <ng-container cdkColumnDef="EmailSubject">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> EMAIL SUBJECT </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">EMAIL SUBJECT</span> {{element.emailSubject}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="TO_Email">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> TO EMAIL </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft wordBreakAll"> <span class="mobile-label">TO EMAIL</span> {{element.toEmail}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <ng-container cdkColumnDef="CC_Email">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> CC EMAIL </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft wordBreakAll"> <span class="mobile-label">CC EMAIL</span> {{element.ccEmail}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="BCC_Email">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> BCC EMAIL </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft wordBreakAll"> <span class="mobile-label">BCC EMAIL</span> {{element.bccEmail}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <ng-container cdkColumnDef="FetchQuery">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter flex380px"> FETCH QUERY</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft flex380px"> <span class="mobile-label"> FETCH QUERY</span> {{element.fetchQuery}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="ColumnHeaderString">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> HEADER TEXT</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft wordBreakAll"> <span class="mobile-label"> HEADER TEXT</span> {{element.columnHeaderString}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="FetchCondition">
      <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> FETCH CONDITION</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">FETCH CONDITION</span> {{element.fetchCondition}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

<!-- Review Automated mail Detals ENDS -->

  <!-- Client Invoice Screen  STARTS-->
   <ng-container cdkColumnDef="clientName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CLIENT_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t100ContactClientId)">
      <a class="reportLink" style="text-decoration:none;" >{{element.t100ContactClientId.contactDisplayName}}
      </a></p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!-- Client Invoice Screen  ENDS-->
<!-- hrm medical bill Screen -->
  <ng-container cdkColumnDef="firstName">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Employee Name </th>
      <td cdk-cell *cdkCellDef="let element"  class="alignLeft"> <span class="mobile-label">Employee Name</span>  {{element.employeeName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        
    <!-- Position Column -->
    <ng-container cdkColumnDef="medicalBillDate">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Bill Date </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">Bill Date</span> {{element.billDate  | dateFormatPipe}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Name Column -->
    <ng-container cdkColumnDef="medicalBillAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="alignRight"> Bill Amount </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight"> <span class="mobile-label">Bill Amount</span> {{element.billAmount}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Weight Column -->
    <ng-container cdkColumnDef="medicalBillNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Bill Number </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">Bill Number</span> {{element.billNumber}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- Symbol Column -->
    <ng-container cdkColumnDef="dependentName">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Dependent Name </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">Dependent Name</span> {{element.dependentName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="approvalReject">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Approval/Reject </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
        <button *ngIf="element.status==='APPLIED'" matTooltip="Approve" mat-button color="primary" focusable="false" (click)="saveOrUpdateMedicallBill('APPROVED',element)" style="padding-right: 10%; flex-shrink: unset;">
            <mat-icon class="material-icons">done</mat-icon>
        </button>
        <button *ngIf="element.status==='APPLIED'" matTooltip="Reject" mat-button color="primary" focusable="false"  (click)="saveOrUpdateMedicallBill('REJECTED',element)" style="padding-right: 10%; flex-shrink: unset;">
            <mat-icon class="material-icons">close</mat-icon>
        </button>
        <!-- <span class="mobile-label">Approval/Reject</span> 
        <a *ngIf="element.status==='APPLIED'">
          <a (click)="saveOrUpdateMedicallBill('APPROVED',element)"> <mat-icon class="mat_icon_color">done</mat-icon></a>
          <a (click)="saveOrUpdateMedicallBill('REJECTED',element)"> <mat-icon class="mat_icon_color_red">highlight_off</mat-icon></a>
        </a> -->
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="medicalBillStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft"> Status </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <span class="mobile-label">Status</span> {{element.status}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- hrm medical bill Screen -->

 <!-- manage testcase link master starts -->
      <ng-container cdkColumnDef="endPointUrl">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">EndPointURL</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.endPointUrl}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="request">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">Request</th>
        <td cdk-cell *cdkCellDef="let element" style="word-break: break-word;">{{element.request}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="response">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">Response</th>
        <td cdk-cell *cdkCellDef="let element" >{{element.response}}</td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="statusId">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flex100">Status</th>
        <td cdk-cell *cdkCellDef="let element" class="flex100" ><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004LookupStatusId)">{{element.t004LookupStatusId.lookupValue}}</p></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <!-- manage testcase link master ends -->

<!-- resource extension starts -->
<ng-container cdkColumnDef="resourceProjectName">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="text-align:left">PROJECT</th>
   <td cdk-cell *cdkCellDef="let element" >{{element.t822ProjectObj.projectName}}-{{element.t822ProjectObj.projectCode}}</td>
  <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container> 

    <ng-container cdkColumnDef="deleteResource">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv">{{DELETE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class=" smallDiv" style="text-align: center;">
       <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
          <mat-icon matTooltip="Delete" class="mat_icon_color" (click)="deleteResourceExtension(element)">delete</mat-icon> </button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="resource">
          <th cdk-header-cell *cdkHeaderCellDef  > {{RESOURCE_NAME_BOLD}} </th>
          <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t800_EmployeeObj)">
        {{element.t800_EmployeeObj.firstName}} - {{element.t800_EmployeeObj.userName}}</p> </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

        <ng-container cdkColumnDef="userNameDisp">
          <th cdk-header-cell *cdkHeaderCellDef  style="text-align:left"> {{USERNAME_BOLD}} </th>
          <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t001_UserObj)">
        {{element.t001_UserObj.userName}} - {{element.t001_UserObj.empName}}</p> </td>
         <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="appraisalAssignedDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{APPRAISAL_ASSIGNED_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element"> <p> {{element.appraisalDate | dateFormatPipe}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!-- resource extension ends -->

     <!--  Menu -starts -->
    <ng-container cdkColumnDef="menuId">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MENU_ID}} </th>
      <td cdk-cell *cdkCellDef="let element"> <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);" >{{element.menuId}}</a></td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="menuName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MENU_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.menuName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="menuOrder">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MENU_ORDER}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.menuorder}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="menuLevel">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MENU_LEVEL}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.menuLevel}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="rourterUrl">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ROUTER_URL}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.routerurl}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="addUrl">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ADD_URL}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.addurl}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="moduleName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MODULE_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.moduleName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="iconName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ICON_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.iconName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!-- part request starts -9109 -->
   <ng-container cdkColumnDef="partName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PART_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element">  {{element.t200_item.itemName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="partNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PART_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t200_item.sku}}</td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <ng-container cdkColumnDef="partDescription">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PART_DESC}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t200_item.purchaseDescription}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="partRequestNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PART_REQUEST_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element">{{element.refPartRequestId}}</td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="partJobNo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{JOB_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.jobNumber}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="partWONo">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{WO_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.workOrderNumber}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="partRequestStatus">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{STATUS_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" ><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004_LookupPartStatusObj)"> {{element.t004_LookupPartStatusObj.lookupValue}}</p></td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="partCheckIn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter "> {{CHECK_IN}} </th>
      <td cdk-cell class="alignCenter " *cdkCellDef="let element">
         <button matTooltip="Check In" mat-button type="button" matSuffix mat-button aria-label="Clear" (click)="partReceive(element)" mat-icon-button [disabled]="element.hidePORecieveIcon">
          <mat-icon >add_shopping_cart
         </mat-icon> </button></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="partCheckOut">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter "> {{CHECK_OUT}} </th>
      <td cdk-cell class="alignCenter " *cdkCellDef="let element">
       <button matTooltip="Check Out" mat-button type="button" matSuffix mat-button aria-label="Clear" (click)="partPackage(element)" mat-icon-button [disabled]="element.hidePackageIcon">
          <mat-icon >shopping_cart
         </mat-icon> </button> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="isWarranty">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PART_REQUEST_WARRANTY}} </th>
      <td cdk-cell *cdkCellDef="let element" ><p *ngIf="element.warranty == true"> Yes </p> <p *ngIf="element.warranty == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!-- part request ends -9109 -->
    <!-- timesheet history starts -9103 -->
     <ng-container cdkColumnDef="timesheetResourceName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{RESOURCE_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element">{{element.t823_taskObj.t001_UserObj.userName}} - {{element.t823_taskObj.t001_UserObj.empName}}</td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="timesheetProjectName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PROJECT_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t823_taskObj.t822ProjectObj.projectName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="timesheetTaskNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{TASK_NUMBER}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t823_taskObj.taskNumber}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="timesheetTaskName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{TASK_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t823_taskObj.taskName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="workedHours">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{WORKED_HOURS}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.workedHours}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="workedDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{WORKED_DATE}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.workedDate | dateFormatPipe}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="taskComment">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{USER_COMMENT}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.taskDescription}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="timesheetCreatedDate">
           <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CREATED_DATE}}</th>
               <td cdk-cell *cdkCellDef="let element" > {{element.createdDate | dateFormatPipe}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!-- timesheet history ends -9103 -->

  <!-- validation starts -->
  <ng-container cdkColumnDef="controlName">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="padding-right: 3%;">{{CONTROL_NAME}} </th>
        <td cdk-cell *cdkCellDef="let element" style="word-break: break-word;padding-right: 3%;"> {{element.controlName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="validationParent">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_PARENT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter" ><p *ngIf="element.parent == true"> Yes </p> <p *ngIf="element.isParent == false"> No </p></td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!--  <ng-container cdkColumnDef="parentName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{PARENT_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.parentName}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  -->

   <ng-container cdkColumnDef="level">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{LEVEL}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter"> {{element.level}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="required">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_REQUIRED}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter" ><p *ngIf="element.required == true"> Yes </p> <p *ngIf="element.required == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="nullCheck">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_NULL}} </th>
        <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter" ><p *ngIf="element.nullCheck == true"> Yes </p><p *ngIf="element.nullCheck == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="minLength">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_MINLENGTH}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter"><p *ngIf="element.minLength == true"> Yes </p> <p *ngIf="element.minLength == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="minLengthValue">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{MIN_LENGTH}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter"> {{element.minLengthValue}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="emailRequired">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_EMAIL}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter" ><p *ngIf="element.email == true"> Yes </p> <p *ngIf="element.email == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="maxLength">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{IS_MAXLENGTH}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter" ><p *ngIf="element.maxLength == true"> Yes </p> <p *ngIf="element.maxLength == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="maxLengthValue">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignJustifyCenter">{{MAX_LENGTH}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignJustifyCenter"> {{element.maxLengthValue}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="validationEdit" >
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel miniDiv">{{EDIT}} </th>
    <td cdk-cell *cdkCellDef="let element" class="miniDiv"> <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink" matTooltip="Edit Row" (click)="editRow(element);">edit</mat-icon></button> 
    </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="refreshValidation">
      <th cdk-header-cell *cdkHeaderCellDef class="reportHeaderRightAlign miniDiv nopadding">
        <!--<a (click)="refreshIconClick(true)">
        <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
          <mat-icon class="reportLink" matTooltip="Refresh Grid">refresh</mat-icon></button>
        </a>
      --></th>
      <td cdk-cell *cdkCellDef="let element" class="miniDiv nopadding"> </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <ng-container cdkColumnDef="validationCreate" >
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel miniDiv nopadding "><button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink" matTooltip="Add validation" (click)="saveOrUpdateValidation()">add</mat-icon></button></th>
      <td cdk-cell *cdkCellDef="let element" class="miniDiv nopadding">  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   
  <!-- validation ends  -->

<!--  Review display -starts 9098 -->
   

     <ng-container cdkColumnDef="rdcModuleName" >
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="padding-right:19px;">{{RDC_MODULE_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element" style="padding-right:19px;"> {{element.moduleName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="rdcColumnName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="flex:25%;padding-left:23px;">{{COLUMN_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element" style="flex:25%;word-break: break-word;padding-left:23px;"> {{element.columnName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="rdcLabelName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" style="flex:15%;">{{RDC_LABEL_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element" style="flex:15%;"> {{element.labelName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!--  Review display -ends 9098 -->  
   <!--  Appraisal master -starts 9098 -->  

 <ng-container cdkColumnDef="appraisalName">
    <th cdk-header-cell *cdkHeaderCellDef class="alignCenter"> {{APPRAISAL_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignCenter">
    <span status-color [status]="element.status"> {{element.appraisalName }}</span>
    </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="subAppraisalName">
      <th cdk-header-cell *cdkHeaderCellDef class="alignLeft">{{SUB_APPRAISAL_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <span status-color [status]="element.status"> {{element.subAppraisalName }}</span>
      </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

 <!--  Appraisal master -ends 9098 -->

    <!-- Employee Apprisal Level - starts-->
    <ng-container cdkColumnDef="appraisalLevel">
          <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{APPRAISAL_BOLD}}</th>
            <td cdk-cell *cdkCellDef="let element">
                <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t828_AppraisalObj)" >
                  {{element.t828_AppraisalObj.appraisalName}} 
                </p>
            </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="deleteAppraisalLevel">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter smallDiv">{{DELETE_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="reportHeaderRightAlign smallDiv" style="text-align: center;">
           <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
              <mat-icon matTooltip="Delete" class="mat_icon_color" (click)="deleteEmployeeAppraisalLevel(element)">delete</mat-icon>
           </button>
         </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Employee Apprisal Level - Ends-->

<!-- Apply  Apprisal Form -->
<ng-container cdkColumnDef="refreshEditForAccess" *ngIf="moduleName == 'VIEW_APPRAISAL_FORM'"> 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight mediumFlexDiv">
        <!--<a (click)="refreshIconClick(true)">
      <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>
    --></th>
      <td cdk-cell  class="reportHeaderRightAlign mediumFlexDiv" *cdkCellDef="let element"  >
      <button mat-button type="button"  (click)="editRow(element);" mat-icon-button matSuffix mat-button aria-label="Clear"  
        [disabled]="element.status" >
      <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
      </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>

    <ng-container cdkColumnDef="appraisalStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{APPRAISAL_STATUS}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
        <p *ngIf="commonUtils.checkNullOrUndefined(element.t004LookupStatusObj)"> {{element.t004LookupStatusObj.lookupValue}} </p></td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>
    <ng-container cdkColumnDef="appraisalDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{APPRAISAL_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">
        <p> {{element.appraisalDate | dateFormatPipe}} </p></td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>

<!--Apply  Apprisal Form -->
<!--Item available qty-->
<ng-container cdkColumnDef="itemAvailableQty">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign alignRight" > {{AVAILABLE_QTY}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign" style="padding-right: 20px;" status-color [status]= "element.active">{{element.availableQtyForSale}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="itemSoldQty">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderRightAlign alignRight" > {{SOLD_QTY}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign" style="padding-right: 20px;" status-color [status]= "element.active">{{element.availableQtyForSale}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!--Item available qty-->
<ng-container cdkColumnDef="searchEdit"> 
      <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel reportHeaderRightAlign mediumFlexDiv mediumFlexDiv1">
     <!--   <a (click)="searchIconClick()">
          <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
            <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon>
          </button>
        </a>
      -->
      </th>
      <td cdk-cell  class="reportHeaderRightAlign mediumFlexDiv mediumFlexDiv1" *cdkCellDef="let element"  >
         <button mat-button type="button"  (click)="editRow(element);" matSuffix mat-button aria-label="Clear">
          <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
        </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <!--warehouse screen-->
     <ng-container cdkColumnDef="isReceiveStock">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{IS_RECEIVESTOCK}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="element.canReceiveStock == true"> Yes </p><p *ngIf="element.canReceiveStock == false"> No </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!--warehouse screen-->

<!-- Artwork Screen For Graphics Login - Start -->
    <ng-container cdkColumnDef="proofDetail"> 
      <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel">PROOF</th> 
      <div *ngIf="userType === 'GRAPHICS'" >
        <td cdk-cell  class="alignCenter" *cdkCellDef="let element" status-color [t301GraphicsRecordUnread]="element.t301GraphicsRecordUnread">{{element.proofNo}}</td>    
      </div>     
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="artworkNumberForGraphics">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel"> {{ARTWORK_NUMBER_BOLD}} </th>
      <div *ngIf="userType === 'GRAPHICS'" >
        <td cdk-cell *cdkCellDef="let element" status-color [t301GraphicsRecordUnread]="element.t301GraphicsRecordUnread">{{element.artworkNo}} </td>
      </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="refreshEditForArtwork" > 
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight mediumFlexDiv">
        <!--<a (click)="refreshIconClick(true)">
      <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>
    --></th>
    <div *ngIf="userType === 'GRAPHICS'" >
      <td cdk-cell  class="reportHeaderRightAlign mediumFlexDiv" *cdkCellDef="let element"  status-color [t301GraphicsRecordUnread]="element.t301GraphicsRecordUnread">
      <button mat-button type="button"  (click)="attachFileAtLineItem(element);" matSuffix mat-button aria-label="Clear"  >
      <mat-icon  matTooltip="Edit Row" class="mat_icon_color" >edit</mat-icon> </button>
      </td>
    </div>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Artwork Screen For Graphics Login - Ends -->

    <!-- View PO Receive Screen starts -->
       <ng-container cdkColumnDef="poReceiveNumber">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{PO_RECEIVE_NUMBER}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="fetchPOReceiveDetails(element)" >{{element.poReceiveNumber}} </a></td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="poReceiveDate">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{PO_RECEIVE_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.poReceiveDate | dateFormatPipe}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="inventoryDetails" >
         <th cdk-header-cell *cdkHeaderCellDef class="boldLabel miniDiv"></th>
         <td  cdk-cell *cdkCellDef="let element" class="miniDiv">  <p *ngIf="this.moduleName === 'VIEW_PO_RECEIVE'"> <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
         <mat-icon class="reportLink" (click)="fetchPOReceiveDetails(element)" >video_label</mat-icon></button> </p>
         <p *ngIf="this.moduleName === 'VIEW_SO_RECEIVE'"> <button mat-button type="button"  matSuffix mat-button aria-label="Clear">
         <mat-icon class="reportLink" (click)="fetchSOReceiveDetails(element)" >video_label</mat-icon></button> </p>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
<!-- View PO Receive Screen ends -->
<!-- View Invoice credit notes Screen starts -->
      <ng-container cdkColumnDef="appliedCredit">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight reportValueRightAlign" style="color:black;">{{AMOUNT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign" style="color:black;">  
        <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t400InvoiceHeaderId) && this.commonUtils.checkNullOrUndefined(element.t400InvoiceHeaderId.t100ContactCustomerId) && this.commonUtils.checkNullOrUndefined(element.t400InvoiceHeaderId.t100ContactCustomerId.t003Currency)">{{element.t400InvoiceHeaderId.t100ContactCustomerId.t003Currency.symbol}} {{element.appliedCredit | myCurrency}} </p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="creditNoteNum">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{CREDIT_NOTE_NUMBER}}</th>
        <td cdk-cell *cdkCellDef="let element">{{element.t411CreditNoteCreditId.invoiceNumber}} </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="invoiceNum">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{INVOICE_NUMBER_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element">{{element.t400InvoiceHeaderId.invoiceNumber}} </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 
       <ng-container cdkColumnDef="creditCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t400InvoiceHeaderId) && this.commonUtils.checkNullOrUndefined(element.t400InvoiceHeaderId.t100ContactCustomerId)">
      {{element.t400InvoiceHeaderId.t100ContactCustomerId.contactDisplayName}}
      </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 
      <!-- View Invoice credit notes Screen ends -->
       <!-- View Bill vendor credit Screen starts -->
     <ng-container cdkColumnDef="appliedAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignRight reportValueRightAlign" style="color:black;">{{AMOUNT_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight reportValueRightAlign" style="color:black;">  
        <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t510BillHeaderDetailId) && this.commonUtils.checkNullOrUndefined(element.t510BillHeaderDetailId.t100ContactVendorId) && this.commonUtils.checkNullOrUndefined(element.t510BillHeaderDetailId.t100ContactVendorId.t003Currency)">{{element.t510BillHeaderDetailId.t100ContactVendorId.t003Currency.symbol}} {{element.appliedAmount | myCurrency}} </p> </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="billNum">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{BILL_NUMBER_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.t510BillHeaderDetailId.invoiceNumber}} </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="vendorCreditNum">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{VENDOR_CREDIT_BOLD}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.t521VendorCreditId.invoiceNumber}} </td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="vendorCreditVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t510BillHeaderDetailId)  ">
      {{element.t510BillHeaderDetailId.vendorName}}
      </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <!-- View  Bill vendor credit  Screen ends -->
        <!-- View SO Receive Screen starts -->
     
      <ng-container cdkColumnDef="soReceiveNumber">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{SO_RECEIVE_NUMBER}}</th>
        <!-- <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.sOReceiveNumber}} </td> -->
         <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="fetchSOReceiveDetails(element)" >{{element.sOReceiveNumber}} </a></td> 
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

      <ng-container cdkColumnDef="soReceiveDate">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{SO_RECEIVE_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.sOReceiveDate | dateFormatPipe}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="soCustomerName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CUSTOMER_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.t303_SalesOrder_HeaderObj.t100ContactCustomerId)  ">
      {{element.t303_SalesOrder_HeaderObj.t100ContactCustomerId.contactDisplayName}}
      </p> </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="soNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{SO_NUMBER}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><a class="reportLink" style="text-decoration:none;" (click)="editRow(element);"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t303_SalesOrder_HeaderObj)"> {{element.t303_SalesOrder_HeaderObj.invoiceNumber}} ({{element.t303_SalesOrder_HeaderObj.totalOrderedQuantity | number : fractionsize}})</p></a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <!-- View  SO Receive  Screen ends -->
      <!-- View Shipping  Screen starts -->
      <ng-container cdkColumnDef="shippingDate">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{SHIPPING_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.shipDate | dateFormatPipe}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="shippingNumber">
        <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{SHIPPING_NUM}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.shipmentOrderNumber}}</td>
        <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       <ng-container cdkColumnDef="trackingNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" >{{TRACKING_NUMBER_BOLD}}</th>
    <td cdk-cell class="alignLeft trackingFlex" *cdkCellDef="let element" class="alignLeft">
         <button mat-button type="button"  *ngIf="element.shipmentStatusId.lookupValue=='SHIPPED'  && (element.carrierId.carrierName == 'UPS' || element.carrierId.carrierName  === 'FedEx' ) && element.trackingNumberStatus === 'Automatic' " matSuffix mat-button aria-label="Clear" (click)="viewTrackingNumber(element)">
           <mat-icon matTooltip="View Download Tracking Number">view_comfy</mat-icon>
        </button>  
        <p *ngIf="element.trackingNumberStatus != 'Automatic' "> {{element.trackingNumber}}</p>
   </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
       
    <ng-container cdkColumnDef="packageSlipNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PACKAGE_NO}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" ><a class="reportLink" style="text-decoration:none;" (click)="viewPackage(element)" >{{element.packageNumber}}</a></td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="carrierShip">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CARRIER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.carrierId)">{{element.carrierId.carrierName}} </p></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="shipStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel flex76"> </th>
      <td cdk-cell class="alignLeft smallDiv" *cdkCellDef="let element" class="flex76">
   <button mat-button type="button"  matSuffix mat-button aria-label="Clear" mat-icon-button [disabled]="element.hideShippedIcon" (click)="updateShip(element)" *ngIf="element.shipmentStatusId.lookupValue!='VOID' && element.packageStatus!='DELIVERED'">

    <p *ngIf="element.shipmentStatusId.lookupValue==='NOT SHIPPED'" ><mat-icon style="color:red;" matTooltip="{{element.shipmentStatusId.lookupValue}}">local_shipping</mat-icon></p>
    <p *ngIf="element.shipmentStatusId.lookupValue==='SHIPPED'" ><mat-icon style="color: orange;" matTooltip="{{element.shipmentStatusId.lookupValue}}">local_shipping</mat-icon></p> 
  </button>
  <button mat-button type="button"  matSuffix mat-button aria-label="Clear" mat-icon-button disabled *ngIf="element.shipmentStatusId.lookupValue==='VOID' || element.packageStatus==='DELIVERED'">
    <p *ngIf="element.shipmentStatusId.lookupValue==='VOID'" style="font-weight: 100;color: black;">{{element.shipmentStatusId.lookupValue}} </p>
    <p *ngIf="element.packageStatus==='DELIVERED'" ><mat-icon style="color: green; " matTooltip="{{element.packageStatus}}">local_shipping</mat-icon></p> 
  </button>
   </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <!-- View Shipping  Screen ends -->
 <!-- View Shift Master Screen starts -->

    <ng-container cdkColumnDef="shiftName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{SHIFT_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.shiftName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="startTime">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{START_TIME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.startTime}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="endTime">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{END_TIME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.endTime}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
 <!-- View Shift Master Screen Ends -->

  <!-- View Shift Allocation Screen starts -->

    <ng-container cdkColumnDef="employeeeCode">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMPLOYEE_CODE_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t800Employee.userName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="employeeeName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMPLOYEE_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.t800Employee.firstName}} {{element.t800Employee.lastName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="salaryGroupName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{SALARY_GROUP_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.t818SalaryGroup.salaryGroupName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

     <ng-container cdkColumnDef="empShiftName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{SHIFT_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" alignLeft> {{element.t834ShiftMaster.shiftName}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="shiftStartDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{SHIFT_START_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.startDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

       <ng-container cdkColumnDef="shiftEndDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignCenter">{{SHIFT_END_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.endDate  | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <!-- View Shift Allocation Screen ends -->

   <!-- View Journal - Contacts Screen starts -->
       <ng-container cdkColumnDef="journalsDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{DATE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.Journal_Date | dateFormatPipe}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

    <ng-container cdkColumnDef="journalsRefNum">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" >{{REFERENCE_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.Journal_RefNo}}  </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>   
  
    <ng-container cdkColumnDef="journalsDebitAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{DEBIT_AMOUNT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight"> <!--  {{element.currencySymbol}}  -->{{element.Debit_Amount | myCurrency}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="journalsCreditAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel" > {{CREDIT_AMOUNT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight"> <!-- {{element.currencySymbol}} --> {{element.Credit_Amount | myCurrency}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
   <!-- View Journal - Contacts Screen ends -->

   <!-- Inventory Adjustment Screen -- Starts-->
    <ng-container cdkColumnDef="inventoryItemName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{ITEM_NAME_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft  "> {{element.t200_Item.itemName}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="inventorySku">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft ">{{SKU}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.t200_Item.sku}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  

   <ng-container cdkColumnDef="originalQtyReceived">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{QUANTITY_RECEIVED_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight">  {{element.originalQtyReceived | myCurrency}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

  <!-- Inventory Adjustment Screen -- Ends-->
  <!-- Refund Receipt Screen-Starts -->
  <ng-container cdkColumnDef="refundReceiptNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{REFUND_RECEIPT_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.invoiceNumber}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="refundReceiptDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{DATE_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.invoiceDate | dateFormatPipe}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
 <!-- Refund Receipt Screen-Ends -->

<!-- Sales Receipt Screen-Starts -->
  <ng-container cdkColumnDef="salesReceiptNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{SALES_RECEIPT_NUMBER_BOLD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.invoiceNumber}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Sales Receipt Screen-Ends -->



<!-- Sales Receipt Screen-Starts -->
  <ng-container cdkColumnDef="isbn">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> Manufacturer SKU </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> {{element.isbn}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Sales Receipt Screen-Ends -->



<!-- Sales Receipt Screen-Starts -->
  <ng-container cdkColumnDef="psku">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> Parent SKU </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> {{element.parentsku}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Sales Receipt Screen-Ends -->



<!-- Sales Receipt Screen-Starts -->
  <ng-container cdkColumnDef="stylenumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> Style # </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> 
       <a class="reportLink" style="text-decoration:none;" (click)="editNistarProduct(element);" matTooltip="Edit Row" status-color [status]= "element.active">{{element.styleNumber}}</a></td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Sales Receipt Screen-Ends -->



<!-- Sales Receipt Screen-Starts -->
  <ng-container cdkColumnDef="colorcodename">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> Color Code / Name </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> {{element.mpn}}</td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- Sales Receipt Screen-Ends -->


 <!-- SKU Column -->
    <ng-container cdkColumnDef="childSku">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft" > Child SKU

    
      </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> {{element.sku}} </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


 <ng-container cdkColumnDef="productName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel reportHeaderLeftAlign alignLeft">
        <div class="sort-caret pos_relative">
          <div title="Name" class="pull-left over-flow"  > Product Name</div>
            <span class="sort hidden-print ">
                
              </span>
        </div>
      </th>

       <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> <a class="reportLink" style="text-decoration:none;" (click)="editNistarProduct(element);" matTooltip="Edit Row" status-color [status]= "element.active"> {{element.itemName}} </a> 
    
    </td>
     <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <!-- ADJUST STOCK -->
    <ng-container cdkColumnDef="adjustStock">
      <th cdk-header-cell *cdkHeaderCellDef  ></th>
        <td cdk-cell *cdkCellDef="let element"  >
          <button matTooltip="AdjustStock" #moreMenu mat-button color="primary" focusable="false" (click)="viewAdjustStock(element)">
              <mat-icon class="mat_icon_color" >adjust </mat-icon> 
          </button>         
        </td>
      <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 


   <!-- Budgeting Screen -- Starts-->
     <ng-container cdkColumnDef="budgetCreatedDate">
       <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{BUDGET_CREATED_DATE}}</th>
        <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.createdTs | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
    <ng-container cdkColumnDef="budgetingName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{BUDGET_NAME}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft  "> {{element.name}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 

   <ng-container cdkColumnDef="budgetYear">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft ">{{BUDGET_YEAR}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.fiscalYearObj.lookupValue}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container>  

   <ng-container cdkColumnDef="budgetPeriod">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft"> {{BUDGET_PERIOD}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.budgetPeriodObj.lookupValue}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <ng-container cdkColumnDef="budgetTotalAmount">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">  {{BUDGET_TOTAL_AMOUNT}} </th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight">  {{element.totalAmount | myCurrency}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!-- Budgeting Screen -- Ends-->
  <!-- Receiving Screen -- starts-->
  
     <ng-container cdkColumnDef="purchaseDate">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{PURCHASE_DATE}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.purchaseDate | dateFormatPipe}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
      <ng-container cdkColumnDef="lotNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{LOT_NUMBER}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"> {{element.lotNumber}} </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
        <ng-container cdkColumnDef="receivingVendorName">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{VENDOR_NAME_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004VendorId)">  {{element.t004VendorId.lookupValue}} </p>
       </td>
       <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="orderNumber">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ORDER_NUMBER_BOLD}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.orderNumber }} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="receiveOrderStatus">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ORDER_STATUS}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignLeft"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004OrderStatusId)">  {{element.t004OrderStatusId.lookupValue}} </p></td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
     <ng-container cdkColumnDef="discrepancies">
      <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{DISCREPANCIES}}</th>
      <td cdk-cell *cdkCellDef="let element" class="alignRight">  {{element.discrepancies}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
    </ng-container> 
  <!-- Receiving screen -end-->

    <!-- Manage Jobs - Screen - starts -->
  <ng-container cdkColumnDef="jobCode">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">JOB CODE</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft"> 
      <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);">{{element.jobCode}} </a></td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="jobTitle">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">JOB TITLE</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.title}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="jobLocation">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">LOCATION</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">{{element.location}}</td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="noOfOpenPositions">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">NUMBER OF OPENINGS</th>
    <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.numberOfOpenPositions}}</td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="offeredSalary">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">SALARY OFFERED</th>
    <td cdk-cell *cdkCellDef="let element" class="alignCenter"> {{element.offeredSalary}}</td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="agingJob">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">JOB AGE</th>
    <td cdk-cell *cdkCellDef="let element" class="alignCenter">
    <p><span *ngIf='element.agingYears!=0'> {{element.agingYears}} Years </span>
      <span *ngIf='element.agingMonths!=0'> {{element.agingMonths}} Months </span>
    <span *ngIf='element.agingDays!=0'> {{element.agingDays}} Days </span>
    <span *ngIf='element.agingHours!=0'> {{element.agingHours}} Hours </span></p></td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="jobStatus">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">STATUS</th>t004_jobTypeObj
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]="element.t004JobStatusObj.lookupValue"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.t004JobStatusObj)"> {{element.t004JobStatusObj.lookupValue}}</p></td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <!-- Manage Jobs - Screen - ends -->


  <!-- Manage Feedback - Screen - starts -->
  <ng-container cdkColumnDef="empName">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{EMPLOYEE_NAME_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.t800EmployeeObj.userName}} - {{element.t800EmployeeObj.firstName}} {{element.t800EmployeeObj.lastName}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="feedbackComments">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{COMMENTS_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.feedbackComments}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="milestones">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{MILESTONES}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.positiveMilestones}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="issues">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ISSUES_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">  {{element.issues}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="feedbackCreatedBy">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{CREATED_BY_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <p *ngIf="commonUtils.checkNullOrUndefined(element.t001UserCreatedBy)"> {{element.t001UserCreatedBy.userName}} - {{element.t001UserCreatedBy.empName}} </p></td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <!-- Manage Feedback - Screen - ends -->
   <!-- Manage Interviewslot - Screen - starts -->
 <ng-container cdkColumnDef="jobNameSlot">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{JOB_NAME_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active">
      <p *ngIf="commonUtils.checkNullOrUndefined(element.t842_JobObj)" >
        <a class="reportLink" style="text-decoration:none;" (click)="redirectToJob(element);"> {{element.t842_JobObj.jobCode}} - {{element.t842_JobObj.title}}</a> </p> </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="interviewDate">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{INTERVIEW_DATE_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active">
       {{element.interviewDate | dateFormatPipe}} </td>  
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
   <ng-container cdkColumnDef="slotType">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{INTERVIEW_STATUS_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> 
      <p *ngIf="commonUtils.checkNullOrUndefined(element.interviewType)" >{{element.interviewType.lookupValue}}  </p> </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
 
 <ng-container cdkColumnDef="interviewSlotStatus">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{INTERVIEW_SLOT_STATUS_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active"> 
      <p *ngIf="commonUtils.checkNullOrUndefined(element.t004_Status)" >{{element.t004_Status.lookupValue}}  </p>  </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="accept" >
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  "> ACCEPT
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #accept (click)="slotStatusClick(element,'ACCEPTED');"  matSuffix mat-button aria-label="Clear" *ngIf="commonUtils.checkNullOrUndefined(element.interviewStatus) && (element.interviewStatus.lookupValue === 'ACCEPTED' || element.interviewStatus.lookupValue === 'CLOSED')"  style="cursor: not-allowed;">
        <mat-icon  matTooltip="Accept Slot"  class="mat_icon_color" style="color: green;cursor: not-allowed;">check
    </mat-icon> </button>
     <button mat-button type="button" #accept (click)="slotStatusClick(element,'ACCEPTED');"  matSuffix mat-button aria-label="Clear" *ngIf="commonUtils.checkNullOrUndefined(element.interviewStatus) && element.interviewStatus.lookupValue != 'ACCEPTED' && element.interviewStatus.lookupValue != 'CLOSED'" style="cursor: pointer;">
        <mat-icon  matTooltip="Accept Slot"  class="mat_icon_color" style="color: green;">check
    </mat-icon> </button> </td>
</ng-container>

<ng-container cdkColumnDef="reschedule">
  <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">RESCHEDULE
  </th>
  <td cdk-cell   *cdkCellDef="let element" class="div20px">
    <button mat-button type="button" #reschedulebtn (click)="addReschedule(element,reschedulebtn);"  matSuffix mat-button aria-label="Clear" *ngIf="commonUtils.checkNullOrUndefined(element.interviewStatus) && element.interviewStatus.lookupValue != 'ACCEPTED'" style="cursor: pointer;">
      <mat-icon  matTooltip="Reschedule Appointment"  class="mat_icon_color">schedule
  </mat-icon> </button>
   <button mat-button type="button" #reschedulebtn (click)="addReschedule(element,reschedulebtn);"  matSuffix mat-button aria-label="Clear" *ngIf="commonUtils.checkNullOrUndefined(element.interviewStatus) && element.interviewStatus.lookupValue === 'ACCEPTED'" style="cursor: not-allowed;">
      <mat-icon  matTooltip="Reschedule Appointment"  class="mat_icon_color" style="cursor: not-allowed;">schedule
  </mat-icon> </button> </td>
</ng-container>

<ng-container cdkColumnDef="clone">
  <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">CLONE
  </th>
  <td cdk-cell   *cdkCellDef="let element" class="div20px">
    <button mat-button type="button" #clonebtn (click)="cloningObj(element);"  matSuffix mat-button aria-label="Clear">
      <mat-icon  matTooltip="Clone Appointment"  class="mat_icon_color">content_copy
  </mat-icon> </button></td>
</ng-container>
  
  <!-- Manage Interviewslot - Screen - ends -->

  <!-- Manage Announcements - Screen - starts -->
  <ng-container cdkColumnDef="announcementDescription">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{ANNOUNCEMENT_DESC}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft" status-color [status]= "element.active">  {{element.announcementDescription}} </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="announcementStartDate">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{START_DATE}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color [status]= "element.active"> {{element.startDate | dateFormatPipe}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="announcementEndDate">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{END_DATE}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color [status]= "element.active"> {{element.endDate | dateFormatPipe}} </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <!-- Manage Announcements - Screen - ends -->
  <!-- Candidate View Screen starts-->
  <ng-container cdkColumnDef="jobName">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel">{{JOB_NAME_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" class="alignLeft">
      <p *ngIf="commonUtils.checkNullOrUndefined(element.jobApplication)" ><a class="reportLink" style="text-decoration:none;" 
        (click)="redirectToJob(element);">{{element.jobApplication.jobCode}} - {{element.jobApplication.title}}</a>  </p> </td>
    <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="candidateCode">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_CODE}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" > 
      <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);">{{element.candidateCode}}  </a></td>
    
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="candidateName">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_NAME}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" >
      <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);"> {{element.firstName}} {{element.lastName}} </a> </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container>
  <ng-container cdkColumnDef="industry">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_INDUSTRY}}</th>
    <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.industry)"> {{element.industry.industryValue}} </p></td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="expectedSalary">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{EXPECTED_SALARY_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.expectedSalary}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="currentlocation">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CURRENT_LOCATION_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.currentLocation}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="noticeperiod">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{NOTICE_PERIOD_BOLD}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.noticePeriod}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="totalYearsOfExp">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{TOTAL_YRS_OF_EXP}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.totalYearsOfExp}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="education">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_EDUCATION}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.latestEducation}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="experience">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_EXPERIENCE}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.latestExperience}}  </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
  <ng-container cdkColumnDef="interviewRound">
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">FEEDBACK
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #reminderBtn (click)="openInterview(element);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Feedback For Interview Rounds"  class="mat_icon_color">feedback
    </mat-icon> </button> </td>
</ng-container>
<ng-container cdkColumnDef="interviewAllotment">
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button"  *ngIf="currentPageAccessObj.editAccess && loggedInUserObjRole == 'HR' " #reminderBtn (click)="openStatusUpdate(element);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Interview Allotment"  class="mat_icon_color">assignment_turned_in
    </mat-icon> </button> </td>
</ng-container>
<ng-container cdkColumnDef="convertEmployee">
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button"  *ngIf="currentPageAccessObj.editAccess && loggedInUserObjRole == 'HR'"  #reminderBtn (click)="convertToEmployee(element);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Convert candidate to employee"  class="mat_icon_color">person_add
    </mat-icon> </button> </td>
</ng-container>

<ng-container cdkColumnDef="resumePreview">
    <th cdk-header-cell *cdkHeaderCellDef  class="boldLabel alignRight div20px  ">RESUME PREVIEW
    </th>
    <td cdk-cell   *cdkCellDef="let element" class="div20px">
      <button mat-button type="button" #reminderBtn (click)="openCandidateResumePreview(element);"  matSuffix mat-button aria-label="Clear">
        <mat-icon  matTooltip="Resume Preview"  class="mat_icon_color">description
    </mat-icon> </button> </td>
</ng-container>
<!-- Candidate View Screen ends-->
<!-- View Interview Appointment Starts-->
<ng-container cdkColumnDef="appointmentNumber">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">APPOINTMENT #</th>
  <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft">
    <a class="reportLink" style="text-decoration:none;" (click)="editRow(element);">{{element.appointmentNumber}} </a> </td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container>
<ng-container cdkColumnDef="interviewName">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INTERVIEW_NAME_BOLD}}</th>
  <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" ><p *ngIf="this.commonUtils.checkNullOrUndefined(element.interviewSlotStatus)"> {{element.interviewSlotStatus.lookupValue}}  </p></td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container> 

<ng-container cdkColumnDef="interviewdate">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INTERVIEW_DATE_BOLD}}</th>
  <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" > {{element.interviewDate | dateFormatPipe}}  </td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container>

<ng-container cdkColumnDef="timeZone">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{TIMEZONE_BOLD}}</th>
  <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" > {{element.startTime}} - {{element.endTime}}  </td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container> 

<ng-container cdkColumnDef="interviewerName">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INTERVIEWER_NAME_BOLD}}</th>
  <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft" > {{element.interviewerNames}}  </td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container> 
<ng-container cdkColumnDef="candidateLatestStatus">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">CANDIDATE STATUS</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> <p *ngIf="this.commonUtils.checkNullOrUndefined(element.candidateLatestStatus)">{{element.candidateLatestStatus}} </p> </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
<ng-container cdkColumnDef="interviewStatus">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{INTERVIEW_BOLD}}</th>
  <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.interviewStatus)"> {{element.interviewStatus.lookupValue}} </p></td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container> 
<ng-container cdkColumnDef="interviewDetailStatus">
  <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">INTERVIEWER STATUS</th>
  <td cdk-cell *cdkCellDef="let element"><p *ngIf="this.commonUtils.checkNullOrUndefined(element.interviewerDetailStatus)"> {{element.interviewerDetailStatus}} </p></td>
 <td cdk-footer-cell *cdkFooterCellDef>  </td>
</ng-container>  

 <ng-container cdkColumnDef="candidateNameAppointed">
    <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">{{CANDIDATE_NAME}}</th>
    <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> 
      <a class="reportLink" style="text-decoration:none;" (click)="redirectToCandidate(element);">{{element.candidateName}} </a> </td>
   <td cdk-footer-cell *cdkFooterCellDef>  </td>
  </ng-container> 
<!-- View Interview Appointment Ends!-->
<!--************************TRAINING_FEEDBACK_MODULE_STARTS***********************************-->

<ng-container cdkColumnDef="inductionDoneBy">
  <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{INDUCTION_DONE_BY}}
  <th>
  <td mat-cell *cdkCellDef="let element;let i=index" class="alignLeft">
    {{element.inductionBy}}
  </td>
  <td cdk-footer-cell *cdkFooterCellDef> </td>

</ng-container>
<ng-container cdkColumnDef="techUsed">
  <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{TECHNOLOGIES_USED}}</th>
  <td cdk-cell [matTooltip]=element.technologyName *cdkCellDef="let element;let i=index" style=" white-space: nowrap;
  text-overflow: ellipsis; overflow: hidden;  max-width: 150px; " class="alignLeft">
    {{element.technologyName}}
  </td>
  <td cdk-footer-cell *cdkFooterCellDef> </td>
</ng-container>
<ng-container cdkColumnDef="skills">
  <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{SKILLS}}</th>
  <td cdk-cell [matTooltip]=element.skillName *cdkCellDef="let element;let i=index" style=" white-space: nowrap;
  text-overflow: ellipsis; overflow: hidden;  max-width: 200px; " class="alignLeft">
    {{element.skillName}}
  </td></ng-container>
  <ng-container cdkColumnDef="trainingStart">
    <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{TRAINING_START}}</th>
    <td cdk-cell *cdkCellDef="let element;let i=index" class="alignCenter">
      {{element.trainingStart | date:'yyyy-MM-dd'}}
    </td>
    <td cdk-footer-cell *cdkFooterCellDef> </td>
  </ng-container>
  <ng-container cdkColumnDef="trainingEnd">
    <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{TRAINING_END}}</th>
    <td cdk-cell *cdkCellDef="let element;let i=index" class="matAlignCenter">
      {{element.trainingEnd | date:'yyyy-MM-dd'}}
    </td>
     <td cdk-footer-cell *cdkFooterCellDef> </td>
  </ng-container>
  <ng-container cdkColumnDef="employeeId">
    <th cdk-header-cell  *cdkHeaderCellDef class="matAlignCenter">{{EMPLOYEE_NAME}}</th>
    <td cdk-cell *cdkCellDef="let element;let i=index" class="alignLeft" >
      {{element.employeeId.userName}}-{{element.employeeId.firstName}} {{element.employeeId.lastName}}
    </td>
    <td cdk-footer-cell *cdkFooterCellDef> </td>
  </ng-container>
  

  <!--****************************** TRAINING FEEDBACK REPORT[9168] -- ENDS***********************************************-->

 <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"  class="table-active alignCenter  vertical-align-middle" style="height: auto;">
 </tr>
     <tr cdk-row  *cdkRowDef="let row; columns: displayedColumns;" class="alternate-row" style="height: auto;" ></tr>
      <tr class ="mat-footer-row" cdk-footer-row *cdkFooterRowDef="displayedColumns"></tr>
  </table>  
      </div> 
  </div>
 </mat-sidenav-content>
 </mat-sidenav-container>