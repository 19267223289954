import {Directive, HostListener, ElementRef, OnInit, Input} from '@angular/core';
import { NumberFormatterPipe } from './numberFormatter.pipe';
@Directive({
	selector: '[numberFormat]'
})
export class NumberFormatDirective implements OnInit {

  private el: any;
  private e2: any;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: NumberFormatterPipe
  ) {
    this.el = this.elementRef.nativeElement;

  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value);

  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value);
    // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value);

  }
  @HostListener('valueChanges', ['$event.target.value'])
  onChange(value) {
    this.el.value = this.currencyPipe.transform(value);
    // opossite of transform
  }
}
