
<mat-card class="col-lg-12">
	<mat-card-title ><mat-icon>home</mat-icon><label class="historyLabel">{{dispLabel}} {{AUTOMATED_EMAIL_TEXT}}</label>
	</mat-card-title>	
		<form [formGroup]="myAutoEmailForm" >
			<mat-card-content >
				<div class="col-lg-12">
					<mat-form-field class="col-lg-4">
						<mat-select placeholder="{{MODULE_NAME_PLACEHOLDER}}"  formControlName="moduleName">
		          		<mat-option  *ngFor="let module of moduleList" [value]="module" (click)="moduleChange(module)" >{{module}}</mat-option>
		        		</mat-select>
					</mat-form-field>
					
				</div>
				<div class="col-lg-12">
					<mat-form-field class="col-lg-12">
		       			<!-- <textarea matInput placeholder="To Email ID" matTextareaAutosize matAutosizeMinRows="2"
            				matAutosizeMaxRows="4" formControlName="toEmail"></textarea> -->
            				 <mat-chip-list #chipList1>
						         <mat-chip color="primary" *ngFor="let email of toEmailIDList" [selectable]="selectable" [removable]="removable" (removed)="removeToEmail(email, 'TO')">            {{email}}
						          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
						        </mat-chip> 
						        <input  matInput  placeholder="{{TO_EMAIL_ID}}"
						               [matChipInputFor]="chipList1"
						               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						               [matChipInputAddOnBlur]="addOnBlur"
						               (matChipInputTokenEnd)="addToEmail($event, 'TO')" />
        				</mat-chip-list>
		     		</mat-form-field>
				</div>
				<div class="col-lg-12">
					 <mat-form-field class="col-lg-12">
		       				 <mat-chip-list #chipList2>
						         <mat-chip color="primary" *ngFor="let email of ccEmailIDList" [selectable]="selectable" [removable]="removable" (removed)="removeCcEmail(email, 'TO')">            {{email}}
						          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
						        </mat-chip> 
						        <input  matInput  placeholder="CC Email IDs"
						               [matChipInputFor]="chipList2"
						               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						               [matChipInputAddOnBlur]="addOnBlur"
						               (matChipInputTokenEnd)="addCcEmail($event, 'TO')" />
        				</mat-chip-list>
		     		</mat-form-field>
		     	</div>
		     	<div class="col-lg-12">
		     		<mat-form-field class="col-lg-12">
		       			<mat-chip-list #chipList3>
						         <mat-chip color="primary" *ngFor="let email of bccEmailIDList" [selectable]="selectable" [removable]="removable" (removed)="removeBccEmail(email, 'TO')">            {{email}}
						          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
						        </mat-chip> 
						        <input  matInput  placeholder="BCC Email IDs"
						               [matChipInputFor]="chipList3"
						               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						               [matChipInputAddOnBlur]="addOnBlur"
						               (matChipInputTokenEnd)="addBccEmail($event, 'TO')" />
        				</mat-chip-list>
		     		</mat-form-field> 
		     	</div>
				<div class="col-lg-12">
					<mat-form-field class="col-lg-12">
		       			<input  matInput placeholder="{{EMAIL_SUBJECT}}" formControlName="emailSubject" >
		     		</mat-form-field>
		     	</div>
		     	<div class="col-lg-12">
		     		<mat-form-field class="col-lg-12">
		     			<textarea matInput placeholder="{{EMAIL_BODY}}" matTextareaAutosize matAutosizeMinRows="2"
            				matAutosizeMaxRows="4" formControlName="emailBodyText" onfocus="this.select()"></textarea>
		       		</mat-form-field>
		     	</div>
		     	<div class="col-lg-12" *ngIf="autoEmailModuleName == 'Item'">
		     	<div class="col-lg-12" ><span> <b> Display Columns </b> </span>  </div> <br>
		     	
			<div class="col-lg-6" >
				<mat-radio-group formControlName="itemOrInventoryFlag" (change)="changeAutomatedEmailFlag()">
						<mat-radio-button class="radio_padding" color="primary" value="item">{{ITEMS_TABLE}}</mat-radio-button>
						<mat-radio-button class="radio_padding" color="primary" value="inventory">{{INVENTORY_TABLE}}</mat-radio-button>
						<mat-radio-button class="radio_padding" color="primary" value="both">{{BOTH}}</mat-radio-button>
				</mat-radio-group>	
			</div>
				<div class="col-lg-12" >
				<mat-form-field class="col-lg-6 nopadding " *ngIf="showItemCol">         
	        		<mat-select placeholder="{{ITEM_COLUMN}}" multiple formControlName="t200ItemColList">
	          		<mat-option  (click)="change()"*ngFor="let t200ItemColumn of t200ItemColumnList" [value]="t200ItemColumn">{{t200ItemColumn}}</mat-option>
	        		</mat-select>
	    		</mat-form-field>
	    		<mat-form-field class="col-lg-6"  *ngIf="showInventoryCol">         
	        		<mat-select placeholder="{{INVENTORY_COLUMN}}" multiple formControlName="t203InventoryColList">
	          		<mat-option  (click)="change()" *ngFor="let t203Inventory of t203InventoryColumnList" [value]="t203Inventory">{{t203Inventory}}</mat-option>
	        		</mat-select>
	        	</mat-form-field>
	        	
	    		</div>
	    		<div class="col-lg-12" >
					<span> <b> Conditions </b> </span>
				</div>
				<div class="col-lg-12" *ngIf="myAutoEmailForm.controls.itemOrInventoryFlag.value != 'inventory'">
					<mat-form-field class="col-lg-4 nopadding" *ngIf="showItemCol">         
		        		<mat-select placeholder="{{ITEM_COLUMN}}"  formControlName="whereCaseLeft1">
		          		<mat-option   *ngFor="let t200ItemColumn of t200ItemColumnList" [value]="t200ItemColumn">{{t200ItemColumn}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
					<mat-form-field class="col-lg-2 ">         
		        		<mat-select placeholder="{{CONDITION}}"  formControlName="condition1">
		          		<mat-option   *ngFor="let condition of conditionList" [value]="condition">{{condition}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
	    			<mat-form-field class="col-lg-4" *ngIf="showInventoryCol">         
		        		<mat-select placeholder="{{INVENTORY_COLUMN}}"  formControlName="whereCaseRight1">
		          		<mat-option   *ngFor="let t203Inventory of t203InventoryColumnList" [value]="t203Inventory">{{t203Inventory}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
	    			<mat-form-field class="col-lg-4" *ngIf="showTextCol">      
	    				<input  matInput placeholder="{{TEXT_CONDITION}}" formControlName="whereCaseRight2" > 
	    			</mat-form-field>
	    			<div class="col-lg-2">
    				<button mat-button matTooltip="Add New Condition"  type="button"  mat-icon-button aria-label="Clear"   matSuffix (click)="addCondition()">
              		 <div class="mat_icon_color" ><mat-icon >add</mat-icon></div>
           			</button>
                  </div>
				</div>
				<div class="col-lg-12"  *ngIf="myAutoEmailForm.controls.itemOrInventoryFlag.value === 'inventory'">
					<mat-form-field class="col-lg-4 nopadding" *ngIf="showInventoryCol">         
		        		<mat-select placeholder="{{INVENTORY_COLUMN}}"  formControlName="whereCaseLeft2">
		          		<mat-option  *ngFor="let t203Inventory of t203InventoryColumnList" [value]="t203Inventory">{{t203Inventory}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
					<mat-form-field class="col-lg-2">         
		        		<mat-select placeholder="{{CONDITION}}"  formControlName="condition1">
		          		<mat-option  *ngFor="let condition of conditionList" [value]="condition">{{condition}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
	    			
	    			<mat-form-field class="col-lg-4" *ngIf="showTextCol">      
	    				<input  matInput placeholder="{{TEXT_CONDITION}}" formControlName="whereCaseRight2" > 
	    			</mat-form-field>
	    			<div class="col-lg-2"><button mat-button matTooltip="Add New Condition"  type="button"  mat-icon-button   matSuffix aria-label="Clear" (click)="addCondition()">
                   <div class="mat_icon_color" ><mat-icon >add</mat-icon></div>
               		</button>
                  </div>
				</div>
				<div class="col-lg-12">

				<mat-form-field class="col-lg-10 nopadding">
					<!--  <textarea matInput readonly placeholder="Query" matTextareaAutosize matAutosizeMinRows="3"
            				matAutosizeMaxRows="4" formControlName="fetchQuery" ></textarea> -->
            				<textarea  matInput placeholder="{{FETCH_CONDITION}}" formControlName="fetchCondition"onfocus="this.select()"></textarea>
		       	</mat-form-field>	
		       	<div class="col-lg-2"><button mat-button matTooltip="CLear Condition"  type="button"   mat-icon-button   matSuffix aria-label="Clear" (click)="clearCondition()">
                   <div class="mat_icon_color" ><mat-icon >clear</mat-icon></div>
               </button>
                  </div>	
				</div>
				<div class="col-lg-12 nopadding">

				<mat-form-field class="col-lg-12">
					<textarea matInput readonly placeholder="{{ADD_COLUMN_STRING}}" matTextareaAutosize matAutosizeMinRows="3"
            				matAutosizeMaxRows="4" formControlName="columnHeaderString" onfocus="this.select()"></textarea>
		       	</mat-form-field>	

				</div>
				</div>

				<div *ngIf="autoEmailModuleName == 'Leave History' ">
				<div class="col-lg-12 nopadding" >
				<span style="padding-left: 28px;"> <b> Display Columns </b> </span></div>
				<div class="col-lg-12" >
				 <mat-form-field class="col-lg-6" >         
	        	   <mat-select placeholder="{{LEAVE_HISTORY_COLUMN}}" multiple formControlName="t825LeaveHistoryColList">
	          	   <mat-option (click)="change()" *ngFor="let t825LeaveHistoryColumn of t825LeaveHistoryColumnList" [value]="t825LeaveHistoryColumn">{{t825LeaveHistoryColumn}}</mat-option>
	        	 </mat-select>
	    		</mat-form-field>
				</div>

	    		<div class="col-lg-12 nopadding" >
					<span  style="padding-left: 28px;"> <b> Conditions </b> </span>
				</div>

				<div class="col-lg-12">
					<mat-form-field class="col-lg-4" >         
		        		<mat-select placeholder="{{LEAVE_HISTORY_COLUMN}}"  formControlName="whereCaseLeft1">
		          		<mat-option   *ngFor="let t825LeaveHistoryColumn of t825LeaveHistoryColumnList" [value]="t825LeaveHistoryColumn">{{t825LeaveHistoryColumn}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
					<mat-form-field class="col-lg-2">         
		        		<mat-select placeholder="{{CONDITION}}"  formControlName="condition1">
		          		<mat-option   *ngFor="let condition of conditionList" [value]="condition">{{condition}}</mat-option>
		        		</mat-select>
	    			</mat-form-field>
	    			<mat-form-field class="col-lg-4" >      
	    				<input  matInput placeholder="{{TEXT_CONDITION}}" formControlName="whereCaseRight2" > 
	    			</mat-form-field>
	    			<div class="col-lg-2"><button mat-button matTooltip="Add New Condition"  type="button"  mat-icon-button   matSuffix  aria-label="Clear" (click)="addCondition()">
                   <div class="mat_icon_color" ><mat-icon >add</mat-icon></div>
              		</button>
                  </div>
				</div>
				<div class="col-lg-12"  style="padding-left: 31px;">

				<mat-form-field class="col-lg-10 nopadding">
            			<textarea  matInput placeholder="{{FETCH_CONDITION}}" formControlName="fetchCondition" onfocus="this.select()"></textarea>
		       	</mat-form-field>	
		       	 <div class="col-lg-2"><button mat-button matTooltip="CLear Condition"  type="button"  mat-icon-button   matSuffix aria-label="Clear" (click)="clearCondition()">
                   <div class="mat_icon_color" ><mat-icon >clear</mat-icon></div>
               </button>
                  </div> 	
				</div>

				<mat-form-field class="col-lg-12" style="padding-left: 35px;">
					<textarea matInput readonly placeholder="{{ADD_COLUMN_STRING}}" matTextareaAutosize matAutosizeMinRows="3"
            				matAutosizeMaxRows="4" formControlName="columnHeaderString" onfocus="this.select()"></textarea>
		   		 </mat-form-field>	
			</div>

				<div class="col-lg-12" style="padding-top: 2% !important">
					<div class="col-lg-2">
        				<button mat-raised-button color="primary" type="button" class="mat-red"  [disabled]="!myAutoEmailForm.valid" (click)="saveAutoEmailScript()">{{SAVE_BUTTON}}</button>
      				</div>
      				<div class="col-lg-2">
        				<button mat-raised-button color="primary" type="button" (click)="closeBtnClick()">{{CANCEL_BUTTON}}</button>
      				</div>
    			</div>
			</mat-card-content>
 		</form>
 </mat-card>
