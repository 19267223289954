<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-pd-0" style="height:auto;" >

<mat-card-title><mat-icon>home</mat-icon>Appraisal Master</mat-card-title>
<mat-card-content>
  <form [formGroup]="appraisalForm" (ngSubmit)="saveOrUpdateAppraisalMaster()">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding row mb-pd-0{">
     <mat-form-field class="col-lg-10 col-md-8 col-sm-8 col-xs-12 paddingRight1 mb-pd-0"  >
      <input matInput  placeholder="{{APPRAISAL_LEVEL}}"  formControlName="appraisalName">
      <button mat-button  *ngIf="appraisalForm.controls['appraisalName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(appraisalForm,'appraisalName')" >
        <mat-icon>close</mat-icon>
      </button>
</mat-form-field>     
     <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 table-label-left-align" >
      <mat-slide-toggle class="example-margin" color="primary" formControlName="status" style="padding-top: 15px"></mat-slide-toggle>
 </div>
   </div>
   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-pd-0">JD Points</div>
  
 
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
  <div formArrayName="subAppraisalSet" *ngFor="let subAppraisal of appraisalForm.get('subAppraisalSet')['controls']; index as idx">
    <div [formGroupName]="idx">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row mb-pd-0" style="padding-right: 0px; margin:0;">
        <mat-form-field class="col-lg-10 col-md-8 col-sm-8 col-xs-12 nopadding">
          <input matInput formControlName="subAppraisalName" maxlength="250">
          <button mat-button  *ngIf="subAppraisal.controls['subAppraisalName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControlInFormArray(appraisalForm,'subAppraisalSet',idx,'subAppraisalName')" >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 table-label-left-align">
      <mat-slide-toggle class="example-margin" color="primary" formControlName="status" style="padding-top: 15px"></mat-slide-toggle>
 </div>
      
      </div>
    </div>
  </div>
 
</div> 
 
  </div>
</div>

  </form>
</mat-card-content>
<div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding" style="margin-bottom: 1% !important;">
        <button type="button" mat-button  matSuffix   aria-label="Clear" (click)="addMoreJDPoints()"><mat-icon>add</mat-icon>&nbsp;&nbsp;Add JD Points
                  </button>
  </div>
 
</mat-card>

 <div style="position: sticky; padding-top: 15px;" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons" >
      <button mat-raised-button color="primary"  type="submit" class="mat-red" [disabled]="!appraisalForm.valid || saveBlock" (click)="saveOrUpdateAppraisalMaster()">{{SAVE_BUTTON}}</button>
      <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
    </div> 