<div class="Header-values">
  <h3>Organization Contacts</h3>
</div>
<div class="dropdown-divider"></div>
<div class="col-lg-12" style="margin-top: 2%;">
  <mat-table #table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="boldLabel" style="flex: 0 1 150px;"> NAME </mat-header-cell>
      <mat-cell *matCellDef="let emailelement" style="flex: 0 1 150px;"> 
        <mat-form-field class="col-lg-12" floatLabel="never">
          <input matInput placeholder="{{ENTER_NAME}}" [disabled]="disableInput" style="color: black;" [(ngModel)]="emailelement.name">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef class="boldLabel"> EMAIL </mat-header-cell>
      <mat-cell *matCellDef="let emailelement">
        <mat-form-field class="col-lg-12" floatLabel="never">
          <input matInput placeholder="{{ENTER_EMAIL}}" [disabled]="disableInput" style="color: black;" [(ngModel)]="emailelement.email">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit" *ngIf="defaultIconField">
      <mat-header-cell *matHeaderCellDef class="boldLabel" style="flex: 0 1 30px;"> </mat-header-cell>
      <mat-cell *matCellDef="let emailelement" style="text-align: right;flex: 0 1 30px;"><mat-icon style="cursor: pointer;" (click)="editContact()">edit</mat-icon></mat-cell>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete" *ngIf="defaultIconField">
      <mat-header-cell *matHeaderCellDef class="boldLabel" style="flex: 0 1 30px;"> </mat-header-cell>
      <mat-cell *matCellDef="let emailelement" style="text-align: right;flex: 0 1 30px;"><mat-icon style="cursor: pointer;" (click)="cancel(emailelement)">delete</mat-icon></mat-cell>
    </ng-container>

    <!-- Save Column -->
    <ng-container matColumnDef="save" *ngIf="newAddContactField">
      <mat-header-cell *matHeaderCellDef class="boldLabel" style="flex: 0 1 30px;"> </mat-header-cell>
      <mat-cell *matCellDef="let emailelement" style="text-align: right;flex: 0 1 30px;"><mat-icon (click)="saveContact(emailelement)" style="cursor: pointer;">save</mat-icon></mat-cell>
    </ng-container>

    <!-- Cancel Column -->
    <ng-container matColumnDef="cancel" *ngIf="newAddContactField">
      <mat-header-cell *matHeaderCellDef class="boldLabel" style="flex: 0 1 30px;"> </mat-header-cell>
      <mat-cell *matCellDef="let emailelement" style="text-align: right;flex: 0 1 30px;"><mat-icon style="cursor: pointer;" (click)="cancel(emailelement)">cancel</mat-icon></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>
</div>
<div class="col-lg-12" style="margin-top: 2%;" *ngIf="defaultIconField">
  <button mat-raised-button color="primary" class="paddingLabels" (click)="addNewContacts()"><mat-icon class="matIconColor">add</mat-icon>Add Additional Contact</button>
</div>