<a (click)="openIcon('REFRESH')">
      <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
      <mat-icon class="reportLink"  matTooltip="Refresh Grid">refresh</mat-icon> </button> </a>

       <a (click)="openIcon('SEARCH')">
          <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
            <mat-icon class="reportLink" matTooltip="Search by Additional fields">search</mat-icon>
          </button>
        </a>

        <a (click)="openIcon('ANALYTICALCHART')">
        <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear">
        <mat-icon class="reportLink" matTooltip="View Analytical Chart">insert_chart</mat-icon></button>
        </a>