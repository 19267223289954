import { Component, OnInit, Inject,ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component';
import {SignaturePad} from 'angular2-signaturepad'; 

@Component({
  selector: 'app-uploadsignature',
  templateUrl: './upload-signature.component.html'
})
export class UploadSignatureComponent extends CommonUtils
{ 
  @ViewChild(SignaturePad) signaturePadViewChildObj : SignaturePad; 
  moduleName:string;
  labelName:string;
    portalName: string;
    employeeId: string;
    employeeCode: string;
constructor(public dialogRef: MatDialogRef<UploadSignatureComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private commonService:CommonService,private CommonUtils:CommonUtils,public dialog: MatDialog) 
{
  super();
  this.moduleName = data.moduleName;
  this.portalName = data.portalName;
  this.employeeCode = data.employeeCode;
  this.employeeId = data.employeeId;
}
 
 ngOnInit()
 {
  this.pageLoad();
 }
pageLoad()
{
 
  
} 
cancelClick() 
{
	this.dialogRef.close('close');
}

  saveSignature(){
	  this.commonService.saveOrUpdateNDAPDFSignature({userName: this.employeeCode, empId: this.employeeId}).subscribe(
    result => {
      this.commonService.showERPMessage("Signature has been saved successfully.");
      this.dialogRef.close('close');
     }, error => {
      console.log(error)
    });
  } 
 
  public signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 920,
    'canvasHeight': 300
  }; 

  drawComplete() {
    this.commonService.hideProgressBar();
    const dataURL = this.signaturePadViewChildObj.toDataURL('image/png');
    const data = atob(dataURL.substring('data:image/png;base64,'.length)),asArray = new Uint8Array(data.length);
    for (var loop = 0, len = data.length; loop < len; ++loop) {
      asArray[loop] = data.charCodeAt(loop);
    }
    const blob = new Blob([asArray], { type:'image/png'});
    const body = new FormData();
    body.append('file', blob);
    body.append('empId', this.employeeId);
    body.append('empCode', this.employeeCode);
    body.append('authCode', this.portalName);
  
    this.commonService.uploadSignatureFile(body).subscribe(
    (response) => {
      console.log(response);
    },
    (error)=>{
      console.log(error);
      this.commonService.hideProgressBar();
    });
  } 

}
