import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HrmService} from '../hrm-service';
import {CommonService} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
 import {environment} from '../../../environments/environment';
 import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@Component({
  selector: 'app-addshiftmaster',
  templateUrl: './create-shift-master.component.html'

})
export class CreateShiftMasterComponent extends CommonUtils implements OnInit {
  shiftMasterForm: FormGroup;  
  createButtonEnable = true; 
  editId: number;
  userObj;
  shiftMasterObj;
  currentlySelectedMenu;
  editAccess = true;
  moduleName : string = environment.NEW_SHIFT_MASTER;
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  });
  shiftMasterId;
/*  time = {hour: 09, minute: 30};
  meridian = true;*/
  constructor(private hrmService: HrmService, public snackBar: MatSnackBar, private router: Router,private ngxTimepicker: NgxMaterialTimepickerModule,
    private commonService: CommonService, private commonUtils: CommonUtils,
    public dialogRef: MatDialogRef<CreateShiftMasterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  
   // this.editId = data.shiftMasterId;
  
    this.shiftMasterObj=data;
this.shiftMasterId=data.shiftMasterId;
    this.shiftMasterForm = new FormGroup({
      shiftMasterId: new FormControl(0),
      shiftName: new FormControl(''),
      startTime:new FormControl(),
      endTime:new FormControl(),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
      setStartTime:new FormControl(false),
      setEndTime:new FormControl(false)
    }
    )
  }
  ngOnInit() 
  {

  /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
   //  this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu
           this.pageLoad();
           
         }
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
 this.pageLoad();

  }
  pageLoad():void
  {
    try
    {
    if (this.commonUtils.checkNullOrUndefined(this.shiftMasterObj))
    this.bindShiftMaster();
   }
catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{});
}

  }
  saveOrUpdateShiftMaster(): void 
  {
    var startTimeFormat=this.changeTimeFormat(this.shiftMasterForm.get('startTime').value);
    var endTimeFormat=this.changeTimeFormat(this.shiftMasterForm.get('endTime').value);
    this.shiftMasterForm.get('startTime').setValue(startTimeFormat);
    this.shiftMasterForm.get('endTime').setValue(endTimeFormat);
   this.userObj = this.commonService.loggedInUserObj.userId;
    if (this.shiftMasterForm.get('shiftMasterId').value === 0 || (!this.commonUtils.checkNullOrUndefined(this.shiftMasterForm.get('shiftMasterId').value))) {
      this.shiftMasterForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.shiftMasterForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }
    if (this.shiftMasterForm.get('shiftMasterId').value != 0) {
      this.shiftMasterForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    }

    this.commonService.showProgressBar();
   
    this.hrmService.saveOrUpdateShiftMaster(this.shiftMasterForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage);
        this.dialogRef.close(true);        
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
changeTimeFormat(event){
var timeData=event.replace(" ",":");
let hour = (timeData.split(':'))[0];
      let min = (timeData.split(':'))[1];
      let part = (timeData.split(':'))[2];
     if(part=="PM"){
      var hoursFormat:any=12+Number(hour);
     }
     else{
       hoursFormat=hour;
     }
     var timeFormat=hoursFormat+":"+min+":00";
     return timeFormat;
}
  changeFormat(event){ 
    if(event!=''){
      let hour = (event.split(':'))[0]
      let min = (event.split(':'))[1]
      let part = hour > 12 ? 'PM' : 'AM';
      min = (min+'').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour+'').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }
    else{
      return '';
    }
  }
bindShiftMaster():void
{
   this.shiftMasterForm.patchValue(this.shiftMasterObj);
   this.shiftMasterForm.get('startTime').setValue(this.changeFormat(this.shiftMasterObj.startTime));
   this.shiftMasterForm.get('endTime').setValue(this.changeFormat(this.shiftMasterObj.endTime));
}

  cancelClick(): void {
    this.dialogRef.close();
  }
  setTime(type:string){

    if(this.shiftMasterId != 0 && type==='start' )
    {
      this.shiftMasterForm.get('setStartTime').setValue(true);
    }
    else if(this.shiftMasterId != 0 && type==='end' )
    {
      this.shiftMasterForm.get('setEndTime').setValue(true);
    }
    else
    {
      this.shiftMasterForm.get('setStartTime').setValue(false);
      this.shiftMasterForm.get('setEndTime').setValue(false);
    }
       
  }

}
