<mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
<mat-card-title >CREATE REVIEW DISPLAY COLUMN</mat-card-title>
<mat-card-content>
<form (ngSubmit)="saveOrUpdateReviewDisplayColumn()"   [formGroup]="myReviewForm">
<div class="col-lg-12">
	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<input  matInput placeholder="{{MODULE_NAME_PLACEHOLDER}}" formControlName="moduleName">
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<textarea matInput placeholder="{{COLUMN_NAME_PLACEHOLDER}}"   formControlName="columnName" onfocus="this.select()"></textarea> 	
		</mat-form-field>
	</div>

	<div class="col-lg-12 col-md-10 col-sm-10 col-xs-12">
		<mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
			<textarea matInput placeholder="{{LABEL_NAME_PLACEHOLDER}}"   formControlName="labelName"  onfocus="this.select()"></textarea> 	
		</mat-form-field>
	</div>

	
	<div class="col-lg-12"></div>

	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 buttons" style="padding-left: 91px;">
			<button mat-raised-button color="primary" class="mat-red" type="submit">{{SAVE_BUTTON}}</button>
			<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>



</div>
</form>
</mat-card-content>
</mat-card>