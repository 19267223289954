/**Author -esales -9014  Rajesh Subramanian **/
import {Component, OnInit,EventEmitter,Output} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {CommonService} from '../shared/common.service';
import {CommonUtils} from '../core/common-utils';
import {environment} from '../../environments/environment';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  buildNumber=this.commonService.BuildNumber;
  shouldRun = true; 
  panelOpenState = false;
  t002MenuSet;
loggedInUserObj;
imageSrc;

@Output() settingsAvailableEmitter:EventEmitter<any>=new EventEmitter<any>();
  constructor(private commonService: CommonService,private commonUtils: CommonUtils, private router: Router) {
    this.imageSrc=this.commonService.SERVICE_URL;
  }
  ngOnInit() {

    //Rajesh commented this code, as the subject is not available here . this is taken care by the ngif in the app component while calling this 
  this.commonService.loggesInUserObjSubject.subscribe(data => {
       
      if (data === 'LOGIN_AVAILABLE') {         
this.pageLoad();
       
     }
    });  

    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
  this.pageLoad();


  }

   async pageLoad(){
     this.t002MenuSet= await this.assignMenuSet();
 

          for(var loop = 0; loop < this.t002MenuSet.length; loop++){            
            if(this.t002MenuSet[loop].menuName==='Settings' && this.t002MenuSet[loop].t030_Current_Access.viewAccess && 
              this.t002MenuSet[loop].t030_Current_Access.enableMenu){
             this.settingsAvailableEmitter.emit(true);
             break;
           }            
         }
   }
   assignMenuSet():Promise<any>{
     return new Promise(resolve=>{      
     resolve(this.commonService.t002MenuSet);
   }
   );

   }

  addMenuToHistory(t002MenuObj,value):void
  {
    if(t002MenuObj.routerurl == 'api/documentation') {
      let swaggerURL:string = this.commonService.SERVICE_URL+'swagger-ui/index.html';
      swaggerURL = swaggerURL.replace("/api/swagger","/swagger"); 
       window.open(swaggerURL);
  } else {
   let navigateUrl;
   this.commonService.menuClickedSubject.next({action:"CLOSE_MENU"});
    if(value=='review')
    this.commonService.addMenuList(t002MenuObj.routerurl);
    if(value=='add'){
     this.commonService.addMenuList(t002MenuObj.addurl);
    }  
  }  
  }

offlineModeAlert(){
  alert("Do you want to work Offline ?");  
}

ngAfterViewInit() {
  this.asideChange();
}

   

asideChange(){
    
  let asideObj=document.getElementById('aside');
  let bodyId=document.getElementById('bodyId');
 // bodyId.className = bodyId.className.concat(" fuse-aside-expanded");
 asideObj.onmouseover=function(){
   bodyId.className = bodyId.className.concat(" fuse-aside-expanded");
 }
  asideObj.onmouseout=function(){
    bodyId.classList.remove("fuse-aside-expanded");
 }
   
}
 
}

