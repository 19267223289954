<div class="modal-body">
    <div class="row">
      <div class="col-md-2">
       <mat-icon style="font-size: 35px;color: crimson;">warning</mat-icon>
      </div>
      <div style="padding-left: 2px" class="col-md-10 ">
        <p data-test-title="confirm-modal-msg">{{dialogTextToDisplay}}</p>
        <div class="alert-actions btn-toolbar" style="margin-left:50px">
          <button *ngIf="confTextToDisplay!='' && confTextToDisplay != 'ConfirmOfferEmail' && confTextToDisplay != 'ConfirmShiftDate'  && confTextToDisplay != 'ConfirmBaselineTaskDate'"mat-raised-button type="submit" color="primary" (click)="onOKClick()">OK</button>
            <button *ngIf="confTextToDisplay!='' && (confTextToDisplay === 'ConfirmOfferEmail' || confTextToDisplay === 'ConfirmShiftDate')" mat-raised-button type="submit" color="primary" (click)="onOKClick()">Yes</button>
             <button *ngIf="confTextToDisplay!='' && confTextToDisplay === 'ConfirmBaselineTaskDate'" mat-raised-button type="submit" color="primary" (click)="onOKClick()"> Continue Saving </button>
          <button *ngIf="confTextToDisplay!='' && confTextToDisplay!='GetTrackingNumber' && confTextToDisplay!='ConfirmOfferEmail'  && confTextToDisplay != 'ConfirmBaselineTaskDate' && confTextToDisplay != 'ConfirmShiftDate'" mat-raised-button color="primary" (click)="onNoClick()">CANCEL</button>
          <button *ngIf="confTextToDisplay!='' && (confTextToDisplay === 'ConfirmOfferEmail' || confTextToDisplay === 'ConfirmShiftDate')" mat-raised-button color="primary" (click)="onNoClick()">No</button>
           <button *ngIf="confTextToDisplay!='' && confTextToDisplay === 'ConfirmBaselineTaskDate'" mat-raised-button color="primary" (click)="onNoClick()">Re-Edit Dates</button>
        </div>
      </div>
    </div>
  </div>