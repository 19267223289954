<div class="modal-body">
    <div class="row">
      <div class="col-md-2">
       <mat-icon style="font-size: 35px;color: crimson;">warning</mat-icon>
      </div>
      <div style="padding-left: 2px" class="col-md-10 ">
        <p data-test-title="confirm-modal-msg">{{dialogTextToDisplay}}</p>
        <div class="alert-actions btn-toolbar" style="margin-left:50px">
          <button mat-raised-button type="submit" color="primary" (click)="onButton1Click()">{{confButton1}}</button>
          <button *ngIf="confButton2!=''" mat-raised-button color="primary" (click)="onButton2Click()">{{confButton2}}</button>
        </div>
      </div>
    </div>
  </div>