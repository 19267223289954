
<div >
<mat-card-title  class="mat-card-title cardheadertitle">
<mat-icon>vpn_key</mat-icon>{{CHANGE_PASSWORD}}</mat-card-title>
<mat-card class="col-lg-12">
<mat-card-content>

<form  [formGroup]="ChangePasswordForm">

<div class ="col-lg-12">
	<mat-form-field class="col-lg-10" >
		<input type='password' matInput placeholder="{{NEW_PASSWORD}}" formControlName="newPassword" >
	</mat-form-field>	
</div>

<div class ="col-lg-12">
	<mat-form-field class="col-lg-10">
		<input type='password'  matInput placeholder="{{CONFIRM_PASSWORD}}" formControlName="confirmPassword"  (keyup)="checkUserPassword()">
		 <mat-hint *ngIf="enableHint" style="color: crimson">Password mismatch</mat-hint>
	</mat-form-field>	
</div>

 <div class="col-lg-12" align="left" style="margin:20px">
    <button mat-raised-button color="primary" type="submit" (click)="saveOrUpdateUserPassword()" class="mat-red" [disabled]="disableButton">{{SAVE_BUTTON}}</button>
      <label style="padding-left:10px;">    <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
      </label>
 </div>

</form>
  </mat-card-content>
</mat-card>
</div>