import { Injectable, Injector } from '@angular/core';
import {HttpInterceptor, HttpClient,HttpEvent, HttpHandler, HttpRequest,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import {Observable,throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {CommonService} from '../shared/common.service';
import {FormGroup, FormControl, FormGroupDirective, NgForm, Validators,FormBuilder,FormArray} from '@angular/forms';
import { map,catchError } from 'rxjs/operators';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	 authService;
	 commonService;
	 injectorObj;
	        errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
	 constructor(private injector: Injector,private http: HttpClient) {
	 	 setTimeout(() => {

      this.authService = this.injector.get(AuthService);
      this.commonService=injector.get(CommonService);
 	  this.injectorObj=this.injector;


      //  let token=this.authService.getToken();
 	 
  })

	 }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	 
	let token:string;
	//commented by Rajesh - will need to checkin later 
   
	 /* if(this.authService!=null &&  this.authService!=undefined && 
		this.authService.getToken()!=null && this.authService.getToken()!=undefined){
	    token=this.authService.getToken().access_token;
     
    }*/
	   
	 
	 this.commonService=this.injector.get(CommonService);
    

	  if(this.commonService!=null && this.commonService!=undefined &&
		this.commonService.loggedInUserObj!=null && this.commonService.loggedInUserObj!=undefined
		&& this.commonService.loggedInUserObj!='undefined' && 
		req.url.indexOf('data.fixer.io')==-1 ){
   	
      if(this.commonService.authObject!= null && this.commonService.authObject!=undefined){ 
          token=this.commonService.authObject.access_token;
        // console.log(this.commonService.authObject);
       }   
//Bearer token added by Ajith[9121] implementing JWT authentication on 18, OCT 2022
      token =  this.commonService.getUserDataCustom('accessToken') ;
      if(token == null || token === '' || token.length==0){ 
        if(this.authService!=null &&  this.authService!=undefined  ){
          let promise=this.authService.getToken();
          promise.then(data=>{
            token=data;
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
          })
        }
      } else {
        req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
      }
//Bearer token added by Ajith[9121] implementing JWT authentication on 18, OCT 2022    
     req = req.clone({ headers: req.headers.set('AuthCode', this.commonService.loggedInUserObj.authCode) });
   	 req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
  }
 		return next.handle(req) 
		 .pipe(
         catchError( (error: HttpErrorResponse) => { 
            let errMsg = '';
            // Client Side Error
            if (error.error instanceof ErrorEvent) {        
              errMsg = `Error: ${error.error.message}`;
            } 
            else {  // Server Side Error
              errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
            
           let errorMessage=error.message+ ' ' + error.message;

   this.errorFormGroup.get('errorMessage').setValue(' Error in Angular ');
   this.errorFormGroup.get('errorStackTrace').setValue(errorMessage);
   this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
   	data=>{
   	console.log(' saved ');
   		
   	},
   	error=>{
   		console.log(error);
   		
   	}); 
 
            return throwError(error);
          })
       );

	}

}
