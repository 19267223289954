import { Directive, Input, EventEmitter, ElementRef, Inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focus]'
}) 
export class FocusDirective {
  @Input( ) focusEvent: EventEmitter<string>;
  //@Input() keyBoardValue:string;
  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {   
    this.focusEvent.subscribe(event => {
       
      if(this.element.nativeElement.id===event)
        this.element.nativeElement.focus();
    });
  }

}