import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {FormControl, FormGroup, NgForm, FormBuilder,FormArray,Validators} from '@angular/forms';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
import {SettingsService} from '../settings-service';
import {ENTER, COMMA} from '@angular/cdk/keycodes';

@Component({
  selector: 'create-automatedEmail',
  templateUrl: './create-automatedEmail.component.html'
})
export class CreateAutomatedEmailComponent extends CommonUtils implements OnInit {
	AUTOMATED_EMAIL_TEXT: string = 'Automated Email Script';
	moduleName='NEW_AUTOMATED_EMAIL';
	dispLabel: string = 'New';
	validationListForSelectedModule=[];
	myAutoEmailForm: FormGroup;
	toEmailIDList = [];
	ccEmailIDList = [];
	bccEmailIDList = [];
	selectColumnStr;
	whereConditionStr;
	fetchConditionStr;
	whereLeftStr;
	whereRightStr;
	conditionStr;
	separatorKeysCodes = [ENTER, COMMA];
 	 selectable = true;
	 removable = true;
	 addOnBlur = true;

	 showItemCol=true;
  	 showInventoryCol=false;
     showTextCol=true;
     fetchConditionList = [];
     whereConditionList = [];
	moduleList: string[] = ['Item','Leave History'];
	autoEmailModuleName = '';
	t200ItemColumnList: string[] = ['Type','Name','SKU','UOM','Manufacturer','UPC','EAN','ISBN','MPN','SalesRate',
							'SalesDescription','PurchaseRate','PurchaseDescription','ReorderLevel'];

	t203InventoryColumnList: string[] = ['OnHandQuantity','TobeReceivedQuantity','TobeShippedQuantity','SalesOrderQty'];

	conditionList: string[] = ['<','>','=','!=','<=','>=','IS NULL'];

	t200ItemColumnMap:Map<string,string>=new Map();
	

	t203InventoryColumnMap:Map<string,string>=new Map();

	t825LeaveHistoryColumnMap:Map<string,string>=new Map();

	t825LeaveHistoryColumnList: string[] = ['LeaveId','ApproverId','LeaveTypeId','LeaveReason','TotalAppliedDays','AppliedDate','StatusId','CL_Available','SL_Available','CompOff_Available',
							'LOP','StartDate','EndDate','ApprovedDays','SL_Approved','CL_Approved','CompOff_Approved','Permission','Month','Trx_Type','appliedPermissionHours',
							'appliedPermissionMinutes','MonthId','YearId'];


	constructor(private commonService: CommonService,private router: Router, private commonUtils: CommonUtils, private formBuilder: FormBuilder,public settingsService: SettingsService,
	public dialogRef: MatDialogRef<CreateAutomatedEmailComponent>,@Inject(MAT_DIALOG_DATA) public data: any)
	{
		super();
		
		this.myAutoEmailForm = new FormGroup({
								automatedEmailId: new FormControl(0),
								profileName: new FormControl(''),
								emailSubject: new FormControl(''),
								emailBodyText: new FormControl(''),
								toEmail: new FormControl(''),
								ccEmail: new FormControl(''),
								bccEmail: new FormControl(''),
								moduleName: new FormControl(this.moduleList[0]),
								fetchQuery: new FormControl(''),
								t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
								t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
								t200ItemColList: new FormControl(''),
								t203InventoryColList: new FormControl(''),
								condition1: new FormControl(''),
								whereCaseLeft1: new FormControl(''),
								whereCaseRight1: new FormControl(''),
								columnHeaderString: new FormControl(''),
								fetchCondition: new FormControl(''),
								itemOrInventoryFlag: new FormControl('both'),
								whereCaseRight2: new FormControl(),
								whereCaseLeft2: new FormControl(''),

								t825LeaveHistoryColList: new FormControl(''),
							});

		this.autoEmailModuleName = this.moduleList[0];
		
		
 		this.t200ItemColumnMap.set('Type', 'varchar');
		this.t200ItemColumnMap.set('Name', 'varchar');
		this.t200ItemColumnMap.set('SKU', 'varchar');
		this.t200ItemColumnMap.set('UOM', 'varchar');
		this.t200ItemColumnMap.set('Manufacturer', 'varchar');
		this.t200ItemColumnMap.set('UPC', 'varchar');
		this.t200ItemColumnMap.set('EAN', 'varchar');
		this.t200ItemColumnMap.set('ISBN', 'varchar');
		this.t200ItemColumnMap.set('MPN', 'varchar');
		this.t200ItemColumnMap.set('SalesRate', 'double');
		this.t200ItemColumnMap.set('SalesDescription', 'varchar');
		this.t200ItemColumnMap.set('PurchaseRate', 'double');
		this.t200ItemColumnMap.set('PurchaseDescription', 'varchar');
		this.t200ItemColumnMap.set('ReorderLevel', 'decimal');

		this.t203InventoryColumnMap.set('OnHandQuantity','bigint');
		this.t203InventoryColumnMap.set('TobeReceivedQuantity','bigint');
		this.t203InventoryColumnMap.set('TobeShippedQuantity','bigint');
		this.t203InventoryColumnMap.set('SalesOrderQty','bigint');


	this.t825LeaveHistoryColumnMap.set('LeaveId','int');
	this.t825LeaveHistoryColumnMap.set('ApproverId','int');
	this.t825LeaveHistoryColumnMap.set('LeaveTypeId','int');
	this.t825LeaveHistoryColumnMap.set('LeaveReason','varchar');
	this.t825LeaveHistoryColumnMap.set('TotalAppliedDays','double');
	this.t825LeaveHistoryColumnMap.set('AppliedDate','datetime');
	this.t825LeaveHistoryColumnMap.set('StatusId','int');
	this.t825LeaveHistoryColumnMap.set('CL_Available','double');
	this.t825LeaveHistoryColumnMap.set('SL_Available','double');
	this.t825LeaveHistoryColumnMap.set('CompOff_Available','float');
	this.t825LeaveHistoryColumnMap.set('LOP','double');
	this.t825LeaveHistoryColumnMap.set('StartDate','datetime');
	this.t825LeaveHistoryColumnMap.set('EndDate','datetime');
	this.t825LeaveHistoryColumnMap.set('ApprovedDays','float');
	this.t825LeaveHistoryColumnMap.set('SL_Approved','double');
	this.t825LeaveHistoryColumnMap.set('CL_Approved','double');
	this.t825LeaveHistoryColumnMap.set('CompOff_Approved','double');
	this.t825LeaveHistoryColumnMap.set('Permission','varchar');
	this.t825LeaveHistoryColumnMap.set('Month','varchar');
	this.t825LeaveHistoryColumnMap.set('Trx_Type','varchar');
	this.t825LeaveHistoryColumnMap.set('appliedPermissionHours','decimal');
	this.t825LeaveHistoryColumnMap.set('appliedPermissionMinutes','decimal');
	this.t825LeaveHistoryColumnMap.set('Permission','varchar');
	this.t825LeaveHistoryColumnMap.set('MonthId','bigint');
	this.t825LeaveHistoryColumnMap.set('YearId','bigint');

	}

	pageLoad():void
	  {
	  	this.validationMethod();
	  	this.changeAutomatedEmailFlag();
	  }
	
	ngOnInit()
	{
  		this.pageLoad();
	}

	saveAutoEmailScript(): void{
		
		if(this.myAutoEmailForm.get('automatedEmailId').value === 0)
		{
			this.myAutoEmailForm.get('t001UserCreatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
				this.myAutoEmailForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
		}else{
				this.myAutoEmailForm.get('t001UserUpdatedBy.userId').setValue(this.commonService.loggedInUserObj.userId);
		}

		var toEmailListStr = "";
	    for(var loop=0; loop < this.toEmailIDList.length; loop++){
	       toEmailListStr = toEmailListStr + this.toEmailIDList[loop]+",";
	    }
	    if(toEmailListStr != "")
	      toEmailListStr = toEmailListStr.substring(0,toEmailListStr.length-1);
	    this.myAutoEmailForm.get('toEmail').setValue(toEmailListStr);

		var ccEmailListStr = "";
	    for(var loop=0; loop < this.ccEmailIDList.length; loop++){
	       ccEmailListStr = ccEmailListStr + this.ccEmailIDList[loop]+",";
	    }
	    if(ccEmailListStr != "")
	      ccEmailListStr = ccEmailListStr.substring(0,ccEmailListStr.length-1);
	    this.myAutoEmailForm.get('ccEmail').setValue(ccEmailListStr);

		var bccEmailListStr = "";
	    for(var loop=0; loop < this.bccEmailIDList.length; loop++){
	       bccEmailListStr = bccEmailListStr + this.bccEmailIDList[loop]+",";
	    }
	    if(bccEmailListStr != "")
	      bccEmailListStr = bccEmailListStr.substring(0,bccEmailListStr.length-1);
	    this.myAutoEmailForm.get('bccEmail').setValue(bccEmailListStr);

		this.commonService.showProgressBar();
		
		this.commonService.saveOrUpdateAutomatedEmailDetails(this.myAutoEmailForm.value).subscribe(
			data => {
						this.commonService.hideProgressBar();
						this.commonService.showERPMessage('Automated Email Script saved successfully');
						this.dialogRef.close(true);
				},
			error => {

			});
	}

	closeBtnClick(): void{
		this.dialogRef.close(true);
	}
	conditionChange(value){
		
		let whereLeft1 = this.myAutoEmailForm.get('whereCaseLeft1').value;
  		let whereRight1 = this.myAutoEmailForm.get('whereCaseRight1').value;
  		let condition1 = this.myAutoEmailForm.get('condition1').value;
		
		this.whereConditionStr =  ' T200.'+ whereLeft1+ ' '+ condition1+' T203.'+whereRight1;
		this.fetchConditionStr = whereLeft1 +' '+condition1+' '+whereRight1;
	}
	
	change()
  	{
  		 
      let selectColumnList1: string[] = this.myAutoEmailForm.get('t200ItemColList').value;
      let selectColumnList2: string[] = this.myAutoEmailForm.get('t203InventoryColList').value;
      let selectedColumnList3: string[] = this.myAutoEmailForm.get('t825LeaveHistoryColList').value;


	  let columnHeader = '' ;
      let t200ColumnTEXT = '';
      

      for(var loop=0; loop<selectColumnList1.length;loop++){
      	t200ColumnTEXT = t200ColumnTEXT + 'T200.'+ selectColumnList1[loop] +','; 
      	columnHeader = columnHeader + selectColumnList1[loop]+',';
      }

	  t200ColumnTEXT = t200ColumnTEXT.substring(0,t200ColumnTEXT.length-1);

	 
      let t203ColumnTEXT = '';
     // let t203SUMColumnTEXT = '';
      let t203TempSumColumnText = '';
      
       for(var loop=0; loop<selectColumnList2.length;loop++){

      	//t203SUMColumnTEXT = t203SUMColumnTEXT + 'SUM(t203Inventory.'+ selectColumnList2[loop] + ') AS '+  selectColumnList2[loop] +','; 
      	t203ColumnTEXT = t203ColumnTEXT + 'T203.'+ selectColumnList2[loop]  +','; 
      	columnHeader = columnHeader + selectColumnList2[loop] +',';
      }
	 // t203SUMColumnTEXT = t203SUMColumnTEXT.substring(0,t203SUMColumnTEXT.length-1);
	  t203ColumnTEXT = t203ColumnTEXT.substring(0,t203ColumnTEXT.length-1);
	  

		/****Leave History ****/
	  let t825ColumnTEXT = '';
       for(var loop=0; loop<selectedColumnList3.length;loop++){
      	t825ColumnTEXT = t825ColumnTEXT + 'T825.'+ selectedColumnList3[loop] +','; 
      	columnHeader = columnHeader + selectedColumnList3[loop]+',';

      	
      }
      t825ColumnTEXT = t825ColumnTEXT.substring(0,t825ColumnTEXT.length-1);
      

	  columnHeader = columnHeader.substring(0,columnHeader.length-1);

	   	let t200TableStr = ' T200_Item T200 ';
	  	let t203TableStr = ' T203_Inventory T203 ';
		let t203TempTableStr = ' (SELECT  SUM(t203Inventory.OnHandQuantity) AS OnHandQuantity ,SUM(t203Inventory.TobeReceivedQuantity) AS TobeReceivedQuantity ,SUM(t203Inventory.TobeShippedQuantity) AS TobeShippedQuantity ,SUM(t203Inventory.SalesOrderQty) AS SalesOrderQty ,t203Inventory.ItemId  AS ItemId FROM T203_Inventory t203Inventory GROUP BY  t203Inventory.ItemId ) AS T203'; 
      	let onCondition = ' ON T200.ItemId = T203.ItemId'; 
      	let whereText = ' WHERE ';
      	let finalQueryStr = '';
      	let t825TableStr = ' T825_LeaveHistory T825 ';

		if(this.autoEmailModuleName === 'Leave History' &&  this.commonUtils.checkNullOrUndefined(t825ColumnTEXT) )
      	{
      		finalQueryStr = 'SELECT ' + t825ColumnTEXT + ' FROM'+ t825TableStr;
      		
      	
      	}

      	if(this.autoEmailModuleName === 'Leave History' && this.commonUtils.checkNullOrUndefined(this.whereConditionStr) && this.commonUtils.checkNullOrUndefined(t825ColumnTEXT)){

	     		 finalQueryStr = 'SELECT ' +  t825ColumnTEXT + ' FROM '+t825TableStr +  whereText + this.whereConditionStr;
	     		 
	    }

      	if(!this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) )
      	{
      		finalQueryStr = 'SELECT ' + t200ColumnTEXT + ' FROM'+ t200TableStr;
      	
      	}
      	if(this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) )
      	{
      		finalQueryStr = 'SELECT ' +  t200ColumnTEXT + ' , '+ t203ColumnTEXT + ' FROM '+t200TableStr + ' JOIN '+ t203TempTableStr + onCondition ;
      	
      	}
      	if(this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && !this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) )
      	{
      		finalQueryStr = 'SELECT ' +  t203ColumnTEXT + ' FROM '+t203TableStr;
      		
      	}
		if(this.commonUtils.checkNullOrUndefined(this.whereConditionStr) && !this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) ){

	     		 finalQueryStr = 'SELECT ' +  t200ColumnTEXT + ' FROM '+t200TableStr +  whereText + this.whereConditionStr;
	    }
	    if(this.commonUtils.checkNullOrUndefined(this.whereConditionStr) && this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && !this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) ){

	     		 finalQueryStr = 'SELECT ' +  t203ColumnTEXT + ' FROM '+t203TableStr +  whereText + this.whereConditionStr;
	    }
	    if(this.commonUtils.checkNullOrUndefined(this.whereConditionStr) && this.commonUtils.checkNullOrUndefined(t203ColumnTEXT) && this.commonUtils.checkNullOrUndefined(t200ColumnTEXT) ){

	     		 finalQueryStr = 'SELECT ' +  t200ColumnTEXT + ' , '+  t203ColumnTEXT + ' FROM '+t200TableStr + ' JOIN '+ t203TempTableStr + onCondition +  whereText + this.whereConditionStr;
	     		//finalQueryStr = 'SELECT ' +  t200ColumnTEXT + ' , '+  t203ColumnTEXT + ' FROM T200_Item T200 JOIN (SELECT SUM(t203Inventory.OnHandQuantity) AS OnHandQuantity,t203Inventory.ItemId AS ItemId FROM T203_Inventory t203Inventory GROUP BY  t203Inventory.ItemId ) AS T203 ON T200.ItemId  = T203.ItemId JOIN (SELECT T304.ItemId AS ItemId,SUM(T304.Quantity) AS SO_Quantity  FROM T303_SalesOrder_Header T303 JOIN T304_SalesOrder_Details T304 ON T303.SOHeaderId = T304.SOHeaderId WHERE T303.SOStatusId = (SELECT Lookup_Id FROM T004_Lookup WHERE Lookup_Code = "SO_STATUS" AND Lookup_Value = "OPEN") GROUP BY T304.ItemId) SO ON T203.ItemId  = SO.ItemId WHERE T200.ReorderLevel > (T203.OnHandQuantity - SO.SO_Quantity)';
	    }

	 
	  this.myAutoEmailForm.get('columnHeaderString').setValue(columnHeader);
      this.myAutoEmailForm.get('fetchQuery').setValue(finalQueryStr);


  }	

  moduleChange(value){
 	if( value === "Item"){
 		this.myAutoEmailForm.get('fetchCondition').reset();
 		this.myAutoEmailForm.get('columnHeaderString').reset();
 		this.myAutoEmailForm.get('emailSubject').reset();
 		this.myAutoEmailForm.get('emailBodyText').reset();
 		this.toEmailIDList=[];
 		this.ccEmailIDList=[];
 		this.bccEmailIDList=[];
 		
  		this.autoEmailModuleName = this.moduleList[0];
  		this.validationMethod();
 	}
  	
  	else{
  		this.myAutoEmailForm.get('fetchCondition').reset();
 		this.myAutoEmailForm.get('columnHeaderString').reset();
 		this.myAutoEmailForm.get('emailSubject').reset();
 		this.myAutoEmailForm.get('emailBodyText').reset();
 		this.toEmailIDList=[];
 		this.ccEmailIDList=[];
 		this.bccEmailIDList=[];

  		this.autoEmailModuleName = this.moduleList[1];
  		this.validationMethod();
  	}

  }

  addToEmail(event: MatChipInputEvent, mailFor : String): void {
    const input = event.input;
    const value = event.value;
  if(mailFor === 'TO'){
      if ((value || '').trim()) {
        this.toEmailIDList.push(value.trim());
      }
  } 
  if (input) {
      input.value = '';
  }
}


removeToEmail(email: any, mailFor : String): void {
  
 if(mailFor === 'TO'){
   const index = this.toEmailIDList.indexOf(email);
    if (index >= 0) {
      this.toEmailIDList.splice(index, 1);
    }
  } 

}
addCcEmail(event: MatChipInputEvent, mailFor : String): void {
    const input = event.input;
    const value = event.value;
  if(mailFor === 'TO'){
      if ((value || '').trim()) {
        this.ccEmailIDList.push(value.trim());
      }
  } 
  if (input) {
      input.value = '';
  }
}


removeCcEmail(email: any, mailFor : String): void {
  
 if(mailFor === 'TO'){
   const index = this.ccEmailIDList.indexOf(email);
    if (index >= 0) {
      this.ccEmailIDList.splice(index, 1);
    }
  } 

}
addBccEmail(event: MatChipInputEvent, mailFor : String): void {
    const input = event.input;
    const value = event.value;
  if(mailFor === 'TO'){
      if ((value || '').trim()) {
        this.bccEmailIDList.push(value.trim());
      }
  } 
  if (input) {
      input.value = '';
  }
}


removeBccEmail(email: any, mailFor : String): void {
  
 if(mailFor === 'TO'){
   const index = this.bccEmailIDList.indexOf(email);
    if (index >= 0) {
      this.bccEmailIDList.splice(index, 1);
    }
  } 

}

   validationMethod(): void{ 
     let promise=this.commonService.fetchAndSetValidationData(this.moduleName,this.myAutoEmailForm);
     promise.then(data=>
        {
          this.validationListForSelectedModule=data;
          this.setValidationForPage(this.myAutoEmailForm,data);
         
        }
     ); 
   }
changeAutomatedEmailFlag(): any{
	let itemOrInventoryType=this.myAutoEmailForm.get('itemOrInventoryFlag').value;
   
    if(itemOrInventoryType==='item' || itemOrInventoryType==='inventory' || itemOrInventoryType==='both'){
   	this.myAutoEmailForm.get('fetchCondition').patchValue('');
   	this.myAutoEmailForm.get('whereCaseLeft1').setValue('');
	this.myAutoEmailForm.get('whereCaseRight1').setValue('');
	this.myAutoEmailForm.get('whereCaseLeft2').setValue('');
	this.myAutoEmailForm.get('whereCaseRight2').setValue('');
	this.myAutoEmailForm.get('condition1').setValue('');
	this.myAutoEmailForm.get('t200ItemColList').setValue('');
	this.myAutoEmailForm.get('t203InventoryColList').setValue('');
	this.myAutoEmailForm.get('columnHeaderString').setValue('');
 	this.fetchConditionList =[];
 	this.whereConditionList =[];
 	this.whereConditionStr ='';
    }

	this.setAutomatedEmailGridStyle();
}
setAutomatedEmailGridStyle()
{
    let itemOrInventoryType=this.myAutoEmailForm.get('itemOrInventoryFlag').value;
   
    if(itemOrInventoryType==='item'){
    	this.showItemCol=true;
  	 	this.showInventoryCol=false;
        this.showTextCol=true;
     
    }
    else if(itemOrInventoryType==='inventory'){
     	this.showItemCol=false;
  	 	this.showInventoryCol=true;
        this.showTextCol=true;
    }
    else if(itemOrInventoryType==='both'){
      	this.showItemCol=true;
  	 	this.showInventoryCol=true;
        this.showTextCol=false;
    }
}

addCondition(): void {

		let whereLeft1 = this.myAutoEmailForm.get('whereCaseLeft1').value;
  		let whereRight1 = this.myAutoEmailForm.get('whereCaseRight1').value;
  		let whereRight2 =this.myAutoEmailForm.get('whereCaseRight2').value;
  		let whereLeft2 = this.myAutoEmailForm.get('whereCaseLeft2').value;
  		let condition1 = this.myAutoEmailForm.get('condition1').value;
  		let t200TableName='T200.';
   		let t203TableName='T203.';
   		let t825TableName='T825.';
   		let whereCondition='';
  		let fetchCondition='';
  		let whereRightCase2='';
  		let dataTypeItemStr=this.t200ItemColumnMap.get(whereLeft1);
  		let dataTypeInventoryStr=this.t203InventoryColumnMap.get(whereLeft2);
  		let dataTypeLeaveHistoryStr=this.t825LeaveHistoryColumnMap.get(whereLeft1);
  		this.whereConditionStr='';


  		if( this.autoEmailModuleName === 'Leave History'){
  			if( this.commonUtils.checkNullOrUndefined(whereLeft1) &&  this.commonUtils.checkNullOrUndefined(whereRight2) && this.commonUtils.checkNullOrUndefined(condition1)){
  			if(dataTypeLeaveHistoryStr === 'varchar')
			{
				whereRightCase2='"'+this.myAutoEmailForm.get('whereCaseRight2').value +'"';
			}
			else
			{
				whereRightCase2=this.myAutoEmailForm.get('whereCaseRight2').value;
			}
  			this.whereLeftStr=whereLeft1;
  			this.whereRightStr=whereRightCase2;
  			this.conditionStr=condition1;
  			whereCondition= t825TableName + this.whereLeftStr +this.conditionStr +this.whereRightStr;
  			
  		}
  		}
  		else{
  			 if(this.commonUtils.checkNullOrUndefined(whereLeft1) && this.commonUtils.checkNullOrUndefined(condition1) && this.commonUtils.checkNullOrUndefined(whereRight1))
  		{
  			this.whereLeftStr=whereLeft1;
  			this.whereRightStr=whereRight1;
  			this.conditionStr=condition1;
  			whereCondition= t200TableName + this.whereLeftStr +this.conditionStr+ t203TableName +this.whereRightStr;
  		}
  		else if(this.commonUtils.checkNullOrUndefined(whereLeft1) && this.commonUtils.checkNullOrUndefined(condition1) && this.commonUtils.checkNullOrUndefined(whereRight2))
  		{
  			
	  		if(dataTypeItemStr === 'varchar')
			{
				whereRightCase2='"'+this.myAutoEmailForm.get('whereCaseRight2').value +'"';
			}
			else
			{
				whereRightCase2=this.myAutoEmailForm.get('whereCaseRight2').value;
			}
  			this.whereLeftStr=whereLeft1;
  			this.whereRightStr=whereRightCase2;
  			this.conditionStr=condition1;
  			whereCondition= t200TableName + this.whereLeftStr +this.conditionStr+' '+this.whereRightStr;
  		}
  		else if(this.commonUtils.checkNullOrUndefined(whereLeft2) && this.commonUtils.checkNullOrUndefined(condition1) && this.commonUtils.checkNullOrUndefined(whereRight2))
  		{
  			
  			if(dataTypeInventoryStr === 'varchar')
			{
				whereRightCase2='"'+this.myAutoEmailForm.get('whereCaseRight2').value +'"';
			}
			else
			{
				whereRightCase2=this.myAutoEmailForm.get('whereCaseRight2').value;
			}
  			this.whereLeftStr=whereLeft2;
  			this.whereRightStr=whereRightCase2;
  			this.conditionStr=condition1;
  			whereCondition= t203TableName + this.whereLeftStr +this.conditionStr+' '+this.whereRightStr;
  			
  		}
  		
  		}

		

		 this.whereConditionList.push(whereCondition);
		this.fetchConditionStr = this.whereLeftStr +' '+this.conditionStr+' '+this.whereRightStr;
		this.fetchConditionList.push(this.fetchConditionStr);
		for(var loop=0; loop<this.fetchConditionList.length;loop++){
      		fetchCondition = fetchCondition + this.fetchConditionList[loop] + ' AND '; 
      	}
		fetchCondition = fetchCondition.substring(0,fetchCondition.length-4);

		 for(var loop=0; loop<this.whereConditionList.length;loop++){
      		this.whereConditionStr = this.whereConditionStr + this.whereConditionList[loop] + ' AND '; 
      	}
 		this.whereConditionStr = this.whereConditionStr.substring(0,this.whereConditionStr.length-4);
		this.myAutoEmailForm.get('fetchCondition').setValue(fetchCondition);
		

	 	this.myAutoEmailForm.get('whereCaseLeft1').setValue('');
	    this.myAutoEmailForm.get('whereCaseRight1').setValue('');
	    this.myAutoEmailForm.get('whereCaseLeft2').setValue('');
	    this.myAutoEmailForm.get('whereCaseRight2').setValue('');
	    this.myAutoEmailForm.get('condition1').setValue('');
	   
		this.change();		
 }
 clearCondition(): void
 {
 	this.myAutoEmailForm.get('fetchCondition').reset();
 	this.fetchConditionList =[];
 	this.whereConditionList =[];

 }

}
