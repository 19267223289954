import { DIR_DOCUMENT } from "@angular/cdk/bidi";

export const environment = {
production: false,
//SERVICE_URL: 'http://localhost:9000/',
SERVICE_PORT:80,

APP_URL: 'http://localhost:4200/',
LATEST_RATES_URL: 'https://data.fixer.io/api/latest?access_key=21d2a2b6b6875a6162ec4b8671bc88f8&symbols=',
IP_FETCH_URL: 'https://api.ipify.org/?format=json',
EDITOR_KEY:"dC8A5A4D3A-9H3E2I2C2C6C3A1B5B1E1phA-21nB-13taD1B-8H-8==",
VIEW_USER:'VIEW_USER',
NEW_USER:'NEW_USER',
VIEW_ROLE:'VIEW_ROLE',
//NEW_ROLE:'NEW_USER',
VIEW_ISSUES:'VIEW_ISSUES',
VIEW_TICKETS:'VIEW_TICKETS',
VIEW_ITEM: 'VIEW_ITEM',
VIEW_ITEMS: 'VIEW_ITEMS',
NEW_ITEM:'NEW_ITEM',
NEW_ITEMS:'NEW_ITEMS',
NEW_ITEM_AG: 'Y',
VIEW_CONTACT: 'VIEW_CONTACT',
NEW_CONTACT: 'NEW_CONTACT',
NEW_SALES_PERSON: 'NEW_SALES_PERSON',
VIEW_SALES_PERSON: 'VIEW_SALES_PERSON',
NEW_CONTACT_AG: 'Y',
MANAGE_ARTWORK: 'MANAGE_ARTWORK',
VIEW_ARTWORK_REQUEST: 'VIEW_ARTWORK_REQUEST',
MANAGE_GRAPHICS_ARTWORK_REQUEST: 'MANAGE_GRAPHICS_ARTWORK_REQUEST',
NEW_ARTWORK_REQUEST: 'NEW_ARTWORK_REQUEST',
MANUAL_LEAVE_CREDITS: 'MANUAL_LEAVE_CREDITS',
CREATE_DELIVERY_CHALLAN_RETURN:'CREATE_DELIVERY_CHALLAN_RETURN',
VIEW_DELIVERY_CHALLAN_RETURN:'VIEW_DELIVERY_CHALLAN_RETURN',
NEW_DELIVERY_CHALLAN_RETURN:'New Return',
VIEW_GENERAL_SETTINGS: 'VIEW_GENERAL_SETTINGS',
NEW_GENERAL_SETTINGS: 'NEW_GENERAL_SETTINGS',
VIEW_ORGANIZATION: 'VIEW_ORGANIZATION',
NEW_ORGANIZATION: 'NEW_ORGANIZATION',
VIEW_FP_SETTINGS: 'VIEW_FP_SETTINGS',
NEW_FP_SETTINGS: 'NEW_FP_SETTINGS',
NEW_CART_INTEGRATION: 'NEW_CART_INTEGRATION',
CREATE_ORGANIZATION:'CREATE_ORGANISATION_PROFILE',
VIEW_INVOICE_DETAILS:'INVOICE_DETAILS',
NEW_PO: 'NEW_PO',
VIEW_PO: 'VIEW_PO',
VIEW_PO_LABEL: 'Review Purchase Order',
NEW_PO_AG: 'Y',
NEW_SO_AG: 'Y',
NEW_PO_D_LABEL: 'Purchase Order',
NEW_INDENT_D_LABEL: 'Indent',
NEW_STOCK_REQUEST_D_LABEL:'Stock Request',
NEW_BILL: 'NEW_BILL',
VIEW_BILL: 'VIEW_BILL',
NEW_REFUND_RECEIPT: 'NEW_REFUND_RECEIPT',
VIEW_REFUND_RECEIPT: 'VIEW_REFUND_RECEIPT',
CONVERT_TO_BILL: 'CONVERT_TO_BILL',
NEW_INDENT:'NEW_INDENT',
VIEW_INDENT: 'VIEW_INDENT',
NEW_STOCK_REQUEST: 'NEW_STOCK_REQUEST',
NEW_APPROVE_STOCK_REQUEST: 'NEW_APPROVE_STOCK_REQUEST',
NEW_PRODUCTION_SLIP:'NEW_PRODUCTION_SLIP',
VIEW_PRODUCTION_SLIP: 'VIEW_PRODUCTION_SLIP',
NEW_R_BILL: 'NEW_R_BILL',
VIEW_R_BILL: 'VIEW_R_BILL',
NEW_EXPENSE: 'NEW_EXPENSE',
VIEW_EXPENSE: 'VIEW_EXPENSE',
NEW_R_EXPENSE: 'NEW_R_EXPENSE',
VIEW_R_EXPENSE: 'VIEW_R_EXPENSE',
NEW_PAYMENT_MADE: 'NEW_PAYMENT_MADE',
VIEW_PAYMENT_MADE: 'VIEW_PAYMENT_MADE',
VIEW_PAYMENT_MADE_FOR_BILL:'VIEW_PAYMENT_MADE_FOR_BILL',
NEW_VENDOR_CREDIT: 'NEW_VENDOR_CREDIT',
VIEW_VENDOR_CREDIT: 'VIEW_VENDOR_CREDIT',
NEW_VENDOR_CREDIT_AG: 'Y',
NEW_VENDOR_CREDIT_D_LABEL: 'Vendor Credit',
NEW_ARTWORK: 'NEW_ARTWORK',
VIEW_ARTWORK: 'VIEW_ARTWORK',
NEW_ARTWORK_D_LABEL: 'Artwork',
NEW_ESTIMATE: 'NEW_ESTIMATE',
VIEW_ESTIMATE: 'VIEW_ESTIMATE',
NEW_ESTIMATE_AG: 'Y',
NEW_ESTIMATE_D_LABEL: 'Estimate',
NEW_ARTWORK_REQUEST_D_LABEL: 'Artwork Request',
NEW_SALES_RECEIPT_D_LABEL: 'Sales Receipt',
NEW_SALESORDER: 'NEW_SALESORDER',
VIEW_SALESORDER: 'VIEW_SALESORDER',
NEW_SALESORDER_AG: 'Y',
NEW_SALESORDER_D_LABEL: 'Sales Order',
NEW_PACKAGE: 'NEW_PACKAGE',
NEW_PACKAGE_AG: 'Y',
NEW_PRODUCTION_SLIP_AG: 'Y',
NEW_INVOICE: 'NEW_INVOICE',
NEW_SALES_RECEIPT : 'NEW_SALES_RECEIPT',
VIEW_INVOICE: 'VIEW_INVOICE',
VIEW_SALES_RECEIPT : 'VIEW_SALES_RECEIPT',
NEW_INVOICE_AG: 'Y',
NEW_INVOICE_D_LABEL: 'Invoice',
NEW_R_INVOICE: 'NEW_R_INVOICE',
VIEW_R_INVOICE: 'VIEW_R_INVOICE',
NEW_PAYMENT_RECIEVED: 'NEW_PAYMENT_RECIEVED',
CREATE_CLIENT_INVOICE : 'Create Client Invoice',
NEW_CLIENT_INVOICE: 'NEW_CLIENT_INVOICE',
VIEW_PAYMENT_RECIEVED: 'VIEW_PAYMENT_RECIEVED',
VIEW_CLIENT_INVOICE: 'VIEW_CLIENT_INVOICE',
VIEW_JOURNAL_SCREEN:'VIEW_JOURNAL',
NEW_CREDIT_NOTES: 'NEW_CREDIT_NOTES',
VIEW_CREDIT_NOTES: 'VIEW_CREDIT_NOTES',
NEW_CREDIT_NOTES_AG: 'Y',
NEW_CREDIT_NOTES_D_LABEL: 'Credit Notes',
NEW_EMPLOYEE: 'NEW_EMPLOYEE',
NEW_RESIGNATION_EMAIL: 'RESIGNATION_EMAIL',
VIEW_EMPLOYEE: 'VIEW_EMPLOYEE',
NEW_EMPLOYEE_AG: 'Y',
VIEW_PAYCODE: 'VIEW_PAYCODE',
CREATE_EMPLOYEE_TASK:'CREATE_EMPLOYEE_TASK',
VIEW_LOAN: 'VIEW_LOAN',
MY_LOAN:'MY_LOAN',
VIEW_PACKAGES: 'VIEW_PACKAGES',
REVIEW_ROLE :'REVIEW_ROLE',
NEW_RESERVATION:'NEW_RESERVATION',
NEW_RESERVATION_D_LABEL:'Reservation',
VIEW_RESERVATION:'VIEW_RESERVATION',
MANAGE_FEEDBACK:'MANAGE_FEEDBACK_MYCORNER',
FEEDBACK_REPORT:'FEEDBACK_REPORT',
APPRAISAL_REPORT:'APPRAISAL_REPORT',
APPRAISAL_DETAIL_REPORT:'APPRAISAL_DETAIL_REPORT',
NEW_FEEDBACK:'NEW_FEEDBACK_MYCORNER',
ARTWORK_PROOF_PRODUCT_FOLDER : 'PRODUCT_IMAGES',
ARTWORK_PROOF_DECO_FOLDER : 'DECO_IMAGES',
ARTWORK_PROOF_VERSION_FOLDER : 'VERSION_IMAGES',
VIEW_WAREHOUSE:'VIEW_WAREHOUSE',
NEW_WAREHOUSE:'NEW_WAREHOUSE',
NEW_PAYCODE: 'NEW_PAYCODE',
VIEW_DECLARATION :'VIEW_DECLARATION',
VIEW_EMPLOYEEEARNINGS : 'VIEW_EMPLOYEEEARNINGS',
VIEW_PAYABLEDAYS:'VIEW_PAYABLEDAYS',
VIEW_PAYROLLPROCESS:'VIEW_PAYROLLPROCESS',
VIEW_PAYROLLREPORTS:'VIEW_PAYROLLREPORTS',
VIEW_PAYROLLSETTINGS:'VIEW_PAYROLLSETTINGS',
VIEW_ASSETS:'VIEW_ASSETS',
NEW_ASSETS:'NEW_ASSETS',
VIEW_ITEMS_GROUP:'VIEW_ITEMS_GROUP',
NEW_ITEMS_GROUP:'NEW_ITEMS_GROUP',
NEW_PO_RECEIVE:'NEW_PO_RECEIVE',
NEW_SHIPPING:'NEW_SHIPPING',
NEW_SHIPPING_AG:'Y',
SHIPMENT_ORDER_FOLDER: 'SHIPMENT_ORDER',
NEW_JOURNAL:'NEW_JOURNAL',
VIEW_JOURNAL:'JOURNAL_REPORT',
VIEW_JOURNAL_D:'Journal',
NEW_APPRAISAL_MASTER:'NEW_APPRAISAL_MASTER',
VIEW_APPRAISAL_MASTER:'VIEW_APPRAISAL_MASTER',
VIEW_ANNOUNCEMENTS:'VIEW_ANNOUNCEMENTS',
VIEW_EMPLOYEE_APPRAISAL_LEVEL:"VIEW_EMPLOYEE_APPRAISAL_LEVEL",
VIEW_APPRAISAL_FORM:"VIEW_APPRAISAL_FORM",
APPLY_APPRAISAL_FORM:"APPLY_APPRAISAL_FORM",
NEW_SO_RECEIVE:'NEW_SO_RECEIVE',
//Accounts
VIEW_CHART_OF_ACCOUNTS: 'CHART_OF_ACCOUNTS',
NEW_ACCOUNT: 'NEW_ACCOUNT',
VIEW_RECONCILIATION: 'VIEW_RECONCILIATION',
NEW_RECONCILIATION: 'NEW_RECONCILIATION',
NEW_ACCOUNT_BUDGET: 'NEW_ACCOUNT_BUDGET',
VIEW_ACCOUNT_BUDGET: 'VIEW_ACCOUNT_BUDGET',

//RECRUITMENT
VIEW_JOB: 'VIEW_JOB',
NEW_JOB:'NEW_JOB',


VIEW_INTERVIEW_SLOT: 'VIEW_INTERVIEW_SLOT',
NEW_INTERVIEW_SLOT: 'NEW_INTERVIEW_SLOT',

//PROJECT
VIEW_PROJECT:'VIEW_PROJECT',
NEW_PROJECT:'NEW_PROJECT',
PROJECT_PLAN:'PROJECT_PLAN',
CREATE_TASK:'CREATE_TASK',
CREATE_HRIS_TIMESHEET:'CREATE_HRIS_TIMESHEET',
NEW_TASK_LABEL:'Task',
VIEW_TIMESHEET:'VIEW_TIMESHEET',
TIMESHEET_HISTORY :'TIMESHEET_HISTORY',
HRIS_TIMESHEET_HISTORY :'HRIS_TIMESHEET_HISTORY',
VIEW_HRIS_TIMESHEET: 'VIEW_HRIS_TIMESHEET',
ADD_RESOURCE_EXTENSION:"ADD_RESOURCE_EXTENSION",

PROJECT_PLAN_CLIENT:'PROJECT_PLAN_CLIENT',
CREATE_NEW_TASK:'CREATE_NEW_TASK',
//MY CORNER
APPLY_LEAVE: 'APPLY_LEAVE',
APPROVE_LEAVE: 'APPROVE_LEAVE',
UNAPPROVED_LEAVES: 'UNAPPROVED_LEAVES',
PAYCODE_LOAN: 'PAYCODE_LOAN',
MEDICAL_BILL: 'MEDICAL_BILL',
DECLARATION: 'DECLARATION',
MY_LEAVE: 'MY_LEAVE',
VIEW_LEAVE: 'VIEW_LEAVE',
REVIEW_MEDICAL_BILL: 'REVIEW_MEDICAL_BILL',
//Report Summary
VIEW_SALESBYSALESPERSON:'SALES_BY_SALESPERSON_SUMMARY',
VIEW_SALESBYSALESPERSON_D:'Sales By Sales Person ',
VIEW_SALESBYCUSTOMER:'SALES_BY_CUSTOMER_SUMMARY',
VIEW_SALESBYCUSTOMER_D:'Sales By Customer',
VIEW_SALESBYCUSTOMERGROUP:'SALES_BY_CUSTOMER_GROUP_SUMMARY',
VIEW_SALESBYCUSTOMERGROUP_D:'Sales By Customer Group',
VIEW_PURCHASEDETAILBYVENDR:'PURCHASE_DETAILS_FOR_VENDOR_SUMMARY',
VIEW_SALESBYITEM:'SALES_BY_ITEM_SUMMARY',
VIEW_SALESBYITEM_D:'Sales By Item',
VIEW_EXPENSEDETAIL:'EXPENSE_DETAILS_SUMMARY',
PURCHASES_BY_VENDOR_SUMMARY:'PURCHASES_BY_VENDOR_SUMMARY',
PURCHASE_DETAILS_VENDOR_D:'Purchase details for vendor',
PURCHASES_BY_ITEM_SUMMARY:'PURCHASES_BY_ITEM_SUMMARY',
VIEW_ACCTRANSACTION: 'ACCOUNT_TRANSACTION',
VIEW_ACCTRANSACTION_D: 'Account Transaction',
VIEW_EXPENSES_BY_VENDOR:'EXPENSES_BY_VENDOR_SUMMARY',
VIEW_GENERALLEDGER: 'GENERAL_LEDGER',
VIEW_GENERALLEDGER_D: 'General Ledger',
VIEW_TRIALBALANCE: 'TRIAL_BALANCE',
VIEW_TRIALBALANCE_D:'Trial Balance',
VIEW_PAYABLE_AGING:'PAYABLE_AGING',
VIEW_AGING_DETAILS_D:'Aging Details',
VIEW_RECEIVABLE_AGING:'RECEIVABLE_AGING',
VIEW_RECEIVABLE_AGING_SUMMARY: 'RECEIVABLE_AGING_SUMMARY',
VIEW_PAYABLE_AGING_SUMMARY: 'PAYABLE_AGING_SUMMARY',
VIEW_AGING_SUMMARY_D:'Aging Summary',
VIEW_CUSTOMER_BALANCES: 'CUSTOMER_BALANCES',
VIEW_CUSTOMER_BALANCES_D:'Customer Balance',
VIEW_ESTIMATE_DETAILS: 'ESTIMATE_DETAILS',
VIEW_ESTIMATE_DETAILS_D:'Sales Estimate Details',
VIEW_EXPENSEDETAIL_D:'Expense Details',
VIEW_INVOICE_DETAILS_D:'Invoice Details',
VIEW_SALESORDER_DETAILS: 'SALESORDER_DETAILS',
VIEW_EXPENSES_BY_VENDOR_D:'Expense By Vendor',
VIEW_SALESORDER_DETAILS_D:'Sales Order Details',
VIEW_ERROR_LOG: 'ERROR_LOG',
VIEW_ERROR_LOG_D:'Error Log',
VIEW_PO_HISTORY: 'PURCHASE_ORDER_HISTORY',
VIEW_PO_RECEIVE_HISTORY: 'PURCHASE_ORDER_RECEIVE_HISTORY',
VIEW_PO_RECEIVE_HISTORY_D: 'Receive History',
SO_PRODUCTIONSLIP: 'PRODUCTION_SLIP',
VIEW_CATEGORY:'VIEW_CATEGORY',
NEW_CATEGORY:'NEW_CATEGORY',
USE_CREDITS : 'USE CREDITS',
NEW_PRICE_LIST: 'NEW_PRICE_LIST',
VIEW_PRICE_LIST: 'VIEW_PRICE_LIST',
VIEW_LICENSE:'VIEW_LICENSE',
NEW_LICENSE:'NEW_LICENSE',
NEW_UPS:'NEW_UPS',
NEW_ISSUES:'NEW_ISSUES',
VIEW_ACTIVITYLOG: 'ACTIVITY_LOG',
VIEW_ACTIVITYLOG_D: 'Activity Log',
VIEW_BATCH_SYNC:'VIEW_BATCH_SYNC',
VIEW_FEEDBACK:'VIEW_FEEDBACK',
VIEW_MENU: 'VIEW_MENU',
NEW_MENU: 'NEW_MENU',
VIEW_REVIEW_DISPLAY_COLUMN: 'VIEW_REVIEW_DISPLAY_COLUMN',
NEW_REVIEW_DISPLAY_COLUMN: 'NEW_REVIEW_DISPLAY_COLUMN',
CREATE_TEMPLATE_SETTINGS:'CREATE_TEMPLATE_SETTINGS',
VIEW_STOCK_REQUEST:'VIEW_STOCK_REQUEST',
VIEW_APPROVE_STOCK_REQUEST:'VIEW_APPROVE_STOCK_REQUEST',
CREATE_STOCK_TRANSFER_REQUEST:'Stock Transfer Request',
VIEW_INVENTORY_ADJUSTMENT:'VIEW_INVENTORY_ADJUSTMENT',
VIEW_DATABASE:'VIEW_DATABASE',
EMAIL_CONFIGURATION:'EMAIL_CONFIGURATION',
INVENTORY_DETAIL_REPORT:'INVENTORY_DETAIL_REPORT',
INVENTORY_SUMMARY:'INVENTORY_SUMMARY',
LEAVE_HISTORY:'LEAVE_HISTORY',
ATTENDANCE_HISTORY:'ATTENDANCE_HISTORY',
STUDENT_ORDER:'STUDENT_ORDER',
BULK_ORDER:'BULK_ORDER',
SERVICE_ORDER:'SERVICE_ORDER',
VIEW_AUTOMATED_EMAIL: "VIEW_AUTOMATED_EMAIL",
VIEW_TCLINKMASTER:'VIEW_TCLINKMASTER',
NEW_TCLINKMASTER:'NEW_TCLINKMASTER',
VIEW_RESOURCE_EXTENSION:"VIEW_RESOURCE_EXTENSION",
PART_REQUEST:'PART_REQUEST',
INVENTORY_AGING_DETAILS:'INVENTORY_AGING_DETAILS',
INVENTORY_TALLY_REPORT:'INVENTORY_TALLY_REPORT',
VIEW_VALIDATION:'VIEW_VALIDATION',
QB_NOTSYNCHED_INVOICE:'QB_NOTSYNCHED_INVOICE',
BILLING_REPORT :'BILLING_REPORT',
RESOURCE_WISE_PROJECT_ALLOCATION_REPORT: 'RESOURCE_WISE_PROJECT_ALLOCATION_REPORT',
PROJECT_WISE_RESOURCE_ALLOCATION_REPORT: 'PROJECT_WISE_RESOURCE_ALLOCATION_REPORT',
WAREHOUSE_REPORT_SUMMARY: 'WAREHOUSE_REPORT_SUMMARY',
EMAIL_SETTINGS_CONFIGURATION:'EMAIL_SETTINGS_CONFIGURATION',
//date format
//DATE_FORMAT: 'dd,MMM yyyy', //commmented by 9168 for date format bug
DATE_FORMAT:'dd/MM/yy',
TEMP_FOLDER: 'erpassets/Temp_Images/',
IMPORT_FOLDER: 'erpassets/template/import_template_files/',
// IMPORT_EMPLOYEE_TEMPLATE: 'erpassets/template/import_template_files/Import_Employee_Template.csv',
IMPORT_EMPLOYEE_TEMPLATE: 'Import_Employee_Template.csv',
IMPORT_ASSETS_TEMPLATE: 'Import_Assets_Template.csv',
IMPORT_EARNINGS_TEMPLATE: 'Import_Earnings_Template.csv', 

NO_IMAGE_FOR_ORG_PROFILE: 'assets/Placeholder.png',
ESALESUITE_LOGO:'assets/esales_suite_erp.png',
HRIS_LOGO:'assets/hris_logo_image.png',
APPROVED_IMAGE_ICON:'assets/approved_image.jpg',
APPROVE_STOCK_REQUEST:'Approve Stock Request',
VIEW_DISPATCH_PACKAGE:'VIEW_DISPATCH_PACKAGE',
VIEW_DISPATCH_RETURNS:'VIEW_DISPATCH_RETURNS',
PAYMENT_RECEIVED_HEADER_ID:'',
CREATE_RETURNS : 'Create Returns',
NEW_RETURNS : 'NEW_RETURNS',
VIEW_RETURNS : 'VIEW_RETURNS',
VIEW_RECEIVE_RETURNS : 'VIEW_RECEIVE_RETURNS',
// Review PO Receive Screen
VIEW_PO_RECEIVE : 'VIEW_PO_RECEIVE',
DISCARD_LABEL: ' Changes you have made will not be saved. ',
DISCARD_LABEL_MENU: 'Do you want move to your selected menu ? ',
CURRENCY_CONVERSION_MESSAGE:'Currency rate has been changed. do you want to continue?',
VIEW_CREDIT_NOTES_INVOICE:'VIEW_CREDIT_NOTES_INVOICE',
VIEW_VENDOR_CREDIT_BILL: 'VIEW_VENDOR_CREDIT_BILL',
VIEW_SO_RECEIVE: 'VIEW_SO_RECEIVE',
VIEW_SHIPPING: 'VIEW_SHIPPING',
NEW_SHIFT_MASTER:'NEW_SHIFT_MASTER',
VIEW_SHIFT_MASTER:'VIEW_SHIFT_MASTER',
NEW_SHIFT_ALLOCATION:'NEW_SHIFT_ALLOCATION',
VIEW_SHIFT_ALLOCATION:'VIEW_SHIFT_ALLOCATION',
TIMESHEET_REPORT:'TIMESHEET_REPORT',
TASK_REPORT:'TASK_REPORT',
FEDEX_CONFIGURATION: 'FEDEX_CONFIGURATION',
VIEW_CONTACT_JOURNAL:'VIEW_CONTACT_JOURNAL',
//Product Setup screen-NistarMarketing
VIEW_PRODUCT_SETUP: 'VIEW_PRODUCT_SETUP',
NEW_MANAGE_ORDER:'NEW_MANAGE_ORDER',
VIEW_RECEIVINGS:'VIEW_RECEIVINGS',
VIEW_NISTAR_PACKING:'VIEW_NISTAR_PACKING',
//mamabear inventory summary
MAMABEAR_INVENTORY_SUMMARY:'MAMABEAR_INVENTORY_SUMMARY',
// view candidate
VIEW_CANDIDATE: 'VIEW_CANDIDATE',
NEW_CANDIDATE: 'NEW_CANDIDATE',
// view Appointment
VIEW_APPOINTMENT: 'VIEW_APPOINTMENT',
NEW_APPOINTMENT: 'NEW_APPOINTMENT',
//View Designation
VIEW_DESIGNATION: 'VIEW_DESIGNATION'

}