import * as fromTemplateActions from '../actions/createtemplate.actions';

export interface CreateTemplateState{
	data:any[];
	loaded:boolean;
	loading:boolean;
}
export const initialState:CreateTemplateState={
	data:[{invoiceHeaderId:1,invoiceNumber:'PO111'}],
	loaded:false,
	loading:false
}


export function reducer(
	state=initialState,
	action:fromTemplateActions.ViewTemplateAction
):CreateTemplateState{

	switch(action.type){
		case fromTemplateActions.LOAD:{
			return { 
			...state , 
			loading:true
		};
		}

	 	case fromTemplateActions.LOAD_SUCCESS:{
	 		 
			return { 
			data:[] , 
			loading:false,
			loaded:true

			};
		}

		case fromTemplateActions.LOAD_FAIL:{
			return { 
			...state , 
			loading:false,
			loaded:false
		}; 
	}
		case fromTemplateActions.LOAD_VIEW_TEMPLATE_SEARCH_RESULTS:{
			return { 
			...state , 
			loading:false,
			loaded:false
		}; 
	}

		case fromTemplateActions.SHOW_PROGRESS:{
			return { 
			...state , 
			loading:true,
			loaded:false
		}; 
	}

		case fromTemplateActions.HIDE_PROGRESS:{
			return { 
			...state , 
			loading:false,
			loaded:true
		}; 

	}
		 
	}
 


	
	return state;
}


export const viewTemplateDataLoading=(state:CreateTemplateState)=>state.loading;
export const viewTemplateDataLoaded=(state:CreateTemplateState)=>state.loaded;
export const viewTemplateData=(state:CreateTemplateState)=>state.data;