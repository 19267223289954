import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-managelicense',
  templateUrl: './managelicense.component.html' 
})
export class ManagelicenseComponent{

 moduleName: string = environment.VIEW_LICENSE;

}
