import { Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HrmService} from '../hrm-service';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-addloan',
  templateUrl: './create-loan.component.html'

})

export class CreateLoanComponent extends CommonUtils implements OnInit {
salaryList = [];
employeeList = [];
SALARY_GROUP;
EMPLOYEE;
userObj;
element;
myForm: FormGroup;
loanForm: FormGroup;
currentlySelectedMenu;
editAccess = true;
moduleName : string = environment.VIEW_LOAN;
errorFormGroup=new FormGroup({
  errorMessage: new FormControl(''),
  errorStackTrace: new FormControl('')      
});

constructor(private hrmService: HrmService, private commonService: CommonService, private commonUtils: CommonUtils, 
  public dialogRef: MatDialogRef<CreateLoanComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
  super();
  this.element = data.element;
  this.myForm = new FormGroup({
	  status: new FormControl(),
    t818SalaryGroup: new FormGroup({salaryId: new FormControl(0)}),
    t004_LookupObj: new FormGroup({lookupValue: new FormControl('ACTIVE EMPLOYEE')}),
    requestFlag: new FormControl('FetchAll')
  });
  this.loanForm = new FormGroup({
    loanId: new FormControl(0),
    loanAmount: new FormControl(),
    eMIAmount: new FormControl(0),
    loanDate: new FormControl(new Date()),
    amountPaid: new FormControl(0),
    balanceAmount: new FormControl(),
    t800Employee: new FormGroup({employeeId: new FormControl(0),
    t818SalaryGroup: new FormGroup({salaryId: new FormControl(0)})}),
      requestFlag: new FormControl('FetchAll'),
      nextEmi: new FormControl(0),
      t001UserUpdatedBy : new FormGroup({userId:new FormControl()}),
      t001UserCreatedBy : new FormGroup({userId:new FormControl()})
  });
}

  ngOnInit() 
  {

   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
//     this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu;
           this.pageLoad();
         }
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
 this.pageLoad();
}
pageLoad():void
{
  try
  {
    this.SALARY_GROUP = this.commonUtils.SALARY_GROUP;
    this.EMPLOYEE = this.commonUtils.EMPLOYEE;
  	this.fetchSalary();
    if (this.commonUtils.checkNullOrUndefined(this.element)) 
    {
     if (this.element.loanId != 0) {
      if (this.element.t800Employee.t818SalaryGroup.salaryId != 0) {
        this.myForm.get('t818SalaryGroup.salaryId').setValue(this.element.t800Employee.t818SalaryGroup.salaryId);
        this.fetchEmployeeList();
      }
      this.loanForm.patchValue(this.element);
      this.commonUtils.patchDates(this.loanForm,'loanDate',this.element.loanDate);
      this.loanForm.get('amountPaid').setValue(0);
    }
  }
}
          catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{});
} 
}

 cancelClick(): void {
 this.dialogRef.close();
}

salary(salaryObj) {

this.myForm.get('t818SalaryGroup.salaryId').setValue(salaryObj);
 this.fetchEmployeeList();
}

  fetchSalary(): void {
    this.hrmService.fetchSalary().
      subscribe(
       data => {
         this.salaryList = data;
       
       },
        error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  fetchEmployeeList(): void {

    this.hrmService.fetchActiveEmployeeForSalary(this.myForm.value).
      subscribe(
       data => {
         this.employeeList = data;
         
       },
        error => {
        this.commonService.showERPErrorMessage(error);
      });
  }
  saveOrUpdateLoan(): void {
    this.userObj = this.commonService.loggedInUserObj.userId;
      this.loanForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
      this.loanForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);
    
    this.disableFlag=true;
    /*if(this.loanForm.get('eMIAmount').value)*/
  	this.hrmService.saveOrUpdateLoan(this.loanForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
              this.commonService.showERPMessage(data.erpmessage);
         this.dialogRef.close(true);
         this.disableFlag=false;       
      }, error => {
       this.disableFlag=false;
       this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error);
         

      });
  }
 
}
