import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
declare const require: any;
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
   providers: []
})

export class FileUploadComponent extends CommonUtils  {
uploadedFileArray:Array<any>=[];
dialogTextToDisplay:string;
dialogLabel:string='';
dialogResult:any=[];
errorFlag:boolean=false;
imagePath:string;
tempFolderPath: string;
hideDeleteIcon: boolean = false;
fileUploadType: string = "";
deleteStatusFlag: boolean = false;
errorMsg:string;
@ViewChild("myInput1") myInputFileObj1: ElementRef;
@ViewChild("myInput2") myInputFileObj2: ElementRef;
@ViewChild("myInput3") myInputFileObj3: ElementRef;
  constructor( 
     public dialog: MatDialog,
     public dialogRef: MatDialogRef<FileUploadComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,public commonUtils:CommonUtils,
    public commonService:CommonService ) {
      super();
       this.hideDeleteIcon = data.hideDeleteFlag;
       this.dialogLabel=data.dialogLabel;
       
       this.imagePath = data.imagePath; 
       if(this.commonUtils.checkNullOrUndefined(data.uploadedFileArray) && data.uploadedFileArray.length >0)
         this.uploadedFileArray=data.uploadedFileArray;
      this.tempFolderPath = this.commonService.SERVICE_URL+environment.TEMP_FOLDER;
      this.fileUploadType = data.fileUploadType;
    }

onFileChange(fileInput) {
  this.commonService.showProgressBar();
  let imgFile = fileInput.target.files[0];
  let validFileFlag =true;
  let fileObj:any={};
    
    if (imgFile != null) {
    const body = new FormData();
    body.append('file', imgFile);
    let fileType = imgFile.type;
    let limit: number = 5 * 1024 * 1024; // 5MB

    if(imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }
    else{
      validFileFlag = false;
      this.errorFlag = true;
      this.errorMsg = "Image size is greater than 5MB";
    }
    

    /************************** this section added for Onboading tab - uploads - starts - Monisha **************************/
    if(this.dialogLabel === 'Upload Resume' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' ||  fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Salary Slip' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Declarations of Relative working' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Educational Certificates' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Address Proof Documents' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Photographs' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Relieving Certificates' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Experience / Service Certificates' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Form 16' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Offer Letters' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload NDA' && ( fileType === 'application/pdf' ) 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Interview Evaluation Sheet' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Appointment Letter' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Educational Certificates' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Salary Breakup / Tax Structure' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Resignation Letter' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Acceptance Letter / Retention' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Exit Interview Form' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Relieving Letter' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Experience Letter' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload Other Documents (if any)' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }else if(this.dialogLabel === 'Upload HR Policy' && (fileType === 'application/pdf') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } else if(this.dialogLabel === 'Upload Test Document' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } else if(this.dialogLabel === 'Upload Test Result' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } else if(this.dialogLabel === 'Upload PaySlip' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } else if(this.dialogLabel === 'Upload Offer Letter' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')  
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } else if(this.dialogLabel === 'Upload Candidate Resume' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){ 
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    } else if(this.dialogLabel === 'Upload Issue' && (fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
      } 
      
      ///Create task Upload Attchement not showing in ERP tp Neuzen conversion process 
      else if(this.dialogLabel === 'Attach Files' && (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/doc' || fileType === 'application/ms-doc' || fileType === 'application/pdf'
      || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'image/png' 
      || fileType === 'image/jpeg' || fileType === 'image/gif') 
      && imgFile.size <= limit){
      validFileFlag = true;
      this.errorFlag = false;
      this.errorMsg = "";
    }
	else{ 
      validFileFlag = false;
      this.errorFlag = true;
      if(this.dialogLabel === 'Upload HR Policy')
        this.errorMsg = "Only file of type pdf is allowed. Please upload a different file.";
      else if(this.dialogLabel === 'Upload Resume' || this.dialogLabel === 'Upload Resignation Letter' 
        || this.dialogLabel === 'Upload Acceptance Letter / Retention' || this.dialogLabel === 'Upload Exit Interview Form' 
        || this.dialogLabel === 'Upload Relieving Letter' || this.dialogLabel === 'Upload Experience Letter')
        this.errorMsg = "Only file of type img/doc/docx/pdf are allowed. Please upload a different file.";
      else if(this.dialogLabel === 'Upload NDA' || this.dialogLabel === 'Upload Candidate Resume')
        this.errorMsg = "Only file of type pdf is allowed. Please try to upload a pdf file.";
      else
        this.errorMsg = "Only file of type doc/docx/pdf/jpg/png are allowed. Please upload a different file.";
    }
    /************************** this section added for Onboading tab - uploads - starts - Monisha **************************/

     if(validFileFlag ==true){
       this.commonService.uploadFiles(body).
      subscribe(
       (response) => {
         fileObj.name=response ; 
          fileObj.size=imgFile.size;
          fileObj.addedNewly=true;
          this.uploadedFileArray.push(fileObj);
          this.commonService.hideProgressBar();
        },
       (error)=>{
         console.log(error);
          this.commonService.hideProgressBar();
       });
      }else{
        this.commonService.hideProgressBar();
      }
     }
}
removeFile(index){
   this.uploadedFileArray.splice(index,1);
   if(this.dialogLabel === 'Upload HR Policy'){
    this.deleteStatusFlag = true;
   }else{
    this.deleteStatusFlag = false;
   }
}

errorHandler(event) { }

downloadFile(fileName){
  var FileSaver = require('file-saver');
  let fileNameSplit = fileName.split('.');
  this.commonService.downloadImage(this.imagePath+'/'+fileName).subscribe(
    (response) => {
      var blob = new Blob([response], {type:"image/jpeg"});
      FileSaver.saveAs(blob,fileName);
    },
    (error)=>{
      console.log(error);
    });
}

doneClick(){
  this.dialogResult.done=true;
  this.dialogResult.uploadedArray=this.uploadedFileArray;
  this.dialogResult.statusFlag=this.deleteStatusFlag;
  this.dialogRef.close(this.dialogResult);
}
cancelClick(){
  this.dialogResult.done=false;
  this.dialogRef.close(this.dialogResult);
}

}