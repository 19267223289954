import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import {ProjectService} from '../project.service'; 
import {environment} from '../../../environments/environment';
import{HrmService} from '../../hrm/hrm-service';

@Component({
 selector: 'app-multiuserworkedhours',
templateUrl: './multiuserworkedhours.component.html',
providers: [],
})
export class MultiUserWorkedHoursComponent  extends CommonUtils implements OnInit {
  historyForm = new FormGroup({ 
    invoiceHeaderId: new FormControl(),
    moduleName: new FormControl('TASK_LEVEL_ACTUAL_WORKED_HRS'),
          t823_taskObj: new FormGroup({
            taskId: new FormControl()
          }),  
  });
  moduleName:string;
  labelName:string;
    portalName: string;
    
    displayedColumns: string[] = ['empname','comments','workedhrs', 'workedDate'];
     dataSource;
      taskId;
      recordFlag = false;

   constructor( private projectService: ProjectService, private hrmService: HrmService, private commonService: CommonService,
    private router: Router,private commonUtils: CommonUtils,private dialog:MatDialog,private dialogRef:MatDialogRef<MultiUserWorkedHoursComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
       super();
       this.taskId=data.taskId; 
       if(this.commonUtils.checkNullOrUndefined(data.moduleName)){
         this.moduleName = data.moduleName;
       }
    }

  ngOnInit() {
    this.pageLoad();
  }

  pageLoad()
  {
    if(this.moduleName === 'TASK_WORKED_HRS_AT_USER_LEVEL'){ 
      this.displayedColumns = ['resource', 'workedhrs' ];
      this.historyForm.get('t823_taskObj.taskId').setValue(this.taskId);
      this.hrmService.fetchActualWorkedHrsTaskBasedUserLevel(this.historyForm.value).subscribe(
        data => { 
 
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.dataSource = data;
         }
         else
         {
             this.recordFlag=true;
         }
         },error => {
           console.log(error);
         });

    }else{
      this.historyForm.get('t823_taskObj.taskId').setValue(this.taskId);
        this.hrmService.fetchTimeSheet(this.historyForm.value).subscribe(
        data => {  
          
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0) {
             this.dataSource = data;
         }
         else
         {
             this.recordFlag=true;
         }
        },error => {
     
        });
    }
      
    
    
}
 
    closeDialog(): void {
        this.dialogRef.close();
    }


}