<mat-card class="col-lg-12 history" style="height: 100%;">
	<mat-card-title >
		<mat-icon class="col-lg-1">home</mat-icon>
		<label class="historyLabel col-lg-10">{{labelName}} <label  *ngIf=" moduleName === 'HOME_NEW_DASHBOARD' || moduleName === 'VIEW_HRIS_TIMESHEET'" >History</label></label>
		 <label class="col-lg-1" style="text-align: right;" *ngIf=" moduleName === 'HOME_NEW_DASHBOARD' || moduleName === 'VIEW_HRIS_TIMESHEET' ||  moduleName === 'CANDIDATE_RESUME_PREVIEW'" >
		 	<mat-icon mat-icon-button (click)="closeDialog()" style="cursor:pointer;">close</mat-icon>
		 </label>
	</mat-card-title>	

	<mat-card-content >
		<div class="col-lg-12" *ngIf="timesheetHistoryFlag === false && moduleName != 'HOME_NEW_DASHBOARD' 
			&& moduleName != 'VIEW_APPRAISAL_FORM' && moduleName != 'VIEW_CANDIDATE'
			&& moduleName != 'VIEW_APPOINTMENT' && moduleName != 'VIEW_JOB' && moduleName != 'NEW_HR_POLICY' && moduleName != 'NDA_FILE' && moduleName != 'CANDIDATE_RESUME_PREVIEW'">
			<div class="col-lg-12">
                <div class="col-lg-12" *ngFor="let historyItem of historyList" style="margin-bottom: 5px;"> 
                    {{historyItem.createdTs | date:'short'}} - {{historyItem.userComments}} 
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="moduleName === 'VIEW_CANDIDATE'">
			<div class="col-lg-12">
                <div class="col-lg-12" *ngFor="let historyItem of historyList" style="margin-bottom: 5px;"> 
                	<p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.t004StatusObj))>
                    {{historyItem.stausDate | dateFormatPipe}} - {{historyItem.t004StatusObj.lookupValue}} </p>
                    <p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.t843_InterviewAppointmentObj))>
                    	Interview Appointment Name : {{historyItem.t843_InterviewAppointmentObj.interviewName}} - {{historyItem.t843_InterviewAppointmentObj.appointmentNumber}}
                    	<span  *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.t843_InterviewAppointmentObj.interviewSlotStatus))> ({{historyItem.t843_InterviewAppointmentObj.interviewSlotStatus.lookupValue}})</span>
                    </p>
                    <p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.editedBy))>
                    	Accepted By : {{historyItem.editedBy}}
                    </p>
                    <p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.remarks))>
                    	Remarks : {{historyItem.remarks}}
                    </p>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="moduleName === 'VIEW_APPOINTMENT'">
			<div class="col-lg-12">
                <div class="col-lg-12" *ngFor="let historyItem of historyList" style="margin-bottom: 5px;"> 
                	<p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.comments))>
                	  {{historyItem.createdTs | date:'short'}} - {{historyItem.comments}} </p>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="moduleName === 'VIEW_JOB'">
			<div class="col-lg-12">
                <div class="col-lg-12" *ngFor="let historyItem of historyList" style="margin-bottom: 5px;"> 
                	<p *ngIf=(this.commonUtils.checkNullOrUndefined(historyItem.t004JobStatusObj))>
                	  {{historyItem.agingJobDate | date:'short'}} - {{historyItem.t004JobStatusObj.lookupValue}} </p>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="moduleName === 'VIEW_APPRAISAL_FORM'">
			<div class="col-lg-12">
                <div class="col-lg-12" *ngFor="let historyItem of historyList" style="margin-bottom: 5px;"> 
                    {{historyItem.createdTs | date:'short'}} - {{historyItem.comments}} 
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div>
		</div>
		<div class="col-lg-12" *ngIf="timesheetHistoryFlag === false && moduleName === 'HOME_NEW_DASHBOARD' && labelName != 'My Attendance'">
			<div class="widget-content minht pt-2 pb-2 d-flex flex-column align-items-center justify-content-center">
                 <div class="fullrow row pt-5">
			 		<div class="activity row no-gutters py-1 col-12 col-sm-6 col-xl-6"  *ngFor="let emp of historyList; let i = index;"  >
             			<!-- <img  src="assets/images/avatars/alice.jpg" class="col-auto avatar mr-2" alt="Alice Freeman"> -->
             			
             			 <img  src="{{photoSrcPath}}{{emp.USER_NAME}}/{{emp.PHOTO_FILE_NAME}}" (error)="errorHandler($event)" class="col-auto avatar mr-2" alt="{{emp.USER_NAME}}" >  
                            <div  class="col">
                                    <div>
                                        <span class="bday-employee md-accent-color">{{emp.EMP_NAME}}</span>
                                                               
                                    </div>
                                    <span class="time text-muted" style="padding-right: 10px;">{{emp.DESIGNATION}}</span>
                                       <span *ngIf="labelName === 'Birthday for Current Month'" class="time text-muted">{{emp.DOB | dateFormatPipe}}</span>
                                       <span *ngIf="labelName === 'Anniversary for Current Month'" class="time text-muted">{{emp.DOJ | dateFormatPipe}}</span>
                             </div>
                        </div>
                  </div>
             </div>
		</div>
		<div class="col-lg-12" *ngIf="timesheetHistoryFlag === false && moduleName === 'HOME_NEW_DASHBOARD' && labelName === 'My Attendance'">
			<!-- <div class="col-lg-12">
				<div class="col-lg-12" *ngFor="let historyItem of historyList"  style="margin-bottom: 5px;"> 
                    {{historyItem.PunchIn | date:'short'}} - <span *ngIf="this.commonUtils.checkNullOrUndefined(historyItem.PunchOut)">{{historyItem.PunchOut | date:'short'}}</span>
					<span *ngIf="!this.commonUtils.checkNullOrUndefined(historyItem.PunchOut)">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="recordFlag">
                	<p>No records</p>
                </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
			</div> -->
			<mat-table #table [dataSource]="dataSourceAttendance" class="example-container mat-elevation-z8">
				<ng-container matColumnDef="punchIn">
				  <mat-header-cell *matHeaderCellDef class="alignLeft">Type</mat-header-cell>
				  <mat-cell *matCellDef="let element" class="alignLeft"> {{element.punchType}} </mat-cell>
				</ng-container>
   
				<ng-container matColumnDef="punchOut">
				  <mat-header-cell *matHeaderCellDef class="alignLeft">Punch Time</mat-header-cell>
				  <mat-cell *matCellDef="let element" class="alignLeft">
					<span *ngIf="this.commonUtils.checkNullOrUndefined(element.punchInOutTime)">{{element.punchInOutTime | date: 'short'}}</span>
					<span *ngIf="!this.commonUtils.checkNullOrUndefined(element.punchInOutTime)">-</span></mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayColumn"></mat-header-row>
    		  <mat-row *matRowDef="let row; columns: displayColumn;"></mat-row>
			</mat-table>
			<div class="alignCenter" *ngIf="recordFlag">
				<p>No records</p>
		   </div>
		   <div class="col-lg-12" class="historyButton">
			<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
		</div>
		</div>
		<div class="col-lg-12" *ngIf="timesheetHistoryFlag">
		
		   <mat-table #table [dataSource]="dataSource" class="example-container mat-elevation-z8">

  
			  <ng-container matColumnDef="taskNumber">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Task Number </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft"> {{element.t823_taskObj.taskNumber}} </mat-cell>
			  </ng-container>

 
			  <ng-container matColumnDef="taskName">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Task Name </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft"> {{element.t823_taskObj.taskName}} </mat-cell>
			  </ng-container>


			  <ng-container matColumnDef="description">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Description </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft"> {{element.taskDescription}} </mat-cell>
			  </ng-container>

 			  <ng-container matColumnDef="workedHours">
			    <mat-header-cell *matHeaderCellDef class="reportCenterAlign"> Worked Hours </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="reportRightAlign"> {{element.workedHours}} </mat-cell>
			  </ng-container>

			  <ng-container matColumnDef="workedDate">
			    <mat-header-cell *matHeaderCellDef  class="reportCenterAlign"> Worked Date </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="reportCenterAlign"> {{element.workedDate | dateFormatPipe}} </mat-cell>
			  </ng-container>
 
			  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    		  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

		</mat-table>
		
        
                
         <div class="alignCenter" *ngIf="recordFlag">
               <p>No records</p>
          </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
	</div>

	<div class="col-lg-12" *ngIf="moduleName === 'NEW_HR_POLICY' && labelName === 'View Uploaded PDF'">
		<div class="container" width="90%" height="650" style="pointer-events: none;">
			<pdf-viewer [src]="pdfSource" [original-size]="false"></pdf-viewer>
		</div>
	</div>

	<div class="col-lg-12" *ngIf="moduleName === 'CANDIDATE_RESUME_PREVIEW' && labelName === 'View Candidate Resume'">
		<div *ngIf="resumeFileFileExistFlag" class="container" width="90%" height="650" style="pointer-events: none;">
			<pdf-viewer [src]="pdfSource" [original-size]="false"></pdf-viewer>

		</div>
		  <div class="alignCenter" *ngIf="!resumeFileFileExistFlag">
               <p>Candidate Resume not found</p>
          </div>
	
	</div>

	<div class="col-lg-12" *ngIf="moduleName === 'NDA_FILE' && labelName === 'View Uploaded NDA PDF'">
		<div class="container" width="90%" height="auto" style="pointer-events: none;">
			<pdf-viewer [src]="pdfSource" [original-size]="false"></pdf-viewer>
		</div>

		<div class="col-lg-12" class="historyButton" *ngIf="showSignNDAButton ">
			<div class="row col-lg-12" style="padding-bottom: 10px;"  >
          		<div class="col-lg-12"> 
                	<button *ngIf="!ndaSignImageFileExistFlag && loggedInUserObjRole == 'EMPLOYEE'"  mat-raised-button color="primary" type="button" (click)="openUploadSignaturePopup()">Sign NDA</button>
                  	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button>
            	</div>
          	</div> 
		</div>
	</div>

	</mat-card-content>
	<div class="col-lg-12" *ngIf="moduleName === 'NEW_HR_POLICY' && labelName === 'View Uploaded PDF' && showAgreeTermsFlag">
		<mat-checkbox color="primary" (change)="saveOrUpdateEmployeeHRPolicyFlag($event)" style="margin-left: 3%;" *ngIf="hrPolicyFlagValue === false">
			I have read and agree the Terms and Conditions (or) Privacy Statement applied in this HR Policy</mat-checkbox>
		<label *ngIf="hrPolicyFlagValue" style="float: left;font-weight: bold;font-size: 14px;font-style: italic;margin-left: 3%;">
			You have read and agreed the Terms and Conditions (or) Privacy Statement applied in this HR Policy</label>
		<button mat-raised-button type="button" color="primary" (click)="closeDialog()" 
			style="margin-left: 50%;margin-top: 2%;margin-bottom: 2%;">Close</button> 
	</div>
</mat-card>