import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormGroup, FormControl} from '@angular/forms';
import {CommonUtils} from '../core/common-utils';
import {CommonService} from '../shared/common.service';
import {HrmService} from '../hrm/hrm-service';
import {RecruitmentService} from '../recruitment/recruitment-service';

@Component({
  selector: 'app-exportpo',
  templateUrl: './exportpo.component.html' 
})
export class ExportPODialogComponent extends CommonUtils implements OnInit {

 moduleNamesList: any ;
    radioToggleValue: string;
    showThisPanel = false;
    showPOPanel = false;
    showCustPanel = false;
    moduleValue: string;
    showVendorsPanel = false;
     commonForm: FormGroup;
     MODULES;
     disableButton=true;
     screenModuleName: string;
     dateFormat;
     invoiceForm:FormGroup
     moduleType: string;
     shiftAllocationFlag = false;
   myControl: FormControl = new FormControl();
  date: FormControl = new FormControl(new Date());
	constructor(private commonService:CommonService, private hrmService: HrmService, public snackBar: MatSnackBar, private router: Router,
    private commonUtils:CommonUtils,private recruitService:RecruitmentService,
	  public dialogRef: MatDialogRef<ExportPODialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  {
    super();
      this.MODULES = this.commonUtils.MODULES;
      this.invoiceForm=data.invoiceForm;
      this.moduleNamesList = this.commonService.appScreenList;
	}

	onNoClick(): void 
  {
	  this.dialogRef.close();
 	}

  ngOnInit() 
  {
    if (this.invoiceForm.get('moduleName').value == 'VIEW_SHIFT_ALLOCATION')
    this.shiftAllocationFlag = true;
  }

downloadExportData():void
{  
  if(this.checkNullOrUndefined(this.invoiceForm.get('moduleName')))
    this.moduleValue=this.invoiceForm.get('moduleName').value;
   if(this.checkNullOrUndefined(this.invoiceForm.get('moduleType')))
     this.moduleType = this.invoiceForm.get('moduleType').value; 
 
// added to bind the currenny value in the report
 if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj) && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t000Organization) && 
   this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t000Organization.t003Currency) && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t000Organization.t003Currency.symbol)) {
   this.invoiceForm.get('currencySymbol').setValue(this.commonService.loggedInUserObj.t000Organization.t003Currency.symbol);
 }
 // added this method for loan export file excel and csv both my corner/hrm
  if(this.moduleValue=='VIEW_LOAN' || this.moduleValue=='MY_LOAN')
 {
    this.invoiceForm.get('moduleName').setValue('NEW_LOAN');
    this.commonService.showProgressBar();
    this.hrmService.downloadExportDataLoan(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
         this.commonService.downloadGeneratedReport(data,'NEW_LOAN'); 
  
       },
       error=>
       {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error) ;         
       }
       );
 }

 if(this.moduleValue === 'VIEW_EMPLOYEE' || this.moduleValue === 'NEW_EMPLOYEE')
 {
   this.invoiceForm.get('moduleName').setValue('NEW_EMPLOYEE');  
   this.commonService.showProgressBar();
      this.hrmService.downloadExportDataEmployee(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
         this.commonService.downloadGeneratedReport(data,'NEW_EMPLOYEE');
        
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
          
       }
       ); 
 }
 if(this.moduleValue === 'VIEW_ASSETS' || this.moduleValue === 'NEW_ASSETS')
 {
      this.invoiceForm.get('moduleName').setValue('NEW_ASSETS');
      this.commonService.showProgressBar();
     this.commonService.downloadExportDataAsset(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
         this.commonService.downloadGeneratedReport(data,'NEW_ASSETS');
      
       },
       error=>
       {
       
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
 }
 if(this.moduleValue=='PROJECT_PLAN' || this.moduleValue=='PROJECT_PLAN_CLIENT')
 {
     this.invoiceForm.get('moduleName').setValue(this.moduleValue);
     this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForProjectPlan(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,this.moduleValue);
      
       },
       error=>
       {
         
         this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error) ;
         
       }
       );
 }

 //method to generate report in CSV / XSL format for medical bills
 if(this.moduleValue=='REVIEW_MEDICAL_BILL'){
     this.invoiceForm.get('moduleName').setValue('REVIEW_MEDICAL_BILL');
     this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForMedicalBill(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'REVIEW_MEDICAL_BILL');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }

  //method to generate report in CSV / XSL format for paycode
  if(this.moduleValue=='VIEW_PAYCODE'){
   this.invoiceForm.get('moduleName').setValue('VIEW_PAYCODE');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForPaycode(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'VIEW_PAYCODE');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }

 //method to generate report in CSV / XSL format for timesheet
 if(this.moduleValue=='VIEW_TIMESHEET'){
   this.invoiceForm.get('moduleName').setValue('VIEW_TIMESHEET');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForTimesheet(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'VIEW_TIMESHEET');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }

  //method to generate report in CSV / XSL format for HRIS timesheet
 if(this.moduleValue=='VIEW_HRIS_TIMESHEET'){
   this.invoiceForm.get('moduleName').setValue('VIEW_HRIS_TIMESHEET');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForHrisTimesheet(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'VIEW_HRIS_TIMESHEET');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }

 //method to generate report in CSV / XSL format for timesheet history
  if(this.moduleValue=='TIMESHEET_HISTORY'){
   this.invoiceForm.get('moduleName').setValue('TIMESHEET_HISTORY');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForTimesheetHistory(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'TIMESHEET_HISTORY');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }
  if(this.moduleValue=='VIEW_SHIFT_MASTER'){
   this.invoiceForm.get('moduleName').setValue('NEW_SHIFT_MASTER');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForShiftMaster(this.invoiceForm.value).subscribe(
       data=>
       {
        this.commonService.hideProgressBar();
        this.commonService.downloadGeneratedReport(data,'NEW_SHIFT_MASTER');
       },
       error=>
       {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error) ;
       }
       );
  }
   if(this.moduleValue=='VIEW_SHIFT_ALLOCATION'){
   this.invoiceForm.get('moduleName').setValue('NEW_SHIFT_ALLOCATION');
   this.commonService.showProgressBar();
     this.hrmService.downloadExportDataForShiftAllocation(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'NEW_SHIFT_ALLOCATION');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  }
 if(this.moduleValue=='VIEW_CANDIDATE'){
   this.invoiceForm.get('moduleName').setValue('VIEW_CANDIDATE');
   this.commonService.showProgressBar();
     this.recruitService.downloadExportDataForCandidate(this.invoiceForm.value).subscribe(
       data=>
       {
         this.commonService.hideProgressBar();
       this.commonService.downloadGeneratedReport(data,'VIEW_CANDIDATE');
     
       },
       error=>
       {
        
         this.commonService.hideProgressBar();
       this.commonService.showERPErrorMessage(error) ;
         
       }
       );
  
 }
 if(this.moduleValue=='VIEW_JOB'){
  this.invoiceForm.get('moduleName').setValue(this.moduleValue);
  this.commonService.showProgressBar();
    this.recruitService.downloadExportDataForJob(this.invoiceForm.value).subscribe(
      data=>
      {
        this.commonService.hideProgressBar();
      this.commonService.downloadGeneratedReport(data,'VIEW_JOB');
    
      },
      error=>
      {
       
        this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error) ;
        
      }
      );
 
}
  this.dialogRef.close();
 }





  radioToggle(): void
   {
    if (this.radioToggleValue === 'Specific Period') 
    {
      this.showThisPanel = true;
    } else 
    {
      this.showThisPanel = false;
    }
  }
}
