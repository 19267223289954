import * as fromCreateTemplate from './createtemplate.reducers';
import {ActionReducerMap,createSelector, createFeatureSelector} from '@ngrx/store';

export interface CreateTemplateStates{
	createtemplates:fromCreateTemplate.CreateTemplateState;
}

export const reducers:ActionReducerMap<CreateTemplateStates>={
	createtemplates:fromCreateTemplate.reducer
} 

export const getViewTemplateWrapperState=createFeatureSelector<CreateTemplateStates>('createtemplate');

export const getDataState=createSelector(getViewTemplateWrapperState,(state:CreateTemplateStates)=>state.createtemplates);


export const getLoadingState=createSelector(getDataState,fromCreateTemplate.viewTemplateDataLoading);
export const getLoadedState=createSelector(getDataState,fromCreateTemplate.viewTemplateDataLoaded);
export const getData=createSelector(getDataState,fromCreateTemplate.viewTemplateData);

