import { Component, Input ,Inject,Output} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
@Component({
  selector: 'file-preview-overlay',
  templateUrl: './timesheet.component.html'
   
})
export class TimeSheetComponent extends CommonUtils { 

inputObj;
timesheetForm:FormGroup;
  constructor(
      public dialogRef: ErpOverlayRef,private commonService: CommonService,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
     super();
    this.inputObj=data;
    this.timesheetForm=new FormGroup({
      workedDate: new FormControl(new Date()),
      workedHours: new FormControl(0),
      taskDescription: new FormControl(''),
       t001_UserObj: new FormGroup({
        userId: new FormControl()
      }),
      t823_taskObj:new FormGroup({
      taskId: new FormControl(0),
      taskNumber: new FormControl(''),
      t822ProjectObj:new FormGroup({projectId:new FormControl(0),projectName:new FormControl('')}),
      })
    })
  }
ngOnInit() 
{
  this.pageload();
}
pageload()
{
  
}
saveTimeSheet()
{ 
  if(this.inputObj.moduleName === 'VIEW_TIMESHEET'){
    this.timesheetForm.get('t823_taskObj.taskId').setValue(this.inputObj.t823TaskObj.taskId);
  this.timesheetForm.get('t823_taskObj.taskNumber').setValue(this.inputObj.taskNumber);
  this.timesheetForm.get('t823_taskObj.t822ProjectObj.projectId').setValue(this.inputObj.projectId); 
  this.timesheetForm.get('t823_taskObj.t822ProjectObj.projectName').setValue(this.inputObj.projectName); 
  this.timesheetForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId); 
  this.dialogRef.dialogClose(this.timesheetForm.value);
  }else if (this.inputObj.moduleName === 'VIEW_HRIS_TIMESHEET'){
    this.timesheetForm.get('t823_taskObj.taskId').setValue(this.inputObj.taskId);
  this.timesheetForm.get('t823_taskObj.taskNumber').setValue(this.inputObj.taskNumber);
  this.timesheetForm.get('t823_taskObj.t822ProjectObj').patchValue(this.inputObj.t822ProjectObj);
  this.timesheetForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId); 
  this.dialogRef.dialogClose(this.timesheetForm.value);
  }
  
}

}