import {Injectable} from '@angular/core';
import {throwError as observableThrowError, Observable,Subscription,Subject} from 'rxjs';
import { map,catchError } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
SERVICE_URL;
  constructor( private http:HttpClient) {}

 saveErrorMessage(errorObj) :Observable<any>
  {
	  if(window.location.href.indexOf('localhost')!=-1)
      this.SERVICE_URL="http://localhost:"+environment.SERVICE_PORT+"/";
    else if(window.location.href.indexOf('neuzenhr.peoplezenn.com')!=-1)
      this.SERVICE_URL="https://neuzenhrapi.peoplezenn.com/";
    return this.http.post(this.SERVICE_URL+"admin/saveAngularErrorMessage",errorObj)
      .pipe(map(data => data));     
  }
   
}