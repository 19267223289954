
import { Component, OnInit,HostListener,EventEmitter,OnDestroy } from '@angular/core';
import{Response} from '@angular/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router,ActivatedRoute} from '@angular/router';
import {CommonService} from '../shared/common.service';
import {SettingsService} from '../settings/settings-service';
import {CommonUtils} from '../core/common-utils';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import { Injectable, Injector } from '@angular/core';
import {environment} from '../../environments/environment';
import {Constants} from '../shared/dateFormatPipe.pipe';
  import {OverlayModule,Overlay} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ErpOverlayComponent } from '../popup/erp-overlay.component';
 @Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'

})

export class SigninComponent implements OnInit,OnDestroy {
  errorimageSourcePath=environment.ESALESUITE_LOGO;
  keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
    htmlElementMap:Map<string,string>=new Map();
    currentFocus='INITIAL';
    loggedInUserIp;
    geolocationPosition;

public myFocusTriggeringEventEmitter = new EventEmitter<string>();
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   // event.preventDefault();
   // event.stopPropagation();
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

    if(this.keyCode==13){
    //    event.preventDefault();
   //  event.stopPropagation();
      if(this.ctrlKey){

      }else{
    
       this.myFocusTriggeringEventEmitter.emit(this.htmlElementMap[this.currentFocus]);
       this.currentFocus=this.htmlElementMap[this.currentFocus];
      if(this.currentFocus==='SUBMIT')
        this.loginPageSubmit();
    }
  }
  }

 
 USERNAME_PLACEHOLDER;
 
 defaultNavigateScreen: string;
  
 loginOrg;
  userDataObj ;
   imagePath;
   action:string;
   headerId:string;
   detailId:string;
   originalUrl;
myForm: FormGroup = new FormGroup
({
  userName: new FormControl('', Validators.required),
  password: new FormControl('',Validators.required),
  userId: new FormControl(0),
  orgName:new FormControl(),
  t000Organization:new FormGroup({
    organizationId:new FormControl(0),
    portalName:new FormControl('',Validators.required),
    portalNametemp:new FormControl('')
  }),
  userIpaddress:new FormControl(''),
   longitude: new FormControl(''),
    latitude: new FormControl(''),
    browserName:new FormControl(''),
    browserVersion:new FormControl('')
});

  constructor(private router: Router, private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private commonService: CommonService ,
    private settingsService: SettingsService, private commonUtils: CommonUtils,
   private overlay: Overlay) {
  this.USERNAME_PLACEHOLDER = this.commonUtils.USERNAME_PLACEHOLDER;
 } 
  
  ngOnDestroy(){
     let bodyId=document.getElementById('bodyId');
    bodyId.classList.remove("sign-in-loaded");
  }
  ngOnInit() {
   this.commonService.checkUserLogin('SignUp');
    this.ipClientIp();
    let bodyId=document.getElementById('bodyId');
 // bodyId.className = bodyId.className.concat(" fuse-aside-expanded");
    bodyId.className = bodyId.className.concat(" sign-in-loaded"); 

//   this.commonService.clearUserData();
    this.action=this.route.snapshot.params.action;
    this.headerId=this.route.snapshot.params.headerId;
    this.detailId=this.route.snapshot.params.detailId;
    this.originalUrl = this.route.url.toString();
    this.imagePath = environment.HRIS_LOGO;

    const overlayRef = this.overlay.create({
      height: '400px',
      width: '600px',
      hasBackdrop:true
    });
   const filePreviewPortal = new ComponentPortal(ErpOverlayComponent);

  

 
 
var browser=this.get_browser_info();
this.myForm.get('browserName').setValue(browser.name);
this.myForm.get('browserVersion').setValue(browser.version)

     this.commonUtils.formHtmlElementsMap();
     this.htmlElementMap=this.commonUtils.moduleMap['LOGIN'];
 
this.myFocusTriggeringEventEmitter.emit(this.htmlElementMap[this.currentFocus]);
    
   } 

 async  ipClientIp(){
    //  console.log("ipClientIp");
   await this.commonService.getIpClientIp().subscribe(
      data=>{
        // console.log(data['ip']);
        this.loggedInUserIp=data['ip'];
        this.myForm.get('userIpaddress').patchValue(this.loggedInUserIp); 
      });
   }

public socialSignInGoogle(socialPlatform: string) {
	window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=733594033776-rg97b4q3i9le9t4dmcuj9ji8n4hbhbt5.apps.googleusercontent.com&redirect_uri=' + this.commonService.SERVICE_URL + 'signin/code/&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/plus.me&access_type=offline&response_type=code&prompt=consent';

}

get_browser_info(){
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|edge|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
        return {name:'IE ',version:(tem[1]||'')};
    }   
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR\/(\d+)/)
        if(tem!=null)   {
          return {name:'Opera', version:tem[1]};
        }
    }   
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {
      M.splice(1,1,tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
 }

loginPageSubmit(): void {
  this.commonService.showProgressBar();
  
  this.myForm.get('userIpaddress').patchValue(this.loggedInUserIp);
  if(!this.commonUtils.checkNullOrUndefined(this.myForm.get('t000Organization.portalName').value) 
    || !this.commonUtils.checkNullOrUndefined(this.myForm.get('userName').value)
    || !this.commonUtils.checkNullOrUndefined(this.myForm.get('password').value)){
    this.snackBar.open('Please enter PortalName / UserName / Password to proceed with SignIn', 'OK', {
      duration: 3000
    });
    return;
  }else{
  this.commonService.checkForLoginUser(this.myForm.value).subscribe(
   data => {
      this.commonService.loggedInUserObj = data;  
      //accessToken added by Ajith[9121] implementing JWT authentication on 18, OCT 2022
      this.commonService.setUserDataCustom('accessToken',data.jwtToken);  
      this.commonService.formCreateTemplateSettingsMap(data);
      this.commonService.formMenuMap();
      this.setDateFormat();
       this.commonService.hideProgressBar();
       if (data== null) {
         this.snackBar.open('Username and Password incorrect', 'OK', {
            duration: 3000
          });
        }
        else if (data!= null) {
          if( this.commonUtils.checkNullOrUndefined(this.action) && this.action.toUpperCase()==='authenticateArtwork'.toUpperCase())
            this.commonService.afterLoginSuccess(false,'invoice/createartworkrequest/'+this.headerId+'/null/null/null/'+this.detailId);
          else if( this.commonUtils.checkNullOrUndefined(this.action) && this.action.toUpperCase()==='authenticateArtworkToVendor'.toUpperCase())
            this.commonService.afterLoginSuccess(false,'sales/manageArtwork/'+this.detailId);
          else 
            this.commonService.afterLoginSuccess(true);           
        }
     }, error => {
          this.router.navigateByUrl('/');
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);     
      });
	  }
  }

setDateFormat():void{
  if(this.commonService.loggedInUserObj.t000Organization != null && this.commonService.loggedInUserObj.t000Organization != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId != null && this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != null && this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != ''){
      Constants.DATE_FMT =  this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;          
  }else{
      Constants.DATE_FMT =  environment.DATE_FORMAT;
  }      
}

  errorHandler(event) {
    console.debug(event);
    event.target.src = this.errorimageSourcePath;
  }
}