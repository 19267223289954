
import {switchMap, debounceTime} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {CommonService} from '../../shared/common.service';
import {environment} from '../../../environments/environment';
import {FormGroup, FormControl, FormGroupDirective, NgForm, Validators, FormArray, FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription, Observable} from 'rxjs';
@Component({
  selector: 'app-createlicense',
  templateUrl: './createlicense.component.html'
  
})
export class CreateLicenseComponent extends CommonUtils implements OnInit {
licenseForm: FormGroup;
moduleName:string=environment.NEW_LICENSE;
countryList;
languages;
dateFormats;
timezones;
currencyList;
currencyFormGroup:FormGroup;
erpFormArray:FormArray;
crmFormArray:FormArray;
webStoreFormArray:FormArray;
eCartFormArray:FormArray;
organizationFormArray:FormArray;
licenseEditId;
erpEnable:boolean=false;
crmEnable:boolean=false;
webStoreEnable:boolean=false;
eCartEnable:boolean=false
orgLoopCount:number=0;
productError={error:'Any one of the product should be enable'};
public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
private sub :any;
editData;
emailStatus;
  constructor( private commonService: CommonService,private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,private commonUtils:CommonUtils )
   {
   	super();
   this.licenseEditId = this.activatedRoute.snapshot.params.id;
   this.licenseForm = new FormGroup({

    licenseId : new FormControl(0),
    licenseName : new FormControl(''),
    primaryContactName : new FormControl(''),
    primaryContactEmailAddress : new FormControl(''),
    createdTs: new FormControl(''),
    updatedTs: new FormControl(''),
    t000_OrganizationSet: this.formBuilder.array([]),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0)}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    t003Currency : new FormGroup({currencyId: new FormControl(0), currencyCode: new FormControl(''), currencyValue: new FormControl('', Validators.maxLength(100))}),
    t012Language : new FormGroup({langId: new FormControl(0), name: new FormControl('', Validators.maxLength(75))}),
    t004LookuptimeZoneId : new FormGroup({lookupId: new FormControl(0), lookupCode: new FormControl('TIME_ZONE'), lookupValue: new FormControl('', Validators.maxLength(100))}) ,
    t004LookupDateFormatId : new FormGroup({lookupId: new FormControl(0), lookupCode: new FormControl('DATE_FORMAT'), lookupValue: new FormControl('', Validators.maxLength(100))}),
    billingAttention: new FormControl(''),
    billingAddressLineOne: new FormControl(''),
    billingAddressLineTwo: new FormControl(''),
    billingCity: new FormControl(''),
    billingState: new FormControl(''),
    billingZipCode: new FormControl(''),
    t010BillingCountry:new FormGroup({countryId: new FormControl(0), countryCode: new FormControl(''),
  countryValue: new FormControl(''), active: new FormControl(''), createdBy: new FormControl(''),
  createdDttm: new FormControl(new Date()), updatedBy: new FormControl(''),
  updatedDttm: new FormControl(new Date())}),
    billingPhone: new FormControl(''),
    billingFax: new FormControl(''),
    shippingAttention: new FormControl(''),
    shippingAddressLineOne: new FormControl(''),
    shippingAddressLineTwo: new FormControl(''),
    shippingCity: new FormControl(''),
    shippingState: new FormControl(''),
    shippingZipCode: new FormControl(''),
    t010ShippingCountry:new FormGroup({countryId: new FormControl(4), countryCode: new FormControl(''),
  countryValue: new FormControl(''), active: new FormControl(''), createdBy: new FormControl(''),
  createdDttm: new FormControl(new Date()), updatedBy: new FormControl(''),
  updatedDttm: new FormControl(new Date())}),
    shippingPhone: new FormControl(''),
    shippingFax: new FormControl(''),
    enableERP: new FormControl(false),
    enableCRM: new FormControl(false),
	enableWebStore: new FormControl(false),
	enableEsalesCart: new FormControl(false),
	erpFormArray: this.formBuilder.array([]),
	crmFormArray: this.formBuilder.array([]),
	webStoreFormArray: this.formBuilder.array([]),
	eCartFormArray: this.formBuilder.array([]),
	lazyLoad:new FormControl('N')

  })
this.currencyFormGroup=new FormGroup({
      currencyId: new FormControl(0),
      currencyCode: new FormControl(''),
      currencyValue: new FormControl(''),
      status: new FormControl('N'),
      sortOrder: new FormControl(0),
      symbol: new FormControl(''),
      baseCurrency: new FormControl(false),
      exchangeRate: new FormControl('') 
     
   })
  }
  ngOnInit() 
  {
  	 /**
}
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/ // this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.pageLoad();
         }
       });
/** code ends above **/ 
 if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
  this.pageLoad();
  /******Below codes is for loading the contact object on refresh - starts******/
  }
  pageLoad()
  {

    this.emailStatus = this.licenseForm.controls['primaryContactEmailAddress'].valueChanges.pipe(
      debounceTime(400),
      switchMap(emailStatus => this.emailIdCheck()),);

  
  	this.organizationFormArray=this.licenseForm.get('t000_OrganizationSet') as FormArray;
  	if(this.commonUtils.checkNullOrUndefined(this.licenseEditId) && this.licenseEditId==0)
  	{
  		this.erpFormArray=this.licenseForm.get('erpFormArray') as FormArray;
  		this.erpFormArray.push(this.addOrganization());
  		this.organizationFormArray.push(this.addOrganization());

  		this.crmFormArray=this.licenseForm.get('crmFormArray') as FormArray;
  		this.crmFormArray.push(this.addOrganization());
  		this.organizationFormArray.push(this.addOrganization());

  		this.webStoreFormArray=this.licenseForm.get('webStoreFormArray') as FormArray;
  		this.webStoreFormArray.push(this.addOrganization());
  		this.organizationFormArray.push(this.addOrganization());

  		this.eCartFormArray=this.licenseForm.get('eCartFormArray') as FormArray;
  		this.eCartFormArray.push(this.addOrganization());
  		this.organizationFormArray.push(this.addOrganization());
  	}
  	if(this.commonUtils.checkNullOrUndefined(this.licenseEditId) && this.licenseEditId!=0)	
  	{
  		
  		this.loadfetchForEdit();
  	}	
	 this.fetchCurrency();
     this.fetchCountry();
     this.fetchLanguage();
     this.fetchFromLookup(this.licenseForm.get('t004LookuptimeZoneId'));
     this.fetchFromLookup(this.licenseForm.get('t004LookupDateFormatId'));
     this.validationMethod();
     
  }
   cancelClick():void{
    this.commonService.showERPMessage('', this.moduleName);
  }
    copyAsBillingAddress(): void 
{

	this.licenseForm.get('shippingAttention').setValue(this.licenseForm.get('billingAttention').value);
	this.licenseForm.get('shippingAddressLineOne').setValue(this.licenseForm.get('billingAddressLineOne').value);
	this.licenseForm.get('shippingAddressLineTwo').setValue(this.licenseForm.get('billingAddressLineTwo').value);
	this.licenseForm.get('shippingCity').setValue(this.licenseForm.get('billingCity').value);
	this.licenseForm.get('shippingState').setValue(this.licenseForm.get('billingState').value);
	this.licenseForm.get('shippingZipCode').setValue(this.licenseForm.get('billingZipCode').value);
	this.licenseForm.get('shippingPhone').setValue(this.licenseForm.get('billingPhone').value);
	this.licenseForm.get('shippingFax').setValue(this.licenseForm.get('billingFax').value);
	this.licenseForm.get('t010ShippingCountry').get('countryId').setValue(this.licenseForm.get('t010BillingCountry').get('countryId').value);
}
fetchLanguage(): void {
   this.commonService.fetchLanguage().
      subscribe(
      data => {
         this.languages = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}
fetchFromLookup(lookupObj): void {

   this.commonService.fetchFromLookup(lookupObj.value).
      subscribe(
      data => {
         if (this.commonUtils.checkNullOrUndefined(data)) {
           if (data[0].lookupCode === 'TIME_ZONE') {
             this.timezones = data;
           } 
           else if (data[0].lookupCode === 'DATE_FORMAT') {
             this.dateFormats = data;
           }
         }

       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
}
fetchCurrency(): void {
    this.commonService.fetchCurrency(this.currencyFormGroup.value).
      subscribe(
      data => {
         this.currencyList = data;
       },
       error => {
         this.commonService.showERPErrorMessage(error);
       });
  }

 currencySelected(selectedCurrency): void {
   this.licenseForm.get('t003Currency.currencyCode').setValue(selectedCurrency.viewValue);
   this.licenseForm.get('t003Currency.currencyId').setValue(selectedCurrency.value);
 }

 languageSelected(selectedlanguage): void {
   this.licenseForm.get('t012Language.name').setValue(selectedlanguage.viewValue);
   this.licenseForm.get('t012Language.langId').setValue(selectedlanguage.value);
 }
 timeZoneSelected(selectedTimeZone): void {
   this.licenseForm.get('t004LookuptimeZoneId.lookupValue').setValue(selectedTimeZone.viewValue);
   this.licenseForm.get('t004LookuptimeZoneId.lookupId').setValue(selectedTimeZone.value);
 }

 dateFormatSelected(selectedDateFormat): void {
   this.licenseForm.get('t004LookupDateFormatId.lookupValue').setValue(selectedDateFormat.viewValue);
   this.licenseForm.get('t004LookupDateFormatId.lookupId').setValue(selectedDateFormat.value);
 }
 fetchCountry(): void {
  this.commonService.fetchCountry().
  subscribe(
  data => {
  this.countryList = data;
  },
  error => {
  this.commonService.showERPErrorMessage(error);
  });
  }
  saveOrUpdateLicense()
  {
    
  	this.erpFormArray=this.licenseForm.get('erpFormArray') as FormArray;
  	this.crmFormArray=this.licenseForm.get('crmFormArray') as FormArray;
  	this.webStoreFormArray=this.licenseForm.get('webStoreFormArray') as FormArray;
  	this.eCartFormArray=this.licenseForm.get('eCartFormArray') as FormArray;

  	this.organizationFormArray=this.licenseForm.get('t000_OrganizationSet') as FormArray;

   	this.erpEnable=this.licenseForm.get('enableERP').value;
   	this.crmEnable=this.licenseForm.get('enableCRM').value;
   	this.webStoreEnable=this.licenseForm.get('enableWebStore').value;
   	this.eCartEnable=this.licenseForm.get('enableEsalesCart').value;
   	if(this.erpEnable==true || this.crmEnable==true || this.webStoreEnable==true || this.eCartEnable==true)
   	{
   	if(this.commonUtils.checkNullOrUndefined(this.crmFormArray) && this.crmFormArray.length>0)
  	{  		
  		for(var loop=0;loop<this.crmFormArray.length;loop++)
  		{
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationName').setValue(this.crmFormArray.at(loop).get('organizationName').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationId').setValue(this.crmFormArray.at(loop).get('organizationId').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('productName').setValue('CRM');
  			this.organizationFormArray.at(this.orgLoopCount).get('active').setValue(this.crmEnable);
  			this.orgLoopCount=this.orgLoopCount+1;
  		}  		
  	}

  	if(this.commonUtils.checkNullOrUndefined(this.webStoreFormArray) && this.webStoreFormArray.length>0)
  	{  		
  		for(var loop=0;loop<this.webStoreFormArray.length;loop++)
  		{
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationName').setValue(this.webStoreFormArray.at(loop).get('organizationName').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationId').setValue(this.webStoreFormArray.at(loop).get('organizationId').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('productName').setValue('WEB STORE');
  			this.organizationFormArray.at(this.orgLoopCount).get('active').setValue(this.webStoreEnable);
  			this.orgLoopCount=this.orgLoopCount+1;
  		}  		
  	}

  	if(this.commonUtils.checkNullOrUndefined(this.eCartFormArray) && this.eCartFormArray.length>0)
  	{  		
  		for(var loop=0;loop<this.eCartFormArray.length;loop++)
  		{
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationName').setValue(this.eCartFormArray.at(loop).get('organizationName').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationId').setValue(this.eCartFormArray.at(loop).get('organizationId').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('productName').setValue('ECART');
  			this.organizationFormArray.at(this.orgLoopCount).get('active').setValue(this.eCartEnable);
  			this.orgLoopCount=this.orgLoopCount+1;
  		}  		
  	}

  	if(this.commonUtils.checkNullOrUndefined(this.erpFormArray) && this.erpFormArray.length>0)
  	{  		
  		for(var loop=0;loop<this.erpFormArray.length;loop++)
  		{
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationName').setValue(this.erpFormArray.at(loop).get('organizationName').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('organizationId').setValue(this.erpFormArray.at(loop).get('organizationId').value);
  			this.organizationFormArray.at(this.orgLoopCount).get('productName').setValue('ERP');
  			this.organizationFormArray.at(this.orgLoopCount).get('active').setValue(this.erpEnable);
  			this.orgLoopCount=this.orgLoopCount+1;
  		}  		
  	}
  
  if(this.licenseForm.get('licenseId').value==0 || (!this.commonUtils.checkNullOrUndefined(this.licenseForm.get('licenseId').value)))
  {
  	this.licenseForm.get('t001UserCreatedBy').setValue(this.commonService.loggedInUserObj);
	this.licenseForm.get('t001UserUpdatedBy').setValue(this.commonService.loggedInUserObj);
  }
  else
  {
  	this.licenseForm.get('t001UserUpdatedBy').setValue(this.commonService.loggedInUserObj);
  }

  this.commonService.showProgressBar();
  	this.commonService.saveOrUpdateLicense(this.licenseForm.value).subscribe(
  		data=>
  		{
  			this.commonService.hideProgressBar();
  			this.commonService.showERPMessage('License Saved Successfully',this.moduleName);
  		},
  		error=>
  		{
  			this.commonService.hideProgressBar();
  			this.commonService.showERPErrorMessage(error);
  		})
  }
  else
  {
  		this.commonService.showERPErrorMessage(this.productError);
  }
  	
  }
   addOrganization(): FormGroup 
 {
  return this.formBuilder.group({
  organizationId: new FormControl(0),
  organizationName: new FormControl(''),
  productName: new FormControl(''),
  active:new FormControl(false),
   address1 : new FormControl(''),
    address2 : new FormControl(''),
    city : new FormControl(''),
    state : new FormControl(''),
    zipCode : new FormControl(''),
    phone : new FormControl(''),
    fax : new FormControl(''),
    website : new FormControl(''),
    primaryContactName : new FormControl(''),
    primaryContactEmailAddress : new FormControl(''),
    createdTs: new FormControl(''),
    updatedTs: new FormControl(''),
    imageName: new FormControl (''),
    logoPath : new FormControl(''),
    enableFP: new FormControl(true),
    schemaName:new FormControl(''),
    userName:new FormControl(''),
    password:new FormControl(''),
    portalName:new FormControl(''),
    authSecret:new FormControl(''),
    estimateModule:new FormControl(''),
    inventoryModule:new FormControl(''),
    inventoryStartDate:new FormControl(''),
    liableToCollectSalesTax:new FormControl(''),
    priceListModule:new FormControl(''),
    purchaseOrderModule:new FormControl(''),
    retainerInvoiceModule:new FormControl(''),
    salesOrderModule:new FormControl(''),
    timeSheetModule:new FormControl(''), 
    intializeFlag:new FormControl('')
})
   }

   addErpOrganization(): void 
   {
    this.erpFormArray = this.licenseForm.get('erpFormArray') as FormArray;
    this.erpFormArray.push(this.addOrganization());
    this.organizationFormArray.push(this.addOrganization());    
  }
 validationMethod(): void
  {
   this.commonService.fetchAndSetValidationData(this.moduleName,this.licenseForm);   
  }
loadfetchForEdit()
{
  this.licenseForm.get('licenseId').setValue(this.licenseEditId);
  this.licenseForm.get('lazyLoad').setValue('Y');
  this.commonService.fetchLicense(this.licenseForm.value).subscribe(
  	data=>
  	{
  		if(this.commonUtils.checkNullOrUndefined(data))
  		this.editData=data[0];
  		let erpCount:number=0;
      let crmCount:number=0;
      let webStoreCount:number=0;
      let eCartCount:number=0;
        this.licenseForm.patchValue(this.editData);
        if(this.commonUtils.checkNullOrUndefined(this.editData.t000_OrganizationSet))
        {
        	for(var loop=0;loop<this.editData.t000_OrganizationSet.length;loop++)
        	{
        		
            this.erpFormArray = this.licenseForm.get('erpFormArray') as FormArray;
            this.crmFormArray = this.licenseForm.get('crmFormArray') as FormArray;
            this.webStoreFormArray = this.licenseForm.get('webStoreFormArray') as FormArray;
            this.eCartFormArray = this.licenseForm.get('eCartFormArray') as FormArray;
            
            if(this.editData.t000_OrganizationSet[loop].productName=='ERP')
            {
               this.erpFormArray.push(this.addOrganization());
              this.erpFormArray.at(erpCount).setValue(this.editData.t000_OrganizationSet[loop]);
              erpCount=erpCount+1;
            }
        		if(this.editData.t000_OrganizationSet[loop].productName=='CRM')
            {
               this.crmFormArray.push(this.addOrganization());
              this.crmFormArray.at(crmCount).setValue(this.editData.t000_OrganizationSet[loop]);
              crmCount=crmCount+1;
            }
        		if(this.editData.t000_OrganizationSet[loop].productName=='WEB STORE')
            {
               this.webStoreFormArray.push(this.addOrganization());
              this.webStoreFormArray.at(webStoreCount).setValue(this.editData.t000_OrganizationSet[loop]);
              webStoreCount=webStoreCount+1;
            }
        		if(this.editData.t000_OrganizationSet[loop].productName=='ECART')
            {
               this.eCartFormArray.push(this.addOrganization());
              this.eCartFormArray.at(eCartCount).setValue(this.editData.t000_OrganizationSet[loop]);
              eCartCount=eCartCount+1;
            }
        	}
        
 }
  		
  	})
}
emailIdCheck():Observable<any>{
  let fetchEmail = this.commonService.fetchLicenseEmailAlreadyExists(this.licenseForm.value);  
  fetchEmail.subscribe(
    data => {
      if(data.length > 0){
        this.licenseForm.get('primaryContactEmailAddress').setErrors({required: true});
      }
    },
    error => {
    this.commonService.showERPErrorMessage(error);
    }
  );
  return fetchEmail;
}

allowNumeric(formCtrlVal){ 
  if(formCtrlVal === 'billingZipCode'){
    if(this.commonUtils.checkNullOrUndefined(this.licenseForm.get('billingZipCode').value)
    && isNaN(this.licenseForm.get('billingZipCode').value)){
      this.licenseForm.get('billingZipCode').reset();
    }
  }else if(formCtrlVal === 'billingFax'){
    if(this.commonUtils.checkNullOrUndefined(this.licenseForm.get('billingFax').value)
    && isNaN(this.licenseForm.get('billingFax').value)){
      this.licenseForm.get('billingFax').reset();
    }
  }else if(formCtrlVal === 'shippingZipCode'){
    if(this.commonUtils.checkNullOrUndefined(this.licenseForm.get('shippingZipCode').value)
    && isNaN(this.licenseForm.get('shippingZipCode').value)){
      this.licenseForm.get('shippingZipCode').reset();
    }
  }else if(formCtrlVal === 'shippingFax'){
    if(this.commonUtils.checkNullOrUndefined(this.licenseForm.get('shippingFax').value)
    && isNaN(this.licenseForm.get('shippingFax').value)){
      this.licenseForm.get('shippingFax').reset();
    }
  }
  
 }

}
