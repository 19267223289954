<div style="margin: 5px">
<div class="Header-values">
  <h3>{{SEND_FEEDBACK}}</h3>
</div>
<div class="dropdown-divider"></div>
<br>
<form (ngSubmit)="saveIssuesOrFeedback()" [formGroup]="issueForm">
<div class="Formdiv row " >
  <mat-form-field class="col-lg-12">
    <textarea matAutosizeMaxRows="20" matAutosizeMinRows="20" matInput placeholder="{{FEEDBACK_NOTES}}" name="notes" class="textarea-width" formControlName="description" onfocus="this.select()">   
    </textarea>
  </mat-form-field>
  <div class="col-lg-8">    
    <div class="col-lg-12 ">{{ATTACH_FILES}} 
    	<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
            <mat-icon>attach_file</mat-icon>          
        </button>
        <p style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
        </p>
    </div>
  </div>
  
  <div class="col-lg-8 row paddingbut" align="left" >
  	<button mat-raised-button color="primary" class="mat-red" [disabled]="!issueForm.valid">{{SAVE_SEND_BUTTON}}</button>
    <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
  </div>
  <div class="col-lg-4 paddingbut alignRight"  > 
  <button type="button" mat-raised-button color="primary" (click)="loadFeedBack()">View All My Feedbacks</button>   
</div>
</div>
</form>
</div>