<div>
<form [formGroup]="appraisalCommentsForm">
<div class="col-lg-12" > 
  <mat-card  class="col-lg-12" >
  	 <div class="row col-lg-12" > 
  			<mat-label class="col-lg-12" style="font-weight: 700;font-size: 15px; padding-left: 0px;padding-top: 10px;padding-bottom: 20px;">JD Point : {{subAppraisalName}}</mat-label>
 		 </div>

	<mat-tab-group  class="col-lg-12" [selectedIndex]="activeAppraiserTabIndex">
  		<mat-tab style="font-weight: bolder; font-size: 14px; " label="Self Appraiser">
		    <ng-template matTabContent>
		    	 <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Self Appraiser Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('selfAppraiserObj').get('firstName').value === ''">
				     	 - </mat-label>
				     	 <mat-label *ngIf="appraisalCommentsForm.get('selfAppraiserObj').get('firstName').value != ''">{{appraisalCommentsForm.get('selfAppraiserObj').get('firstName').value}}
				     	 {{appraisalCommentsForm.get('selfAppraiserObj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
			  <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Self Appraiser Points : </mat-label>	
		      	 <mat-slider [disabled]="selfAppraiserDisableFlag" style = "width: 60% !important" formControlName="selfAppraiselPoint"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div><div class="col-lg-1 paddingTop10px" *ngIf="appraisalCommentsForm.get('selfAppraiselPoint').value > 0" >{{appraisalCommentsForm.get('selfAppraiselPoint').value}} / 5</div>
			    <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea [readonly]="selfAppraiserDisableFlag" matInput  rows="8" cols="200"  placeholder="{{SELF_APRAISER_COMMENTS}}" maxlength="5000"  formControlName="selfAppraiserComments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	     </ng-template>
  		</mat-tab>
	  <mat-tab class="boldHeader" label="Appraiser 1">
	   <ng-template matTabContent>
	       <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Appraiser 1 Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser1Obj').get('firstName').value === ''">
				     		 - 
				     	</mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser1Obj').get('firstName').value != ''">{{appraisalCommentsForm.get('appraiser1Obj').get('firstName').value}} {{appraisalCommentsForm.get('appraiser1Obj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
		     <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Appraiser 1 Points : </mat-label>	
		      	 <mat-slider [disabled]="appraiser1DisableFlag" style = "width: 60% !important" formControlName="appraiser1Point"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div>
			    <div class="col-lg-2 paddingTop10px" *ngIf="appraisalCommentsForm.get('appraiser1Point').value > 0" >{{appraisalCommentsForm.get('appraiser1Point').value}} / 5</div>
			     <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea  [readonly]="appraiser1DisableFlag"  matInput rows="8" cols="200" placeholder="{{APRAISER_1_COMMENT}}" maxlength="5000"  formControlName="appraiser1Comments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	    </ng-template>
	  </mat-tab>
	  <mat-tab label="Appraiser 2">
	     <ng-template matTabContent>
	         <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Appraiser 2 Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser2Obj').get('firstName').value === ''"> - </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser2Obj').get('firstName').value != ''">{{appraisalCommentsForm.get('appraiser2Obj').get('firstName').value}} {{appraisalCommentsForm.get('appraiser2Obj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
		     <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Appraiser 2 Points : </mat-label>	
		      	 <mat-slider  [disabled]="appraiser2DisableFlag"  style = "width: 60% !important" formControlName="appraiser2Point"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div>
			    <div class="col-lg-2 paddingTop10px" *ngIf="appraisalCommentsForm.get('appraiser2Point').value > 0" >{{appraisalCommentsForm.get('appraiser2Point').value}} / 5</div>
			     <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea  [readonly]="appraiser2DisableFlag" matInput rows="8" cols="200" placeholder="{{APRAISER_2_COMMENT}}" maxlength="5000"  formControlName="appraiser2Comments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	    </ng-template>
	  </mat-tab>
	  <mat-tab label="Appraiser 3">
	     <ng-template matTabContent>
	        <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Appraiser 3 Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser3Obj').get('firstName').value === ''"> - </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser3Obj').get('firstName').value != ''">{{appraisalCommentsForm.get('appraiser3Obj').get('firstName').value}} {{appraisalCommentsForm.get('appraiser3Obj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
		     <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Appraiser 3 Points : </mat-label>	
		      	 <mat-slider  [disabled]="appraiser3DisableFlag" style = "width: 60% !important" formControlName="appraiser3Point"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div>
			    <div class="col-lg-2 paddingTop10px" *ngIf="appraisalCommentsForm.get('appraiser3Point').value > 0" >{{appraisalCommentsForm.get('appraiser3Point').value}} / 5</div>
			     <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea  [readonly]="appraiser3DisableFlag" matInput rows="8" cols="200" placeholder="{{APRAISER_3_COMMENT}}" maxlength="5000"  formControlName="appraiser3Comments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	    </ng-template>
	  </mat-tab>
	  <mat-tab label="Appraiser 4">
	     <ng-template matTabContent>
	        <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Appraiser 4 Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser4Obj').get('firstName').value === ''"> - </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser4Obj').get('firstName').value != ''">{{appraisalCommentsForm.get('appraiser4Obj').get('(firstName').value}} {{appraisalCommentsForm.get('appraiser4Obj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
		     <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Appraiser 4 Points : </mat-label>	
		      	 <mat-slider  [disabled]="appraiser4DisableFlag" style = "width: 60% !important" formControlName="appraiser4Point"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div>
			    <div class="col-lg-2 paddingTop10px" *ngIf="appraisalCommentsForm.get('appraiser4Point').value > 0" >{{appraisalCommentsForm.get('appraiser4Point').value}} / 5</div>
			     <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea  [readonly]="appraiser4DisableFlag" matInput rows="8" cols="200" placeholder="{{APRAISER_4_COMMENT}}" maxlength="5000"  formControlName="appraiser4Comments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	    </ng-template>
	  </mat-tab>
	  <mat-tab label="Appraiser 5">
	    <ng-template matTabContent>
	        <div class="row col-lg-12">
	        		<div class="col-lg-8" style="padding-left: 30px; padding-top: 15px;">
				     	<mat-label class="col-lg-4" style="padding-left: 0px;">Appraiser 5 Name : </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser5Obj').get('firstName').value === ''"> - </mat-label>
				     	<mat-label *ngIf="appraisalCommentsForm.get('appraiser5Obj').get('firstName').value != ''">{{appraisalCommentsForm.get('appraiser5Obj').get('firstName').value}} {{appraisalCommentsForm.get('appraiser5Obj').get('lastName').value}} </mat-label>
			      	</div>
		      	</div>
		     <div class="row col-lg-12">
		      	<div class="col-lg-8">

		      	 <mat-label  class="col-lg-4"  style="padding-top: 15px;">Appraiser 5 Points : </mat-label>	
		      	 <mat-slider  [disabled]="appraiser5DisableFlag"  style = "width: 60% !important" formControlName="appraiser5Point"  thumbLabel   [displayWith]="formatLabel"   tickInterval="1"  min="1"  color="primary" max="5"></mat-slider>
			      	
			    </div>
			    <div class="col-lg-2 paddingTop10px" *ngIf="appraisalCommentsForm.get('appraiser5Point').value > 0" >{{appraisalCommentsForm.get('appraiser5Point').value}} / 5</div>
			     <div class="col-lg-3">Attach Files<button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p>  </div>
	       </div>
	      	<div class="row col-lg-12">
	       		<div class="col-lg-12">
		      		<mat-form-field  class="col-lg-12" >
	      				<textarea  [readonly]="appraiser5DisableFlag" matInput rows="8" cols="200" placeholder="{{APRAISER_5_COMMENT}}" maxlength="5000"  formControlName="appraiser5Comments" onfocus="this.select()"></textarea>
	    			</mat-form-field>
	      		</div>
	      </div>
	    </ng-template>
	  </mat-tab>
</mat-tab-group>
<div class="col-lg-12 paddingTopBottom1pert">
<div class="col-lg-3 paddingTop10px">
	<button mat-raised-button color="primary" type="button" (click)="closePopup()">Done</button>
</div>
</div>
</mat-card>
</div>
</form>
</div>