<div>

<form   [formGroup]="myForm">
	<!-- <div *ngIf="moduleName === 'NEW_PO' && templateData.templateName !== 'PO Template'  && templateData.templateName !== 'PO Servmobi Template'"> -->
<div *ngIf="moduleName === 'NEW_PO' && templateData.xslTemplateName === 'PurchaseOrder_Template2.XSL'">
    <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div>
                      <label *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></label>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag">
                         <div class="col-lg-12 nopadding" *ngIf=" this.commonUtils.checkNullOrUndefined(organizationObj.defaultShippingLocationId.locationAddressLineOne) && organizationObj.defaultShippingLocationId.locationAddressLineOne != ''">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                         <div class="col-lg-12 nopadding" *ngIf=" this.commonUtils.checkNullOrUndefined(organizationObj.defaultShippingLocationId.locationCity) && organizationObj.defaultShippingLocationId.locationCity != ''">{{organizationObj.defaultShippingLocationId.locationCity}}</div>
                         <div class="col-lg-12 nopadding" *ngIf=" this.commonUtils.checkNullOrUndefined(organizationObj.defaultShippingLocationId.locationState) && organizationObj.defaultShippingLocationId.locationState != ''">{{organizationObj.defaultShippingLocationId.locationState}}</div>
                         <div class="col-lg-12 nopadding" *ngIf=" this.commonUtils.checkNullOrUndefined(organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue) && organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue != ''">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div><label style="font-size: 10pt;"><strong>Vendor Address</strong></label>
                      <div class="col-lg-12 nopadding">
                        <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                          <strong>{{t100ContactVendorId.contactDisplayName}}</strong>
                        </div>
                        <div class="col-lg-12 nopadding">{{myForm.controls.billingAttention.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.controls.billingAddressLineOne.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.controls.billingAddressLineTwo.value}}</div>
                        <div class="col-lg-12 nopadding">
                        {{myForm.controls.billingCity.value}} {{myForm.controls.billingState.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.get('t010BillingCountry').get('countryValue').value}}</div>
                      </div>
                    </div>  
                    <div class="marginTop5">
                      <label style="font-size: 10pt;"><strong >Deliver To</strong></label>
                      <div class="col-lg-12 nopadding">
                        <div class="col-lg-12 nopadding">{{myForm.controls.shippingAttention.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.controls.shippingAddressLineOne.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.controls.shippingAddressLineTwo.value}}</div>
                        <div class="col-lg-12 nopadding">
                        {{myForm.controls.shippingCity.value}} {{myForm.controls.shippingState.value}}</div>
                        <div class="col-lg-12 nopadding">{{myForm.get('t010ShippingCountry').get('countryValue').value}}</div>
                      </div>
                    </div>
                  </td>
                  <td class="alignRight" style="vertical-align:bottom;width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                           <!--   <tr>
                                <td class="alignRight">
                                  <span >Shipment Pref. :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{shipmentPref.name}}</span>
                                </td>
                            </tr> -->
                            <tr>
                                <td class="alignRight">
                                  <span>Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span>Delivery Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span >Ref# :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
          	<th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
        	<tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
	            <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
	            <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
	            <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
	            <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
	            <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
	            <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
	        
           </tr>
         
        </tbody>
        </table>
      </div>
      <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
        <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
     
    </div>
  <!--     <div *ngIf="moduleName === 'NEW_PO' && templateData.templateName === 'PO Template'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr><td>
                    <div><label style="font-size: 10pt;"><strong>Vendor Address</strong></label>
                      
                      <span> <p>
                         <span [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}"><strong>{{t100ContactVendorId.contactDisplayName}}</strong></span><br>
                         {{t100ContactVendorId.defaultBillingId.billingAddressLineOne}}
                        <br>{{t100ContactVendorId.defaultBillingId.billingCity}} {{t100ContactVendorId.defaultBillingId.billingState}}
                        <br>
                        {{t100ContactVendorId.defaultBillingId.t010BillingCountry.countryValue}}
                      </p></span>
                    </div>  </td>
                 <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody>
                             <tr>
                                <td class="alignRight"> <div>
                      <label  *ngIf="templateData.showOrgNameFlag" style="font-size: 12pt; text-align: right;"><strong>Bill To</strong></label><br>
                      <label *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></label>
                      <br>
                      <span style="font-size: 10pt; text-align: right;" *ngIf="templateData.showOrgAddressFlag" ><p >{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}
                        <br>{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}
                        <br>{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}
                      </p></span>
                    </div></td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                  
                    <div class="marginTop5">
                      <label style="font-size: 10pt;"><strong >Deliver To</strong></label>
                      <br>
                      <span><p >{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}
                        <br>{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}
                        <br>{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}
                      </p></span>
                    </div>
                  </td>
                  <td align="right" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Shipment Pref. :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{shipmentPref.name}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span>Delivery Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span >Ref# :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12 nopadding">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12 nopadding">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
     
    </div> -->

 <!--    Servmobi Template -->
<!-- <div *ngIf="moduleName === 'NEW_PO' && templateData.templateName === 'PO Servmobi Template'"> -->
  <div *ngIf="moduleName === 'NEW_PO' && templateData.xslTemplateName === 'Purchase_Order.XSL'">
   <div class="col-lg-12 alignCenter"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
    <table class="tableBorder width100Percent">
      <tr>
        <td class="nopadding">
      <div class="nopadding titleStyle" >
         <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}  </strong></span>
        </div>
    </td>
  </tr>
  <tr>
    <td class="nopadding">
      <div class="row text-white bg-dark nopadding borderNone">
            <div  class="col-lg-6 " >
               <div style="float: left;text-align: left;">
                  <span><p *ngIf="organizationObj.organizationName !='' && organizationObj.organizationName != null ">{{organizationObj.organizationName}}
                    <br>{{myForm.controls.billingAttention.value}}
                  <br>{{myForm.controls.billingAddressLineOne.value}}
                  <br>{{myForm.controls.billingAddressLineTwo.value}} 
                  <br>{{myForm.controls.billingCity.value}},{{myForm.controls.billingState.value}}-{{myForm.controls.billingZipCode.value}}
                  <br>Phone: {{myForm.controls.billingPhone.value}}
                  <br>Fax: {{myForm.controls.billingFax.value}}
                  </p></span>
                  </div>
          </div>
          <div class="col-lg-6 " style=" text-align: right">
           <span>
            <p style="padding-right: 11px;" *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> PO Number : {{myForm.controls.invoiceNumber.value}}</p> 
                <p style="padding-right: 18px;"> PO Date : {{myForm.controls.invoiceDate.value | dateFormatPipe}}</p>
               
          </span>
          </div>
        </div>
      </td>
    </tr>
       
      <tr>
        <td class="nopadding">
        <div class="col-lg-12 nopadding borderNone">
          <div  class="col-lg-6" >
              <label style="float: left;">Ship To:</label>
          </div>

        <div class="col-lg-6 nopadding" >
          <label style="float: left;">Vendor:</label>
        </div>
        </div>
      </td>
    </tr>
        
    <tr>
      <td class="nopadding">
          <div >
          <div class="col-lg-12 borderNone">
            <div class="col-lg-6 nopadding" >
              <span style="float: left;text-align: left;"><p>
                Contact Person:{{organizationObj.primaryContactName}}
                <br>{{myForm.controls.shippingAddressLineOne.value}}
                <br>{{myForm.controls.shippingAddressLineTwo.value}}
                <br>{{myForm.controls.shippingCity.value}},{{myForm.controls.shippingState.value}}-{{myForm.controls.shippingZipCode.value}}
                <br>Contact Number:{{myForm.controls.shippingPhone.value}}
                <span *ngIf="myForm.controls.shippingGstNumber.value != '' && myForm.controls.shippingGstNumber.value != null && 
                myForm.controls.shippingGstNumber.value != 'undefined' && myForm.controls.shippingGstNumber.value != undefined"><br>GST No:{{myForm.controls.shippingGstNumber.value}}</span>
                </p>
            </span>
            </div>
            <div class="col-lg-6 nopadding" style=" text-align: left">
             <span> <p>
                   <span [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">{{t100ContactVendorId.contactDisplayName}}</span>
                  <br>{{t100ContactVendorId.defaultBillingId.billingAddressLineOne}},{{t100ContactVendorId.defaultBillingId.billingAddressLineTwo}}
                  <br>{{t100ContactVendorId.defaultBillingId.billingCity}} {{t100ContactVendorId.defaultBillingId.billingState}}-{{t100ContactVendorId.defaultBillingId.billingZipCode}}
                  <br>Email:{{t100ContactVendorId.email}}
                  <br>Contact Name:{{t100ContactVendorId.defaultBillingId.billingAttention}}
                  <br>Mobile:{{t100ContactVendorId.defaultBillingId.billingPhone}}
                  <span *ngIf="t100ContactVendorId.defaultBillingId.billingGstNumber != '' && t100ContactVendorId.defaultBillingId.billingGstNumber != null 
                  && t100ContactVendorId.defaultBillingId.billingGstNumber != 'undefined' && t100ContactVendorId.defaultBillingId.billingGstNumber != undefined"> <br>GST No:{{t100ContactVendorId.defaultBillingId.billingGstNumber}} </span>
                  </p>  
          </span>
            </div>
            </div>
        </div>
      </td>
    </tr>
       
       <tr>
        <td class="nopadding">
        <div class="col-lg-12  nopadding" style="float: left;margin-top: 2px;background-color: #bdb8b8;border: thin solid;text-align: left;">
              <p><b>Required By : {{myForm.controls.dueDate.value | dateFormatPipe}}</b></p>
        </div>
      </td>
    </tr>
  </table>

        <table width="100%" style="border: thin solid; border-collapse: collapse;">
        <thead class="alignBorder" style="background-color: #bdb8b8; ">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignCenter alignBorder padding5px"  width="4%" >Sr.No</th>
            <th class="alignCenter alignBorder padding5px"  width="20%">Product</th>
            <th class="alignCenter alignBorder padding5px" width="15%">Modal</th>
            <th class="alignCenter alignBorder padding5px" width="20%">Part Required </th>
            <th class="alignCenter alignBorder padding5px" width="10%">Brand</th>
            <th class="alignCenter alignBorder padding5px" width="10%">Qty</th>
            <th class="alignCenter alignBorder padding5px" width="15%">Amount</th>
          </tr>
        </thead>
        <tbody class="alignBorder" width="4%" [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignCenter alignBorder">{{idx+1}}</td>
             <td  class="alignCenter alignBorder padding5px" width="20%" >{{lineitem.itemName}} </td>
              <td  class="alignCenter alignBorder padding5px"width="20%" >{{lineitem.t200_Item.t055ModelObj.modelName}}</td>
              <td class="alignCenter alignBorder padding5px" width="20%"> {{lineitem.t200_Item.customValue5}}</td>
              <td class="alignCenter alignBorder padding5px" width="15"> 
              {{lineitem.t200_Item.t054brandObj.brandName}} </td>
              <td class="alignRight alignBorder padding5px"  width="10%">{{lineitem.quantity}}</td>  
              <td class="alignRight alignBorder padding5px"  width="10%">{{lineitem.amount | myCurrency}}</td>
           </tr>
             <tr class="alignBorder" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
                <td class="alignRight alignBorder padding5px" colspan="5"><span>Sub Total</span></td>
                <td class="alignRight alignBorder padding5px"><span>{{myForm.controls.totalOrderedQuantity.value }}</span></td>
                <td  class="alignRight alignBorder padding5px" class="pdfPreviewSubTotal"><span>{{myForm.controls.subTotal.value  | myCurrency}}</span></td>
              </tr>

            <tr class="tableBorder alignBorder">
               <td  colspan="7" class=" tableBorder paddingBottom25"></td>
           </tr>
           
             <tr *ngIf="myForm.controls.invoiceHeaderId.value != 0" class="tableBorder alignBorder"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
               <td  colspan="7" class=" tableBorder alignLeft padding5px"><span>Rupees: {{myForm.controls.amountInWords.value}}</span></td>
           </tr>

          <tr class="tableBorder alignBorder" style="background-color: #bdb8b8">
            <td colspan="7" class="alignBorder paddingBottom25"></td>
          </tr>

          <tr class="tableBorder alignBorder">
               <td  colspan="3" class="alignBorder text"><p>Remarks:{{myForm.controls.remarks.value}}</p></td>
               <td  colspan="4" class="text"><p>Annexure:{{myForm.controls.annexure.value}}</p></td>
          </tr>

    <tr>
        <td clss="alignBorder"colspan="3">
         <div class="nopadding">
              <div  style="text-align:left"><strong >Terms & Conditions</strong></div>
               <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
              <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
              <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
            </div>
           </div>
          </td>
          
          <td colspan="4">
            <div>
               For SERMOBI Technologies Pvt Ltd <br><br><br>
               Authosrised Signatory
            </div>
        </td>
    </tr> 
  </tbody>
</table>
   
 </div>
</div>
      <!-- servmobi Template Ends-->
     <div *ngIf="moduleName === 'NEW_BILL'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
             <div *ngIf="myForm.controls.balanceDue.value != '0'"class="col-lg-12">    
              <span class="fontSize14">Balance Due</span>
              <p class="fontSize14"><strong >{{t100ContactVendorId.t003Currency.symbol}} {{myForm.controls.balanceDue.value | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                     <div  *ngIf="templateData.showOrgNameFlag" class="col-lg-12 nopadding"> 
                    <div class="col-lg-12 nopadding"  [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}"><strong >{{organizationObj.organizationName}}</strong>
                      </div>
                      <div *ngIf="templateData.showOrgAddressFlag" class="col-lg-12 nopadding">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>

                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 15pt;"><strong>Bill From</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding"><strong>{{t100ContactVendorId.contactDisplayName}}</strong></div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingCity}} {{t100ContactVendorId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.t010BillingCountry.countryValue}}</div>

                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Bill Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Due Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span>Terms :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{terms.lookupValue}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span >Order Number :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>
    <div *ngIf="moduleName === 'NEW_VENDOR_CREDIT'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
            <div *ngIf="myForm.controls.creditBalance.value != '0'"class="col-lg-12">    
              <span class="fontSize14">Credits Remaining</span>
              <p class="fontSize14"><strong >{{t100ContactVendorId.t003Currency.symbol}} {{myForm.controls.creditBalance.value  | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                     <div class="col-lg-12 nopadding">
                       <div *ngIf="templateData.showOrgNameFlag" class="col-lg-12 nopadding"  [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}"> <strong >{{organizationObj.organizationName}}</strong></div>
                      <div  *ngIf="templateData.showOrgAddressFlag"  class="col-lg-12 nopadding">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                    </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" style="font-size: 10pt;"><strong>Vendor Address</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding">
                        <strong>{{t100ContactVendorId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingCity}} {{t100ContactVendorId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">
                        {{t100ContactVendorId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            
                            <tr>
                                 <td class="alignRight">
                                    <span >Reference# :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>
   <div *ngIf="moduleName === 'NEW_ESTIMATE' && templateData.xslTemplateName === 'Estimate_Order.XSL'"> 
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag" >
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                       <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong> </div>
                        <div class="col-lg-12 nopadding"> {{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Estimate Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Expiry Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2" style="border-bottom: 1px solid black;">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th> 
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td> 
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>
   <div *ngIf="moduleName === 'NEW_ESTIMATE' && templateData.xslTemplateName === 'Estimate_Order_FO.XSL'">
      <div class="col-lg-12 " style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag" >
                    <div class="col-lg-12 nopadding">
                        {{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                    </div>  
                  </td>
                   <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Estimate Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Expiry Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2"  style="border-bottom: 1px solid black;">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="8%">Engraved</th>
            <th class="alignCenter" width="8%">Blank</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th> 
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}} {{lineitem.itemDescription}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td *ngIf="lineitem.customValue1.value != ''" class="alignRight" width="8%">{{lineitem.customValue1}}</td>
               <td *ngIf="lineitem.customValue1.value === ''" class="alignRight" width="8%">0.00</td>
              <!-- <td class="alignRight" width="8%">{{lineitem.customValue2}}</td> -->
              <td *ngIf="lineitem.customValue2.value != ''" class="alignRight" width="8%">{{lineitem.customValue2}}</td>
               <td *ngIf="lineitem.customValue2.value === ''" class="alignRight" width="8%">0.00</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
               <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td> 
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>
  <!-- <div *ngIf="moduleName === 'NEW_SALESORDER' && templateData.xslTemplateName === 'Sales Order Template'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div>
                      <label *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></label>
                      <br>
                      <span *ngIf="templateData.showOrgAddressFlag" ><p>{{myForm.controls.billingAddressLineOne.value}}
                        <span *ngIf="myForm.controls.billingAddressLineTwo.value != '' 
                        && myForm.controls.billingAddressLineTwo.value != null"><br>{{myForm.controls.billingAddressLineTwo.value}} </span>
                        <br>{{myForm.controls.billingCity.value}},{{myForm.controls.billingState.value}}
                        <br>{{myForm.controls.billingZipCode.value}}
                         <br>{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}} 
                        <br><span *ngIf="myForm.controls.billingGstNumber.value != '' && myForm.controls.billingGstNumber.value != null && 
                        myForm.controls.billingGstNumber.value != 'undefined' && myForm.controls.billingGstNumber.value != undefined">
                           GST No:{{myForm.controls.billingGstNumber.value}} </span>
                      </p></span>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div><label style="font-size: 12pt;"><strong>Bill To</strong></label>
                      
                      <span> <p>
                         <span [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></span><br>
                         {{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}
                        <br>{{t100ContactCustomerId.defaultBillingId.billingCity}} ,{{t100ContactCustomerId.defaultBillingId.billingState}}
                        <br>{{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}
                        <br><span *ngIf="t100ContactCustomerId.defaultBillingId.billingGstNumber != '' && t100ContactCustomerId.defaultBillingId.billingGstNumber != null &&
                        t100ContactCustomerId.defaultBillingId.billingGstNumber != 'undefined' && t100ContactCustomerId.defaultBillingId.billingGstNumber != undefined">
                         GST No:{{t100ContactCustomerId.defaultBillingId.billingGstNumber}} </span>
                      </p></span>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Order Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Shipment Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12 nopadding">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12 nopadding">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div> -->
     <div *ngIf="moduleName === 'NEW_SALESORDER' && templateData.xslTemplateName === 'Sales_Order_FO.XSL'">
      <div class="col-lg-12 "  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important;display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag" >
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
                   <td class="alignRight" style="width: 50%;">
                    
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Order Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Shipment Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table> 
                  </div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed; width: 85%;" >
               <tbody>
                <tr>
                  <td style="width: 50%;">
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.billingAddressLineOne != null 
                         && t100ContactCustomerId.defaultBillingId.billingAddressLineOne != ''
                         && t100ContactCustomerId.defaultBillingId.billingAddressLineOne != undefined" >
                         {{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="( t100ContactCustomerId.defaultBillingId.billingCity != null 
                        && t100ContactCustomerId.defaultBillingId.billingCity != '' 
                        && t100ContactCustomerId.defaultBillingId.billingCity != undefined ) ||
                        (t100ContactCustomerId.defaultBillingId.billingState != null &&
                        t100ContactCustomerId.defaultBillingId.billingState != ''
                        && t100ContactCustomerId.defaultBillingId.billingState != undefined)
                        ">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.t010BillingCountry != null && t100ContactCustomerId.defaultBillingId.t010BillingCountry != '' && t100ContactCustomerId.defaultBillingId.t010BillingCountry != undefined && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != '' && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != null 
                        && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != undefined">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                  </td><td style="width: 25%;"></td>
                  <td style="width: 25%;">
                   <div class="col-lg-12 nopadding">    
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Ship To</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="dropShipAddress.shippingAttention != null && dropShipAddress.shippingAttention != '' && dropShipAddress.shippingAttention != 'undefined' && dropShipAddress.shippingAttention != undefined" >{{dropShipAddress.shippingAttention}}</div>
                    <div class="col-lg-12 nopadding" *ngIf="dropShipAddress.shippingAddressLineOne != null && dropShipAddress.shippingAddressLineOne != '' && dropShipAddress.shippingAddressLineOne != 'undefined' && dropShipAddress.shippingAddressLineOne != undefined">{{dropShipAddress.shippingAddressLineOne}}</div>
                    <div class="col-lg-12 nopadding"  *ngIf="dropShipAddress.shippingAddressLineTwo != null && dropShipAddress.shippingAddressLineTwo !='' && dropShipAddress.shippingAddressLineTwo!='undefined' && dropShipAddress.shippingAddressLineTwo!=undefined">{{dropShipAddress.shippingAddressLineTwo}}</div>
                      <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingCity != null && dropShipAddress.shippingCity != '' ">
                        {{dropShipAddress.shippingCity}}</div>
                    <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingState != null && dropShipAddress.shippingState != '' ">
                      {{dropShipAddress.shippingState}}</div>
      <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingZipCode != null && 
       dropShipAddress.shippingZipCode != '' "> {{dropShipAddress.shippingZipCode}}</div>
      <div class="col-lg-12 nopadding"  *ngIf="(dropShipAddress.t010ShippingCountry != null && dropShipAddress.t010ShippingCountry !='' && dropShipAddress.t010ShippingCountry != 'undefined' && dropShipAddress.t010ShippingCountry !=undefined) && (dropShipAddress.t010ShippingCountry.countryValue != null && dropShipAddress.t010ShippingCountry.countryValue !='' && dropShipAddress.t010ShippingCountry.countryValue != 'undefined' && dropShipAddress.t010ShippingCountry.countryValue != undefined)">{{dropShipAddress.t010ShippingCountry.countryValue}}</div>
                    </div> </td></tr></tbody></table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="5%"></th>
            <th class="alignLeft" width="5%">#</th>
            <th class="alignLeft" width="10%">SKU #</th>
            <th class="alignLeft" width="25%">Item  Name</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="10%">Qty</th>
            <th class="alignLeft" width="5%"></th> </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">
            <td  class="alignLeft" width="5%"> </td>
            <td  class="alignLeft" width="5%">{{idx+1}}</td>
              <td  class="alignLeft" width="10%">{{lineitem.t200_Item.sku}}</td>
              <td  class="alignLeft" width="25%">{{lineitem.itemName}}</td>
                <td  class="alignLeft" width="35%">{{lineitem.itemDescription}}</td>
              <td class="alignRight" width="10%">{{lineitem.quantity}}</td>
              <td  class="alignLeft" width="5%"></td>
          </tr>
         
        </tbody>
        </table>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div class="col-lg-12 nopadding">
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
       <!--  <br> -->
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div class="col-lg-12 nopadding">
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
        <!-- <br> -->
      </div>
  </div>
   <div *ngIf="moduleName === 'NEW_SALESORDER' && templateData.xslTemplateName === 'Sales_Order_FO_Amount.XSL'">
      <div class="col-lg-12 "  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important;display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
                   <td class="alignRight" style="width: 50%;">
                    
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Order Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Shipment Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table> 
                  </div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed; width: 85%;" >
               <tbody>
                <tr>
                  <td style="width: 50%;">
                   <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong> </div>
                         <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.billingAddressLineOne != null 
                         && t100ContactCustomerId.defaultBillingId.billingAddressLineOne != ''
                         && t100ContactCustomerId.defaultBillingId.billingAddressLineOne != undefined" >
                         {{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="( t100ContactCustomerId.defaultBillingId.billingCity != null 
                        && t100ContactCustomerId.defaultBillingId.billingCity != '' 
                        && t100ContactCustomerId.defaultBillingId.billingCity != undefined ) ||
                        (t100ContactCustomerId.defaultBillingId.billingState != null &&
                        t100ContactCustomerId.defaultBillingId.billingState != ''
                        && t100ContactCustomerId.defaultBillingId.billingState != undefined)
                        ">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.t010BillingCountry != null && t100ContactCustomerId.defaultBillingId.t010BillingCountry != '' && t100ContactCustomerId.defaultBillingId.t010BillingCountry != undefined && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != '' && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != null 
                        && t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue != undefined">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                    </div>  
                  </td><td style="width: 25%;"></td>
                  <td style="width: 25%;">
                     <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Ship To</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="dropShipAddress.shippingAttention != null && dropShipAddress.shippingAttention != '' && dropShipAddress.shippingAttention != 'undefined' && dropShipAddress.shippingAttention != undefined" >{{dropShipAddress.shippingAttention}}</div>
                      <div class="col-lg-12 nopadding" *ngIf="dropShipAddress.shippingAddressLineOne != null && dropShipAddress.shippingAddressLineOne != '' && dropShipAddress.shippingAddressLineOne != 'undefined' && dropShipAddress.shippingAddressLineOne != undefined">{{dropShipAddress.shippingAddressLineOne}}</div>
                       <div class="col-lg-12 nopadding" *ngIf="dropShipAddress.shippingAddressLineTwo != null && dropShipAddress.shippingAddressLineTwo !='' && dropShipAddress.shippingAddressLineTwo!='undefined' && dropShipAddress.shippingAddressLineTwo!=undefined">{{dropShipAddress.shippingAddressLineTwo}}</div>
                       <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingCity != null && 
                       dropShipAddress.shippingCity != '' ">
                       {{dropShipAddress.shippingCity}}</div>
                       <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingState != null && 
                       dropShipAddress.shippingState != '' ">
                      {{dropShipAddress.shippingState}}</div>
                      <div class="col-lg-12 nopadding" *ngIf=" dropShipAddress.shippingZipCode != null && 
                       dropShipAddress.shippingZipCode != '' ">
                      {{dropShipAddress.shippingZipCode}}</div>
                      <div class="col-lg-12 nopadding" *ngIf="(dropShipAddress.t010ShippingCountry != null && dropShipAddress.t010ShippingCountry !='' && dropShipAddress.t010ShippingCountry != 'undefined' && dropShipAddress.t010ShippingCountry !=undefined) && (dropShipAddress.t010ShippingCountry.countryValue != null && dropShipAddress.t010ShippingCountry.countryValue !='' && dropShipAddress.t010ShippingCountry.countryValue != 'undefined' && dropShipAddress.t010ShippingCountry.countryValue != undefined)">{{dropShipAddress.t010ShippingCountry.countryValue}}</div>
                    </div> </td></tr></tbody></table>
             </div>
          </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="5%"></th>
            <th class="alignLeft" width="5%">#</th>
            <th class="alignLeft" width="10%">SKU #</th>
            <th class="alignLeft" width="15%">Item  Name</th>
            <th class="alignLeft" width="25%">Item  Description</th>
            <th class="alignCenter" width="10%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th class="alignCenter" width="10%">Amount</th> 
              <th class="alignLeft" width="5%"></th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">
            <td  class="alignLeft" width="5%"> </td>
            <td  class="alignLeft" width="5%">{{idx+1}}</td>
              <td  class="alignLeft" width="10%">{{lineitem.t200_Item.sku}}</td>
              <td  class="alignLeft" width="15%">{{lineitem.itemName}}</td>
                <td  class="alignLeft" width="25%">{{lineitem.itemDescription}}</td>
              <td class="alignRight" width="10%">{{lineitem.quantity}}</td>
             <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
            <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td> 
             <td  class="alignLeft" width="5%"></td>
           </tr>
         
        </tbody>
        </table>
      </div>
       <div class="row col-lg-12 pdfPreviewSubTotal">
        <div class="col-lg-6"></div>
        <div class="col-lg-6 nopadding">
          <table cellspacing="0" border="0" width="100%">
            <tbody>
              <tr style="height:40px;">
                <td class="alignRight"><span [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></span></td>
                <td  class="alignRight"  class="pdfPreviewSubTotal"><span [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
      <div class="col-lg-12 " style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div class="col-lg-12 nopadding">
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
       <!--  <br> -->
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div class="col-lg-12 nopadding">
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
        <!-- <br> -->
      </div>
  </div>
    <div *ngIf="moduleName === 'NEW_INVOICE' && templateData.xslTemplateName === 'Invoice_Order.XSL'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
             <div *ngIf="myForm.controls.amountDue.value > 0"class="col-lg-12">    
              <span class="fontSize14">Balance Due</span>
              <p class="fontSize14"><strong >{{t100ContactCustomerId.t003Currency.symbol}} {{myForm.controls.amountDue.value | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineTwo}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationZipCode}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="organizationObj.defaultShippingLocationId.gstNumber != ''&& organizationObj.defaultShippingLocationId.gstNumber != null && organizationObj.defaultShippingLocationId.gstNumber != 'undefined'&& organizationObj.defaultShippingLocationId.gstNumber != undefined"> GST No:{{organizationObj.defaultShippingLocationId.gstNumber}} </div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                     <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                      <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAttention}}</div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.billingGstNumber != '' && t100ContactCustomerId.defaultBillingId.billingGstNumber != null && 
                        t100ContactCustomerId.defaultBillingId.billingGstNumber != 'undefined' && t100ContactCustomerId.defaultBillingId.billingGstNumber != undefined">GST No:{{t100ContactCustomerId.defaultBillingId.billingGstNumber}} ></div>
                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Invoice Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span>Terms :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{terms.lookupValue}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Due Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >SO # :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Name</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}
              </td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
     <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12 " style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12 " style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>
<!-- sales receipt -preview screen-starts -->
    <div *ngIf="moduleName === 'NEW_SALES_RECEIPT' && templateData.xslTemplateName === 'Sales_Receipt.XSL'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
          <!--    <div *ngIf="myForm.controls.amountDue.value > 0"class="col-lg-12">    
              <span class="fontSize14">Balance Due</span>
              <p class="fontSize14"><strong >{{t100ContactCustomerId.t003Currency.symbol}} {{myForm.controls.amountDue.value | myCurrency}}</strong></p>
            </div> -->
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineTwo}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationZipCode}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="organizationObj.defaultShippingLocationId.gstNumber != ''&& organizationObj.defaultShippingLocationId.gstNumber != null && organizationObj.defaultShippingLocationId.gstNumber != 'undefined'&& organizationObj.defaultShippingLocationId.gstNumber != undefined"> GST No:{{organizationObj.defaultShippingLocationId.gstNumber}} </div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                     <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                      <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAttention}}</div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                        <div class="col-lg-12 nopadding" *ngIf="t100ContactCustomerId.defaultBillingId.billingGstNumber != '' && t100ContactCustomerId.defaultBillingId.billingGstNumber != null && 
                        t100ContactCustomerId.defaultBillingId.billingGstNumber != 'undefined' && t100ContactCustomerId.defaultBillingId.billingGstNumber != undefined">GST No:{{t100ContactCustomerId.defaultBillingId.billingGstNumber}} ></div>
                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 55%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span > Sales Receipt Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                           <!--  <tr>
                                <td class="alignRight">
                                  <span>Due Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr> -->
                            <tr>
                                 <td class="alignRight"><span >Reference # :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                              <tr>
                                 <td class="alignRight">
                                    <span>Payment Mode :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <!-- <span>{{myForm.controls.t023PaymentMode.controls.name}}</span> -->
                                     <span>{{t023PaymentMode.name}}</span> 
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Name</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}
              </td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
     <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12 " style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12 " style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>


   <div *ngIf="moduleName === 'NEW_INVOICE' && templateData.xslTemplateName === 'Invoice_Order_FO.XSL'">
      <div class="col-lg-12" style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
            <div *ngIf="myForm.controls.amountDue.value > 0"class="col-lg-12">    
              <span class="fontSize14">Balance Due</span>
              <p class="fontSize14"><strong >{{t100ContactCustomerId.t003Currency.symbol}} {{myForm.controls.amountDue.value | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong></div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag" > 
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">
                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                    </div>  
                  </td>
                   <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 68%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Invoice Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight">
                                    <span>Terms :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{terms.lookupValue}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="alignRight">
                                  <span>Due Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >SO # :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
    <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.totalTaxValue.value  != 0">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
        <br>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <p><strong >Terms & Conditions</strong></p>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
        <br>
      </div>
  </div>
  <div *ngIf="moduleName === 'NEW_CREDIT_NOTES'">
      <div class="col-lg-12 " style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [style.font-size.px]="templateData.docInfoFontSize"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
             <div *ngIf="myForm.controls.creditBalance.value != '0'"class="col-lg-12">    
              <span class="fontSize14">Credits Remaining</span>
              <p class="fontSize14"><strong >{{t100ContactCustomerId.t003Currency.symbol}} {{myForm.controls.creditBalance.value  | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding">
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgNameFlag" [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}" ><strong >{{organizationObj.organizationName}}</strong> </div>
                      <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgAddressFlag"><div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>
                    <div class="col-lg-12 nopadding"><div class="col-lg-12 nopadding" style="font-size: 12pt;"><strong>Bill To</strong></div>
                     <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" 
                     [ngStyle]="{'color':templateData.vendorNameFontColor}">
                      <div class="col-lg-12 nopadding"><strong>{{t100ContactCustomerId.contactDisplayName}}</strong></div>
                         <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactCustomerId.defaultBillingId.billingCity}} {{t100ContactCustomerId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">                        {{t100ContactCustomerId.defaultBillingId.t010BillingCountry.countryValue}}</div>
                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 58%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Credit Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                            <tr>
                                 <td class="alignRight"><span >Ref# :</span></td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
   <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <div><strong >Terms & Conditions</strong></div>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}></div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>

   <div *ngIf="moduleName === 'NEW_REFUND_RECEIPT'">
      <div class="col-lg-12"  style="padding-left: 25px; padding-right: 25px; padding-top: 25px;">
        <div class="row">
          <div class="col-lg-6 nopadding">
            <div class="col-lg-12 nopadding" *ngIf="templateData.showOrgLogoFlag">
            <img src="{{orgImgPath}}/{{portalName}}/{{organizationObj.logoPath}}" style="max-width: 240px !important;max-height: 166px !important; border: 1px solid; display: block;">
            </div>
          </div>
          <div class="col-lg-6 nopadding" style="text-align: right">
            <div class="col-lg-12">    
              <span [style.font-size.px]="templateData.docTitleFontSize" [ngStyle]="{'color': templateData.docTitleFontColor }"><strong >{{templateData.reportTitle}}</strong></span>
              <p *ngIf="myForm.controls.invoiceNumber.value != '' && myForm.controls.invoiceNumber.value != null" [ngStyle]="{'font-family': templateData.t004LookupPDFFont.lookupValue}"> <strong ># {{myForm.controls.invoiceNumber.value}}</strong></p>
            </div>
             <div *ngIf="myForm.controls.balanceDue.value != '0'"class="col-lg-12">    
              <span class="fontSize14">Balance Due</span>
              <p class="fontSize14"><strong >{{t100ContactVendorId.t003Currency.symbol}} {{myForm.controls.balanceDue.value | myCurrency}}</strong></p>
            </div>
          </div>
        </div>
        <div class="row marginTop2">
        <div class="col-lg-12">
            <table style="table-layout:fixed;">
               <tbody>
                <tr>
                  <td>
                     <div  *ngIf="templateData.showOrgNameFlag" class="col-lg-12 nopadding"> 
                    <div class="col-lg-12 nopadding"  [style.font-size.px]="templateData.orgNameFontSize" [ngStyle]="{'color':templateData.orgNameFontColor}"><strong >{{organizationObj.organizationName}}</strong>
                      </div>
                      <div *ngIf="templateData.showOrgAddressFlag" class="col-lg-12 nopadding">
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.locationCity}} {{organizationObj.defaultShippingLocationId.locationState}}</div>
                        <div class="col-lg-12 nopadding">{{organizationObj.defaultShippingLocationId.t010locationCountryObj.countryValue}}</div>
                      </div>
                    </div>
                  </td>
              </tr>
          </tbody>
      </table></div></div>

        <div class="row marginTop2">
          <div class="col-lg-12">
            <table style="table-layout:fixed;" >
               <tbody>
                <tr>
                  <td>

                    <div class="col-lg-12 nopadding">
                    <div class="col-lg-12 nopadding" style="font-size: 15pt;"><strong>Bill From</strong></div>
                      <div class="col-lg-12 nopadding" [style.font-size.px]="templateData.vendorNameFontSize" [ngStyle]="{'color':templateData.vendorNameFontColor}">
                        <div class="col-lg-12 nopadding"><strong>{{t100ContactVendorId.contactDisplayName}}</strong></div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingAddressLineOne}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.billingCity}} {{t100ContactVendorId.defaultBillingId.billingState}}</div>
                        <div class="col-lg-12 nopadding">{{t100ContactVendorId.defaultBillingId.t010BillingCountry.countryValue}}</div>

                      </div>
                    </div>  
                  </td>
                  <td class="alignRight" style="width: 50%;">
                     <table style="float:right;width: 75%;table-layout: fixed;word-wrap: break-word;" border="0" cellspacing="0" cellpadding="0">
                         <tbody [style.font-size.px]="templateData.docInfoFontSize" [ngStyle]="{'color':templateData.docInfoFontColor}">
                             <tr>
                                <td class="alignRight">
                                  <span >Refund Receipt Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span >{{myForm.controls.invoiceDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr>
                          <!--   <tr>
                                <td class="alignRight">
                                  <span>Due Date :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                  <span>{{myForm.controls.dueDate.value | dateFormatPipe}}</span>
                                </td>
                            </tr> -->
                           <!--  <tr>
                                 <td class="alignRight">
                                    <span>Terms :</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{terms.lookupValue}}</span>
                                </td>
                            </tr> -->
                            <tr>
                                 <td class="alignRight">
                                    <span >Ref #:</span>
                                </td>
                                <td class="alignLeft paddingLeftRight">
                                    <span>{{myForm.controls.refNumber.value}}</span>
                                </td>
                            </tr>
                         </tbody>
                      </table>
                  </td>
               </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12 marginTop2">
        <table width="100%">
        <thead style="line-height: 2.5!important;">
          <tr  [style.font-size.px]="templateData.itemHeaderFontSize" [ngStyle]="{'background-color': templateData.itemHeaderBgColor, 'color':templateData.itemHeaderFontColor}">
            <th class="alignLeft" width="4%">#</th>
            <th class="alignLeft" width="35%">Item  Description</th>
            <th class="alignCenter" width="8%">Qty</th>
            <th class="alignCenter" width="10%">Rate </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="8%">Tax% </th>
            <th *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignCenter" width="10%">Tax</th>
            <th class="alignCenter" width="10%">Amount</th>
          </tr>
        </thead>
        <tbody [style.font-size.px]="templateData.itemRowFontSize" [ngStyle]="{'background-color': templateData.itemRowBgColor, 'color':templateData.itemRowFontColor}">
          <tr style="line-height: 1.5 !important;" *ngFor="let lineitem of lineItemList; index as idx">  <td  class="alignLeft" width="4%">{{idx+1}}</td>
              <td  class="alignLeft" width="35%">{{lineitem.itemName}}</td>
              <td class="alignRight" width="8%">{{lineitem.quantity}}</td>
              <td class="alignRight" width="10%">{{lineitem.rate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="8%">{{lineitem.lineitemtaxrate | myCurrency}}</td>
              <td *ngIf="myForm.controls.taxAtLineLevel.value === true" class="alignRight" width="10%">{{lineitem.lineitemtaxvalue | myCurrency}}</td>
              <td class="alignRight" width="10%">{{lineitem.amount | myCurrency}}</td>
          
           </tr>
         
        </tbody>
        </table>
      </div>
         <div  class="col-lg-12" style="margin-top: 2%; line-height: 1.8">
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
              Sub Total
          </div>
        <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.subTotal.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Tax Total</span>
          </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.totalTaxValue.value  | myCurrency}} </div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding"  *ngIf="myForm.controls.discountValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;" [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
          <span class="alignLeft">Discount</span>
        </div>
            <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.discountValue.value  | myCurrency}}</div>
        </div>
      </div>
     <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding" *ngIf="myForm.controls.adjustmentFinalValue.value != '0'" >
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"   [style.font-size.px]="templateData.subTotalFontSize" [ngStyle]="{'color':templateData.subTotalFontColor}">
         <span *ngIf="myForm.controls.adjustmentName.value!=''" class="alignRight"><span>{{myForm.controls.adjustmentName.value}}</span></span>
                <span *ngIf="myForm.controls.adjustmentName.value===''" class="alignRight"><span>Adjustments</span>
              </span>
            </div>
               <div class="col-lg-3" style="text-align: right; padding-right: 0px;">{{myForm.controls.adjustmentFinalValue.value  | myCurrency}} </div>
        </div>
      </div>
      <div class="row col-lg-12 nopadding" >
        <div class="col-lg-6"></div>
      <div class="row col-lg-6 nopadding">
         <div class="col-lg-3"></div>
          <div class="col-lg-6" style="padding-left: 26%;"  [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >Total</strong></div>
                 <div class="col-lg-3" style="text-align: right; padding-right: 0px;" [style.font-size.px]="templateData.totalFontSize" [ngStyle]="{'color':templateData.totalFontColor}"><strong >{{myForm.controls.finalTotal.value  | myCurrency}}</strong></div>
          </div>
        </div>
        </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Notes</strong>
        </div>
        <div  [style.font-size.px]="templateData.notesFontSize" [ngStyle]="{'color':templateData.notesFontColor}">
        <div *ngIf="myForm.controls.customerNotes.value != ''">{{myForm.controls.customerNotes.value}}</div>
         <div *ngIf="myForm.controls.customerNotes.value===''">{{templateData.notesTitle}}</div>
        </div>
      </div>
      <div class="col-lg-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
        <div>
        <strong >Terms & Conditions</strong>
        </div>
        <div  [style.font-size.px]="templateData.termsFontSize" [ngStyle]="{'color':templateData.termsFontColor}">
        <div *ngIf="myForm.controls.termsandcondition.value != ''">{{myForm.controls.termsandcondition.value}}</div>
         <div *ngIf="myForm.controls.termsandcondition.value===''">{{templateData.termsTitle}}</div>
        </div>
      </div>
  </div>

  
	</form>

</div>
<div *ngIf="noTemplateFlag === true">
  <div class="col-lg-12 nopadding">No template found for preview.<br/><br/></div>
</div>
<footer>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-top: 2% !important; padding-left: 0px;">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <button mat-raised-button color="primary" type="button" (click)="closeBtnClick()">{{CLOSE_BUTTON}}</button>
      </div>
    </div>
  </footer>