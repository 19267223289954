import { Component, OnInit, Inject} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {FileUploadComponent} from '../../dialogBox/fileupload/file-upload.component';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component';

@Component({
  selector: 'app-createTicket',
  templateUrl: './createTicket.component.html'
})
export class CreateTicketComponent extends CommonUtils implements OnInit {

  ticketForm: FormGroup;
  loggedInUserId;
  loggedInUserName;
  organizationId;
  loggedInEmail;
  moduleName:string = environment.NEW_ISSUES;
  constructor(public dialogRef: MatDialogRef<CreateTicketComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  	private commonService: CommonService, private commonUtils: CommonUtils, private dialog: MatDialog,
    private router: Router) { 
  	super();
  	this.ticketForm = new FormGroup({
  		projectId: new FormControl(0),
      taskName: new FormControl(''),
  		taskDesc: new FormControl(''),
  		t001_UserObj: new FormGroup({userName: new FormControl(''), userId: new FormControl(0),
  		t000Organization: new FormGroup({organizationId : new FormControl(0)})}),
  		createdTs: new FormControl(new Date()),
  		moduleName: new FormControl(this.moduleName),
      statusText: new FormControl('OPEN'),
      portalName : new FormControl(''),
      createdBy : new FormControl(''),
      createdByEmail:new FormControl(''),
      taskNumber: new FormControl('')

  	})
  }

  ngOnInit() {
  	this.loggedInUserId = this.commonService.loggedInUserObj.userId;
  	this.organizationId = this.commonService.loggedInUserObj.t000Organization.organizationId;
    this.loggedInUserName = this.commonService.loggedInUserObj.userName;
     this.loggedInEmail = this.commonService.loggedInUserObj.email;
  }

  cancelClick(): void{
  	this.dialogRef.close();
  }

  saveTicket(): void{
    let userDescription=this.ticketForm.get('taskDesc').value.trim();
   if(this.commonUtils.checkNullOrUndefined(userDescription) && userDescription !="")
   {
     this.ticketForm.get('portalName').setValue(this.commonService.loggedInUserObj.t000Organization.portalName);
    this.ticketForm.get('createdBy').setValue(this.loggedInUserName);
    this.ticketForm.get('t001_UserObj.userId').setValue(this.loggedInUserId);
    this.ticketForm.get('createdByEmail').setValue(this.loggedInEmail);
    this.commonService.showProgressBar();
     this.commonService.createTicket(this.ticketForm.value).
      subscribe(
       data => 
       {
         if(this.commonUtils.checkNullOrUndefined(data))
         {
            this.commonService.hideProgressBar();
            this.commonService.showERPMessage('Your ticket has been raised successfully and the ticket # is ' +data.taskNumber+ ".");
         }    
             this.dialogRef.close();
    
       },
       error => {
         this.commonService.showERPErrorMessage(error);
         this.dialogRef.close(true);
       });
   }
    
  }

  loadTickets()
  {
    this.dialogRef.close();
    this.router.navigate(['/settings/reviewTickets']);
  }
}
