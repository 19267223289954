import {Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import {CommonService} from '../../shared/common.service';
import {Router} from '@angular/router';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
import {HrmService} from '../../hrm/hrm-service';
import {UnapprovedLeavesDialog} from '../applyleave/unapprovedLeaves.component';

@Component({
  selector: 'app-applyleave',
  templateUrl: './applyleave.component.html'

})
export class ApplyLeaveComponent extends CommonUtils implements OnInit {
  employeeObj;
  approverId;
  currentlySelectedMenu;
  date: FormControl = new FormControl();
  moduleName : string = environment.APPLY_LEAVE;
  editAccess =true;
  currentDate= new Date();
  employeeId:number=0;
  userName:string;
  leaveId:number=0;
  ELEMENT_DATA: Element[] = [];
  START_DATE;
  END_DATE;
  displayedColumns = [];
  yearList = [];
  leaveTypeList = [];
  clAvail:string;
  slAvail:string;
  totalAvail:string;
  compOffAvail:string;
  slUsed:string;
  clUsed:string;
  compOffUsed:string;
  lop:string;
  permissionApplied:string;
  permissionHours:string;
  permissionFlag:boolean = false;
  noRecordFound:boolean = false;
  monthInYear=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  maxEndDate =  new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  currentPageAccessObj;
//pie chart
  pieChartLabels:string[] = ['SL Used', 'CL Used', 'CompOff Used', 'LOP', 'Permission' ];
  pieChartData:any[] = [];
  pieChartType:string = 'pie';
  validationListForSelectedModule=[];
  
  chartClicked(e:any):void {
   
   
  }
 
  chartHovered(e:any):void {
   
   
   
  }

  employeeForm=new FormGroup({
   employeeId: new FormControl(0),
   userName: new FormControl(''),
  })
  displayForm = new FormGroup({
    moduleName: new FormControl(this.moduleName)})

  applyLeaveForm = new FormGroup({
    moduleName: new FormControl(this.moduleName) ,
    clAvailable: new FormControl(''),
    slAvailable: new FormControl(''),
    totalAvail: new FormControl(''),
    compOffAvailable: new FormControl(''),
    slApproved: new FormControl(''),
    clApproved: new FormControl(''),
    compOffApproved: new FormControl(''),
    //t800EmployeeObj: new FormGroup({employeeId: new FormControl(this.employeeId)}),
    t004LookupLeaveTypeObj:new FormGroup({
    lookupId: new FormControl(''),
    lookupValue:new FormControl('')}),
    t004LookupStatusObj:new FormGroup({
    lookupId: new FormControl(''),
    lookupValue:new FormControl('')}),
   //year:new FormGroup({lookupValue: new FormControl('2017-2018')}),
    startDate :new FormControl(new Date()),
    endDate : new FormControl(new Date()),
    leaveReason: new FormControl(''),
    totalAppliedDays: new FormControl(''),
    leaveId : new FormControl(''),
    appliedPermission : new FormControl(''),
    appliedPermissionHours:new FormControl(''),
    appliedPermissionMinutes:new FormControl(''),
    requestFlag :new FormControl('approvedLeaves'),
    empId : new FormControl(''),
     slApplied: new FormControl(''),
      clApplied: new FormControl(''),
      compOffApplied: new FormControl(''),
      approverId: new FormControl(''),
      month: new FormControl('')
 })


  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
   private router: Router, private commonUtils: CommonUtils,private dialog:MatDialog,
   public dialogRef: MatDialogRef<ApplyLeaveComponent>,
private hrmService:HrmService) {super();}

  ngOnInit() {
   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
   //  this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
            
            this.currentPageAccessObj = this.commonService.getAccessObjForCurrentScreen(this.moduleName);
             this.editAccess=this.currentPageAccessObj.editAccess;
            
             if(this.commonUtils.checkNullOrUndefined(this.currentPageAccessObj)){
              //this.editAccess=this.currentPageAccessObj.editAccess;

              this.pageLoad();
            }else
            this.commonService.emitInvalidLogin(); 

         }
       });
/** code ends above **/ 
if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
  this.pageLoad();

  }
    
   pageLoad()
   {
     this.fetchReviewDisplayedColumns();
   this.fetchLeaveTypeList();
   this.fetchEmployeeDetails();
   this.validationMethod();
   this.validationForTotalDaysField();
   }
   fetchReviewDisplayedColumns(){
     this.commonService.showProgressBar();  
     this.commonService.fetchReviewDisplayColumn(this.displayForm.value).
      subscribe(
       data => 
       {  
         if(this.commonUtils.checkNullOrUndefined(data.columnName))       
             this.displayedColumns=data.columnName.split(",");
           this.commonService.hideProgressBar();   
       },
         error => {
           this.commonService.hideProgressBar();
           this.commonService.showERPErrorMessage(error);
      });    
    }
 // displayedColumns = this.commonService.reviewDisplayedColumnsMap.get(this.moduleName).split(","); 
 /* ['Month', 'CL', 'SL', 'CompOff', 'LOP', 'LeaveReason','startDate','endDate','appliedDate', 'Type' , 'Status'];*/
  dataSource = new MatTableDataSource<Element>(this.ELEMENT_DATA);

  fetchLeaveTypeList(){
    this.hrmService.fetchLeaveType()
    .subscribe(
      data => {  
         this.leaveTypeList = data;

      }, error => {
        
        this.commonService.showERPErrorMessage(error);
      });
    
  }
  leaveTypeChangeEvent(leaveType){
    this.applyLeaveForm.get('t004LookupLeaveTypeObj.lookupValue').setValue(leaveType);
    if(leaveType === 'PERMISSION'){
      this.commonUtils.patchDates(this.applyLeaveForm,'endDate',new Date());
      this.applyLeaveForm.get('totalAppliedDays').setValue(0);
      this.applyLeaveForm.get('appliedPermission').setValue(1);

      this.permissionFlag = true;

   this.applyLeaveForm.get('totalAppliedDays').setValidators(null);
   this.applyLeaveForm.get('totalAppliedDays').markAsTouched();
   this.applyLeaveForm.get('totalAppliedDays').updateValueAndValidity();
    }
    else{
      this.permissionFlag = false;
      this.validationForTotalDaysField();
    }
  }
  fetchApplyLeave(){
     this.commonService.showProgressBar();
      this.hrmService.fetchApplyLeave(this.applyLeaveForm.value)
    .subscribe(
      data => { 
          
        this.commonService.hideProgressBar();
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
           var leaveObj = data[0];
           this.applyLeaveForm.patchValue(leaveObj);
           /*this.applyLeaveForm.get('clAvailable').setValue(leaveObj.clAvailable);
           this.applyLeaveForm.get('slAvailable').setValue(leaveObj.slAvailable);
           this.applyLeaveForm.get('compOffAvailable').setValue(leaveObj.compOffAvailable);
           this.applyLeaveForm.get('clApplied').setValue(leaveObj.clApplied);
           this.applyLeaveForm.get('slApplied').setValue(leaveObj.slApplied);*/
           var totalAvailableLeaves = leaveObj.clAvailable+leaveObj.slAvailable;
           this.applyLeaveForm.get('totalAvail').setValue(totalAvailableLeaves);
           this.clAvail = leaveObj.clAvailable;
           this.slAvail = leaveObj.slAvailable;
           this.totalAvail = totalAvailableLeaves;
           this.compOffAvail = leaveObj.compOffAvailable;
           this.slUsed = leaveObj.slUsed;
           this.clUsed = leaveObj.clUsed;
           this.compOffUsed = leaveObj.compOffUsed;
           this.lop = leaveObj.lopDays;
           this.applyLeaveForm.get('leaveId').setValue(leaveObj.leaveId);
           this.leaveId=leaveObj.leaveId;
           this.permissionApplied=leaveObj.permissionApplied;
           this.permissionHours=leaveObj.permissionHoursApplied +" Hrs";
           this.pieChartData.push(this.slUsed);
           this.pieChartData.push(this.clUsed);
           this.pieChartData.push(this.compOffUsed);
           this.pieChartData.push(this.lop);
           this.pieChartData.push(this.permissionApplied);
           var hrs = 0;
           var min = 0;
           if(this.commonUtils.checkNullOrUndefined(leaveObj) && this.commonUtils.checkNullOrUndefined(leaveObj.t825LeaveHistory) && leaveObj.t825LeaveHistory.length > 0 ){
              for(var loop=0; loop<leaveObj.t825LeaveHistory.length;loop++){
                hrs = hrs + leaveObj.t825LeaveHistory[loop].appliedPermissionHours;
                min = min + leaveObj.t825LeaveHistory[loop].appliedPermissionMinutes;
              }
            } 
            var totalHrs = hrs + ( min / 60);
            var totalPermission = totalHrs/8;
            // this.pieChartData.push(totalPermission); commented on 30, Dec 2022 by 9121- since it is not required for the pie chart
           
         }
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }
  onChangeStartDate(event){
    var startDate = event;
    var lastDay = new Date(this.applyLeaveForm.get('startDate').value.getFullYear(), this.applyLeaveForm.get('startDate').value.getMonth() + 1, 0);
    this.maxEndDate = lastDay;
    if(this.applyLeaveForm.get('endDate').value > this.maxEndDate){
      this.applyLeaveForm.get('endDate').reset();
    }
    this.numberOfDaysEntered();
  }
  saveLeave(){
    this.disableFlag=true;
    let monthNumber=this.applyLeaveForm.get('startDate').value.getMonth() + 1;
    let monthName = this.monthInYear[monthNumber-1]
    this.applyLeaveForm.get('month').setValue(monthName);
    this.commonService.showProgressBar();
    this.applyLeaveForm.get('approverId').setValue(this.approverId);
    this.hrmService.saveApplyLeave(this.applyLeaveForm.value)

    .subscribe(
      data => {  
        
        this.commonService.hideProgressBar();
        this.commonService.showERPMessage(data.erpmessage,null);
        this.applyLeaveForm.reset();
        this.applyLeaveForm.get('startDate').setValue(new Date());
        this.applyLeaveForm.get('endDate').setValue(new Date());
        this.applyLeaveForm.get('t004LookupLeaveTypeObj.lookupId').setValue('');
        this.applyLeaveForm.get('empId').setValue(data.empId);
        this.applyLeaveForm.get('leaveId').setValue(data.leaveId);
   //     this.leaveId=data.leaveId;
        this.applyLeaveForm.get('requestFlag').setValue('approvedLeaves');
        this.fetchApplyLeave();
        this.disableFlag=false;
        this.dialogRef.close(true);
      },
        
       error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
        this.disableFlag=false;
        this.dialogRef.close(true);
      });
  }

  fetchEmployeeDetails()
  {
    this.employeeForm.get('userName').setValue(this.commonService.loggedInUserObj.userName);
     this.hrmService.fetchEmployee(this.employeeForm.value).subscribe(
     data=>
     {
       if(this.commonUtils.checkNullOrUndefined(data))
       {
         this.employeeObj=data[0];
         this.applyLeaveForm.get('empId').setValue(this.employeeObj.employeeId);
         this.applyLeaveForm.get('approverId').setValue(this.employeeObj.managerId);
         this.employeeId=this.employeeObj.employeeId;
         this.approverId= this.employeeObj.managerId;
         this.fetchApplyLeave();

       }
     },error=>
     {

     })
  }

   validationMethod(): void{ 
     let promise=this.commonService.fetchAndSetValidationData(this.moduleName,this.applyLeaveForm);
     promise.then(data=>
        {
         this.validationListForSelectedModule=data;
        }
     ); 
   }

validationForTotalDaysField(){
   this.applyLeaveForm.get('totalAppliedDays').setValidators([Validators.required]);
   this.applyLeaveForm.get('totalAppliedDays').markAsTouched();
   this.applyLeaveForm.get('totalAppliedDays').updateValueAndValidity();
}

  closeDialog()
  {
    this.dialogRef.close(true);
  }
  numberOfDaysEntered(){
    let noOfDays = this.applyLeaveForm.get('totalAppliedDays').value;
    if(noOfDays > 0){
      let startDate = new Date(this.applyLeaveForm.get('startDate').value);
       let endDate = new Date();
      endDate.setDate( startDate.getDate() + noOfDays);
      this.commonUtils.patchDates(this.applyLeaveForm,'endDate',endDate);
    }
  }
}

export interface Element {
  Month: string;
  CL: number;
  SL: number;
  CompOff: number;
  LOP: number;
  LeaveReason: string;
  Type: string;
}
 