
import {throwError as observableThrowError, Observable,Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CommonService} from '../shared/common.service';
@Injectable()
export class RecruitmentService {
  constructor(private http: HttpClient,private commonService:CommonService) {}
SERVICE_URL: string = this.commonService.SERVICE_URL;
  
  private handleError(error: any) :Observable<any>{
    console.log(error);
     
    return observableThrowError(error.erpmessage);
  }

  saveOrUpdateJobs(jobObj) :Observable<any>{
      return this.http.post(this.commonService.SERVICE_URL + 'recruit/saveOrUpdateJobs', jobObj)
        .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  fetchJobNumberStatus(jobObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchJobNumberStatus', jobObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  fetchInterviewSlotNumberStatus(jobObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchInterviewSlotNumberStatus', jobObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  fetchInterviewAppointmentNumberStatus(appointmentObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchInterviewAppointmentNumberStatus', appointmentObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  fetchJobs(jobObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'recruit/fetchJobs', jobObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchCandidateNumberStatus(candidateObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchCandidateNumberStatus', candidateObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  fetchCandidate(candidateObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/fetchCandidate',candidateObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  saveCandidate(candidateObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/saveCandidate',candidateObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  fetchAppointment(appointmentObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/fetchInterviewAppointment',appointmentObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  saveAppointment(appointmentObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/saveInterviewAppointment',appointmentObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  fetchInterviewSlot(interviewSlotObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/fetchInterviewSlot', interviewSlotObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  saveOrUpdateInterviewSlot(interviewSlotObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/saveOrUpdateInterviewSlot', interviewSlotObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  fetchCandidateSource(sourceObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/fetchCandidateSource',sourceObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }

  fetchCandidateIndustry(industryObj):Observable<any>
  {
     return this.http.post(this.SERVICE_URL + 'recruit/fetchCandidateIndustry',industryObj)
         .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  bulkUpdateActionForCandidateStatus(candidateObj):Observable<any> {

    return this.http.post(this.SERVICE_URL + 'recruit/bulkUpdateActionForCandidateStatus', candidateObj,{responseType: 'text'})
      .pipe(map(data => data.valueOf())
      ,catchError(this.handleError));
  }
   fetchEmailAlreadyExists(candidateObj) :Observable<any>{
     return this.http.post(this.commonService.SERVICE_URL + 'recruit/fetchEmailAlreadyExists', candidateObj)
      .pipe(map(data => data),
      catchError(this.handleError));
  }
  saveorUpdateSlotStatus(slotObj) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'recruit/saveorUpdateSlotStatus', slotObj)
     .pipe(map(data => data),
     catchError(this.handleError));
 }
 saveOrUpdateFeedbackForRounds(feedbackObj) :Observable<any>{
      return this.http.post(this.commonService.SERVICE_URL + 'recruit/saveOrUpdateFeedbackForRounds', feedbackObj)
        .pipe(map(data => data)
        ,catchError(this.handleError));
  }
  fetchFeedbackForRounds(feedbackObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'recruit/fetchFeedbackForRounds', feedbackObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
 saveorUpdateSlotReschedule(slotObj) :Observable<any>{
  return this.http.post(this.commonService.SERVICE_URL + 'recruit/saveorUpdateSlotReschedule', slotObj)
   .pipe(map(data => data),
   catchError(this.handleError));
}
fetchCandidateStatusHistory(statusObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'recruit/fetchCandidateStatusHistory', statusObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchRescheduleHistory(historyObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'recruit/fetchRescheduleHistory', historyObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  fetchJobAgingHistory(historyObj):Observable<any> {
  
    return this.http.post(this.SERVICE_URL + 'recruit/fetchJobAgingHistory', historyObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  downloadExportDataForCandidate(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'reports/downloadExportDataForCandidate', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }
  fetchTaxSettingForSalaryCalculation(commonObj) :Observable<any>{
    return this.http.post(this.SERVICE_URL + 'recruit/fetchTaxSettingForSalaryCalculation', commonObj)
      .pipe(map(data => data)
      ,catchError(this.handleError));
  }
  downloadExportDataForJob(myForm) :Observable<any>{
    return this.http.post(this.commonService.SERVICE_URL + 'reports/downloadExportDataForJobs', myForm,{responseType: 'text'})
    .pipe(map(data =>data)
    ,catchError(this.handleError));
  }
}
