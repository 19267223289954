<mat-card class="col-lg-12 history" style="height: 100%;">
  <mat-card-title style="padding: 0px;">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">      
     <mat-icon>description</mat-icon> Salary Breakdown Calculation </div>
  </mat-card-title>

<mat-card-content >
    <form  [formGroup]="myForm">        
          <div class="col-lg-12 row"  >
            <mat-form-field  class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" >
              <mat-select placeholder="Salary Type" formControlName="salaryType" >
	              <mat-option value="GrossSalary"  (click)="onChangeSalaryType('GrossSalary')"> Gross Salary</mat-option>
	              <mat-option value="MonthlyCTC"  (click)="onChangeSalaryType('MonthlyCTC')"> Monthly CTC</mat-option>
	              <mat-option value="TakeHome" (click)="onChangeSalaryType('TakeHome')" > Take Home</mat-option>    
                 <mat-option value="YearlyCTC" (click)="onChangeSalaryType('YearlyCTC')" > Yearly CTC</mat-option>               
              </mat-select>
            </mat-form-field>            
            <mat-form-field  class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0" >
            	<input matInput placeholder="Amount" formControlName="amount"  noAndDotOnly DecimalPlaces="2" roundoff>
            </mat-form-field>
             <div class="col-lg-4 buttons" style="margin-top: 1%;">
             <button mat-raised-button color="primary" class="mat-red"  (click)="onCalculatingSalary()"> Calculate </button>
          	</div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " >
       <div class="mat-table  mb-pd-0 mb-mr-0" *ngIf="showFlag">
            <div class="mat-header-row">                    
            	 <div class="mat-header-cell" style="font-weight: bold;color: black;font-size: small;">Pay Code</div>
                <div class="mat-header-cell alignRight"  style="font-weight: bold;color: black;font-size: small;">Amount Per Month</div>
                <div class="mat-header-cell alignRight"  style="font-weight: bold;color: black;font-size: small;" >Amount Per Year</div>
              </div>
              
        <div class="mat-row" >
               <div class="mat-cell" >Basic</div>
              <div class="mat-cell alignRight" >{{basicAmountString }}</div> 
               <div class="mat-cell alignRight" >{{yearlybasicAmountString }}</div>              
         </div>
          <div class="mat-row">
               <div class="mat-cell" >HRA</div>
              <div class="mat-cell alignRight" >{{hraAmountString}}</div>   
              <div class="mat-cell alignRight" >{{yearlyhraAmountString}}</div>      
         </div>
          <div class="mat-row">
               <div class="mat-cell" >Conv.Allowance</div>
              <div class="mat-cell alignRight" >{{convAllowString}}</div>  
              <div class="mat-cell alignRight" >{{yearlyconvAllowString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >Special Allowance</div>
              <div class="mat-cell alignRight" >{{specAllowString }}</div> 
               <div class="mat-cell alignRight" >{{yearlyspecAllowString }}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >PF-Employee</div>
              <div class="mat-cell alignRight" >{{pfEmployeeString}}</div>     
               <div class="mat-cell alignRight" >{{yearlypfEmployeeString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >ESI-Employee</div>
              <div class="mat-cell alignRight" >{{esiEmployeeString}}</div>        
               <div class="mat-cell alignRight" >{{yearlyesiEmployeeString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >PF-Employer</div>
              <div class="mat-cell alignRight" >{{pfEmployerString}}</div>
              <div class="mat-cell alignRight" >{{yearlypfEmployerString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >ESI-Employer</div>
              <div class="mat-cell alignRight"  >{{esiEmployerString}}</div>  
               <div class="mat-cell alignRight"  >{{yearlyesiEmployerString}}</div>        
         </div>
         <div class="mat-row">
          <div class="mat-cell" >IT</div>
         <div class="mat-cell alignRight"  >{{taxEmployerString}}</div>  
          <div class="mat-cell alignRight"  >{{yearlytaxEmployerString}}</div>        
    </div>
          <div class="mat-row">
               <div class="mat-cell" >Take Home</div>
              <div class="mat-cell alignRight" >{{takehomeString}}</div>  
               <div class="mat-cell alignRight" >{{yearlytakehomeString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >Gross Salary</div>
              <div class="mat-cell alignRight" >{{grosspayString}}</div>    
               <div class="mat-cell alignRight" >{{yearlygrosspayString}}</div>        
         </div>
          <div class="mat-row">
               <div class="mat-cell" >CTC</div>
              <div class="mat-cell alignRight" >{{ctcString}}</div>   
              <div class="mat-cell alignRight" >{{yearlyctcString}}</div>      
         </div>                  

        </div>

</div>
<div class="col-md-12 col-sm-12 col-lg-12" style="padding-top: 15px;" *ngIf="showFlag">
		<div class="button-row">
			<button mat-raised-button color="primary" class="mat-red" (click) = saveSalaryBreakDowncalculation()>{{SAVE_BUTTON}}</button>
        <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
		</div>
</div>
    </form>  
</mat-card-content>
</mat-card>
          
