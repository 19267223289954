<!--  <div style='height:50px;' class="col-lg-12">

 <div class="col-lg-1">
   <a (click)="loadHomeUrl()" style="cursor:pointer;text-decoration: none">Home</a>
 </div>

  <div class="col-lg-1" *ngFor="let breadCrumbObj of breadCrumbList;index as breadcrumbId"> 
    <a (click)="openChildTask(breadcrumbId,breadCrumbObj)" style="cursor:pointer;text-decoration: none"> 
      <span>{{breadCrumbObj.taskName}}</span>
    </a>
 </div>

</div>  -->
<div style='height: 32px;'>
<div class="col-lg-12 breadcrumbs">
  <div class='innerBreadCrumb'>
    <ul class='BreadCrumbList'>
      <li ><a (click)="loadHomeUrl()" matTooltip="Load all tasks not associated with the summary tasks"><span>Home</span> </a> 
      </li>    
      <li *ngFor="let breadCrumbObj of breadCrumbList;index as breadcrumbId">
      	<a class='active' (click)="openChildTask(breadcrumbId,breadCrumbObj)"><span>{{breadCrumbObj.taskName}}</span> </a> 
      </li>
    </ul>    
  </div>
</div>
</div>
