import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ERPFormBase } from './base/erp-form-base';
/**
author - 9014  - Rajesh subramanian
All form objects will be formed here and returned back
*/
@Injectable()
export class FormControlService {
  constructor() { }

  toFormGroup(customFieldElements: ERPFormBase<any>[] ) {
    const group: any = {};

    customFieldElements.forEach(customFieldElementObj => {
      group[customFieldElementObj.key] = customFieldElementObj.required ? new FormControl(customFieldElementObj.value || '', Validators.required)
                                              : new FormControl(customFieldElementObj.value || '');
    });
    return new FormGroup(group);
  }
}
