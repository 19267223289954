<div class="example-container margin-left mat-elevation-z8 {{templateCSSHeightFlag}} ">
  
  <mat-card-title  *ngIf="displayHeaderLabel" class="mat-card-title cardheadertitle mobileTitlePadding title_icon">
      <mat-icon>home</mat-icon><span>{{screenTitle}}  </span> 
  </mat-card-title>
 <!--  <form (ngSubmit)="fetchContactList()" [formGroup]="myForm"> -->
    <mat-card *ngIf="showFirstPanel" class="mat-card-view-edit-top">
      <view-default-top *ngIf="displayDefaultTop" [moduleName]="moduleName"  [currentPageAccessObj]="currentPageAccessObj" [currentlySelectedMenu]="currentlySelectedMenu" [filterData]="filterSelectedOption" [orgUsersList]="orgUsersList" (viewDefaultTopEmitter)="viewTemplateWrapperReceiver($event)" [selectedTaskObj]="selectedTaskObj"  [appraisalLevelList]="appraisalLevelList"  [versionNumberList]="versionNumberList" [displayedColumns]="userDisplayedColumns"  [displayedColumnsOriginal]="displayedColumns" [UserLabelNameForColumn]="userLabelColumn"  ></view-default-top>
     
    </mat-card>  
   <mat-card *ngIf="showSecondPanel" class="mat-card-view-edit-top">
      <app-view-edit-top [moduleName]="moduleName" [arrayListIsOne]="arrayListIsOne" [emailShowFlag]="emailShowFlag" [showConvertOpen]="showConvertOpen" [showConvertTask]="showConvertTask" [salesConvertFlag]="salesConvertFlag" [showShipmentFlag]="showShipmentFlag" (printButtonClicked)="viewTemplateWrapperReceiver($event)" [currentPageAccessObj]="currentPageAccessObj" [multiplePDFPackage]="multiplePDFPackage" [convertFlag]="convertFlag" [showVoidFlag]="showVoidFlag" [showConvertSent]="showConvertSent" [showConvertWIP]="showConvertWIP" [closeTaskFlag]="closeTask"  (executeQueryEmitter)="executeQuery($event)" ></app-view-edit-top>
    </mat-card>
     <div *ngIf="showMenuShortcuts" class="col-lg-12" style="text-align: right;font-size: 13px;color:crimson;font-weight: bold">
    Go mouse free -  Ctrl S - Search , Ctrl R - Reset Grid , Ctrl Shift -> Next Page , Ctrl Shift <- Previous Page , Ctrl Space - Create screen , Ctrl > - Forward , Ctrl < - Backward
   </div>

   <mat-card *ngIf="showBreadCrumb" class="mat-card-view-breadcrumb">
   <app-view-breadcrumb (viewSearchresultEmitter)="viewTemplateWrapperReceiver($event)" ></app-view-breadcrumb>
   </mat-card>

  <app-view-search-results  [selection]="selection" [dataSource]="dataSource" [displayedColumns]="userDisplayedColumns"  [displayedColumnsOriginal]="displayedColumns" [moduleName]="moduleName" [quickInfoValuesOriginal]="quickInfoValues" [quickInfoLabels]="quickInfoLabels"  [currentPageAccessObj]="currentPageAccessObj"    [editHideFlag]="editHideFlag"   
       (viewSearchresultEmitter)="viewTemplateWrapperReceiver($event)" [cloneSearchIconFalg]="cloneSearchIconFalg" [schemaValue]="schemaValue" 
       [searchString]="this.searchString" [tempModuleNameForItem]="this.tempModuleNameForItem"></app-view-search-results>
  <div class="col-lg-12" *ngIf="!isEmptyGrid && moduleName !== 'VIEW_LEAVE' && moduleName !== 'VIEW_VALIDATION' && showPagination">
    <app-view-pagination [recordCount]="recordCount" [pageNo]="pageNo" [pageSize]="pageSize"></app-view-pagination>
  </div>
  <div class="noRecordPadding" *ngIf="isEmptyGrid">
    <p>No Records Found</p>
  </div> 
<!--   </form> -->

