import { Component,Inject,HostListener} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import {CommonService} from '../../shared/common.service';
import {CommonUtils} from '../../core/common-utils';
import { MatDialog } from '@angular/material/dialog';
 import {ChangePasswordComponent} from '../../header/changepassword/change-password.component';
 import {Router} from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import {InterviewRoundComponent} from '../../recruitment/candidate/interviewround.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'erp-filter-overlay',
  templateUrl: './view-topicon.component.html'
})

export class ViewMainHeaderIconComponent extends CommonUtils { 
getIconName:string;
 keyboardEvent: any;
 keyCode: number;
 buildNumber;
 displaySettings;
 notificationFlag;
 notificationDetailList;
 errorimageSourcePath;
 photoSrcPath: string="";
 roleName;
 @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    this.keyboardEvent = event;
    this.keyCode = event.keyCode;
  }
  t037ReminderDetailsFormGroup: FormGroup;

  constructor(
      public dialogRef: ErpOverlayRef,private commonService: CommonService,public dialog: MatDialog,
      private commonUtils: CommonUtils, private router: Router,
      @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
    super();
    this.getIconName=data.iconName;
    this.displaySettings=data.displaySettings;
    this.buildNumber=this.commonService.BuildNumber;

    this.t037ReminderDetailsFormGroup = new FormGroup({
      t001UserReminderTo: new FormGroup({
        userId: new FormControl(),
      }),
      reminderDetailId:new FormControl(0)
    });
  }

ngOnInit()
{
    if(this.getIconName ==='notifications'){
      this.fetchReminderToShowNotification();
    } 
    this.errorimageSourcePath=environment.NO_IMAGE_FOR_ORG_PROFILE;
    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
      let portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
      this.roleName = this.commonService.loggedInUserObj.roleName.toUpperCase();
      this.photoSrcPath = this.commonService.SERVICE_URL+'erpassets/'+portalName+'/NEW_EMPLOYEE/'; 
   }
}
errorHandler(event) {
  event.target.src = this.errorimageSourcePath;
}
offlineModeAlert(){
}

callChangePassword()
{
this.closeDialog();
const dialogRef = this.dialog.open(ChangePasswordComponent, {
            //width: '25%',
            id: 'changepasspopup',
            data: {}
          });

          dialogRef.afterClosed().subscribe(result => 
          {
            
          });  
}

fetchReminderToShowNotification(){
  this.t037ReminderDetailsFormGroup.get('t001UserReminderTo.userId').setValue(this.commonService.loggedInUserObj.employeeId);
  this.commonService.fetchReminderToShowNotification(this.t037ReminderDetailsFormGroup.value).subscribe(
    data=>
      {
        if(this.commonUtils.checkNullOrUndefined(data))
        {
          this.notificationFlag=true;
          this.notificationDetailList = data;
        }          
      },
    error=>
    {
      console.log(error);
    });
  }

  routeToEditPage(notificationItem: any){
   
      this.t037ReminderDetailsFormGroup.get('reminderDetailId').setValue(notificationItem.reminderDetailId);
      this.commonService.saveOrUpdateNotification(this.t037ReminderDetailsFormGroup.value).subscribe(
     data=>{
    
       this.fetchReminderToShowNotification();
        this.commonService.notificationChangedSubject.next("REFRESH_NOTIFICATION");
     });
     this.router.navigate([notificationItem.routeURL]);  
     this.closeDialog();  
   } 
  closeDialog(){
    this.dialogRef.close();
  }
  
}
