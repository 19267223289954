import {switchMap, debounceTime, map,distinctUntilChanged,} from 'rxjs/operators';
import { Component, OnInit, Inject,Output,EventEmitter, Input,ViewChild, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {FormGroup, FormControl,Validators, FormArray, FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ProjectService} from './../project.service'; 
import {Response} from '@angular/http';
import {CommonService} from '../../shared/common.service';
import {HrmService} from '../../hrm/hrm-service';
import {CommonUtils} from '../../core/common-utils';
import {environment} from '../../../environments/environment';
import {AutoNumberGenerateComponent} from '../../dialogBox/autogenerate/auto-num-generate.component';
import {CanComponentDeactivate} from '../../auth/can-deactivate-guard.service';
import {ERPErrorStateMatcher} from '../../shared/ERPErrormatcher';
import {ERPPromptComponent} from '../../dialogBox/prompt/erp-prompt.component';
import {FileUploadComponent} from'../../dialogBox/fileupload/file-upload.component';
import {DialogOverviewExampleComponent} from '../../dialogBox/dialogBox.component';
import { DateFormatPipe } from '../../shared/dateFormatPipe.pipe';
import {Subscription, Observable} from 'rxjs/Rx';
import {SettingsService} from '../../settings/settings-service';

@Component({
selector: 'app-createtimesheet',
templateUrl: './create-timesheet.component.html',
providers: [],
})
export class CreateTimesheetComponent extends CommonUtils implements CanComponentDeactivate,OnInit {
//placeholder from common utils

DISPLAY_NAME;
CANCEL_BUTTON;
SAVE_BUTTON;
elseBlock;
moduleName : string =environment.CREATE_HRIS_TIMESHEET;
saveClick = true;
dispLabel = 'New';
disableButton = true;
step = 0;
editAccess = true;
projectObj;
resourceList = [];
parentTaskList;
projectList=[];
taskList=[];
taskStatusList=[];
showProjectDropDown:boolean=false;
currentlySelectedMenu;
contactEditId;
dialogLabel=environment.NEW_TASK_LABEL;
disabledAutoFlag=false;
displayAutoGen=false;
placeholderstr = 'Task #';
TASKNUMBER_ALERT = 'Task # is';
TASKNUMBER_EXISTS_ALERT = 'Task # already exists';
editId;
imagePath:string;
uploadedFileArrayparent:Array<any>=[];
accessFlag:boolean=true;
taskObj;
workedHours;
userEmail;
taskStatus;
taskId = 0;
changeTaskStatus:boolean=false;
baseLine_start_date;
baseLine_end_date;
editStr;
systemGeneratedDisableFlag = false;
orgUsersList;
startDateFlag: boolean = true;
endDateFlag: boolean = true;
userFlag: boolean = true;
matcher = new ERPErrorStateMatcher();

myAGForm = new FormGroup({
      type: new FormControl(this.moduleName)
    })

 @Input() myFocusTriggeringEventEmitter: EventEmitter<string>;

createTaskForm =this.getTaskForm(); 

getTaskForm(){
  return  new FormGroup({
   taskId: new FormControl(0),
   moduleName: new FormControl(this.moduleName) ,
   taskNumber: new FormControl(''),
   summary: new FormControl(false),
  t823ParentTask: new FormGroup({taskId:new FormControl(0),taskName: new FormControl('')}),
   autoSchedule: new FormControl(false),
   clientViewable: new FormControl(false),
   taskName: new FormControl('',[Validators.required,Validators.minLength(1),Validators.maxLength(1000)]),
   taskDesc: new FormControl('',Validators.maxLength(10000)),
   estimated_hours:new FormControl(''),
   development:new FormControl(false),
   codeReview: new FormControl(false),
   testing: new FormControl(false),
   billing_hours:new FormControl(0),
   nonBillableHours:new FormControl(0),
   actualHours:new FormControl(0),
   percent_complete:new FormControl(0),
   startDate: new FormControl(new Date()),
   endDate: new FormControl(new Date()),
   prodmoveDate:new FormControl(new Date()),
    t001_UserObj: new FormGroup({
      userId:new FormControl(0),
      empName:new FormControl(''),
      userName:new FormControl('')
    }),
   displayDicardMessage:new FormControl('Y'),
   t822ProjectObj:new FormGroup({projectId:new FormControl(0,Validators.required),projectName:new FormControl('',Validators.required)}),
   validationString: new FormControl(''),
     fileUpload : new FormControl(''),
     globalFlag : new FormControl(false),
   t004_LookupTaskStatusObj:new FormGroup({
   lookupId: new FormControl(0),
   lookupCode: new FormControl(''), 
   lookupValue: new FormControl('')
  }),
   versionNumber:new FormControl(''),
   createdByEmail: new FormControl(''),
   taskType:new FormControl('AP'), //AP = ALL PARENT TASKS 
   portalName:new FormControl(''),
   userEmail : new FormControl(''),
   createdBy : new FormControl(''),
   baseLine_start_date: new FormControl(''),
   baseLine_end_date: new FormControl(''),
   newEntryDateStatus:new FormControl(false),
   isPagination: new FormControl(''),
   projectValue:new FormControl(0),
   sendemail: new FormControl(false),
 })
}
fetchTaskFlag:boolean = false;
setForFileAttachmentFlag:boolean = false;
  errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private commonService: CommonService,
private activatedRoute: ActivatedRoute, public dialog: MatDialog, private router: Router,
private route: ActivatedRoute, public commonUtils: CommonUtils,private hrmService: HrmService,private datePipe: DateFormatPipe,
public dialogRef: MatDialogRef<CreateTimesheetComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private settingsService: SettingsService) {
 super();
}
ngOnInit()
{
 
   /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
//     this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
           this.currentlySelectedMenu=this.commonService.currentlySelectedMenu;
           this.editAccess=this.currentlySelectedMenu.editAccess;
           this.pageLoad();
         }
       });

     if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
  this.pageLoad();
if(this.commonUtils.checkNullOrUndefined(this.taskObj) && this.commonUtils.checkNullOrUndefined(this.taskObj.taskId) && this.taskObj.taskId != 0 && this.editStr != 'clone' ){
        this.systemGeneratedDisableFlag = true;
       }else{
        this.systemGeneratedDisableFlag = false;
       }
}

pageLoad(){ 
  this.displayAutoGen=this.commonService.loggedInUserObj.t000Organization.t036_General_Settings.displaySettingsIcon;
  this.fetchAutoGenerate();   
  this.fetchProject();
  this.fetchOrgUsersForDropDown();
  this.createTaskForm.get('taskName').disable();
  this.createTaskForm.get('taskDesc').disable();
}

canDeactivate() { 
 if(this.createTaskForm.dirty && this.createTaskForm.get('displayDicardMessage').value==='Y'){
     const dialogRef = this.dialog.open(ERPPromptComponent, {
      width: '500px',
      data: {dialogText: environment.DISCARD_LABEL, confButton1: 'Leave',confButton2:'Stay'}
    });
     return  dialogRef.afterClosed().pipe(map(
       result => {
         if(result==='BUTTON1')
           return true;
           return false;
            }));
  }
}

fetchProject(){ 
  let projectFormGroup=new FormGroup({
    statusFlag: new FormControl('LOAD_FOR_CREATE_TIMESHEET')
  });
  this.hrmService.fetchProject(projectFormGroup.value).subscribe(
  data=>{
    this.projectList = [];
    if (this.commonUtils.checkNullOrUndefined(data) != null && data.length > 0) {
      this.projectList = data;
    }
  },
  error=>{
    this.commonService.hideProgressBar();
    this.commonService.showERPErrorMessage(error);    
  });
}

  fetchAutoGenerate() {
    this.myAGForm.get('type').setValue(this.moduleName);
    this.commonService.fetchAutoGenerateNumber(this.myAGForm.value).subscribe(
      data => {
        if (this.commonUtils.checkNullOrUndefined(data)) 
        {
          if(data.autoGenerate){
            //this.displayAutoGen = true;
            this.disabledAutoFlag = true;
            this.placeholderstr = 'System Generated';
         }
        }
      }, error => {
        this.commonService.showERPErrorMessage(error);
      });
  }

  fetchOrgUsersForDropDown(): void {
        let invoiceForm = new FormGroup({
            moduleName:new FormControl('VIEW_RESOURCE_EXTENSION'),
            pageSize:new FormControl(1000),
            userFilter: new FormControl('active')

        })     
    this.settingsService.fetchUsers(invoiceForm.value).
      subscribe(
      data => {
        this.orgUsersList = data;
      },
      error => {        
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
  }

  onChangeProject(project,event){
    if(event.isUserInput==true){
      if(this.commonUtils.checkNullOrUndefined(project)){
        this.disableButton = false;
        this.projectObj = project;
        this.createTaskForm.get('t822ProjectObj.projectId').setValue(this.projectObj.projectId);
        this.createTaskForm.get('t822ProjectObj.projectName').setValue(this.projectObj.projectName);
         this.createTaskForm.get('taskName').enable();
        this.createTaskForm.get('taskDesc').enable();
         this.createTaskForm.get('taskName').markAsTouched();
         this.createTaskForm.get('taskName').updateValueAndValidity();
        this.startDateFlag = false;
        this.endDateFlag = false;
        this.userFlag = false;
        this.fetchSearchParentTask("SUPER_PARENT_TASK");
      }else{
        this.disableButton = true;
        this.createTaskForm.get('taskName').disable();
        this.createTaskForm.get('taskDesc').disable();
         
         this.startDateFlag = true;
        this.endDateFlag = true;
          this.userFlag = true;
        this.commonService.showERPMessage("Please select the project for creating the new task");
      }
    }    
  }

  saveOrUpdateTask(){
    this.enableFormBeforeSave();
    this.createTaskForm.get('displayDicardMessage').setValue('N'); 
    this.createTaskForm.get('userEmail').setValue(this.userEmail);
    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.t000Organization != null && this.commonService.loggedInUserObj.t000Organization.portalName != null
      && this.taskId === 0)
      this.createTaskForm.get('portalName').setValue(this.commonService.loggedInUserObj.t000Organization.portalName);
    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.userName != null)
      this.createTaskForm.get('createdBy').setValue(this.commonService.loggedInUserObj.userName);
    if(this.commonService.loggedInUserObj != null && this.commonService.loggedInUserObj.email != null)
      this.createTaskForm.get('createdByEmail').setValue(this.commonService.loggedInUserObj.email);
 this.createTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
    this.commonService.showProgressBar();
    this.taskList.push(this.createTaskForm.value);  
    this.projectService.saveTask(this.taskList)
    .subscribe(
      data => {  
        if(this.commonUtils.checkNullOrUndefined(data)){
          this.commonService.hideProgressBar();
          this.commonService.showERPMessage(data[0].erpmessage);
          this.dialogRef.close(true);
        }
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });

  }
  cancelClick(){
    this.dialogRef.close();
  }

  fetchTaskStatus()
   {
     this.commonService.fetchFromLookup(this.commonService.formLookUpFormGroup('PROJECT_PLAN').value).
     subscribe(
     data =>
      {
             this.taskStatusList = data;
      },
    error => 
    {
        this.commonService.showERPErrorMessage(error);
      });
   }

enableFormBeforeSave(){
    this.createTaskForm.get('billing_hours').enable();
    this.createTaskForm.get('estimated_hours').enable();
    this.createTaskForm.get('percent_complete').enable();
    this.createTaskForm.get('startDate').enable();
    this.createTaskForm.get('endDate').enable();
    this.createTaskForm.get('t001_UserObj.userId').enable();
}

confirmToSaveBaseLineDate(){  
     let currentStatus = this.createTaskForm.get('t004_LookupTaskStatusObj.lookupValue').value;
     let startDate= this.createTaskForm.get('startDate').value;
     let organisationFormat=this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;
     var startDateChanged=this.datePipe.transform(startDate, organisationFormat);
     let endDate=this.createTaskForm.get('endDate').value;
     var endDateChanged=this.datePipe.transform(endDate, organisationFormat);

     if(this.taskStatus === 'YTS' && currentStatus === 'OPEN' && this.taskId != 0 ){
   
    const dialogTextVal = 'The ' + startDateChanged +' and ' + endDateChanged + ' will be set as Baseline dates for the tasks. Do you want to continue saving ?';
    const confTextVal = 'ConfirmBaselineTaskDate';
    const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                          width: '500px',
                          data: {dialogText: dialogTextVal, confText: confTextVal}
                        });
          dialogRef.afterClosed().subscribe(result => {
           if (result === true) {
                  this.createTaskForm.get('newEntryDateStatus').setValue(true);
                  this.saveOrUpdateTask();
                }
          });
      }else if(currentStatus === 'OPEN' && this.taskId === 0 ){
    
        const dialogTextVal = 'The ' + startDateChanged +' and ' + endDateChanged + ' will be set as Baseline dates for the tasks. Do you want to continue saving ?';
        const confTextVal = 'ConfirmBaselineTaskDate';
        const dialogRef = this.dialog.open(DialogOverviewExampleComponent, {
                              width: '500px',
                              data: {dialogText: dialogTextVal, confText: confTextVal}
                            });
              dialogRef.afterClosed().subscribe(result => {
               if (result === true) {
                  this.createTaskForm.get('newEntryDateStatus').setValue(true);
                      this.saveOrUpdateTask();
                    }
              });
      }
    else{
            this.createTaskForm.get('newEntryDateStatus').setValue(false);
            this.saveOrUpdateTask();
    }

   }

 // Prevent Saturday and Sunday from being selected.
    satSunDisableInSelectDateFilter = (d: Date): boolean => {
      const day = d.getDay();
      return day !== 0 && day !== 6;
  }

  fetchSearchParentTask(taskName){ 
    if(this.commonUtils.checkNullOrUndefined(this.projectObj) && this.createTaskForm.get('t822ProjectObj.projectId').value !=0){
      let searchParentTaskForm=this.getTaskForm();
      searchParentTaskForm.get('taskType').setValue('AP');
      if(this.commonUtils.checkNullOrUndefined(this.createTaskForm.get('t822ProjectObj.projectId').value) && this.createTaskForm.get('t822ProjectObj.projectId').value !=0){
        searchParentTaskForm.get('t822ProjectObj.projectId').setValue(this.createTaskForm.get('t822ProjectObj.projectId').value);
      }     
      searchParentTaskForm.get('startDate').setValue('');
      searchParentTaskForm.get('endDate').setValue('');
      searchParentTaskForm.get('t004_LookupTaskStatusObj.lookupValue').setValue('OPEN');
      searchParentTaskForm.get('t823ParentTask.taskName').setValue(taskName);
      searchParentTaskForm.get('isPagination').setValue('true');
      this.commonService.fetchAllParentTask(searchParentTaskForm.value).subscribe(
      data => {
        if(this.commonUtils.checkNullOrUndefined(data) && data.length === 1){
          this.createTaskForm.get('t823ParentTask.taskName').setValue(data[0].taskName);
          this.createTaskForm.get('t823ParentTask.taskId').setValue(data[0].taskId);
        }
      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);
      });
    }
    else{
      this.createTaskForm.get('t823ParentTask.taskName').disable();  
      this.createTaskForm.get('development').disable();
      this.createTaskForm.get('codeReview').disable();
      this.createTaskForm.get('testing').disable();
      this.createTaskForm.get('taskName').disable();
      this.createTaskForm.get('t004_LookupTaskStatusObj.lookupId').disable();
      this.createTaskForm.get('taskDesc').disable();
      this.commonService.showERPMessage("Please select the project for creating the new task");

    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
   
}