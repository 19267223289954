import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {CommonUtils} from '../../common-utils';
import {CommonService} from '../../../shared/common.service';
import{HrmService} from '../../../hrm/hrm-service';
import {environment} from '../../../../environments/environment';
import {UploadSignatureComponent} from '../../../dialogBox/signatureUpload/upload-signature.component';
import { RecruitmentService } from '../../../recruitment/recruitment-service';
import {MatTableDataSource} from '@angular/material/table';
@Component({
  selector: 'app-view-history',
  templateUrl: './view-history.component.html',
  providers: []
})

export class ViewHistoryComponent implements OnInit {
    loggedInUserObjRole: string;
	historyForm = new FormGroup({
		headerHistoryId:new FormGroup({
            invoiceHeaderId:new FormControl(0),
            expenseHeaderId:new FormControl(0),
            paymentMadeHeaderId:new FormControl(0),
            contactId: new FormControl(0),
            itemId:new FormControl(0),
            paymentReceivedHeaderId: new FormControl(0),
            candidateHistoryId:new FormControl(0)
        }),
        invoiceHeaderId:new FormControl(0),
        t828AppraisalObj:new FormGroup({
            appraisalId:new FormControl(0)
        }),
        t001UserObj:new FormGroup({
            userId:new FormControl(0)
        }),
        loggedInUserObj:new FormGroup({
            userId:new FormControl(0)
        }),
        moduleName: new FormControl('')
	});
	moduleName:string;
	labelName:string;
    portalName: string;
    employeeId: string;
    employeeCode: string;
    ndaFilePDFWithSignSource;
    ndaSignImageSource;
    ndaSignImageFileExistFlag: boolean = false;
    resumeFileFileExistFlag: boolean = false;
	historyList=[];
    selectedRow;
    recordFlag:boolean=false;
    dashboardHistory: boolean = false;
    timesheetHistoryFlag:boolean=false;
    errorimageSourcePath=environment.NO_IMAGE_FOR_ORG_PROFILE;
    displayedColumns: string[] = ['taskNumber', 'taskName', 'description', 'workedHours', 'workedDate'];
    displayColumn: string[]= ['punchIn', 'punchOut']
    dataSourceAttendance= new MatTableDataSource([]);
     dataSource;
     photoSrcPath;
     pdfSource: string;
     showAgreeTermsFlag: boolean;
     t800EmployeeFormGroup: FormGroup;
     hrPolicyFlagValue: boolean = false;
     showSignNDAButton: boolean = false;

 constructor(private router: Router,private commonService: CommonService, private hrmService: HrmService, private recruitService: RecruitmentService,  private commonUtils: CommonUtils, 
    public dialog: MatDialog,public dialogRef: MatDialogRef<ViewHistoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

        let portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
        this.photoSrcPath = this.commonService.SERVICE_URL+'erpassets/'+portalName+'/NEW_EMPLOYEE/';
	    this.moduleName=data.moduleName;
        this.selectedRow=data.selectedRow;
        
        this.t800EmployeeFormGroup = new FormGroup({
            employeeId: new FormControl(0),
            hrPolicyAgreedFlag: new FormControl(false)
        });

        if(this.moduleName=="VIEW_EXPENSE")
        {
        this.historyForm.get('headerHistoryId.expenseHeaderId').setValue(data.selectedRow.expenseHeaderId);    
        }
        else if(this.moduleName=="VIEW_PAYMENT_MADE")
        {
        this.historyForm.get('headerHistoryId.paymentMadeHeaderId').setValue(data.selectedRow.paymentMadeHeaderId);
        }
        else if(this.moduleName=="VIEW_TIMESHEET" )
        {
        this.historyForm.get('invoiceHeaderId').setValue(data.selectedRow.t823TaskObj.taskId);
        }
        else if(this.moduleName=="VIEW_TIMESHEET_RESOURCE" )
        {
        this.historyForm.get('invoiceHeaderId').setValue(data.selectedRow.taskId);
        }
        else if(this.moduleName=="VIEW_HRIS_TIMESHEET")
        {
        this.historyForm.get('invoiceHeaderId').setValue(data.selectedRow.taskId);
        }
        else if(this.moduleName=="VIEW_CONTACT")
        {
        this.historyForm.get('headerHistoryId.contactId').setValue(this.selectedRow.contactId);
        }
        else if(this.moduleName=="VIEW_ITEM")
        {
        this.historyForm.get('headerHistoryId.itemId').setValue(this.selectedRow.itemId);
        }
        else if(this.moduleName=="VIEW_PAYMENT_RECIEVED"){
            this.historyForm.get('headerHistoryId.paymentReceivedHeaderId').setValue(this.selectedRow.paymentReceivedHeaderId);
        }
        else if(this.moduleName=="VIEW_CANDIDATE"){
            this.historyForm.get('invoiceHeaderId').setValue(this.selectedRow.candidateId);
        }
        else if(this.moduleName=="VIEW_APPOINTMENT"){
            this.historyForm.get('invoiceHeaderId').setValue(this.selectedRow.appointmentId);
        }
        else if(this.moduleName=="VIEW_JOB"){
            this.historyForm.get('invoiceHeaderId').setValue(this.selectedRow.jobId);
        }
        else if(this.moduleName == 'HOME_NEW_DASHBOARD'){
            this.historyList = data.historyList;
            this.dashboardHistory = false;
            this.labelName = data.labelName;
            if (this.labelName == 'My Attendance'){
                if (this.historyList.length >0){
                    this.recordFlag = false;
                    this.dataSourceAttendance.data = this.historyList;
                } else {
                    this.recordFlag = true;
                }
                
            }
        }
        else if(this.moduleName=="VIEW_APPRAISAL_FORM"){
            this.historyForm.get('t001UserObj.userId').setValue(this.selectedRow.t001_UserObj.userId);
            this.historyForm.get('t828AppraisalObj.appraisalId').setValue(this.selectedRow.t828_AppraisalObj.appraisalId);
        } else if(this.moduleName=="CANDIDATE_RESUME_PREVIEW"){
            this.recordFlag = false;
            this.labelName = data.labelName;
            this.pdfSource = data.pdfSource;
            this.resumeFileFileExistFlag  = this.commonService.doesFileExist(this.pdfSource);
        }
        else if(this.moduleName=="NEW_HR_POLICY"){
            this.commonService.showProgressBar();
            this.recordFlag = false;
            this.labelName = data.labelName;
            this.pdfSource = data.pdfSource;
            this.showAgreeTermsFlag = data.showAgreeTermsFlag;
            this.fetchEmployeeFlagValue();
        }  
         else if(this.moduleName=="NDA_FILE"){
            this.recordFlag = false;
            this.labelName = data.labelName;
            this.pdfSource = data.pdfSource; 
            this.portalName = data.portalName;
            this.employeeCode = data.employeeCode;
            this.employeeId = data.employeeId;
            this.showSignNDAButton = data.showSignNDAButton;
            // check NDA sign file path exist
            this.ndaSignImageSource = this.commonService.SERVICE_URL+"erpassets/"
                                 + portalName.toUpperCase() + "/Employee/"+ this.employeeCode + "/NDA/NDA_SIGNATURE/NDA_Sign_"+this.employeeId+".png";
            this.ndaSignImageFileExistFlag  = this.commonService.doesFileExist(this.ndaSignImageSource);
          
        }
        else
        {
        this.historyForm.get('headerHistoryId.invoiceHeaderId').setValue(data.selectedRow.invoiceHeaderId);
        }
    }

	ngOnInit() {
		this.pageLoad();
	}

	pageLoad()
	{
        this.loggedInUserObjRole = this.commonService.loggedInUserObj.roleName;
        
    if(this.moduleName=="VIEW_TIMESHEET" || this.moduleName=="VIEW_HRIS_TIMESHEET" || this.moduleName=="VIEW_TIMESHEET_RESOURCE" )
        {
        this.timesheetHistoryFlag=true;
        this.labelName="TimeSheet";
       
        if(this.moduleName === 'VIEW_TIMESHEET' ){
            this.historyForm.get('moduleName').setValue('VIEW_TIMESHEET');
            this.historyForm.get('loggedInUserObj.userId').setValue(this.commonService.loggedInUserObj.userId); 
        }else if(this.moduleName === "VIEW_TIMESHEET_RESOURCE"){
            this.historyForm.get('moduleName').setValue('VIEW_TIMESHEET');
        }
        this.hrmService.fetchTimeSheet(this.historyForm.value).subscribe(
        data => {
         this.dataSource = data; 
         if(this.commonUtils.checkNullOrUndefined(data))
         {
             for(var loop=0; loop < data.length; loop++){
                  if(this.commonUtils.checkNullOrUndefined(data[loop].userComments))
                 {
                    this.historyList.push(data[loop]);
                 }
             }
         }
         else
         {
             this.recordFlag=true;
         }
        },error => {
     
        });
    }
    
    if(this.moduleName=="VIEW_APPRAISAL_FORM")
	{
		this.labelName="Appraisal Form";
		this.hrmService.fetchHistoryForAppraisalForm(this.historyForm.value).subscribe(
    	data => {
            if(this.commonUtils.checkNullOrUndefined(data))
            {
                for(var loop=0; loop < data.length; loop++){
                    if(this.commonUtils.checkNullOrUndefined(data[loop].comments))
                    {
                        this.historyList.push(data[loop]);
                    }
                }
            }
            else
            {
                this.recordFlag=true;
            }
    	},error => {
            console.log(error);
    	});
	}
    if(this.moduleName=="VIEW_CANDIDATE")
        {
        this.labelName="Candidate Status History";
        this.recruitService.fetchCandidateStatusHistory(this.historyForm.value).subscribe(
        data => {
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)
         {
             for(var loop=0; loop < data.length; loop++){
                    this.historyList.push(data[loop]);                 
             }
         }
         else
         {
             this.recordFlag=true;
         }
     
        },error => {
     
        });
    }
    if(this.moduleName=="VIEW_APPOINTMENT")
        {
        this.labelName="Appointment History - " + this.selectedRow.appointmentNumber;
        this.recruitService.fetchRescheduleHistory(this.historyForm.value).subscribe(
        data => {
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)
         {    
             for(var loop=0; loop < data.length; loop++){
                    this.historyList.push(data[loop]);                 
             }   
         }
         else
         {
             this.recordFlag=true;
         }
     
        },error => {
     
        });
    }
     if(this.moduleName=="VIEW_JOB")
        {
        this.labelName="Job Age History - " + this.selectedRow.jobCode;
        this.recruitService.fetchJobAgingHistory(this.historyForm.value).subscribe(
        data => {
         if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)
         {    
             for(var loop=0; loop < data.length; loop++){
                    this.historyList.push(data[loop]);                 
             }   
         }
         else
         {
             this.recordFlag=true;
         }
     
        },error => {
     
        });
    }
}

    errorHandler(event) {
        event.target.src = this.errorimageSourcePath;
    }
    closeDialog(): void {
        this.dialogRef.close();
    }

openUploadSignaturePopup(){
    const dialogRef = this.dialog.open(UploadSignatureComponent, {
      width: '95%',
      height:'95%',
      data:{ 
          moduleName: 'NDA_SIGNATURE', portalName: this.portalName.toUpperCase(),
          employeeId: this.employeeId, employeeCode: this.employeeCode
        }
    });
    dialogRef.afterClosed().subscribe(result => {
        this.ndaSignImageSource = this.commonService.SERVICE_URL+"erpassets/"+ this.portalName.toUpperCase() + "/Employee/"+ this.employeeCode 
            + "/NDA/NDA_SIGNATURE/NDA_Sign_"+this.employeeId+".png";
        this.ndaSignImageFileExistFlag  = this.commonService.doesFileExist(this.ndaSignImageSource);
    });
}
    saveOrUpdateEmployeeHRPolicyFlag(event){
        this.t800EmployeeFormGroup.get('employeeId').setValue(this.commonService.loggedInUserObj.employeeId);
        this.t800EmployeeFormGroup.get('hrPolicyAgreedFlag').setValue(event.checked);
        this.hrmService.saveOrUpdateEmployeeHRPolicyFlag(this.t800EmployeeFormGroup.value).subscribe(
        data => {
            this.fetchEmployeeFlagValue();
        },
        error => {
            this.commonService.showERPErrorMessage(error);
        });
    }

    fetchEmployeeFlagValue(){
        this.t800EmployeeFormGroup.reset();
        this.t800EmployeeFormGroup.get('employeeId').setValue(this.commonService.loggedInUserObj.employeeId);
        this.hrmService.fetchEmployeeFlagValue(this.t800EmployeeFormGroup.value).subscribe(
        data => {
            if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
                this.hrPolicyFlagValue = data[0].HR_POLICY_FLAG;
            }
            this.commonService.hideProgressBar();
        },
        error => {
            this.commonService.showERPErrorMessage(error);
            this.commonService.hideProgressBar();
        });
    }
}