/* Author Rajesh Subramanian - esales software solutions*/ 
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroup,FormBuilder,FormArray} from '@angular/forms';
import {Router} from '@angular/router';
import { MatButton } from '@angular/material/button';
import {Subscription,BehaviorSubject} from 'rxjs';
import {CommonUtils} from '../../common-utils';
import {CreateLoanComponent} from '../../../hrm/loans/create-loan.component';
import {LoanTransactionComponent} from '../../../hrm/loans/loan-transaction.component';
import {CreatePaycodeComponent} from '../../../hrm/paycode/create-paycode.component';
import {CreateTaskComponent} from '../../../project/createtask.component';
import{HrmService} from '../../../hrm/hrm-service';
import {CommonService} from '../../../shared/common.service';
import {CreateOrganizationProfileComponent} from '../../../settings/organizationprofile/createorganization/createorganizationprofile.component'
import {CreateUserComponent} from '../../../settings/users/create-user.component';
import {DialogOverviewExampleComponent} from '../../../dialogBox/dialogBox.component';
import {environment } from '../../../../environments/environment';
 import {EmailDialogComponent}from '../../../dialogBox/dialogBox.component';
import {ApproveLeaveComponent} from '../../../popup/approveleave/approveleave.component';
import {ReminderComponent} from '../../../popup/reminder/reminder.component';
import {RescheduleComponent} from '../../../popup/reschedule/reschedule.component';
import {IconsComponent} from '../../../popup/icons/icons.component';
import { Overlay } from '@angular/cdk/overlay';
declare const require: any;
import {ErpOverlayRef} from '../../components/ErpOverlayRef';
import {ErpPopupService} from '../../components/Erp-popup-service';
import { ViewQuickInfoComponent} from '../view-quick-info/view-quick-info.component';
import {ViewTemplateWrapperAnalyticsComponent} from  '../../analytics/view-template-wrapper-analytics.component';
import {TimeSheetComponent} from '../../../popup/edittimesheet/timesheet.component';
import {ProjectService} from '../../../project/project.service';
import{ViewHistoryComponent} from '../../../core/view-template/view-history/view-history.component';
import {CreateMenuComponent} from '../../../superadmin/superadmin-menu/createMenu.component';
import {CreateValidationComponent} from '../../../superadmin/createtemplate/create-validation.component';
import {CreateReviewDisplayColumn} from '../../../superadmin/reviewdisplay/createreviewdisplay.component';
import {CreateAppraisalMaster} from '../../../hrm/appraisalmaster/create-appraisalmaster.component';
import {ApplyAppraisalForm} from '../../../mycorner/appraisalForm/applyappraisalform.component';
import {CreateShiftMasterComponent} from '../../../hrm/shiftmaster/create-shift-master.component';
import {CreateShiftAllocationComponent} from '../../../hrm/shiftallocation/create-shift-allocation.component';
import {CreateProjectComponent} from '../../../project/createproject.component';
import {MultiUserWorkedHoursComponent} from '../../../project/multiuserworkedhours/multiuserworkedhours.component';
import {CreateJobComponent} from '../../../recruitment/jobs/createjob.component';
import {InterviewRoundComponent} from '../../../recruitment/candidate/interviewround.component';
import { RecruitmentService } from '../../../recruitment/recruitment-service';
import {InterviewAllotmentComponent} from '../../../recruitment/candidate/interviewallotment/interviewallotment.component';
import { DateFormatPipe } from '../../../shared/dateFormatPipe.pipe';
import { AssetTransactionComponent } from '../../../assettracking/assets/asset-transaction.component';
import { CreateWareHouseComponent } from '../../../settings/warehouse/create-warehouse.component';

@Component({
  selector: 'app-view-search-results',
  templateUrl: './view-search-results.component.html',
  providers: [
   
  ],
})

export class ViewSearchResultsComponent extends CommonUtils implements OnInit,OnDestroy  {
NAME_BOLD;
EDIT;
COMPANY_NAME_BOLD;
EMAIL_BOLD;
WORK_PHONE_BOLD;
RECEIVABLES;
PAYABLES;
PROFILE_NAME;
DATE_BOLD;
BILL_BOLD;
ESTIMATE_BOLD
VENDOR_NAME_BOLD;
REFERENCE_NUMBER_BOLD;
STATUS_BOLD;
PORTAL_NAME;
DUE_DATE_BOLD;
AMOUNT_BOLD;
BALANCE_DUE_BOLD;
REF_BOLD;
MODE_BOLD;
UNUSED_AMOUNT_BOLD;
PO_RECEIVE;
PAID_THROUGH_BOLD;
CUSTOMER_NAME_BOLD;
EMPLOYEE_CODE_BOLD;
EMPLOYEE_NAME_BOLD;
MOBILE_NUMBER_BOLD;
DEPARTMENT_BOLD;
DESIGNATION_BOLD;
EXPENSE_ACCOUNT_BOLD;
FREQUENCY_BOLD;
START_DATE_BOLD;
END_DATE_BOLD;
PACKAGE;
PACKAGE_NO;
SO_NO;
ASSET_NUMBER_BOLD;
PACKAGE_DATE;
SHIPPING;
DESCRIPTION_BOLD;
SHOW_IN_REPORT_BOLD;
CONSIDER_FOR_PF_BOLD;
CONSIDER_FOR_ESI_BOLD;
CONSIDER_FOR_LOP_BOLD;
DEDUCTION_BOLD;
DISPLAY_ORDER_BOLD;
PAYCODE_NUMBER_BOLD;
BILLED_STATUS_BOLD;
PROFILE_NAME_BOLD;
message: string;
tableLength;
CANDIDATE_CODE;
CANDIDATE_NAME;
CANDIDATE_MOBILE;
CANDIDATE_EMAIL;
CANDIDATE_SOURCE;
CANDIDATE_INDUSTRY;
TOTAL_YRS_OF_EXP;
pageSize = 10;
accessObj;
totalApproved: number=0;
artworkApprovalFlag:boolean=false;
  myForm: FormGroup;
  errorFormGroup:FormGroup;
  myNotesForm: FormGroup;
  myMedicalForm: FormGroup;
  myBatchQbForm: FormGroup;
  notesFlag:boolean=false;
  loggedInUserObjRole;
  //reminderFlag:boolean=true;
  userObj;
  toEmail;
  ccEmail;
  bccEmail;
  result;
  invoiceHeaderId;
  salesPersonEmail;
 selectedRow:any={};
 convtToBill:boolean=false;
 convertInvoice:boolean=false;
 statusToConvert:boolean=false;
 notesToggleFlag:boolean=false;
 //recordUnread:boolean =false;
 graphicsRecordUnread:boolean=false;
 customerRecordUnread:boolean=false;
 salespersonRecordUnread:boolean=false;
 portalName:string;
 contactsPaymentArray=[];
 SERVICE_URL;
 detailID;
 estimateHeaderID;
 rescheduleFlag = false;
@Input() dataSource;
  @Input() displayedColumns;
   @Input() displayedColumnsOriginal;
   @Input() UserLabelNameForColumn;
   @Input() quickInfoLabels;
   @Input() quickInfoValuesOriginal;
    @Input() moduleName: string ='';
    @Input() editHideFlag: boolean;
    @Input() cloneSearchIconFalg: boolean ;
     @Input() schemaValue;
      @Input() searchString;
       @Input() tempModuleNameForItem;
       noRecord: boolean ;
   editId: any;
   cloneId : number;
   userType;
fractionsize: string = '1.0-0';
  subscriptionList: Array<Subscription> = [];
 @Input() selection = new SelectionModel<Element>(true, []);
  //@Input() contactsArray = [];
  @Output() viewSearchresultEmitter=new EventEmitter<any>();
    @ViewChild('moreMenu') elementRef: MatButton;
    @ViewChild('empHierarchyId') empHierarchyElementRef: MatButton;

  @Input() currentPageAccessObj;
 
    artworkForm;
  getArtworkForm():FormGroup{
    return new FormGroup({
      masterSlideToggle:new FormControl(false),
      editedT316ArtworkHistory:this.addArtworkHistory(),    
      t316ArtworkHistoryList: this.formBuilder.array([])
    });
  };


    artworkFormForGraphics = new FormGroup({
      invoiceHeaderId: new FormControl(''),
      refNumber: new FormControl(''),
      createdDate: new FormControl(new Date()),
      artworkCSAName: new FormControl(''),
      decoVendorName: new FormControl(''),
      artServiceVendorName: new FormControl(''),
      primaryContactEmail: new FormControl(''),
      moduleType:  new FormControl(''),
      t401InvoiceDetailsList: new FormArray([]),
      createdTs: new FormControl(new Date()),
      updatedTs: new FormControl(new Date()),
      t001UserCreatedBy: new FormGroup({userId: new FormControl(0), userName : new FormControl('')}),
      t001UserUpdatedBy: new FormGroup({userId: new FormControl(0), userName : new FormControl('')}),
      moduleName: new FormControl(this.moduleName),
      lazyLoad: new FormControl('N'),
      invoiceDetailsId : new FormControl('')

     });
 

  addArtworkHistory(): FormGroup {
    return this.formBuilder.group({
      arkWorkHistoryId: new FormControl(0),
      description:new FormControl(''),
      createdTs:new FormControl(new Date()),
      artworkAutoGenNumber: new FormControl(''),
      t001UserCreatedById: new FormGroup({userId: new FormControl(this.commonService.loggedInUserObj.userId)}),
      uploadedByName: new FormControl(this.commonService.loggedInUserObj.userName),
      t004ArtworkStatusObj: new FormGroup({lookupId: new FormControl(0), lookupValue: new FormControl('')}),
      loginType: new FormControl(this.commonService.loggedInUserObj.type),
      t315ArtworkDetailsId: new FormGroup({invoiceDetailsId: new FormControl(0), moduleName: new FormControl(''), headerId: new FormControl(0), artworkNumber: new FormControl(''),
        t004ArtworkStatusObj:new FormGroup({lookupId: new FormControl(0)})}),
      t317ArtworkRequestImagesList: this.formBuilder.array([])
    });
  }

 
 
  

  constructor(
    public commonUtils: CommonUtils, 
    private router: Router, 
    private dialog: MatDialog,private hrmService: HrmService,
    private commonService: CommonService,
    private formBuilder:FormBuilder,private overlay: Overlay,
    private projectService: ProjectService,
    private erpCustomDialog: ErpPopupService,
    private recruitService: RecruitmentService,
    private datePipe: DateFormatPipe) {
super();
this.SERVICE_URL=this.commonService.SERVICE_URL;
 this.myForm = new FormGroup({
   reminderDetailId: new FormControl(),
     reminderDate : new FormControl(),
    notesDescription : new FormControl(''),
    createdDttm: new FormControl(new Date),
    updatedDttm: new FormControl(new Date),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0), userName: new FormControl('')}),
    t001UserUpdatedBy: new FormGroup({userId: new FormControl(0)}),
    invoiceHeaderId:new FormControl(0),
    moduleName: new FormControl(''),
    routeURL: new FormControl(''),
    contactId: new FormControl(0),
     itemId: new FormControl(0),
     interviewSlotId: new FormControl(0),
     appointmentId: new FormControl(0),
     itemGroupId: new FormControl(0),
     priceListHeaderId: new FormControl(0),
     paymentMadeHeaderId:new FormControl(0),
     employeeId:new FormControl(0),
     payCodeId:new FormControl(0),
     paymentReceivedHeaderId:new FormControl(0),
     expenseHeaderId:new FormControl(0),
     t001UserReminderTo: new FormGroup({
              userId: new FormControl(),
     }),
     reminderModuleName: new FormControl(''),
     reminderSentBy: new FormGroup({
       userId: new FormControl(),
     })
 })
   this.errorFormGroup=new FormGroup({
      errorMessage: new FormControl(''),
      errorStackTrace: new FormControl('')      
  })
   this.myNotesForm = new FormGroup({
    historyId: new FormControl(0),
    userComments : new FormControl(''),
    createdTs: new FormControl(new Date),
    headerHistoryId:new FormGroup({invoiceHeaderId:new FormControl(0),
      expenseHeaderId:new FormControl(0),
      paymentMadeHeaderId:new FormControl(0),
    contactId: new FormControl(0)}),
    t001UserCreatedBy: new FormGroup({userId: new FormControl(0),userName: new FormControl('')})    
   })
   this.myMedicalForm = new FormGroup({
     medicalBillId: new FormControl(0),
     billNumber: new FormControl(0),
     status: new FormControl('')});

   this.myBatchQbForm = new FormGroup({
     invoiceHeaderId: new FormControl(0),
     moduleName: new FormControl(''),
     totalFinalAmount: new FormControl('0'),
   });
   
 }
 
 onSONumFocusOut(value1,value2){
    
   
 }
	
  quickInfoValues;
bindSelectedValue(selectedRow:any,elementRef)
{
/*Logic is: QuickInfoLabels and QuickInfoValues will come from the database. This will set up for each modules in the Review Display Columns Table.
QuickInfoLabels is the one which is displayed as Label in the screen.QuickInfoValues will be the formcontrolname of the value 
from the selected row object.*/
/*try
{*/
  this.selectedRow=selectedRow; 
  
  /*here the original object is cloned and assigned to quickInfoValues array 
  so that the original value from database will not get affected*/
  this.quickInfoValues=Object.assign([],this.quickInfoValuesOriginal);
   
    for(var loop = 0; loop < this.quickInfoValues.length; loop++){   
   /*QuickInfoValues will be looped - separated using dot '.'*/
    let objString = this.quickInfoValues[loop];
    let firstLevelString = this.quickInfoValues[loop].split('.')[0];
    let secondLevelString = this.quickInfoValues[loop].split('.')[1];
    let thirdLevelString = this.quickInfoValues[loop].split('.')[2]; 
    let fourthLevelString = this.quickInfoValues[loop].split('.')[3];
  
  /*Value will be taken from the selected row object and creates the new object. Eg: If the string is 
      'contactObj.billingObj.billingAddressLineOne' then in the selectedRow - new object will be created as 
      billingAddressLineOne14(14 is the loop to make the string unique in the object) assigned with an value*/

    /*this if loop will be executed if the loop string is having levels of 2 - means string1.string2*/ 
    if(this.quickInfoValues[loop].split('.').length === 2){ 
       
      if(this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString]))
        /*this is the place where the new object is created and pushed to selected row and value is assigned*/
        this.selectedRow[secondLevelString+loop]=this.selectedRow[firstLevelString][secondLevelString];
      else
        this.selectedRow[secondLevelString+loop]='';
       /*the last level of the string is assigned to quickInfoValues - so that it can be taken directly in the QuickInfoComponent HTML*/
      this.quickInfoValues[loop] = this.quickInfoValues[loop].split('.')[1]+loop;
     }

    /*this if loop will be executed if the loop string is having levels of 23- means string1.string2.string3*/
    if(this.quickInfoValues[loop].split('.').length === 3){
       
      if(this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString][thirdLevelString]))
        this.selectedRow[thirdLevelString+loop]=this.selectedRow[firstLevelString][secondLevelString][thirdLevelString];
      else
        this.selectedRow[thirdLevelString+loop]='';
      this.quickInfoValues[loop] = this.quickInfoValues[loop].split('.')[2]+loop;
    }

    /*this if loop will be executed if the loop string is having levels of 4 - means string1.string2.string3.string4*/
    if(this.quickInfoValues[loop].split('.').length === 4){
        
      if(this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString][thirdLevelString]) && 
        this.commonUtils.checkNullOrUndefined(this.selectedRow[firstLevelString][secondLevelString][thirdLevelString][fourthLevelString]))
        this.selectedRow[fourthLevelString+loop]=this.selectedRow[firstLevelString][secondLevelString][thirdLevelString][fourthLevelString];
      else
        this.selectedRow[fourthLevelString+loop]='';
      this.quickInfoValues[loop] = this.quickInfoValues[loop].split('.')[3]+loop;
    }
  }
  
  if(this.commonUtils.checkNullOrUndefined(this.selectedRow.t004LookupPOBillStatusId)){
    if(this.selectedRow.t004LookupPOBillStatusId.lookupValue==='BILLED')
      this.convtToBill=false;
    else
      this.convtToBill=true;  
  }  

   if(this.commonUtils.checkNullOrUndefined(this.selectedRow.t004StatusObj))
   {
    if(this.selectedRow.t004StatusObj.lookupValue==='ACCEPTED')
      this.statusToConvert=true;
    else
      this.statusToConvert=false;  
  } 

   if(this.commonUtils.checkNullOrUndefined(this.selectedRow.t004LookupSOStatusId))
   {
    if(this.selectedRow.t004LookupSOStatusId.lookupValue==='OPEN')
      this.statusToConvert=true;
    else
      this.statusToConvert=false;  
  } 

  //this.contactsArray.push(selectedRow);
/*}
catch(error)
{
  this.errorFormGroup.get('errorMessage').setValue(this.moduleName);
     this.errorFormGroup.get('errorStackTrace').setValue(error.message);
     
     this.commonService.saveErrorMessage(this.errorFormGroup.value).subscribe(
       data=>{
        },
       error=>{
        
       });
}*/

  let reminderDialogRef;
    reminderDialogRef= this.erpCustomDialog.open(elementRef._elementRef,{
      data: {moduleName:this.moduleName,selectedRow:selectedRow,quickInfoValues:this.quickInfoValues,quickInfoLabels:this.quickInfoLabels,convtToBill:this.convtToBill,convertInvoice:this.convertInvoice}
    },ViewQuickInfoComponent);

 
reminderDialogRef.afterClosed.subscribe( result => { 

  this.openDialogPrint(result);
  });
}

  downloadAttachedFiles(issueObj): void{
    var FileSaver = require('file-saver');
    let fileName: string = issueObj.issueOrFeedbackId+'.zip';
    let portalName = this.commonService.loggedInUserObj.t000Organization.portalName;
    let imagePath = this.SERVICE_URL + 'erpassets/'+portalName.toUpperCase()+'/NEW_ISSUES';
    this.commonService.downloadImage(imagePath+'/'+fileName).subscribe(
      (response) => {
        var blob = new Blob([response], {type:"application/zip"});
        FileSaver.saveAs(blob,fileName);
      },
      (error)=>{
        console.log(error);
      });
 }

openDialogPrint(value):void{
  if(value.action === 'CONVERT_TO_BILL'){
    this.viewSearchresultEmitter.emit({action:"PRINT_DIALOG_OPEN",data:'BILL',selectedObjectFromGrid:value.selectedObject});
  }
  if(value.action === 'INVOICE'){
    this.viewSearchresultEmitter.emit({action:"PRINT_DIALOG_OPEN",data:'INVOICE'});
  }
  if(value.action === 'ESTIMATE_TO_SALES_ORDER'){
    this.viewSearchresultEmitter.emit({action:"PRINT_DIALOG_OPEN",data:'ESTIMATE_TO_SALES_ORDER'});
  }
  if(value.action === 'VIEW_HISTORY'){
    this.viewSearchresultEmitter.emit({action:"VIEW_HISTORY",data:value.selectedRow});
  }
  if(value.action === 'VIEW_FEEDBACK'){
    this.viewSearchresultEmitter.emit({action:"PRINT_DIALOG_OPEN",data:'TASK'});
  }
}

addReminder(selectedRow:any,elementRef:any){

//below code is to load the default value in teh pop up 
//myform - reminder
//mynotesform - notes 
this.myForm.get('reminderDate').setValue(new Date);
if(this.commonUtils.checkNullOrUndefined(selectedRow.contactsType)){
this.myForm.get('notesDescription').setValue(selectedRow.contactDisplayName);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.invoiceNumber)){
this.myForm.get('notesDescription').setValue(selectedRow.invoiceNumber);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.itemName)){
this.myForm.get('notesDescription').setValue(selectedRow.itemName);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.profileName)){
this.myForm.get('notesDescription').setValue(selectedRow.profileName);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.name)){
this.myForm.get('notesDescription').setValue(selectedRow.name);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.itemGroupName)){
this.myForm.get('notesDescription').setValue(selectedRow.itemGroupName);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.paymentMadeHeaderId)){
this.myForm.get('notesDescription').setValue(selectedRow.paymentMadeHeaderId);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.productionSlipNumber)){
this.myForm.get('notesDescription').setValue(selectedRow.productionSlipNumber);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.packageSlipNumber)){
this.myForm.get('notesDescription').setValue(selectedRow.packageSlipNumber);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.userName)){
this.myForm.get('notesDescription').setValue(selectedRow.userName);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.payCode)){
this.myForm.get('notesDescription').setValue(selectedRow.payCode);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.paymentReceivedHeaderId)){
this.myForm.get('notesDescription').setValue(selectedRow.paymentReceivedHeaderId);
}
if(this.commonUtils.checkNullOrUndefined(selectedRow.expenseHeaderId)){
this.myForm.get('notesDescription').setValue(selectedRow.expenseHeaderId);
}


  let reminderDialogRef;
    reminderDialogRef= this.erpCustomDialog.open(elementRef._elementRef,{
      data: this.myForm.value
    },ReminderComponent);

 
reminderDialogRef.afterClosed.subscribe( result => {
  this.commonService.showProgressBar();
    this.myForm.patchValue(result);
      this.myForm.get('invoiceHeaderId').setValue(selectedRow.invoiceHeaderId);
       this.myForm.get('contactId').setValue(selectedRow.contactId);
       this.myForm.get('itemId').setValue(selectedRow.itemId);
        this.myForm.get('itemGroupId').setValue(selectedRow.itemGroupId);
        this.myForm.get('priceListHeaderId').setValue(selectedRow.priceListHeaderId);
        this.myForm.get('paymentMadeHeaderId').setValue(selectedRow.paymentMadeHeaderId);
        this.myForm.get('employeeId').setValue(selectedRow.employeeId);
        this.myForm.get('payCodeId').setValue(selectedRow.payCodeId);
        this.myForm.get('paymentReceivedHeaderId').setValue(selectedRow.paymentReceivedHeaderId);
        this.myForm.get('expenseHeaderId').setValue(selectedRow.expenseHeaderId);
     
    this.addReminderDetails();
});
  }

erpSubjectrSubscription;
    ngOnInit() 
    { 
     
     let projectSubject = this.commonService.projectSubject.subscribe(
      (status: any) => 
      {
        this.noRecord=status;
      });
      this.loggedInUserObjRole = this.commonService.loggedInUserObj.roleName;
this.subscriptionList.push(projectSubject);

      if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)  && this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.type))
         this.userType =this.commonService.loggedInUserObj.type;
      if(this.moduleName=='VIEW_PO' || this.moduleName=='VIEW_BILL' || this.moduleName=='VIEW_R_BILL' || this.moduleName=='VIEW_PAYEMNT_MADE' || this.moduleName=='VIEW_EXPENSE'
        || this.moduleName=='VIEW_VENDOR_CREDIT' || this.moduleName=='VIEW_ESTIMATE' || this.moduleName=='VIEW_SALESORDER' || this.moduleName=='VIEW_INVOICE' || this.moduleName=='VIEW_R_INVOICE' || this.moduleName=='VIEW_CREDIT_NOTES')
      this.notesToggleFlag=true;
    else
      this.notesToggleFlag=false;
    this.erpSubjectrSubscription=this.commonService.erpSubject.subscribe(result=>{
      if (this.commonUtils.checkNullOrUndefined(result)){
        if(result==='REFRESH'){
           this.refreshIconClick(true);
        }
        if(result==='SEARCH'){
           this.searchIconClick();
        }
        if(result==='ANALYTICALCHART'){
        this.openAnalyticalChart();
        } else {
          this.viewSearchresultEmitter.emit({action:"USER_DISPLAY_COLUMN",data:result});
        }
      } 
    })
    } 

    openPopUpOnVendorForArtwork(detailObj){
      if(this.commonUtils.checkNullOrUndefined(detailObj)){
        this.editRow(detailObj);
      }
    }

  ngOnDestroy() {
      for (let loop = 0; loop < this.subscriptionList.length; loop++) {
        this.subscriptionList[loop].unsubscribe();
      }
      if (this.commonUtils.checkNullOrUndefined(this.erpSubjectrSubscription))
      this.erpSubjectrSubscription.unsubscribe();
    }
   searchIconClick() {
     this.viewSearchresultEmitter.emit({action:"SEARCH_ICON_CLICK"});
   }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    
      
    }
 payment(element) {

      const dialogRef = this.dialog.open(LoanTransactionComponent,
      {
        width: '800px',
        data: {element: element}
      });

      dialogRef.afterClosed().subscribe(result => {

      });

 }

 getTotaEstimatedHrs(){     
    return this.dataSource._data._value.map(t => t.estimated_hours).reduce((acc, value) => Number(acc) + Number(value), 0);
  }
 getTotalActualHrs(){     
    return this.dataSource._data._value.map(t => t.actualHours).reduce((acc, value) => Number(acc) + Number(value), 0);
  }
  getTotalBillableHrs(){     
    return this.dataSource._data._value.map(t => t.billing_hours).reduce((acc, value) => Number(acc) + Number(value), 0);
  }
  getTotalNonBillableHrs(){     
    return this.dataSource._data._value.map(t => t.nonBillableHours).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

editRow(element,elementRef?) 
{
this.portalName=this.commonService.loggedInUserObj.t000Organization.portalName;
    if (this.moduleName === 'VIEW_EMPLOYEE') {
      this.editId = element.employeeId;
    }
    else if (this.moduleName === 'REVIEW_TRAINEE'){
      this.editId = element.feedbackHeaderId;
    }
    else if (this.moduleName === 'VIEW_CANDIDATE') {
      this.editId = element.candidateId;
    }
    else if (this.moduleName === 'VIEW_APPOINTMENT') {
      this.editId = element.appointmentId;
    }
    else if (this.moduleName === 'VIEW_ANNOUNCEMENTS') {
      this.editId = element.announcementId;
    }
     else if (this.moduleName === 'VIEW_INTERVIEW_SLOT') {
      this.editId = element.interviewSlotId;
    }
      else if (this.moduleName === 'VIEW_RECEIVINGS') {
       this.editId = element.receivingsHeaderId;
     }
     else if (this.moduleName === 'VIEW_LOAN')
      {
         this.editId = element.t800Employee.employeeId;
          const dialogRef = this.dialog.open(CreateLoanComponent,{width: '800px',
          data: {element: element}
        });
        dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
     }
     else if (this.moduleName === 'VIEW_PAYCODE') {
      const dialogRef = this.dialog.open(CreatePaycodeComponent,
      {
        width: '500px',
        data: {paycodeId:element.payCodeId}
      });

       dialogRef.afterClosed().subscribe(result => {
       if(result)
          this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
       });

      }
      else if (this.moduleName === 'VIEW_SHIFT_MASTER') {
      const dialogRef = this.dialog.open(CreateShiftMasterComponent,
      {
        width: '500px',
        data: element
      });

       dialogRef.afterClosed().subscribe(result => {
       if(result)
          this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
       });

      }
         else if (this.moduleName === 'VIEW_SHIFT_ALLOCATION') {
      const dialogRef = this.dialog.open(CreateShiftAllocationComponent,
      {
        width: '500px',
        data: element
      });

      

       dialogRef.afterClosed().subscribe(result => {
       if(result)
          this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
       });

      }
      else if (this.moduleName === 'VIEW_ASSETS') {
        this.editId = element.assetHeaderId;
     }
     else if (this.moduleName === 'VIEW_ORGANIZATION')
      {
        this.editId = element.organizationId;

        const dialogRef = this.dialog.open(CreateOrganizationProfileComponent,{width: '95%',maxWidth:'95vw',
          height:'95%', data: {organizationId: this.editId }


        });
        dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
     }else if(this.moduleName === 'VIEW_USER'){
    
      const dialogRef = this.dialog.open(CreateUserComponent,
      {
        width: '500px',
        data: {userObj: element, userNameEditFlag:true}
      });
      //this.commonService.hideProgressBar();
      dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
      

     }else if(this.moduleName === 'VIEW_ROLE'){
      
       this.router.navigate(['/settings/createrole',btoa( element.roleId)]);
     }
     else if (this.moduleName === 'VIEW_ITEMS_GROUP' || this.moduleName === 'TS_VIEW_ITEMS_GROUP') {
       this.editId = element.itemGroupId;
     }
     else if(this.moduleName === 'VIEW_VALIDATION'){
      this.commonService.hideProgressBar();
      const dialogRef = this.dialog.open(CreateValidationComponent,
      {
        width: '50%',
        data: {validationObj: element}
      });
      dialogRef.afterClosed().subscribe(result => {
        
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
      this.commonService.hideProgressBar();

     }
     else if (this.moduleName === 'VIEW_PRICE_LIST' || this.moduleName === 'TS_VIEW_PRICE_LIST') {
       this.editId = element.priceListHeaderId;
     }
   else if (this.moduleName === 'VIEW_LICENSE') {
       this.editId = element.licenseId;
     }
 else if(this.moduleName === 'MANAGE_ARTWORK')
 { 
      
 }
  else if(this.moduleName === 'VIEW_EXPENSE')
  {
    this.editId = element.expenseHeaderId;
  }
else if (this.moduleName === 'VIEW_PO_RECEIVE') {

       this.editId = element.invoiceHeaderId;
    }
    else if (this.moduleName === 'VIEW_SO_RECEIVE') {
       this.editId = element.t303_SalesOrder_HeaderObj.invoiceHeaderId;
    }
   else if(this.moduleName === 'PROJECT_PLAN')
     {
      
      const dialogRef = this.dialog.open(CreateTaskComponent,
        {
          width: '80%',
          height:'95%',
          data: {selectedTaskObj:element,statusFlag:'edit'}
        });
        dialogRef.afterClosed().subscribe(result => {
         
        if(result)
         this.viewSearchresultEmitter.emit({action:"REFRESH_TASK",value:element.t822ProjectObj.projectId});
        });
     }
     else if(this.moduleName === 'APPROVE_LEAVE'){
       
        let approveDialogRef:ErpOverlayRef= this.erpCustomDialog.open(elementRef._elementRef,{data: element},ApproveLeaveComponent);
         approveDialogRef.afterClosed.subscribe(result=>{  
           if(this.commonUtils.checkNullOrUndefined(result))
           {
           let leaveHistoryObj = result;
            if(leaveHistoryObj.statusFlag === 'REJECTED')
            {
              leaveHistoryObj.clApproved = 0;
              leaveHistoryObj.slApproved = 0;
              leaveHistoryObj.compOffApproved = 0;
              leaveHistoryObj.lop = 0;
              leaveHistoryObj.approvedDays = 0;
            }
            else if(leaveHistoryObj.statusFlag === 'APPROVED')
            {
               this.totalApproved = parseFloat(leaveHistoryObj.clApproved)+parseFloat(leaveHistoryObj.slApproved)+
                                  parseFloat(leaveHistoryObj.compOffApproved)+parseFloat(leaveHistoryObj.lop);             
              leaveHistoryObj.approvedDays = this.totalApproved;
            }
           this.hrmService.updateStatusForLeaveHistory(leaveHistoryObj)
              .subscribe(
                data => {  
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage(data.erpmessage,null);
                  this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);
                });
                
      
        } })
    }
    else if(this.moduleName === 'VIEW_TIMESHEET'){
       element.moduleName = this.moduleName;
        let timesheetDialogRef:ErpOverlayRef= this.erpCustomDialog.open(elementRef._elementRef,{data: element},TimeSheetComponent);
         timesheetDialogRef.afterClosed.subscribe(result=>{ 
           if(this.commonUtils.checkNullOrUndefined(result))
           {
           let timesheetObj = result;
           this.commonService.showProgressBar();
           this.projectService.saveOrUpdateTimeSheet(timesheetObj)
              .subscribe(
                data => {  
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage(data.erpmessage);
                  this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);
                });
        } })
    }
     else if(this.moduleName === 'VIEW_HRIS_TIMESHEET'){
        element.moduleName = this.moduleName;
        let timesheetDialogRef:ErpOverlayRef= this.erpCustomDialog.open(elementRef._elementRef,{data: element},TimeSheetComponent);
         timesheetDialogRef.afterClosed.subscribe(result=>{ 
           if(this.commonUtils.checkNullOrUndefined(result))
           {
           let timesheetObj = result;
           this.commonService.showProgressBar();
           this.projectService.saveOrUpdateTimeSheet(timesheetObj)
              .subscribe(
                data => {  
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage(data.erpmessage);
                  this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);
                });
                
      
        } })
    }
    else if(this.moduleName === 'VIEW_MENU')
     {
       this.editId = element.menuId;
         const dialogRef = this.dialog.open(CreateMenuComponent,
        {
          width: '500px',
          data: {menuObj: element,schemaName:this.schemaValue}
        });
        dialogRef.afterClosed().subscribe(result => {
           if(result)
              this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
          });
     }
      else if(this.moduleName === 'VIEW_REVIEW_DISPLAY_COLUMN')
     {
         const dialogRef = this.dialog.open(CreateReviewDisplayColumn,
        {
          width: '900px',
          data: {reviewDisplayObj: element}
        });
        dialogRef.afterClosed().subscribe(result => {
           if(result)
              this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
          });
     }
      else if(this.moduleName === 'VIEW_APPRAISAL_MASTER'){
    
      const dialogRef = this.dialog.open(CreateAppraisalMaster,
      {
        width: '95%',
        height: '95%',
        data: {appraisalObj: element}
      });
      dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
      this.commonService.hideProgressBar();

     }

      else if(this.moduleName === 'VIEW_APPRAISAL_FORM'){
    
      const dialogRef = this.dialog.open(ApplyAppraisalForm,
      {
        width: '95%',
        height: '95%',
        data: {appraisalFormObj: element}
      });
      dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
      this.commonService.hideProgressBar();

     }else if (this.moduleName === 'VIEW_WAREHOUSE') {
      const dialogRef = this.dialog.open(CreateWareHouseComponent,
        {
          width: '500px',
          height: '600px',
          data: { wareHouseObj: element }
        });
      dialogRef.afterClosed().subscribe(result => {
        if (result)
          this.viewSearchresultEmitter.emit({ action: "REFRESH_PAGE" });
      });

    }
   else if (this.moduleName === 'TS_VIEW_CONTACT') {
       this.editId = element.contactId; 
     }
     else if(this.moduleName === 'TS_VIEW_ITEM' && (this.tempModuleNameForItem === 'VIEW_ITEM' || this.tempModuleNameForItem === 'VIEW_ITEMS') ) {
       this.editId = element.itemId;
       this.moduleName = this.tempModuleNameForItem; 
     }
     else if(this.moduleName === 'TS_VIEW_SALES_PERSON') {
       this.viewSearchresultEmitter.emit({action:"OPEN_DAILOG_POPUP",data:element});
     }
     else if(this.moduleName === 'TS_VIEW_PRODUCTION_SLIP') {
       this.viewSearchresultEmitter.emit({action:"OPEN_DAILOG_POPUP",data:element});
     }
      else if (this.moduleName === 'VIEW_PROJECT')
      {
         this.editId = element.projectId;
          const dialogRef = this.dialog.open(CreateProjectComponent,{width: '500px',
          data: {projectObj: element}
        });
        dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
     }
     else if (this.moduleName === 'VIEW_JOB')
      {
         this.editId = element.jobId;
          const dialogRef = this.dialog.open(CreateJobComponent,{width: '60%',
          data: {jobObj: element}
        });
        dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        });
     }
      else if (this.moduleName === 'VIEW_ACCOUNT_BUDGET') {
       this.editId = element.budgetHeaderId;
      }

     else {
       this.editId = element.invoiceHeaderId;
     }
 
//  THIS BELOW IF BLOCK WILL GET EXECUTE FOR ALL THE MODULES EXCEPT VIEW_LOAN AND VIEW_PAYCODE
     if (this.moduleName !== 'PROJECT_PLAN' && this.moduleName !== 'VIEW_LOAN' && this.moduleName !== 'VIEW_PAYCODE' && this.moduleName != 'VIEW_ROLE' && this.moduleName != 'VIEW_USER' && this.moduleName !== 'VIEW_CATEGORY'
       && this.moduleName !== 'VIEW_SALES_PERSON' && this.moduleName !== 'VIEW_WAREHOUSE' && this.moduleName !== 'VIEW_ORGANIZATION' && this.moduleName!=='VIEW_PRODUCTION_SLIP'
       && this.moduleName !== 'MANAGE_ARTWORK' && this.moduleName !== 'VIEW_TAX_GROUP' && this.moduleName !== 'CHART_OF_ACCOUNTS'  && this.moduleName !== 'VIEW_MENU' && this.moduleName != 'VIEW_APPRAISAL_MASTER' && this.moduleName != 'VIEW_VALIDATION' &&
       this.moduleName !== 'VIEW_APPRAISAL_FORM' && this.moduleName !== 'TS_VIEW_PRODUCTION_SLIP' &&  this.moduleName !== 'TS_VIEW_SALES_PERSON' &&  this.moduleName != 'VIEW_PROJECT' && this.moduleName !='VIEW_SHIFT_MASTER' && this.moduleName !='VIEW_SHIFT_ALLOCATION') {
     
        this.router.navigate([this.commonUtils.getAddLink(this.moduleName, btoa(this.editId))]);
        this.commonService.addMenuList(this.commonUtils.getAddLink(this.moduleName, btoa(this.editId)));
     }
      if(this.searchString === 'topSearch' && (this.moduleName === 'TS_VIEW_CONTACT'|| this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS'|| this.moduleName === 'TS_VIEW_ITEMS_GROUP' 
        || this.moduleName === 'TS_VIEW_PRICE_LIST' ||  this.moduleName === 'TS_VIEW_PO' || this.moduleName === 'TS_VIEW_BILL' || this.moduleName === 'TS_VIEW_R_BILL' 
        || this.moduleName === 'TS_VIEW_R_EXPENSE' || this.moduleName === 'TS_VIEW_PAYMENT_MADE' || this.moduleName === 'TS_VIEW_VENDOR_CREDIT' 
        || this.moduleName === 'TS_VIEW_EXPENSE' || this.moduleName === 'TS_VIEW_SALESORDER' || this.moduleName === 'TS_VIEW_ESTIMATE' 
        || this.moduleName === 'TS_VIEW_ARTWORK' || this.moduleName === 'TS_VIEW_PACKAGES' || this.moduleName === 'TS_VIEW_INVOICE' 
        || this.moduleName === 'TS_VIEW_R_INVOICE' || this.moduleName === 'TS_VIEW_PAYMENT_RECIEVED' || this.moduleName === 'TS_VIEW_CREDIT_NOTES'))
          {
              this.viewSearchresultEmitter.emit({action:"CLOSE_TOP_SEARCH"});
          }
}
editNistarProduct(element){

  this.commonService.nistarProductSetupSubject.next({action:"EDIT_PRODUCT",data:element});
 }

trigger(element)
{
   this.commonService.showProgressBar();
 this.commonService.triggerBatchSyncLog(element).subscribe(
   data=>
   {
     this.commonService.showERPMessage('Message has been triggered');
     this.commonService.hideProgressBar();
   },
   error=>
   {
     this.commonService.hideProgressBar();
     this.commonService.showERPErrorMessage(error);
   })

}
deleteResourceExtension(element)
{
   this.commonService.showProgressBar();
 this.hrmService.deleteResourceExtension(element).subscribe(
   data=>
   {
     this.commonService.showERPMessage('Resource has been deleted');
     this.commonService.hideProgressBar();
      this.viewSearchresultEmitter.emit({action:"REFRESH_RESOURCE",data:element.t822ProjectObj.projectId});
   },
   error=>
   {
     this.commonService.hideProgressBar();
     this.commonService.showERPErrorMessage(error);
   })

}
cloningObj(element){
  if (this.moduleName === 'VIEW_CONTACT') {
       this.editId = element.contactId;
  }else if (this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS') {
       this.editId = element.itemId;
  }
  else if(this.moduleName === 'VIEW_EXPENSE'){
    this.editId = element.expenseHeaderId;
  }
  else if(this.moduleName === 'VIEW_PAYMENT_MADE'){
    this.editId = element.paymentMadeHeaderId;
  }
  else if(this.moduleName === 'VIEW_PAYMENT_RECIEVED'){
    this.editId = element.paymentReceivedHeaderId;
  } else if (this.moduleName == 'VIEW_APPOINTMENT'){
    this.editId = element.appointmentId;
  }
else if(this.moduleName === 'PROJECT_PLAN')
     {
      const dialogRef = this.dialog.open(CreateTaskComponent,
        {
          width: '80%',
          height: '95%',
          data: {selectedTaskObj:element,statusFlag:'clone'}
        });
        dialogRef.afterClosed().subscribe(result => {
         
        if(result)
         this.viewSearchresultEmitter.emit({action:"REFRESH_TASK"});
        });
     } else{
    this.editId = element.invoiceHeaderId;
   
  }
  if (this.moduleName !== 'VIEW_LOAN' && this.moduleName !== 'VIEW_PAYCODE' && this.moduleName !== 'VIEW_ROLE' &&
   this.moduleName !== 'VIEW_USER' && this.moduleName !== 'VIEW_SALES_PERSON' && this.moduleName != 'PROJECT_PLAN') 
  {
        this.router.navigate([this.commonUtils.getRedirectCloneLink(this.moduleName,   btoa(this.editId),'true')]);
        //this.commonService.addMenuList(this.commonUtils.getAddLink(this.moduleName, this.editId));
  }
}
/*Commented  by 9056 Payment Flow ----- START*/
/*invoicePayment(finalTotal){
  const dialogRef = this.dialog.open(AuthorizePaymentComponent, {
            width: '950px',
            data: {amount: finalTotal}
          });
          dialogRef.afterClosed().subscribe(result =>
           {
           if(result)
                  this.viewPageRefresh.emit({action:"REFRESH_PAGE"});
           });
   
}*/
/*Commented by 9056 Payment Flow -------- END */
deleteItem(roleObj) {
 
}

addReminderDetails(){ 
     /*****setting user obj in the form****/
this.userObj = this.commonService.loggedInUserObj.userId;
this.myForm.get('t001UserCreatedBy.userId').setValue(this.userObj);
this.myForm.get('t001UserUpdatedBy.userId').setValue(this.userObj);

if(this.moduleName=== 'VIEW_EMPLOYEE')
{ 
  this.myForm.get('reminderModuleName').setValue('EMPLOYEE');
  this.myForm.get('t001UserReminderTo.userId').setValue(this.commonService.loggedInUserObj.employeeId);
  let navigateUrl = this.commonUtils.getAddLink(this.moduleName, this.myForm.get('employeeId').value);
  this.myForm.get('routeURL').setValue(navigateUrl);
}
else if(this.moduleName=== 'VIEW_PAYCODE')
{
  this.myForm.get('reminderModuleName').setValue('PAYCODE');
  let navigateUrl = this.commonUtils.getAddLink(this.moduleName, this.myForm.get('payCodeId').value);
	this.myForm.get('routeURL').setValue(navigateUrl);
}
else if(this.moduleName=== 'VIEW_INTERVIEW_SLOT'){
  this.myForm.get('reminderModuleName').setValue('INTERVIEW SLOT');
  let navigateUrl = this.commonUtils.getAddLink(this.moduleName, this.myForm.get('interviewSlotId').value);
  this.myForm.get('routeURL').setValue(navigateUrl);
}
else if(this.moduleName=== 'VIEW_APPOINTMENT'){
  this.myForm.get('reminderModuleName').setValue('APPOINTMENT');
 this.myForm.get('routeURL').setValue('/recruitment/reviewappointment/');
}
else
{
  let navigateUrl = this.commonUtils.getAddLink(this.moduleName, this.myForm.get('invoiceHeaderId').value);
  this.myForm.get('routeURL').setValue(navigateUrl);
}

this.myForm.get('reminderSentBy.userId').setValue( this.commonService.loggedInUserObj.employeeId);

this.myForm.get('t001UserCreatedBy.userName').setValue(this.commonService.loggedInUserObj.userName);
this.myForm.get('moduleName').setValue(this.moduleName);

    this.commonService.addReminderDetails(this.myForm.value).subscribe(
    data => {
      this.commonService.hideProgressBar();
      //this.commonService.showERPMessage(data.erpmessage);
      this.commonService.showERPMessage("Notification sent successfully");
      this.commonService.notificationChangedSubject.next("REFRESH_NOTIFICATION");
    },error => {
     this.commonService.hideProgressBar();
    });
}
   masterToggle() {
     /* this.isAllSelected() ?   this.selection.clear() : this.selectAll();*/


     this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row)) && this.selectAll();

       if (this.isAllSelected()) {
         /*for(var loop=0;loop< this.selection.selected.length; loop++)
         {
           this.contactsArray.push(this.selection.selected[loop]);
       }*/
         this.viewSearchresultEmitter.emit({action:"TRIGGER_TOGGLE_PANEL",data:false});
       /* this.showFirstPanel = false;
        this.showSecondPanel = true;*/
      } else {
        //this.contactsArray=[];
         this.viewSearchresultEmitter.emit({action:"TRIGGER_TOGGLE_PANEL",data:true});
       /* this.showFirstPanel = true;
        this.showSecondPanel = false;*/
      }
this.viewSearchresultEmitter.emit({action:"GRID_CHECKBOX_SELECTED",data:this.selection.selected});

//this.checkBoxSelectedEmitter.emit(this.selection);
this.viewSearchresultEmitter.emit({action:"CHECKBOX_SELECTED",data:this.selection});
     // this.togglePanel();
    }

    checkBoxAtLeastOneSelected(row) 
    {
              
       if (this.selection.isSelected(row) === false) 
          {
           this.selection.selected.push(row);
         
          }
           else if (this.selection.isSelected(row) === true) 
          {
            const index: number = this.selection.selected.indexOf(row);
            if (index !== -1) 
            {
            this.selection.selected.splice(index, 1);
            }
            
          } 
          const numSelected = this.selection.selected.length;
          if (this.selection.isSelected(row) && numSelected < 1) 
          {
             this.viewSearchresultEmitter.emit({action:"TRIGGER_TOGGLE_PANEL",data:true});
          } 
          else 
          {
             this.viewSearchresultEmitter.emit({action:"TRIGGER_TOGGLE_PANEL",data:false});
          }  
      this.viewSearchresultEmitter.emit({action:"GRID_CHECKBOX_SELECTED",data:this.selection.selected});

      //added by 9014
      //this.checkBoxSelectedEmitter.emit(this.selection);
      this.viewSearchresultEmitter.emit({action:"CHECKBOX_SELECTED",data:this.selection});
      return this.selection.toggle(row);
     
      
    }


    closeBtn() {
      this.viewSearchresultEmitter.emit({action:"TRIGGER_TOGGLE_PANEL",data:true});
      this.selection.clear();

    }
    selectAll(): void {
      //this.contactsArray = [];
      this.dataSource.rowsSubject._value.forEach(row => { this.selection.select(row);
                                                   //this.contactsArray.push(row);
                                          });
      this.viewSearchresultEmitter.emit({action:"GRID_CHECKBOX_SELECTED",data:this.selection.selected});
      this.viewSearchresultEmitter.emit({action:"CHECKBOX_SELECTED",data:this.selection});
    }

  calculateTotalApproveDays(selectedRow){
    var clApproved = selectedRow.clApproved;
    var slApproved = selectedRow.slApproved;
    var compOff = selectedRow.compOffApproved;
    var lop = selectedRow.lop;
    var totalApproved = parseFloat(clApproved)+parseFloat(slApproved)+parseFloat(compOff)+parseFloat(lop);
    selectedRow.approvedDays = totalApproved;
  }
  updateLeave(updateFlag,selectedRow){
    //this.commonService.showProgressBar();
   let leaveHistory_Obj = selectedRow;
            if(updateFlag === 'REJECTED')
            {
              leaveHistory_Obj.clApproved = 0;
              leaveHistory_Obj.slApproved = 0;
              leaveHistory_Obj.compOffApproved = 0;
              leaveHistory_Obj.lop = 0;
              leaveHistory_Obj.approvedDays = 0;
              leaveHistory_Obj.statusFlag = 'REJECTED';
            }
            else if(updateFlag === 'APPROVED')
            {
               this.totalApproved = parseFloat(leaveHistory_Obj.clApproved)+parseFloat(leaveHistory_Obj.slApproved)+
                                  parseFloat(leaveHistory_Obj.compOffApproved)+parseFloat(leaveHistory_Obj.lop);             
              leaveHistory_Obj.approvedDays = this.totalApproved;
              leaveHistory_Obj.statusFlag = 'APPROVED';
            }
           this.hrmService.updateStatusForLeaveHistory(leaveHistory_Obj)
              .subscribe(
                data => {  
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage(data.erpmessage,null);
                  this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);
                });

  
  
    /*let leaveHistoryObj = selectedRow;
    let validationFlag = false;
    if(updateFlag === 'Approve'){
      var clApproved = leaveHistoryObj.clApproved;
      var slApproved = leaveHistoryObj.slApproved;
      var compOff = leaveHistoryObj.compOffApproved;
      var lop = leaveHistoryObj.lop;
      var totalApproved = parseFloat(clApproved)+parseFloat(slApproved)+parseFloat(compOff)+parseFloat(lop);
      leaveHistoryObj.approvedDays = totalApproved;
      if(parseFloat(leaveHistoryObj.totalAppliedDays) != parseFloat(leaveHistoryObj.approvedDays)){
        validationFlag = true;
      }
      else
        leaveHistoryObj.statusFlag = 'APPROVED';
    }
    else{
      leaveHistoryObj.statusFlag = 'REJECTED';
    }
   
      if(validationFlag == true){
        this.commonService.showERPMessage("Total Approved days is not equal to the Total Applied days");
      }
      else{
        
          this.hrmService.updateAppliedLeaves(leaveHistoryObj)
              .subscribe(
                data => {  
                  this.commonService.hideProgressBar();
                  this.commonService.showERPMessage(data.erpmessage,null);
                  this.viewPageRefresh.emit({action:"REFRESH_PAGE"});
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);
                });
                
      }*/
   
  }
  /*View Production Slip ---- Sales Order -------- Starts ------- 9056*/

 saveOrUpdateProjectPlan(selectedRow){
    let taskList =[];
    taskList.push(selectedRow);
  
   
    this.hrmService.saveOrUpdateProjectPlan(taskList).
    subscribe(data=>
      {
        this.commonService.showERPMessage("Project Plan saved");
        this.refreshIconClick(false);
      },
      error=>
      {
      
        
        this.commonService.showERPErrorMessage("Error saving project plan "+error);
      });
  }

/*Refresh whole grid ------- START --------- 9056*/
refreshIconClick(resetFilterflag?:boolean){
  this.viewSearchresultEmitter.emit({action:"REFRESH_GRID",resetFilter:resetFilterflag});
  this.commonService.refreshClickedSubject.next({action:"REFRESH_GRID",resetFilter:resetFilterflag});

  // if(this.moduleName == "PROJECT_PLAN")
  //    this.viewSearchresultEmitter.emit({action:"CLEAR_BREADCRUMB",data:null}); commented on 18, Nov 2022 - 9168
}
/*Refresh whole grid ------- END --------- 9056*/
directToReviewPackage()
{
  
  if(this.moduleName=='VIEW_SALESORDER' || this.moduleName == 'VIEW_SHIPPING')
  {
   this.accessObj=this.commonService.getAccessObjForCurrentScreen('VIEW_PACKAGES')
   if(this.accessObj.viewAccess==true)
   this.commonService.showERPMessage('Redirecting to review package','VIEW_PACKAGES') 
  }

  if(this.moduleName=='VIEW_APPROVE_STOCK_REQUEST' || this.moduleName=='VIEW_STOCK_REQUEST' || this.moduleName=='VIEW_RETURNS' || this.moduleName=='VIEW_DELIVERY_CHALLAN_RETURN' ||this.moduleName=='VIEW_RECEIVE_RETURNS')
  {
   this.accessObj=this.commonService.getAccessObjForCurrentScreen('VIEW_DISPATCH_PACKAGE')
   if(this.accessObj.viewAccess==true)
   this.commonService.showERPMessage('Redirecting to review package','VIEW_DISPATCH_PACKAGE') 
  }
  
}
directToReviewInvoice()
{
  
  if(this.moduleName=='VIEW_SALESORDER')
  {
   this.accessObj=this.commonService.getAccessObjForCurrentScreen('NEW_INVOICE')
   if(this.accessObj.viewAccess==true)
   this.commonService.showERPMessage('Redirecting to review invoice','NEW_INVOICE') 
  }
}

openChildTask(element){
  this.commonService.erpSubject.next({action:"BREADCRUMB_RESULT",data:element});
    this.viewSearchresultEmitter.emit({action:"GET_TASK_NAMES",data:element});
}

notesFlagStatus(event)
{
  if(event.checked === true)
  {
    this.notesFlag=true;
  }
  else if(event.checked === false)
  {
    this.notesFlag=false;
  }
}


isOpen:boolean=false;
reviewDisplayColRef: ErpOverlayRef ;
  
openIcons(){
   this.reviewDisplayColRef= this.erpCustomDialog.open(this.elementRef._elementRef,{
      data: {displayedColumns:this.displayedColumns,displayedColumnsOriginal:this.displayedColumnsOriginal}
  },IconsComponent);

 
this.reviewDisplayColRef.afterClosed.subscribe( result =>{
   if(result==='REFRESH')
     this.refreshIconClick(true);
   if(result==='SEARCH')
     this.searchIconClick();
   if(result==='ANALYTICALCHART')
     this.openAnalyticalChart();
  });
}
closeIcons(){
   
  
  this.reviewDisplayColRef.dialogClose('');
}
closeReviewDisplaycolumns(element){

  
this.reviewDisplayColRef.close();
}

databaseDetailsForm; 

redirectToVendor(element){
 
  if (this.moduleName === 'TS_VIEW_PO' || this.moduleName === 'TS_VIEW_BILL'
 || this.moduleName === 'TS_VIEW_R_BILL' || this.moduleName === 'TS_VIEW_R_EXPENSE' || this.moduleName === 'TS_VIEW_PAYMENT_MADE'
 || this.moduleName === 'TS_VIEW_VENDOR_CREDIT')
 {
   this.router.navigate(['contacts/createcontacts/'+  btoa(element.contactId)])
 }
 else if(this.commonUtils.checkNullOrUndefined(element.poVendorId)  && this.moduleName === 'VIEW_PO_RECEIVE')
 {
   let contactId=element.poVendorId;

   this.router.navigate(['contacts/createcontacts/'+btoa(contactId)])
 }
 else if(this.commonUtils.checkNullOrUndefined(element.vendorId)  && this.moduleName === 'TS_VIEW_EXPENSE')
 {
   let contactId=element.vendorId;

   this.router.navigate(['contacts/createcontacts/'+btoa(contactId)])
 }
 else if(this.commonUtils.checkNullOrUndefined(element.t100Contact) && this.commonUtils.checkNullOrUndefined(element.t100Contact.contactId) 
   && this.moduleName === 'VIEW_PAYMENT_MADE' ){
    this.router.navigate(['contacts/createcontacts/'+btoa(element.t100Contact.contactId)])
 }
  else
  {
  this.router.navigate(['contacts/createcontacts/'+btoa(element.t100ContactVendorId.contactId)])
 }

  if(this.searchString === 'topSearch' && (this.moduleName === 'TS_VIEW_PO' || this.moduleName === 'TS_VIEW_BILL'
 || this.moduleName === 'TS_VIEW_R_BILL' || this.moduleName === 'TS_VIEW_R_EXPENSE' || this.moduleName === 'TS_VIEW_PAYMENT_MADE'
 || this.moduleName === 'TS_VIEW_VENDOR_CREDIT' || this.moduleName === 'TS_VIEW_EXPENSE'))
          {
            
              this.viewSearchresultEmitter.emit({action:"CLOSE_TOP_SEARCH"});
          }
}

redirectToCustomer(element){
  if (this.moduleName === 'TS_VIEW_SALESORDER' || this.moduleName === 'TS_VIEW_ESTIMATE' || this.moduleName === 'TS_VIEW_ARTWORK'
 || this.moduleName === 'TS_VIEW_PACKAGES' || this.moduleName === 'TS_VIEW_INVOICE' || this.moduleName === 'TS_VIEW_R_INVOICE'
 || this.moduleName === 'TS_VIEW_PAYMENT_RECIEVED' || this.moduleName === 'TS_VIEW_CREDIT_NOTES' )
  {
    this.router.navigate(['contacts/createcontacts/'+btoa(element.contactId)])
  }
  else if(this.commonUtils.checkNullOrUndefined(element.customerId) &&  (this.moduleName === 'TS_VIEW_EXPENSE' || 
    this.moduleName === 'VIEW_PAYMENT_RECIEVED' || this.moduleName === 'VIEW_PACKAGES'))
 {
   let contactId=element.customerId;
   this.router.navigate(['contacts/createcontacts/'+btoa(contactId)])
 }
  else
  {
  this.router.navigate(['contacts/createcontacts/'+btoa(element.t100ContactCustomerId.contactId)])
}

 if(this.searchString === 'topSearch' && (this.moduleName === 'TS_VIEW_SALESORDER' || this.moduleName === 'TS_VIEW_ESTIMATE' || this.moduleName === 'TS_VIEW_ARTWORK'
 || this.moduleName === 'TS_VIEW_PACKAGES' || this.moduleName === 'TS_VIEW_INVOICE' || this.moduleName === 'TS_VIEW_R_INVOICE'
 || this.moduleName === 'TS_VIEW_PAYMENT_RECIEVED' || this.moduleName === 'TS_VIEW_CREDIT_NOTES' ||  this.moduleName === 'TS_VIEW_EXPENSE'))
          {
            
              this.viewSearchresultEmitter.emit({action:"CLOSE_TOP_SEARCH"});
          }
 
}

deleteRow(element){
  
   if(this.moduleName === 'VIEW_DATABASE'){
      let myDatabaseScriptForm :FormGroup = new FormGroup({databaseId: new FormControl(element.databaseId)});
      this.commonService.showProgressBar();
      this.commonService.deleteDatabaseScript(myDatabaseScriptForm.value).subscribe(
      data => {
                this.commonService.hideProgressBar();
                this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                this.commonService.showERPMessage(data.erpmessage);
            },
      error => {
                this.commonService.hideProgressBar();
                this.commonService.showERPErrorMessage(error);
      });
   }
   else if(this.moduleName === 'VIEW_AUTOMATED_EMAIL'){
    let myautoEmailForm :FormGroup = new FormGroup({automatedEmailId: new FormControl(element.automatedEmailId)});

 
      this.commonService.showProgressBar();
    this.commonService.deleteAutomatedEmail(myautoEmailForm.value).subscribe(
      data => {
                this.commonService.hideProgressBar();
                this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
                this.commonService.showERPMessage(data.erpMessage);
            },
      error => {
                this.commonService.hideProgressBar();
                this.commonService.showERPErrorMessage(error);
      });
  }
}  

  saveOrUpdateMedicallBill(status,element) {
    this.myMedicalForm.get('medicalBillId').setValue(element.medicalBillId);
     this.myMedicalForm.get('billNumber').setValue(element.billNumber);
    this.myMedicalForm.get('status').setValue(status);
    this.hrmService.saveOrUpdateMedicallBill(this.myMedicalForm.value).subscribe(
      data => {
        this.commonService.hideProgressBar();
        this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
        this.commonService.showERPMessage(data.erpmessage);

      },error => {
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
      });
  }
  openAnalyticalChart(){
   const dialogRef = this.dialog.open(ViewTemplateWrapperAnalyticsComponent, {
      width: '85%',height:'auto',
      data: {moduleName: this.moduleName}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
}
viewHistory(selectedRow){
  selectedRow.moduleName = this.moduleName;

    const dialogRef = this.dialog.open(ViewHistoryComponent, {
          width: '60%',
          height:'50%',
          data:{selectedRow:selectedRow,moduleName: this.moduleName}
        });

        dialogRef.afterClosed().subscribe(result => {
        });

  }

  quickBookSync(invoiceObj)
  {
    let moduleNameForSave = '';
     this.portalName=this.commonService.loggedInUserObj.t000Organization.portalName;
       this.myBatchQbForm.get('invoiceHeaderId').setValue(invoiceObj.invoiceHeaderId);
       this.myBatchQbForm.get('totalFinalAmount').setValue(invoiceObj.finalTotal);
       if(this.moduleName === 'VIEW_SALESORDER')
         moduleNameForSave = 'SalesOrder';
       if(this.moduleName === 'VIEW_BILL')
         moduleNameForSave = 'Bill';
       if(this.moduleName === 'VIEW_PAYMENT_RECIEVED'){
         moduleNameForSave = 'PaymentReceived';
         this.myBatchQbForm.get('invoiceHeaderId').setValue(invoiceObj.paymentReceivedHeaderId);
       }
       if(this.moduleName === 'VIEW_CREDIT_NOTES')
         moduleNameForSave = 'CreditNote';
       if(this.moduleName === 'VIEW_ESTIMATE')
         moduleNameForSave = 'Estimate';
       if(this.moduleName === 'VIEW_INVOICE')
         moduleNameForSave = 'Invoice';
       if(this.moduleName === 'VIEW_EXPENSE'){
         moduleNameForSave = 'Expense';
         this.myBatchQbForm.get('invoiceHeaderId').setValue(invoiceObj.expenseHeaderId);
       }
       if(this.moduleName === 'VIEW_PAYMENT_MADE')
         moduleNameForSave = 'PaymentMade';
       if(this.moduleName === 'VIEW_VENDOR_CREDIT')
         moduleNameForSave = 'VendorCredit';
       if(this.moduleName === 'VIEW_PO')
         moduleNameForSave = 'PurchaseOrder';
       if(this.moduleName === 'VIEW_ITEM'){
         moduleNameForSave = 'Item';
         this.myBatchQbForm.get('invoiceHeaderId').setValue(invoiceObj.itemId);
       }

     this.myBatchQbForm.get('moduleName').setValue(moduleNameForSave);
     this.commonService.saveOrUpdateBatchSyncLogForQB( this.myBatchQbForm.value).subscribe(
      data => {
        this.commonService.showERPMessage(data.erpmessage);
      },
      error=>
      {
        this.commonService.showERPErrorMessage("QuickBooks Sync failed to start");
      });
  }
saveOrUpdateValidation()
{
 
   const dialogRef = this.dialog.open(CreateValidationComponent,
   {
    width: '50%',
    data: { moduleName: this.moduleName}
  });
  dialogRef.afterClosed().subscribe(result => {
   
   if(result)
            this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
    }); 
  
}
  quickBookPaymentSync(invoiceObj){
    let moduleNameForSave = '';
     this.portalName=this.commonService.loggedInUserObj.t000Organization.portalName;
       this.myBatchQbForm.get('invoiceHeaderId').setValue(invoiceObj.invoiceHeaderId);
      
       
         moduleNameForSave = 'INV_PYMT_QB_TO_ERP';

     this.myBatchQbForm.get('moduleName').setValue(moduleNameForSave);
     this.commonService.saveOrUpdateBatchSyncLogForQB( this.myBatchQbForm.value).subscribe(
      data => {
        this.commonService.showERPMessage("QuickBooks Payment Sync process started");
      },
      error=>
      {
      });
  }

deleteEmployeeAppraisalLevel(element)
{
   this.commonService.showProgressBar();
   this.hrmService.deleteEmployeeAppraisalLevel(element).subscribe(
   data=>
   {
     this.commonService.showERPMessage('Employee Appraisal has been deleted');
     this.commonService.hideProgressBar();
     this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
   },
   error=>
   {
     this.commonService.hideProgressBar();
     this.commonService.showERPErrorMessage(error);
   })

}

  revokeAppliedLeaveStatus(element){
    this.viewSearchresultEmitter.emit({action:"UPDATE_REVOKE_APPLIED_LEAVE_STATUS",data:element});
  }

  downloadAttachments(elementObj){
    //condition added by 9121 for download attachment in view timesheet screen
    if (this.moduleName == 'VIEW_TIMESHEET'){
      let taskNumber = elementObj.taskNumber;
      elementObj = elementObj.t823TaskObj;
      elementObj.taskNumber = taskNumber;
    }
    elementObj.moduleName = 'CREATE_TASK';
    this.hrmService.downloadAttachments(elementObj).subscribe(
        data => {
            if(data != "" && data != null){
              if(data != "No attachments found"){
                 window.open(this.SERVICE_URL + "erpassets/" + data, '_blank');
              }else{
                this.commonService.showERPMessage('No files found');
              }
            }
        },
        error => {
          console.log(error)
        }
      );
  }

  viewEmpHierarchy(elementObj,elementRef){
    let empName = "";
    if(this.commonUtils.checkNullOrUndefined(elementObj.firstName)){
      empName = elementObj.firstName;
      if(this.commonUtils.checkNullOrUndefined(elementObj.lastName)){
       empName = elementObj.firstName +" "+ elementObj.lastName;
      }
    } 
    let infoDialogRef;
    infoDialogRef= this.erpCustomDialog.open(elementRef._elementRef,{
      data: {moduleName: "VIEW_EMPLOYEE_HIERARCHY" , employeeId: elementObj.employeeId, empName: empName}
    },ViewQuickInfoComponent);
    infoDialogRef.afterClosed.subscribe( result => { 
    });
  }
  viewTaskHierarchy(elementObj, elementRef) {
    let taskName = "";
    if (this.commonUtils.checkNullOrUndefined(elementObj.taskName)) {
      taskName = elementObj.taskName;      
    }
    let infoDialogRef;
    infoDialogRef = this.erpCustomDialog.open(elementRef._elementRef, {
      data: { moduleName: "VIEW_TASK_HIERARCHY", taskId: elementObj.taskId, taskName: taskName }
    }, ViewQuickInfoComponent);
    infoDialogRef.afterClosed.subscribe(result => {
    });
  }
  openActualHours(rowObj){ 

     const dialogRef = this.dialog.open(MultiUserWorkedHoursComponent,
    {
      width: '50%',
      height:'75%',
      data: {taskId: rowObj.taskId, moduleName: 'TASK_WORKED_HRS_AT_USER_LEVEL'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
        console.log(result);
    });
  }
  
  openInterview(element){
    const dialogRef = this.dialog.open(InterviewRoundComponent,
      {
        
        width: '75%',
        height:'80%',
        data: element
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result)
          console.log(result);
      });
    
  }

  openCandidateResumePreview(element){ 
    let myCandidateForm  = new FormGroup({
      candidateId: new FormControl(element.candidateId),
      lazyLoad: new FormControl('N')
    });
    this.recruitService.fetchCandidate(myCandidateForm.value).subscribe(
      data=> {
        let pdfSource;
          if (this.commonUtils.checkNullOrUndefined(data[0].resumeFileName)){
            pdfSource = this.commonService.SERVICE_URL+"erpassets/"
            + this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase()
             +"/Candidate/"+element.candidateId+"/RESUME/"+data[0].resumeFileName;
            window.open(pdfSource, '_blank');
          } else {
            this.commonService.showERPMessage("Resume not Uploaded");
          }
          
          // const dialogRef = this.dialog.open(ViewHistoryComponent, {
          //   width: '95%',
          //   height:'95%',
          //   data:{
          //       moduleName: 'CANDIDATE_RESUME_PREVIEW',
          //       labelName: 'View Candidate Resume',
          //       pdfSource: pdfSource
          //   }
          // });
          // dialogRef.afterClosed().subscribe(result => {
          // });
      },
      error =>{

      }
      );
   
  
  }

  openStatusUpdate(element){
    const dialogRef = this.dialog.open(InterviewAllotmentComponent,
      {
        
        width: '75%',
        height:'50%',
        data:element
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result)
          this.refreshIconClick(true);
      });

  }
  slotStatusClick(element, moduleName){
  let slotForm = new FormGroup({
     appointmentId: new FormControl(element.appointmentId),
     currentInterviewerId: new FormControl(this.commonService.loggedInUserObj.employeeId),
     candidateId: new FormControl(element.candidateobj.candidateId),
     bulkAction: new FormControl(element.interviewSlotStatus.lookupValue),
     t004_CandidateStatusObj: new FormGroup({
       lookupValue: new FormControl(element.interviewSlotStatus.lookupValue),
     }),
     interviewStatus: new FormGroup({
        lookupId: new FormControl(0),
        lookupCode: new FormControl('VIEW_APPOINTMENT'),
        lookupValue: new FormControl(moduleName)
      }),
     email:new FormControl(element.candidateobj.email),
     t843_InterviewAppointmentObj:new FormGroup({
        appointmentId: new FormControl(element.appointmentId),
       interviewerEmail:new FormControl(element.interviewerEmail),
       endTime:new FormControl(element.endTime),
       interviewDate:new FormControl(element.interviewDate),
       startTime:new FormControl(element.startTime),
       extraInterviewData:new FormControl(element.extraInterviewData),
       interviewType: new FormGroup({
        lookupId: new FormControl(element.interviewType.lookupId),
        lookupCode: new FormControl('INTERVIEW_TYPE'),
        lookupValue: new FormControl(element.interviewType.lookupValue)
      }),
     }),
      editedBy:new FormControl(this.commonService.loggedInUserObj.empName),
     dateFormat:new FormControl(this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue),
    
    });
  
  if(this.commonUtils.checkNullOrUndefined(element.interviewerStatusVal) && element.interviewerStatusVal.indexOf("::"+this.commonService.loggedInUserObj.employeeId) >= 0 ){
    if(element.interviewerStatusVal.indexOf("ACTIVE::"+this.commonService.loggedInUserObj.employeeId)>=0 && element.interviewerStatusVal.indexOf("::"+this.commonService.loggedInUserObj.employeeId) >= 0){
       this.recruitService.saveorUpdateSlotStatus(slotForm.value).subscribe(
      data=>{
        this.commonService.showERPMessage(data.erpmessage);
              this.refreshIconClick(true);
        if(this.commonUtils.checkNullOrUndefined(data.action) && data.action === 'APPOINTMENT_FULLY_ACCEPTED') {
          let interviewUpdateStatusList = []; 
          interviewUpdateStatusList.push(slotForm.value);
          this.recruitService.bulkUpdateActionForCandidateStatus(interviewUpdateStatusList).subscribe(
           data => 
           { },error =>{});
        }     
        

      }, error => {
        this.commonService.hideProgressBar();
        this.commonService.showERPErrorMessage(error);  

      });
        this.myForm.get('appointmentId').setValue(element.appointmentId);
        this.myForm.get('notesDescription').setValue('Accepted the interview appointment # ('+element.appointmentNumber+') By '+ this.commonService.loggedInUserObj.empName);
        this.myForm.get('t001UserReminderTo.userId').setValue(element.hremployeeId);
        this.myForm.get('reminderDate').setValue(new Date());
        this.addReminderDetails();  
    }else{
       this.commonService.showERPMessage('Already this appointment # ( '+element.appointmentNumber+' ) is Accepted');
    }
   
  }else{
    this.commonService.showERPMessage("The selected appointment is not alloted for this member "+this.commonService.loggedInUserObj.empName);
  }
     

  }

  addReschedule(selectedRow:any,elementRef:any){
    let reminderDialogRef;
    let myslotForm = new FormGroup({
      appointmentId: new FormControl(selectedRow.appointmentId),
      currentInterviewerId: new FormControl(this.commonService.loggedInUserObj.employeeId),
      rescheduleDate: new FormControl(new Date()),
      rescheduleReason: new FormControl(''),
      rescheduledBy: new FormControl(),
      interviewDate:new FormControl(new Date(selectedRow.interviewDate)),
      rescheduleTime:new FormControl(),
      interviewStatus: new FormGroup({
        lookupId: new FormControl(0),
        lookupCode: new FormControl('VIEW_APPOINTMENT'),
        lookupValue: new FormControl('')
      }),
       candidateId: new FormControl(selectedRow.candidateobj.candidateId),
     bulkAction: new FormControl(selectedRow.interviewSlotStatus.lookupValue),
     t004_CandidateStatusObj: new FormGroup({
       lookupValue: new FormControl(selectedRow.interviewSlotStatus.lookupValue),
     }),
     email:new FormControl(selectedRow.candidateobj.email),
     interviewerName: new FormControl(selectedRow.interviewerName),
     interviewerId : new FormControl(selectedRow.interviewerId),
     statusFlag:new FormControl('BatchEmail'), 
        interviewerEmail: new FormControl(selectedRow.interviewerEmail),
     t843_InterviewAppointmentObj:new FormGroup({
        appointmentId: new FormControl(selectedRow.appointmentId),
       interviewerEmail:new FormControl(selectedRow.interviewerEmail),
       endTime:new FormControl(selectedRow.endTime),
       interviewDate:new FormControl(selectedRow.interviewDate),
       startTime:new FormControl(selectedRow.startTime),
       extraInterviewData:new FormControl(selectedRow.extraInterviewData),
       interviewType: new FormGroup({
        lookupId: new FormControl(selectedRow.interviewType.lookupId),
        lookupCode: new FormControl('INTERVIEW_TYPE'),
        lookupValue: new FormControl(selectedRow.interviewType.lookupValue)
      }),
     }),
     editedBy:new FormControl(this.commonService.loggedInUserObj.empName),
      dateFormat:new FormControl(this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue),
    
    })
    
     if(this.commonUtils.checkNullOrUndefined(selectedRow.interviewerStatusVal) &&  selectedRow.interviewerStatusVal.indexOf("::"+this.commonService.loggedInUserObj.employeeId) >= 0){
    if(selectedRow.interviewerStatusVal.indexOf("ACTIVE::"+this.commonService.loggedInUserObj.employeeId)>=0 && selectedRow.interviewerStatusVal.indexOf("::"+this.commonService.loggedInUserObj.employeeId) >= 0){
         reminderDialogRef= this.erpCustomDialog.open(elementRef._elementRef,{
      data: myslotForm.value
    },RescheduleComponent);
    reminderDialogRef.afterClosed.subscribe( result => {
      this.commonService.showProgressBar();
        if (this.commonUtils.checkNullOrUndefined(result)){
          myslotForm.get('appointmentId').setValue(result.appointmentId);
          myslotForm.get('rescheduleDate').setValue(new Date(result.rescheduleDate));
          //  myslotForm.get('interviewDate').setValue(new Date(result.rescheduleDate));
          myslotForm.get('rescheduleReason').setValue(result.reason);
          myslotForm.get('rescheduleTime').setValue(result.rescheduleTime);
          myslotForm.get('rescheduledBy').setValue(this.commonService.loggedInUserObj.empName);
          myslotForm.get('interviewStatus.lookupValue').setValue('RESCHEDULED');
         myslotForm.get('editedBy').setValue(this.commonService.loggedInUserObj.empName);
         
         let interviewerList=[];
          let interviewUpdateStatusList = []; 
          interviewUpdateStatusList.push(myslotForm.value);
          this.recruitService.saveorUpdateSlotReschedule(myslotForm.value).subscribe(
            data=>{   
              var interviewerIds =data.interviewerId;
              interviewerList= interviewerIds.split(',');
              this.myForm.get('appointmentId').setValue(selectedRow.appointmentId);
              this.myForm.get('notesDescription').setValue('The interview date is rescheduled ('+this.datePipe.transform(result.rescheduleDate, 'MM-dd-yyyy') +') of Appointment # ('+selectedRow.appointmentNumber+')');
              this.myForm.get('t001UserReminderTo.userId').setValue(selectedRow.hremployeeId);
              this.myForm.get('reminderDate').setValue(new Date());
                for (var loop =0; loop < interviewerList.length; loop++){
                  if (interviewerList[loop] != this.commonService.loggedInUserObj.employeeId){
                    this.myForm.get('t001UserReminderTo.userId').setValue(interviewerList[loop]);
                    this.addReminderDetails();
                  }
                }
                this.commonService.showERPMessage(data.erpmessage);
                this.refreshIconClick(true);
                if(this.commonUtils.checkNullOrUndefined(data.action) && data.action === 'APPOINTMENT_FULLY_ACCEPTED') {
                    let interviewUpdateStatusList = []; 
                    interviewUpdateStatusList.push(myslotForm.value);
                    this.recruitService.bulkUpdateActionForCandidateStatus(interviewUpdateStatusList).subscribe(
                     data => 
                     { },error =>{});
                  }   
            }, error => {
              this.commonService.hideProgressBar();
              this.commonService.showERPErrorMessage(error);          
            }); 
          this.myForm.get('appointmentId').setValue(selectedRow.appointmentId);
          this.myForm.get('notesDescription').setValue('The interview date is rescheduled ('+this.datePipe.transform(result.rescheduleDate, 'MM-dd-yyyy') +') of Appointment # ('+selectedRow.appointmentNumber+')');
          this.myForm.get('t001UserReminderTo.userId').setValue(selectedRow.hremployeeId);
          this.myForm.get('reminderDate').setValue(new Date());
          this.addReminderDetails();

          /*code for Rescheduling Appointment time gap of 2Hrs... Uncomment the code when the functionality needed
          this.getCurrentTime(result.rescheduleTime);
          if (result.rescheduleDate.getDate() == new Date().getDate()){
            if (this.rescheduleFlag){
              this.recruitService.saveorUpdateSlotReschedule(myslotForm.value).subscribe(
                data=>{   
                  var interviewerIds =data.interviewerName;
                  interviewerList= interviewerIds.split(',');
                  this.myForm.get('appointmentId').setValue(selectedRow.appointmentId);
                  this.myForm.get('notesDescription').setValue('The interview date is rescheduled ('+this.datePipe.transform(result.rescheduleDate, 'MM-dd-yyyy') +') of Appointment # ('+selectedRow.appointmentNumber+')');
                  this.myForm.get('t001UserReminderTo.userId').setValue(selectedRow.hremployeeId);
                  this.myForm.get('reminderDate').setValue(new Date());
                    for (var loop =0; loop < interviewerList.length; loop++){
                      if (interviewerList[loop] != this.commonService.loggedInUserObj.employeeId){
                        this.myForm.get('t001UserReminderTo.userId').setValue(interviewerList[loop]);
                        this.addReminderDetails();
                      }
                    }
                    this.commonService.showERPMessage(data.erpmessage);
                    this.refreshIconClick(true);
                    if(this.commonUtils.checkNullOrUndefined(data.action) && data.action === 'APPOINTMENT_FULLY_ACCEPTED') {
                        let interviewUpdateStatusList = []; 
                        interviewUpdateStatusList.push(myslotForm.value);
                        this.recruitService.bulkUpdateActionForCandidateStatus(interviewUpdateStatusList).subscribe(
                         data => 
                         { },error =>{});
                      }   
                }, error => {
                  this.commonService.hideProgressBar();
                  this.commonService.showERPErrorMessage(error);          
                }); 
              this.myForm.get('appointmentId').setValue(selectedRow.appointmentId);
              this.myForm.get('notesDescription').setValue('The interview date is rescheduled ('+this.datePipe.transform(result.rescheduleDate, 'MM-dd-yyyy') +') of Appointment # ('+selectedRow.appointmentNumber+')');
              this.myForm.get('t001UserReminderTo.userId').setValue(selectedRow.hremployeeId);
              this.myForm.get('reminderDate').setValue(new Date());
              this.addReminderDetails();
            } else {
              this.commonService.showERPMessage("The Time gap for Rescheduling Appointment will be 2 Hrs.");
            }
          } else {
            //save function should come here
          }*/
          
          
        }
       
    });
    }else{
      this.commonService.showERPMessage('Already this appointment # ( '+selectedRow.appointmentNumber+' ) is Reschedule');
    }
       
   }else{
    this.commonService.showERPMessage("The selected appointment is not alloted for this member "+this.commonService.loggedInUserObj.empName);
  }

    
    
  }
  convertToEmployee(elementObj){
     if(this.moduleName === 'VIEW_CANDIDATE'){
    this.viewSearchresultEmitter.emit({action:"PRINT_DIALOG_OPEN",data:'EMPLOYEE',selectedObjectFromGrid:elementObj});
  }
  }
  redirectToJob(element){
    let jobObj;
    if (this.moduleName == 'VIEW_CANDIDATE'){
      this.editId = element.jobApplication.jobId;
      jobObj = element.jobApplication;
    } else if (this.moduleName == 'VIEW_APPOINTMENT'){
      this.editId = element.t842_JobObj.jobId;
      jobObj = element.t842_JobObj;
    }
      const dialogRef = this.dialog.open(CreateJobComponent,{width: '60%',
            data: {jobObj: jobObj}
          });
          dialogRef.afterClosed().subscribe(result => {
           if(result)
              this.viewSearchresultEmitter.emit({action:"REFRESH_PAGE"});
          });
    
  }
  redirectToCandidate(element){
    let module = 'VIEW_CANDIDATE';
    if (this.moduleName == 'VIEW_APPOINTMENT'){
      this.editId = element.candidateobj.candidateId;
    } 
      this.router.navigate([this.commonUtils.getAddLink(module, btoa(this.editId))]);
      this.commonService.addMenuList(this.commonUtils.getAddLink(module, btoa(this.editId)));
  }
  getCurrentTime(reschedule){
    let rescheduleHour = parseInt(reschedule.split(":")[0]);
    let rescheduleMin = parseInt(reschedule.split(":")[1]);
    let currentHour = new Date().getHours()+2;
    let currentMin = new Date().getMinutes();
    let currentTime = (currentHour *60 ) + currentMin;
    let rescheduleTime = (rescheduleHour *60 ) + rescheduleMin;
    if (rescheduleTime >= currentTime){
      this.rescheduleFlag = true;
    }
  }
  assetTransaction(element, val) {
    this.editId = element.assetHeaderId;
    const dialogRef = this.dialog.open(AssetTransactionComponent, {
      width: '80%',
      height: '80%',
      data: { editId: this.editId, transaction: val, refreshGrid: 'Y', assetData: element }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.viewSearchresultEmitter.emit({ action: "REFRESH_PAGE" });
    });
  }
}