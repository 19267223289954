<mat-card class="col-lg-12">
<mat-card-title >Sign NDA</mat-card-title>
<mat-card-content> 
  <div class="col-lg-12" >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="border-radius: 5px;border: 1px solid gray;" >
        <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad> 
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 module-center-align marginTop12" >
         <button mat-raised-button color="primary" class="mat-red" (click)="saveSignature()">{{SAVE_BUTTON}}</button>
         <button mat-raised-button type="button" color="primary" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
      </div>
  </div> 
</mat-card-content>
</mat-card>