<!--   <script src="https://apis.google.com/js/platform.js" async defer></script> -->
 <!--<meta name="google-signin-client_id" content="733594033776-rg97b4q3i9le9t4dmcuj9ji8n4hbhbt5.apps.googleusercontent.com"> -->
  <!-- KeyboardEvent
    <ul>
      <li>altKey: {{altKey}}</li>
      <li>charCode: {{charCode}}</li>
      <li>code: {{code}}</li>
      <li>ctrlKey: {{ctrlKey}}</li>
      <li>keyCode: {{keyCode}}</li>
      <li>keyIdentifier: {{keyIdentifier}}</li>
      <li>metaKey: {{metaKey}}</li>
      <li>shiftKey: {{shiftKey}}</li>
      <li>timeStamp: {{timeStamp}}</li>
      <li>type: {{type}}</li>
      <li>which: {{which}}</li>
    </ul>`  -->
<div class="col-lg-12" ></div>
  <div class="col-lg-12 loginbox">
  <div class="col-lg-4"></div>
   <div class="col-lg-4">
 <mat-card class="col-lg-12">
  <mat-card-title >
      Portal Sign In</mat-card-title>
   
   <mat-card-content >
  <form (ngSubmit)="checkForLoginUser()"   [formGroup]="myForm">
  <div >
  <mat-form-field class="col-lg-12">
    <input matInput placeholder="{{USERNAME_PLACEHOLDER}}" id="username"  formControlName="userName" 
    focus [focusEvent]="myFocusTriggeringEventEmitter"
   >
    <mat-hint  *ngIf="(availableuserList | async)?.length>0"   class="discountDivColor">  Username doesnt exists</mat-hint>
  </mat-form-field>
  </div> 
   <div >
    <mat-form-field    class="col-lg-12">
      <input matInput  type="password" placeholder="{{PASSWORD_PLACEHOLDER}}" id="password" formControlName="password"
      focus [focusEvent]="myFocusTriggeringEventEmitter">
    </mat-form-field>
  </div>
<!--  <div class="col-lg-4">
  <mat-checkbox color="primary">Remember Me!</mat-checkbox>
</div>
<div class="col-lg-8" style="text-align: right;color: #666;cursor: pointer;font-size: 13px;text-align: right;margin-top: 3px;">
  <a>Forgot Password?</a>
</div> -->
 <br>
   <div layout="row" class="buttonalgincenter">
    <button mat-raised-button color="primary" style="width: 100%;margin-top:2%;" type="submit" >Sign In</button>
  <!--  <button (click)="socialSignIn('facebook')">Sign in with Facebook</button> -->
  </div>
  <div class="dropdown-divider" style="margin-top: 3%;margin-bottom: 3%;"></div>
  <div align="center" style="margin-bottom: 3%;">OR</div>
  <div class="g-signin2" style="margin-bottom: 3%;"  >
<button matTooltip="Available in Next version" mat-raised-button  disabled  color="primary" style="width: 100%;" (click)="socialSignInGoogle('google')">Sign In Using Google</button>              
</div>  
</form>
</mat-card-content>
</mat-card>
</div>
</div>
