<!-- <div style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);"> -->
   
    <div>
    <div *ngIf= "this.getIconName ==='account_circle'" style="width:100%;background-color: white;float: left!important;padding-left:8px;
   box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);font-size: 13px; line-height: 0.75rem">
           <div class="row">
             <div class="row" *ngIf="displaySettings">
                    <div class="col-lg-12">
                     <button  mat-button type="button"      aria-label="Clear" matTooltip="View Organization Profile " 
                         [routerLink]="['/settings/orgProfile']"  (click)="closeDialog()"><mat-icon mat-icon-button mat-button  >home</mat-icon> Organization Profile</button>
                   </div>
             </div>
            <div class="row" *ngIf="displaySettings">
                  <div class="col-lg-12">
                    <button  mat-button type="button"      aria-label="Clear"  (click)="callChangePassword()" matTooltip="Change Password" 
                           ><mat-icon mat-icon-button mat-button >home</mat-icon>Change Password</button></div>
            </div>
          </div>
         </div>
        
    <div  class="col-lg-12" *ngIf= "this.getIconName ==='help'" style="width:100%;background-color: white;float: left!important;
     padding-left:8px;
   box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);font-size: 13px; line-height: 0.75rem">
         <div class="col-lg-12 nopadding" style="text-align:center">
        <!--  <img src="assets/esales_suite_erp.png" style="width:80%" alt=""></div>  -->
            <span class="count h3 text-secondary">Neuzen</span>
          <div style=" font-size: 9px;text-align:  right;" appDropdown>Build #: {{this.buildNumber}}
          <mat-icon matTooltip="Online Mode" (click)="offlineModeAlert()">cloud_queue</mat-icon>
          </div>
    </div>
    </div>
    <div *ngIf= "this.getIconName ==='notifications'" style="background-color: white;float: left!important;font-size: 12px;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);     width: 320px;   border-radius: 8px;padding-bottom: 10px;">
      <div class="col-lg-12" style="padding: 0;" *ngIf="notificationFlag===true">
        <h2 style=" font-size: 18px;text-align: center; padding-top: 7px; margin-bottom: 7px;color: #000;">Notification</h2>
        <div class=""style="display: flex; padding-bottom: 6px; padding-top: 6px;border-bottom: 1px solid #d6d6d67a;" matTooltip=" {{notificationItem.notesDescription}}" matTooltipPosition="left" *ngFor="let notificationItem of notificationDetailList">
<div class="" style="padding: 5px;    cursor: pointer;" (click)= "routeToEditPage(notificationItem)">
<img src="{{photoSrcPath}}{{notificationItem.userName}}/{{notificationItem.photoFileName}}" style="width: 40px;padding: 0px;margin-right: 0px !important;"  (error)="errorHandler($event)" class="col-auto avatar mr-2" alt="{{notificationItem.userName}}">
 <!-- src="{{photoSrcPath}}{{emp.USER_NAME}}/{{emp.PHOTO_FILE_NAME}}" -->
</div>
<div class="" style="padding: 0px 5px;    cursor: pointer;"  (click)= "routeToEditPage(notificationItem)">
<h5 style="margin-bottom: 0px;font-size: 14px;color: #2c89d4;"> {{notificationItem.reminderModuleName}}<span style="float: right;font-size: 10px; color: #c8c8c8;"> {{notificationItem.createdDttm | dateFormatPipe}}</span></h5>
<p style="font-size: 12px;color: #5f5f5f;margin-bottom: 0px;"> {{notificationItem.notesDescription}}</p>
</div>
        </div>
        <!-- <div class="col-lg-12 notification" style="word-break: break-word;white-space: break-spaces;" 
          matTooltip=" {{notificationItem.notesDescription}}" matTooltipPosition="left" *ngFor="let notificationItem of notificationDetailList"> 
          <div style="cursor: pointer;" (click)= "routeToEditPage(notificationItem)"> 
            {{notificationItem.notesDescription}}
          </div>
          <mat-divider></mat-divider>
        </div> -->
      </div>
    </div>
  </div>