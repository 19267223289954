<mat-card class="col-lg-12">
	<mat-card-title >{{dialogLabelDisplay}}</mat-card-title>
      	<mat-card-content>
<form [formGroup]="myForm">
	<div class="col-lg-12" style="margin-bottom: 3%;">
		{{warningText}}
	</div>
	<div class="col-lg-12 row">
		<div class="col-lg-5">
		<mat-radio-button color="primary" value="autoGenerate" (change)="radioToggle($event)" [checked]="autoGenerateNumber" class="col-lg-12">Continue auto-generating {{dialogOrderType}}</mat-radio-button></div>
		<div class="col-lg-1"></div>
		<div class="col-lg-6">
		<mat-form-field class="col-lg-4" style="margin-top: -2%;" *ngIf="showFields">
	   	 <input matInput placeholder="{{PREFIX}}" formControlName="prefix" maxlength="10">
	   	</mat-form-field>
	    <mat-form-field class="col-lg-4" style="margin-top: -2%;" *ngIf="showFields">
	   	 <input matInput placeholder="{{NEXT_NUMBER}}" formControlName="nextNumber" maxlength="10" [required]=requiredNextNumber myNumberOnly (blur)="allowNumeric()">
	    </mat-form-field>
	    <mat-form-field class="col-lg-4" style="margin-top: -2%;" *ngIf="showFields">
	   	 <input matInput placeholder="{{SUFFIX}}" formControlName="suffix" maxlength="10">
	   	</mat-form-field></div>
	</div>
	<div class="col-lg-12 row">
		<div class="col-lg-1">
	  <mat-radio-button color="primary" value="keyedIn" (change)="radioToggle($event)" [checked]="keyedInNumber" class="col-lg-12">{{MANUALLY_ADDED}}</mat-radio-button></div>
	</div>
	<div class="col-lg-12 buttons" style="margin-top: 2%;">
		<button mat-raised-button color="primary" type="button" class="mat-red" (click)="onSaveClick()" [disabled]="!myForm.valid">{{SAVE_BUTTON}}</button>
	  	<button mat-raised-button color="primary" type="button" (click)="onCancelClick()">{{CANCEL_BUTTON}}</button>
 	</div>
</form>  
</mat-card-content>
</mat-card>








