import { Component, Input ,Inject,Output} from '@angular/core';
import {FormControl, FormControlName, FormGroup, NgForm} from '@angular/forms';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonUtils} from '../../core/common-utils';
import {CommonService} from '../../shared/common.service';
@Component({
  selector: 'reschedule-component',
  templateUrl: './reschedule.component.html'
})

export class RescheduleComponent extends CommonUtils { 
myForm: FormGroup;
inputData;
    constructor(private commonService: CommonService,
        public dialogRef: ErpOverlayRef,
      @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
       super();
       this.inputData=data;
       this.myForm = new FormGroup({
        appointmentId: new FormControl(0),
        rescheduleTime:new FormControl(),
        rescheduleDate: new FormControl(new Date()),
        reason: new FormControl(),
        rescheduledBy: new FormControl(),
        interviewerName: new FormControl(),
        interviewerEmail: new FormControl()
       });
      }
      ngOnInit() 
      {
        this.pageload(); 
        
      }
      pageload(){
        this.myForm.patchValue(this.inputData);
      }
      addRescheduler(){
        this.dialogRef.dialogClose(this.myForm.value);
      }
    }