import { Component, OnInit,HostListener,EventEmitter } from '@angular/core';
import{Response} from '@angular/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import{AuthService} from '../auth/auth.service';
import {CommonService} from '../shared/common.service';
import {SettingsService} from '../settings/settings-service';
import {CommonUtils} from '../core/common-utils';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import { Injectable, Injector } from '@angular/core';
import {environment} from '../../environments/environment';
import {Constants} from '../shared/dateFormatPipe.pipe';


/*import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider
} from 'angular5-social-login';
*/

@Component({
  selector: 'app-portal-signin',
  templateUrl: './portal-signin.component.html'

})
export class PortalSigninComponent extends CommonUtils implements OnInit{
 
  
  keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
    htmlElementMap:Map<string,string>=new Map();
    currentFocus='INITIAL';
public myFocusTriggeringEventEmitter = new EventEmitter<string>();
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   // event.preventDefault();
   // event.stopPropagation();
    
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

    if(this.keyCode==13){
        event.preventDefault();
    event.stopPropagation();
      if(this.ctrlKey){

      }else{
    
       this.myFocusTriggeringEventEmitter.emit(this.htmlElementMap[this.currentFocus]);
       this.currentFocus=this.htmlElementMap[this.currentFocus];
      if(this.currentFocus==='SUBMIT')
        this.checkForLoginUser();
    }
  }
  }

 
 USERNAME_PLACEHOLDER;
 myForm: FormGroup;
 defaultNavigateScreen: string;
 availableuserList;
  constructor(private router: Router,
    private authservice: AuthService, public snackBar: MatSnackBar,
    private commonService: CommonService ,
    private settingsService: SettingsService, private commonUtils: CommonUtils,
    private authService: AuthService) {
super();

  this.USERNAME_PLACEHOLDER = this.commonUtils.USERNAME_PLACEHOLDER;

  this.myForm = new FormGroup
({
userName: new FormControl('', Validators.required),
password: new FormControl(''),
userId: new FormControl('0')
});

 }
   userDataObj ;
  ngOnInit() {

    /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
      let subscriptionObj=this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
          if(result=='LOGIN_AVAILABLE')
         {    
         /*  this.defaultNavigateScreen = this.commonService.loggedInUserObj.defaultUrl;*/
           this.defaultNavigateScreen = this.commonService.loggedInUserObj.t029Roles.defaultPage;
          this.commonService.addMenuList(this.defaultNavigateScreen);
           this.router.navigate([this.defaultNavigateScreen]); 
           }          
        });
     
/** code ends above **/ 


    this.commonUtils.formHtmlElementsMap();
     this.htmlElementMap=this.commonUtils.moduleMap['LOGIN'];
    
//this.commonService.loggedInUserObj = null;
     this.myForm = new FormGroup
({
userName: new FormControl('', Validators.required),
password: new FormControl(''),
userId: new FormControl('0')
});
this.myFocusTriggeringEventEmitter.emit(this.htmlElementMap[this.currentFocus]);
    
   } 
public socialSignInGoogle(socialPlatform: string) {
  window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=733594033776-rg97b4q3i9le9t4dmcuj9ji8n4hbhbt5.apps.googleusercontent.com&redirect_uri=' + this.commonService.SERVICE_URL + 'signin/code/&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/plus.me&access_type=offline&response_type=code&prompt=consent';

}


checkForLoginUser(): void {

 this.commonService.showProgressBar();
this.commonService.checkForLoginUser(this.myForm.value).subscribe(
   data => {
      this.commonService.loggedInUserObj = data;
      this.commonService.formMenuMap();
      
      this.setDateFormat();
       this.commonService.hideProgressBar();
       if (data== null) {
         this.snackBar.open('username and password incorrect', 'OK', {
            duration: 3000
          });
        }
        else if (data!= null) {
     //     sessionStorage.setItem('userId', this.commonService.loggedInUserObj.userId);            
           this.commonService.loggesInUserObjSubject.next("LOGIN_AVAILABLE");     
         //  this.defaultNavigateScreen = this.commonService.loggedInUserObj.defaultUrl;
           this.defaultNavigateScreen = this.commonService.loggedInUserObj.t029Roles.defaultPage;
          this.commonService.addMenuList(this.defaultNavigateScreen);
           this.router.navigate([this.defaultNavigateScreen]);
         }
     }, error => {
       
       this.router.navigateByUrl('/');
          this.commonService.hideProgressBar();
          this.commonService.showERPErrorMessage(error);
        console.log(error);
      });
}

setDateFormat():void{
  if(this.commonService.loggedInUserObj.t000Organization != null && this.commonService.loggedInUserObj.t000Organization != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId != null && this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != null && this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != undefined &&
     this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue != ''){

      Constants.DATE_FMT =  this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;
          
  }else{
      Constants.DATE_FMT =  environment.DATE_FORMAT;
  }
      
}

}
