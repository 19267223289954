  
 <form [formGroup]="createTaskForm" >
 	<mat-card>
<mat-card class="col-lg-12 createcontact">
  	<mat-card-title *ngIf="projectObj != null"><span style="color:blue">{{projectObj.projectName}}</span></mat-card-title>
  	<mat-card-title *ngIf="projectObj == null"><mat-icon>home</mat-icon>{{dispLabel}} Task</mat-card-title>

  		<mat-card-subtitle> Basic Information  </mat-card-subtitle>
   
 
   
  	<mat-card-content >
        <div class="row">
        	 
        	<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding"> 			
	              <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
	             <mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding">
			      		<input matInput placeholder="{{placeholderstr}}" formControlName="taskNumber" readonly="{{disabledAutoFlag}}" [errorStateMatcher]="matcher" (focusout)="onfocusoutAutoGenField()" (keypress)="taskNumberExistsCheck()" (paste)="taskNumberExistsCheck()" id="uniqueNumberId">
			      		<mat-error *ngIf="((createTaskForm.controls['taskNumber'].hasError('required')) && (!disabledAutoFlag)) else elseBlock">{{TASKNUMBER_ALERT}} <strong>{{REQUIRED}}</strong> </mat-error> 
			      		<mat-hint #elseBlock *ngIf="(taskNumberStatus | async)?.length>0" class="discountDivColor">  {{TASKNUMBER_EXISTS_ALERT}}</mat-hint>
			     
					    <button *ngIf="displayAutoGen" type="button" mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="openSettingsDialog()" [disabled]="systemGeneratedDisableFlag">
				    	<div><mat-icon >settings</mat-icon></div>
					    </button>
					</mat-form-field>
				</div>	
				
	 	 	     
			<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 nopadding" style="width:14%;">
				<mat-checkbox  class="col-lg-12 col-md-12 col-sm-12 col-xs-12" color="primary" formControlName="summary" matTooltip="Click this if you will create tasks under this task" (change)="checkSummaryFlag()">{{IS_SUMMARY}}
				</mat-checkbox>
			</div> 

			<div  class="col-lg-1 col-md-12 col-sm-12 col-xs-12 nopadding" style="width:14%; cursor: pointer;text-decoration: underline;" *ngIf="createTaskForm.controls['taskId'].value!=0" (click)="openActualHours(createTaskForm.controls['taskId'].value)">
				<mat-label style="font-weight: 700;" *ngIf="this.commonUtils.checkNullOrUndefined(workedHours)">
			  			Worked Hrs : {{workedHours}}
				</mat-label>
				<mat-label style="font-weight: 700;text-decoration: underline" *ngIf="workedHours == '' || workedHours == null || workedHours == 'undefined'">
			  			Worked Hrs : 0
				</mat-label>
			</div>
			<div class="col-lg-1 col-md-12 col-sm-12 col-xs-12 nopadding" style="width:25%;"
			*ngIf="createTaskForm.controls['taskId'].value!=0">
			<button #taskHierarchyId mat-button type="button" matSuffix mat-button aria-label="Clear" style="margin-top: -7px;text-align: right;"
				(click)="viewTaskHierarchy(createTaskForm.value,createTaskForm.controls['taskId'].value,createTaskForm.controls['taskName'].value,taskHierarchyId)">
				<mat-icon matTooltip="View Task Hierarchy Level">low_priority</mat-icon>
			</button>
		</div>
			<div  class="col-lg-3 col-md-12 col-sm-12 col-xs-12 nopadding"  style="width:25%;" *ngIf="createTaskForm.controls['taskId'].value!=0">
				<mat-label style="font-weight: 700;" *ngIf="this.commonUtils.checkNullOrUndefined(baseLine_start_date)">
			  			BaseLine Start Date : {{baseLine_start_date | dateFormatPipe}}
				</mat-label>
			</div>
			<div  class="col-lg-3 col-md-12 col-sm-12 col-xs-12 nopadding" style="width:25%;" *ngIf="createTaskForm.controls['taskId'].value!=0">
				<mat-label style="font-weight: 700;" *ngIf="this.commonUtils.checkNullOrUndefined(baseLine_end_date)">
			  			BaseLine End Date : {{baseLine_end_date | dateFormatPipe}}
				</mat-label>
				
			</div>
		
			</div>

				<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding">
					<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
					let-onCheckedChange="onCheckedChange">
					<div class="form-inline row-item">
						<i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
						<svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
							fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path
							d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
						</svg>
						<svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
							fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path
							d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
						</svg>
						</i>
						<div class="form-check">
						<!-- <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
							[disabled]="item.disabled" [indeterminate]="item.indeterminate" /> -->
						<label class="form-check-label" DropdownToggleDirective (click)="onCheckedChangeFunction(item.value,item.text)">
							{{item.text}}
						</label>
						
						</div>
					</div>
				</ng-template>
				<ngx-dropdown-treeview class="treeViewCls col-lg-5" [config]="config"  [items]="items" (filterChange)="onFilterChange($event)" [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)">
				</ngx-dropdown-treeview>

				 <mat-form-field   class="col-lg-5" formGroupName="t823ParentTask">
					<input type="text" placeholder="{{PARENT_TASK}}" aria-label="task" matInput formControlName="taskName"   [matAutocomplete]="parentTask"  focus [focusEvent]="myFocusTriggeringEventEmitter">
					<mat-autocomplete #parentTask="matAutocomplete" (optionSelected)="taskSelected($event.option.value)"  >
					<mat-option *ngFor="let parentTask of parentTaskList | async" [value]="parentTask" >
					{{ parentTask.taskName }}<span *ngIf ="this.commonUtils.checkNullOrUndefined(parentTask.t823ParentTask)" style="color:grey;"><i> - {{ parentTask.t823ParentTask.taskName}} </i></span> 
					</mat-option>
					</mat-autocomplete>
				</mat-form-field>
		        <!-- <mat-form-field   *ngIf="(this.createTaskForm.controls['summary'].value)" class="col-lg-5" formGroupName="t823ParentTask" >
    <input type="text" placeholder="{{PARENT_TASK}}" aria-label="task" matInput formControlName="taskName"   [matAutocomplete]="parentTask"  focus [focusEvent]="myFocusTriggeringEventEmitter">
    <mat-autocomplete #parentTask="matAutocomplete" (optionSelected)="taskSelected($event.option.value)"  >
    	<mat-option  value="" >None</mat-option>
    <mat-option *ngFor="let parentTask of parentTaskList | async" [value]="parentTask" >
    {{ parentTask.taskName }}<span *ngIf ="this.commonUtils.checkNullOrUndefined(parentTask.t823ParentTask)" style="color:grey;"><i> - {{ parentTask.t823ParentTask.taskName}} </i></span> 
    </mat-option>
    </mat-autocomplete>
    </mat-form-field>

     <mat-form-field  *ngIf="!(this.createTaskForm.controls['summary'].value)" class="col-lg-5" formGroupName="t823ParentTask">
    <input type="text" placeholder="{{PARENT_TASK}}" aria-label="task" matInput formControlName="taskName"   [matAutocomplete]="parentTask"  focus [focusEvent]="myFocusTriggeringEventEmitter">
    <mat-autocomplete #parentTask="matAutocomplete" (optionSelected)="taskSelected($event.option.value)"  >
    	 
    <mat-option *ngFor="let parentTask of subParentTaskList | async" [value]="parentTask" >
    {{ parentTask.taskName }}<span *ngIf ="this.commonUtils.checkNullOrUndefined(parentTask.t823ParentTask)" style="color:grey;"><i> - {{ parentTask.t823ParentTask.taskName}} </i></span> 
    </mat-option>
    </mat-autocomplete>
    </mat-form-field> -->

			   <!-- <button class="col-lg-1" mat-button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetParentTask()" >
   					 <mat-icon>close</mat-icon>
  				</button> -->
	     		<mat-form-field    class="col-lg-3" formGroupName="t004_LookupTaskStatusObj" >			 
					<mat-select placeholder="{{TASK_STATUS}}" formControlName="lookupId">
		                <mat-option>None</mat-option>
		                <mat-option *ngFor="let status of taskStatusList" [value]="status.lookupId" (click)=onChangeStatus(status.lookupValue)>
		                   {{status.lookupValue}}
		                </mat-option>
		         	</mat-select>
				</mat-form-field>
				
				<mat-form-field   *ngIf="!(this.createTaskForm.controls['summary'].value)"   class="col-lg-2" formGroupName="t004_LookupTaskTypeObj" >			 
					<mat-select placeholder="Task Type" formControlName="lookupId">
		                 
		                <mat-option *ngFor="let status of taskTypeList" [value]="status.lookupId" (click)=onChangeTaskType(status)>
		                   {{status.lookupValue}}
		                </mat-option>
		         	</mat-select>
				</mat-form-field>
				<mat-form-field 
				 *ngIf="this.createTaskForm.get('t004_LookupTaskTypeObj.lookupValue').value === 'BUG'" class="col-lg-2" formGroupName="t004_LookupBugTypeObj" >			 
					<mat-select placeholder="Bug Type" formControlName="lookupId">
		                 
		                <mat-option *ngFor="let status of taskBugTypeList" [value]="status.lookupId" (click)=onChangeBugType(status)>
		                   {{status.lookupValue}}
		                </mat-option>
		         	</mat-select>
				</mat-form-field>
			</div>
			<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding">
				<mat-form-field  class="col-lg-5">
				    <input type="text"   matInput  placeholder="{{TASK_NAME}}" formControlName="taskName">
				    <button mat-button  *ngIf="createTaskForm.controls['taskName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'taskName')" >
                    <mat-icon>close</mat-icon>
                </button>
				</mat-form-field>
            	<!-- <mat-checkbox class="col-lg-2 col-md-4 col-sm-12 col-xs-12" formControlName="development" color="primary" matTooltip="Create a Development Task ">Development </mat-checkbox>
            	<mat-checkbox class="col-lg-2 col-md-4 col-sm-12 col-xs-12" formControlName="codeReview" color="primary" matTooltip="Create a Code Review Task ">Code Review </mat-checkbox>
		        <mat-checkbox class="col-lg-2 col-md-4 col-sm-12 col-xs-12" formControlName="testing" color="primary" matTooltip="Create a Testing Task ">Testing  </mat-checkbox> -->
			    <div class="col-lg-1 col-md-6 col-sm-6 col-xs-12">
	             <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="attachFiles()">
	              <mat-icon>attach_file</mat-icon>          
	             </button>
	             <p   style="font-weight: bold;" *ngIf="uploadedFileArrayparent.length>0">({{uploadedFileArrayparent.length}} files attached)
	             </p> 
	            </div>
			</div> 

			<div class="col-lg-12 row col-md-12 col-sm-12 col-xs-12 nopadding">	
				<mat-form-field  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				    <textarea matTextareaAutosize matAutosizeMinRows="3" onfocus="this.select()"
                matAutosizeMaxRows="4" matInput  placeholder="{{TASK_DESC}}" formControlName="taskDesc" ></textarea> 

                <button mat-button  *ngIf="createTaskForm.controls['taskDesc'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'taskDesc')" >
                    <mat-icon>close</mat-icon>
                  </button>  
				</mat-form-field>
			</div> 
	  	 
	  		</div>
  		  
  		 </mat-card-content>
  		</mat-card>

  		 <mat-card class="col-lg-12 createcontact" style= "margin-top: 10px !important" *ngIf="!(this.createTaskForm.controls['summary'].value)">
  	<mat-card-subtitle *ngIf="projectObj != null">
  		 Allocation Information 
  	</mat-card-subtitle>
    <mat-card-content>
   <mat-form-field class="col-lg-2" formGroupName="t001_ResponsibleUserObj"  >			 
	  				<mat-select    placeholder="Select Responsible Person"  formControlName="userId">
  
	  					<mat-option>None</mat-option>
	  				    <mat-option *ngFor="let user of resourceExtensionList" [value]="user.t001_UserObj.userId" 
						  [disabled]="!user.t001_UserObj.userStatus">
	  				        {{user.t001_UserObj.userName}}  <span style="color:grey;" *ngIf="user.t001_UserObj.empName != null && user.t001_UserObj.empName != '' "> - <i> {{user.t001_UserObj.empName}} </i></span> 
	  				    </mat-option>
	  				</mat-select>
	  			</mat-form-field>
    	  <mat-form-field class="col-lg-2"  >			 
	  				<mat-select  multiple placeholder="{{ALLOCATED_PLACEHOLDER}}" #multiSelect formControlName="userIdList">
  
	  					<mat-option (click)="removeAllResource()">None</mat-option>
	  				    <mat-option *ngFor="let user of resourceExtensionList" [value]="user.t001_UserObj.userId"
						  [disabled]="!user.t001_UserObj.userStatus" (click)="addingMoreUser(user)">
	  				        {{user.t001_UserObj.userName}}  <span style="color:grey;" *ngIf="user.t001_UserObj.empName != null && user.t001_UserObj.empName != '' "> - <i> {{user.t001_UserObj.empName}} </i></span> 
	  				    </mat-option>
	  				</mat-select>
	  			</mat-form-field>
 			 
   <mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12 ">
				    <input type="number"  min='0'  matInput  placeholder="Estimated Hrs" formControlName="estimated_hours">
				    <button mat-button  *ngIf="createTaskForm.controls['estimated_hours'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'estimated_hours')" >
                    <mat-icon>close</mat-icon>
                </button>
				    </mat-form-field>

				    <mat-form-field class="col-lg-2 col-md-4 col-sm-12 col-xs-12" >
				  
				  <input matInput  [matDatepickerFilter]="satSunDisableInSelectDateFilter" dateFormatter [matDatepicker]="startDatePicker" placeholder="{{START_DATE}}" 
				     formControlName="startDate"   id="startDatePicker">  
				       <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
				      <mat-datepicker #startDatePicker ></mat-datepicker>  
				</mat-form-field>
			    <mat-form-field class="col-lg-2 col-md-4 col-sm-12 col-xs-12"  >
				     <input matInput  [matDatepickerFilter]="satSunDisableInSelectDateFilter" [matDatepicker]="endDatePicker" dateFormatter placeholder="{{END_DATE}}"  id="endDatePicker"
				     formControlName="endDate" [min]="createTaskForm.controls.startDate.value">
				       <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
				      <mat-datepicker #endDatePicker ></mat-datepicker>	    

			    </mat-form-field>
				<mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
				    <input type="number"   matInput  placeholder="% Complete" formControlName="percent_complete">
				    <button mat-button  *ngIf="createTaskForm.controls['percent_complete'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'percent_complete')" >
                    <mat-icon>close</mat-icon>
                </button>
				</mat-form-field>

    </mat-card-content>

  
 
    

</mat-card>


  		 <mat-card class="col-lg-12    createcontact" style= "margin-top: 10px !important">
  	<mat-card-subtitle *ngIf="projectObj != null">
  		 Billing Information 
  	</mat-card-subtitle>
    <mat-card-content>
    	<mat-checkbox class="col-lg-1 col-md-4 col-sm-12 col-xs-12" formControlName="clientViewable" color="primary" matTooltip="This would be visible in the Customer login" >Billable
		        </mat-checkbox>

		   		    <mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12 ">
				    <input type="number"   matInput  placeholder="Billable Hrs" formControlName="billing_hours">
				    <button mat-button  *ngIf="createTaskForm.controls['billing_hours'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear">
                    <mat-icon>close</mat-icon>
                </button>
				    </mat-form-field>
				    <mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12 ">
				    <input type="number"   matInput  placeholder="Non Billable Hrs" formControlName="nonBillableHours">
				    <button mat-button  *ngIf="createTaskForm.controls['nonBillableHours'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" >
                    <mat-icon>close</mat-icon>
                </button>
				    </mat-form-field>
 
  <mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12 ">
				    <input     matInput  placeholder="Delivery Version #" formControlName="versionNumber">
				    <button mat-button  *ngIf="createTaskForm.controls['versionNumber'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'versionNumber')" >
                    <mat-icon>close</mat-icon>
                </button>

			    </mat-form-field>

 <mat-form-field  class="col-lg-2 col-md-12 col-sm-12 col-xs-12 ">
				    <input  matInput  placeholder="Billing Client" formControlName="portalName">
				    <button mat-button  *ngIf="createTaskForm.controls['portalName'].value!=''" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="resetFormControl(createTaskForm,'portalName')" >
                    <mat-icon>close</mat-icon>
                </button>
				    </mat-form-field>

				    <mat-form-field class="col-lg-2 col-md-4 col-sm-12 col-xs-12" >
				     <input matInput  [matDatepicker]="prodmoveDatePicker" placeholder="Delivery Date" dateFormatter
				     formControlName="prodmoveDate"  id="prodmoveDatePicker" >  
				       <mat-datepicker-toggle class="matdatepickericon" matSuffix [for]="prodmoveDatePicker"></mat-datepicker-toggle>
				      <mat-datepicker #prodmoveDatePicker ></mat-datepicker>    
				</mat-form-field>
 

    </mat-card-content>

  
 
    

</mat-card>
 
 <!--T_5214 / 9014--> 	
 <mat-checkbox  class="col-lg-12 col-md-12 col-sm-12 col-xs-12" color="primary" formControlName="sendemail" matTooltip="Click this if you want to send email"  >Send Email 
				</mat-checkbox>

 <!--T_5214 / 9014--> 	
 <mat-card-actions>
   <button mat-raised-button color="primary" *ngIf="editAccess" class="mat-red" [disabled]="((taskNumberStatus | async)?.length>0) ||( disableButton) || (!createTaskForm.valid)" (click)="confirmToSaveBaseLineDate()">{{SAVE_BUTTON}}</button>
    	<button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
  </mat-card-actions>
</mat-card>
 </form>
 
 


 