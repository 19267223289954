
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {SigninComponent} from './auth/signin.component';
import {SigninTokenComponent} from './auth/signintoken.component';
import {AuthGuard} from './auth/auth-guard.service';
import {PortalSigninComponent} from './portal/portal-signin.component';
import {NotFoundComponent} from './notfound/notfound.component';
import {HomeDashboardComponent} from './homeDashboard/homeDashboard.component';

const APP_ROUTES: Routes = [
 	{path: '', component: SigninComponent},
	{path: 'settings', loadChildren: ()=>import( './settings/settings-module').then(m=>m.SettingsModule), canActivate: [AuthGuard]},
	{path: 'hrmmodule', loadChildren:()=>import(  './hrm/hrm-module').then(m=>m.HrmModule), canActivate: [AuthGuard]},
	{path: 'recruitment', loadChildren:()=>import(  './recruitment/recruitment-module').then(m=>m.RecruitmentModule), canActivate: [AuthGuard]},
	{path: 'reports', loadChildren:()=>import(  './reports/reports-module').then(m=>m.ReportsModule), canActivate: [AuthGuard]},
	{path: 'projectmodule', loadChildren: ()=>import( './project/project-module').then(m=>m.ProjectModule), canActivate: [AuthGuard]},
	{path: 'mycornermodule', loadChildren:()=>import(  './mycorner/corner-module').then(m=>m.MyCornerModule), canActivate: [AuthGuard]},
	{path: 'superadmin', loadChildren: ()=>import( './superadmin/superadmin.module').then(m=>m.SuperadminModule), canActivate: [AuthGuard]},
	{path: 'assetmodule', loadChildren:()=>import(  './assettracking/assettracking.module').then(m=>m.AssetTrackingModule), canActivate: [AuthGuard]},
	{path: 'signin', redirectTo:'',pathMatch: 'full'},
	{path: 'api', loadChildren: ()=>import( './api/api-module').then(m=>m.APIModule), canActivate: [AuthGuard]},
	{path: 'homeDashboard', component: HomeDashboardComponent, canActivate: [AuthGuard]},
	{path: 'signin/code', component: SigninTokenComponent},
	{path: ':action/:headerId/:detailId', component: SigninComponent},
	{path: ':action/:headerId', component: SigninComponent}, 
	{path: ':Pthms,r', component: SigninComponent}, 	
 	{path: '**',  component: NotFoundComponent }
];

export const routing = RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })