<div [@dialog] style="background-color: white;box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);margin: 0">


<!-- <mat-checkbox *ngIf="displayedColumns.includes(columnName)" color="primary" checked="true"  (change)="clickedColumn($event,columnName);">{{UserLabelName.get(columnName)}}</mat-checkbox>

<mat-checkbox *ngIf="!displayedColumns.includes(columnName)"color="primary" (change)="clickedColumn($event,columnName);">{{UserLabelName.get(columnName)}}</mat-checkbox> -->

 <div *ngIf="sortoptions!=null && sortoptions.length>0 " style="margin: 0">
         <label class="fltheader">SORT BY</label>

        <label class="col-lg-12 nopadding submenu"> 
          <div class="col-lg-12  row" style="margin: 0"
          *ngFor="let option of sortoptions" > 

          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap"> {{option.displayValue}} </div>
         
                <mat-icon  (click)="openSideMenuOption(option.lookupValue,'asc')"  style="color: green;font-size: 15px;" class="menu_add_btn col-lg-2"> arrow_upward
                </mat-icon>  

                <mat-icon  (click)="openSideMenuOption(option.lookupValue,'desc')" style="color: crimson;font-size: 15px;" class="col-lg-2"> arrow_downward</mat-icon> 
          
           </div>
        </label>

          </div>  

          <div class="row">

            <div class="col-lg-12" style="margin: 0">
        <ng-template [ngIf]="importDisable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap"> <p style="margin-left: -1%;font-size: 12px;">Import Data</p></div>
         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="importData()">
          <mat-icon class="mat_icon_color">file_upload</mat-icon> </button>

        <div class="dropdown-divider"></div>
      </ng-template>
    </div>

       <div class="col-lg-12" style="margin: 0;display: flex;">
        <ng-template [ngIf]="exportDisable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap"> <p style="margin-left: -1%;font-size: 12px;margin-top: 12px !important;">Export Data</p></div>
         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportData()">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>

        <div class="dropdown-divider"></div>
      </ng-template>
    </div>

    <div class="col-lg-12" style="margin: 0">    
      <ng-template [ngIf]="bulkImportDeleteEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Bulk Import Delete</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="bulkImportForNistar('BULK_IMPORT_DELETE')">
          <mat-icon class="mat_icon_color">file_upload</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>  
      <ng-template [ngIf]="bulkImportItemEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Bulk Import Item</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="bulkImportForNistar('BULK_IMPORT_ITEM')">
          <mat-icon class="mat_icon_color">file_upload</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>     
        <ng-template [ngIf]="exportParentEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active Parent data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Export Parent</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportDataForNistar('PARENT')">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>

      <ng-template [ngIf]="exportChildEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active Child data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Export Child</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportDataForNistar('CHILD')">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>

      <ng-template [ngIf]="exportParentMatrixEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Export Parent Matrix</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportDataForNistar('PARENT_MATRIX')">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>

      <ng-template [ngIf]="exportParentImagesEnable">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Active data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Export Parent Images</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportDataForNistar('PARENT_IMAGES')">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>

       <ng-template [ngIf]="exportAmazonListing">
         <div class="dropdown-divider"></div>
          <div style="color: black;font-size: 12px;" class="sortOptionsTitle col-lg-8 sortWordWrap">
            <p matTootTip="Only Amazon Listing data will be exported" style="margin-left: 10%;font-size: 12px;margin-top: 8%;">Export Amazon Listing</p>
          </div>         
           <button mat-button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="exportDataForNistar('AMAZON_LISTING')">
          <mat-icon class="mat_icon_color">file_download</mat-icon> </button>
        <div class="dropdown-divider"></div>
      </ng-template>
    </div>
 
</div> 