import {Component, OnInit, ViewChild, Input,EventEmitter,Output,HostListener,ViewChildren, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroup,FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ImportContactsDialog} from '../../../import/importContacts.component';
import {ExportPODialogComponent} from '../../../export/exportpo.component';
import {CommonUtils} from '../../common-utils';
import {CommonService} from '../../../shared/common.service';
import {CreatePaycodeComponent} from '../../../hrm/paycode/create-paycode.component';
import {CreateLoanComponent} from '../../../hrm/loans/create-loan.component';
import {CreateProjectComponent} from '../../../project/createproject.component';
import {CreateUserComponent} from '../../../settings/users/create-user.component';
import {CreateOrganizationProfileComponent} from '../../../settings/organizationprofile/createorganization/createorganizationprofile.component';
import {CreateTaskComponent} from '../../../project/createtask.component';
import {CreateissuesComponent} from '../../../issues/createissues/createissues.component';
import {ApplyLeaveComponent} from '../../../mycorner/applyleave/applyleave.component';
import  {CreateAutomatedEmailComponent} from '../../../settings/automatedEmail/create-automatedEmail.component';
import {ViewTemplateWrapperAnalyticsComponent} from  '../../analytics/view-template-wrapper-analytics.component';
import {CreateTestCaseLinkComponent} from '../../../superadmin/createtclinkmaster/create-testcaselinkmaster.component';
import {CreateMenuComponent} from '../../../superadmin/superadmin-menu/createMenu.component';
import {HrmService} from '../../../hrm/hrm-service';
import {HttpClient} from '@angular/common/http';
import {throwError as observableThrowError, Observable,Subscription,Subject} from 'rxjs';
import {ErpFilterComponent} from '../../../popup/view-default-top-filter/erp-filter.component';
import {ErpPopupService} from '../../components/Erp-popup-service';
import {ErpOverlayRef} from '../../components/ErpOverlayRef';
import {CreateReviewDisplayColumn} from '../../../superadmin/reviewdisplay/createreviewdisplay.component';
import {CreateAppraisalMaster} from '../../../hrm/appraisalmaster/create-appraisalmaster.component';
import {CreateShiftMasterComponent} from '../../../hrm/shiftmaster/create-shift-master.component';
import {CreateShiftAllocationComponent} from '../../../hrm/shiftallocation/create-shift-allocation.component';
import { ErpOverlayComponent } from '../../../popup/erp-overlay.component';
import { SideMenuOverlayComponent } from '../../../popup/side-menu/side-menu-overlay.component';
import {SettingsService} from '../../../settings/settings-service';
import {CreateTimesheetComponent} from '../../../project/timesheet/create-timesheet.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {CreateJobComponent} from '../../../recruitment/jobs/createjob.component';
import { CreateWareHouseComponent } from '../../../settings/warehouse/create-warehouse.component';
import {ViewMapComponent} from '../../../popup/viewmap/viewmap.component';
import { AddResourceExtensionComponent } from 'src/app/project/addResourceExtension/add-resource-extension.component';
@Component({
  selector: 'view-default-top',
  templateUrl: './view-default-top.component.html'
})
export class ViewDefaultComponent extends CommonUtils implements OnInit {

  @ViewChildren('filterdropdown') filterfield;
  @ViewChild('moreMenu') elementRefBtn: MatButton;
    @Input() displayedColumns;
   @Input() displayedColumnsOriginal;
   @Input() UserLabelNameForColumn;
   @ViewChild('dateSearchInput') dateSearchInput: ElementRef;
   @ViewChild('employeeSearchFilterInput') employeeSearchFilterInput: ElementRef;
   @ViewChild('sideMenu') elementRef: MatButton;

/*showChart:boolean=false;*/
 keyboardEvent: any;
  altKey: boolean;
  charCode: number;
  code: string;
  ctrlKey: boolean;
  keyCode: number;
  keyIdentifier: string;
  metaKey: boolean;
  shiftKey: boolean;
  timeStamp: number;
  type: string;
  which: number;
  portalName : string;
  addDisable:boolean=true; // added to disable the add button
   employeeForm=new FormGroup({
   employeeId: new FormControl(0),
   userName: new FormControl(''),
  });
   @Input() orgUsersList;
   @Input() appraisalLevelList;
   @Input() versionNumberList; 
   schemaValue:string;
selectedUserObj;
selectedProjectObj; 
diableResetBtnFlag: boolean = false;
exportParentEnable: boolean = false;
exportChildEnable: boolean = false;
exportParentMatrixEnable: boolean = false;
exportParentImagesEnable: boolean = false;
exportAmazonListing: boolean = false;
bulkImportDeleteEnable:boolean = false;
bulkImportItemEnable:boolean=false;
analyticalChartLable:string='Analytical Chart';
summaryStringList = [{value:'A',dispValue:'All'},{value:'S',dispValue:'Summary'},{value:'NS',dispValue:'Non-Summary'}];
@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
   
    this.keyboardEvent = event;
    this.altKey = event.altKey;
    this.charCode = event.charCode;
    this.code = event.code;
    this.ctrlKey = event.ctrlKey;
    this.keyCode = event.keyCode;
    this.keyIdentifier = event.keyIdentifier;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.timeStamp = event.timeStamp;
    this.type = event.type;
    this.which = event.which;

   if(this.keyCode==32 && this.ctrlKey){

    event.preventDefault();
    event.stopPropagation();
    
 if(this.currentPageAccessObj!=undefined && this.currentPageAccessObj.createAccess){      this.newContact();
    }
     
  }
  }

  lookupFormGroup: FormGroup;
  lookupFormGroupSort: FormGroup;
  resourceExtensionForm:FormGroup;
  employeeAppraisalLevelForm: FormGroup;
  emitValueFormGroup : FormGroup;
  license: FormControl;
  dateFormat;
  @Input()  filterData;
  filterOptionValue;
  userId;
  versionNumber;
  menuDisable:boolean=false;
  settingsDisable:boolean=true;
  customFilter:boolean=false;
 projectList;
  dropDownFlag:boolean=false;
  importDisable:boolean=false;
  exportDisable:boolean=true;
  @Input() moduleName: string;
  editId:any = 0;
  options;
  sortoptions: Array<any>;
  @Input() currentlySelectedMenu;
  @Input() currentPageAccessObj;
  @Input() selectedTaskObj;
  parentTaskObj;
  createLabel;
  managerAccess:boolean=false;
  finalApproverAccess:boolean=false;
  taskType;
  globalFlag: boolean = false;
  appraisalLevelValue;
  createFlag: boolean= true;
  employeeId; // added to bind in ng model for employee appraisal save
  //licenseList = ['eSalesERP','esales','esalescorp','servmobi','esaleserp','fusionoffice'];
  licenseList;
  lookUpFormGroup: FormGroup = new FormGroup({
    lookupValue: new FormControl('OPEN')
  });
  estimatedHours;
  billableHours;
  nonBillableHours;
  showHoursFlag:boolean=false;
  salaryGroupList = [];  
  salaryGroupId;
  createdDate:Date;
  employeeList = [];
  t800EmployeeFormGroup: FormGroup;

  @Output() viewDefaultTopEmitter:EventEmitter<any>=new EventEmitter<any>();

    constructor(private router: Router, private http: HttpClient,
    public dialog: MatDialog, public commonUtils: CommonUtils, public commonService: CommonService, private route: ActivatedRoute,
    private hrmService:HrmService,
    private erpCustomDialog: ErpPopupService,private formBuilder: FormBuilder,private settingsService: SettingsService)
     {
       super();
     
       this.resourceExtensionForm=new FormGroup
       ({
          extensionId:new FormControl(0),
          /*t800_EmployeeObj:new FormGroup({
            employeeId:new FormControl(0)}),*/
          t001_UserObj: new FormGroup({userId: new FormControl(0)}),
          t822ProjectObj:new FormGroup({projectId:new FormControl(0)}),
          t001_UserCreatedByObj:new FormGroup({userId:new FormControl(0)}),
          t001_UserUpdatedByObj:new FormGroup({userId:new FormControl(0)}),
          createdTs:new FormControl(),
          updatedTs:new FormControl(),
          pageSize:new FormControl(0),
          statusFlag: new FormControl(''),
          status: new FormControl(true),
          userIds : new FormControl(),
          employeeId:new FormControl(0),
          t004_LookupObj:new FormGroup({lookupId:new FormControl(0),lookupValue:new FormControl('')})
       })

     this.employeeAppraisalLevelForm=new FormGroup({
          appraisalLevelId:new FormControl(0),
          t828_AppraisalObj : new FormGroup({appraisalId:new FormControl(0),appraisalName:new FormControl('')}),
          status: new FormControl(false),
          t001_UserObj: new FormGroup({userId: new FormControl(0)}),
          t001_UserCreatedByObj:new FormGroup({userId:new FormControl(0),userName:new FormControl('')}),
          t001_UserUpdatedByObj:new FormGroup({userId:new FormControl(0)}),
          createdTs:new FormControl(),
          updatedTs:new FormControl(),
          appraisalDate: new FormControl(new Date()),
          t800_EmployeeObj: new FormGroup({employeeId: new FormControl(0)}),
          t800_ApproverObj: new FormGroup({employeeId: new FormControl(0)}),
          t004LookupStatusObj: new FormGroup({lookupCode: new FormControl('APPRAISAL_STATUS'),lookupValue: new FormControl('ASSIGNED')}),
      })

     this.emitValueFormGroup = new FormGroup({
       filterOptionValue : new FormControl(''),
       filterEmployeeOptionValue: new FormControl(''),
       projectValue : new FormControl(''),
       taskType : new FormControl(''),
       globalFlag : new FormControl(false),
       invoiceStartDate : new FormControl(),
       invoiceEndDate : new FormControl(),
       loggedInUserObj:new FormGroup({userId:new FormControl(0)}),
     });
     
        this.license = new FormControl('esaleserp');

        this.t800EmployeeFormGroup = new FormGroup({
          t004_LookupObj: new FormGroup({
            lookupValue: new FormControl('ACTIVE EMPLOYEE')
          }),
          t818SalaryGroup: new FormGroup({
            salaryId: new FormControl(0)
          }),
          requestFlag: new FormControl('FetchAll'),
          fetchInitializeFlag: new FormControl('N')
        });
     }
parentTaskId;
projectObj;
roleName;
SERVICE_URL: string = this.commonService.SERVICE_URL;
  ngOnInit() 
  {    
         if(this.commonUtils.checkNullOrUndefined(this.filterData)){
           this.filterOptionValue = this.filterData;
         }
       this.commonService.refreshClickedSubject.subscribe(
                  result=>{
                           if(result.action==='REFRESH_GRID'){
                              // reset the filter after hitting the refresh grid
                              if(result.resetFilter)
                                    this.filterOptionValue = '';
                              if(this.moduleName=="VIEW_RESOURCE_EXTENSION"){
                                this.resourceExtensionForm.get('userIds').setValue('');
                                this.emitValueFormGroup.get('projectValue').setValue('');
                              }
                                // this.resourceExtensionForm.get('userIds').setValue(''); code commented on 18, Nov 2022 - 9168
                              this.emitValueFormGroup.get('filterOptionValue').setValue('');
                              this.emitValueFormGroup.get('filterEmployeeOptionValue').setValue('');                    
                              if(this.commonUtils.checkNullOrUndefined(this.dateSearchInput)){
                                this.dateSearchInput.nativeElement.value = '';
                              }
                              if(this.commonUtils.checkNullOrUndefined(this.employeeSearchFilterInput)){
                                this.employeeSearchFilterInput.nativeElement.value = '';
                              }
                             }else if(result.action==='RESET'){
                                this.globalFlag=false;
                                this.emitValueFormGroup.get('globalFlag').setValue(false);  
                                this.emitValueFormGroup.get('taskType').setValue('A');                          
                             }else if(result.action==='UNCHECK_GLOBAL'){
                                this.globalFlag=false;
                                this.emitValueFormGroup.get('globalFlag').setValue(false);                         
                             }
         });

         this.route.params.subscribe(params => {
     this.parentTaskId = params.parentTaskId;
   });

     

     /**
DO NOT MODIFY THE BELOW CODE UNLESS YOU KNOW WHAT IT IS 
Below code is used to get the access for the page which is being loaded / refreshed. 
Without the below code, the refresh of page will not display the access properly
**/
// this.commonService.checkLoggedInUser(this.moduleName);
     this.commonService.loggesInUserObjSubject.subscribe(
       result=>{
         if(result==='LOGIN_AVAILABLE')
         {
            
           this.pageLoad();
         }
       }); 
/** code ends above **/ 

 if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
           this.pageLoad();  
          }
  }
  pageLoad():void
  {
   this.portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();

      this.currentPageAccessObj =this.commonService.getAccessObjForCurrentScreen(this.moduleName);
 	  this.roleName = this.commonService.loggedInUserObj.roleName.toUpperCase();
this.dateFormat=this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue;

     /** setting value for create label start below **/ 
 if (this.moduleName === 'VIEW_CONTACT') 
      {
         this.createLabel='Create Contact';
         this.importDisable=true;
         this.customFilter=true;
     
      }
       else if (this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS') 
       {
            this.createLabel='Create Item';
            this.importDisable=true;
    } else if(this.moduleName === 'VIEW_PRODUCT_SETUP'){
      
            this.importDisable = false;
            this.exportDisable= false;
            this.exportParentEnable = true; 
            this.exportChildEnable= true;
            this.exportParentMatrixEnable= true;
            this.exportParentImagesEnable= true;
            this.exportAmazonListing = true;
            this.bulkImportDeleteEnable = true;
            this.bulkImportItemEnable=true;
    }
    else if (this.moduleName === 'VIEW_INVENTORY_ADJUSTMENT') 
       {
         
         this.importDisable=true;
    } 
      else if (this.moduleName === 'VIEW_PO') 
      {
        this.createLabel='Create Purchase Order';
      /*  this.showChart=true;*/
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_BILL') 
      {
           
       this.createLabel='Create Bill';
       this.customFilter=true;
    /*   this.showChart=true;*/
      } else if (this.moduleName === 'VIEW_R_BILL')
      {
          
       this.createLabel='Create Recurring Bill';
       this.customFilter=true;
      } else if (this.moduleName === 'VIEW_VENDOR_CREDIT') 
      {
           
        this.createLabel='Create Vendor Credit';
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_SALESORDER') 
      {
           
       this.createLabel='Create Sales Order';
       this.customFilter=true;
      } else if (this.moduleName === 'VIEW_INVOICE') 
      {
           
       this.createLabel='Create Invoice';
       this.customFilter=true;
      } else if (this.moduleName === 'VIEW_R_INVOICE') 
      {
           
        this.createLabel='Create Recurring  Invoice';
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_ARTWORK') 
      {
          
       this.createLabel='Create Artwork';
       this.customFilter=true;
      }else if (this.moduleName === 'VIEW_ESTIMATE') 
      {
          
       this.createLabel='Create Estimate';
       this.customFilter=true;
      }else if (this.moduleName === 'VIEW_RESERVATION') 
      { 
       this.createLabel='Create Reservation';
       this.customFilter=true;
       this.importDisable=false;
      } else if (this.moduleName === 'VIEW_CREDIT_NOTES') 
      {
            
       this.createLabel='Create Credit Note';
       this.customFilter=true;
      } else if (this.moduleName === 'VIEW_PAYMENT_MADE')
      {
        this.createLabel='Create Payment Made';
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_EXPENSE') 
      {
        this.createLabel='Create Expense';
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_EMPLOYEE') 
      {
         this.createLabel='Create Employee';
         this.importDisable=true;         
        
      } else if (this.moduleName === 'VIEW_PAYCODE') 
      {
        this.createLabel='Create Paycode';

      } else if (this.moduleName === 'VIEW_PACKAGES') 
      {
         this.customFilter=true;
         this.createLabel='Create Package';
      }
      else if (this.moduleName === 'VIEW_SHIPPING') 
      {
         this.customFilter=true;
         this.menuDisable=true;
      }
      else if (this.moduleName === 'VIEW_PROJECT') 
      {
        this.exportDisable=false;
         this.customFilter=true; //given to add open filter
      }
       else if (this.moduleName === 'VIEW_JOB') 
      {
        //  this.menuDisable=true;
         this.createLabel='Create Job';
         this.customFilter=true; //given to add open filter
         this.exportDisable = true;
      }
      else if (this.moduleName === 'VIEW_CANDIDATE') 
      {
        
         this.createLabel='Create Candidate';
         this.customFilter=true; //given to add open filter
      }
      else if (this.moduleName === 'VIEW_APPOINTMENT') 
      {
         this.menuDisable=false;
          this.createLabel='Create Interview Appointment';
         this.customFilter=true; //given to add open filter
         this.exportDisable = false;
      }
      else if (this.moduleName === 'VIEW_PAYMENT_RECIEVED') 
      {
        this.createLabel='Create Payment Recieve';
        
      } 
      else if (this.moduleName === 'VIEW_INTERVIEW_SLOT') 
      {
         this.menuDisable=true;
         this.createLabel='Create Interview Slot';
         this.customFilter=true; //given to add open filter
      }
      else if (this.moduleName === 'VIEW_R_EXPENSE')
      {
        this.createLabel='Create Recurring Expense';
        this.customFilter=true;
      } else if (this.moduleName === 'VIEW_LOAN') 
      {
          this.createLabel='Create Loan';

      }
      else if (this.moduleName === 'VIEW_ASSETS') 
      {
         this.createLabel='Create Asset';

         this.importDisable=true;
      }
      else if(this.moduleName==='VIEW_INDENT')
      {
        this.createLabel='Create Indent';
        this.customFilter=true; //given to add open filter
      }
       else if(this.moduleName==='VIEW_STOCK_REQUEST')
      {
        this.createLabel='Create Stock Request';
         this.menuDisable=true;
      }
      else if(this.moduleName==='VIEW_ITEMS_GROUP')
      {
        this.createLabel='Create Items Group';
        this.exportDisable=false; // for disabling the export in Review Item Group Screen
      }
       else if(this.moduleName==='VIEW_CATEGORY')
      {
        this.createLabel='Create Category';
        this.exportDisable=false;
      }
        else if(this.moduleName==='VIEW_USER')
      {
        this.createLabel='Create User';
        this.menuDisable=true;
      }
        else if(this.moduleName==='VIEW_ROLE')
      {
        this.createLabel='Create Role';
        this.menuDisable=true;
      }
       else if(this.moduleName==='VIEW_EMPLOYEEEARNINGS')
      {
        this.exportDisable=false;
        this.importDisable=true;
      }
        else if(this.moduleName==='VIEW_SALES_PERSON')
      {
        this.createLabel='Create Sales Person';
      }
         else if(this.moduleName==='VIEW_WAREHOUSE')
      {
        this.createLabel='Create Warehouse';
        this.menuDisable=true;
      }
       else if(this.moduleName === 'VIEW_ISSUES')
      {
        this.createLabel='Create Issues/Feedback';
       // this.globalCheckboxChange();
       // this.fetchProjectBasedOnResourceExtension();
        this.menuDisable=true;
      }
        else if(this.moduleName==='VIEW_PRODUCTION_SLIP')
      {
       this.customFilter=true;
        this.importDisable=false;
         //this.exportDisable=true; 
      }
        else if(this.moduleName==='VIEW_ARTWORK_REQUEST')
      {
        this.createLabel='Create Artwork';
        //this.customFilter=true;
        this.importDisable=false;
        this.exportDisable=false;
        if(this.commonService.loggedInUserObj.type === 'GRAPHICS')
            this.createFlag = false;
        else
          this.createFlag = true;
      }
       else if(this.moduleName==='MANAGE_GRAPHICS_ARTWORK_REQUEST')
      {
        this.menuDisable=true; 
      }
        else if(this.moduleName==='MANAGE_ARTWORK')
      {
        this.createLabel='';
        
      }
       else if(this.moduleName==='VIEW_FEEDBACK')
      {
        this.menuDisable=true;       
      }
      else if(this.moduleName==='MANAGE_FEEDBACK_MYCORNER')
      {
        this.menuDisable=true; 
        this.importDisable=false;  
        this.exportDisable=false;
        this.dropDownFlag = false;
        this.fetchSalaryGroup();
      }
      else if(this.moduleName==='VIEW_ANNOUNCEMENTS')
      {
        this.menuDisable = true; 
        this.importDisable = false;  
        this.exportDisable = false;
        this.dropDownFlag = false;
        this.customFilter = false;
      }else  if(this.moduleName==='VIEW_APPRAISAL_FORM'){
        this.fetchActiveEmployees();
        this.menuDisable=true;
      }
        else if(this.moduleName==='VIEW_BATCH_SYNC')
      {
        this.importDisable=true;
        this.menuDisable=true;  
      }
      else if(this.moduleName==='VIEW_APPROVE_STOCK_REQUEST')
      {
       // this.createLabel='Create Approved Stock Request';
        this.menuDisable=true;
      }

        else if(this.moduleName==='VIEW_ORGANIZATION')
      {
        this.importDisable=true;
        this.menuDisable=true;
       
      }
       else if(this.moduleName==='VIEW_DATABASE')
      {
        this.createLabel='Create Database Script';
         this.importDisable=true;
        this.menuDisable=true;
      }
       else if(this.moduleName==='VIEW_DISPATCH_PACKAGE')
      {
        this.menuDisable=true;
        this.customFilter=true;
      }
       else if(this.moduleName==='VIEW_DISPATCH_RETURNS')
      {
       this.menuDisable=true;
      }
       else if(this.moduleName==='VIEW_RETURNS')
      {
        this.menuDisable=true;
      }
      else if(this.moduleName === 'VIEW_TAX_GROUP'){
         this.exportDisable=false;
      }
       else if(this.moduleName==='VIEW_RECEIVE_RETURNS')
      {
        this.menuDisable=true;
      }
      //added to disable menu icon in employee appraisal level
      else if(this.moduleName==='VIEW_EMPLOYEE_APPRAISAL_LEVEL')
      {
        this.menuDisable=true;
      }
      else if(this.moduleName==='VIEW_TCLINKMASTER')
      {
        this.createLabel='Create TestcaseLink';
        this.menuDisable=true;
      }
       else if(this.moduleName==='VIEW_REVIEW_DISPLAY_COLUMN')
      {
        this.menuDisable=true;
      // this.exportDisable=false; 
      }
       else if(this.moduleName==='VIEW_RECEIVINGS')
      {
        this.createLabel='Create Receivings';
         this.importDisable=true;
        this.menuDisable=true;
      }
       else if(this.moduleName==='VIEW_RESOURCE_EXTENSION')
      {
        this.menuDisable=true;
        this.fetchProject();
       // this.disableFields(); // disable all the fields before selecting the project commented by 9168 on 10, Nov 2022
        //this.fetchEmployee();
      }
       else if(this.moduleName==='TIMESHEET_HISTORY')
      {
         this.fetchProjectBasedOnResourceExtension();
      }
       else if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT')
      {
        this.fetchProjectBasedOnResourceExtension();
        this.disableFields();
       }
      else if(this.moduleName==='VIEW_LEAVE')
      {
       this.menuDisable=true;
       if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t800EmployeeObj)){
         if(this.commonService.loggedInUserObj.t800EmployeeObj.reportingManager===true){
           this.managerAccess=true;
         }
         else{
           this.managerAccess=false;
         }
       }
       else
         this.managerAccess=false;
      }
      else if(this.moduleName==='APPROVE_LEAVE')
      {
        this.menuDisable=true;
       if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.t800EmployeeObj)){
         if(this.commonService.loggedInUserObj.t800EmployeeObj.finalApprover===true){
           this.finalApproverAccess=true;
         }
         else{
           this.finalApproverAccess=false;
         }
       }
       else
         this.finalApproverAccess=false;
      }
      else if(this.moduleName==='VIEW_APPRAISAL_MASTER')
      {
        this.createLabel='Create Appraisal';
        this.exportDisable=false;
      }
        else if(this.moduleName==='VIEW_JOURNAL')
      {
        // added for disabling the import and export menu in review journal screen
        this.createLabel='Create Jorunal';
        this.menuDisable=true;
        this.exportDisable=true;
        this.importDisable=true;
      }

      // added for disabling the import , export and menu in review po receive screen
       else if(this.moduleName==='VIEW_PO_RECEIVE')
      {
        this.menuDisable=true;
        this.exportDisable=true;
        this.importDisable=true;
      }
      
      else if(this.moduleName==='VIEW_SHIFT_MASTER')
      {
        this.importDisable=false;
      }
     else if(this.moduleName==='VIEW_SHIFT_ALLOCATION')
      {
        this.importDisable=false;
      }
      else if(this.moduleName==='VIEW_REFUND_RECEIPT')
      {
        this.menuDisable=true;
      }
      else if(this.moduleName==='VIEW_SALES_RECEIPT')
      {
        this.menuDisable=true;
      }
      else if(this.moduleName==='VIEW_MENU')
      {
        this.fetchOrganisation();
      } else if (this.moduleName == "REVIEW_TRAINEE"){
        this.menuDisable=true;
      }
 /** setting value for create label end above **/ 
 if(this.moduleName=='VIEW_CONTACT')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_CONTACT');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_CONTACT_SORT');
   
    }else if(this.moduleName === 'VIEW_APPRAISAL_FORM'){
      this.lookupFormGroup = this.commonService.formLookUpFormGroup('APPRAISAL_STATUS');
    }
    else if(this.moduleName=='VIEW_BILL')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('BILL_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_BILL_SORT');
   
    }
    else if(this.moduleName=='VIEW_SALESORDER')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('SO_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_SALESORDER_SORT');
    
    }
    else if(this.moduleName=='VIEW_PO')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PO_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PO_SORT');
    
    }
     else if(this.moduleName=='VIEW_PACKAGES')
    {

    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PACKAGE_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PACKAGES_SORT');
    
    }
    else if(this.moduleName=='VIEW_SHIPPING')
    {

    this.lookupFormGroup = this.commonService.formLookUpFormGroup('SHIPMENT_STATUS');
    
    }
    else if(this.moduleName=='VIEW_PAYMENT_RECIEVED')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_PAYMENT_RECIEVE');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PAYMENT_RECIEVED_SORT');
    
    }
    else if(this.moduleName=='VIEW_INDENT')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('INDENT_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_INDENT_SORT');
    
    }
     else if(this.moduleName=='VIEW_STOCK_REQUEST')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_STOCK_REQUEST');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_STOCK_REQUEST_SORT');
    
    }
    else if(this.moduleName=='VIEW_PROJECT')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PROJECT_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PROJECT_STATUS_SORT');
    
    }
    else if(this.moduleName=='VIEW_JOB')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('JOB_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_JOB_SORT');
    
    }
    else if(this.moduleName=='VIEW_CANDIDATE')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_CANDIDATE_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_CANDIDATE_SORT');
    }
    else if(this.moduleName=='VIEW_INTERVIEW_SLOT')
    { 
        this.lookupFormGroup = this.commonService.formLookUpFormGroup('INTERVIEW_SLOT_STATUS');
       // this.lookupFormGroup = this.commonService.formLookUpFormGroup('INTERVIEW_SLOT_TYPE');
    }
    else if(this.moduleName=='VIEW_APPOINTMENT')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_APPOINTMENT');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_APPOINTMENT_SORT');
    
    }
    else if(this.moduleName=='APPROVE_LEAVE')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('APPROVE_LEAVE');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('APPROVE_LEAVE_SORT');
    
    } else if(this.moduleName=='VIEW_TIMESHEET')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PROJECT_PLAN');
       
    }  else if(this.moduleName=='VIEW_USER')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_USER');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_USER_SORT');
  
    }else if(this.moduleName=='VIEW_DELIVERY_CHALLAN_RETURN')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_DELIVERY_CHALLAN_RETURN');
    
    }
    else if(this.moduleName=='VIEW_RETURNS')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_STOCK_REQUEST');
    
    }
     else if(this.moduleName=='VIEW_RECEIVE_RETURNS')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_STOCK_REQUEST');    
    }
     else if(this.moduleName=='VIEW_APPROVE_STOCK_REQUEST')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('VIEW_STOCK_REQUEST');
    
    }
     else if(this.moduleName=='VIEW_DISPATCH_PACKAGE')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PACKAGE_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PACKAGES_SORT');
    
    }
      else if(this.moduleName=='VIEW_DISPATCH_RETURNS')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PACKAGE_STATUS');
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup('VIEW_PACKAGES_SORT');
    
    }

   /* else if(this.moduleName=='VIEW_ISSUES')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PROJECT_PLAN');
    
    }*/

    else if(this.moduleName === 'PROJECT_PLAN_CLIENT')
    {
    
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('PROJECT_PLAN');
    
    }
    
    /* else if(this.moduleName=='VIEW_ROLE')
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup('SO_STATUS');
  
    }*/
    else
    {
    this.lookupFormGroup = this.commonService.formLookUpFormGroup(this.moduleName);
    this.lookupFormGroupSort = this.commonService.formLookUpFormGroup(this.moduleName + '_SORT');
     }
     

    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj) && this.commonUtils.checkNullOrUndefined(this.lookupFormGroup)){
      this.loadFilterValues();
    }    
  }
  
  fetchSalaryGroup(): void {
    this.hrmService.fetchSalary().subscribe(
    data => {
      this.salaryGroupList = data;
    }, error => {
      this.commonService.showERPErrorMessage(error);
    });
  }

  salaryChangeEvent(){    
    if(this.commonUtils.checkNullOrUndefined(this.salaryGroupId) && this.salaryGroupId != 0){
      this.fetchActiveEmployees();
    }
  }

  fetchActiveEmployees(){
    if(this.commonUtils.checkNullOrUndefined(this.salaryGroupId) && this.salaryGroupId != 0){
      this.t800EmployeeFormGroup.get('t818SalaryGroup.salaryId').setValue(this.salaryGroupId);
    }
    this.hrmService.fetchEmployee(this.t800EmployeeFormGroup.value).subscribe(
    data => {
      if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0)
        this.employeeList = data;
    },error => {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
    });
  }

  employeeChangeEvent(){
    this.viewDefaultTopEmitter.emit({action:"EMPLOYEE_CHANGE_FOR_FEEDBACK",value:this.employeeId});
  }

  employeeSelected(empId){
    // if(empId > 0){
    //   this.emitValueFormGroup.get('filterOptionValue').setValue(empId);
    //   this.fetchReportsDetails();
    // }
      
  }
  fetchReportsDetails(){
    this.viewDefaultTopEmitter.emit({action: "EMPLOYEE_CHANGE_FOR_APPRAISAL_FORM", value: this.emitValueFormGroup.value});
  }

  loadFilterValues() 
  {
    let graphicsFilterBY=[];
    if(this.commonUtils.checkNullOrUndefined(this.lookupFormGroup))
    {
    this.commonService.fetchFromLookup(this.lookupFormGroup.value).subscribe(
      data => {
      
         if(this.moduleName === 'VIEW_ARTWORK_REQUEST' && this.commonService.loggedInUserObj.type === 'GRAPHICS')
        {
          for(var loop=0;loop<= data.length;loop++)
          {
            if(this.commonUtils.checkNullOrUndefined(data[loop]) && (data[loop].lookupValue === 'SENT' || data[loop].lookupValue === 'OPEN'))
            { 
              graphicsFilterBY.push(data[loop]);
            }
          }
          if(this.commonUtils.checkNullOrUndefined(graphicsFilterBY))
          {
             this.options = graphicsFilterBY;          
          }
        }
        else
          this.options = data;
      },
      error => {
        this.commonService.showERPErrorMessage(error)
      });
    }
    if(this.commonUtils.checkNullOrUndefined(this.lookupFormGroupSort))
    {
       this.commonService.fetchFromLookup(this.lookupFormGroupSort.value).subscribe(
      data => {

        this.sortoptions = data;
      },
      error => {
        this.commonService.showERPErrorMessage(error)
      }); 

    }
   

  }
  filterDropdownChanged(selectedOption): void 
  {
    if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
       if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)){
         let dataArray =  {action:"EMIT_ALL_VALUES", value:this.emitValueFormGroup.value};
         this.commonService.pageChangeEvent.next(dataArray);
       }
       else{
         this.commonService.showERPMessage('Please Select a Project');
         return;
       } 
    }
    else{
       let dataArray = {action:'FILTER_CHANGED', value:selectedOption};
       this.commonService.pageChangeEvent.next(dataArray);
    }
  }

  sortContacts(sortBy, sortType): void {
    const dataArray = {action:'SORT_CLICKED', sortBy:sortBy, sortType:sortType};
    this.commonService.pageChangeEvent.next(dataArray);
  }

  newContact(): void {
    if (this.moduleName==='VIEW_PROJECT'){

      const dialogRef = this.dialog.open(CreateProjectComponent,
        {
          width: '500px',
          height:'523px',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
        if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });
    }    else if (this.moduleName === 'VIEW_RESOURCE_EXTENSION') {

      const dialogRef = this.dialog.open(AddResourceExtensionComponent,
        {
          width: '500px',
          data: {projectList: this.projectList, userList: this.orgUsersList, pageSize: 10,selectedProject:this.emitValueFormGroup.get('projectValue').value  }
        });

      dialogRef.afterClosed().subscribe(result => {
        if (this.commonUtils.checkNullOrUndefined(result) && 
         this.commonUtils.checkNullOrUndefined(result.t822ProjectObj))
         {
         this.emitValueFormGroup.get('projectValue').setValue(result.t822ProjectObj.projectId);
         this.loadSelectedProject(result.t822ProjectObj);
         }
           // this.viewDefaultTopEmitter.emit({ action: "REFRESH_RESOURCE" ,data:this.selectedProjectObj.projectId});
      });

    }
    else if (this.moduleName==='VIEW_JOB'){

      const dialogRef = this.dialog.open(CreateJobComponent,
        {
          width: '60%',
          height:'100%',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
        if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });
    }
    else if (this.moduleName==='VIEW_INTERVIEW_SLOT'){

      const dialogRef = this.dialog.open(ViewMapComponent,
        {
          width: '60%',
          height:'100%',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
        if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });
    }
    else if (this.moduleName === 'VIEW_PAYCODE') {
      const dialogRef = this.dialog.open(CreatePaycodeComponent,
      {
        width: '500px',
        data: {}
      });

       dialogRef.afterClosed().subscribe(result => {
       if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
       });

      }else if (this.moduleName === 'VIEW_WAREHOUSE') {

        this.commonService.showProgressBar();
        const dialogRef = this.dialog.open(CreateWareHouseComponent,
          {
            width: '500px',
            height: '500px',
            data: {}
          });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result)
            this.viewDefaultTopEmitter.emit({ action: "REFRESH_PAGE" });
        });
        this.commonService.hideProgressBar();
  
      }
 else if (this.moduleName === 'VIEW_ORGANIZATION')
     {   
   
                 
        const dialogRef = this.dialog.open(CreateOrganizationProfileComponent,{width: '90%',
          height:'100%', data: {organizationId: parseInt(this.editId) }
        });
        dialogRef.afterClosed().subscribe(result => {
         if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
        });
     }else if(this.moduleName === 'VIEW_USER'){
      
      this.commonService.showProgressBar();
     const dialogRef = this.dialog.open(CreateUserComponent,
      {
        width: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
    this.commonService.hideProgressBar();

     }else if(this.moduleName === 'VIEW_ROLE'){
         this.router.navigate(['/settings/createrole', btoa('0')]);
       
     }
     else if (this.moduleName === 'VIEW_ISSUES') 
      {

      const dialogRef = this.dialog.open(CreateissuesComponent,
        {
          width: '700px',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
        }
          
      });

    }
     else if(this.moduleName === 'PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT') 
     {
       if(!this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)){
         this.commonService.showERPMessage('Select a Project to create Task');
         return;
       }
       if(!this.commonUtils.checkNullOrUndefined(this.selectedTaskObj)){
         this.selectedTaskObj = {};
       }
        const dialogRef = this.dialog.open(CreateTaskComponent,
        {
          width:'80%',
          height:'95%',
          data: {selectedTaskObj:{t822ProjectObj:this.selectedProjectObj,t823ParentTask:this.selectedTaskObj}}
        });
        dialogRef.afterClosed().subscribe(result => {         
          if(result){
            this.viewDefaultTopEmitter.emit({action:"REFRESH_TASK",value:this.selectedProjectObj.projectId});
          }
        });
     }
     else if(this.moduleName === 'VIEW_LEAVE')
    {
      this.commonService.showProgressBar();
      const dialogRef = this.dialog.open(ApplyLeaveComponent,
      {
        width: '90vw',
        height: '90vw',
        data: {}
      });

     dialogRef.afterClosed().subscribe(result => {
           if(result)
           this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
        
        this.commonService.hideProgressBar();
     }
     else if(this.moduleName === 'VIEW_AUTOMATED_EMAIL'){
      
      this.commonService.showProgressBar();
     const dialogRef = this.dialog.open(CreateAutomatedEmailComponent,
      {
        width: '75%', height: '90%',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
    this.commonService.hideProgressBar();

     }
     else if(this.moduleName === 'VIEW_TCLINKMASTER'){
      
      this.commonService.showProgressBar();
     const dialogRef = this.dialog.open(CreateTestCaseLinkComponent,
      {
        width: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
    this.commonService.hideProgressBar();

     }

       else if(this.moduleName === 'VIEW_MENU'){
      
      this.commonService.showProgressBar();
     const dialogRef = this.dialog.open(CreateMenuComponent,
      {
        width: '500px',
        data: { schemaName:this.schemaValue}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
    this.commonService.hideProgressBar();

     }
     else if(this.moduleName === 'VIEW_REVIEW_DISPLAY_COLUMN'){
      
      this.commonService.showProgressBar();
     const dialogRef = this.dialog.open(CreateReviewDisplayColumn,
      {
        width: '900px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
            this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
    this.commonService.hideProgressBar();

     }
     else if (this.moduleName === 'VIEW_APPRAISAL_MASTER') 
      {

      const dialogRef = this.dialog.open(CreateAppraisalMaster,
        {
          width: '95%',          
          height: '95%',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
        }
          
      });

    }else if(this.moduleName === 'VIEW_SHIFT_MASTER') {

      const dialogRef = this.dialog.open(CreateShiftMasterComponent,
        {
          width: '500px',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });

    }else if(this.moduleName === 'VIEW_SHIFT_ALLOCATION') {

      const dialogRef = this.dialog.open(CreateShiftAllocationComponent,
        {
          width: '500px',
          data: {}
        });

      dialogRef.afterClosed().subscribe(result => {
if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });

    }
     else if(this.moduleName === 'VIEW_HRIS_TIMESHEET' )
     {
         const dialogRef = this.dialog.open(CreateTimesheetComponent,
        {
          width:'80%',
          height:'90%',
          data: {selectedTaskObj:{t822ProjectObj: this.selectedProjectObj,t823ParentTask: this.selectedTaskObj}}
        });
        dialogRef.afterClosed().subscribe(result => {         
          if(result){
            this.viewDefaultTopEmitter.emit({action:"REFRESH_TASK",value:this.selectedProjectObj.projectId});
          }
        });
     }
     else if (this.moduleName === 'VIEW_LOAN') {
      const dialogRef = this.dialog.open(CreateLoanComponent, {
        width: '800px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result)
          this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
      });  
    }
     else {
      // add menu url into menu history List to get this in back button click
      let navigateUrl = this.commonUtils.getAddLink(this.moduleName, btoa(this.editId),this.portalName); 
       navigateUrl = this.commonUtils.getAddLink(this.moduleName, btoa(this.editId),this.portalName);
            this.commonService.addMenuList(navigateUrl);
            this.router.navigate([navigateUrl]);
    }
  }

  importData(): void {

    var label = 'Contacts/ImportCustomers';
    if(this.moduleName === 'VIEW_EMPLOYEE')
      label = "ImportEmployee";
    else if(this.moduleName === 'VIEW_ASSETS')
      label = "ImportAssets";   
     else if(this.moduleName === 'VIEW_ITEM' || this.moduleName === 'VIEW_ITEMS')
      label="ImportItems";
     else if(this.moduleName === 'VIEW_CONTACT')
      label="ImportContacts";
    else if(this.moduleName === 'VIEW_INVENTORY_ADJUSTMENT')
      label="ImportInventoryAdjustments";
    else if(this.moduleName === 'VIEW_PRODUCT_SETUP')
      label="ImportItemsForDelete";

    const dialogRef = this.dialog.open(ImportContactsDialog, {
      width: '500px',
      data: {labelText: label,dialogName: this.moduleName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
  }
   importNistarData(moduleType): void {
    var label = 'Contacts/ImportCustomers';
    if(this.moduleName === 'VIEW_PRODUCT_SETUP' && moduleType==="BULK_IMPORT_DELETE")
      label="ImportItemsForDelete";
    else if(this.moduleName === 'VIEW_PRODUCT_SETUP' && moduleType==="BULK_IMPORT_ITEM")
      label="ImportNistarItems";

    const dialogRef = this.dialog.open(ImportContactsDialog, {
      width: '500px',
      data: {labelText: label,dialogName: this.moduleName,dialogType:moduleType}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.viewDefaultTopEmitter.emit({action:"REFRESH_PAGE"});
    });
  }

  importVendors(): void {
    const dialogRef = this.dialog.open(ImportContactsDialog,
      {
        width: '500px',
        data: {labelText: 'Contacts/ImportVendors'}
      });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  exportData(): void 
  {
    this.viewDefaultTopEmitter.emit({action:'EXPORT_DATA'});
  }

  exportVendors(): void {
    const dialogRef = this.dialog.open(ExportPODialogComponent, {
      width: '800px',
      data: {labelText: 'Contacts/ExportVendors'}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  refreshContacts(): void {

  }
  checkIfCreateEligible(): boolean {
     if (this.commonService.currentlySelectedMenu != null)
      return this.commonService.currentlySelectedMenu.createAccess;
    else return false;
  }
callDropDown()
{
this.dropDownFlag=true;
}

sendEmail(): void{
  let userObj = this.commonService.loggedInUserObj;
  let inputForm: FormGroup = new FormGroup({automatedEmailId: new FormControl(0),
                                          profileName: new FormControl('')});
  this.commonService.sendAutomatedEmail(inputForm.value).subscribe(
    data => {
          
        this.commonService.showERPMessage(data.erpMessage);
  },
  error => {

  });
}
reloadERPProperties(): void{
  this.commonService.fetchERPPropertiesMap().subscribe(
    data => {
              if(this.commonUtils.checkNullOrUndefined(data) && data === 'Success'){
                this.commonService.showERPMessage('ERP Properties has been loaded successfully');
              }else{
                this.commonService.showERPMessage('Failed to load ERP Properties');
              }
    },
    error => {
    });
}
openAnalyticalChart()
{
   const dialogRef = this.dialog.open(ViewTemplateWrapperAnalyticsComponent, {
      width: '90%',height:'90%',
      data: {moduleName: this.moduleName}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
}
fetchProject()
{
 
this.resourceExtensionForm.get('pageSize').setValue(0);
this.resourceExtensionForm.get('t004_LookupObj.lookupValue').setValue('DEVELOPMENT');

   this.hrmService.fetchProject(this.resourceExtensionForm.value).subscribe(
    data=>
    {   
          
      this.projectList=[];

       if(this.commonUtils.checkNullOrUndefined(data))
          this.projectList=data;
    },
    error=>
    {
       this.commonService.showERPErrorMessage(error);
    })

}

saveOrUpdateResourceExtension()
{
  this.viewDefaultTopEmitter.emit({action:"SAVE_RESOURCE_EXTENSION",data:this.resourceExtensionForm.value});
  this.userId = '';
  this.tempArrayLengthOfSelectedList =0 ;
}


userIdList=[];
tempArrayLengthOfSelectedList: number=0;
checkUserExists(userId , multiSelectValue)
{
  // The user selected will be checked with the respective project and added if the user does not exist 
   // added to enable the button once the user selected
    if(this.tempArrayLengthOfSelectedList < multiSelectValue.selected.length){
     if(this.commonUtils.checkNullOrUndefined(userId))
      this.addDisable=false;
    else
      this.addDisable=true;
      this.tempArrayLengthOfSelectedList = multiSelectValue.selected.length;
      // added to enable the button once the user selected
     if(this.commonUtils.checkNullOrUndefined(this.emitValueFormGroup) && this.commonUtils.checkNullOrUndefined(this.emitValueFormGroup.value) && 
       this.commonUtils.checkNullOrUndefined(this.emitValueFormGroup.value.projectValue)){
      this.resourceExtensionForm.get('t822ProjectObj.projectId').setValue(this.emitValueFormGroup.value.projectValue);
      this.resourceExtensionForm.get('t001_UserObj.userId').setValue(userId);
      this.viewDefaultTopEmitter.emit({action:"CHECK_RESOURCE_EXTENSION",data:this.resourceExtensionForm.value});
     }
   }else{
     this.tempArrayLengthOfSelectedList = multiSelectValue.selected.length;
   }
}

loadSelectedProject(project)
{
  this.userId = '';
  this.selectedProjectObj = project;
  if(this.moduleName==='VIEW_RESOURCE_EXTENSION') 
  {
     this.viewDefaultTopEmitter.emit({action:"REFRESH_RESOURCE",data:project.projectId});
      this.disableFields(); // added to enable the fields once the project is selected
  }  
  
/* if(this.moduleName==='PROJECT_PLAN')
  this.viewPageRefresh.emit({action:"REFRESH_TASK",id:this.projectValue});*/
 /*if(this.moduleName==='VIEW_ISSUES' || this.moduleName === 'VIEW_TICKETS')
 {
  //this.router.navigate([menuUrl]);
  this.viewDefaultTopEmitter.emit({action:"REFRESH_TASK",data:project});
 }*/
if(this.moduleName === 'VIEW_TICKETS')
 {
  //this.router.navigate([menuUrl]);
  this.viewDefaultTopEmitter.emit({action:"REFRESH_TASK",data:project});
 }
if(this.moduleName==='PROJECT_PLAN' || this.moduleName==='PROJECT_PLAN_CLIENT')
 {
    this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value}); 
    this.disableFields();
    this.viewDefaultTopEmitter.emit({action:"CLEAR_BREADCRUMB",data:null}); 
 }
 if(this.moduleName==='TIMESHEET_HISTORY')
 {
    this.viewDefaultTopEmitter.emit({action:"PROJECT_BASED_TIMESHEET", data:this.emitValueFormGroup.value}); 
 }

}
searchClicked(){
   
    if(this.moduleName==='PROJECT_PLAN'  )
    {  
      if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)){
        this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value});    
        //this.viewDefaultTopEmitter.emit({action:"TASK_TYPE_CHANGED",data:taskType});
      }
      else{
        this.commonService.showERPMessage('Please Select a Project');
        return;
      } 
    }  
}
taskTypeclicked(taskType){
    if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT' )
    {  
      if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)){
        this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value});    
        //this.viewDefaultTopEmitter.emit({action:"TASK_TYPE_CHANGED",data:taskType});
      }
      else{
        this.commonService.showERPMessage('Please Select a Project');
        return;
      } 
    }  
   if(this.moduleName==='VIEW_ISSUES')
    this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value});  
          
}

globalCheckboxChange(){
   if(this.moduleName==='PROJECT_PLAN' || this.moduleName === 'PROJECT_PLAN_CLIENT'){
     if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj)){
        this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value}); 
     }
     else{
       this.commonService.showERPMessage('Please Select a Project');
       return;
     }  
 }
 if(this.moduleName==='VIEW_ISSUES')
    this.viewDefaultTopEmitter.emit({action:"EMIT_ALL_VALUES", data:this.emitValueFormGroup.value}); 
 
  /*this.viewDefaultTopEmitter.emit({action:"GLOBAL_CHKBOX_CHANGED",data:this.globalFlag});*/
}

fetchProjectBasedOnResourceExtension()
{
  
  this.resourceExtensionForm.get('t001_UserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
  this.resourceExtensionForm.get('t004_LookupObj.lookupValue').setValue('DEVELOPMENT');
  
  this.hrmService.fetchProjectBasedOnResourceExtension(this.resourceExtensionForm.value).subscribe(
    data=>
    {
      this.projectList=[];
      if(this.commonUtils.checkNullOrUndefined(data))
         this.projectList = data;      
      
    },
    error=>
    {
       this.commonService.showERPErrorMessage(error);
    })
}
 
requestValue: string;
testCaseForm = new FormGroup({
  endPointUrl: new FormControl('')
});

  executeUrl()
  {
this.viewDefaultTopEmitter.emit({action:"EXECUTE_TC_LINK"})
    
    }
   private handleError(error: any):Observable<any> {
     console.log(error);
     
    return observableThrowError(error);
  } 
    

  fetchTeamLeave()
  {
    this.viewDefaultTopEmitter.emit({action:"TEAM_LEAVE",data:this.commonService.loggedInUserObj.t800EmployeeObj.employeeId});
  }
  fetchMyLeave(){
     this.viewDefaultTopEmitter.emit({action:"MY_LEAVE",data:this.commonService.loggedInUserObj.t800EmployeeObj.employeeId});
  }
  fetchAllQueue(){
    this.viewDefaultTopEmitter.emit({action:"ALL_QUEUE"});
  }
  fetchMyQueue(){
     this.viewDefaultTopEmitter.emit({action:"MY_QUEUE",data:this.commonService.loggedInUserObj.t800EmployeeObj.employeeId});
  }
  viewHistory(){
    if(this.moduleName=='VIEW_TIMESHEET'){
      this.router.navigate(["/projectmodule/timesheethistory"]);  
    }else if(this.moduleName=='VIEW_HRIS_TIMESHEET'){
      this.router.navigate(["/projectmodule/hristimesheethistory"]);  
    }
    
  }

  erpFiltercomponent: ErpFilterComponent ;
  openFilter(elementRef){
    
    let erpFiltercomponent:ErpOverlayRef= this.erpCustomDialog.open(elementRef._elementRef,{data: {filterArray:this.options}},ErpFilterComponent);
         erpFiltercomponent.afterClosed.subscribe(result=>{  
           if(result.flag==true)
             this.viewDefaultTopEmitter.emit({action:'DEFAULT_FILTER', data:result.data});
           else
             this.viewDefaultTopEmitter.emit({action:'FILTER_CHANGED', data:result.data});
           },
           error=>{

           });
       }

     
  saveOrUpdateEmployeeAppraisalLevel()
    {
     
     // this.commonService.showProgressBar();
     if(this.commonUtils.checkNullOrUndefined(this.employeeId) && this.commonUtils.checkNullOrUndefined(this.appraisalLevelValue)){
       if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
      {
        this.employeeAppraisalLevelForm.get('t001_UserCreatedByObj.userId').setValue(this.commonService.loggedInUserObj.userId);
        this.employeeAppraisalLevelForm.get('t001_UserCreatedByObj.userName').setValue(this.commonService.loggedInUserObj.userName);
        this.employeeAppraisalLevelForm.get('t001_UserUpdatedByObj.userId').setValue(this.commonService.loggedInUserObj.userId);
      }
      this.employeeAppraisalLevelForm.get('t001_UserObj.userId').setValue(this.employeeId);
      if(this.selectedUserObj.employeeId != null && this.checkNullOrUndefined(this.selectedUserObj.employeeId))
        this.employeeAppraisalLevelForm.get('t800_EmployeeObj.employeeId').setValue(this.selectedUserObj.employeeId);
      this.employeeAppraisalLevelForm.get('t828_AppraisalObj').patchValue(this.appraisalLevelValue);
      this.viewDefaultTopEmitter.emit({action:"APPRAISAL_LEVEL",data:this.employeeAppraisalLevelForm.value});
      this.employeeId = '';
      this.appraisalLevelValue='';
       this.addDisable=true;
     }
     else if(this.commonUtils.checkNullOrUndefined(this.employeeId) && !this.commonUtils.checkNullOrUndefined(this.appraisalLevelValue)){
       this.commonService.showERPMessage("Please select Appraisal Level to proceed");
     }
     else if(!this.commonUtils.checkNullOrUndefined(this.employeeId) && !this.commonUtils.checkNullOrUndefined(this.appraisalLevelValue)){
       this.commonService.showERPMessage("Please select Employee and Appraisal Level to proceed");
     }
      
    }

    loadSelectedLicense(license)
    {   
      this.schemaValue=license;
      this.viewDefaultTopEmitter.emit({action:"SCHEMA_LIST",data:license});
    }

    disableFields(){
      if(this.commonUtils.checkNullOrUndefined(this.selectedProjectObj))
      {
        this.emitValueFormGroup.get('filterOptionValue').enable();
        this.emitValueFormGroup.get('taskType').enable();
       // this.emitValueFormGroup.get('globalFlag').enable(); 
        this.diableResetBtnFlag = false;
      }
      else{
        this.emitValueFormGroup.get('filterOptionValue').disable();
        this.emitValueFormGroup.get('taskType').disable();
        this.emitValueFormGroup.get('globalFlag').disable();
        this.diableResetBtnFlag = true;
      }
      /****added for the resource extension module******/
      if(this.commonUtils.checkNullOrUndefined(this.moduleName) && this.moduleName==='VIEW_RESOURCE_EXTENSION' && this.commonUtils.checkNullOrUndefined(this.selectedProjectObj) )
      {
        this.resourceExtensionForm.get('userIds').enable();
       
      }
      else
      {
       // this.resourceExtensionForm.get('userIds').disable();
         
      }
      /****added for the resource extension module******/
  }
  loadUsers(){
    this.viewDefaultTopEmitter.emit({action:"LOAD_USERS"});
 
      
    }
    loadVersionNumber(){
      if(this.commonUtils.checkNullOrUndefined(this.versionNumber))
        this.viewDefaultTopEmitter.emit({action:"LOAD_VERSION_NUMBER",data:this.versionNumber});
      else
        this.viewDefaultTopEmitter.emit({action:"LOAD_VERSION_NUMBER",data:null});  
    }
    setUserObj(userObj){
      this.selectedUserObj = userObj;
       this.addDisable=false;
    }

    resetProject(){ 
     this.viewDefaultTopEmitter.emit({action:"HOME_CLICKED",data:null});
    //   let filterAllOptionForm: FormGroup = new FormGroup({
    //   lookupValue: new FormControl('ALL'),
    //    lookupCode: new FormControl('PROJECT_PLAN')
    // }) ;
    //   this.emitValueFormGroup.get('filterOptionValue').setValue(filterAllOptionForm.value);
    //  let dataArray =  {action:"EMIT_ALL_VALUES", value:this.emitValueFormGroup.value};
    //     this.commonService.pageChangeEvent.next(dataArray);  
  }
onChangeProject(project, event) { 
  if(event.isUserInput==true){
    if(this.commonUtils.checkNullOrUndefined(project))
      this.selectedProjectObj = project;
    else
      this.selectedProjectObj = null;

    this.disableFields();
  }
}

 
openIcon(type:String){
  if ( type==='REVIEWDISPLAYCOLUMN'){
    this.openReviewDisplayColumns(null);
  }
else{
  this.commonService.erpSubject.next(type);
}
 
} 
         

         reviewDisplayColRef: ErpOverlayRef ;
  openReviewDisplayColumns(element){
          
    this.reviewDisplayColRef= this.erpCustomDialog.open(this.elementRefBtn._elementRef,{
      data: {displayedColumns:this.displayedColumns,displayedColumnsOriginal:this.displayedColumnsOriginal,UserLabelName:this.UserLabelNameForColumn }
    },ErpOverlayComponent);

 
 }

sideMenuBtnRef: ErpOverlayRef ;
sideMenuBtnClick(){
        this.dropDownFlag=true;
      this.sideMenuBtnRef= this.erpCustomDialog.open(this.elementRef._elementRef,{
      data: {sortoptions: this.sortoptions,importDisable: this.importDisable, exportDisable: this.exportDisable,
        exportParentEnable : this.exportParentEnable, bulkImportDeleteEnable : this.bulkImportDeleteEnable,bulkImportItemEnable : this.bulkImportItemEnable,
      exportChildEnable: this.exportChildEnable, exportParentMatrixEnable: this.exportParentMatrixEnable, exportParentImagesEnable: this.exportParentImagesEnable,exportAmazonListing:this.exportAmazonListing,}
    },SideMenuOverlayComponent);
 
    this.sideMenuBtnRef.afterClosed.subscribe( result =>{
      if(result === 'IMPORT_DATA'){
        this.importData();
      }else if(result === 'EXPORT_DATA'){
         this.viewDefaultTopEmitter.emit({action:'EXPORT_DATA'});
      }
      else if(this.commonUtils.checkNullOrUndefined(result) &&  typeof result === 'string' && result.split(",").length > 0 && result.split(",")[0] === 'EXPORT_DATA'){
        this.viewDefaultTopEmitter.emit({action:'EXPORT_DATA',data:result.split(",")[1]});
      }
      else if(this.commonUtils.checkNullOrUndefined(result) &&  typeof result === 'string' && result.split(",").length > 0 && result.split(",")[0] === 'IMPORT_DATA'){
        let moduleType=result.split(",")[1];
         this.importNistarData(result.split(",")[1]);
      }
        else{
        this.sortContacts(result.option,result.orderStr);
      }
      });
  }
showHoursInTimesheet(){
 this.emitValueFormGroup.get('loggedInUserObj.userId').setValue(this.commonService.loggedInUserObj.userId);
 let dataArray = {action:'FILTER_CHANGED', value: this.emitValueFormGroup.value};
 this.commonService.pageChangeEvent.next(dataArray);
 if(this.commonUtils.checkNullOrUndefined(this.emitValueFormGroup.get('invoiceStartDate').value) && this.commonUtils.checkNullOrUndefined(this.emitValueFormGroup.get('invoiceEndDate').value)){

 }

 this.commonService.fetchEstimatedHoursForTimesheet(this.emitValueFormGroup.value).subscribe(
       data => {
         if(this.commonUtils.checkNullOrUndefined(data) && data.length === 1){
           this.showHoursFlag=true;
         this.estimatedHours = data[0].Estimated_Hours;
         this.billableHours = data[0].Billable_Hours;
         this.nonBillableHours = data[0].Non_Billable_Hours;
       }
         this.commonService.hideProgressBar();

       },
       error => {
         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);       
       }
      );
}

fetchOrganisation(){
  let t000_OrganizationFormFroup=new FormGroup({
    license:new FormControl(''),
  });

  t000_OrganizationFormFroup.get('license').setValue('esaleserp');
  this.settingsService.fetchOrganization(t000_OrganizationFormFroup.value).subscribe(
     data => {
       this.licenseList=[];
      if(this.commonUtils.checkNullOrUndefined(data) && data.length >0){
       for(var loop= 0;loop<data.length;loop++){
         this.licenseList.push(data[loop].portalName);
       }
      }
    },error => {
     
    });
 }

checkDate(event){
    this.viewDefaultTopEmitter.emit({action:"CREATED_DATE_BASED_FEEDBACK_FETCH",value: this.createdDate});
  }
  onChangeUsersForProject(user, event) {  
 
    if(event.isUserInput==true){
      if(this.commonUtils.checkNullOrUndefined(user)){
         this.selectedUserObj = user;      
      }     
      else{
        this.selectedUserObj = null;
      }
    }
    
   
  }
  loadSelectedUserBasedProjectAllocation(user) {

    this.selectedUserObj = user;
   
    if (this.moduleName === 'VIEW_RESOURCE_EXTENSION') {
      if(this.commonUtils.checkNullOrUndefined(user.userId)){
          this.viewDefaultTopEmitter.emit({ action: "REFRESH_RESOURCE_BASED_PROJECT", data: user.userId });
      }
     
     // this.disableFields(); // added to enable the fields once the project is selected
    }
  }
}