import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {CommonService} from '../../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CommonUtils} from '../../common-utils';
 @Component({
  selector: 'app-view-breadcrumb',
  templateUrl: './view-breadcrumb.component.html',
  providers: [],
  })
 export class ViewBreadcrumbComponent implements OnInit{
   
 breadCrumbList:any=[];
 @Output() viewSearchresultEmitter=new EventEmitter<any>();
 	constructor( private commonService: CommonService,private router: Router,private commonUtils:CommonUtils ) 
 {

 }
 ngOnInit()
 { 
  // this.breadCrumbList=this.commonService.breadCrumbList;
  this.commonService.erpSubject.subscribe(
    (data:any)=>{
     switch (data.action)
     {        
      case "BC_CHANGED":{
        if(this.commonUtils.checkNullOrUndefined(data.data))
        {
         this.breadCrumbList.push(data.data);
      }
        else
          this.breadCrumbList=[];
      }
      break;
      case "BREADCRUMB_RESULT":{
     
        if(this.commonUtils.checkNullOrUndefined(data.data.taskHirarchy))
        {         
          this.breadCrumbList=data.data.taskHirarchy;
        }
      else{
        this.breadCrumbList=[];
      }
      }
   }
      
    })
 }

 loadHomeUrl(){	
     this.breadCrumbList=[];
     this.viewSearchresultEmitter.emit({action:"HOME_CLICKED",data:null});    
 }

    openChildTask(breadcrumbId,breadCrumbObj)
    {	
      this.breadCrumbList.splice(breadcrumbId+1);   
      this.viewSearchresultEmitter.emit({action:"GET_TASK_NAMES",data:breadCrumbObj});      
    }

 }