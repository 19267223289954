<mat-card class="col-lg-12 history" style="height: 100%;">
	<mat-card-title >
		<mat-icon class="col-lg-1">home</mat-icon>
		<label class="historyLabel col-lg-10">Actual Hours Worked </label>
		 <label class="col-lg-1" style="text-align: right;"  >
		 	<mat-icon mat-icon-button (click)="closeDialog()">close</mat-icon>
		 </label>
	</mat-card-title>	

	<mat-card-content >

		<div class="col-lg-12">
		
		   <mat-table #table [dataSource]="dataSource" class="example-container mat-elevation-z8">

  	  			<ng-container matColumnDef="empname">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Resource </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft">  {{element.userName}} - {{element.empName}} </mat-cell>
			  </ng-container>

			  <ng-container matColumnDef="resource">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Resource </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft">  {{element.userName}}  </mat-cell>
			  </ng-container>
 
 
			  <ng-container matColumnDef="comments">
			    <mat-header-cell *matHeaderCellDef class="alignLeft"> Comments </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="alignLeft">  {{element.taskDescription}}  </mat-cell>
			  </ng-container>

 			 <ng-container matColumnDef="workedhrs">
			    <mat-header-cell *matHeaderCellDef class="reportCenterAlign"> Worked Hours</mat-header-cell>
			    <mat-cell *matCellDef="let element" class="reportRightAlign"> {{element.workedHours}} </mat-cell>
			  </ng-container>
 
			  <ng-container matColumnDef="workedDate">
			    <mat-header-cell *matHeaderCellDef  class="reportCenterAlign"> Worked Date </mat-header-cell>
			    <mat-cell *matCellDef="let element" class="reportCenterAlign"> {{element.workedDate | dateFormatPipe}} </mat-cell>
			  </ng-container>  
 
			  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    		  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

		</mat-table>
		
        
                
         <div class="alignCenter" *ngIf="recordFlag" style="padding: 20px !important;">
              <p>No Records Found</p>
          </div>
        		 <div class="col-lg-12" class="historyButton">
			 	<button mat-raised-button type="button" color="primary"  (click)="closeDialog()">Close</button> 
			 </div>
	</div>

	

	</mat-card-content>
	
</mat-card>