<div class="row">
 <h1 >Address</h1>    
   </div>
<div>
<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" style="height:300px" (mapReady)="mapReadyHandler($event)">
   <agm-marker [latitude]="lat" [longitude]="lng" ></agm-marker>
 </agm-map>

</div>
<div>
   <div style="padding:0px; text-align: center; font-weight: bold;">Your Location</div>
   <div style="padding:0px; text-align: center;">{{currentAddress}}</div>
   <form  [formGroup]="addressFormObj">   
     <div class="row" style="padding:0px">
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="Address Line 1"  name="line1" readonly="true"  formControlName="line1"  [readonly]="true" > 
          </mat-form-field> 
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="Address Line 2"  name="line2" readonly="true"  formControlName="line2"  [readonly]="true" > 
          </mat-form-field>  
   </div>
   <div class="row" style="padding:0px">
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="City"  name="city" readonly="true"  formControlName="city"  [readonly]="true" > 
          </mat-form-field> 
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="State"  name="state" readonly="true"  formControlName="state"  [readonly]="true" > 
          </mat-form-field>  
   </div>
   <div class="row" style="padding:0px">
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="Country"  name="country" readonly="true"  formControlName="country"  [readonly]="true" > 
          </mat-form-field>      
         <mat-form-field  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-mr-0 mb-pd-0">
            <input matInput  placeholder="Zip Code"  name="zipCode"  [readonly]="true"  formControlName="zip"  > 
          </mat-form-field>
   </div>
     <div class="col-lg-6" style="padding-top:10px;" >
      <button mat-raised-button color="primary"  class="mat-red" (click)="saveAddress()">{{SAVE_BUTTON}}</button>
      <button mat-raised-button color="primary" type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
    </div>
   </form>
</div>