import { Directive, HostListener, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';
import {CommonService} from '../shared/common.service';

@Directive({
  selector: '[status-color]'
})
export class StatusColorDirective implements OnInit {

  private el: any;
  @Input() status: any;
  @Input() isDeduct:any;
  @Input() lopValue:any;
  @Input() recordUnread:any;
  @Input() graphicsRecordUnread:any;
  @Input() customerRecordUnread:any;
  @Input() salespersonRecordUnread:any;
  @Input() t301GraphicsRecordUnread:any;
  @Input() summary:any;
  
  @Input() rowObj:any;
  renderer: Renderer2;
  constructor(private elementRef: ElementRef, renderer: Renderer2, private commonService: CommonService) {
    this.el = this.elementRef.nativeElement;
    this.renderer = renderer;

  }
  @HostListener('mouseenter') mouseover(){
    
    if(this.status==='SHOW_POPUP'){
      
    }
  //  this.renderer.setElementStyle(this.elementRef.nativeElement,'color','green');
 //   this.renderer.setElementStyle(this.elementRef.nativeElement,'font-size','18px');
  }
   @HostListener('mouseleave') mouseleave(){
  //  this.renderer.setElementStyle(this.elementRef.nativeElement,'color','black');
 //   this.renderer.setElementStyle(this.elementRef.nativeElement,'font-size','15px');
    
  }

  ngOnInit() {
    
    if (this.status === 'CLOSED' || this.status === 'BILLED' || this.status === 'ACCEPTED'
      || this.status === 'PACKAGED' ||  this.status === 'DELIVERED' || this.status === 'PAID'
      ||this.status === 'INVOICED' || this.status === 'LEAVE_CREDIT' || this.isDeduct===false 
      || this.status===100.00 ||this.status==='RECEIVED' || this.status==='APPROVED' || this.status === 'READY FOR PRODUCTION'
      || this.status==='ACTIVE' || this.status === true) 
    {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'green');
    }

    if (this.status === 'PARTIALLY BILLED' || this.status === 'PARTIALLY INVOICED' || 
     this.status === 'SHIPPED' || this.status === 'PARTIALLY PAID' || this.status === 'PARTIALLY PACKAGED' 
     || this.status === 'PARTIALLY SHIPPED' || this.status === 'PARTIALLY RECEIVED'|| this.status==='PROOF REVIEW') 
    {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'orange');
    }
    
    if (this.status === 'NOT PACKAGED' ||this.status === 'NOT RECEIVED'|| this.status === 'DECLINED' || this.status === 'OVERDUE'||
      this.status === 'NOT SHIPPED'|| this.status==false || this.status === 'LEAVE_DEBIT' || this.isDeduct===true || this.status==='REWORK' 
      || this.status==='REJECTED' || this.status==='INACTIVE')
    {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'crimson');
    }
  
    if(this.status === 'PERMISSION' || this.status==='PROOF WIP' || this.status==='WIP'){
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'blue');
    }
    if(this.status==='PROOF PENDING' || this.status==='PAUSED')
    {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'darkslateblue');
    }
    if(this.lopValue != null && this.lopValue != undefined && this.lopValue.value > 0){
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'crimson');
    }
     if(this.status==='OPEN')
    {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', 'red');
    }      

    if(this.graphicsRecordUnread === true){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'bolder');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '17px');
    }
    if(this.graphicsRecordUnread === false){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'normal');
    }
    if(this.salespersonRecordUnread === true){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'bolder');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '17px');
    }
    if(this.salespersonRecordUnread === false){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'normal');
    }
    if(this.customerRecordUnread === true){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'bolder');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '17px');
    }
    if(this.customerRecordUnread === false){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'normal');
    }
    if(this.t301GraphicsRecordUnread === true){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'bolder');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '17px');
    }
    if(this.t301GraphicsRecordUnread === false){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'normal');
    }
    if(this.summary === true){
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', 'bolder');
    }
  }
}
