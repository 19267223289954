<mat-card class="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
<mat-card-title >
<mat-icon>home</mat-icon>{{VALIDATION}}</mat-card-title>
<mat-card-content>
<form (ngSubmit)="saveOrUpdateValidation()"   [formGroup]="validationForm">
  <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
    
     <mat-form-field class="col-lg-12 col-sm-12 col-md-12 col-xs-12" >  
      <mat-select placeholder="{{MODULE_NAME}}" formControlName="moduleName">
    <mat-option *ngFor="let valdiationModule of validationModuleList " [value]="valdiationModule.moduleName" (click)="bindValue(valdiationModule)">
    {{ valdiationModule.moduleName }}
    </mat-option>
    </mat-select>
      </mat-form-field>

      <mat-form-field class="col-lg-6" > 
        <mat-select placeholder="{{SELECT_CONTROL_NAME}}" formControlName="controlName"> 
          <mat-option *ngFor="let controlName of controlNameList" [value]="controlName"  >{{controlName}}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <!-- <mat-form-field class="col-lg-12 col-sm-12 col-md-12 col-xs-12" >
      <input matInput placeholder="{{CONTROL_NAME}}" formControlName="controlName">     
      </mat-form-field> -->
   

     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="parent" >Is Parent
       </mat-slide-toggle>
            <!-- <mat-form-field class="col-lg-6 col-md-6 col-sm-6 col-xs-6" formGroupName="parentObj">
      <mat-select placeholder="{{PARENT_NAME}}" formControlName="parentId">
      <mat-option *ngFor="let parentMenu of parentValidationList" [value]="parentMenu.parentId">
      {{parentMenu.parentName}}
      </mat-option>
      </mat-select>
      </mat-form-field> -->

     </div>

     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="required" >Is Required
       </mat-slide-toggle>
      <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="nullCheck" >Is Null
       </mat-slide-toggle>

     </div>

     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="minLength" >Is Min Length
       </mat-slide-toggle>
       <mat-form-field class="col-lg-6 col-sm-6 col-md-6 col-xs-6" >
      <input matInput placeholder="{{MIN_LENGTH}}" formControlName="minLengthValue">     
      </mat-form-field>

     </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="maxLength" >Is Max Length
       </mat-slide-toggle>
       <mat-form-field class="col-lg-6 col-sm-6 col-md-6 col-xs-6" >
      <input matInput placeholder="{{MAX_LENGTH}}" formControlName="maxLengthValue">     
      </mat-form-field>

     </div>

     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <mat-slide-toggle class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nopadding marginTop6 font paddingBottom" color="primary" style="padding-top: 15px" formControlName="email" >Is Email
       </mat-slide-toggle>
      <mat-form-field class="col-lg-6 col-sm-6 col-md-6 col-xs-12" >
      <input matInput placeholder="{{LEVEL}}" formControlName="level">     
      </mat-form-field>

     </div>

      <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 buttons marginLeft3 paddingTop15">
      <button mat-raised-button color="primary" class="mat-red" type="submit">{{SAVE_BUTTON}}</button>
      <button mat-raised-button color="primary"  type="button" (click)="cancelClick()">{{CANCEL_BUTTON}}</button>
    </div>
   </div>
</form>
</mat-card-content>
</mat-card>