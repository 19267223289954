import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {SharedModule} from './shared.module';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {MenuComponent} from './menu/menu.component';
import {MainHeaderComponent} from './header/mainheader.component';
import {SigninComponent} from './auth/signin.component';
import {SigninTokenComponent} from './auth/signintoken.component';
import {NotFoundComponent} from './notfound/notfound.component';
import {CanDeactivateGuard} from './auth/can-deactivate-guard.service';
import {MyCurrencyPipe} from './shared/my-currency.pipe';
import {NumberFormatterPipe} from './shared/numberFormatter.pipe';
/*services*/
import {CustomFieldService} from './core/custom-fields/customfields.service';
import {CommonService} from './shared/common.service';
import {FormControlService} from './core/custom-fields/form-control.service';
import {SettingsService} from './settings/settings-service';
import {HrmService} from './hrm/hrm-service';
import {RecruitmentService} from './recruitment/recruitment-service';
import {ReportsService} from './reports/reports-service';
import {AuthGuard} from './auth/auth-guard.service';
import {AuthService} from './auth/auth.service';
import {FeatureModule} from './feature-module';
import {CommonUtils} from './core/common-utils';
import {ProjectService} from './project/project.service'
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';
import {ErrorMessageAlertComponent} from './dialogBox/error/error-msg.component';
import {DateFormatPipe} from './shared/dateFormatPipe.pipe';
import {DateTimeFormatPipe} from './shared/dateTimeFormatPipe.pipe';
import {DateFormatterDirective} from './shared/dateFormatter.directive';
import {APIService} from './api/api-service';
import { ChartsModule } from 'ng2-charts';
import {AutoNumberGenerateComponent} from './dialogBox/autogenerate/auto-num-generate.component';
import {FocusDirective} from './shared/focus.directive';
import {PortalSigninComponent} from './portal/portal-signin.component';
import { environment } from '../environments/environment'; 
 import { ColorPickerModule } from 'ngx-color-picker';
import { CreateissuesComponent } from './issues/createissues/createissues.component';
import {OverlayContainer, OverlayModule} from '@angular/cdk/overlay';
import { ErpOverlayComponent } from './popup/erp-overlay.component';
import { ErpFilterComponent } from './popup/view-default-top-filter/erp-filter.component';
import {ReminderComponent} from './popup/reminder/reminder.component';
import {RescheduleComponent} from './popup/reschedule/reschedule.component';
import {IconsComponent} from './popup/icons/icons.component';
import {ChangePasswordComponent} from './header/changepassword/change-password.component';
import {ViewHistoryComponent} from './core/view-template/view-history/view-history.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {CommonModule} from '@angular/common';
import {ErpPopupService} from './core/components/Erp-popup-service';
import { CookieService } from 'ngx-cookie-service';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {ApproveLeaveComponent} from './popup/approveleave/approveleave.component';
import {CtcCalculationComponent} from './popup/ctccalculation/ctccalculation.component';
import {ViewQuickInfoComponent} from './core/view-template/view-quick-info/view-quick-info.component';
 import {ErrorHandler} from '@angular/core';
 import {ERPErrorHandler} from './shared/erp-errorhandler';
 import {TimeSheetComponent} from './popup/edittimesheet/timesheet.component';
import {ViewSearchComponent} from './popup/view-search/view-search.component';
import {ViewMainHeaderIconComponent} from './popup/view-topicon/view-topicon.component';
import { CreateTicketComponent } from './header/createTicket/createTicket.component';
import {SideMenuOverlayComponent} from './popup/side-menu/side-menu-overlay.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {HomeDashboardComponent} from './homeDashboard/homeDashboard.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AgmCoreModule } from '@agm/core';
import { ViewMapComponent } from './popup/viewmap/viewmap.component';
import {AssetTrackingService} from './assettracking/assettracking-service';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MainHeaderComponent,
    SigninComponent,
    SigninTokenComponent,
    NotFoundComponent,
    ErrorMessageAlertComponent,
    AutoNumberGenerateComponent,
    PortalSigninComponent,
    CreateissuesComponent,
    ErpOverlayComponent,
    ErpFilterComponent,
    ChangePasswordComponent,
    ViewHistoryComponent,
    ApproveLeaveComponent,
    CtcCalculationComponent,
    ReminderComponent,
    ViewQuickInfoComponent,
    TimeSheetComponent,
    ViewMainHeaderIconComponent,
    ViewSearchComponent,
    IconsComponent,
    CreateTicketComponent,
    SideMenuOverlayComponent,
    HomeDashboardComponent,
    RescheduleComponent,
    ViewMapComponent
  ],
  entryComponents: [
    ErrorMessageAlertComponent,
    AutoNumberGenerateComponent,
    CreateissuesComponent,
    ErpOverlayComponent,
    ErpFilterComponent,
    ChangePasswordComponent,
    ViewHistoryComponent,
    ApproveLeaveComponent,
    CtcCalculationComponent,
    ReminderComponent,
    ViewQuickInfoComponent,
    TimeSheetComponent,
    ViewMainHeaderIconComponent,
    ViewSearchComponent,
    CreateTicketComponent,
    IconsComponent,
	SideMenuOverlayComponent,
  RescheduleComponent,
  ViewMapComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    routing,
    BrowserAnimationsModule,
    SharedModule,
    FeatureModule,
    ChartsModule,
    ColorPickerModule,
    OverlayModule,
    CommonModule,
    AngularEditorModule,
    NgxMaterialTimepickerModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('createtemplate',reducers),
    PdfViewerModule,
    SignaturePadModule,
    AgmCoreModule.forRoot({
    apiKey : 'AIzaSyAwj1rLOx6B6SDKJph93CWFDzX8A_qQdYw'
  }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [ 
  ],
  providers: [AuthGuard,
    AuthService,
    CanDeactivateGuard,
    CommonService,
    MyCurrencyPipe,
    NumberFormatterPipe,
    CustomFieldService,
    FormControlService,
    SettingsService,
    CommonUtils,
    HrmService,
    RecruitmentService,
    APIService,
    ReportsService,
    DateFormatterDirective,
    DateFormatPipe,
    DateTimeFormatPipe,    
    AssetTrackingService,
    ProjectService,
    FocusDirective,
     ErpPopupService,
     CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useClass: ERPErrorHandler
    }
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {}