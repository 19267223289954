import { Component, OnInit,Inject } from '@angular/core';
import {environment} from '../../../environments/environment';
import {CommonUtils} from '../../core/common-utils';
import {HrmService} from '../../hrm/hrm-service';
import {FormGroup, FormControl, FormArray, FormBuilder, NgForm, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CommonService} from '../../shared/common.service';
import {Router} from '@angular/router';
import {AddAppraisalComments} from './add-appraisal-comments.component';

@Component({
  selector: 'app-applyappraisalform',
  templateUrl: './applyappraisalform.component.html'

})
export class ApplyAppraisalForm extends CommonUtils implements OnInit{
moduleName : string = environment.APPLY_APPRAISAL_FORM;
applyAppraisalForm: FormGroup;
appraisalLevelForm;
appraisalFormValues;
subAppraisalSet; 
appraisalFormObj;
jdPointsList = [];
appraisalLevel;
userObj;
userId;
status;
appraisalValueList;
showApproverButton: boolean = false;
isFinalApprover: boolean = false;
t830EmployeeDesignationLevelFormObj:FormGroup;
employeeLevelReportingString: string;
reports = [
  {value: 'Excel(XLS)', viewValue: 'Excel(XLS)'}
];
myReportForm: FormGroup;

 constructor(public dialogRef: MatDialogRef<ApplyAppraisalForm>,private router: Router,
   private commonService: CommonService, private commonUtils: CommonUtils,
  @Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder, 
  private hrmService: HrmService, private addCommentsDialog: MatDialog) {
     	super();
     this.appraisalFormObj = data.appraisalFormObj;
      if(this.commonUtils.checkNullOrUndefined(data.appraisalFormObj)
           && this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.t800_ApproverObj)
           && this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.t800_ApproverObj.employeeId)){
        
      let loggedinEmpId = parseInt(this.commonService.loggedInUserObj.employeeId);
          if(data.appraisalFormObj.t800_ApproverObj.employeeId ===  loggedinEmpId){
            this.showApproverButton = true;
          }
      }

      if(!this.commonUtils.checkNullOrUndefined(data.appraisalFormObj.t800_ApproverObj)){
          this.showApproverButton = true;
      }else{
          this.isFinalApprover = data.appraisalFormObj.t800_ApproverObj.finalApprover;
      }

     this.applyAppraisalForm = new FormGroup({
       appraisalLevelId: new FormControl(0),
       t001_UserObj: new FormGroup({
         userId: new FormControl(0),
         userName: new FormControl('')
       }),
       t828_AppraisalObj:  new FormGroup({
           appraisalId: new FormControl(0),
           appraisalName: new FormControl(''),
           subAppraisalSet: new FormArray([])
         }),
        subAppraisalSet: new FormArray([]),
        t800_ApproverObj:  new FormGroup({employeeId: new FormControl(0),userName: new FormControl('')}),
        t800_EmployeeObj:  new FormGroup({
          employeeId: new FormControl(0),userName: new FormControl(''),
          firstName: new FormControl(''),lastName: new FormControl('')
        }),
        statusText: new FormControl(''),
        employeeLevelReporting: new FormControl(''),
        oneToOneDiscussionFlag: new FormControl(false),
        oneToOneDiscussionComments: new FormControl(''),
        t001UserObj: new FormGroup({userId: new FormControl(0),userName: new FormControl('')}),
        t001_UserCreatedByObj: new FormGroup({userId: new FormControl(0),userName: new FormControl('')})
      }); 

      this.t830EmployeeDesignationLevelFormObj = new FormGroup({
        appraisalLevelId: new FormControl(0),
        t004LookupStatusObj: new FormGroup({
          lookupCode: new FormControl('APPRAISAL_STATUS'),
          lookupValue: new FormControl('PROCESSING')
        }),
        oneToOneDiscussionFlag: new FormControl(false),
        oneToOneDiscussionComments: new FormControl(''),
        t828_AppraisalObj:new FormGroup({
          appraisalId: new FormControl(0),
          appraisalName: new FormControl('')
        }),
        t001_UserObj: new FormGroup({
          userId: new FormControl(0),
          userName: new FormControl('')
        }),
        t800_EmployeeObj: new FormGroup({
          employeeId: new FormControl(0),
          userName: new FormControl(''),
          firstName: new FormControl(''),
          lastName: new FormControl('')
        }),
        t001_UserCreatedByObj: new FormGroup({
          userId: new FormControl(0),
          userName: new FormControl('')
        }),
        t800_ApproverObj: new FormGroup({
          employeeId: new FormControl(0),
          userName: new FormControl(''),
        }),
      });

      this.myReportForm = new FormGroup({
        moduleName:new FormControl(this.moduleName),
        reportName: new FormControl(this.moduleName),
        reportType:new FormControl(''),
        reportDateFormat: new FormControl(''),
        currencySymbol:new FormControl(''),
        logoPath:new FormControl(''),
        organizationId:new FormControl(''),
        portalName:new FormControl(''),
        userId:new FormControl(0),
        appraisalId:new FormControl(0),
      });
      
      this.bindAppraisalFormValues(this.appraisalFormObj);
      this.fetchAppraiserDataOnLoad();
}

downloadReportSummary(typereport) {
  this.myReportForm.get("reportType").setValue(typereport);
  this.myReportForm.get("reportDateFormat").setValue(this.commonService.loggedInUserObj.t000Organization.t004LookupDateFormatId.lookupValue);
  this.myReportForm.get("currencySymbol").setValue(this.commonService.loggedInUserObj.t000Organization.t003Currency.symbol);
  this.myReportForm.get('organizationId').setValue(this.commonService.loggedInUserObj.t000Organization.organizationId);
  this.myReportForm.get('logoPath').setValue(this.commonService.loggedInUserObj.t000Organization.logoPath);
  this.myReportForm.get('portalName').setValue(this.commonService.loggedInUserObj.t000Organization.portalName);
  this.myReportForm.get("userId").setValue(this.applyAppraisalForm.get('t001_UserObj.userId').value);
  this.myReportForm.get("appraisalId").setValue(this.applyAppraisalForm.get('t828_AppraisalObj.appraisalId').value);
  this.commonService.showProgressBar();
  this.commonService.downloadReportSummary(this.myReportForm.value).
  subscribe(
  data =>{
    if (this.commonUtils.checkNullOrUndefined(data) && data != "No Records Found"){
      if(typereport==='Excel(XLS)')
          window.open(this.commonService.SERVICE_URL + 'erpassets/'+this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase()+'/Reports/EXCEL/'+ data, '_blank');
      }
      this.commonService.showERPMessage(data);
      this.commonService.hideProgressBar();
  },error => {
      console.log(error);
      this.commonService.showERPErrorMessage(error);
      this.commonService.hideProgressBar();
  });
}

bindAppraisalFormValues(appraisalFormObj): void{ 
  // bind all sub - appraisal set into subAppraisalSet in the applyAppraisalForm 
    if(this.commonUtils.checkNullOrUndefined(appraisalFormObj)){
        this.applyAppraisalForm.get('employeeLevelReporting').setValue(appraisalFormObj.employeeLevelReporting);
        this.applyAppraisalForm.get('appraisalLevelId').setValue(appraisalFormObj.appraisalLevelId);
        //set One to One Flag and Comments - Monisha
        this.applyAppraisalForm.get('oneToOneDiscussionComments').setValue(appraisalFormObj.oneToOneDiscussionComments);
        this.applyAppraisalForm.get('oneToOneDiscussionFlag').setValue(appraisalFormObj.oneToOneDiscussionFlag);
        this.employeeLevelReportingString = appraisalFormObj.employeeLevelReporting;
              
        if(this.commonUtils.checkNullOrUndefined(appraisalFormObj.t828_AppraisalObj)){
              let t828_AppraisalObj: FormGroup = this.applyAppraisalForm.get('t828_AppraisalObj') as FormGroup;
              t828_AppraisalObj.patchValue(appraisalFormObj.t828_AppraisalObj);
         }

        if(this.commonUtils.checkNullOrUndefined(appraisalFormObj.t800_ApproverObj)){
          let t800_ApproverObj: FormGroup = this.applyAppraisalForm.get('t800_ApproverObj') as FormGroup;
          t800_ApproverObj.patchValue(appraisalFormObj.t800_ApproverObj);
        }

          if( this.commonUtils.checkNullOrUndefined(appraisalFormObj.t001_UserObj)){
              let t001_UserObj: FormGroup = this.applyAppraisalForm.get('t001_UserObj') as FormGroup;
              t001_UserObj.patchValue(appraisalFormObj.t001_UserObj);
         }
        if( this.commonUtils.checkNullOrUndefined(appraisalFormObj.t828_AppraisalObj.subAppraisalSet)){
           let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
           for(var loopCount=0;loopCount < appraisalFormObj.t828_AppraisalObj.subAppraisalSet.length; loopCount++){
             subAppraisalArray.push(this.formSubAppraisalList());
           }
          subAppraisalArray.patchValue(appraisalFormObj.t828_AppraisalObj.subAppraisalSet);
          this.setSubAppraisalForm(appraisalFormObj.t828_AppraisalObj.subAppraisalSet);          
        }
    }
}
  
  ngOnInit()
  {
    
  }

  fetchAppraiserDataOnLoad(){
    let appraisalForm: FormGroup = new FormGroup({
      t001_UserObj: new FormGroup({
        userId: new FormControl(this.applyAppraisalForm.get('t001_UserObj.userId').value)
      }),
      t828_AppraisalObj: new FormGroup({
        appraisalId: new FormControl(this.applyAppraisalForm.get('t828_AppraisalObj.appraisalId').value)
      })
    });
    this.hrmService.fetchAppraisalForm(appraisalForm.value).subscribe(
    data => {
      if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
        this.appraisalValueList = data;
        let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
        for(var loop=0; loop<data.length; loop++){
          this.commonUtils.checkNullOrUndefined(subAppraisalArray.at(loop))
            subAppraisalArray.at(loop).get('apprailsalForm').patchValue(data[loop]);
        }
        this.commonService.hideProgressBar();
      }
    },
    error => {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
    });
  }

  setSubAppraisalForm(data){
    let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
           for(var loopCount=0;loopCount < subAppraisalArray.length; loopCount++){
               let appraisalForm = subAppraisalArray.at(loopCount).get('apprailsalForm') as FormGroup;
                appraisalForm.get('t829_SubAppraisalObj.subAppraisalId').setValue(subAppraisalArray.at(loopCount).get('subAppraisalId').value);
                appraisalForm.get('t828_AppraisalObj').patchValue(this.applyAppraisalForm.get('t828_AppraisalObj').value);
                appraisalForm.get('t001_UserObj').patchValue(this.applyAppraisalForm.get('t001_UserObj').value);
        /******** set the appraiser's id next to next level - starts - monisha ********/
        let employeeApproversList = this.employeeLevelReportingString.split(",");
        if(this.commonUtils.checkNullOrUndefined(employeeApproversList) && employeeApproversList.length > 0){
          for(var firstLoop=0; firstLoop < employeeApproversList.length; firstLoop++){
            let eachArray = employeeApproversList[firstLoop].split("::");
            if(firstLoop === 0 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0  && eachArray[0] != ''){              
              appraisalForm.get('selfAppraiserObj.employeeId').setValue(eachArray[0]);
              this.applyAppraisalForm.get('t800_EmployeeObj.employeeId').setValue(eachArray[0]);
              this.applyAppraisalForm.get('t800_EmployeeObj.userName').setValue(eachArray[2]);
              let nameArrayList = eachArray[1].split(" ");
              if(this.commonUtils.checkNullOrUndefined(nameArrayList) && nameArrayList.length > 0){
                this.applyAppraisalForm.get('t800_EmployeeObj.firstName').setValue(nameArrayList[0]);
                this.applyAppraisalForm.get('t800_EmployeeObj.lastName').setValue(nameArrayList[1]);
              } 
            }            
            else if(firstLoop === 1 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0 && eachArray[0] != ''){
              appraisalForm.get('appraiser1Obj.employeeId').setValue(eachArray[0]);
            }
            else if(firstLoop === 2 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0 && eachArray[0] != ''){
              appraisalForm.get('appraiser2Obj.employeeId').setValue(eachArray[0]);
            }
            else if(firstLoop === 3 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0 && eachArray[0] != ''){
              appraisalForm.get('appraiser3Obj.employeeId').setValue(eachArray[0]);
            }
            else if(firstLoop === 4 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0 && eachArray[0] != ''){
              appraisalForm.get('appraiser4Obj.employeeId').setValue(eachArray[0]);
            }
            else if(firstLoop === 5 && this.commonUtils.checkNullOrUndefined(eachArray) && eachArray.length > 0 && eachArray[0] != ''){
              appraisalForm.get('appraiser5Obj.employeeId').setValue(eachArray[0]);
            }
          }
        }
        /******** set the appraiser's id next to next level - ends - monisha ********/ 
  }
}

formSubAppraisalList(): FormGroup{
  return this.formBuilder.group({
    subAppraisalId: new FormControl(0),
    subAppraisalName: new FormControl(''),
    fetchFlag: new FormControl(true),
    status: new FormControl(false),
    apprailsalForm: new FormGroup({
          appraisalFormId: new FormControl(0),
          t001_UserObj: new FormGroup({userId: new FormControl(0), userName: new FormControl('')}),
          t828_AppraisalObj:  new FormGroup({appraisalId: new FormControl(0)}),
          t829_SubAppraisalObj:  new FormGroup({subAppraisalId: new FormControl(0)}),
          selfAppraiserObj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          selfAppraiselPoint: new FormControl(''),
          selfAppraiserComments: new FormControl(''),
          appraiser1Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          appraiser1Point: new FormControl(''),
          appraiser1Comments: new FormControl(''),
          appraiser2Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          appraiser2Point: new FormControl(''),
          appraiser2Comments: new FormControl(''),
          appraiser3Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          appraiser3Point: new FormControl(''),
          appraiser3Comments: new FormControl(''),
          appraiser4Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          appraiser4Point: new FormControl(''),
          appraiser4Comments: new FormControl(''),
          appraiser5Obj: new FormGroup({employeeId: new FormControl(0),userName: new FormControl(''),
                            firstName: new FormControl(''),lastName: new FormControl('')}),
          appraiser5Point: new FormControl(''),
          appraiser5Comments: new FormControl(''),
          t001_UserCreatedByObj:  new FormGroup({userId: new FormControl(0)}),
          t001_UserUpdatedByObj:  new FormGroup({userId: new FormControl(0)}),
          fileUpload : new FormControl(''),
         uploadedFileArray:this.formBuilder.array([])
    })
  });
}
  createUploadFileList(name,size,addedNewly): FormGroup {
  return this.formBuilder.group({
    name: new FormControl(name),
    size: new FormControl(size),
    addedNewly: new FormControl(addedNewly)
   
   });
}
 saveAppraisalForm(): void 
 {
    let appraisalFormList: any[] = [];
    let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
  let currentUserObj = this.commonService.loggedInUserObj;
   for(var outerloopcount = 0; outerloopcount<subAppraisalArray.length; outerloopcount++){
           let  subAppraisalArrayIndexObj = subAppraisalArray.at(outerloopcount).get('apprailsalForm').value ;
          if(subAppraisalArrayIndexObj.appraisalFormId === 0){
                subAppraisalArrayIndexObj.t001_UserCreatedByObj.userId = currentUserObj.userId;
                subAppraisalArrayIndexObj.t001_UserUpdatedByObj.userId = currentUserObj.userId;
                appraisalFormList[outerloopcount] = subAppraisalArrayIndexObj;
              }else if(subAppraisalArrayIndexObj.appraisalFormId != 0){
                subAppraisalArrayIndexObj.t001_UserUpdatedByObj.userId = currentUserObj.userId;
                appraisalFormList[outerloopcount] = subAppraisalArrayIndexObj;
              }
      }
   
    this.commonService.showProgressBar();
     this.hrmService.saveAppraisalForm(appraisalFormList).subscribe(
      data => {
        /********* Udpate the status in T830 Employee Designation Level - Starts - Monisha *********/
        this.statusUpdateInAppraisalEmployeeAssociation('PROCESSING');
        /********* Udpate the status in T830 Employee Designation Level - Ends - Monisha *********/
        this.commonService.showERPMessage('Appraisal Form has been saved successfully');
          this.commonService.hideProgressBar();
        }, error => {
           this.commonService.hideProgressBar();
      }); 
      this.dialogRef.close(true);
   }

  /********* Udpate the status in T830 Employee Designation Level - Starts - Monisha *********/
  statusUpdateInAppraisalEmployeeAssociation(statusValue){
    this.commonService.showProgressBar();

    this.t830EmployeeDesignationLevelFormObj.get('appraisalLevelId').setValue(this.applyAppraisalForm.get('appraisalLevelId').value);
    this.t830EmployeeDesignationLevelFormObj.get('oneToOneDiscussionFlag').setValue(this.applyAppraisalForm.get('oneToOneDiscussionFlag').value);
    this.t830EmployeeDesignationLevelFormObj.get('oneToOneDiscussionComments').setValue(this.applyAppraisalForm.get('oneToOneDiscussionComments').value);
    this.t830EmployeeDesignationLevelFormObj.get('t004LookupStatusObj.lookupValue').setValue(statusValue);
    this.t830EmployeeDesignationLevelFormObj.get('t828_AppraisalObj').patchValue(this.applyAppraisalForm.get('t828_AppraisalObj').value);
    this.t830EmployeeDesignationLevelFormObj.get('t800_ApproverObj').patchValue(this.applyAppraisalForm.get('t800_ApproverObj').value);
    this.t830EmployeeDesignationLevelFormObj.get('t001_UserCreatedByObj.userId').setValue(this.commonService.loggedInUserObj.userId);
    this.t830EmployeeDesignationLevelFormObj.get('t001_UserCreatedByObj.userName').setValue(this.commonService.loggedInUserObj.userName);
    this.t830EmployeeDesignationLevelFormObj.get('t001_UserObj').patchValue(this.applyAppraisalForm.get('t001_UserObj').value);
    this.t830EmployeeDesignationLevelFormObj.get('t800_EmployeeObj').setValue(this.applyAppraisalForm.get('t800_EmployeeObj').value);

    this.hrmService.saveOrUpdateStatusEmployeeAppraisalAssociation(this.t830EmployeeDesignationLevelFormObj.value).subscribe(
    data => {
      this.commonService.showERPMessage('Appraisal status has been updated successfully');
      this.commonService.hideProgressBar();
    },error => {
      this.commonService.hideProgressBar();
    });
    this.dialogRef.close(true);
  } 
  /********* Udpate the status in T830 Employee Designation Level - Ends - Monisha *********/

fetchAppraisalFormData(appraisalIndexObj): Promise<any>{
  let appraisalIndexObjValue = appraisalIndexObj.value;
  let requestFormData = appraisalIndexObjValue.apprailsalForm;
   return new Promise(resolve=>
    this.hrmService.fetchAppraisalForm(requestFormData).subscribe(
      data => {
        if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
             this.appraisalValueList = data;
             this.commonService.hideProgressBar();
             resolve(data); 
          }
       },
       error => {
         this.commonService.hideProgressBar();
         this.commonService.showERPErrorMessage(error);
       })
      );
  }
  // test 

 openPopupToReviewOrAddComments(appraisalIndex,appraisalIndexObj): void{
   if(this.commonUtils.checkNullOrUndefined(appraisalIndexObj) && 
           appraisalIndexObj.value.fetchFlag === true){
    let promise= this.fetchAppraisalFormData(appraisalIndexObj);
    promise.then(data=>
    { 
      if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
        
          let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
              subAppraisalArray.at(appraisalIndex).get('apprailsalForm').patchValue(data[0]);

          const dialogRef = this.addCommentsDialog.open(AddAppraisalComments,
            {
              width: '95%',
              height: '80%',
              data: {appraisalFormObj: this.applyAppraisalForm.value, subAppraisalId: appraisalIndexObj.value.subAppraisalId,
                 subAppraisalName: appraisalIndexObj.value.subAppraisalName, appraisalIndexObj: data[0]}
            });
            dialogRef.afterClosed().subscribe(result => {
              if(this.commonUtils.checkNullOrUndefined(result)){
                let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
                subAppraisalArray.at(appraisalIndex).get('apprailsalForm').patchValue(result);
                subAppraisalArray.at(appraisalIndex).get('fetchFlag').setValue(false);
                let fileUploadArray=subAppraisalArray.at(appraisalIndex).get('apprailsalForm.uploadedFileArray')as FormArray;
                if(this.commonUtils.checkNullOrUndefined(result.uploadedFileArray) && result.uploadedFileArray.length > 0){
                  for(var loop=0;loop < result.uploadedFileArray.length;loop++){
                    (subAppraisalArray.at(appraisalIndex).get('apprailsalForm.uploadedFileArray')as FormArray).push(this.createUploadFileList(result.uploadedFileArray[loop].name,result.uploadedFileArray[loop].size,result.uploadedFileArray[loop].addedNewly))
                  } 
                }  
              }    
            });
         }
      });
    }else{
          const dialogRef = this.addCommentsDialog.open(AddAppraisalComments,
            {
              width: '95%',
              height: '60%',
              data: {appraisalFormObj: this.applyAppraisalForm.value, subAppraisalId: appraisalIndexObj.value.subAppraisalId,
                 subAppraisalName: appraisalIndexObj.value.subAppraisalName, appraisalIndexObj: appraisalIndexObj.value.apprailsalForm}
            });
            dialogRef.afterClosed().subscribe(result => {
               if(this.commonUtils.checkNullOrUndefined(result)){
                       let subAppraisalArray = this.applyAppraisalForm.get('subAppraisalSet') as FormArray;
                      subAppraisalArray.at(appraisalIndex).get('apprailsalForm').patchValue(result);
                      subAppraisalArray.at(appraisalIndex).get('fetchFlag').setValue(false);
                      (subAppraisalArray.at(appraisalIndex).get('apprailsalForm.uploadedFileArray')as FormArray).clear();
                      for(var loop=0;loop < result.uploadedFileArray.length;loop++){
                        (subAppraisalArray.at(appraisalIndex).get('apprailsalForm.uploadedFileArray')as FormArray).push(this.createUploadFileList(result.uploadedFileArray[loop].name,result.uploadedFileArray[loop].size,result.uploadedFileArray[loop].addedNewly))
                      }
                 }    
              });
    }
  }

  approveToNextLevelAppraiser(){
	// Even if the Approve button is directly clicked, the save will be called and then the approve process happens
    this.saveAppraisalForm();
    if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj.managerId)){
       let nextApproverId = parseInt(this.commonService.loggedInUserObj.managerId);
         if(nextApproverId != 0){
            this.applyAppraisalForm.get("t800_ApproverObj.employeeId").setValue(nextApproverId);
            this.applyAppraisalForm.get("t001_UserCreatedByObj.userId").setValue(parseInt(this.commonService.loggedInUserObj.userId));
            this.applyAppraisalForm.get("t001_UserCreatedByObj.userName").setValue(this.commonService.loggedInUserObj.userName);
            this.applyAppraisalForm.get("statusText").setValue("UPDATE_APPROVER_EMP_ID");
            this.hrmService.saveOrUpdateEmployeeAppraisalLevel(this.applyAppraisalForm.value).subscribe(
                  data => {
                            this.commonService.showERPMessage("Appraisal has been moved to next level appraiser successfully");
                            this.cancelClicked();
                  },
                  error => {
                                console.log(error)
                  }
              );
       }
     }
  }
  cancelClicked(){
      this.dialogRef.close(this.applyAppraisalForm.value)
  }
 
}

