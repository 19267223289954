import { Component,OnInit, Input ,Inject,Output,HostListener} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../../core/components/custom-token';
import {ErpOverlayRef} from '../../core/components/ErpOverlayRef';
import{EventEmitter} from '@angular/core';
import {CommonService} from '../../shared/common.service';
import {SettingsService} from '../../settings/settings-service';
import {FormControl, FormGroup, FormArray, NgForm, Validators, AbstractControl, FormBuilder} from '@angular/forms';
import {CommonUtils} from '../../core/common-utils';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import {switchMap, distinctUntilChanged, debounceTime,  catchError, map, tap ,startWith} from 'rxjs/operators';
@Component({
  selector: 'erp-filter-overlay',
  templateUrl: './view-search.component.html'
   
})
export class ViewSearchComponent extends CommonUtils { 
 myForm: FormGroup;
searchPOList: any=[];
searchBillList: any=[];
searchRBIllList: any=[];
searchExpenseList: any=[];
searchRExpenseList: any=[];
searchPayMadeList: any=[];
searchVCList: any=[];
searchSOList: any=[];
searchEstimateList: any=[];
searchArtworkList: any=[];
searchPSList: any=[];
searchPackageList: any=[];
searchInvoiceList: any=[];
searchRInvoiceList: any=[];
searchPayReceiveList: any=[];
searchCNList: any=[];
searchItemList: any=[];
searchCategoryList: any=[];
searchPLList: any=[];
searchIGList: any=[];
searchContactList: any=[];
searchSPList: any=[];
isEmptySearch=false;
searchName:string;
itemModuleName:string;
searchLabel = true;




 keyboardEvent: any;
 keyCode: number;
 @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
 
    
    this.keyboardEvent = event;
    this.keyCode = event.keyCode;
    

  }
  
  constructor(
      public dialogRef: ErpOverlayRef,private commonService: CommonService,
      private settingsService: SettingsService,private commonUtils: CommonUtils,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) { 
    super();
    
   this.searchName=data.searchName;

   this.myForm=new FormGroup({
    searchName:new FormControl(''),
    
  });

  
  }

ngOnInit()
{
 
  if(this.commonUtils.checkNullOrUndefined(this.searchName))
    {
       this.myForm.get('searchName').setValue( this.searchName);
       this.searchLabel=true;
       this.callfetchFunction();
    }
    
}



moduleNameforPO;
moduleNameforBill;
moduleNameforRBill;
moduleNameforExp;
moduleNameforRExp;
moduleNameforPayM;
moduleNameforVC;
moduleNameforSO;
moduleNameforEst;
moduleNameforArt;
moduleNameforPS;
moduleNameforPack;
moduleNameforInv;
moduleNameforRInv;
moduleNameforPayR
moduleNameforCN;
moduleNameforItem;
moduleNameforCategory;
moduleNameforPL;
moduleNameforIG;
moduleNameforContact;
moduleNameforSP;
moduleNameforItems;

/*searchOnKeyPress(event)
{
   if(event.keyCode === 13)
  {
    this.searchName=event.target.value;
    if(this.commonUtils.checkNullOrUndefined(this.searchName))
    {
       this.myForm.get('searchName').setValue( this.searchName);
       this.callfetchFunction();
    }
   else
   {
     this.commonService.showERPMessage("Please enter the input for search");
   }
  }
 
}*/

callfetchFunction()
{
this.searchPOList=[];
this.searchBillList=[];
this.searchRBIllList=[];
this.searchExpenseList=[];
this.searchRExpenseList=[];
this.searchPayMadeList=[];
this.searchVCList=[];
this.searchSOList=[];
this.searchEstimateList=[];
this.searchArtworkList=[];
this.searchPSList=[];
this.searchPackageList=[];
this.searchInvoiceList=[];
this.searchRInvoiceList=[];
this.searchPayReceiveList=[];
this.searchCNList=[];
this.searchItemList=[];
this.searchCategoryList=[];
this.searchPLList=[];
this.searchIGList=[];
this.searchContactList=[];
this.searchSPList=[];

   this.commonService.fetchSearchHeader(this.myForm.value).
   subscribe(       
    data =>
    {
   
     if(this.commonUtils.checkNullOrUndefined(data) && data.length!=0)
     {
      for(var loop=0;loop<data.length;loop++)
      {
        if(data[loop].moduleName==='TS_VIEW_PO')
        {
          
             this.moduleNameforPO= data[loop].moduleName;
             this.searchPOList.push(data[loop]);
           
        }
        else if(data[loop].moduleName==='TS_VIEW_BILL')
        {
          this.moduleNameforBill= data[loop].moduleName;
           this.searchBillList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_R_BILL')
        {
          this.moduleNameforRBill= data[loop].moduleName;
           this.searchRBIllList.push(data[loop]);
         }
        else if(data[loop].moduleName==='TS_VIEW_EXPENSE')
        {
          this.moduleNameforExp= data[loop].moduleName;
           this.searchExpenseList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_R_EXPENSE')
        {
          this.moduleNameforRExp= data[loop].moduleName;
           this.searchRExpenseList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_PAYMENT_MADE')
        {
          this.moduleNameforPayM= data[loop].moduleName;
           this.searchPayMadeList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_VENDOR_CREDIT')
        {
          this.moduleNameforVC= data[loop].moduleName;
           this.searchVCList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_SALESORDER')
        {
          this.moduleNameforSO= data[loop].moduleName;
           this.searchSOList.push(data[loop]);

        }
        else if(data[loop].moduleName==='TS_VIEW_ESTIMATE')
        {
          this.moduleNameforEst= data[loop].moduleName
           this.searchEstimateList.push(data[loop]);

        }
        else if(data[loop].moduleName==='TS_VIEW_ARTWORK')
        {
          this.moduleNameforArt= data[loop].moduleName;
           this.searchArtworkList.push(data[loop]);
        
        }
        else if(data[loop].moduleName==='TS_VIEW_PRODUCTION_SLIP')
        {
        
          this.moduleNameforPS= data[loop].moduleName;
           this.searchPSList.push(data[loop]);
        
        }
        else if(data[loop].moduleName==='TS_VIEW_PACKAGES')
        {
          this.moduleNameforPack= data[loop].moduleName;
           this.searchPackageList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_INVOICE')
        {
          this.moduleNameforInv= data[loop].moduleName;
           this.searchInvoiceList.push(data[loop]);  
        }
        else if(data[loop].moduleName==='TS_VIEW_R_INVOICE')
        {
          this.moduleNameforRInv= data[loop].moduleName;
           this.searchRInvoiceList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_PAYMENT_RECIEVED')
        {
          this.moduleNameforPayR= data[loop].moduleName;
           this.searchPayReceiveList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_CREDIT_NOTES')
        {
          this.moduleNameforCN= data[loop].moduleName;
           this.searchCNList.push(data[loop]);
        }
        else if(data[loop].moduleName==='VIEW_ITEM' || data[loop].moduleName==='VIEW_ITEMS')
        {
          this.moduleNameforItem= 'TS_VIEW_ITEM';
          this.itemModuleName = data[loop].moduleName;
           this.searchItemList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_CATEGORY')
        {
            this.moduleNameforCategory= data[loop].moduleName;
             this.searchCategoryList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_PRICE_LIST')
        {
           this.moduleNameforPL= data[loop].moduleName;
            this.searchPLList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_ITEMS_GROUP')
        {
          this.moduleNameforIG= data[loop].moduleName;
           this.searchIGList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_CONTACT')
        {
          this.moduleNameforContact= data[loop].moduleName;
           this.searchContactList.push(data[loop]);
        }
        else if(data[loop].moduleName==='TS_VIEW_SALES_PERSON')
        {
          this.moduleNameforSP= data[loop].moduleName;
           this.searchSPList.push(data[loop]);
        }
      }
       this.searchLabel=false;
       this.isEmptySearch=false;
    }
    else
    {
      this.searchLabel=false;
       this.isEmptySearch=true;
    }
         
    }, error => {

    });

  }
  closeDialog(){
   this.dialogRef.dialogClose(true);
  }
   viewTopSearch(emittedValue:any){

     if(emittedValue.action === 'CLOSE_TOP_SEARCH')
     {
       this.closeDialog();
     }
   }
}
