/**Author -esales -9014  Rajesh Subramanian **/
import {Component,OnInit,NgZone,ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import{CommonService} from '../shared/common.service';
import {CommonUtils} from '../core/common-utils';
import {HrmService} from './../hrm/hrm-service';
import * as am4core from "@amcharts/amcharts4/core";
// Importing themes
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {FormControl, FormGroup,FormBuilder} from '@angular/forms';
import{ViewHistoryComponent} from '../core/view-template/view-history/view-history.component';
import {environment} from '../../environments/environment';
import { DateFormatPipe } from '../shared/dateFormatPipe.pipe';
import { Pipe, PipeTransform } from '@angular/core';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-homeDashboard',
  templateUrl: './homeDashboard.component.html'
})
export class HomeDashboardComponent extends CommonUtils implements OnInit{
  empBirthdayList = [];
  empAnniversaryList = [];
  attendanceData = {
    "absenteesCount": 0,
    "lateInCount": 0,
    "totalEmps": 0,
    "onTimeCount": 0
  };
  empAttendanceFormObj: FormGroup;
  moduleName:string="HOME_NEW_DASHBOARD";
  loginIn: boolean = true;
  loginOut: boolean = true;
  fromDate: Date;
  toDate: Date;
  photoSrcPath: string="";
  currentDate: Date = new Date();
  myShift;
  // myAttendanceObj;
  myAttendanceHistoryList;
  roleName;
  myShiftStartTime = new Date();
  myShiftEndTime = new Date();
  errorimageSourcePath;
  t838HRMAnnouncementsFormGroup: FormGroup;
  announcementsList = [];
  pdfSource:string;
  id: string='1';
  hrPolicyfileExistFlag: boolean = false;
  loginButton = false;
  loginTime = new Date(new Date().setHours(0, 0, 0, 0));
  logoutTime=new Date(new Date().setHours(0, 0, 0, 0));
  ngOnInit(){
    this.commonService.checkUserLogin('Pages');
   let subscriptionObj= this.commonService.loggesInUserObjSubject.subscribe(
             result=>{
               if(result==='LOGIN_AVAILABLE')
               {
                  this.pageLoad();
               } 
         });

      if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj))
          this.pageLoad();
  }

  constructor(private router: Router,private zone: NgZone,private commonService:CommonService,   private dialog: MatDialog, 
    public commonUtils: CommonUtils,private hrmService: HrmService,
    private datePipe: DateFormatPipe) {
    super();
   
         
      this.empAttendanceFormObj = new FormGroup({
        attendanceId: new FormControl(),
        userObj: new FormGroup({
          userId: new FormControl(),
        }),
          loggedInTime:new FormControl(),
          loggedOutTime:new FormControl(),
          searchName: new FormControl(''),
          createdTs: new FormControl(''),
          presentFlag: new FormControl(),
          locCord:new FormControl(''),
          active: new FormControl(),
          loginInTimeStamp: new FormControl(),
          extraHours: new FormControl(),
          totalHrs: new FormControl(),
          hoursToBeWorked: new FormControl(),
          shiftIdObj: new FormGroup({
            shiftAllocationId: new FormControl()
          }),
          workedHours: new FormControl(),
          logoutFlag: new FormControl()
      });

      this.t838HRMAnnouncementsFormGroup = new FormGroup({
        todayDate: new FormControl(new Date()),
        paginationLoadFlag: new FormControl('N')
      });
  }

  pageLoad(){
        if(this.commonUtils.checkNullOrUndefined(this.commonService.loggedInUserObj)){
            let portalName = this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase();
            this.roleName = this.commonService.loggedInUserObj.roleName.toUpperCase();
            this.photoSrcPath = this.commonService.SERVICE_URL+'erpassets/'+portalName+'/NEW_EMPLOYEE/';
               this.pdfSource = this.commonService.SERVICE_URL+"erpassets/"+this.commonService.loggedInUserObj.t000Organization.portalName.toUpperCase()
                      +"/HR_POLICY/HR_POLICY_FILE.pdf";
         }

  this.errorimageSourcePath=environment.NO_IMAGE_FOR_ORG_PROFILE;
  this.fetchValidationAttendance();// set validation for buttons
    this.fetchCurrentMonthBasisEmployeeList('BIRTHDAY');
    this.fetchCurrentMonthBasisEmployeeList('ANNIVERSARY');
    this.fetchAttendance('Today','1');
    this.fetchShiftDetails();
    this.fetchMyAttendance('Today');//to set value in myAttendanceObj in commonservice
    this.fetchMyAttendance('All');//to fetch detail attendance list to show history
    this.fetchAnnouncements(); 
    this.setCurrentLocation();
    this.hrPolicyfileExistFlag = this.commonService.doesFileExist(this.pdfSource);
  }
   

  /************** Fetch Announcements - starts - Monisha **************/
  fetchAnnouncements(){
    this.commonService.showProgressBar();
    this.hrmService.fetchAnnouncements(this.t838HRMAnnouncementsFormGroup.value).subscribe(
    data => {
      if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
        this.announcementsList = data;
      }
      this.commonService.hideProgressBar();
    },
    error => {
      this.commonService.hideProgressBar();
      this.commonService.showERPErrorMessage(error);
    });
  }
  /************** Fetch Announcements - ends - Monisha **************/
  
  /************** View HR Policy PDF - starts - Monisha **************/
  viewUploadedPDF(){
    const dialogRef = this.dialog.open(ViewHistoryComponent, {
      width: '95%',
      height:'95%',
      data:{
          moduleName: 'NEW_HR_POLICY',
          labelName: 'View Uploaded PDF',
          pdfSource: this.pdfSource,
          showAgreeTermsFlag: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  /************** View HR Policy PDF - ends - Monisha **************/

  fetchMyAttendance(value: string){ 
    this.empAttendanceFormObj.reset();
    this.empAttendanceFormObj.get('searchName').setValue(value);
       let userId = this.commonService.loggedInUserObj.userId;
       this.empAttendanceFormObj.get('userObj.userId').setValue(userId);
        if(value === 'Today')
         this.empAttendanceFormObj.get('createdTs').setValue(new Date(this.datePipe.transform(new Date(), 'dd-MM-yyyy')));
        this.hrmService.fetchEmployeeAttendance(this.empAttendanceFormObj.value).subscribe(
         data => { 
             if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0 ){ 
                if(value === 'Today'){
                  //  this.loginIn = !(data[0].presentFlag);
                   this.commonService.myAttendanceObj = data[0];
                  //  if(this.commonUtils.checkNullOrUndefined(data[0].loggedOutTime))
                  //    this.loginOut = false;
                }
              if(value === 'All'){
                 this.myAttendanceHistoryList = data;
               }
               
             }

         });
  }
  
  fetchValidationAttendance(){
    this.empAttendanceFormObj.get('searchName').setValue("Today");
    this.empAttendanceFormObj.get('userObj.userId').setValue(this.commonService.loggedInUserObj.userId);
    this.empAttendanceFormObj.get('createdTs').setValue(new Date(this.datePipe.transform(new Date(), 'dd-MM-yyyy')));
    this.hrmService.fetchEmployeeAttendance(this.empAttendanceFormObj.value).subscribe(
      data => {        
        if (this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
          if (data[0].presentFlag && !data[0].active){
             this.loginButton = false; 
             this.logoutTime = data[0].loggedOutTime;
             this.loginTime = new Date(new Date().setHours(0, 0, 0, 0));
          } else if (!data[0].presentFlag && !data[0].active) {
            this.loginButton = false; 
            this.logoutTime = new Date(new Date().setHours(0, 0, 0, 0));
            this.loginTime = new Date(new Date().setHours(0, 0, 0, 0));
          } else {
            this.loginButton = true;
            this.loginTime = data[0].loggedInTime;
            this.logoutTime = new Date(new Date().setHours(0, 0, 0, 0));
          }
        }
      });
  }
  fetchAttendance(value: string, id: string){
    this.id = id;
    this.currentDate = new Date();
    this.fromDate = new Date();
    this.toDate = new Date();
    if(value === 'Today'){
      this.fromDate.setDate( this.currentDate.getDate());
      this.toDate.setDate( this.currentDate.getDate());
    }
    else if(value === 'Yesterday'){
      this.fromDate.setDate( this.currentDate.getDate() - 1 );
      this.toDate.setDate( this.currentDate.getDate() - 1 );      
    }
    else if(value === 'Last Week'){
      this.fromDate.setDate( this.currentDate.getDate() - 7);
      this.toDate.setDate( this.currentDate.getDate() );
    }
    else if(value === 'Last Month'){
      this.fromDate.setDate( this.currentDate.getDate() - 30);
      this.toDate.setDate( this.currentDate.getDate() );
    }
    this.fetchDashBoardAttendanceDetails();
  }
  
  fetchShiftDetails(){
    let shiftObj: FormGroup = new FormGroup({
        shiftMasterId: new FormControl(this.commonService.loggedInUserObj.shiftAllocatedId)
    });
    this.hrmService.fetchShiftMaster(shiftObj.value).subscribe(
      data => {
          
          if(this.commonUtils.checkNullOrUndefined(data) && data.length > 0){
            this.myShift = data[0]; 
          }
      },error => {

      }
    );
  }

  loginProcess(value: string){
    let checkObj;
    let employeeFormObj = new FormGroup({
      attendanceId: new FormControl(),
      searchName: new FormControl(),
      createdTs: new FormControl(),
      userObj: new FormGroup({
        userId: new FormControl()
      })
    });  
    let userId = this.commonService.loggedInUserObj.userId;
    //if myAttendanceObj is not null- fetch using attendanceId, else fetch today's record for the UserId
    if (this.commonUtils.checkNullOrUndefined(this.commonService.myAttendanceObj)){
      employeeFormObj.get('attendanceId').setValue(this.commonService.myAttendanceObj.attendanceId);
    } else {
      employeeFormObj.get('createdTs').setValue(new Date(this.datePipe.transform(new Date(), 'dd-MM-yyyy')));
      employeeFormObj.get('searchName').setValue('Today');
      employeeFormObj.get('userObj.userId').setValue(userId);
    }
    //Fetch Shift exists for the employee during Login and logout click, if the user login in two browser
    this.hrmService.fetchEmployeeAttendance(employeeFormObj.value).subscribe(
      data => { 
        if (this.commonUtils.checkNullOrUndefined(data)){
          checkObj = data[0];
          this.commonService.myAttendanceObj = data[0];
          this.empAttendanceFormObj.get('userObj.userId').setValue(userId);
          if(value === 'LOGIN-IN'){
            
            if(this.commonUtils.checkNullOrUndefined(checkObj) && checkObj.attendanceId != 0){
              //if user already punched in show msg, else if presentFlag = true - flag and logout time update else update the record with login time
              if (checkObj.presentFlag && checkObj.active){
                this.commonService.showERPMessage("You are already Punched in..");
                this.loginButton = true;
                this.commonService.myAttendanceObj = "";
                this.fetchMyAttendance('Today');
              } else {
                if (this.commonService.myAttendanceObj.presentFlag){
                  this.empAttendanceFormObj.patchValue(this.commonService.myAttendanceObj);
                  this.empAttendanceFormObj.get('active').setValue(true);
                  this.empAttendanceFormObj.get('loginInTimeStamp').setValue(new Date());
                } else {
                  this.empAttendanceFormObj.patchValue(this.commonService.myAttendanceObj);
                  this.empAttendanceFormObj.get('attendanceId').setValue(this.commonService.myAttendanceObj.attendanceId);
                  this.empAttendanceFormObj.get('createdTs').setValue(new Date(this.datePipe.transform(new Date(), 'dd-MM-yyyy'))); 
                  this.empAttendanceFormObj.get('loggedInTime').setValue(new Date());
                  this.empAttendanceFormObj.get('presentFlag').setValue(true);
                  this.empAttendanceFormObj.get('active').setValue(true);
                }
                this.hrmService.saveOrUpdateEmployeeAttendance(this.empAttendanceFormObj.value).subscribe(
                  data => {
                    if (this.commonUtils.checkNullOrUndefined(data) && data.erpmessage == 'Success'){
                      this.commonService.myAttendanceObj = data;
                      this.loginButton = true;
                      this.loginTime = new Date();
                      this.logoutTime = new Date(new Date().setHours(0, 0, 0, 0));
                    }
                  },error => {
                    // console.log(error);
                  });
              }
            } else {
              this.commonService.showERPMessage('Cannot be Punched-In. Either the Employee does not have the Shift Allocated for Today or Attendance Entry is not registered for the user in the system.')
            }
          } else if (value === 'LOGIN-OUT'){ 
            
            if (checkObj.presentFlag && checkObj.active){
              this.empAttendanceFormObj.patchValue(this.commonService.myAttendanceObj);
              this.empAttendanceFormObj.get('loggedOutTime').setValue(new Date());
              this.empAttendanceFormObj.get('active').setValue(false);
              this.hrmService.saveOrUpdateEmployeeAttendance(this.empAttendanceFormObj.value).subscribe(
                data => {
                  this.commonService.myAttendanceObj = data;
                  this.loginButton = false;
                  this.logoutTime = new Date();
                  this.loginTime = new Date(new Date().setHours(0, 0, 0, 0));
                },error => {
                  console.log(error);
                });
            }else {
              this.commonService.showERPMessage("You are already Puched out..")
              this.loginButton = false;
            }
          } 
      } else{
        this.commonService.showERPMessage('Cannot be Punched-In. Either the Employee does not have the Shift Allocated for Today or Attendance Entry is not registered for the user in the system.')
      }
    });
  }
  private setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          // console.log(lat,lng);
          this.empAttendanceFormObj.get('locCord').setValue(lat + "/" + lng);
        });
      }
    }
  redirectToTimeSheetReport(){
    this.router.navigate(["/reports/timesheetHistory"]);
  }
  redirectToReport(){
    this.router.navigate(["/reports/reportsSummary"]);
  }
  showAsFullHistory(value: string){

  let selectedRow = "";
  let historyList = [];
  let labelName ="";
  if(value==='Birthday'){
      historyList = this.empBirthdayList;
      labelName = "Birthday for Current Month";
      const dialogRef = this.dialog.open(ViewHistoryComponent, {
        width: '50%',
        height:'50%',
        data:{selectedRow:selectedRow,moduleName: this.moduleName,historyList: historyList,labelName: labelName}
      });

      dialogRef.afterClosed().subscribe(result => {
      });
  }else if(value === 'Anniversary'){
      historyList = this.empAnniversaryList;
      labelName = "Anniversary for Current Month";
      const dialogRef = this.dialog.open(ViewHistoryComponent, {
        width: '50%',
        height:'50%',
        data:{selectedRow:selectedRow,moduleName: this.moduleName,historyList: historyList,labelName: labelName}
      });

      dialogRef.afterClosed().subscribe(result => {
      });
  }else if(value === 'LOGIN_HISTORY'){
      let formObj = new FormGroup({
         searchName: new FormControl('TODAY'),
        userObj: new FormGroup({
          userId: new FormControl(this.commonService.loggedInUserObj.userId)
        })
      });
      this.hrmService.fetchEmployeeAttendanceDetail(formObj.value).subscribe(
        data => { 
          if (this.commonUtils.checkNullOrUndefined(data))
          historyList = data;
          labelName = "My Attendance";
          const dialogRef = this.dialog.open(ViewHistoryComponent, {
            width: '50%',
            height:'50%',
            data:{selectedRow:selectedRow,moduleName: this.moduleName,historyList: historyList,labelName: labelName}
          });

          dialogRef.afterClosed().subscribe(result => {
          });
          }, error =>{
             console.log(error);
          })
        
   }
  }
  
  fetchCurrentMonthBasisEmployeeList(value: string) {
    let empFormObj:FormGroup = new FormGroup({
      filterType: new FormControl(value)
    });
    
      this.hrmService.fetchCurrentMonthBasisEmployeeList(empFormObj.value).subscribe(
      data=>{    
              if(value === 'BIRTHDAY')
                  this.empBirthdayList = data;
              else if(value === 'ANNIVERSARY')
                this.empAnniversaryList = data;
        },
      error=>
      {
         console.log(error);
      });
   }
   fetchDashBoardAttendanceDetails() {
    
    let empFormObj:FormGroup = new FormGroup({
        fromDate: new FormControl(this.fromDate),
        toDate: new FormControl(this.toDate),
    });
    
    this.hrmService.fetchDashBoardAttendanceDetails(empFormObj.value).subscribe(
      data=>{
        if(this.commonUtils.checkNullOrUndefined(data))
          this.attendanceData = data;
      },
      error=>
      {
         console.log(error);
      });
   }
   errorHandler(event) {
    event.target.src = this.errorimageSourcePath;
}
   fetchEmployeeAttendance() {
    let empFormObj:FormGroup = new FormGroup({
        userObj: new FormGroup({
          userId: new FormControl(),
        }),
        createdTs: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy'))
    }); 

      this.hrmService.fetchEmployeeAttendance(empFormObj.value).subscribe(
      data=>{    
              this.attendanceData = data; 
              this.empAttendanceFormObj.patchValue(data);
      },
      error=>
      {
         console.log(error);
      });
   }
   getAttendanceDateTooltip(value): string{
     this.currentDate = new Date();
     this.fromDate = new Date();
       this.toDate = new Date(); 

    if(value === 'Today'){
      this.fromDate.setDate( this.currentDate.getDate());
      this.toDate.setDate( this.currentDate.getDate());
    }
    else if(value === 'Yesterday'){
      this.fromDate.setDate( this.currentDate.getDate() - 1 );
      this.toDate.setDate( this.currentDate.getDate() - 1 );      
    }
    else if(value === 'Last Week'){
      this.fromDate.setDate( this.currentDate.getDate() - 7);
      this.toDate.setDate( this.currentDate.getDate() );
    }
    else if(value === 'Last Month'){
      this.fromDate.setDate( this.currentDate.getDate() - 30);
      this.toDate.setDate( this.currentDate.getDate() );
    }

    if(this.commonUtils.checkNullOrUndefined(value))
      return "From: "+this.datePipe.transform(this.fromDate, 'MM-dd-yyyy')+" - To: "+this.datePipe.transform(this.toDate,'MM-dd-yyyy');
    else 
      return '';
   }
    saveOrUpdateEmployeeAttendance() {
      
      this.hrmService.saveOrUpdateEmployeeAttendance(this.empAttendanceFormObj.value).subscribe(
      data=>{    
         this.empAttendanceFormObj.patchValue(data);
      },
      error=>
      {
         console.log(error);
      });
   }
}
     