
import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommonService} from '../shared/common.service';

@Injectable()
export class ReportsService {

  constructor(private http: HttpClient, private commonService: CommonService) {}
   
  private handleError(error: any) {
    console.log(error);
    alert(error);
    return observableThrowError(error.erpmessage);
  }

}