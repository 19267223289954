<!-- <mat-sidenav-container class="col-lg-12 col-md-12 col-sm-12 nopadding"  style="overflow: visible" >
  <mat-sidenav-content class="col-lg-12 nopadding">    
  
    <div class="col-lg-12">     
  
         <div class="col-lg-12 col-md-12 " style="margin-top: 10px;padding-left:0px;padding-right:0px">
  
        <table class="col-lg-12 col-md-12  table   table-striped  erp-veiw-template-table vertical-align-middle cdk-table-class" style="font-size: 14px !important" cdk-table #table [dataSource]="dataSource"  >
          <ng-container cdkColumnDef="userName">
            <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">User Name</th>
            <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.userName}}</td>
           <td cdk-footer-cell *cdkFooterCellDef>  </td>
          </ng-container> 
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">Status</th>
            <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.status}}</td>
           <td cdk-footer-cell *cdkFooterCellDef>  </td>
          </ng-container>
          <tr cdk-header-row *cdkHeaderRowDef="dataSource"  class="table-active alignCenter  vertical-align-middle" style="height: auto;">
          </tr>
              <tr cdk-row  *cdkRowDef="let row; columns: dataSource;" class="alternate-row" style="height: auto;" ></tr>
               <tr class ="mat-footer-row" cdk-footer-row *cdkFooterRowDef="dataSource"></tr>
          </table>
          </div>
          </div>
          
</mat-sidenav-container> -->
<div  class="tableStyle">
 <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" matSort   cdkDropListGroup class="center width98 table-bordered dataTable">
                      
                            <ng-container cdkColumnDef="userName">
                              <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">User Name</th>
                              <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.userName}}</td>
                             <td cdk-footer-cell *cdkFooterCellDef>  </td>
                            </ng-container> 
                            <ng-container cdkColumnDef="status">
                              <th cdk-header-cell *cdkHeaderCellDef class="boldLabel alignLeft">Status</th>
                              <td cdk-cell *cdkCellDef="let element" status-color class="alignLeft"> {{element.status}}</td>
                             <td cdk-footer-cell *cdkFooterCellDef>  </td>
                            </ng-container>
                        
                            
                           
           <tr mat-header-row *matHeaderRowDef="dataSourceColumns; sticky: true" style="height: 30px !important;text-align: center"></tr>
                     <tr style="height: 50px !important;" mat-row *matRowDef="let row; columns: dataSourceColumns;let i = index;"></tr>
        </table> 
      </div>


<div class="popupspace pt-3" style="text-align: center;">
<button type= "button"  mat-flat-button color="warn" class="padding_rt" (click)="cancelClick()">Cancel</button>
  </div>
      </div>